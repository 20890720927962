const data = [
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'JANGKARAN',
    border: [
      [110.0433, -7.884],
      [110.0428, -7.8837],
      [110.042, -7.8831],
      [110.0417, -7.8829],
      [110.0416, -7.8836],
      [110.0416, -7.8841],
      [110.0414, -7.8842],
      [110.0405, -7.8845],
      [110.0391, -7.8842],
      [110.0379, -7.8839],
      [110.0372, -7.8841],
      [110.0366, -7.8843],
      [110.0363, -7.8844],
      [110.0348, -7.8874],
      [110.0345, -7.8883],
      [110.0345, -7.8888],
      [110.0344, -7.8904],
      [110.0339, -7.8929],
      [110.0334, -7.8945],
      [110.032, -7.8954],
      [110.0318, -7.8954],
      [110.0304, -7.8953],
      [110.03, -7.8926],
      [110.0294, -7.8914],
      [110.0285, -7.8909],
      [110.0281, -7.8906],
      [110.0271, -7.8899],
      [110.0266, -7.8899],
      [110.025, -7.89],
      [110.0247, -7.89],
      [110.0233, -7.8906],
      [110.0229, -7.8908],
      [110.0225, -7.891],
      [110.0224, -7.8911],
      [110.0222, -7.8913],
      [110.0219, -7.8915],
      [110.0218, -7.8919],
      [110.0213, -7.892],
      [110.021, -7.8919],
      [110.0208, -7.8919],
      [110.0203, -7.8917],
      [110.0199, -7.8916],
      [110.0195, -7.8913],
      [110.0193, -7.8905],
      [110.0187, -7.89],
      [110.0184, -7.8898],
      [110.0178, -7.8898],
      [110.0174, -7.8896],
      [110.0171, -7.8893],
      [110.0169, -7.8892],
      [110.0165, -7.8893],
      [110.0163, -7.8894],
      [110.0161, -7.8894],
      [110.0157, -7.8895],
      [110.0153, -7.8897],
      [110.0151, -7.8897],
      [110.0149, -7.8899],
      [110.0151, -7.8902],
      [110.0147, -7.8902],
      [110.0144, -7.89],
      [110.014, -7.8896],
      [110.0139, -7.8895],
      [110.0135, -7.8891],
      [110.013, -7.8886],
      [110.0128, -7.8886],
      [110.0127, -7.8886],
      [110.0119, -7.8884],
      [110.0113, -7.8879],
      [110.011, -7.8876],
      [110.0105, -7.8874],
      [110.0103, -7.8873],
      [110.0102, -7.8873],
      [110.0092, -7.8868],
      [110.0091, -7.8867],
      [110.0089, -7.8867],
      [110.0087, -7.8867],
      [110.0083, -7.8866],
      [110.0073, -7.8862],
      [110.006, -7.8856],
      [110.005, -7.8852],
      [110.0046, -7.8859],
      [110.0041, -7.887],
      [110.004, -7.8873],
      [110.0039, -7.8876],
      [110.0037, -7.8878],
      [110.0035, -7.8883],
      [110.0042, -7.8885],
      [110.0056, -7.8891],
      [110.0057, -7.8892],
      [110.0062, -7.8894],
      [110.007, -7.8897],
      [110.0082, -7.8902],
      [110.0084, -7.8903],
      [110.0099, -7.8909],
      [110.0128, -7.8921],
      [110.0135, -7.8925],
      [110.0138, -7.8925],
      [110.0145, -7.8928],
      [110.0153, -7.8931],
      [110.0156, -7.8933],
      [110.0163, -7.8936],
      [110.0167, -7.8936],
      [110.0181, -7.8943],
      [110.0193, -7.8948],
      [110.0203, -7.8953],
      [110.0216, -7.8959],
      [110.0223, -7.8962],
      [110.0231, -7.8964],
      [110.0238, -7.8967],
      [110.0248, -7.8971],
      [110.0259, -7.8975],
      [110.027, -7.898],
      [110.0277, -7.8982],
      [110.0288, -7.8984],
      [110.0302, -7.8986],
      [110.0304, -7.8986],
      [110.0309, -7.8987],
      [110.031, -7.8988],
      [110.0311, -7.8988],
      [110.0312, -7.8994],
      [110.0315, -7.8997],
      [110.0316, -7.8999],
      [110.0319, -7.8999],
      [110.0322, -7.9],
      [110.0325, -7.9002],
      [110.0328, -7.9003],
      [110.0332, -7.9003],
      [110.0337, -7.9004],
      [110.0341, -7.9005],
      [110.0346, -7.9008],
      [110.0352, -7.901],
      [110.0355, -7.9011],
      [110.0359, -7.9013],
      [110.0371, -7.9017],
      [110.0385, -7.9021],
      [110.0389, -7.9023],
      [110.0389, -7.9019],
      [110.0392, -7.901],
      [110.0397, -7.9001],
      [110.0406, -7.8989],
      [110.0409, -7.8979],
      [110.0413, -7.8962],
      [110.0416, -7.8938],
      [110.0434, -7.8948],
      [110.0438, -7.8938],
      [110.0441, -7.893],
      [110.0449, -7.8917],
      [110.0453, -7.8908],
      [110.0453, -7.89],
      [110.0451, -7.8894],
      [110.0439, -7.8887],
      [110.0433, -7.889],
      [110.0429, -7.8891],
      [110.0424, -7.889],
      [110.0425, -7.8879],
      [110.0428, -7.8855],
      [110.0433, -7.8851],
      [110.0433, -7.8844],
      [110.0433, -7.884]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'SINDUTAN',
    border: [
      [110.0475, -7.8687],
      [110.0458, -7.8687],
      [110.0455, -7.8687],
      [110.0447, -7.8688],
      [110.0436, -7.869],
      [110.0437, -7.8691],
      [110.0442, -7.8695],
      [110.0447, -7.8702],
      [110.0448, -7.8706],
      [110.0449, -7.8712],
      [110.0449, -7.8714],
      [110.0448, -7.8722],
      [110.0448, -7.8731],
      [110.0447, -7.8731],
      [110.0444, -7.8732],
      [110.0443, -7.8732],
      [110.0436, -7.8731],
      [110.0431, -7.8728],
      [110.0429, -7.8749],
      [110.0429, -7.8757],
      [110.0429, -7.8764],
      [110.0428, -7.8767],
      [110.0427, -7.8771],
      [110.0424, -7.879],
      [110.0424, -7.8795],
      [110.0424, -7.8799],
      [110.0432, -7.8805],
      [110.0434, -7.8806],
      [110.0434, -7.882],
      [110.0434, -7.8823],
      [110.0435, -7.8833],
      [110.0435, -7.8839],
      [110.0433, -7.884],
      [110.0433, -7.8844],
      [110.0433, -7.8851],
      [110.0428, -7.8855],
      [110.0425, -7.8879],
      [110.0424, -7.889],
      [110.0429, -7.8891],
      [110.0433, -7.889],
      [110.0439, -7.8887],
      [110.0451, -7.8894],
      [110.0453, -7.89],
      [110.0453, -7.8908],
      [110.0449, -7.8917],
      [110.0441, -7.893],
      [110.0438, -7.8938],
      [110.0434, -7.8948],
      [110.0416, -7.8938],
      [110.0413, -7.8962],
      [110.0409, -7.8979],
      [110.0406, -7.8989],
      [110.0397, -7.9001],
      [110.0392, -7.901],
      [110.0389, -7.9019],
      [110.0389, -7.9023],
      [110.0401, -7.9027],
      [110.0406, -7.9029],
      [110.0415, -7.9031],
      [110.0426, -7.9036],
      [110.0437, -7.9039],
      [110.044, -7.9041],
      [110.045, -7.9025],
      [110.0459, -7.9004],
      [110.0469, -7.8986],
      [110.0477, -7.8969],
      [110.0487, -7.8939],
      [110.049, -7.8926],
      [110.0489, -7.8912],
      [110.0489, -7.89],
      [110.0489, -7.8889],
      [110.0491, -7.8881],
      [110.0496, -7.887],
      [110.0504, -7.8861],
      [110.0525, -7.8869],
      [110.0536, -7.8875],
      [110.0537, -7.8873],
      [110.0538, -7.8866],
      [110.0537, -7.8865],
      [110.0536, -7.8863],
      [110.0536, -7.8859],
      [110.0535, -7.8858],
      [110.0533, -7.8854],
      [110.0531, -7.8851],
      [110.053, -7.8849],
      [110.0523, -7.8846],
      [110.0521, -7.8844],
      [110.052, -7.884],
      [110.0519, -7.8838],
      [110.0521, -7.8826],
      [110.0523, -7.8818],
      [110.0525, -7.881],
      [110.0526, -7.8801],
      [110.0526, -7.8799],
      [110.0528, -7.8792],
      [110.053, -7.8782],
      [110.0532, -7.8778],
      [110.0539, -7.8766],
      [110.0537, -7.8765],
      [110.0531, -7.8765],
      [110.0528, -7.8765],
      [110.0517, -7.8761],
      [110.0516, -7.876],
      [110.0514, -7.876],
      [110.0512, -7.8759],
      [110.051, -7.876],
      [110.0509, -7.876],
      [110.0502, -7.8759],
      [110.0499, -7.8759],
      [110.0493, -7.8759],
      [110.0488, -7.8761],
      [110.0478, -7.8757],
      [110.0475, -7.8756],
      [110.0466, -7.875],
      [110.0467, -7.8738],
      [110.0463, -7.8733],
      [110.0465, -7.8724],
      [110.047, -7.8724],
      [110.0471, -7.8723],
      [110.0475, -7.8724],
      [110.0475, -7.8722],
      [110.0474, -7.8716],
      [110.0476, -7.8708],
      [110.048, -7.8705],
      [110.0482, -7.8702],
      [110.0485, -7.8695],
      [110.0481, -7.8693],
      [110.0477, -7.869],
      [110.0475, -7.869],
      [110.0475, -7.8687]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'PALIHAN',
    border: [
      [110.0586, -7.8821],
      [110.0585, -7.882],
      [110.0581, -7.8819],
      [110.0568, -7.8816],
      [110.0552, -7.8807],
      [110.0546, -7.8806],
      [110.0539, -7.8804],
      [110.0526, -7.8801],
      [110.0525, -7.881],
      [110.0523, -7.8818],
      [110.0521, -7.8826],
      [110.0519, -7.8838],
      [110.052, -7.884],
      [110.0521, -7.8844],
      [110.0523, -7.8846],
      [110.053, -7.8849],
      [110.0531, -7.8851],
      [110.0533, -7.8854],
      [110.0535, -7.8858],
      [110.0536, -7.8859],
      [110.0536, -7.8863],
      [110.0537, -7.8865],
      [110.0538, -7.8866],
      [110.0537, -7.8873],
      [110.0536, -7.8875],
      [110.0525, -7.8869],
      [110.0504, -7.8861],
      [110.0496, -7.887],
      [110.0491, -7.8881],
      [110.0489, -7.8889],
      [110.0489, -7.89],
      [110.0489, -7.8912],
      [110.049, -7.8926],
      [110.0487, -7.8939],
      [110.0477, -7.8969],
      [110.0469, -7.8986],
      [110.0459, -7.9004],
      [110.045, -7.9025],
      [110.044, -7.9041],
      [110.0457, -7.9047],
      [110.0467, -7.905],
      [110.0474, -7.9053],
      [110.0479, -7.9055],
      [110.0491, -7.9059],
      [110.05, -7.9063],
      [110.0511, -7.9066],
      [110.0517, -7.9069],
      [110.0523, -7.9071],
      [110.0524, -7.9071],
      [110.0532, -7.9074],
      [110.0538, -7.9076],
      [110.0544, -7.9078],
      [110.0551, -7.9082],
      [110.0552, -7.9083],
      [110.0555, -7.9078],
      [110.0565, -7.9052],
      [110.0575, -7.9029],
      [110.0592, -7.8994],
      [110.0603, -7.8971],
      [110.0608, -7.8955],
      [110.0611, -7.8943],
      [110.0613, -7.8938],
      [110.0618, -7.8916],
      [110.0618, -7.8913],
      [110.0629, -7.8892],
      [110.0636, -7.8877],
      [110.0648, -7.8849],
      [110.0643, -7.8846],
      [110.0616, -7.8833],
      [110.0594, -7.8824],
      [110.0586, -7.8821]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'GLAGAH',
    border: [
      [110.0705, -7.8934],
      [110.0678, -7.8927],
      [110.0656, -7.8922],
      [110.0654, -7.8921],
      [110.0645, -7.8918],
      [110.0641, -7.8918],
      [110.0635, -7.8917],
      [110.0622, -7.8916],
      [110.0618, -7.8916],
      [110.0613, -7.8938],
      [110.0611, -7.8943],
      [110.0608, -7.8955],
      [110.0603, -7.8971],
      [110.0592, -7.8994],
      [110.0575, -7.9029],
      [110.0565, -7.9052],
      [110.0555, -7.9078],
      [110.0552, -7.9083],
      [110.0556, -7.9083],
      [110.0561, -7.9085],
      [110.0565, -7.9087],
      [110.057, -7.9089],
      [110.0582, -7.9093],
      [110.0591, -7.9097],
      [110.0599, -7.9101],
      [110.0609, -7.9104],
      [110.062, -7.9109],
      [110.0625, -7.9111],
      [110.0627, -7.9112],
      [110.063, -7.9114],
      [110.0634, -7.9115],
      [110.0639, -7.9117],
      [110.0642, -7.9118],
      [110.0646, -7.9121],
      [110.065, -7.9122],
      [110.0654, -7.9123],
      [110.0659, -7.9126],
      [110.0663, -7.9127],
      [110.0668, -7.9129],
      [110.0673, -7.913],
      [110.0682, -7.9135],
      [110.0692, -7.914],
      [110.0695, -7.9141],
      [110.0701, -7.9143],
      [110.0703, -7.9144],
      [110.0714, -7.9148],
      [110.0724, -7.9153],
      [110.073, -7.9156],
      [110.0737, -7.9158],
      [110.0744, -7.9162],
      [110.075, -7.9163],
      [110.0755, -7.9166],
      [110.0757, -7.9166],
      [110.0758, -7.9166],
      [110.0764, -7.9169],
      [110.0765, -7.9165],
      [110.0767, -7.9162],
      [110.077, -7.916],
      [110.0775, -7.9159],
      [110.0779, -7.916],
      [110.0782, -7.9164],
      [110.0785, -7.9168],
      [110.0789, -7.9169],
      [110.0796, -7.9169],
      [110.0802, -7.9167],
      [110.0808, -7.9165],
      [110.0811, -7.9161],
      [110.0814, -7.9156],
      [110.0818, -7.9144],
      [110.0822, -7.9127],
      [110.0823, -7.9119],
      [110.0829, -7.9105],
      [110.0835, -7.909],
      [110.0846, -7.9079],
      [110.0848, -7.9073],
      [110.085, -7.9066],
      [110.086, -7.9044],
      [110.0871, -7.9019],
      [110.087, -7.9016],
      [110.0868, -7.9015],
      [110.0867, -7.9015],
      [110.0861, -7.901],
      [110.0854, -7.9003],
      [110.0845, -7.8998],
      [110.0834, -7.899],
      [110.0826, -7.8985],
      [110.0813, -7.898],
      [110.0811, -7.8978],
      [110.0808, -7.8974],
      [110.0807, -7.8974],
      [110.08, -7.8969],
      [110.0798, -7.8968],
      [110.0796, -7.8968],
      [110.0791, -7.8967],
      [110.0783, -7.8965],
      [110.0777, -7.8963],
      [110.0776, -7.8963],
      [110.0771, -7.8964],
      [110.0769, -7.8964],
      [110.0765, -7.8965],
      [110.0762, -7.8965],
      [110.0759, -7.8967],
      [110.0756, -7.8968],
      [110.0747, -7.8966],
      [110.0736, -7.8962],
      [110.0733, -7.8959],
      [110.0728, -7.8951],
      [110.0724, -7.8945],
      [110.0719, -7.8942],
      [110.0714, -7.8937],
      [110.0705, -7.8934]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'KALIDENGEN',
    border: [
      [110.0917, -7.8879],
      [110.0911, -7.8878],
      [110.0901, -7.8876],
      [110.0887, -7.8874],
      [110.0876, -7.8872],
      [110.0872, -7.8872],
      [110.0856, -7.8872],
      [110.0853, -7.8872],
      [110.0844, -7.8872],
      [110.0839, -7.8872],
      [110.0838, -7.8871],
      [110.0838, -7.8875],
      [110.0836, -7.8883],
      [110.0836, -7.8887],
      [110.0836, -7.889],
      [110.0835, -7.8894],
      [110.0824, -7.8904],
      [110.0823, -7.891],
      [110.0822, -7.8918],
      [110.0813, -7.8917],
      [110.0808, -7.8918],
      [110.0807, -7.8925],
      [110.0797, -7.8922],
      [110.0789, -7.8922],
      [110.0784, -7.8922],
      [110.0774, -7.8921],
      [110.0773, -7.8921],
      [110.0769, -7.8921],
      [110.0766, -7.8923],
      [110.0764, -7.8928],
      [110.0763, -7.8933],
      [110.0761, -7.8938],
      [110.0759, -7.8942],
      [110.0759, -7.8944],
      [110.0759, -7.8948],
      [110.0759, -7.8951],
      [110.076, -7.8953],
      [110.0763, -7.8955],
      [110.0764, -7.8956],
      [110.0768, -7.8956],
      [110.0771, -7.8955],
      [110.077, -7.8958],
      [110.0772, -7.896],
      [110.0775, -7.8961],
      [110.0777, -7.8963],
      [110.0783, -7.8965],
      [110.0791, -7.8967],
      [110.0796, -7.8968],
      [110.0798, -7.8968],
      [110.08, -7.8969],
      [110.0807, -7.8974],
      [110.0808, -7.8974],
      [110.0811, -7.8978],
      [110.0813, -7.898],
      [110.0826, -7.8985],
      [110.0834, -7.899],
      [110.0845, -7.8998],
      [110.0854, -7.9003],
      [110.0861, -7.901],
      [110.0867, -7.9015],
      [110.0868, -7.9015],
      [110.087, -7.9016],
      [110.0871, -7.9019],
      [110.0873, -7.9014],
      [110.0873, -7.9012],
      [110.0881, -7.8994],
      [110.0893, -7.8965],
      [110.0903, -7.8941],
      [110.0906, -7.8934],
      [110.0917, -7.8908],
      [110.0925, -7.8888],
      [110.0928, -7.8882],
      [110.0929, -7.8881],
      [110.0918, -7.8879],
      [110.0917, -7.8879]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'PLUMBON',
    border: [
      [110.1059, -7.8899],
      [110.1056, -7.89],
      [110.1048, -7.89],
      [110.1035, -7.8897],
      [110.1034, -7.8915],
      [110.1032, -7.8927],
      [110.1038, -7.8928],
      [110.1036, -7.8936],
      [110.1044, -7.8937],
      [110.1047, -7.8941],
      [110.1053, -7.8944],
      [110.106, -7.8945],
      [110.1058, -7.8956],
      [110.1047, -7.8953],
      [110.1033, -7.8948],
      [110.103, -7.895],
      [110.1022, -7.8945],
      [110.1023, -7.8942],
      [110.1018, -7.8939],
      [110.1012, -7.8941],
      [110.0995, -7.8934],
      [110.0988, -7.8953],
      [110.0977, -7.8948],
      [110.0971, -7.8963],
      [110.0958, -7.8959],
      [110.0941, -7.8952],
      [110.0929, -7.8948],
      [110.0926, -7.8948],
      [110.0922, -7.8948],
      [110.0916, -7.8945],
      [110.0911, -7.8944],
      [110.0906, -7.8942],
      [110.0903, -7.8941],
      [110.0893, -7.8965],
      [110.0881, -7.8994],
      [110.0873, -7.9012],
      [110.0873, -7.9014],
      [110.0871, -7.9019],
      [110.088, -7.9022],
      [110.0882, -7.9023],
      [110.089, -7.9022],
      [110.0893, -7.9022],
      [110.0898, -7.9024],
      [110.0902, -7.9025],
      [110.0903, -7.9025],
      [110.0908, -7.9026],
      [110.0915, -7.903],
      [110.0921, -7.9035],
      [110.0926, -7.9044],
      [110.093, -7.9051],
      [110.0934, -7.9054],
      [110.0941, -7.9055],
      [110.0946, -7.9055],
      [110.095, -7.9059],
      [110.0956, -7.9068],
      [110.0963, -7.9078],
      [110.0966, -7.9084],
      [110.0969, -7.9093],
      [110.0973, -7.9095],
      [110.0979, -7.9097],
      [110.0982, -7.9096],
      [110.0992, -7.909],
      [110.1003, -7.9085],
      [110.1013, -7.908],
      [110.1024, -7.9079],
      [110.1042, -7.908],
      [110.1054, -7.9076],
      [110.1074, -7.9068],
      [110.1081, -7.906],
      [110.1085, -7.9051],
      [110.1086, -7.9041],
      [110.1088, -7.9033],
      [110.109, -7.9029],
      [110.1091, -7.9021],
      [110.1092, -7.9018],
      [110.1093, -7.9011],
      [110.1095, -7.9003],
      [110.1096, -7.8991],
      [110.1097, -7.8983],
      [110.1097, -7.8978],
      [110.1095, -7.8978],
      [110.1093, -7.8988],
      [110.1091, -7.8992],
      [110.1092, -7.8996],
      [110.109, -7.9002],
      [110.1087, -7.9007],
      [110.1086, -7.9007],
      [110.108, -7.9006],
      [110.1077, -7.9002],
      [110.1079, -7.8996],
      [110.1078, -7.8991],
      [110.1077, -7.899],
      [110.1078, -7.8988],
      [110.1075, -7.8985],
      [110.1072, -7.8982],
      [110.1077, -7.8964],
      [110.108, -7.8951],
      [110.1082, -7.8946],
      [110.1083, -7.894],
      [110.1085, -7.8932],
      [110.1085, -7.8923],
      [110.1086, -7.8914],
      [110.1087, -7.8908],
      [110.1087, -7.8904],
      [110.1064, -7.8904],
      [110.1061, -7.8904],
      [110.1059, -7.8899]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'KEDUNDANG',
    border: [
      [110.1057, -7.8785],
      [110.1056, -7.8784],
      [110.1054, -7.8779],
      [110.1054, -7.8775],
      [110.106, -7.8773],
      [110.1061, -7.8772],
      [110.1062, -7.8762],
      [110.1063, -7.8757],
      [110.1047, -7.8763],
      [110.1044, -7.8763],
      [110.1028, -7.8768],
      [110.102, -7.8771],
      [110.1012, -7.8773],
      [110.0993, -7.8778],
      [110.0988, -7.8779],
      [110.0989, -7.8785],
      [110.099, -7.8797],
      [110.0987, -7.8807],
      [110.0986, -7.8815],
      [110.0988, -7.8816],
      [110.0992, -7.8818],
      [110.1001, -7.8818],
      [110.101, -7.8821],
      [110.101, -7.8822],
      [110.1005, -7.884],
      [110.1002, -7.8853],
      [110.0998, -7.8872],
      [110.0994, -7.8891],
      [110.0994, -7.8893],
      [110.0994, -7.8894],
      [110.0983, -7.8893],
      [110.0982, -7.8897],
      [110.0978, -7.8901],
      [110.0974, -7.8903],
      [110.0972, -7.8902],
      [110.0971, -7.8907],
      [110.0972, -7.8915],
      [110.0972, -7.892],
      [110.097, -7.8921],
      [110.0963, -7.8919],
      [110.0955, -7.8915],
      [110.095, -7.8913],
      [110.0946, -7.8912],
      [110.0943, -7.8909],
      [110.0941, -7.891],
      [110.0942, -7.8913],
      [110.094, -7.8917],
      [110.0935, -7.8934],
      [110.0932, -7.8942],
      [110.0929, -7.8948],
      [110.0941, -7.8952],
      [110.0958, -7.8959],
      [110.0971, -7.8963],
      [110.0977, -7.8948],
      [110.0988, -7.8953],
      [110.0995, -7.8934],
      [110.1012, -7.8941],
      [110.1018, -7.8939],
      [110.1023, -7.8942],
      [110.1022, -7.8945],
      [110.103, -7.895],
      [110.1033, -7.8948],
      [110.1047, -7.8953],
      [110.1058, -7.8956],
      [110.106, -7.8945],
      [110.1053, -7.8944],
      [110.1047, -7.8941],
      [110.1044, -7.8937],
      [110.1036, -7.8936],
      [110.1038, -7.8928],
      [110.1032, -7.8927],
      [110.1034, -7.8915],
      [110.1035, -7.8897],
      [110.1048, -7.89],
      [110.1056, -7.89],
      [110.1059, -7.8899],
      [110.1058, -7.8898],
      [110.1056, -7.8895],
      [110.1056, -7.8891],
      [110.1057, -7.8888],
      [110.1056, -7.8885],
      [110.1054, -7.8879],
      [110.1056, -7.8875],
      [110.106, -7.8869],
      [110.1061, -7.8865],
      [110.1063, -7.8864],
      [110.1067, -7.8864],
      [110.1075, -7.8864],
      [110.1081, -7.8864],
      [110.1087, -7.8863],
      [110.1087, -7.8855],
      [110.1088, -7.8846],
      [110.1088, -7.8841],
      [110.1074, -7.8841],
      [110.1075, -7.8833],
      [110.1065, -7.8834],
      [110.1062, -7.8833],
      [110.106, -7.8833],
      [110.1059, -7.883],
      [110.1054, -7.8828],
      [110.105, -7.8825],
      [110.1046, -7.8819],
      [110.1041, -7.8815],
      [110.1036, -7.8808],
      [110.1032, -7.8802],
      [110.1032, -7.88],
      [110.1033, -7.8799],
      [110.1035, -7.8798],
      [110.1041, -7.8798],
      [110.1047, -7.8799],
      [110.1054, -7.8799],
      [110.1054, -7.8791],
      [110.1054, -7.8788],
      [110.1057, -7.8785]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'DEMEN',
    border: [
      [110.0986, -7.8815],
      [110.0985, -7.8814],
      [110.098, -7.8812],
      [110.0975, -7.8809],
      [110.097, -7.881],
      [110.0963, -7.881],
      [110.0955, -7.881],
      [110.0951, -7.8814],
      [110.0941, -7.8834],
      [110.0924, -7.8864],
      [110.0917, -7.8879],
      [110.0918, -7.8879],
      [110.0929, -7.8881],
      [110.0928, -7.8882],
      [110.0925, -7.8888],
      [110.0917, -7.8908],
      [110.0906, -7.8934],
      [110.0903, -7.8941],
      [110.0906, -7.8942],
      [110.0911, -7.8944],
      [110.0916, -7.8945],
      [110.0922, -7.8948],
      [110.0926, -7.8948],
      [110.0929, -7.8948],
      [110.0932, -7.8942],
      [110.0935, -7.8934],
      [110.094, -7.8917],
      [110.0942, -7.8913],
      [110.0941, -7.891],
      [110.0943, -7.8909],
      [110.0946, -7.8912],
      [110.095, -7.8913],
      [110.0955, -7.8915],
      [110.0963, -7.8919],
      [110.097, -7.8921],
      [110.0972, -7.892],
      [110.0972, -7.8915],
      [110.0971, -7.8907],
      [110.0972, -7.8902],
      [110.0974, -7.8903],
      [110.0978, -7.8901],
      [110.0982, -7.8897],
      [110.0983, -7.8893],
      [110.0994, -7.8894],
      [110.0994, -7.8893],
      [110.0994, -7.8891],
      [110.0998, -7.8872],
      [110.1002, -7.8853],
      [110.1005, -7.884],
      [110.101, -7.8822],
      [110.101, -7.8821],
      [110.1001, -7.8818],
      [110.0992, -7.8818],
      [110.0988, -7.8816],
      [110.0986, -7.8815]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'KULUR',
    border: [
      [110.1128, -7.8805],
      [110.1133, -7.8792],
      [110.1135, -7.8788],
      [110.1134, -7.8774],
      [110.1134, -7.877],
      [110.1134, -7.8766],
      [110.1131, -7.8762],
      [110.1125, -7.876],
      [110.1122, -7.876],
      [110.1122, -7.8749],
      [110.1122, -7.8743],
      [110.1119, -7.8741],
      [110.1115, -7.874],
      [110.111, -7.8741],
      [110.1108, -7.8736],
      [110.1108, -7.873],
      [110.1108, -7.8725],
      [110.1105, -7.8721],
      [110.1105, -7.8719],
      [110.1104, -7.8716],
      [110.1106, -7.8709],
      [110.1107, -7.8704],
      [110.1108, -7.8701],
      [110.1109, -7.8695],
      [110.1106, -7.8689],
      [110.1089, -7.8678],
      [110.1073, -7.8671],
      [110.1045, -7.866],
      [110.103, -7.8657],
      [110.1016, -7.8654],
      [110.1005, -7.8652],
      [110.0986, -7.8648],
      [110.0983, -7.8647],
      [110.098, -7.8658],
      [110.0975, -7.8664],
      [110.0972, -7.8667],
      [110.0972, -7.8671],
      [110.0971, -7.8676],
      [110.097, -7.8688],
      [110.097, -7.8697],
      [110.0974, -7.8708],
      [110.0976, -7.8715],
      [110.097, -7.8723],
      [110.0967, -7.873],
      [110.0965, -7.8735],
      [110.0968, -7.8742],
      [110.0963, -7.8746],
      [110.0958, -7.875],
      [110.0956, -7.8756],
      [110.0954, -7.8759],
      [110.0951, -7.8761],
      [110.0953, -7.8767],
      [110.0954, -7.8771],
      [110.0954, -7.8773],
      [110.0955, -7.8783],
      [110.0954, -7.8791],
      [110.0957, -7.8797],
      [110.0961, -7.8804],
      [110.0967, -7.8806],
      [110.097, -7.881],
      [110.0975, -7.8809],
      [110.098, -7.8812],
      [110.0985, -7.8814],
      [110.0986, -7.8815],
      [110.0987, -7.8807],
      [110.099, -7.8797],
      [110.0989, -7.8785],
      [110.0988, -7.8779],
      [110.0993, -7.8778],
      [110.1012, -7.8773],
      [110.102, -7.8771],
      [110.1028, -7.8768],
      [110.1044, -7.8763],
      [110.1047, -7.8763],
      [110.1063, -7.8757],
      [110.1062, -7.8762],
      [110.1061, -7.8772],
      [110.106, -7.8773],
      [110.1054, -7.8775],
      [110.1054, -7.8779],
      [110.1056, -7.8784],
      [110.1057, -7.8785],
      [110.1061, -7.8787],
      [110.1064, -7.879],
      [110.1068, -7.8792],
      [110.1074, -7.8792],
      [110.1076, -7.8795],
      [110.1078, -7.8798],
      [110.1083, -7.8799],
      [110.109, -7.8802],
      [110.1096, -7.8803],
      [110.1102, -7.8804],
      [110.1106, -7.8805],
      [110.1115, -7.8807],
      [110.1117, -7.8807],
      [110.1121, -7.8807],
      [110.1121, -7.8805],
      [110.1126, -7.8805],
      [110.1128, -7.8805]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'KALIGINTUNG',
    border: [
      [110.0894, -7.8608],
      [110.0892, -7.8608],
      [110.0888, -7.8611],
      [110.0883, -7.8619],
      [110.0876, -7.863],
      [110.0866, -7.8645],
      [110.0861, -7.8661],
      [110.0863, -7.8685],
      [110.0863, -7.8696],
      [110.0861, -7.8702],
      [110.0862, -7.8706],
      [110.0863, -7.8714],
      [110.087, -7.8723],
      [110.0874, -7.8734],
      [110.0874, -7.8741],
      [110.0873, -7.8749],
      [110.087, -7.8753],
      [110.0868, -7.8756],
      [110.0864, -7.8762],
      [110.0859, -7.8772],
      [110.0857, -7.8779],
      [110.0856, -7.8789],
      [110.0856, -7.8793],
      [110.0856, -7.8794],
      [110.0851, -7.8806],
      [110.0845, -7.8806],
      [110.0845, -7.8813],
      [110.0846, -7.8818],
      [110.0844, -7.8822],
      [110.0843, -7.8831],
      [110.0842, -7.8843],
      [110.0842, -7.8845],
      [110.084, -7.8857],
      [110.0838, -7.8868],
      [110.0838, -7.8871],
      [110.0839, -7.8872],
      [110.0844, -7.8872],
      [110.0853, -7.8872],
      [110.0856, -7.8872],
      [110.0872, -7.8872],
      [110.0876, -7.8872],
      [110.0887, -7.8874],
      [110.0901, -7.8876],
      [110.0911, -7.8878],
      [110.0917, -7.8879],
      [110.0924, -7.8864],
      [110.0941, -7.8834],
      [110.0951, -7.8814],
      [110.0955, -7.881],
      [110.0963, -7.881],
      [110.097, -7.881],
      [110.0967, -7.8806],
      [110.0961, -7.8804],
      [110.0957, -7.8797],
      [110.0954, -7.8791],
      [110.0955, -7.8783],
      [110.0954, -7.8773],
      [110.0954, -7.8771],
      [110.0953, -7.8767],
      [110.0951, -7.8761],
      [110.0954, -7.8759],
      [110.0956, -7.8756],
      [110.0958, -7.875],
      [110.0963, -7.8746],
      [110.0968, -7.8742],
      [110.0965, -7.8735],
      [110.0967, -7.873],
      [110.097, -7.8723],
      [110.0976, -7.8715],
      [110.0974, -7.8708],
      [110.097, -7.8697],
      [110.097, -7.8688],
      [110.0971, -7.8676],
      [110.0972, -7.8671],
      [110.0972, -7.8667],
      [110.0975, -7.8664],
      [110.098, -7.8658],
      [110.0983, -7.8647],
      [110.0983, -7.8646],
      [110.0951, -7.8632],
      [110.0916, -7.8618],
      [110.0915, -7.8617],
      [110.0909, -7.8614],
      [110.0899, -7.8609],
      [110.0894, -7.8608]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'TEMON WETAN',
    border: [
      [110.0861, -7.8702],
      [110.0855, -7.8711],
      [110.0846, -7.8714],
      [110.0835, -7.8713],
      [110.0825, -7.871],
      [110.0814, -7.8704],
      [110.0796, -7.8697],
      [110.078, -7.869],
      [110.0779, -7.869],
      [110.0777, -7.8701],
      [110.0776, -7.871],
      [110.0774, -7.8716],
      [110.077, -7.8721],
      [110.0764, -7.8722],
      [110.0759, -7.8724],
      [110.0757, -7.8727],
      [110.0757, -7.8732],
      [110.0755, -7.8741],
      [110.0754, -7.8742],
      [110.0753, -7.8744],
      [110.0753, -7.875],
      [110.0751, -7.8757],
      [110.0753, -7.8759],
      [110.0755, -7.8762],
      [110.076, -7.8764],
      [110.0763, -7.8767],
      [110.0764, -7.8769],
      [110.0765, -7.8773],
      [110.077, -7.8778],
      [110.0777, -7.8787],
      [110.0782, -7.8792],
      [110.0781, -7.8798],
      [110.0776, -7.8808],
      [110.0775, -7.8811],
      [110.0769, -7.881],
      [110.0767, -7.8821],
      [110.0774, -7.8821],
      [110.0774, -7.8828],
      [110.0772, -7.8839],
      [110.0773, -7.8843],
      [110.0774, -7.8851],
      [110.0776, -7.8854],
      [110.0776, -7.8859],
      [110.078, -7.8863],
      [110.0784, -7.8865],
      [110.079, -7.8866],
      [110.0794, -7.8868],
      [110.0799, -7.8871],
      [110.0804, -7.8872],
      [110.0812, -7.8876],
      [110.0823, -7.8882],
      [110.0829, -7.8886],
      [110.0836, -7.889],
      [110.0836, -7.8887],
      [110.0836, -7.8883],
      [110.0838, -7.8875],
      [110.0838, -7.8871],
      [110.0838, -7.8868],
      [110.084, -7.8857],
      [110.0842, -7.8845],
      [110.0842, -7.8843],
      [110.0843, -7.8831],
      [110.0844, -7.8822],
      [110.0846, -7.8818],
      [110.0845, -7.8813],
      [110.0845, -7.8806],
      [110.0851, -7.8806],
      [110.0856, -7.8794],
      [110.0856, -7.8793],
      [110.0856, -7.8789],
      [110.0857, -7.8779],
      [110.0859, -7.8772],
      [110.0864, -7.8762],
      [110.0868, -7.8756],
      [110.087, -7.8753],
      [110.0873, -7.8749],
      [110.0874, -7.8741],
      [110.0874, -7.8734],
      [110.087, -7.8723],
      [110.0863, -7.8714],
      [110.0862, -7.8706],
      [110.0861, -7.8702]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'TEMON KULON',
    border: [
      [110.0779, -7.869],
      [110.0771, -7.8687],
      [110.0762, -7.8686],
      [110.0755, -7.8688],
      [110.075, -7.8689],
      [110.0748, -7.8692],
      [110.0747, -7.8693],
      [110.0748, -7.8697],
      [110.0748, -7.8703],
      [110.0747, -7.8714],
      [110.0744, -7.8718],
      [110.0738, -7.8722],
      [110.0736, -7.8724],
      [110.0734, -7.873],
      [110.0735, -7.8735],
      [110.0735, -7.8737],
      [110.0734, -7.874],
      [110.0733, -7.8743],
      [110.0734, -7.8753],
      [110.0735, -7.8763],
      [110.0735, -7.877],
      [110.0733, -7.8781],
      [110.0731, -7.8794],
      [110.0726, -7.8824],
      [110.0721, -7.8852],
      [110.0719, -7.8867],
      [110.0719, -7.8873],
      [110.0722, -7.8877],
      [110.0723, -7.8884],
      [110.072, -7.8895],
      [110.0718, -7.8903],
      [110.0718, -7.8911],
      [110.0717, -7.8914],
      [110.0715, -7.8919],
      [110.0715, -7.8927],
      [110.0714, -7.8936],
      [110.0719, -7.8942],
      [110.0724, -7.8945],
      [110.0728, -7.8951],
      [110.0733, -7.8959],
      [110.0736, -7.8962],
      [110.0747, -7.8966],
      [110.0756, -7.8968],
      [110.0759, -7.8967],
      [110.0762, -7.8965],
      [110.0765, -7.8965],
      [110.0769, -7.8964],
      [110.0771, -7.8964],
      [110.0776, -7.8963],
      [110.0777, -7.8963],
      [110.0775, -7.8961],
      [110.0772, -7.896],
      [110.077, -7.8958],
      [110.0771, -7.8955],
      [110.0768, -7.8956],
      [110.0764, -7.8956],
      [110.0763, -7.8955],
      [110.076, -7.8953],
      [110.0759, -7.8951],
      [110.0759, -7.8948],
      [110.0759, -7.8944],
      [110.0759, -7.8942],
      [110.0761, -7.8938],
      [110.0763, -7.8933],
      [110.0764, -7.8928],
      [110.0766, -7.8923],
      [110.0769, -7.8921],
      [110.0773, -7.8921],
      [110.0774, -7.8921],
      [110.0784, -7.8922],
      [110.0789, -7.8922],
      [110.0797, -7.8922],
      [110.0807, -7.8925],
      [110.0808, -7.8918],
      [110.0813, -7.8917],
      [110.0822, -7.8918],
      [110.0823, -7.891],
      [110.0824, -7.8904],
      [110.0835, -7.8894],
      [110.0836, -7.889],
      [110.0829, -7.8886],
      [110.0823, -7.8882],
      [110.0812, -7.8876],
      [110.0804, -7.8872],
      [110.0799, -7.8871],
      [110.0794, -7.8868],
      [110.079, -7.8866],
      [110.0784, -7.8865],
      [110.078, -7.8863],
      [110.0776, -7.8859],
      [110.0776, -7.8854],
      [110.0774, -7.8851],
      [110.0773, -7.8843],
      [110.0772, -7.8839],
      [110.0774, -7.8828],
      [110.0774, -7.8821],
      [110.0767, -7.8821],
      [110.0769, -7.881],
      [110.0775, -7.8811],
      [110.0776, -7.8808],
      [110.0781, -7.8798],
      [110.0782, -7.8792],
      [110.0777, -7.8787],
      [110.077, -7.8778],
      [110.0765, -7.8773],
      [110.0764, -7.8769],
      [110.0763, -7.8767],
      [110.076, -7.8764],
      [110.0755, -7.8762],
      [110.0753, -7.8759],
      [110.0751, -7.8757],
      [110.0753, -7.875],
      [110.0753, -7.8744],
      [110.0754, -7.8742],
      [110.0755, -7.8741],
      [110.0757, -7.8732],
      [110.0757, -7.8727],
      [110.0759, -7.8724],
      [110.0764, -7.8722],
      [110.077, -7.8721],
      [110.0774, -7.8716],
      [110.0776, -7.871],
      [110.0777, -7.8701],
      [110.0779, -7.869]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'KEBONREJO',
    border: [
      [110.0714, -7.8936],
      [110.0715, -7.8927],
      [110.0715, -7.8919],
      [110.0717, -7.8914],
      [110.0718, -7.8911],
      [110.0718, -7.8903],
      [110.072, -7.8895],
      [110.0723, -7.8884],
      [110.0722, -7.8877],
      [110.0719, -7.8873],
      [110.0719, -7.8867],
      [110.0721, -7.8852],
      [110.0726, -7.8824],
      [110.0731, -7.8794],
      [110.0733, -7.8781],
      [110.0735, -7.877],
      [110.0735, -7.8763],
      [110.0734, -7.8753],
      [110.0733, -7.8743],
      [110.0734, -7.874],
      [110.0735, -7.8737],
      [110.0735, -7.8735],
      [110.0734, -7.873],
      [110.0736, -7.8724],
      [110.0738, -7.8722],
      [110.0744, -7.8718],
      [110.0747, -7.8714],
      [110.0748, -7.8703],
      [110.0748, -7.8697],
      [110.0747, -7.8693],
      [110.0746, -7.8694],
      [110.0742, -7.8698],
      [110.0732, -7.8708],
      [110.0723, -7.8716],
      [110.0721, -7.8717],
      [110.072, -7.8726],
      [110.0719, -7.8734],
      [110.0718, -7.8737],
      [110.0718, -7.8745],
      [110.0718, -7.875],
      [110.0715, -7.8751],
      [110.071, -7.8753],
      [110.0705, -7.8757],
      [110.0702, -7.8766],
      [110.0694, -7.8759],
      [110.0689, -7.8756],
      [110.0687, -7.8755],
      [110.0685, -7.8759],
      [110.0684, -7.8769],
      [110.0684, -7.8771],
      [110.0683, -7.8772],
      [110.068, -7.8782],
      [110.0675, -7.8793],
      [110.069, -7.8803],
      [110.0681, -7.8812],
      [110.0678, -7.8814],
      [110.0674, -7.8816],
      [110.0663, -7.8822],
      [110.0658, -7.8829],
      [110.0653, -7.8837],
      [110.0652, -7.8839],
      [110.0648, -7.8849],
      [110.0636, -7.8877],
      [110.0629, -7.8892],
      [110.0618, -7.8913],
      [110.0618, -7.8916],
      [110.0622, -7.8916],
      [110.0635, -7.8917],
      [110.0641, -7.8918],
      [110.0645, -7.8918],
      [110.0654, -7.8921],
      [110.0656, -7.8922],
      [110.0678, -7.8927],
      [110.0705, -7.8934],
      [110.0714, -7.8936]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'JANTEN',
    border: [
      [110.0721, -7.8717],
      [110.0702, -7.8729],
      [110.0701, -7.873],
      [110.0685, -7.8738],
      [110.0674, -7.8741],
      [110.067, -7.874],
      [110.0669, -7.8739],
      [110.0668, -7.8738],
      [110.0666, -7.8735],
      [110.0668, -7.8726],
      [110.0668, -7.8723],
      [110.0668, -7.872],
      [110.0668, -7.8715],
      [110.067, -7.8705],
      [110.067, -7.8701],
      [110.0669, -7.8701],
      [110.066, -7.87],
      [110.0647, -7.8696],
      [110.0634, -7.8693],
      [110.0621, -7.8689],
      [110.0601, -7.8684],
      [110.0599, -7.8692],
      [110.0601, -7.8696],
      [110.0603, -7.8701],
      [110.0602, -7.8708],
      [110.0607, -7.8711],
      [110.0605, -7.872],
      [110.0598, -7.8733],
      [110.0595, -7.8746],
      [110.0595, -7.8751],
      [110.0595, -7.8754],
      [110.0595, -7.8757],
      [110.06, -7.8761],
      [110.0602, -7.8762],
      [110.0603, -7.8764],
      [110.0605, -7.8773],
      [110.0606, -7.8778],
      [110.0601, -7.8785],
      [110.0595, -7.88],
      [110.0591, -7.881],
      [110.0588, -7.8816],
      [110.0586, -7.8821],
      [110.0594, -7.8824],
      [110.0616, -7.8833],
      [110.0643, -7.8846],
      [110.0648, -7.8849],
      [110.0652, -7.8839],
      [110.0653, -7.8837],
      [110.0658, -7.8829],
      [110.0663, -7.8822],
      [110.0674, -7.8816],
      [110.0678, -7.8814],
      [110.0681, -7.8812],
      [110.069, -7.8803],
      [110.0675, -7.8793],
      [110.068, -7.8782],
      [110.0683, -7.8772],
      [110.0684, -7.8771],
      [110.0684, -7.8769],
      [110.0685, -7.8759],
      [110.0687, -7.8755],
      [110.0689, -7.8756],
      [110.0694, -7.8759],
      [110.0702, -7.8766],
      [110.0705, -7.8757],
      [110.071, -7.8753],
      [110.0715, -7.8751],
      [110.0718, -7.875],
      [110.0718, -7.8745],
      [110.0718, -7.8737],
      [110.0719, -7.8734],
      [110.072, -7.8726],
      [110.0721, -7.8717]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'TEMON',
    village: 'KARANGWULUH',
    border: [
      [110.0586, -7.8821],
      [110.0588, -7.8816],
      [110.0591, -7.881],
      [110.0595, -7.88],
      [110.0601, -7.8785],
      [110.0606, -7.8778],
      [110.0605, -7.8773],
      [110.0603, -7.8764],
      [110.0602, -7.8762],
      [110.06, -7.8761],
      [110.0595, -7.8757],
      [110.0595, -7.8754],
      [110.0595, -7.8751],
      [110.0595, -7.8746],
      [110.0598, -7.8733],
      [110.0605, -7.872],
      [110.0607, -7.8711],
      [110.0602, -7.8708],
      [110.0603, -7.8701],
      [110.0601, -7.8696],
      [110.0599, -7.8692],
      [110.0601, -7.8684],
      [110.0591, -7.8685],
      [110.0567, -7.8688],
      [110.0544, -7.8689],
      [110.0526, -7.8687],
      [110.0512, -7.8687],
      [110.0482, -7.8688],
      [110.0475, -7.8687],
      [110.0475, -7.869],
      [110.0477, -7.869],
      [110.0481, -7.8693],
      [110.0485, -7.8695],
      [110.0482, -7.8702],
      [110.048, -7.8705],
      [110.0476, -7.8708],
      [110.0474, -7.8716],
      [110.0475, -7.8722],
      [110.0475, -7.8724],
      [110.0471, -7.8723],
      [110.047, -7.8724],
      [110.0465, -7.8724],
      [110.0463, -7.8733],
      [110.0467, -7.8738],
      [110.0466, -7.875],
      [110.0475, -7.8756],
      [110.0478, -7.8757],
      [110.0488, -7.8761],
      [110.0493, -7.8759],
      [110.0499, -7.8759],
      [110.0502, -7.8759],
      [110.0509, -7.876],
      [110.051, -7.876],
      [110.0512, -7.8759],
      [110.0514, -7.876],
      [110.0516, -7.876],
      [110.0517, -7.8761],
      [110.0528, -7.8765],
      [110.0531, -7.8765],
      [110.0537, -7.8765],
      [110.0539, -7.8766],
      [110.0532, -7.8778],
      [110.053, -7.8782],
      [110.0528, -7.8792],
      [110.0526, -7.8799],
      [110.0526, -7.8801],
      [110.0539, -7.8804],
      [110.0546, -7.8806],
      [110.0552, -7.8807],
      [110.0568, -7.8816],
      [110.0581, -7.8819],
      [110.0585, -7.882],
      [110.0586, -7.8821]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'WATES',
    border: [
      [110.0954, -7.925],
      [110.0955, -7.925],
      [110.0957, -7.9251],
      [110.0959, -7.9252],
      [110.0964, -7.9254],
      [110.0972, -7.9257],
      [110.0984, -7.9263],
      [110.0993, -7.9267],
      [110.1003, -7.9271],
      [110.1014, -7.9276],
      [110.1023, -7.928],
      [110.1033, -7.9285],
      [110.1035, -7.9286],
      [110.1043, -7.9288],
      [110.1052, -7.9293],
      [110.1056, -7.9294],
      [110.1061, -7.9284],
      [110.1075, -7.925],
      [110.1098, -7.9188],
      [110.114, -7.9205],
      [110.1157, -7.9166],
      [110.1158, -7.9164],
      [110.1168, -7.9141],
      [110.1163, -7.9142],
      [110.1166, -7.9119],
      [110.1171, -7.9106],
      [110.1171, -7.9105],
      [110.1164, -7.9104],
      [110.1152, -7.9105],
      [110.1143, -7.9104],
      [110.1135, -7.9104],
      [110.1128, -7.9104],
      [110.1122, -7.9104],
      [110.1111, -7.9102],
      [110.1099, -7.9101],
      [110.1086, -7.9101],
      [110.1076, -7.9101],
      [110.1069, -7.9102],
      [110.1059, -7.9104],
      [110.1056, -7.9104],
      [110.1045, -7.9104],
      [110.103, -7.9147],
      [110.1002, -7.9187],
      [110.0977, -7.9186],
      [110.0954, -7.925]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'KARANGWUNI',
    border: [
      [110.1045, -7.9104],
      [110.1038, -7.9105],
      [110.1031, -7.9106],
      [110.1026, -7.9106],
      [110.1017, -7.9105],
      [110.1006, -7.9106],
      [110.1, -7.9106],
      [110.0996, -7.9105],
      [110.0985, -7.9101],
      [110.0979, -7.9097],
      [110.0973, -7.9095],
      [110.0969, -7.9093],
      [110.0966, -7.9084],
      [110.0963, -7.9078],
      [110.0956, -7.9068],
      [110.095, -7.9059],
      [110.0946, -7.9055],
      [110.0941, -7.9055],
      [110.0934, -7.9054],
      [110.093, -7.9051],
      [110.0926, -7.9044],
      [110.0921, -7.9035],
      [110.0915, -7.903],
      [110.0908, -7.9026],
      [110.0903, -7.9025],
      [110.0902, -7.9025],
      [110.0898, -7.9024],
      [110.0893, -7.9022],
      [110.089, -7.9022],
      [110.0882, -7.9023],
      [110.088, -7.9022],
      [110.0871, -7.9019],
      [110.086, -7.9044],
      [110.085, -7.9066],
      [110.0848, -7.9073],
      [110.0846, -7.9079],
      [110.0835, -7.909],
      [110.0829, -7.9105],
      [110.0823, -7.9119],
      [110.0822, -7.9127],
      [110.0818, -7.9144],
      [110.0814, -7.9156],
      [110.0811, -7.9161],
      [110.0808, -7.9165],
      [110.0802, -7.9167],
      [110.0796, -7.9169],
      [110.0789, -7.9169],
      [110.0785, -7.9168],
      [110.0782, -7.9164],
      [110.0779, -7.916],
      [110.0775, -7.9159],
      [110.077, -7.916],
      [110.0767, -7.9162],
      [110.0765, -7.9165],
      [110.0764, -7.9169],
      [110.0778, -7.9176],
      [110.0784, -7.9179],
      [110.0787, -7.9181],
      [110.0794, -7.9183],
      [110.0806, -7.9188],
      [110.0814, -7.9191],
      [110.0824, -7.9195],
      [110.0831, -7.9198],
      [110.0833, -7.9199],
      [110.0839, -7.92],
      [110.085, -7.9206],
      [110.0857, -7.9209],
      [110.0863, -7.9212],
      [110.0869, -7.9214],
      [110.0876, -7.9217],
      [110.0884, -7.922],
      [110.0891, -7.9223],
      [110.0897, -7.9226],
      [110.0904, -7.9229],
      [110.0907, -7.923],
      [110.091, -7.9231],
      [110.0911, -7.9232],
      [110.0916, -7.9234],
      [110.092, -7.9235],
      [110.0927, -7.9239],
      [110.0933, -7.9242],
      [110.0938, -7.9244],
      [110.0942, -7.9245],
      [110.0943, -7.9246],
      [110.0951, -7.9249],
      [110.0954, -7.925],
      [110.0977, -7.9186],
      [110.1002, -7.9187],
      [110.103, -7.9147],
      [110.1045, -7.9104]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'SOGAN',
    border: [
      [110.1193, -7.8882],
      [110.1185, -7.8882],
      [110.1176, -7.8879],
      [110.1171, -7.8877],
      [110.1165, -7.887],
      [110.1161, -7.8855],
      [110.114, -7.8856],
      [110.1141, -7.8864],
      [110.1142, -7.8878],
      [110.111, -7.888],
      [110.1108, -7.888],
      [110.1108, -7.8884],
      [110.1105, -7.8906],
      [110.1102, -7.8928],
      [110.1101, -7.8946],
      [110.1099, -7.8961],
      [110.1097, -7.8978],
      [110.1097, -7.8983],
      [110.1096, -7.8991],
      [110.1095, -7.9003],
      [110.1093, -7.9011],
      [110.1092, -7.9018],
      [110.1091, -7.9021],
      [110.109, -7.9029],
      [110.1088, -7.9033],
      [110.1086, -7.9041],
      [110.1085, -7.9051],
      [110.1081, -7.906],
      [110.1074, -7.9068],
      [110.1054, -7.9076],
      [110.1042, -7.908],
      [110.1024, -7.9079],
      [110.1013, -7.908],
      [110.1003, -7.9085],
      [110.0992, -7.909],
      [110.0982, -7.9096],
      [110.0979, -7.9097],
      [110.0985, -7.9101],
      [110.0996, -7.9105],
      [110.1, -7.9106],
      [110.1006, -7.9106],
      [110.1017, -7.9105],
      [110.1026, -7.9106],
      [110.1031, -7.9106],
      [110.1038, -7.9105],
      [110.1045, -7.9104],
      [110.1056, -7.9104],
      [110.1059, -7.9104],
      [110.1069, -7.9102],
      [110.1076, -7.9101],
      [110.1086, -7.9101],
      [110.1099, -7.9101],
      [110.1111, -7.9102],
      [110.1122, -7.9104],
      [110.1128, -7.9104],
      [110.1135, -7.9104],
      [110.1143, -7.9104],
      [110.1143, -7.9102],
      [110.1146, -7.9094],
      [110.1151, -7.9082],
      [110.1154, -7.9067],
      [110.116, -7.9046],
      [110.1162, -7.9041],
      [110.1164, -7.9031],
      [110.1183, -7.9031],
      [110.1184, -7.903],
      [110.1184, -7.9027],
      [110.1185, -7.9023],
      [110.1186, -7.9018],
      [110.1186, -7.9011],
      [110.1184, -7.9001],
      [110.1181, -7.8989],
      [110.1181, -7.8982],
      [110.1178, -7.8979],
      [110.1178, -7.8975],
      [110.1178, -7.8967],
      [110.1182, -7.8951],
      [110.1188, -7.891],
      [110.1188, -7.8909],
      [110.1186, -7.8907],
      [110.1189, -7.8897],
      [110.1193, -7.8882]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'KULWARU',
    border: [
      [110.1296, -7.8964],
      [110.1291, -7.8963],
      [110.1286, -7.8963],
      [110.1281, -7.8963],
      [110.1278, -7.8961],
      [110.1261, -7.8959],
      [110.1262, -7.8956],
      [110.1266, -7.8945],
      [110.1266, -7.8939],
      [110.1254, -7.8937],
      [110.1245, -7.8938],
      [110.1236, -7.8937],
      [110.1238, -7.8917],
      [110.1212, -7.8914],
      [110.1188, -7.891],
      [110.1182, -7.8951],
      [110.1178, -7.8967],
      [110.1178, -7.8975],
      [110.1178, -7.8979],
      [110.1181, -7.8982],
      [110.1181, -7.8989],
      [110.1184, -7.9001],
      [110.1186, -7.9011],
      [110.1186, -7.9018],
      [110.1185, -7.9023],
      [110.1184, -7.9027],
      [110.1184, -7.903],
      [110.1183, -7.9031],
      [110.1164, -7.9031],
      [110.1162, -7.9041],
      [110.116, -7.9046],
      [110.1154, -7.9067],
      [110.1151, -7.9082],
      [110.1146, -7.9094],
      [110.1143, -7.9102],
      [110.1143, -7.9104],
      [110.1152, -7.9105],
      [110.1164, -7.9104],
      [110.1171, -7.9105],
      [110.1192, -7.9107],
      [110.1213, -7.9108],
      [110.1236, -7.9108],
      [110.1261, -7.9109],
      [110.1285, -7.9106],
      [110.1287, -7.9094],
      [110.1289, -7.9071],
      [110.1292, -7.9051],
      [110.1283, -7.905],
      [110.1285, -7.9036],
      [110.1289, -7.9014],
      [110.1296, -7.8964]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'NGESTIHARJO',
    border: [
      [110.141, -7.8934],
      [110.1407, -7.8934],
      [110.1389, -7.8934],
      [110.1359, -7.8931],
      [110.1351, -7.893],
      [110.1303, -7.8924],
      [110.13, -7.8939],
      [110.1296, -7.8964],
      [110.1289, -7.9014],
      [110.1285, -7.9036],
      [110.1283, -7.905],
      [110.1292, -7.9051],
      [110.1289, -7.9071],
      [110.1287, -7.9094],
      [110.1285, -7.9106],
      [110.1298, -7.9109],
      [110.1317, -7.9106],
      [110.1336, -7.9102],
      [110.1352, -7.9097],
      [110.1362, -7.9093],
      [110.138, -7.9088],
      [110.1388, -7.9082],
      [110.1388, -7.9075],
      [110.1391, -7.9072],
      [110.1395, -7.9068],
      [110.14, -7.9067],
      [110.1405, -7.9065],
      [110.1407, -7.9063],
      [110.1407, -7.9054],
      [110.1406, -7.9044],
      [110.1408, -7.9041],
      [110.141, -7.9039],
      [110.1414, -7.9036],
      [110.1417, -7.9032],
      [110.1419, -7.903],
      [110.1421, -7.9024],
      [110.1424, -7.9016],
      [110.1423, -7.9008],
      [110.142, -7.8996],
      [110.142, -7.8991],
      [110.1422, -7.8977],
      [110.1419, -7.8964],
      [110.1414, -7.8949],
      [110.1412, -7.8944],
      [110.1411, -7.8943],
      [110.1409, -7.8936],
      [110.141, -7.8934]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'BENDUNGAN',
    border: [
      [110.1622, -7.8575],
      [110.1628, -7.8556],
      [110.1631, -7.8556],
      [110.1633, -7.8548],
      [110.1636, -7.8536],
      [110.1636, -7.8532],
      [110.1624, -7.8531],
      [110.1615, -7.8531],
      [110.1606, -7.8529],
      [110.1594, -7.8525],
      [110.1586, -7.8524],
      [110.1584, -7.8522],
      [110.1578, -7.852],
      [110.157, -7.8518],
      [110.1554, -7.8516],
      [110.1552, -7.8521],
      [110.1548, -7.8529],
      [110.1545, -7.8532],
      [110.1542, -7.8531],
      [110.1537, -7.8531],
      [110.1533, -7.8531],
      [110.152, -7.853],
      [110.1509, -7.8532],
      [110.1508, -7.8531],
      [110.1497, -7.8524],
      [110.1494, -7.8518],
      [110.1491, -7.851],
      [110.1492, -7.85],
      [110.1494, -7.8489],
      [110.1492, -7.8483],
      [110.149, -7.8481],
      [110.1486, -7.8479],
      [110.1481, -7.8476],
      [110.1479, -7.847],
      [110.1477, -7.8466],
      [110.1473, -7.8464],
      [110.1466, -7.8461],
      [110.1461, -7.8459],
      [110.1458, -7.8455],
      [110.1455, -7.8451],
      [110.1453, -7.8446],
      [110.145, -7.8445],
      [110.1449, -7.8438],
      [110.1447, -7.8436],
      [110.1444, -7.8433],
      [110.1436, -7.8426],
      [110.1429, -7.8424],
      [110.1427, -7.842],
      [110.1426, -7.8426],
      [110.1425, -7.8431],
      [110.1428, -7.8434],
      [110.1428, -7.8439],
      [110.1426, -7.8442],
      [110.1423, -7.8446],
      [110.1423, -7.8449],
      [110.1423, -7.8456],
      [110.1421, -7.8461],
      [110.1419, -7.8464],
      [110.1419, -7.8469],
      [110.1423, -7.8471],
      [110.1427, -7.8473],
      [110.1429, -7.8478],
      [110.1429, -7.8484],
      [110.1431, -7.8492],
      [110.1436, -7.85],
      [110.1444, -7.8508],
      [110.1449, -7.8516],
      [110.1451, -7.8525],
      [110.1454, -7.8532],
      [110.146, -7.8538],
      [110.1468, -7.8545],
      [110.1469, -7.8548],
      [110.1473, -7.8547],
      [110.1479, -7.8556],
      [110.1484, -7.8561],
      [110.1484, -7.8566],
      [110.148, -7.8574],
      [110.1469, -7.8587],
      [110.1457, -7.8604],
      [110.145, -7.861],
      [110.1449, -7.8613],
      [110.1448, -7.8619],
      [110.145, -7.862],
      [110.1451, -7.8619],
      [110.1455, -7.862],
      [110.1453, -7.8631],
      [110.1444, -7.8632],
      [110.1443, -7.8636],
      [110.1433, -7.864],
      [110.1413, -7.8645],
      [110.1414, -7.8651],
      [110.1412, -7.8653],
      [110.1414, -7.8663],
      [110.1412, -7.8664],
      [110.1418, -7.8673],
      [110.142, -7.8678],
      [110.1414, -7.8679],
      [110.1407, -7.8682],
      [110.1406, -7.8683],
      [110.1406, -7.8684],
      [110.1418, -7.8697],
      [110.1424, -7.8704],
      [110.1451, -7.8696],
      [110.1465, -7.8692],
      [110.1463, -7.8704],
      [110.146, -7.8715],
      [110.1464, -7.8716],
      [110.147, -7.8718],
      [110.1473, -7.8719],
      [110.1479, -7.8721],
      [110.1481, -7.8727],
      [110.1483, -7.8729],
      [110.1485, -7.8732],
      [110.1487, -7.8734],
      [110.1489, -7.8735],
      [110.1494, -7.8738],
      [110.1499, -7.8738],
      [110.1503, -7.8735],
      [110.1503, -7.8732],
      [110.1503, -7.8728],
      [110.1501, -7.8725],
      [110.1495, -7.8723],
      [110.1489, -7.872],
      [110.1486, -7.8716],
      [110.1486, -7.8711],
      [110.1487, -7.871],
      [110.1491, -7.8708],
      [110.1498, -7.8704],
      [110.1508, -7.8702],
      [110.1517, -7.87],
      [110.1524, -7.8701],
      [110.1531, -7.8702],
      [110.1535, -7.8697],
      [110.1536, -7.8692],
      [110.1535, -7.8688],
      [110.1531, -7.868],
      [110.1528, -7.8675],
      [110.1528, -7.8669],
      [110.1531, -7.8666],
      [110.1536, -7.8665],
      [110.1545, -7.8667],
      [110.1558, -7.867],
      [110.156, -7.8674],
      [110.1565, -7.8678],
      [110.157, -7.8681],
      [110.1575, -7.8682],
      [110.158, -7.8681],
      [110.1591, -7.868],
      [110.1607, -7.8683],
      [110.1622, -7.8684],
      [110.1627, -7.8679],
      [110.163, -7.8666],
      [110.1637, -7.8653],
      [110.1641, -7.8645],
      [110.1646, -7.8643],
      [110.1655, -7.8646],
      [110.1661, -7.8647],
      [110.1666, -7.8645],
      [110.1667, -7.8642],
      [110.1663, -7.8626],
      [110.166, -7.8624],
      [110.1657, -7.8623],
      [110.1655, -7.8623],
      [110.1645, -7.8623],
      [110.1636, -7.8623],
      [110.1633, -7.8621],
      [110.1631, -7.8615],
      [110.1625, -7.8609],
      [110.1625, -7.8605],
      [110.1625, -7.8597],
      [110.1627, -7.8589],
      [110.1628, -7.8581],
      [110.1627, -7.8577],
      [110.1624, -7.8576],
      [110.1622, -7.8575]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'TRIHARJO',
    border: [
      [110.1473, -7.8719],
      [110.147, -7.8718],
      [110.1464, -7.8716],
      [110.146, -7.8715],
      [110.1463, -7.8704],
      [110.1465, -7.8692],
      [110.1451, -7.8696],
      [110.1424, -7.8704],
      [110.1418, -7.8697],
      [110.1406, -7.8684],
      [110.1406, -7.8683],
      [110.14, -7.8686],
      [110.1393, -7.8691],
      [110.1388, -7.8694],
      [110.1387, -7.8701],
      [110.1384, -7.8704],
      [110.1379, -7.8704],
      [110.1375, -7.8703],
      [110.137, -7.8704],
      [110.1364, -7.8707],
      [110.136, -7.8708],
      [110.1357, -7.8706],
      [110.1353, -7.8706],
      [110.1351, -7.8708],
      [110.1345, -7.8706],
      [110.1341, -7.8708],
      [110.1338, -7.8711],
      [110.1334, -7.8713],
      [110.1331, -7.8713],
      [110.1328, -7.8713],
      [110.1327, -7.8717],
      [110.132, -7.8715],
      [110.1317, -7.872],
      [110.1315, -7.8725],
      [110.1311, -7.8729],
      [110.1305, -7.873],
      [110.1304, -7.8732],
      [110.1304, -7.8733],
      [110.1303, -7.8738],
      [110.13, -7.8743],
      [110.1294, -7.8746],
      [110.1295, -7.875],
      [110.1297, -7.8757],
      [110.13, -7.8776],
      [110.1303, -7.8787],
      [110.1304, -7.8797],
      [110.1304, -7.8806],
      [110.1303, -7.8812],
      [110.1304, -7.8815],
      [110.1313, -7.8818],
      [110.1315, -7.8823],
      [110.1313, -7.8829],
      [110.1309, -7.8833],
      [110.1305, -7.8838],
      [110.1302, -7.884],
      [110.1297, -7.8843],
      [110.129, -7.8845],
      [110.1287, -7.8849],
      [110.1282, -7.8849],
      [110.1281, -7.8857],
      [110.128, -7.8861],
      [110.1275, -7.8875],
      [110.1266, -7.8875],
      [110.1254, -7.8874],
      [110.1249, -7.8872],
      [110.124, -7.887],
      [110.1228, -7.887],
      [110.1226, -7.8875],
      [110.1225, -7.8886],
      [110.1216, -7.8884],
      [110.1208, -7.8884],
      [110.1195, -7.8882],
      [110.1194, -7.8882],
      [110.1193, -7.8882],
      [110.1189, -7.8897],
      [110.1186, -7.8907],
      [110.1188, -7.8909],
      [110.1188, -7.891],
      [110.1212, -7.8914],
      [110.1238, -7.8917],
      [110.1236, -7.8937],
      [110.1245, -7.8938],
      [110.1254, -7.8937],
      [110.1266, -7.8939],
      [110.1266, -7.8945],
      [110.1262, -7.8956],
      [110.1261, -7.8959],
      [110.1278, -7.8961],
      [110.1281, -7.8963],
      [110.1286, -7.8963],
      [110.1291, -7.8963],
      [110.1296, -7.8964],
      [110.13, -7.8939],
      [110.1303, -7.8924],
      [110.1351, -7.893],
      [110.1359, -7.8931],
      [110.1389, -7.8934],
      [110.1407, -7.8934],
      [110.141, -7.8934],
      [110.1412, -7.8929],
      [110.1415, -7.8924],
      [110.1412, -7.8906],
      [110.1414, -7.8899],
      [110.1423, -7.8876],
      [110.1431, -7.8859],
      [110.1431, -7.8844],
      [110.1434, -7.8837],
      [110.1436, -7.8833],
      [110.1437, -7.8831],
      [110.144, -7.8827],
      [110.1441, -7.8818],
      [110.1439, -7.8806],
      [110.1438, -7.8799],
      [110.1441, -7.8792],
      [110.1445, -7.8788],
      [110.1447, -7.8784],
      [110.1448, -7.8778],
      [110.1448, -7.8774],
      [110.1443, -7.8772],
      [110.1436, -7.877],
      [110.1427, -7.8766],
      [110.1421, -7.876],
      [110.1417, -7.8756],
      [110.1413, -7.8749],
      [110.1414, -7.8742],
      [110.1416, -7.8741],
      [110.1422, -7.8738],
      [110.1432, -7.8733],
      [110.1441, -7.8729],
      [110.1447, -7.873],
      [110.145, -7.8733],
      [110.1453, -7.8738],
      [110.1456, -7.8744],
      [110.146, -7.8748],
      [110.1463, -7.8747],
      [110.1463, -7.8744],
      [110.1463, -7.8741],
      [110.1462, -7.8739],
      [110.1462, -7.8731],
      [110.1462, -7.8724],
      [110.1466, -7.872],
      [110.1473, -7.8719]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'WATES',
    village: 'GIRIPENI',
    border: [
      [110.1537, -7.8823],
      [110.1537, -7.8812],
      [110.1536, -7.8812],
      [110.1534, -7.8812],
      [110.1519, -7.8811],
      [110.1502, -7.881],
      [110.1492, -7.8809],
      [110.1493, -7.8801],
      [110.1498, -7.8799],
      [110.1498, -7.8794],
      [110.15, -7.8786],
      [110.1501, -7.878],
      [110.1502, -7.8773],
      [110.1473, -7.8762],
      [110.1458, -7.8755],
      [110.1451, -7.8754],
      [110.1446, -7.8754],
      [110.1439, -7.8753],
      [110.143, -7.8755],
      [110.1424, -7.8758],
      [110.1421, -7.876],
      [110.1427, -7.8766],
      [110.1436, -7.877],
      [110.1443, -7.8772],
      [110.1448, -7.8774],
      [110.1448, -7.8778],
      [110.1447, -7.8784],
      [110.1445, -7.8788],
      [110.1441, -7.8792],
      [110.1438, -7.8799],
      [110.1439, -7.8806],
      [110.1441, -7.8818],
      [110.144, -7.8827],
      [110.1437, -7.8831],
      [110.1436, -7.8833],
      [110.1434, -7.8837],
      [110.1431, -7.8844],
      [110.1431, -7.8859],
      [110.1423, -7.8876],
      [110.1414, -7.8899],
      [110.1412, -7.8906],
      [110.1415, -7.8924],
      [110.1412, -7.8929],
      [110.141, -7.8934],
      [110.1409, -7.8936],
      [110.1411, -7.8943],
      [110.1412, -7.8944],
      [110.1414, -7.8949],
      [110.1419, -7.8964],
      [110.1422, -7.8977],
      [110.142, -7.8991],
      [110.142, -7.8996],
      [110.1423, -7.9008],
      [110.1424, -7.9016],
      [110.1421, -7.9024],
      [110.1419, -7.9031],
      [110.1421, -7.9033],
      [110.1424, -7.9033],
      [110.1432, -7.9032],
      [110.1435, -7.9031],
      [110.1444, -7.903],
      [110.1451, -7.903],
      [110.146, -7.9031],
      [110.1492, -7.9034],
      [110.1523, -7.9035],
      [110.1524, -7.9035],
      [110.1526, -7.9035],
      [110.1527, -7.9006],
      [110.1529, -7.8955],
      [110.1529, -7.895],
      [110.153, -7.8936],
      [110.1531, -7.8916],
      [110.1533, -7.8884],
      [110.1534, -7.8882],
      [110.1534, -7.8879],
      [110.1535, -7.8846],
      [110.1536, -7.8826],
      [110.1537, -7.8823]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'GARONGAN',
    border: [
      [110.1405, -7.9231],
      [110.1403, -7.9231],
      [110.14, -7.9229],
      [110.1389, -7.9224],
      [110.1386, -7.9226],
      [110.138, -7.9224],
      [110.1378, -7.9223],
      [110.1378, -7.9222],
      [110.138, -7.9215],
      [110.1381, -7.9209],
      [110.1375, -7.9205],
      [110.1372, -7.92],
      [110.1372, -7.9196],
      [110.1375, -7.9186],
      [110.1378, -7.9186],
      [110.1384, -7.9187],
      [110.1387, -7.919],
      [110.1386, -7.9193],
      [110.1386, -7.9198],
      [110.1397, -7.9196],
      [110.1399, -7.9183],
      [110.1398, -7.9175],
      [110.1398, -7.9164],
      [110.1395, -7.9161],
      [110.1396, -7.9158],
      [110.1403, -7.9151],
      [110.1404, -7.9146],
      [110.1402, -7.9142],
      [110.1377, -7.914],
      [110.1375, -7.9153],
      [110.1373, -7.9161],
      [110.1371, -7.9164],
      [110.1366, -7.9171],
      [110.1362, -7.9178],
      [110.1313, -7.9162],
      [110.1311, -7.917],
      [110.1303, -7.9202],
      [110.1269, -7.9197],
      [110.1259, -7.9192],
      [110.1257, -7.9191],
      [110.1254, -7.9189],
      [110.125, -7.9187],
      [110.1232, -7.918],
      [110.1231, -7.9182],
      [110.1231, -7.9183],
      [110.1229, -7.9185],
      [110.1186, -7.9172],
      [110.1183, -7.9176],
      [110.1164, -7.9168],
      [110.1157, -7.9166],
      [110.114, -7.9205],
      [110.1098, -7.9188],
      [110.1075, -7.925],
      [110.1061, -7.9284],
      [110.1056, -7.9294],
      [110.1062, -7.9297],
      [110.1072, -7.9302],
      [110.1081, -7.9305],
      [110.1091, -7.9309],
      [110.1094, -7.9311],
      [110.1103, -7.9315],
      [110.1111, -7.9318],
      [110.1123, -7.9324],
      [110.1132, -7.9328],
      [110.114, -7.9331],
      [110.1152, -7.9336],
      [110.1155, -7.9337],
      [110.1165, -7.9341],
      [110.1177, -7.9347],
      [110.119, -7.9353],
      [110.1195, -7.9355],
      [110.1201, -7.9357],
      [110.121, -7.9361],
      [110.1218, -7.9365],
      [110.1232, -7.9372],
      [110.1242, -7.9376],
      [110.1246, -7.9379],
      [110.1251, -7.9382],
      [110.1258, -7.9385],
      [110.1262, -7.9387],
      [110.1264, -7.9388],
      [110.127, -7.9392],
      [110.1272, -7.9394],
      [110.1277, -7.9396],
      [110.128, -7.9398],
      [110.1282, -7.9398],
      [110.1284, -7.9398],
      [110.1286, -7.9393],
      [110.1302, -7.9363],
      [110.1309, -7.9347],
      [110.1324, -7.9315],
      [110.133, -7.9299],
      [110.1333, -7.9285],
      [110.1358, -7.9294],
      [110.1392, -7.9313],
      [110.1421, -7.9326],
      [110.145, -7.9338],
      [110.1451, -7.9339],
      [110.1452, -7.9337],
      [110.1458, -7.9316],
      [110.1463, -7.9296],
      [110.1456, -7.9295],
      [110.1457, -7.9286],
      [110.1444, -7.9283],
      [110.1443, -7.9277],
      [110.1433, -7.9269],
      [110.143, -7.9265],
      [110.1422, -7.9264],
      [110.1416, -7.9266],
      [110.1413, -7.9265],
      [110.1412, -7.9264],
      [110.1412, -7.926],
      [110.1409, -7.9258],
      [110.1406, -7.9255],
      [110.1404, -7.9253],
      [110.1398, -7.9252],
      [110.1397, -7.9249],
      [110.1403, -7.9236],
      [110.1405, -7.9231]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'PLERET',
    border: [
      [110.1475, -7.9198],
      [110.1473, -7.9198],
      [110.1468, -7.9197],
      [110.1449, -7.919],
      [110.1448, -7.9202],
      [110.1447, -7.9211],
      [110.1458, -7.9213],
      [110.1456, -7.9221],
      [110.1455, -7.9224],
      [110.1461, -7.9224],
      [110.1465, -7.9226],
      [110.1461, -7.9234],
      [110.146, -7.9242],
      [110.1457, -7.9244],
      [110.1454, -7.9243],
      [110.145, -7.9243],
      [110.145, -7.9251],
      [110.1448, -7.9252],
      [110.1446, -7.9253],
      [110.1433, -7.9252],
      [110.1421, -7.925],
      [110.1417, -7.925],
      [110.1417, -7.9249],
      [110.1419, -7.9239],
      [110.1414, -7.9238],
      [110.141, -7.9236],
      [110.1409, -7.9235],
      [110.1408, -7.9235],
      [110.1407, -7.9234],
      [110.1406, -7.9232],
      [110.1405, -7.9231],
      [110.1403, -7.9236],
      [110.1397, -7.9249],
      [110.1398, -7.9252],
      [110.1404, -7.9253],
      [110.1406, -7.9255],
      [110.1409, -7.9258],
      [110.1412, -7.926],
      [110.1412, -7.9264],
      [110.1413, -7.9265],
      [110.1416, -7.9266],
      [110.1422, -7.9264],
      [110.143, -7.9265],
      [110.1433, -7.9269],
      [110.1443, -7.9277],
      [110.1444, -7.9283],
      [110.1457, -7.9286],
      [110.1456, -7.9295],
      [110.1463, -7.9296],
      [110.1458, -7.9316],
      [110.1452, -7.9337],
      [110.1451, -7.9339],
      [110.145, -7.9338],
      [110.1421, -7.9326],
      [110.1392, -7.9313],
      [110.1358, -7.9294],
      [110.1333, -7.9285],
      [110.133, -7.9299],
      [110.1324, -7.9315],
      [110.1309, -7.9347],
      [110.1302, -7.9363],
      [110.1286, -7.9393],
      [110.1284, -7.9398],
      [110.1286, -7.9398],
      [110.1288, -7.9398],
      [110.1292, -7.9401],
      [110.1294, -7.9403],
      [110.1295, -7.9404],
      [110.1297, -7.9405],
      [110.1298, -7.9405],
      [110.13, -7.9406],
      [110.1303, -7.9407],
      [110.1307, -7.941],
      [110.131, -7.9412],
      [110.1313, -7.9413],
      [110.1317, -7.9414],
      [110.132, -7.9415],
      [110.1323, -7.9415],
      [110.1325, -7.9416],
      [110.1326, -7.9418],
      [110.1329, -7.942],
      [110.133, -7.9421],
      [110.1336, -7.9423],
      [110.1341, -7.9426],
      [110.1343, -7.9428],
      [110.1347, -7.9431],
      [110.1348, -7.9432],
      [110.1349, -7.9431],
      [110.135, -7.943],
      [110.1355, -7.943],
      [110.1358, -7.9431],
      [110.1365, -7.9435],
      [110.1366, -7.9436],
      [110.1369, -7.9438],
      [110.1372, -7.9439],
      [110.1374, -7.944],
      [110.1377, -7.9441],
      [110.1381, -7.9444],
      [110.1386, -7.9445],
      [110.1391, -7.9447],
      [110.1401, -7.9452],
      [110.1408, -7.9455],
      [110.1413, -7.9458],
      [110.1416, -7.946],
      [110.1418, -7.946],
      [110.1427, -7.9465],
      [110.1435, -7.9469],
      [110.1442, -7.9472],
      [110.1451, -7.9477],
      [110.1453, -7.9478],
      [110.1454, -7.9479],
      [110.1456, -7.948],
      [110.146, -7.9481],
      [110.1461, -7.9482],
      [110.1463, -7.9482],
      [110.1467, -7.9484],
      [110.1474, -7.9488],
      [110.1482, -7.9494],
      [110.1484, -7.9495],
      [110.1495, -7.9474],
      [110.1505, -7.9447],
      [110.1522, -7.9408],
      [110.1539, -7.9375],
      [110.155, -7.9379],
      [110.158, -7.9391],
      [110.1601, -7.9398],
      [110.1606, -7.94],
      [110.1606, -7.9398],
      [110.1607, -7.9389],
      [110.1609, -7.9378],
      [110.1594, -7.9375],
      [110.1598, -7.9352],
      [110.159, -7.9349],
      [110.1574, -7.9346],
      [110.1578, -7.9319],
      [110.1579, -7.9314],
      [110.158, -7.9306],
      [110.158, -7.9293],
      [110.1582, -7.9282],
      [110.1581, -7.9281],
      [110.158, -7.9279],
      [110.1575, -7.9271],
      [110.1567, -7.9255],
      [110.1569, -7.9249],
      [110.1575, -7.9236],
      [110.1576, -7.9233],
      [110.1576, -7.922],
      [110.1575, -7.9205],
      [110.1574, -7.9204],
      [110.1572, -7.9204],
      [110.1566, -7.9201],
      [110.1552, -7.9199],
      [110.1547, -7.9219],
      [110.1547, -7.9222],
      [110.1553, -7.9227],
      [110.1562, -7.9229],
      [110.1562, -7.9232],
      [110.1558, -7.9231],
      [110.1538, -7.9226],
      [110.1528, -7.9223],
      [110.1527, -7.9218],
      [110.1516, -7.9216],
      [110.1505, -7.9213],
      [110.1503, -7.9211],
      [110.1499, -7.9209],
      [110.1499, -7.9201],
      [110.1497, -7.92],
      [110.1486, -7.9198],
      [110.148, -7.9197],
      [110.1475, -7.9198]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'BUGEL',
    border: [
      [110.1643, -7.922],
      [110.164, -7.9221],
      [110.1635, -7.9221],
      [110.1629, -7.9221],
      [110.1619, -7.922],
      [110.1617, -7.9223],
      [110.1615, -7.9225],
      [110.1612, -7.9228],
      [110.1611, -7.9243],
      [110.1606, -7.9242],
      [110.1599, -7.924],
      [110.1588, -7.924],
      [110.1579, -7.9238],
      [110.1575, -7.9236],
      [110.1569, -7.9249],
      [110.1567, -7.9255],
      [110.1575, -7.9271],
      [110.158, -7.9279],
      [110.1581, -7.9281],
      [110.1582, -7.9282],
      [110.158, -7.9293],
      [110.158, -7.9306],
      [110.1579, -7.9314],
      [110.1578, -7.9319],
      [110.1574, -7.9346],
      [110.159, -7.9349],
      [110.1598, -7.9352],
      [110.1594, -7.9375],
      [110.1609, -7.9378],
      [110.1607, -7.9389],
      [110.1606, -7.9398],
      [110.1606, -7.94],
      [110.1601, -7.9398],
      [110.158, -7.9391],
      [110.155, -7.9379],
      [110.1539, -7.9375],
      [110.1522, -7.9408],
      [110.1505, -7.9447],
      [110.1495, -7.9474],
      [110.1484, -7.9495],
      [110.1489, -7.9496],
      [110.1493, -7.9498],
      [110.1497, -7.95],
      [110.1501, -7.9503],
      [110.1503, -7.9504],
      [110.1506, -7.9506],
      [110.1508, -7.9506],
      [110.1511, -7.9508],
      [110.1517, -7.9512],
      [110.1524, -7.9515],
      [110.1527, -7.9516],
      [110.1528, -7.9517],
      [110.153, -7.9518],
      [110.1533, -7.9519],
      [110.1539, -7.9522],
      [110.1548, -7.9527],
      [110.1558, -7.9532],
      [110.1564, -7.9536],
      [110.1571, -7.9539],
      [110.1582, -7.9545],
      [110.1587, -7.9548],
      [110.159, -7.955],
      [110.1592, -7.9551],
      [110.1594, -7.9553],
      [110.1597, -7.9554],
      [110.1602, -7.9555],
      [110.161, -7.956],
      [110.162, -7.9566],
      [110.1631, -7.9572],
      [110.1646, -7.958],
      [110.1652, -7.9584],
      [110.1654, -7.9578],
      [110.1662, -7.9562],
      [110.1673, -7.9542],
      [110.1688, -7.9519],
      [110.1697, -7.9503],
      [110.1701, -7.9493],
      [110.1704, -7.9489],
      [110.1708, -7.9479],
      [110.1708, -7.9473],
      [110.1712, -7.9468],
      [110.1718, -7.9458],
      [110.1721, -7.9452],
      [110.1723, -7.9447],
      [110.1724, -7.9442],
      [110.173, -7.9429],
      [110.1742, -7.94],
      [110.1749, -7.9387],
      [110.175, -7.9384],
      [110.1751, -7.9381],
      [110.1754, -7.9372],
      [110.1748, -7.937],
      [110.174, -7.9365],
      [110.1737, -7.9365],
      [110.1735, -7.936],
      [110.1726, -7.9359],
      [110.1715, -7.936],
      [110.1707, -7.9361],
      [110.1706, -7.9351],
      [110.1706, -7.9338],
      [110.1706, -7.9336],
      [110.1704, -7.9316],
      [110.1703, -7.9302],
      [110.1711, -7.9301],
      [110.1719, -7.9299],
      [110.1718, -7.9284],
      [110.1719, -7.9283],
      [110.1723, -7.9284],
      [110.1725, -7.9283],
      [110.1727, -7.9281],
      [110.1726, -7.9275],
      [110.1728, -7.9273],
      [110.1733, -7.9273],
      [110.1735, -7.927],
      [110.1732, -7.9254],
      [110.1732, -7.9249],
      [110.1733, -7.9243],
      [110.1736, -7.9241],
      [110.1738, -7.9238],
      [110.1737, -7.9236],
      [110.1725, -7.9235],
      [110.1721, -7.9235],
      [110.1718, -7.9233],
      [110.1715, -7.923],
      [110.1699, -7.9228],
      [110.1679, -7.9225],
      [110.1658, -7.9222],
      [110.1643, -7.922]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'KANOMAN',
    border: [
      [110.1781, -7.9203],
      [110.1779, -7.9203],
      [110.1766, -7.9201],
      [110.1752, -7.92],
      [110.1753, -7.9192],
      [110.1758, -7.9192],
      [110.176, -7.919],
      [110.1763, -7.9175],
      [110.1765, -7.916],
      [110.1764, -7.9157],
      [110.1762, -7.9157],
      [110.176, -7.9156],
      [110.1747, -7.9154],
      [110.1749, -7.9141],
      [110.1743, -7.9139],
      [110.1743, -7.9133],
      [110.1736, -7.9129],
      [110.1737, -7.9116],
      [110.1724, -7.9114],
      [110.1692, -7.9108],
      [110.168, -7.9107],
      [110.1668, -7.9105],
      [110.1659, -7.9105],
      [110.1654, -7.9102],
      [110.1653, -7.9106],
      [110.1648, -7.9126],
      [110.1651, -7.9128],
      [110.165, -7.9138],
      [110.1647, -7.9161],
      [110.1645, -7.9183],
      [110.1644, -7.9186],
      [110.1644, -7.9193],
      [110.1645, -7.9198],
      [110.1645, -7.9202],
      [110.1643, -7.9214],
      [110.1643, -7.922],
      [110.1658, -7.9222],
      [110.1679, -7.9225],
      [110.1699, -7.9228],
      [110.1715, -7.923],
      [110.1718, -7.9233],
      [110.1721, -7.9235],
      [110.1725, -7.9235],
      [110.1737, -7.9236],
      [110.1738, -7.9238],
      [110.1736, -7.9241],
      [110.1733, -7.9243],
      [110.1732, -7.9249],
      [110.1732, -7.9254],
      [110.1735, -7.927],
      [110.1733, -7.9273],
      [110.1728, -7.9273],
      [110.1726, -7.9275],
      [110.1727, -7.9281],
      [110.1725, -7.9283],
      [110.1723, -7.9284],
      [110.1719, -7.9283],
      [110.1718, -7.9284],
      [110.1719, -7.9299],
      [110.1711, -7.9301],
      [110.1703, -7.9302],
      [110.1704, -7.9316],
      [110.1706, -7.9336],
      [110.1707, -7.9336],
      [110.1724, -7.9333],
      [110.1738, -7.9331],
      [110.1752, -7.9329],
      [110.1764, -7.9326],
      [110.1776, -7.9321],
      [110.1781, -7.9316],
      [110.1784, -7.9309],
      [110.1788, -7.9301],
      [110.1791, -7.9293],
      [110.1792, -7.9292],
      [110.1793, -7.929],
      [110.1794, -7.9287],
      [110.1793, -7.9284],
      [110.1791, -7.9272],
      [110.1786, -7.9247],
      [110.1782, -7.9227],
      [110.178, -7.9215],
      [110.1781, -7.9203]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'DEPOK',
    border: [
      [110.158, -7.9075],
      [110.1577, -7.9076],
      [110.1567, -7.9076],
      [110.1558, -7.9076],
      [110.1552, -7.9075],
      [110.1545, -7.9076],
      [110.1538, -7.9078],
      [110.153, -7.9081],
      [110.1524, -7.908],
      [110.1525, -7.9085],
      [110.1526, -7.9091],
      [110.1529, -7.9096],
      [110.1532, -7.9102],
      [110.153, -7.9103],
      [110.1526, -7.9103],
      [110.1517, -7.9106],
      [110.1508, -7.9106],
      [110.1501, -7.9104],
      [110.1494, -7.9104],
      [110.1488, -7.91],
      [110.1486, -7.91],
      [110.1479, -7.91],
      [110.1472, -7.9099],
      [110.1466, -7.9098],
      [110.1461, -7.9099],
      [110.1463, -7.9105],
      [110.1466, -7.9111],
      [110.1472, -7.9115],
      [110.1476, -7.9117],
      [110.148, -7.9124],
      [110.1481, -7.9129],
      [110.1479, -7.9138],
      [110.1479, -7.9146],
      [110.1481, -7.9154],
      [110.1481, -7.9162],
      [110.148, -7.9172],
      [110.1481, -7.9183],
      [110.1476, -7.919],
      [110.1475, -7.9194],
      [110.1475, -7.9198],
      [110.148, -7.9197],
      [110.1486, -7.9198],
      [110.1497, -7.92],
      [110.1499, -7.9201],
      [110.1499, -7.9209],
      [110.1503, -7.9211],
      [110.1505, -7.9213],
      [110.1516, -7.9216],
      [110.1527, -7.9218],
      [110.1528, -7.9223],
      [110.1538, -7.9226],
      [110.1558, -7.9231],
      [110.1562, -7.9232],
      [110.1562, -7.9229],
      [110.1553, -7.9227],
      [110.1547, -7.9222],
      [110.1547, -7.9219],
      [110.1552, -7.9199],
      [110.1566, -7.9201],
      [110.1572, -7.9204],
      [110.1574, -7.9204],
      [110.1575, -7.9205],
      [110.1576, -7.922],
      [110.1576, -7.9233],
      [110.1575, -7.9236],
      [110.1579, -7.9238],
      [110.1588, -7.924],
      [110.1599, -7.924],
      [110.1606, -7.9242],
      [110.1611, -7.9243],
      [110.1612, -7.9228],
      [110.1615, -7.9225],
      [110.1617, -7.9223],
      [110.1619, -7.922],
      [110.1629, -7.9221],
      [110.1635, -7.9221],
      [110.164, -7.9221],
      [110.1643, -7.922],
      [110.1643, -7.9214],
      [110.1645, -7.9202],
      [110.1645, -7.9198],
      [110.1644, -7.9193],
      [110.1644, -7.9186],
      [110.1645, -7.9183],
      [110.1647, -7.9161],
      [110.165, -7.9138],
      [110.1651, -7.9128],
      [110.1648, -7.9126],
      [110.1653, -7.9106],
      [110.1654, -7.9102],
      [110.165, -7.9105],
      [110.1644, -7.9108],
      [110.1624, -7.9108],
      [110.1606, -7.9106],
      [110.1595, -7.9105],
      [110.1582, -7.9104],
      [110.1578, -7.9104],
      [110.1578, -7.9095],
      [110.1578, -7.9089],
      [110.1579, -7.9082],
      [110.158, -7.9077],
      [110.158, -7.9075]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'BOJONG',
    border: [
      [110.1461, -7.9099],
      [110.1462, -7.9094],
      [110.1462, -7.9088],
      [110.1458, -7.9082],
      [110.1457, -7.9076],
      [110.1454, -7.9068],
      [110.1452, -7.9059],
      [110.145, -7.9046],
      [110.1449, -7.904],
      [110.1451, -7.903],
      [110.1444, -7.903],
      [110.1435, -7.9031],
      [110.1432, -7.9032],
      [110.1424, -7.9033],
      [110.1421, -7.9033],
      [110.1419, -7.9031],
      [110.1417, -7.9032],
      [110.1414, -7.9036],
      [110.141, -7.9039],
      [110.1408, -7.9041],
      [110.1406, -7.9044],
      [110.1407, -7.9054],
      [110.1407, -7.9063],
      [110.1405, -7.9065],
      [110.14, -7.9067],
      [110.1395, -7.9068],
      [110.1391, -7.9072],
      [110.1388, -7.9075],
      [110.1388, -7.9082],
      [110.138, -7.9088],
      [110.1362, -7.9093],
      [110.1352, -7.9097],
      [110.1336, -7.9102],
      [110.1317, -7.9106],
      [110.1298, -7.9109],
      [110.1285, -7.9107],
      [110.1261, -7.9109],
      [110.1236, -7.9108],
      [110.1213, -7.9108],
      [110.1192, -7.9107],
      [110.1171, -7.9105],
      [110.1171, -7.9106],
      [110.1166, -7.9119],
      [110.1163, -7.9142],
      [110.1168, -7.9141],
      [110.1158, -7.9164],
      [110.1157, -7.9166],
      [110.1164, -7.9168],
      [110.1183, -7.9176],
      [110.1186, -7.9172],
      [110.1229, -7.9185],
      [110.1231, -7.9183],
      [110.1231, -7.9182],
      [110.1232, -7.918],
      [110.125, -7.9187],
      [110.1254, -7.9189],
      [110.1257, -7.9191],
      [110.1259, -7.9192],
      [110.1269, -7.9197],
      [110.1303, -7.9202],
      [110.1311, -7.917],
      [110.1313, -7.9162],
      [110.1362, -7.9178],
      [110.1366, -7.9171],
      [110.1371, -7.9164],
      [110.1373, -7.9161],
      [110.1375, -7.9153],
      [110.1377, -7.914],
      [110.1402, -7.9142],
      [110.1404, -7.9146],
      [110.1403, -7.9151],
      [110.1396, -7.9158],
      [110.1395, -7.9161],
      [110.1398, -7.9164],
      [110.1398, -7.9175],
      [110.1399, -7.9183],
      [110.1397, -7.9196],
      [110.1386, -7.9198],
      [110.1386, -7.9193],
      [110.1387, -7.919],
      [110.1384, -7.9187],
      [110.1378, -7.9186],
      [110.1375, -7.9186],
      [110.1372, -7.9196],
      [110.1372, -7.92],
      [110.1375, -7.9205],
      [110.1381, -7.9209],
      [110.138, -7.9215],
      [110.1378, -7.9222],
      [110.1378, -7.9223],
      [110.138, -7.9224],
      [110.1386, -7.9226],
      [110.1389, -7.9224],
      [110.14, -7.9229],
      [110.1403, -7.9231],
      [110.1405, -7.9231],
      [110.1406, -7.9232],
      [110.1407, -7.9234],
      [110.1408, -7.9235],
      [110.1409, -7.9235],
      [110.141, -7.9236],
      [110.1414, -7.9238],
      [110.1419, -7.9239],
      [110.1417, -7.9249],
      [110.1417, -7.925],
      [110.1421, -7.925],
      [110.1433, -7.9252],
      [110.1446, -7.9253],
      [110.1448, -7.9252],
      [110.145, -7.9251],
      [110.145, -7.9243],
      [110.1454, -7.9243],
      [110.1457, -7.9244],
      [110.146, -7.9242],
      [110.1461, -7.9234],
      [110.1465, -7.9226],
      [110.1461, -7.9224],
      [110.1455, -7.9224],
      [110.1456, -7.9221],
      [110.1458, -7.9213],
      [110.1447, -7.9211],
      [110.1448, -7.9202],
      [110.1449, -7.919],
      [110.1468, -7.9197],
      [110.1473, -7.9198],
      [110.1475, -7.9198],
      [110.1475, -7.9194],
      [110.1476, -7.919],
      [110.1481, -7.9183],
      [110.148, -7.9172],
      [110.1481, -7.9162],
      [110.1481, -7.9154],
      [110.1479, -7.9146],
      [110.1479, -7.9138],
      [110.1481, -7.9129],
      [110.148, -7.9124],
      [110.1476, -7.9117],
      [110.1472, -7.9115],
      [110.1466, -7.9111],
      [110.1463, -7.9105],
      [110.1461, -7.9099]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'TAYUBAN',
    border: [
      [110.1669, -7.9014],
      [110.1666, -7.901],
      [110.1664, -7.9008],
      [110.1659, -7.9003],
      [110.1642, -7.8984],
      [110.1637, -7.8978],
      [110.1635, -7.8976],
      [110.1626, -7.8974],
      [110.1591, -7.8967],
      [110.1554, -7.896],
      [110.1541, -7.8957],
      [110.1537, -7.8956],
      [110.1536, -7.8956],
      [110.1531, -7.8955],
      [110.1529, -7.8955],
      [110.1527, -7.9006],
      [110.1526, -7.9035],
      [110.1524, -7.9035],
      [110.1523, -7.9035],
      [110.1492, -7.9034],
      [110.146, -7.9031],
      [110.1451, -7.903],
      [110.1449, -7.904],
      [110.145, -7.9046],
      [110.1452, -7.9059],
      [110.1454, -7.9068],
      [110.1457, -7.9076],
      [110.1458, -7.9082],
      [110.1462, -7.9088],
      [110.1462, -7.9094],
      [110.1461, -7.9099],
      [110.1466, -7.9098],
      [110.1472, -7.9099],
      [110.1479, -7.91],
      [110.1486, -7.91],
      [110.1488, -7.91],
      [110.1494, -7.9104],
      [110.1501, -7.9104],
      [110.1508, -7.9106],
      [110.1517, -7.9106],
      [110.1526, -7.9103],
      [110.153, -7.9103],
      [110.1532, -7.9102],
      [110.1529, -7.9096],
      [110.1526, -7.9091],
      [110.1525, -7.9085],
      [110.1524, -7.908],
      [110.153, -7.9081],
      [110.1538, -7.9078],
      [110.1545, -7.9076],
      [110.1552, -7.9075],
      [110.1558, -7.9076],
      [110.1567, -7.9076],
      [110.1577, -7.9076],
      [110.158, -7.9075],
      [110.1582, -7.9067],
      [110.1585, -7.906],
      [110.1591, -7.9061],
      [110.16, -7.9063],
      [110.1603, -7.9064],
      [110.1605, -7.9063],
      [110.1614, -7.9066],
      [110.1621, -7.9068],
      [110.1631, -7.907],
      [110.1637, -7.907],
      [110.1643, -7.9069],
      [110.1645, -7.9066],
      [110.1647, -7.9063],
      [110.1651, -7.9063],
      [110.1657, -7.9063],
      [110.1662, -7.9062],
      [110.1663, -7.9054],
      [110.1664, -7.9045],
      [110.1666, -7.9033],
      [110.1669, -7.9024],
      [110.1666, -7.902],
      [110.1668, -7.9016],
      [110.1669, -7.9014]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'GOTAKAN',
    border: [
      [110.1718, -7.8843],
      [110.171, -7.8841],
      [110.1698, -7.8838],
      [110.1689, -7.8834],
      [110.1677, -7.8827],
      [110.167, -7.8818],
      [110.1665, -7.8813],
      [110.1658, -7.881],
      [110.166, -7.8796],
      [110.1651, -7.8795],
      [110.164, -7.8795],
      [110.163, -7.8795],
      [110.1622, -7.8795],
      [110.1614, -7.8792],
      [110.1602, -7.8789],
      [110.1591, -7.8788],
      [110.1581, -7.8788],
      [110.1579, -7.8802],
      [110.1578, -7.8811],
      [110.1573, -7.8826],
      [110.1549, -7.8824],
      [110.1544, -7.8824],
      [110.1537, -7.8823],
      [110.1536, -7.8826],
      [110.1535, -7.8846],
      [110.1534, -7.8879],
      [110.1534, -7.8882],
      [110.1533, -7.8884],
      [110.1531, -7.8916],
      [110.153, -7.8936],
      [110.1529, -7.895],
      [110.1529, -7.8955],
      [110.1531, -7.8955],
      [110.1536, -7.8956],
      [110.1537, -7.8956],
      [110.1541, -7.8957],
      [110.1554, -7.896],
      [110.1591, -7.8967],
      [110.1626, -7.8974],
      [110.1635, -7.8976],
      [110.1637, -7.8978],
      [110.1642, -7.8984],
      [110.1659, -7.9003],
      [110.1664, -7.9008],
      [110.1666, -7.901],
      [110.1669, -7.9014],
      [110.1671, -7.9016],
      [110.1675, -7.9021],
      [110.1677, -7.9024],
      [110.1697, -7.9046],
      [110.1705, -7.9056],
      [110.1707, -7.9055],
      [110.1714, -7.9056],
      [110.1716, -7.9059],
      [110.1724, -7.906],
      [110.1724, -7.9056],
      [110.1724, -7.9029],
      [110.1725, -7.9025],
      [110.1739, -7.9029],
      [110.1747, -7.9028],
      [110.1747, -7.9017],
      [110.1746, -7.8998],
      [110.1746, -7.8996],
      [110.1746, -7.8983],
      [110.174, -7.8982],
      [110.1733, -7.8986],
      [110.1722, -7.8991],
      [110.1716, -7.8992],
      [110.1708, -7.8989],
      [110.1707, -7.8989],
      [110.1703, -7.8989],
      [110.1701, -7.8989],
      [110.1693, -7.8987],
      [110.1681, -7.8986],
      [110.1673, -7.898],
      [110.167, -7.8978],
      [110.1664, -7.8975],
      [110.1662, -7.8973],
      [110.1657, -7.8972],
      [110.1647, -7.8969],
      [110.1636, -7.8964],
      [110.1632, -7.8964],
      [110.1624, -7.8963],
      [110.162, -7.8962],
      [110.1621, -7.8956],
      [110.1622, -7.8953],
      [110.1623, -7.8943],
      [110.1627, -7.8943],
      [110.1634, -7.8945],
      [110.1635, -7.8942],
      [110.1634, -7.8939],
      [110.1634, -7.8934],
      [110.1636, -7.8925],
      [110.1639, -7.8917],
      [110.1643, -7.8908],
      [110.165, -7.8906],
      [110.1653, -7.89],
      [110.1665, -7.8903],
      [110.1667, -7.8901],
      [110.1672, -7.8895],
      [110.1677, -7.8894],
      [110.1688, -7.8896],
      [110.1699, -7.8897],
      [110.1708, -7.89],
      [110.1712, -7.8899],
      [110.1711, -7.8894],
      [110.171, -7.8889],
      [110.1709, -7.8887],
      [110.1708, -7.8882],
      [110.1711, -7.8875],
      [110.1711, -7.887],
      [110.1712, -7.8862],
      [110.1718, -7.8862],
      [110.1718, -7.8846],
      [110.1718, -7.8843]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'PANJATAN',
    border: [
      [110.1705, -7.9056],
      [110.1697, -7.9046],
      [110.1677, -7.9024],
      [110.1675, -7.9021],
      [110.1671, -7.9016],
      [110.1669, -7.9014],
      [110.1668, -7.9016],
      [110.1666, -7.902],
      [110.1669, -7.9024],
      [110.1666, -7.9033],
      [110.1664, -7.9045],
      [110.1663, -7.9054],
      [110.1662, -7.9062],
      [110.1657, -7.9063],
      [110.1651, -7.9063],
      [110.1647, -7.9063],
      [110.1645, -7.9066],
      [110.1643, -7.9069],
      [110.1637, -7.907],
      [110.1631, -7.907],
      [110.1621, -7.9068],
      [110.1614, -7.9066],
      [110.1605, -7.9063],
      [110.1603, -7.9064],
      [110.16, -7.9063],
      [110.1591, -7.9061],
      [110.1585, -7.906],
      [110.1582, -7.9067],
      [110.158, -7.9075],
      [110.158, -7.9077],
      [110.1579, -7.9082],
      [110.1578, -7.9089],
      [110.1578, -7.9095],
      [110.1578, -7.9104],
      [110.1582, -7.9104],
      [110.1595, -7.9105],
      [110.1606, -7.9106],
      [110.1624, -7.9108],
      [110.1644, -7.9108],
      [110.165, -7.9105],
      [110.1654, -7.9102],
      [110.1659, -7.9105],
      [110.1668, -7.9105],
      [110.168, -7.9107],
      [110.1692, -7.9108],
      [110.1724, -7.9114],
      [110.1737, -7.9116],
      [110.1736, -7.9129],
      [110.1743, -7.9133],
      [110.1743, -7.9139],
      [110.1749, -7.9141],
      [110.1747, -7.9154],
      [110.176, -7.9156],
      [110.1762, -7.9157],
      [110.1764, -7.9157],
      [110.1765, -7.916],
      [110.1763, -7.9175],
      [110.176, -7.919],
      [110.1758, -7.9192],
      [110.1753, -7.9192],
      [110.1752, -7.92],
      [110.1766, -7.9201],
      [110.1779, -7.9203],
      [110.1781, -7.9203],
      [110.1783, -7.9176],
      [110.1782, -7.9173],
      [110.1783, -7.9145],
      [110.1783, -7.9144],
      [110.1781, -7.9142],
      [110.177, -7.9129],
      [110.1739, -7.9094],
      [110.1705, -7.9056]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'CERME',
    border: [
      [110.1835, -7.8829],
      [110.1834, -7.8829],
      [110.1827, -7.8832],
      [110.1819, -7.8832],
      [110.1814, -7.8832],
      [110.1809, -7.8831],
      [110.1801, -7.883],
      [110.1793, -7.8831],
      [110.1793, -7.8828],
      [110.1793, -7.882],
      [110.1794, -7.8811],
      [110.1798, -7.8809],
      [110.1798, -7.8806],
      [110.1797, -7.8799],
      [110.1797, -7.8793],
      [110.1802, -7.8791],
      [110.1801, -7.8784],
      [110.1802, -7.8778],
      [110.1804, -7.8771],
      [110.1802, -7.8765],
      [110.1802, -7.8753],
      [110.1791, -7.8751],
      [110.1787, -7.875],
      [110.1784, -7.8749],
      [110.1778, -7.8749],
      [110.177, -7.8745],
      [110.1762, -7.8742],
      [110.1758, -7.8743],
      [110.175, -7.8743],
      [110.1743, -7.8744],
      [110.1744, -7.8752],
      [110.1745, -7.876],
      [110.1746, -7.8762],
      [110.1754, -7.8761],
      [110.1754, -7.8764],
      [110.1753, -7.8773],
      [110.1754, -7.8777],
      [110.1756, -7.8779],
      [110.1759, -7.8782],
      [110.1767, -7.8784],
      [110.1771, -7.8789],
      [110.1771, -7.8798],
      [110.1771, -7.8803],
      [110.1766, -7.8805],
      [110.1761, -7.8806],
      [110.1759, -7.8814],
      [110.1758, -7.8819],
      [110.1759, -7.8826],
      [110.176, -7.8834],
      [110.1757, -7.884],
      [110.1757, -7.8847],
      [110.1752, -7.885],
      [110.1745, -7.8851],
      [110.1736, -7.8849],
      [110.1725, -7.8846],
      [110.1718, -7.8843],
      [110.1718, -7.8846],
      [110.1718, -7.8862],
      [110.1712, -7.8862],
      [110.1711, -7.887],
      [110.1711, -7.8875],
      [110.1708, -7.8882],
      [110.1709, -7.8887],
      [110.171, -7.8889],
      [110.1711, -7.8894],
      [110.1712, -7.8899],
      [110.1708, -7.89],
      [110.1699, -7.8897],
      [110.1688, -7.8896],
      [110.1677, -7.8894],
      [110.1672, -7.8895],
      [110.1667, -7.8901],
      [110.1665, -7.8903],
      [110.1653, -7.89],
      [110.165, -7.8906],
      [110.1643, -7.8908],
      [110.1639, -7.8917],
      [110.1636, -7.8925],
      [110.1634, -7.8934],
      [110.1634, -7.8939],
      [110.1635, -7.8942],
      [110.1634, -7.8945],
      [110.1627, -7.8943],
      [110.1623, -7.8943],
      [110.1622, -7.8953],
      [110.1621, -7.8956],
      [110.162, -7.8962],
      [110.1624, -7.8963],
      [110.1632, -7.8964],
      [110.1636, -7.8964],
      [110.1647, -7.8969],
      [110.1657, -7.8972],
      [110.1662, -7.8973],
      [110.1664, -7.8975],
      [110.167, -7.8978],
      [110.1673, -7.898],
      [110.1681, -7.8986],
      [110.1693, -7.8987],
      [110.1701, -7.8989],
      [110.1703, -7.8989],
      [110.1707, -7.8989],
      [110.1708, -7.8989],
      [110.1716, -7.8992],
      [110.1722, -7.8991],
      [110.1733, -7.8986],
      [110.174, -7.8982],
      [110.1746, -7.8983],
      [110.1746, -7.8996],
      [110.1746, -7.8998],
      [110.1747, -7.9017],
      [110.1747, -7.9028],
      [110.1739, -7.9029],
      [110.1725, -7.9025],
      [110.1724, -7.9029],
      [110.1724, -7.9056],
      [110.1724, -7.906],
      [110.1716, -7.9059],
      [110.1714, -7.9056],
      [110.1707, -7.9055],
      [110.1705, -7.9056],
      [110.1739, -7.9094],
      [110.177, -7.9129],
      [110.1781, -7.9142],
      [110.1783, -7.9144],
      [110.1784, -7.9147],
      [110.1786, -7.9149],
      [110.1795, -7.916],
      [110.1798, -7.9162],
      [110.1799, -7.9161],
      [110.1797, -7.9127],
      [110.1795, -7.909],
      [110.1781, -7.909],
      [110.1779, -7.9065],
      [110.1799, -7.9064],
      [110.1819, -7.9062],
      [110.1821, -7.906],
      [110.1817, -7.9039],
      [110.1817, -7.9037],
      [110.1823, -7.9015],
      [110.1833, -7.8978],
      [110.1832, -7.8972],
      [110.1807, -7.897],
      [110.1805, -7.8951],
      [110.1805, -7.8945],
      [110.1805, -7.893],
      [110.1807, -7.8919],
      [110.1805, -7.8914],
      [110.1804, -7.8907],
      [110.1806, -7.89],
      [110.1802, -7.8892],
      [110.1801, -7.8883],
      [110.1807, -7.888],
      [110.1804, -7.887],
      [110.181, -7.8867],
      [110.1817, -7.8866],
      [110.1823, -7.8863],
      [110.1826, -7.8862],
      [110.1826, -7.8858],
      [110.1836, -7.8853],
      [110.1835, -7.8843],
      [110.1836, -7.8835],
      [110.1835, -7.8829]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PANJATAN',
    village: 'KREMBANGAN',
    border: [
      [110.1969, -7.876],
      [110.1966, -7.876],
      [110.196, -7.8757],
      [110.1945, -7.8749],
      [110.1935, -7.8746],
      [110.1927, -7.8746],
      [110.1919, -7.8746],
      [110.1913, -7.8745],
      [110.1903, -7.8745],
      [110.1902, -7.8745],
      [110.1896, -7.8745],
      [110.1887, -7.8745],
      [110.1882, -7.8748],
      [110.1876, -7.8747],
      [110.187, -7.8747],
      [110.1868, -7.8745],
      [110.1865, -7.8745],
      [110.1859, -7.8745],
      [110.1855, -7.8746],
      [110.185, -7.8747],
      [110.1845, -7.8746],
      [110.1841, -7.8745],
      [110.1837, -7.8745],
      [110.1836, -7.8755],
      [110.184, -7.8757],
      [110.1847, -7.8759],
      [110.1861, -7.8764],
      [110.1859, -7.8769],
      [110.186, -7.877],
      [110.1862, -7.8772],
      [110.1861, -7.8782],
      [110.1858, -7.8793],
      [110.1857, -7.8802],
      [110.1855, -7.8812],
      [110.1855, -7.882],
      [110.185, -7.8822],
      [110.185, -7.8826],
      [110.1848, -7.883],
      [110.1846, -7.883],
      [110.184, -7.8829],
      [110.1835, -7.8829],
      [110.1836, -7.8835],
      [110.1835, -7.8843],
      [110.1836, -7.8853],
      [110.1826, -7.8858],
      [110.1826, -7.8862],
      [110.1823, -7.8863],
      [110.1817, -7.8866],
      [110.181, -7.8867],
      [110.1804, -7.887],
      [110.1807, -7.888],
      [110.1801, -7.8883],
      [110.1802, -7.8892],
      [110.1806, -7.89],
      [110.1804, -7.8907],
      [110.1805, -7.8914],
      [110.1807, -7.8919],
      [110.1805, -7.893],
      [110.1805, -7.8945],
      [110.1805, -7.8951],
      [110.1807, -7.897],
      [110.1832, -7.8972],
      [110.1833, -7.8978],
      [110.1823, -7.9015],
      [110.1817, -7.9037],
      [110.1817, -7.9039],
      [110.1821, -7.906],
      [110.1819, -7.9062],
      [110.1799, -7.9064],
      [110.1779, -7.9065],
      [110.1781, -7.909],
      [110.1795, -7.909],
      [110.1797, -7.9127],
      [110.1799, -7.9161],
      [110.1798, -7.9162],
      [110.1795, -7.916],
      [110.1786, -7.9149],
      [110.1784, -7.9147],
      [110.1783, -7.9144],
      [110.1783, -7.9145],
      [110.1782, -7.9173],
      [110.1783, -7.9176],
      [110.1812, -7.918],
      [110.1834, -7.9203],
      [110.1835, -7.9204],
      [110.1839, -7.9195],
      [110.1842, -7.9178],
      [110.1843, -7.9174],
      [110.1844, -7.9167],
      [110.1849, -7.9145],
      [110.1854, -7.9121],
      [110.186, -7.9098],
      [110.1862, -7.9086],
      [110.1868, -7.907],
      [110.1872, -7.9056],
      [110.1874, -7.9053],
      [110.188, -7.9032],
      [110.1884, -7.9026],
      [110.1885, -7.9025],
      [110.1886, -7.9023],
      [110.1897, -7.9008],
      [110.1905, -7.8995],
      [110.1908, -7.8992],
      [110.1919, -7.8975],
      [110.1928, -7.8962],
      [110.1928, -7.8958],
      [110.1929, -7.8955],
      [110.1931, -7.8945],
      [110.1931, -7.8937],
      [110.1932, -7.8934],
      [110.1934, -7.8931],
      [110.1939, -7.8929],
      [110.1942, -7.8925],
      [110.1944, -7.8912],
      [110.1948, -7.8885],
      [110.1948, -7.8875],
      [110.1944, -7.887],
      [110.1939, -7.8863],
      [110.194, -7.8858],
      [110.1946, -7.8837],
      [110.1949, -7.8826],
      [110.1947, -7.882],
      [110.1946, -7.8816],
      [110.1946, -7.8815],
      [110.1952, -7.8812],
      [110.1954, -7.8809],
      [110.1965, -7.8811],
      [110.1969, -7.8803],
      [110.1973, -7.879],
      [110.1981, -7.8766],
      [110.1979, -7.8764],
      [110.1971, -7.876],
      [110.1969, -7.876]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GALUR',
    village: 'BANARAN',
    border: [
      [110.2335, -7.9547],
      [110.2317, -7.9539],
      [110.2314, -7.9538],
      [110.2301, -7.9534],
      [110.2288, -7.953],
      [110.2283, -7.9531],
      [110.2275, -7.9536],
      [110.2263, -7.9532],
      [110.2256, -7.953],
      [110.2248, -7.953],
      [110.224, -7.9531],
      [110.2236, -7.9536],
      [110.2237, -7.9541],
      [110.2238, -7.9547],
      [110.2237, -7.9552],
      [110.223, -7.9555],
      [110.222, -7.9552],
      [110.222, -7.9542],
      [110.2216, -7.9537],
      [110.221, -7.9535],
      [110.2202, -7.9535],
      [110.2197, -7.9537],
      [110.2193, -7.954],
      [110.219, -7.9546],
      [110.2189, -7.9554],
      [110.2177, -7.9549],
      [110.2174, -7.9556],
      [110.2155, -7.9603],
      [110.2126, -7.9673],
      [110.2116, -7.9667],
      [110.208, -7.9654],
      [110.2067, -7.9656],
      [110.2059, -7.9658],
      [110.2047, -7.9654],
      [110.2036, -7.9653],
      [110.2016, -7.9644],
      [110.2002, -7.9638],
      [110.1998, -7.9637],
      [110.1996, -7.9636],
      [110.1979, -7.9629],
      [110.1961, -7.9616],
      [110.1928, -7.9595],
      [110.1902, -7.9581],
      [110.1893, -7.9583],
      [110.1889, -7.9585],
      [110.188, -7.9617],
      [110.186, -7.9679],
      [110.1853, -7.9701],
      [110.1857, -7.9703],
      [110.1864, -7.9709],
      [110.1874, -7.9717],
      [110.1878, -7.972],
      [110.1884, -7.9724],
      [110.1888, -7.9726],
      [110.1899, -7.9734],
      [110.1909, -7.974],
      [110.1922, -7.975],
      [110.1933, -7.9759],
      [110.1934, -7.976],
      [110.194, -7.9764],
      [110.1946, -7.9768],
      [110.195, -7.9772],
      [110.1958, -7.9779],
      [110.1959, -7.9779],
      [110.197, -7.9788],
      [110.1978, -7.9795],
      [110.1989, -7.9802],
      [110.1997, -7.9807],
      [110.2003, -7.9811],
      [110.2005, -7.9814],
      [110.2009, -7.9815],
      [110.2018, -7.9819],
      [110.2023, -7.9823],
      [110.2034, -7.983],
      [110.2038, -7.9832],
      [110.2039, -7.9832],
      [110.2042, -7.9829],
      [110.205, -7.9825],
      [110.2057, -7.982],
      [110.2087, -7.9808],
      [110.2118, -7.9793],
      [110.2144, -7.9776],
      [110.2155, -7.9767],
      [110.218, -7.9746],
      [110.2191, -7.9733],
      [110.2196, -7.9727],
      [110.2197, -7.9725],
      [110.2202, -7.9719],
      [110.2215, -7.9699],
      [110.2228, -7.9682],
      [110.2233, -7.9675],
      [110.224, -7.9669],
      [110.2245, -7.9665],
      [110.2249, -7.9659],
      [110.2254, -7.9651],
      [110.2256, -7.9646],
      [110.2262, -7.9635],
      [110.2271, -7.9625],
      [110.2276, -7.9617],
      [110.2286, -7.9606],
      [110.2302, -7.9587],
      [110.2315, -7.9574],
      [110.2323, -7.9565],
      [110.2329, -7.9555],
      [110.2335, -7.9547]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GALUR',
    village: 'KRANGGAN',
    border: [
      [110.2353, -7.9523],
      [110.2347, -7.9519],
      [110.2337, -7.9517],
      [110.2329, -7.9516],
      [110.2329, -7.9515],
      [110.232, -7.9514],
      [110.2322, -7.9507],
      [110.2326, -7.9497],
      [110.2329, -7.9487],
      [110.2337, -7.9479],
      [110.2337, -7.9475],
      [110.2336, -7.9471],
      [110.2334, -7.9468],
      [110.2329, -7.9471],
      [110.2324, -7.9479],
      [110.2318, -7.9483],
      [110.2312, -7.9481],
      [110.2303, -7.9478],
      [110.2288, -7.9472],
      [110.2278, -7.947],
      [110.2252, -7.9467],
      [110.2252, -7.9465],
      [110.2247, -7.9464],
      [110.2222, -7.9459],
      [110.2216, -7.9465],
      [110.2213, -7.9471],
      [110.2209, -7.9472],
      [110.2204, -7.9476],
      [110.22, -7.9477],
      [110.2196, -7.9489],
      [110.2191, -7.9503],
      [110.2185, -7.9503],
      [110.2181, -7.9512],
      [110.2181, -7.9517],
      [110.2181, -7.9527],
      [110.218, -7.9533],
      [110.2176, -7.9539],
      [110.2171, -7.9544],
      [110.2164, -7.9551],
      [110.2161, -7.9551],
      [110.2159, -7.9554],
      [110.2157, -7.9561],
      [110.2156, -7.9569],
      [110.2154, -7.9576],
      [110.215, -7.958],
      [110.215, -7.9585],
      [110.2147, -7.9593],
      [110.2145, -7.9596],
      [110.2143, -7.9601],
      [110.2143, -7.9607],
      [110.2138, -7.961],
      [110.2132, -7.9608],
      [110.2129, -7.9605],
      [110.2119, -7.9603],
      [110.2113, -7.9603],
      [110.2113, -7.9596],
      [110.2114, -7.9592],
      [110.212, -7.9592],
      [110.2122, -7.9586],
      [110.212, -7.9585],
      [110.2116, -7.9582],
      [110.2112, -7.9584],
      [110.2107, -7.9578],
      [110.2088, -7.9578],
      [110.209, -7.9615],
      [110.209, -7.9624],
      [110.2088, -7.9632],
      [110.2088, -7.9633],
      [110.2084, -7.9643],
      [110.2076, -7.9641],
      [110.2069, -7.9639],
      [110.2063, -7.9635],
      [110.2063, -7.9632],
      [110.2064, -7.9629],
      [110.2069, -7.9626],
      [110.207, -7.9625],
      [110.2064, -7.9624],
      [110.2057, -7.9624],
      [110.2049, -7.9624],
      [110.2049, -7.9624],
      [110.2044, -7.962],
      [110.2038, -7.9618],
      [110.2029, -7.9614],
      [110.2021, -7.9606],
      [110.2019, -7.9606],
      [110.2018, -7.9608],
      [110.201, -7.9615],
      [110.2002, -7.9621],
      [110.1999, -7.9622],
      [110.1996, -7.9623],
      [110.199, -7.9625],
      [110.1984, -7.9627],
      [110.1979, -7.9629],
      [110.1996, -7.9636],
      [110.1998, -7.9637],
      [110.2002, -7.9638],
      [110.2016, -7.9644],
      [110.2036, -7.9653],
      [110.2047, -7.9654],
      [110.2059, -7.9658],
      [110.2067, -7.9656],
      [110.208, -7.9654],
      [110.2116, -7.9667],
      [110.2126, -7.9673],
      [110.2155, -7.9603],
      [110.2174, -7.9556],
      [110.2177, -7.9549],
      [110.2189, -7.9554],
      [110.219, -7.9546],
      [110.2193, -7.954],
      [110.2197, -7.9537],
      [110.2202, -7.9535],
      [110.221, -7.9535],
      [110.2216, -7.9537],
      [110.222, -7.9542],
      [110.222, -7.9552],
      [110.223, -7.9555],
      [110.2237, -7.9552],
      [110.2238, -7.9547],
      [110.2237, -7.9541],
      [110.2236, -7.9536],
      [110.224, -7.9531],
      [110.2248, -7.953],
      [110.2256, -7.953],
      [110.2263, -7.9532],
      [110.2275, -7.9536],
      [110.2283, -7.9531],
      [110.2288, -7.953],
      [110.2301, -7.9534],
      [110.2314, -7.9538],
      [110.2317, -7.9539],
      [110.2335, -7.9547],
      [110.2336, -7.9545],
      [110.2348, -7.9529],
      [110.2353, -7.9523]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GALUR',
    village: 'NOMPOREJO',
    border: [
      [110.2202, -7.9456],
      [110.2198, -7.9455],
      [110.2183, -7.9452],
      [110.2171, -7.945],
      [110.2167, -7.9454],
      [110.2162, -7.9457],
      [110.2158, -7.946],
      [110.2156, -7.9467],
      [110.2155, -7.9469],
      [110.2159, -7.9473],
      [110.216, -7.9479],
      [110.2164, -7.9482],
      [110.2165, -7.9488],
      [110.217, -7.9492],
      [110.2169, -7.9497],
      [110.2165, -7.9499],
      [110.2163, -7.9496],
      [110.2161, -7.9491],
      [110.2158, -7.9491],
      [110.2158, -7.9504],
      [110.2157, -7.951],
      [110.2146, -7.9514],
      [110.2128, -7.9514],
      [110.2123, -7.9513],
      [110.2122, -7.9511],
      [110.2122, -7.9509],
      [110.2126, -7.9506],
      [110.2129, -7.95],
      [110.213, -7.9495],
      [110.213, -7.9491],
      [110.2133, -7.9486],
      [110.2134, -7.948],
      [110.2132, -7.9478],
      [110.2129, -7.948],
      [110.2127, -7.9486],
      [110.2123, -7.949],
      [110.2118, -7.9491],
      [110.2114, -7.9494],
      [110.2107, -7.9499],
      [110.2102, -7.9501],
      [110.2097, -7.9507],
      [110.2089, -7.9507],
      [110.2087, -7.9511],
      [110.2086, -7.9514],
      [110.2086, -7.9517],
      [110.2073, -7.952],
      [110.2052, -7.9521],
      [110.2044, -7.9522],
      [110.2042, -7.9524],
      [110.2042, -7.9529],
      [110.2043, -7.9532],
      [110.2047, -7.9534],
      [110.2053, -7.9534],
      [110.2061, -7.9534],
      [110.2065, -7.9536],
      [110.2066, -7.9542],
      [110.2061, -7.9554],
      [110.2053, -7.9564],
      [110.2043, -7.9576],
      [110.2033, -7.9585],
      [110.2029, -7.9585],
      [110.2025, -7.9579],
      [110.2012, -7.958],
      [110.2003, -7.9577],
      [110.2004, -7.9584],
      [110.2006, -7.959],
      [110.2014, -7.96],
      [110.2019, -7.9606],
      [110.2021, -7.9606],
      [110.2029, -7.9614],
      [110.2038, -7.9618],
      [110.2044, -7.962],
      [110.2049, -7.9624],
      [110.2049, -7.9624],
      [110.2057, -7.9624],
      [110.2064, -7.9624],
      [110.207, -7.9625],
      [110.2069, -7.9626],
      [110.2064, -7.9629],
      [110.2063, -7.9632],
      [110.2063, -7.9635],
      [110.2069, -7.9639],
      [110.2076, -7.9641],
      [110.2084, -7.9643],
      [110.2088, -7.9633],
      [110.2088, -7.9632],
      [110.209, -7.9624],
      [110.209, -7.9615],
      [110.2088, -7.9578],
      [110.2107, -7.9578],
      [110.2112, -7.9584],
      [110.2116, -7.9582],
      [110.212, -7.9585],
      [110.2122, -7.9586],
      [110.212, -7.9592],
      [110.2114, -7.9592],
      [110.2113, -7.9596],
      [110.2113, -7.9603],
      [110.2119, -7.9603],
      [110.2129, -7.9605],
      [110.2132, -7.9608],
      [110.2138, -7.961],
      [110.2143, -7.9607],
      [110.2143, -7.9601],
      [110.2145, -7.9596],
      [110.2147, -7.9593],
      [110.215, -7.9585],
      [110.215, -7.958],
      [110.2154, -7.9576],
      [110.2156, -7.9569],
      [110.2157, -7.9561],
      [110.2159, -7.9554],
      [110.2161, -7.9551],
      [110.2164, -7.9551],
      [110.2171, -7.9544],
      [110.2176, -7.9539],
      [110.218, -7.9533],
      [110.2181, -7.9527],
      [110.2181, -7.9517],
      [110.2181, -7.9512],
      [110.2185, -7.9503],
      [110.2191, -7.9503],
      [110.2196, -7.9489],
      [110.22, -7.9477],
      [110.2204, -7.9476],
      [110.2209, -7.9472],
      [110.2213, -7.9471],
      [110.2216, -7.9465],
      [110.2222, -7.9459],
      [110.2219, -7.9459],
      [110.2217, -7.9459],
      [110.2211, -7.9458],
      [110.2207, -7.9457],
      [110.2202, -7.9456]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GALUR',
    village: 'KARANGSEWU',
    border: [
      [110.2021, -7.9433],
      [110.202, -7.9435],
      [110.2018, -7.9444],
      [110.2019, -7.9452],
      [110.2013, -7.9454],
      [110.2001, -7.945],
      [110.1987, -7.9448],
      [110.1987, -7.9443],
      [110.199, -7.9436],
      [110.199, -7.9432],
      [110.1986, -7.9432],
      [110.1974, -7.9435],
      [110.1965, -7.9438],
      [110.1964, -7.9439],
      [110.1966, -7.9443],
      [110.197, -7.9448],
      [110.1969, -7.9452],
      [110.1964, -7.9452],
      [110.1958, -7.9452],
      [110.1954, -7.9453],
      [110.194, -7.9455],
      [110.1924, -7.9457],
      [110.191, -7.9459],
      [110.1905, -7.946],
      [110.1903, -7.9461],
      [110.1897, -7.9459],
      [110.1887, -7.9479],
      [110.1884, -7.948],
      [110.1869, -7.9471],
      [110.1855, -7.9463],
      [110.1847, -7.9458],
      [110.1845, -7.9456],
      [110.184, -7.9451],
      [110.1831, -7.9442],
      [110.1826, -7.9428],
      [110.1819, -7.9402],
      [110.1817, -7.9402],
      [110.181, -7.94],
      [110.1767, -7.9389],
      [110.175, -7.9384],
      [110.1749, -7.9387],
      [110.1742, -7.94],
      [110.173, -7.9429],
      [110.1724, -7.9442],
      [110.1723, -7.9447],
      [110.1721, -7.9452],
      [110.1718, -7.9458],
      [110.1712, -7.9468],
      [110.1708, -7.9473],
      [110.1708, -7.9479],
      [110.1704, -7.9489],
      [110.1701, -7.9493],
      [110.1697, -7.9503],
      [110.1688, -7.9519],
      [110.1673, -7.9542],
      [110.1662, -7.9562],
      [110.1654, -7.9578],
      [110.1652, -7.9584],
      [110.1656, -7.9586],
      [110.166, -7.9588],
      [110.1669, -7.9592],
      [110.1674, -7.9596],
      [110.1679, -7.9598],
      [110.1688, -7.9604],
      [110.1697, -7.9608],
      [110.1703, -7.9613],
      [110.1708, -7.9615],
      [110.1712, -7.9617],
      [110.1715, -7.9618],
      [110.172, -7.9621],
      [110.1725, -7.9624],
      [110.1728, -7.9625],
      [110.1735, -7.963],
      [110.1741, -7.9632],
      [110.1745, -7.9636],
      [110.175, -7.9637],
      [110.1753, -7.964],
      [110.1756, -7.9641],
      [110.1759, -7.9643],
      [110.1761, -7.9644],
      [110.1765, -7.9646],
      [110.1772, -7.965],
      [110.178, -7.9655],
      [110.1782, -7.9656],
      [110.1784, -7.9657],
      [110.1785, -7.9658],
      [110.1788, -7.966],
      [110.1791, -7.9662],
      [110.1792, -7.9663],
      [110.1794, -7.9664],
      [110.1797, -7.9666],
      [110.18, -7.9668],
      [110.1804, -7.967],
      [110.1808, -7.9672],
      [110.1815, -7.9677],
      [110.1822, -7.9681],
      [110.1827, -7.9684],
      [110.1836, -7.969],
      [110.1848, -7.9698],
      [110.1851, -7.97],
      [110.1853, -7.9701],
      [110.186, -7.9679],
      [110.188, -7.9617],
      [110.1889, -7.9585],
      [110.1893, -7.9583],
      [110.1902, -7.9581],
      [110.1928, -7.9595],
      [110.1961, -7.9616],
      [110.1979, -7.9629],
      [110.1984, -7.9627],
      [110.199, -7.9625],
      [110.1996, -7.9623],
      [110.1999, -7.9622],
      [110.2002, -7.9621],
      [110.201, -7.9615],
      [110.2018, -7.9608],
      [110.2019, -7.9606],
      [110.2014, -7.96],
      [110.2006, -7.959],
      [110.2004, -7.9584],
      [110.2003, -7.9577],
      [110.2012, -7.958],
      [110.2025, -7.9579],
      [110.2029, -7.9585],
      [110.2033, -7.9585],
      [110.2043, -7.9576],
      [110.2053, -7.9564],
      [110.2061, -7.9554],
      [110.2066, -7.9542],
      [110.2065, -7.9536],
      [110.2061, -7.9534],
      [110.2053, -7.9534],
      [110.2047, -7.9534],
      [110.2043, -7.9532],
      [110.2042, -7.9529],
      [110.2042, -7.9524],
      [110.2044, -7.9522],
      [110.2052, -7.9521],
      [110.2073, -7.952],
      [110.2086, -7.9517],
      [110.2086, -7.9514],
      [110.2087, -7.9511],
      [110.2089, -7.9507],
      [110.2097, -7.9507],
      [110.2102, -7.9501],
      [110.2107, -7.9499],
      [110.2114, -7.9494],
      [110.2118, -7.9491],
      [110.2123, -7.949],
      [110.2127, -7.9486],
      [110.2129, -7.948],
      [110.2132, -7.9478],
      [110.2134, -7.948],
      [110.2133, -7.9486],
      [110.213, -7.9491],
      [110.213, -7.9495],
      [110.2129, -7.95],
      [110.2126, -7.9506],
      [110.2122, -7.9509],
      [110.2122, -7.9511],
      [110.2123, -7.9513],
      [110.2128, -7.9514],
      [110.2146, -7.9514],
      [110.2157, -7.951],
      [110.2158, -7.9504],
      [110.2158, -7.9491],
      [110.2161, -7.9491],
      [110.2163, -7.9496],
      [110.2165, -7.9499],
      [110.2169, -7.9497],
      [110.217, -7.9492],
      [110.2165, -7.9488],
      [110.2164, -7.9482],
      [110.216, -7.9479],
      [110.2159, -7.9473],
      [110.2155, -7.9469],
      [110.2156, -7.9467],
      [110.2158, -7.946],
      [110.2162, -7.9457],
      [110.2167, -7.9454],
      [110.2171, -7.945],
      [110.2173, -7.9444],
      [110.2174, -7.9439],
      [110.2171, -7.9434],
      [110.2167, -7.943],
      [110.2162, -7.9429],
      [110.2155, -7.943],
      [110.2146, -7.9428],
      [110.2141, -7.9426],
      [110.2137, -7.9419],
      [110.2134, -7.9412],
      [110.2129, -7.9408],
      [110.2126, -7.9407],
      [110.212, -7.9408],
      [110.2113, -7.9409],
      [110.211, -7.9417],
      [110.2108, -7.9423],
      [110.2104, -7.9426],
      [110.2096, -7.9425],
      [110.208, -7.9423],
      [110.2075, -7.9447],
      [110.2084, -7.9449],
      [110.2089, -7.9463],
      [110.2085, -7.9466],
      [110.208, -7.9466],
      [110.2075, -7.9463],
      [110.2066, -7.9464],
      [110.206, -7.9461],
      [110.2062, -7.9457],
      [110.2064, -7.9451],
      [110.2061, -7.9447],
      [110.2056, -7.9441],
      [110.2052, -7.9438],
      [110.2049, -7.9431],
      [110.2047, -7.9427],
      [110.2044, -7.943],
      [110.2038, -7.9432],
      [110.203, -7.9432],
      [110.2023, -7.9433],
      [110.2021, -7.9433]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GALUR',
    village: 'TIRTORAHAYU',
    border: [
      [110.1835, -7.9204],
      [110.1834, -7.9203],
      [110.1812, -7.918],
      [110.1783, -7.9176],
      [110.1781, -7.9203],
      [110.178, -7.9215],
      [110.1782, -7.9227],
      [110.1786, -7.9247],
      [110.1791, -7.9272],
      [110.1793, -7.9284],
      [110.1794, -7.9287],
      [110.1793, -7.929],
      [110.1792, -7.9292],
      [110.1791, -7.9293],
      [110.1788, -7.9301],
      [110.1784, -7.9309],
      [110.1781, -7.9316],
      [110.1776, -7.9321],
      [110.1764, -7.9326],
      [110.1752, -7.9329],
      [110.1738, -7.9331],
      [110.1724, -7.9333],
      [110.1707, -7.9336],
      [110.1706, -7.9336],
      [110.1706, -7.9338],
      [110.1706, -7.9351],
      [110.1707, -7.9361],
      [110.1715, -7.936],
      [110.1726, -7.9359],
      [110.1735, -7.936],
      [110.1737, -7.9365],
      [110.174, -7.9365],
      [110.1748, -7.937],
      [110.1754, -7.9372],
      [110.1751, -7.9381],
      [110.175, -7.9384],
      [110.1767, -7.9389],
      [110.181, -7.94],
      [110.1817, -7.9402],
      [110.1819, -7.9402],
      [110.1826, -7.9428],
      [110.1831, -7.9442],
      [110.184, -7.9451],
      [110.1845, -7.9456],
      [110.1847, -7.9458],
      [110.1855, -7.9463],
      [110.1869, -7.9471],
      [110.1884, -7.948],
      [110.1887, -7.9479],
      [110.1897, -7.9459],
      [110.1903, -7.9461],
      [110.1905, -7.946],
      [110.191, -7.9459],
      [110.1924, -7.9457],
      [110.194, -7.9455],
      [110.1954, -7.9453],
      [110.1958, -7.9452],
      [110.1964, -7.9452],
      [110.1969, -7.9452],
      [110.197, -7.9448],
      [110.1966, -7.9443],
      [110.1964, -7.9439],
      [110.1965, -7.9438],
      [110.1974, -7.9435],
      [110.1986, -7.9432],
      [110.199, -7.9432],
      [110.199, -7.9436],
      [110.1987, -7.9443],
      [110.1987, -7.9448],
      [110.2001, -7.945],
      [110.2013, -7.9454],
      [110.2019, -7.9452],
      [110.2018, -7.9444],
      [110.202, -7.9435],
      [110.2021, -7.9433],
      [110.2024, -7.9427],
      [110.2023, -7.9421],
      [110.2022, -7.9413],
      [110.2022, -7.94],
      [110.203, -7.9399],
      [110.2038, -7.9402],
      [110.2044, -7.9406],
      [110.2051, -7.9406],
      [110.2052, -7.9405],
      [110.2055, -7.9403],
      [110.2059, -7.94],
      [110.206, -7.9395],
      [110.2057, -7.939],
      [110.2057, -7.9384],
      [110.2065, -7.9384],
      [110.2072, -7.9385],
      [110.2077, -7.9383],
      [110.2083, -7.9383],
      [110.2087, -7.9381],
      [110.2087, -7.938],
      [110.209, -7.9363],
      [110.209, -7.9359],
      [110.2091, -7.9353],
      [110.2093, -7.9347],
      [110.2092, -7.9342],
      [110.2088, -7.9341],
      [110.208, -7.9343],
      [110.2075, -7.9341],
      [110.207, -7.9335],
      [110.2063, -7.9335],
      [110.2059, -7.9337],
      [110.2058, -7.9339],
      [110.2058, -7.9341],
      [110.2058, -7.9346],
      [110.2058, -7.9347],
      [110.2056, -7.9351],
      [110.2057, -7.9356],
      [110.2056, -7.9359],
      [110.2056, -7.9364],
      [110.2051, -7.9364],
      [110.205, -7.9366],
      [110.2048, -7.9367],
      [110.2044, -7.9367],
      [110.2047, -7.9362],
      [110.2047, -7.9356],
      [110.2047, -7.9354],
      [110.2044, -7.9349],
      [110.2043, -7.9344],
      [110.2046, -7.9336],
      [110.2046, -7.9328],
      [110.2047, -7.9323],
      [110.2053, -7.9318],
      [110.2059, -7.9321],
      [110.2062, -7.9321],
      [110.2064, -7.9321],
      [110.2067, -7.932],
      [110.2072, -7.9321],
      [110.2075, -7.9316],
      [110.2076, -7.9315],
      [110.2078, -7.9312],
      [110.2079, -7.931],
      [110.2079, -7.9305],
      [110.2079, -7.9304],
      [110.2079, -7.9302],
      [110.2068, -7.93],
      [110.2056, -7.9299],
      [110.2046, -7.93],
      [110.2027, -7.9298],
      [110.2009, -7.9296],
      [110.1992, -7.9297],
      [110.198, -7.9296],
      [110.1967, -7.9299],
      [110.1944, -7.9301],
      [110.1939, -7.9302],
      [110.1935, -7.9303],
      [110.1934, -7.9304],
      [110.1916, -7.9336],
      [110.1871, -7.9335],
      [110.1849, -7.9335],
      [110.1843, -7.9302],
      [110.184, -7.9293],
      [110.1835, -7.9292],
      [110.1831, -7.929],
      [110.1821, -7.9291],
      [110.1819, -7.9278],
      [110.1818, -7.9276],
      [110.1816, -7.9269],
      [110.1817, -7.9267],
      [110.1823, -7.926],
      [110.1824, -7.9256],
      [110.1824, -7.9249],
      [110.1826, -7.9243],
      [110.1829, -7.9238],
      [110.1832, -7.9232],
      [110.1834, -7.9227],
      [110.1837, -7.9219],
      [110.1838, -7.9211],
      [110.1839, -7.9209],
      [110.1835, -7.9204]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GALUR',
    village: 'PANDOWAN',
    border: [
      [110.2118, -7.9316],
      [110.2105, -7.9312],
      [110.2093, -7.9308],
      [110.2088, -7.9305],
      [110.2084, -7.9302],
      [110.2079, -7.9302],
      [110.2079, -7.9304],
      [110.2079, -7.9305],
      [110.2079, -7.931],
      [110.2078, -7.9312],
      [110.2076, -7.9315],
      [110.2075, -7.9316],
      [110.2072, -7.9321],
      [110.2067, -7.932],
      [110.2064, -7.9321],
      [110.2062, -7.9321],
      [110.2059, -7.9321],
      [110.2053, -7.9318],
      [110.2047, -7.9323],
      [110.2046, -7.9328],
      [110.2046, -7.9336],
      [110.2043, -7.9344],
      [110.2044, -7.9349],
      [110.2047, -7.9354],
      [110.2047, -7.9356],
      [110.2047, -7.9362],
      [110.2044, -7.9367],
      [110.2048, -7.9367],
      [110.205, -7.9366],
      [110.2051, -7.9364],
      [110.2056, -7.9364],
      [110.2056, -7.9359],
      [110.2057, -7.9356],
      [110.2056, -7.9351],
      [110.2058, -7.9347],
      [110.2058, -7.9346],
      [110.2058, -7.9341],
      [110.2058, -7.9339],
      [110.2059, -7.9337],
      [110.2063, -7.9335],
      [110.207, -7.9335],
      [110.2075, -7.9341],
      [110.208, -7.9343],
      [110.2088, -7.9341],
      [110.2092, -7.9342],
      [110.2093, -7.9347],
      [110.2091, -7.9353],
      [110.209, -7.9359],
      [110.209, -7.9363],
      [110.2087, -7.938],
      [110.2087, -7.9381],
      [110.2083, -7.9383],
      [110.2077, -7.9383],
      [110.2072, -7.9385],
      [110.2065, -7.9384],
      [110.2057, -7.9384],
      [110.2057, -7.939],
      [110.206, -7.9395],
      [110.2059, -7.94],
      [110.2055, -7.9403],
      [110.2052, -7.9405],
      [110.2051, -7.9406],
      [110.2044, -7.9406],
      [110.2038, -7.9402],
      [110.203, -7.9399],
      [110.2022, -7.94],
      [110.2022, -7.9413],
      [110.2023, -7.9421],
      [110.2024, -7.9427],
      [110.2021, -7.9433],
      [110.2023, -7.9433],
      [110.203, -7.9432],
      [110.2038, -7.9432],
      [110.2044, -7.943],
      [110.2047, -7.9427],
      [110.2049, -7.9431],
      [110.2052, -7.9438],
      [110.2056, -7.9441],
      [110.2061, -7.9447],
      [110.2064, -7.9451],
      [110.2062, -7.9457],
      [110.206, -7.9461],
      [110.2066, -7.9464],
      [110.2075, -7.9463],
      [110.208, -7.9466],
      [110.2085, -7.9466],
      [110.2089, -7.9463],
      [110.2084, -7.9449],
      [110.2075, -7.9447],
      [110.208, -7.9423],
      [110.2096, -7.9425],
      [110.2104, -7.9426],
      [110.2108, -7.9423],
      [110.211, -7.9417],
      [110.2113, -7.9409],
      [110.212, -7.9408],
      [110.2126, -7.9407],
      [110.2129, -7.9408],
      [110.2134, -7.9412],
      [110.2137, -7.9419],
      [110.2141, -7.9426],
      [110.2146, -7.9428],
      [110.2155, -7.943],
      [110.2162, -7.9429],
      [110.2167, -7.943],
      [110.2171, -7.9434],
      [110.2174, -7.9439],
      [110.2173, -7.9444],
      [110.2171, -7.945],
      [110.2183, -7.9452],
      [110.2198, -7.9455],
      [110.2202, -7.9456],
      [110.2204, -7.9444],
      [110.2205, -7.9436],
      [110.2207, -7.9428],
      [110.2208, -7.9419],
      [110.2207, -7.9412],
      [110.2209, -7.9406],
      [110.2207, -7.9402],
      [110.2205, -7.9395],
      [110.22, -7.9395],
      [110.2187, -7.9395],
      [110.2179, -7.9398],
      [110.2169, -7.9396],
      [110.2158, -7.9394],
      [110.2137, -7.9385],
      [110.213, -7.9384],
      [110.2122, -7.9384],
      [110.2118, -7.9382],
      [110.2119, -7.9371],
      [110.2122, -7.9362],
      [110.212, -7.9361],
      [110.2114, -7.9359],
      [110.2114, -7.9356],
      [110.2115, -7.9349],
      [110.2117, -7.9342],
      [110.2117, -7.934],
      [110.212, -7.9339],
      [110.2123, -7.9337],
      [110.2126, -7.9329],
      [110.2127, -7.9323],
      [110.2128, -7.9321],
      [110.2118, -7.9316]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GALUR',
    village: 'BROSOT',
    border: [
      [110.2426, -7.9357],
      [110.242, -7.9355],
      [110.2406, -7.935],
      [110.2404, -7.9355],
      [110.2396, -7.9354],
      [110.2394, -7.9363],
      [110.2387, -7.9362],
      [110.2348, -7.9354],
      [110.2344, -7.9353],
      [110.2343, -7.9353],
      [110.2338, -7.9376],
      [110.2334, -7.9401],
      [110.2329, -7.9401],
      [110.2319, -7.9397],
      [110.2312, -7.9399],
      [110.2303, -7.9402],
      [110.2294, -7.9402],
      [110.2285, -7.9401],
      [110.2277, -7.9399],
      [110.2264, -7.9396],
      [110.2252, -7.9394],
      [110.225, -7.9389],
      [110.2244, -7.9379],
      [110.2238, -7.9373],
      [110.2234, -7.9372],
      [110.2227, -7.9369],
      [110.2223, -7.9368],
      [110.2211, -7.9365],
      [110.2209, -7.9364],
      [110.22, -7.9361],
      [110.2195, -7.9359],
      [110.2191, -7.9358],
      [110.2187, -7.9357],
      [110.2174, -7.9354],
      [110.2171, -7.9348],
      [110.2169, -7.934],
      [110.2165, -7.9337],
      [110.2149, -7.933],
      [110.2128, -7.9321],
      [110.2127, -7.9323],
      [110.2126, -7.9329],
      [110.2123, -7.9337],
      [110.212, -7.9339],
      [110.2117, -7.934],
      [110.2117, -7.9342],
      [110.2115, -7.9349],
      [110.2114, -7.9356],
      [110.2114, -7.9359],
      [110.212, -7.9361],
      [110.2122, -7.9362],
      [110.2119, -7.9371],
      [110.2118, -7.9382],
      [110.2122, -7.9384],
      [110.213, -7.9384],
      [110.2137, -7.9385],
      [110.2158, -7.9394],
      [110.2169, -7.9396],
      [110.2179, -7.9398],
      [110.2187, -7.9395],
      [110.22, -7.9395],
      [110.2205, -7.9395],
      [110.2207, -7.9402],
      [110.2209, -7.9406],
      [110.2207, -7.9412],
      [110.2208, -7.9419],
      [110.2207, -7.9428],
      [110.2205, -7.9436],
      [110.2204, -7.9444],
      [110.2202, -7.9456],
      [110.2207, -7.9457],
      [110.2211, -7.9458],
      [110.2217, -7.9459],
      [110.2219, -7.9459],
      [110.2222, -7.9459],
      [110.2247, -7.9464],
      [110.2252, -7.9465],
      [110.2252, -7.9467],
      [110.2278, -7.947],
      [110.2288, -7.9472],
      [110.2303, -7.9478],
      [110.2312, -7.9481],
      [110.2318, -7.9483],
      [110.2324, -7.9479],
      [110.2329, -7.9471],
      [110.2334, -7.9468],
      [110.2336, -7.9471],
      [110.2337, -7.9475],
      [110.2337, -7.9479],
      [110.2329, -7.9487],
      [110.2326, -7.9497],
      [110.2322, -7.9507],
      [110.232, -7.9514],
      [110.2329, -7.9515],
      [110.2329, -7.9516],
      [110.2337, -7.9517],
      [110.2347, -7.9519],
      [110.2353, -7.9523],
      [110.2355, -7.9519],
      [110.236, -7.9511],
      [110.2367, -7.9503],
      [110.2371, -7.9497],
      [110.2377, -7.9489],
      [110.2378, -7.9485],
      [110.2394, -7.946],
      [110.2409, -7.9431],
      [110.2416, -7.9415],
      [110.2419, -7.9405],
      [110.2421, -7.9398],
      [110.2423, -7.9392],
      [110.2427, -7.9382],
      [110.243, -7.9373],
      [110.2432, -7.9366],
      [110.2434, -7.936],
      [110.2426, -7.9357]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'LENDAH',
    village: 'WAHYUHARJO',
    border: [
      [110.1944, -7.9301],
      [110.1948, -7.9278],
      [110.1955, -7.9228],
      [110.1946, -7.9227],
      [110.1942, -7.9228],
      [110.1939, -7.9226],
      [110.1937, -7.9226],
      [110.1936, -7.9225],
      [110.1929, -7.9221],
      [110.1926, -7.9222],
      [110.1924, -7.9221],
      [110.1922, -7.9218],
      [110.1914, -7.922],
      [110.1907, -7.9222],
      [110.1903, -7.9219],
      [110.1907, -7.9208],
      [110.1908, -7.9201],
      [110.1873, -7.92],
      [110.1857, -7.92],
      [110.1858, -7.9175],
      [110.1852, -7.9173],
      [110.1848, -7.9174],
      [110.1843, -7.9174],
      [110.1842, -7.9178],
      [110.1839, -7.9195],
      [110.1835, -7.9204],
      [110.1839, -7.9209],
      [110.1838, -7.9211],
      [110.1837, -7.9219],
      [110.1834, -7.9227],
      [110.1832, -7.9232],
      [110.1829, -7.9238],
      [110.1826, -7.9243],
      [110.1824, -7.9249],
      [110.1824, -7.9256],
      [110.1823, -7.926],
      [110.1817, -7.9267],
      [110.1816, -7.9269],
      [110.1818, -7.9276],
      [110.1819, -7.9278],
      [110.1821, -7.9291],
      [110.1831, -7.929],
      [110.1835, -7.9292],
      [110.184, -7.9293],
      [110.1843, -7.9302],
      [110.1849, -7.9335],
      [110.1871, -7.9335],
      [110.1916, -7.9336],
      [110.1934, -7.9304],
      [110.1935, -7.9303],
      [110.1939, -7.9302],
      [110.1944, -7.9301]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'LENDAH',
    village: 'BUMIREJO',
    border: [
      [110.218, -7.9043],
      [110.217, -7.904],
      [110.2172, -7.903],
      [110.2177, -7.9012],
      [110.2178, -7.9006],
      [110.2176, -7.9004],
      [110.2172, -7.9003],
      [110.217, -7.9007],
      [110.2165, -7.9008],
      [110.2159, -7.9007],
      [110.2151, -7.9007],
      [110.2147, -7.9003],
      [110.2139, -7.9002],
      [110.2134, -7.9003],
      [110.2128, -7.9003],
      [110.2126, -7.8999],
      [110.2123, -7.8997],
      [110.2115, -7.8995],
      [110.2115, -7.8993],
      [110.2116, -7.8985],
      [110.2111, -7.8979],
      [110.2103, -7.8973],
      [110.2094, -7.8969],
      [110.2083, -7.8966],
      [110.2084, -7.8962],
      [110.2082, -7.8958],
      [110.2077, -7.8955],
      [110.2061, -7.8954],
      [110.2046, -7.8952],
      [110.2045, -7.8947],
      [110.2042, -7.8945],
      [110.204, -7.8947],
      [110.2039, -7.8953],
      [110.2035, -7.8969],
      [110.2032, -7.8979],
      [110.2029, -7.9003],
      [110.1996, -7.9001],
      [110.1978, -7.9],
      [110.1944, -7.8998],
      [110.1945, -7.8992],
      [110.1943, -7.8991],
      [110.1943, -7.8983],
      [110.1932, -7.8982],
      [110.1929, -7.8986],
      [110.1926, -7.8997],
      [110.1922, -7.9004],
      [110.1916, -7.9015],
      [110.1917, -7.9027],
      [110.1898, -7.9026],
      [110.1885, -7.9025],
      [110.1884, -7.9026],
      [110.188, -7.9032],
      [110.1874, -7.9053],
      [110.1872, -7.9056],
      [110.1868, -7.907],
      [110.1862, -7.9086],
      [110.186, -7.9098],
      [110.1854, -7.9121],
      [110.1849, -7.9145],
      [110.1844, -7.9167],
      [110.1843, -7.9174],
      [110.1848, -7.9174],
      [110.1852, -7.9173],
      [110.1858, -7.9175],
      [110.1857, -7.92],
      [110.1873, -7.92],
      [110.1908, -7.9201],
      [110.1907, -7.9208],
      [110.1903, -7.9219],
      [110.1907, -7.9222],
      [110.1914, -7.922],
      [110.1922, -7.9218],
      [110.1924, -7.9221],
      [110.1926, -7.9222],
      [110.1929, -7.9221],
      [110.1936, -7.9225],
      [110.1937, -7.9226],
      [110.1939, -7.9226],
      [110.1942, -7.9228],
      [110.1946, -7.9227],
      [110.1955, -7.9228],
      [110.1948, -7.9278],
      [110.1944, -7.9301],
      [110.1967, -7.9299],
      [110.198, -7.9296],
      [110.1992, -7.9297],
      [110.2009, -7.9296],
      [110.2027, -7.9298],
      [110.2046, -7.93],
      [110.2056, -7.9299],
      [110.2068, -7.93],
      [110.2079, -7.9302],
      [110.2084, -7.9302],
      [110.2088, -7.9305],
      [110.2093, -7.9308],
      [110.2105, -7.9312],
      [110.2118, -7.9316],
      [110.212, -7.9306],
      [110.212, -7.9302],
      [110.2116, -7.9297],
      [110.2113, -7.9293],
      [110.2104, -7.9289],
      [110.2099, -7.9285],
      [110.2094, -7.9281],
      [110.2094, -7.9279],
      [110.2101, -7.9282],
      [110.211, -7.9288],
      [110.2119, -7.9291],
      [110.2122, -7.9289],
      [110.2124, -7.9287],
      [110.2124, -7.9287],
      [110.2125, -7.9276],
      [110.2126, -7.9272],
      [110.2118, -7.9255],
      [110.2116, -7.9249],
      [110.2109, -7.9243],
      [110.2108, -7.9241],
      [110.2093, -7.9242],
      [110.2092, -7.9241],
      [110.2092, -7.9235],
      [110.2096, -7.9219],
      [110.2093, -7.921],
      [110.2101, -7.9187],
      [110.2105, -7.9178],
      [110.2106, -7.9161],
      [110.2095, -7.9159],
      [110.2093, -7.9155],
      [110.2079, -7.9149],
      [110.2083, -7.9145],
      [110.2094, -7.9129],
      [110.2101, -7.912],
      [110.2104, -7.9117],
      [110.2105, -7.9103],
      [110.2115, -7.91],
      [110.2129, -7.9095],
      [110.2136, -7.909],
      [110.214, -7.9086],
      [110.2142, -7.9083],
      [110.2142, -7.9075],
      [110.2154, -7.9066],
      [110.2166, -7.9055],
      [110.218, -7.9043]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'LENDAH',
    village: 'JATIREJO',
    border: [
      [110.2233, -7.9067],
      [110.2229, -7.9065],
      [110.2228, -7.9062],
      [110.2225, -7.9062],
      [110.2221, -7.906],
      [110.2216, -7.9058],
      [110.2211, -7.9059],
      [110.2207, -7.9056],
      [110.2199, -7.9057],
      [110.2193, -7.9057],
      [110.2183, -7.9058],
      [110.2178, -7.9059],
      [110.218, -7.9043],
      [110.2166, -7.9055],
      [110.2154, -7.9066],
      [110.2142, -7.9075],
      [110.2142, -7.9083],
      [110.214, -7.9086],
      [110.2136, -7.909],
      [110.2129, -7.9095],
      [110.2115, -7.91],
      [110.2105, -7.9103],
      [110.2104, -7.9117],
      [110.2101, -7.912],
      [110.2094, -7.9129],
      [110.2083, -7.9145],
      [110.2079, -7.9149],
      [110.2093, -7.9155],
      [110.2095, -7.9159],
      [110.2106, -7.9161],
      [110.2105, -7.9178],
      [110.2101, -7.9187],
      [110.2093, -7.921],
      [110.2096, -7.9219],
      [110.2092, -7.9235],
      [110.2092, -7.9241],
      [110.2093, -7.9242],
      [110.2108, -7.9241],
      [110.2109, -7.9243],
      [110.2116, -7.9249],
      [110.2118, -7.9255],
      [110.2126, -7.9272],
      [110.2125, -7.9276],
      [110.2124, -7.9287],
      [110.2124, -7.9287],
      [110.2122, -7.9289],
      [110.2119, -7.9291],
      [110.211, -7.9288],
      [110.2101, -7.9282],
      [110.2094, -7.9279],
      [110.2094, -7.9281],
      [110.2099, -7.9285],
      [110.2104, -7.9289],
      [110.2113, -7.9293],
      [110.2116, -7.9297],
      [110.212, -7.9302],
      [110.212, -7.9306],
      [110.2118, -7.9316],
      [110.2128, -7.9321],
      [110.2149, -7.933],
      [110.2165, -7.9337],
      [110.2169, -7.934],
      [110.2171, -7.9348],
      [110.2174, -7.9354],
      [110.2187, -7.9357],
      [110.2191, -7.9358],
      [110.2195, -7.9359],
      [110.22, -7.9361],
      [110.2209, -7.9364],
      [110.2211, -7.9365],
      [110.2223, -7.9368],
      [110.2227, -7.9369],
      [110.2234, -7.9372],
      [110.2238, -7.9373],
      [110.2244, -7.9379],
      [110.225, -7.9389],
      [110.2252, -7.9394],
      [110.2264, -7.9396],
      [110.2277, -7.9399],
      [110.2285, -7.9401],
      [110.2294, -7.9402],
      [110.2303, -7.9402],
      [110.2312, -7.9399],
      [110.2319, -7.9397],
      [110.2329, -7.9401],
      [110.2334, -7.9401],
      [110.2338, -7.9376],
      [110.2343, -7.9353],
      [110.2344, -7.9353],
      [110.2348, -7.9354],
      [110.2387, -7.9362],
      [110.2394, -7.9363],
      [110.2396, -7.9354],
      [110.2404, -7.9355],
      [110.2406, -7.935],
      [110.242, -7.9355],
      [110.2426, -7.9357],
      [110.2434, -7.936],
      [110.2435, -7.9353],
      [110.2438, -7.9344],
      [110.2447, -7.9324],
      [110.2452, -7.9317],
      [110.2453, -7.9316],
      [110.2448, -7.9314],
      [110.2442, -7.9313],
      [110.2431, -7.9311],
      [110.2419, -7.9311],
      [110.2419, -7.9316],
      [110.2415, -7.9319],
      [110.2408, -7.9319],
      [110.2407, -7.9324],
      [110.2399, -7.9324],
      [110.2388, -7.9325],
      [110.2387, -7.9317],
      [110.2386, -7.9316],
      [110.2384, -7.9313],
      [110.2377, -7.9309],
      [110.2371, -7.9307],
      [110.2367, -7.9307],
      [110.2363, -7.9303],
      [110.2361, -7.9293],
      [110.2357, -7.929],
      [110.235, -7.9288],
      [110.2341, -7.9285],
      [110.2333, -7.928],
      [110.2327, -7.9277],
      [110.2325, -7.9272],
      [110.2323, -7.9266],
      [110.2309, -7.9253],
      [110.2299, -7.9253],
      [110.2289, -7.9253],
      [110.2266, -7.9249],
      [110.2255, -7.9246],
      [110.2233, -7.9236],
      [110.222, -7.9228],
      [110.2214, -7.9225],
      [110.221, -7.9219],
      [110.2206, -7.9213],
      [110.2204, -7.9201],
      [110.2201, -7.9189],
      [110.22, -7.9182],
      [110.2199, -7.9177],
      [110.2205, -7.9167],
      [110.2208, -7.916],
      [110.2209, -7.9153],
      [110.221, -7.9145],
      [110.2213, -7.9131],
      [110.2218, -7.9112],
      [110.2221, -7.9099],
      [110.2226, -7.9087],
      [110.2233, -7.9074],
      [110.2234, -7.9071],
      [110.2233, -7.9067]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'LENDAH',
    village: 'SIDOREJO',
    border: [
      [110.2357, -7.9035],
      [110.2338, -7.9024],
      [110.2337, -7.9036],
      [110.2334, -7.9047],
      [110.2332, -7.9047],
      [110.2323, -7.9045],
      [110.2317, -7.9044],
      [110.2314, -7.905],
      [110.2314, -7.9054],
      [110.2308, -7.9051],
      [110.2302, -7.9051],
      [110.2294, -7.905],
      [110.2287, -7.9046],
      [110.2283, -7.9054],
      [110.2282, -7.9061],
      [110.228, -7.9068],
      [110.2278, -7.9072],
      [110.2273, -7.9072],
      [110.227, -7.907],
      [110.2266, -7.9068],
      [110.2262, -7.9064],
      [110.2258, -7.9064],
      [110.2252, -7.9064],
      [110.2238, -7.9061],
      [110.2235, -7.9065],
      [110.2233, -7.9067],
      [110.2234, -7.9071],
      [110.2233, -7.9074],
      [110.2226, -7.9087],
      [110.2221, -7.9099],
      [110.2218, -7.9112],
      [110.2213, -7.9131],
      [110.221, -7.9145],
      [110.2209, -7.9153],
      [110.2208, -7.916],
      [110.2205, -7.9167],
      [110.2199, -7.9177],
      [110.22, -7.9182],
      [110.2201, -7.9189],
      [110.2204, -7.9201],
      [110.2206, -7.9213],
      [110.221, -7.9219],
      [110.2214, -7.9225],
      [110.222, -7.9228],
      [110.2233, -7.9236],
      [110.2255, -7.9246],
      [110.2266, -7.9249],
      [110.2289, -7.9253],
      [110.2299, -7.9253],
      [110.2309, -7.9253],
      [110.2323, -7.9266],
      [110.2325, -7.9272],
      [110.2327, -7.9277],
      [110.2333, -7.928],
      [110.2341, -7.9285],
      [110.235, -7.9288],
      [110.2357, -7.929],
      [110.2361, -7.9293],
      [110.2363, -7.9303],
      [110.2367, -7.9307],
      [110.2371, -7.9307],
      [110.2377, -7.9309],
      [110.2384, -7.9313],
      [110.2386, -7.9316],
      [110.2387, -7.9317],
      [110.2388, -7.9325],
      [110.2399, -7.9324],
      [110.2407, -7.9324],
      [110.2408, -7.9319],
      [110.2415, -7.9319],
      [110.2419, -7.9316],
      [110.2419, -7.9311],
      [110.2431, -7.9311],
      [110.2442, -7.9313],
      [110.2448, -7.9314],
      [110.2453, -7.9316],
      [110.2457, -7.9309],
      [110.2464, -7.9294],
      [110.2468, -7.9288],
      [110.2476, -7.928],
      [110.2482, -7.9275],
      [110.2487, -7.9274],
      [110.2495, -7.9272],
      [110.2505, -7.9262],
      [110.252, -7.925],
      [110.2536, -7.9243],
      [110.2553, -7.9235],
      [110.2553, -7.9228],
      [110.2553, -7.9227],
      [110.255, -7.9225],
      [110.2558, -7.9218],
      [110.2558, -7.9196],
      [110.2555, -7.9181],
      [110.2555, -7.9169],
      [110.2553, -7.9161],
      [110.255, -7.9149],
      [110.2551, -7.9134],
      [110.2553, -7.9122],
      [110.2545, -7.9118],
      [110.2532, -7.9114],
      [110.2521, -7.9113],
      [110.2508, -7.9111],
      [110.2505, -7.9109],
      [110.2497, -7.9099],
      [110.2484, -7.909],
      [110.2473, -7.9085],
      [110.2463, -7.9081],
      [110.2457, -7.9079],
      [110.2441, -7.9076],
      [110.2437, -7.907],
      [110.2425, -7.9065],
      [110.2413, -7.9062],
      [110.2408, -7.906],
      [110.2403, -7.9053],
      [110.239, -7.9045],
      [110.2383, -7.9041],
      [110.2372, -7.9041],
      [110.2367, -7.904],
      [110.2357, -7.9035]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'LENDAH',
    village: 'GULUREJO',
    border: [
      [110.2731, -7.9127],
      [110.2728, -7.9126],
      [110.2725, -7.9125],
      [110.2712, -7.912],
      [110.2701, -7.9117],
      [110.2691, -7.9114],
      [110.2689, -7.9114],
      [110.2679, -7.9112],
      [110.2669, -7.9116],
      [110.2658, -7.9118],
      [110.2636, -7.9118],
      [110.264, -7.91],
      [110.2636, -7.9099],
      [110.264, -7.908],
      [110.2641, -7.9076],
      [110.2639, -7.9072],
      [110.2607, -7.9064],
      [110.2593, -7.906],
      [110.2577, -7.9054],
      [110.257, -7.9051],
      [110.2564, -7.9048],
      [110.2563, -7.9046],
      [110.2556, -7.9046],
      [110.255, -7.9053],
      [110.2548, -7.9057],
      [110.2544, -7.9062],
      [110.2542, -7.9064],
      [110.254, -7.9071],
      [110.2531, -7.9069],
      [110.2523, -7.9067],
      [110.2523, -7.9064],
      [110.2523, -7.9061],
      [110.252, -7.9058],
      [110.2518, -7.9055],
      [110.2519, -7.905],
      [110.2511, -7.9042],
      [110.2506, -7.9035],
      [110.2499, -7.9033],
      [110.2497, -7.9031],
      [110.2496, -7.903],
      [110.2491, -7.902],
      [110.2487, -7.9017],
      [110.2479, -7.9009],
      [110.2475, -7.9006],
      [110.247, -7.9002],
      [110.2467, -7.8997],
      [110.246, -7.899],
      [110.2464, -7.8985],
      [110.2454, -7.8972],
      [110.2453, -7.8968],
      [110.2436, -7.8961],
      [110.2432, -7.896],
      [110.2432, -7.8956],
      [110.2417, -7.8954],
      [110.2406, -7.8952],
      [110.2402, -7.8951],
      [110.24, -7.8951],
      [110.2399, -7.8951],
      [110.2392, -7.8949],
      [110.239, -7.8952],
      [110.2388, -7.8954],
      [110.2385, -7.8959],
      [110.238, -7.8972],
      [110.2379, -7.8978],
      [110.2379, -7.8982],
      [110.2383, -7.8986],
      [110.239, -7.8989],
      [110.2391, -7.8994],
      [110.2396, -7.8997],
      [110.2402, -7.9001],
      [110.2407, -7.9005],
      [110.2408, -7.901],
      [110.2406, -7.9013],
      [110.2402, -7.9013],
      [110.2398, -7.9011],
      [110.2395, -7.9011],
      [110.2392, -7.9011],
      [110.2387, -7.9008],
      [110.2381, -7.9009],
      [110.2372, -7.9012],
      [110.2362, -7.9015],
      [110.2362, -7.9016],
      [110.2357, -7.9035],
      [110.2367, -7.904],
      [110.2372, -7.9041],
      [110.2383, -7.9041],
      [110.239, -7.9045],
      [110.2403, -7.9053],
      [110.2408, -7.906],
      [110.2413, -7.9062],
      [110.2425, -7.9065],
      [110.2437, -7.907],
      [110.2441, -7.9076],
      [110.2457, -7.9079],
      [110.2463, -7.9081],
      [110.2473, -7.9085],
      [110.2484, -7.909],
      [110.2497, -7.9099],
      [110.2505, -7.9109],
      [110.2508, -7.9111],
      [110.2521, -7.9113],
      [110.2532, -7.9114],
      [110.2545, -7.9118],
      [110.2553, -7.9122],
      [110.2551, -7.9134],
      [110.255, -7.9149],
      [110.2553, -7.9161],
      [110.2555, -7.9169],
      [110.2555, -7.9181],
      [110.2558, -7.9196],
      [110.2558, -7.9218],
      [110.255, -7.9225],
      [110.2553, -7.9227],
      [110.2553, -7.9228],
      [110.2553, -7.9235],
      [110.256, -7.9236],
      [110.2574, -7.9241],
      [110.2577, -7.9244],
      [110.2597, -7.926],
      [110.2631, -7.9289],
      [110.2639, -7.929],
      [110.2653, -7.9291],
      [110.2663, -7.9288],
      [110.2671, -7.9285],
      [110.268, -7.9279],
      [110.2692, -7.9267],
      [110.2697, -7.9259],
      [110.2702, -7.9251],
      [110.2708, -7.924],
      [110.2712, -7.9221],
      [110.2718, -7.9194],
      [110.2722, -7.9178],
      [110.2726, -7.9153],
      [110.2731, -7.9127]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'LENDAH',
    village: 'NGENTAKREJO',
    border: [
      [110.2737, -7.9096],
      [110.2737, -7.9094],
      [110.2737, -7.9078],
      [110.2736, -7.9061],
      [110.2739, -7.9041],
      [110.2741, -7.9009],
      [110.2741, -7.9005],
      [110.2739, -7.8985],
      [110.2735, -7.8962],
      [110.273, -7.8928],
      [110.2727, -7.8914],
      [110.2719, -7.8896],
      [110.2704, -7.8873],
      [110.2692, -7.8858],
      [110.2687, -7.8852],
      [110.2686, -7.8855],
      [110.268, -7.8861],
      [110.2676, -7.8863],
      [110.2674, -7.8863],
      [110.2664, -7.8866],
      [110.2647, -7.8868],
      [110.2631, -7.887],
      [110.2616, -7.8875],
      [110.2605, -7.888],
      [110.2602, -7.8878],
      [110.2597, -7.8884],
      [110.2592, -7.8881],
      [110.259, -7.8877],
      [110.2584, -7.8867],
      [110.2575, -7.8864],
      [110.2563, -7.8861],
      [110.255, -7.8858],
      [110.2545, -7.8862],
      [110.254, -7.8865],
      [110.2534, -7.887],
      [110.2529, -7.8875],
      [110.2528, -7.8879],
      [110.2522, -7.8902],
      [110.2516, -7.8921],
      [110.2513, -7.8926],
      [110.2505, -7.8924],
      [110.2498, -7.8923],
      [110.2492, -7.8925],
      [110.2485, -7.8925],
      [110.2479, -7.8923],
      [110.2472, -7.8919],
      [110.2468, -7.892],
      [110.2458, -7.892],
      [110.2441, -7.8918],
      [110.2432, -7.8918],
      [110.243, -7.8926],
      [110.2426, -7.8928],
      [110.242, -7.8928],
      [110.2414, -7.8926],
      [110.2413, -7.8924],
      [110.2411, -7.8922],
      [110.241, -7.8921],
      [110.2405, -7.8916],
      [110.24, -7.8912],
      [110.2398, -7.8917],
      [110.2395, -7.8925],
      [110.2391, -7.8933],
      [110.2389, -7.8939],
      [110.2386, -7.8941],
      [110.2388, -7.8945],
      [110.2392, -7.8949],
      [110.2399, -7.8951],
      [110.24, -7.8951],
      [110.2402, -7.8951],
      [110.2406, -7.8952],
      [110.2417, -7.8954],
      [110.2432, -7.8956],
      [110.2432, -7.896],
      [110.2436, -7.8961],
      [110.2453, -7.8968],
      [110.2454, -7.8972],
      [110.2464, -7.8985],
      [110.246, -7.899],
      [110.2467, -7.8997],
      [110.247, -7.9002],
      [110.2475, -7.9006],
      [110.2479, -7.9009],
      [110.2487, -7.9017],
      [110.2491, -7.902],
      [110.2496, -7.903],
      [110.2497, -7.9031],
      [110.2499, -7.9033],
      [110.2506, -7.9035],
      [110.2511, -7.9042],
      [110.2519, -7.905],
      [110.2518, -7.9055],
      [110.252, -7.9058],
      [110.2523, -7.9061],
      [110.2523, -7.9064],
      [110.2523, -7.9067],
      [110.2531, -7.9069],
      [110.254, -7.9071],
      [110.2542, -7.9064],
      [110.2544, -7.9062],
      [110.2548, -7.9057],
      [110.255, -7.9053],
      [110.2556, -7.9046],
      [110.2563, -7.9046],
      [110.2564, -7.9048],
      [110.257, -7.9051],
      [110.2577, -7.9054],
      [110.2593, -7.906],
      [110.2607, -7.9064],
      [110.2639, -7.9072],
      [110.2641, -7.9076],
      [110.264, -7.908],
      [110.2636, -7.9099],
      [110.264, -7.91],
      [110.2636, -7.9118],
      [110.2658, -7.9118],
      [110.2669, -7.9116],
      [110.2679, -7.9112],
      [110.2689, -7.9114],
      [110.2691, -7.9114],
      [110.2701, -7.9117],
      [110.2712, -7.912],
      [110.2725, -7.9125],
      [110.2728, -7.9126],
      [110.2731, -7.9127],
      [110.2733, -7.9117],
      [110.2735, -7.9108],
      [110.2736, -7.91],
      [110.2737, -7.9096]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'DEMANGREJO',
    border: [
      [110.2066, -7.8768],
      [110.2065, -7.877],
      [110.2049, -7.8761],
      [110.2032, -7.8751],
      [110.2027, -7.8749],
      [110.2025, -7.8748],
      [110.2021, -7.8746],
      [110.2006, -7.8738],
      [110.199, -7.8731],
      [110.1979, -7.8728],
      [110.1974, -7.8735],
      [110.1971, -7.8742],
      [110.197, -7.8747],
      [110.1972, -7.8753],
      [110.1969, -7.876],
      [110.1971, -7.876],
      [110.1979, -7.8764],
      [110.1981, -7.8766],
      [110.1973, -7.879],
      [110.1969, -7.8803],
      [110.1965, -7.8811],
      [110.1954, -7.8809],
      [110.1952, -7.8812],
      [110.1946, -7.8815],
      [110.1946, -7.8816],
      [110.1947, -7.882],
      [110.1949, -7.8826],
      [110.1946, -7.8837],
      [110.194, -7.8858],
      [110.1939, -7.8863],
      [110.1944, -7.887],
      [110.1948, -7.8875],
      [110.1948, -7.8885],
      [110.1944, -7.8912],
      [110.1942, -7.8925],
      [110.1939, -7.8929],
      [110.1934, -7.8931],
      [110.1932, -7.8934],
      [110.1931, -7.8937],
      [110.1931, -7.8945],
      [110.1929, -7.8955],
      [110.1928, -7.8958],
      [110.1928, -7.8962],
      [110.1919, -7.8975],
      [110.1908, -7.8992],
      [110.1905, -7.8995],
      [110.1897, -7.9008],
      [110.1886, -7.9023],
      [110.1885, -7.9025],
      [110.1898, -7.9026],
      [110.1917, -7.9027],
      [110.1916, -7.9015],
      [110.1922, -7.9004],
      [110.1926, -7.8997],
      [110.1929, -7.8986],
      [110.1932, -7.8982],
      [110.1943, -7.8983],
      [110.1943, -7.8991],
      [110.1945, -7.8992],
      [110.1944, -7.8998],
      [110.1978, -7.9],
      [110.1996, -7.9001],
      [110.2029, -7.9003],
      [110.2032, -7.8979],
      [110.2035, -7.8969],
      [110.2039, -7.8953],
      [110.204, -7.8947],
      [110.2042, -7.8945],
      [110.2045, -7.8931],
      [110.2048, -7.8912],
      [110.2051, -7.8888],
      [110.2052, -7.8862],
      [110.2053, -7.8853],
      [110.2057, -7.8834],
      [110.206, -7.8819],
      [110.2065, -7.8805],
      [110.2082, -7.8808],
      [110.2086, -7.8808],
      [110.2109, -7.8813],
      [110.2131, -7.8816],
      [110.2142, -7.8816],
      [110.2145, -7.8816],
      [110.2147, -7.8804],
      [110.2139, -7.8801],
      [110.214, -7.8795],
      [110.2139, -7.8792],
      [110.2135, -7.8791],
      [110.2133, -7.8792],
      [110.2127, -7.8793],
      [110.2122, -7.8791],
      [110.2113, -7.879],
      [110.2109, -7.879],
      [110.2105, -7.879],
      [110.2104, -7.8787],
      [110.2102, -7.8784],
      [110.2099, -7.878],
      [110.2097, -7.878],
      [110.2094, -7.878],
      [110.2092, -7.878],
      [110.2089, -7.878],
      [110.2086, -7.8777],
      [110.2082, -7.8779],
      [110.2079, -7.8778],
      [110.2078, -7.8777],
      [110.2077, -7.8776],
      [110.2075, -7.8774],
      [110.2073, -7.8771],
      [110.2072, -7.8768],
      [110.2071, -7.8767],
      [110.207, -7.8767],
      [110.2066, -7.8768]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'SRIKAYANGAN',
    border: [
      [110.2217, -7.8833],
      [110.2214, -7.8831],
      [110.2213, -7.883],
      [110.221, -7.8829],
      [110.2208, -7.8826],
      [110.2202, -7.8824],
      [110.2199, -7.8823],
      [110.2197, -7.8823],
      [110.2193, -7.8824],
      [110.219, -7.8823],
      [110.2187, -7.882],
      [110.2185, -7.8817],
      [110.2175, -7.8814],
      [110.2166, -7.881],
      [110.2165, -7.881],
      [110.2163, -7.8809],
      [110.216, -7.8808],
      [110.2151, -7.8804],
      [110.2148, -7.8804],
      [110.2147, -7.8804],
      [110.2145, -7.8816],
      [110.2142, -7.8816],
      [110.2131, -7.8816],
      [110.2109, -7.8813],
      [110.2086, -7.8808],
      [110.2082, -7.8808],
      [110.2065, -7.8805],
      [110.206, -7.8819],
      [110.2057, -7.8834],
      [110.2053, -7.8853],
      [110.2052, -7.8862],
      [110.2051, -7.8888],
      [110.2048, -7.8912],
      [110.2045, -7.8931],
      [110.2042, -7.8945],
      [110.2045, -7.8947],
      [110.2046, -7.8952],
      [110.2061, -7.8954],
      [110.2077, -7.8955],
      [110.2082, -7.8958],
      [110.2084, -7.8962],
      [110.2083, -7.8966],
      [110.2094, -7.8969],
      [110.2103, -7.8973],
      [110.2111, -7.8979],
      [110.2116, -7.8985],
      [110.2115, -7.8993],
      [110.2115, -7.8995],
      [110.2123, -7.8997],
      [110.2126, -7.8999],
      [110.2128, -7.9003],
      [110.2134, -7.9003],
      [110.2139, -7.9002],
      [110.2147, -7.9003],
      [110.2151, -7.9007],
      [110.2159, -7.9007],
      [110.2165, -7.9008],
      [110.217, -7.9007],
      [110.2172, -7.9003],
      [110.2176, -7.9004],
      [110.2178, -7.9006],
      [110.2177, -7.9012],
      [110.2172, -7.903],
      [110.217, -7.904],
      [110.218, -7.9043],
      [110.2178, -7.9059],
      [110.2183, -7.9058],
      [110.2193, -7.9057],
      [110.2199, -7.9057],
      [110.2207, -7.9056],
      [110.2211, -7.9059],
      [110.2216, -7.9058],
      [110.2221, -7.906],
      [110.2225, -7.9062],
      [110.2228, -7.9062],
      [110.2229, -7.9065],
      [110.2233, -7.9067],
      [110.2235, -7.9065],
      [110.2238, -7.9061],
      [110.2252, -7.9064],
      [110.2258, -7.9064],
      [110.2262, -7.9064],
      [110.2266, -7.9068],
      [110.227, -7.907],
      [110.2273, -7.9072],
      [110.2278, -7.9072],
      [110.228, -7.9068],
      [110.2282, -7.9061],
      [110.2283, -7.9054],
      [110.2287, -7.9046],
      [110.2294, -7.905],
      [110.2302, -7.9051],
      [110.2308, -7.9051],
      [110.2314, -7.9054],
      [110.2314, -7.905],
      [110.2317, -7.9044],
      [110.2323, -7.9045],
      [110.2332, -7.9047],
      [110.2334, -7.9047],
      [110.2337, -7.9036],
      [110.2338, -7.9024],
      [110.2357, -7.9035],
      [110.2362, -7.9016],
      [110.2362, -7.9015],
      [110.2372, -7.9012],
      [110.2381, -7.9009],
      [110.2387, -7.9008],
      [110.2392, -7.9011],
      [110.2395, -7.9011],
      [110.2398, -7.9011],
      [110.2402, -7.9013],
      [110.2406, -7.9013],
      [110.2408, -7.901],
      [110.2407, -7.9005],
      [110.2402, -7.9001],
      [110.2396, -7.8997],
      [110.2391, -7.8994],
      [110.239, -7.8989],
      [110.2383, -7.8986],
      [110.2379, -7.8982],
      [110.2379, -7.8978],
      [110.238, -7.8972],
      [110.2385, -7.8959],
      [110.2388, -7.8954],
      [110.239, -7.8952],
      [110.2392, -7.8949],
      [110.2388, -7.8945],
      [110.2386, -7.8941],
      [110.2385, -7.894],
      [110.2383, -7.8938],
      [110.238, -7.8936],
      [110.2378, -7.8931],
      [110.2378, -7.8924],
      [110.2376, -7.8918],
      [110.2374, -7.8915],
      [110.2371, -7.8914],
      [110.2369, -7.8914],
      [110.2363, -7.8906],
      [110.2358, -7.8897],
      [110.2358, -7.8896],
      [110.2357, -7.889],
      [110.2348, -7.8885],
      [110.2346, -7.8884],
      [110.2334, -7.8878],
      [110.2332, -7.8876],
      [110.233, -7.8874],
      [110.2328, -7.8873],
      [110.2325, -7.8872],
      [110.232, -7.8869],
      [110.2317, -7.8869],
      [110.231, -7.8868],
      [110.2308, -7.8863],
      [110.2307, -7.8863],
      [110.2303, -7.8863],
      [110.23, -7.8865],
      [110.2298, -7.8866],
      [110.2296, -7.8866],
      [110.2293, -7.8867],
      [110.2292, -7.8866],
      [110.2288, -7.8864],
      [110.2287, -7.8863],
      [110.2282, -7.8863],
      [110.2281, -7.8858],
      [110.228, -7.8857],
      [110.2276, -7.8855],
      [110.2273, -7.8853],
      [110.227, -7.8851],
      [110.2267, -7.885],
      [110.2266, -7.8849],
      [110.2265, -7.8849],
      [110.2264, -7.8849],
      [110.226, -7.8849],
      [110.2257, -7.885],
      [110.2254, -7.885],
      [110.225, -7.8849],
      [110.2246, -7.8848],
      [110.2245, -7.8847],
      [110.2241, -7.8844],
      [110.224, -7.8843],
      [110.2237, -7.884],
      [110.2233, -7.8835],
      [110.2227, -7.8834],
      [110.2221, -7.8835],
      [110.2218, -7.8834],
      [110.2217, -7.8833]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'TUKSONO',
    border: [
      [110.2564, -7.8658],
      [110.2563, -7.8656],
      [110.254, -7.864],
      [110.2505, -7.8626],
      [110.2499, -7.8623],
      [110.2494, -7.8617],
      [110.2483, -7.8608],
      [110.2476, -7.8604],
      [110.2469, -7.86],
      [110.2459, -7.8593],
      [110.2451, -7.8588],
      [110.2442, -7.858],
      [110.2436, -7.8573],
      [110.2427, -7.8568],
      [110.2424, -7.8566],
      [110.2421, -7.8569],
      [110.2415, -7.8573],
      [110.2414, -7.8574],
      [110.2408, -7.858],
      [110.2394, -7.8596],
      [110.2391, -7.86],
      [110.2388, -7.8612],
      [110.2383, -7.8611],
      [110.2381, -7.8618],
      [110.2377, -7.8625],
      [110.2376, -7.8634],
      [110.2375, -7.8642],
      [110.2371, -7.8648],
      [110.2366, -7.8665],
      [110.2361, -7.8677],
      [110.2357, -7.869],
      [110.2355, -7.8697],
      [110.2351, -7.8708],
      [110.2349, -7.8714],
      [110.2339, -7.8712],
      [110.2335, -7.8722],
      [110.2328, -7.8724],
      [110.2325, -7.8731],
      [110.2319, -7.8733],
      [110.2315, -7.8738],
      [110.2309, -7.875],
      [110.2306, -7.8753],
      [110.2302, -7.8754],
      [110.2301, -7.8756],
      [110.23, -7.8758],
      [110.2302, -7.876],
      [110.2304, -7.8761],
      [110.2304, -7.8768],
      [110.23, -7.8771],
      [110.2295, -7.8772],
      [110.229, -7.8773],
      [110.2287, -7.8772],
      [110.2284, -7.8771],
      [110.2275, -7.8771],
      [110.227, -7.8769],
      [110.2269, -7.8763],
      [110.2271, -7.8759],
      [110.2273, -7.8754],
      [110.2274, -7.875],
      [110.2275, -7.8744],
      [110.2268, -7.8745],
      [110.2262, -7.8744],
      [110.2259, -7.8746],
      [110.2257, -7.8748],
      [110.2258, -7.8754],
      [110.2257, -7.876],
      [110.2251, -7.8775],
      [110.2245, -7.8799],
      [110.2242, -7.8813],
      [110.2241, -7.882],
      [110.224, -7.8821],
      [110.2221, -7.8828],
      [110.2217, -7.8833],
      [110.2218, -7.8834],
      [110.2221, -7.8835],
      [110.2227, -7.8834],
      [110.2233, -7.8835],
      [110.2237, -7.884],
      [110.224, -7.8843],
      [110.2241, -7.8844],
      [110.2245, -7.8847],
      [110.2246, -7.8848],
      [110.225, -7.8849],
      [110.2254, -7.885],
      [110.2257, -7.885],
      [110.226, -7.8849],
      [110.2264, -7.8849],
      [110.2265, -7.8849],
      [110.2266, -7.8849],
      [110.2267, -7.885],
      [110.227, -7.8851],
      [110.2273, -7.8853],
      [110.2276, -7.8855],
      [110.228, -7.8857],
      [110.2281, -7.8858],
      [110.2282, -7.8863],
      [110.2287, -7.8863],
      [110.2288, -7.8864],
      [110.2292, -7.8866],
      [110.2293, -7.8867],
      [110.2296, -7.8866],
      [110.2298, -7.8866],
      [110.23, -7.8865],
      [110.2303, -7.8863],
      [110.2307, -7.8863],
      [110.2308, -7.8863],
      [110.231, -7.8868],
      [110.2317, -7.8869],
      [110.232, -7.8869],
      [110.2325, -7.8872],
      [110.2328, -7.8873],
      [110.233, -7.8874],
      [110.2332, -7.8876],
      [110.2334, -7.8878],
      [110.2346, -7.8884],
      [110.2348, -7.8885],
      [110.2357, -7.889],
      [110.2358, -7.8896],
      [110.2358, -7.8897],
      [110.2363, -7.8906],
      [110.2369, -7.8914],
      [110.2371, -7.8914],
      [110.2374, -7.8915],
      [110.2376, -7.8918],
      [110.2378, -7.8924],
      [110.2378, -7.8931],
      [110.238, -7.8936],
      [110.2383, -7.8938],
      [110.2385, -7.894],
      [110.2386, -7.8941],
      [110.2389, -7.8939],
      [110.2391, -7.8933],
      [110.2395, -7.8925],
      [110.2398, -7.8917],
      [110.24, -7.8912],
      [110.2405, -7.8916],
      [110.241, -7.8921],
      [110.2411, -7.8922],
      [110.2413, -7.8924],
      [110.2414, -7.8926],
      [110.242, -7.8928],
      [110.2426, -7.8928],
      [110.243, -7.8926],
      [110.2432, -7.8918],
      [110.2441, -7.8918],
      [110.2458, -7.892],
      [110.2468, -7.892],
      [110.2472, -7.8919],
      [110.2479, -7.8923],
      [110.2485, -7.8925],
      [110.2492, -7.8925],
      [110.2498, -7.8923],
      [110.2505, -7.8924],
      [110.2513, -7.8926],
      [110.2516, -7.8921],
      [110.2522, -7.8902],
      [110.2528, -7.8879],
      [110.2529, -7.8875],
      [110.2534, -7.887],
      [110.254, -7.8865],
      [110.2545, -7.8862],
      [110.255, -7.8858],
      [110.2563, -7.8861],
      [110.2575, -7.8864],
      [110.2584, -7.8867],
      [110.259, -7.8877],
      [110.2592, -7.8881],
      [110.2597, -7.8884],
      [110.2602, -7.8878],
      [110.2605, -7.888],
      [110.2616, -7.8875],
      [110.2631, -7.887],
      [110.2647, -7.8868],
      [110.2664, -7.8866],
      [110.2674, -7.8863],
      [110.2676, -7.8863],
      [110.268, -7.8861],
      [110.2686, -7.8855],
      [110.2687, -7.8852],
      [110.2682, -7.8844],
      [110.267, -7.8826],
      [110.2667, -7.8822],
      [110.2661, -7.8814],
      [110.2646, -7.8786],
      [110.2638, -7.8774],
      [110.2635, -7.8771],
      [110.2624, -7.8761],
      [110.2617, -7.875],
      [110.2609, -7.8737],
      [110.2588, -7.8708],
      [110.2581, -7.8686],
      [110.2571, -7.8665],
      [110.2564, -7.8658]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'SALAMREJO',
    border: [
      [110.2424, -7.8566],
      [110.2419, -7.8562],
      [110.2409, -7.8554],
      [110.2375, -7.853],
      [110.2357, -7.851],
      [110.2347, -7.8497],
      [110.234, -7.848],
      [110.2331, -7.8465],
      [110.2325, -7.8455],
      [110.2318, -7.8449],
      [110.2313, -7.8443],
      [110.2308, -7.8442],
      [110.2295, -7.8437],
      [110.2278, -7.8433],
      [110.2264, -7.8427],
      [110.2255, -7.842],
      [110.2246, -7.8413],
      [110.223, -7.8403],
      [110.2227, -7.8398],
      [110.2223, -7.84],
      [110.2219, -7.8401],
      [110.2218, -7.8401],
      [110.2213, -7.8402],
      [110.2206, -7.8402],
      [110.2198, -7.8404],
      [110.2194, -7.841],
      [110.2192, -7.8416],
      [110.2189, -7.8426],
      [110.2186, -7.8437],
      [110.2185, -7.8446],
      [110.218, -7.8448],
      [110.217, -7.8447],
      [110.2165, -7.8446],
      [110.2164, -7.845],
      [110.2165, -7.8463],
      [110.2165, -7.8474],
      [110.2171, -7.8475],
      [110.2174, -7.8477],
      [110.2178, -7.848],
      [110.2178, -7.8482],
      [110.2176, -7.8497],
      [110.2175, -7.8502],
      [110.2178, -7.8505],
      [110.2188, -7.8515],
      [110.2195, -7.8519],
      [110.2202, -7.8525],
      [110.2208, -7.8529],
      [110.2214, -7.8531],
      [110.2224, -7.8539],
      [110.2238, -7.8545],
      [110.2251, -7.8549],
      [110.226, -7.8551],
      [110.2276, -7.8561],
      [110.2291, -7.8569],
      [110.2307, -7.858],
      [110.2318, -7.8588],
      [110.2319, -7.8593],
      [110.2313, -7.862],
      [110.2308, -7.8646],
      [110.23, -7.8699],
      [110.2299, -7.8705],
      [110.2298, -7.8706],
      [110.2287, -7.8702],
      [110.2285, -7.8707],
      [110.229, -7.871],
      [110.2288, -7.8721],
      [110.2284, -7.8719],
      [110.2278, -7.8731],
      [110.2277, -7.8733],
      [110.2276, -7.8741],
      [110.2275, -7.8744],
      [110.2274, -7.875],
      [110.2273, -7.8754],
      [110.2271, -7.8759],
      [110.2269, -7.8763],
      [110.227, -7.8769],
      [110.2275, -7.8771],
      [110.2284, -7.8771],
      [110.2287, -7.8772],
      [110.229, -7.8773],
      [110.2295, -7.8772],
      [110.23, -7.8771],
      [110.2304, -7.8768],
      [110.2304, -7.8761],
      [110.2302, -7.876],
      [110.23, -7.8758],
      [110.2301, -7.8756],
      [110.2302, -7.8754],
      [110.2306, -7.8753],
      [110.2309, -7.875],
      [110.2315, -7.8738],
      [110.2319, -7.8733],
      [110.2325, -7.8731],
      [110.2328, -7.8724],
      [110.2335, -7.8722],
      [110.2339, -7.8712],
      [110.2349, -7.8714],
      [110.2351, -7.8708],
      [110.2355, -7.8697],
      [110.2357, -7.869],
      [110.2361, -7.8677],
      [110.2366, -7.8665],
      [110.2371, -7.8648],
      [110.2375, -7.8642],
      [110.2376, -7.8634],
      [110.2377, -7.8625],
      [110.2381, -7.8618],
      [110.2383, -7.8611],
      [110.2388, -7.8612],
      [110.2391, -7.86],
      [110.2394, -7.8596],
      [110.2408, -7.858],
      [110.2414, -7.8574],
      [110.2415, -7.8573],
      [110.2421, -7.8569],
      [110.2424, -7.8566]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'SUKORENO',
    border: [
      [110.2165, -7.8446],
      [110.2162, -7.8445],
      [110.2151, -7.8446],
      [110.2141, -7.8447],
      [110.2133, -7.8447],
      [110.2123, -7.8449],
      [110.2119, -7.8451],
      [110.2115, -7.845],
      [110.211, -7.845],
      [110.2107, -7.845],
      [110.2106, -7.8449],
      [110.2104, -7.8447],
      [110.2099, -7.8447],
      [110.2095, -7.844],
      [110.2091, -7.8435],
      [110.2085, -7.8427],
      [110.208, -7.8418],
      [110.2071, -7.8406],
      [110.2069, -7.8405],
      [110.2061, -7.841],
      [110.2057, -7.8414],
      [110.2051, -7.8419],
      [110.2048, -7.8425],
      [110.2042, -7.8431],
      [110.2042, -7.8435],
      [110.204, -7.8438],
      [110.2044, -7.8443],
      [110.2041, -7.8453],
      [110.2037, -7.8455],
      [110.2034, -7.846],
      [110.2032, -7.8464],
      [110.2033, -7.8467],
      [110.204, -7.8472],
      [110.2047, -7.8479],
      [110.2062, -7.8493],
      [110.2068, -7.8499],
      [110.2088, -7.852],
      [110.2082, -7.8531],
      [110.2075, -7.8542],
      [110.2072, -7.8546],
      [110.2069, -7.8545],
      [110.2066, -7.8546],
      [110.2056, -7.8545],
      [110.2055, -7.8547],
      [110.2045, -7.8556],
      [110.2036, -7.8554],
      [110.2033, -7.8557],
      [110.2027, -7.856],
      [110.2014, -7.8561],
      [110.2004, -7.8561],
      [110.2004, -7.8559],
      [110.2002, -7.8557],
      [110.1993, -7.8552],
      [110.1981, -7.8547],
      [110.1974, -7.8547],
      [110.1975, -7.8549],
      [110.197, -7.8551],
      [110.1968, -7.8553],
      [110.1964, -7.8557],
      [110.1962, -7.8565],
      [110.1959, -7.8571],
      [110.1959, -7.8576],
      [110.1963, -7.8582],
      [110.1977, -7.8594],
      [110.1986, -7.8603],
      [110.1989, -7.8615],
      [110.199, -7.8631],
      [110.1991, -7.864],
      [110.2, -7.8642],
      [110.201, -7.8645],
      [110.201, -7.8648],
      [110.2008, -7.8652],
      [110.2007, -7.8653],
      [110.2003, -7.8658],
      [110.1999, -7.8664],
      [110.1995, -7.8669],
      [110.1993, -7.8675],
      [110.1993, -7.8677],
      [110.1994, -7.8678],
      [110.1997, -7.868],
      [110.2001, -7.868],
      [110.2005, -7.8679],
      [110.2007, -7.868],
      [110.2008, -7.8681],
      [110.201, -7.8686],
      [110.2011, -7.8691],
      [110.2013, -7.8695],
      [110.2017, -7.8698],
      [110.2022, -7.87],
      [110.2023, -7.8704],
      [110.2024, -7.8707],
      [110.2028, -7.8711],
      [110.203, -7.8716],
      [110.2029, -7.8717],
      [110.2028, -7.8718],
      [110.2031, -7.8722],
      [110.2034, -7.8725],
      [110.2036, -7.8727],
      [110.2039, -7.8729],
      [110.2043, -7.8732],
      [110.2044, -7.8735],
      [110.2042, -7.8738],
      [110.2044, -7.8741],
      [110.2048, -7.8746],
      [110.2051, -7.875],
      [110.2051, -7.8754],
      [110.2059, -7.8755],
      [110.2059, -7.876],
      [110.2061, -7.8764],
      [110.2066, -7.8768],
      [110.207, -7.8767],
      [110.2071, -7.8767],
      [110.2072, -7.8768],
      [110.2073, -7.8771],
      [110.2075, -7.8774],
      [110.2077, -7.8776],
      [110.2078, -7.8777],
      [110.2079, -7.8778],
      [110.2082, -7.8779],
      [110.2086, -7.8777],
      [110.2089, -7.878],
      [110.2092, -7.878],
      [110.2094, -7.878],
      [110.2097, -7.878],
      [110.2099, -7.878],
      [110.2102, -7.8784],
      [110.2104, -7.8787],
      [110.2105, -7.879],
      [110.2109, -7.879],
      [110.2113, -7.879],
      [110.2122, -7.8791],
      [110.2127, -7.8793],
      [110.2133, -7.8792],
      [110.2135, -7.8791],
      [110.2139, -7.8792],
      [110.214, -7.8795],
      [110.2139, -7.8801],
      [110.2147, -7.8804],
      [110.2148, -7.8804],
      [110.2151, -7.8804],
      [110.216, -7.8808],
      [110.2163, -7.8809],
      [110.2165, -7.881],
      [110.2166, -7.881],
      [110.2175, -7.8814],
      [110.2185, -7.8817],
      [110.2187, -7.882],
      [110.219, -7.8823],
      [110.2193, -7.8824],
      [110.2197, -7.8823],
      [110.2199, -7.8823],
      [110.2202, -7.8824],
      [110.2208, -7.8826],
      [110.221, -7.8829],
      [110.2213, -7.883],
      [110.2214, -7.8831],
      [110.2217, -7.8833],
      [110.2221, -7.8828],
      [110.224, -7.8821],
      [110.2241, -7.882],
      [110.2242, -7.8813],
      [110.2245, -7.8799],
      [110.2251, -7.8775],
      [110.2257, -7.876],
      [110.2258, -7.8754],
      [110.2257, -7.8748],
      [110.2259, -7.8746],
      [110.2262, -7.8744],
      [110.2268, -7.8745],
      [110.2275, -7.8744],
      [110.2276, -7.8741],
      [110.2277, -7.8733],
      [110.2278, -7.8731],
      [110.2284, -7.8719],
      [110.2288, -7.8721],
      [110.229, -7.871],
      [110.2285, -7.8707],
      [110.2287, -7.8702],
      [110.2298, -7.8706],
      [110.2299, -7.8705],
      [110.23, -7.8699],
      [110.2308, -7.8646],
      [110.2313, -7.862],
      [110.2319, -7.8593],
      [110.2318, -7.8588],
      [110.2307, -7.858],
      [110.2291, -7.8569],
      [110.2276, -7.8561],
      [110.226, -7.8551],
      [110.2251, -7.8549],
      [110.2238, -7.8545],
      [110.2224, -7.8539],
      [110.2214, -7.8531],
      [110.2208, -7.8529],
      [110.2202, -7.8525],
      [110.2195, -7.8519],
      [110.2188, -7.8515],
      [110.2178, -7.8505],
      [110.2175, -7.8502],
      [110.2176, -7.8497],
      [110.2178, -7.8482],
      [110.2178, -7.848],
      [110.2174, -7.8477],
      [110.2171, -7.8475],
      [110.2165, -7.8474],
      [110.2165, -7.8463],
      [110.2164, -7.845],
      [110.2165, -7.8446]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'KALIAGUNG',
    border: [
      [110.2054, -7.8252],
      [110.2046, -7.825],
      [110.2041, -7.8246],
      [110.203, -7.8236],
      [110.202, -7.8229],
      [110.2019, -7.8222],
      [110.2014, -7.822],
      [110.2006, -7.822],
      [110.1999, -7.8223],
      [110.1994, -7.8227],
      [110.1987, -7.8233],
      [110.1982, -7.8234],
      [110.197, -7.8236],
      [110.1965, -7.8222],
      [110.1971, -7.8223],
      [110.1972, -7.8221],
      [110.1968, -7.8219],
      [110.1966, -7.8215],
      [110.1955, -7.8218],
      [110.1935, -7.8223],
      [110.1927, -7.8225],
      [110.1919, -7.8226],
      [110.1913, -7.8225],
      [110.1906, -7.8227],
      [110.1901, -7.823],
      [110.1897, -7.8234],
      [110.1893, -7.8239],
      [110.1892, -7.824],
      [110.1891, -7.8245],
      [110.1893, -7.8255],
      [110.1893, -7.826],
      [110.1891, -7.826],
      [110.1887, -7.826],
      [110.1882, -7.826],
      [110.1873, -7.8261],
      [110.1868, -7.826],
      [110.1861, -7.8257],
      [110.1851, -7.8255],
      [110.1847, -7.8251],
      [110.1837, -7.8252],
      [110.1823, -7.8253],
      [110.1823, -7.8258],
      [110.1821, -7.8262],
      [110.1819, -7.8264],
      [110.1816, -7.8269],
      [110.1813, -7.8275],
      [110.1817, -7.8281],
      [110.1818, -7.8285],
      [110.1817, -7.8289],
      [110.182, -7.8294],
      [110.1826, -7.8299],
      [110.1827, -7.8299],
      [110.1828, -7.831],
      [110.1826, -7.8315],
      [110.1824, -7.832],
      [110.1823, -7.8329],
      [110.1825, -7.8337],
      [110.1827, -7.834],
      [110.1833, -7.835],
      [110.1834, -7.8355],
      [110.1844, -7.8367],
      [110.1844, -7.8372],
      [110.1847, -7.8376],
      [110.1846, -7.8379],
      [110.1846, -7.839],
      [110.1847, -7.84],
      [110.1848, -7.8409],
      [110.1849, -7.8411],
      [110.1849, -7.8418],
      [110.1853, -7.8429],
      [110.1858, -7.844],
      [110.1862, -7.8448],
      [110.1875, -7.8466],
      [110.1876, -7.8471],
      [110.1876, -7.8479],
      [110.1877, -7.8488],
      [110.1878, -7.8499],
      [110.1879, -7.8505],
      [110.1879, -7.8509],
      [110.1884, -7.8512],
      [110.1895, -7.8522],
      [110.19, -7.8526],
      [110.191, -7.8526],
      [110.1933, -7.8522],
      [110.194, -7.8518],
      [110.1951, -7.8519],
      [110.1962, -7.852],
      [110.1963, -7.8522],
      [110.1968, -7.8532],
      [110.1973, -7.8539],
      [110.1974, -7.8547],
      [110.1981, -7.8547],
      [110.1993, -7.8552],
      [110.2002, -7.8557],
      [110.2004, -7.8559],
      [110.2004, -7.8561],
      [110.2014, -7.8561],
      [110.2027, -7.856],
      [110.2033, -7.8557],
      [110.2036, -7.8554],
      [110.2045, -7.8556],
      [110.2055, -7.8547],
      [110.2056, -7.8545],
      [110.2066, -7.8546],
      [110.2069, -7.8545],
      [110.2072, -7.8546],
      [110.2075, -7.8542],
      [110.2082, -7.8531],
      [110.2088, -7.852],
      [110.2068, -7.8499],
      [110.2062, -7.8493],
      [110.2047, -7.8479],
      [110.204, -7.8472],
      [110.2033, -7.8467],
      [110.2032, -7.8464],
      [110.2034, -7.846],
      [110.2037, -7.8455],
      [110.2041, -7.8453],
      [110.2044, -7.8443],
      [110.204, -7.8438],
      [110.2042, -7.8435],
      [110.2042, -7.8431],
      [110.2048, -7.8425],
      [110.2051, -7.8419],
      [110.2057, -7.8414],
      [110.2061, -7.841],
      [110.2069, -7.8405],
      [110.2068, -7.8401],
      [110.2064, -7.8396],
      [110.2059, -7.8393],
      [110.2052, -7.8389],
      [110.2051, -7.8386],
      [110.205, -7.8382],
      [110.2055, -7.8378],
      [110.2057, -7.8372],
      [110.2061, -7.8365],
      [110.2065, -7.8359],
      [110.2066, -7.8354],
      [110.2064, -7.8347],
      [110.2058, -7.8342],
      [110.2058, -7.8337],
      [110.2056, -7.8334],
      [110.2056, -7.833],
      [110.2054, -7.8327],
      [110.2055, -7.8322],
      [110.2055, -7.8317],
      [110.2054, -7.8311],
      [110.2054, -7.8304],
      [110.2054, -7.8299],
      [110.2055, -7.829],
      [110.2054, -7.8279],
      [110.2054, -7.8269],
      [110.2053, -7.8261],
      [110.2054, -7.8256],
      [110.2054, -7.8252]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'SENTOLO',
    border: [
      [110.2337, -7.8224],
      [110.2333, -7.8225],
      [110.2327, -7.8226],
      [110.2323, -7.8226],
      [110.2319, -7.8224],
      [110.231, -7.8225],
      [110.2307, -7.8219],
      [110.2302, -7.8218],
      [110.2284, -7.8216],
      [110.2263, -7.8215],
      [110.2254, -7.8214],
      [110.225, -7.8215],
      [110.2245, -7.8216],
      [110.2241, -7.8215],
      [110.2239, -7.8211],
      [110.2232, -7.8206],
      [110.2226, -7.8204],
      [110.2218, -7.8204],
      [110.2208, -7.8202],
      [110.2201, -7.8206],
      [110.2198, -7.8203],
      [110.2195, -7.8199],
      [110.2186, -7.8202],
      [110.218, -7.8203],
      [110.2176, -7.8205],
      [110.2173, -7.821],
      [110.2173, -7.822],
      [110.2176, -7.8229],
      [110.218, -7.8238],
      [110.2185, -7.8245],
      [110.2184, -7.8257],
      [110.2189, -7.8263],
      [110.2184, -7.8271],
      [110.2177, -7.8268],
      [110.217, -7.8266],
      [110.2162, -7.8265],
      [110.2155, -7.8264],
      [110.215, -7.826],
      [110.2143, -7.8256],
      [110.2137, -7.8253],
      [110.2131, -7.8251],
      [110.2124, -7.8246],
      [110.2118, -7.8242],
      [110.2111, -7.8242],
      [110.211, -7.8235],
      [110.21, -7.8229],
      [110.2086, -7.8224],
      [110.2084, -7.8222],
      [110.2072, -7.8221],
      [110.207, -7.8231],
      [110.2067, -7.8237],
      [110.2064, -7.824],
      [110.206, -7.8241],
      [110.2057, -7.8243],
      [110.2057, -7.8252],
      [110.2054, -7.8252],
      [110.2054, -7.8256],
      [110.2053, -7.8261],
      [110.2054, -7.8269],
      [110.2054, -7.8279],
      [110.2055, -7.829],
      [110.2054, -7.8299],
      [110.2054, -7.8304],
      [110.2054, -7.8311],
      [110.2055, -7.8317],
      [110.2055, -7.8322],
      [110.2054, -7.8327],
      [110.2056, -7.833],
      [110.2056, -7.8334],
      [110.2058, -7.8337],
      [110.2058, -7.8342],
      [110.2064, -7.8347],
      [110.2066, -7.8354],
      [110.2065, -7.8359],
      [110.2061, -7.8365],
      [110.2057, -7.8372],
      [110.2055, -7.8378],
      [110.205, -7.8382],
      [110.2051, -7.8386],
      [110.2052, -7.8389],
      [110.2059, -7.8393],
      [110.2064, -7.8396],
      [110.2068, -7.8401],
      [110.2069, -7.8405],
      [110.2071, -7.8406],
      [110.208, -7.8418],
      [110.2085, -7.8427],
      [110.2091, -7.8435],
      [110.2095, -7.844],
      [110.2099, -7.8447],
      [110.2104, -7.8447],
      [110.2106, -7.8449],
      [110.2107, -7.845],
      [110.211, -7.845],
      [110.2115, -7.845],
      [110.2119, -7.8451],
      [110.2123, -7.8449],
      [110.2133, -7.8447],
      [110.2141, -7.8447],
      [110.2151, -7.8446],
      [110.2162, -7.8445],
      [110.2165, -7.8446],
      [110.217, -7.8447],
      [110.218, -7.8448],
      [110.2185, -7.8446],
      [110.2186, -7.8437],
      [110.2189, -7.8426],
      [110.2192, -7.8416],
      [110.2194, -7.841],
      [110.2198, -7.8404],
      [110.2206, -7.8402],
      [110.2213, -7.8402],
      [110.2218, -7.8401],
      [110.2219, -7.8401],
      [110.2223, -7.84],
      [110.2227, -7.8398],
      [110.222, -7.8388],
      [110.2211, -7.8375],
      [110.221, -7.8367],
      [110.221, -7.8363],
      [110.2213, -7.8356],
      [110.2219, -7.8351],
      [110.2226, -7.8349],
      [110.2231, -7.8348],
      [110.2244, -7.8346],
      [110.2258, -7.8344],
      [110.2265, -7.8341],
      [110.2271, -7.8336],
      [110.2279, -7.8328],
      [110.2285, -7.8324],
      [110.229, -7.832],
      [110.2292, -7.8315],
      [110.2296, -7.8311],
      [110.23, -7.8307],
      [110.2307, -7.8301],
      [110.2312, -7.8293],
      [110.2319, -7.8282],
      [110.2324, -7.8268],
      [110.2329, -7.8257],
      [110.2333, -7.8244],
      [110.2335, -7.8238],
      [110.2337, -7.8231],
      [110.2337, -7.8226],
      [110.2337, -7.8224]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SENTOLO',
    village: 'BANGUNCIPTO',
    border: [
      [110.2242, -7.8048],
      [110.2238, -7.8048],
      [110.2223, -7.8044],
      [110.2213, -7.8041],
      [110.2212, -7.8043],
      [110.221, -7.8046],
      [110.2209, -7.8049],
      [110.2205, -7.8052],
      [110.2201, -7.8052],
      [110.2198, -7.8056],
      [110.2197, -7.8059],
      [110.2193, -7.8059],
      [110.2187, -7.8059],
      [110.2183, -7.8058],
      [110.2179, -7.8058],
      [110.2176, -7.8062],
      [110.2173, -7.8066],
      [110.217, -7.8068],
      [110.2166, -7.8076],
      [110.2164, -7.8078],
      [110.2161, -7.808],
      [110.2157, -7.808],
      [110.2153, -7.8085],
      [110.2154, -7.8087],
      [110.2155, -7.8088],
      [110.2157, -7.8089],
      [110.2158, -7.809],
      [110.2159, -7.8094],
      [110.2159, -7.8097],
      [110.2157, -7.8101],
      [110.2156, -7.8103],
      [110.2153, -7.8103],
      [110.2153, -7.8105],
      [110.2155, -7.8108],
      [110.2156, -7.8112],
      [110.216, -7.8116],
      [110.216, -7.8118],
      [110.2157, -7.8117],
      [110.2153, -7.8118],
      [110.2152, -7.812],
      [110.2151, -7.8121],
      [110.2149, -7.8122],
      [110.2147, -7.8122],
      [110.2145, -7.8122],
      [110.2144, -7.812],
      [110.2143, -7.8118],
      [110.214, -7.8118],
      [110.2139, -7.8116],
      [110.2138, -7.8113],
      [110.2135, -7.8112],
      [110.2134, -7.8112],
      [110.213, -7.811],
      [110.2128, -7.8108],
      [110.2127, -7.8105],
      [110.2126, -7.8105],
      [110.2125, -7.8104],
      [110.2123, -7.8104],
      [110.2122, -7.8104],
      [110.2121, -7.8103],
      [110.2119, -7.81],
      [110.2116, -7.8096],
      [110.2114, -7.8095],
      [110.211, -7.8091],
      [110.2108, -7.8092],
      [110.2106, -7.8095],
      [110.2103, -7.8094],
      [110.21, -7.8096],
      [110.2096, -7.8097],
      [110.2096, -7.8095],
      [110.2096, -7.8094],
      [110.2095, -7.8093],
      [110.2092, -7.8093],
      [110.2087, -7.8095],
      [110.2081, -7.8101],
      [110.2077, -7.8107],
      [110.2076, -7.8108],
      [110.2071, -7.8105],
      [110.2067, -7.8115],
      [110.2064, -7.8117],
      [110.2059, -7.8131],
      [110.2055, -7.8141],
      [110.2053, -7.815],
      [110.2058, -7.817],
      [110.2054, -7.818],
      [110.2052, -7.819],
      [110.2051, -7.8199],
      [110.2049, -7.8207],
      [110.205, -7.8211],
      [110.2055, -7.8212],
      [110.2063, -7.8213],
      [110.2067, -7.8217],
      [110.2072, -7.8221],
      [110.2084, -7.8222],
      [110.2086, -7.8224],
      [110.21, -7.8229],
      [110.211, -7.8235],
      [110.2111, -7.8242],
      [110.2118, -7.8242],
      [110.2124, -7.8246],
      [110.2131, -7.8251],
      [110.2137, -7.8253],
      [110.2143, -7.8256],
      [110.215, -7.826],
      [110.2155, -7.8264],
      [110.2162, -7.8265],
      [110.217, -7.8266],
      [110.2177, -7.8268],
      [110.2184, -7.8271],
      [110.2189, -7.8263],
      [110.2184, -7.8257],
      [110.2185, -7.8245],
      [110.218, -7.8238],
      [110.2176, -7.8229],
      [110.2173, -7.822],
      [110.2173, -7.821],
      [110.2176, -7.8205],
      [110.218, -7.8203],
      [110.2186, -7.8202],
      [110.2195, -7.8199],
      [110.2198, -7.8203],
      [110.2201, -7.8206],
      [110.2208, -7.8202],
      [110.2218, -7.8204],
      [110.2226, -7.8204],
      [110.2232, -7.8206],
      [110.2239, -7.8211],
      [110.2241, -7.8215],
      [110.2245, -7.8216],
      [110.225, -7.8215],
      [110.2254, -7.8214],
      [110.2263, -7.8215],
      [110.2284, -7.8216],
      [110.2302, -7.8218],
      [110.2307, -7.8219],
      [110.231, -7.8225],
      [110.2319, -7.8224],
      [110.2323, -7.8226],
      [110.2327, -7.8226],
      [110.2333, -7.8225],
      [110.2337, -7.8224],
      [110.2336, -7.8216],
      [110.2335, -7.8206],
      [110.2335, -7.8198],
      [110.2335, -7.8196],
      [110.2337, -7.8186],
      [110.2339, -7.8181],
      [110.2341, -7.8173],
      [110.2342, -7.8169],
      [110.2341, -7.8165],
      [110.2337, -7.8158],
      [110.2334, -7.8153],
      [110.2332, -7.8149],
      [110.2331, -7.8147],
      [110.2326, -7.814],
      [110.2325, -7.8136],
      [110.2325, -7.8125],
      [110.2326, -7.8113],
      [110.2324, -7.8106],
      [110.2323, -7.81],
      [110.2319, -7.8095],
      [110.2314, -7.8089],
      [110.2307, -7.8081],
      [110.2303, -7.808],
      [110.2296, -7.8078],
      [110.2292, -7.8075],
      [110.2288, -7.8072],
      [110.2285, -7.8067],
      [110.2283, -7.8063],
      [110.2283, -7.8056],
      [110.2278, -7.8055],
      [110.2271, -7.8054],
      [110.2264, -7.8054],
      [110.2255, -7.8053],
      [110.2253, -7.8053],
      [110.225, -7.8051],
      [110.2245, -7.805],
      [110.2242, -7.8048]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PENGASIH',
    village: 'TAWANGSARI',
    border: [
      [110.134, -7.8082],
      [110.1339, -7.8084],
      [110.1336, -7.8089],
      [110.1334, -7.8092],
      [110.133, -7.8095],
      [110.1327, -7.8098],
      [110.1324, -7.8098],
      [110.1321, -7.8094],
      [110.1318, -7.8087],
      [110.1316, -7.8084],
      [110.1314, -7.8082],
      [110.131, -7.808],
      [110.1306, -7.8079],
      [110.13, -7.8079],
      [110.1292, -7.8073],
      [110.1284, -7.8066],
      [110.1277, -7.8063],
      [110.1273, -7.8064],
      [110.1269, -7.8066],
      [110.1264, -7.8071],
      [110.126, -7.8077],
      [110.1257, -7.8079],
      [110.1256, -7.8094],
      [110.1255, -7.8104],
      [110.1254, -7.8116],
      [110.1254, -7.8119],
      [110.1257, -7.8124],
      [110.1258, -7.8126],
      [110.1258, -7.8129],
      [110.1256, -7.8134],
      [110.1254, -7.8136],
      [110.1254, -7.8139],
      [110.1256, -7.8142],
      [110.1257, -7.8145],
      [110.1257, -7.8148],
      [110.1255, -7.8151],
      [110.1253, -7.8156],
      [110.1252, -7.816],
      [110.1249, -7.8162],
      [110.1246, -7.8163],
      [110.1243, -7.8164],
      [110.1243, -7.8167],
      [110.1245, -7.8169],
      [110.1247, -7.8171],
      [110.1249, -7.8177],
      [110.1252, -7.8184],
      [110.1256, -7.8187],
      [110.1258, -7.8189],
      [110.1261, -7.8198],
      [110.1266, -7.8201],
      [110.127, -7.821],
      [110.1271, -7.8212],
      [110.1273, -7.8218],
      [110.1276, -7.8221],
      [110.1278, -7.8223],
      [110.1278, -7.8228],
      [110.1278, -7.8229],
      [110.1277, -7.8231],
      [110.1277, -7.8235],
      [110.1272, -7.824],
      [110.1271, -7.8245],
      [110.1275, -7.8256],
      [110.1277, -7.8262],
      [110.1276, -7.8268],
      [110.1274, -7.8275],
      [110.1275, -7.8281],
      [110.1277, -7.8289],
      [110.1284, -7.8296],
      [110.1288, -7.8303],
      [110.129, -7.8308],
      [110.1291, -7.832],
      [110.1291, -7.8328],
      [110.1296, -7.8347],
      [110.13, -7.8356],
      [110.1304, -7.8365],
      [110.1307, -7.8376],
      [110.1307, -7.8383],
      [110.1303, -7.8392],
      [110.1296, -7.8399],
      [110.1292, -7.841],
      [110.1288, -7.8412],
      [110.1286, -7.8414],
      [110.1284, -7.8415],
      [110.1279, -7.8422],
      [110.1274, -7.8426],
      [110.1272, -7.8429],
      [110.1268, -7.8439],
      [110.1261, -7.845],
      [110.1259, -7.8452],
      [110.1258, -7.8461],
      [110.1254, -7.8466],
      [110.125, -7.8469],
      [110.1246, -7.8474],
      [110.125, -7.8479],
      [110.1254, -7.8481],
      [110.126, -7.8483],
      [110.1262, -7.8487],
      [110.1264, -7.849],
      [110.1264, -7.8496],
      [110.1267, -7.85],
      [110.1273, -7.8503],
      [110.1276, -7.8508],
      [110.1278, -7.8515],
      [110.1279, -7.8519],
      [110.1279, -7.8528],
      [110.1279, -7.8533],
      [110.1275, -7.8538],
      [110.1269, -7.8544],
      [110.1266, -7.8547],
      [110.127, -7.8555],
      [110.1274, -7.8566],
      [110.1266, -7.8576],
      [110.1262, -7.8593],
      [110.1261, -7.86],
      [110.1257, -7.8609],
      [110.1255, -7.8617],
      [110.1255, -7.8624],
      [110.1256, -7.8628],
      [110.1254, -7.8635],
      [110.1257, -7.8646],
      [110.1258, -7.865],
      [110.1256, -7.8653],
      [110.1252, -7.8655],
      [110.1249, -7.8656],
      [110.125, -7.8662],
      [110.125, -7.8665],
      [110.1249, -7.8668],
      [110.1245, -7.867],
      [110.1247, -7.8676],
      [110.1248, -7.8682],
      [110.1248, -7.8684],
      [110.1248, -7.8687],
      [110.1239, -7.8688],
      [110.1235, -7.8688],
      [110.1235, -7.8694],
      [110.1233, -7.8694],
      [110.1233, -7.8698],
      [110.1233, -7.8704],
      [110.1247, -7.8699],
      [110.1261, -7.8696],
      [110.1261, -7.8706],
      [110.1262, -7.8715],
      [110.1263, -7.8727],
      [110.1263, -7.8735],
      [110.1263, -7.8737],
      [110.1264, -7.8744],
      [110.1265, -7.8754],
      [110.1263, -7.8764],
      [110.1263, -7.8769],
      [110.1263, -7.877],
      [110.1261, -7.8772],
      [110.1256, -7.8771],
      [110.1251, -7.877],
      [110.1247, -7.8768],
      [110.1238, -7.8768],
      [110.1229, -7.8767],
      [110.1225, -7.8766],
      [110.122, -7.8767],
      [110.1214, -7.8768],
      [110.1209, -7.8769],
      [110.1201, -7.877],
      [110.1196, -7.8771],
      [110.1187, -7.8776],
      [110.1185, -7.8778],
      [110.1177, -7.8778],
      [110.117, -7.8778],
      [110.1168, -7.8782],
      [110.1166, -7.8785],
      [110.1162, -7.8787],
      [110.116, -7.8788],
      [110.1157, -7.8789],
      [110.1152, -7.8788],
      [110.1148, -7.8792],
      [110.114, -7.8797],
      [110.1134, -7.8802],
      [110.113, -7.8805],
      [110.1128, -7.8805],
      [110.1126, -7.8805],
      [110.1121, -7.8805],
      [110.1121, -7.8807],
      [110.1117, -7.8807],
      [110.1115, -7.8807],
      [110.1106, -7.8805],
      [110.1102, -7.8804],
      [110.1096, -7.8803],
      [110.109, -7.8802],
      [110.1083, -7.8799],
      [110.1078, -7.8798],
      [110.1076, -7.8795],
      [110.1074, -7.8792],
      [110.1068, -7.8792],
      [110.1064, -7.879],
      [110.1061, -7.8787],
      [110.1057, -7.8786],
      [110.1054, -7.8788],
      [110.1054, -7.8791],
      [110.1054, -7.8799],
      [110.1047, -7.8799],
      [110.1041, -7.8798],
      [110.1035, -7.8798],
      [110.1033, -7.8799],
      [110.1032, -7.88],
      [110.1032, -7.8802],
      [110.1036, -7.8808],
      [110.1041, -7.8815],
      [110.1046, -7.8819],
      [110.105, -7.8825],
      [110.1054, -7.8828],
      [110.1059, -7.883],
      [110.106, -7.8833],
      [110.1062, -7.8833],
      [110.1065, -7.8834],
      [110.1075, -7.8833],
      [110.1074, -7.8841],
      [110.1088, -7.8841],
      [110.1088, -7.8846],
      [110.1087, -7.8855],
      [110.1087, -7.8863],
      [110.1081, -7.8864],
      [110.1075, -7.8864],
      [110.1067, -7.8864],
      [110.1063, -7.8864],
      [110.1061, -7.8865],
      [110.106, -7.8869],
      [110.1056, -7.8875],
      [110.1054, -7.8879],
      [110.1056, -7.8885],
      [110.1057, -7.8888],
      [110.1056, -7.8891],
      [110.1056, -7.8895],
      [110.1058, -7.8898],
      [110.1059, -7.8899],
      [110.1061, -7.8904],
      [110.1064, -7.8904],
      [110.1087, -7.8904],
      [110.1087, -7.8908],
      [110.1086, -7.8914],
      [110.1085, -7.8923],
      [110.1085, -7.8932],
      [110.1083, -7.894],
      [110.1082, -7.8946],
      [110.108, -7.8951],
      [110.1077, -7.8964],
      [110.1072, -7.8982],
      [110.1075, -7.8985],
      [110.1078, -7.8988],
      [110.1077, -7.899],
      [110.1078, -7.8991],
      [110.1079, -7.8996],
      [110.1077, -7.9002],
      [110.108, -7.9006],
      [110.1086, -7.9007],
      [110.1087, -7.9007],
      [110.109, -7.9002],
      [110.1092, -7.8996],
      [110.1091, -7.8992],
      [110.1093, -7.8988],
      [110.1095, -7.8978],
      [110.1097, -7.8978],
      [110.1099, -7.8961],
      [110.1101, -7.8946],
      [110.1102, -7.8928],
      [110.1105, -7.8906],
      [110.1108, -7.8884],
      [110.1108, -7.888],
      [110.111, -7.888],
      [110.1142, -7.8878],
      [110.1141, -7.8864],
      [110.114, -7.8856],
      [110.1161, -7.8855],
      [110.1165, -7.887],
      [110.1171, -7.8877],
      [110.1176, -7.8879],
      [110.1185, -7.8882],
      [110.1193, -7.8882],
      [110.1194, -7.8882],
      [110.1195, -7.8882],
      [110.1208, -7.8884],
      [110.1216, -7.8884],
      [110.1225, -7.8886],
      [110.1226, -7.8875],
      [110.1228, -7.887],
      [110.124, -7.887],
      [110.1249, -7.8872],
      [110.1254, -7.8874],
      [110.1266, -7.8875],
      [110.1275, -7.8875],
      [110.128, -7.8861],
      [110.1281, -7.8857],
      [110.1282, -7.8849],
      [110.1287, -7.8849],
      [110.129, -7.8845],
      [110.1297, -7.8843],
      [110.1302, -7.884],
      [110.1305, -7.8838],
      [110.1309, -7.8833],
      [110.1313, -7.8829],
      [110.1315, -7.8823],
      [110.1313, -7.8818],
      [110.1304, -7.8815],
      [110.1303, -7.8812],
      [110.1304, -7.8806],
      [110.1304, -7.8797],
      [110.1303, -7.8787],
      [110.13, -7.8776],
      [110.1297, -7.8757],
      [110.1295, -7.875],
      [110.1294, -7.8746],
      [110.13, -7.8743],
      [110.1303, -7.8738],
      [110.1304, -7.8733],
      [110.1304, -7.8732],
      [110.1305, -7.873],
      [110.1311, -7.8729],
      [110.1315, -7.8725],
      [110.1317, -7.872],
      [110.132, -7.8715],
      [110.1327, -7.8717],
      [110.1328, -7.8713],
      [110.1331, -7.8713],
      [110.1334, -7.8713],
      [110.1338, -7.8711],
      [110.1341, -7.8708],
      [110.1345, -7.8706],
      [110.1351, -7.8708],
      [110.1353, -7.8706],
      [110.1357, -7.8706],
      [110.136, -7.8708],
      [110.1364, -7.8707],
      [110.137, -7.8704],
      [110.1375, -7.8703],
      [110.1379, -7.8704],
      [110.1384, -7.8704],
      [110.1387, -7.8701],
      [110.1388, -7.8694],
      [110.1393, -7.8691],
      [110.14, -7.8686],
      [110.1406, -7.8683],
      [110.1407, -7.8682],
      [110.1414, -7.8679],
      [110.142, -7.8678],
      [110.1418, -7.8673],
      [110.1412, -7.8664],
      [110.1414, -7.8663],
      [110.1412, -7.8653],
      [110.1414, -7.8651],
      [110.1413, -7.8645],
      [110.1433, -7.864],
      [110.1443, -7.8636],
      [110.1444, -7.8632],
      [110.1453, -7.8631],
      [110.1455, -7.862],
      [110.1451, -7.8619],
      [110.145, -7.862],
      [110.1448, -7.8619],
      [110.1449, -7.8613],
      [110.145, -7.861],
      [110.1457, -7.8604],
      [110.1469, -7.8587],
      [110.148, -7.8574],
      [110.1484, -7.8566],
      [110.1484, -7.8561],
      [110.1479, -7.8556],
      [110.1473, -7.8547],
      [110.1469, -7.8548],
      [110.1468, -7.8545],
      [110.146, -7.8538],
      [110.1454, -7.8532],
      [110.1451, -7.8525],
      [110.1449, -7.8516],
      [110.1444, -7.8508],
      [110.1436, -7.85],
      [110.1431, -7.8492],
      [110.1429, -7.8484],
      [110.1429, -7.8478],
      [110.1427, -7.8473],
      [110.1423, -7.8471],
      [110.1419, -7.8469],
      [110.1419, -7.8464],
      [110.1421, -7.8461],
      [110.1423, -7.8456],
      [110.1423, -7.8449],
      [110.1423, -7.8446],
      [110.1426, -7.8442],
      [110.1428, -7.8439],
      [110.1428, -7.8434],
      [110.1425, -7.8431],
      [110.1426, -7.8426],
      [110.1427, -7.842],
      [110.1425, -7.8415],
      [110.1424, -7.8405],
      [110.1418, -7.8404],
      [110.1415, -7.8401],
      [110.1415, -7.8396],
      [110.1414, -7.839],
      [110.1412, -7.8387],
      [110.1409, -7.8379],
      [110.1415, -7.8374],
      [110.1425, -7.8369],
      [110.143, -7.8361],
      [110.1437, -7.8354],
      [110.1445, -7.8345],
      [110.1448, -7.8338],
      [110.145, -7.8331],
      [110.1452, -7.8328],
      [110.1464, -7.8318],
      [110.1472, -7.8308],
      [110.1477, -7.8297],
      [110.1477, -7.8289],
      [110.1478, -7.8286],
      [110.1483, -7.828],
      [110.148, -7.8273],
      [110.1483, -7.8268],
      [110.1474, -7.8258],
      [110.1464, -7.8249],
      [110.1458, -7.8248],
      [110.1452, -7.8245],
      [110.1442, -7.8241],
      [110.1438, -7.8241],
      [110.1436, -7.8238],
      [110.1436, -7.8236],
      [110.1436, -7.8235],
      [110.1437, -7.8231],
      [110.1437, -7.823],
      [110.1436, -7.8227],
      [110.1431, -7.8225],
      [110.1431, -7.822],
      [110.1429, -7.8213],
      [110.1426, -7.8202],
      [110.1425, -7.8197],
      [110.1424, -7.8195],
      [110.1425, -7.819],
      [110.1419, -7.8183],
      [110.1416, -7.8181],
      [110.1415, -7.8177],
      [110.1413, -7.8172],
      [110.1416, -7.8167],
      [110.1415, -7.8162],
      [110.1418, -7.8157],
      [110.1418, -7.8149],
      [110.1415, -7.8145],
      [110.141, -7.8143],
      [110.1407, -7.8139],
      [110.1403, -7.8138],
      [110.1402, -7.8134],
      [110.1399, -7.8132],
      [110.1394, -7.813],
      [110.1388, -7.813],
      [110.1385, -7.8129],
      [110.1382, -7.8127],
      [110.1377, -7.8127],
      [110.1375, -7.8124],
      [110.1372, -7.812],
      [110.1366, -7.8119],
      [110.1364, -7.8113],
      [110.1361, -7.8113],
      [110.136, -7.8108],
      [110.1358, -7.8106],
      [110.1353, -7.81],
      [110.1354, -7.8095],
      [110.1351, -7.8091],
      [110.134, -7.8082]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PENGASIH',
    village: 'KARANGSARI',
    border: [
      [110.1974, -7.8547],
      [110.1973, -7.8539],
      [110.1968, -7.8532],
      [110.1963, -7.8522],
      [110.1962, -7.852],
      [110.1951, -7.8519],
      [110.194, -7.8518],
      [110.1933, -7.8522],
      [110.191, -7.8526],
      [110.19, -7.8526],
      [110.1895, -7.8522],
      [110.1884, -7.8512],
      [110.1879, -7.8509],
      [110.1877, -7.8512],
      [110.1876, -7.8516],
      [110.1878, -7.8523],
      [110.1876, -7.8526],
      [110.1871, -7.8538],
      [110.1866, -7.8546],
      [110.1868, -7.8559],
      [110.1865, -7.8562],
      [110.1863, -7.8567],
      [110.186, -7.8574],
      [110.186, -7.8583],
      [110.1853, -7.86],
      [110.1844, -7.8619],
      [110.1841, -7.8621],
      [110.184, -7.8631],
      [110.1837, -7.8643],
      [110.1836, -7.8655],
      [110.1837, -7.8666],
      [110.1835, -7.8669],
      [110.1826, -7.867],
      [110.1819, -7.8673],
      [110.1816, -7.8675],
      [110.1813, -7.8671],
      [110.1811, -7.8669],
      [110.1808, -7.8667],
      [110.1804, -7.8667],
      [110.1802, -7.8665],
      [110.1801, -7.8661],
      [110.18, -7.8658],
      [110.1797, -7.8655],
      [110.1792, -7.8651],
      [110.179, -7.865],
      [110.1784, -7.8641],
      [110.1781, -7.8639],
      [110.1774, -7.8629],
      [110.1765, -7.8623],
      [110.176, -7.8623],
      [110.1755, -7.8621],
      [110.175, -7.8621],
      [110.1746, -7.8624],
      [110.1743, -7.8627],
      [110.1745, -7.8629],
      [110.1745, -7.8632],
      [110.1745, -7.8634],
      [110.1742, -7.8637],
      [110.1739, -7.864],
      [110.1738, -7.8643],
      [110.1738, -7.8647],
      [110.1738, -7.8651],
      [110.1737, -7.8661],
      [110.1743, -7.8662],
      [110.1742, -7.8667],
      [110.174, -7.8669],
      [110.1739, -7.8676],
      [110.1736, -7.8678],
      [110.1735, -7.8681],
      [110.1736, -7.8684],
      [110.1739, -7.8686],
      [110.1737, -7.8692],
      [110.1736, -7.8696],
      [110.1738, -7.8698],
      [110.1737, -7.8702],
      [110.1737, -7.8706],
      [110.1736, -7.8711],
      [110.1734, -7.8715],
      [110.1734, -7.8719],
      [110.1736, -7.8724],
      [110.1738, -7.8726],
      [110.1741, -7.8729],
      [110.1743, -7.8731],
      [110.1744, -7.8734],
      [110.1745, -7.8737],
      [110.1745, -7.874],
      [110.1743, -7.8744],
      [110.175, -7.8743],
      [110.1758, -7.8743],
      [110.1762, -7.8742],
      [110.177, -7.8745],
      [110.1778, -7.8749],
      [110.1784, -7.8749],
      [110.1787, -7.875],
      [110.1791, -7.8751],
      [110.1802, -7.8753],
      [110.1802, -7.8765],
      [110.1804, -7.8771],
      [110.1802, -7.8778],
      [110.1801, -7.8784],
      [110.1802, -7.8791],
      [110.1797, -7.8793],
      [110.1797, -7.8799],
      [110.1798, -7.8806],
      [110.1798, -7.8809],
      [110.1794, -7.8811],
      [110.1793, -7.882],
      [110.1793, -7.8828],
      [110.1793, -7.8831],
      [110.1801, -7.883],
      [110.1809, -7.8831],
      [110.1814, -7.8832],
      [110.1819, -7.8832],
      [110.1827, -7.8832],
      [110.1834, -7.8829],
      [110.1835, -7.8829],
      [110.184, -7.8829],
      [110.1846, -7.883],
      [110.1848, -7.883],
      [110.185, -7.8826],
      [110.185, -7.8822],
      [110.1855, -7.882],
      [110.1855, -7.8812],
      [110.1857, -7.8802],
      [110.1858, -7.8793],
      [110.1861, -7.8782],
      [110.1862, -7.8772],
      [110.186, -7.877],
      [110.1859, -7.8769],
      [110.1861, -7.8764],
      [110.1847, -7.8759],
      [110.184, -7.8757],
      [110.1836, -7.8755],
      [110.1837, -7.8745],
      [110.1841, -7.8745],
      [110.1845, -7.8746],
      [110.185, -7.8747],
      [110.1855, -7.8746],
      [110.1859, -7.8745],
      [110.1865, -7.8745],
      [110.1868, -7.8745],
      [110.187, -7.8747],
      [110.1876, -7.8747],
      [110.1882, -7.8748],
      [110.1887, -7.8745],
      [110.1896, -7.8745],
      [110.1902, -7.8745],
      [110.1903, -7.8745],
      [110.1913, -7.8745],
      [110.1919, -7.8746],
      [110.1927, -7.8746],
      [110.1935, -7.8746],
      [110.1945, -7.8749],
      [110.196, -7.8757],
      [110.1966, -7.876],
      [110.1969, -7.876],
      [110.1972, -7.8753],
      [110.197, -7.8747],
      [110.1971, -7.8742],
      [110.1974, -7.8735],
      [110.1979, -7.8728],
      [110.199, -7.8731],
      [110.2006, -7.8738],
      [110.2021, -7.8746],
      [110.2025, -7.8748],
      [110.2027, -7.8749],
      [110.2032, -7.8751],
      [110.2049, -7.8761],
      [110.2065, -7.877],
      [110.2066, -7.8768],
      [110.2061, -7.8764],
      [110.2059, -7.876],
      [110.2059, -7.8755],
      [110.2051, -7.8754],
      [110.2051, -7.875],
      [110.2048, -7.8746],
      [110.2044, -7.8741],
      [110.2042, -7.8738],
      [110.2044, -7.8735],
      [110.2043, -7.8732],
      [110.2039, -7.8729],
      [110.2036, -7.8727],
      [110.2034, -7.8725],
      [110.2031, -7.8722],
      [110.2028, -7.8718],
      [110.2029, -7.8717],
      [110.203, -7.8716],
      [110.2028, -7.8711],
      [110.2024, -7.8707],
      [110.2023, -7.8704],
      [110.2022, -7.87],
      [110.2017, -7.8698],
      [110.2013, -7.8695],
      [110.2011, -7.8691],
      [110.201, -7.8686],
      [110.2008, -7.8681],
      [110.2007, -7.868],
      [110.2005, -7.8679],
      [110.2001, -7.868],
      [110.1997, -7.868],
      [110.1994, -7.8678],
      [110.1993, -7.8677],
      [110.1993, -7.8675],
      [110.1995, -7.8669],
      [110.1999, -7.8664],
      [110.2003, -7.8658],
      [110.2007, -7.8653],
      [110.2008, -7.8652],
      [110.201, -7.8648],
      [110.201, -7.8645],
      [110.2, -7.8642],
      [110.1991, -7.864],
      [110.199, -7.8631],
      [110.1989, -7.8615],
      [110.1986, -7.8603],
      [110.1977, -7.8594],
      [110.1963, -7.8582],
      [110.1959, -7.8576],
      [110.1959, -7.8571],
      [110.1962, -7.8565],
      [110.1964, -7.8557],
      [110.1968, -7.8553],
      [110.197, -7.8551],
      [110.1975, -7.8549],
      [110.1974, -7.8547]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PENGASIH',
    village: 'KEDUNGSARI',
    border: [
      [110.1743, -7.8627],
      [110.1731, -7.8626],
      [110.1723, -7.8625],
      [110.1715, -7.8622],
      [110.1707, -7.8621],
      [110.1697, -7.8621],
      [110.1688, -7.8616],
      [110.1677, -7.8608],
      [110.1675, -7.8606],
      [110.1673, -7.8608],
      [110.1672, -7.8609],
      [110.1669, -7.861],
      [110.1665, -7.8611],
      [110.1661, -7.8612],
      [110.1658, -7.8616],
      [110.1657, -7.8619],
      [110.1657, -7.8623],
      [110.166, -7.8624],
      [110.1663, -7.8626],
      [110.1667, -7.8642],
      [110.1666, -7.8645],
      [110.1661, -7.8647],
      [110.1655, -7.8646],
      [110.1646, -7.8643],
      [110.1641, -7.8645],
      [110.1637, -7.8653],
      [110.163, -7.8666],
      [110.1627, -7.8679],
      [110.1622, -7.8684],
      [110.1607, -7.8683],
      [110.1591, -7.868],
      [110.158, -7.8681],
      [110.1575, -7.8682],
      [110.157, -7.8681],
      [110.1565, -7.8678],
      [110.156, -7.8674],
      [110.1558, -7.867],
      [110.1545, -7.8667],
      [110.1536, -7.8665],
      [110.1531, -7.8666],
      [110.1528, -7.8669],
      [110.1528, -7.8675],
      [110.1531, -7.868],
      [110.1535, -7.8688],
      [110.1536, -7.8692],
      [110.1535, -7.8697],
      [110.1531, -7.8702],
      [110.1524, -7.8701],
      [110.1517, -7.87],
      [110.1508, -7.8702],
      [110.1498, -7.8704],
      [110.1491, -7.8708],
      [110.1487, -7.871],
      [110.1486, -7.8711],
      [110.1486, -7.8716],
      [110.1489, -7.872],
      [110.1495, -7.8723],
      [110.1501, -7.8725],
      [110.1503, -7.8728],
      [110.1503, -7.8732],
      [110.1503, -7.8735],
      [110.1499, -7.8738],
      [110.1494, -7.8738],
      [110.1489, -7.8735],
      [110.1487, -7.8734],
      [110.1485, -7.8732],
      [110.1483, -7.8729],
      [110.1481, -7.8727],
      [110.1479, -7.8721],
      [110.1473, -7.8719],
      [110.1466, -7.872],
      [110.1462, -7.8724],
      [110.1462, -7.8731],
      [110.1462, -7.8739],
      [110.1463, -7.8741],
      [110.1463, -7.8744],
      [110.1463, -7.8747],
      [110.146, -7.8748],
      [110.1456, -7.8744],
      [110.1453, -7.8738],
      [110.145, -7.8733],
      [110.1447, -7.873],
      [110.1441, -7.8729],
      [110.1432, -7.8733],
      [110.1422, -7.8738],
      [110.1416, -7.8741],
      [110.1414, -7.8742],
      [110.1413, -7.8749],
      [110.1417, -7.8756],
      [110.1421, -7.876],
      [110.1424, -7.8758],
      [110.143, -7.8755],
      [110.1439, -7.8753],
      [110.1446, -7.8754],
      [110.1451, -7.8754],
      [110.1458, -7.8755],
      [110.1473, -7.8762],
      [110.1502, -7.8773],
      [110.1501, -7.878],
      [110.15, -7.8786],
      [110.1498, -7.8794],
      [110.1498, -7.8799],
      [110.1493, -7.8801],
      [110.1492, -7.8809],
      [110.1502, -7.881],
      [110.1519, -7.8811],
      [110.1534, -7.8812],
      [110.1536, -7.8812],
      [110.1537, -7.8812],
      [110.1537, -7.8823],
      [110.1544, -7.8824],
      [110.1549, -7.8824],
      [110.1573, -7.8826],
      [110.1578, -7.8811],
      [110.1579, -7.8802],
      [110.1581, -7.8788],
      [110.1591, -7.8788],
      [110.1602, -7.8789],
      [110.1614, -7.8792],
      [110.1622, -7.8795],
      [110.163, -7.8795],
      [110.164, -7.8795],
      [110.1651, -7.8795],
      [110.166, -7.8796],
      [110.1658, -7.881],
      [110.1665, -7.8813],
      [110.167, -7.8818],
      [110.1677, -7.8827],
      [110.1689, -7.8834],
      [110.1698, -7.8838],
      [110.171, -7.8841],
      [110.1718, -7.8843],
      [110.1718, -7.8843],
      [110.1725, -7.8846],
      [110.1736, -7.8849],
      [110.1745, -7.8851],
      [110.1752, -7.885],
      [110.1757, -7.8847],
      [110.1757, -7.884],
      [110.176, -7.8834],
      [110.1759, -7.8826],
      [110.1758, -7.8819],
      [110.1759, -7.8814],
      [110.1761, -7.8806],
      [110.1766, -7.8805],
      [110.1771, -7.8803],
      [110.1771, -7.8798],
      [110.1771, -7.8789],
      [110.1767, -7.8784],
      [110.1759, -7.8782],
      [110.1756, -7.8779],
      [110.1754, -7.8777],
      [110.1753, -7.8773],
      [110.1754, -7.8764],
      [110.1754, -7.8761],
      [110.1746, -7.8762],
      [110.1745, -7.876],
      [110.1744, -7.8752],
      [110.1743, -7.8744],
      [110.1745, -7.874],
      [110.1745, -7.8737],
      [110.1744, -7.8734],
      [110.1743, -7.8731],
      [110.1741, -7.8729],
      [110.1738, -7.8726],
      [110.1736, -7.8724],
      [110.1734, -7.8719],
      [110.1734, -7.8715],
      [110.1736, -7.8711],
      [110.1737, -7.8706],
      [110.1737, -7.8702],
      [110.1738, -7.8698],
      [110.1736, -7.8696],
      [110.1737, -7.8692],
      [110.1739, -7.8686],
      [110.1736, -7.8684],
      [110.1735, -7.8681],
      [110.1736, -7.8678],
      [110.1739, -7.8676],
      [110.174, -7.8669],
      [110.1742, -7.8667],
      [110.1743, -7.8662],
      [110.1737, -7.8661],
      [110.1738, -7.8651],
      [110.1738, -7.8647],
      [110.1738, -7.8643],
      [110.1739, -7.864],
      [110.1742, -7.8637],
      [110.1745, -7.8634],
      [110.1745, -7.8632],
      [110.1745, -7.8629],
      [110.1743, -7.8627]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PENGASIH',
    village: 'MARGOSARI',
    border: [
      [110.1879, -7.8509],
      [110.1879, -7.8505],
      [110.1878, -7.8499],
      [110.1877, -7.8488],
      [110.1876, -7.8479],
      [110.1876, -7.8471],
      [110.1875, -7.8466],
      [110.1862, -7.8448],
      [110.1858, -7.844],
      [110.1853, -7.8429],
      [110.1849, -7.8418],
      [110.1849, -7.8411],
      [110.1843, -7.8415],
      [110.1839, -7.8421],
      [110.1838, -7.8425],
      [110.1829, -7.8425],
      [110.1822, -7.8426],
      [110.1817, -7.8426],
      [110.1813, -7.8434],
      [110.1816, -7.8437],
      [110.1813, -7.8453],
      [110.1808, -7.8468],
      [110.1803, -7.8468],
      [110.1799, -7.8468],
      [110.1793, -7.8471],
      [110.1786, -7.8468],
      [110.1774, -7.8466],
      [110.1763, -7.8465],
      [110.1756, -7.8468],
      [110.1752, -7.8471],
      [110.175, -7.8476],
      [110.1742, -7.8483],
      [110.173, -7.8494],
      [110.1715, -7.8505],
      [110.1699, -7.8513],
      [110.169, -7.8518],
      [110.1686, -7.8522],
      [110.1683, -7.8527],
      [110.1683, -7.8532],
      [110.1684, -7.8538],
      [110.1687, -7.854],
      [110.1697, -7.8542],
      [110.1702, -7.8543],
      [110.1706, -7.8546],
      [110.1707, -7.8549],
      [110.1706, -7.8552],
      [110.1703, -7.8555],
      [110.1694, -7.8559],
      [110.1687, -7.8564],
      [110.1685, -7.8568],
      [110.1683, -7.8568],
      [110.1673, -7.8567],
      [110.1675, -7.8537],
      [110.1663, -7.8547],
      [110.1636, -7.8567],
      [110.1625, -7.8576],
      [110.1627, -7.8577],
      [110.1628, -7.8581],
      [110.1627, -7.8589],
      [110.1625, -7.8597],
      [110.1625, -7.8605],
      [110.1625, -7.8609],
      [110.1631, -7.8615],
      [110.1633, -7.8621],
      [110.1636, -7.8623],
      [110.1645, -7.8623],
      [110.1655, -7.8623],
      [110.1657, -7.8623],
      [110.1657, -7.8619],
      [110.1658, -7.8616],
      [110.1661, -7.8612],
      [110.1665, -7.8611],
      [110.1669, -7.861],
      [110.1672, -7.8609],
      [110.1673, -7.8608],
      [110.1675, -7.8606],
      [110.1677, -7.8608],
      [110.1688, -7.8616],
      [110.1697, -7.8621],
      [110.1707, -7.8621],
      [110.1715, -7.8622],
      [110.1723, -7.8625],
      [110.1731, -7.8626],
      [110.1743, -7.8627],
      [110.1746, -7.8624],
      [110.175, -7.8621],
      [110.1755, -7.8621],
      [110.176, -7.8623],
      [110.1765, -7.8623],
      [110.1774, -7.8629],
      [110.1781, -7.8639],
      [110.1784, -7.8641],
      [110.179, -7.865],
      [110.1792, -7.8651],
      [110.1797, -7.8655],
      [110.18, -7.8658],
      [110.1801, -7.8661],
      [110.1802, -7.8665],
      [110.1804, -7.8667],
      [110.1808, -7.8667],
      [110.1811, -7.8669],
      [110.1813, -7.8671],
      [110.1816, -7.8675],
      [110.1819, -7.8673],
      [110.1826, -7.867],
      [110.1835, -7.8669],
      [110.1837, -7.8666],
      [110.1836, -7.8655],
      [110.1837, -7.8643],
      [110.184, -7.8631],
      [110.1841, -7.8621],
      [110.1844, -7.8619],
      [110.1853, -7.86],
      [110.186, -7.8583],
      [110.186, -7.8574],
      [110.1863, -7.8567],
      [110.1865, -7.8562],
      [110.1868, -7.8559],
      [110.1866, -7.8546],
      [110.1871, -7.8538],
      [110.1876, -7.8526],
      [110.1878, -7.8523],
      [110.1876, -7.8516],
      [110.1877, -7.8512],
      [110.1879, -7.8509]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PENGASIH',
    village: 'PENGASIH',
    border: [
      [110.1823, -7.8253],
      [110.1814, -7.8253],
      [110.1806, -7.8253],
      [110.1797, -7.8252],
      [110.179, -7.825],
      [110.1782, -7.8246],
      [110.1775, -7.8243],
      [110.1774, -7.8243],
      [110.1773, -7.825],
      [110.1773, -7.8256],
      [110.177, -7.8259],
      [110.1768, -7.8264],
      [110.1768, -7.8267],
      [110.1767, -7.8274],
      [110.1766, -7.8289],
      [110.1764, -7.8297],
      [110.1761, -7.8299],
      [110.1757, -7.8296],
      [110.1756, -7.8295],
      [110.175, -7.8295],
      [110.1747, -7.8298],
      [110.1744, -7.83],
      [110.1737, -7.83],
      [110.1732, -7.8298],
      [110.1728, -7.83],
      [110.1726, -7.8302],
      [110.1724, -7.8303],
      [110.172, -7.8305],
      [110.1719, -7.8307],
      [110.1718, -7.8311],
      [110.1717, -7.8316],
      [110.1712, -7.8317],
      [110.1712, -7.8324],
      [110.1712, -7.833],
      [110.1712, -7.8336],
      [110.1711, -7.8343],
      [110.1714, -7.8351],
      [110.1712, -7.8353],
      [110.1709, -7.8356],
      [110.1708, -7.8359],
      [110.1706, -7.8367],
      [110.1705, -7.8371],
      [110.1708, -7.8374],
      [110.171, -7.8375],
      [110.171, -7.8385],
      [110.1709, -7.8389],
      [110.1704, -7.8394],
      [110.1702, -7.8399],
      [110.17, -7.8401],
      [110.1696, -7.84],
      [110.1691, -7.84],
      [110.1689, -7.8399],
      [110.1686, -7.8398],
      [110.168, -7.8395],
      [110.1675, -7.8392],
      [110.167, -7.8392],
      [110.1666, -7.8394],
      [110.1663, -7.8396],
      [110.1659, -7.8399],
      [110.166, -7.8406],
      [110.1651, -7.8407],
      [110.1647, -7.8408],
      [110.1642, -7.8411],
      [110.1635, -7.8415],
      [110.1631, -7.8418],
      [110.163, -7.8421],
      [110.1628, -7.8429],
      [110.1627, -7.8429],
      [110.1618, -7.8429],
      [110.1606, -7.8428],
      [110.1605, -7.8427],
      [110.1605, -7.8412],
      [110.1606, -7.8398],
      [110.1588, -7.8393],
      [110.1576, -7.8387],
      [110.1572, -7.8383],
      [110.1564, -7.8377],
      [110.155, -7.8376],
      [110.1547, -7.8369],
      [110.1543, -7.8369],
      [110.1527, -7.837],
      [110.1522, -7.8367],
      [110.1505, -7.8377],
      [110.1501, -7.8375],
      [110.149, -7.8392],
      [110.148, -7.8395],
      [110.147, -7.8396],
      [110.1454, -7.8396],
      [110.1441, -7.8396],
      [110.1431, -7.8395],
      [110.1423, -7.839],
      [110.1418, -7.8389],
      [110.1414, -7.839],
      [110.1415, -7.8396],
      [110.1415, -7.8401],
      [110.1418, -7.8404],
      [110.1424, -7.8405],
      [110.1425, -7.8415],
      [110.1427, -7.842],
      [110.1429, -7.8424],
      [110.1436, -7.8426],
      [110.1444, -7.8433],
      [110.1447, -7.8436],
      [110.1449, -7.8438],
      [110.145, -7.8445],
      [110.1453, -7.8446],
      [110.1455, -7.8451],
      [110.1458, -7.8455],
      [110.1461, -7.8459],
      [110.1466, -7.8461],
      [110.1473, -7.8464],
      [110.1477, -7.8466],
      [110.1479, -7.847],
      [110.1481, -7.8476],
      [110.1486, -7.8479],
      [110.149, -7.8481],
      [110.1492, -7.8483],
      [110.1494, -7.8489],
      [110.1492, -7.85],
      [110.1491, -7.851],
      [110.1494, -7.8518],
      [110.1497, -7.8524],
      [110.1508, -7.8531],
      [110.1509, -7.8532],
      [110.152, -7.853],
      [110.1533, -7.8531],
      [110.1537, -7.8531],
      [110.1542, -7.8531],
      [110.1545, -7.8532],
      [110.1548, -7.8529],
      [110.1552, -7.8521],
      [110.1554, -7.8516],
      [110.157, -7.8518],
      [110.1578, -7.852],
      [110.1584, -7.8522],
      [110.1586, -7.8524],
      [110.1594, -7.8525],
      [110.1606, -7.8529],
      [110.1615, -7.8531],
      [110.1624, -7.8531],
      [110.1636, -7.8532],
      [110.1636, -7.8536],
      [110.1633, -7.8548],
      [110.1631, -7.8556],
      [110.1628, -7.8556],
      [110.1622, -7.8575],
      [110.1625, -7.8576],
      [110.1636, -7.8567],
      [110.1663, -7.8547],
      [110.1675, -7.8537],
      [110.1673, -7.8567],
      [110.1683, -7.8568],
      [110.1685, -7.8568],
      [110.1687, -7.8564],
      [110.1694, -7.8559],
      [110.1703, -7.8555],
      [110.1706, -7.8552],
      [110.1707, -7.8549],
      [110.1706, -7.8546],
      [110.1702, -7.8543],
      [110.1697, -7.8542],
      [110.1687, -7.854],
      [110.1684, -7.8538],
      [110.1683, -7.8532],
      [110.1683, -7.8527],
      [110.1686, -7.8522],
      [110.169, -7.8518],
      [110.1699, -7.8513],
      [110.1715, -7.8505],
      [110.173, -7.8494],
      [110.1742, -7.8483],
      [110.175, -7.8476],
      [110.1752, -7.8471],
      [110.1756, -7.8468],
      [110.1763, -7.8465],
      [110.1774, -7.8466],
      [110.1786, -7.8468],
      [110.1793, -7.8471],
      [110.1799, -7.8468],
      [110.1803, -7.8468],
      [110.1808, -7.8468],
      [110.1813, -7.8453],
      [110.1816, -7.8437],
      [110.1813, -7.8434],
      [110.1817, -7.8426],
      [110.1822, -7.8426],
      [110.1829, -7.8425],
      [110.1838, -7.8425],
      [110.1839, -7.8421],
      [110.1843, -7.8415],
      [110.1849, -7.8411],
      [110.1848, -7.8409],
      [110.1847, -7.84],
      [110.1846, -7.839],
      [110.1846, -7.8379],
      [110.1847, -7.8376],
      [110.1844, -7.8372],
      [110.1844, -7.8367],
      [110.1834, -7.8355],
      [110.1833, -7.835],
      [110.1827, -7.834],
      [110.1825, -7.8337],
      [110.1823, -7.8329],
      [110.1824, -7.832],
      [110.1826, -7.8315],
      [110.1828, -7.831],
      [110.1827, -7.8299],
      [110.1826, -7.8299],
      [110.182, -7.8294],
      [110.1817, -7.8289],
      [110.1818, -7.8285],
      [110.1817, -7.8281],
      [110.1813, -7.8275],
      [110.1816, -7.8269],
      [110.1819, -7.8264],
      [110.1821, -7.8262],
      [110.1823, -7.8258],
      [110.1823, -7.8253]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PENGASIH',
    village: 'SENDANGSARI',
    border: [
      [110.1686, -7.8114],
      [110.1683, -7.8113],
      [110.1682, -7.8113],
      [110.1676, -7.8114],
      [110.1668, -7.8115],
      [110.1662, -7.8116],
      [110.1652, -7.8116],
      [110.1648, -7.8115],
      [110.1642, -7.8114],
      [110.1639, -7.8103],
      [110.1634, -7.8101],
      [110.1628, -7.8101],
      [110.1619, -7.8098],
      [110.1614, -7.8094],
      [110.1609, -7.8094],
      [110.1602, -7.8093],
      [110.1594, -7.8094],
      [110.1583, -7.8098],
      [110.1579, -7.8102],
      [110.1573, -7.8107],
      [110.1566, -7.8108],
      [110.1563, -7.8109],
      [110.1552, -7.8109],
      [110.1546, -7.8111],
      [110.1546, -7.8108],
      [110.1543, -7.8106],
      [110.1544, -7.8101],
      [110.1546, -7.8099],
      [110.1545, -7.8094],
      [110.1545, -7.809],
      [110.1547, -7.8085],
      [110.1545, -7.8083],
      [110.1543, -7.8081],
      [110.1541, -7.8077],
      [110.1536, -7.8074],
      [110.1532, -7.807],
      [110.1527, -7.807],
      [110.1522, -7.8072],
      [110.1518, -7.807],
      [110.1512, -7.807],
      [110.1507, -7.807],
      [110.1501, -7.8062],
      [110.1496, -7.8058],
      [110.1491, -7.8054],
      [110.1486, -7.8053],
      [110.1482, -7.8044],
      [110.1476, -7.8041],
      [110.1467, -7.8035],
      [110.146, -7.8033],
      [110.1453, -7.8033],
      [110.1444, -7.8035],
      [110.1441, -7.8032],
      [110.1439, -7.8026],
      [110.1434, -7.8021],
      [110.1433, -7.8018],
      [110.1434, -7.8016],
      [110.1436, -7.8013],
      [110.1439, -7.8009],
      [110.144, -7.8006],
      [110.1441, -7.8004],
      [110.1445, -7.7998],
      [110.1443, -7.7994],
      [110.144, -7.799],
      [110.1435, -7.7988],
      [110.143, -7.7984],
      [110.1429, -7.7985],
      [110.1427, -7.7987],
      [110.1424, -7.7991],
      [110.1409, -7.7995],
      [110.1398, -7.7999],
      [110.1394, -7.8],
      [110.1387, -7.8001],
      [110.138, -7.8005],
      [110.1372, -7.801],
      [110.136, -7.8012],
      [110.1353, -7.8012],
      [110.1341, -7.8015],
      [110.133, -7.8016],
      [110.1325, -7.8017],
      [110.1322, -7.8019],
      [110.1317, -7.8032],
      [110.1315, -7.8036],
      [110.1312, -7.8037],
      [110.1307, -7.8037],
      [110.1309, -7.8044],
      [110.1312, -7.805],
      [110.1313, -7.8057],
      [110.1318, -7.8059],
      [110.1321, -7.806],
      [110.1323, -7.8064],
      [110.1327, -7.8066],
      [110.1328, -7.8068],
      [110.1331, -7.8071],
      [110.1335, -7.8072],
      [110.1337, -7.8074],
      [110.1337, -7.8079],
      [110.134, -7.8082],
      [110.1351, -7.8091],
      [110.1354, -7.8095],
      [110.1353, -7.81],
      [110.1358, -7.8106],
      [110.136, -7.8108],
      [110.1361, -7.8113],
      [110.1364, -7.8113],
      [110.1366, -7.8119],
      [110.1372, -7.812],
      [110.1375, -7.8124],
      [110.1377, -7.8127],
      [110.1382, -7.8127],
      [110.1385, -7.8129],
      [110.1388, -7.813],
      [110.1394, -7.813],
      [110.1399, -7.8132],
      [110.1402, -7.8134],
      [110.1403, -7.8138],
      [110.1407, -7.8139],
      [110.141, -7.8143],
      [110.1415, -7.8145],
      [110.1418, -7.8149],
      [110.1418, -7.8157],
      [110.1415, -7.8162],
      [110.1416, -7.8167],
      [110.1413, -7.8172],
      [110.1415, -7.8177],
      [110.1416, -7.8181],
      [110.1419, -7.8183],
      [110.1425, -7.819],
      [110.1424, -7.8195],
      [110.1425, -7.8197],
      [110.1426, -7.8202],
      [110.1429, -7.8213],
      [110.1431, -7.822],
      [110.1431, -7.8225],
      [110.1436, -7.8227],
      [110.1437, -7.823],
      [110.1437, -7.8231],
      [110.1436, -7.8235],
      [110.1436, -7.8236],
      [110.1436, -7.8238],
      [110.1438, -7.8241],
      [110.1442, -7.8241],
      [110.1452, -7.8245],
      [110.1458, -7.8248],
      [110.1464, -7.8249],
      [110.1474, -7.8258],
      [110.1483, -7.8268],
      [110.148, -7.8273],
      [110.1483, -7.828],
      [110.1478, -7.8286],
      [110.1477, -7.8289],
      [110.1477, -7.8297],
      [110.1472, -7.8308],
      [110.1464, -7.8318],
      [110.1452, -7.8328],
      [110.145, -7.8331],
      [110.1448, -7.8338],
      [110.1445, -7.8345],
      [110.1437, -7.8354],
      [110.143, -7.8361],
      [110.1425, -7.8369],
      [110.1415, -7.8374],
      [110.1409, -7.8379],
      [110.1412, -7.8387],
      [110.1414, -7.839],
      [110.1418, -7.8389],
      [110.1423, -7.839],
      [110.1431, -7.8395],
      [110.1441, -7.8396],
      [110.1454, -7.8396],
      [110.147, -7.8396],
      [110.148, -7.8395],
      [110.149, -7.8392],
      [110.1501, -7.8375],
      [110.1505, -7.8377],
      [110.1522, -7.8367],
      [110.1527, -7.837],
      [110.1543, -7.8369],
      [110.1547, -7.8369],
      [110.155, -7.8376],
      [110.1564, -7.8377],
      [110.1572, -7.8383],
      [110.1576, -7.8387],
      [110.1588, -7.8393],
      [110.1606, -7.8398],
      [110.1605, -7.8412],
      [110.1605, -7.8427],
      [110.1606, -7.8428],
      [110.1618, -7.8429],
      [110.1627, -7.8429],
      [110.1628, -7.8429],
      [110.163, -7.8421],
      [110.1631, -7.8418],
      [110.1635, -7.8415],
      [110.1642, -7.8411],
      [110.1647, -7.8408],
      [110.1651, -7.8407],
      [110.166, -7.8406],
      [110.1659, -7.8399],
      [110.1663, -7.8396],
      [110.1666, -7.8394],
      [110.167, -7.8392],
      [110.1675, -7.8392],
      [110.168, -7.8395],
      [110.1686, -7.8398],
      [110.1689, -7.8399],
      [110.1691, -7.84],
      [110.1696, -7.84],
      [110.17, -7.8401],
      [110.1702, -7.8399],
      [110.1704, -7.8394],
      [110.1709, -7.8389],
      [110.171, -7.8385],
      [110.171, -7.8375],
      [110.1708, -7.8374],
      [110.1705, -7.8371],
      [110.1706, -7.8367],
      [110.1708, -7.8359],
      [110.1709, -7.8356],
      [110.1712, -7.8353],
      [110.1714, -7.8351],
      [110.1711, -7.8343],
      [110.1712, -7.8336],
      [110.1712, -7.833],
      [110.1712, -7.8324],
      [110.1712, -7.8317],
      [110.1717, -7.8316],
      [110.1718, -7.8311],
      [110.1719, -7.8307],
      [110.172, -7.8305],
      [110.1724, -7.8303],
      [110.1726, -7.8302],
      [110.1728, -7.83],
      [110.1732, -7.8298],
      [110.1737, -7.83],
      [110.1744, -7.83],
      [110.1747, -7.8298],
      [110.175, -7.8295],
      [110.1756, -7.8295],
      [110.1757, -7.8296],
      [110.1761, -7.8299],
      [110.1764, -7.8297],
      [110.1766, -7.8289],
      [110.1767, -7.8274],
      [110.1768, -7.8267],
      [110.1768, -7.8264],
      [110.177, -7.8259],
      [110.1773, -7.8256],
      [110.1773, -7.825],
      [110.1774, -7.8243],
      [110.1771, -7.8242],
      [110.1763, -7.824],
      [110.1755, -7.8237],
      [110.1751, -7.8232],
      [110.1748, -7.8228],
      [110.1743, -7.823],
      [110.1735, -7.8229],
      [110.173, -7.8226],
      [110.1722, -7.8223],
      [110.1717, -7.8221],
      [110.1714, -7.8224],
      [110.1711, -7.8227],
      [110.171, -7.8229],
      [110.1708, -7.823],
      [110.1705, -7.8232],
      [110.1706, -7.8236],
      [110.1705, -7.8239],
      [110.1703, -7.8242],
      [110.1698, -7.8243],
      [110.1696, -7.8243],
      [110.1689, -7.8239],
      [110.1681, -7.8234],
      [110.1674, -7.8232],
      [110.1668, -7.8232],
      [110.1667, -7.8232],
      [110.1665, -7.8233],
      [110.1663, -7.824],
      [110.1663, -7.8243],
      [110.1658, -7.8249],
      [110.1651, -7.8254],
      [110.1644, -7.8259],
      [110.1637, -7.8265],
      [110.1635, -7.8265],
      [110.1633, -7.8258],
      [110.1631, -7.8249],
      [110.1628, -7.8235],
      [110.1625, -7.823],
      [110.1622, -7.8227],
      [110.1622, -7.8225],
      [110.1622, -7.8223],
      [110.1626, -7.8217],
      [110.1629, -7.8213],
      [110.1634, -7.8208],
      [110.164, -7.8204],
      [110.1644, -7.8203],
      [110.165, -7.8196],
      [110.1653, -7.819],
      [110.1655, -7.8186],
      [110.1657, -7.818],
      [110.1656, -7.8175],
      [110.1656, -7.8172],
      [110.1666, -7.8173],
      [110.1671, -7.8173],
      [110.1675, -7.8168],
      [110.1678, -7.8163],
      [110.168, -7.8153],
      [110.1682, -7.8151],
      [110.1683, -7.8149],
      [110.1684, -7.8145],
      [110.1684, -7.8137],
      [110.1684, -7.8129],
      [110.1685, -7.8121],
      [110.1686, -7.8114]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'PENGASIH',
    village: 'SIDOMULYO',
    border: [
      [110.1525, -7.77],
      [110.152, -7.7694],
      [110.1515, -7.7688],
      [110.151, -7.768],
      [110.1508, -7.7672],
      [110.1509, -7.7658],
      [110.1507, -7.7652],
      [110.1505, -7.7648],
      [110.1498, -7.764],
      [110.1492, -7.7633],
      [110.1484, -7.762],
      [110.1473, -7.761],
      [110.1465, -7.7604],
      [110.1463, -7.7601],
      [110.1461, -7.7601],
      [110.145, -7.7597],
      [110.144, -7.7598],
      [110.1427, -7.7608],
      [110.1425, -7.7609],
      [110.1417, -7.7616],
      [110.1408, -7.7619],
      [110.1399, -7.7621],
      [110.1384, -7.7623],
      [110.1379, -7.7624],
      [110.138, -7.7629],
      [110.1384, -7.7633],
      [110.1385, -7.7639],
      [110.1384, -7.7642],
      [110.1382, -7.7643],
      [110.138, -7.7648],
      [110.138, -7.7657],
      [110.1379, -7.7661],
      [110.1374, -7.7668],
      [110.1373, -7.7674],
      [110.1373, -7.7683],
      [110.1379, -7.7698],
      [110.1387, -7.7717],
      [110.1391, -7.7722],
      [110.1392, -7.7725],
      [110.1393, -7.7729],
      [110.1396, -7.7734],
      [110.1399, -7.7736],
      [110.1402, -7.7742],
      [110.1405, -7.7746],
      [110.1403, -7.7751],
      [110.1402, -7.7759],
      [110.1402, -7.7761],
      [110.1404, -7.7763],
      [110.1402, -7.7767],
      [110.1399, -7.777],
      [110.1399, -7.7778],
      [110.1399, -7.7781],
      [110.1397, -7.7787],
      [110.1392, -7.78],
      [110.1386, -7.7805],
      [110.1378, -7.7807],
      [110.1364, -7.7802],
      [110.1352, -7.7795],
      [110.1348, -7.7791],
      [110.1339, -7.7789],
      [110.1331, -7.7791],
      [110.1326, -7.7796],
      [110.1325, -7.7801],
      [110.1326, -7.7804],
      [110.1326, -7.7807],
      [110.1328, -7.7811],
      [110.1326, -7.7819],
      [110.1323, -7.7829],
      [110.1323, -7.7834],
      [110.132, -7.7839],
      [110.1315, -7.7844],
      [110.1309, -7.7845],
      [110.1311, -7.7849],
      [110.1312, -7.7854],
      [110.132, -7.786],
      [110.1326, -7.7863],
      [110.1335, -7.7867],
      [110.1341, -7.7869],
      [110.1344, -7.7877],
      [110.1349, -7.7892],
      [110.1353, -7.7901],
      [110.1362, -7.7909],
      [110.1379, -7.7914],
      [110.139, -7.792],
      [110.1401, -7.7927],
      [110.1409, -7.7931],
      [110.1415, -7.7937],
      [110.1429, -7.7962],
      [110.1434, -7.7977],
      [110.1432, -7.7982],
      [110.143, -7.7984],
      [110.1435, -7.7988],
      [110.144, -7.799],
      [110.1443, -7.7994],
      [110.1445, -7.7998],
      [110.1441, -7.8004],
      [110.144, -7.8006],
      [110.1439, -7.8009],
      [110.1436, -7.8013],
      [110.1434, -7.8016],
      [110.1433, -7.8018],
      [110.1434, -7.8021],
      [110.1439, -7.8026],
      [110.1441, -7.8032],
      [110.1444, -7.8035],
      [110.1453, -7.8033],
      [110.146, -7.8033],
      [110.1467, -7.8035],
      [110.1476, -7.8041],
      [110.1482, -7.8044],
      [110.1486, -7.8053],
      [110.1491, -7.8054],
      [110.1496, -7.8058],
      [110.1501, -7.8062],
      [110.1507, -7.807],
      [110.1512, -7.807],
      [110.1518, -7.807],
      [110.1522, -7.8072],
      [110.1527, -7.807],
      [110.1532, -7.807],
      [110.1536, -7.8074],
      [110.1541, -7.8077],
      [110.1543, -7.8081],
      [110.1545, -7.8083],
      [110.1547, -7.8085],
      [110.1545, -7.809],
      [110.1545, -7.8094],
      [110.1546, -7.8099],
      [110.1544, -7.8101],
      [110.1543, -7.8106],
      [110.1546, -7.8108],
      [110.1546, -7.8111],
      [110.1552, -7.8109],
      [110.1563, -7.8109],
      [110.1566, -7.8108],
      [110.1573, -7.8107],
      [110.1579, -7.8102],
      [110.1583, -7.8098],
      [110.1594, -7.8094],
      [110.1602, -7.8093],
      [110.1609, -7.8094],
      [110.1614, -7.8094],
      [110.1619, -7.8098],
      [110.1628, -7.8101],
      [110.1634, -7.8101],
      [110.1639, -7.8103],
      [110.1642, -7.8114],
      [110.1648, -7.8115],
      [110.1652, -7.8116],
      [110.1662, -7.8116],
      [110.1668, -7.8115],
      [110.1676, -7.8114],
      [110.1682, -7.8113],
      [110.1683, -7.8113],
      [110.1686, -7.8114],
      [110.1688, -7.811],
      [110.1692, -7.8105],
      [110.1693, -7.81],
      [110.1695, -7.8095],
      [110.1694, -7.8089],
      [110.1691, -7.8081],
      [110.1689, -7.8074],
      [110.1687, -7.8069],
      [110.1685, -7.8066],
      [110.1683, -7.8064],
      [110.1684, -7.806],
      [110.1687, -7.8058],
      [110.1689, -7.8057],
      [110.1693, -7.8054],
      [110.1698, -7.805],
      [110.17, -7.8049],
      [110.17, -7.8048],
      [110.1704, -7.8046],
      [110.1707, -7.8045],
      [110.1711, -7.8041],
      [110.1711, -7.8038],
      [110.1712, -7.8035],
      [110.1713, -7.803],
      [110.1713, -7.8025],
      [110.1713, -7.802],
      [110.171, -7.8014],
      [110.1708, -7.8007],
      [110.1708, -7.8002],
      [110.171, -7.7998],
      [110.1714, -7.7997],
      [110.1716, -7.7996],
      [110.1727, -7.7992],
      [110.1728, -7.7991],
      [110.1732, -7.799],
      [110.1732, -7.7988],
      [110.1733, -7.7983],
      [110.1734, -7.7977],
      [110.1737, -7.7976],
      [110.1738, -7.7975],
      [110.1741, -7.7975],
      [110.1743, -7.7972],
      [110.1744, -7.7968],
      [110.1744, -7.7967],
      [110.174, -7.796],
      [110.1733, -7.7948],
      [110.1731, -7.794],
      [110.1732, -7.7937],
      [110.1733, -7.7932],
      [110.1732, -7.7928],
      [110.1732, -7.7927],
      [110.1731, -7.7923],
      [110.173, -7.792],
      [110.1726, -7.7919],
      [110.1724, -7.7917],
      [110.1721, -7.7912],
      [110.1721, -7.7911],
      [110.1721, -7.7908],
      [110.1721, -7.7903],
      [110.1722, -7.7896],
      [110.1722, -7.7895],
      [110.1722, -7.789],
      [110.1721, -7.7886],
      [110.1718, -7.7887],
      [110.1711, -7.7886],
      [110.1709, -7.7886],
      [110.1704, -7.7887],
      [110.17, -7.7884],
      [110.1696, -7.7882],
      [110.1691, -7.7882],
      [110.168, -7.7884],
      [110.1677, -7.7883],
      [110.1675, -7.7881],
      [110.1669, -7.7882],
      [110.1666, -7.7879],
      [110.1664, -7.7874],
      [110.1663, -7.787],
      [110.1658, -7.7868],
      [110.1654, -7.7866],
      [110.1651, -7.7862],
      [110.1649, -7.786],
      [110.1642, -7.7861],
      [110.1639, -7.7862],
      [110.1634, -7.7863],
      [110.163, -7.7861],
      [110.1625, -7.786],
      [110.1621, -7.7858],
      [110.1619, -7.7857],
      [110.1617, -7.7854],
      [110.1617, -7.7851],
      [110.1619, -7.7848],
      [110.1624, -7.7846],
      [110.1627, -7.7845],
      [110.1627, -7.7844],
      [110.1624, -7.7842],
      [110.1621, -7.784],
      [110.1619, -7.7839],
      [110.1618, -7.7842],
      [110.1615, -7.7845],
      [110.1612, -7.7845],
      [110.1609, -7.7843],
      [110.1605, -7.784],
      [110.1599, -7.7836],
      [110.1593, -7.7834],
      [110.159, -7.7832],
      [110.159, -7.7829],
      [110.1586, -7.7825],
      [110.1584, -7.7823],
      [110.1581, -7.7823],
      [110.1578, -7.7823],
      [110.1576, -7.7821],
      [110.1576, -7.7818],
      [110.1577, -7.7815],
      [110.1579, -7.7813],
      [110.1581, -7.7811],
      [110.158, -7.7808],
      [110.1578, -7.7804],
      [110.158, -7.7802],
      [110.1582, -7.78],
      [110.1583, -7.7798],
      [110.1584, -7.7797],
      [110.1585, -7.7791],
      [110.1588, -7.7787],
      [110.159, -7.7785],
      [110.159, -7.7781],
      [110.1592, -7.7776],
      [110.1594, -7.7775],
      [110.1597, -7.7775],
      [110.1597, -7.7772],
      [110.1597, -7.7769],
      [110.16, -7.7765],
      [110.1602, -7.7763],
      [110.1603, -7.7759],
      [110.1601, -7.7756],
      [110.1599, -7.7753],
      [110.1597, -7.7749],
      [110.1594, -7.7747],
      [110.1593, -7.7743],
      [110.1591, -7.774],
      [110.1588, -7.7741],
      [110.1586, -7.774],
      [110.1585, -7.7736],
      [110.1581, -7.7736],
      [110.1578, -7.7732],
      [110.1572, -7.773],
      [110.1565, -7.7724],
      [110.156, -7.7722],
      [110.1551, -7.7718],
      [110.1548, -7.7718],
      [110.1541, -7.7716],
      [110.1537, -7.7712],
      [110.1534, -7.771],
      [110.1532, -7.7706],
      [110.153, -7.7703],
      [110.1528, -7.7701],
      [110.1525, -7.77],
      [110.1525, -7.77]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KOKAP',
    village: 'HARGOMULYO',
    border: [
      [110.0894, -7.8608],
      [110.0889, -7.8591],
      [110.0887, -7.8585],
      [110.0884, -7.8574],
      [110.0878, -7.8556],
      [110.0878, -7.855],
      [110.0876, -7.8538],
      [110.0875, -7.8536],
      [110.0863, -7.8501],
      [110.0863, -7.8499],
      [110.0851, -7.8455],
      [110.0849, -7.8448],
      [110.0846, -7.8439],
      [110.0842, -7.8427],
      [110.0831, -7.8418],
      [110.0817, -7.8409],
      [110.0814, -7.8406],
      [110.0808, -7.8409],
      [110.0797, -7.8412],
      [110.0768, -7.8417],
      [110.0756, -7.8419],
      [110.0716, -7.8437],
      [110.0688, -7.8451],
      [110.0671, -7.8459],
      [110.0655, -7.8465],
      [110.0644, -7.8469],
      [110.0625, -7.8472],
      [110.0608, -7.8471],
      [110.0591, -7.8472],
      [110.0571, -7.847],
      [110.0562, -7.8469],
      [110.0549, -7.8467],
      [110.053, -7.8464],
      [110.0523, -7.8462],
      [110.0516, -7.8457],
      [110.0512, -7.8465],
      [110.0508, -7.8471],
      [110.0506, -7.8473],
      [110.0505, -7.8476],
      [110.0505, -7.848],
      [110.0506, -7.8481],
      [110.0507, -7.8485],
      [110.0507, -7.8486],
      [110.0508, -7.8491],
      [110.0509, -7.8496],
      [110.0509, -7.8497],
      [110.0509, -7.8499],
      [110.0509, -7.8502],
      [110.0508, -7.8507],
      [110.0506, -7.8514],
      [110.0504, -7.8518],
      [110.0499, -7.8524],
      [110.0496, -7.8528],
      [110.0495, -7.8529],
      [110.0494, -7.8531],
      [110.0494, -7.8531],
      [110.0493, -7.8532],
      [110.0491, -7.8533],
      [110.049, -7.8534],
      [110.0489, -7.8535],
      [110.0485, -7.8537],
      [110.0484, -7.8538],
      [110.0482, -7.8539],
      [110.048, -7.854],
      [110.0474, -7.8545],
      [110.0472, -7.8546],
      [110.0468, -7.8552],
      [110.0466, -7.8561],
      [110.0465, -7.8562],
      [110.0464, -7.8564],
      [110.0458, -7.8572],
      [110.0456, -7.8574],
      [110.0455, -7.8575],
      [110.0454, -7.8577],
      [110.0452, -7.858],
      [110.0446, -7.8586],
      [110.0434, -7.8596],
      [110.0431, -7.86],
      [110.0432, -7.8602],
      [110.0433, -7.8605],
      [110.0433, -7.8608],
      [110.0433, -7.861],
      [110.0433, -7.8613],
      [110.0434, -7.8615],
      [110.0435, -7.8621],
      [110.0436, -7.8626],
      [110.0436, -7.8627],
      [110.0437, -7.8639],
      [110.0437, -7.8641],
      [110.0431, -7.8648],
      [110.0427, -7.8653],
      [110.0427, -7.8655],
      [110.0427, -7.8658],
      [110.0427, -7.866],
      [110.0428, -7.8661],
      [110.0429, -7.8662],
      [110.0431, -7.8663],
      [110.0432, -7.8666],
      [110.0433, -7.8669],
      [110.0433, -7.867],
      [110.0433, -7.8673],
      [110.0434, -7.8685],
      [110.0436, -7.869],
      [110.0447, -7.8688],
      [110.0455, -7.8687],
      [110.0458, -7.8687],
      [110.0475, -7.8687],
      [110.0482, -7.8688],
      [110.0512, -7.8687],
      [110.0526, -7.8687],
      [110.0544, -7.8689],
      [110.0567, -7.8688],
      [110.0591, -7.8685],
      [110.0601, -7.8684],
      [110.0621, -7.8689],
      [110.0634, -7.8693],
      [110.0647, -7.8696],
      [110.066, -7.87],
      [110.0669, -7.8701],
      [110.067, -7.8701],
      [110.067, -7.8705],
      [110.0668, -7.8715],
      [110.0668, -7.872],
      [110.0668, -7.8723],
      [110.0668, -7.8726],
      [110.0666, -7.8735],
      [110.0668, -7.8738],
      [110.0669, -7.8739],
      [110.067, -7.874],
      [110.0674, -7.8741],
      [110.0685, -7.8738],
      [110.0701, -7.873],
      [110.0702, -7.8729],
      [110.0721, -7.8717],
      [110.0723, -7.8716],
      [110.0732, -7.8708],
      [110.0742, -7.8698],
      [110.0746, -7.8694],
      [110.0747, -7.8693],
      [110.0748, -7.8692],
      [110.075, -7.8689],
      [110.0755, -7.8688],
      [110.0762, -7.8686],
      [110.0771, -7.8687],
      [110.0779, -7.869],
      [110.078, -7.869],
      [110.0796, -7.8697],
      [110.0814, -7.8704],
      [110.0825, -7.871],
      [110.0835, -7.8713],
      [110.0846, -7.8714],
      [110.0855, -7.8711],
      [110.0861, -7.8702],
      [110.0863, -7.8696],
      [110.0863, -7.8685],
      [110.0861, -7.8661],
      [110.0866, -7.8645],
      [110.0876, -7.863],
      [110.0883, -7.8619],
      [110.0888, -7.8611],
      [110.0892, -7.8608],
      [110.0894, -7.8608]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KOKAP',
    village: 'HARGOREJO',
    border: [
      [110.1288, -7.8412],
      [110.1282, -7.8409],
      [110.1273, -7.8404],
      [110.1267, -7.8396],
      [110.1264, -7.8391],
      [110.1256, -7.838],
      [110.1252, -7.8374],
      [110.125, -7.837],
      [110.1245, -7.8369],
      [110.1239, -7.8367],
      [110.1236, -7.8364],
      [110.1232, -7.8362],
      [110.1231, -7.8361],
      [110.1227, -7.8359],
      [110.1219, -7.8356],
      [110.1216, -7.8357],
      [110.1211, -7.8358],
      [110.1206, -7.8358],
      [110.12, -7.8362],
      [110.1195, -7.8366],
      [110.1191, -7.8369],
      [110.1181, -7.8366],
      [110.1163, -7.8364],
      [110.1151, -7.8364],
      [110.1146, -7.8364],
      [110.113, -7.8355],
      [110.1126, -7.8353],
      [110.1092, -7.8334],
      [110.1066, -7.832],
      [110.1052, -7.8313],
      [110.1047, -7.8307],
      [110.1043, -7.8307],
      [110.104, -7.8305],
      [110.1034, -7.8306],
      [110.1031, -7.8297],
      [110.1026, -7.8292],
      [110.1023, -7.829],
      [110.1015, -7.8288],
      [110.101, -7.8285],
      [110.1003, -7.8281],
      [110.0986, -7.8273],
      [110.0979, -7.8267],
      [110.0969, -7.8259],
      [110.0945, -7.8248],
      [110.0943, -7.8246],
      [110.0938, -7.825],
      [110.0923, -7.8264],
      [110.0899, -7.8282],
      [110.0891, -7.8284],
      [110.089, -7.8285],
      [110.0882, -7.8291],
      [110.0879, -7.8297],
      [110.0878, -7.8299],
      [110.0867, -7.8311],
      [110.0859, -7.8318],
      [110.0852, -7.8326],
      [110.0849, -7.8339],
      [110.0849, -7.8347],
      [110.0849, -7.8351],
      [110.0851, -7.8363],
      [110.0851, -7.8367],
      [110.0851, -7.8371],
      [110.0835, -7.8385],
      [110.0828, -7.8396],
      [110.0821, -7.8403],
      [110.0814, -7.8406],
      [110.0817, -7.8409],
      [110.0831, -7.8418],
      [110.0842, -7.8427],
      [110.0846, -7.8439],
      [110.0849, -7.8448],
      [110.0851, -7.8455],
      [110.0863, -7.8499],
      [110.0863, -7.8501],
      [110.0875, -7.8536],
      [110.0876, -7.8538],
      [110.0878, -7.855],
      [110.0878, -7.8556],
      [110.0884, -7.8574],
      [110.0887, -7.8585],
      [110.0889, -7.8591],
      [110.0894, -7.8608],
      [110.0899, -7.8609],
      [110.0909, -7.8614],
      [110.0915, -7.8617],
      [110.0916, -7.8618],
      [110.0951, -7.8632],
      [110.0983, -7.8646],
      [110.0983, -7.8647],
      [110.0986, -7.8648],
      [110.1005, -7.8652],
      [110.1016, -7.8654],
      [110.103, -7.8657],
      [110.1045, -7.866],
      [110.1073, -7.8671],
      [110.1089, -7.8678],
      [110.1106, -7.8689],
      [110.1109, -7.8695],
      [110.1108, -7.8701],
      [110.1107, -7.8704],
      [110.1106, -7.8709],
      [110.1104, -7.8716],
      [110.1105, -7.8719],
      [110.1105, -7.8721],
      [110.1108, -7.8725],
      [110.1108, -7.873],
      [110.1108, -7.8736],
      [110.111, -7.8741],
      [110.1115, -7.874],
      [110.1119, -7.8741],
      [110.1122, -7.8743],
      [110.1122, -7.8749],
      [110.1122, -7.876],
      [110.1125, -7.876],
      [110.1131, -7.8762],
      [110.1134, -7.8766],
      [110.1134, -7.877],
      [110.1134, -7.8774],
      [110.1135, -7.8788],
      [110.1133, -7.8792],
      [110.1128, -7.8805],
      [110.113, -7.8805],
      [110.1134, -7.8802],
      [110.114, -7.8797],
      [110.1148, -7.8792],
      [110.1152, -7.8788],
      [110.1157, -7.8789],
      [110.116, -7.8788],
      [110.1162, -7.8787],
      [110.1166, -7.8785],
      [110.1168, -7.8782],
      [110.117, -7.8778],
      [110.1177, -7.8778],
      [110.1185, -7.8778],
      [110.1187, -7.8776],
      [110.1196, -7.8771],
      [110.1201, -7.877],
      [110.1209, -7.8769],
      [110.1214, -7.8768],
      [110.122, -7.8767],
      [110.1225, -7.8766],
      [110.1229, -7.8767],
      [110.1238, -7.8768],
      [110.1247, -7.8768],
      [110.1251, -7.877],
      [110.1256, -7.8771],
      [110.1261, -7.8772],
      [110.1263, -7.877],
      [110.1263, -7.8769],
      [110.1263, -7.8764],
      [110.1265, -7.8754],
      [110.1264, -7.8744],
      [110.1263, -7.8737],
      [110.1263, -7.8735],
      [110.1263, -7.8727],
      [110.1262, -7.8715],
      [110.1261, -7.8706],
      [110.1261, -7.8696],
      [110.1247, -7.8699],
      [110.1233, -7.8704],
      [110.1233, -7.8698],
      [110.1233, -7.8694],
      [110.1235, -7.8694],
      [110.1235, -7.8688],
      [110.1239, -7.8688],
      [110.1248, -7.8687],
      [110.1248, -7.8684],
      [110.1248, -7.8682],
      [110.1247, -7.8676],
      [110.1245, -7.867],
      [110.1249, -7.8668],
      [110.125, -7.8665],
      [110.125, -7.8662],
      [110.1249, -7.8656],
      [110.1252, -7.8655],
      [110.1256, -7.8653],
      [110.1258, -7.865],
      [110.1257, -7.8646],
      [110.1254, -7.8635],
      [110.1256, -7.8628],
      [110.1255, -7.8624],
      [110.1255, -7.8617],
      [110.1257, -7.8609],
      [110.1261, -7.86],
      [110.1262, -7.8593],
      [110.1266, -7.8576],
      [110.1274, -7.8566],
      [110.127, -7.8555],
      [110.1266, -7.8547],
      [110.1269, -7.8544],
      [110.1275, -7.8538],
      [110.1279, -7.8533],
      [110.1279, -7.8528],
      [110.1279, -7.8519],
      [110.1278, -7.8515],
      [110.1276, -7.8508],
      [110.1273, -7.8503],
      [110.1267, -7.85],
      [110.1264, -7.8496],
      [110.1264, -7.849],
      [110.1262, -7.8487],
      [110.126, -7.8483],
      [110.1254, -7.8481],
      [110.125, -7.8479],
      [110.1246, -7.8474],
      [110.125, -7.8469],
      [110.1254, -7.8466],
      [110.1258, -7.8461],
      [110.1259, -7.8452],
      [110.1261, -7.845],
      [110.1268, -7.8439],
      [110.1272, -7.8429],
      [110.1274, -7.8426],
      [110.1279, -7.8422],
      [110.1284, -7.8415],
      [110.1286, -7.8414],
      [110.1288, -7.8412]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KOKAP',
    village: 'HARGOWILIS',
    border: [
      [110.1309, -7.7845],
      [110.1303, -7.7842],
      [110.1296, -7.7836],
      [110.1289, -7.7833],
      [110.1279, -7.7828],
      [110.1274, -7.7826],
      [110.127, -7.7829],
      [110.1266, -7.7834],
      [110.1264, -7.7843],
      [110.1262, -7.7856],
      [110.1261, -7.7858],
      [110.126, -7.7862],
      [110.1254, -7.788],
      [110.125, -7.7886],
      [110.1243, -7.7886],
      [110.1237, -7.7886],
      [110.1234, -7.7887],
      [110.1231, -7.7885],
      [110.123, -7.7881],
      [110.1229, -7.7877],
      [110.1222, -7.7875],
      [110.122, -7.7875],
      [110.1214, -7.7883],
      [110.1206, -7.7895],
      [110.12, -7.791],
      [110.1201, -7.7918],
      [110.1205, -7.7931],
      [110.1206, -7.7937],
      [110.1206, -7.7939],
      [110.1203, -7.7942],
      [110.1196, -7.7947],
      [110.1189, -7.7953],
      [110.1187, -7.7956],
      [110.1186, -7.796],
      [110.1193, -7.7965],
      [110.1196, -7.7974],
      [110.1196, -7.798],
      [110.1197, -7.7982],
      [110.1194, -7.7992],
      [110.119, -7.7998],
      [110.1177, -7.8006],
      [110.1165, -7.8015],
      [110.1158, -7.8022],
      [110.1156, -7.8026],
      [110.1149, -7.8035],
      [110.1141, -7.8047],
      [110.114, -7.805],
      [110.114, -7.8055],
      [110.1134, -7.8063],
      [110.1126, -7.8069],
      [110.1121, -7.8071],
      [110.1117, -7.8077],
      [110.1114, -7.8086],
      [110.1115, -7.8096],
      [110.1109, -7.8104],
      [110.1105, -7.8107],
      [110.1103, -7.8109],
      [110.1101, -7.811],
      [110.1096, -7.8114],
      [110.1084, -7.8118],
      [110.1071, -7.8119],
      [110.1065, -7.8124],
      [110.1055, -7.8131],
      [110.1047, -7.8132],
      [110.1032, -7.8133],
      [110.1016, -7.8134],
      [110.1011, -7.8136],
      [110.1005, -7.8141],
      [110.1, -7.8139],
      [110.0997, -7.8136],
      [110.0995, -7.8137],
      [110.099, -7.814],
      [110.0987, -7.8139],
      [110.0981, -7.8136],
      [110.0978, -7.8134],
      [110.0974, -7.8134],
      [110.0967, -7.8137],
      [110.0963, -7.8137],
      [110.0954, -7.8138],
      [110.0944, -7.8144],
      [110.0938, -7.8147],
      [110.0929, -7.8155],
      [110.0924, -7.8155],
      [110.0913, -7.8153],
      [110.0909, -7.8154],
      [110.0906, -7.8159],
      [110.0901, -7.8163],
      [110.0898, -7.8167],
      [110.089, -7.8172],
      [110.0896, -7.818],
      [110.0904, -7.8192],
      [110.0912, -7.8209],
      [110.0916, -7.8218],
      [110.0918, -7.8221],
      [110.0933, -7.8237],
      [110.0943, -7.8246],
      [110.0945, -7.8248],
      [110.0969, -7.8259],
      [110.0979, -7.8267],
      [110.0986, -7.8273],
      [110.1003, -7.8281],
      [110.101, -7.8285],
      [110.1015, -7.8288],
      [110.1023, -7.829],
      [110.1026, -7.8292],
      [110.1031, -7.8297],
      [110.1034, -7.8306],
      [110.104, -7.8305],
      [110.1043, -7.8307],
      [110.1047, -7.8307],
      [110.1052, -7.8313],
      [110.1066, -7.832],
      [110.1092, -7.8334],
      [110.1126, -7.8353],
      [110.113, -7.8355],
      [110.1146, -7.8364],
      [110.1151, -7.8364],
      [110.1163, -7.8364],
      [110.1181, -7.8366],
      [110.1191, -7.8369],
      [110.1195, -7.8366],
      [110.12, -7.8362],
      [110.1206, -7.8358],
      [110.1211, -7.8358],
      [110.1216, -7.8357],
      [110.1219, -7.8356],
      [110.1227, -7.8359],
      [110.1231, -7.8361],
      [110.1232, -7.8362],
      [110.1236, -7.8364],
      [110.1239, -7.8367],
      [110.1245, -7.8369],
      [110.125, -7.837],
      [110.1252, -7.8374],
      [110.1256, -7.838],
      [110.1264, -7.8391],
      [110.1267, -7.8396],
      [110.1273, -7.8404],
      [110.1282, -7.8409],
      [110.1288, -7.8412],
      [110.1292, -7.841],
      [110.1296, -7.8399],
      [110.1303, -7.8392],
      [110.1307, -7.8383],
      [110.1307, -7.8376],
      [110.1304, -7.8365],
      [110.13, -7.8356],
      [110.1296, -7.8347],
      [110.1291, -7.8328],
      [110.1291, -7.832],
      [110.129, -7.8308],
      [110.1288, -7.8303],
      [110.1284, -7.8296],
      [110.1277, -7.8289],
      [110.1275, -7.8281],
      [110.1274, -7.8275],
      [110.1276, -7.8268],
      [110.1277, -7.8262],
      [110.1275, -7.8256],
      [110.1271, -7.8245],
      [110.1272, -7.824],
      [110.1277, -7.8235],
      [110.1277, -7.8231],
      [110.1278, -7.8229],
      [110.1278, -7.8228],
      [110.1278, -7.8223],
      [110.1276, -7.8221],
      [110.1273, -7.8218],
      [110.1271, -7.8212],
      [110.127, -7.821],
      [110.1266, -7.8201],
      [110.1261, -7.8198],
      [110.1258, -7.8189],
      [110.1256, -7.8187],
      [110.1252, -7.8184],
      [110.1249, -7.8177],
      [110.1247, -7.8171],
      [110.1245, -7.8169],
      [110.1243, -7.8167],
      [110.1243, -7.8164],
      [110.1246, -7.8163],
      [110.1249, -7.8162],
      [110.1252, -7.816],
      [110.1253, -7.8156],
      [110.1255, -7.8151],
      [110.1257, -7.8148],
      [110.1257, -7.8145],
      [110.1256, -7.8142],
      [110.1254, -7.8139],
      [110.1254, -7.8136],
      [110.1256, -7.8134],
      [110.1258, -7.8129],
      [110.1258, -7.8126],
      [110.1257, -7.8124],
      [110.1254, -7.8119],
      [110.1254, -7.8116],
      [110.1255, -7.8104],
      [110.1256, -7.8094],
      [110.1257, -7.8079],
      [110.126, -7.8077],
      [110.1264, -7.8071],
      [110.1269, -7.8066],
      [110.1273, -7.8064],
      [110.1277, -7.8063],
      [110.1284, -7.8066],
      [110.1292, -7.8073],
      [110.13, -7.8079],
      [110.1306, -7.8079],
      [110.131, -7.808],
      [110.1314, -7.8082],
      [110.1316, -7.8084],
      [110.1318, -7.8087],
      [110.1321, -7.8094],
      [110.1324, -7.8098],
      [110.1327, -7.8098],
      [110.133, -7.8095],
      [110.1334, -7.8092],
      [110.1336, -7.8089],
      [110.1339, -7.8084],
      [110.134, -7.8082],
      [110.1337, -7.8079],
      [110.1337, -7.8074],
      [110.1335, -7.8072],
      [110.1331, -7.8071],
      [110.1328, -7.8068],
      [110.1327, -7.8066],
      [110.1323, -7.8064],
      [110.1321, -7.806],
      [110.1318, -7.8059],
      [110.1313, -7.8057],
      [110.1312, -7.805],
      [110.1309, -7.8044],
      [110.1307, -7.8037],
      [110.1312, -7.8037],
      [110.1315, -7.8036],
      [110.1317, -7.8032],
      [110.1322, -7.8019],
      [110.1325, -7.8017],
      [110.133, -7.8016],
      [110.1341, -7.8015],
      [110.1353, -7.8012],
      [110.136, -7.8012],
      [110.1372, -7.801],
      [110.138, -7.8005],
      [110.1387, -7.8001],
      [110.1394, -7.8],
      [110.1398, -7.7999],
      [110.1409, -7.7995],
      [110.1424, -7.7991],
      [110.1427, -7.7987],
      [110.1429, -7.7985],
      [110.143, -7.7984],
      [110.1432, -7.7982],
      [110.1434, -7.7977],
      [110.1429, -7.7962],
      [110.1415, -7.7937],
      [110.1409, -7.7931],
      [110.1401, -7.7927],
      [110.139, -7.792],
      [110.1379, -7.7914],
      [110.1362, -7.7909],
      [110.1353, -7.7901],
      [110.1349, -7.7892],
      [110.1344, -7.7877],
      [110.1341, -7.7869],
      [110.1335, -7.7867],
      [110.1326, -7.7863],
      [110.132, -7.786],
      [110.1312, -7.7854],
      [110.1311, -7.7849],
      [110.1309, -7.7845]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KOKAP',
    village: 'KALIREJO',
    border: [
      [110.089, -7.8172],
      [110.0875, -7.8153],
      [110.0868, -7.8144],
      [110.0843, -7.8114],
      [110.0826, -7.8095],
      [110.0816, -7.8093],
      [110.0801, -7.8068],
      [110.08, -7.8066],
      [110.0784, -7.8067],
      [110.0758, -7.8081],
      [110.0733, -7.8092],
      [110.072, -7.8093],
      [110.0699, -7.8092],
      [110.0692, -7.8091],
      [110.0673, -7.8093],
      [110.0655, -7.8101],
      [110.0651, -7.8104],
      [110.0645, -7.8108],
      [110.0637, -7.8121],
      [110.0635, -7.8122],
      [110.0626, -7.8131],
      [110.0624, -7.8141],
      [110.0624, -7.8146],
      [110.0623, -7.8152],
      [110.0623, -7.8159],
      [110.0625, -7.8176],
      [110.0623, -7.8181],
      [110.0622, -7.8184],
      [110.0622, -7.8187],
      [110.0613, -7.8193],
      [110.0612, -7.8195],
      [110.0611, -7.8196],
      [110.061, -7.8199],
      [110.0608, -7.8202],
      [110.0606, -7.8209],
      [110.0605, -7.8214],
      [110.0602, -7.8232],
      [110.0601, -7.8237],
      [110.06, -7.8241],
      [110.0599, -7.8248],
      [110.0597, -7.8258],
      [110.0595, -7.8265],
      [110.0591, -7.8278],
      [110.0591, -7.8285],
      [110.0592, -7.8288],
      [110.0592, -7.8299],
      [110.0592, -7.8302],
      [110.0593, -7.8309],
      [110.0592, -7.8316],
      [110.0591, -7.832],
      [110.059, -7.8325],
      [110.059, -7.8327],
      [110.059, -7.8331],
      [110.0589, -7.8333],
      [110.0588, -7.8341],
      [110.0587, -7.8343],
      [110.0587, -7.8346],
      [110.0587, -7.8351],
      [110.0586, -7.8357],
      [110.0584, -7.8362],
      [110.058, -7.8367],
      [110.0571, -7.8373],
      [110.0558, -7.838],
      [110.0556, -7.8381],
      [110.0552, -7.8382],
      [110.055, -7.8383],
      [110.0546, -7.8385],
      [110.0537, -7.8391],
      [110.0501, -7.8416],
      [110.0504, -7.8435],
      [110.051, -7.8445],
      [110.0512, -7.8447],
      [110.0517, -7.8455],
      [110.0516, -7.8457],
      [110.0523, -7.8462],
      [110.053, -7.8464],
      [110.0549, -7.8467],
      [110.0562, -7.8469],
      [110.0571, -7.847],
      [110.0591, -7.8472],
      [110.0608, -7.8471],
      [110.0625, -7.8472],
      [110.0644, -7.8469],
      [110.0655, -7.8465],
      [110.0671, -7.8459],
      [110.0688, -7.8451],
      [110.0716, -7.8437],
      [110.0756, -7.8419],
      [110.0768, -7.8417],
      [110.0797, -7.8412],
      [110.0808, -7.8409],
      [110.0814, -7.8406],
      [110.0821, -7.8403],
      [110.0828, -7.8396],
      [110.0835, -7.8385],
      [110.0851, -7.8371],
      [110.0851, -7.8367],
      [110.0851, -7.8363],
      [110.0849, -7.8351],
      [110.0849, -7.8347],
      [110.0849, -7.8339],
      [110.0852, -7.8326],
      [110.0859, -7.8318],
      [110.0867, -7.8311],
      [110.0878, -7.8299],
      [110.0879, -7.8297],
      [110.0882, -7.8291],
      [110.089, -7.8285],
      [110.0891, -7.8284],
      [110.0899, -7.8282],
      [110.0923, -7.8264],
      [110.0938, -7.825],
      [110.0943, -7.8246],
      [110.0933, -7.8237],
      [110.0918, -7.8221],
      [110.0916, -7.8218],
      [110.0912, -7.8209],
      [110.0904, -7.8192],
      [110.0896, -7.818],
      [110.089, -7.8172]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KOKAP',
    village: 'HARGOTIRTO',
    border: [
      [110.1107, -7.778],
      [110.1104, -7.7781],
      [110.1102, -7.7782],
      [110.1098, -7.7781],
      [110.1091, -7.7778],
      [110.1083, -7.778],
      [110.1077, -7.7782],
      [110.1071, -7.7784],
      [110.1066, -7.7787],
      [110.1064, -7.7788],
      [110.1062, -7.7788],
      [110.1061, -7.7787],
      [110.106, -7.7787],
      [110.1058, -7.7784],
      [110.105, -7.7778],
      [110.1038, -7.7769],
      [110.1035, -7.7764],
      [110.1025, -7.7755],
      [110.1017, -7.7751],
      [110.1011, -7.7757],
      [110.1008, -7.7764],
      [110.1008, -7.7766],
      [110.1007, -7.7773],
      [110.1005, -7.7782],
      [110.1003, -7.7795],
      [110.0998, -7.7804],
      [110.0996, -7.7809],
      [110.0994, -7.7814],
      [110.0988, -7.7822],
      [110.0983, -7.783],
      [110.0976, -7.7834],
      [110.0969, -7.7836],
      [110.0963, -7.7836],
      [110.0959, -7.7838],
      [110.0953, -7.7843],
      [110.0951, -7.7844],
      [110.0948, -7.7849],
      [110.0947, -7.7856],
      [110.0942, -7.7857],
      [110.0939, -7.7858],
      [110.0933, -7.7858],
      [110.0927, -7.7864],
      [110.0925, -7.7869],
      [110.0924, -7.7871],
      [110.0926, -7.7873],
      [110.0926, -7.7875],
      [110.0921, -7.7874],
      [110.0918, -7.7875],
      [110.0917, -7.7878],
      [110.0916, -7.7882],
      [110.0913, -7.7887],
      [110.0906, -7.7901],
      [110.0904, -7.7906],
      [110.0896, -7.7916],
      [110.0893, -7.7921],
      [110.0891, -7.7924],
      [110.0883, -7.7935],
      [110.0881, -7.7942],
      [110.0879, -7.7951],
      [110.0876, -7.7961],
      [110.0874, -7.7968],
      [110.0873, -7.797],
      [110.087, -7.7973],
      [110.0857, -7.7987],
      [110.0845, -7.8006],
      [110.0839, -7.8014],
      [110.0833, -7.8022],
      [110.0827, -7.8033],
      [110.0823, -7.8042],
      [110.0821, -7.8047],
      [110.0819, -7.8052],
      [110.0811, -7.8068],
      [110.0806, -7.8067],
      [110.08, -7.8066],
      [110.0801, -7.8068],
      [110.0816, -7.8093],
      [110.0826, -7.8095],
      [110.0843, -7.8114],
      [110.0868, -7.8144],
      [110.0875, -7.8153],
      [110.089, -7.8172],
      [110.0898, -7.8167],
      [110.0901, -7.8163],
      [110.0906, -7.8159],
      [110.0909, -7.8154],
      [110.0913, -7.8153],
      [110.0924, -7.8155],
      [110.0929, -7.8155],
      [110.0938, -7.8147],
      [110.0944, -7.8144],
      [110.0954, -7.8138],
      [110.0963, -7.8137],
      [110.0967, -7.8137],
      [110.0974, -7.8134],
      [110.0978, -7.8134],
      [110.0981, -7.8136],
      [110.0987, -7.8139],
      [110.099, -7.814],
      [110.0995, -7.8137],
      [110.0997, -7.8136],
      [110.1, -7.8139],
      [110.1005, -7.8141],
      [110.1011, -7.8136],
      [110.1016, -7.8134],
      [110.1032, -7.8133],
      [110.1047, -7.8132],
      [110.1055, -7.8131],
      [110.1065, -7.8124],
      [110.1071, -7.8119],
      [110.1084, -7.8118],
      [110.1096, -7.8114],
      [110.1101, -7.811],
      [110.1103, -7.8109],
      [110.1105, -7.8107],
      [110.1109, -7.8104],
      [110.1115, -7.8096],
      [110.1114, -7.8086],
      [110.1117, -7.8077],
      [110.1121, -7.8071],
      [110.1126, -7.8069],
      [110.1134, -7.8063],
      [110.114, -7.8055],
      [110.114, -7.805],
      [110.1141, -7.8047],
      [110.1149, -7.8035],
      [110.1156, -7.8026],
      [110.1158, -7.8022],
      [110.1165, -7.8015],
      [110.1177, -7.8006],
      [110.119, -7.7998],
      [110.1194, -7.7992],
      [110.1197, -7.7982],
      [110.1196, -7.798],
      [110.1196, -7.7974],
      [110.1193, -7.7965],
      [110.1186, -7.796],
      [110.1187, -7.7956],
      [110.1189, -7.7953],
      [110.1196, -7.7947],
      [110.1203, -7.7942],
      [110.1206, -7.7939],
      [110.1206, -7.7937],
      [110.1205, -7.7931],
      [110.1201, -7.7918],
      [110.12, -7.791],
      [110.1206, -7.7895],
      [110.1214, -7.7883],
      [110.122, -7.7875],
      [110.1217, -7.7874],
      [110.1213, -7.7869],
      [110.121, -7.7865],
      [110.1208, -7.7863],
      [110.1205, -7.7861],
      [110.1201, -7.7859],
      [110.1201, -7.7855],
      [110.1198, -7.7855],
      [110.1197, -7.7852],
      [110.1197, -7.7848],
      [110.1195, -7.7846],
      [110.119, -7.7845],
      [110.1181, -7.7838],
      [110.117, -7.7826],
      [110.1167, -7.7824],
      [110.1155, -7.782],
      [110.1143, -7.7812],
      [110.113, -7.7801],
      [110.1119, -7.7792],
      [110.1113, -7.7785],
      [110.1107, -7.778]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GIRIMULYO',
    village: 'JATIMULYO',
    border: [
      [110.1505, -7.7363],
      [110.1488, -7.7357],
      [110.1451, -7.7343],
      [110.144, -7.7336],
      [110.1427, -7.7326],
      [110.1421, -7.7319],
      [110.1416, -7.7307],
      [110.1412, -7.7301],
      [110.1407, -7.7301],
      [110.1402, -7.7304],
      [110.1399, -7.7309],
      [110.1396, -7.7319],
      [110.1395, -7.7331],
      [110.1391, -7.734],
      [110.1382, -7.7349],
      [110.1371, -7.7359],
      [110.1369, -7.7364],
      [110.1367, -7.7371],
      [110.1366, -7.7381],
      [110.1364, -7.7388],
      [110.1361, -7.7396],
      [110.1363, -7.7403],
      [110.136, -7.7409],
      [110.1355, -7.7412],
      [110.1349, -7.7419],
      [110.1336, -7.7428],
      [110.1332, -7.7434],
      [110.1329, -7.744],
      [110.1325, -7.7443],
      [110.1319, -7.7447],
      [110.1317, -7.745],
      [110.1316, -7.7454],
      [110.1314, -7.7459],
      [110.1312, -7.7462],
      [110.1308, -7.7464],
      [110.1306, -7.7466],
      [110.1303, -7.7471],
      [110.1296, -7.7475],
      [110.1295, -7.7475],
      [110.1297, -7.7484],
      [110.1291, -7.7485],
      [110.1285, -7.7487],
      [110.1278, -7.7491],
      [110.1271, -7.7493],
      [110.1266, -7.7495],
      [110.1257, -7.75],
      [110.1254, -7.7502],
      [110.1251, -7.7505],
      [110.1243, -7.7511],
      [110.1235, -7.7517],
      [110.1225, -7.752],
      [110.1215, -7.7539],
      [110.1201, -7.7561],
      [110.1189, -7.7577],
      [110.1189, -7.7578],
      [110.118, -7.7584],
      [110.1169, -7.761],
      [110.1166, -7.7614],
      [110.1157, -7.7626],
      [110.1147, -7.7639],
      [110.1141, -7.7645],
      [110.1138, -7.7652],
      [110.1136, -7.7656],
      [110.1137, -7.7662],
      [110.1137, -7.7671],
      [110.114, -7.7683],
      [110.1141, -7.7686],
      [110.1143, -7.7692],
      [110.1145, -7.7696],
      [110.1145, -7.7698],
      [110.1145, -7.7705],
      [110.1141, -7.7711],
      [110.1139, -7.7715],
      [110.1134, -7.7719],
      [110.1128, -7.7719],
      [110.1124, -7.7722],
      [110.1122, -7.7724],
      [110.1121, -7.7729],
      [110.112, -7.7734],
      [110.1121, -7.7744],
      [110.112, -7.7748],
      [110.1114, -7.7773],
      [110.1113, -7.7774],
      [110.1109, -7.7778],
      [110.1108, -7.7779],
      [110.1107, -7.778],
      [110.1113, -7.7785],
      [110.1119, -7.7792],
      [110.113, -7.7801],
      [110.1143, -7.7812],
      [110.1155, -7.782],
      [110.1167, -7.7824],
      [110.117, -7.7826],
      [110.1181, -7.7838],
      [110.119, -7.7845],
      [110.1195, -7.7846],
      [110.1197, -7.7848],
      [110.1197, -7.7852],
      [110.1198, -7.7855],
      [110.1201, -7.7855],
      [110.1201, -7.7859],
      [110.1205, -7.7861],
      [110.1208, -7.7863],
      [110.121, -7.7865],
      [110.1213, -7.7869],
      [110.1217, -7.7874],
      [110.122, -7.7875],
      [110.1222, -7.7875],
      [110.1229, -7.7877],
      [110.123, -7.7881],
      [110.1231, -7.7885],
      [110.1234, -7.7887],
      [110.1237, -7.7886],
      [110.1243, -7.7886],
      [110.125, -7.7886],
      [110.1254, -7.788],
      [110.126, -7.7862],
      [110.1261, -7.7858],
      [110.1262, -7.7856],
      [110.1264, -7.7843],
      [110.1266, -7.7834],
      [110.127, -7.7829],
      [110.1274, -7.7826],
      [110.1279, -7.7828],
      [110.1289, -7.7833],
      [110.1296, -7.7836],
      [110.1303, -7.7842],
      [110.1309, -7.7845],
      [110.1315, -7.7844],
      [110.132, -7.7839],
      [110.1323, -7.7834],
      [110.1323, -7.7829],
      [110.1326, -7.7819],
      [110.1328, -7.7811],
      [110.1326, -7.7807],
      [110.1326, -7.7804],
      [110.1325, -7.7801],
      [110.1326, -7.7796],
      [110.1331, -7.7791],
      [110.1339, -7.7789],
      [110.1348, -7.7791],
      [110.1352, -7.7795],
      [110.1364, -7.7802],
      [110.1378, -7.7807],
      [110.1386, -7.7805],
      [110.1392, -7.78],
      [110.1397, -7.7787],
      [110.1399, -7.7781],
      [110.1399, -7.7778],
      [110.1399, -7.777],
      [110.1402, -7.7767],
      [110.1404, -7.7763],
      [110.1402, -7.7761],
      [110.1402, -7.7759],
      [110.1403, -7.7751],
      [110.1405, -7.7746],
      [110.1402, -7.7742],
      [110.1399, -7.7736],
      [110.1396, -7.7734],
      [110.1393, -7.7729],
      [110.1392, -7.7725],
      [110.1391, -7.7722],
      [110.1387, -7.7717],
      [110.1379, -7.7698],
      [110.1373, -7.7683],
      [110.1373, -7.7674],
      [110.1374, -7.7668],
      [110.1379, -7.7661],
      [110.138, -7.7657],
      [110.138, -7.7648],
      [110.1382, -7.7643],
      [110.1384, -7.7642],
      [110.1385, -7.7639],
      [110.1384, -7.7633],
      [110.138, -7.7629],
      [110.1379, -7.7624],
      [110.1384, -7.7623],
      [110.1399, -7.7621],
      [110.1408, -7.7619],
      [110.1417, -7.7616],
      [110.1425, -7.7609],
      [110.1427, -7.7608],
      [110.144, -7.7598],
      [110.145, -7.7597],
      [110.1461, -7.7601],
      [110.1463, -7.7601],
      [110.1465, -7.7604],
      [110.1473, -7.761],
      [110.1484, -7.762],
      [110.1492, -7.7633],
      [110.1498, -7.764],
      [110.1505, -7.7648],
      [110.1507, -7.7652],
      [110.1509, -7.7658],
      [110.1508, -7.7672],
      [110.151, -7.768],
      [110.1515, -7.7688],
      [110.152, -7.7694],
      [110.1525, -7.77],
      [110.1528, -7.77],
      [110.1534, -7.7699],
      [110.1541, -7.7693],
      [110.1552, -7.7681],
      [110.1556, -7.7677],
      [110.1565, -7.7668],
      [110.1571, -7.766],
      [110.1586, -7.7654],
      [110.1593, -7.7648],
      [110.1605, -7.7637],
      [110.1613, -7.7628],
      [110.162, -7.7628],
      [110.1622, -7.7623],
      [110.1623, -7.7617],
      [110.1621, -7.7612],
      [110.162, -7.7611],
      [110.1618, -7.7605],
      [110.1612, -7.7601],
      [110.1605, -7.7594],
      [110.1601, -7.7591],
      [110.1599, -7.7585],
      [110.1596, -7.7582],
      [110.1593, -7.758],
      [110.1587, -7.758],
      [110.1582, -7.7577],
      [110.1576, -7.7577],
      [110.1573, -7.7573],
      [110.157, -7.7573],
      [110.1569, -7.7574],
      [110.1568, -7.7575],
      [110.1566, -7.7577],
      [110.1563, -7.7577],
      [110.1561, -7.7577],
      [110.1556, -7.7575],
      [110.1556, -7.7568],
      [110.1554, -7.7563],
      [110.1549, -7.7558],
      [110.1546, -7.7554],
      [110.1547, -7.7548],
      [110.1547, -7.7542],
      [110.1541, -7.7539],
      [110.1541, -7.7536],
      [110.1541, -7.7531],
      [110.1539, -7.7526],
      [110.1541, -7.752],
      [110.1539, -7.7515],
      [110.1535, -7.7512],
      [110.1533, -7.751],
      [110.153, -7.7506],
      [110.1527, -7.7502],
      [110.1525, -7.7499],
      [110.1521, -7.7496],
      [110.1517, -7.7492],
      [110.1516, -7.749],
      [110.1515, -7.7488],
      [110.1512, -7.7483],
      [110.1509, -7.7483],
      [110.1507, -7.7477],
      [110.1507, -7.7469],
      [110.1511, -7.7463],
      [110.1518, -7.7462],
      [110.1526, -7.7459],
      [110.1527, -7.7453],
      [110.1527, -7.7452],
      [110.1528, -7.7442],
      [110.1526, -7.7439],
      [110.1521, -7.7432],
      [110.1511, -7.7422],
      [110.1513, -7.7418],
      [110.151, -7.7415],
      [110.1502, -7.7409],
      [110.1496, -7.7402],
      [110.1496, -7.739],
      [110.1499, -7.7379],
      [110.1503, -7.7369],
      [110.1505, -7.7363]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GIRIMULYO',
    village: 'GIRIPURWO',
    border: [
      [110.155, -7.7356],
      [110.1549, -7.7355],
      [110.1548, -7.7353],
      [110.1543, -7.7348],
      [110.1538, -7.735],
      [110.1535, -7.7349],
      [110.1532, -7.735],
      [110.153, -7.7351],
      [110.1523, -7.7352],
      [110.1522, -7.7356],
      [110.1519, -7.736],
      [110.1517, -7.7362],
      [110.1513, -7.7362],
      [110.1509, -7.736],
      [110.1506, -7.736],
      [110.1506, -7.7361],
      [110.1505, -7.7363],
      [110.1503, -7.7369],
      [110.1499, -7.7379],
      [110.1496, -7.739],
      [110.1496, -7.7402],
      [110.1502, -7.7409],
      [110.151, -7.7415],
      [110.1513, -7.7418],
      [110.1511, -7.7422],
      [110.1521, -7.7432],
      [110.1526, -7.7439],
      [110.1528, -7.7442],
      [110.1527, -7.7452],
      [110.1527, -7.7453],
      [110.1526, -7.7459],
      [110.1518, -7.7462],
      [110.1511, -7.7463],
      [110.1507, -7.7469],
      [110.1507, -7.7477],
      [110.1509, -7.7483],
      [110.1512, -7.7483],
      [110.1515, -7.7488],
      [110.1516, -7.749],
      [110.1517, -7.7492],
      [110.1521, -7.7496],
      [110.1525, -7.7499],
      [110.1527, -7.7502],
      [110.153, -7.7506],
      [110.1533, -7.751],
      [110.1535, -7.7512],
      [110.1539, -7.7515],
      [110.1541, -7.752],
      [110.1539, -7.7526],
      [110.1541, -7.7531],
      [110.1541, -7.7536],
      [110.1541, -7.7539],
      [110.1547, -7.7542],
      [110.1547, -7.7548],
      [110.1546, -7.7554],
      [110.1549, -7.7558],
      [110.1554, -7.7563],
      [110.1556, -7.7568],
      [110.1556, -7.7575],
      [110.1561, -7.7577],
      [110.1563, -7.7577],
      [110.1566, -7.7577],
      [110.1568, -7.7575],
      [110.1569, -7.7574],
      [110.157, -7.7573],
      [110.1573, -7.7573],
      [110.1576, -7.7577],
      [110.1582, -7.7577],
      [110.1587, -7.758],
      [110.1593, -7.758],
      [110.1596, -7.7582],
      [110.1599, -7.7585],
      [110.1601, -7.7591],
      [110.1605, -7.7594],
      [110.1612, -7.7601],
      [110.1618, -7.7605],
      [110.162, -7.7611],
      [110.1621, -7.7612],
      [110.1623, -7.7617],
      [110.1622, -7.7623],
      [110.162, -7.7628],
      [110.1613, -7.7628],
      [110.1605, -7.7637],
      [110.1593, -7.7648],
      [110.1586, -7.7654],
      [110.1571, -7.766],
      [110.1565, -7.7668],
      [110.1556, -7.7677],
      [110.1552, -7.7681],
      [110.1541, -7.7693],
      [110.1534, -7.7699],
      [110.1528, -7.77],
      [110.1525, -7.77],
      [110.1528, -7.7701],
      [110.153, -7.7703],
      [110.1532, -7.7706],
      [110.1534, -7.771],
      [110.1537, -7.7712],
      [110.1541, -7.7716],
      [110.1548, -7.7718],
      [110.1551, -7.7718],
      [110.156, -7.7722],
      [110.1565, -7.7724],
      [110.1572, -7.773],
      [110.1578, -7.7732],
      [110.1581, -7.7736],
      [110.1585, -7.7736],
      [110.1586, -7.774],
      [110.1588, -7.7741],
      [110.1591, -7.774],
      [110.1593, -7.7743],
      [110.1594, -7.7747],
      [110.1597, -7.7749],
      [110.1599, -7.7753],
      [110.1601, -7.7756],
      [110.1603, -7.7759],
      [110.1602, -7.7763],
      [110.16, -7.7765],
      [110.1597, -7.7769],
      [110.1597, -7.7772],
      [110.1597, -7.7775],
      [110.1594, -7.7775],
      [110.1592, -7.7776],
      [110.159, -7.7781],
      [110.159, -7.7785],
      [110.1588, -7.7787],
      [110.1585, -7.7791],
      [110.1584, -7.7797],
      [110.1583, -7.7798],
      [110.1582, -7.78],
      [110.158, -7.7802],
      [110.1578, -7.7804],
      [110.158, -7.7808],
      [110.1581, -7.7811],
      [110.1579, -7.7813],
      [110.1577, -7.7815],
      [110.1576, -7.7818],
      [110.1576, -7.7821],
      [110.1578, -7.7823],
      [110.1581, -7.7823],
      [110.1584, -7.7823],
      [110.1586, -7.7825],
      [110.159, -7.7829],
      [110.159, -7.7832],
      [110.1593, -7.7834],
      [110.1599, -7.7836],
      [110.1605, -7.784],
      [110.1609, -7.7843],
      [110.1612, -7.7845],
      [110.1615, -7.7845],
      [110.1618, -7.7842],
      [110.1619, -7.7839],
      [110.1621, -7.784],
      [110.1624, -7.7842],
      [110.1627, -7.7844],
      [110.1627, -7.7845],
      [110.1624, -7.7846],
      [110.1619, -7.7848],
      [110.1617, -7.7851],
      [110.1617, -7.7854],
      [110.1619, -7.7857],
      [110.1621, -7.7858],
      [110.1625, -7.786],
      [110.163, -7.7861],
      [110.1634, -7.7863],
      [110.1639, -7.7862],
      [110.1642, -7.7861],
      [110.1649, -7.786],
      [110.1651, -7.7862],
      [110.1654, -7.7866],
      [110.1658, -7.7868],
      [110.1663, -7.787],
      [110.1664, -7.7874],
      [110.1666, -7.7879],
      [110.1669, -7.7882],
      [110.1675, -7.7881],
      [110.1677, -7.7883],
      [110.168, -7.7884],
      [110.1691, -7.7882],
      [110.1696, -7.7882],
      [110.17, -7.7884],
      [110.1704, -7.7887],
      [110.1709, -7.7886],
      [110.1711, -7.7886],
      [110.1718, -7.7887],
      [110.1721, -7.7886],
      [110.1728, -7.7887],
      [110.1733, -7.7886],
      [110.1741, -7.7886],
      [110.1746, -7.7887],
      [110.1751, -7.7888],
      [110.1756, -7.7886],
      [110.1756, -7.7882],
      [110.1757, -7.7877],
      [110.1759, -7.7871],
      [110.1759, -7.7865],
      [110.1758, -7.7858],
      [110.176, -7.785],
      [110.1775, -7.7852],
      [110.1787, -7.7852],
      [110.1794, -7.7855],
      [110.1795, -7.7855],
      [110.1798, -7.7855],
      [110.1802, -7.7854],
      [110.1803, -7.7853],
      [110.1808, -7.7847],
      [110.1816, -7.7847],
      [110.182, -7.7845],
      [110.1827, -7.7845],
      [110.1834, -7.7848],
      [110.1855, -7.7855],
      [110.1868, -7.7859],
      [110.1869, -7.7858],
      [110.1872, -7.7858],
      [110.1874, -7.7858],
      [110.1879, -7.7857],
      [110.1879, -7.7855],
      [110.1879, -7.7852],
      [110.1882, -7.7846],
      [110.1883, -7.7843],
      [110.1888, -7.7843],
      [110.1889, -7.7842],
      [110.1891, -7.7837],
      [110.1889, -7.7834],
      [110.1889, -7.7829],
      [110.1884, -7.7827],
      [110.1882, -7.7824],
      [110.1883, -7.782],
      [110.1879, -7.7816],
      [110.1877, -7.781],
      [110.1877, -7.7806],
      [110.1878, -7.7801],
      [110.188, -7.7798],
      [110.1883, -7.7796],
      [110.1884, -7.7793],
      [110.1882, -7.7789],
      [110.1883, -7.7787],
      [110.1885, -7.7785],
      [110.1884, -7.7782],
      [110.1883, -7.7779],
      [110.1885, -7.7779],
      [110.1886, -7.7779],
      [110.1888, -7.7775],
      [110.1889, -7.7772],
      [110.1893, -7.777],
      [110.1894, -7.777],
      [110.1894, -7.7766],
      [110.1893, -7.7761],
      [110.1897, -7.7758],
      [110.1899, -7.7754],
      [110.1899, -7.7753],
      [110.1904, -7.7746],
      [110.1905, -7.7746],
      [110.1908, -7.7745],
      [110.1909, -7.7741],
      [110.1911, -7.7738],
      [110.1915, -7.7738],
      [110.1916, -7.7739],
      [110.1917, -7.7739],
      [110.192, -7.7739],
      [110.1921, -7.7738],
      [110.1926, -7.7737],
      [110.1927, -7.7736],
      [110.193, -7.7734],
      [110.193, -7.773],
      [110.193, -7.7726],
      [110.193, -7.7723],
      [110.1927, -7.7716],
      [110.1925, -7.7713],
      [110.1921, -7.7708],
      [110.1916, -7.7704],
      [110.1916, -7.7698],
      [110.1916, -7.769],
      [110.1919, -7.7685],
      [110.1925, -7.7678],
      [110.1934, -7.7673],
      [110.1946, -7.7668],
      [110.1942, -7.7663],
      [110.1937, -7.7657],
      [110.1932, -7.7654],
      [110.193, -7.7647],
      [110.1919, -7.7643],
      [110.1913, -7.7641],
      [110.1901, -7.7633],
      [110.1895, -7.7626],
      [110.1879, -7.7614],
      [110.1866, -7.7606],
      [110.1854, -7.7603],
      [110.1847, -7.7596],
      [110.1842, -7.7586],
      [110.1841, -7.7579],
      [110.1835, -7.7571],
      [110.1834, -7.7567],
      [110.183, -7.7562],
      [110.1819, -7.7551],
      [110.181, -7.7543],
      [110.1806, -7.7536],
      [110.1793, -7.7521],
      [110.1791, -7.7514],
      [110.1791, -7.7508],
      [110.1789, -7.75],
      [110.1786, -7.7494],
      [110.1779, -7.7482],
      [110.1771, -7.7475],
      [110.1759, -7.7465],
      [110.1749, -7.746],
      [110.1739, -7.746],
      [110.1727, -7.7461],
      [110.1718, -7.7456],
      [110.1713, -7.7456],
      [110.1705, -7.7456],
      [110.17, -7.7452],
      [110.1692, -7.7445],
      [110.1688, -7.7443],
      [110.1683, -7.744],
      [110.1679, -7.7438],
      [110.1671, -7.7434],
      [110.1667, -7.743],
      [110.1658, -7.7424],
      [110.1655, -7.7424],
      [110.1646, -7.742],
      [110.1632, -7.7414],
      [110.1629, -7.7408],
      [110.1621, -7.7399],
      [110.1616, -7.7396],
      [110.1611, -7.7393],
      [110.1603, -7.7391],
      [110.1595, -7.7389],
      [110.1585, -7.739],
      [110.1579, -7.7389],
      [110.1578, -7.7388],
      [110.1569, -7.7381],
      [110.1558, -7.737],
      [110.155, -7.7359],
      [110.155, -7.7356]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GIRIMULYO',
    village: 'PENDOWOREJO',
    border: [
      [110.1976, -7.7382],
      [110.1976, -7.738],
      [110.1976, -7.7379],
      [110.1974, -7.7375],
      [110.1973, -7.7366],
      [110.1973, -7.7356],
      [110.1971, -7.7348],
      [110.1977, -7.734],
      [110.1983, -7.7335],
      [110.1984, -7.7328],
      [110.1981, -7.7329],
      [110.1975, -7.7326],
      [110.1969, -7.7322],
      [110.1968, -7.7318],
      [110.1963, -7.7316],
      [110.196, -7.7312],
      [110.1957, -7.7312],
      [110.1954, -7.7307],
      [110.1949, -7.7307],
      [110.1942, -7.7305],
      [110.1938, -7.73],
      [110.1934, -7.7296],
      [110.1929, -7.7293],
      [110.1924, -7.729],
      [110.1915, -7.7285],
      [110.1912, -7.7287],
      [110.1907, -7.7285],
      [110.1899, -7.7282],
      [110.1898, -7.7278],
      [110.1893, -7.7277],
      [110.1889, -7.7279],
      [110.1883, -7.7276],
      [110.1882, -7.7279],
      [110.1878, -7.7284],
      [110.1874, -7.7287],
      [110.1869, -7.7286],
      [110.1864, -7.7289],
      [110.186, -7.7289],
      [110.1856, -7.7286],
      [110.1852, -7.7286],
      [110.1848, -7.7285],
      [110.1847, -7.7283],
      [110.1847, -7.7281],
      [110.1844, -7.7281],
      [110.1842, -7.7285],
      [110.184, -7.729],
      [110.1838, -7.7294],
      [110.1833, -7.73],
      [110.183, -7.7303],
      [110.1825, -7.7306],
      [110.1821, -7.7309],
      [110.1817, -7.731],
      [110.1815, -7.7313],
      [110.1813, -7.7316],
      [110.1811, -7.7317],
      [110.1808, -7.7316],
      [110.1806, -7.7318],
      [110.1804, -7.732],
      [110.18, -7.7322],
      [110.1798, -7.7323],
      [110.1794, -7.7321],
      [110.1791, -7.7321],
      [110.1784, -7.7319],
      [110.1776, -7.7318],
      [110.1775, -7.7319],
      [110.1773, -7.7323],
      [110.1766, -7.7329],
      [110.1756, -7.7339],
      [110.1744, -7.735],
      [110.1742, -7.7351],
      [110.1735, -7.7356],
      [110.1734, -7.7357],
      [110.1732, -7.736],
      [110.1733, -7.7363],
      [110.1741, -7.7372],
      [110.1749, -7.7384],
      [110.1756, -7.7394],
      [110.1756, -7.7398],
      [110.1754, -7.7403],
      [110.175, -7.7407],
      [110.1742, -7.7409],
      [110.1735, -7.741],
      [110.1727, -7.7409],
      [110.1721, -7.7407],
      [110.1714, -7.7404],
      [110.1707, -7.7401],
      [110.1699, -7.7401],
      [110.1697, -7.7401],
      [110.1692, -7.74],
      [110.1691, -7.7398],
      [110.1687, -7.7394],
      [110.1681, -7.7393],
      [110.1678, -7.7392],
      [110.1675, -7.7391],
      [110.1674, -7.7387],
      [110.1671, -7.7384],
      [110.1669, -7.7383],
      [110.1666, -7.7381],
      [110.1661, -7.7383],
      [110.1654, -7.7382],
      [110.1648, -7.7379],
      [110.1641, -7.7376],
      [110.1638, -7.7372],
      [110.1636, -7.7371],
      [110.1634, -7.7371],
      [110.1632, -7.7373],
      [110.1632, -7.7375],
      [110.1631, -7.7378],
      [110.1629, -7.7378],
      [110.1628, -7.7377],
      [110.1625, -7.7375],
      [110.162, -7.7374],
      [110.1614, -7.737],
      [110.1612, -7.7369],
      [110.1611, -7.7368],
      [110.1608, -7.7369],
      [110.1603, -7.7369],
      [110.1601, -7.7368],
      [110.1598, -7.7366],
      [110.1591, -7.7365],
      [110.1588, -7.7364],
      [110.1587, -7.7362],
      [110.1586, -7.736],
      [110.1583, -7.7358],
      [110.1581, -7.7358],
      [110.1578, -7.736],
      [110.1575, -7.7362],
      [110.1573, -7.7363],
      [110.1568, -7.7363],
      [110.1564, -7.7363],
      [110.1562, -7.7363],
      [110.156, -7.7363],
      [110.1558, -7.7362],
      [110.1557, -7.7358],
      [110.1555, -7.7356],
      [110.155, -7.7356],
      [110.155, -7.7359],
      [110.1558, -7.737],
      [110.1569, -7.7381],
      [110.1578, -7.7388],
      [110.1579, -7.7389],
      [110.1585, -7.739],
      [110.1595, -7.7389],
      [110.1603, -7.7391],
      [110.1611, -7.7393],
      [110.1616, -7.7396],
      [110.1621, -7.7399],
      [110.1629, -7.7408],
      [110.1632, -7.7414],
      [110.1646, -7.742],
      [110.1655, -7.7424],
      [110.1658, -7.7424],
      [110.1667, -7.743],
      [110.1671, -7.7434],
      [110.1679, -7.7438],
      [110.1683, -7.744],
      [110.1688, -7.7443],
      [110.1692, -7.7445],
      [110.17, -7.7452],
      [110.1705, -7.7456],
      [110.1713, -7.7456],
      [110.1718, -7.7456],
      [110.1727, -7.7461],
      [110.1739, -7.746],
      [110.1749, -7.746],
      [110.1759, -7.7465],
      [110.1771, -7.7475],
      [110.1779, -7.7482],
      [110.1786, -7.7494],
      [110.1789, -7.75],
      [110.1791, -7.7508],
      [110.1791, -7.7514],
      [110.1793, -7.7521],
      [110.1806, -7.7536],
      [110.181, -7.7543],
      [110.1819, -7.7551],
      [110.183, -7.7562],
      [110.1834, -7.7567],
      [110.1835, -7.7571],
      [110.1841, -7.7579],
      [110.1842, -7.7586],
      [110.1847, -7.7596],
      [110.1854, -7.7603],
      [110.1866, -7.7606],
      [110.1879, -7.7614],
      [110.1895, -7.7626],
      [110.1901, -7.7633],
      [110.1913, -7.7641],
      [110.1919, -7.7643],
      [110.193, -7.7647],
      [110.1932, -7.7654],
      [110.1937, -7.7657],
      [110.1942, -7.7663],
      [110.1946, -7.7668],
      [110.1947, -7.7667],
      [110.1959, -7.7662],
      [110.1963, -7.7662],
      [110.1969, -7.7664],
      [110.1974, -7.7664],
      [110.198, -7.766],
      [110.1988, -7.7654],
      [110.1989, -7.7646],
      [110.199, -7.7644],
      [110.199, -7.7641],
      [110.1995, -7.7628],
      [110.1999, -7.7616],
      [110.1998, -7.7603],
      [110.1998, -7.76],
      [110.1996, -7.7589],
      [110.1995, -7.7584],
      [110.1993, -7.7573],
      [110.1994, -7.7572],
      [110.1996, -7.7557],
      [110.1997, -7.7552],
      [110.2, -7.7529],
      [110.2002, -7.7514],
      [110.1984, -7.7511],
      [110.197, -7.7508],
      [110.1973, -7.7485],
      [110.1973, -7.7484],
      [110.1974, -7.7474],
      [110.1982, -7.7457],
      [110.1986, -7.7444],
      [110.1992, -7.7433],
      [110.1994, -7.7422],
      [110.1994, -7.7416],
      [110.1987, -7.7416],
      [110.1981, -7.7414],
      [110.198, -7.7411],
      [110.1983, -7.7407],
      [110.1984, -7.7403],
      [110.1981, -7.7398],
      [110.1977, -7.7391],
      [110.1975, -7.7387],
      [110.1976, -7.7384],
      [110.1976, -7.7382]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'GIRIMULYO',
    village: 'PURWOSARI',
    border: [
      [110.1689, -7.715],
      [110.1685, -7.7154],
      [110.1681, -7.716],
      [110.1667, -7.7173],
      [110.1659, -7.7182],
      [110.165, -7.7188],
      [110.1636, -7.7193],
      [110.1628, -7.7195],
      [110.1616, -7.7195],
      [110.1609, -7.7194],
      [110.1603, -7.7193],
      [110.1596, -7.7193],
      [110.1587, -7.7192],
      [110.1576, -7.719],
      [110.1564, -7.7185],
      [110.1559, -7.7181],
      [110.1556, -7.7179],
      [110.155, -7.7174],
      [110.1543, -7.7173],
      [110.1535, -7.7173],
      [110.1532, -7.7172],
      [110.1529, -7.7171],
      [110.1527, -7.717],
      [110.1525, -7.7166],
      [110.1526, -7.7162],
      [110.1528, -7.7155],
      [110.1528, -7.7151],
      [110.1525, -7.7146],
      [110.1524, -7.7145],
      [110.1523, -7.7142],
      [110.1523, -7.7136],
      [110.152, -7.7133],
      [110.1518, -7.7128],
      [110.1514, -7.7127],
      [110.1513, -7.7128],
      [110.1508, -7.7131],
      [110.1507, -7.7137],
      [110.1507, -7.7139],
      [110.1506, -7.7141],
      [110.1504, -7.7147],
      [110.15, -7.715],
      [110.1498, -7.7154],
      [110.1495, -7.716],
      [110.1487, -7.7164],
      [110.1481, -7.7167],
      [110.1473, -7.7166],
      [110.1464, -7.7164],
      [110.1457, -7.7162],
      [110.1454, -7.7162],
      [110.1451, -7.7162],
      [110.1444, -7.7161],
      [110.1439, -7.7164],
      [110.1434, -7.7163],
      [110.143, -7.7162],
      [110.1426, -7.7162],
      [110.1419, -7.7161],
      [110.1415, -7.7161],
      [110.1412, -7.7161],
      [110.141, -7.7162],
      [110.1406, -7.7162],
      [110.1402, -7.7162],
      [110.1399, -7.7159],
      [110.1399, -7.7157],
      [110.1395, -7.7152],
      [110.1395, -7.7147],
      [110.1397, -7.7143],
      [110.1397, -7.7137],
      [110.14, -7.7133],
      [110.1401, -7.7131],
      [110.14, -7.7128],
      [110.1395, -7.7128],
      [110.1392, -7.7126],
      [110.1389, -7.7124],
      [110.1384, -7.7122],
      [110.138, -7.7124],
      [110.1375, -7.7126],
      [110.1373, -7.7126],
      [110.1369, -7.7124],
      [110.1365, -7.7122],
      [110.1361, -7.712],
      [110.1357, -7.7119],
      [110.1352, -7.7118],
      [110.1348, -7.7119],
      [110.1347, -7.7121],
      [110.1345, -7.7121],
      [110.1341, -7.712],
      [110.1337, -7.7119],
      [110.1336, -7.7124],
      [110.1334, -7.7133],
      [110.1333, -7.7133],
      [110.133, -7.7135],
      [110.1326, -7.7138],
      [110.1318, -7.7142],
      [110.1298, -7.7152],
      [110.1295, -7.7154],
      [110.1292, -7.7156],
      [110.1273, -7.7162],
      [110.1268, -7.7173],
      [110.1267, -7.7177],
      [110.1246, -7.7265],
      [110.1241, -7.7292],
      [110.1254, -7.731],
      [110.1261, -7.7315],
      [110.1295, -7.7338],
      [110.1321, -7.7347],
      [110.1322, -7.7359],
      [110.1322, -7.7362],
      [110.1322, -7.7364],
      [110.132, -7.7377],
      [110.1319, -7.7391],
      [110.132, -7.7396],
      [110.132, -7.7397],
      [110.1323, -7.7402],
      [110.1323, -7.7405],
      [110.132, -7.7407],
      [110.1319, -7.7408],
      [110.1316, -7.7408],
      [110.1311, -7.7408],
      [110.1305, -7.7407],
      [110.1304, -7.7408],
      [110.1304, -7.741],
      [110.1304, -7.7415],
      [110.1303, -7.7419],
      [110.1302, -7.7425],
      [110.13, -7.7429],
      [110.1299, -7.743],
      [110.1299, -7.7432],
      [110.1298, -7.7435],
      [110.1299, -7.7436],
      [110.13, -7.7439],
      [110.1301, -7.744],
      [110.1301, -7.7442],
      [110.1301, -7.7445],
      [110.1302, -7.7452],
      [110.1301, -7.7461],
      [110.13, -7.7467],
      [110.1297, -7.7473],
      [110.1296, -7.7475],
      [110.1303, -7.7471],
      [110.1306, -7.7466],
      [110.1308, -7.7464],
      [110.1312, -7.7462],
      [110.1314, -7.7459],
      [110.1316, -7.7454],
      [110.1317, -7.745],
      [110.1319, -7.7447],
      [110.1325, -7.7443],
      [110.1329, -7.744],
      [110.1332, -7.7434],
      [110.1336, -7.7428],
      [110.1349, -7.7419],
      [110.1355, -7.7412],
      [110.136, -7.7409],
      [110.1363, -7.7403],
      [110.1361, -7.7396],
      [110.1364, -7.7388],
      [110.1366, -7.7381],
      [110.1367, -7.7371],
      [110.1369, -7.7364],
      [110.1371, -7.7359],
      [110.1382, -7.7349],
      [110.1391, -7.734],
      [110.1395, -7.7331],
      [110.1396, -7.7319],
      [110.1399, -7.7309],
      [110.1402, -7.7304],
      [110.1407, -7.7301],
      [110.1412, -7.7301],
      [110.1416, -7.7307],
      [110.1421, -7.7319],
      [110.1427, -7.7326],
      [110.144, -7.7336],
      [110.1451, -7.7343],
      [110.1488, -7.7357],
      [110.1505, -7.7363],
      [110.1506, -7.7361],
      [110.1506, -7.736],
      [110.1509, -7.736],
      [110.1513, -7.7362],
      [110.1517, -7.7362],
      [110.1519, -7.736],
      [110.1522, -7.7356],
      [110.1523, -7.7352],
      [110.153, -7.7351],
      [110.1532, -7.735],
      [110.1535, -7.7349],
      [110.1538, -7.735],
      [110.1543, -7.7348],
      [110.1548, -7.7353],
      [110.1549, -7.7355],
      [110.155, -7.7356],
      [110.1555, -7.7356],
      [110.1557, -7.7358],
      [110.1558, -7.7362],
      [110.156, -7.7363],
      [110.1562, -7.7363],
      [110.1564, -7.7363],
      [110.1568, -7.7363],
      [110.1573, -7.7363],
      [110.1575, -7.7362],
      [110.1578, -7.736],
      [110.1581, -7.7358],
      [110.1583, -7.7358],
      [110.1586, -7.736],
      [110.1587, -7.7362],
      [110.1588, -7.7364],
      [110.1591, -7.7365],
      [110.1598, -7.7366],
      [110.1601, -7.7368],
      [110.1603, -7.7369],
      [110.1608, -7.7369],
      [110.1611, -7.7368],
      [110.1612, -7.7369],
      [110.1614, -7.737],
      [110.162, -7.7374],
      [110.1625, -7.7375],
      [110.1628, -7.7377],
      [110.1629, -7.7378],
      [110.1631, -7.7378],
      [110.1632, -7.7375],
      [110.1632, -7.7373],
      [110.1634, -7.7371],
      [110.1636, -7.7371],
      [110.1638, -7.7372],
      [110.1641, -7.7376],
      [110.1648, -7.7379],
      [110.1654, -7.7382],
      [110.1661, -7.7383],
      [110.1666, -7.7381],
      [110.1669, -7.7383],
      [110.1671, -7.7384],
      [110.1674, -7.7387],
      [110.1675, -7.7391],
      [110.1678, -7.7392],
      [110.1681, -7.7393],
      [110.1687, -7.7394],
      [110.1691, -7.7398],
      [110.1692, -7.74],
      [110.1697, -7.7401],
      [110.1699, -7.7401],
      [110.1707, -7.7401],
      [110.1714, -7.7404],
      [110.1721, -7.7407],
      [110.1727, -7.7409],
      [110.1735, -7.741],
      [110.1742, -7.7409],
      [110.175, -7.7407],
      [110.1754, -7.7403],
      [110.1756, -7.7398],
      [110.1756, -7.7394],
      [110.1749, -7.7384],
      [110.1741, -7.7372],
      [110.1733, -7.7363],
      [110.1732, -7.736],
      [110.1734, -7.7357],
      [110.1735, -7.7356],
      [110.1742, -7.7351],
      [110.1744, -7.735],
      [110.1756, -7.7339],
      [110.1766, -7.7329],
      [110.1773, -7.7323],
      [110.1775, -7.7319],
      [110.1776, -7.7318],
      [110.1784, -7.7319],
      [110.1791, -7.7321],
      [110.1794, -7.7321],
      [110.1798, -7.7323],
      [110.18, -7.7322],
      [110.1804, -7.732],
      [110.1806, -7.7318],
      [110.1808, -7.7316],
      [110.1811, -7.7317],
      [110.1813, -7.7316],
      [110.1815, -7.7313],
      [110.1817, -7.731],
      [110.1821, -7.7309],
      [110.1825, -7.7306],
      [110.183, -7.7303],
      [110.1833, -7.73],
      [110.1838, -7.7294],
      [110.184, -7.729],
      [110.1842, -7.7285],
      [110.1844, -7.7281],
      [110.1847, -7.7281],
      [110.1849, -7.7277],
      [110.1861, -7.7245],
      [110.1865, -7.7237],
      [110.187, -7.7234],
      [110.1874, -7.7233],
      [110.188, -7.7233],
      [110.1885, -7.7231],
      [110.1891, -7.7233],
      [110.1895, -7.7234],
      [110.1898, -7.7232],
      [110.1902, -7.7226],
      [110.1906, -7.7224],
      [110.1912, -7.722],
      [110.1921, -7.7219],
      [110.1925, -7.7218],
      [110.1927, -7.7218],
      [110.1919, -7.7218],
      [110.1914, -7.7214],
      [110.1912, -7.7214],
      [110.1907, -7.721],
      [110.1905, -7.7208],
      [110.1901, -7.7205],
      [110.19, -7.7202],
      [110.1898, -7.7198],
      [110.1897, -7.7198],
      [110.1891, -7.7197],
      [110.1887, -7.7198],
      [110.1883, -7.7196],
      [110.1875, -7.7196],
      [110.1871, -7.7196],
      [110.1869, -7.7197],
      [110.1865, -7.7197],
      [110.1859, -7.7201],
      [110.1849, -7.7203],
      [110.184, -7.7202],
      [110.1829, -7.7198],
      [110.1822, -7.7193],
      [110.1816, -7.7189],
      [110.1814, -7.7188],
      [110.1812, -7.7188],
      [110.1805, -7.7194],
      [110.1801, -7.7197],
      [110.1797, -7.7197],
      [110.1795, -7.7194],
      [110.1795, -7.719],
      [110.1793, -7.7187],
      [110.1788, -7.7186],
      [110.1786, -7.7186],
      [110.1779, -7.7183],
      [110.1774, -7.7181],
      [110.1767, -7.7179],
      [110.1762, -7.7179],
      [110.1758, -7.718],
      [110.1752, -7.7178],
      [110.1745, -7.7176],
      [110.1739, -7.7172],
      [110.1733, -7.7166],
      [110.1733, -7.7157],
      [110.1731, -7.7155],
      [110.173, -7.7154],
      [110.1729, -7.715],
      [110.1726, -7.7147],
      [110.1721, -7.7145],
      [110.1712, -7.7145],
      [110.1705, -7.7145],
      [110.1695, -7.7148],
      [110.169, -7.7149],
      [110.1689, -7.715]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'NANGGULAN',
    village: 'BANYUROTO',
    border: [
      [110.1869, -7.7858],
      [110.1868, -7.7859],
      [110.1855, -7.7855],
      [110.1834, -7.7848],
      [110.1827, -7.7845],
      [110.182, -7.7845],
      [110.1816, -7.7847],
      [110.1808, -7.7847],
      [110.1803, -7.7853],
      [110.1802, -7.7854],
      [110.1798, -7.7855],
      [110.1795, -7.7855],
      [110.1794, -7.7855],
      [110.1787, -7.7852],
      [110.1775, -7.7852],
      [110.176, -7.785],
      [110.1758, -7.7858],
      [110.1759, -7.7865],
      [110.1759, -7.7871],
      [110.1757, -7.7877],
      [110.1756, -7.7882],
      [110.1756, -7.7886],
      [110.1751, -7.7888],
      [110.1746, -7.7887],
      [110.1741, -7.7886],
      [110.1733, -7.7886],
      [110.1728, -7.7887],
      [110.1721, -7.7886],
      [110.1722, -7.789],
      [110.1722, -7.7895],
      [110.1722, -7.7896],
      [110.1721, -7.7903],
      [110.1721, -7.7908],
      [110.1721, -7.7911],
      [110.1721, -7.7912],
      [110.1724, -7.7917],
      [110.1726, -7.7919],
      [110.173, -7.792],
      [110.1731, -7.7923],
      [110.1732, -7.7927],
      [110.1732, -7.7928],
      [110.1733, -7.7932],
      [110.1732, -7.7937],
      [110.1731, -7.794],
      [110.1733, -7.7948],
      [110.174, -7.796],
      [110.1744, -7.7967],
      [110.1744, -7.7968],
      [110.1743, -7.7972],
      [110.1741, -7.7975],
      [110.1738, -7.7975],
      [110.1737, -7.7976],
      [110.1734, -7.7977],
      [110.1733, -7.7983],
      [110.1732, -7.7988],
      [110.1732, -7.799],
      [110.1728, -7.7991],
      [110.1727, -7.7992],
      [110.1716, -7.7996],
      [110.1714, -7.7997],
      [110.171, -7.7998],
      [110.1708, -7.8002],
      [110.1708, -7.8007],
      [110.171, -7.8014],
      [110.1713, -7.802],
      [110.1713, -7.8025],
      [110.1713, -7.803],
      [110.1712, -7.8035],
      [110.1711, -7.8038],
      [110.1711, -7.8041],
      [110.1707, -7.8045],
      [110.1704, -7.8046],
      [110.17, -7.8048],
      [110.17, -7.8049],
      [110.1698, -7.805],
      [110.1693, -7.8054],
      [110.1689, -7.8057],
      [110.1687, -7.8058],
      [110.1684, -7.806],
      [110.1683, -7.8064],
      [110.1685, -7.8066],
      [110.1687, -7.8069],
      [110.1689, -7.8074],
      [110.1691, -7.8081],
      [110.1694, -7.8089],
      [110.1695, -7.8095],
      [110.1693, -7.81],
      [110.1692, -7.8105],
      [110.1688, -7.811],
      [110.1686, -7.8114],
      [110.1685, -7.8121],
      [110.1684, -7.8129],
      [110.1684, -7.8137],
      [110.1684, -7.8145],
      [110.1683, -7.8149],
      [110.1682, -7.8151],
      [110.168, -7.8153],
      [110.1678, -7.8163],
      [110.1675, -7.8168],
      [110.1671, -7.8173],
      [110.1666, -7.8173],
      [110.1656, -7.8172],
      [110.1656, -7.8175],
      [110.1657, -7.818],
      [110.1655, -7.8186],
      [110.1653, -7.819],
      [110.165, -7.8196],
      [110.1644, -7.8203],
      [110.164, -7.8204],
      [110.1634, -7.8208],
      [110.1629, -7.8213],
      [110.1626, -7.8217],
      [110.1622, -7.8223],
      [110.1622, -7.8225],
      [110.1622, -7.8227],
      [110.1625, -7.823],
      [110.1628, -7.8235],
      [110.1631, -7.8249],
      [110.1633, -7.8258],
      [110.1635, -7.8265],
      [110.1637, -7.8265],
      [110.1644, -7.8259],
      [110.1651, -7.8254],
      [110.1658, -7.8249],
      [110.1663, -7.8243],
      [110.1663, -7.824],
      [110.1665, -7.8233],
      [110.1667, -7.8232],
      [110.1668, -7.8232],
      [110.1674, -7.8232],
      [110.1681, -7.8234],
      [110.1689, -7.8239],
      [110.1696, -7.8243],
      [110.1698, -7.8243],
      [110.1703, -7.8242],
      [110.1705, -7.8239],
      [110.1706, -7.8236],
      [110.1705, -7.8232],
      [110.1708, -7.823],
      [110.171, -7.8229],
      [110.1711, -7.8227],
      [110.1714, -7.8224],
      [110.1717, -7.8221],
      [110.1722, -7.8223],
      [110.173, -7.8226],
      [110.1735, -7.8229],
      [110.1743, -7.823],
      [110.1748, -7.8228],
      [110.1753, -7.8224],
      [110.1764, -7.8214],
      [110.1774, -7.8203],
      [110.1782, -7.8192],
      [110.1788, -7.8182],
      [110.1793, -7.8177],
      [110.1799, -7.8177],
      [110.1809, -7.8181],
      [110.1816, -7.8177],
      [110.1824, -7.8173],
      [110.183, -7.8163],
      [110.1832, -7.8157],
      [110.1831, -7.8152],
      [110.1828, -7.8142],
      [110.1827, -7.8137],
      [110.1827, -7.8131],
      [110.183, -7.813],
      [110.1831, -7.812],
      [110.1835, -7.8122],
      [110.184, -7.8119],
      [110.1843, -7.8112],
      [110.1844, -7.8106],
      [110.1852, -7.8098],
      [110.1855, -7.8095],
      [110.186, -7.8092],
      [110.187, -7.8087],
      [110.1874, -7.8081],
      [110.1876, -7.8076],
      [110.1876, -7.8073],
      [110.188, -7.807],
      [110.1883, -7.8069],
      [110.1885, -7.8069],
      [110.1887, -7.8064],
      [110.189, -7.8062],
      [110.1892, -7.806],
      [110.1892, -7.8057],
      [110.1894, -7.8055],
      [110.1897, -7.8052],
      [110.1899, -7.8051],
      [110.1903, -7.8049],
      [110.1905, -7.8044],
      [110.1907, -7.8041],
      [110.1911, -7.8037],
      [110.1916, -7.8035],
      [110.1917, -7.8029],
      [110.1919, -7.8027],
      [110.1928, -7.8023],
      [110.1936, -7.8022],
      [110.1938, -7.802],
      [110.1942, -7.8018],
      [110.1948, -7.8016],
      [110.1955, -7.8014],
      [110.196, -7.801],
      [110.1964, -7.8007],
      [110.1966, -7.8004],
      [110.1965, -7.8],
      [110.1963, -7.7996],
      [110.1961, -7.7991],
      [110.1955, -7.7988],
      [110.1951, -7.7985],
      [110.1948, -7.7974],
      [110.1948, -7.7969],
      [110.1945, -7.7967],
      [110.1943, -7.7965],
      [110.194, -7.7963],
      [110.1931, -7.796],
      [110.1928, -7.7957],
      [110.1925, -7.7956],
      [110.1923, -7.7953],
      [110.1922, -7.795],
      [110.1923, -7.7946],
      [110.1925, -7.7944],
      [110.1926, -7.7942],
      [110.1927, -7.7939],
      [110.1926, -7.7935],
      [110.1923, -7.7929],
      [110.1921, -7.7921],
      [110.1917, -7.7917],
      [110.1911, -7.7916],
      [110.1905, -7.7912],
      [110.1897, -7.7913],
      [110.1889, -7.7915],
      [110.1882, -7.7917],
      [110.1879, -7.7921],
      [110.1878, -7.7919],
      [110.1876, -7.7915],
      [110.1874, -7.7909],
      [110.1876, -7.7908],
      [110.1877, -7.7907],
      [110.1875, -7.7903],
      [110.1876, -7.7899],
      [110.1877, -7.7897],
      [110.1882, -7.7898],
      [110.1886, -7.7899],
      [110.1887, -7.7897],
      [110.1889, -7.7896],
      [110.1888, -7.7892],
      [110.1887, -7.789],
      [110.1885, -7.7886],
      [110.1881, -7.7883],
      [110.1884, -7.788],
      [110.1885, -7.7876],
      [110.1881, -7.7874],
      [110.1877, -7.7872],
      [110.1878, -7.7867],
      [110.1878, -7.7864],
      [110.1873, -7.786],
      [110.1869, -7.7858]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'NANGGULAN',
    village: 'DONOMULYO',
    border: [
      [110.2242, -7.8048],
      [110.2239, -7.8037],
      [110.2239, -7.8034],
      [110.2248, -7.8032],
      [110.2248, -7.8029],
      [110.225, -7.8025],
      [110.2249, -7.8015],
      [110.225, -7.8014],
      [110.2252, -7.8011],
      [110.2251, -7.8003],
      [110.2245, -7.8001],
      [110.2226, -7.7995],
      [110.2223, -7.7986],
      [110.2218, -7.7982],
      [110.2215, -7.7981],
      [110.2208, -7.7981],
      [110.2205, -7.7973],
      [110.2201, -7.7971],
      [110.2198, -7.7967],
      [110.2196, -7.7965],
      [110.2191, -7.7963],
      [110.2189, -7.7957],
      [110.2186, -7.7957],
      [110.2182, -7.7957],
      [110.218, -7.7955],
      [110.2146, -7.7933],
      [110.2119, -7.7921],
      [110.2108, -7.7916],
      [110.2087, -7.7907],
      [110.2073, -7.7905],
      [110.206, -7.791],
      [110.2051, -7.7911],
      [110.2046, -7.7914],
      [110.2043, -7.7916],
      [110.2041, -7.7916],
      [110.2034, -7.7919],
      [110.2034, -7.7927],
      [110.2028, -7.793],
      [110.2019, -7.7934],
      [110.2014, -7.7936],
      [110.1999, -7.7937],
      [110.1992, -7.7936],
      [110.1992, -7.7937],
      [110.1992, -7.7942],
      [110.1988, -7.7948],
      [110.1985, -7.7953],
      [110.1952, -7.7952],
      [110.1942, -7.7952],
      [110.1928, -7.7957],
      [110.1931, -7.796],
      [110.194, -7.7963],
      [110.1943, -7.7965],
      [110.1945, -7.7967],
      [110.1948, -7.7969],
      [110.1948, -7.7974],
      [110.1951, -7.7985],
      [110.1955, -7.7988],
      [110.1961, -7.7991],
      [110.1963, -7.7996],
      [110.1965, -7.8],
      [110.1966, -7.8004],
      [110.1964, -7.8007],
      [110.196, -7.801],
      [110.1955, -7.8014],
      [110.1948, -7.8016],
      [110.1942, -7.8018],
      [110.1938, -7.802],
      [110.1936, -7.8022],
      [110.1928, -7.8023],
      [110.1919, -7.8027],
      [110.1917, -7.8029],
      [110.1916, -7.8035],
      [110.1911, -7.8037],
      [110.1907, -7.8041],
      [110.1905, -7.8044],
      [110.1903, -7.8049],
      [110.1899, -7.8051],
      [110.1897, -7.8052],
      [110.1894, -7.8055],
      [110.1892, -7.8057],
      [110.1892, -7.806],
      [110.189, -7.8062],
      [110.1887, -7.8064],
      [110.1885, -7.8069],
      [110.1883, -7.8069],
      [110.188, -7.807],
      [110.1876, -7.8073],
      [110.1876, -7.8076],
      [110.1874, -7.8081],
      [110.187, -7.8087],
      [110.186, -7.8092],
      [110.1855, -7.8095],
      [110.1852, -7.8098],
      [110.1844, -7.8106],
      [110.1843, -7.8112],
      [110.184, -7.8119],
      [110.1835, -7.8122],
      [110.1831, -7.812],
      [110.183, -7.813],
      [110.1827, -7.8131],
      [110.1827, -7.8137],
      [110.1828, -7.8142],
      [110.1831, -7.8152],
      [110.1832, -7.8157],
      [110.183, -7.8163],
      [110.1824, -7.8173],
      [110.1816, -7.8177],
      [110.1809, -7.8181],
      [110.1799, -7.8177],
      [110.1793, -7.8177],
      [110.1788, -7.8182],
      [110.1782, -7.8192],
      [110.1774, -7.8203],
      [110.1764, -7.8214],
      [110.1753, -7.8224],
      [110.1748, -7.8228],
      [110.1751, -7.8232],
      [110.1755, -7.8237],
      [110.1763, -7.824],
      [110.1771, -7.8242],
      [110.1774, -7.8243],
      [110.1775, -7.8243],
      [110.1782, -7.8246],
      [110.179, -7.825],
      [110.1797, -7.8252],
      [110.1806, -7.8253],
      [110.1814, -7.8253],
      [110.1823, -7.8253],
      [110.1837, -7.8252],
      [110.1847, -7.8251],
      [110.1851, -7.8255],
      [110.1861, -7.8257],
      [110.1868, -7.826],
      [110.1873, -7.8261],
      [110.1882, -7.826],
      [110.1887, -7.826],
      [110.1891, -7.826],
      [110.1893, -7.826],
      [110.1893, -7.8255],
      [110.1891, -7.8245],
      [110.1892, -7.824],
      [110.1893, -7.8239],
      [110.1897, -7.8234],
      [110.1901, -7.823],
      [110.1906, -7.8227],
      [110.1913, -7.8225],
      [110.1919, -7.8226],
      [110.1927, -7.8225],
      [110.1935, -7.8223],
      [110.1955, -7.8218],
      [110.1966, -7.8215],
      [110.1968, -7.8219],
      [110.1972, -7.8221],
      [110.1971, -7.8223],
      [110.1965, -7.8222],
      [110.197, -7.8236],
      [110.1982, -7.8234],
      [110.1987, -7.8233],
      [110.1994, -7.8227],
      [110.1999, -7.8223],
      [110.2006, -7.822],
      [110.2014, -7.822],
      [110.2019, -7.8222],
      [110.202, -7.8229],
      [110.203, -7.8236],
      [110.2041, -7.8246],
      [110.2046, -7.825],
      [110.2054, -7.8252],
      [110.2057, -7.8252],
      [110.2057, -7.8243],
      [110.206, -7.8241],
      [110.2064, -7.824],
      [110.2067, -7.8237],
      [110.207, -7.8231],
      [110.2072, -7.8221],
      [110.2067, -7.8217],
      [110.2063, -7.8213],
      [110.2055, -7.8212],
      [110.205, -7.8211],
      [110.2049, -7.8207],
      [110.2051, -7.8199],
      [110.2052, -7.819],
      [110.2054, -7.818],
      [110.2058, -7.817],
      [110.2053, -7.815],
      [110.2055, -7.8141],
      [110.2059, -7.8131],
      [110.2064, -7.8117],
      [110.2067, -7.8115],
      [110.2071, -7.8105],
      [110.2076, -7.8108],
      [110.2077, -7.8107],
      [110.2081, -7.8101],
      [110.2087, -7.8095],
      [110.2092, -7.8093],
      [110.2095, -7.8093],
      [110.2096, -7.8094],
      [110.2096, -7.8095],
      [110.2096, -7.8097],
      [110.21, -7.8096],
      [110.2103, -7.8094],
      [110.2106, -7.8095],
      [110.2108, -7.8092],
      [110.211, -7.8091],
      [110.2114, -7.8095],
      [110.2116, -7.8096],
      [110.2119, -7.81],
      [110.2121, -7.8103],
      [110.2122, -7.8104],
      [110.2123, -7.8104],
      [110.2125, -7.8104],
      [110.2126, -7.8105],
      [110.2127, -7.8105],
      [110.2128, -7.8108],
      [110.213, -7.811],
      [110.2134, -7.8112],
      [110.2135, -7.8112],
      [110.2138, -7.8113],
      [110.2139, -7.8116],
      [110.214, -7.8118],
      [110.2143, -7.8118],
      [110.2144, -7.812],
      [110.2145, -7.8122],
      [110.2147, -7.8122],
      [110.2149, -7.8122],
      [110.2151, -7.8121],
      [110.2152, -7.812],
      [110.2153, -7.8118],
      [110.2157, -7.8117],
      [110.216, -7.8118],
      [110.216, -7.8116],
      [110.2156, -7.8112],
      [110.2155, -7.8108],
      [110.2153, -7.8105],
      [110.2153, -7.8103],
      [110.2156, -7.8103],
      [110.2157, -7.8101],
      [110.2159, -7.8097],
      [110.2159, -7.8094],
      [110.2158, -7.809],
      [110.2157, -7.8089],
      [110.2155, -7.8088],
      [110.2154, -7.8087],
      [110.2153, -7.8085],
      [110.2157, -7.808],
      [110.2161, -7.808],
      [110.2164, -7.8078],
      [110.2166, -7.8076],
      [110.217, -7.8068],
      [110.2173, -7.8066],
      [110.2176, -7.8062],
      [110.2179, -7.8058],
      [110.2183, -7.8058],
      [110.2187, -7.8059],
      [110.2193, -7.8059],
      [110.2197, -7.8059],
      [110.2198, -7.8056],
      [110.2201, -7.8052],
      [110.2205, -7.8052],
      [110.2209, -7.8049],
      [110.221, -7.8046],
      [110.2212, -7.8043],
      [110.2213, -7.8041],
      [110.2223, -7.8044],
      [110.2238, -7.8048],
      [110.2242, -7.8048]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'NANGGULAN',
    village: 'WIJIMULYO',
    border: [
      [110.22, -7.7748],
      [110.2196, -7.7744],
      [110.2193, -7.774],
      [110.2193, -7.7739],
      [110.219, -7.7737],
      [110.2191, -7.7735],
      [110.2192, -7.7733],
      [110.2193, -7.7729],
      [110.2195, -7.7722],
      [110.2192, -7.7718],
      [110.2189, -7.7714],
      [110.2188, -7.7711],
      [110.2182, -7.7709],
      [110.2179, -7.7706],
      [110.2175, -7.7701],
      [110.2169, -7.7702],
      [110.217, -7.7706],
      [110.2165, -7.7708],
      [110.2157, -7.7707],
      [110.2149, -7.7709],
      [110.2141, -7.7708],
      [110.2135, -7.7711],
      [110.2131, -7.7713],
      [110.213, -7.7717],
      [110.2127, -7.7721],
      [110.2124, -7.7727],
      [110.2122, -7.7734],
      [110.2112, -7.7734],
      [110.2112, -7.7747],
      [110.2115, -7.7748],
      [110.2115, -7.776],
      [110.21, -7.7743],
      [110.2092, -7.7739],
      [110.2088, -7.7736],
      [110.2082, -7.7743],
      [110.2075, -7.7748],
      [110.2073, -7.775],
      [110.2069, -7.7766],
      [110.206, -7.7776],
      [110.2062, -7.7784],
      [110.2062, -7.7785],
      [110.2068, -7.7788],
      [110.2073, -7.7792],
      [110.2078, -7.7793],
      [110.208, -7.7793],
      [110.2091, -7.7804],
      [110.2092, -7.7811],
      [110.2093, -7.7813],
      [110.2095, -7.7816],
      [110.2094, -7.7819],
      [110.2094, -7.7821],
      [110.2089, -7.7821],
      [110.2085, -7.7821],
      [110.2079, -7.7822],
      [110.2075, -7.7823],
      [110.2073, -7.7827],
      [110.2069, -7.783],
      [110.2066, -7.7829],
      [110.2062, -7.7827],
      [110.2059, -7.783],
      [110.2057, -7.7832],
      [110.2053, -7.7833],
      [110.2049, -7.7838],
      [110.2044, -7.7843],
      [110.204, -7.7843],
      [110.2036, -7.7851],
      [110.2029, -7.7858],
      [110.2031, -7.7863],
      [110.2032, -7.7868],
      [110.2037, -7.7874],
      [110.2043, -7.7881],
      [110.2048, -7.7888],
      [110.2057, -7.7894],
      [110.2064, -7.79],
      [110.2073, -7.7905],
      [110.2087, -7.7907],
      [110.2108, -7.7916],
      [110.2119, -7.7921],
      [110.2146, -7.7933],
      [110.218, -7.7955],
      [110.2182, -7.7957],
      [110.2186, -7.7957],
      [110.2189, -7.7957],
      [110.2191, -7.7963],
      [110.2196, -7.7965],
      [110.2198, -7.7967],
      [110.2201, -7.7971],
      [110.2205, -7.7973],
      [110.2208, -7.7981],
      [110.2215, -7.7981],
      [110.2218, -7.7982],
      [110.2223, -7.7986],
      [110.2226, -7.7995],
      [110.2245, -7.8001],
      [110.2251, -7.8003],
      [110.2252, -7.8011],
      [110.225, -7.8014],
      [110.2249, -7.8015],
      [110.225, -7.8025],
      [110.2248, -7.8029],
      [110.2248, -7.8032],
      [110.2239, -7.8034],
      [110.2239, -7.8037],
      [110.2242, -7.8048],
      [110.2245, -7.805],
      [110.225, -7.8051],
      [110.2253, -7.8053],
      [110.2255, -7.8053],
      [110.2264, -7.8054],
      [110.2271, -7.8054],
      [110.2278, -7.8055],
      [110.2283, -7.8056],
      [110.2285, -7.8051],
      [110.2288, -7.8044],
      [110.2292, -7.8038],
      [110.2299, -7.8031],
      [110.2304, -7.8028],
      [110.2313, -7.8024],
      [110.2322, -7.8022],
      [110.2328, -7.802],
      [110.2333, -7.8017],
      [110.2339, -7.8013],
      [110.2351, -7.8004],
      [110.2357, -7.7996],
      [110.236, -7.7988],
      [110.236, -7.7983],
      [110.2358, -7.7977],
      [110.2353, -7.7971],
      [110.235, -7.7967],
      [110.2345, -7.7959],
      [110.2341, -7.795],
      [110.2334, -7.7936],
      [110.2332, -7.7928],
      [110.2331, -7.7918],
      [110.233, -7.7911],
      [110.2327, -7.7908],
      [110.2325, -7.7904],
      [110.2321, -7.79],
      [110.2316, -7.7898],
      [110.2309, -7.7897],
      [110.23, -7.7895],
      [110.2294, -7.7892],
      [110.229, -7.7888],
      [110.2288, -7.7885],
      [110.2287, -7.7882],
      [110.2287, -7.7872],
      [110.2286, -7.7866],
      [110.2284, -7.786],
      [110.228, -7.7851],
      [110.2273, -7.7842],
      [110.2261, -7.7834],
      [110.2252, -7.7828],
      [110.2248, -7.7822],
      [110.224, -7.7809],
      [110.2233, -7.7795],
      [110.2229, -7.7793],
      [110.2225, -7.7791],
      [110.222, -7.7788],
      [110.2215, -7.7784],
      [110.2209, -7.7779],
      [110.2205, -7.7774],
      [110.2202, -7.7769],
      [110.22, -7.7764],
      [110.2199, -7.7759],
      [110.2198, -7.7751],
      [110.22, -7.7748]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'NANGGULAN',
    village: 'TANJUNGHARJO',
    border: [
      [110.2089, -7.7732],
      [110.2087, -7.7726],
      [110.2085, -7.7724],
      [110.2079, -7.7721],
      [110.2072, -7.7713],
      [110.2063, -7.7707],
      [110.2054, -7.7701],
      [110.2052, -7.7701],
      [110.2049, -7.7701],
      [110.2044, -7.7699],
      [110.2041, -7.7694],
      [110.204, -7.7692],
      [110.2039, -7.769],
      [110.2036, -7.7684],
      [110.203, -7.7678],
      [110.2028, -7.7675],
      [110.2027, -7.7674],
      [110.2025, -7.7669],
      [110.2018, -7.7665],
      [110.2012, -7.7656],
      [110.2004, -7.7651],
      [110.2001, -7.765],
      [110.1993, -7.7647],
      [110.1989, -7.7646],
      [110.1988, -7.7654],
      [110.198, -7.766],
      [110.1974, -7.7664],
      [110.1969, -7.7664],
      [110.1963, -7.7662],
      [110.1959, -7.7662],
      [110.1947, -7.7667],
      [110.1946, -7.7668],
      [110.1934, -7.7673],
      [110.1925, -7.7678],
      [110.1919, -7.7685],
      [110.1916, -7.769],
      [110.1916, -7.7698],
      [110.1916, -7.7704],
      [110.1921, -7.7708],
      [110.1925, -7.7713],
      [110.1927, -7.7716],
      [110.193, -7.7723],
      [110.193, -7.7726],
      [110.193, -7.773],
      [110.193, -7.7734],
      [110.1927, -7.7736],
      [110.1926, -7.7737],
      [110.1921, -7.7738],
      [110.192, -7.7739],
      [110.1917, -7.7739],
      [110.1916, -7.7739],
      [110.1915, -7.7738],
      [110.1911, -7.7738],
      [110.1909, -7.7741],
      [110.1908, -7.7745],
      [110.1905, -7.7746],
      [110.1904, -7.7746],
      [110.1899, -7.7753],
      [110.1899, -7.7754],
      [110.1897, -7.7758],
      [110.1893, -7.7761],
      [110.1894, -7.7766],
      [110.1894, -7.777],
      [110.1893, -7.777],
      [110.1889, -7.7772],
      [110.1888, -7.7775],
      [110.1886, -7.7779],
      [110.1885, -7.7779],
      [110.1883, -7.7779],
      [110.1884, -7.7782],
      [110.1885, -7.7785],
      [110.1883, -7.7787],
      [110.1882, -7.7789],
      [110.1884, -7.7793],
      [110.1883, -7.7796],
      [110.188, -7.7798],
      [110.1878, -7.7801],
      [110.1877, -7.7806],
      [110.1877, -7.781],
      [110.1879, -7.7816],
      [110.1883, -7.782],
      [110.1882, -7.7824],
      [110.1884, -7.7827],
      [110.1889, -7.7829],
      [110.1889, -7.7834],
      [110.1891, -7.7837],
      [110.1889, -7.7842],
      [110.1888, -7.7843],
      [110.1883, -7.7843],
      [110.1882, -7.7846],
      [110.1879, -7.7852],
      [110.1879, -7.7855],
      [110.1879, -7.7857],
      [110.1874, -7.7858],
      [110.1872, -7.7858],
      [110.1869, -7.7858],
      [110.1873, -7.786],
      [110.1878, -7.7864],
      [110.1878, -7.7867],
      [110.1877, -7.7872],
      [110.1881, -7.7874],
      [110.1885, -7.7876],
      [110.1884, -7.788],
      [110.1881, -7.7883],
      [110.1885, -7.7886],
      [110.1887, -7.789],
      [110.1888, -7.7892],
      [110.1889, -7.7896],
      [110.1887, -7.7897],
      [110.1886, -7.7899],
      [110.1882, -7.7898],
      [110.1877, -7.7897],
      [110.1876, -7.7899],
      [110.1875, -7.7903],
      [110.1877, -7.7907],
      [110.1876, -7.7908],
      [110.1874, -7.7909],
      [110.1876, -7.7915],
      [110.1878, -7.7919],
      [110.1879, -7.7921],
      [110.1882, -7.7917],
      [110.1889, -7.7915],
      [110.1897, -7.7913],
      [110.1905, -7.7912],
      [110.1911, -7.7916],
      [110.1917, -7.7917],
      [110.1921, -7.7921],
      [110.1923, -7.7929],
      [110.1926, -7.7935],
      [110.1927, -7.7939],
      [110.1926, -7.7942],
      [110.1925, -7.7944],
      [110.1923, -7.7946],
      [110.1922, -7.795],
      [110.1923, -7.7953],
      [110.1925, -7.7956],
      [110.1928, -7.7957],
      [110.1942, -7.7952],
      [110.1952, -7.7952],
      [110.1985, -7.7953],
      [110.1988, -7.7948],
      [110.1992, -7.7942],
      [110.1992, -7.7937],
      [110.1992, -7.7936],
      [110.1999, -7.7937],
      [110.2014, -7.7936],
      [110.2019, -7.7934],
      [110.2028, -7.793],
      [110.2034, -7.7927],
      [110.2034, -7.7919],
      [110.2041, -7.7916],
      [110.2043, -7.7916],
      [110.2046, -7.7914],
      [110.2051, -7.7911],
      [110.206, -7.791],
      [110.2073, -7.7905],
      [110.2064, -7.79],
      [110.2057, -7.7894],
      [110.2048, -7.7888],
      [110.2043, -7.7881],
      [110.2037, -7.7874],
      [110.2032, -7.7868],
      [110.2031, -7.7863],
      [110.2029, -7.7858],
      [110.2036, -7.7851],
      [110.204, -7.7843],
      [110.2044, -7.7843],
      [110.2049, -7.7838],
      [110.2053, -7.7833],
      [110.2057, -7.7832],
      [110.2059, -7.783],
      [110.2062, -7.7827],
      [110.2066, -7.7829],
      [110.2069, -7.783],
      [110.2073, -7.7827],
      [110.2075, -7.7823],
      [110.2079, -7.7822],
      [110.2085, -7.7821],
      [110.2089, -7.7821],
      [110.2094, -7.7821],
      [110.2094, -7.7819],
      [110.2095, -7.7816],
      [110.2093, -7.7813],
      [110.2092, -7.7811],
      [110.2091, -7.7804],
      [110.208, -7.7793],
      [110.2078, -7.7793],
      [110.2073, -7.7792],
      [110.2068, -7.7788],
      [110.2062, -7.7785],
      [110.2062, -7.7784],
      [110.206, -7.7776],
      [110.2069, -7.7766],
      [110.2073, -7.775],
      [110.2075, -7.7748],
      [110.2082, -7.7743],
      [110.2088, -7.7736],
      [110.2089, -7.7732]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'NANGGULAN',
    village: 'JATISARONO',
    border: [
      [110.2189, -7.7571],
      [110.2188, -7.7568],
      [110.2187, -7.7566],
      [110.2185, -7.7564],
      [110.2183, -7.7565],
      [110.2181, -7.7567],
      [110.2179, -7.7567],
      [110.2175, -7.7568],
      [110.2172, -7.7568],
      [110.2172, -7.7565],
      [110.2169, -7.7562],
      [110.2165, -7.7557],
      [110.2164, -7.7554],
      [110.2159, -7.7555],
      [110.2155, -7.7552],
      [110.2152, -7.7551],
      [110.215, -7.7553],
      [110.2143, -7.7554],
      [110.2141, -7.7556],
      [110.2137, -7.7556],
      [110.2132, -7.7555],
      [110.2128, -7.7557],
      [110.2123, -7.7556],
      [110.2118, -7.7557],
      [110.2116, -7.7557],
      [110.2111, -7.7557],
      [110.2114, -7.754],
      [110.2101, -7.7538],
      [110.2083, -7.7535],
      [110.2081, -7.7537],
      [110.2079, -7.7554],
      [110.2072, -7.7554],
      [110.2067, -7.7551],
      [110.2063, -7.7543],
      [110.2059, -7.7542],
      [110.2056, -7.7537],
      [110.2055, -7.7536],
      [110.2055, -7.7534],
      [110.2054, -7.7531],
      [110.2056, -7.7528],
      [110.2033, -7.7522],
      [110.2021, -7.7518],
      [110.2002, -7.7514],
      [110.2, -7.7529],
      [110.1997, -7.7552],
      [110.1996, -7.7557],
      [110.1994, -7.7572],
      [110.1993, -7.7573],
      [110.1995, -7.7584],
      [110.1996, -7.7589],
      [110.1998, -7.76],
      [110.1998, -7.7603],
      [110.1999, -7.7616],
      [110.1995, -7.7628],
      [110.199, -7.7641],
      [110.199, -7.7644],
      [110.1989, -7.7646],
      [110.1993, -7.7647],
      [110.2001, -7.765],
      [110.2004, -7.7651],
      [110.2012, -7.7656],
      [110.2018, -7.7665],
      [110.2025, -7.7669],
      [110.2027, -7.7674],
      [110.2028, -7.7675],
      [110.203, -7.7678],
      [110.2036, -7.7684],
      [110.2039, -7.769],
      [110.204, -7.7692],
      [110.2041, -7.7694],
      [110.2044, -7.7699],
      [110.2049, -7.7701],
      [110.2052, -7.7701],
      [110.2054, -7.7701],
      [110.2063, -7.7707],
      [110.2072, -7.7713],
      [110.2079, -7.7721],
      [110.2085, -7.7724],
      [110.2087, -7.7726],
      [110.2089, -7.7732],
      [110.2088, -7.7735],
      [110.2092, -7.7739],
      [110.21, -7.7743],
      [110.2115, -7.776],
      [110.2115, -7.7748],
      [110.2112, -7.7747],
      [110.2112, -7.7734],
      [110.2122, -7.7734],
      [110.2124, -7.7727],
      [110.2127, -7.7721],
      [110.213, -7.7717],
      [110.2131, -7.7713],
      [110.2135, -7.7711],
      [110.2141, -7.7708],
      [110.2149, -7.7709],
      [110.2157, -7.7707],
      [110.2165, -7.7708],
      [110.217, -7.7706],
      [110.2169, -7.7702],
      [110.2175, -7.7701],
      [110.2179, -7.7706],
      [110.2182, -7.7709],
      [110.2188, -7.7711],
      [110.2189, -7.7714],
      [110.2192, -7.7718],
      [110.2195, -7.7722],
      [110.2193, -7.7729],
      [110.2192, -7.7733],
      [110.2191, -7.7735],
      [110.219, -7.7737],
      [110.2193, -7.7739],
      [110.2193, -7.774],
      [110.2196, -7.7744],
      [110.22, -7.7748],
      [110.2204, -7.7744],
      [110.2207, -7.774],
      [110.2214, -7.7736],
      [110.2218, -7.7732],
      [110.2218, -7.7728],
      [110.2218, -7.7723],
      [110.2217, -7.7716],
      [110.2217, -7.7706],
      [110.2218, -7.77],
      [110.2221, -7.7691],
      [110.2224, -7.7683],
      [110.2224, -7.7678],
      [110.2224, -7.7672],
      [110.2222, -7.7663],
      [110.2221, -7.7653],
      [110.2219, -7.765],
      [110.2218, -7.7648],
      [110.2215, -7.7647],
      [110.2212, -7.7648],
      [110.221, -7.7649],
      [110.2205, -7.7649],
      [110.2202, -7.7646],
      [110.2197, -7.7641],
      [110.2195, -7.7635],
      [110.2195, -7.763],
      [110.2197, -7.7629],
      [110.2203, -7.7626],
      [110.2209, -7.7622],
      [110.2214, -7.7619],
      [110.2218, -7.7615],
      [110.2219, -7.7608],
      [110.222, -7.7599],
      [110.2216, -7.7596],
      [110.221, -7.7593],
      [110.2206, -7.7591],
      [110.2197, -7.7587],
      [110.2191, -7.7587],
      [110.2187, -7.7586],
      [110.2185, -7.7581],
      [110.2186, -7.7576],
      [110.2189, -7.7571]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'NANGGULAN',
    village: 'KEMBANG',
    border: [
      [110.2204, -7.7355],
      [110.2198, -7.7352],
      [110.2197, -7.7352],
      [110.2192, -7.7349],
      [110.2186, -7.7346],
      [110.218, -7.7343],
      [110.2176, -7.7337],
      [110.2171, -7.733],
      [110.2167, -7.733],
      [110.2162, -7.7331],
      [110.216, -7.7335],
      [110.2157, -7.734],
      [110.2155, -7.7345],
      [110.2155, -7.7348],
      [110.2156, -7.7352],
      [110.2157, -7.7355],
      [110.2159, -7.7358],
      [110.216, -7.7361],
      [110.2161, -7.7365],
      [110.2158, -7.7368],
      [110.2156, -7.7367],
      [110.2153, -7.7365],
      [110.2149, -7.7366],
      [110.2147, -7.7369],
      [110.2146, -7.7373],
      [110.2143, -7.7374],
      [110.2139, -7.7373],
      [110.2135, -7.737],
      [110.2132, -7.7366],
      [110.213, -7.7362],
      [110.2127, -7.7359],
      [110.2125, -7.7356],
      [110.2124, -7.7355],
      [110.2119, -7.7354],
      [110.2117, -7.7353],
      [110.2114, -7.735],
      [110.2112, -7.7347],
      [110.2109, -7.7347],
      [110.2106, -7.7349],
      [110.2102, -7.7351],
      [110.2099, -7.7349],
      [110.2096, -7.7349],
      [110.209, -7.7351],
      [110.2088, -7.7349],
      [110.2087, -7.7346],
      [110.2083, -7.7347],
      [110.208, -7.7343],
      [110.2079, -7.734],
      [110.2073, -7.734],
      [110.2069, -7.734],
      [110.2067, -7.7337],
      [110.2065, -7.7336],
      [110.2059, -7.7338],
      [110.2054, -7.7339],
      [110.2048, -7.7341],
      [110.2044, -7.7345],
      [110.204, -7.7347],
      [110.2034, -7.735],
      [110.2029, -7.7351],
      [110.2025, -7.7351],
      [110.2023, -7.7353],
      [110.2021, -7.7355],
      [110.202, -7.7359],
      [110.2013, -7.7362],
      [110.2009, -7.7361],
      [110.2007, -7.7363],
      [110.2004, -7.7363],
      [110.2003, -7.7366],
      [110.2, -7.7368],
      [110.1995, -7.7365],
      [110.1992, -7.7371],
      [110.1989, -7.7371],
      [110.1986, -7.7371],
      [110.1982, -7.7377],
      [110.1978, -7.7382],
      [110.1976, -7.7382],
      [110.1976, -7.7384],
      [110.1975, -7.7387],
      [110.1977, -7.7391],
      [110.1981, -7.7398],
      [110.1984, -7.7403],
      [110.1983, -7.7407],
      [110.198, -7.7411],
      [110.1981, -7.7414],
      [110.1987, -7.7416],
      [110.1994, -7.7416],
      [110.1994, -7.7422],
      [110.1992, -7.7433],
      [110.1986, -7.7444],
      [110.1982, -7.7457],
      [110.1974, -7.7474],
      [110.1973, -7.7484],
      [110.1973, -7.7485],
      [110.197, -7.7508],
      [110.1984, -7.7511],
      [110.2002, -7.7514],
      [110.2021, -7.7518],
      [110.2033, -7.7522],
      [110.2056, -7.7528],
      [110.2054, -7.7531],
      [110.2055, -7.7534],
      [110.2055, -7.7536],
      [110.2056, -7.7537],
      [110.2059, -7.7542],
      [110.2063, -7.7543],
      [110.2067, -7.7551],
      [110.2072, -7.7554],
      [110.2079, -7.7554],
      [110.2081, -7.7537],
      [110.2083, -7.7535],
      [110.2101, -7.7538],
      [110.2114, -7.754],
      [110.2111, -7.7557],
      [110.2116, -7.7557],
      [110.2118, -7.7557],
      [110.2123, -7.7556],
      [110.2128, -7.7557],
      [110.2132, -7.7555],
      [110.2137, -7.7556],
      [110.2141, -7.7556],
      [110.2143, -7.7554],
      [110.215, -7.7553],
      [110.2152, -7.7551],
      [110.2155, -7.7552],
      [110.2159, -7.7555],
      [110.2164, -7.7554],
      [110.2165, -7.7557],
      [110.2169, -7.7562],
      [110.2172, -7.7565],
      [110.2172, -7.7568],
      [110.2175, -7.7568],
      [110.2179, -7.7567],
      [110.2181, -7.7567],
      [110.2183, -7.7565],
      [110.2185, -7.7564],
      [110.2187, -7.7566],
      [110.2188, -7.7568],
      [110.2189, -7.7571],
      [110.2191, -7.7569],
      [110.2195, -7.7559],
      [110.2202, -7.7549],
      [110.2202, -7.7547],
      [110.22, -7.7543],
      [110.2195, -7.7538],
      [110.2193, -7.7536],
      [110.2189, -7.7533],
      [110.2185, -7.753],
      [110.218, -7.7526],
      [110.2178, -7.7523],
      [110.2177, -7.7517],
      [110.2178, -7.7513],
      [110.2184, -7.7504],
      [110.2191, -7.75],
      [110.2193, -7.7493],
      [110.2195, -7.748],
      [110.2193, -7.7472],
      [110.2191, -7.7468],
      [110.2186, -7.7457],
      [110.218, -7.745],
      [110.2174, -7.7445],
      [110.2171, -7.7442],
      [110.2169, -7.744],
      [110.2162, -7.7428],
      [110.2159, -7.7417],
      [110.216, -7.7408],
      [110.2162, -7.7394],
      [110.2165, -7.7383],
      [110.2175, -7.7371],
      [110.2188, -7.7365],
      [110.2197, -7.7359],
      [110.2204, -7.7355]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SAMIGALUH',
    village: 'KEBONHARJO',
    border: [
      [110.1689, -7.715],
      [110.1686, -7.7143],
      [110.1682, -7.7131],
      [110.168, -7.7122],
      [110.1681, -7.7115],
      [110.168, -7.7109],
      [110.1678, -7.7102],
      [110.1677, -7.7092],
      [110.168, -7.7087],
      [110.1685, -7.7081],
      [110.1688, -7.7075],
      [110.1692, -7.7066],
      [110.1687, -7.7061],
      [110.1682, -7.7055],
      [110.1677, -7.7055],
      [110.1674, -7.7052],
      [110.1668, -7.7052],
      [110.1664, -7.7048],
      [110.1659, -7.7044],
      [110.1655, -7.7041],
      [110.1651, -7.7037],
      [110.1649, -7.7035],
      [110.1648, -7.7034],
      [110.1646, -7.7032],
      [110.1643, -7.703],
      [110.1639, -7.703],
      [110.1633, -7.7026],
      [110.1632, -7.7023],
      [110.1631, -7.7016],
      [110.1626, -7.7018],
      [110.1622, -7.7019],
      [110.1619, -7.7018],
      [110.1614, -7.7017],
      [110.1608, -7.7012],
      [110.1603, -7.7006],
      [110.1598, -7.7004],
      [110.1595, -7.7002],
      [110.1589, -7.6998],
      [110.1584, -7.7],
      [110.158, -7.7002],
      [110.1574, -7.7001],
      [110.1567, -7.7001],
      [110.1563, -7.6999],
      [110.1558, -7.6997],
      [110.1553, -7.6996],
      [110.1548, -7.6996],
      [110.1543, -7.6997],
      [110.154, -7.6996],
      [110.1537, -7.6994],
      [110.1534, -7.6989],
      [110.1526, -7.6987],
      [110.152, -7.6984],
      [110.1515, -7.6979],
      [110.1511, -7.6979],
      [110.1504, -7.6979],
      [110.15, -7.6977],
      [110.1495, -7.6977],
      [110.1489, -7.6978],
      [110.1483, -7.6978],
      [110.1477, -7.6976],
      [110.1471, -7.6976],
      [110.1465, -7.6977],
      [110.1463, -7.6974],
      [110.1459, -7.6973],
      [110.1452, -7.6973],
      [110.1444, -7.6973],
      [110.1438, -7.6974],
      [110.1435, -7.6974],
      [110.1432, -7.6974],
      [110.1427, -7.697],
      [110.1424, -7.6968],
      [110.1418, -7.6966],
      [110.141, -7.6965],
      [110.1405, -7.6965],
      [110.1392, -7.6951],
      [110.1386, -7.6948],
      [110.1383, -7.6948],
      [110.1382, -7.695],
      [110.1379, -7.6951],
      [110.1378, -7.6952],
      [110.1376, -7.6953],
      [110.1375, -7.6956],
      [110.1375, -7.6958],
      [110.1367, -7.696],
      [110.1362, -7.6962],
      [110.1357, -7.6965],
      [110.1354, -7.6967],
      [110.1352, -7.6968],
      [110.135, -7.6968],
      [110.1347, -7.6972],
      [110.1345, -7.6975],
      [110.1339, -7.698],
      [110.1337, -7.6983],
      [110.1336, -7.6983],
      [110.1333, -7.6986],
      [110.1326, -7.6987],
      [110.1325, -7.6988],
      [110.1323, -7.699],
      [110.1321, -7.6992],
      [110.1318, -7.6992],
      [110.1316, -7.6995],
      [110.1314, -7.6997],
      [110.1311, -7.6998],
      [110.1315, -7.7],
      [110.1312, -7.7003],
      [110.131, -7.7005],
      [110.1309, -7.7011],
      [110.1309, -7.7016],
      [110.131, -7.7017],
      [110.1315, -7.702],
      [110.1318, -7.7024],
      [110.132, -7.7027],
      [110.132, -7.7029],
      [110.132, -7.7033],
      [110.1321, -7.7036],
      [110.1322, -7.7037],
      [110.1324, -7.7038],
      [110.1328, -7.7036],
      [110.133, -7.7036],
      [110.1333, -7.7039],
      [110.1335, -7.704],
      [110.1336, -7.704],
      [110.1339, -7.7041],
      [110.1341, -7.7042],
      [110.1346, -7.704],
      [110.1347, -7.7043],
      [110.1348, -7.7046],
      [110.1353, -7.7048],
      [110.1353, -7.7051],
      [110.1351, -7.7053],
      [110.1353, -7.7055],
      [110.1354, -7.7056],
      [110.1356, -7.7058],
      [110.1358, -7.706],
      [110.1356, -7.7063],
      [110.1352, -7.7066],
      [110.135, -7.7067],
      [110.1349, -7.7069],
      [110.1348, -7.7072],
      [110.1345, -7.7075],
      [110.1344, -7.7077],
      [110.1344, -7.708],
      [110.1344, -7.7082],
      [110.1343, -7.7085],
      [110.1343, -7.7087],
      [110.1341, -7.7091],
      [110.1341, -7.7092],
      [110.1339, -7.7093],
      [110.1337, -7.7095],
      [110.1336, -7.7097],
      [110.1335, -7.7099],
      [110.1335, -7.71],
      [110.1335, -7.7103],
      [110.1336, -7.7105],
      [110.1337, -7.7109],
      [110.1337, -7.7112],
      [110.1337, -7.7113],
      [110.1337, -7.7119],
      [110.1341, -7.712],
      [110.1345, -7.7121],
      [110.1347, -7.7121],
      [110.1348, -7.7119],
      [110.1352, -7.7118],
      [110.1357, -7.7119],
      [110.1361, -7.712],
      [110.1365, -7.7122],
      [110.1369, -7.7124],
      [110.1373, -7.7126],
      [110.1375, -7.7126],
      [110.138, -7.7124],
      [110.1384, -7.7122],
      [110.1389, -7.7124],
      [110.1392, -7.7126],
      [110.1395, -7.7128],
      [110.14, -7.7128],
      [110.1401, -7.7131],
      [110.14, -7.7133],
      [110.1397, -7.7137],
      [110.1397, -7.7143],
      [110.1395, -7.7147],
      [110.1395, -7.7152],
      [110.1399, -7.7157],
      [110.1399, -7.7159],
      [110.1402, -7.7162],
      [110.1406, -7.7162],
      [110.141, -7.7162],
      [110.1412, -7.7161],
      [110.1415, -7.7161],
      [110.1419, -7.7161],
      [110.1426, -7.7162],
      [110.143, -7.7162],
      [110.1434, -7.7163],
      [110.1439, -7.7164],
      [110.1444, -7.7161],
      [110.1451, -7.7162],
      [110.1454, -7.7162],
      [110.1457, -7.7162],
      [110.1464, -7.7164],
      [110.1473, -7.7166],
      [110.1481, -7.7167],
      [110.1487, -7.7164],
      [110.1495, -7.716],
      [110.1498, -7.7154],
      [110.15, -7.715],
      [110.1504, -7.7147],
      [110.1506, -7.7141],
      [110.1507, -7.7139],
      [110.1507, -7.7137],
      [110.1508, -7.7131],
      [110.1513, -7.7128],
      [110.1514, -7.7127],
      [110.1518, -7.7128],
      [110.152, -7.7133],
      [110.1523, -7.7136],
      [110.1523, -7.7142],
      [110.1524, -7.7145],
      [110.1525, -7.7146],
      [110.1528, -7.7151],
      [110.1528, -7.7155],
      [110.1526, -7.7162],
      [110.1525, -7.7166],
      [110.1527, -7.717],
      [110.1529, -7.7171],
      [110.1532, -7.7172],
      [110.1535, -7.7173],
      [110.1543, -7.7173],
      [110.155, -7.7174],
      [110.1556, -7.7179],
      [110.1559, -7.7181],
      [110.1564, -7.7185],
      [110.1576, -7.719],
      [110.1587, -7.7192],
      [110.1596, -7.7193],
      [110.1603, -7.7193],
      [110.1609, -7.7194],
      [110.1616, -7.7195],
      [110.1628, -7.7195],
      [110.1636, -7.7193],
      [110.165, -7.7188],
      [110.1659, -7.7182],
      [110.1667, -7.7173],
      [110.1681, -7.716],
      [110.1685, -7.7154],
      [110.1689, -7.715]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SAMIGALUH',
    village: 'BANJARSARI',
    border: [
      [110.1567, -7.6804],
      [110.1561, -7.6801],
      [110.1556, -7.6797],
      [110.155, -7.6796],
      [110.1542, -7.6791],
      [110.1535, -7.6784],
      [110.1531, -7.6782],
      [110.153, -7.6779],
      [110.1527, -7.6778],
      [110.1525, -7.6774],
      [110.1522, -7.6773],
      [110.1521, -7.6774],
      [110.152, -7.6776],
      [110.1519, -7.6781],
      [110.1518, -7.6785],
      [110.1514, -7.679],
      [110.1514, -7.6794],
      [110.1511, -7.68],
      [110.151, -7.6801],
      [110.1511, -7.6802],
      [110.1511, -7.6804],
      [110.1511, -7.6806],
      [110.151, -7.681],
      [110.1506, -7.6813],
      [110.1503, -7.6819],
      [110.1502, -7.6821],
      [110.1502, -7.6826],
      [110.1502, -7.6832],
      [110.1501, -7.6833],
      [110.1499, -7.6834],
      [110.1497, -7.6834],
      [110.1496, -7.6833],
      [110.1493, -7.6837],
      [110.1488, -7.6836],
      [110.1485, -7.6841],
      [110.1483, -7.6845],
      [110.1486, -7.6849],
      [110.1483, -7.6855],
      [110.1479, -7.6861],
      [110.1475, -7.6871],
      [110.1474, -7.6872],
      [110.147, -7.6879],
      [110.1468, -7.6886],
      [110.1468, -7.6887],
      [110.1464, -7.689],
      [110.146, -7.6891],
      [110.1453, -7.6892],
      [110.1452, -7.6892],
      [110.145, -7.6894],
      [110.1446, -7.6902],
      [110.1439, -7.6902],
      [110.1438, -7.6906],
      [110.1436, -7.6912],
      [110.1431, -7.6919],
      [110.1424, -7.6926],
      [110.1419, -7.6928],
      [110.1417, -7.693],
      [110.1414, -7.6933],
      [110.1407, -7.6937],
      [110.1398, -7.6941],
      [110.1392, -7.6947],
      [110.139, -7.6947],
      [110.1386, -7.6948],
      [110.1392, -7.6951],
      [110.1405, -7.6965],
      [110.141, -7.6965],
      [110.1418, -7.6966],
      [110.1424, -7.6968],
      [110.1427, -7.697],
      [110.1432, -7.6974],
      [110.1435, -7.6974],
      [110.1438, -7.6974],
      [110.1444, -7.6973],
      [110.1452, -7.6973],
      [110.1459, -7.6973],
      [110.1463, -7.6974],
      [110.1465, -7.6977],
      [110.1471, -7.6976],
      [110.1477, -7.6976],
      [110.1483, -7.6978],
      [110.1489, -7.6978],
      [110.1495, -7.6977],
      [110.15, -7.6977],
      [110.1504, -7.6979],
      [110.1511, -7.6979],
      [110.1515, -7.6979],
      [110.152, -7.6984],
      [110.1526, -7.6987],
      [110.1534, -7.6989],
      [110.1537, -7.6994],
      [110.154, -7.6996],
      [110.1543, -7.6997],
      [110.1548, -7.6996],
      [110.1553, -7.6996],
      [110.1558, -7.6997],
      [110.1563, -7.6999],
      [110.1567, -7.7001],
      [110.1574, -7.7001],
      [110.158, -7.7002],
      [110.1584, -7.7],
      [110.1589, -7.6998],
      [110.1595, -7.7002],
      [110.1598, -7.7004],
      [110.1603, -7.7006],
      [110.1608, -7.7012],
      [110.1614, -7.7017],
      [110.1619, -7.7018],
      [110.1622, -7.7019],
      [110.1626, -7.7018],
      [110.1631, -7.7016],
      [110.1632, -7.7023],
      [110.1633, -7.7026],
      [110.1639, -7.703],
      [110.1643, -7.703],
      [110.1646, -7.7032],
      [110.1648, -7.7034],
      [110.1649, -7.7035],
      [110.1651, -7.7037],
      [110.1655, -7.7041],
      [110.1659, -7.7044],
      [110.1664, -7.7048],
      [110.1668, -7.7052],
      [110.1674, -7.7052],
      [110.1677, -7.7055],
      [110.1682, -7.7055],
      [110.1687, -7.7061],
      [110.1692, -7.7066],
      [110.1688, -7.7075],
      [110.1685, -7.7081],
      [110.168, -7.7087],
      [110.1677, -7.7092],
      [110.1678, -7.7102],
      [110.168, -7.7109],
      [110.1681, -7.7115],
      [110.168, -7.7122],
      [110.1682, -7.7131],
      [110.1686, -7.7143],
      [110.1689, -7.715],
      [110.169, -7.7149],
      [110.1695, -7.7148],
      [110.1705, -7.7145],
      [110.1712, -7.7145],
      [110.1721, -7.7145],
      [110.1726, -7.7147],
      [110.1729, -7.715],
      [110.173, -7.7154],
      [110.1731, -7.7155],
      [110.1733, -7.7157],
      [110.1733, -7.7166],
      [110.1739, -7.7172],
      [110.1745, -7.7176],
      [110.1752, -7.7178],
      [110.1758, -7.718],
      [110.1762, -7.7179],
      [110.1767, -7.7179],
      [110.1774, -7.7181],
      [110.1779, -7.7183],
      [110.1786, -7.7186],
      [110.1788, -7.7186],
      [110.1793, -7.7187],
      [110.1795, -7.719],
      [110.1795, -7.7194],
      [110.1797, -7.7197],
      [110.1801, -7.7197],
      [110.1805, -7.7194],
      [110.1812, -7.7188],
      [110.1814, -7.7188],
      [110.1816, -7.7189],
      [110.1822, -7.7193],
      [110.1829, -7.7198],
      [110.184, -7.7202],
      [110.1849, -7.7203],
      [110.1859, -7.7201],
      [110.1865, -7.7197],
      [110.1869, -7.7197],
      [110.1871, -7.7196],
      [110.1875, -7.7196],
      [110.1883, -7.7196],
      [110.1887, -7.7198],
      [110.1891, -7.7197],
      [110.1897, -7.7198],
      [110.1898, -7.7198],
      [110.1902, -7.7195],
      [110.1906, -7.7192],
      [110.1914, -7.7193],
      [110.1921, -7.7194],
      [110.1927, -7.7194],
      [110.1931, -7.7196],
      [110.1935, -7.7189],
      [110.1938, -7.7182],
      [110.1936, -7.7169],
      [110.1934, -7.7158],
      [110.1937, -7.7145],
      [110.194, -7.7141],
      [110.1947, -7.7134],
      [110.1949, -7.7129],
      [110.1945, -7.7116],
      [110.1941, -7.7113],
      [110.1934, -7.7105],
      [110.1934, -7.7099],
      [110.1933, -7.7091],
      [110.1932, -7.7088],
      [110.1928, -7.7083],
      [110.1916, -7.7079],
      [110.191, -7.7078],
      [110.1902, -7.7077],
      [110.1896, -7.7076],
      [110.1892, -7.7074],
      [110.189, -7.7074],
      [110.189, -7.707],
      [110.1895, -7.7064],
      [110.1901, -7.7057],
      [110.1907, -7.7047],
      [110.1915, -7.7034],
      [110.1919, -7.7025],
      [110.1918, -7.7016],
      [110.1914, -7.7012],
      [110.1911, -7.7009],
      [110.1909, -7.7008],
      [110.19, -7.7005],
      [110.1886, -7.6998],
      [110.1871, -7.699],
      [110.1864, -7.6988],
      [110.1859, -7.6988],
      [110.1856, -7.6991],
      [110.1852, -7.6994],
      [110.1847, -7.6999],
      [110.1842, -7.7001],
      [110.1837, -7.7001],
      [110.1832, -7.6999],
      [110.1822, -7.6993],
      [110.1819, -7.6991],
      [110.181, -7.6985],
      [110.1803, -7.6979],
      [110.1799, -7.6973],
      [110.1792, -7.6963],
      [110.1777, -7.6959],
      [110.1752, -7.6955],
      [110.1736, -7.6954],
      [110.1724, -7.6955],
      [110.1711, -7.6955],
      [110.1702, -7.6954],
      [110.1694, -7.695],
      [110.1685, -7.6942],
      [110.1672, -7.6931],
      [110.1661, -7.6922],
      [110.1646, -7.6904],
      [110.1643, -7.6902],
      [110.1628, -7.6887],
      [110.1621, -7.6882],
      [110.1615, -7.688],
      [110.1609, -7.6879],
      [110.1587, -7.6876],
      [110.1585, -7.6876],
      [110.1561, -7.6872],
      [110.156, -7.6872],
      [110.1557, -7.6868],
      [110.1557, -7.6864],
      [110.1559, -7.686],
      [110.156, -7.6857],
      [110.1561, -7.6853],
      [110.1564, -7.6852],
      [110.1566, -7.685],
      [110.1567, -7.6847],
      [110.1564, -7.6845],
      [110.1564, -7.684],
      [110.1564, -7.6834],
      [110.1571, -7.6825],
      [110.1575, -7.6818],
      [110.1574, -7.6813],
      [110.157, -7.6808],
      [110.1567, -7.6804]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SAMIGALUH',
    village: 'PURWOHARJO',
    border: [
      [110.2081, -7.6867],
      [110.2077, -7.6865],
      [110.2074, -7.6861],
      [110.2072, -7.6854],
      [110.207, -7.6852],
      [110.2067, -7.685],
      [110.2065, -7.6846],
      [110.206, -7.6843],
      [110.2054, -7.6843],
      [110.2047, -7.684],
      [110.2045, -7.6843],
      [110.2042, -7.6842],
      [110.2038, -7.6835],
      [110.2034, -7.6833],
      [110.2032, -7.6828],
      [110.2022, -7.6827],
      [110.2012, -7.6827],
      [110.1993, -7.6826],
      [110.1982, -7.6824],
      [110.1979, -7.6822],
      [110.1973, -7.6823],
      [110.197, -7.6822],
      [110.1966, -7.6821],
      [110.1964, -7.6821],
      [110.1962, -7.6821],
      [110.1959, -7.6818],
      [110.1956, -7.682],
      [110.1953, -7.6823],
      [110.1947, -7.6824],
      [110.1946, -7.6826],
      [110.1943, -7.6829],
      [110.1938, -7.6827],
      [110.1933, -7.6831],
      [110.1929, -7.6833],
      [110.1927, -7.6834],
      [110.1924, -7.6835],
      [110.1921, -7.6831],
      [110.1915, -7.6832],
      [110.1908, -7.6833],
      [110.1906, -7.6833],
      [110.1899, -7.6834],
      [110.1895, -7.6834],
      [110.1891, -7.6836],
      [110.1888, -7.6837],
      [110.1883, -7.6836],
      [110.1882, -7.6841],
      [110.1882, -7.6848],
      [110.188, -7.6855],
      [110.1879, -7.686],
      [110.1873, -7.6862],
      [110.1864, -7.6866],
      [110.1859, -7.6868],
      [110.1857, -7.687],
      [110.1854, -7.6873],
      [110.1849, -7.6875],
      [110.1845, -7.6879],
      [110.184, -7.6881],
      [110.1834, -7.6889],
      [110.1824, -7.6901],
      [110.1821, -7.6903],
      [110.1816, -7.6905],
      [110.1812, -7.6904],
      [110.181, -7.6903],
      [110.1807, -7.6901],
      [110.1805, -7.6901],
      [110.1801, -7.6898],
      [110.1795, -7.6898],
      [110.1794, -7.6898],
      [110.1787, -7.6898],
      [110.1778, -7.6898],
      [110.1775, -7.6898],
      [110.1769, -7.6896],
      [110.1753, -7.689],
      [110.1752, -7.689],
      [110.1749, -7.6889],
      [110.1739, -7.6887],
      [110.1727, -7.6887],
      [110.1717, -7.6887],
      [110.1716, -7.6889],
      [110.1713, -7.6896],
      [110.1709, -7.6899],
      [110.1703, -7.6901],
      [110.1702, -7.6903],
      [110.1702, -7.6907],
      [110.17, -7.6912],
      [110.1697, -7.6916],
      [110.1695, -7.6919],
      [110.1692, -7.6918],
      [110.1687, -7.6921],
      [110.1684, -7.6923],
      [110.1681, -7.6923],
      [110.1678, -7.6924],
      [110.1675, -7.6926],
      [110.1674, -7.693],
      [110.1672, -7.6931],
      [110.1685, -7.6942],
      [110.1694, -7.695],
      [110.1702, -7.6954],
      [110.1711, -7.6955],
      [110.1724, -7.6955],
      [110.1736, -7.6954],
      [110.1752, -7.6955],
      [110.1777, -7.6959],
      [110.1792, -7.6963],
      [110.1799, -7.6973],
      [110.1803, -7.6979],
      [110.181, -7.6985],
      [110.1819, -7.6991],
      [110.1822, -7.6993],
      [110.1832, -7.6999],
      [110.1837, -7.7001],
      [110.1842, -7.7001],
      [110.1847, -7.6999],
      [110.1852, -7.6994],
      [110.1856, -7.6991],
      [110.1859, -7.6988],
      [110.1864, -7.6988],
      [110.1871, -7.699],
      [110.1886, -7.6998],
      [110.19, -7.7005],
      [110.1909, -7.7008],
      [110.1911, -7.7009],
      [110.1914, -7.7012],
      [110.1918, -7.7016],
      [110.1919, -7.7025],
      [110.1915, -7.7034],
      [110.1907, -7.7047],
      [110.1901, -7.7057],
      [110.1895, -7.7064],
      [110.189, -7.707],
      [110.189, -7.7074],
      [110.1892, -7.7074],
      [110.1896, -7.7076],
      [110.1902, -7.7077],
      [110.191, -7.7078],
      [110.1916, -7.7079],
      [110.1928, -7.7083],
      [110.1932, -7.7088],
      [110.1933, -7.7091],
      [110.1934, -7.7099],
      [110.1934, -7.7105],
      [110.1941, -7.7113],
      [110.1945, -7.7116],
      [110.1949, -7.7129],
      [110.1947, -7.7134],
      [110.194, -7.7141],
      [110.1937, -7.7145],
      [110.1934, -7.7158],
      [110.1936, -7.7169],
      [110.1938, -7.7182],
      [110.1935, -7.7189],
      [110.1931, -7.7196],
      [110.1932, -7.7197],
      [110.1943, -7.7198],
      [110.1952, -7.7197],
      [110.1959, -7.7195],
      [110.1962, -7.7192],
      [110.1965, -7.7186],
      [110.1969, -7.7175],
      [110.1974, -7.7168],
      [110.198, -7.7166],
      [110.1987, -7.7167],
      [110.199, -7.7168],
      [110.1995, -7.7172],
      [110.1998, -7.7177],
      [110.2001, -7.718],
      [110.201, -7.7183],
      [110.2019, -7.7186],
      [110.203, -7.7189],
      [110.2036, -7.7188],
      [110.2046, -7.7191],
      [110.2059, -7.7189],
      [110.207, -7.7185],
      [110.2081, -7.7179],
      [110.209, -7.7174],
      [110.2099, -7.7165],
      [110.211, -7.7153],
      [110.2112, -7.7151],
      [110.2119, -7.7144],
      [110.2122, -7.714],
      [110.2125, -7.7138],
      [110.2127, -7.7135],
      [110.2131, -7.713],
      [110.2132, -7.7129],
      [110.2134, -7.7126],
      [110.2139, -7.7119],
      [110.2143, -7.7106],
      [110.2146, -7.7092],
      [110.2147, -7.7082],
      [110.2149, -7.7074],
      [110.2148, -7.7067],
      [110.2146, -7.7066],
      [110.2144, -7.7064],
      [110.2137, -7.7062],
      [110.2136, -7.7058],
      [110.2134, -7.7056],
      [110.2127, -7.7056],
      [110.2125, -7.7055],
      [110.2122, -7.7051],
      [110.2118, -7.705],
      [110.2114, -7.7047],
      [110.2111, -7.7046],
      [110.2107, -7.7048],
      [110.2104, -7.7046],
      [110.2101, -7.7042],
      [110.2095, -7.7038],
      [110.209, -7.7034],
      [110.2088, -7.7033],
      [110.2084, -7.703],
      [110.208, -7.703],
      [110.2078, -7.7027],
      [110.2078, -7.7022],
      [110.2075, -7.7019],
      [110.2071, -7.7017],
      [110.2067, -7.7017],
      [110.2065, -7.7017],
      [110.2063, -7.7014],
      [110.2061, -7.7008],
      [110.206, -7.7003],
      [110.2058, -7.7001],
      [110.2059, -7.6998],
      [110.2061, -7.6994],
      [110.2065, -7.6992],
      [110.2066, -7.6991],
      [110.2067, -7.6989],
      [110.2069, -7.6986],
      [110.2069, -7.6983],
      [110.2076, -7.6972],
      [110.2084, -7.696],
      [110.2087, -7.6956],
      [110.2087, -7.6952],
      [110.2085, -7.6949],
      [110.2083, -7.6945],
      [110.2084, -7.6941],
      [110.2086, -7.6933],
      [110.2088, -7.6928],
      [110.209, -7.6925],
      [110.2093, -7.692],
      [110.2094, -7.6913],
      [110.2095, -7.6908],
      [110.2093, -7.6905],
      [110.2095, -7.6902],
      [110.2094, -7.6896],
      [110.2093, -7.6893],
      [110.2089, -7.6891],
      [110.2085, -7.6886],
      [110.2083, -7.6883],
      [110.2082, -7.6877],
      [110.2081, -7.6871],
      [110.2081, -7.6867]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SAMIGALUH',
    village: 'SIDOHARJO',
    border: [
      [110.1918, -7.645],
      [110.1917, -7.645],
      [110.1912, -7.645],
      [110.1908, -7.645],
      [110.1906, -7.6451],
      [110.1904, -7.6452],
      [110.1901, -7.6454],
      [110.1899, -7.6457],
      [110.1895, -7.6454],
      [110.1888, -7.6456],
      [110.1884, -7.6456],
      [110.1882, -7.6457],
      [110.1881, -7.6461],
      [110.1876, -7.6461],
      [110.1874, -7.6463],
      [110.1871, -7.6464],
      [110.1868, -7.6463],
      [110.1867, -7.646],
      [110.1866, -7.6464],
      [110.1869, -7.6468],
      [110.1872, -7.6471],
      [110.1874, -7.6476],
      [110.1876, -7.6482],
      [110.1878, -7.6487],
      [110.1876, -7.649],
      [110.188, -7.6494],
      [110.188, -7.65],
      [110.188, -7.6506],
      [110.1882, -7.6512],
      [110.1882, -7.6515],
      [110.1881, -7.6519],
      [110.1878, -7.6522],
      [110.1879, -7.6524],
      [110.1881, -7.6526],
      [110.1883, -7.6529],
      [110.1883, -7.6531],
      [110.1883, -7.6532],
      [110.1883, -7.6544],
      [110.1883, -7.655],
      [110.1883, -7.6551],
      [110.1879, -7.6553],
      [110.1874, -7.6554],
      [110.1873, -7.6557],
      [110.1876, -7.6559],
      [110.1877, -7.656],
      [110.1879, -7.6564],
      [110.1877, -7.6567],
      [110.1876, -7.6569],
      [110.1877, -7.6573],
      [110.1875, -7.6577],
      [110.1876, -7.6583],
      [110.1875, -7.6585],
      [110.1876, -7.6589],
      [110.1874, -7.6597],
      [110.1872, -7.66],
      [110.1872, -7.6605],
      [110.1873, -7.6606],
      [110.1874, -7.6608],
      [110.1874, -7.6614],
      [110.1874, -7.6618],
      [110.1876, -7.6626],
      [110.1877, -7.6629],
      [110.1878, -7.6632],
      [110.1879, -7.664],
      [110.188, -7.6643],
      [110.1881, -7.6646],
      [110.1881, -7.6649],
      [110.1882, -7.6654],
      [110.1881, -7.6666],
      [110.188, -7.667],
      [110.1876, -7.6674],
      [110.1868, -7.6676],
      [110.1855, -7.6676],
      [110.1842, -7.6674],
      [110.1841, -7.6674],
      [110.1836, -7.6674],
      [110.183, -7.6677],
      [110.1816, -7.6687],
      [110.1806, -7.6697],
      [110.1798, -7.6708],
      [110.1795, -7.6715],
      [110.1794, -7.6722],
      [110.1793, -7.6731],
      [110.1797, -7.674],
      [110.1798, -7.6742],
      [110.1799, -7.6744],
      [110.18, -7.6746],
      [110.1797, -7.6749],
      [110.1804, -7.6755],
      [110.1807, -7.6758],
      [110.1812, -7.6766],
      [110.1815, -7.6769],
      [110.1813, -7.6772],
      [110.1813, -7.6777],
      [110.1807, -7.6778],
      [110.1805, -7.6784],
      [110.1801, -7.6784],
      [110.18, -7.6788],
      [110.1799, -7.679],
      [110.18, -7.6795],
      [110.1802, -7.68],
      [110.1801, -7.6802],
      [110.1804, -7.6803],
      [110.1805, -7.6805],
      [110.1806, -7.6809],
      [110.1811, -7.6818],
      [110.1817, -7.6826],
      [110.1818, -7.6833],
      [110.1822, -7.6839],
      [110.1821, -7.6843],
      [110.1826, -7.6846],
      [110.1833, -7.6853],
      [110.1837, -7.6858],
      [110.184, -7.686],
      [110.1843, -7.6861],
      [110.185, -7.686],
      [110.1853, -7.6857],
      [110.1857, -7.6858],
      [110.1858, -7.6863],
      [110.1859, -7.6868],
      [110.1864, -7.6866],
      [110.1873, -7.6862],
      [110.1879, -7.686],
      [110.188, -7.6855],
      [110.1882, -7.6848],
      [110.1882, -7.6841],
      [110.1883, -7.6836],
      [110.1888, -7.6837],
      [110.1891, -7.6836],
      [110.1895, -7.6834],
      [110.1899, -7.6834],
      [110.1906, -7.6833],
      [110.1908, -7.6833],
      [110.1915, -7.6832],
      [110.1921, -7.6831],
      [110.1924, -7.6835],
      [110.1927, -7.6834],
      [110.1929, -7.6833],
      [110.1933, -7.6831],
      [110.1938, -7.6827],
      [110.1943, -7.6829],
      [110.1946, -7.6826],
      [110.1947, -7.6824],
      [110.1953, -7.6823],
      [110.1956, -7.682],
      [110.1959, -7.6818],
      [110.1962, -7.6821],
      [110.1964, -7.6821],
      [110.1966, -7.6821],
      [110.197, -7.6822],
      [110.1973, -7.6823],
      [110.1979, -7.6822],
      [110.1982, -7.6824],
      [110.1993, -7.6826],
      [110.2012, -7.6827],
      [110.2022, -7.6827],
      [110.2032, -7.6828],
      [110.2034, -7.6833],
      [110.2038, -7.6835],
      [110.2042, -7.6842],
      [110.2045, -7.6843],
      [110.2047, -7.684],
      [110.2054, -7.6843],
      [110.206, -7.6843],
      [110.2065, -7.6846],
      [110.2067, -7.685],
      [110.207, -7.6852],
      [110.2072, -7.6854],
      [110.2074, -7.6861],
      [110.2077, -7.6865],
      [110.2081, -7.6867],
      [110.208, -7.6865],
      [110.2082, -7.6862],
      [110.2086, -7.6857],
      [110.2088, -7.6854],
      [110.2091, -7.685],
      [110.2094, -7.6849],
      [110.2098, -7.685],
      [110.2102, -7.685],
      [110.2106, -7.6848],
      [110.2109, -7.6844],
      [110.2113, -7.6844],
      [110.2117, -7.6845],
      [110.212, -7.6844],
      [110.2125, -7.6841],
      [110.2131, -7.6839],
      [110.2133, -7.6836],
      [110.2134, -7.6831],
      [110.2135, -7.6829],
      [110.2137, -7.6825],
      [110.2139, -7.6825],
      [110.2141, -7.6824],
      [110.2145, -7.6824],
      [110.2148, -7.682],
      [110.2151, -7.6815],
      [110.2149, -7.6805],
      [110.2149, -7.6798],
      [110.215, -7.6795],
      [110.2152, -7.6793],
      [110.2153, -7.6788],
      [110.2155, -7.6784],
      [110.2154, -7.6781],
      [110.2153, -7.6778],
      [110.215, -7.6775],
      [110.2146, -7.6772],
      [110.2141, -7.6768],
      [110.2138, -7.6764],
      [110.2136, -7.6757],
      [110.2137, -7.6752],
      [110.2138, -7.6747],
      [110.2133, -7.6736],
      [110.2131, -7.673],
      [110.213, -7.6725],
      [110.2122, -7.6719],
      [110.2119, -7.6716],
      [110.2117, -7.6711],
      [110.2115, -7.6706],
      [110.2113, -7.6701],
      [110.2108, -7.6699],
      [110.2107, -7.6695],
      [110.2107, -7.6691],
      [110.2107, -7.669],
      [110.2107, -7.6684],
      [110.2108, -7.6677],
      [110.2108, -7.6671],
      [110.2107, -7.6668],
      [110.2106, -7.6664],
      [110.2105, -7.666],
      [110.2102, -7.6656],
      [110.21, -7.665],
      [110.2097, -7.6646],
      [110.2094, -7.6639],
      [110.2091, -7.6631],
      [110.2087, -7.6625],
      [110.2085, -7.6618],
      [110.2081, -7.6611],
      [110.2078, -7.6607],
      [110.2075, -7.66],
      [110.2074, -7.6595],
      [110.2073, -7.6589],
      [110.2073, -7.6582],
      [110.2072, -7.6578],
      [110.2072, -7.6576],
      [110.2071, -7.6573],
      [110.2072, -7.6569],
      [110.207, -7.6564],
      [110.2071, -7.6563],
      [110.2072, -7.656],
      [110.2069, -7.6558],
      [110.2063, -7.6556],
      [110.2061, -7.6555],
      [110.2061, -7.6551],
      [110.2057, -7.655],
      [110.2055, -7.6548],
      [110.2055, -7.6543],
      [110.2053, -7.6541],
      [110.2053, -7.6538],
      [110.2055, -7.6536],
      [110.2056, -7.6533],
      [110.2056, -7.6532],
      [110.2056, -7.6531],
      [110.2054, -7.6529],
      [110.2052, -7.6525],
      [110.2052, -7.652],
      [110.2046, -7.6516],
      [110.2044, -7.6515],
      [110.204, -7.6514],
      [110.2032, -7.6514],
      [110.2023, -7.6512],
      [110.2006, -7.6507],
      [110.1992, -7.6501],
      [110.198, -7.6495],
      [110.1962, -7.6485],
      [110.1948, -7.6474],
      [110.194, -7.6467],
      [110.1933, -7.646],
      [110.1928, -7.6456],
      [110.1922, -7.6453],
      [110.1918, -7.645]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SAMIGALUH',
    village: 'GERBOSARI',
    border: [
      [110.1867, -7.646],
      [110.1866, -7.6459],
      [110.186, -7.6458],
      [110.1859, -7.6457],
      [110.1854, -7.646],
      [110.185, -7.6464],
      [110.184, -7.6466],
      [110.1832, -7.6468],
      [110.1823, -7.6469],
      [110.1817, -7.6468],
      [110.1814, -7.6472],
      [110.181, -7.647],
      [110.1801, -7.647],
      [110.1795, -7.6472],
      [110.1792, -7.6475],
      [110.1788, -7.6477],
      [110.1784, -7.6477],
      [110.1778, -7.6477],
      [110.1774, -7.648],
      [110.1771, -7.6476],
      [110.1768, -7.6475],
      [110.1765, -7.6474],
      [110.1764, -7.6474],
      [110.1761, -7.6477],
      [110.1761, -7.648],
      [110.1758, -7.648],
      [110.1754, -7.6481],
      [110.175, -7.6483],
      [110.1748, -7.6481],
      [110.1744, -7.6479],
      [110.1742, -7.6478],
      [110.1739, -7.6479],
      [110.1737, -7.6481],
      [110.1736, -7.6483],
      [110.1731, -7.6483],
      [110.1728, -7.6483],
      [110.172, -7.6484],
      [110.1714, -7.6484],
      [110.171, -7.6484],
      [110.1707, -7.6481],
      [110.1705, -7.6481],
      [110.17, -7.6483],
      [110.1697, -7.6483],
      [110.1694, -7.648],
      [110.1691, -7.648],
      [110.1689, -7.6486],
      [110.1688, -7.6487],
      [110.1686, -7.6491],
      [110.1685, -7.6495],
      [110.1683, -7.6498],
      [110.1684, -7.6502],
      [110.1683, -7.6506],
      [110.1683, -7.651],
      [110.1683, -7.6513],
      [110.1685, -7.6525],
      [110.1682, -7.6539],
      [110.168, -7.6549],
      [110.1677, -7.6566],
      [110.1677, -7.6567],
      [110.1675, -7.6578],
      [110.1672, -7.6593],
      [110.1671, -7.6602],
      [110.1671, -7.6611],
      [110.1668, -7.6616],
      [110.1665, -7.662],
      [110.1663, -7.6627],
      [110.166, -7.6633],
      [110.1657, -7.6638],
      [110.1659, -7.6641],
      [110.166, -7.6645],
      [110.1663, -7.665],
      [110.1662, -7.6654],
      [110.1657, -7.6656],
      [110.1655, -7.666],
      [110.1652, -7.6665],
      [110.1649, -7.6672],
      [110.1651, -7.6679],
      [110.1652, -7.6685],
      [110.1651, -7.6689],
      [110.1654, -7.6691],
      [110.1655, -7.6704],
      [110.1651, -7.6713],
      [110.1646, -7.6723],
      [110.1637, -7.6731],
      [110.1626, -7.6737],
      [110.1613, -7.6741],
      [110.1605, -7.6739],
      [110.1604, -7.6743],
      [110.1603, -7.6746],
      [110.1599, -7.6752],
      [110.1594, -7.6757],
      [110.1585, -7.6763],
      [110.1581, -7.6765],
      [110.1582, -7.6772],
      [110.1574, -7.679],
      [110.1567, -7.6804],
      [110.157, -7.6808],
      [110.1574, -7.6813],
      [110.1575, -7.6818],
      [110.1571, -7.6825],
      [110.1564, -7.6834],
      [110.1564, -7.684],
      [110.1564, -7.6845],
      [110.1567, -7.6847],
      [110.1566, -7.685],
      [110.1564, -7.6852],
      [110.1561, -7.6853],
      [110.156, -7.6857],
      [110.1559, -7.686],
      [110.1557, -7.6864],
      [110.1557, -7.6868],
      [110.156, -7.6872],
      [110.1561, -7.6872],
      [110.1585, -7.6876],
      [110.1587, -7.6876],
      [110.1609, -7.6879],
      [110.1615, -7.688],
      [110.1621, -7.6882],
      [110.1628, -7.6887],
      [110.1643, -7.6902],
      [110.1646, -7.6904],
      [110.1661, -7.6922],
      [110.1672, -7.6931],
      [110.1674, -7.693],
      [110.1675, -7.6926],
      [110.1678, -7.6924],
      [110.1681, -7.6923],
      [110.1684, -7.6923],
      [110.1687, -7.6921],
      [110.1692, -7.6918],
      [110.1695, -7.6919],
      [110.1697, -7.6916],
      [110.17, -7.6912],
      [110.1702, -7.6907],
      [110.1702, -7.6903],
      [110.1703, -7.6901],
      [110.1709, -7.6899],
      [110.1713, -7.6896],
      [110.1716, -7.6889],
      [110.1717, -7.6887],
      [110.1727, -7.6887],
      [110.1739, -7.6887],
      [110.1749, -7.6889],
      [110.1752, -7.689],
      [110.1753, -7.689],
      [110.1769, -7.6896],
      [110.1775, -7.6898],
      [110.1778, -7.6898],
      [110.1787, -7.6898],
      [110.1794, -7.6898],
      [110.1795, -7.6898],
      [110.1801, -7.6898],
      [110.1805, -7.6901],
      [110.1807, -7.6901],
      [110.181, -7.6903],
      [110.1812, -7.6904],
      [110.1816, -7.6905],
      [110.1821, -7.6903],
      [110.1824, -7.6901],
      [110.1834, -7.6889],
      [110.184, -7.6881],
      [110.1845, -7.6879],
      [110.1849, -7.6875],
      [110.1854, -7.6873],
      [110.1857, -7.687],
      [110.1859, -7.6868],
      [110.1858, -7.6863],
      [110.1857, -7.6858],
      [110.1853, -7.6857],
      [110.185, -7.686],
      [110.1843, -7.6861],
      [110.184, -7.686],
      [110.1837, -7.6858],
      [110.1833, -7.6853],
      [110.1826, -7.6846],
      [110.1821, -7.6843],
      [110.1822, -7.6839],
      [110.1818, -7.6833],
      [110.1817, -7.6826],
      [110.1811, -7.6818],
      [110.1806, -7.6809],
      [110.1805, -7.6805],
      [110.1804, -7.6803],
      [110.1801, -7.6802],
      [110.1802, -7.68],
      [110.18, -7.6795],
      [110.1799, -7.679],
      [110.18, -7.6788],
      [110.1801, -7.6784],
      [110.1805, -7.6784],
      [110.1807, -7.6778],
      [110.1813, -7.6777],
      [110.1813, -7.6772],
      [110.1815, -7.6769],
      [110.1812, -7.6766],
      [110.1807, -7.6758],
      [110.1804, -7.6755],
      [110.1797, -7.6749],
      [110.18, -7.6746],
      [110.1799, -7.6744],
      [110.1798, -7.6742],
      [110.1797, -7.674],
      [110.1793, -7.6731],
      [110.1794, -7.6722],
      [110.1795, -7.6715],
      [110.1798, -7.6708],
      [110.1806, -7.6697],
      [110.1816, -7.6687],
      [110.183, -7.6677],
      [110.1836, -7.6674],
      [110.1841, -7.6674],
      [110.1842, -7.6674],
      [110.1855, -7.6676],
      [110.1868, -7.6676],
      [110.1876, -7.6674],
      [110.188, -7.667],
      [110.1881, -7.6666],
      [110.1882, -7.6654],
      [110.1881, -7.6649],
      [110.1881, -7.6646],
      [110.188, -7.6643],
      [110.1879, -7.664],
      [110.1878, -7.6632],
      [110.1877, -7.6629],
      [110.1876, -7.6626],
      [110.1874, -7.6618],
      [110.1874, -7.6614],
      [110.1874, -7.6608],
      [110.1873, -7.6606],
      [110.1872, -7.6605],
      [110.1872, -7.66],
      [110.1874, -7.6597],
      [110.1876, -7.6589],
      [110.1875, -7.6585],
      [110.1876, -7.6583],
      [110.1875, -7.6577],
      [110.1877, -7.6573],
      [110.1876, -7.6569],
      [110.1877, -7.6567],
      [110.1879, -7.6564],
      [110.1877, -7.656],
      [110.1876, -7.6559],
      [110.1873, -7.6557],
      [110.1874, -7.6554],
      [110.1879, -7.6553],
      [110.1883, -7.6551],
      [110.1883, -7.655],
      [110.1883, -7.6544],
      [110.1883, -7.6532],
      [110.1883, -7.6531],
      [110.1883, -7.6529],
      [110.1881, -7.6526],
      [110.1879, -7.6524],
      [110.1878, -7.6522],
      [110.1881, -7.6519],
      [110.1882, -7.6515],
      [110.1882, -7.6512],
      [110.188, -7.6506],
      [110.188, -7.65],
      [110.188, -7.6494],
      [110.1876, -7.649],
      [110.1878, -7.6487],
      [110.1876, -7.6482],
      [110.1874, -7.6476],
      [110.1872, -7.6471],
      [110.1869, -7.6468],
      [110.1866, -7.6464],
      [110.1867, -7.646]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SAMIGALUH',
    village: 'NGARGOSARI',
    border: [
      [110.1691, -7.648],
      [110.1688, -7.6482],
      [110.1687, -7.6482],
      [110.168, -7.648],
      [110.1678, -7.648],
      [110.1674, -7.648],
      [110.167, -7.6483],
      [110.1665, -7.6486],
      [110.1662, -7.6486],
      [110.166, -7.6486],
      [110.1658, -7.6489],
      [110.1655, -7.6489],
      [110.165, -7.6486],
      [110.1648, -7.6485],
      [110.1646, -7.6485],
      [110.1643, -7.6485],
      [110.1639, -7.6482],
      [110.1636, -7.6478],
      [110.1634, -7.6477],
      [110.1631, -7.6473],
      [110.163, -7.647],
      [110.1627, -7.6466],
      [110.1624, -7.6472],
      [110.1623, -7.6473],
      [110.1625, -7.6476],
      [110.1625, -7.6478],
      [110.1625, -7.648],
      [110.1624, -7.648],
      [110.1623, -7.6479],
      [110.162, -7.6478],
      [110.1617, -7.6481],
      [110.1616, -7.6482],
      [110.1614, -7.6478],
      [110.1614, -7.6477],
      [110.1613, -7.6475],
      [110.1612, -7.6473],
      [110.1611, -7.6472],
      [110.1611, -7.647],
      [110.1607, -7.6463],
      [110.1606, -7.6462],
      [110.1605, -7.646],
      [110.1604, -7.6459],
      [110.1603, -7.6459],
      [110.1599, -7.6458],
      [110.1596, -7.6458],
      [110.1593, -7.6458],
      [110.1587, -7.6458],
      [110.1578, -7.646],
      [110.1573, -7.6461],
      [110.1569, -7.6462],
      [110.1568, -7.6464],
      [110.1568, -7.6466],
      [110.1566, -7.6469],
      [110.156, -7.6471],
      [110.1556, -7.6469],
      [110.1551, -7.6467],
      [110.1541, -7.6466],
      [110.1527, -7.6464],
      [110.1524, -7.6463],
      [110.1514, -7.6463],
      [110.1502, -7.6465],
      [110.1501, -7.6465],
      [110.1496, -7.6467],
      [110.1488, -7.647],
      [110.1487, -7.6471],
      [110.148, -7.6477],
      [110.1478, -7.6482],
      [110.1476, -7.6486],
      [110.1473, -7.6491],
      [110.147, -7.6495],
      [110.1467, -7.6498],
      [110.1465, -7.6499],
      [110.1464, -7.6499],
      [110.1461, -7.6499],
      [110.1459, -7.6497],
      [110.1457, -7.6497],
      [110.1454, -7.6499],
      [110.145, -7.6497],
      [110.1449, -7.6497],
      [110.1447, -7.6498],
      [110.1445, -7.65],
      [110.1444, -7.6501],
      [110.1443, -7.6503],
      [110.1443, -7.6505],
      [110.1445, -7.6509],
      [110.1446, -7.6516],
      [110.1449, -7.6523],
      [110.1448, -7.6531],
      [110.1447, -7.6537],
      [110.145, -7.6541],
      [110.1449, -7.6545],
      [110.1446, -7.6547],
      [110.1447, -7.6553],
      [110.1447, -7.6557],
      [110.1446, -7.6562],
      [110.1444, -7.6573],
      [110.1447, -7.6584],
      [110.1445, -7.6614],
      [110.1445, -7.6619],
      [110.1444, -7.6624],
      [110.1447, -7.6635],
      [110.1456, -7.6657],
      [110.1456, -7.6658],
      [110.1464, -7.6673],
      [110.1468, -7.6678],
      [110.1476, -7.6696],
      [110.1481, -7.6703],
      [110.149, -7.6716],
      [110.1499, -7.6724],
      [110.1506, -7.6725],
      [110.1508, -7.6727],
      [110.1513, -7.6728],
      [110.1515, -7.673],
      [110.1519, -7.6732],
      [110.1527, -7.674],
      [110.153, -7.6741],
      [110.1534, -7.6743],
      [110.1539, -7.6742],
      [110.1538, -7.6744],
      [110.1537, -7.6749],
      [110.1538, -7.6754],
      [110.1536, -7.6756],
      [110.1531, -7.6758],
      [110.1528, -7.6763],
      [110.1525, -7.6766],
      [110.1523, -7.6769],
      [110.1522, -7.6773],
      [110.1525, -7.6774],
      [110.1527, -7.6778],
      [110.153, -7.6779],
      [110.1531, -7.6782],
      [110.1535, -7.6784],
      [110.1542, -7.6791],
      [110.155, -7.6796],
      [110.1556, -7.6797],
      [110.1561, -7.6801],
      [110.1567, -7.6804],
      [110.1574, -7.679],
      [110.1582, -7.6772],
      [110.1581, -7.6765],
      [110.1585, -7.6763],
      [110.1594, -7.6757],
      [110.1599, -7.6752],
      [110.1603, -7.6746],
      [110.1604, -7.6743],
      [110.1605, -7.6739],
      [110.1613, -7.6741],
      [110.1626, -7.6737],
      [110.1637, -7.6731],
      [110.1646, -7.6723],
      [110.1651, -7.6713],
      [110.1655, -7.6704],
      [110.1654, -7.6691],
      [110.1651, -7.6689],
      [110.1652, -7.6685],
      [110.1651, -7.6679],
      [110.1649, -7.6672],
      [110.1652, -7.6665],
      [110.1655, -7.666],
      [110.1657, -7.6656],
      [110.1662, -7.6654],
      [110.1663, -7.665],
      [110.166, -7.6645],
      [110.1659, -7.6641],
      [110.1657, -7.6638],
      [110.166, -7.6633],
      [110.1663, -7.6627],
      [110.1665, -7.662],
      [110.1668, -7.6616],
      [110.1671, -7.6611],
      [110.1671, -7.6602],
      [110.1672, -7.6593],
      [110.1675, -7.6578],
      [110.1677, -7.6567],
      [110.1677, -7.6566],
      [110.168, -7.6549],
      [110.1682, -7.6539],
      [110.1685, -7.6525],
      [110.1683, -7.6513],
      [110.1683, -7.651],
      [110.1683, -7.6506],
      [110.1684, -7.6502],
      [110.1683, -7.6498],
      [110.1685, -7.6495],
      [110.1686, -7.6491],
      [110.1688, -7.6487],
      [110.1689, -7.6486],
      [110.1691, -7.648]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'SAMIGALUH',
    village: 'PAGERHARJO',
    border: [
      [110.1447, -7.6498],
      [110.1446, -7.6497],
      [110.1445, -7.6496],
      [110.1444, -7.6494],
      [110.1443, -7.6489],
      [110.1443, -7.6486],
      [110.1443, -7.6485],
      [110.1441, -7.6469],
      [110.144, -7.6461],
      [110.1439, -7.6458],
      [110.1436, -7.6452],
      [110.1433, -7.6448],
      [110.1428, -7.6447],
      [110.1423, -7.6446],
      [110.1418, -7.6448],
      [110.1414, -7.6449],
      [110.1411, -7.6455],
      [110.141, -7.646],
      [110.1409, -7.6465],
      [110.1405, -7.6471],
      [110.1403, -7.6478],
      [110.1405, -7.6486],
      [110.1404, -7.6487],
      [110.1401, -7.649],
      [110.1396, -7.6494],
      [110.1395, -7.6495],
      [110.1394, -7.6496],
      [110.1392, -7.6498],
      [110.1389, -7.6502],
      [110.1387, -7.6504],
      [110.1385, -7.6505],
      [110.1384, -7.6507],
      [110.1383, -7.6508],
      [110.1382, -7.6509],
      [110.138, -7.6509],
      [110.1378, -7.6509],
      [110.1377, -7.651],
      [110.1376, -7.6511],
      [110.1374, -7.6511],
      [110.1373, -7.6514],
      [110.1371, -7.6517],
      [110.137, -7.652],
      [110.1369, -7.6522],
      [110.1367, -7.6526],
      [110.1365, -7.6536],
      [110.1364, -7.6541],
      [110.1357, -7.6541],
      [110.135, -7.6541],
      [110.1346, -7.654],
      [110.1342, -7.654],
      [110.134, -7.654],
      [110.133, -7.6541],
      [110.1324, -7.6542],
      [110.1315, -7.6542],
      [110.1311, -7.6542],
      [110.1309, -7.6543],
      [110.1308, -7.6543],
      [110.1306, -7.6547],
      [110.1305, -7.6552],
      [110.1304, -7.656],
      [110.1303, -7.6563],
      [110.1302, -7.6571],
      [110.1302, -7.6574],
      [110.1301, -7.6577],
      [110.1295, -7.6582],
      [110.129, -7.6584],
      [110.1283, -7.6587],
      [110.1278, -7.659],
      [110.1276, -7.6594],
      [110.1276, -7.6595],
      [110.1276, -7.6598],
      [110.1276, -7.6599],
      [110.1276, -7.6602],
      [110.1271, -7.6605],
      [110.127, -7.6608],
      [110.1269, -7.661],
      [110.1267, -7.6611],
      [110.1265, -7.6612],
      [110.1261, -7.6616],
      [110.1258, -7.6618],
      [110.1255, -7.662],
      [110.1253, -7.6623],
      [110.1251, -7.6623],
      [110.1249, -7.6624],
      [110.1248, -7.6624],
      [110.1245, -7.6626],
      [110.1241, -7.6628],
      [110.124, -7.6631],
      [110.1239, -7.6632],
      [110.1238, -7.6634],
      [110.1234, -7.6634],
      [110.1231, -7.6635],
      [110.123, -7.6636],
      [110.1229, -7.6638],
      [110.1228, -7.664],
      [110.1226, -7.6644],
      [110.1226, -7.6645],
      [110.1224, -7.6647],
      [110.1223, -7.6648],
      [110.1223, -7.6652],
      [110.1223, -7.6653],
      [110.1222, -7.6659],
      [110.1222, -7.6666],
      [110.1221, -7.6668],
      [110.122, -7.6671],
      [110.1221, -7.6674],
      [110.122, -7.6677],
      [110.1216, -7.6676],
      [110.1214, -7.6675],
      [110.1212, -7.6677],
      [110.1211, -7.6679],
      [110.121, -7.6681],
      [110.1208, -7.6683],
      [110.1206, -7.6685],
      [110.1204, -7.6685],
      [110.1202, -7.6684],
      [110.12, -7.6681],
      [110.1195, -7.6678],
      [110.1192, -7.6677],
      [110.1191, -7.6677],
      [110.1189, -7.6679],
      [110.1188, -7.6679],
      [110.1186, -7.6679],
      [110.1183, -7.668],
      [110.1182, -7.6681],
      [110.118, -7.6683],
      [110.1181, -7.6686],
      [110.1181, -7.6688],
      [110.1179, -7.6692],
      [110.1174, -7.6701],
      [110.1173, -7.6709],
      [110.1183, -7.6711],
      [110.1186, -7.6713],
      [110.1192, -7.6716],
      [110.12, -7.6721],
      [110.1202, -7.6722],
      [110.1204, -7.6723],
      [110.1206, -7.6724],
      [110.1208, -7.6726],
      [110.1211, -7.6728],
      [110.1212, -7.6733],
      [110.1212, -7.6735],
      [110.1213, -7.6736],
      [110.1214, -7.6739],
      [110.1214, -7.6741],
      [110.1215, -7.6743],
      [110.1216, -7.6746],
      [110.122, -7.675],
      [110.1223, -7.6753],
      [110.1228, -7.6756],
      [110.1231, -7.6761],
      [110.1232, -7.6763],
      [110.1238, -7.6765],
      [110.1244, -7.6765],
      [110.125, -7.6765],
      [110.1253, -7.6766],
      [110.1255, -7.6768],
      [110.1257, -7.6773],
      [110.1259, -7.6775],
      [110.1262, -7.6772],
      [110.1267, -7.6772],
      [110.1268, -7.6772],
      [110.1269, -7.6773],
      [110.1269, -7.678],
      [110.1269, -7.6786],
      [110.1268, -7.6791],
      [110.1266, -7.6793],
      [110.1264, -7.6796],
      [110.1253, -7.6802],
      [110.1253, -7.6809],
      [110.1253, -7.6819],
      [110.1262, -7.6823],
      [110.1269, -7.6825],
      [110.1271, -7.6825],
      [110.1276, -7.6827],
      [110.1285, -7.6827],
      [110.1289, -7.6828],
      [110.1292, -7.6831],
      [110.1296, -7.6834],
      [110.1303, -7.6832],
      [110.1308, -7.6833],
      [110.1316, -7.6837],
      [110.1322, -7.6841],
      [110.1322, -7.6843],
      [110.1325, -7.6848],
      [110.1328, -7.6853],
      [110.1338, -7.6861],
      [110.1346, -7.6868],
      [110.1351, -7.687],
      [110.1355, -7.6873],
      [110.1357, -7.6877],
      [110.1361, -7.6881],
      [110.1364, -7.6885],
      [110.1365, -7.6888],
      [110.1363, -7.6892],
      [110.1362, -7.6897],
      [110.1363, -7.69],
      [110.1368, -7.6906],
      [110.1371, -7.6911],
      [110.1373, -7.6913],
      [110.1376, -7.6921],
      [110.1379, -7.693],
      [110.1382, -7.6939],
      [110.1385, -7.6946],
      [110.1386, -7.6948],
      [110.139, -7.6947],
      [110.1392, -7.6947],
      [110.1398, -7.6941],
      [110.1407, -7.6937],
      [110.1414, -7.6933],
      [110.1417, -7.693],
      [110.1419, -7.6928],
      [110.1424, -7.6926],
      [110.1431, -7.6919],
      [110.1436, -7.6912],
      [110.1438, -7.6906],
      [110.1439, -7.6902],
      [110.1446, -7.6902],
      [110.145, -7.6894],
      [110.1452, -7.6892],
      [110.1453, -7.6892],
      [110.146, -7.6891],
      [110.1464, -7.689],
      [110.1468, -7.6887],
      [110.1468, -7.6886],
      [110.147, -7.6879],
      [110.1474, -7.6872],
      [110.1475, -7.6871],
      [110.1479, -7.6861],
      [110.1483, -7.6855],
      [110.1486, -7.6849],
      [110.1483, -7.6845],
      [110.1485, -7.6841],
      [110.1488, -7.6836],
      [110.1493, -7.6837],
      [110.1496, -7.6833],
      [110.1497, -7.6834],
      [110.1499, -7.6834],
      [110.1501, -7.6833],
      [110.1502, -7.6832],
      [110.1502, -7.6826],
      [110.1502, -7.6821],
      [110.1503, -7.6819],
      [110.1506, -7.6813],
      [110.151, -7.681],
      [110.1511, -7.6806],
      [110.1511, -7.6804],
      [110.1511, -7.6802],
      [110.151, -7.6801],
      [110.1511, -7.68],
      [110.1514, -7.6794],
      [110.1514, -7.679],
      [110.1518, -7.6785],
      [110.1519, -7.6781],
      [110.152, -7.6776],
      [110.1521, -7.6774],
      [110.1522, -7.6773],
      [110.1523, -7.6769],
      [110.1525, -7.6766],
      [110.1528, -7.6763],
      [110.1531, -7.6758],
      [110.1536, -7.6756],
      [110.1538, -7.6754],
      [110.1537, -7.6749],
      [110.1538, -7.6744],
      [110.1539, -7.6742],
      [110.1534, -7.6743],
      [110.153, -7.6741],
      [110.1527, -7.674],
      [110.1519, -7.6732],
      [110.1515, -7.673],
      [110.1513, -7.6728],
      [110.1508, -7.6727],
      [110.1506, -7.6725],
      [110.1499, -7.6724],
      [110.149, -7.6716],
      [110.1481, -7.6703],
      [110.1476, -7.6696],
      [110.1468, -7.6678],
      [110.1464, -7.6673],
      [110.1456, -7.6658],
      [110.1456, -7.6657],
      [110.1447, -7.6635],
      [110.1444, -7.6624],
      [110.1445, -7.6619],
      [110.1445, -7.6614],
      [110.1447, -7.6584],
      [110.1444, -7.6573],
      [110.1446, -7.6562],
      [110.1447, -7.6557],
      [110.1447, -7.6553],
      [110.1446, -7.6547],
      [110.1449, -7.6545],
      [110.145, -7.6541],
      [110.1447, -7.6537],
      [110.1448, -7.6531],
      [110.1449, -7.6523],
      [110.1446, -7.6516],
      [110.1445, -7.6509],
      [110.1443, -7.6505],
      [110.1443, -7.6503],
      [110.1444, -7.6501],
      [110.1445, -7.65],
      [110.1447, -7.6498]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KALIBAWANG',
    village: 'BANJARARUM',
    border: [
      [110.233, -7.7135],
      [110.2329, -7.7126],
      [110.2329, -7.7125],
      [110.233, -7.7123],
      [110.2332, -7.7118],
      [110.2333, -7.7109],
      [110.233, -7.7104],
      [110.2326, -7.7101],
      [110.232, -7.7099],
      [110.2312, -7.7096],
      [110.2306, -7.7091],
      [110.2302, -7.7088],
      [110.2292, -7.7079],
      [110.2277, -7.7061],
      [110.2268, -7.7052],
      [110.2261, -7.7041],
      [110.2254, -7.7033],
      [110.2248, -7.7025],
      [110.2246, -7.7025],
      [110.2243, -7.7025],
      [110.2239, -7.7025],
      [110.2239, -7.7024],
      [110.2237, -7.7021],
      [110.2233, -7.7015],
      [110.2232, -7.7014],
      [110.223, -7.7013],
      [110.2228, -7.7011],
      [110.2227, -7.7012],
      [110.2222, -7.7014],
      [110.2218, -7.7014],
      [110.2216, -7.7014],
      [110.2211, -7.7016],
      [110.2203, -7.702],
      [110.2196, -7.7022],
      [110.2194, -7.7023],
      [110.219, -7.7029],
      [110.2184, -7.7032],
      [110.2177, -7.7037],
      [110.217, -7.7029],
      [110.2162, -7.7028],
      [110.2156, -7.7023],
      [110.2154, -7.7019],
      [110.2151, -7.7013],
      [110.2146, -7.701],
      [110.2145, -7.7009],
      [110.2142, -7.7007],
      [110.2141, -7.7006],
      [110.2136, -7.7005],
      [110.2123, -7.7002],
      [110.2118, -7.7],
      [110.2115, -7.6998],
      [110.211, -7.6998],
      [110.2108, -7.6998],
      [110.2107, -7.6997],
      [110.2102, -7.6994],
      [110.2102, -7.6993],
      [110.2101, -7.699],
      [110.2099, -7.6986],
      [110.2095, -7.6983],
      [110.2093, -7.6978],
      [110.2087, -7.6975],
      [110.2084, -7.6972],
      [110.2076, -7.6972],
      [110.2069, -7.6983],
      [110.2069, -7.6986],
      [110.2067, -7.6989],
      [110.2066, -7.6991],
      [110.2065, -7.6992],
      [110.2061, -7.6994],
      [110.2059, -7.6998],
      [110.2058, -7.7001],
      [110.206, -7.7003],
      [110.2061, -7.7008],
      [110.2063, -7.7014],
      [110.2065, -7.7017],
      [110.2067, -7.7017],
      [110.2071, -7.7017],
      [110.2075, -7.7019],
      [110.2078, -7.7022],
      [110.2078, -7.7027],
      [110.208, -7.703],
      [110.2084, -7.703],
      [110.2088, -7.7033],
      [110.209, -7.7034],
      [110.2095, -7.7038],
      [110.2101, -7.7042],
      [110.2104, -7.7046],
      [110.2107, -7.7048],
      [110.2111, -7.7046],
      [110.2114, -7.7047],
      [110.2118, -7.705],
      [110.2122, -7.7051],
      [110.2125, -7.7055],
      [110.2127, -7.7056],
      [110.2134, -7.7056],
      [110.2136, -7.7058],
      [110.2137, -7.7062],
      [110.2144, -7.7064],
      [110.2146, -7.7066],
      [110.2148, -7.7067],
      [110.2149, -7.7074],
      [110.2147, -7.7082],
      [110.2146, -7.7092],
      [110.2143, -7.7106],
      [110.2139, -7.7119],
      [110.2134, -7.7126],
      [110.2132, -7.7129],
      [110.2131, -7.713],
      [110.2127, -7.7135],
      [110.2125, -7.7138],
      [110.2122, -7.714],
      [110.2119, -7.7144],
      [110.2112, -7.7151],
      [110.211, -7.7153],
      [110.2099, -7.7165],
      [110.209, -7.7174],
      [110.2081, -7.7179],
      [110.207, -7.7185],
      [110.2059, -7.7189],
      [110.2046, -7.7191],
      [110.2036, -7.7188],
      [110.203, -7.7189],
      [110.2019, -7.7186],
      [110.201, -7.7183],
      [110.2001, -7.718],
      [110.1998, -7.7177],
      [110.1995, -7.7172],
      [110.199, -7.7168],
      [110.1987, -7.7167],
      [110.198, -7.7166],
      [110.1974, -7.7168],
      [110.1969, -7.7175],
      [110.1965, -7.7186],
      [110.1962, -7.7192],
      [110.1959, -7.7195],
      [110.1952, -7.7197],
      [110.1943, -7.7198],
      [110.1932, -7.7197],
      [110.1931, -7.7196],
      [110.1927, -7.7194],
      [110.1921, -7.7194],
      [110.1914, -7.7193],
      [110.1906, -7.7192],
      [110.1902, -7.7195],
      [110.1898, -7.7198],
      [110.19, -7.7202],
      [110.1901, -7.7205],
      [110.1905, -7.7208],
      [110.1907, -7.721],
      [110.1912, -7.7214],
      [110.1914, -7.7214],
      [110.1919, -7.7218],
      [110.1927, -7.7218],
      [110.1925, -7.7218],
      [110.1921, -7.7219],
      [110.1912, -7.722],
      [110.1906, -7.7224],
      [110.1902, -7.7226],
      [110.1898, -7.7232],
      [110.1895, -7.7234],
      [110.1891, -7.7233],
      [110.1885, -7.7231],
      [110.188, -7.7233],
      [110.1874, -7.7233],
      [110.187, -7.7234],
      [110.1865, -7.7237],
      [110.1861, -7.7245],
      [110.1849, -7.7277],
      [110.1847, -7.7281],
      [110.1847, -7.7281],
      [110.1847, -7.7283],
      [110.1848, -7.7285],
      [110.1852, -7.7286],
      [110.1856, -7.7286],
      [110.186, -7.7289],
      [110.1864, -7.7289],
      [110.1869, -7.7286],
      [110.1874, -7.7287],
      [110.1878, -7.7284],
      [110.1882, -7.7279],
      [110.1883, -7.7276],
      [110.1889, -7.7279],
      [110.1893, -7.7277],
      [110.1898, -7.7278],
      [110.1899, -7.7282],
      [110.1907, -7.7285],
      [110.1912, -7.7287],
      [110.1915, -7.7285],
      [110.1924, -7.729],
      [110.1929, -7.7293],
      [110.1934, -7.7296],
      [110.1938, -7.73],
      [110.1942, -7.7305],
      [110.1949, -7.7307],
      [110.1954, -7.7307],
      [110.1957, -7.7312],
      [110.196, -7.7312],
      [110.1963, -7.7316],
      [110.1968, -7.7318],
      [110.1969, -7.7322],
      [110.1975, -7.7326],
      [110.1981, -7.7329],
      [110.1984, -7.7328],
      [110.1983, -7.7335],
      [110.1977, -7.734],
      [110.1971, -7.7348],
      [110.1973, -7.7356],
      [110.1973, -7.7366],
      [110.1974, -7.7375],
      [110.1976, -7.7379],
      [110.1976, -7.738],
      [110.1976, -7.7382],
      [110.1978, -7.7382],
      [110.1982, -7.7377],
      [110.1986, -7.7371],
      [110.1989, -7.7371],
      [110.1992, -7.7371],
      [110.1995, -7.7365],
      [110.2, -7.7368],
      [110.2003, -7.7366],
      [110.2004, -7.7363],
      [110.2007, -7.7363],
      [110.2009, -7.7361],
      [110.2013, -7.7362],
      [110.202, -7.7359],
      [110.2021, -7.7355],
      [110.2023, -7.7353],
      [110.2025, -7.7351],
      [110.2029, -7.7351],
      [110.2034, -7.735],
      [110.204, -7.7347],
      [110.2044, -7.7345],
      [110.2048, -7.7341],
      [110.2054, -7.7339],
      [110.2059, -7.7338],
      [110.2065, -7.7336],
      [110.2067, -7.7337],
      [110.2069, -7.734],
      [110.2073, -7.734],
      [110.2079, -7.734],
      [110.208, -7.7343],
      [110.2083, -7.7347],
      [110.2087, -7.7346],
      [110.2088, -7.7349],
      [110.209, -7.7351],
      [110.2096, -7.7349],
      [110.2099, -7.7349],
      [110.2102, -7.7351],
      [110.2106, -7.7349],
      [110.2109, -7.7347],
      [110.2112, -7.7347],
      [110.2114, -7.735],
      [110.2117, -7.7353],
      [110.2119, -7.7354],
      [110.2124, -7.7355],
      [110.2125, -7.7356],
      [110.2127, -7.7359],
      [110.213, -7.7362],
      [110.2132, -7.7366],
      [110.2135, -7.737],
      [110.2139, -7.7373],
      [110.2143, -7.7374],
      [110.2146, -7.7373],
      [110.2147, -7.7369],
      [110.2149, -7.7366],
      [110.2153, -7.7365],
      [110.2156, -7.7367],
      [110.2158, -7.7368],
      [110.2161, -7.7365],
      [110.216, -7.7361],
      [110.2159, -7.7358],
      [110.2157, -7.7355],
      [110.2156, -7.7352],
      [110.2155, -7.7348],
      [110.2155, -7.7345],
      [110.2157, -7.734],
      [110.216, -7.7335],
      [110.2162, -7.7331],
      [110.2167, -7.733],
      [110.2171, -7.733],
      [110.2176, -7.7337],
      [110.218, -7.7343],
      [110.2186, -7.7346],
      [110.2192, -7.7349],
      [110.2197, -7.7352],
      [110.2198, -7.7352],
      [110.2204, -7.7355],
      [110.2208, -7.7352],
      [110.2215, -7.7338],
      [110.2218, -7.7324],
      [110.2222, -7.7298],
      [110.2232, -7.7269],
      [110.2235, -7.7264],
      [110.2241, -7.7251],
      [110.2246, -7.7241],
      [110.2249, -7.7235],
      [110.2254, -7.7232],
      [110.2261, -7.7231],
      [110.2269, -7.7231],
      [110.2277, -7.7233],
      [110.2293, -7.7235],
      [110.2307, -7.7233],
      [110.2314, -7.7229],
      [110.2318, -7.7226],
      [110.232, -7.7222],
      [110.2319, -7.7217],
      [110.2319, -7.7211],
      [110.2316, -7.7196],
      [110.2315, -7.7187],
      [110.2315, -7.7176],
      [110.2316, -7.7166],
      [110.2319, -7.715],
      [110.2321, -7.7143],
      [110.2325, -7.7138],
      [110.233, -7.7135]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KALIBAWANG',
    village: 'BANJARASRI',
    border: [
      [110.2269, -7.6719],
      [110.2267, -7.6719],
      [110.2264, -7.6719],
      [110.2232, -7.6716],
      [110.2228, -7.6716],
      [110.22, -7.6713],
      [110.2192, -7.6711],
      [110.2174, -7.6707],
      [110.2149, -7.6693],
      [110.2124, -7.6674],
      [110.2104, -7.6652],
      [110.2102, -7.6651],
      [110.21, -7.665],
      [110.2102, -7.6656],
      [110.2105, -7.666],
      [110.2106, -7.6664],
      [110.2107, -7.6668],
      [110.2108, -7.6671],
      [110.2108, -7.6677],
      [110.2107, -7.6684],
      [110.2107, -7.669],
      [110.2107, -7.6691],
      [110.2107, -7.6695],
      [110.2108, -7.6699],
      [110.2113, -7.6701],
      [110.2115, -7.6706],
      [110.2117, -7.6711],
      [110.2119, -7.6716],
      [110.2122, -7.6719],
      [110.213, -7.6725],
      [110.2131, -7.673],
      [110.2133, -7.6736],
      [110.2138, -7.6747],
      [110.2137, -7.6752],
      [110.2136, -7.6757],
      [110.2138, -7.6764],
      [110.2141, -7.6768],
      [110.2146, -7.6772],
      [110.215, -7.6775],
      [110.2153, -7.6778],
      [110.2154, -7.6781],
      [110.2155, -7.6784],
      [110.2153, -7.6788],
      [110.2152, -7.6793],
      [110.215, -7.6795],
      [110.2149, -7.6798],
      [110.2149, -7.6805],
      [110.2151, -7.6815],
      [110.2148, -7.682],
      [110.2145, -7.6824],
      [110.2141, -7.6824],
      [110.2139, -7.6825],
      [110.2137, -7.6825],
      [110.2135, -7.6829],
      [110.2134, -7.6831],
      [110.2133, -7.6836],
      [110.2131, -7.6839],
      [110.2125, -7.6841],
      [110.212, -7.6844],
      [110.2117, -7.6845],
      [110.2113, -7.6844],
      [110.2109, -7.6844],
      [110.2106, -7.6848],
      [110.2102, -7.685],
      [110.2098, -7.685],
      [110.2094, -7.6849],
      [110.2091, -7.685],
      [110.2088, -7.6854],
      [110.2086, -7.6857],
      [110.2082, -7.6862],
      [110.208, -7.6865],
      [110.2081, -7.6867],
      [110.2081, -7.6871],
      [110.2082, -7.6877],
      [110.2083, -7.6883],
      [110.2085, -7.6886],
      [110.2089, -7.6891],
      [110.2093, -7.6893],
      [110.2094, -7.6896],
      [110.2095, -7.6902],
      [110.2093, -7.6905],
      [110.2095, -7.6908],
      [110.2094, -7.6913],
      [110.2093, -7.692],
      [110.209, -7.6925],
      [110.2088, -7.6928],
      [110.2086, -7.6933],
      [110.2084, -7.6941],
      [110.2083, -7.6945],
      [110.2085, -7.6949],
      [110.2087, -7.6952],
      [110.2087, -7.6956],
      [110.2084, -7.696],
      [110.2076, -7.6972],
      [110.2084, -7.6972],
      [110.2087, -7.6975],
      [110.2093, -7.6978],
      [110.2095, -7.6983],
      [110.2099, -7.6986],
      [110.2101, -7.699],
      [110.2102, -7.6993],
      [110.2102, -7.6994],
      [110.2107, -7.6997],
      [110.2108, -7.6998],
      [110.211, -7.6998],
      [110.2115, -7.6998],
      [110.2118, -7.7],
      [110.2123, -7.7002],
      [110.2136, -7.7005],
      [110.2141, -7.7006],
      [110.2142, -7.7007],
      [110.2145, -7.7009],
      [110.2146, -7.701],
      [110.2151, -7.7013],
      [110.2154, -7.7019],
      [110.2156, -7.7023],
      [110.2162, -7.7028],
      [110.217, -7.7029],
      [110.2177, -7.7037],
      [110.2184, -7.7032],
      [110.219, -7.7029],
      [110.2194, -7.7023],
      [110.2196, -7.7022],
      [110.2203, -7.702],
      [110.2211, -7.7016],
      [110.2216, -7.7014],
      [110.2218, -7.7014],
      [110.2222, -7.7014],
      [110.2227, -7.7012],
      [110.2228, -7.7011],
      [110.223, -7.7013],
      [110.2232, -7.7014],
      [110.2233, -7.7015],
      [110.2237, -7.7021],
      [110.2239, -7.7024],
      [110.2239, -7.7025],
      [110.2243, -7.7025],
      [110.2246, -7.7025],
      [110.2248, -7.7025],
      [110.2254, -7.7033],
      [110.2261, -7.7041],
      [110.2268, -7.7052],
      [110.2277, -7.7061],
      [110.2292, -7.7079],
      [110.2302, -7.7088],
      [110.2306, -7.7091],
      [110.2312, -7.7096],
      [110.232, -7.7099],
      [110.2326, -7.7101],
      [110.233, -7.7104],
      [110.2333, -7.7109],
      [110.2332, -7.7118],
      [110.233, -7.7123],
      [110.2329, -7.7125],
      [110.2329, -7.7126],
      [110.233, -7.7135],
      [110.233, -7.7134],
      [110.2335, -7.7133],
      [110.2339, -7.7133],
      [110.2342, -7.7134],
      [110.2353, -7.7136],
      [110.236, -7.7136],
      [110.2373, -7.7134],
      [110.2387, -7.7129],
      [110.2403, -7.712],
      [110.2416, -7.7113],
      [110.2438, -7.7101],
      [110.2444, -7.71],
      [110.2454, -7.7099],
      [110.2463, -7.71],
      [110.2472, -7.7101],
      [110.2478, -7.7099],
      [110.2482, -7.7096],
      [110.2486, -7.7094],
      [110.2489, -7.709],
      [110.2494, -7.7087],
      [110.2496, -7.7087],
      [110.2498, -7.7086],
      [110.2493, -7.7083],
      [110.2491, -7.7081],
      [110.248, -7.7075],
      [110.2478, -7.7074],
      [110.2464, -7.707],
      [110.2458, -7.707],
      [110.2449, -7.707],
      [110.2443, -7.7068],
      [110.2436, -7.7067],
      [110.2432, -7.7062],
      [110.2427, -7.7055],
      [110.2427, -7.705],
      [110.2428, -7.7045],
      [110.2426, -7.7038],
      [110.2422, -7.7033],
      [110.2414, -7.7024],
      [110.2411, -7.7027],
      [110.2408, -7.703],
      [110.2407, -7.703],
      [110.2405, -7.703],
      [110.2404, -7.7031],
      [110.2401, -7.7031],
      [110.24, -7.703],
      [110.2399, -7.7028],
      [110.2398, -7.7023],
      [110.2398, -7.7018],
      [110.2403, -7.7011],
      [110.2407, -7.7002],
      [110.241, -7.6994],
      [110.241, -7.6989],
      [110.2408, -7.6985],
      [110.2402, -7.6976],
      [110.2402, -7.6971],
      [110.2402, -7.6966],
      [110.2405, -7.6959],
      [110.2411, -7.6953],
      [110.2415, -7.6944],
      [110.2417, -7.6935],
      [110.2417, -7.6933],
      [110.2414, -7.6924],
      [110.2408, -7.6916],
      [110.2391, -7.6901],
      [110.2383, -7.6898],
      [110.2378, -7.6894],
      [110.2365, -7.6891],
      [110.2361, -7.6891],
      [110.2357, -7.689],
      [110.2354, -7.6881],
      [110.2348, -7.6872],
      [110.2342, -7.6858],
      [110.2341, -7.6853],
      [110.2339, -7.685],
      [110.2336, -7.6849],
      [110.2333, -7.6847],
      [110.233, -7.6844],
      [110.2325, -7.684],
      [110.2321, -7.6839],
      [110.2321, -7.6838],
      [110.2322, -7.6837],
      [110.2324, -7.6834],
      [110.2325, -7.6833],
      [110.2324, -7.6831],
      [110.2323, -7.6829],
      [110.2321, -7.6827],
      [110.2319, -7.6823],
      [110.2317, -7.6822],
      [110.2315, -7.6821],
      [110.2314, -7.682],
      [110.2313, -7.6817],
      [110.2313, -7.6813],
      [110.231, -7.6808],
      [110.2309, -7.6804],
      [110.231, -7.6799],
      [110.231, -7.6795],
      [110.2305, -7.6791],
      [110.2301, -7.6787],
      [110.2299, -7.6784],
      [110.2295, -7.6779],
      [110.2293, -7.6779],
      [110.229, -7.6779],
      [110.2288, -7.6777],
      [110.2286, -7.6773],
      [110.2286, -7.677],
      [110.2286, -7.6767],
      [110.2289, -7.6765],
      [110.2289, -7.6763],
      [110.2288, -7.6759],
      [110.2287, -7.6752],
      [110.2284, -7.6748],
      [110.2281, -7.6747],
      [110.2281, -7.6746],
      [110.2281, -7.6742],
      [110.2281, -7.6738],
      [110.2279, -7.6733],
      [110.2278, -7.673],
      [110.2275, -7.6724],
      [110.2269, -7.6719]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KALIBAWANG',
    village: 'BANJARHARJO',
    border: [
      [110.2629, -7.6798],
      [110.2627, -7.6798],
      [110.2617, -7.6795],
      [110.2611, -7.6792],
      [110.2611, -7.6789],
      [110.2619, -7.6769],
      [110.2624, -7.6762],
      [110.2614, -7.6758],
      [110.2611, -7.6757],
      [110.2609, -7.6756],
      [110.2606, -7.6755],
      [110.26, -7.6752],
      [110.2599, -7.6748],
      [110.2597, -7.6745],
      [110.2594, -7.6744],
      [110.2588, -7.6744],
      [110.258, -7.6742],
      [110.2568, -7.674],
      [110.2553, -7.674],
      [110.2549, -7.6737],
      [110.2542, -7.6733],
      [110.2537, -7.6727],
      [110.2529, -7.6727],
      [110.2526, -7.6726],
      [110.2526, -7.6722],
      [110.2527, -7.6716],
      [110.2525, -7.6714],
      [110.2515, -7.6711],
      [110.2509, -7.6707],
      [110.2499, -7.6705],
      [110.2494, -7.6701],
      [110.2489, -7.6697],
      [110.2488, -7.6692],
      [110.2484, -7.6687],
      [110.2484, -7.6679],
      [110.2479, -7.6679],
      [110.2472, -7.6679],
      [110.246, -7.6678],
      [110.2449, -7.6676],
      [110.2446, -7.6677],
      [110.2446, -7.6679],
      [110.2443, -7.6681],
      [110.2437, -7.6681],
      [110.2425, -7.6681],
      [110.2414, -7.6678],
      [110.2408, -7.6677],
      [110.24, -7.6681],
      [110.2393, -7.6685],
      [110.2384, -7.669],
      [110.2382, -7.669],
      [110.2377, -7.6691],
      [110.2364, -7.6689],
      [110.2355, -7.669],
      [110.2353, -7.6692],
      [110.2349, -7.6696],
      [110.2347, -7.6699],
      [110.2347, -7.6701],
      [110.2346, -7.6704],
      [110.2343, -7.6704],
      [110.2339, -7.6704],
      [110.2334, -7.6707],
      [110.2332, -7.6709],
      [110.2329, -7.6712],
      [110.2326, -7.6718],
      [110.2323, -7.672],
      [110.2321, -7.6721],
      [110.232, -7.6719],
      [110.2319, -7.6717],
      [110.2311, -7.6714],
      [110.23, -7.6713],
      [110.2284, -7.6711],
      [110.2274, -7.6707],
      [110.227, -7.6707],
      [110.2268, -7.6708],
      [110.2267, -7.6711],
      [110.2268, -7.6713],
      [110.2269, -7.6716],
      [110.2269, -7.6718],
      [110.2269, -7.6719],
      [110.2275, -7.6724],
      [110.2278, -7.673],
      [110.2279, -7.6733],
      [110.2281, -7.6738],
      [110.2281, -7.6742],
      [110.2281, -7.6746],
      [110.2281, -7.6747],
      [110.2284, -7.6748],
      [110.2287, -7.6752],
      [110.2288, -7.6759],
      [110.2289, -7.6763],
      [110.2289, -7.6765],
      [110.2286, -7.6767],
      [110.2286, -7.677],
      [110.2286, -7.6773],
      [110.2288, -7.6777],
      [110.229, -7.6779],
      [110.2293, -7.6779],
      [110.2295, -7.6779],
      [110.2299, -7.6784],
      [110.2301, -7.6787],
      [110.2305, -7.6791],
      [110.231, -7.6795],
      [110.231, -7.6799],
      [110.2309, -7.6804],
      [110.231, -7.6808],
      [110.2313, -7.6813],
      [110.2313, -7.6817],
      [110.2314, -7.682],
      [110.2315, -7.6821],
      [110.2317, -7.6822],
      [110.2319, -7.6823],
      [110.2321, -7.6827],
      [110.2323, -7.6829],
      [110.2324, -7.6831],
      [110.2325, -7.6833],
      [110.2324, -7.6834],
      [110.2322, -7.6837],
      [110.2321, -7.6838],
      [110.2321, -7.6839],
      [110.2325, -7.684],
      [110.233, -7.6844],
      [110.2333, -7.6847],
      [110.2336, -7.6849],
      [110.2339, -7.685],
      [110.2341, -7.6853],
      [110.2342, -7.6858],
      [110.2348, -7.6872],
      [110.2354, -7.6881],
      [110.2357, -7.689],
      [110.2361, -7.6891],
      [110.2365, -7.6891],
      [110.2378, -7.6894],
      [110.2383, -7.6898],
      [110.2391, -7.6901],
      [110.2408, -7.6916],
      [110.2414, -7.6924],
      [110.2417, -7.6933],
      [110.2417, -7.6935],
      [110.2415, -7.6944],
      [110.2411, -7.6953],
      [110.2405, -7.6959],
      [110.2402, -7.6966],
      [110.2402, -7.6971],
      [110.2402, -7.6976],
      [110.2408, -7.6985],
      [110.241, -7.6989],
      [110.241, -7.6994],
      [110.2407, -7.7002],
      [110.2403, -7.7011],
      [110.2398, -7.7018],
      [110.2398, -7.7023],
      [110.2399, -7.7028],
      [110.24, -7.703],
      [110.2401, -7.7031],
      [110.2404, -7.7031],
      [110.2405, -7.703],
      [110.2407, -7.703],
      [110.2408, -7.703],
      [110.2411, -7.7027],
      [110.2414, -7.7024],
      [110.2422, -7.7033],
      [110.2426, -7.7038],
      [110.2428, -7.7045],
      [110.2427, -7.705],
      [110.2427, -7.7055],
      [110.2432, -7.7062],
      [110.2436, -7.7067],
      [110.2443, -7.7068],
      [110.2449, -7.707],
      [110.2458, -7.707],
      [110.2464, -7.707],
      [110.2478, -7.7074],
      [110.248, -7.7075],
      [110.2491, -7.7081],
      [110.2493, -7.7083],
      [110.2498, -7.7086],
      [110.2502, -7.7084],
      [110.2508, -7.7084],
      [110.2516, -7.7086],
      [110.253, -7.7086],
      [110.2538, -7.7082],
      [110.2545, -7.7076],
      [110.2555, -7.7061],
      [110.2562, -7.7052],
      [110.2571, -7.7046],
      [110.2584, -7.7043],
      [110.2594, -7.7042],
      [110.2597, -7.7043],
      [110.2606, -7.7046],
      [110.2635, -7.7057],
      [110.265, -7.7063],
      [110.2673, -7.7072],
      [110.2678, -7.7073],
      [110.268, -7.7073],
      [110.2688, -7.7071],
      [110.2691, -7.7068],
      [110.2695, -7.7064],
      [110.27, -7.706],
      [110.2703, -7.7057],
      [110.2703, -7.7056],
      [110.2703, -7.7053],
      [110.2705, -7.7049],
      [110.2707, -7.7047],
      [110.2708, -7.7045],
      [110.2708, -7.7039],
      [110.2707, -7.7032],
      [110.2702, -7.7021],
      [110.2698, -7.7016],
      [110.2694, -7.7011],
      [110.269, -7.7003],
      [110.2686, -7.6996],
      [110.2684, -7.6993],
      [110.2683, -7.6989],
      [110.2683, -7.6987],
      [110.2683, -7.6985],
      [110.2685, -7.698],
      [110.2686, -7.6978],
      [110.2687, -7.6977],
      [110.2686, -7.6975],
      [110.2685, -7.6973],
      [110.2684, -7.6968],
      [110.2684, -7.6967],
      [110.2681, -7.6959],
      [110.2674, -7.6947],
      [110.2669, -7.6943],
      [110.2662, -7.6936],
      [110.2658, -7.6929],
      [110.2658, -7.6926],
      [110.2659, -7.6918],
      [110.2658, -7.6913],
      [110.2658, -7.691],
      [110.2656, -7.6906],
      [110.265, -7.6901],
      [110.2644, -7.6896],
      [110.264, -7.6893],
      [110.264, -7.6891],
      [110.2638, -7.6887],
      [110.2636, -7.6883],
      [110.2634, -7.6881],
      [110.2632, -7.6879],
      [110.2629, -7.6879],
      [110.2624, -7.6879],
      [110.262, -7.6879],
      [110.2617, -7.6877],
      [110.2614, -7.6875],
      [110.2611, -7.6872],
      [110.2609, -7.6869],
      [110.2609, -7.6868],
      [110.261, -7.6862],
      [110.2613, -7.6854],
      [110.2616, -7.6845],
      [110.2619, -7.684],
      [110.2624, -7.6835],
      [110.2626, -7.6833],
      [110.2627, -7.6831],
      [110.263, -7.6829],
      [110.2632, -7.6828],
      [110.2635, -7.6828],
      [110.2639, -7.6826],
      [110.2642, -7.6824],
      [110.2644, -7.682],
      [110.2644, -7.6816],
      [110.2644, -7.6815],
      [110.2642, -7.6811],
      [110.2641, -7.681],
      [110.2638, -7.6809],
      [110.2634, -7.6808],
      [110.2633, -7.6807],
      [110.2631, -7.6806],
      [110.2629, -7.6803],
      [110.2629, -7.6799],
      [110.2629, -7.6798]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KULON PROGO',
    sub_district: 'KALIBAWANG',
    village: 'BANJAROYO',
    border: [
      [110.2604, -7.644],
      [110.2587, -7.6438],
      [110.2585, -7.6438],
      [110.2579, -7.6436],
      [110.257, -7.6433],
      [110.2565, -7.643],
      [110.2553, -7.6424],
      [110.2546, -7.6421],
      [110.2541, -7.6418],
      [110.2538, -7.6416],
      [110.253, -7.6421],
      [110.2525, -7.6424],
      [110.2522, -7.6423],
      [110.252, -7.6424],
      [110.2519, -7.6424],
      [110.2518, -7.6425],
      [110.2516, -7.6426],
      [110.2514, -7.6428],
      [110.2513, -7.6434],
      [110.2513, -7.6438],
      [110.2513, -7.6439],
      [110.2512, -7.6441],
      [110.251, -7.6442],
      [110.2506, -7.6445],
      [110.2502, -7.6449],
      [110.2502, -7.6452],
      [110.2502, -7.6454],
      [110.2503, -7.6458],
      [110.2503, -7.6461],
      [110.2501, -7.6462],
      [110.25, -7.6462],
      [110.2499, -7.6462],
      [110.2497, -7.6461],
      [110.2497, -7.646],
      [110.2496, -7.6459],
      [110.2493, -7.6459],
      [110.2492, -7.6461],
      [110.249, -7.6463],
      [110.2489, -7.6463],
      [110.2488, -7.6464],
      [110.2486, -7.6466],
      [110.2485, -7.6468],
      [110.2483, -7.6468],
      [110.2482, -7.6467],
      [110.2481, -7.6468],
      [110.2479, -7.6469],
      [110.2478, -7.647],
      [110.2477, -7.6469],
      [110.2476, -7.6468],
      [110.2475, -7.6465],
      [110.2475, -7.6465],
      [110.2474, -7.6464],
      [110.2473, -7.6465],
      [110.2472, -7.6466],
      [110.2471, -7.6469],
      [110.2471, -7.6474],
      [110.247, -7.6476],
      [110.247, -7.6478],
      [110.2469, -7.6479],
      [110.2467, -7.6479],
      [110.2467, -7.6478],
      [110.2465, -7.6477],
      [110.2464, -7.6478],
      [110.2464, -7.6479],
      [110.2464, -7.648],
      [110.2466, -7.6482],
      [110.2466, -7.6483],
      [110.2463, -7.6487],
      [110.2461, -7.6491],
      [110.2457, -7.6492],
      [110.2456, -7.6495],
      [110.2455, -7.6496],
      [110.2452, -7.6501],
      [110.2451, -7.6505],
      [110.245, -7.6506],
      [110.2451, -7.651],
      [110.2451, -7.6511],
      [110.2447, -7.6513],
      [110.2446, -7.6513],
      [110.2445, -7.6514],
      [110.2444, -7.6515],
      [110.2442, -7.6517],
      [110.244, -7.652],
      [110.2439, -7.6521],
      [110.2434, -7.6524],
      [110.2432, -7.6526],
      [110.2431, -7.6527],
      [110.243, -7.6529],
      [110.243, -7.653],
      [110.243, -7.6531],
      [110.2428, -7.6532],
      [110.2427, -7.6531],
      [110.2426, -7.6531],
      [110.2425, -7.653],
      [110.2425, -7.6528],
      [110.2424, -7.6527],
      [110.2423, -7.6528],
      [110.2423, -7.653],
      [110.2423, -7.6531],
      [110.2422, -7.6532],
      [110.242, -7.6533],
      [110.2417, -7.6535],
      [110.2415, -7.6536],
      [110.2414, -7.6536],
      [110.2413, -7.6535],
      [110.2412, -7.6534],
      [110.2411, -7.6534],
      [110.241, -7.6534],
      [110.2408, -7.6535],
      [110.2407, -7.6537],
      [110.2406, -7.6538],
      [110.2407, -7.654],
      [110.2408, -7.654],
      [110.2407, -7.6541],
      [110.2406, -7.6542],
      [110.2405, -7.6543],
      [110.2404, -7.6543],
      [110.2398, -7.6539],
      [110.2396, -7.6538],
      [110.2394, -7.6537],
      [110.2393, -7.6537],
      [110.2389, -7.6538],
      [110.2389, -7.6537],
      [110.2388, -7.6536],
      [110.2388, -7.6535],
      [110.2386, -7.6535],
      [110.2384, -7.6533],
      [110.2383, -7.6533],
      [110.2379, -7.6532],
      [110.2378, -7.6532],
      [110.2373, -7.6527],
      [110.2372, -7.6526],
      [110.2371, -7.6525],
      [110.237, -7.6525],
      [110.2365, -7.6523],
      [110.2363, -7.6523],
      [110.2361, -7.6522],
      [110.2358, -7.6521],
      [110.2357, -7.6521],
      [110.2355, -7.652],
      [110.2351, -7.652],
      [110.235, -7.6519],
      [110.2349, -7.6517],
      [110.2348, -7.6516],
      [110.2346, -7.6515],
      [110.2345, -7.6515],
      [110.2343, -7.6514],
      [110.2341, -7.6512],
      [110.2339, -7.651],
      [110.2336, -7.6509],
      [110.2334, -7.6508],
      [110.2333, -7.6507],
      [110.2331, -7.6506],
      [110.2328, -7.6506],
      [110.2327, -7.6506],
      [110.2325, -7.6507],
      [110.2323, -7.6506],
      [110.2321, -7.6506],
      [110.2316, -7.6506],
      [110.231, -7.6505],
      [110.2307, -7.6505],
      [110.2303, -7.6505],
      [110.2297, -7.6504],
      [110.2285, -7.6502],
      [110.2282, -7.6502],
      [110.2281, -7.6502],
      [110.2278, -7.6501],
      [110.2251, -7.6495],
      [110.2219, -7.6486],
      [110.2186, -7.6486],
      [110.2184, -7.6485],
      [110.2169, -7.6484],
      [110.2158, -7.6483],
      [110.2151, -7.6482],
      [110.2146, -7.6482],
      [110.2139, -7.6481],
      [110.2133, -7.648],
      [110.2129, -7.6481],
      [110.2129, -7.6481],
      [110.2123, -7.6483],
      [110.2121, -7.6484],
      [110.2114, -7.6488],
      [110.2096, -7.65],
      [110.2091, -7.6502],
      [110.2079, -7.6509],
      [110.2063, -7.6515],
      [110.2053, -7.6519],
      [110.2052, -7.652],
      [110.2052, -7.6525],
      [110.2054, -7.6529],
      [110.2056, -7.6531],
      [110.2056, -7.6532],
      [110.2056, -7.6533],
      [110.2055, -7.6536],
      [110.2053, -7.6538],
      [110.2053, -7.6541],
      [110.2055, -7.6543],
      [110.2055, -7.6548],
      [110.2057, -7.655],
      [110.2061, -7.6551],
      [110.2061, -7.6555],
      [110.2063, -7.6556],
      [110.2069, -7.6558],
      [110.2072, -7.656],
      [110.2071, -7.6563],
      [110.207, -7.6564],
      [110.2072, -7.6569],
      [110.2071, -7.6573],
      [110.2072, -7.6576],
      [110.2072, -7.6578],
      [110.2073, -7.6582],
      [110.2073, -7.6589],
      [110.2074, -7.6595],
      [110.2075, -7.66],
      [110.2078, -7.6607],
      [110.2081, -7.6611],
      [110.2085, -7.6618],
      [110.2087, -7.6625],
      [110.2091, -7.6631],
      [110.2094, -7.6639],
      [110.2097, -7.6646],
      [110.21, -7.665],
      [110.2102, -7.6651],
      [110.2104, -7.6652],
      [110.2124, -7.6674],
      [110.2149, -7.6693],
      [110.2174, -7.6707],
      [110.2192, -7.6711],
      [110.22, -7.6713],
      [110.2228, -7.6716],
      [110.2232, -7.6716],
      [110.2264, -7.6719],
      [110.2267, -7.6719],
      [110.2269, -7.6719],
      [110.2269, -7.6718],
      [110.2269, -7.6716],
      [110.2268, -7.6713],
      [110.2267, -7.6711],
      [110.2268, -7.6708],
      [110.227, -7.6707],
      [110.2274, -7.6707],
      [110.2284, -7.6711],
      [110.23, -7.6713],
      [110.2311, -7.6714],
      [110.2319, -7.6717],
      [110.232, -7.6719],
      [110.2321, -7.6721],
      [110.2323, -7.672],
      [110.2326, -7.6718],
      [110.2329, -7.6712],
      [110.2332, -7.6709],
      [110.2334, -7.6707],
      [110.2339, -7.6704],
      [110.2343, -7.6704],
      [110.2346, -7.6704],
      [110.2347, -7.6701],
      [110.2347, -7.6699],
      [110.2349, -7.6696],
      [110.2353, -7.6692],
      [110.2355, -7.669],
      [110.2364, -7.6689],
      [110.2377, -7.6691],
      [110.2382, -7.669],
      [110.2384, -7.669],
      [110.2393, -7.6685],
      [110.24, -7.6681],
      [110.2408, -7.6677],
      [110.2414, -7.6678],
      [110.2425, -7.6681],
      [110.2437, -7.6681],
      [110.2443, -7.6681],
      [110.2446, -7.6679],
      [110.2446, -7.6677],
      [110.2449, -7.6676],
      [110.246, -7.6678],
      [110.2472, -7.6679],
      [110.2479, -7.6679],
      [110.2484, -7.6679],
      [110.2484, -7.6687],
      [110.2488, -7.6692],
      [110.2489, -7.6697],
      [110.2494, -7.6701],
      [110.2499, -7.6705],
      [110.2509, -7.6707],
      [110.2515, -7.6711],
      [110.2525, -7.6714],
      [110.2527, -7.6716],
      [110.2526, -7.6722],
      [110.2526, -7.6726],
      [110.2529, -7.6727],
      [110.2537, -7.6727],
      [110.2542, -7.6733],
      [110.2549, -7.6737],
      [110.2553, -7.674],
      [110.2568, -7.674],
      [110.258, -7.6742],
      [110.2588, -7.6744],
      [110.2594, -7.6744],
      [110.2597, -7.6745],
      [110.2599, -7.6748],
      [110.26, -7.6752],
      [110.2606, -7.6755],
      [110.2609, -7.6756],
      [110.2611, -7.6757],
      [110.2614, -7.6758],
      [110.2624, -7.6762],
      [110.2619, -7.6769],
      [110.2611, -7.6789],
      [110.2611, -7.6792],
      [110.2617, -7.6795],
      [110.2627, -7.6798],
      [110.2629, -7.6798],
      [110.263, -7.6791],
      [110.263, -7.6789],
      [110.2631, -7.6784],
      [110.2633, -7.6779],
      [110.2634, -7.6776],
      [110.2637, -7.6771],
      [110.2641, -7.6767],
      [110.2642, -7.6766],
      [110.2645, -7.6764],
      [110.2647, -7.6762],
      [110.2648, -7.6761],
      [110.2651, -7.6757],
      [110.2655, -7.6751],
      [110.2658, -7.6742],
      [110.2659, -7.6739],
      [110.2659, -7.6737],
      [110.2659, -7.6735],
      [110.266, -7.6732],
      [110.2662, -7.6726],
      [110.266, -7.6718],
      [110.2659, -7.6714],
      [110.2657, -7.671],
      [110.2653, -7.67],
      [110.2653, -7.6698],
      [110.2653, -7.6694],
      [110.2653, -7.669],
      [110.2653, -7.6683],
      [110.2655, -7.6675],
      [110.2657, -7.6669],
      [110.2662, -7.6664],
      [110.2663, -7.6663],
      [110.2666, -7.6658],
      [110.267, -7.6654],
      [110.2671, -7.6653],
      [110.2671, -7.6649],
      [110.2672, -7.6646],
      [110.2672, -7.6644],
      [110.2672, -7.6637],
      [110.2671, -7.6631],
      [110.267, -7.6629],
      [110.267, -7.6628],
      [110.2667, -7.6625],
      [110.2666, -7.6624],
      [110.2665, -7.6623],
      [110.2663, -7.6621],
      [110.266, -7.6618],
      [110.2658, -7.6617],
      [110.2652, -7.6614],
      [110.2651, -7.6613],
      [110.2647, -7.6613],
      [110.264, -7.6612],
      [110.2636, -7.6612],
      [110.2635, -7.6612],
      [110.2631, -7.6611],
      [110.263, -7.6607],
      [110.263, -7.6603],
      [110.263, -7.6593],
      [110.2631, -7.659],
      [110.2633, -7.6586],
      [110.2638, -7.6571],
      [110.2643, -7.6563],
      [110.2648, -7.6559],
      [110.2647, -7.6555],
      [110.2646, -7.6553],
      [110.2644, -7.6548],
      [110.2643, -7.6545],
      [110.264, -7.6536],
      [110.2637, -7.6531],
      [110.2638, -7.6528],
      [110.2638, -7.6526],
      [110.2642, -7.6521],
      [110.2644, -7.6518],
      [110.2648, -7.6512],
      [110.265, -7.651],
      [110.2651, -7.6509],
      [110.2655, -7.6507],
      [110.2659, -7.6505],
      [110.2661, -7.6499],
      [110.2661, -7.6497],
      [110.2661, -7.6496],
      [110.266, -7.6494],
      [110.2659, -7.6492],
      [110.2657, -7.6488],
      [110.2656, -7.6487],
      [110.2654, -7.6484],
      [110.2649, -7.6479],
      [110.2647, -7.6476],
      [110.2645, -7.6473],
      [110.2644, -7.6471],
      [110.2641, -7.6466],
      [110.2638, -7.6462],
      [110.2634, -7.6458],
      [110.2627, -7.6455],
      [110.2625, -7.6454],
      [110.2623, -7.6454],
      [110.2622, -7.6453],
      [110.2614, -7.6446],
      [110.2607, -7.644],
      [110.2604, -7.644]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SRANDAKAN',
    village: 'PONCOSARI',
    border: [
      [110.2548, -7.9533],
      [110.2547, -7.9533],
      [110.2542, -7.9531],
      [110.2518, -7.9525],
      [110.248, -7.9518],
      [110.2471, -7.9516],
      [110.2463, -7.9514],
      [110.2461, -7.9514],
      [110.2458, -7.9514],
      [110.2447, -7.9513],
      [110.2432, -7.9513],
      [110.2426, -7.951],
      [110.2418, -7.951],
      [110.2412, -7.9508],
      [110.2401, -7.9507],
      [110.2392, -7.9505],
      [110.2374, -7.9499],
      [110.2371, -7.9497],
      [110.2367, -7.9503],
      [110.236, -7.9511],
      [110.2355, -7.9519],
      [110.2353, -7.9523],
      [110.2348, -7.9529],
      [110.2336, -7.9545],
      [110.2335, -7.9547],
      [110.2329, -7.9555],
      [110.2323, -7.9565],
      [110.2315, -7.9574],
      [110.2302, -7.9587],
      [110.2286, -7.9606],
      [110.2276, -7.9617],
      [110.2271, -7.9625],
      [110.2262, -7.9635],
      [110.2256, -7.9646],
      [110.2254, -7.9651],
      [110.2249, -7.9659],
      [110.2245, -7.9665],
      [110.224, -7.9669],
      [110.2233, -7.9675],
      [110.2228, -7.9682],
      [110.2215, -7.9699],
      [110.2202, -7.9719],
      [110.2197, -7.9725],
      [110.2196, -7.9727],
      [110.2191, -7.9733],
      [110.218, -7.9746],
      [110.2155, -7.9767],
      [110.2144, -7.9776],
      [110.2118, -7.9793],
      [110.2087, -7.9808],
      [110.2057, -7.982],
      [110.205, -7.9825],
      [110.2042, -7.9829],
      [110.2039, -7.9832],
      [110.2041, -7.9833],
      [110.2044, -7.9835],
      [110.2048, -7.9838],
      [110.2056, -7.9841],
      [110.2063, -7.9845],
      [110.2068, -7.9847],
      [110.2077, -7.9851],
      [110.2084, -7.9855],
      [110.2094, -7.986],
      [110.2098, -7.9861],
      [110.2102, -7.9863],
      [110.2105, -7.9865],
      [110.2108, -7.9866],
      [110.2114, -7.9869],
      [110.212, -7.9871],
      [110.2127, -7.9873],
      [110.2131, -7.9875],
      [110.2137, -7.9877],
      [110.2142, -7.9878],
      [110.2146, -7.988],
      [110.2152, -7.9882],
      [110.2157, -7.9884],
      [110.2161, -7.9885],
      [110.2165, -7.9885],
      [110.217, -7.9888],
      [110.2175, -7.989],
      [110.2183, -7.9892],
      [110.219, -7.9894],
      [110.2195, -7.9895],
      [110.2208, -7.99],
      [110.2224, -7.9906],
      [110.2229, -7.9908],
      [110.2237, -7.9909],
      [110.2244, -7.9911],
      [110.2253, -7.9914],
      [110.2255, -7.9915],
      [110.2259, -7.9916],
      [110.2266, -7.9919],
      [110.2274, -7.9922],
      [110.2279, -7.9923],
      [110.2288, -7.9925],
      [110.2294, -7.9927],
      [110.23, -7.993],
      [110.231, -7.9933],
      [110.2316, -7.9935],
      [110.2322, -7.9938],
      [110.2329, -7.994],
      [110.2332, -7.9942],
      [110.2335, -7.9945],
      [110.2341, -7.9946],
      [110.2346, -7.9947],
      [110.2352, -7.9948],
      [110.2359, -7.995],
      [110.2365, -7.9951],
      [110.2371, -7.9953],
      [110.2378, -7.9956],
      [110.2382, -7.9958],
      [110.2389, -7.9961],
      [110.2395, -7.9965],
      [110.2401, -7.9967],
      [110.2403, -7.9967],
      [110.2404, -7.9967],
      [110.2409, -7.997],
      [110.2417, -7.9973],
      [110.2418, -7.997],
      [110.2425, -7.9948],
      [110.2431, -7.9927],
      [110.2434, -7.9914],
      [110.2438, -7.9903],
      [110.2441, -7.9895],
      [110.2444, -7.9889],
      [110.245, -7.9875],
      [110.2456, -7.9862],
      [110.2464, -7.9849],
      [110.2468, -7.9842],
      [110.247, -7.9837],
      [110.2446, -7.983],
      [110.2432, -7.9824],
      [110.2425, -7.9822],
      [110.2426, -7.9819],
      [110.2432, -7.9807],
      [110.2439, -7.9792],
      [110.2439, -7.979],
      [110.2432, -7.9773],
      [110.2429, -7.9764],
      [110.2429, -7.9762],
      [110.2428, -7.9758],
      [110.2426, -7.9757],
      [110.2421, -7.9754],
      [110.2418, -7.9749],
      [110.2418, -7.9748],
      [110.2418, -7.9738],
      [110.242, -7.9729],
      [110.2426, -7.9695],
      [110.2426, -7.9693],
      [110.2429, -7.9687],
      [110.2433, -7.9684],
      [110.2436, -7.9681],
      [110.244, -7.9676],
      [110.2444, -7.9675],
      [110.245, -7.9675],
      [110.2455, -7.9676],
      [110.2459, -7.9677],
      [110.2464, -7.9676],
      [110.2471, -7.9674],
      [110.2476, -7.9672],
      [110.2479, -7.9671],
      [110.2491, -7.9661],
      [110.2501, -7.9656],
      [110.2507, -7.9645],
      [110.2511, -7.9636],
      [110.2513, -7.9631],
      [110.2515, -7.9629],
      [110.252, -7.961],
      [110.2526, -7.9594],
      [110.2531, -7.9574],
      [110.2535, -7.9558],
      [110.2541, -7.9546],
      [110.2546, -7.9535],
      [110.2548, -7.9533]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SRANDAKAN',
    village: 'TRIMURTI',
    border: [
      [110.2697, -7.9259],
      [110.2692, -7.9267],
      [110.268, -7.9279],
      [110.2671, -7.9285],
      [110.2663, -7.9288],
      [110.2653, -7.9291],
      [110.2639, -7.929],
      [110.2631, -7.9289],
      [110.2597, -7.926],
      [110.2577, -7.9244],
      [110.2574, -7.9241],
      [110.256, -7.9236],
      [110.2553, -7.9235],
      [110.2536, -7.9243],
      [110.252, -7.925],
      [110.2505, -7.9262],
      [110.2495, -7.9272],
      [110.2487, -7.9274],
      [110.2482, -7.9275],
      [110.2476, -7.928],
      [110.2468, -7.9288],
      [110.2464, -7.9294],
      [110.2457, -7.9309],
      [110.2453, -7.9316],
      [110.2452, -7.9317],
      [110.2447, -7.9324],
      [110.2438, -7.9344],
      [110.2435, -7.9353],
      [110.2434, -7.936],
      [110.2434, -7.936],
      [110.2432, -7.9366],
      [110.243, -7.9373],
      [110.2427, -7.9382],
      [110.2423, -7.9392],
      [110.2421, -7.9398],
      [110.2419, -7.9405],
      [110.2416, -7.9415],
      [110.2409, -7.9431],
      [110.2394, -7.946],
      [110.2378, -7.9485],
      [110.2377, -7.9489],
      [110.2371, -7.9497],
      [110.2374, -7.9499],
      [110.2392, -7.9505],
      [110.2401, -7.9507],
      [110.2412, -7.9508],
      [110.2418, -7.951],
      [110.2426, -7.951],
      [110.2432, -7.9513],
      [110.2447, -7.9513],
      [110.2458, -7.9514],
      [110.2461, -7.9514],
      [110.2463, -7.9514],
      [110.2471, -7.9516],
      [110.248, -7.9518],
      [110.2518, -7.9525],
      [110.2542, -7.9531],
      [110.2547, -7.9533],
      [110.2548, -7.9533],
      [110.2568, -7.9537],
      [110.2574, -7.9539],
      [110.2595, -7.9543],
      [110.261, -7.9545],
      [110.2614, -7.9539],
      [110.2616, -7.953],
      [110.2618, -7.9523],
      [110.262, -7.9512],
      [110.2623, -7.95],
      [110.2624, -7.9497],
      [110.263, -7.9495],
      [110.2633, -7.949],
      [110.2639, -7.9487],
      [110.2643, -7.9486],
      [110.2644, -7.9478],
      [110.2638, -7.948],
      [110.2628, -7.9478],
      [110.2622, -7.9478],
      [110.2617, -7.9478],
      [110.2611, -7.9476],
      [110.2605, -7.9474],
      [110.2595, -7.9472],
      [110.2592, -7.9469],
      [110.2591, -7.9468],
      [110.2593, -7.9459],
      [110.2595, -7.9439],
      [110.2586, -7.9437],
      [110.2579, -7.9434],
      [110.2573, -7.943],
      [110.2569, -7.9428],
      [110.2564, -7.9428],
      [110.2556, -7.9423],
      [110.2551, -7.9421],
      [110.2546, -7.9421],
      [110.2545, -7.9417],
      [110.2543, -7.9412],
      [110.2544, -7.9408],
      [110.2544, -7.9406],
      [110.2547, -7.9404],
      [110.2552, -7.9402],
      [110.2552, -7.9395],
      [110.2554, -7.9386],
      [110.2555, -7.9382],
      [110.2567, -7.9379],
      [110.2589, -7.9362],
      [110.2605, -7.9351],
      [110.2607, -7.9322],
      [110.2618, -7.9322],
      [110.2626, -7.9319],
      [110.2628, -7.9319],
      [110.2631, -7.9325],
      [110.2628, -7.9351],
      [110.2636, -7.9351],
      [110.2635, -7.9361],
      [110.2638, -7.9361],
      [110.2641, -7.9363],
      [110.2646, -7.9364],
      [110.2647, -7.9367],
      [110.2645, -7.9375],
      [110.2648, -7.9379],
      [110.2651, -7.9385],
      [110.265, -7.939],
      [110.2647, -7.9397],
      [110.2645, -7.94],
      [110.2655, -7.9398],
      [110.2657, -7.9398],
      [110.2665, -7.9397],
      [110.2669, -7.9397],
      [110.2668, -7.9379],
      [110.2666, -7.9364],
      [110.2667, -7.9359],
      [110.2673, -7.935],
      [110.2677, -7.9341],
      [110.2678, -7.9332],
      [110.2678, -7.9325],
      [110.2685, -7.9326],
      [110.2687, -7.9324],
      [110.2697, -7.9326],
      [110.2699, -7.9323],
      [110.271, -7.9323],
      [110.2719, -7.9321],
      [110.2731, -7.9318],
      [110.2728, -7.9312],
      [110.2727, -7.9305],
      [110.2721, -7.9295],
      [110.2718, -7.9289],
      [110.2722, -7.9283],
      [110.2726, -7.9276],
      [110.2727, -7.9271],
      [110.2726, -7.9266],
      [110.2718, -7.9264],
      [110.2716, -7.9263],
      [110.2708, -7.9262],
      [110.2702, -7.9261],
      [110.2697, -7.9259]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SANDEN',
    village: 'GADINGSARI',
    border: [
      [110.2595, -7.9543],
      [110.2574, -7.9539],
      [110.2568, -7.9537],
      [110.2548, -7.9533],
      [110.2546, -7.9535],
      [110.2541, -7.9546],
      [110.2535, -7.9558],
      [110.2531, -7.9574],
      [110.2526, -7.9594],
      [110.252, -7.961],
      [110.2515, -7.9629],
      [110.2513, -7.9631],
      [110.2511, -7.9636],
      [110.2507, -7.9645],
      [110.2501, -7.9656],
      [110.2491, -7.9661],
      [110.2479, -7.9671],
      [110.2476, -7.9672],
      [110.2471, -7.9674],
      [110.2464, -7.9676],
      [110.2459, -7.9677],
      [110.2455, -7.9676],
      [110.245, -7.9675],
      [110.2444, -7.9675],
      [110.244, -7.9676],
      [110.2436, -7.9681],
      [110.2433, -7.9684],
      [110.2429, -7.9687],
      [110.2426, -7.9693],
      [110.2426, -7.9695],
      [110.242, -7.9729],
      [110.2418, -7.9738],
      [110.2418, -7.9748],
      [110.2418, -7.9749],
      [110.2421, -7.9754],
      [110.2426, -7.9757],
      [110.2428, -7.9758],
      [110.2429, -7.9762],
      [110.2429, -7.9764],
      [110.2432, -7.9773],
      [110.2439, -7.979],
      [110.2439, -7.9792],
      [110.2432, -7.9807],
      [110.2426, -7.9819],
      [110.2425, -7.9822],
      [110.2432, -7.9824],
      [110.2446, -7.983],
      [110.247, -7.9837],
      [110.2468, -7.9842],
      [110.2464, -7.9849],
      [110.2456, -7.9862],
      [110.245, -7.9875],
      [110.2444, -7.9889],
      [110.2441, -7.9895],
      [110.2438, -7.9903],
      [110.2434, -7.9914],
      [110.2431, -7.9927],
      [110.2425, -7.9948],
      [110.2418, -7.997],
      [110.2417, -7.9973],
      [110.2423, -7.9975],
      [110.2428, -7.9975],
      [110.2433, -7.9975],
      [110.2437, -7.9976],
      [110.244, -7.9977],
      [110.2443, -7.9979],
      [110.2445, -7.9981],
      [110.2447, -7.9983],
      [110.2449, -7.9985],
      [110.2451, -7.9986],
      [110.2455, -7.9987],
      [110.2458, -7.9987],
      [110.246, -7.9988],
      [110.247, -7.9993],
      [110.2473, -7.9994],
      [110.2482, -7.9996],
      [110.249, -7.9998],
      [110.2497, -7.9999],
      [110.2502, -8.0002],
      [110.2506, -8.0004],
      [110.2512, -8.0008],
      [110.2514, -8.001],
      [110.2518, -8.0012],
      [110.2521, -8.0013],
      [110.2525, -8.0015],
      [110.253, -8.0015],
      [110.2532, -8.0015],
      [110.2535, -8.0015],
      [110.2537, -8.0015],
      [110.2541, -8.0017],
      [110.2542, -8.0018],
      [110.2544, -8.0021],
      [110.2545, -8.0022],
      [110.2547, -8.0023],
      [110.2548, -8.0024],
      [110.255, -8.0025],
      [110.2554, -8.0025],
      [110.2565, -8.0025],
      [110.2567, -8.0025],
      [110.2568, -8.0025],
      [110.257, -8.0026],
      [110.2573, -8.0027],
      [110.258, -8.0016],
      [110.259, -7.9995],
      [110.2597, -7.9981],
      [110.2613, -7.9962],
      [110.2614, -7.9959],
      [110.2625, -7.9931],
      [110.2628, -7.9921],
      [110.263, -7.9902],
      [110.2629, -7.9894],
      [110.2627, -7.9893],
      [110.2618, -7.9892],
      [110.2611, -7.9888],
      [110.26, -7.9885],
      [110.2592, -7.9883],
      [110.2587, -7.9879],
      [110.2587, -7.9874],
      [110.2579, -7.987],
      [110.2581, -7.9863],
      [110.2588, -7.9865],
      [110.2597, -7.9848],
      [110.2591, -7.9846],
      [110.2586, -7.9843],
      [110.2586, -7.9837],
      [110.2573, -7.9831],
      [110.2563, -7.9826],
      [110.2567, -7.9822],
      [110.2568, -7.9814],
      [110.2571, -7.9796],
      [110.2574, -7.9784],
      [110.2574, -7.9782],
      [110.2573, -7.9776],
      [110.2575, -7.9772],
      [110.2582, -7.9765],
      [110.2585, -7.9761],
      [110.2574, -7.9754],
      [110.2563, -7.9757],
      [110.2558, -7.9762],
      [110.2549, -7.9764],
      [110.254, -7.9764],
      [110.254, -7.9754],
      [110.2542, -7.9745],
      [110.2546, -7.9736],
      [110.2554, -7.9737],
      [110.2566, -7.974],
      [110.2569, -7.974],
      [110.2576, -7.974],
      [110.2585, -7.9736],
      [110.2595, -7.9732],
      [110.2598, -7.9728],
      [110.2603, -7.9723],
      [110.2598, -7.9721],
      [110.2591, -7.9715],
      [110.2586, -7.971],
      [110.2573, -7.9704],
      [110.2577, -7.9697],
      [110.2581, -7.9689],
      [110.2585, -7.9673],
      [110.2588, -7.9661],
      [110.2588, -7.966],
      [110.2587, -7.9644],
      [110.2587, -7.9635],
      [110.2588, -7.9634],
      [110.2588, -7.9629],
      [110.2589, -7.9629],
      [110.2591, -7.9629],
      [110.2591, -7.9622],
      [110.2593, -7.962],
      [110.2593, -7.9614],
      [110.2599, -7.9611],
      [110.2602, -7.9599],
      [110.2603, -7.9597],
      [110.2604, -7.9591],
      [110.2606, -7.9588],
      [110.2611, -7.9585],
      [110.2612, -7.958],
      [110.2619, -7.9582],
      [110.2623, -7.957],
      [110.2625, -7.9567],
      [110.2629, -7.9567],
      [110.2638, -7.9569],
      [110.2646, -7.9567],
      [110.2646, -7.9559],
      [110.2648, -7.9552],
      [110.2636, -7.955],
      [110.2616, -7.9547],
      [110.2609, -7.9546],
      [110.2595, -7.9543]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SANDEN',
    village: 'GADINGHARJO',
    border: [
      [110.2614, -7.9959],
      [110.2622, -7.9959],
      [110.2627, -7.9962],
      [110.264, -7.9965],
      [110.2651, -7.9969],
      [110.2655, -7.9972],
      [110.2677, -7.997],
      [110.269, -7.9969],
      [110.2693, -7.9969],
      [110.2693, -7.996],
      [110.2692, -7.9957],
      [110.2693, -7.9954],
      [110.2695, -7.9951],
      [110.2696, -7.9951],
      [110.2696, -7.9947],
      [110.2693, -7.9945],
      [110.2692, -7.9941],
      [110.2693, -7.9937],
      [110.2695, -7.9934],
      [110.2696, -7.9931],
      [110.2702, -7.9928],
      [110.2702, -7.9927],
      [110.2702, -7.9923],
      [110.2703, -7.9922],
      [110.2704, -7.9919],
      [110.2706, -7.9917],
      [110.2711, -7.9909],
      [110.2717, -7.9892],
      [110.2721, -7.9882],
      [110.2721, -7.9873],
      [110.2724, -7.9871],
      [110.2727, -7.9852],
      [110.273, -7.9832],
      [110.2719, -7.9824],
      [110.2713, -7.9821],
      [110.2715, -7.981],
      [110.2714, -7.981],
      [110.2713, -7.9809],
      [110.2715, -7.9776],
      [110.2716, -7.976],
      [110.2714, -7.976],
      [110.2714, -7.9756],
      [110.2718, -7.9745],
      [110.2723, -7.9729],
      [110.2707, -7.9725],
      [110.2706, -7.9724],
      [110.2707, -7.9718],
      [110.27, -7.9716],
      [110.269, -7.9715],
      [110.2688, -7.9715],
      [110.268, -7.9714],
      [110.2673, -7.9732],
      [110.2669, -7.9747],
      [110.2667, -7.9753],
      [110.2666, -7.9763],
      [110.2666, -7.9766],
      [110.2657, -7.9764],
      [110.2652, -7.9767],
      [110.2648, -7.9769],
      [110.264, -7.9769],
      [110.263, -7.9769],
      [110.2614, -7.9767],
      [110.2602, -7.9766],
      [110.2594, -7.9764],
      [110.2588, -7.9762],
      [110.2585, -7.9761],
      [110.2582, -7.9765],
      [110.2575, -7.9772],
      [110.2573, -7.9776],
      [110.2574, -7.9782],
      [110.2574, -7.9784],
      [110.2571, -7.9796],
      [110.2568, -7.9814],
      [110.2567, -7.9822],
      [110.2563, -7.9826],
      [110.2573, -7.9831],
      [110.2586, -7.9837],
      [110.2586, -7.9843],
      [110.2591, -7.9846],
      [110.2597, -7.9848],
      [110.2588, -7.9865],
      [110.2581, -7.9863],
      [110.2579, -7.987],
      [110.2587, -7.9874],
      [110.2587, -7.9879],
      [110.2592, -7.9883],
      [110.26, -7.9885],
      [110.2611, -7.9888],
      [110.2618, -7.9892],
      [110.2627, -7.9893],
      [110.2629, -7.9894],
      [110.263, -7.9902],
      [110.2628, -7.9921],
      [110.2625, -7.9931],
      [110.2614, -7.9959]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SANDEN',
    village: 'SRIGADING',
    border: [
      [110.2723, -7.9729],
      [110.2718, -7.9745],
      [110.2714, -7.9756],
      [110.2714, -7.976],
      [110.2716, -7.976],
      [110.2715, -7.9776],
      [110.2713, -7.9809],
      [110.2714, -7.981],
      [110.2715, -7.981],
      [110.2713, -7.9821],
      [110.2719, -7.9824],
      [110.273, -7.9832],
      [110.2727, -7.9852],
      [110.2724, -7.9871],
      [110.2721, -7.9873],
      [110.2721, -7.9882],
      [110.2717, -7.9892],
      [110.2711, -7.9909],
      [110.2706, -7.9917],
      [110.2704, -7.9919],
      [110.2703, -7.9922],
      [110.2702, -7.9923],
      [110.2702, -7.9927],
      [110.2702, -7.9928],
      [110.2696, -7.9931],
      [110.2695, -7.9934],
      [110.2693, -7.9937],
      [110.2692, -7.9941],
      [110.2693, -7.9945],
      [110.2696, -7.9947],
      [110.2696, -7.9951],
      [110.2695, -7.9951],
      [110.2693, -7.9954],
      [110.2692, -7.9957],
      [110.2693, -7.996],
      [110.2693, -7.9969],
      [110.269, -7.9969],
      [110.2677, -7.997],
      [110.2655, -7.9972],
      [110.2651, -7.9969],
      [110.264, -7.9965],
      [110.2627, -7.9962],
      [110.2622, -7.9959],
      [110.2614, -7.9959],
      [110.2613, -7.9962],
      [110.2597, -7.9981],
      [110.259, -7.9995],
      [110.258, -8.0016],
      [110.2573, -8.0027],
      [110.2575, -8.0028],
      [110.2577, -8.0029],
      [110.258, -8.0032],
      [110.2582, -8.0034],
      [110.2584, -8.0035],
      [110.2586, -8.0035],
      [110.2589, -8.0036],
      [110.2592, -8.0038],
      [110.2597, -8.004],
      [110.2599, -8.0042],
      [110.2602, -8.0043],
      [110.2606, -8.0042],
      [110.2609, -8.0044],
      [110.2614, -8.0047],
      [110.2618, -8.0049],
      [110.2621, -8.0051],
      [110.2623, -8.0052],
      [110.2624, -8.0053],
      [110.2626, -8.0053],
      [110.2631, -8.0053],
      [110.2634, -8.0053],
      [110.2639, -8.0055],
      [110.2642, -8.0056],
      [110.2645, -8.0059],
      [110.2649, -8.0061],
      [110.2654, -8.0063],
      [110.2657, -8.0063],
      [110.2662, -8.0064],
      [110.2666, -8.0064],
      [110.2668, -8.0065],
      [110.2673, -8.0068],
      [110.2676, -8.007],
      [110.2679, -8.0071],
      [110.2684, -8.0072],
      [110.2687, -8.0073],
      [110.2691, -8.0075],
      [110.2695, -8.0076],
      [110.2698, -8.0079],
      [110.2705, -8.0082],
      [110.2716, -8.0085],
      [110.272, -8.0087],
      [110.2724, -8.0089],
      [110.2727, -8.0089],
      [110.2731, -8.009],
      [110.2737, -8.0091],
      [110.2741, -8.0091],
      [110.2748, -8.0094],
      [110.2751, -8.0087],
      [110.2754, -8.0081],
      [110.2761, -8.0065],
      [110.2764, -8.006],
      [110.2771, -8.0047],
      [110.2777, -8.0038],
      [110.2782, -8.003],
      [110.2791, -8.0028],
      [110.28, -8.0025],
      [110.2803, -8.0018],
      [110.2805, -8.0015],
      [110.2804, -8.001],
      [110.2807, -8.0005],
      [110.2808, -8.0001],
      [110.2813, -8.0],
      [110.2817, -7.9999],
      [110.2819, -7.9998],
      [110.282, -7.9997],
      [110.2821, -7.9991],
      [110.2821, -7.999],
      [110.2823, -7.9987],
      [110.2828, -7.9984],
      [110.2833, -7.9973],
      [110.2834, -7.9951],
      [110.2838, -7.9916],
      [110.284, -7.9904],
      [110.2851, -7.9905],
      [110.2867, -7.9904],
      [110.2875, -7.9905],
      [110.2875, -7.9904],
      [110.2886, -7.9876],
      [110.2897, -7.9847],
      [110.2901, -7.9845],
      [110.29, -7.9835],
      [110.2901, -7.9826],
      [110.2901, -7.9822],
      [110.2902, -7.9809],
      [110.2902, -7.9807],
      [110.2901, -7.9806],
      [110.2901, -7.9805],
      [110.29, -7.98],
      [110.2898, -7.9797],
      [110.2896, -7.9793],
      [110.2895, -7.9792],
      [110.2894, -7.979],
      [110.2893, -7.9782],
      [110.289, -7.9781],
      [110.2891, -7.9772],
      [110.2891, -7.9763],
      [110.2888, -7.976],
      [110.2885, -7.976],
      [110.2883, -7.9761],
      [110.2874, -7.976],
      [110.2874, -7.9751],
      [110.2872, -7.9746],
      [110.2872, -7.9739],
      [110.287, -7.9731],
      [110.2867, -7.9724],
      [110.2852, -7.9719],
      [110.2848, -7.9717],
      [110.2846, -7.9716],
      [110.2846, -7.9711],
      [110.2843, -7.9705],
      [110.2822, -7.9709],
      [110.2803, -7.9711],
      [110.2789, -7.9714],
      [110.277, -7.9722],
      [110.2764, -7.9724],
      [110.2758, -7.9731],
      [110.2739, -7.9732],
      [110.2723, -7.9729]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SANDEN',
    village: 'MURTIGADING',
    border: [
      [110.2843, -7.9705],
      [110.285, -7.9705],
      [110.2855, -7.9708],
      [110.2857, -7.9708],
      [110.286, -7.9709],
      [110.2862, -7.9709],
      [110.2865, -7.9703],
      [110.2868, -7.9696],
      [110.2873, -7.9697],
      [110.2876, -7.9702],
      [110.2884, -7.9703],
      [110.2901, -7.9705],
      [110.2901, -7.9704],
      [110.2902, -7.9698],
      [110.2901, -7.9686],
      [110.2899, -7.9679],
      [110.2896, -7.9678],
      [110.2897, -7.9674],
      [110.2901, -7.9674],
      [110.2905, -7.9665],
      [110.2906, -7.9663],
      [110.2906, -7.9655],
      [110.2901, -7.9653],
      [110.2898, -7.9653],
      [110.2897, -7.9658],
      [110.2895, -7.9661],
      [110.289, -7.9662],
      [110.2889, -7.9667],
      [110.2883, -7.9666],
      [110.2877, -7.9664],
      [110.2874, -7.9663],
      [110.2872, -7.9663],
      [110.287, -7.9662],
      [110.287, -7.9662],
      [110.2866, -7.9664],
      [110.2861, -7.9666],
      [110.2858, -7.9666],
      [110.2858, -7.9662],
      [110.2852, -7.966],
      [110.284, -7.9656],
      [110.2835, -7.9653],
      [110.2829, -7.9649],
      [110.2823, -7.9643],
      [110.2816, -7.9637],
      [110.2808, -7.9632],
      [110.2799, -7.963],
      [110.2799, -7.9627],
      [110.2794, -7.9624],
      [110.2788, -7.9617],
      [110.2782, -7.9612],
      [110.2781, -7.9607],
      [110.2781, -7.9605],
      [110.2783, -7.9598],
      [110.2783, -7.9597],
      [110.2783, -7.9593],
      [110.2781, -7.9585],
      [110.2779, -7.9576],
      [110.278, -7.9568],
      [110.2765, -7.9567],
      [110.2762, -7.9567],
      [110.275, -7.9566],
      [110.2748, -7.9565],
      [110.2742, -7.9565],
      [110.274, -7.9565],
      [110.2734, -7.9569],
      [110.2726, -7.9571],
      [110.2721, -7.9572],
      [110.2704, -7.9571],
      [110.2689, -7.9565],
      [110.2682, -7.9559],
      [110.2672, -7.9556],
      [110.265, -7.9551],
      [110.2649, -7.9552],
      [110.2648, -7.9552],
      [110.2646, -7.9559],
      [110.2646, -7.9567],
      [110.2638, -7.9569],
      [110.2629, -7.9567],
      [110.2625, -7.9567],
      [110.2623, -7.957],
      [110.2619, -7.9582],
      [110.2612, -7.958],
      [110.2611, -7.9585],
      [110.2606, -7.9588],
      [110.2604, -7.9591],
      [110.2603, -7.9597],
      [110.2602, -7.9599],
      [110.2599, -7.9611],
      [110.2593, -7.9614],
      [110.2593, -7.962],
      [110.2591, -7.9622],
      [110.2591, -7.9629],
      [110.2589, -7.9629],
      [110.2588, -7.9629],
      [110.2588, -7.9634],
      [110.2587, -7.9635],
      [110.2587, -7.9644],
      [110.2588, -7.966],
      [110.2588, -7.9661],
      [110.2585, -7.9673],
      [110.2581, -7.9689],
      [110.2577, -7.9697],
      [110.2573, -7.9704],
      [110.2586, -7.971],
      [110.2591, -7.9715],
      [110.2598, -7.9721],
      [110.2603, -7.9723],
      [110.2598, -7.9728],
      [110.2595, -7.9732],
      [110.2585, -7.9736],
      [110.2576, -7.974],
      [110.2569, -7.974],
      [110.2566, -7.974],
      [110.2554, -7.9737],
      [110.2546, -7.9736],
      [110.2542, -7.9745],
      [110.254, -7.9754],
      [110.254, -7.9764],
      [110.2549, -7.9764],
      [110.2558, -7.9762],
      [110.2563, -7.9757],
      [110.2574, -7.9754],
      [110.2585, -7.9761],
      [110.2588, -7.9762],
      [110.2594, -7.9764],
      [110.2602, -7.9766],
      [110.2614, -7.9767],
      [110.263, -7.9769],
      [110.264, -7.9769],
      [110.2648, -7.9769],
      [110.2652, -7.9767],
      [110.2657, -7.9764],
      [110.2666, -7.9766],
      [110.2666, -7.9763],
      [110.2667, -7.9753],
      [110.2669, -7.9747],
      [110.2673, -7.9732],
      [110.268, -7.9714],
      [110.2688, -7.9715],
      [110.269, -7.9715],
      [110.27, -7.9716],
      [110.2707, -7.9718],
      [110.2706, -7.9724],
      [110.2707, -7.9725],
      [110.2723, -7.9729],
      [110.2739, -7.9732],
      [110.2758, -7.9731],
      [110.2764, -7.9724],
      [110.277, -7.9722],
      [110.2789, -7.9714],
      [110.2803, -7.9711],
      [110.2822, -7.9709],
      [110.2843, -7.9705]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KRETEK',
    village: 'TIRTOMULYO',
    border: [
      [110.2875, -7.9905],
      [110.2867, -7.9904],
      [110.2851, -7.9905],
      [110.284, -7.9904],
      [110.2838, -7.9916],
      [110.2834, -7.9951],
      [110.2833, -7.9973],
      [110.2828, -7.9984],
      [110.2823, -7.9987],
      [110.2821, -7.999],
      [110.2821, -7.9991],
      [110.282, -7.9997],
      [110.2819, -7.9998],
      [110.2817, -7.9999],
      [110.2813, -8.0],
      [110.2808, -8.0001],
      [110.2807, -8.0005],
      [110.2804, -8.001],
      [110.2805, -8.0015],
      [110.2803, -8.0018],
      [110.28, -8.0025],
      [110.2791, -8.0028],
      [110.2782, -8.003],
      [110.2777, -8.0038],
      [110.2771, -8.0047],
      [110.2764, -8.006],
      [110.2761, -8.0065],
      [110.2754, -8.0081],
      [110.2751, -8.0087],
      [110.2748, -8.0094],
      [110.2749, -8.0095],
      [110.2757, -8.0098],
      [110.2762, -8.01],
      [110.2769, -8.0101],
      [110.2775, -8.0102],
      [110.2777, -8.0103],
      [110.278, -8.0105],
      [110.2783, -8.0106],
      [110.2784, -8.0106],
      [110.2785, -8.0106],
      [110.2786, -8.0107],
      [110.2788, -8.0106],
      [110.2791, -8.0104],
      [110.2794, -8.0099],
      [110.28, -8.0096],
      [110.2808, -8.0099],
      [110.2819, -8.0099],
      [110.2834, -8.0097],
      [110.2848, -8.0095],
      [110.2875, -8.0099],
      [110.2886, -8.0104],
      [110.2895, -8.0104],
      [110.2906, -8.01],
      [110.2914, -8.0096],
      [110.2919, -8.0093],
      [110.2926, -8.009],
      [110.2934, -8.0086],
      [110.2935, -8.0086],
      [110.2941, -8.0086],
      [110.2944, -8.0086],
      [110.2949, -8.0086],
      [110.2956, -8.0082],
      [110.2963, -8.0078],
      [110.2964, -8.0077],
      [110.2969, -8.0073],
      [110.2974, -8.0067],
      [110.298, -8.0057],
      [110.2982, -8.0053],
      [110.2993, -8.0043],
      [110.2999, -8.0028],
      [110.3007, -8.0011],
      [110.301, -8.0006],
      [110.3013, -8.0003],
      [110.3015, -8.0001],
      [110.3014, -8.0],
      [110.301, -7.9996],
      [110.3007, -7.9992],
      [110.3006, -7.9987],
      [110.3008, -7.9976],
      [110.3012, -7.9961],
      [110.3012, -7.9959],
      [110.2999, -7.9958],
      [110.2989, -7.9954],
      [110.2976, -7.995],
      [110.2968, -7.9944],
      [110.2961, -7.9951],
      [110.2955, -7.9955],
      [110.2953, -7.9959],
      [110.2947, -7.9962],
      [110.2945, -7.9966],
      [110.2943, -7.9968],
      [110.2937, -7.9966],
      [110.2937, -7.9962],
      [110.2932, -7.996],
      [110.2922, -7.9956],
      [110.2917, -7.9951],
      [110.2911, -7.9956],
      [110.2902, -7.9958],
      [110.2897, -7.9958],
      [110.2897, -7.9955],
      [110.2897, -7.9953],
      [110.2903, -7.995],
      [110.2905, -7.9947],
      [110.2908, -7.9946],
      [110.2907, -7.9931],
      [110.291, -7.993],
      [110.2909, -7.9915],
      [110.2907, -7.9914],
      [110.2885, -7.9908],
      [110.2875, -7.9905]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KRETEK',
    village: 'PARANGTRITIS',
    border: [
      [110.3307, -8.0072],
      [110.3293, -8.0064],
      [110.3283, -8.0058],
      [110.3271, -8.0044],
      [110.3261, -8.0032],
      [110.3248, -8.0028],
      [110.324, -8.0022],
      [110.3229, -8.0004],
      [110.3228, -7.9999],
      [110.3226, -7.9994],
      [110.3222, -7.9983],
      [110.3213, -7.9963],
      [110.3208, -7.9956],
      [110.3204, -7.995],
      [110.3201, -7.9935],
      [110.3196, -7.9917],
      [110.3195, -7.991],
      [110.3196, -7.9902],
      [110.319, -7.9888],
      [110.3186, -7.9879],
      [110.3185, -7.9876],
      [110.3175, -7.9882],
      [110.3165, -7.9891],
      [110.316, -7.9897],
      [110.3154, -7.9903],
      [110.3144, -7.9907],
      [110.3132, -7.9912],
      [110.3108, -7.9926],
      [110.3095, -7.9933],
      [110.3089, -7.9937],
      [110.308, -7.994],
      [110.3075, -7.9946],
      [110.3066, -7.9955],
      [110.3059, -7.9966],
      [110.305, -7.9971],
      [110.3045, -7.9975],
      [110.304, -7.9982],
      [110.3034, -7.9987],
      [110.3029, -7.9991],
      [110.3021, -7.9999],
      [110.3016, -8.0001],
      [110.3015, -8.0001],
      [110.3013, -8.0003],
      [110.301, -8.0006],
      [110.3007, -8.0011],
      [110.2999, -8.0028],
      [110.2993, -8.0043],
      [110.2982, -8.0053],
      [110.298, -8.0057],
      [110.2974, -8.0067],
      [110.2969, -8.0073],
      [110.2964, -8.0077],
      [110.2963, -8.0078],
      [110.2956, -8.0082],
      [110.2949, -8.0086],
      [110.2944, -8.0086],
      [110.2941, -8.0086],
      [110.2935, -8.0086],
      [110.2934, -8.0086],
      [110.2926, -8.009],
      [110.2919, -8.0093],
      [110.2914, -8.0096],
      [110.2906, -8.01],
      [110.2895, -8.0104],
      [110.2886, -8.0104],
      [110.2875, -8.0099],
      [110.2848, -8.0095],
      [110.2834, -8.0097],
      [110.2819, -8.0099],
      [110.2808, -8.0099],
      [110.28, -8.0096],
      [110.2794, -8.0099],
      [110.2791, -8.0104],
      [110.2788, -8.0106],
      [110.2786, -8.0107],
      [110.2787, -8.0108],
      [110.279, -8.011],
      [110.2796, -8.0111],
      [110.2804, -8.0111],
      [110.2812, -8.0112],
      [110.2818, -8.0112],
      [110.282, -8.0112],
      [110.2822, -8.0113],
      [110.2826, -8.0116],
      [110.2827, -8.0116],
      [110.2832, -8.0118],
      [110.2836, -8.0121],
      [110.2839, -8.0122],
      [110.2844, -8.0123],
      [110.285, -8.0123],
      [110.2855, -8.0123],
      [110.286, -8.0126],
      [110.2864, -8.0128],
      [110.2869, -8.013],
      [110.2871, -8.013],
      [110.2873, -8.013],
      [110.2882, -8.013],
      [110.2889, -8.0131],
      [110.2894, -8.0132],
      [110.2898, -8.0134],
      [110.2905, -8.0136],
      [110.2907, -8.0137],
      [110.2913, -8.0138],
      [110.2918, -8.014],
      [110.2923, -8.0142],
      [110.2928, -8.0143],
      [110.2933, -8.0145],
      [110.2944, -8.0148],
      [110.2951, -8.0151],
      [110.2955, -8.0152],
      [110.296, -8.0152],
      [110.2962, -8.0152],
      [110.2965, -8.0151],
      [110.2969, -8.0151],
      [110.2976, -8.0153],
      [110.2982, -8.0154],
      [110.2984, -8.0155],
      [110.2989, -8.0156],
      [110.2998, -8.016],
      [110.3009, -8.0164],
      [110.3017, -8.0167],
      [110.3033, -8.0172],
      [110.3039, -8.0174],
      [110.3045, -8.0174],
      [110.3049, -8.0174],
      [110.3051, -8.0174],
      [110.3057, -8.0174],
      [110.3061, -8.0174],
      [110.3064, -8.0175],
      [110.307, -8.0176],
      [110.3074, -8.0178],
      [110.3075, -8.018],
      [110.3078, -8.0182],
      [110.3084, -8.0187],
      [110.3087, -8.0188],
      [110.3091, -8.0189],
      [110.3094, -8.0191],
      [110.3099, -8.0191],
      [110.3108, -8.0191],
      [110.3112, -8.0191],
      [110.3122, -8.0194],
      [110.313, -8.0196],
      [110.3138, -8.02],
      [110.3145, -8.0204],
      [110.3149, -8.0207],
      [110.3151, -8.0207],
      [110.3156, -8.0208],
      [110.3159, -8.0208],
      [110.3165, -8.0209],
      [110.317, -8.0211],
      [110.3175, -8.0212],
      [110.3179, -8.0212],
      [110.3184, -8.0213],
      [110.3192, -8.0214],
      [110.3194, -8.0215],
      [110.3204, -8.0219],
      [110.3205, -8.022],
      [110.3211, -8.0223],
      [110.3218, -8.0225],
      [110.3224, -8.0226],
      [110.3229, -8.0226],
      [110.3238, -8.0231],
      [110.3244, -8.0235],
      [110.3254, -8.0235],
      [110.3258, -8.0235],
      [110.3269, -8.0238],
      [110.3274, -8.024],
      [110.3277, -8.0241],
      [110.3285, -8.0243],
      [110.3291, -8.0244],
      [110.3294, -8.0246],
      [110.3303, -8.025],
      [110.3305, -8.0252],
      [110.3307, -8.0253],
      [110.3317, -8.0255],
      [110.3322, -8.0255],
      [110.3332, -8.0258],
      [110.3338, -8.026],
      [110.3341, -8.0261],
      [110.3347, -8.0262],
      [110.3355, -8.0265],
      [110.3362, -8.027],
      [110.3367, -8.0273],
      [110.3372, -8.0275],
      [110.338, -8.0276],
      [110.3387, -8.0278],
      [110.3395, -8.028],
      [110.3399, -8.0282],
      [110.3394, -8.0271],
      [110.339, -8.0263],
      [110.3386, -8.0255],
      [110.338, -8.0251],
      [110.3378, -8.0249],
      [110.3378, -8.0245],
      [110.3377, -8.0242],
      [110.3373, -8.0238],
      [110.3372, -8.0236],
      [110.3371, -8.023],
      [110.337, -8.0226],
      [110.3367, -8.0225],
      [110.3363, -8.0224],
      [110.3359, -8.0222],
      [110.3359, -8.0219],
      [110.3358, -8.0216],
      [110.3359, -8.0211],
      [110.3358, -8.0207],
      [110.3358, -8.0203],
      [110.3355, -8.02],
      [110.3351, -8.0195],
      [110.3347, -8.0191],
      [110.3344, -8.0186],
      [110.3342, -8.0182],
      [110.334, -8.0174],
      [110.3337, -8.0166],
      [110.3336, -8.016],
      [110.3335, -8.0155],
      [110.3331, -8.0144],
      [110.3328, -8.0137],
      [110.3326, -8.0128],
      [110.3323, -8.0115],
      [110.3321, -8.0105],
      [110.332, -8.0101],
      [110.3316, -8.0094],
      [110.3311, -8.0087],
      [110.3307, -8.0079],
      [110.3307, -8.0074],
      [110.3307, -8.0072]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KRETEK',
    village: 'DONOTIRTO',
    border: [
      [110.3253, -7.9735],
      [110.3239, -7.9732],
      [110.3233, -7.973],
      [110.3225, -7.9728],
      [110.3217, -7.9729],
      [110.3206, -7.9724],
      [110.3202, -7.9723],
      [110.3187, -7.9722],
      [110.3183, -7.9721],
      [110.3172, -7.972],
      [110.317, -7.972],
      [110.3162, -7.9719],
      [110.3156, -7.9718],
      [110.3154, -7.9718],
      [110.315, -7.9717],
      [110.3149, -7.9717],
      [110.3138, -7.9716],
      [110.3124, -7.9713],
      [110.3109, -7.971],
      [110.3091, -7.9705],
      [110.309, -7.9711],
      [110.3079, -7.9711],
      [110.3076, -7.9725],
      [110.3073, -7.9737],
      [110.3074, -7.9742],
      [110.3078, -7.9743],
      [110.3081, -7.9739],
      [110.3084, -7.9739],
      [110.3087, -7.9743],
      [110.309, -7.9745],
      [110.309, -7.9748],
      [110.3088, -7.9761],
      [110.3076, -7.9764],
      [110.3075, -7.9771],
      [110.3069, -7.9772],
      [110.3067, -7.978],
      [110.3072, -7.9781],
      [110.3074, -7.9782],
      [110.3078, -7.9784],
      [110.3077, -7.9797],
      [110.3081, -7.9813],
      [110.3079, -7.9827],
      [110.3078, -7.9829],
      [110.3076, -7.9829],
      [110.3074, -7.9839],
      [110.3071, -7.9844],
      [110.3066, -7.985],
      [110.3064, -7.9865],
      [110.3063, -7.9869],
      [110.306, -7.9875],
      [110.3058, -7.9884],
      [110.3057, -7.9888],
      [110.3051, -7.989],
      [110.3049, -7.9894],
      [110.3044, -7.9899],
      [110.3041, -7.9899],
      [110.3039, -7.99],
      [110.304, -7.9904],
      [110.3038, -7.9909],
      [110.3038, -7.9911],
      [110.3037, -7.9925],
      [110.3033, -7.9927],
      [110.3033, -7.9941],
      [110.3022, -7.9941],
      [110.3016, -7.9942],
      [110.3014, -7.9949],
      [110.3012, -7.9959],
      [110.3012, -7.9961],
      [110.3008, -7.9976],
      [110.3006, -7.9987],
      [110.3007, -7.9992],
      [110.301, -7.9996],
      [110.3014, -8.0],
      [110.3015, -8.0001],
      [110.3016, -8.0001],
      [110.3021, -7.9999],
      [110.3029, -7.9991],
      [110.3034, -7.9987],
      [110.304, -7.9982],
      [110.3045, -7.9975],
      [110.305, -7.9971],
      [110.3059, -7.9966],
      [110.3066, -7.9955],
      [110.3075, -7.9946],
      [110.308, -7.994],
      [110.3089, -7.9937],
      [110.3095, -7.9933],
      [110.3108, -7.9926],
      [110.3132, -7.9912],
      [110.3144, -7.9907],
      [110.3154, -7.9903],
      [110.316, -7.9897],
      [110.3165, -7.9891],
      [110.3175, -7.9882],
      [110.3185, -7.9876],
      [110.319, -7.9871],
      [110.3198, -7.986],
      [110.3205, -7.9853],
      [110.3217, -7.9845],
      [110.3229, -7.984],
      [110.3241, -7.984],
      [110.3257, -7.9843],
      [110.3268, -7.9845],
      [110.3279, -7.9841],
      [110.328, -7.9841],
      [110.3281, -7.984],
      [110.3284, -7.9832],
      [110.3287, -7.9821],
      [110.329, -7.9809],
      [110.3295, -7.9801],
      [110.33, -7.9795],
      [110.3299, -7.9793],
      [110.3295, -7.979],
      [110.3289, -7.9784],
      [110.3288, -7.9774],
      [110.3288, -7.977],
      [110.3287, -7.9765],
      [110.3283, -7.9766],
      [110.3277, -7.9757],
      [110.3262, -7.9753],
      [110.3261, -7.9749],
      [110.3264, -7.9747],
      [110.3264, -7.9743],
      [110.3262, -7.9736],
      [110.3254, -7.9735],
      [110.3253, -7.9735]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KRETEK',
    village: 'TIRTOSARI',
    border: [
      [110.3076, -7.9829],
      [110.3056, -7.9829],
      [110.304, -7.9828],
      [110.3025, -7.9826],
      [110.3023, -7.9826],
      [110.3023, -7.9819],
      [110.3023, -7.9813],
      [110.3014, -7.9812],
      [110.3008, -7.9811],
      [110.3007, -7.981],
      [110.3006, -7.98],
      [110.3007, -7.9789],
      [110.301, -7.9774],
      [110.301, -7.9768],
      [110.301, -7.9765],
      [110.3013, -7.9753],
      [110.3014, -7.9746],
      [110.3005, -7.9746],
      [110.3004, -7.975],
      [110.2997, -7.9749],
      [110.2994, -7.9751],
      [110.2987, -7.9749],
      [110.2985, -7.9755],
      [110.2972, -7.9753],
      [110.2972, -7.9758],
      [110.2972, -7.9765],
      [110.2971, -7.9772],
      [110.2969, -7.9775],
      [110.2966, -7.9775],
      [110.2966, -7.9778],
      [110.2959, -7.9777],
      [110.2958, -7.978],
      [110.2954, -7.978],
      [110.2953, -7.9782],
      [110.2948, -7.9782],
      [110.2948, -7.9792],
      [110.2944, -7.98],
      [110.2937, -7.9803],
      [110.2924, -7.9806],
      [110.2913, -7.9806],
      [110.2901, -7.9805],
      [110.2901, -7.9806],
      [110.2902, -7.9807],
      [110.2902, -7.9809],
      [110.2901, -7.9822],
      [110.2901, -7.9826],
      [110.29, -7.9835],
      [110.2901, -7.9845],
      [110.2897, -7.9847],
      [110.2886, -7.9876],
      [110.2875, -7.9904],
      [110.2875, -7.9905],
      [110.2885, -7.9908],
      [110.2907, -7.9914],
      [110.2909, -7.9915],
      [110.291, -7.993],
      [110.2907, -7.9931],
      [110.2908, -7.9946],
      [110.2905, -7.9947],
      [110.2903, -7.995],
      [110.2897, -7.9953],
      [110.2897, -7.9955],
      [110.2897, -7.9958],
      [110.2902, -7.9958],
      [110.2911, -7.9956],
      [110.2917, -7.9951],
      [110.2922, -7.9956],
      [110.2932, -7.996],
      [110.2937, -7.9962],
      [110.2937, -7.9966],
      [110.2943, -7.9968],
      [110.2945, -7.9966],
      [110.2947, -7.9962],
      [110.2953, -7.9959],
      [110.2955, -7.9955],
      [110.2961, -7.9951],
      [110.2968, -7.9944],
      [110.2976, -7.995],
      [110.2989, -7.9954],
      [110.2999, -7.9958],
      [110.3012, -7.9959],
      [110.3014, -7.9949],
      [110.3016, -7.9942],
      [110.3022, -7.9941],
      [110.3033, -7.9941],
      [110.3033, -7.9927],
      [110.3037, -7.9925],
      [110.3038, -7.9911],
      [110.3038, -7.9909],
      [110.304, -7.9904],
      [110.3039, -7.99],
      [110.3041, -7.9899],
      [110.3044, -7.9899],
      [110.3049, -7.9894],
      [110.3051, -7.989],
      [110.3057, -7.9888],
      [110.3058, -7.9884],
      [110.306, -7.9875],
      [110.3063, -7.9869],
      [110.3064, -7.9865],
      [110.3066, -7.985],
      [110.3071, -7.9844],
      [110.3074, -7.9839],
      [110.3076, -7.9829]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KRETEK',
    village: 'TIRTOHARGO',
    border: [
      [110.3088, -7.9704],
      [110.308, -7.9701],
      [110.3076, -7.97],
      [110.3058, -7.9693],
      [110.3039, -7.9687],
      [110.3031, -7.9683],
      [110.3016, -7.9676],
      [110.3012, -7.9675],
      [110.3003, -7.967],
      [110.2989, -7.9664],
      [110.2986, -7.9662],
      [110.2983, -7.9661],
      [110.2977, -7.9657],
      [110.2973, -7.9655],
      [110.2958, -7.965],
      [110.2941, -7.9643],
      [110.2926, -7.9637],
      [110.2894, -7.9624],
      [110.2892, -7.9628],
      [110.289, -7.9627],
      [110.2887, -7.9622],
      [110.2871, -7.9615],
      [110.2871, -7.9614],
      [110.2872, -7.9608],
      [110.2869, -7.9604],
      [110.2847, -7.9594],
      [110.285, -7.9585],
      [110.2856, -7.9579],
      [110.2857, -7.9573],
      [110.286, -7.9566],
      [110.2858, -7.9565],
      [110.2854, -7.9564],
      [110.2844, -7.9564],
      [110.2829, -7.9568],
      [110.2817, -7.9572],
      [110.2817, -7.9574],
      [110.2815, -7.9581],
      [110.2815, -7.9582],
      [110.2814, -7.9582],
      [110.2812, -7.9584],
      [110.279, -7.9571],
      [110.278, -7.9568],
      [110.2779, -7.9576],
      [110.2781, -7.9585],
      [110.2783, -7.9593],
      [110.2783, -7.9597],
      [110.2783, -7.9598],
      [110.2781, -7.9605],
      [110.2781, -7.9607],
      [110.2782, -7.9612],
      [110.2788, -7.9617],
      [110.2794, -7.9624],
      [110.2799, -7.9627],
      [110.2799, -7.963],
      [110.2808, -7.9632],
      [110.2816, -7.9637],
      [110.2823, -7.9643],
      [110.2829, -7.9649],
      [110.2835, -7.9653],
      [110.284, -7.9656],
      [110.2852, -7.966],
      [110.2858, -7.9662],
      [110.2858, -7.9666],
      [110.2861, -7.9666],
      [110.2866, -7.9664],
      [110.287, -7.9662],
      [110.287, -7.9662],
      [110.2872, -7.9663],
      [110.2874, -7.9663],
      [110.2877, -7.9664],
      [110.2883, -7.9666],
      [110.2889, -7.9667],
      [110.289, -7.9662],
      [110.2895, -7.9661],
      [110.2897, -7.9658],
      [110.2898, -7.9653],
      [110.2901, -7.9653],
      [110.2906, -7.9655],
      [110.2906, -7.9663],
      [110.2905, -7.9665],
      [110.2901, -7.9674],
      [110.2897, -7.9674],
      [110.2896, -7.9678],
      [110.2899, -7.9679],
      [110.2901, -7.9686],
      [110.2902, -7.9698],
      [110.2901, -7.9704],
      [110.2901, -7.9705],
      [110.2884, -7.9703],
      [110.2876, -7.9702],
      [110.2873, -7.9697],
      [110.2868, -7.9696],
      [110.2865, -7.9703],
      [110.2862, -7.9709],
      [110.286, -7.9709],
      [110.2857, -7.9708],
      [110.2855, -7.9708],
      [110.285, -7.9705],
      [110.2843, -7.9705],
      [110.2846, -7.9711],
      [110.2846, -7.9716],
      [110.2848, -7.9717],
      [110.2852, -7.9719],
      [110.2867, -7.9724],
      [110.287, -7.9731],
      [110.2872, -7.9739],
      [110.2872, -7.9746],
      [110.2874, -7.9751],
      [110.2874, -7.976],
      [110.2883, -7.9761],
      [110.2885, -7.976],
      [110.2888, -7.976],
      [110.2891, -7.9763],
      [110.2891, -7.9772],
      [110.289, -7.9781],
      [110.2893, -7.9782],
      [110.2894, -7.979],
      [110.2895, -7.9792],
      [110.2896, -7.9793],
      [110.2898, -7.9797],
      [110.29, -7.98],
      [110.2901, -7.9805],
      [110.2913, -7.9806],
      [110.2924, -7.9806],
      [110.2937, -7.9803],
      [110.2944, -7.98],
      [110.2948, -7.9792],
      [110.2948, -7.9782],
      [110.2953, -7.9782],
      [110.2954, -7.978],
      [110.2958, -7.978],
      [110.2959, -7.9777],
      [110.2966, -7.9778],
      [110.2966, -7.9775],
      [110.2969, -7.9775],
      [110.2971, -7.9772],
      [110.2972, -7.9765],
      [110.2972, -7.9758],
      [110.2972, -7.9753],
      [110.2985, -7.9755],
      [110.2987, -7.9749],
      [110.2994, -7.9751],
      [110.2997, -7.9749],
      [110.3004, -7.975],
      [110.3005, -7.9746],
      [110.3014, -7.9746],
      [110.3013, -7.9753],
      [110.301, -7.9765],
      [110.301, -7.9768],
      [110.301, -7.9774],
      [110.3007, -7.9789],
      [110.3006, -7.98],
      [110.3007, -7.981],
      [110.3008, -7.9811],
      [110.3014, -7.9812],
      [110.3023, -7.9813],
      [110.3023, -7.9819],
      [110.3023, -7.9826],
      [110.3025, -7.9826],
      [110.304, -7.9828],
      [110.3056, -7.9829],
      [110.3076, -7.9829],
      [110.3078, -7.9829],
      [110.3079, -7.9827],
      [110.3081, -7.9813],
      [110.3077, -7.9797],
      [110.3078, -7.9784],
      [110.3074, -7.9782],
      [110.3072, -7.9781],
      [110.3067, -7.978],
      [110.3069, -7.9772],
      [110.3075, -7.9771],
      [110.3076, -7.9764],
      [110.3088, -7.9761],
      [110.309, -7.9748],
      [110.309, -7.9745],
      [110.3087, -7.9743],
      [110.3084, -7.9739],
      [110.3081, -7.9739],
      [110.3078, -7.9743],
      [110.3074, -7.9742],
      [110.3073, -7.9737],
      [110.3076, -7.9725],
      [110.3079, -7.9711],
      [110.309, -7.9711],
      [110.3091, -7.9705],
      [110.3088, -7.9704]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PUNDONG',
    village: 'SELOHARJO',
    border: [
      [110.3675, -7.9737],
      [110.3673, -7.973],
      [110.367, -7.9718],
      [110.3668, -7.9709],
      [110.3662, -7.97],
      [110.3655, -7.9696],
      [110.3651, -7.9687],
      [110.3646, -7.9673],
      [110.3645, -7.9665],
      [110.3644, -7.9656],
      [110.3645, -7.9649],
      [110.3645, -7.9644],
      [110.3643, -7.964],
      [110.3643, -7.9633],
      [110.3642, -7.9624],
      [110.363, -7.9616],
      [110.3618, -7.9609],
      [110.3605, -7.9605],
      [110.36, -7.9595],
      [110.3598, -7.9584],
      [110.3597, -7.958],
      [110.3583, -7.9582],
      [110.3556, -7.9587],
      [110.3534, -7.9592],
      [110.3527, -7.9596],
      [110.352, -7.9604],
      [110.3516, -7.9612],
      [110.3513, -7.9624],
      [110.3511, -7.9636],
      [110.3506, -7.9645],
      [110.3497, -7.9661],
      [110.3486, -7.9672],
      [110.3473, -7.9679],
      [110.3466, -7.9685],
      [110.3462, -7.9692],
      [110.346, -7.9702],
      [110.3461, -7.971],
      [110.3462, -7.9717],
      [110.3468, -7.9731],
      [110.3469, -7.9741],
      [110.3469, -7.9746],
      [110.3466, -7.9756],
      [110.3459, -7.9762],
      [110.3454, -7.9764],
      [110.3444, -7.9763],
      [110.3436, -7.9761],
      [110.3426, -7.9757],
      [110.3415, -7.9756],
      [110.341, -7.9758],
      [110.3399, -7.976],
      [110.3379, -7.9764],
      [110.3369, -7.9766],
      [110.3352, -7.9768],
      [110.3344, -7.9771],
      [110.3331, -7.9777],
      [110.3325, -7.9781],
      [110.3311, -7.9789],
      [110.33, -7.9795],
      [110.3295, -7.9801],
      [110.329, -7.9809],
      [110.3287, -7.9821],
      [110.3284, -7.9832],
      [110.3281, -7.984],
      [110.328, -7.9841],
      [110.3279, -7.9841],
      [110.3268, -7.9845],
      [110.3257, -7.9843],
      [110.3241, -7.984],
      [110.3229, -7.984],
      [110.3217, -7.9845],
      [110.3205, -7.9853],
      [110.3198, -7.986],
      [110.319, -7.9871],
      [110.3185, -7.9876],
      [110.3186, -7.9879],
      [110.319, -7.9888],
      [110.3196, -7.9902],
      [110.3195, -7.991],
      [110.3196, -7.9917],
      [110.3201, -7.9935],
      [110.3204, -7.995],
      [110.3208, -7.9956],
      [110.3213, -7.9963],
      [110.3222, -7.9983],
      [110.3226, -7.9994],
      [110.3228, -7.9999],
      [110.3229, -8.0004],
      [110.324, -8.0022],
      [110.3248, -8.0028],
      [110.3261, -8.0032],
      [110.3271, -8.0044],
      [110.3283, -8.0058],
      [110.3293, -8.0064],
      [110.3307, -8.0072],
      [110.3306, -8.0062],
      [110.3306, -8.0057],
      [110.3307, -8.0049],
      [110.3309, -8.0041],
      [110.331, -8.0031],
      [110.3312, -8.0025],
      [110.3312, -8.0018],
      [110.3313, -8.0013],
      [110.3315, -8.0007],
      [110.3314, -8.0001],
      [110.3313, -7.9994],
      [110.3315, -7.9987],
      [110.3319, -7.9977],
      [110.3323, -7.9971],
      [110.3326, -7.9967],
      [110.3329, -7.9963],
      [110.3337, -7.9958],
      [110.3342, -7.9955],
      [110.3343, -7.9955],
      [110.3351, -7.9952],
      [110.336, -7.9949],
      [110.3363, -7.9948],
      [110.3368, -7.9943],
      [110.3374, -7.9938],
      [110.338, -7.9935],
      [110.3384, -7.9929],
      [110.3391, -7.9924],
      [110.34, -7.992],
      [110.3405, -7.9917],
      [110.3418, -7.9917],
      [110.3425, -7.9918],
      [110.3427, -7.9918],
      [110.3434, -7.992],
      [110.3455, -7.9926],
      [110.3473, -7.9932],
      [110.3485, -7.9936],
      [110.3489, -7.9937],
      [110.3496, -7.9938],
      [110.3511, -7.994],
      [110.3519, -7.9939],
      [110.3522, -7.9937],
      [110.3525, -7.9932],
      [110.3529, -7.9922],
      [110.3531, -7.9905],
      [110.3533, -7.9888],
      [110.3533, -7.9881],
      [110.3531, -7.9876],
      [110.3529, -7.9876],
      [110.3526, -7.9877],
      [110.3522, -7.988],
      [110.3516, -7.9885],
      [110.351, -7.9891],
      [110.3507, -7.9896],
      [110.3506, -7.9899],
      [110.3499, -7.9895],
      [110.3495, -7.9889],
      [110.3494, -7.9884],
      [110.3495, -7.9881],
      [110.3495, -7.988],
      [110.3497, -7.9871],
      [110.3497, -7.9866],
      [110.3498, -7.9861],
      [110.3501, -7.9858],
      [110.3503, -7.9856],
      [110.3504, -7.9854],
      [110.3504, -7.985],
      [110.3502, -7.9846],
      [110.3502, -7.9843],
      [110.3502, -7.984],
      [110.3504, -7.9839],
      [110.351, -7.9837],
      [110.3515, -7.9835],
      [110.3515, -7.9833],
      [110.3516, -7.983],
      [110.3518, -7.9827],
      [110.352, -7.9822],
      [110.3521, -7.9822],
      [110.3523, -7.9822],
      [110.3524, -7.9818],
      [110.3526, -7.9812],
      [110.3527, -7.9808],
      [110.3524, -7.9804],
      [110.3522, -7.9801],
      [110.352, -7.9799],
      [110.3519, -7.9797],
      [110.3516, -7.9793],
      [110.3512, -7.979],
      [110.351, -7.9788],
      [110.3508, -7.9784],
      [110.3506, -7.9782],
      [110.3503, -7.9782],
      [110.3502, -7.978],
      [110.35, -7.9777],
      [110.3498, -7.9774],
      [110.3493, -7.9772],
      [110.349, -7.977],
      [110.3489, -7.9766],
      [110.3487, -7.9762],
      [110.3484, -7.9759],
      [110.3481, -7.9758],
      [110.348, -7.9753],
      [110.348, -7.9749],
      [110.348, -7.9745],
      [110.348, -7.9742],
      [110.3482, -7.9741],
      [110.3485, -7.9743],
      [110.3488, -7.9743],
      [110.3492, -7.9742],
      [110.3496, -7.9743],
      [110.3501, -7.9743],
      [110.3504, -7.9744],
      [110.3506, -7.9751],
      [110.3508, -7.9753],
      [110.3512, -7.9762],
      [110.3513, -7.9764],
      [110.3515, -7.9767],
      [110.3517, -7.977],
      [110.3519, -7.9773],
      [110.3524, -7.9774],
      [110.3526, -7.9776],
      [110.3527, -7.9781],
      [110.3528, -7.9784],
      [110.3532, -7.9785],
      [110.3536, -7.9784],
      [110.3537, -7.9784],
      [110.3542, -7.9784],
      [110.3546, -7.9786],
      [110.3549, -7.9786],
      [110.355, -7.9784],
      [110.355, -7.9783],
      [110.3551, -7.9781],
      [110.3553, -7.9781],
      [110.3554, -7.9782],
      [110.3556, -7.9784],
      [110.3556, -7.9787],
      [110.3558, -7.979],
      [110.3559, -7.9791],
      [110.3559, -7.9794],
      [110.3558, -7.9795],
      [110.3559, -7.9797],
      [110.3562, -7.9797],
      [110.3565, -7.98],
      [110.357, -7.9805],
      [110.3573, -7.9806],
      [110.3576, -7.9807],
      [110.3579, -7.9815],
      [110.3581, -7.9818],
      [110.3584, -7.982],
      [110.3588, -7.9821],
      [110.3596, -7.9823],
      [110.3605, -7.9824],
      [110.3611, -7.9823],
      [110.3611, -7.9823],
      [110.3619, -7.9821],
      [110.3625, -7.9819],
      [110.363, -7.9815],
      [110.3631, -7.9798],
      [110.3632, -7.9792],
      [110.3633, -7.9786],
      [110.3633, -7.9776],
      [110.3637, -7.9772],
      [110.3641, -7.9768],
      [110.3641, -7.9757],
      [110.3642, -7.9749],
      [110.3646, -7.9741],
      [110.3648, -7.9734],
      [110.3652, -7.9727],
      [110.3656, -7.9724],
      [110.3665, -7.9726],
      [110.3671, -7.9732],
      [110.3675, -7.9737]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PUNDONG',
    village: 'PANJANGREJO',
    border: [
      [110.346, -7.9702],
      [110.3456, -7.9702],
      [110.3451, -7.9703],
      [110.3449, -7.9703],
      [110.3437, -7.9709],
      [110.3436, -7.9714],
      [110.3426, -7.9712],
      [110.3409, -7.971],
      [110.3382, -7.9705],
      [110.3376, -7.9704],
      [110.3377, -7.9689],
      [110.3379, -7.9666],
      [110.3384, -7.966],
      [110.3386, -7.9658],
      [110.3387, -7.9647],
      [110.3389, -7.9625],
      [110.3391, -7.9606],
      [110.3382, -7.9605],
      [110.3382, -7.9603],
      [110.3385, -7.9575],
      [110.3387, -7.9564],
      [110.3374, -7.9563],
      [110.3376, -7.9549],
      [110.3376, -7.9547],
      [110.3378, -7.9528],
      [110.3372, -7.9524],
      [110.3373, -7.9518],
      [110.3376, -7.9507],
      [110.3376, -7.95],
      [110.3373, -7.9494],
      [110.3374, -7.9487],
      [110.3362, -7.9485],
      [110.336, -7.9494],
      [110.3356, -7.9494],
      [110.3346, -7.9494],
      [110.3332, -7.949],
      [110.3324, -7.9489],
      [110.3308, -7.9485],
      [110.329, -7.9481],
      [110.3285, -7.948],
      [110.3278, -7.9493],
      [110.3272, -7.9507],
      [110.3258, -7.9535],
      [110.3266, -7.9539],
      [110.3265, -7.954],
      [110.3262, -7.9545],
      [110.3252, -7.9572],
      [110.3242, -7.957],
      [110.3239, -7.9573],
      [110.3236, -7.9581],
      [110.3234, -7.9585],
      [110.323, -7.9593],
      [110.323, -7.9594],
      [110.3232, -7.9596],
      [110.3233, -7.9602],
      [110.3229, -7.9609],
      [110.3226, -7.9617],
      [110.3226, -7.9624],
      [110.3223, -7.9631],
      [110.3221, -7.9643],
      [110.3219, -7.9649],
      [110.3217, -7.9653],
      [110.3215, -7.966],
      [110.3214, -7.9664],
      [110.3218, -7.9672],
      [110.3216, -7.9677],
      [110.3218, -7.9681],
      [110.3224, -7.9682],
      [110.323, -7.9681],
      [110.3243, -7.9683],
      [110.3258, -7.9685],
      [110.3256, -7.9705],
      [110.3254, -7.9719],
      [110.3253, -7.9735],
      [110.3254, -7.9735],
      [110.3262, -7.9736],
      [110.3264, -7.9743],
      [110.3264, -7.9747],
      [110.3261, -7.9749],
      [110.3262, -7.9753],
      [110.3277, -7.9757],
      [110.3283, -7.9766],
      [110.3287, -7.9765],
      [110.3288, -7.977],
      [110.3288, -7.9774],
      [110.3289, -7.9784],
      [110.3295, -7.979],
      [110.3299, -7.9793],
      [110.33, -7.9795],
      [110.3311, -7.9789],
      [110.3325, -7.9781],
      [110.3331, -7.9777],
      [110.3344, -7.9771],
      [110.3352, -7.9768],
      [110.3369, -7.9766],
      [110.3379, -7.9764],
      [110.3399, -7.976],
      [110.341, -7.9758],
      [110.3415, -7.9756],
      [110.3426, -7.9757],
      [110.3436, -7.9761],
      [110.3444, -7.9763],
      [110.3454, -7.9764],
      [110.3459, -7.9762],
      [110.3466, -7.9756],
      [110.3469, -7.9746],
      [110.3469, -7.9741],
      [110.3468, -7.9731],
      [110.3462, -7.9717],
      [110.3461, -7.971],
      [110.346, -7.9702]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PUNDONG',
    village: 'SRIHARDONO',
    border: [
      [110.3497, -7.9447],
      [110.3485, -7.9449],
      [110.3474, -7.945],
      [110.3459, -7.945],
      [110.3457, -7.9449],
      [110.3446, -7.9449],
      [110.3441, -7.9448],
      [110.3423, -7.9446],
      [110.3424, -7.944],
      [110.3421, -7.944],
      [110.3416, -7.9439],
      [110.3414, -7.9436],
      [110.341, -7.9435],
      [110.3404, -7.9437],
      [110.34, -7.9442],
      [110.3394, -7.9441],
      [110.3395, -7.9437],
      [110.3398, -7.9432],
      [110.3404, -7.9429],
      [110.3412, -7.9427],
      [110.341, -7.9422],
      [110.3409, -7.9416],
      [110.3405, -7.9413],
      [110.3405, -7.9408],
      [110.3403, -7.9403],
      [110.3394, -7.9401],
      [110.3396, -7.9393],
      [110.3384, -7.939],
      [110.3391, -7.938],
      [110.3388, -7.9378],
      [110.3386, -7.9377],
      [110.338, -7.9376],
      [110.3377, -7.9377],
      [110.3372, -7.9378],
      [110.3366, -7.9382],
      [110.3355, -7.9387],
      [110.3353, -7.9392],
      [110.335, -7.9402],
      [110.3343, -7.9401],
      [110.3341, -7.941],
      [110.3334, -7.9418],
      [110.3322, -7.9403],
      [110.3321, -7.9404],
      [110.332, -7.9408],
      [110.3316, -7.9416],
      [110.3311, -7.9427],
      [110.331, -7.9429],
      [110.3296, -7.9456],
      [110.3285, -7.948],
      [110.329, -7.9481],
      [110.3308, -7.9485],
      [110.3324, -7.9489],
      [110.3332, -7.949],
      [110.3346, -7.9494],
      [110.3356, -7.9494],
      [110.336, -7.9494],
      [110.3362, -7.9485],
      [110.3374, -7.9487],
      [110.3373, -7.9494],
      [110.3376, -7.95],
      [110.3376, -7.9507],
      [110.3373, -7.9518],
      [110.3372, -7.9524],
      [110.3378, -7.9528],
      [110.3376, -7.9547],
      [110.3376, -7.9549],
      [110.3374, -7.9563],
      [110.3387, -7.9564],
      [110.3385, -7.9575],
      [110.3382, -7.9603],
      [110.3382, -7.9605],
      [110.3391, -7.9606],
      [110.3389, -7.9625],
      [110.3387, -7.9647],
      [110.3386, -7.9658],
      [110.3384, -7.966],
      [110.3379, -7.9666],
      [110.3377, -7.9689],
      [110.3376, -7.9704],
      [110.3382, -7.9705],
      [110.3409, -7.971],
      [110.3426, -7.9712],
      [110.3436, -7.9714],
      [110.3437, -7.9709],
      [110.3449, -7.9703],
      [110.3451, -7.9703],
      [110.3456, -7.9702],
      [110.346, -7.9702],
      [110.3462, -7.9692],
      [110.3466, -7.9685],
      [110.3473, -7.9679],
      [110.3486, -7.9672],
      [110.3497, -7.9661],
      [110.3506, -7.9645],
      [110.3511, -7.9636],
      [110.3513, -7.9624],
      [110.3516, -7.9612],
      [110.352, -7.9604],
      [110.3527, -7.9596],
      [110.3534, -7.9592],
      [110.3556, -7.9587],
      [110.3583, -7.9582],
      [110.3597, -7.958],
      [110.36, -7.9577],
      [110.3608, -7.9574],
      [110.3616, -7.9572],
      [110.3625, -7.9569],
      [110.3645, -7.9562],
      [110.3651, -7.9558],
      [110.3656, -7.9551],
      [110.3659, -7.9537],
      [110.3658, -7.9519],
      [110.3655, -7.9498],
      [110.3654, -7.949],
      [110.3651, -7.9477],
      [110.3651, -7.9469],
      [110.3655, -7.9461],
      [110.3662, -7.9454],
      [110.3659, -7.9451],
      [110.3658, -7.945],
      [110.3649, -7.945],
      [110.3637, -7.9447],
      [110.3631, -7.9445],
      [110.3629, -7.9443],
      [110.3621, -7.9441],
      [110.362, -7.9443],
      [110.362, -7.9445],
      [110.3593, -7.9446],
      [110.3591, -7.9447],
      [110.3569, -7.9448],
      [110.3565, -7.9448],
      [110.3564, -7.9448],
      [110.3561, -7.9448],
      [110.3554, -7.9447],
      [110.3545, -7.9447],
      [110.3541, -7.9452],
      [110.354, -7.9453],
      [110.3536, -7.9459],
      [110.3524, -7.946],
      [110.3521, -7.946],
      [110.3511, -7.9459],
      [110.3507, -7.9459],
      [110.3503, -7.9461],
      [110.3501, -7.9462],
      [110.3497, -7.9447]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BAMBANGLIPURO',
    village: 'SIDOMULYO',
    border: [
      [110.2997, -7.9411],
      [110.2992, -7.9412],
      [110.299, -7.9403],
      [110.2987, -7.94],
      [110.2982, -7.9402],
      [110.2976, -7.9399],
      [110.2971, -7.9393],
      [110.2966, -7.9394],
      [110.296, -7.9395],
      [110.2955, -7.9395],
      [110.2948, -7.9393],
      [110.2943, -7.9394],
      [110.2939, -7.9395],
      [110.2927, -7.9391],
      [110.2924, -7.9399],
      [110.2923, -7.9407],
      [110.292, -7.9413],
      [110.2916, -7.9413],
      [110.2913, -7.9427],
      [110.2906, -7.9428],
      [110.2903, -7.9441],
      [110.2901, -7.9447],
      [110.2892, -7.9445],
      [110.2888, -7.9443],
      [110.2883, -7.9444],
      [110.288, -7.9447],
      [110.2878, -7.945],
      [110.2872, -7.9449],
      [110.2868, -7.9446],
      [110.2864, -7.9444],
      [110.2857, -7.9444],
      [110.2854, -7.9444],
      [110.2852, -7.9444],
      [110.2846, -7.9443],
      [110.2839, -7.9442],
      [110.2834, -7.9442],
      [110.2827, -7.9441],
      [110.2826, -7.9444],
      [110.2824, -7.9446],
      [110.2821, -7.9449],
      [110.282, -7.9456],
      [110.2818, -7.9463],
      [110.281, -7.9463],
      [110.2809, -7.9476],
      [110.2816, -7.9478],
      [110.2817, -7.9482],
      [110.2818, -7.9492],
      [110.2818, -7.95],
      [110.2819, -7.9505],
      [110.2822, -7.9507],
      [110.2825, -7.951],
      [110.2824, -7.9517],
      [110.2826, -7.9525],
      [110.2832, -7.9524],
      [110.2831, -7.9532],
      [110.2834, -7.9537],
      [110.283, -7.954],
      [110.2826, -7.9542],
      [110.2822, -7.9543],
      [110.2815, -7.9543],
      [110.2808, -7.9541],
      [110.2806, -7.9547],
      [110.2806, -7.9548],
      [110.2817, -7.9572],
      [110.2829, -7.9568],
      [110.2844, -7.9564],
      [110.2854, -7.9564],
      [110.2858, -7.9565],
      [110.286, -7.9566],
      [110.2857, -7.9573],
      [110.2856, -7.9579],
      [110.285, -7.9585],
      [110.2847, -7.9594],
      [110.2869, -7.9604],
      [110.2872, -7.9608],
      [110.2871, -7.9614],
      [110.2871, -7.9615],
      [110.2887, -7.9622],
      [110.289, -7.9627],
      [110.2892, -7.9628],
      [110.2894, -7.9624],
      [110.2926, -7.9637],
      [110.2941, -7.9643],
      [110.2958, -7.965],
      [110.2973, -7.9655],
      [110.2977, -7.9657],
      [110.2983, -7.9661],
      [110.2986, -7.9662],
      [110.2989, -7.9664],
      [110.3003, -7.967],
      [110.3012, -7.9675],
      [110.3016, -7.9676],
      [110.3031, -7.9683],
      [110.3039, -7.9687],
      [110.3058, -7.9693],
      [110.3076, -7.97],
      [110.308, -7.9701],
      [110.3088, -7.9704],
      [110.3091, -7.9705],
      [110.3109, -7.971],
      [110.3124, -7.9713],
      [110.3138, -7.9716],
      [110.3149, -7.9717],
      [110.315, -7.9717],
      [110.3154, -7.9718],
      [110.3156, -7.9718],
      [110.3162, -7.9719],
      [110.317, -7.972],
      [110.3172, -7.972],
      [110.3183, -7.9721],
      [110.3187, -7.9722],
      [110.3202, -7.9723],
      [110.3206, -7.9724],
      [110.3217, -7.9729],
      [110.3225, -7.9728],
      [110.3233, -7.973],
      [110.3239, -7.9732],
      [110.3253, -7.9735],
      [110.3254, -7.9719],
      [110.3256, -7.9705],
      [110.3258, -7.9685],
      [110.3243, -7.9683],
      [110.323, -7.9681],
      [110.3224, -7.9682],
      [110.3218, -7.9681],
      [110.3216, -7.9677],
      [110.3218, -7.9672],
      [110.3214, -7.9664],
      [110.3215, -7.966],
      [110.3217, -7.9653],
      [110.3212, -7.9653],
      [110.3205, -7.9652],
      [110.3202, -7.9652],
      [110.3195, -7.9649],
      [110.3184, -7.9648],
      [110.3184, -7.9656],
      [110.3184, -7.9658],
      [110.3173, -7.9655],
      [110.3167, -7.9669],
      [110.3159, -7.9668],
      [110.3158, -7.9668],
      [110.3163, -7.9642],
      [110.3146, -7.9636],
      [110.314, -7.9636],
      [110.3137, -7.9636],
      [110.3133, -7.9633],
      [110.3133, -7.9628],
      [110.3136, -7.9627],
      [110.3137, -7.9624],
      [110.3137, -7.9616],
      [110.3136, -7.961],
      [110.3131, -7.9608],
      [110.3125, -7.9605],
      [110.3114, -7.9603],
      [110.3107, -7.9601],
      [110.3105, -7.9598],
      [110.3107, -7.9592],
      [110.3108, -7.9587],
      [110.3107, -7.958],
      [110.3105, -7.9571],
      [110.3106, -7.9569],
      [110.3108, -7.9569],
      [110.3112, -7.9569],
      [110.3114, -7.9568],
      [110.3115, -7.9562],
      [110.3116, -7.9554],
      [110.3118, -7.9549],
      [110.312, -7.9544],
      [110.3119, -7.9538],
      [110.3121, -7.9533],
      [110.3121, -7.9531],
      [110.3111, -7.9526],
      [110.3107, -7.9525],
      [110.3105, -7.9525],
      [110.3097, -7.9518],
      [110.3091, -7.9515],
      [110.3079, -7.9511],
      [110.307, -7.9508],
      [110.3069, -7.9505],
      [110.3066, -7.9504],
      [110.3066, -7.9501],
      [110.306, -7.9499],
      [110.3049, -7.9499],
      [110.3045, -7.95],
      [110.3028, -7.9499],
      [110.3021, -7.9497],
      [110.3021, -7.9491],
      [110.3021, -7.9485],
      [110.302, -7.948],
      [110.3016, -7.9476],
      [110.3011, -7.9475],
      [110.3005, -7.9472],
      [110.2999, -7.9483],
      [110.2992, -7.9481],
      [110.2992, -7.9464],
      [110.2993, -7.9448],
      [110.2993, -7.944],
      [110.2993, -7.9435],
      [110.2995, -7.9426],
      [110.2996, -7.9417],
      [110.2997, -7.9411]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BAMBANGLIPURO',
    village: 'MULYODADI',
    border: [
      [110.3321, -7.9404],
      [110.3319, -7.9405],
      [110.3317, -7.9405],
      [110.3314, -7.9405],
      [110.3304, -7.9405],
      [110.3304, -7.9402],
      [110.3294, -7.94],
      [110.3289, -7.9388],
      [110.3281, -7.9388],
      [110.3277, -7.9402],
      [110.3275, -7.9418],
      [110.3275, -7.942],
      [110.3278, -7.9422],
      [110.3274, -7.9427],
      [110.327, -7.9432],
      [110.3268, -7.9432],
      [110.3264, -7.9431],
      [110.3263, -7.9424],
      [110.3259, -7.9424],
      [110.3257, -7.9428],
      [110.3251, -7.9426],
      [110.3247, -7.9424],
      [110.3247, -7.9417],
      [110.3247, -7.9413],
      [110.3246, -7.9409],
      [110.3244, -7.9408],
      [110.3244, -7.9405],
      [110.3236, -7.9408],
      [110.3226, -7.9408],
      [110.3225, -7.9401],
      [110.3221, -7.9374],
      [110.3219, -7.936],
      [110.3217, -7.9352],
      [110.3217, -7.9344],
      [110.3217, -7.9334],
      [110.3216, -7.9321],
      [110.3206, -7.9316],
      [110.32, -7.9314],
      [110.32, -7.9306],
      [110.318, -7.9303],
      [110.3181, -7.9321],
      [110.3185, -7.9324],
      [110.3187, -7.9326],
      [110.3188, -7.9332],
      [110.3194, -7.9333],
      [110.3195, -7.9342],
      [110.32, -7.9343],
      [110.3203, -7.9345],
      [110.3198, -7.935],
      [110.3196, -7.9351],
      [110.3188, -7.9348],
      [110.3187, -7.9354],
      [110.3192, -7.9359],
      [110.3193, -7.9366],
      [110.3193, -7.9371],
      [110.3189, -7.9374],
      [110.3189, -7.938],
      [110.3189, -7.9382],
      [110.3189, -7.9384],
      [110.3171, -7.938],
      [110.3172, -7.94],
      [110.3168, -7.9405],
      [110.3162, -7.9409],
      [110.3156, -7.9408],
      [110.3154, -7.9401],
      [110.3152, -7.9401],
      [110.315, -7.9409],
      [110.3146, -7.9416],
      [110.3146, -7.9417],
      [110.3136, -7.9416],
      [110.3132, -7.9417],
      [110.3132, -7.9424],
      [110.3126, -7.9423],
      [110.3126, -7.9428],
      [110.312, -7.9428],
      [110.3119, -7.943],
      [110.3115, -7.9431],
      [110.3112, -7.9432],
      [110.3109, -7.9432],
      [110.3109, -7.9429],
      [110.3088, -7.9429],
      [110.309, -7.9415],
      [110.3085, -7.9414],
      [110.3087, -7.9398],
      [110.3082, -7.9395],
      [110.3078, -7.9373],
      [110.3061, -7.9369],
      [110.3051, -7.9368],
      [110.3048, -7.9368],
      [110.3044, -7.9368],
      [110.3033, -7.9383],
      [110.3031, -7.9382],
      [110.3022, -7.9379],
      [110.3021, -7.9379],
      [110.3012, -7.9376],
      [110.3009, -7.9381],
      [110.3004, -7.9382],
      [110.3003, -7.9389],
      [110.3002, -7.9392],
      [110.3, -7.9396],
      [110.3, -7.9403],
      [110.2999, -7.9411],
      [110.2997, -7.9411],
      [110.2996, -7.9417],
      [110.2995, -7.9426],
      [110.2993, -7.9435],
      [110.2993, -7.944],
      [110.2993, -7.9448],
      [110.2992, -7.9464],
      [110.2992, -7.9481],
      [110.2999, -7.9483],
      [110.3005, -7.9472],
      [110.3011, -7.9475],
      [110.3016, -7.9476],
      [110.302, -7.948],
      [110.3021, -7.9485],
      [110.3021, -7.9491],
      [110.3021, -7.9497],
      [110.3028, -7.9499],
      [110.3045, -7.95],
      [110.3049, -7.9499],
      [110.306, -7.9499],
      [110.3066, -7.9501],
      [110.3066, -7.9504],
      [110.3069, -7.9505],
      [110.307, -7.9508],
      [110.3079, -7.9511],
      [110.3091, -7.9515],
      [110.3097, -7.9518],
      [110.3105, -7.9525],
      [110.3107, -7.9525],
      [110.3111, -7.9526],
      [110.3121, -7.9531],
      [110.3121, -7.9533],
      [110.3119, -7.9538],
      [110.312, -7.9544],
      [110.3118, -7.9549],
      [110.3116, -7.9554],
      [110.3115, -7.9562],
      [110.3114, -7.9568],
      [110.3112, -7.9569],
      [110.3108, -7.9569],
      [110.3106, -7.9569],
      [110.3105, -7.9571],
      [110.3107, -7.958],
      [110.3108, -7.9587],
      [110.3107, -7.9592],
      [110.3105, -7.9598],
      [110.3107, -7.9601],
      [110.3114, -7.9603],
      [110.3125, -7.9605],
      [110.3131, -7.9608],
      [110.3136, -7.961],
      [110.3137, -7.9616],
      [110.3137, -7.9624],
      [110.3136, -7.9627],
      [110.3133, -7.9628],
      [110.3133, -7.9633],
      [110.3137, -7.9636],
      [110.314, -7.9636],
      [110.3146, -7.9636],
      [110.3163, -7.9642],
      [110.3158, -7.9668],
      [110.3159, -7.9668],
      [110.3167, -7.9669],
      [110.3173, -7.9655],
      [110.3184, -7.9658],
      [110.3184, -7.9656],
      [110.3184, -7.9648],
      [110.3195, -7.9649],
      [110.3202, -7.9652],
      [110.3205, -7.9652],
      [110.3212, -7.9653],
      [110.3217, -7.9653],
      [110.3219, -7.9649],
      [110.3221, -7.9643],
      [110.3223, -7.9631],
      [110.3226, -7.9624],
      [110.3226, -7.9617],
      [110.3229, -7.9609],
      [110.3233, -7.9602],
      [110.3232, -7.9596],
      [110.323, -7.9594],
      [110.323, -7.9593],
      [110.3234, -7.9585],
      [110.3236, -7.9581],
      [110.3239, -7.9573],
      [110.3242, -7.957],
      [110.3252, -7.9572],
      [110.3262, -7.9545],
      [110.3265, -7.954],
      [110.3266, -7.9539],
      [110.3258, -7.9535],
      [110.3272, -7.9507],
      [110.3278, -7.9493],
      [110.3284, -7.948],
      [110.3296, -7.9456],
      [110.331, -7.9429],
      [110.3311, -7.9427],
      [110.3316, -7.9416],
      [110.332, -7.9408],
      [110.3321, -7.9404]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BAMBANGLIPURO',
    village: 'SUMBERMULYO',
    border: [
      [110.3422, -7.9182],
      [110.3414, -7.9178],
      [110.3409, -7.9178],
      [110.3407, -7.9175],
      [110.3404, -7.9172],
      [110.3398, -7.9171],
      [110.3395, -7.917],
      [110.3395, -7.9166],
      [110.3393, -7.9162],
      [110.3388, -7.9161],
      [110.3384, -7.9164],
      [110.3383, -7.917],
      [110.338, -7.9176],
      [110.3377, -7.9184],
      [110.3375, -7.9182],
      [110.3369, -7.9182],
      [110.3365, -7.9184],
      [110.3362, -7.9182],
      [110.3356, -7.9182],
      [110.3354, -7.9179],
      [110.3345, -7.9177],
      [110.3342, -7.9178],
      [110.3338, -7.9179],
      [110.3335, -7.9181],
      [110.3333, -7.9183],
      [110.332, -7.919],
      [110.3312, -7.919],
      [110.3304, -7.919],
      [110.3294, -7.919],
      [110.3293, -7.919],
      [110.3282, -7.92],
      [110.3276, -7.9201],
      [110.3272, -7.9204],
      [110.3266, -7.9213],
      [110.3268, -7.9206],
      [110.3268, -7.9193],
      [110.3268, -7.9189],
      [110.3254, -7.9188],
      [110.3233, -7.9187],
      [110.3229, -7.9188],
      [110.3223, -7.9188],
      [110.3217, -7.9187],
      [110.3206, -7.9183],
      [110.3192, -7.918],
      [110.3182, -7.9178],
      [110.3182, -7.9184],
      [110.3184, -7.9186],
      [110.3183, -7.9191],
      [110.3178, -7.9191],
      [110.3173, -7.9188],
      [110.3171, -7.9177],
      [110.3166, -7.9176],
      [110.3161, -7.9176],
      [110.316, -7.9181],
      [110.316, -7.9198],
      [110.3158, -7.9201],
      [110.3155, -7.9201],
      [110.315, -7.9183],
      [110.315, -7.9183],
      [110.3145, -7.9191],
      [110.3139, -7.9205],
      [110.3133, -7.9215],
      [110.3132, -7.9215],
      [110.3129, -7.9215],
      [110.3113, -7.9209],
      [110.3101, -7.9206],
      [110.3092, -7.9205],
      [110.3082, -7.9203],
      [110.3075, -7.921],
      [110.3072, -7.9214],
      [110.3071, -7.922],
      [110.3069, -7.9225],
      [110.3067, -7.9228],
      [110.3076, -7.9234],
      [110.3073, -7.9237],
      [110.3066, -7.9233],
      [110.3058, -7.9232],
      [110.3047, -7.9229],
      [110.3042, -7.9232],
      [110.304, -7.9231],
      [110.3038, -7.9244],
      [110.3035, -7.9244],
      [110.303, -7.9259],
      [110.3029, -7.9265],
      [110.3034, -7.9266],
      [110.3031, -7.9272],
      [110.3029, -7.9279],
      [110.3026, -7.9285],
      [110.3023, -7.929],
      [110.3024, -7.9293],
      [110.3023, -7.9299],
      [110.3021, -7.931],
      [110.3032, -7.9315],
      [110.3044, -7.93],
      [110.3055, -7.93],
      [110.3055, -7.931],
      [110.3067, -7.9313],
      [110.3065, -7.9325],
      [110.3066, -7.9327],
      [110.3061, -7.9334],
      [110.3051, -7.9348],
      [110.3041, -7.9347],
      [110.3033, -7.9346],
      [110.3031, -7.9346],
      [110.3021, -7.9379],
      [110.3022, -7.9379],
      [110.3031, -7.9382],
      [110.3033, -7.9383],
      [110.3044, -7.9368],
      [110.3048, -7.9368],
      [110.3051, -7.9368],
      [110.3061, -7.9369],
      [110.3078, -7.9373],
      [110.3082, -7.9395],
      [110.3087, -7.9398],
      [110.3085, -7.9414],
      [110.309, -7.9415],
      [110.3088, -7.9429],
      [110.3109, -7.9429],
      [110.3109, -7.9432],
      [110.3112, -7.9432],
      [110.3115, -7.9431],
      [110.3119, -7.943],
      [110.312, -7.9428],
      [110.3126, -7.9428],
      [110.3126, -7.9423],
      [110.3132, -7.9424],
      [110.3132, -7.9417],
      [110.3136, -7.9416],
      [110.3146, -7.9417],
      [110.3146, -7.9416],
      [110.315, -7.9409],
      [110.3152, -7.9401],
      [110.3154, -7.9401],
      [110.3156, -7.9408],
      [110.3162, -7.9409],
      [110.3168, -7.9405],
      [110.3172, -7.94],
      [110.3171, -7.938],
      [110.3189, -7.9384],
      [110.3189, -7.9382],
      [110.3189, -7.938],
      [110.3189, -7.9374],
      [110.3193, -7.9371],
      [110.3193, -7.9366],
      [110.3192, -7.9359],
      [110.3187, -7.9354],
      [110.3188, -7.9348],
      [110.3196, -7.9351],
      [110.3198, -7.935],
      [110.3203, -7.9345],
      [110.32, -7.9343],
      [110.3195, -7.9342],
      [110.3194, -7.9333],
      [110.3188, -7.9332],
      [110.3187, -7.9326],
      [110.3185, -7.9324],
      [110.3181, -7.9321],
      [110.318, -7.9303],
      [110.32, -7.9306],
      [110.32, -7.9314],
      [110.3206, -7.9316],
      [110.3216, -7.9321],
      [110.3217, -7.9334],
      [110.3217, -7.9344],
      [110.3217, -7.9352],
      [110.3219, -7.936],
      [110.3221, -7.9374],
      [110.3225, -7.9401],
      [110.3226, -7.9408],
      [110.3236, -7.9408],
      [110.3244, -7.9405],
      [110.3244, -7.9408],
      [110.3246, -7.9409],
      [110.3247, -7.9413],
      [110.3247, -7.9417],
      [110.3247, -7.9424],
      [110.3251, -7.9426],
      [110.3257, -7.9428],
      [110.3259, -7.9424],
      [110.3263, -7.9424],
      [110.3264, -7.9431],
      [110.3268, -7.9432],
      [110.327, -7.9432],
      [110.3274, -7.9427],
      [110.3278, -7.9422],
      [110.3275, -7.942],
      [110.3275, -7.9418],
      [110.3277, -7.9402],
      [110.3281, -7.9388],
      [110.3289, -7.9388],
      [110.3294, -7.94],
      [110.3304, -7.9402],
      [110.3304, -7.9405],
      [110.3314, -7.9405],
      [110.3317, -7.9405],
      [110.3319, -7.9405],
      [110.3321, -7.9404],
      [110.3322, -7.9403],
      [110.3321, -7.9401],
      [110.3322, -7.9398],
      [110.3324, -7.9389],
      [110.3327, -7.9381],
      [110.3328, -7.9378],
      [110.333, -7.9377],
      [110.3332, -7.9377],
      [110.3336, -7.9375],
      [110.3337, -7.9374],
      [110.3339, -7.9372],
      [110.334, -7.9368],
      [110.334, -7.9364],
      [110.334, -7.9363],
      [110.334, -7.9357],
      [110.334, -7.9352],
      [110.3341, -7.9351],
      [110.3342, -7.935],
      [110.3347, -7.9349],
      [110.3348, -7.9348],
      [110.3351, -7.9346],
      [110.3354, -7.9339],
      [110.3356, -7.9327],
      [110.3358, -7.9322],
      [110.3362, -7.9317],
      [110.3365, -7.9312],
      [110.3369, -7.931],
      [110.3372, -7.9303],
      [110.3377, -7.9303],
      [110.3379, -7.9297],
      [110.3389, -7.9279],
      [110.3395, -7.9265],
      [110.3396, -7.9257],
      [110.3404, -7.9256],
      [110.3406, -7.9248],
      [110.3408, -7.9243],
      [110.3413, -7.9242],
      [110.3421, -7.9242],
      [110.3428, -7.9243],
      [110.3441, -7.9244],
      [110.3445, -7.9245],
      [110.3448, -7.9245],
      [110.3451, -7.9249],
      [110.3453, -7.9249],
      [110.3458, -7.9247],
      [110.346, -7.9243],
      [110.3465, -7.9236],
      [110.3469, -7.9231],
      [110.3473, -7.9225],
      [110.3476, -7.9218],
      [110.3478, -7.9207],
      [110.3479, -7.9199],
      [110.3478, -7.9195],
      [110.3474, -7.9193],
      [110.3469, -7.9191],
      [110.3468, -7.9192],
      [110.3467, -7.9196],
      [110.3467, -7.9201],
      [110.3464, -7.9202],
      [110.3462, -7.9207],
      [110.346, -7.9213],
      [110.3452, -7.9211],
      [110.3439, -7.9209],
      [110.3436, -7.9202],
      [110.3432, -7.92],
      [110.3429, -7.9199],
      [110.3427, -7.9198],
      [110.3425, -7.9197],
      [110.3418, -7.9197],
      [110.3418, -7.9196],
      [110.3418, -7.9192],
      [110.3421, -7.9188],
      [110.3422, -7.9182]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PANDAK',
    village: 'CATUHARJO',
    border: [
      [110.282, -7.9456],
      [110.2817, -7.9455],
      [110.2806, -7.945],
      [110.2797, -7.9442],
      [110.2802, -7.9437],
      [110.2806, -7.9428],
      [110.2801, -7.9423],
      [110.2804, -7.9414],
      [110.2798, -7.9407],
      [110.2792, -7.9406],
      [110.2789, -7.9403],
      [110.279, -7.9399],
      [110.278, -7.9394],
      [110.2782, -7.9389],
      [110.2774, -7.9386],
      [110.2762, -7.9383],
      [110.2758, -7.9381],
      [110.2757, -7.9375],
      [110.275, -7.9372],
      [110.274, -7.9369],
      [110.2737, -7.9367],
      [110.2725, -7.9361],
      [110.2733, -7.9342],
      [110.2734, -7.9332],
      [110.2733, -7.932],
      [110.2731, -7.9318],
      [110.2719, -7.9321],
      [110.271, -7.9323],
      [110.2699, -7.9323],
      [110.2697, -7.9326],
      [110.2687, -7.9324],
      [110.2685, -7.9326],
      [110.2678, -7.9325],
      [110.2678, -7.9332],
      [110.2677, -7.9341],
      [110.2673, -7.935],
      [110.2667, -7.9359],
      [110.2666, -7.9364],
      [110.2668, -7.9379],
      [110.2669, -7.9397],
      [110.2665, -7.9397],
      [110.2657, -7.9398],
      [110.2655, -7.9398],
      [110.2645, -7.94],
      [110.2647, -7.9397],
      [110.265, -7.939],
      [110.2651, -7.9385],
      [110.2648, -7.9379],
      [110.2645, -7.9375],
      [110.2647, -7.9367],
      [110.2646, -7.9364],
      [110.2641, -7.9363],
      [110.2638, -7.9361],
      [110.2635, -7.9361],
      [110.2636, -7.9351],
      [110.2628, -7.9351],
      [110.2631, -7.9325],
      [110.2628, -7.9319],
      [110.2626, -7.9319],
      [110.2618, -7.9322],
      [110.2607, -7.9322],
      [110.2605, -7.9351],
      [110.2589, -7.9362],
      [110.2567, -7.9379],
      [110.2555, -7.9382],
      [110.2554, -7.9386],
      [110.2552, -7.9395],
      [110.2552, -7.9402],
      [110.2547, -7.9404],
      [110.2544, -7.9406],
      [110.2544, -7.9408],
      [110.2543, -7.9412],
      [110.2545, -7.9417],
      [110.2546, -7.9421],
      [110.2551, -7.9421],
      [110.2556, -7.9423],
      [110.2564, -7.9428],
      [110.2569, -7.9428],
      [110.2573, -7.943],
      [110.2579, -7.9434],
      [110.2586, -7.9437],
      [110.2595, -7.9439],
      [110.2593, -7.9459],
      [110.2591, -7.9468],
      [110.2592, -7.9469],
      [110.2595, -7.9472],
      [110.2605, -7.9474],
      [110.2611, -7.9476],
      [110.2617, -7.9478],
      [110.2622, -7.9478],
      [110.2628, -7.9478],
      [110.2638, -7.948],
      [110.2644, -7.9478],
      [110.2643, -7.9486],
      [110.2639, -7.9487],
      [110.2633, -7.949],
      [110.263, -7.9495],
      [110.2624, -7.9497],
      [110.2623, -7.95],
      [110.262, -7.9512],
      [110.2618, -7.9523],
      [110.2616, -7.953],
      [110.2614, -7.9539],
      [110.261, -7.9545],
      [110.2616, -7.9547],
      [110.2636, -7.955],
      [110.2648, -7.9552],
      [110.2649, -7.9552],
      [110.265, -7.9551],
      [110.2672, -7.9556],
      [110.2682, -7.9559],
      [110.2689, -7.9565],
      [110.2704, -7.9571],
      [110.2721, -7.9572],
      [110.2726, -7.9571],
      [110.2734, -7.9569],
      [110.274, -7.9565],
      [110.2742, -7.9565],
      [110.2748, -7.9565],
      [110.275, -7.9566],
      [110.2762, -7.9567],
      [110.2765, -7.9567],
      [110.278, -7.9568],
      [110.279, -7.9571],
      [110.2812, -7.9584],
      [110.2814, -7.9582],
      [110.2815, -7.9582],
      [110.2815, -7.9581],
      [110.2817, -7.9574],
      [110.2817, -7.9572],
      [110.2806, -7.9548],
      [110.2806, -7.9547],
      [110.2808, -7.9541],
      [110.2815, -7.9543],
      [110.2822, -7.9543],
      [110.2826, -7.9542],
      [110.283, -7.954],
      [110.2834, -7.9537],
      [110.2831, -7.9532],
      [110.2832, -7.9524],
      [110.2826, -7.9525],
      [110.2824, -7.9517],
      [110.2825, -7.951],
      [110.2822, -7.9507],
      [110.2819, -7.9505],
      [110.2818, -7.95],
      [110.2818, -7.9492],
      [110.2817, -7.9482],
      [110.2816, -7.9478],
      [110.2809, -7.9476],
      [110.281, -7.9463],
      [110.2818, -7.9463],
      [110.282, -7.9456]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PANDAK',
    village: 'TRIHARJO',
    border: [
      [110.2927, -7.9155],
      [110.2926, -7.9146],
      [110.2919, -7.9145],
      [110.2915, -7.9134],
      [110.2907, -7.9132],
      [110.2896, -7.912],
      [110.2888, -7.9112],
      [110.2875, -7.9106],
      [110.2872, -7.9106],
      [110.2867, -7.9103],
      [110.2868, -7.9102],
      [110.2873, -7.9085],
      [110.286, -7.9081],
      [110.285, -7.9092],
      [110.2841, -7.9089],
      [110.2837, -7.9095],
      [110.2832, -7.9094],
      [110.283, -7.9099],
      [110.2817, -7.9094],
      [110.281, -7.909],
      [110.2806, -7.9092],
      [110.2805, -7.9092],
      [110.2805, -7.9095],
      [110.2804, -7.91],
      [110.2798, -7.9102],
      [110.2789, -7.9101],
      [110.2781, -7.9098],
      [110.2775, -7.9093],
      [110.277, -7.909],
      [110.2763, -7.9092],
      [110.2752, -7.9095],
      [110.2741, -7.9096],
      [110.2737, -7.9096],
      [110.2736, -7.91],
      [110.2735, -7.9108],
      [110.2733, -7.9117],
      [110.2731, -7.9127],
      [110.2726, -7.9153],
      [110.2722, -7.9178],
      [110.2718, -7.9194],
      [110.2712, -7.9221],
      [110.2708, -7.924],
      [110.2702, -7.9251],
      [110.2697, -7.9259],
      [110.2702, -7.9261],
      [110.2708, -7.9262],
      [110.2716, -7.9263],
      [110.2718, -7.9264],
      [110.2726, -7.9266],
      [110.2727, -7.9271],
      [110.2726, -7.9276],
      [110.2722, -7.9283],
      [110.2718, -7.9289],
      [110.2721, -7.9295],
      [110.2727, -7.9305],
      [110.2728, -7.9312],
      [110.2731, -7.9318],
      [110.2733, -7.932],
      [110.2734, -7.9332],
      [110.2733, -7.9342],
      [110.2725, -7.9361],
      [110.2737, -7.9367],
      [110.274, -7.9369],
      [110.275, -7.9372],
      [110.2757, -7.9375],
      [110.2758, -7.9381],
      [110.2762, -7.9383],
      [110.2774, -7.9386],
      [110.2782, -7.9389],
      [110.278, -7.9394],
      [110.279, -7.9399],
      [110.2789, -7.9403],
      [110.2792, -7.9406],
      [110.2798, -7.9407],
      [110.2804, -7.9414],
      [110.2801, -7.9423],
      [110.2806, -7.9428],
      [110.2802, -7.9437],
      [110.2797, -7.9442],
      [110.2806, -7.945],
      [110.2817, -7.9455],
      [110.282, -7.9456],
      [110.2821, -7.9449],
      [110.2824, -7.9446],
      [110.2826, -7.9444],
      [110.2827, -7.9441],
      [110.2834, -7.9442],
      [110.2839, -7.9442],
      [110.2846, -7.9443],
      [110.2852, -7.9444],
      [110.2854, -7.9444],
      [110.2852, -7.9436],
      [110.2851, -7.9426],
      [110.2852, -7.9415],
      [110.2851, -7.9404],
      [110.2853, -7.9378],
      [110.2862, -7.937],
      [110.2864, -7.9357],
      [110.2872, -7.934],
      [110.2882, -7.9342],
      [110.2885, -7.9337],
      [110.289, -7.933],
      [110.2897, -7.9331],
      [110.2906, -7.9322],
      [110.2911, -7.9312],
      [110.2915, -7.9298],
      [110.2914, -7.9282],
      [110.2916, -7.9268],
      [110.2919, -7.9267],
      [110.2923, -7.9261],
      [110.293, -7.9254],
      [110.2939, -7.9237],
      [110.2939, -7.9235],
      [110.2936, -7.9221],
      [110.2938, -7.9217],
      [110.2937, -7.9213],
      [110.2941, -7.9191],
      [110.2928, -7.9189],
      [110.2927, -7.9179],
      [110.2915, -7.9167],
      [110.2912, -7.9165],
      [110.2914, -7.916],
      [110.2924, -7.9162],
      [110.2925, -7.9157],
      [110.2927, -7.9155]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PANDAK',
    village: 'GILANGHARJO',
    border: [
      [110.3098, -7.891],
      [110.3095, -7.891],
      [110.3091, -7.8916],
      [110.3085, -7.8926],
      [110.3081, -7.8933],
      [110.3078, -7.8937],
      [110.3078, -7.8937],
      [110.3071, -7.895],
      [110.307, -7.8951],
      [110.3069, -7.8952],
      [110.3062, -7.896],
      [110.3059, -7.8961],
      [110.3059, -7.8962],
      [110.3058, -7.8966],
      [110.3057, -7.8969],
      [110.3053, -7.899],
      [110.3048, -7.9002],
      [110.3044, -7.9008],
      [110.3043, -7.9015],
      [110.3043, -7.9019],
      [110.3043, -7.9022],
      [110.3033, -7.9037],
      [110.3016, -7.9064],
      [110.3015, -7.9065],
      [110.3014, -7.9068],
      [110.3026, -7.9077],
      [110.3028, -7.908],
      [110.3039, -7.9078],
      [110.3046, -7.9092],
      [110.3046, -7.9095],
      [110.3046, -7.911],
      [110.3046, -7.9154],
      [110.3033, -7.9149],
      [110.3021, -7.9149],
      [110.3001, -7.9145],
      [110.2987, -7.9144],
      [110.2989, -7.913],
      [110.2975, -7.9124],
      [110.2968, -7.9135],
      [110.2964, -7.9145],
      [110.2958, -7.9144],
      [110.2954, -7.914],
      [110.2952, -7.914],
      [110.295, -7.9144],
      [110.2948, -7.9151],
      [110.2942, -7.9159],
      [110.2927, -7.9155],
      [110.2925, -7.9157],
      [110.2924, -7.9162],
      [110.2914, -7.916],
      [110.2912, -7.9165],
      [110.2915, -7.9167],
      [110.2927, -7.9179],
      [110.2928, -7.9189],
      [110.2941, -7.9191],
      [110.2937, -7.9213],
      [110.2938, -7.9217],
      [110.2936, -7.9221],
      [110.2939, -7.9235],
      [110.2939, -7.9237],
      [110.293, -7.9254],
      [110.2923, -7.9261],
      [110.2919, -7.9267],
      [110.2916, -7.9268],
      [110.2914, -7.9282],
      [110.2915, -7.9298],
      [110.2911, -7.9312],
      [110.2906, -7.9322],
      [110.2897, -7.9331],
      [110.289, -7.933],
      [110.2885, -7.9337],
      [110.2882, -7.9342],
      [110.2872, -7.934],
      [110.2864, -7.9357],
      [110.2862, -7.937],
      [110.2853, -7.9378],
      [110.2851, -7.9404],
      [110.2852, -7.9415],
      [110.2851, -7.9426],
      [110.2852, -7.9436],
      [110.2854, -7.9444],
      [110.2857, -7.9444],
      [110.2864, -7.9444],
      [110.2868, -7.9446],
      [110.2872, -7.9449],
      [110.2878, -7.945],
      [110.288, -7.9447],
      [110.2883, -7.9444],
      [110.2888, -7.9443],
      [110.2892, -7.9445],
      [110.2901, -7.9447],
      [110.2903, -7.9441],
      [110.2906, -7.9428],
      [110.2913, -7.9427],
      [110.2916, -7.9413],
      [110.292, -7.9413],
      [110.2923, -7.9407],
      [110.2924, -7.9399],
      [110.2927, -7.9391],
      [110.2939, -7.9395],
      [110.2943, -7.9394],
      [110.2948, -7.9393],
      [110.2955, -7.9395],
      [110.296, -7.9395],
      [110.2966, -7.9394],
      [110.2971, -7.9393],
      [110.2976, -7.9399],
      [110.2982, -7.9402],
      [110.2987, -7.94],
      [110.299, -7.9403],
      [110.2992, -7.9412],
      [110.2997, -7.9411],
      [110.2999, -7.9411],
      [110.3, -7.9403],
      [110.3, -7.9396],
      [110.3002, -7.9392],
      [110.3003, -7.9389],
      [110.3004, -7.9382],
      [110.3009, -7.9381],
      [110.3012, -7.9376],
      [110.3021, -7.9379],
      [110.3031, -7.9346],
      [110.3033, -7.9346],
      [110.3041, -7.9347],
      [110.3051, -7.9348],
      [110.3061, -7.9334],
      [110.3066, -7.9327],
      [110.3065, -7.9325],
      [110.3067, -7.9313],
      [110.3055, -7.931],
      [110.3055, -7.93],
      [110.3044, -7.93],
      [110.3032, -7.9315],
      [110.3021, -7.931],
      [110.3023, -7.9299],
      [110.3024, -7.9293],
      [110.3023, -7.929],
      [110.3026, -7.9285],
      [110.3029, -7.9279],
      [110.3031, -7.9272],
      [110.3034, -7.9266],
      [110.3029, -7.9265],
      [110.303, -7.9259],
      [110.3035, -7.9244],
      [110.3038, -7.9244],
      [110.304, -7.9231],
      [110.3042, -7.9232],
      [110.3047, -7.9229],
      [110.3058, -7.9232],
      [110.3066, -7.9233],
      [110.3073, -7.9237],
      [110.3076, -7.9234],
      [110.3067, -7.9228],
      [110.3069, -7.9225],
      [110.3071, -7.922],
      [110.3072, -7.9214],
      [110.3075, -7.921],
      [110.3082, -7.9203],
      [110.3092, -7.9205],
      [110.3101, -7.9206],
      [110.3113, -7.9209],
      [110.3129, -7.9215],
      [110.3132, -7.9215],
      [110.3133, -7.9215],
      [110.3139, -7.9205],
      [110.3145, -7.9191],
      [110.3149, -7.9182],
      [110.3147, -7.9182],
      [110.3125, -7.918],
      [110.3125, -7.9176],
      [110.313, -7.9167],
      [110.3132, -7.9158],
      [110.3131, -7.9156],
      [110.3129, -7.9152],
      [110.3131, -7.9148],
      [110.3134, -7.9143],
      [110.3141, -7.9142],
      [110.3146, -7.9139],
      [110.3154, -7.9132],
      [110.3154, -7.9125],
      [110.3152, -7.9117],
      [110.3145, -7.9118],
      [110.3139, -7.9115],
      [110.3142, -7.9107],
      [110.3144, -7.9099],
      [110.3146, -7.9089],
      [110.3146, -7.9079],
      [110.3146, -7.9068],
      [110.3146, -7.906],
      [110.3147, -7.9059],
      [110.3146, -7.905],
      [110.3145, -7.9048],
      [110.3144, -7.9045],
      [110.3137, -7.9038],
      [110.3135, -7.903],
      [110.3133, -7.9019],
      [110.3132, -7.9013],
      [110.3134, -7.8997],
      [110.3135, -7.8987],
      [110.3136, -7.8969],
      [110.3135, -7.8968],
      [110.3133, -7.8965],
      [110.3129, -7.8961],
      [110.313, -7.8956],
      [110.3133, -7.8951],
      [110.3133, -7.8947],
      [110.3128, -7.8944],
      [110.3111, -7.894],
      [110.3106, -7.8938],
      [110.3107, -7.8933],
      [110.311, -7.893],
      [110.3109, -7.8922],
      [110.3109, -7.8921],
      [110.3107, -7.8913],
      [110.3105, -7.8912],
      [110.3098, -7.891]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PANDAK',
    village: 'WIJIREJO',
    border: [
      [110.3059, -7.8961],
      [110.3054, -7.8962],
      [110.3052, -7.8962],
      [110.305, -7.8962],
      [110.3042, -7.8962],
      [110.3036, -7.8962],
      [110.3029, -7.8961],
      [110.3025, -7.8962],
      [110.3023, -7.8962],
      [110.302, -7.8963],
      [110.302, -7.8962],
      [110.302, -7.8956],
      [110.3016, -7.8953],
      [110.3016, -7.8949],
      [110.3012, -7.8947],
      [110.3005, -7.8945],
      [110.3, -7.8943],
      [110.2991, -7.8941],
      [110.2992, -7.8938],
      [110.2987, -7.8935],
      [110.299, -7.8927],
      [110.2993, -7.8924],
      [110.2999, -7.8904],
      [110.2996, -7.8902],
      [110.2992, -7.8906],
      [110.2984, -7.8904],
      [110.298, -7.8915],
      [110.2977, -7.8918],
      [110.2976, -7.8923],
      [110.2957, -7.8923],
      [110.2945, -7.892],
      [110.294, -7.8935],
      [110.2939, -7.895],
      [110.2938, -7.8971],
      [110.2939, -7.8988],
      [110.2933, -7.8991],
      [110.2931, -7.8992],
      [110.2928, -7.8994],
      [110.2926, -7.8996],
      [110.2918, -7.9001],
      [110.2911, -7.9003],
      [110.2907, -7.9004],
      [110.29, -7.9003],
      [110.2897, -7.9002],
      [110.2895, -7.9002],
      [110.2894, -7.9001],
      [110.2892, -7.9],
      [110.2888, -7.8999],
      [110.2886, -7.8997],
      [110.2884, -7.8994],
      [110.288, -7.899],
      [110.2874, -7.8982],
      [110.2871, -7.8978],
      [110.2869, -7.8976],
      [110.2868, -7.8976],
      [110.2864, -7.8973],
      [110.2862, -7.8972],
      [110.286, -7.8972],
      [110.2855, -7.897],
      [110.2849, -7.8969],
      [110.2848, -7.8969],
      [110.2844, -7.8971],
      [110.2842, -7.8976],
      [110.2842, -7.898],
      [110.2843, -7.8982],
      [110.2843, -7.8984],
      [110.2844, -7.8987],
      [110.2844, -7.8989],
      [110.2845, -7.8991],
      [110.2846, -7.8997],
      [110.2847, -7.9004],
      [110.2846, -7.9007],
      [110.2846, -7.901],
      [110.2842, -7.9012],
      [110.2835, -7.9014],
      [110.2832, -7.9014],
      [110.2825, -7.9015],
      [110.2816, -7.902],
      [110.2813, -7.9028],
      [110.281, -7.9035],
      [110.281, -7.904],
      [110.2813, -7.9044],
      [110.2815, -7.9047],
      [110.2816, -7.9049],
      [110.2816, -7.905],
      [110.2817, -7.9059],
      [110.2817, -7.9067],
      [110.2815, -7.9072],
      [110.2811, -7.9077],
      [110.2813, -7.9082],
      [110.2808, -7.9085],
      [110.2806, -7.9089],
      [110.2805, -7.9092],
      [110.2806, -7.9092],
      [110.281, -7.909],
      [110.2817, -7.9094],
      [110.283, -7.9099],
      [110.2832, -7.9094],
      [110.2837, -7.9095],
      [110.2841, -7.9089],
      [110.285, -7.9092],
      [110.286, -7.9081],
      [110.2873, -7.9085],
      [110.2868, -7.9102],
      [110.2867, -7.9103],
      [110.2872, -7.9106],
      [110.2875, -7.9106],
      [110.2888, -7.9112],
      [110.2896, -7.912],
      [110.2907, -7.9132],
      [110.2915, -7.9134],
      [110.2919, -7.9145],
      [110.2926, -7.9146],
      [110.2927, -7.9155],
      [110.2942, -7.9159],
      [110.2948, -7.9151],
      [110.295, -7.9144],
      [110.2952, -7.914],
      [110.2954, -7.914],
      [110.2958, -7.9144],
      [110.2964, -7.9145],
      [110.2968, -7.9135],
      [110.2975, -7.9124],
      [110.2989, -7.913],
      [110.2987, -7.9144],
      [110.3001, -7.9145],
      [110.3021, -7.9149],
      [110.3033, -7.9149],
      [110.3046, -7.9154],
      [110.3046, -7.911],
      [110.3046, -7.9095],
      [110.3046, -7.9092],
      [110.3039, -7.9078],
      [110.3028, -7.908],
      [110.3026, -7.9077],
      [110.3014, -7.9068],
      [110.3015, -7.9065],
      [110.3016, -7.9064],
      [110.3033, -7.9037],
      [110.3043, -7.9022],
      [110.3043, -7.9019],
      [110.3043, -7.9015],
      [110.3044, -7.9008],
      [110.3048, -7.9002],
      [110.3053, -7.899],
      [110.3057, -7.8969],
      [110.3058, -7.8966],
      [110.3059, -7.8962],
      [110.3059, -7.8961]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PAJANGAN',
    village: 'TRIWIDADI',
    border: [
      [110.282, -7.8343],
      [110.2817, -7.8348],
      [110.2812, -7.8351],
      [110.2805, -7.8348],
      [110.2799, -7.8347],
      [110.2796, -7.8342],
      [110.2792, -7.8343],
      [110.2788, -7.8342],
      [110.2785, -7.8345],
      [110.2784, -7.8352],
      [110.2783, -7.8357],
      [110.2783, -7.8359],
      [110.2779, -7.8366],
      [110.2773, -7.8373],
      [110.2764, -7.8376],
      [110.2752, -7.8376],
      [110.2742, -7.8374],
      [110.273, -7.8373],
      [110.2716, -7.8371],
      [110.271, -7.8373],
      [110.2709, -7.8375],
      [110.2705, -7.8378],
      [110.2695, -7.839],
      [110.2691, -7.8395],
      [110.2687, -7.8402],
      [110.2681, -7.8406],
      [110.268, -7.8411],
      [110.2675, -7.8417],
      [110.2673, -7.8422],
      [110.2667, -7.843],
      [110.2663, -7.8431],
      [110.2661, -7.8433],
      [110.2656, -7.8443],
      [110.2648, -7.8457],
      [110.264, -7.8468],
      [110.263, -7.8486],
      [110.2612, -7.8513],
      [110.2608, -7.8522],
      [110.2604, -7.8523],
      [110.2597, -7.8525],
      [110.2591, -7.8529],
      [110.2584, -7.8534],
      [110.2578, -7.8544],
      [110.2575, -7.8556],
      [110.2574, -7.8568],
      [110.2571, -7.858],
      [110.2574, -7.8582],
      [110.2576, -7.8586],
      [110.2579, -7.8592],
      [110.2582, -7.8593],
      [110.259, -7.8593],
      [110.2591, -7.8602],
      [110.2593, -7.8618],
      [110.2575, -7.8619],
      [110.2574, -7.8632],
      [110.2573, -7.8646],
      [110.2564, -7.8658],
      [110.2571, -7.8665],
      [110.2581, -7.8686],
      [110.2588, -7.8708],
      [110.2609, -7.8737],
      [110.2617, -7.875],
      [110.2624, -7.8761],
      [110.2635, -7.8771],
      [110.2638, -7.8774],
      [110.2646, -7.8786],
      [110.2661, -7.8814],
      [110.2667, -7.8822],
      [110.2677, -7.8809],
      [110.2677, -7.88],
      [110.2678, -7.8798],
      [110.2685, -7.8793],
      [110.2688, -7.879],
      [110.2691, -7.8783],
      [110.2695, -7.8791],
      [110.2697, -7.8793],
      [110.2703, -7.8794],
      [110.27, -7.8801],
      [110.2717, -7.8801],
      [110.2724, -7.88],
      [110.2732, -7.88],
      [110.2739, -7.8806],
      [110.2743, -7.8815],
      [110.2749, -7.8814],
      [110.2752, -7.8818],
      [110.2756, -7.8818],
      [110.2759, -7.8821],
      [110.277, -7.8819],
      [110.2794, -7.8814],
      [110.2806, -7.881],
      [110.2802, -7.8805],
      [110.2805, -7.8793],
      [110.281, -7.879],
      [110.2813, -7.8788],
      [110.2818, -7.8785],
      [110.2823, -7.8781],
      [110.283, -7.878],
      [110.2832, -7.8773],
      [110.284, -7.8758],
      [110.2844, -7.8755],
      [110.2844, -7.875],
      [110.2845, -7.8745],
      [110.2846, -7.8739],
      [110.2848, -7.8735],
      [110.2852, -7.8718],
      [110.2836, -7.8715],
      [110.2824, -7.8702],
      [110.2827, -7.8701],
      [110.2827, -7.8699],
      [110.2828, -7.8697],
      [110.2828, -7.8696],
      [110.2831, -7.8694],
      [110.2834, -7.8691],
      [110.2837, -7.8689],
      [110.2839, -7.8688],
      [110.2843, -7.868],
      [110.2848, -7.8675],
      [110.2852, -7.8673],
      [110.2854, -7.8669],
      [110.2854, -7.8667],
      [110.2854, -7.8661],
      [110.2854, -7.8653],
      [110.2854, -7.8651],
      [110.2853, -7.8647],
      [110.285, -7.8641],
      [110.285, -7.8639],
      [110.2845, -7.8638],
      [110.2844, -7.8638],
      [110.2841, -7.8637],
      [110.2838, -7.8632],
      [110.2837, -7.8631],
      [110.2837, -7.863],
      [110.2836, -7.8627],
      [110.2836, -7.8626],
      [110.2836, -7.8622],
      [110.2838, -7.8619],
      [110.2839, -7.8616],
      [110.2842, -7.8616],
      [110.2843, -7.8616],
      [110.2848, -7.8617],
      [110.285, -7.8616],
      [110.2854, -7.8614],
      [110.2863, -7.8612],
      [110.2865, -7.8611],
      [110.2867, -7.861],
      [110.2871, -7.8609],
      [110.2875, -7.8609],
      [110.2879, -7.861],
      [110.2879, -7.8609],
      [110.2879, -7.8608],
      [110.2879, -7.8607],
      [110.2876, -7.8604],
      [110.2875, -7.8603],
      [110.2868, -7.8598],
      [110.2866, -7.8599],
      [110.2862, -7.8601],
      [110.2861, -7.86],
      [110.2859, -7.86],
      [110.2858, -7.8599],
      [110.2857, -7.8597],
      [110.2857, -7.8593],
      [110.286, -7.8588],
      [110.2861, -7.8578],
      [110.2862, -7.8569],
      [110.2864, -7.8552],
      [110.2867, -7.8541],
      [110.2873, -7.8533],
      [110.287, -7.853],
      [110.2872, -7.8525],
      [110.2875, -7.8514],
      [110.2877, -7.8505],
      [110.288, -7.8496],
      [110.2876, -7.8488],
      [110.287, -7.8488],
      [110.2859, -7.8486],
      [110.2851, -7.8485],
      [110.2848, -7.8484],
      [110.2846, -7.848],
      [110.2833, -7.8473],
      [110.2824, -7.847],
      [110.2816, -7.8469],
      [110.2812, -7.8469],
      [110.2809, -7.8471],
      [110.2804, -7.847],
      [110.2803, -7.8466],
      [110.2806, -7.8458],
      [110.2814, -7.8449],
      [110.2818, -7.8442],
      [110.282, -7.8435],
      [110.2818, -7.8428],
      [110.2821, -7.8423],
      [110.283, -7.8417],
      [110.2837, -7.8411],
      [110.2841, -7.8404],
      [110.2846, -7.8393],
      [110.2847, -7.8378],
      [110.2847, -7.8368],
      [110.284, -7.8358],
      [110.2838, -7.8354],
      [110.2833, -7.8351],
      [110.2823, -7.8346],
      [110.282, -7.8343]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PAJANGAN',
    village: 'SENDANGSARI',
    border: [
      [110.3062, -7.8545],
      [110.3048, -7.8545],
      [110.3036, -7.8546],
      [110.3031, -7.8547],
      [110.3018, -7.8543],
      [110.3019, -7.8539],
      [110.301, -7.8534],
      [110.3009, -7.8534],
      [110.3006, -7.8526],
      [110.2993, -7.8522],
      [110.2984, -7.8519],
      [110.2977, -7.852],
      [110.2966, -7.852],
      [110.2955, -7.852],
      [110.2945, -7.8516],
      [110.2945, -7.8515],
      [110.2933, -7.8517],
      [110.2912, -7.8514],
      [110.2903, -7.8514],
      [110.2902, -7.8514],
      [110.2886, -7.8502],
      [110.288, -7.8496],
      [110.2877, -7.8505],
      [110.2875, -7.8514],
      [110.2872, -7.8525],
      [110.287, -7.853],
      [110.2873, -7.8533],
      [110.2867, -7.8541],
      [110.2864, -7.8552],
      [110.2862, -7.8569],
      [110.2861, -7.8578],
      [110.286, -7.8588],
      [110.2857, -7.8593],
      [110.2857, -7.8597],
      [110.2858, -7.8599],
      [110.2859, -7.86],
      [110.2861, -7.86],
      [110.2862, -7.8601],
      [110.2866, -7.8599],
      [110.2868, -7.8598],
      [110.2875, -7.8603],
      [110.2876, -7.8604],
      [110.2879, -7.8607],
      [110.2879, -7.8608],
      [110.2879, -7.8609],
      [110.2879, -7.861],
      [110.2875, -7.8609],
      [110.2871, -7.8609],
      [110.2867, -7.861],
      [110.2865, -7.8611],
      [110.2863, -7.8612],
      [110.2854, -7.8614],
      [110.285, -7.8616],
      [110.2848, -7.8617],
      [110.2843, -7.8616],
      [110.2842, -7.8616],
      [110.2839, -7.8616],
      [110.2838, -7.8619],
      [110.2836, -7.8622],
      [110.2836, -7.8626],
      [110.2836, -7.8627],
      [110.2837, -7.863],
      [110.2837, -7.8631],
      [110.2838, -7.8632],
      [110.2841, -7.8637],
      [110.2844, -7.8638],
      [110.2845, -7.8638],
      [110.285, -7.8639],
      [110.285, -7.8641],
      [110.2853, -7.8647],
      [110.2854, -7.8651],
      [110.2854, -7.8653],
      [110.2854, -7.8661],
      [110.2854, -7.8667],
      [110.2854, -7.8669],
      [110.2852, -7.8673],
      [110.2848, -7.8675],
      [110.2843, -7.868],
      [110.2839, -7.8688],
      [110.2837, -7.8689],
      [110.2834, -7.8691],
      [110.2831, -7.8694],
      [110.2828, -7.8696],
      [110.2828, -7.8697],
      [110.2827, -7.8699],
      [110.2827, -7.8701],
      [110.2824, -7.8702],
      [110.2836, -7.8715],
      [110.2852, -7.8718],
      [110.2848, -7.8735],
      [110.2846, -7.8739],
      [110.2845, -7.8745],
      [110.2844, -7.875],
      [110.2844, -7.8755],
      [110.284, -7.8758],
      [110.2832, -7.8773],
      [110.283, -7.878],
      [110.2823, -7.8781],
      [110.2818, -7.8785],
      [110.2813, -7.8788],
      [110.281, -7.879],
      [110.2805, -7.8793],
      [110.2802, -7.8805],
      [110.2806, -7.881],
      [110.2794, -7.8814],
      [110.277, -7.8819],
      [110.2759, -7.8821],
      [110.2756, -7.8818],
      [110.2752, -7.8818],
      [110.2749, -7.8814],
      [110.2743, -7.8815],
      [110.2739, -7.8806],
      [110.2732, -7.88],
      [110.2724, -7.88],
      [110.2717, -7.8801],
      [110.27, -7.8801],
      [110.2703, -7.8794],
      [110.2697, -7.8793],
      [110.2695, -7.8791],
      [110.2691, -7.8783],
      [110.2688, -7.879],
      [110.2685, -7.8793],
      [110.2678, -7.8798],
      [110.2677, -7.88],
      [110.2677, -7.8809],
      [110.2667, -7.8822],
      [110.267, -7.8826],
      [110.2682, -7.8844],
      [110.2687, -7.8852],
      [110.2692, -7.8858],
      [110.2704, -7.8873],
      [110.2719, -7.8896],
      [110.2727, -7.8914],
      [110.273, -7.8928],
      [110.2735, -7.8962],
      [110.2739, -7.8985],
      [110.2741, -7.9005],
      [110.2741, -7.9009],
      [110.2739, -7.9041],
      [110.2736, -7.9061],
      [110.2737, -7.9078],
      [110.2737, -7.9094],
      [110.2737, -7.9096],
      [110.2741, -7.9096],
      [110.2752, -7.9095],
      [110.2763, -7.9092],
      [110.277, -7.909],
      [110.2775, -7.9093],
      [110.2781, -7.9098],
      [110.2789, -7.9101],
      [110.2798, -7.9102],
      [110.2804, -7.91],
      [110.2805, -7.9095],
      [110.2805, -7.9092],
      [110.2806, -7.9089],
      [110.2808, -7.9085],
      [110.2813, -7.9082],
      [110.2811, -7.9077],
      [110.2815, -7.9072],
      [110.2817, -7.9067],
      [110.2817, -7.9059],
      [110.2816, -7.905],
      [110.2816, -7.9049],
      [110.2815, -7.9047],
      [110.2813, -7.9044],
      [110.281, -7.904],
      [110.281, -7.9035],
      [110.2813, -7.9028],
      [110.2816, -7.902],
      [110.2825, -7.9015],
      [110.2832, -7.9014],
      [110.2835, -7.9014],
      [110.2842, -7.9012],
      [110.2846, -7.901],
      [110.2846, -7.9007],
      [110.2847, -7.9004],
      [110.2846, -7.8997],
      [110.2845, -7.8991],
      [110.2844, -7.8989],
      [110.2844, -7.8987],
      [110.2843, -7.8984],
      [110.2843, -7.8982],
      [110.2842, -7.898],
      [110.2842, -7.8976],
      [110.2844, -7.8971],
      [110.2848, -7.8969],
      [110.2849, -7.8969],
      [110.2855, -7.897],
      [110.286, -7.8972],
      [110.2862, -7.8972],
      [110.2864, -7.8973],
      [110.2868, -7.8976],
      [110.2869, -7.8976],
      [110.2871, -7.8978],
      [110.2874, -7.8982],
      [110.288, -7.899],
      [110.2884, -7.8994],
      [110.2886, -7.8997],
      [110.2888, -7.8999],
      [110.2892, -7.9],
      [110.2894, -7.9001],
      [110.2895, -7.9002],
      [110.2897, -7.9002],
      [110.29, -7.9003],
      [110.2907, -7.9004],
      [110.2911, -7.9003],
      [110.2918, -7.9001],
      [110.2926, -7.8996],
      [110.2928, -7.8994],
      [110.2931, -7.8992],
      [110.2933, -7.8991],
      [110.2939, -7.8988],
      [110.2938, -7.8971],
      [110.2939, -7.895],
      [110.294, -7.8935],
      [110.2945, -7.892],
      [110.2957, -7.8923],
      [110.2952, -7.8917],
      [110.2953, -7.8908],
      [110.2951, -7.8902],
      [110.295, -7.8897],
      [110.2953, -7.8893],
      [110.2954, -7.8892],
      [110.297, -7.886],
      [110.2986, -7.8828],
      [110.2999, -7.8824],
      [110.3004, -7.8809],
      [110.3015, -7.8774],
      [110.2946, -7.8756],
      [110.2949, -7.875],
      [110.2949, -7.8748],
      [110.2956, -7.873],
      [110.2961, -7.8713],
      [110.2961, -7.871],
      [110.2976, -7.8704],
      [110.301, -7.8718],
      [110.3012, -7.8718],
      [110.3017, -7.8712],
      [110.3022, -7.8702],
      [110.3026, -7.8692],
      [110.3027, -7.8682],
      [110.304, -7.8688],
      [110.3049, -7.8672],
      [110.3075, -7.8625],
      [110.3076, -7.862],
      [110.3063, -7.8603],
      [110.3057, -7.8591],
      [110.3059, -7.8585],
      [110.3064, -7.8568],
      [110.3063, -7.8561],
      [110.3062, -7.8545]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PAJANGAN',
    village: 'GUWOSARI',
    border: [
      [110.3242, -7.8614],
      [110.3242, -7.8613],
      [110.324, -7.8611],
      [110.3225, -7.8604],
      [110.3213, -7.86],
      [110.3206, -7.8599],
      [110.3197, -7.86],
      [110.3192, -7.8601],
      [110.3187, -7.8599],
      [110.3181, -7.8586],
      [110.3172, -7.8582],
      [110.3173, -7.8569],
      [110.3163, -7.8565],
      [110.3163, -7.8559],
      [110.3165, -7.8552],
      [110.3163, -7.8549],
      [110.3152, -7.8544],
      [110.3137, -7.8542],
      [110.3124, -7.8541],
      [110.3124, -7.8539],
      [110.3107, -7.8531],
      [110.3105, -7.8533],
      [110.3098, -7.853],
      [110.3095, -7.8527],
      [110.3092, -7.8531],
      [110.3089, -7.8534],
      [110.3085, -7.8535],
      [110.3078, -7.8533],
      [110.3076, -7.8545],
      [110.3071, -7.8544],
      [110.3062, -7.8545],
      [110.3063, -7.8561],
      [110.3064, -7.8568],
      [110.3059, -7.8585],
      [110.3057, -7.8591],
      [110.3063, -7.8603],
      [110.3076, -7.862],
      [110.3075, -7.8625],
      [110.3049, -7.8672],
      [110.304, -7.8688],
      [110.3027, -7.8682],
      [110.3026, -7.8692],
      [110.3022, -7.8702],
      [110.3017, -7.8712],
      [110.3012, -7.8718],
      [110.301, -7.8718],
      [110.2976, -7.8704],
      [110.2961, -7.871],
      [110.2961, -7.8713],
      [110.2956, -7.873],
      [110.2949, -7.8748],
      [110.2949, -7.875],
      [110.2946, -7.8756],
      [110.3015, -7.8774],
      [110.3004, -7.8809],
      [110.2999, -7.8824],
      [110.2986, -7.8828],
      [110.297, -7.886],
      [110.2954, -7.8892],
      [110.2953, -7.8893],
      [110.295, -7.8897],
      [110.2951, -7.8902],
      [110.2953, -7.8908],
      [110.2952, -7.8917],
      [110.2957, -7.8923],
      [110.2976, -7.8923],
      [110.2977, -7.8918],
      [110.298, -7.8915],
      [110.2984, -7.8904],
      [110.2992, -7.8906],
      [110.2996, -7.8902],
      [110.2999, -7.8904],
      [110.2993, -7.8924],
      [110.299, -7.8927],
      [110.2987, -7.8935],
      [110.2992, -7.8938],
      [110.2991, -7.8941],
      [110.3, -7.8943],
      [110.3005, -7.8945],
      [110.3012, -7.8947],
      [110.3016, -7.8949],
      [110.3016, -7.8953],
      [110.302, -7.8956],
      [110.302, -7.8962],
      [110.302, -7.8963],
      [110.3023, -7.8962],
      [110.3025, -7.8962],
      [110.3029, -7.8961],
      [110.3036, -7.8962],
      [110.3042, -7.8962],
      [110.305, -7.8962],
      [110.3052, -7.8962],
      [110.3054, -7.8962],
      [110.3059, -7.8961],
      [110.3062, -7.896],
      [110.3069, -7.8952],
      [110.307, -7.8951],
      [110.3071, -7.895],
      [110.3078, -7.8937],
      [110.3078, -7.8937],
      [110.3081, -7.8933],
      [110.3085, -7.8926],
      [110.3091, -7.8916],
      [110.3095, -7.8909],
      [110.3101, -7.8903],
      [110.3107, -7.8896],
      [110.3111, -7.8887],
      [110.3113, -7.8881],
      [110.311, -7.8871],
      [110.3106, -7.8862],
      [110.3104, -7.8855],
      [110.3105, -7.8847],
      [110.3108, -7.8841],
      [110.311, -7.8836],
      [110.3108, -7.8834],
      [110.3104, -7.8831],
      [110.3098, -7.8829],
      [110.3097, -7.8823],
      [110.3098, -7.8816],
      [110.3101, -7.8809],
      [110.3095, -7.8807],
      [110.3096, -7.8803],
      [110.3102, -7.8806],
      [110.3105, -7.88],
      [110.3102, -7.8798],
      [110.3105, -7.8793],
      [110.311, -7.8795],
      [110.3113, -7.8801],
      [110.3121, -7.8802],
      [110.3135, -7.8802],
      [110.314, -7.88],
      [110.3143, -7.8801],
      [110.3149, -7.8793],
      [110.3152, -7.8784],
      [110.3152, -7.8781],
      [110.3154, -7.8781],
      [110.3161, -7.878],
      [110.3174, -7.8782],
      [110.3191, -7.8786],
      [110.3196, -7.8787],
      [110.3198, -7.8788],
      [110.32, -7.8782],
      [110.3207, -7.8784],
      [110.321, -7.8784],
      [110.3214, -7.8779],
      [110.3217, -7.877],
      [110.3218, -7.8766],
      [110.3221, -7.8765],
      [110.3225, -7.8745],
      [110.3233, -7.8737],
      [110.3235, -7.8734],
      [110.3236, -7.8732],
      [110.3238, -7.8726],
      [110.3242, -7.8724],
      [110.3245, -7.8719],
      [110.3251, -7.8715],
      [110.3256, -7.871],
      [110.3252, -7.871],
      [110.3249, -7.8711],
      [110.3244, -7.8709],
      [110.3233, -7.8696],
      [110.3236, -7.8686],
      [110.3241, -7.8674],
      [110.3245, -7.8666],
      [110.3246, -7.8656],
      [110.324, -7.8653],
      [110.3238, -7.8656],
      [110.3235, -7.8654],
      [110.3237, -7.8647],
      [110.323, -7.8644],
      [110.3224, -7.8645],
      [110.3217, -7.8643],
      [110.3226, -7.863],
      [110.3232, -7.8623],
      [110.3238, -7.862],
      [110.3242, -7.8614]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANTUL',
    village: 'PALBAPANG',
    border: [
      [110.3161, -7.8954],
      [110.3163, -7.8941],
      [110.3154, -7.894],
      [110.3151, -7.8939],
      [110.3145, -7.8939],
      [110.3136, -7.8946],
      [110.3133, -7.8947],
      [110.3133, -7.8951],
      [110.313, -7.8956],
      [110.3129, -7.8961],
      [110.3133, -7.8965],
      [110.3135, -7.8968],
      [110.3136, -7.8969],
      [110.3135, -7.8987],
      [110.3134, -7.8997],
      [110.3132, -7.9013],
      [110.3133, -7.9019],
      [110.3135, -7.903],
      [110.3137, -7.9038],
      [110.3144, -7.9045],
      [110.3145, -7.9048],
      [110.3146, -7.905],
      [110.3147, -7.9059],
      [110.3146, -7.906],
      [110.3146, -7.9068],
      [110.3146, -7.9079],
      [110.3146, -7.9089],
      [110.3144, -7.9099],
      [110.3142, -7.9107],
      [110.3139, -7.9115],
      [110.3145, -7.9118],
      [110.3152, -7.9117],
      [110.3154, -7.9125],
      [110.3154, -7.9132],
      [110.3146, -7.9139],
      [110.3141, -7.9142],
      [110.3134, -7.9143],
      [110.3131, -7.9148],
      [110.3129, -7.9152],
      [110.3131, -7.9156],
      [110.3132, -7.9158],
      [110.313, -7.9167],
      [110.3125, -7.9176],
      [110.3125, -7.918],
      [110.3147, -7.9182],
      [110.3149, -7.9182],
      [110.315, -7.9183],
      [110.3155, -7.9201],
      [110.3158, -7.9201],
      [110.316, -7.9198],
      [110.316, -7.9181],
      [110.3161, -7.9176],
      [110.3166, -7.9176],
      [110.3171, -7.9177],
      [110.3173, -7.9188],
      [110.3178, -7.9191],
      [110.3183, -7.9191],
      [110.3184, -7.9186],
      [110.3182, -7.9184],
      [110.3182, -7.9178],
      [110.3192, -7.918],
      [110.3206, -7.9183],
      [110.3217, -7.9187],
      [110.3223, -7.9188],
      [110.3229, -7.9188],
      [110.3233, -7.9187],
      [110.3254, -7.9188],
      [110.3268, -7.9189],
      [110.3268, -7.9193],
      [110.3268, -7.9206],
      [110.3266, -7.9213],
      [110.3272, -7.9204],
      [110.3276, -7.9201],
      [110.3282, -7.92],
      [110.3293, -7.919],
      [110.3294, -7.919],
      [110.3304, -7.919],
      [110.3312, -7.919],
      [110.332, -7.919],
      [110.3333, -7.9183],
      [110.3335, -7.9179],
      [110.3336, -7.9163],
      [110.334, -7.9143],
      [110.3342, -7.9132],
      [110.3343, -7.9125],
      [110.3345, -7.9121],
      [110.3353, -7.9123],
      [110.3353, -7.9126],
      [110.3361, -7.9127],
      [110.3361, -7.9125],
      [110.3362, -7.9113],
      [110.3363, -7.9101],
      [110.3363, -7.9099],
      [110.3362, -7.9091],
      [110.3353, -7.9092],
      [110.3352, -7.9092],
      [110.3347, -7.9088],
      [110.3343, -7.9086],
      [110.334, -7.9086],
      [110.3335, -7.9078],
      [110.3342, -7.9055],
      [110.3343, -7.9046],
      [110.3343, -7.9044],
      [110.3343, -7.9038],
      [110.3343, -7.9032],
      [110.3341, -7.9028],
      [110.3342, -7.9023],
      [110.3329, -7.9022],
      [110.3328, -7.9022],
      [110.3328, -7.9016],
      [110.3328, -7.9012],
      [110.3325, -7.901],
      [110.331, -7.9015],
      [110.3296, -7.9019],
      [110.3279, -7.9018],
      [110.328, -7.8995],
      [110.328, -7.8986],
      [110.3281, -7.898],
      [110.3281, -7.8975],
      [110.3278, -7.8974],
      [110.3268, -7.8972],
      [110.3254, -7.8969],
      [110.3245, -7.8968],
      [110.3235, -7.8966],
      [110.3233, -7.8965],
      [110.3232, -7.8964],
      [110.3215, -7.8961],
      [110.3208, -7.896],
      [110.3207, -7.8967],
      [110.3202, -7.8979],
      [110.3198, -7.8989],
      [110.3196, -7.8993],
      [110.3194, -7.8995],
      [110.3174, -7.8991],
      [110.3173, -7.899],
      [110.3173, -7.8984],
      [110.3175, -7.8972],
      [110.3177, -7.8962],
      [110.318, -7.8954],
      [110.317, -7.8951],
      [110.3167, -7.8951],
      [110.3162, -7.8954],
      [110.3161, -7.8954]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANTUL',
    village: 'RINGINHARJO',
    border: [
      [110.3198, -7.8788],
      [110.3196, -7.8787],
      [110.3191, -7.8786],
      [110.3174, -7.8782],
      [110.3161, -7.878],
      [110.3154, -7.8781],
      [110.3152, -7.8781],
      [110.3152, -7.8784],
      [110.3149, -7.8793],
      [110.3143, -7.8801],
      [110.314, -7.88],
      [110.3135, -7.8802],
      [110.3121, -7.8802],
      [110.3113, -7.8801],
      [110.311, -7.8795],
      [110.3105, -7.8793],
      [110.3102, -7.8798],
      [110.3105, -7.88],
      [110.3102, -7.8806],
      [110.3096, -7.8803],
      [110.3095, -7.8807],
      [110.3101, -7.8809],
      [110.3098, -7.8816],
      [110.3097, -7.8823],
      [110.3098, -7.8829],
      [110.3104, -7.8831],
      [110.3108, -7.8834],
      [110.311, -7.8836],
      [110.3108, -7.8841],
      [110.3105, -7.8847],
      [110.3104, -7.8855],
      [110.3106, -7.8862],
      [110.311, -7.8871],
      [110.3113, -7.8881],
      [110.3111, -7.8887],
      [110.3107, -7.8896],
      [110.3101, -7.8903],
      [110.3095, -7.8909],
      [110.3098, -7.891],
      [110.3105, -7.8912],
      [110.3107, -7.8913],
      [110.3109, -7.8921],
      [110.3109, -7.8922],
      [110.311, -7.893],
      [110.3107, -7.8933],
      [110.3106, -7.8938],
      [110.3111, -7.894],
      [110.3128, -7.8944],
      [110.3133, -7.8947],
      [110.3136, -7.8946],
      [110.3145, -7.8939],
      [110.3151, -7.8939],
      [110.3154, -7.894],
      [110.3163, -7.8941],
      [110.3161, -7.8954],
      [110.3162, -7.8954],
      [110.3167, -7.8951],
      [110.317, -7.8951],
      [110.318, -7.8954],
      [110.3177, -7.8962],
      [110.3175, -7.8972],
      [110.3173, -7.8984],
      [110.3173, -7.899],
      [110.3174, -7.8991],
      [110.3194, -7.8995],
      [110.3196, -7.8993],
      [110.3198, -7.8989],
      [110.3202, -7.8979],
      [110.3207, -7.8967],
      [110.3208, -7.896],
      [110.3215, -7.8961],
      [110.3232, -7.8964],
      [110.3233, -7.8965],
      [110.3234, -7.8964],
      [110.3234, -7.8962],
      [110.3237, -7.8952],
      [110.3238, -7.8944],
      [110.3243, -7.8939],
      [110.3246, -7.8937],
      [110.3248, -7.8931],
      [110.3251, -7.8922],
      [110.3253, -7.8917],
      [110.3257, -7.89],
      [110.3261, -7.8885],
      [110.3263, -7.8874],
      [110.3262, -7.8874],
      [110.3253, -7.8871],
      [110.3251, -7.887],
      [110.3245, -7.8867],
      [110.3247, -7.8855],
      [110.3228, -7.8849],
      [110.3215, -7.8845],
      [110.3221, -7.8824],
      [110.3222, -7.8819],
      [110.321, -7.8816],
      [110.3202, -7.8815],
      [110.3192, -7.8826],
      [110.3196, -7.8799],
      [110.3198, -7.8788]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANTUL',
    village: 'BANTUL',
    border: [
      [110.3375, -7.8794],
      [110.3374, -7.8793],
      [110.3373, -7.8793],
      [110.3368, -7.8794],
      [110.3366, -7.8795],
      [110.3363, -7.879],
      [110.3362, -7.8789],
      [110.3363, -7.8781],
      [110.3365, -7.8773],
      [110.3366, -7.8771],
      [110.3359, -7.8768],
      [110.335, -7.8766],
      [110.3338, -7.8763],
      [110.3337, -7.8754],
      [110.3334, -7.8744],
      [110.3327, -7.8735],
      [110.3323, -7.873],
      [110.332, -7.8727],
      [110.3315, -7.8725],
      [110.3312, -7.8723],
      [110.3308, -7.8716],
      [110.3304, -7.871],
      [110.3306, -7.8707],
      [110.3305, -7.8704],
      [110.3307, -7.8702],
      [110.3309, -7.8693],
      [110.3295, -7.869],
      [110.3293, -7.8693],
      [110.3289, -7.8692],
      [110.3288, -7.869],
      [110.3289, -7.8686],
      [110.3292, -7.8676],
      [110.3295, -7.8669],
      [110.3298, -7.8661],
      [110.3295, -7.8659],
      [110.3294, -7.8659],
      [110.3288, -7.8657],
      [110.3284, -7.8655],
      [110.3281, -7.865],
      [110.3278, -7.8644],
      [110.3275, -7.8637],
      [110.3274, -7.8636],
      [110.3268, -7.8634],
      [110.3261, -7.8631],
      [110.3257, -7.8629],
      [110.325, -7.8622],
      [110.3244, -7.8616],
      [110.3242, -7.8614],
      [110.3238, -7.862],
      [110.3232, -7.8623],
      [110.3226, -7.863],
      [110.3217, -7.8643],
      [110.3224, -7.8645],
      [110.323, -7.8644],
      [110.3237, -7.8647],
      [110.3235, -7.8654],
      [110.3238, -7.8656],
      [110.324, -7.8653],
      [110.3246, -7.8656],
      [110.3245, -7.8666],
      [110.3241, -7.8674],
      [110.3236, -7.8686],
      [110.3233, -7.8696],
      [110.3244, -7.8709],
      [110.3249, -7.8711],
      [110.3252, -7.871],
      [110.3256, -7.871],
      [110.3251, -7.8715],
      [110.3245, -7.8719],
      [110.3242, -7.8724],
      [110.3238, -7.8726],
      [110.3236, -7.8732],
      [110.3235, -7.8734],
      [110.3233, -7.8737],
      [110.3225, -7.8745],
      [110.3221, -7.8765],
      [110.3218, -7.8766],
      [110.3217, -7.877],
      [110.3214, -7.8779],
      [110.321, -7.8784],
      [110.3207, -7.8784],
      [110.32, -7.8782],
      [110.3198, -7.8788],
      [110.3196, -7.8799],
      [110.3192, -7.8826],
      [110.3202, -7.8815],
      [110.321, -7.8816],
      [110.3222, -7.8819],
      [110.3221, -7.8824],
      [110.3215, -7.8845],
      [110.3228, -7.8849],
      [110.3247, -7.8855],
      [110.3245, -7.8867],
      [110.3251, -7.887],
      [110.3253, -7.8871],
      [110.3262, -7.8874],
      [110.3263, -7.8874],
      [110.3261, -7.8885],
      [110.3257, -7.89],
      [110.3253, -7.8917],
      [110.3251, -7.8922],
      [110.3248, -7.8931],
      [110.3246, -7.8937],
      [110.3243, -7.8939],
      [110.3238, -7.8944],
      [110.3237, -7.8952],
      [110.3234, -7.8962],
      [110.3234, -7.8964],
      [110.3233, -7.8965],
      [110.3235, -7.8966],
      [110.3245, -7.8968],
      [110.3254, -7.8969],
      [110.3268, -7.8972],
      [110.3278, -7.8974],
      [110.3281, -7.8975],
      [110.3281, -7.898],
      [110.328, -7.8986],
      [110.328, -7.8995],
      [110.3279, -7.9018],
      [110.3296, -7.9019],
      [110.331, -7.9015],
      [110.3325, -7.901],
      [110.3328, -7.9012],
      [110.3328, -7.9016],
      [110.3328, -7.9022],
      [110.3329, -7.9022],
      [110.3342, -7.9023],
      [110.3341, -7.9028],
      [110.3343, -7.9032],
      [110.3343, -7.9038],
      [110.3343, -7.9044],
      [110.3343, -7.9046],
      [110.3342, -7.9055],
      [110.3335, -7.9078],
      [110.334, -7.9086],
      [110.3343, -7.9086],
      [110.3347, -7.9088],
      [110.3352, -7.9092],
      [110.3354, -7.9085],
      [110.3356, -7.9081],
      [110.3359, -7.9077],
      [110.3363, -7.9067],
      [110.3363, -7.906],
      [110.3364, -7.9054],
      [110.3364, -7.9053],
      [110.3365, -7.9051],
      [110.3368, -7.9045],
      [110.337, -7.9038],
      [110.337, -7.9033],
      [110.337, -7.903],
      [110.3369, -7.9028],
      [110.3368, -7.9023],
      [110.3369, -7.902],
      [110.3371, -7.9017],
      [110.3372, -7.901],
      [110.3373, -7.9003],
      [110.3375, -7.9002],
      [110.3377, -7.9001],
      [110.3378, -7.8994],
      [110.3378, -7.8992],
      [110.3375, -7.8988],
      [110.3369, -7.8987],
      [110.3365, -7.8986],
      [110.3363, -7.8985],
      [110.3358, -7.8982],
      [110.3354, -7.898],
      [110.3355, -7.8968],
      [110.3356, -7.8952],
      [110.3356, -7.8941],
      [110.3351, -7.8935],
      [110.3349, -7.8933],
      [110.3347, -7.893],
      [110.3345, -7.8926],
      [110.3351, -7.8928],
      [110.3351, -7.8926],
      [110.3351, -7.8923],
      [110.3353, -7.8923],
      [110.3368, -7.8926],
      [110.3365, -7.892],
      [110.3363, -7.8915],
      [110.3364, -7.8912],
      [110.3354, -7.891],
      [110.3344, -7.8909],
      [110.3334, -7.8907],
      [110.3333, -7.8901],
      [110.3335, -7.8892],
      [110.3337, -7.8883],
      [110.3339, -7.8875],
      [110.334, -7.8868],
      [110.3345, -7.8862],
      [110.3351, -7.8857],
      [110.3353, -7.8855],
      [110.3353, -7.8853],
      [110.3357, -7.8854],
      [110.3362, -7.8856],
      [110.3363, -7.8856],
      [110.3368, -7.8857],
      [110.3375, -7.8858],
      [110.3381, -7.8861],
      [110.3383, -7.8861],
      [110.3383, -7.8859],
      [110.3381, -7.8857],
      [110.3381, -7.8855],
      [110.3382, -7.8847],
      [110.3386, -7.8848],
      [110.3386, -7.8843],
      [110.3383, -7.8842],
      [110.338, -7.8846],
      [110.3379, -7.8847],
      [110.3377, -7.8846],
      [110.3372, -7.8846],
      [110.3364, -7.8849],
      [110.3363, -7.8849],
      [110.3362, -7.8843],
      [110.3364, -7.883],
      [110.3367, -7.8818],
      [110.3367, -7.8817],
      [110.337, -7.8811],
      [110.3372, -7.8803],
      [110.3374, -7.8799],
      [110.3376, -7.8796],
      [110.3375, -7.8794]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANTUL',
    village: 'TRIRENGGO',
    border: [
      [110.3487, -7.885],
      [110.3483, -7.8849],
      [110.3481, -7.8845],
      [110.3478, -7.8834],
      [110.3481, -7.8833],
      [110.3484, -7.8829],
      [110.3487, -7.8823],
      [110.3487, -7.8821],
      [110.3481, -7.8814],
      [110.3482, -7.8801],
      [110.348, -7.8799],
      [110.3479, -7.8798],
      [110.3472, -7.8795],
      [110.3471, -7.8795],
      [110.3467, -7.8793],
      [110.3466, -7.8793],
      [110.3464, -7.8793],
      [110.3462, -7.8793],
      [110.3455, -7.8797],
      [110.345, -7.8802],
      [110.3448, -7.8802],
      [110.3447, -7.8802],
      [110.3443, -7.8802],
      [110.3431, -7.88],
      [110.3429, -7.8799],
      [110.3424, -7.8816],
      [110.3404, -7.8812],
      [110.3403, -7.8811],
      [110.3392, -7.8808],
      [110.3389, -7.8796],
      [110.3385, -7.8796],
      [110.3375, -7.8794],
      [110.3376, -7.8796],
      [110.3374, -7.8799],
      [110.3372, -7.8803],
      [110.337, -7.8811],
      [110.3367, -7.8817],
      [110.3367, -7.8818],
      [110.3364, -7.883],
      [110.3362, -7.8843],
      [110.3363, -7.8849],
      [110.3364, -7.8849],
      [110.3372, -7.8846],
      [110.3377, -7.8846],
      [110.3379, -7.8847],
      [110.338, -7.8846],
      [110.3383, -7.8842],
      [110.3386, -7.8843],
      [110.3386, -7.8848],
      [110.3382, -7.8847],
      [110.3381, -7.8855],
      [110.3381, -7.8857],
      [110.3383, -7.8859],
      [110.3383, -7.8861],
      [110.3381, -7.8861],
      [110.3375, -7.8858],
      [110.3368, -7.8857],
      [110.3363, -7.8856],
      [110.3362, -7.8856],
      [110.3357, -7.8854],
      [110.3353, -7.8853],
      [110.3353, -7.8855],
      [110.3351, -7.8857],
      [110.3345, -7.8862],
      [110.334, -7.8868],
      [110.3339, -7.8875],
      [110.3337, -7.8883],
      [110.3335, -7.8892],
      [110.3333, -7.8901],
      [110.3334, -7.8907],
      [110.3344, -7.8909],
      [110.3354, -7.891],
      [110.3364, -7.8912],
      [110.3363, -7.8915],
      [110.3365, -7.892],
      [110.3368, -7.8926],
      [110.3353, -7.8923],
      [110.3351, -7.8923],
      [110.3351, -7.8926],
      [110.3351, -7.8928],
      [110.3345, -7.8926],
      [110.3347, -7.893],
      [110.3349, -7.8933],
      [110.3351, -7.8935],
      [110.3356, -7.8941],
      [110.3356, -7.8952],
      [110.3355, -7.8968],
      [110.3354, -7.898],
      [110.3358, -7.8982],
      [110.3363, -7.8985],
      [110.3365, -7.8986],
      [110.3369, -7.8987],
      [110.3375, -7.8988],
      [110.3378, -7.8992],
      [110.3378, -7.8994],
      [110.3377, -7.9001],
      [110.3375, -7.9002],
      [110.3373, -7.9003],
      [110.3372, -7.901],
      [110.3371, -7.9017],
      [110.3369, -7.902],
      [110.3368, -7.9023],
      [110.3369, -7.9028],
      [110.337, -7.903],
      [110.337, -7.9033],
      [110.337, -7.9038],
      [110.3368, -7.9045],
      [110.3365, -7.9051],
      [110.3364, -7.9053],
      [110.3364, -7.9054],
      [110.3363, -7.906],
      [110.3363, -7.9067],
      [110.3359, -7.9077],
      [110.3356, -7.9081],
      [110.3354, -7.9085],
      [110.3352, -7.9092],
      [110.3353, -7.9092],
      [110.3362, -7.9091],
      [110.3363, -7.9099],
      [110.3363, -7.9101],
      [110.3362, -7.9113],
      [110.3361, -7.9125],
      [110.3361, -7.9127],
      [110.3353, -7.9126],
      [110.3353, -7.9123],
      [110.3345, -7.9121],
      [110.3343, -7.9125],
      [110.3342, -7.9132],
      [110.334, -7.9143],
      [110.3336, -7.9163],
      [110.3335, -7.9179],
      [110.3333, -7.9183],
      [110.3335, -7.9181],
      [110.3338, -7.9179],
      [110.3342, -7.9178],
      [110.3345, -7.9177],
      [110.3354, -7.9179],
      [110.3356, -7.9182],
      [110.3362, -7.9182],
      [110.3365, -7.9184],
      [110.3369, -7.9182],
      [110.3375, -7.9182],
      [110.3377, -7.9184],
      [110.338, -7.9176],
      [110.3383, -7.917],
      [110.3384, -7.9164],
      [110.3388, -7.9161],
      [110.3393, -7.9162],
      [110.3395, -7.9166],
      [110.3395, -7.917],
      [110.3398, -7.9171],
      [110.3404, -7.9172],
      [110.3407, -7.9175],
      [110.3409, -7.9178],
      [110.3414, -7.9178],
      [110.3422, -7.9182],
      [110.3421, -7.9188],
      [110.3418, -7.9192],
      [110.3418, -7.9196],
      [110.3418, -7.9197],
      [110.3425, -7.9197],
      [110.3427, -7.9198],
      [110.3429, -7.9199],
      [110.3432, -7.92],
      [110.3436, -7.9202],
      [110.3439, -7.9209],
      [110.3452, -7.9211],
      [110.346, -7.9213],
      [110.3462, -7.9207],
      [110.3464, -7.9202],
      [110.3467, -7.9201],
      [110.3467, -7.9196],
      [110.3468, -7.9192],
      [110.3469, -7.9191],
      [110.3458, -7.9185],
      [110.3451, -7.9184],
      [110.3445, -7.9181],
      [110.3441, -7.9179],
      [110.3438, -7.9176],
      [110.3436, -7.9172],
      [110.3438, -7.9169],
      [110.3442, -7.9165],
      [110.3447, -7.9162],
      [110.3448, -7.9162],
      [110.3454, -7.9156],
      [110.3455, -7.9155],
      [110.3463, -7.9145],
      [110.3467, -7.9141],
      [110.3468, -7.914],
      [110.3469, -7.9137],
      [110.3471, -7.9129],
      [110.3474, -7.9119],
      [110.3473, -7.9118],
      [110.3472, -7.9116],
      [110.3461, -7.9112],
      [110.3465, -7.9103],
      [110.3466, -7.9098],
      [110.3459, -7.9096],
      [110.3462, -7.9087],
      [110.3463, -7.9083],
      [110.3452, -7.9079],
      [110.3441, -7.9077],
      [110.3443, -7.907],
      [110.3439, -7.9066],
      [110.3438, -7.9061],
      [110.3435, -7.9059],
      [110.3437, -7.9049],
      [110.3451, -7.905],
      [110.345, -7.9055],
      [110.3461, -7.9056],
      [110.3461, -7.906],
      [110.3478, -7.9062],
      [110.3483, -7.9057],
      [110.3495, -7.9061],
      [110.3502, -7.9064],
      [110.3504, -7.9065],
      [110.3507, -7.9064],
      [110.3508, -7.9064],
      [110.3515, -7.9059],
      [110.3516, -7.9056],
      [110.3517, -7.9054],
      [110.3517, -7.9052],
      [110.3517, -7.9046],
      [110.3519, -7.9036],
      [110.3524, -7.9032],
      [110.3526, -7.9027],
      [110.3525, -7.9018],
      [110.3524, -7.9007],
      [110.3524, -7.9005],
      [110.3524, -7.9003],
      [110.3519, -7.8991],
      [110.3516, -7.8982],
      [110.3515, -7.8981],
      [110.351, -7.8977],
      [110.3508, -7.8974],
      [110.3499, -7.8968],
      [110.3501, -7.8961],
      [110.3503, -7.8952],
      [110.3503, -7.8945],
      [110.3502, -7.8936],
      [110.3504, -7.8922],
      [110.3502, -7.8921],
      [110.3503, -7.8914],
      [110.3499, -7.8912],
      [110.3499, -7.8908],
      [110.3498, -7.8907],
      [110.3493, -7.8904],
      [110.3492, -7.8898],
      [110.3491, -7.8894],
      [110.3493, -7.889],
      [110.3495, -7.8888],
      [110.3489, -7.8884],
      [110.3481, -7.8881],
      [110.3482, -7.8876],
      [110.3477, -7.8875],
      [110.3478, -7.887],
      [110.3482, -7.8869],
      [110.3482, -7.886],
      [110.348, -7.8854],
      [110.3484, -7.8851],
      [110.3485, -7.8851],
      [110.3487, -7.885]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANTUL',
    village: 'SABDODADI',
    border: [
      [110.3636, -7.8872],
      [110.3629, -7.8862],
      [110.3626, -7.8861],
      [110.3619, -7.8853],
      [110.3614, -7.8845],
      [110.361, -7.8837],
      [110.3611, -7.8832],
      [110.3611, -7.8827],
      [110.3612, -7.8815],
      [110.361, -7.8823],
      [110.3609, -7.8823],
      [110.3596, -7.8823],
      [110.3595, -7.8823],
      [110.3595, -7.8832],
      [110.3595, -7.8835],
      [110.3595, -7.886],
      [110.3581, -7.8859],
      [110.3579, -7.8867],
      [110.3569, -7.8865],
      [110.3566, -7.8862],
      [110.3563, -7.8861],
      [110.3563, -7.8848],
      [110.3563, -7.884],
      [110.3565, -7.8833],
      [110.3567, -7.8828],
      [110.3567, -7.8822],
      [110.3562, -7.882],
      [110.3551, -7.8818],
      [110.3555, -7.8821],
      [110.3555, -7.8825],
      [110.3554, -7.883],
      [110.3555, -7.8833],
      [110.3552, -7.8841],
      [110.3549, -7.8845],
      [110.3548, -7.8849],
      [110.355, -7.885],
      [110.3551, -7.885],
      [110.3552, -7.8851],
      [110.3554, -7.8851],
      [110.3553, -7.8856],
      [110.3552, -7.8856],
      [110.3545, -7.8856],
      [110.3536, -7.8855],
      [110.3536, -7.8864],
      [110.3535, -7.8872],
      [110.3532, -7.8876],
      [110.3533, -7.8877],
      [110.3533, -7.8879],
      [110.3533, -7.888],
      [110.353, -7.8881],
      [110.3515, -7.8881],
      [110.351, -7.8881],
      [110.3509, -7.8874],
      [110.3502, -7.8875],
      [110.3497, -7.8858],
      [110.3495, -7.8854],
      [110.349, -7.885],
      [110.3487, -7.885],
      [110.3485, -7.8851],
      [110.3484, -7.8851],
      [110.348, -7.8854],
      [110.3482, -7.886],
      [110.3482, -7.8869],
      [110.3478, -7.887],
      [110.3477, -7.8875],
      [110.3482, -7.8876],
      [110.3481, -7.8881],
      [110.3489, -7.8884],
      [110.3495, -7.8888],
      [110.3493, -7.889],
      [110.3491, -7.8894],
      [110.3492, -7.8898],
      [110.3493, -7.8904],
      [110.3498, -7.8907],
      [110.3499, -7.8908],
      [110.3499, -7.8912],
      [110.3503, -7.8914],
      [110.3502, -7.8921],
      [110.3504, -7.8922],
      [110.3502, -7.8936],
      [110.3503, -7.8945],
      [110.3503, -7.8952],
      [110.3501, -7.8961],
      [110.3499, -7.8968],
      [110.3508, -7.8974],
      [110.351, -7.8977],
      [110.3515, -7.8981],
      [110.3516, -7.8982],
      [110.3519, -7.8991],
      [110.3524, -7.9003],
      [110.3524, -7.9005],
      [110.3524, -7.9007],
      [110.3527, -7.9008],
      [110.3529, -7.9008],
      [110.3528, -7.8996],
      [110.3528, -7.8984],
      [110.3532, -7.8983],
      [110.3532, -7.8992],
      [110.3539, -7.899],
      [110.3539, -7.8986],
      [110.3539, -7.8974],
      [110.354, -7.8968],
      [110.3543, -7.8968],
      [110.3544, -7.8973],
      [110.3544, -7.898],
      [110.3541, -7.898],
      [110.354, -7.8987],
      [110.3551, -7.8997],
      [110.3562, -7.8997],
      [110.3563, -7.9002],
      [110.3567, -7.9004],
      [110.3578, -7.9002],
      [110.3579, -7.9],
      [110.3578, -7.8989],
      [110.358, -7.8985],
      [110.3582, -7.8982],
      [110.3588, -7.8981],
      [110.3601, -7.8981],
      [110.3607, -7.8979],
      [110.361, -7.8977],
      [110.3612, -7.8977],
      [110.3615, -7.8971],
      [110.3614, -7.8967],
      [110.3617, -7.8962],
      [110.3615, -7.8962],
      [110.3613, -7.8961],
      [110.3613, -7.896],
      [110.3613, -7.8941],
      [110.3615, -7.8916],
      [110.3616, -7.891],
      [110.3623, -7.891],
      [110.3626, -7.891],
      [110.3625, -7.89],
      [110.3626, -7.8899],
      [110.3627, -7.8885],
      [110.3633, -7.8886],
      [110.3634, -7.8874],
      [110.3636, -7.8872]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'JETIS',
    village: 'PATALAN',
    border: [
      [110.3563, -7.9002],
      [110.3562, -7.8997],
      [110.3551, -7.8997],
      [110.354, -7.8987],
      [110.3541, -7.898],
      [110.3544, -7.898],
      [110.3544, -7.8973],
      [110.3543, -7.8968],
      [110.354, -7.8968],
      [110.3539, -7.8974],
      [110.3539, -7.8986],
      [110.3539, -7.899],
      [110.3532, -7.8992],
      [110.3532, -7.8983],
      [110.3528, -7.8984],
      [110.3528, -7.8996],
      [110.3529, -7.9008],
      [110.3527, -7.9008],
      [110.3524, -7.9007],
      [110.3525, -7.9018],
      [110.3526, -7.9027],
      [110.3524, -7.9032],
      [110.3519, -7.9036],
      [110.3517, -7.9046],
      [110.3517, -7.9052],
      [110.3517, -7.9054],
      [110.3516, -7.9056],
      [110.3515, -7.9059],
      [110.3508, -7.9064],
      [110.3507, -7.9064],
      [110.3504, -7.9065],
      [110.3502, -7.9064],
      [110.3495, -7.9061],
      [110.3483, -7.9057],
      [110.3478, -7.9062],
      [110.3461, -7.906],
      [110.3461, -7.9056],
      [110.345, -7.9055],
      [110.3451, -7.905],
      [110.3437, -7.9049],
      [110.3435, -7.9059],
      [110.3438, -7.9061],
      [110.3439, -7.9066],
      [110.3443, -7.907],
      [110.3441, -7.9077],
      [110.3452, -7.9079],
      [110.3463, -7.9083],
      [110.3462, -7.9087],
      [110.3459, -7.9096],
      [110.3466, -7.9098],
      [110.3465, -7.9103],
      [110.3461, -7.9112],
      [110.3472, -7.9116],
      [110.3473, -7.9118],
      [110.3474, -7.9119],
      [110.3471, -7.9129],
      [110.3469, -7.9137],
      [110.3468, -7.914],
      [110.3467, -7.9141],
      [110.3463, -7.9145],
      [110.3455, -7.9155],
      [110.3454, -7.9156],
      [110.3448, -7.9162],
      [110.3447, -7.9162],
      [110.3442, -7.9165],
      [110.3438, -7.9169],
      [110.3436, -7.9172],
      [110.3438, -7.9176],
      [110.3441, -7.9179],
      [110.3445, -7.9181],
      [110.3451, -7.9184],
      [110.3458, -7.9185],
      [110.3469, -7.9191],
      [110.3474, -7.9193],
      [110.3478, -7.9195],
      [110.3479, -7.9199],
      [110.3478, -7.9207],
      [110.3476, -7.9218],
      [110.3473, -7.9225],
      [110.3469, -7.9231],
      [110.3465, -7.9236],
      [110.346, -7.9243],
      [110.3458, -7.9247],
      [110.3453, -7.9249],
      [110.3451, -7.9249],
      [110.3448, -7.9245],
      [110.3445, -7.9245],
      [110.3441, -7.9244],
      [110.3428, -7.9243],
      [110.3421, -7.9242],
      [110.3413, -7.9242],
      [110.3408, -7.9243],
      [110.3406, -7.9248],
      [110.3404, -7.9256],
      [110.3396, -7.9257],
      [110.3395, -7.9265],
      [110.3389, -7.9279],
      [110.3379, -7.9297],
      [110.3377, -7.9303],
      [110.3372, -7.9303],
      [110.3369, -7.931],
      [110.3365, -7.9312],
      [110.3362, -7.9317],
      [110.3358, -7.9322],
      [110.3356, -7.9327],
      [110.3354, -7.9339],
      [110.3351, -7.9346],
      [110.3348, -7.9348],
      [110.3347, -7.9349],
      [110.3342, -7.935],
      [110.3341, -7.9351],
      [110.334, -7.9352],
      [110.334, -7.9357],
      [110.334, -7.9363],
      [110.334, -7.9364],
      [110.334, -7.9368],
      [110.3339, -7.9372],
      [110.3337, -7.9374],
      [110.3336, -7.9375],
      [110.3332, -7.9377],
      [110.333, -7.9377],
      [110.3328, -7.9378],
      [110.3327, -7.9381],
      [110.3324, -7.9389],
      [110.3322, -7.9398],
      [110.3321, -7.9401],
      [110.3322, -7.9403],
      [110.3334, -7.9418],
      [110.3341, -7.941],
      [110.3343, -7.9401],
      [110.335, -7.9402],
      [110.3353, -7.9392],
      [110.3355, -7.9387],
      [110.3366, -7.9382],
      [110.3372, -7.9378],
      [110.3377, -7.9377],
      [110.338, -7.9376],
      [110.3386, -7.9377],
      [110.3388, -7.9378],
      [110.3391, -7.938],
      [110.3384, -7.939],
      [110.3396, -7.9393],
      [110.3394, -7.9401],
      [110.3403, -7.9403],
      [110.3405, -7.9408],
      [110.3405, -7.9413],
      [110.3409, -7.9416],
      [110.341, -7.9422],
      [110.3412, -7.9427],
      [110.3404, -7.9429],
      [110.3398, -7.9432],
      [110.3395, -7.9437],
      [110.3394, -7.9441],
      [110.34, -7.9442],
      [110.3404, -7.9437],
      [110.341, -7.9435],
      [110.3414, -7.9436],
      [110.3416, -7.9439],
      [110.3421, -7.944],
      [110.3424, -7.944],
      [110.3423, -7.9446],
      [110.3441, -7.9448],
      [110.3446, -7.9449],
      [110.3457, -7.9449],
      [110.3459, -7.945],
      [110.3474, -7.945],
      [110.3485, -7.9449],
      [110.3497, -7.9447],
      [110.3498, -7.944],
      [110.3499, -7.9435],
      [110.35, -7.9431],
      [110.3498, -7.9429],
      [110.3496, -7.9425],
      [110.3495, -7.9422],
      [110.3489, -7.9418],
      [110.3487, -7.9408],
      [110.3487, -7.9401],
      [110.3486, -7.9397],
      [110.3485, -7.9393],
      [110.3486, -7.9388],
      [110.3481, -7.9387],
      [110.3481, -7.9384],
      [110.3478, -7.9383],
      [110.3478, -7.9378],
      [110.348, -7.9371],
      [110.3482, -7.9365],
      [110.3487, -7.9365],
      [110.3488, -7.9357],
      [110.3503, -7.9357],
      [110.3502, -7.9347],
      [110.3502, -7.9332],
      [110.3503, -7.9328],
      [110.3503, -7.9319],
      [110.3504, -7.9319],
      [110.3522, -7.9321],
      [110.3527, -7.93],
      [110.3532, -7.9274],
      [110.3536, -7.9274],
      [110.3545, -7.9274],
      [110.3548, -7.926],
      [110.3552, -7.9261],
      [110.3555, -7.9261],
      [110.3557, -7.926],
      [110.3557, -7.9253],
      [110.3541, -7.925],
      [110.3539, -7.9249],
      [110.3543, -7.9232],
      [110.3548, -7.9207],
      [110.3549, -7.9201],
      [110.3558, -7.9202],
      [110.3555, -7.9222],
      [110.3561, -7.9222],
      [110.3566, -7.9194],
      [110.357, -7.9176],
      [110.3571, -7.9171],
      [110.3577, -7.9163],
      [110.3587, -7.9163],
      [110.359, -7.9144],
      [110.3592, -7.9137],
      [110.3595, -7.9134],
      [110.3578, -7.913],
      [110.3557, -7.9125],
      [110.3553, -7.9122],
      [110.3548, -7.9119],
      [110.3549, -7.9117],
      [110.355, -7.9105],
      [110.3543, -7.9103],
      [110.3544, -7.9102],
      [110.355, -7.9074],
      [110.3552, -7.9061],
      [110.3553, -7.905],
      [110.3556, -7.9038],
      [110.3555, -7.9023],
      [110.3556, -7.9023],
      [110.3558, -7.9022],
      [110.3558, -7.9021],
      [110.3559, -7.9015],
      [110.3555, -7.9011],
      [110.3559, -7.9004],
      [110.3563, -7.9002]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'JETIS',
    village: 'CANDEN',
    border: [
      [110.3683, -7.9199],
      [110.3683, -7.9198],
      [110.3683, -7.919],
      [110.3685, -7.9177],
      [110.3687, -7.9164],
      [110.3687, -7.9158],
      [110.369, -7.9143],
      [110.3693, -7.9142],
      [110.3695, -7.9136],
      [110.3695, -7.9131],
      [110.3698, -7.912],
      [110.3696, -7.912],
      [110.3692, -7.912],
      [110.3685, -7.9118],
      [110.3686, -7.9114],
      [110.3682, -7.9109],
      [110.3674, -7.9109],
      [110.3673, -7.9109],
      [110.367, -7.9117],
      [110.3666, -7.9123],
      [110.366, -7.9123],
      [110.3646, -7.9121],
      [110.3633, -7.9119],
      [110.3631, -7.9123],
      [110.363, -7.9124],
      [110.3622, -7.9125],
      [110.3618, -7.9126],
      [110.3614, -7.9127],
      [110.3604, -7.9129],
      [110.3597, -7.913],
      [110.3595, -7.9134],
      [110.3592, -7.9137],
      [110.359, -7.9144],
      [110.3587, -7.9163],
      [110.3577, -7.9163],
      [110.3571, -7.9171],
      [110.357, -7.9176],
      [110.3566, -7.9194],
      [110.3561, -7.9222],
      [110.3555, -7.9222],
      [110.3558, -7.9202],
      [110.3549, -7.9201],
      [110.3548, -7.9207],
      [110.3543, -7.9232],
      [110.3539, -7.9249],
      [110.3541, -7.925],
      [110.3557, -7.9253],
      [110.3557, -7.926],
      [110.3555, -7.9261],
      [110.3552, -7.9261],
      [110.3548, -7.926],
      [110.3545, -7.9274],
      [110.3536, -7.9274],
      [110.3532, -7.9274],
      [110.3527, -7.93],
      [110.3522, -7.9321],
      [110.3504, -7.9319],
      [110.3503, -7.9319],
      [110.3503, -7.9328],
      [110.3502, -7.9332],
      [110.3502, -7.9347],
      [110.3503, -7.9357],
      [110.3488, -7.9357],
      [110.3487, -7.9365],
      [110.3482, -7.9365],
      [110.348, -7.9371],
      [110.3478, -7.9378],
      [110.3478, -7.9383],
      [110.3481, -7.9384],
      [110.3481, -7.9387],
      [110.3486, -7.9388],
      [110.3485, -7.9393],
      [110.3486, -7.9397],
      [110.3487, -7.9401],
      [110.3487, -7.9408],
      [110.3489, -7.9418],
      [110.3495, -7.9422],
      [110.3496, -7.9425],
      [110.3498, -7.9429],
      [110.35, -7.9431],
      [110.3499, -7.9435],
      [110.3498, -7.944],
      [110.3497, -7.9447],
      [110.3501, -7.9462],
      [110.3503, -7.9461],
      [110.3507, -7.9459],
      [110.3511, -7.9459],
      [110.3521, -7.946],
      [110.3524, -7.946],
      [110.3536, -7.9459],
      [110.354, -7.9453],
      [110.3541, -7.9452],
      [110.3545, -7.9447],
      [110.3554, -7.9447],
      [110.3561, -7.9448],
      [110.3564, -7.9448],
      [110.3565, -7.9448],
      [110.3569, -7.9448],
      [110.3591, -7.9447],
      [110.3593, -7.9446],
      [110.362, -7.9445],
      [110.362, -7.9443],
      [110.3621, -7.9441],
      [110.3629, -7.9443],
      [110.3631, -7.9445],
      [110.3637, -7.9447],
      [110.3649, -7.945],
      [110.3658, -7.945],
      [110.3659, -7.9451],
      [110.3662, -7.9454],
      [110.3668, -7.9442],
      [110.367, -7.9433],
      [110.3671, -7.9425],
      [110.3668, -7.942],
      [110.366, -7.9421],
      [110.3653, -7.9423],
      [110.3642, -7.9421],
      [110.3635, -7.9416],
      [110.363, -7.9406],
      [110.3628, -7.9396],
      [110.3628, -7.9385],
      [110.3631, -7.9377],
      [110.3633, -7.9371],
      [110.3641, -7.9354],
      [110.3652, -7.9341],
      [110.366, -7.9332],
      [110.3665, -7.9328],
      [110.3668, -7.9319],
      [110.3673, -7.9303],
      [110.3669, -7.9291],
      [110.3666, -7.9278],
      [110.3667, -7.9265],
      [110.367, -7.9251],
      [110.3672, -7.9241],
      [110.3672, -7.9239],
      [110.367, -7.9225],
      [110.3669, -7.9211],
      [110.367, -7.9204],
      [110.3678, -7.9199],
      [110.3683, -7.9199]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'JETIS',
    village: 'SUMBERAGUNG',
    border: [
      [110.3744, -7.8834],
      [110.3736, -7.8833],
      [110.3727, -7.8833],
      [110.3723, -7.8832],
      [110.3721, -7.8829],
      [110.3723, -7.8822],
      [110.3715, -7.8821],
      [110.3711, -7.8821],
      [110.3704, -7.8819],
      [110.3696, -7.8819],
      [110.3694, -7.8818],
      [110.3678, -7.8814],
      [110.3663, -7.8812],
      [110.3661, -7.8817],
      [110.366, -7.8819],
      [110.3657, -7.8826],
      [110.3655, -7.8831],
      [110.3655, -7.8835],
      [110.3658, -7.8839],
      [110.3657, -7.8843],
      [110.3655, -7.8847],
      [110.3653, -7.885],
      [110.3652, -7.8855],
      [110.3653, -7.8856],
      [110.3659, -7.8857],
      [110.3659, -7.8858],
      [110.3659, -7.8861],
      [110.3657, -7.8863],
      [110.3654, -7.8865],
      [110.3648, -7.8869],
      [110.3647, -7.8875],
      [110.3643, -7.8875],
      [110.3636, -7.8872],
      [110.3634, -7.8874],
      [110.3633, -7.8886],
      [110.3627, -7.8885],
      [110.3626, -7.8899],
      [110.3625, -7.89],
      [110.3626, -7.891],
      [110.3623, -7.891],
      [110.3616, -7.891],
      [110.3615, -7.8916],
      [110.3613, -7.8941],
      [110.3613, -7.896],
      [110.3613, -7.8961],
      [110.3615, -7.8962],
      [110.3617, -7.8962],
      [110.3614, -7.8967],
      [110.3615, -7.8971],
      [110.3612, -7.8977],
      [110.361, -7.8977],
      [110.3607, -7.8979],
      [110.3601, -7.8981],
      [110.3588, -7.8981],
      [110.3582, -7.8982],
      [110.358, -7.8985],
      [110.3578, -7.8989],
      [110.3579, -7.9],
      [110.3578, -7.9002],
      [110.3567, -7.9004],
      [110.3563, -7.9002],
      [110.3559, -7.9004],
      [110.3555, -7.9011],
      [110.3559, -7.9015],
      [110.3558, -7.9021],
      [110.3558, -7.9022],
      [110.3556, -7.9023],
      [110.3555, -7.9023],
      [110.3556, -7.9038],
      [110.3553, -7.905],
      [110.3552, -7.9061],
      [110.355, -7.9074],
      [110.3544, -7.9102],
      [110.3543, -7.9103],
      [110.355, -7.9105],
      [110.3549, -7.9117],
      [110.3548, -7.9119],
      [110.3553, -7.9122],
      [110.3557, -7.9125],
      [110.3578, -7.913],
      [110.3595, -7.9134],
      [110.3597, -7.913],
      [110.3604, -7.9129],
      [110.3614, -7.9127],
      [110.3618, -7.9126],
      [110.3622, -7.9125],
      [110.363, -7.9124],
      [110.3631, -7.9123],
      [110.3633, -7.9119],
      [110.3646, -7.9121],
      [110.366, -7.9123],
      [110.3666, -7.9123],
      [110.367, -7.9117],
      [110.3673, -7.9109],
      [110.3674, -7.9109],
      [110.3682, -7.9109],
      [110.3686, -7.9114],
      [110.3685, -7.9118],
      [110.3692, -7.912],
      [110.3696, -7.912],
      [110.3698, -7.912],
      [110.3695, -7.9131],
      [110.3695, -7.9136],
      [110.3693, -7.9142],
      [110.369, -7.9143],
      [110.3687, -7.9158],
      [110.3687, -7.9164],
      [110.3685, -7.9177],
      [110.3683, -7.919],
      [110.3683, -7.9198],
      [110.3683, -7.9199],
      [110.3686, -7.9199],
      [110.3697, -7.92],
      [110.3707, -7.92],
      [110.3716, -7.9197],
      [110.3723, -7.9195],
      [110.373, -7.9198],
      [110.3733, -7.9199],
      [110.3741, -7.9196],
      [110.375, -7.9191],
      [110.3754, -7.9186],
      [110.3756, -7.9183],
      [110.3758, -7.918],
      [110.376, -7.9177],
      [110.376, -7.9173],
      [110.3757, -7.9166],
      [110.3754, -7.9157],
      [110.3755, -7.9153],
      [110.3755, -7.915],
      [110.3758, -7.9143],
      [110.3757, -7.9136],
      [110.3757, -7.9135],
      [110.3761, -7.9126],
      [110.3765, -7.912],
      [110.3769, -7.9115],
      [110.3775, -7.911],
      [110.3777, -7.9104],
      [110.3779, -7.91],
      [110.3782, -7.9094],
      [110.3786, -7.9088],
      [110.3789, -7.9086],
      [110.3791, -7.9085],
      [110.3789, -7.9083],
      [110.3787, -7.9081],
      [110.3783, -7.908],
      [110.3781, -7.9079],
      [110.3776, -7.9086],
      [110.3768, -7.9083],
      [110.3769, -7.9077],
      [110.3764, -7.9075],
      [110.3759, -7.9073],
      [110.3758, -7.9071],
      [110.3754, -7.9072],
      [110.375, -7.9074],
      [110.3748, -7.9075],
      [110.3747, -7.908],
      [110.374, -7.9078],
      [110.3737, -7.9086],
      [110.374, -7.9089],
      [110.374, -7.9092],
      [110.3728, -7.909],
      [110.3728, -7.9062],
      [110.3727, -7.9037],
      [110.3734, -7.9037],
      [110.3735, -7.9043],
      [110.3743, -7.9043],
      [110.3743, -7.9036],
      [110.3749, -7.9036],
      [110.3751, -7.9024],
      [110.3746, -7.9021],
      [110.3741, -7.9024],
      [110.374, -7.9022],
      [110.3727, -7.902],
      [110.3728, -7.9005],
      [110.3728, -7.9003],
      [110.3728, -7.9001],
      [110.3728, -7.8999],
      [110.3722, -7.8998],
      [110.3716, -7.8997],
      [110.372, -7.8979],
      [110.3721, -7.8973],
      [110.3741, -7.8975],
      [110.3749, -7.8976],
      [110.3757, -7.8976],
      [110.3759, -7.8976],
      [110.376, -7.8963],
      [110.3764, -7.8963],
      [110.3766, -7.8962],
      [110.3766, -7.8958],
      [110.3763, -7.8952],
      [110.3766, -7.8946],
      [110.3761, -7.8942],
      [110.3762, -7.8934],
      [110.3766, -7.8933],
      [110.3769, -7.8911],
      [110.3765, -7.8912],
      [110.3767, -7.89],
      [110.3766, -7.89],
      [110.3739, -7.8895],
      [110.3741, -7.8866],
      [110.3742, -7.8848],
      [110.3744, -7.8834]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'JETIS',
    village: 'TRIMULYO',
    border: [
      [110.3938, -7.8775],
      [110.3935, -7.8775],
      [110.3929, -7.8773],
      [110.3923, -7.8771],
      [110.3908, -7.8767],
      [110.3901, -7.8769],
      [110.389, -7.8769],
      [110.388, -7.8771],
      [110.387, -7.8772],
      [110.3862, -7.8771],
      [110.386, -7.8768],
      [110.386, -7.8761],
      [110.386, -7.8754],
      [110.3856, -7.8752],
      [110.3842, -7.8753],
      [110.3835, -7.8753],
      [110.3832, -7.8753],
      [110.3829, -7.8753],
      [110.3823, -7.8757],
      [110.3822, -7.876],
      [110.3819, -7.8767],
      [110.3817, -7.8776],
      [110.3816, -7.8786],
      [110.3812, -7.8785],
      [110.3802, -7.8784],
      [110.3793, -7.8783],
      [110.3792, -7.8776],
      [110.379, -7.8772],
      [110.3786, -7.877],
      [110.3781, -7.8769],
      [110.3776, -7.8768],
      [110.3771, -7.8765],
      [110.377, -7.8774],
      [110.3768, -7.879],
      [110.3763, -7.8803],
      [110.3763, -7.8808],
      [110.3763, -7.8815],
      [110.3761, -7.8822],
      [110.3751, -7.8824],
      [110.3745, -7.8825],
      [110.3744, -7.8831],
      [110.3744, -7.8834],
      [110.3742, -7.8848],
      [110.3741, -7.8866],
      [110.3739, -7.8895],
      [110.3766, -7.89],
      [110.3767, -7.89],
      [110.3765, -7.8912],
      [110.3769, -7.8911],
      [110.3766, -7.8933],
      [110.3762, -7.8934],
      [110.3761, -7.8942],
      [110.3766, -7.8946],
      [110.3763, -7.8952],
      [110.3766, -7.8958],
      [110.3766, -7.8962],
      [110.3764, -7.8963],
      [110.376, -7.8963],
      [110.3759, -7.8976],
      [110.3757, -7.8976],
      [110.3749, -7.8976],
      [110.3741, -7.8975],
      [110.3721, -7.8973],
      [110.372, -7.8979],
      [110.3716, -7.8997],
      [110.3722, -7.8998],
      [110.3728, -7.8999],
      [110.3728, -7.9001],
      [110.3728, -7.9003],
      [110.3728, -7.9005],
      [110.3727, -7.902],
      [110.374, -7.9022],
      [110.3741, -7.9024],
      [110.3746, -7.9021],
      [110.3751, -7.9024],
      [110.3749, -7.9036],
      [110.3743, -7.9036],
      [110.3743, -7.9043],
      [110.3735, -7.9043],
      [110.3734, -7.9037],
      [110.3727, -7.9037],
      [110.3728, -7.9062],
      [110.3728, -7.909],
      [110.374, -7.9092],
      [110.374, -7.9089],
      [110.3737, -7.9086],
      [110.374, -7.9078],
      [110.3747, -7.908],
      [110.3748, -7.9075],
      [110.375, -7.9074],
      [110.3754, -7.9072],
      [110.3758, -7.9071],
      [110.3759, -7.9073],
      [110.3764, -7.9075],
      [110.3769, -7.9077],
      [110.3768, -7.9083],
      [110.3776, -7.9086],
      [110.3781, -7.9079],
      [110.3783, -7.908],
      [110.3787, -7.9081],
      [110.3789, -7.9083],
      [110.3791, -7.9085],
      [110.3794, -7.9084],
      [110.3799, -7.9076],
      [110.381, -7.906],
      [110.3814, -7.9052],
      [110.3815, -7.9044],
      [110.3825, -7.904],
      [110.384, -7.9038],
      [110.3856, -7.9038],
      [110.3866, -7.9038],
      [110.3873, -7.9036],
      [110.3881, -7.9029],
      [110.3885, -7.9022],
      [110.3886, -7.9017],
      [110.3884, -7.9015],
      [110.3879, -7.9016],
      [110.3873, -7.9015],
      [110.3868, -7.9013],
      [110.3863, -7.901],
      [110.386, -7.9004],
      [110.3857, -7.8999],
      [110.3857, -7.899],
      [110.3857, -7.8981],
      [110.386, -7.8973],
      [110.3861, -7.8972],
      [110.3865, -7.8969],
      [110.3866, -7.897],
      [110.3876, -7.8972],
      [110.3883, -7.8974],
      [110.3893, -7.8975],
      [110.3899, -7.8975],
      [110.3907, -7.8974],
      [110.3911, -7.8974],
      [110.3915, -7.8976],
      [110.392, -7.8977],
      [110.3929, -7.8983],
      [110.3935, -7.8989],
      [110.3941, -7.8993],
      [110.3945, -7.8991],
      [110.3949, -7.8986],
      [110.3952, -7.898],
      [110.3955, -7.8977],
      [110.3955, -7.8969],
      [110.3954, -7.8969],
      [110.395, -7.8964],
      [110.3947, -7.8959],
      [110.3941, -7.8954],
      [110.3941, -7.8944],
      [110.3943, -7.8936],
      [110.3942, -7.8928],
      [110.3942, -7.8922],
      [110.3946, -7.8913],
      [110.3948, -7.8908],
      [110.395, -7.8905],
      [110.3952, -7.8893],
      [110.3953, -7.8874],
      [110.3961, -7.8872],
      [110.3968, -7.887],
      [110.397, -7.8866],
      [110.397, -7.8863],
      [110.3969, -7.8859],
      [110.3966, -7.8856],
      [110.3962, -7.8855],
      [110.3958, -7.8854],
      [110.3956, -7.8849],
      [110.3951, -7.8833],
      [110.3949, -7.8817],
      [110.395, -7.8806],
      [110.395, -7.8803],
      [110.3947, -7.8802],
      [110.3942, -7.8803],
      [110.394, -7.8799],
      [110.394, -7.8792],
      [110.3943, -7.8786],
      [110.3945, -7.8782],
      [110.394, -7.8775],
      [110.3938, -7.8775]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'SELOPAMIORO',
    border: [
      [110.4251, -7.9517],
      [110.4252, -7.9509],
      [110.4254, -7.9501],
      [110.4259, -7.9494],
      [110.4264, -7.9491],
      [110.4266, -7.9491],
      [110.4267, -7.9491],
      [110.4269, -7.9491],
      [110.4274, -7.9473],
      [110.4272, -7.9466],
      [110.4272, -7.9466],
      [110.4267, -7.946],
      [110.4267, -7.9459],
      [110.4254, -7.9454],
      [110.4247, -7.9453],
      [110.4239, -7.9454],
      [110.4227, -7.9456],
      [110.4213, -7.9454],
      [110.4193, -7.9454],
      [110.4185, -7.9454],
      [110.4176, -7.9455],
      [110.4166, -7.946],
      [110.4156, -7.946],
      [110.4145, -7.9458],
      [110.4135, -7.9453],
      [110.4125, -7.9445],
      [110.412, -7.9439],
      [110.4112, -7.9436],
      [110.4104, -7.9435],
      [110.4095, -7.9437],
      [110.4084, -7.944],
      [110.4074, -7.9441],
      [110.4061, -7.9441],
      [110.4047, -7.9442],
      [110.4042, -7.9444],
      [110.4028, -7.945],
      [110.4016, -7.9453],
      [110.4007, -7.9455],
      [110.3991, -7.9458],
      [110.3967, -7.9459],
      [110.395, -7.946],
      [110.3945, -7.9461],
      [110.3938, -7.9466],
      [110.3925, -7.9479],
      [110.3915, -7.9484],
      [110.3902, -7.9497],
      [110.389, -7.9507],
      [110.388, -7.9515],
      [110.3877, -7.9515],
      [110.3869, -7.9516],
      [110.386, -7.9517],
      [110.3848, -7.9517],
      [110.3837, -7.9516],
      [110.383, -7.9515],
      [110.3823, -7.9511],
      [110.3817, -7.9506],
      [110.3816, -7.9499],
      [110.3814, -7.9494],
      [110.3809, -7.9488],
      [110.3801, -7.9479],
      [110.3791, -7.9473],
      [110.3786, -7.9473],
      [110.3781, -7.9474],
      [110.378, -7.9474],
      [110.3772, -7.9475],
      [110.3762, -7.9474],
      [110.3759, -7.9482],
      [110.3758, -7.9487],
      [110.3758, -7.9505],
      [110.3759, -7.9519],
      [110.3759, -7.9529],
      [110.376, -7.9542],
      [110.376, -7.9544],
      [110.376, -7.9548],
      [110.376, -7.9559],
      [110.3759, -7.9565],
      [110.3753, -7.9573],
      [110.375, -7.9572],
      [110.3747, -7.9569],
      [110.3745, -7.9566],
      [110.3739, -7.9561],
      [110.3736, -7.9559],
      [110.3734, -7.9553],
      [110.3733, -7.955],
      [110.3732, -7.9546],
      [110.3725, -7.9539],
      [110.3712, -7.9533],
      [110.3707, -7.9532],
      [110.3704, -7.9534],
      [110.3698, -7.9538],
      [110.3692, -7.9542],
      [110.3685, -7.9545],
      [110.368, -7.955],
      [110.3674, -7.9555],
      [110.3666, -7.9558],
      [110.3659, -7.956],
      [110.3651, -7.9558],
      [110.3645, -7.9562],
      [110.3625, -7.9569],
      [110.3616, -7.9572],
      [110.3608, -7.9574],
      [110.36, -7.9577],
      [110.3597, -7.958],
      [110.3598, -7.9584],
      [110.36, -7.9595],
      [110.3605, -7.9605],
      [110.3618, -7.9609],
      [110.363, -7.9616],
      [110.3642, -7.9624],
      [110.3643, -7.9633],
      [110.3643, -7.964],
      [110.3645, -7.9644],
      [110.3645, -7.9649],
      [110.3644, -7.9656],
      [110.3645, -7.9665],
      [110.3646, -7.9673],
      [110.3651, -7.9687],
      [110.3655, -7.9696],
      [110.3662, -7.97],
      [110.3668, -7.9709],
      [110.367, -7.9718],
      [110.3673, -7.973],
      [110.3675, -7.9737],
      [110.3675, -7.9738],
      [110.3684, -7.9744],
      [110.37, -7.9756],
      [110.3704, -7.9759],
      [110.3706, -7.9764],
      [110.3712, -7.9767],
      [110.3717, -7.9772],
      [110.3726, -7.9784],
      [110.3739, -7.9793],
      [110.3755, -7.9795],
      [110.3761, -7.9793],
      [110.377, -7.9794],
      [110.3782, -7.9795],
      [110.3793, -7.9797],
      [110.3796, -7.9799],
      [110.3799, -7.9796],
      [110.38, -7.9795],
      [110.3807, -7.9799],
      [110.3809, -7.9799],
      [110.3811, -7.9795],
      [110.3813, -7.9793],
      [110.382, -7.9794],
      [110.383, -7.9795],
      [110.3834, -7.9797],
      [110.3841, -7.9797],
      [110.3849, -7.9795],
      [110.3858, -7.9794],
      [110.3865, -7.9795],
      [110.3877, -7.9801],
      [110.3885, -7.9805],
      [110.3903, -7.9809],
      [110.3915, -7.9812],
      [110.3916, -7.9812],
      [110.3931, -7.9812],
      [110.3951, -7.9811],
      [110.3967, -7.9809],
      [110.3976, -7.9807],
      [110.3981, -7.9804],
      [110.3986, -7.9799],
      [110.3991, -7.9794],
      [110.3994, -7.9788],
      [110.3984, -7.9766],
      [110.3978, -7.9754],
      [110.3974, -7.9746],
      [110.397, -7.9744],
      [110.3965, -7.9742],
      [110.3963, -7.9737],
      [110.3963, -7.973],
      [110.3963, -7.9719],
      [110.3962, -7.9711],
      [110.3962, -7.9704],
      [110.3962, -7.9701],
      [110.3963, -7.9697],
      [110.3958, -7.9691],
      [110.3956, -7.9686],
      [110.3957, -7.9682],
      [110.3959, -7.9679],
      [110.3966, -7.9675],
      [110.3971, -7.9671],
      [110.3974, -7.9672],
      [110.3986, -7.9679],
      [110.3991, -7.968],
      [110.3993, -7.968],
      [110.3994, -7.9679],
      [110.3997, -7.9678],
      [110.4007, -7.9678],
      [110.4012, -7.9679],
      [110.4019, -7.968],
      [110.4027, -7.9681],
      [110.4031, -7.9682],
      [110.4036, -7.9683],
      [110.4042, -7.9683],
      [110.4045, -7.9682],
      [110.4056, -7.9689],
      [110.4061, -7.9694],
      [110.4064, -7.9698],
      [110.4065, -7.9703],
      [110.4065, -7.9705],
      [110.407, -7.9709],
      [110.4084, -7.9715],
      [110.4096, -7.9718],
      [110.4105, -7.9716],
      [110.4112, -7.9717],
      [110.4115, -7.9717],
      [110.4124, -7.9716],
      [110.413, -7.9719],
      [110.4137, -7.9721],
      [110.4145, -7.9721],
      [110.4149, -7.9721],
      [110.4158, -7.9726],
      [110.4159, -7.9727],
      [110.4168, -7.9726],
      [110.4175, -7.9726],
      [110.4187, -7.9726],
      [110.4193, -7.9727],
      [110.4209, -7.9734],
      [110.4221, -7.9722],
      [110.4235, -7.9709],
      [110.4242, -7.9701],
      [110.4253, -7.9696],
      [110.4269, -7.9689],
      [110.4274, -7.9685],
      [110.4281, -7.9677],
      [110.4295, -7.9665],
      [110.4302, -7.9656],
      [110.4305, -7.9649],
      [110.4304, -7.9642],
      [110.4306, -7.9638],
      [110.4309, -7.9634],
      [110.4315, -7.9631],
      [110.431, -7.9628],
      [110.4306, -7.9625],
      [110.4304, -7.9621],
      [110.4303, -7.9612],
      [110.4303, -7.9608],
      [110.4304, -7.9604],
      [110.4305, -7.9598],
      [110.4306, -7.9594],
      [110.43, -7.9588],
      [110.4296, -7.9586],
      [110.429, -7.9587],
      [110.4285, -7.9587],
      [110.4283, -7.9586],
      [110.4279, -7.9583],
      [110.4277, -7.9582],
      [110.4276, -7.958],
      [110.4274, -7.9574],
      [110.4273, -7.9571],
      [110.4271, -7.9567],
      [110.426, -7.9555],
      [110.425, -7.9546],
      [110.4247, -7.954],
      [110.4246, -7.9532],
      [110.4249, -7.9521],
      [110.4251, -7.9517]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'SRIHARJO',
    border: [
      [110.3757, -7.9364],
      [110.375, -7.9356],
      [110.3748, -7.9356],
      [110.3746, -7.936],
      [110.3743, -7.936],
      [110.3739, -7.9358],
      [110.3731, -7.9355],
      [110.3729, -7.9352],
      [110.3725, -7.935],
      [110.372, -7.935],
      [110.371, -7.9348],
      [110.3709, -7.9358],
      [110.3696, -7.9356],
      [110.3685, -7.9353],
      [110.3678, -7.9353],
      [110.3665, -7.9351],
      [110.3663, -7.9351],
      [110.3664, -7.9361],
      [110.3662, -7.9369],
      [110.366, -7.9369],
      [110.3659, -7.9373],
      [110.366, -7.9375],
      [110.3661, -7.9383],
      [110.3662, -7.9384],
      [110.3662, -7.9396],
      [110.3667, -7.9403],
      [110.3665, -7.9406],
      [110.3662, -7.9412],
      [110.366, -7.9418],
      [110.366, -7.9421],
      [110.3668, -7.942],
      [110.3671, -7.9425],
      [110.367, -7.9433],
      [110.3668, -7.9442],
      [110.3662, -7.9454],
      [110.3655, -7.9461],
      [110.3651, -7.9469],
      [110.3651, -7.9477],
      [110.3654, -7.949],
      [110.3655, -7.9498],
      [110.3658, -7.9519],
      [110.3659, -7.9537],
      [110.3656, -7.9551],
      [110.3651, -7.9558],
      [110.3659, -7.956],
      [110.3666, -7.9558],
      [110.3674, -7.9555],
      [110.368, -7.955],
      [110.3685, -7.9545],
      [110.3692, -7.9542],
      [110.3698, -7.9538],
      [110.3704, -7.9534],
      [110.3707, -7.9532],
      [110.3712, -7.9533],
      [110.3725, -7.9539],
      [110.3732, -7.9546],
      [110.3733, -7.955],
      [110.3734, -7.9553],
      [110.3736, -7.9559],
      [110.3739, -7.9561],
      [110.3745, -7.9566],
      [110.3747, -7.9569],
      [110.375, -7.9572],
      [110.3753, -7.9573],
      [110.3759, -7.9565],
      [110.376, -7.9559],
      [110.376, -7.9548],
      [110.376, -7.9544],
      [110.376, -7.9542],
      [110.3759, -7.9529],
      [110.3759, -7.9519],
      [110.3758, -7.9505],
      [110.3758, -7.9487],
      [110.3759, -7.9482],
      [110.3762, -7.9474],
      [110.3772, -7.9475],
      [110.378, -7.9474],
      [110.3781, -7.9474],
      [110.3786, -7.9473],
      [110.3791, -7.9473],
      [110.3801, -7.9479],
      [110.3809, -7.9488],
      [110.3814, -7.9494],
      [110.3816, -7.9499],
      [110.3817, -7.9506],
      [110.3823, -7.9511],
      [110.383, -7.9515],
      [110.3837, -7.9516],
      [110.3848, -7.9517],
      [110.386, -7.9517],
      [110.3869, -7.9516],
      [110.3877, -7.9515],
      [110.388, -7.9515],
      [110.389, -7.9507],
      [110.3902, -7.9497],
      [110.3915, -7.9484],
      [110.3925, -7.9479],
      [110.3938, -7.9466],
      [110.3945, -7.9461],
      [110.395, -7.946],
      [110.3967, -7.9459],
      [110.3991, -7.9458],
      [110.4007, -7.9455],
      [110.4016, -7.9453],
      [110.4028, -7.945],
      [110.4042, -7.9444],
      [110.4047, -7.9442],
      [110.4061, -7.9441],
      [110.4074, -7.9441],
      [110.4084, -7.944],
      [110.4095, -7.9437],
      [110.4104, -7.9435],
      [110.4112, -7.9436],
      [110.412, -7.9439],
      [110.4125, -7.9445],
      [110.4135, -7.9453],
      [110.4145, -7.9458],
      [110.4156, -7.946],
      [110.4166, -7.946],
      [110.4176, -7.9455],
      [110.4185, -7.9454],
      [110.4193, -7.9454],
      [110.4213, -7.9454],
      [110.4227, -7.9456],
      [110.4239, -7.9454],
      [110.4247, -7.9453],
      [110.4254, -7.9454],
      [110.4267, -7.9459],
      [110.4267, -7.946],
      [110.4272, -7.9466],
      [110.4272, -7.9466],
      [110.4274, -7.9473],
      [110.4269, -7.9491],
      [110.4267, -7.9491],
      [110.4266, -7.9491],
      [110.4264, -7.9491],
      [110.4259, -7.9494],
      [110.4254, -7.9501],
      [110.4252, -7.9509],
      [110.4251, -7.9517],
      [110.4253, -7.9515],
      [110.4257, -7.9513],
      [110.4265, -7.9508],
      [110.427, -7.9503],
      [110.4275, -7.9497],
      [110.4278, -7.9489],
      [110.4279, -7.9477],
      [110.4279, -7.9476],
      [110.428, -7.9465],
      [110.428, -7.946],
      [110.4279, -7.9456],
      [110.4275, -7.9446],
      [110.427, -7.9439],
      [110.4267, -7.9436],
      [110.426, -7.943],
      [110.4248, -7.9422],
      [110.4235, -7.9414],
      [110.4227, -7.9409],
      [110.4218, -7.9407],
      [110.4216, -7.9406],
      [110.4199, -7.9399],
      [110.4184, -7.9392],
      [110.4164, -7.9385],
      [110.4148, -7.9377],
      [110.4134, -7.9371],
      [110.4123, -7.9366],
      [110.4119, -7.9365],
      [110.4116, -7.9364],
      [110.4106, -7.9366],
      [110.4105, -7.9366],
      [110.4099, -7.9365],
      [110.4094, -7.9364],
      [110.4089, -7.9364],
      [110.4083, -7.9363],
      [110.4079, -7.9361],
      [110.4076, -7.9363],
      [110.4071, -7.9366],
      [110.406, -7.9373],
      [110.4051, -7.9379],
      [110.4048, -7.938],
      [110.4043, -7.9381],
      [110.4041, -7.9381],
      [110.4035, -7.9384],
      [110.4029, -7.9391],
      [110.4025, -7.9398],
      [110.4016, -7.9406],
      [110.4011, -7.9411],
      [110.4005, -7.9416],
      [110.3998, -7.9424],
      [110.3992, -7.9429],
      [110.3977, -7.9434],
      [110.3967, -7.9437],
      [110.3956, -7.9439],
      [110.395, -7.9439],
      [110.3947, -7.9439],
      [110.3937, -7.944],
      [110.393, -7.9438],
      [110.3928, -7.9437],
      [110.3922, -7.9437],
      [110.392, -7.9438],
      [110.3915, -7.944],
      [110.3905, -7.9444],
      [110.39, -7.9448],
      [110.3892, -7.9451],
      [110.3885, -7.9451],
      [110.3871, -7.9445],
      [110.3867, -7.9445],
      [110.3854, -7.9438],
      [110.3851, -7.9432],
      [110.3848, -7.943],
      [110.3842, -7.9428],
      [110.3836, -7.9431],
      [110.3832, -7.9433],
      [110.3825, -7.9433],
      [110.382, -7.9435],
      [110.3815, -7.944],
      [110.3809, -7.9443],
      [110.3802, -7.9446],
      [110.3786, -7.9452],
      [110.3781, -7.9451],
      [110.3776, -7.9446],
      [110.3775, -7.9445],
      [110.377, -7.9435],
      [110.3771, -7.9427],
      [110.3774, -7.942],
      [110.3774, -7.9418],
      [110.3774, -7.9415],
      [110.3782, -7.9405],
      [110.3791, -7.9395],
      [110.3796, -7.9381],
      [110.3795, -7.9378],
      [110.379, -7.9374],
      [110.3781, -7.937],
      [110.3777, -7.9368],
      [110.3772, -7.9368],
      [110.376, -7.9367],
      [110.3757, -7.9364]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'WUKIRSARI',
    border: [
      [110.4217, -7.8933],
      [110.4213, -7.8929],
      [110.4196, -7.8924],
      [110.4184, -7.8921],
      [110.4172, -7.8916],
      [110.4166, -7.8914],
      [110.416, -7.8914],
      [110.4154, -7.8915],
      [110.415, -7.8921],
      [110.4145, -7.8925],
      [110.4143, -7.8929],
      [110.4136, -7.8931],
      [110.4126, -7.894],
      [110.412, -7.8943],
      [110.4115, -7.8946],
      [110.4109, -7.8949],
      [110.4104, -7.8951],
      [110.4095, -7.8954],
      [110.4078, -7.8958],
      [110.4049, -7.8962],
      [110.4044, -7.8964],
      [110.4022, -7.8965],
      [110.4002, -7.8968],
      [110.3989, -7.8971],
      [110.3982, -7.8972],
      [110.3974, -7.8971],
      [110.3964, -7.8971],
      [110.3955, -7.8969],
      [110.3955, -7.8977],
      [110.3952, -7.898],
      [110.3949, -7.8986],
      [110.3945, -7.8991],
      [110.3941, -7.8993],
      [110.3935, -7.8989],
      [110.3929, -7.8983],
      [110.392, -7.8977],
      [110.3915, -7.8976],
      [110.3911, -7.8974],
      [110.3907, -7.8974],
      [110.3899, -7.8975],
      [110.3893, -7.8975],
      [110.3883, -7.8974],
      [110.3876, -7.8972],
      [110.3866, -7.897],
      [110.3865, -7.8969],
      [110.3861, -7.8972],
      [110.386, -7.8973],
      [110.3857, -7.8981],
      [110.3857, -7.899],
      [110.3857, -7.8999],
      [110.386, -7.9004],
      [110.3863, -7.901],
      [110.3868, -7.9013],
      [110.3873, -7.9015],
      [110.3879, -7.9016],
      [110.3884, -7.9015],
      [110.3886, -7.9017],
      [110.3885, -7.9022],
      [110.3881, -7.9029],
      [110.3873, -7.9036],
      [110.3866, -7.9038],
      [110.3856, -7.9038],
      [110.384, -7.9038],
      [110.3825, -7.904],
      [110.3815, -7.9044],
      [110.3814, -7.9052],
      [110.381, -7.906],
      [110.3799, -7.9076],
      [110.3794, -7.9084],
      [110.3791, -7.9085],
      [110.3789, -7.9086],
      [110.3786, -7.9088],
      [110.3782, -7.9094],
      [110.3779, -7.91],
      [110.3777, -7.9104],
      [110.3775, -7.911],
      [110.3778, -7.9111],
      [110.378, -7.9108],
      [110.3781, -7.9106],
      [110.3782, -7.9105],
      [110.3788, -7.9107],
      [110.3794, -7.911],
      [110.3794, -7.9117],
      [110.3799, -7.912],
      [110.3806, -7.9123],
      [110.3813, -7.9123],
      [110.3817, -7.9121],
      [110.3818, -7.9113],
      [110.3825, -7.9109],
      [110.3832, -7.9109],
      [110.3838, -7.911],
      [110.3843, -7.9109],
      [110.3847, -7.9104],
      [110.3851, -7.9105],
      [110.3856, -7.9108],
      [110.3864, -7.911],
      [110.3875, -7.9116],
      [110.3875, -7.9117],
      [110.387, -7.9123],
      [110.3864, -7.913],
      [110.3859, -7.9136],
      [110.3859, -7.9144],
      [110.3861, -7.9151],
      [110.3863, -7.9162],
      [110.3862, -7.9169],
      [110.3858, -7.9176],
      [110.3857, -7.918],
      [110.3861, -7.9196],
      [110.3862, -7.92],
      [110.3863, -7.9203],
      [110.3865, -7.9206],
      [110.3866, -7.921],
      [110.3864, -7.9217],
      [110.3865, -7.9222],
      [110.3866, -7.9226],
      [110.3866, -7.923],
      [110.3867, -7.9233],
      [110.3867, -7.9236],
      [110.3866, -7.924],
      [110.3866, -7.9244],
      [110.3865, -7.9246],
      [110.3867, -7.9245],
      [110.3875, -7.9245],
      [110.3885, -7.9247],
      [110.3896, -7.9248],
      [110.3907, -7.9248],
      [110.3913, -7.9247],
      [110.3917, -7.9244],
      [110.3918, -7.9235],
      [110.3921, -7.922],
      [110.3921, -7.9218],
      [110.3925, -7.9198],
      [110.3926, -7.9191],
      [110.393, -7.9187],
      [110.3935, -7.9187],
      [110.3938, -7.9193],
      [110.3941, -7.92],
      [110.3943, -7.921],
      [110.395, -7.9222],
      [110.3956, -7.9229],
      [110.3961, -7.9233],
      [110.3967, -7.9233],
      [110.3973, -7.9234],
      [110.3977, -7.9234],
      [110.3983, -7.9234],
      [110.399, -7.9237],
      [110.3998, -7.9237],
      [110.4004, -7.9237],
      [110.401, -7.9237],
      [110.4016, -7.9238],
      [110.4022, -7.9239],
      [110.4024, -7.924],
      [110.4029, -7.9243],
      [110.4037, -7.9252],
      [110.4044, -7.9257],
      [110.4057, -7.9272],
      [110.4061, -7.9279],
      [110.4064, -7.9296],
      [110.4068, -7.9308],
      [110.4069, -7.9311],
      [110.4069, -7.9312],
      [110.4072, -7.9309],
      [110.408, -7.9307],
      [110.4092, -7.9305],
      [110.4093, -7.9305],
      [110.4108, -7.93],
      [110.4123, -7.9296],
      [110.4138, -7.9289],
      [110.4146, -7.9285],
      [110.4152, -7.9283],
      [110.416, -7.9283],
      [110.4173, -7.9285],
      [110.4182, -7.9283],
      [110.4197, -7.9278],
      [110.4211, -7.9274],
      [110.4221, -7.9271],
      [110.4233, -7.9269],
      [110.4241, -7.9265],
      [110.4249, -7.9263],
      [110.4257, -7.9261],
      [110.4261, -7.9257],
      [110.4264, -7.9251],
      [110.4264, -7.9244],
      [110.4264, -7.9236],
      [110.4263, -7.9227],
      [110.4263, -7.922],
      [110.4267, -7.9216],
      [110.4274, -7.921],
      [110.4279, -7.9207],
      [110.4287, -7.9198],
      [110.4291, -7.9189],
      [110.4295, -7.918],
      [110.4301, -7.9175],
      [110.4306, -7.9169],
      [110.4309, -7.9167],
      [110.4318, -7.9158],
      [110.4326, -7.9148],
      [110.4331, -7.9142],
      [110.4338, -7.9136],
      [110.4347, -7.9129],
      [110.4355, -7.912],
      [110.436, -7.9109],
      [110.4361, -7.9106],
      [110.4363, -7.91],
      [110.4364, -7.9089],
      [110.4368, -7.9077],
      [110.4372, -7.9068],
      [110.4371, -7.9058],
      [110.437, -7.9056],
      [110.4369, -7.905],
      [110.4363, -7.9046],
      [110.4352, -7.9034],
      [110.434, -7.9022],
      [110.433, -7.9014],
      [110.4318, -7.9012],
      [110.4314, -7.9012],
      [110.4308, -7.9009],
      [110.4295, -7.8997],
      [110.4287, -7.8986],
      [110.4281, -7.8972],
      [110.4278, -7.8965],
      [110.4274, -7.8955],
      [110.4268, -7.8952],
      [110.4259, -7.895],
      [110.4252, -7.8949],
      [110.4243, -7.8951],
      [110.4238, -7.895],
      [110.4228, -7.8944],
      [110.4219, -7.8935],
      [110.4217, -7.8933]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'KEBONAGUNG',
    border: [
      [110.3769, -7.9268],
      [110.3761, -7.9266],
      [110.3755, -7.9265],
      [110.3749, -7.9267],
      [110.3744, -7.9267],
      [110.3736, -7.9267],
      [110.3733, -7.9266],
      [110.3732, -7.9253],
      [110.3736, -7.9244],
      [110.3737, -7.9233],
      [110.3738, -7.922],
      [110.3736, -7.9218],
      [110.3726, -7.9216],
      [110.3724, -7.9234],
      [110.3725, -7.9243],
      [110.3717, -7.924],
      [110.3714, -7.9243],
      [110.3715, -7.925],
      [110.3705, -7.9248],
      [110.3682, -7.9243],
      [110.3675, -7.9241],
      [110.3672, -7.9241],
      [110.367, -7.9251],
      [110.3667, -7.9265],
      [110.3666, -7.9278],
      [110.3669, -7.9291],
      [110.3673, -7.9303],
      [110.3668, -7.9319],
      [110.3665, -7.9328],
      [110.366, -7.9332],
      [110.3652, -7.9341],
      [110.3641, -7.9354],
      [110.3633, -7.9371],
      [110.3631, -7.9377],
      [110.3628, -7.9385],
      [110.3628, -7.9396],
      [110.363, -7.9406],
      [110.3635, -7.9416],
      [110.3642, -7.9421],
      [110.3653, -7.9423],
      [110.366, -7.9421],
      [110.366, -7.9418],
      [110.3662, -7.9412],
      [110.3665, -7.9406],
      [110.3667, -7.9403],
      [110.3662, -7.9396],
      [110.3662, -7.9384],
      [110.3661, -7.9383],
      [110.366, -7.9375],
      [110.3659, -7.9373],
      [110.366, -7.9369],
      [110.3662, -7.9369],
      [110.3664, -7.9361],
      [110.3663, -7.9351],
      [110.3665, -7.9351],
      [110.3678, -7.9353],
      [110.3685, -7.9353],
      [110.3696, -7.9356],
      [110.3709, -7.9358],
      [110.371, -7.9348],
      [110.372, -7.935],
      [110.3725, -7.935],
      [110.3729, -7.9352],
      [110.3731, -7.9355],
      [110.3739, -7.9358],
      [110.3743, -7.936],
      [110.3746, -7.936],
      [110.3748, -7.9356],
      [110.375, -7.9356],
      [110.3757, -7.9364],
      [110.3758, -7.9357],
      [110.3758, -7.9354],
      [110.3757, -7.9353],
      [110.3758, -7.9338],
      [110.3758, -7.9335],
      [110.3761, -7.9308],
      [110.3762, -7.9292],
      [110.3764, -7.9285],
      [110.3767, -7.9275],
      [110.3768, -7.927],
      [110.3769, -7.9268]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'KARANGTENGAH',
    border: [
      [110.4041, -7.9381],
      [110.404, -7.938],
      [110.403, -7.9376],
      [110.4021, -7.9378],
      [110.401, -7.9379],
      [110.4005, -7.9379],
      [110.3999, -7.9378],
      [110.3994, -7.9378],
      [110.3987, -7.9377],
      [110.3979, -7.9371],
      [110.3969, -7.9367],
      [110.3958, -7.9367],
      [110.3945, -7.9369],
      [110.3937, -7.9369],
      [110.3928, -7.937],
      [110.3918, -7.9367],
      [110.3904, -7.936],
      [110.39, -7.9356],
      [110.3885, -7.9349],
      [110.3877, -7.9344],
      [110.3869, -7.9343],
      [110.3863, -7.9344],
      [110.3859, -7.9347],
      [110.3852, -7.9345],
      [110.3846, -7.9343],
      [110.3832, -7.9331],
      [110.3826, -7.9325],
      [110.3827, -7.932],
      [110.3829, -7.9315],
      [110.3829, -7.9312],
      [110.3826, -7.9305],
      [110.3813, -7.9305],
      [110.3804, -7.9306],
      [110.3796, -7.9307],
      [110.3791, -7.9308],
      [110.3787, -7.9306],
      [110.3784, -7.9307],
      [110.3775, -7.9308],
      [110.3761, -7.9308],
      [110.3758, -7.9335],
      [110.3758, -7.9338],
      [110.3757, -7.9353],
      [110.3758, -7.9354],
      [110.3758, -7.9357],
      [110.3757, -7.9364],
      [110.376, -7.9367],
      [110.3772, -7.9368],
      [110.3777, -7.9368],
      [110.3781, -7.937],
      [110.379, -7.9374],
      [110.3795, -7.9378],
      [110.3796, -7.9381],
      [110.3791, -7.9395],
      [110.3782, -7.9405],
      [110.3774, -7.9415],
      [110.3774, -7.9418],
      [110.3774, -7.942],
      [110.3771, -7.9427],
      [110.377, -7.9435],
      [110.3775, -7.9445],
      [110.3776, -7.9446],
      [110.3781, -7.9451],
      [110.3786, -7.9452],
      [110.3802, -7.9446],
      [110.3809, -7.9443],
      [110.3815, -7.944],
      [110.382, -7.9435],
      [110.3825, -7.9433],
      [110.3832, -7.9433],
      [110.3836, -7.9431],
      [110.3842, -7.9428],
      [110.3848, -7.943],
      [110.3851, -7.9432],
      [110.3854, -7.9438],
      [110.3867, -7.9445],
      [110.3871, -7.9445],
      [110.3885, -7.9451],
      [110.3892, -7.9451],
      [110.39, -7.9448],
      [110.3905, -7.9444],
      [110.3915, -7.944],
      [110.392, -7.9438],
      [110.3922, -7.9437],
      [110.3928, -7.9437],
      [110.393, -7.9438],
      [110.3937, -7.944],
      [110.3947, -7.9439],
      [110.395, -7.9439],
      [110.3956, -7.9439],
      [110.3967, -7.9437],
      [110.3977, -7.9434],
      [110.3992, -7.9429],
      [110.3998, -7.9424],
      [110.4005, -7.9416],
      [110.4011, -7.9411],
      [110.4016, -7.9406],
      [110.4025, -7.9398],
      [110.4029, -7.9391],
      [110.4035, -7.9384],
      [110.4041, -7.9381]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'GIRIREJO',
    border: [
      [110.4069, -7.9312],
      [110.4069, -7.9311],
      [110.4068, -7.9308],
      [110.4064, -7.9296],
      [110.4061, -7.9279],
      [110.4057, -7.9272],
      [110.4044, -7.9257],
      [110.4037, -7.9252],
      [110.4029, -7.9243],
      [110.4024, -7.924],
      [110.4022, -7.9239],
      [110.4016, -7.9238],
      [110.401, -7.9237],
      [110.4004, -7.9237],
      [110.3998, -7.9237],
      [110.399, -7.9237],
      [110.3983, -7.9234],
      [110.3977, -7.9234],
      [110.3973, -7.9234],
      [110.3967, -7.9233],
      [110.3961, -7.9233],
      [110.3956, -7.9229],
      [110.395, -7.9222],
      [110.3943, -7.921],
      [110.3941, -7.92],
      [110.3938, -7.9193],
      [110.3935, -7.9187],
      [110.393, -7.9187],
      [110.3926, -7.9191],
      [110.3925, -7.9198],
      [110.3921, -7.9218],
      [110.3921, -7.922],
      [110.3918, -7.9235],
      [110.3917, -7.9244],
      [110.3913, -7.9247],
      [110.3907, -7.9248],
      [110.3896, -7.9248],
      [110.3885, -7.9247],
      [110.3875, -7.9245],
      [110.3867, -7.9245],
      [110.3865, -7.9246],
      [110.3864, -7.9248],
      [110.3862, -7.9252],
      [110.3861, -7.926],
      [110.3863, -7.9262],
      [110.3864, -7.9263],
      [110.387, -7.927],
      [110.3872, -7.9274],
      [110.387, -7.9276],
      [110.3866, -7.9277],
      [110.385, -7.9272],
      [110.3848, -7.9269],
      [110.3843, -7.9268],
      [110.383, -7.9266],
      [110.3819, -7.9265],
      [110.3815, -7.9265],
      [110.3804, -7.9262],
      [110.3803, -7.9262],
      [110.3792, -7.9265],
      [110.3789, -7.9267],
      [110.3784, -7.927],
      [110.3776, -7.927],
      [110.3769, -7.9268],
      [110.3768, -7.927],
      [110.3767, -7.9275],
      [110.3764, -7.9285],
      [110.3762, -7.9292],
      [110.3761, -7.9308],
      [110.3775, -7.9308],
      [110.3784, -7.9307],
      [110.3787, -7.9306],
      [110.3791, -7.9308],
      [110.3796, -7.9307],
      [110.3804, -7.9306],
      [110.3813, -7.9305],
      [110.3826, -7.9305],
      [110.3829, -7.9312],
      [110.3829, -7.9315],
      [110.3827, -7.932],
      [110.3826, -7.9325],
      [110.3832, -7.9331],
      [110.3846, -7.9343],
      [110.3852, -7.9345],
      [110.3859, -7.9347],
      [110.3863, -7.9344],
      [110.3869, -7.9343],
      [110.3877, -7.9344],
      [110.3885, -7.9349],
      [110.39, -7.9356],
      [110.3904, -7.936],
      [110.3918, -7.9367],
      [110.3928, -7.937],
      [110.3937, -7.9369],
      [110.3945, -7.9369],
      [110.3958, -7.9367],
      [110.3969, -7.9367],
      [110.3979, -7.9371],
      [110.3987, -7.9377],
      [110.3994, -7.9378],
      [110.3999, -7.9378],
      [110.4005, -7.9379],
      [110.401, -7.9379],
      [110.4021, -7.9378],
      [110.403, -7.9376],
      [110.404, -7.938],
      [110.4041, -7.9381],
      [110.4043, -7.9381],
      [110.4048, -7.938],
      [110.4051, -7.9379],
      [110.406, -7.9373],
      [110.4071, -7.9366],
      [110.4076, -7.9363],
      [110.4079, -7.936],
      [110.4076, -7.9359],
      [110.4075, -7.9357],
      [110.4073, -7.9355],
      [110.4072, -7.9346],
      [110.4072, -7.934],
      [110.4073, -7.9331],
      [110.4069, -7.932],
      [110.4069, -7.9314],
      [110.4069, -7.9312]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'KARANGTALUN',
    border: [
      [110.3866, -7.9233],
      [110.3864, -7.9235],
      [110.3859, -7.9235],
      [110.3852, -7.9233],
      [110.384, -7.9229],
      [110.3825, -7.9226],
      [110.3824, -7.9221],
      [110.3824, -7.9212],
      [110.3817, -7.9206],
      [110.3805, -7.9196],
      [110.3802, -7.9193],
      [110.3783, -7.9179],
      [110.3771, -7.9169],
      [110.3765, -7.9165],
      [110.3756, -7.9154],
      [110.3755, -7.9153],
      [110.3754, -7.9157],
      [110.3757, -7.9166],
      [110.376, -7.9173],
      [110.376, -7.9177],
      [110.3758, -7.918],
      [110.3756, -7.9183],
      [110.3754, -7.9186],
      [110.375, -7.9191],
      [110.3741, -7.9196],
      [110.3733, -7.9199],
      [110.373, -7.9198],
      [110.3723, -7.9195],
      [110.3716, -7.9197],
      [110.3707, -7.92],
      [110.3697, -7.92],
      [110.3686, -7.9199],
      [110.3683, -7.9199],
      [110.3678, -7.9199],
      [110.367, -7.9204],
      [110.3669, -7.9211],
      [110.367, -7.9225],
      [110.3672, -7.9239],
      [110.3672, -7.9241],
      [110.3675, -7.9241],
      [110.3682, -7.9243],
      [110.3705, -7.9248],
      [110.3715, -7.925],
      [110.3714, -7.9243],
      [110.3717, -7.924],
      [110.3725, -7.9243],
      [110.3724, -7.9234],
      [110.3726, -7.9216],
      [110.3736, -7.9218],
      [110.3738, -7.922],
      [110.3737, -7.9233],
      [110.3736, -7.9244],
      [110.3732, -7.9253],
      [110.3733, -7.9266],
      [110.3736, -7.9267],
      [110.3744, -7.9267],
      [110.3749, -7.9267],
      [110.3755, -7.9265],
      [110.3761, -7.9266],
      [110.3769, -7.9268],
      [110.3776, -7.927],
      [110.3784, -7.927],
      [110.3789, -7.9267],
      [110.3792, -7.9265],
      [110.3803, -7.9262],
      [110.3804, -7.9262],
      [110.3815, -7.9265],
      [110.3819, -7.9265],
      [110.383, -7.9266],
      [110.3843, -7.9268],
      [110.3848, -7.9269],
      [110.385, -7.9272],
      [110.3866, -7.9277],
      [110.387, -7.9276],
      [110.3872, -7.9274],
      [110.387, -7.927],
      [110.3864, -7.9263],
      [110.3863, -7.9262],
      [110.3861, -7.926],
      [110.3862, -7.9252],
      [110.3864, -7.9248],
      [110.3865, -7.9246],
      [110.3866, -7.9244],
      [110.3866, -7.924],
      [110.3867, -7.9236],
      [110.3866, -7.9233]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'IMOGIRI',
    village: 'IMOGIRI',
    border: [
      [110.3866, -7.923],
      [110.3866, -7.9226],
      [110.3865, -7.9222],
      [110.3864, -7.9217],
      [110.3866, -7.921],
      [110.3865, -7.9206],
      [110.3863, -7.9203],
      [110.3862, -7.92],
      [110.3861, -7.9196],
      [110.3857, -7.918],
      [110.3858, -7.9176],
      [110.3862, -7.9169],
      [110.3863, -7.9162],
      [110.3861, -7.9151],
      [110.3859, -7.9144],
      [110.3859, -7.9136],
      [110.3864, -7.913],
      [110.387, -7.9123],
      [110.3875, -7.9117],
      [110.3875, -7.9116],
      [110.3864, -7.911],
      [110.3856, -7.9108],
      [110.3851, -7.9105],
      [110.3847, -7.9104],
      [110.3843, -7.9109],
      [110.3838, -7.911],
      [110.3832, -7.9109],
      [110.3825, -7.9109],
      [110.3818, -7.9113],
      [110.3817, -7.9121],
      [110.3813, -7.9123],
      [110.3806, -7.9123],
      [110.3799, -7.912],
      [110.3794, -7.9117],
      [110.3794, -7.911],
      [110.3788, -7.9107],
      [110.3782, -7.9105],
      [110.3781, -7.9106],
      [110.378, -7.9108],
      [110.3778, -7.9111],
      [110.3775, -7.911],
      [110.3769, -7.9115],
      [110.3765, -7.912],
      [110.3761, -7.9126],
      [110.3757, -7.9135],
      [110.3757, -7.9136],
      [110.3758, -7.9143],
      [110.3755, -7.915],
      [110.3755, -7.9153],
      [110.3756, -7.9154],
      [110.3765, -7.9165],
      [110.3771, -7.9169],
      [110.3783, -7.9179],
      [110.3802, -7.9193],
      [110.3805, -7.9196],
      [110.3817, -7.9206],
      [110.3824, -7.9212],
      [110.3824, -7.9221],
      [110.3825, -7.9226],
      [110.384, -7.9229],
      [110.3852, -7.9233],
      [110.3859, -7.9235],
      [110.3864, -7.9235],
      [110.3866, -7.9233],
      [110.3866, -7.923]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'DLINGO',
    village: 'MANGUNAN',
    border: [
      [110.4445, -7.9501],
      [110.4442, -7.95],
      [110.4433, -7.9495],
      [110.4429, -7.9492],
      [110.4423, -7.9484],
      [110.4421, -7.9477],
      [110.4419, -7.9462],
      [110.4418, -7.945],
      [110.4418, -7.9439],
      [110.4417, -7.942],
      [110.4417, -7.9401],
      [110.4421, -7.9382],
      [110.4423, -7.9371],
      [110.4423, -7.9369],
      [110.4422, -7.9364],
      [110.4421, -7.9357],
      [110.442, -7.9352],
      [110.442, -7.9343],
      [110.4425, -7.9329],
      [110.443, -7.932],
      [110.4432, -7.9311],
      [110.443, -7.9302],
      [110.4427, -7.9292],
      [110.4422, -7.9279],
      [110.4417, -7.9264],
      [110.4416, -7.9253],
      [110.4417, -7.9243],
      [110.4418, -7.924],
      [110.4417, -7.9233],
      [110.4412, -7.9221],
      [110.4406, -7.9215],
      [110.44, -7.9211],
      [110.4396, -7.921],
      [110.4379, -7.9204],
      [110.4373, -7.9202],
      [110.4353, -7.9201],
      [110.4344, -7.9203],
      [110.4334, -7.9203],
      [110.4327, -7.9204],
      [110.4319, -7.9203],
      [110.4315, -7.9198],
      [110.4311, -7.9191],
      [110.4306, -7.9183],
      [110.4304, -7.918],
      [110.4301, -7.9175],
      [110.4295, -7.918],
      [110.4291, -7.9189],
      [110.4287, -7.9198],
      [110.4279, -7.9207],
      [110.4274, -7.921],
      [110.4267, -7.9216],
      [110.4263, -7.922],
      [110.4263, -7.9227],
      [110.4264, -7.9236],
      [110.4264, -7.9244],
      [110.4264, -7.9251],
      [110.4261, -7.9257],
      [110.4257, -7.9261],
      [110.4249, -7.9263],
      [110.4241, -7.9265],
      [110.4233, -7.9269],
      [110.4221, -7.9271],
      [110.4211, -7.9274],
      [110.4197, -7.9278],
      [110.4182, -7.9283],
      [110.4173, -7.9285],
      [110.416, -7.9283],
      [110.4152, -7.9283],
      [110.4146, -7.9285],
      [110.4138, -7.9289],
      [110.4123, -7.9296],
      [110.4108, -7.93],
      [110.4093, -7.9305],
      [110.4092, -7.9305],
      [110.408, -7.9307],
      [110.4072, -7.9309],
      [110.4069, -7.9312],
      [110.4069, -7.9314],
      [110.4069, -7.932],
      [110.4073, -7.9331],
      [110.4072, -7.934],
      [110.4072, -7.9346],
      [110.4073, -7.9355],
      [110.4075, -7.9357],
      [110.4076, -7.9359],
      [110.4079, -7.936],
      [110.4079, -7.9361],
      [110.4083, -7.9363],
      [110.4089, -7.9364],
      [110.4094, -7.9364],
      [110.4099, -7.9365],
      [110.4105, -7.9366],
      [110.4106, -7.9366],
      [110.4116, -7.9364],
      [110.4119, -7.9365],
      [110.4123, -7.9366],
      [110.4134, -7.9371],
      [110.4148, -7.9377],
      [110.4164, -7.9385],
      [110.4184, -7.9392],
      [110.4199, -7.9399],
      [110.4216, -7.9406],
      [110.4218, -7.9407],
      [110.4227, -7.9409],
      [110.4235, -7.9414],
      [110.4248, -7.9422],
      [110.426, -7.943],
      [110.4267, -7.9436],
      [110.427, -7.9439],
      [110.4275, -7.9446],
      [110.4279, -7.9456],
      [110.428, -7.946],
      [110.428, -7.9465],
      [110.4279, -7.9476],
      [110.4279, -7.9477],
      [110.4278, -7.9489],
      [110.4275, -7.9497],
      [110.427, -7.9503],
      [110.4265, -7.9508],
      [110.4257, -7.9513],
      [110.4253, -7.9515],
      [110.4251, -7.9517],
      [110.4249, -7.9521],
      [110.4246, -7.9532],
      [110.4247, -7.954],
      [110.425, -7.9546],
      [110.426, -7.9555],
      [110.4271, -7.9567],
      [110.4273, -7.9571],
      [110.4274, -7.9574],
      [110.4276, -7.958],
      [110.4277, -7.9582],
      [110.4279, -7.9583],
      [110.4283, -7.9586],
      [110.4285, -7.9587],
      [110.429, -7.9587],
      [110.4296, -7.9586],
      [110.43, -7.9588],
      [110.4306, -7.9594],
      [110.4305, -7.9598],
      [110.4304, -7.9604],
      [110.4303, -7.9608],
      [110.4303, -7.9612],
      [110.4304, -7.9621],
      [110.4306, -7.9625],
      [110.431, -7.9628],
      [110.4315, -7.9631],
      [110.4316, -7.963],
      [110.4322, -7.9632],
      [110.4326, -7.9638],
      [110.4333, -7.965],
      [110.4345, -7.9662],
      [110.4352, -7.9666],
      [110.4358, -7.9668],
      [110.4367, -7.9668],
      [110.4375, -7.9668],
      [110.4401, -7.9673],
      [110.4408, -7.9673],
      [110.4412, -7.9673],
      [110.4425, -7.9673],
      [110.4433, -7.967],
      [110.4438, -7.9662],
      [110.4441, -7.9654],
      [110.4442, -7.9641],
      [110.4444, -7.9625],
      [110.4445, -7.9613],
      [110.4445, -7.9602],
      [110.4445, -7.9586],
      [110.4447, -7.9575],
      [110.4446, -7.9567],
      [110.4446, -7.956],
      [110.4445, -7.9551],
      [110.4443, -7.954],
      [110.4438, -7.9527],
      [110.4436, -7.9522],
      [110.4436, -7.9515],
      [110.4441, -7.9506],
      [110.4445, -7.9501]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'DLINGO',
    village: 'MUNTUK',
    border: [
      [110.4613, -7.9091],
      [110.4613, -7.9086],
      [110.461, -7.9083],
      [110.4612, -7.9079],
      [110.4612, -7.9078],
      [110.461, -7.9073],
      [110.4609, -7.9069],
      [110.46, -7.906],
      [110.4592, -7.9052],
      [110.4587, -7.9049],
      [110.4586, -7.9047],
      [110.4586, -7.9043],
      [110.4583, -7.9039],
      [110.4579, -7.9033],
      [110.458, -7.9031],
      [110.4582, -7.9027],
      [110.4579, -7.9022],
      [110.458, -7.902],
      [110.4575, -7.9013],
      [110.4572, -7.9012],
      [110.4569, -7.9011],
      [110.4566, -7.9006],
      [110.4562, -7.9001],
      [110.4563, -7.8995],
      [110.4562, -7.8989],
      [110.4564, -7.8985],
      [110.4563, -7.8984],
      [110.4559, -7.8983],
      [110.4559, -7.898],
      [110.4556, -7.897],
      [110.4558, -7.8967],
      [110.4558, -7.8964],
      [110.4555, -7.8963],
      [110.4554, -7.8963],
      [110.455, -7.8963],
      [110.455, -7.896],
      [110.4548, -7.8956],
      [110.4542, -7.8953],
      [110.4541, -7.8952],
      [110.4538, -7.8949],
      [110.4534, -7.8947],
      [110.4532, -7.8946],
      [110.453, -7.8945],
      [110.4528, -7.8943],
      [110.4527, -7.8942],
      [110.4524, -7.8941],
      [110.4524, -7.8937],
      [110.4524, -7.8934],
      [110.4523, -7.8933],
      [110.4519, -7.893],
      [110.4518, -7.8927],
      [110.4515, -7.892],
      [110.4513, -7.8917],
      [110.4511, -7.8911],
      [110.4508, -7.891],
      [110.4502, -7.8908],
      [110.4487, -7.8896],
      [110.4465, -7.8878],
      [110.4459, -7.8872],
      [110.4457, -7.8874],
      [110.4445, -7.8885],
      [110.4434, -7.8892],
      [110.4423, -7.8897],
      [110.4419, -7.89],
      [110.4414, -7.8909],
      [110.4407, -7.8918],
      [110.4401, -7.8924],
      [110.4398, -7.8927],
      [110.439, -7.894],
      [110.4379, -7.8952],
      [110.4378, -7.8953],
      [110.4361, -7.8969],
      [110.4358, -7.8971],
      [110.4349, -7.898],
      [110.4343, -7.8985],
      [110.434, -7.8988],
      [110.4336, -7.899],
      [110.4325, -7.9001],
      [110.4318, -7.9006],
      [110.4308, -7.9009],
      [110.4314, -7.9012],
      [110.4318, -7.9012],
      [110.433, -7.9014],
      [110.434, -7.9022],
      [110.4352, -7.9034],
      [110.4363, -7.9046],
      [110.4369, -7.905],
      [110.437, -7.9056],
      [110.4371, -7.9058],
      [110.4372, -7.9068],
      [110.4368, -7.9077],
      [110.4364, -7.9089],
      [110.4363, -7.91],
      [110.4361, -7.9106],
      [110.436, -7.9109],
      [110.4355, -7.912],
      [110.4347, -7.9129],
      [110.4338, -7.9136],
      [110.4331, -7.9142],
      [110.4326, -7.9148],
      [110.4318, -7.9158],
      [110.4309, -7.9167],
      [110.4306, -7.9169],
      [110.4301, -7.9175],
      [110.4304, -7.918],
      [110.4306, -7.9183],
      [110.4311, -7.9191],
      [110.4315, -7.9198],
      [110.4319, -7.9203],
      [110.4327, -7.9204],
      [110.4334, -7.9203],
      [110.4344, -7.9203],
      [110.4353, -7.9201],
      [110.4373, -7.9202],
      [110.4379, -7.9204],
      [110.4396, -7.921],
      [110.44, -7.9211],
      [110.4406, -7.9215],
      [110.4412, -7.9221],
      [110.4417, -7.9233],
      [110.4418, -7.924],
      [110.4417, -7.9243],
      [110.4416, -7.9253],
      [110.4417, -7.9264],
      [110.4422, -7.9279],
      [110.4427, -7.9292],
      [110.443, -7.9302],
      [110.4432, -7.9311],
      [110.443, -7.932],
      [110.4425, -7.9329],
      [110.442, -7.9343],
      [110.442, -7.9352],
      [110.4421, -7.9357],
      [110.4422, -7.9364],
      [110.4423, -7.9369],
      [110.4423, -7.9371],
      [110.4421, -7.9382],
      [110.4417, -7.9401],
      [110.4417, -7.942],
      [110.4418, -7.9439],
      [110.4418, -7.945],
      [110.4419, -7.9462],
      [110.4421, -7.9477],
      [110.4423, -7.9484],
      [110.4429, -7.9492],
      [110.4433, -7.9495],
      [110.4442, -7.95],
      [110.4445, -7.9501],
      [110.4449, -7.9497],
      [110.4455, -7.949],
      [110.4461, -7.9489],
      [110.447, -7.9486],
      [110.4478, -7.9483],
      [110.4482, -7.9479],
      [110.4485, -7.9474],
      [110.4487, -7.9472],
      [110.4487, -7.947],
      [110.4487, -7.9469],
      [110.449, -7.9459],
      [110.4493, -7.9457],
      [110.4495, -7.9456],
      [110.4501, -7.9448],
      [110.4502, -7.9448],
      [110.4505, -7.9447],
      [110.4511, -7.9446],
      [110.4521, -7.9448],
      [110.4523, -7.9449],
      [110.4528, -7.9451],
      [110.4532, -7.9457],
      [110.4534, -7.9459],
      [110.4536, -7.9461],
      [110.4539, -7.9461],
      [110.4541, -7.9461],
      [110.4542, -7.9461],
      [110.4544, -7.946],
      [110.455, -7.9457],
      [110.4554, -7.9454],
      [110.4555, -7.9454],
      [110.4556, -7.9453],
      [110.456, -7.9449],
      [110.456, -7.9447],
      [110.456, -7.9446],
      [110.456, -7.9444],
      [110.4559, -7.9435],
      [110.4561, -7.9433],
      [110.4564, -7.9432],
      [110.4568, -7.9434],
      [110.457, -7.9436],
      [110.4576, -7.9439],
      [110.4579, -7.9439],
      [110.4581, -7.9439],
      [110.4582, -7.9438],
      [110.4588, -7.9432],
      [110.4591, -7.9426],
      [110.4591, -7.942],
      [110.4589, -7.9417],
      [110.4583, -7.941],
      [110.4579, -7.9404],
      [110.4578, -7.9401],
      [110.4577, -7.9394],
      [110.4576, -7.9393],
      [110.4568, -7.9392],
      [110.4566, -7.9389],
      [110.4565, -7.9382],
      [110.4564, -7.9374],
      [110.4563, -7.9373],
      [110.456, -7.937],
      [110.456, -7.9369],
      [110.455, -7.9359],
      [110.4544, -7.9356],
      [110.4542, -7.9353],
      [110.4542, -7.935],
      [110.4544, -7.9344],
      [110.455, -7.934],
      [110.4553, -7.9337],
      [110.4556, -7.933],
      [110.4555, -7.9328],
      [110.4555, -7.9321],
      [110.4556, -7.9317],
      [110.456, -7.9309],
      [110.4561, -7.9297],
      [110.4562, -7.9295],
      [110.4564, -7.9288],
      [110.4562, -7.9275],
      [110.456, -7.9263],
      [110.4563, -7.9259],
      [110.4564, -7.9256],
      [110.4563, -7.9253],
      [110.456, -7.925],
      [110.4557, -7.9247],
      [110.4557, -7.9236],
      [110.456, -7.9231],
      [110.4561, -7.9228],
      [110.456, -7.9216],
      [110.4559, -7.9209],
      [110.4568, -7.9202],
      [110.4573, -7.9198],
      [110.4567, -7.9192],
      [110.4565, -7.9189],
      [110.4565, -7.9186],
      [110.4571, -7.9182],
      [110.4572, -7.9181],
      [110.4577, -7.9173],
      [110.458, -7.916],
      [110.4587, -7.9158],
      [110.4588, -7.9156],
      [110.4587, -7.9152],
      [110.4583, -7.9144],
      [110.4586, -7.9142],
      [110.4589, -7.9139],
      [110.4591, -7.9131],
      [110.4594, -7.9131],
      [110.4595, -7.9124],
      [110.4595, -7.9119],
      [110.4596, -7.9116],
      [110.4599, -7.9108],
      [110.4605, -7.9093],
      [110.4606, -7.9092],
      [110.4608, -7.9092],
      [110.4611, -7.9091],
      [110.4613, -7.9091]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'DLINGO',
    village: 'DLINGO',
    border: [
      [110.4822, -7.9353],
      [110.4817, -7.935],
      [110.481, -7.9344],
      [110.4808, -7.9344],
      [110.48, -7.9342],
      [110.4793, -7.9342],
      [110.4788, -7.9341],
      [110.4781, -7.9337],
      [110.4777, -7.9332],
      [110.477, -7.9329],
      [110.476, -7.9325],
      [110.4751, -7.932],
      [110.4741, -7.9318],
      [110.4732, -7.9313],
      [110.473, -7.9312],
      [110.4718, -7.9307],
      [110.4701, -7.93],
      [110.4687, -7.9298],
      [110.4678, -7.9298],
      [110.4666, -7.9301],
      [110.4661, -7.9303],
      [110.4658, -7.9304],
      [110.4653, -7.9309],
      [110.4649, -7.931],
      [110.4643, -7.9309],
      [110.4638, -7.9309],
      [110.4632, -7.9307],
      [110.4628, -7.9305],
      [110.461, -7.9303],
      [110.4608, -7.9304],
      [110.4605, -7.9305],
      [110.46, -7.931],
      [110.4599, -7.9311],
      [110.4598, -7.9311],
      [110.4594, -7.9313],
      [110.4585, -7.932],
      [110.4579, -7.9325],
      [110.457, -7.9336],
      [110.4565, -7.9338],
      [110.456, -7.934],
      [110.4554, -7.9341],
      [110.455, -7.9344],
      [110.4547, -7.9349],
      [110.4545, -7.9355],
      [110.4544, -7.9356],
      [110.455, -7.9359],
      [110.456, -7.9369],
      [110.456, -7.937],
      [110.4563, -7.9373],
      [110.4564, -7.9374],
      [110.4565, -7.9382],
      [110.4566, -7.9389],
      [110.4568, -7.9392],
      [110.4576, -7.9393],
      [110.4577, -7.9394],
      [110.4578, -7.9401],
      [110.4579, -7.9404],
      [110.4583, -7.941],
      [110.4589, -7.9417],
      [110.4591, -7.942],
      [110.4591, -7.9426],
      [110.4588, -7.9432],
      [110.4582, -7.9438],
      [110.4581, -7.9439],
      [110.4579, -7.9439],
      [110.4576, -7.9439],
      [110.457, -7.9436],
      [110.4568, -7.9434],
      [110.4564, -7.9432],
      [110.4561, -7.9433],
      [110.4559, -7.9435],
      [110.456, -7.9444],
      [110.456, -7.9446],
      [110.456, -7.9447],
      [110.456, -7.9449],
      [110.4556, -7.9453],
      [110.4555, -7.9454],
      [110.4554, -7.9454],
      [110.455, -7.9457],
      [110.4544, -7.946],
      [110.4542, -7.9461],
      [110.4541, -7.9461],
      [110.4539, -7.9461],
      [110.4536, -7.9461],
      [110.4534, -7.9459],
      [110.4532, -7.9457],
      [110.4528, -7.9451],
      [110.4523, -7.9449],
      [110.4521, -7.9448],
      [110.4511, -7.9446],
      [110.4505, -7.9447],
      [110.4502, -7.9448],
      [110.4501, -7.9448],
      [110.4495, -7.9456],
      [110.4493, -7.9457],
      [110.449, -7.9459],
      [110.4487, -7.9469],
      [110.4487, -7.947],
      [110.4487, -7.9472],
      [110.449, -7.9471],
      [110.4494, -7.9469],
      [110.4504, -7.947],
      [110.4513, -7.9472],
      [110.4519, -7.9475],
      [110.4526, -7.9482],
      [110.4537, -7.9497],
      [110.4543, -7.9508],
      [110.4548, -7.9515],
      [110.4549, -7.9523],
      [110.4545, -7.953],
      [110.4538, -7.9537],
      [110.4533, -7.9541],
      [110.4521, -7.9547],
      [110.4514, -7.955],
      [110.4512, -7.9555],
      [110.4512, -7.9563],
      [110.4512, -7.9581],
      [110.4511, -7.9589],
      [110.4508, -7.9598],
      [110.4507, -7.9601],
      [110.4507, -7.9603],
      [110.4506, -7.9608],
      [110.4508, -7.9614],
      [110.4509, -7.9622],
      [110.4519, -7.963],
      [110.4527, -7.9637],
      [110.4534, -7.9642],
      [110.4538, -7.965],
      [110.4543, -7.966],
      [110.4545, -7.967],
      [110.4547, -7.9674],
      [110.4553, -7.9674],
      [110.4559, -7.9671],
      [110.4564, -7.9667],
      [110.4568, -7.9663],
      [110.4571, -7.966],
      [110.4573, -7.9656],
      [110.4574, -7.9652],
      [110.4574, -7.9641],
      [110.4574, -7.9637],
      [110.4575, -7.9633],
      [110.4575, -7.9626],
      [110.4578, -7.9619],
      [110.458, -7.9614],
      [110.4579, -7.9609],
      [110.4576, -7.9605],
      [110.4571, -7.9603],
      [110.457, -7.9602],
      [110.4569, -7.9601],
      [110.4568, -7.96],
      [110.4567, -7.9598],
      [110.4566, -7.9597],
      [110.4564, -7.9592],
      [110.4565, -7.9585],
      [110.4567, -7.958],
      [110.4571, -7.9575],
      [110.4575, -7.9572],
      [110.4581, -7.9569],
      [110.4591, -7.9567],
      [110.46, -7.9565],
      [110.4607, -7.956],
      [110.4614, -7.9555],
      [110.4621, -7.9553],
      [110.4625, -7.9552],
      [110.4634, -7.9552],
      [110.4642, -7.9552],
      [110.4646, -7.9551],
      [110.465, -7.9546],
      [110.4655, -7.9543],
      [110.466, -7.9541],
      [110.4661, -7.9541],
      [110.4666, -7.9542],
      [110.467, -7.9546],
      [110.4673, -7.9551],
      [110.4672, -7.9556],
      [110.4666, -7.9562],
      [110.4661, -7.9563],
      [110.4657, -7.9565],
      [110.4648, -7.9569],
      [110.4645, -7.9572],
      [110.4644, -7.9579],
      [110.4647, -7.9588],
      [110.4652, -7.9596],
      [110.4661, -7.96],
      [110.4667, -7.9603],
      [110.4673, -7.9606],
      [110.4674, -7.9607],
      [110.4688, -7.9612],
      [110.4698, -7.9616],
      [110.4703, -7.9619],
      [110.4706, -7.9623],
      [110.4706, -7.9629],
      [110.4703, -7.9636],
      [110.4697, -7.964],
      [110.4686, -7.9646],
      [110.4684, -7.9648],
      [110.4681, -7.9656],
      [110.4679, -7.9661],
      [110.4676, -7.9666],
      [110.4671, -7.9668],
      [110.4667, -7.9668],
      [110.4661, -7.9666],
      [110.4659, -7.9665],
      [110.4656, -7.9663],
      [110.4646, -7.9665],
      [110.4641, -7.9668],
      [110.4636, -7.9673],
      [110.4634, -7.9677],
      [110.4633, -7.9683],
      [110.4636, -7.9686],
      [110.4644, -7.9692],
      [110.4654, -7.9696],
      [110.4661, -7.9698],
      [110.4667, -7.9698],
      [110.4669, -7.9701],
      [110.4671, -7.9707],
      [110.4672, -7.9711],
      [110.4675, -7.9712],
      [110.4677, -7.9711],
      [110.4681, -7.9709],
      [110.4685, -7.9705],
      [110.4689, -7.97],
      [110.4692, -7.9698],
      [110.4698, -7.9699],
      [110.471, -7.97],
      [110.4717, -7.9701],
      [110.4723, -7.97],
      [110.4731, -7.9702],
      [110.4732, -7.9703],
      [110.4734, -7.9703],
      [110.4736, -7.9704],
      [110.4741, -7.9712],
      [110.4744, -7.9717],
      [110.4747, -7.9719],
      [110.4756, -7.9719],
      [110.4762, -7.9718],
      [110.4768, -7.9716],
      [110.4771, -7.9714],
      [110.4773, -7.9712],
      [110.4774, -7.9711],
      [110.4774, -7.971],
      [110.4774, -7.9707],
      [110.4773, -7.9704],
      [110.4772, -7.9703],
      [110.4771, -7.9702],
      [110.4769, -7.9698],
      [110.4759, -7.9692],
      [110.4747, -7.9686],
      [110.4739, -7.9682],
      [110.473, -7.9678],
      [110.4729, -7.9676],
      [110.4728, -7.9675],
      [110.4727, -7.9674],
      [110.4727, -7.967],
      [110.4728, -7.9669],
      [110.473, -7.9667],
      [110.4735, -7.9668],
      [110.4743, -7.9669],
      [110.4751, -7.9668],
      [110.4754, -7.9664],
      [110.4755, -7.9663],
      [110.4759, -7.9652],
      [110.476, -7.9647],
      [110.4756, -7.963],
      [110.4755, -7.9627],
      [110.4746, -7.9602],
      [110.4744, -7.9594],
      [110.4744, -7.9592],
      [110.4741, -7.9585],
      [110.4739, -7.9579],
      [110.4736, -7.9569],
      [110.473, -7.9557],
      [110.4728, -7.9552],
      [110.4726, -7.9546],
      [110.4725, -7.9541],
      [110.4726, -7.9535],
      [110.473, -7.9531],
      [110.4734, -7.9525],
      [110.4738, -7.952],
      [110.4742, -7.9518],
      [110.4757, -7.9514],
      [110.4769, -7.951],
      [110.4775, -7.9508],
      [110.4778, -7.9506],
      [110.4781, -7.9503],
      [110.478, -7.9496],
      [110.4779, -7.9492],
      [110.4776, -7.9485],
      [110.4772, -7.9477],
      [110.4769, -7.9467],
      [110.4767, -7.9457],
      [110.4765, -7.9448],
      [110.4763, -7.9441],
      [110.4762, -7.9433],
      [110.4762, -7.9429],
      [110.4765, -7.9426],
      [110.4769, -7.9424],
      [110.4774, -7.9422],
      [110.4781, -7.9424],
      [110.4785, -7.9426],
      [110.4791, -7.9428],
      [110.48, -7.9428],
      [110.4807, -7.9426],
      [110.4814, -7.9424],
      [110.4818, -7.9421],
      [110.482, -7.9421],
      [110.4828, -7.9423],
      [110.4836, -7.9427],
      [110.4841, -7.9429],
      [110.485, -7.943],
      [110.4857, -7.9432],
      [110.4866, -7.944],
      [110.4873, -7.9447],
      [110.4879, -7.9451],
      [110.4883, -7.945],
      [110.4885, -7.9446],
      [110.4889, -7.9438],
      [110.4895, -7.9425],
      [110.4896, -7.942],
      [110.4896, -7.9418],
      [110.4896, -7.9416],
      [110.4895, -7.9414],
      [110.4894, -7.9412],
      [110.4893, -7.941],
      [110.4891, -7.9407],
      [110.4888, -7.9402],
      [110.4886, -7.9397],
      [110.4886, -7.9394],
      [110.4884, -7.9393],
      [110.4874, -7.9388],
      [110.4867, -7.9385],
      [110.4864, -7.9385],
      [110.486, -7.9377],
      [110.4853, -7.9368],
      [110.4846, -7.9364],
      [110.4838, -7.9359],
      [110.4832, -7.9359],
      [110.4822, -7.9353]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'DLINGO',
    village: 'TEMUWUH',
    border: [
      [110.4822, -7.9353],
      [110.482, -7.9347],
      [110.482, -7.9343],
      [110.4816, -7.9334],
      [110.4815, -7.933],
      [110.4812, -7.9329],
      [110.4812, -7.9322],
      [110.4812, -7.9317],
      [110.4812, -7.9306],
      [110.4812, -7.9286],
      [110.481, -7.9277],
      [110.481, -7.9269],
      [110.4814, -7.9262],
      [110.4818, -7.9259],
      [110.4825, -7.9255],
      [110.483, -7.9243],
      [110.4833, -7.9234],
      [110.4833, -7.923],
      [110.4829, -7.9225],
      [110.4825, -7.9223],
      [110.4821, -7.9222],
      [110.482, -7.9219],
      [110.4817, -7.9213],
      [110.4816, -7.9208],
      [110.4816, -7.9201],
      [110.4819, -7.9194],
      [110.4823, -7.9181],
      [110.4823, -7.9168],
      [110.482, -7.9157],
      [110.4816, -7.9148],
      [110.4813, -7.9137],
      [110.4806, -7.9124],
      [110.4802, -7.9118],
      [110.4802, -7.9114],
      [110.4789, -7.9115],
      [110.4784, -7.9108],
      [110.478, -7.9099],
      [110.4778, -7.9096],
      [110.477, -7.9092],
      [110.4764, -7.9093],
      [110.4762, -7.9089],
      [110.4761, -7.9086],
      [110.4756, -7.9081],
      [110.4747, -7.9078],
      [110.4743, -7.9076],
      [110.4738, -7.9073],
      [110.4733, -7.9068],
      [110.4729, -7.9066],
      [110.4725, -7.9062],
      [110.472, -7.9056],
      [110.4719, -7.9053],
      [110.4715, -7.9045],
      [110.4714, -7.904],
      [110.4701, -7.9039],
      [110.4694, -7.904],
      [110.4687, -7.904],
      [110.4674, -7.9041],
      [110.4665, -7.9042],
      [110.4661, -7.9043],
      [110.4656, -7.9045],
      [110.465, -7.9046],
      [110.4649, -7.9046],
      [110.4645, -7.905],
      [110.4642, -7.9052],
      [110.4642, -7.9055],
      [110.4638, -7.9057],
      [110.4638, -7.9061],
      [110.4632, -7.9063],
      [110.4631, -7.9065],
      [110.4633, -7.907],
      [110.4631, -7.9071],
      [110.4632, -7.9073],
      [110.463, -7.9074],
      [110.4627, -7.9075],
      [110.4625, -7.9078],
      [110.4621, -7.9081],
      [110.462, -7.9087],
      [110.4615, -7.9091],
      [110.4613, -7.9091],
      [110.4611, -7.9091],
      [110.4608, -7.9092],
      [110.4606, -7.9092],
      [110.4605, -7.9093],
      [110.4599, -7.9108],
      [110.4596, -7.9116],
      [110.4595, -7.9119],
      [110.4595, -7.9124],
      [110.4594, -7.9131],
      [110.4591, -7.9131],
      [110.4589, -7.9139],
      [110.4586, -7.9142],
      [110.4583, -7.9144],
      [110.4587, -7.9152],
      [110.4588, -7.9156],
      [110.4587, -7.9158],
      [110.458, -7.916],
      [110.4577, -7.9173],
      [110.4572, -7.9181],
      [110.4571, -7.9182],
      [110.4565, -7.9186],
      [110.4565, -7.9189],
      [110.4567, -7.9192],
      [110.4573, -7.9198],
      [110.4568, -7.9202],
      [110.4559, -7.9209],
      [110.456, -7.9216],
      [110.4561, -7.9228],
      [110.456, -7.9231],
      [110.4557, -7.9236],
      [110.4557, -7.9247],
      [110.456, -7.925],
      [110.4563, -7.9253],
      [110.4564, -7.9256],
      [110.4563, -7.9259],
      [110.456, -7.9263],
      [110.4562, -7.9275],
      [110.4564, -7.9288],
      [110.4562, -7.9295],
      [110.4561, -7.9297],
      [110.456, -7.9309],
      [110.4556, -7.9317],
      [110.4555, -7.9321],
      [110.4555, -7.9328],
      [110.4556, -7.933],
      [110.4553, -7.9337],
      [110.455, -7.934],
      [110.4544, -7.9344],
      [110.4542, -7.935],
      [110.4542, -7.9353],
      [110.4544, -7.9356],
      [110.4545, -7.9355],
      [110.4547, -7.9349],
      [110.455, -7.9344],
      [110.4554, -7.9341],
      [110.456, -7.934],
      [110.4565, -7.9338],
      [110.457, -7.9336],
      [110.4579, -7.9325],
      [110.4585, -7.932],
      [110.4594, -7.9313],
      [110.4598, -7.9311],
      [110.4599, -7.9311],
      [110.46, -7.931],
      [110.4605, -7.9305],
      [110.4608, -7.9304],
      [110.461, -7.9303],
      [110.4628, -7.9305],
      [110.4632, -7.9307],
      [110.4638, -7.9309],
      [110.4643, -7.9309],
      [110.4649, -7.931],
      [110.4653, -7.9309],
      [110.4658, -7.9304],
      [110.4661, -7.9303],
      [110.4666, -7.9301],
      [110.4678, -7.9298],
      [110.4687, -7.9298],
      [110.4701, -7.93],
      [110.4718, -7.9307],
      [110.473, -7.9312],
      [110.4732, -7.9313],
      [110.4741, -7.9318],
      [110.4751, -7.932],
      [110.476, -7.9325],
      [110.477, -7.9329],
      [110.4777, -7.9332],
      [110.4781, -7.9337],
      [110.4788, -7.9341],
      [110.4793, -7.9342],
      [110.48, -7.9342],
      [110.4808, -7.9344],
      [110.481, -7.9344],
      [110.4817, -7.935],
      [110.4822, -7.9353]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'DLINGO',
    village: 'TERONG',
    border: [
      [110.4676, -7.8669],
      [110.4673, -7.8668],
      [110.4669, -7.8669],
      [110.4669, -7.8673],
      [110.4668, -7.8673],
      [110.4662, -7.8674],
      [110.4661, -7.868],
      [110.4661, -7.8683],
      [110.4658, -7.8684],
      [110.4658, -7.8686],
      [110.4655, -7.8687],
      [110.4653, -7.8684],
      [110.4654, -7.8677],
      [110.4646, -7.8676],
      [110.4646, -7.8675],
      [110.4646, -7.867],
      [110.4641, -7.8668],
      [110.4639, -7.8669],
      [110.4633, -7.8676],
      [110.4628, -7.8679],
      [110.4623, -7.8679],
      [110.4617, -7.8679],
      [110.4615, -7.8684],
      [110.4614, -7.8687],
      [110.4613, -7.8694],
      [110.4609, -7.8699],
      [110.4608, -7.8698],
      [110.4605, -7.8694],
      [110.4602, -7.8693],
      [110.4595, -7.8698],
      [110.4589, -7.8704],
      [110.4581, -7.871],
      [110.457, -7.8718],
      [110.456, -7.8724],
      [110.4549, -7.8727],
      [110.4547, -7.8729],
      [110.4543, -7.8731],
      [110.4538, -7.8736],
      [110.4535, -7.8739],
      [110.4529, -7.8745],
      [110.4519, -7.8752],
      [110.4515, -7.8757],
      [110.451, -7.8765],
      [110.4505, -7.8775],
      [110.45, -7.8781],
      [110.4491, -7.8792],
      [110.4478, -7.8806],
      [110.4471, -7.8814],
      [110.4467, -7.882],
      [110.4463, -7.8827],
      [110.446, -7.8836],
      [110.4461, -7.8843],
      [110.4463, -7.8851],
      [110.4466, -7.8864],
      [110.4466, -7.8865],
      [110.4465, -7.8866],
      [110.4459, -7.8872],
      [110.4465, -7.8878],
      [110.4487, -7.8896],
      [110.4502, -7.8908],
      [110.4508, -7.891],
      [110.4511, -7.8911],
      [110.4513, -7.8917],
      [110.4515, -7.892],
      [110.4518, -7.8927],
      [110.4519, -7.893],
      [110.4523, -7.8933],
      [110.4524, -7.8934],
      [110.4524, -7.8937],
      [110.4524, -7.8941],
      [110.4527, -7.8942],
      [110.4528, -7.8943],
      [110.453, -7.8945],
      [110.4532, -7.8946],
      [110.4534, -7.8947],
      [110.4538, -7.8949],
      [110.4541, -7.8952],
      [110.4542, -7.8953],
      [110.4548, -7.8956],
      [110.455, -7.896],
      [110.455, -7.8963],
      [110.4554, -7.8963],
      [110.4555, -7.8963],
      [110.4558, -7.8964],
      [110.4558, -7.8967],
      [110.4556, -7.897],
      [110.4559, -7.898],
      [110.4559, -7.8983],
      [110.4563, -7.8984],
      [110.4564, -7.8985],
      [110.4562, -7.8989],
      [110.4563, -7.8995],
      [110.4562, -7.9001],
      [110.4566, -7.9006],
      [110.4569, -7.9011],
      [110.4572, -7.9012],
      [110.4575, -7.9013],
      [110.458, -7.902],
      [110.4579, -7.9022],
      [110.4582, -7.9027],
      [110.458, -7.9031],
      [110.4579, -7.9033],
      [110.4583, -7.9039],
      [110.4586, -7.9043],
      [110.4586, -7.9047],
      [110.4587, -7.9049],
      [110.4592, -7.9052],
      [110.46, -7.906],
      [110.4609, -7.9069],
      [110.461, -7.9073],
      [110.4612, -7.9078],
      [110.4612, -7.9079],
      [110.461, -7.9083],
      [110.4613, -7.9086],
      [110.4613, -7.9091],
      [110.4615, -7.9091],
      [110.462, -7.9087],
      [110.4621, -7.9081],
      [110.4625, -7.9078],
      [110.4627, -7.9075],
      [110.463, -7.9074],
      [110.4632, -7.9073],
      [110.4631, -7.9071],
      [110.4633, -7.907],
      [110.4631, -7.9065],
      [110.4632, -7.9063],
      [110.4638, -7.9061],
      [110.4638, -7.9057],
      [110.4642, -7.9055],
      [110.4642, -7.9052],
      [110.4645, -7.905],
      [110.4649, -7.9046],
      [110.465, -7.9046],
      [110.4656, -7.9045],
      [110.4661, -7.9043],
      [110.4665, -7.9042],
      [110.4674, -7.9041],
      [110.4687, -7.904],
      [110.4694, -7.904],
      [110.4702, -7.9025],
      [110.4706, -7.9019],
      [110.4716, -7.9011],
      [110.4722, -7.9003],
      [110.4722, -7.8997],
      [110.4722, -7.8983],
      [110.4724, -7.8978],
      [110.4733, -7.8967],
      [110.4737, -7.8953],
      [110.4736, -7.8948],
      [110.4735, -7.8939],
      [110.4737, -7.8929],
      [110.4739, -7.8921],
      [110.4743, -7.8917],
      [110.4752, -7.8911],
      [110.4765, -7.8906],
      [110.4783, -7.8902],
      [110.4776, -7.8898],
      [110.4774, -7.8897],
      [110.4773, -7.8897],
      [110.4762, -7.8899],
      [110.4761, -7.8899],
      [110.476, -7.8898],
      [110.4755, -7.8891],
      [110.475, -7.8863],
      [110.4749, -7.8848],
      [110.474, -7.8838],
      [110.4738, -7.8836],
      [110.4735, -7.8828],
      [110.4734, -7.8827],
      [110.473, -7.8821],
      [110.4724, -7.88],
      [110.4719, -7.8793],
      [110.4716, -7.8789],
      [110.4715, -7.8787],
      [110.4714, -7.8781],
      [110.4708, -7.8772],
      [110.4702, -7.8759],
      [110.4698, -7.8749],
      [110.4693, -7.8743],
      [110.4692, -7.8742],
      [110.4689, -7.8737],
      [110.4684, -7.8728],
      [110.4676, -7.8712],
      [110.4671, -7.8698],
      [110.4673, -7.8683],
      [110.4674, -7.8676],
      [110.4676, -7.8669]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'DLINGO',
    village: 'JATIMULYO',
    border: [
      [110.4953, -7.9152],
      [110.4955, -7.9148],
      [110.4957, -7.9145],
      [110.4959, -7.9141],
      [110.496, -7.9135],
      [110.496, -7.9128],
      [110.4959, -7.9119],
      [110.496, -7.9114],
      [110.4963, -7.9102],
      [110.4965, -7.909],
      [110.4968, -7.908],
      [110.4974, -7.9061],
      [110.4976, -7.9055],
      [110.4974, -7.9046],
      [110.4973, -7.9033],
      [110.4975, -7.9026],
      [110.4975, -7.902],
      [110.4978, -7.9011],
      [110.4978, -7.9001],
      [110.4976, -7.8992],
      [110.4972, -7.898],
      [110.4967, -7.8973],
      [110.4959, -7.8968],
      [110.4957, -7.8964],
      [110.4957, -7.8958],
      [110.496, -7.8947],
      [110.4963, -7.8935],
      [110.4963, -7.8924],
      [110.4958, -7.8915],
      [110.4951, -7.8908],
      [110.4949, -7.8907],
      [110.4947, -7.8906],
      [110.4935, -7.8902],
      [110.4927, -7.8899],
      [110.4923, -7.8897],
      [110.4921, -7.8898],
      [110.492, -7.8898],
      [110.4915, -7.89],
      [110.4883, -7.89],
      [110.4861, -7.8901],
      [110.4855, -7.89],
      [110.4844, -7.8899],
      [110.4834, -7.8897],
      [110.4804, -7.89],
      [110.4787, -7.8903],
      [110.4785, -7.8903],
      [110.4783, -7.8902],
      [110.4765, -7.8906],
      [110.4752, -7.8911],
      [110.4743, -7.8917],
      [110.4739, -7.8921],
      [110.4737, -7.8929],
      [110.4735, -7.8939],
      [110.4736, -7.8948],
      [110.4737, -7.8953],
      [110.4733, -7.8967],
      [110.4724, -7.8978],
      [110.4722, -7.8983],
      [110.4722, -7.8997],
      [110.4722, -7.9003],
      [110.4716, -7.9011],
      [110.4706, -7.9019],
      [110.4702, -7.9025],
      [110.4694, -7.904],
      [110.4701, -7.9039],
      [110.4714, -7.904],
      [110.4715, -7.9045],
      [110.4719, -7.9053],
      [110.472, -7.9056],
      [110.4725, -7.9062],
      [110.4729, -7.9066],
      [110.4733, -7.9068],
      [110.4738, -7.9073],
      [110.4743, -7.9076],
      [110.4747, -7.9078],
      [110.4756, -7.9081],
      [110.4761, -7.9086],
      [110.4762, -7.9089],
      [110.4764, -7.9093],
      [110.477, -7.9092],
      [110.4778, -7.9096],
      [110.478, -7.9099],
      [110.4784, -7.9108],
      [110.4789, -7.9115],
      [110.4802, -7.9114],
      [110.4802, -7.9118],
      [110.4806, -7.9124],
      [110.4813, -7.9137],
      [110.4816, -7.9148],
      [110.482, -7.9157],
      [110.4823, -7.9168],
      [110.4823, -7.9181],
      [110.4819, -7.9194],
      [110.4816, -7.9201],
      [110.4816, -7.9208],
      [110.4817, -7.9213],
      [110.482, -7.9219],
      [110.4821, -7.9222],
      [110.4825, -7.9223],
      [110.4829, -7.9225],
      [110.4833, -7.923],
      [110.4833, -7.9234],
      [110.483, -7.9243],
      [110.4825, -7.9255],
      [110.4818, -7.9259],
      [110.4814, -7.9262],
      [110.481, -7.9269],
      [110.481, -7.9277],
      [110.4812, -7.9286],
      [110.4812, -7.9306],
      [110.4812, -7.9317],
      [110.4812, -7.9322],
      [110.4812, -7.9329],
      [110.4815, -7.933],
      [110.4816, -7.9334],
      [110.482, -7.9343],
      [110.482, -7.9347],
      [110.4822, -7.9353],
      [110.4832, -7.9359],
      [110.4838, -7.9359],
      [110.4846, -7.9364],
      [110.4853, -7.9368],
      [110.486, -7.9377],
      [110.4864, -7.9385],
      [110.4867, -7.9385],
      [110.4874, -7.9388],
      [110.4884, -7.9393],
      [110.4886, -7.9394],
      [110.4886, -7.9393],
      [110.4889, -7.939],
      [110.4893, -7.939],
      [110.4896, -7.9391],
      [110.4899, -7.9392],
      [110.4901, -7.9393],
      [110.4907, -7.9394],
      [110.4913, -7.939],
      [110.4918, -7.9386],
      [110.4921, -7.9381],
      [110.4922, -7.9377],
      [110.4922, -7.9374],
      [110.4921, -7.9369],
      [110.492, -7.9366],
      [110.4919, -7.9362],
      [110.4919, -7.9357],
      [110.492, -7.935],
      [110.4922, -7.9344],
      [110.4926, -7.9337],
      [110.4929, -7.9329],
      [110.4929, -7.9328],
      [110.4931, -7.9322],
      [110.4932, -7.9316],
      [110.4929, -7.931],
      [110.4921, -7.9291],
      [110.4916, -7.928],
      [110.4912, -7.9274],
      [110.4912, -7.9265],
      [110.4913, -7.9253],
      [110.4915, -7.9244],
      [110.4917, -7.9234],
      [110.4918, -7.9232],
      [110.4924, -7.9226],
      [110.4926, -7.9222],
      [110.4927, -7.9221],
      [110.4927, -7.922],
      [110.4929, -7.9211],
      [110.4929, -7.9209],
      [110.4926, -7.9205],
      [110.4926, -7.9204],
      [110.4926, -7.9202],
      [110.4929, -7.9194],
      [110.4936, -7.9185],
      [110.4942, -7.9177],
      [110.4946, -7.9172],
      [110.4949, -7.9168],
      [110.4951, -7.9163],
      [110.4952, -7.9156],
      [110.4953, -7.9152]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'BATURETNO',
    border: [
      [110.4224, -7.7986],
      [110.4218, -7.7986],
      [110.4214, -7.7986],
      [110.4211, -7.7986],
      [110.4204, -7.7985],
      [110.4198, -7.7985],
      [110.4195, -7.7985],
      [110.4193, -7.8002],
      [110.4192, -7.8018],
      [110.4194, -7.8022],
      [110.4195, -7.8033],
      [110.4194, -7.8041],
      [110.4188, -7.8052],
      [110.418, -7.8062],
      [110.4177, -7.8064],
      [110.417, -7.8067],
      [110.4161, -7.8069],
      [110.4152, -7.8069],
      [110.415, -7.8079],
      [110.4147, -7.808],
      [110.4143, -7.809],
      [110.4136, -7.81],
      [110.413, -7.8104],
      [110.4127, -7.8111],
      [110.4113, -7.811],
      [110.4112, -7.8114],
      [110.411, -7.8127],
      [110.4107, -7.8137],
      [110.4105, -7.8147],
      [110.4104, -7.815],
      [110.4105, -7.8155],
      [110.4107, -7.8166],
      [110.411, -7.8171],
      [110.4116, -7.8182],
      [110.412, -7.819],
      [110.412, -7.8194],
      [110.4118, -7.8202],
      [110.4115, -7.8211],
      [110.4111, -7.8223],
      [110.4107, -7.8238],
      [110.4104, -7.8243],
      [110.4101, -7.8244],
      [110.41, -7.8245],
      [110.41, -7.8251],
      [110.4102, -7.8253],
      [110.4101, -7.8257],
      [110.41, -7.8261],
      [110.4099, -7.8265],
      [110.4092, -7.8264],
      [110.4089, -7.8266],
      [110.4087, -7.827],
      [110.4085, -7.8278],
      [110.4081, -7.8285],
      [110.4079, -7.829],
      [110.4078, -7.8299],
      [110.4077, -7.831],
      [110.4067, -7.8315],
      [110.4067, -7.8316],
      [110.4066, -7.832],
      [110.4066, -7.8326],
      [110.4068, -7.833],
      [110.4067, -7.8333],
      [110.4067, -7.8334],
      [110.4065, -7.8345],
      [110.4078, -7.8346],
      [110.4088, -7.8345],
      [110.409, -7.8345],
      [110.4091, -7.8342],
      [110.4088, -7.8333],
      [110.4093, -7.8334],
      [110.4095, -7.8331],
      [110.4103, -7.8331],
      [110.4106, -7.8332],
      [110.4124, -7.8338],
      [110.4122, -7.8353],
      [110.4149, -7.836],
      [110.4153, -7.8347],
      [110.4155, -7.834],
      [110.4158, -7.833],
      [110.4162, -7.8325],
      [110.4163, -7.8322],
      [110.4165, -7.8317],
      [110.4168, -7.8313],
      [110.4171, -7.8312],
      [110.4179, -7.8314],
      [110.4187, -7.8319],
      [110.419, -7.8321],
      [110.4194, -7.8321],
      [110.4192, -7.8314],
      [110.4193, -7.8308],
      [110.4194, -7.8305],
      [110.4197, -7.8303],
      [110.4197, -7.8297],
      [110.42, -7.8296],
      [110.4201, -7.829],
      [110.4204, -7.8284],
      [110.4213, -7.8273],
      [110.422, -7.8263],
      [110.4223, -7.8257],
      [110.4228, -7.825],
      [110.4231, -7.8244],
      [110.4231, -7.8241],
      [110.4228, -7.8235],
      [110.4227, -7.8228],
      [110.4227, -7.8218],
      [110.4224, -7.8218],
      [110.4219, -7.8218],
      [110.4217, -7.8215],
      [110.4214, -7.8214],
      [110.4214, -7.821],
      [110.4215, -7.8207],
      [110.422, -7.8205],
      [110.4223, -7.8202],
      [110.4228, -7.8201],
      [110.4229, -7.8199],
      [110.4226, -7.8196],
      [110.4222, -7.8192],
      [110.4221, -7.8189],
      [110.4223, -7.8188],
      [110.4225, -7.8187],
      [110.4228, -7.8187],
      [110.423, -7.8186],
      [110.423, -7.8184],
      [110.4229, -7.818],
      [110.4225, -7.8175],
      [110.4216, -7.8172],
      [110.4211, -7.817],
      [110.421, -7.8167],
      [110.421, -7.8163],
      [110.4214, -7.816],
      [110.4216, -7.8157],
      [110.4214, -7.815],
      [110.4213, -7.8144],
      [110.4214, -7.814],
      [110.4216, -7.8135],
      [110.4219, -7.8131],
      [110.4219, -7.8127],
      [110.4218, -7.8119],
      [110.4217, -7.8118],
      [110.4214, -7.8112],
      [110.4211, -7.8107],
      [110.421, -7.81],
      [110.4211, -7.8096],
      [110.4212, -7.8091],
      [110.4212, -7.8086],
      [110.4211, -7.808],
      [110.421, -7.8075],
      [110.421, -7.8068],
      [110.4212, -7.8064],
      [110.4214, -7.8061],
      [110.4216, -7.8059],
      [110.4218, -7.8055],
      [110.4219, -7.8051],
      [110.422, -7.805],
      [110.4221, -7.8043],
      [110.4222, -7.8039],
      [110.4223, -7.8031],
      [110.4225, -7.8022],
      [110.4226, -7.8011],
      [110.4228, -7.8002],
      [110.4227, -7.7996],
      [110.4225, -7.799],
      [110.4224, -7.7986]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'BANGUNTAPAN',
    border: [
      [110.4113, -7.811],
      [110.4127, -7.8111],
      [110.413, -7.8104],
      [110.4136, -7.81],
      [110.4143, -7.809],
      [110.4147, -7.808],
      [110.415, -7.8079],
      [110.4152, -7.8069],
      [110.4161, -7.8069],
      [110.417, -7.8067],
      [110.4177, -7.8064],
      [110.418, -7.8062],
      [110.4188, -7.8052],
      [110.4194, -7.8041],
      [110.4195, -7.8033],
      [110.4194, -7.8022],
      [110.4192, -7.8018],
      [110.4193, -7.8002],
      [110.4195, -7.7985],
      [110.4198, -7.7985],
      [110.4204, -7.7985],
      [110.4211, -7.7986],
      [110.4214, -7.7986],
      [110.4218, -7.7986],
      [110.4224, -7.7986],
      [110.4223, -7.7985],
      [110.4223, -7.7981],
      [110.4225, -7.7977],
      [110.4227, -7.7974],
      [110.4228, -7.7969],
      [110.4229, -7.7963],
      [110.4226, -7.796],
      [110.4223, -7.7956],
      [110.422, -7.7953],
      [110.4217, -7.7951],
      [110.4215, -7.7949],
      [110.4215, -7.7945],
      [110.4215, -7.7942],
      [110.421, -7.7935],
      [110.4208, -7.7932],
      [110.4208, -7.7929],
      [110.4209, -7.7926],
      [110.4208, -7.7924],
      [110.4204, -7.792],
      [110.4203, -7.7917],
      [110.4203, -7.7914],
      [110.4205, -7.7911],
      [110.4208, -7.791],
      [110.4212, -7.7908],
      [110.4214, -7.7907],
      [110.4214, -7.7905],
      [110.4211, -7.7904],
      [110.4208, -7.7903],
      [110.4206, -7.79],
      [110.4207, -7.7898],
      [110.4209, -7.7896],
      [110.4211, -7.7893],
      [110.4211, -7.7891],
      [110.421, -7.7889],
      [110.4209, -7.7886],
      [110.421, -7.7884],
      [110.4212, -7.788],
      [110.4212, -7.7876],
      [110.421, -7.787],
      [110.4208, -7.7864],
      [110.4197, -7.7866],
      [110.4183, -7.7867],
      [110.4164, -7.7868],
      [110.4162, -7.7869],
      [110.4137, -7.7871],
      [110.4119, -7.7872],
      [110.4105, -7.7873],
      [110.4102, -7.7874],
      [110.4094, -7.7874],
      [110.4092, -7.7863],
      [110.4086, -7.7864],
      [110.4084, -7.7868],
      [110.4084, -7.7874],
      [110.4068, -7.7876],
      [110.4066, -7.7876],
      [110.4064, -7.7871],
      [110.4066, -7.7866],
      [110.4069, -7.7859],
      [110.4067, -7.7859],
      [110.4067, -7.7856],
      [110.4063, -7.7855],
      [110.4057, -7.787],
      [110.4055, -7.7871],
      [110.4039, -7.7869],
      [110.4037, -7.7879],
      [110.4017, -7.788],
      [110.4002, -7.7882],
      [110.399, -7.7882],
      [110.3984, -7.7883],
      [110.3984, -7.788],
      [110.3985, -7.7876],
      [110.3985, -7.7873],
      [110.3987, -7.7871],
      [110.3988, -7.7869],
      [110.3991, -7.7864],
      [110.3992, -7.786],
      [110.399, -7.7857],
      [110.3988, -7.7855],
      [110.3986, -7.7855],
      [110.3984, -7.7858],
      [110.3982, -7.786],
      [110.3981, -7.7863],
      [110.3978, -7.7864],
      [110.3971, -7.7864],
      [110.3972, -7.787],
      [110.3971, -7.7878],
      [110.3972, -7.7881],
      [110.3972, -7.7884],
      [110.3974, -7.7893],
      [110.3975, -7.7899],
      [110.3973, -7.7904],
      [110.3969, -7.7907],
      [110.3965, -7.7909],
      [110.3961, -7.7911],
      [110.3961, -7.7913],
      [110.3963, -7.7915],
      [110.3964, -7.7917],
      [110.3962, -7.792],
      [110.3961, -7.7923],
      [110.396, -7.7925],
      [110.3959, -7.7928],
      [110.396, -7.7932],
      [110.396, -7.7936],
      [110.396, -7.794],
      [110.396, -7.7944],
      [110.3962, -7.7947],
      [110.3964, -7.7952],
      [110.3966, -7.7961],
      [110.3972, -7.7975],
      [110.3977, -7.7982],
      [110.398, -7.7989],
      [110.3983, -7.7995],
      [110.3985, -7.7996],
      [110.3987, -7.8002],
      [110.3986, -7.8008],
      [110.3986, -7.8013],
      [110.3985, -7.8017],
      [110.3981, -7.8019],
      [110.3978, -7.802],
      [110.3979, -7.8021],
      [110.3985, -7.8022],
      [110.3992, -7.8022],
      [110.3997, -7.8022],
      [110.4004, -7.8022],
      [110.4014, -7.8022],
      [110.4024, -7.8022],
      [110.4024, -7.8043],
      [110.4023, -7.8067],
      [110.4023, -7.8067],
      [110.4038, -7.8067],
      [110.4052, -7.8081],
      [110.4067, -7.8097],
      [110.4088, -7.8099],
      [110.4103, -7.8091],
      [110.4103, -7.8073],
      [110.4122, -7.8077],
      [110.4113, -7.811]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'JAGALAN',
    border: [
      [110.4113, -7.811],
      [110.4122, -7.8077],
      [110.4103, -7.8073],
      [110.4103, -7.8091],
      [110.4088, -7.8099],
      [110.4067, -7.8097],
      [110.4052, -7.8081],
      [110.4038, -7.8067],
      [110.4023, -7.8067],
      [110.4023, -7.8105],
      [110.4021, -7.816],
      [110.4021, -7.8173],
      [110.4019, -7.8181],
      [110.4015, -7.8192],
      [110.4011, -7.8205],
      [110.4024, -7.8207],
      [110.4033, -7.8209],
      [110.4038, -7.821],
      [110.4039, -7.821],
      [110.404, -7.8216],
      [110.404, -7.8225],
      [110.4048, -7.8225],
      [110.4048, -7.8229],
      [110.4047, -7.8238],
      [110.4046, -7.8241],
      [110.4048, -7.8244],
      [110.4048, -7.825],
      [110.4043, -7.825],
      [110.4042, -7.8254],
      [110.4047, -7.8255],
      [110.405, -7.8255],
      [110.405, -7.8257],
      [110.405, -7.8279],
      [110.405, -7.8296],
      [110.4051, -7.8304],
      [110.4057, -7.8305],
      [110.4063, -7.8307],
      [110.4065, -7.8308],
      [110.4066, -7.8313],
      [110.4067, -7.8315],
      [110.4077, -7.831],
      [110.4078, -7.8299],
      [110.4079, -7.829],
      [110.4081, -7.8285],
      [110.4085, -7.8278],
      [110.4087, -7.827],
      [110.4089, -7.8266],
      [110.4092, -7.8264],
      [110.4099, -7.8265],
      [110.41, -7.8261],
      [110.4101, -7.8257],
      [110.4102, -7.8253],
      [110.41, -7.8251],
      [110.41, -7.8245],
      [110.4101, -7.8244],
      [110.4104, -7.8243],
      [110.4107, -7.8238],
      [110.4111, -7.8223],
      [110.4115, -7.8211],
      [110.4118, -7.8202],
      [110.412, -7.8194],
      [110.412, -7.819],
      [110.4116, -7.8182],
      [110.411, -7.8171],
      [110.4107, -7.8166],
      [110.4105, -7.8155],
      [110.4104, -7.815],
      [110.4105, -7.8147],
      [110.4107, -7.8137],
      [110.411, -7.8127],
      [110.4112, -7.8114],
      [110.4113, -7.811]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'SINGOSAREN',
    border: [
      [110.3993, -7.8278],
      [110.3986, -7.8277],
      [110.398, -7.8276],
      [110.3966, -7.8275],
      [110.3953, -7.8274],
      [110.3947, -7.8274],
      [110.3937, -7.8271],
      [110.3939, -7.8277],
      [110.3939, -7.8281],
      [110.3938, -7.8285],
      [110.3937, -7.8288],
      [110.3934, -7.8291],
      [110.3933, -7.8293],
      [110.3934, -7.8296],
      [110.3937, -7.83],
      [110.3942, -7.8308],
      [110.3947, -7.8314],
      [110.3953, -7.832],
      [110.3958, -7.8325],
      [110.396, -7.8328],
      [110.396, -7.8333],
      [110.3955, -7.8336],
      [110.3951, -7.8337],
      [110.3951, -7.8343],
      [110.3951, -7.8352],
      [110.3951, -7.8361],
      [110.3953, -7.8367],
      [110.3952, -7.8369],
      [110.3951, -7.837],
      [110.3953, -7.8375],
      [110.3956, -7.8376],
      [110.396, -7.8375],
      [110.3963, -7.8372],
      [110.3968, -7.8372],
      [110.397, -7.8376],
      [110.397, -7.838],
      [110.3967, -7.8383],
      [110.3959, -7.8384],
      [110.3954, -7.8387],
      [110.3952, -7.8391],
      [110.3953, -7.8396],
      [110.3956, -7.8402],
      [110.396, -7.8408],
      [110.3962, -7.8413],
      [110.3962, -7.8417],
      [110.3963, -7.8417],
      [110.3984, -7.8418],
      [110.4005, -7.842],
      [110.4015, -7.842],
      [110.4028, -7.8419],
      [110.4026, -7.8402],
      [110.4025, -7.8389],
      [110.4026, -7.8384],
      [110.4025, -7.837],
      [110.4024, -7.8357],
      [110.4023, -7.8352],
      [110.402, -7.8339],
      [110.4018, -7.8334],
      [110.4011, -7.8335],
      [110.4009, -7.8331],
      [110.4007, -7.8327],
      [110.4007, -7.8324],
      [110.4008, -7.832],
      [110.4006, -7.832],
      [110.4004, -7.8327],
      [110.4002, -7.8333],
      [110.3998, -7.8335],
      [110.3991, -7.8335],
      [110.3989, -7.8334],
      [110.399, -7.8322],
      [110.3991, -7.8319],
      [110.3989, -7.8317],
      [110.3989, -7.8311],
      [110.3989, -7.8302],
      [110.3989, -7.8297],
      [110.3991, -7.8293],
      [110.3993, -7.8289],
      [110.3994, -7.8287],
      [110.3994, -7.8283],
      [110.3993, -7.8278]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'JAMBIDAN',
    border: [
      [110.4277, -7.8459],
      [110.4273, -7.8458],
      [110.4272, -7.8458],
      [110.4257, -7.8455],
      [110.4234, -7.8452],
      [110.4209, -7.8448],
      [110.4191, -7.8444],
      [110.4176, -7.8441],
      [110.4177, -7.8433],
      [110.4177, -7.8429],
      [110.4172, -7.8428],
      [110.4165, -7.8428],
      [110.4166, -7.8422],
      [110.4169, -7.8419],
      [110.4168, -7.8417],
      [110.416, -7.8414],
      [110.4162, -7.8423],
      [110.416, -7.8425],
      [110.4159, -7.843],
      [110.4157, -7.843],
      [110.4156, -7.8436],
      [110.4138, -7.8432],
      [110.4135, -7.8438],
      [110.4128, -7.8438],
      [110.4127, -7.8433],
      [110.411, -7.8429],
      [110.4108, -7.8444],
      [110.4103, -7.8482],
      [110.4101, -7.8505],
      [110.4097, -7.8537],
      [110.4094, -7.8553],
      [110.4089, -7.8597],
      [110.4098, -7.8599],
      [110.411, -7.86],
      [110.4118, -7.8603],
      [110.4124, -7.8608],
      [110.4133, -7.861],
      [110.4135, -7.8611],
      [110.4143, -7.8616],
      [110.4146, -7.8619],
      [110.4149, -7.862],
      [110.4156, -7.8621],
      [110.4162, -7.8622],
      [110.4166, -7.8622],
      [110.4169, -7.8622],
      [110.4173, -7.8621],
      [110.4182, -7.8623],
      [110.4198, -7.8626],
      [110.4208, -7.8628],
      [110.421, -7.8619],
      [110.4211, -7.8613],
      [110.4211, -7.8612],
      [110.4212, -7.8611],
      [110.4218, -7.8606],
      [110.4227, -7.86],
      [110.423, -7.8597],
      [110.4231, -7.8594],
      [110.4239, -7.8589],
      [110.4249, -7.8579],
      [110.4258, -7.857],
      [110.4264, -7.8563],
      [110.4268, -7.8557],
      [110.427, -7.8549],
      [110.4272, -7.8543],
      [110.4277, -7.8537],
      [110.4284, -7.8531],
      [110.429, -7.8525],
      [110.4296, -7.8521],
      [110.4296, -7.8519],
      [110.4296, -7.8517],
      [110.4295, -7.8513],
      [110.4294, -7.8508],
      [110.429, -7.8501],
      [110.4281, -7.849],
      [110.4276, -7.8483],
      [110.4274, -7.8476],
      [110.4274, -7.8471],
      [110.4277, -7.8462],
      [110.4277, -7.8459]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'POTORONO',
    border: [
      [110.4299, -7.8358],
      [110.4299, -7.8339],
      [110.4299, -7.8319],
      [110.4306, -7.832],
      [110.4311, -7.8321],
      [110.4313, -7.8313],
      [110.4314, -7.8306],
      [110.4315, -7.8303],
      [110.4309, -7.8304],
      [110.4304, -7.8304],
      [110.4295, -7.8303],
      [110.4285, -7.8298],
      [110.4276, -7.829],
      [110.4274, -7.8287],
      [110.4274, -7.8282],
      [110.4264, -7.8275],
      [110.4261, -7.827],
      [110.4261, -7.8265],
      [110.4262, -7.8259],
      [110.4261, -7.8257],
      [110.4265, -7.8252],
      [110.4267, -7.8248],
      [110.4269, -7.8244],
      [110.427, -7.8234],
      [110.4282, -7.824],
      [110.4283, -7.8216],
      [110.4282, -7.8216],
      [110.4274, -7.8225],
      [110.427, -7.8225],
      [110.4268, -7.8231],
      [110.4262, -7.8229],
      [110.4263, -7.8226],
      [110.4265, -7.8223],
      [110.4264, -7.8221],
      [110.4262, -7.8226],
      [110.4257, -7.8226],
      [110.4252, -7.8225],
      [110.4248, -7.8225],
      [110.4244, -7.8225],
      [110.4243, -7.8225],
      [110.4234, -7.822],
      [110.4229, -7.8218],
      [110.4227, -7.8218],
      [110.4227, -7.8228],
      [110.4228, -7.8235],
      [110.4231, -7.8241],
      [110.4231, -7.8244],
      [110.4228, -7.825],
      [110.4223, -7.8257],
      [110.422, -7.8263],
      [110.4213, -7.8273],
      [110.4204, -7.8284],
      [110.4201, -7.829],
      [110.42, -7.8296],
      [110.4197, -7.8297],
      [110.4197, -7.8303],
      [110.4194, -7.8305],
      [110.4193, -7.8308],
      [110.4192, -7.8314],
      [110.4194, -7.8321],
      [110.419, -7.8321],
      [110.4187, -7.8319],
      [110.4179, -7.8314],
      [110.4171, -7.8312],
      [110.4168, -7.8313],
      [110.4165, -7.8317],
      [110.4163, -7.8322],
      [110.4162, -7.8325],
      [110.4158, -7.833],
      [110.4155, -7.834],
      [110.4153, -7.8347],
      [110.4149, -7.836],
      [110.4122, -7.8353],
      [110.4124, -7.8338],
      [110.4106, -7.8332],
      [110.4103, -7.8331],
      [110.4095, -7.8331],
      [110.4093, -7.8334],
      [110.4088, -7.8333],
      [110.4091, -7.8342],
      [110.409, -7.8345],
      [110.4088, -7.8345],
      [110.4078, -7.8346],
      [110.4078, -7.835],
      [110.4078, -7.8357],
      [110.4081, -7.8358],
      [110.4082, -7.8361],
      [110.4081, -7.8365],
      [110.4083, -7.8368],
      [110.4083, -7.8371],
      [110.4082, -7.8378],
      [110.4083, -7.8379],
      [110.4083, -7.8383],
      [110.4079, -7.8383],
      [110.4078, -7.8395],
      [110.4082, -7.8396],
      [110.4084, -7.84],
      [110.4083, -7.8404],
      [110.4078, -7.8403],
      [110.4076, -7.8408],
      [110.4075, -7.8408],
      [110.4071, -7.8405],
      [110.4066, -7.8405],
      [110.4064, -7.8404],
      [110.4055, -7.8403],
      [110.4054, -7.8403],
      [110.4053, -7.8407],
      [110.4052, -7.8409],
      [110.4053, -7.8417],
      [110.4056, -7.8422],
      [110.4056, -7.8431],
      [110.4054, -7.8438],
      [110.4053, -7.8443],
      [110.4079, -7.8447],
      [110.4077, -7.8464],
      [110.4077, -7.8473],
      [110.41, -7.8481],
      [110.4103, -7.8482],
      [110.4108, -7.8444],
      [110.411, -7.8429],
      [110.4127, -7.8433],
      [110.4128, -7.8438],
      [110.4135, -7.8438],
      [110.4138, -7.8432],
      [110.4156, -7.8436],
      [110.4157, -7.843],
      [110.4159, -7.843],
      [110.416, -7.8425],
      [110.4162, -7.8423],
      [110.416, -7.8414],
      [110.4168, -7.8417],
      [110.4169, -7.8419],
      [110.4166, -7.8422],
      [110.4165, -7.8428],
      [110.4172, -7.8428],
      [110.4177, -7.8429],
      [110.4177, -7.8433],
      [110.4176, -7.8441],
      [110.4191, -7.8444],
      [110.4209, -7.8448],
      [110.4234, -7.8452],
      [110.4257, -7.8455],
      [110.4272, -7.8458],
      [110.4273, -7.8458],
      [110.4277, -7.8459],
      [110.4279, -7.8456],
      [110.4282, -7.8452],
      [110.4287, -7.8451],
      [110.4288, -7.8449],
      [110.4285, -7.8444],
      [110.4286, -7.8442],
      [110.4288, -7.8439],
      [110.429, -7.8437],
      [110.4292, -7.8432],
      [110.4289, -7.8431],
      [110.4282, -7.8431],
      [110.4278, -7.8428],
      [110.4272, -7.8422],
      [110.427, -7.8416],
      [110.4268, -7.8409],
      [110.4267, -7.8402],
      [110.4267, -7.84],
      [110.4268, -7.8395],
      [110.427, -7.8394],
      [110.4274, -7.8394],
      [110.4281, -7.8393],
      [110.4285, -7.8393],
      [110.4286, -7.8391],
      [110.4283, -7.839],
      [110.4282, -7.8387],
      [110.428, -7.8385],
      [110.4279, -7.8381],
      [110.4278, -7.8378],
      [110.4281, -7.837],
      [110.4282, -7.8362],
      [110.4283, -7.8355],
      [110.4299, -7.8358]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'TAMANAN',
    border: [
      [110.3844, -7.83],
      [110.3844, -7.8296],
      [110.3845, -7.8291],
      [110.3831, -7.8291],
      [110.3821, -7.8291],
      [110.3813, -7.8291],
      [110.3811, -7.829],
      [110.3809, -7.8287],
      [110.3799, -7.8286],
      [110.3802, -7.8298],
      [110.3796, -7.8298],
      [110.3792, -7.83],
      [110.3791, -7.8302],
      [110.379, -7.8305],
      [110.3777, -7.8309],
      [110.3768, -7.831],
      [110.3766, -7.831],
      [110.3764, -7.8306],
      [110.3764, -7.8304],
      [110.3763, -7.8299],
      [110.3762, -7.8296],
      [110.3761, -7.8292],
      [110.3758, -7.8296],
      [110.3757, -7.83],
      [110.3757, -7.8304],
      [110.3756, -7.8309],
      [110.3754, -7.831],
      [110.3758, -7.8313],
      [110.3762, -7.8315],
      [110.3766, -7.8318],
      [110.3769, -7.8323],
      [110.3767, -7.8328],
      [110.3765, -7.8334],
      [110.3761, -7.8341],
      [110.3757, -7.8347],
      [110.3754, -7.8356],
      [110.3755, -7.8363],
      [110.3756, -7.8368],
      [110.3763, -7.8371],
      [110.3766, -7.8376],
      [110.3766, -7.8383],
      [110.3764, -7.8387],
      [110.3766, -7.8392],
      [110.3767, -7.8407],
      [110.3768, -7.8407],
      [110.3782, -7.8402],
      [110.3803, -7.8396],
      [110.3804, -7.8407],
      [110.381, -7.8431],
      [110.3812, -7.8442],
      [110.3813, -7.845],
      [110.3813, -7.8456],
      [110.3811, -7.8458],
      [110.3808, -7.8461],
      [110.3804, -7.8462],
      [110.3804, -7.8463],
      [110.3809, -7.8464],
      [110.3814, -7.8465],
      [110.3816, -7.8466],
      [110.3816, -7.8469],
      [110.3816, -7.8475],
      [110.3815, -7.8484],
      [110.3812, -7.8484],
      [110.3803, -7.8484],
      [110.3803, -7.849],
      [110.3802, -7.8501],
      [110.3802, -7.8517],
      [110.3802, -7.8521],
      [110.3801, -7.8522],
      [110.3796, -7.8522],
      [110.3786, -7.8522],
      [110.3784, -7.852],
      [110.378, -7.8519],
      [110.377, -7.8519],
      [110.3764, -7.8518],
      [110.3755, -7.8517],
      [110.3753, -7.852],
      [110.3754, -7.8523],
      [110.3756, -7.853],
      [110.3759, -7.8537],
      [110.376, -7.8547],
      [110.3776, -7.855],
      [110.3784, -7.8551],
      [110.3794, -7.8551],
      [110.3805, -7.8555],
      [110.3815, -7.8559],
      [110.3824, -7.8563],
      [110.3833, -7.8567],
      [110.3842, -7.857],
      [110.3851, -7.857],
      [110.3857, -7.8573],
      [110.3856, -7.858],
      [110.3857, -7.8583],
      [110.3858, -7.8583],
      [110.3862, -7.8584],
      [110.3868, -7.8584],
      [110.3879, -7.8582],
      [110.3889, -7.858],
      [110.3898, -7.8579],
      [110.3899, -7.8569],
      [110.3901, -7.8555],
      [110.3902, -7.8553],
      [110.39, -7.8552],
      [110.39, -7.8543],
      [110.3881, -7.8541],
      [110.3886, -7.851],
      [110.3893, -7.8479],
      [110.3876, -7.8476],
      [110.3875, -7.8476],
      [110.388, -7.8459],
      [110.3887, -7.8444],
      [110.3889, -7.8444],
      [110.3897, -7.8444],
      [110.3899, -7.8432],
      [110.3928, -7.8434],
      [110.3954, -7.8435],
      [110.3955, -7.8429],
      [110.3955, -7.8421],
      [110.3957, -7.8419],
      [110.3962, -7.8417],
      [110.3962, -7.8413],
      [110.396, -7.8408],
      [110.3956, -7.8402],
      [110.3953, -7.8396],
      [110.3952, -7.8391],
      [110.3954, -7.8387],
      [110.3959, -7.8384],
      [110.3967, -7.8383],
      [110.397, -7.838],
      [110.397, -7.8376],
      [110.3968, -7.8372],
      [110.3963, -7.8372],
      [110.396, -7.8375],
      [110.3956, -7.8376],
      [110.3953, -7.8375],
      [110.3951, -7.837],
      [110.395, -7.8371],
      [110.3943, -7.8371],
      [110.3939, -7.8371],
      [110.3931, -7.837],
      [110.3921, -7.8369],
      [110.3912, -7.8368],
      [110.3906, -7.8369],
      [110.3903, -7.8379],
      [110.39, -7.8397],
      [110.3899, -7.8402],
      [110.3895, -7.8402],
      [110.3888, -7.8401],
      [110.3881, -7.8401],
      [110.388, -7.8393],
      [110.3882, -7.8381],
      [110.3882, -7.8376],
      [110.3882, -7.8373],
      [110.3881, -7.8368],
      [110.3879, -7.8367],
      [110.3876, -7.8366],
      [110.3871, -7.8369],
      [110.3869, -7.8365],
      [110.387, -7.8362],
      [110.3873, -7.8362],
      [110.3875, -7.836],
      [110.3877, -7.8358],
      [110.3877, -7.8354],
      [110.3878, -7.8347],
      [110.3878, -7.8341],
      [110.3876, -7.8341],
      [110.3863, -7.8341],
      [110.3861, -7.8341],
      [110.3861, -7.8337],
      [110.3861, -7.8331],
      [110.3862, -7.8326],
      [110.3861, -7.8322],
      [110.3857, -7.8315],
      [110.3853, -7.8314],
      [110.3846, -7.8314],
      [110.3845, -7.8306],
      [110.3844, -7.83]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'BANGUNTAPAN',
    village: 'WIROKERTEN',
    border: [
      [110.4065, -7.8345],
      [110.4058, -7.8343],
      [110.4049, -7.8341],
      [110.4039, -7.834],
      [110.403, -7.834],
      [110.4022, -7.834],
      [110.402, -7.8339],
      [110.4023, -7.8352],
      [110.4024, -7.8357],
      [110.4025, -7.837],
      [110.4026, -7.8384],
      [110.4025, -7.8389],
      [110.4026, -7.8402],
      [110.4028, -7.8419],
      [110.4015, -7.842],
      [110.4005, -7.842],
      [110.3984, -7.8418],
      [110.3963, -7.8417],
      [110.3962, -7.8417],
      [110.3957, -7.8419],
      [110.3955, -7.8421],
      [110.3955, -7.8429],
      [110.3954, -7.8435],
      [110.3928, -7.8434],
      [110.3899, -7.8432],
      [110.3897, -7.8444],
      [110.3889, -7.8444],
      [110.3887, -7.8444],
      [110.388, -7.8459],
      [110.3875, -7.8476],
      [110.3876, -7.8476],
      [110.3893, -7.8479],
      [110.3886, -7.851],
      [110.3881, -7.8541],
      [110.39, -7.8543],
      [110.39, -7.8552],
      [110.3902, -7.8553],
      [110.3915, -7.8556],
      [110.3923, -7.8557],
      [110.3933, -7.8559],
      [110.3938, -7.856],
      [110.3941, -7.8561],
      [110.3939, -7.8566],
      [110.3938, -7.8572],
      [110.3937, -7.8576],
      [110.3937, -7.858],
      [110.3937, -7.8584],
      [110.3943, -7.8584],
      [110.3947, -7.8586],
      [110.3948, -7.8582],
      [110.3952, -7.858],
      [110.3957, -7.858],
      [110.396, -7.8581],
      [110.3961, -7.8584],
      [110.3969, -7.8586],
      [110.3974, -7.8587],
      [110.3969, -7.8597],
      [110.3964, -7.8608],
      [110.3963, -7.8615],
      [110.3964, -7.8616],
      [110.3966, -7.8616],
      [110.3975, -7.8619],
      [110.3985, -7.8621],
      [110.3994, -7.8622],
      [110.3996, -7.8628],
      [110.4005, -7.8629],
      [110.4013, -7.863],
      [110.4019, -7.8632],
      [110.4021, -7.8631],
      [110.4022, -7.8623],
      [110.4024, -7.8606],
      [110.4025, -7.8596],
      [110.4027, -7.859],
      [110.4033, -7.8569],
      [110.4036, -7.8558],
      [110.4038, -7.8546],
      [110.4059, -7.8549],
      [110.4094, -7.8553],
      [110.4097, -7.8537],
      [110.4101, -7.8505],
      [110.4103, -7.8482],
      [110.41, -7.8481],
      [110.4077, -7.8473],
      [110.4077, -7.8464],
      [110.4079, -7.8447],
      [110.4053, -7.8443],
      [110.4054, -7.8438],
      [110.4056, -7.8431],
      [110.4056, -7.8422],
      [110.4053, -7.8417],
      [110.4052, -7.8409],
      [110.4053, -7.8407],
      [110.4054, -7.8403],
      [110.4055, -7.8403],
      [110.4064, -7.8404],
      [110.4066, -7.8405],
      [110.4071, -7.8405],
      [110.4075, -7.8408],
      [110.4076, -7.8408],
      [110.4078, -7.8403],
      [110.4083, -7.8404],
      [110.4084, -7.84],
      [110.4082, -7.8396],
      [110.4078, -7.8395],
      [110.4079, -7.8383],
      [110.4083, -7.8383],
      [110.4083, -7.8379],
      [110.4082, -7.8378],
      [110.4083, -7.8371],
      [110.4083, -7.8368],
      [110.4081, -7.8365],
      [110.4082, -7.8361],
      [110.4081, -7.8358],
      [110.4078, -7.8357],
      [110.4078, -7.835],
      [110.4078, -7.8346],
      [110.4065, -7.8345]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PLERET',
    village: 'WONOKROMO',
    border: [
      [110.3902, -7.8553],
      [110.3901, -7.8555],
      [110.3899, -7.8569],
      [110.3898, -7.8579],
      [110.3889, -7.858],
      [110.3879, -7.8582],
      [110.3868, -7.8584],
      [110.3862, -7.8584],
      [110.3858, -7.8583],
      [110.3857, -7.8583],
      [110.3856, -7.858],
      [110.3857, -7.8573],
      [110.3851, -7.857],
      [110.3842, -7.857],
      [110.3833, -7.8567],
      [110.3824, -7.8563],
      [110.3815, -7.8559],
      [110.3805, -7.8555],
      [110.3794, -7.8551],
      [110.3784, -7.8551],
      [110.3776, -7.855],
      [110.376, -7.8547],
      [110.376, -7.8555],
      [110.376, -7.856],
      [110.3762, -7.8566],
      [110.3761, -7.8572],
      [110.3761, -7.8576],
      [110.3763, -7.8581],
      [110.3763, -7.8587],
      [110.3763, -7.8593],
      [110.3763, -7.8593],
      [110.3766, -7.8599],
      [110.3771, -7.8606],
      [110.3778, -7.8611],
      [110.3783, -7.8616],
      [110.3786, -7.8624],
      [110.3785, -7.863],
      [110.3782, -7.864],
      [110.3783, -7.8647],
      [110.3785, -7.8654],
      [110.3786, -7.8661],
      [110.3786, -7.8666],
      [110.3788, -7.8669],
      [110.3792, -7.867],
      [110.3798, -7.8668],
      [110.3804, -7.8666],
      [110.3809, -7.8666],
      [110.3814, -7.8666],
      [110.3814, -7.8668],
      [110.3813, -7.8673],
      [110.3812, -7.868],
      [110.3809, -7.8687],
      [110.3805, -7.8687],
      [110.3802, -7.8687],
      [110.3801, -7.8689],
      [110.38, -7.8695],
      [110.3798, -7.8707],
      [110.3797, -7.8713],
      [110.3796, -7.8717],
      [110.3797, -7.8721],
      [110.38, -7.8725],
      [110.3804, -7.8726],
      [110.3806, -7.8726],
      [110.3808, -7.8726],
      [110.3815, -7.8726],
      [110.3819, -7.8727],
      [110.382, -7.8729],
      [110.3821, -7.873],
      [110.3821, -7.8735],
      [110.382, -7.8739],
      [110.3818, -7.8746],
      [110.3821, -7.8753],
      [110.3823, -7.8757],
      [110.3829, -7.8753],
      [110.3832, -7.8753],
      [110.3835, -7.8753],
      [110.3842, -7.8753],
      [110.3856, -7.8752],
      [110.386, -7.8754],
      [110.386, -7.8761],
      [110.386, -7.8768],
      [110.3862, -7.8771],
      [110.387, -7.8772],
      [110.388, -7.8771],
      [110.389, -7.8769],
      [110.3901, -7.8769],
      [110.3908, -7.8767],
      [110.3923, -7.8771],
      [110.3929, -7.8773],
      [110.3935, -7.8775],
      [110.3938, -7.8775],
      [110.3939, -7.8773],
      [110.3945, -7.8765],
      [110.3949, -7.8762],
      [110.3955, -7.8765],
      [110.3955, -7.8763],
      [110.3954, -7.8758],
      [110.3955, -7.8753],
      [110.3956, -7.8748],
      [110.3954, -7.8744],
      [110.395, -7.8739],
      [110.3949, -7.8737],
      [110.3949, -7.8735],
      [110.3949, -7.8731],
      [110.3952, -7.8726],
      [110.3954, -7.8722],
      [110.3952, -7.8718],
      [110.395, -7.8717],
      [110.3949, -7.8711],
      [110.3948, -7.8701],
      [110.3949, -7.8689],
      [110.3949, -7.8683],
      [110.3946, -7.8669],
      [110.3947, -7.8659],
      [110.3952, -7.8646],
      [110.3953, -7.864],
      [110.3953, -7.8633],
      [110.3954, -7.8631],
      [110.3959, -7.8627],
      [110.3963, -7.8625],
      [110.3966, -7.862],
      [110.3966, -7.8616],
      [110.3964, -7.8616],
      [110.3963, -7.8615],
      [110.3964, -7.8608],
      [110.3969, -7.8597],
      [110.3974, -7.8587],
      [110.3969, -7.8586],
      [110.3961, -7.8584],
      [110.396, -7.8581],
      [110.3957, -7.858],
      [110.3952, -7.858],
      [110.3948, -7.8582],
      [110.3947, -7.8586],
      [110.3943, -7.8584],
      [110.3937, -7.8584],
      [110.3937, -7.858],
      [110.3937, -7.8576],
      [110.3938, -7.8572],
      [110.3939, -7.8566],
      [110.3941, -7.8561],
      [110.3938, -7.856],
      [110.3933, -7.8559],
      [110.3923, -7.8557],
      [110.3915, -7.8556],
      [110.3902, -7.8553]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PLERET',
    village: 'PLERET',
    border: [
      [110.4094, -7.8553],
      [110.4059, -7.8549],
      [110.4038, -7.8546],
      [110.4036, -7.8558],
      [110.4033, -7.8569],
      [110.4027, -7.859],
      [110.4025, -7.8596],
      [110.4024, -7.8606],
      [110.4022, -7.8623],
      [110.4021, -7.8631],
      [110.4019, -7.8632],
      [110.4013, -7.863],
      [110.4005, -7.8629],
      [110.3996, -7.8628],
      [110.3994, -7.8622],
      [110.3985, -7.8621],
      [110.3975, -7.8619],
      [110.3966, -7.8616],
      [110.3966, -7.862],
      [110.3963, -7.8625],
      [110.3959, -7.8627],
      [110.3954, -7.8631],
      [110.3953, -7.8633],
      [110.3953, -7.864],
      [110.3952, -7.8646],
      [110.3947, -7.8659],
      [110.3946, -7.8669],
      [110.3949, -7.8683],
      [110.3949, -7.8689],
      [110.3948, -7.8701],
      [110.3949, -7.8711],
      [110.395, -7.8717],
      [110.3952, -7.8718],
      [110.3954, -7.8722],
      [110.3952, -7.8726],
      [110.3949, -7.8731],
      [110.3949, -7.8735],
      [110.3949, -7.8737],
      [110.395, -7.8739],
      [110.3954, -7.8744],
      [110.3956, -7.8748],
      [110.3955, -7.8753],
      [110.3954, -7.8758],
      [110.3955, -7.8763],
      [110.3955, -7.8765],
      [110.3967, -7.8777],
      [110.3973, -7.8781],
      [110.3978, -7.8783],
      [110.3986, -7.8786],
      [110.3994, -7.8793],
      [110.4006, -7.8795],
      [110.401, -7.8795],
      [110.4016, -7.8796],
      [110.4017, -7.8797],
      [110.4026, -7.8799],
      [110.4028, -7.8799],
      [110.403, -7.8799],
      [110.4037, -7.8804],
      [110.4049, -7.8806],
      [110.4058, -7.8804],
      [110.4066, -7.8794],
      [110.4071, -7.8783],
      [110.4075, -7.8773],
      [110.4079, -7.8771],
      [110.4084, -7.8769],
      [110.4085, -7.8769],
      [110.409, -7.8767],
      [110.4098, -7.8764],
      [110.4101, -7.8763],
      [110.4105, -7.876],
      [110.4108, -7.8758],
      [110.4112, -7.8757],
      [110.4114, -7.8756],
      [110.4116, -7.8756],
      [110.4122, -7.8752],
      [110.4124, -7.8746],
      [110.4123, -7.8743],
      [110.4122, -7.8741],
      [110.412, -7.8738],
      [110.4119, -7.8734],
      [110.4121, -7.8728],
      [110.4127, -7.872],
      [110.4135, -7.8713],
      [110.4147, -7.8706],
      [110.4165, -7.8697],
      [110.4176, -7.8694],
      [110.4187, -7.8687],
      [110.4191, -7.868],
      [110.4195, -7.8664],
      [110.4197, -7.8656],
      [110.4202, -7.8647],
      [110.4206, -7.8642],
      [110.4207, -7.8635],
      [110.4209, -7.863],
      [110.4208, -7.8628],
      [110.4198, -7.8626],
      [110.4182, -7.8623],
      [110.4173, -7.8621],
      [110.4169, -7.8622],
      [110.4166, -7.8622],
      [110.4162, -7.8622],
      [110.4156, -7.8621],
      [110.4149, -7.862],
      [110.4146, -7.8619],
      [110.4143, -7.8616],
      [110.4135, -7.8611],
      [110.4133, -7.861],
      [110.4124, -7.8608],
      [110.4118, -7.8603],
      [110.411, -7.86],
      [110.4098, -7.8599],
      [110.4089, -7.8597],
      [110.4094, -7.8553]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PLERET',
    village: 'SEGOROYOSO',
    border: [
      [110.4242, -7.8834],
      [110.424, -7.8832],
      [110.4236, -7.8828],
      [110.4232, -7.8818],
      [110.4226, -7.8803],
      [110.4219, -7.8789],
      [110.4214, -7.8784],
      [110.421, -7.878],
      [110.4208, -7.8779],
      [110.4202, -7.8778],
      [110.4193, -7.8774],
      [110.4177, -7.8772],
      [110.4168, -7.877],
      [110.4151, -7.8767],
      [110.4139, -7.8764],
      [110.4126, -7.8762],
      [110.4118, -7.8758],
      [110.4114, -7.8756],
      [110.4112, -7.8757],
      [110.4108, -7.8758],
      [110.4105, -7.876],
      [110.4101, -7.8763],
      [110.4098, -7.8764],
      [110.409, -7.8767],
      [110.4085, -7.8769],
      [110.4084, -7.8769],
      [110.4079, -7.8771],
      [110.4075, -7.8773],
      [110.4071, -7.8783],
      [110.4066, -7.8794],
      [110.4058, -7.8804],
      [110.4049, -7.8806],
      [110.4037, -7.8804],
      [110.403, -7.8799],
      [110.4028, -7.8799],
      [110.4026, -7.8799],
      [110.4017, -7.8797],
      [110.4016, -7.8796],
      [110.401, -7.8795],
      [110.4006, -7.8795],
      [110.3994, -7.8793],
      [110.3986, -7.8786],
      [110.3978, -7.8783],
      [110.3973, -7.8781],
      [110.3967, -7.8777],
      [110.3955, -7.8765],
      [110.3949, -7.8762],
      [110.3945, -7.8765],
      [110.3939, -7.8773],
      [110.3938, -7.8775],
      [110.394, -7.8775],
      [110.3945, -7.8782],
      [110.3943, -7.8786],
      [110.394, -7.8792],
      [110.394, -7.8799],
      [110.3942, -7.8803],
      [110.3947, -7.8802],
      [110.395, -7.8803],
      [110.395, -7.8806],
      [110.3949, -7.8817],
      [110.3951, -7.8833],
      [110.3956, -7.8849],
      [110.3958, -7.8854],
      [110.3962, -7.8855],
      [110.3966, -7.8856],
      [110.3969, -7.8859],
      [110.397, -7.8863],
      [110.397, -7.8866],
      [110.3968, -7.887],
      [110.3961, -7.8872],
      [110.3953, -7.8874],
      [110.3952, -7.8893],
      [110.395, -7.8905],
      [110.3948, -7.8908],
      [110.3946, -7.8913],
      [110.3942, -7.8922],
      [110.3942, -7.8928],
      [110.3943, -7.8936],
      [110.3941, -7.8944],
      [110.3941, -7.8954],
      [110.3947, -7.8959],
      [110.395, -7.8964],
      [110.3954, -7.8969],
      [110.3955, -7.8969],
      [110.3964, -7.8971],
      [110.3974, -7.8971],
      [110.3982, -7.8972],
      [110.3989, -7.8971],
      [110.4002, -7.8968],
      [110.4022, -7.8965],
      [110.4044, -7.8964],
      [110.4049, -7.8962],
      [110.4078, -7.8958],
      [110.4095, -7.8954],
      [110.4104, -7.8951],
      [110.4109, -7.8949],
      [110.4115, -7.8946],
      [110.412, -7.8943],
      [110.4126, -7.894],
      [110.4136, -7.8931],
      [110.4143, -7.8929],
      [110.4145, -7.8925],
      [110.415, -7.8921],
      [110.4154, -7.8915],
      [110.416, -7.8914],
      [110.4166, -7.8914],
      [110.4172, -7.8916],
      [110.4184, -7.8921],
      [110.4196, -7.8924],
      [110.4213, -7.8929],
      [110.4217, -7.8933],
      [110.4222, -7.8929],
      [110.4231, -7.8919],
      [110.4232, -7.8912],
      [110.4232, -7.8901],
      [110.4233, -7.8894],
      [110.4244, -7.8882],
      [110.4252, -7.8872],
      [110.4257, -7.8859],
      [110.4256, -7.8855],
      [110.4255, -7.8852],
      [110.4242, -7.8834]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PLERET',
    village: 'BAWURAN',
    border: [
      [110.4431, -7.8649],
      [110.4426, -7.8651],
      [110.4417, -7.8651],
      [110.4414, -7.8653],
      [110.4403, -7.8653],
      [110.4399, -7.8653],
      [110.4393, -7.866],
      [110.439, -7.8661],
      [110.4383, -7.8664],
      [110.4379, -7.8664],
      [110.4375, -7.8668],
      [110.4374, -7.8671],
      [110.4371, -7.8674],
      [110.4368, -7.8675],
      [110.4363, -7.8674],
      [110.4356, -7.8674],
      [110.4351, -7.8674],
      [110.4347, -7.8672],
      [110.4344, -7.8672],
      [110.4342, -7.8673],
      [110.4341, -7.8679],
      [110.4339, -7.8684],
      [110.4336, -7.869],
      [110.4333, -7.8694],
      [110.4328, -7.8695],
      [110.4323, -7.8693],
      [110.432, -7.8696],
      [110.4318, -7.87],
      [110.4318, -7.8704],
      [110.4317, -7.8709],
      [110.4316, -7.8713],
      [110.4313, -7.8713],
      [110.431, -7.8711],
      [110.4307, -7.8713],
      [110.4305, -7.8714],
      [110.4303, -7.8717],
      [110.4297, -7.8721],
      [110.4294, -7.8721],
      [110.4291, -7.8719],
      [110.429, -7.8714],
      [110.4289, -7.8712],
      [110.4284, -7.8707],
      [110.4285, -7.8697],
      [110.4286, -7.8691],
      [110.4282, -7.8684],
      [110.4281, -7.8676],
      [110.4274, -7.8669],
      [110.4267, -7.8662],
      [110.4259, -7.866],
      [110.4256, -7.8657],
      [110.4255, -7.8653],
      [110.4258, -7.8647],
      [110.4255, -7.8641],
      [110.4245, -7.8644],
      [110.4237, -7.8645],
      [110.4225, -7.8644],
      [110.4215, -7.8643],
      [110.4212, -7.8643],
      [110.4206, -7.8642],
      [110.4202, -7.8647],
      [110.4197, -7.8656],
      [110.4195, -7.8664],
      [110.4191, -7.868],
      [110.4187, -7.8687],
      [110.4176, -7.8694],
      [110.4165, -7.8697],
      [110.4147, -7.8706],
      [110.4135, -7.8713],
      [110.4127, -7.872],
      [110.4121, -7.8728],
      [110.4119, -7.8734],
      [110.412, -7.8738],
      [110.4122, -7.8741],
      [110.4123, -7.8743],
      [110.4124, -7.8746],
      [110.4122, -7.8752],
      [110.4116, -7.8756],
      [110.4114, -7.8756],
      [110.4118, -7.8758],
      [110.4126, -7.8762],
      [110.4139, -7.8764],
      [110.4151, -7.8767],
      [110.4168, -7.877],
      [110.4177, -7.8772],
      [110.4193, -7.8774],
      [110.4202, -7.8778],
      [110.4208, -7.8779],
      [110.421, -7.878],
      [110.4214, -7.8784],
      [110.4219, -7.8789],
      [110.4226, -7.8803],
      [110.4232, -7.8818],
      [110.4236, -7.8828],
      [110.424, -7.8832],
      [110.4242, -7.8834],
      [110.4247, -7.8828],
      [110.4255, -7.8827],
      [110.4259, -7.8826],
      [110.4268, -7.8821],
      [110.428, -7.8817],
      [110.4291, -7.8815],
      [110.4302, -7.8818],
      [110.4302, -7.8822],
      [110.4304, -7.8825],
      [110.4312, -7.8825],
      [110.4317, -7.8822],
      [110.4328, -7.8821],
      [110.4332, -7.8821],
      [110.4338, -7.8821],
      [110.4343, -7.8819],
      [110.4347, -7.8816],
      [110.4355, -7.8815],
      [110.4358, -7.8812],
      [110.4359, -7.8809],
      [110.4356, -7.8801],
      [110.4357, -7.8799],
      [110.4359, -7.8797],
      [110.4361, -7.8796],
      [110.4361, -7.8791],
      [110.4362, -7.8785],
      [110.4362, -7.8782],
      [110.4359, -7.8778],
      [110.4353, -7.8776],
      [110.4349, -7.8776],
      [110.4346, -7.8774],
      [110.4347, -7.877],
      [110.4352, -7.8764],
      [110.4357, -7.876],
      [110.4363, -7.8757],
      [110.4369, -7.8753],
      [110.4372, -7.8748],
      [110.4374, -7.874],
      [110.4375, -7.8738],
      [110.4381, -7.8736],
      [110.4379, -7.8728],
      [110.4377, -7.8719],
      [110.4378, -7.8713],
      [110.438, -7.8711],
      [110.4385, -7.8709],
      [110.4393, -7.8708],
      [110.4405, -7.8708],
      [110.4411, -7.8708],
      [110.4416, -7.8707],
      [110.442, -7.8701],
      [110.4425, -7.8696],
      [110.4429, -7.8693],
      [110.4436, -7.8691],
      [110.4437, -7.869],
      [110.4443, -7.8687],
      [110.4444, -7.868],
      [110.4442, -7.8676],
      [110.4442, -7.8672],
      [110.4441, -7.8668],
      [110.4439, -7.8665],
      [110.4439, -7.8659],
      [110.4436, -7.8656],
      [110.4433, -7.8654],
      [110.4431, -7.8649]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PLERET',
    village: 'WONOLELO',
    border: [
      [110.4547, -7.8729],
      [110.4538, -7.8725],
      [110.4529, -7.872],
      [110.4519, -7.8715],
      [110.4508, -7.8713],
      [110.4492, -7.8711],
      [110.4488, -7.871],
      [110.4487, -7.8705],
      [110.4482, -7.8708],
      [110.448, -7.8713],
      [110.4478, -7.8715],
      [110.4466, -7.8716],
      [110.4459, -7.8712],
      [110.4448, -7.8703],
      [110.4436, -7.8691],
      [110.4429, -7.8693],
      [110.4425, -7.8696],
      [110.442, -7.8701],
      [110.4416, -7.8707],
      [110.4411, -7.8708],
      [110.4405, -7.8708],
      [110.4393, -7.8708],
      [110.4385, -7.8709],
      [110.438, -7.8711],
      [110.4378, -7.8713],
      [110.4377, -7.8719],
      [110.4379, -7.8728],
      [110.4381, -7.8736],
      [110.4375, -7.8738],
      [110.4374, -7.874],
      [110.4372, -7.8748],
      [110.4369, -7.8753],
      [110.4363, -7.8757],
      [110.4357, -7.876],
      [110.4352, -7.8764],
      [110.4347, -7.877],
      [110.4346, -7.8774],
      [110.4349, -7.8776],
      [110.4353, -7.8776],
      [110.4359, -7.8778],
      [110.4362, -7.8782],
      [110.4362, -7.8785],
      [110.4361, -7.8791],
      [110.4361, -7.8796],
      [110.4359, -7.8797],
      [110.4357, -7.8799],
      [110.4356, -7.8801],
      [110.4359, -7.8809],
      [110.4358, -7.8812],
      [110.4355, -7.8815],
      [110.4347, -7.8816],
      [110.4343, -7.8819],
      [110.4338, -7.8821],
      [110.4332, -7.8821],
      [110.4328, -7.8821],
      [110.4317, -7.8822],
      [110.4312, -7.8825],
      [110.4304, -7.8825],
      [110.4302, -7.8822],
      [110.4302, -7.8818],
      [110.4291, -7.8815],
      [110.428, -7.8817],
      [110.4268, -7.8821],
      [110.4259, -7.8826],
      [110.4255, -7.8827],
      [110.4247, -7.8828],
      [110.4242, -7.8834],
      [110.4255, -7.8852],
      [110.4256, -7.8855],
      [110.4257, -7.8859],
      [110.4252, -7.8872],
      [110.4244, -7.8882],
      [110.4233, -7.8894],
      [110.4232, -7.8901],
      [110.4232, -7.8912],
      [110.4231, -7.8919],
      [110.4222, -7.8929],
      [110.4217, -7.8933],
      [110.4219, -7.8935],
      [110.4228, -7.8944],
      [110.4238, -7.895],
      [110.4243, -7.8951],
      [110.4252, -7.8949],
      [110.4259, -7.895],
      [110.4268, -7.8952],
      [110.4274, -7.8955],
      [110.4278, -7.8965],
      [110.4281, -7.8972],
      [110.4287, -7.8986],
      [110.4295, -7.8997],
      [110.4308, -7.9009],
      [110.4318, -7.9006],
      [110.4325, -7.9001],
      [110.4336, -7.899],
      [110.434, -7.8988],
      [110.4343, -7.8985],
      [110.4349, -7.898],
      [110.4358, -7.8971],
      [110.4361, -7.8969],
      [110.4378, -7.8953],
      [110.4379, -7.8952],
      [110.439, -7.894],
      [110.4398, -7.8927],
      [110.4401, -7.8924],
      [110.4407, -7.8918],
      [110.4414, -7.8909],
      [110.4419, -7.89],
      [110.4423, -7.8897],
      [110.4434, -7.8892],
      [110.4445, -7.8885],
      [110.4457, -7.8874],
      [110.4459, -7.8872],
      [110.4465, -7.8866],
      [110.4466, -7.8865],
      [110.4466, -7.8864],
      [110.4463, -7.8851],
      [110.4461, -7.8843],
      [110.446, -7.8836],
      [110.4463, -7.8827],
      [110.4467, -7.882],
      [110.4471, -7.8814],
      [110.4478, -7.8806],
      [110.4491, -7.8792],
      [110.45, -7.8781],
      [110.4505, -7.8775],
      [110.451, -7.8765],
      [110.4515, -7.8757],
      [110.4519, -7.8752],
      [110.4529, -7.8745],
      [110.4535, -7.8739],
      [110.4538, -7.8736],
      [110.4543, -7.8731],
      [110.4547, -7.8729]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PIYUNGAN',
    village: 'SITIMULYO',
    border: [
      [110.4526, -7.8247],
      [110.4525, -7.8247],
      [110.4511, -7.8245],
      [110.4504, -7.8244],
      [110.4501, -7.8243],
      [110.4478, -7.8241],
      [110.446, -7.824],
      [110.4439, -7.8239],
      [110.4436, -7.826],
      [110.4435, -7.8267],
      [110.4443, -7.8268],
      [110.4443, -7.8278],
      [110.4425, -7.8276],
      [110.441, -7.8275],
      [110.4409, -7.8281],
      [110.4402, -7.8281],
      [110.4401, -7.8283],
      [110.4397, -7.8282],
      [110.4396, -7.828],
      [110.4393, -7.828],
      [110.4392, -7.8288],
      [110.4389, -7.8287],
      [110.4384, -7.8286],
      [110.4383, -7.8289],
      [110.4381, -7.8291],
      [110.4377, -7.8292],
      [110.4371, -7.8293],
      [110.4364, -7.8294],
      [110.4362, -7.8303],
      [110.4363, -7.831],
      [110.4364, -7.8313],
      [110.4364, -7.8317],
      [110.4362, -7.8321],
      [110.4358, -7.8324],
      [110.4352, -7.8326],
      [110.4347, -7.8327],
      [110.4344, -7.833],
      [110.4344, -7.8331],
      [110.4346, -7.8334],
      [110.4348, -7.8335],
      [110.4348, -7.8336],
      [110.4343, -7.8342],
      [110.4342, -7.8345],
      [110.4341, -7.835],
      [110.4341, -7.8355],
      [110.4348, -7.836],
      [110.4353, -7.8362],
      [110.4354, -7.8364],
      [110.4354, -7.8366],
      [110.4352, -7.8366],
      [110.4341, -7.8366],
      [110.4336, -7.8366],
      [110.433, -7.8366],
      [110.4324, -7.8365],
      [110.4322, -7.8369],
      [110.4325, -7.837],
      [110.4324, -7.8376],
      [110.4313, -7.8374],
      [110.4308, -7.8373],
      [110.4299, -7.8373],
      [110.4299, -7.836],
      [110.4299, -7.8358],
      [110.4283, -7.8355],
      [110.4282, -7.8362],
      [110.4281, -7.837],
      [110.4278, -7.8378],
      [110.4279, -7.8381],
      [110.428, -7.8385],
      [110.4282, -7.8387],
      [110.4283, -7.839],
      [110.4286, -7.8391],
      [110.4285, -7.8393],
      [110.4281, -7.8393],
      [110.4274, -7.8394],
      [110.427, -7.8394],
      [110.4268, -7.8395],
      [110.4267, -7.84],
      [110.4267, -7.8402],
      [110.4268, -7.8409],
      [110.427, -7.8416],
      [110.4272, -7.8422],
      [110.4278, -7.8428],
      [110.4282, -7.8431],
      [110.4289, -7.8431],
      [110.4292, -7.8432],
      [110.429, -7.8437],
      [110.4288, -7.8439],
      [110.4286, -7.8442],
      [110.4285, -7.8444],
      [110.4288, -7.8449],
      [110.4287, -7.8451],
      [110.4282, -7.8452],
      [110.4279, -7.8456],
      [110.4277, -7.8459],
      [110.4277, -7.8462],
      [110.4274, -7.8471],
      [110.4274, -7.8476],
      [110.4276, -7.8483],
      [110.4281, -7.849],
      [110.429, -7.8501],
      [110.4294, -7.8508],
      [110.4295, -7.8513],
      [110.4296, -7.8517],
      [110.4296, -7.8519],
      [110.4296, -7.8521],
      [110.429, -7.8525],
      [110.4284, -7.8531],
      [110.4277, -7.8537],
      [110.4272, -7.8543],
      [110.427, -7.8549],
      [110.4268, -7.8557],
      [110.4264, -7.8563],
      [110.4258, -7.857],
      [110.4249, -7.8579],
      [110.4239, -7.8589],
      [110.4231, -7.8594],
      [110.423, -7.8597],
      [110.4227, -7.86],
      [110.4218, -7.8606],
      [110.4212, -7.8611],
      [110.4211, -7.8612],
      [110.4211, -7.8613],
      [110.421, -7.8619],
      [110.4208, -7.8628],
      [110.4209, -7.863],
      [110.4207, -7.8635],
      [110.4206, -7.8642],
      [110.4212, -7.8643],
      [110.4215, -7.8643],
      [110.4225, -7.8644],
      [110.4237, -7.8645],
      [110.4245, -7.8644],
      [110.4255, -7.8641],
      [110.4258, -7.8647],
      [110.4255, -7.8653],
      [110.4256, -7.8657],
      [110.4259, -7.866],
      [110.4267, -7.8662],
      [110.4274, -7.8669],
      [110.4281, -7.8676],
      [110.4282, -7.8684],
      [110.4286, -7.8691],
      [110.4285, -7.8697],
      [110.4284, -7.8707],
      [110.4289, -7.8712],
      [110.429, -7.8714],
      [110.4291, -7.8719],
      [110.4294, -7.8721],
      [110.4297, -7.8721],
      [110.4303, -7.8717],
      [110.4305, -7.8714],
      [110.4307, -7.8713],
      [110.431, -7.8711],
      [110.4313, -7.8713],
      [110.4316, -7.8713],
      [110.4317, -7.8709],
      [110.4318, -7.8704],
      [110.4318, -7.87],
      [110.432, -7.8696],
      [110.4323, -7.8693],
      [110.4328, -7.8695],
      [110.4333, -7.8694],
      [110.4336, -7.869],
      [110.4339, -7.8684],
      [110.4341, -7.8679],
      [110.4342, -7.8673],
      [110.4344, -7.8672],
      [110.4347, -7.8672],
      [110.4351, -7.8674],
      [110.4356, -7.8674],
      [110.4363, -7.8674],
      [110.4368, -7.8675],
      [110.4371, -7.8674],
      [110.4374, -7.8671],
      [110.4375, -7.8668],
      [110.4379, -7.8664],
      [110.4383, -7.8664],
      [110.439, -7.8661],
      [110.4393, -7.866],
      [110.4399, -7.8653],
      [110.4403, -7.8653],
      [110.4414, -7.8653],
      [110.4417, -7.8651],
      [110.4426, -7.8651],
      [110.4431, -7.8649],
      [110.4431, -7.8644],
      [110.4434, -7.864],
      [110.4439, -7.8636],
      [110.4445, -7.8633],
      [110.4449, -7.8631],
      [110.4453, -7.8629],
      [110.4456, -7.8624],
      [110.4457, -7.8619],
      [110.4459, -7.8616],
      [110.4465, -7.8614],
      [110.4468, -7.8613],
      [110.4474, -7.8617],
      [110.4479, -7.8609],
      [110.4477, -7.8606],
      [110.4476, -7.8602],
      [110.4477, -7.8599],
      [110.4479, -7.8596],
      [110.4483, -7.8599],
      [110.4487, -7.8591],
      [110.4486, -7.8588],
      [110.4486, -7.8582],
      [110.4486, -7.8577],
      [110.4492, -7.8564],
      [110.4498, -7.8547],
      [110.4501, -7.854],
      [110.4509, -7.8527],
      [110.451, -7.8522],
      [110.451, -7.8517],
      [110.4509, -7.8512],
      [110.4504, -7.8508],
      [110.4498, -7.8505],
      [110.4494, -7.8502],
      [110.4488, -7.8502],
      [110.4484, -7.85],
      [110.4481, -7.8496],
      [110.4481, -7.8492],
      [110.4478, -7.8487],
      [110.4476, -7.8483],
      [110.4465, -7.8479],
      [110.4461, -7.8475],
      [110.4455, -7.8474],
      [110.4447, -7.8476],
      [110.4442, -7.8476],
      [110.4437, -7.8475],
      [110.4432, -7.8477],
      [110.4429, -7.8476],
      [110.4424, -7.8472],
      [110.4423, -7.8469],
      [110.4423, -7.8468],
      [110.4431, -7.8453],
      [110.4443, -7.8434],
      [110.4449, -7.8424],
      [110.4454, -7.8418],
      [110.4456, -7.8416],
      [110.446, -7.8415],
      [110.4475, -7.8416],
      [110.4481, -7.8416],
      [110.4485, -7.8415],
      [110.4491, -7.841],
      [110.4495, -7.8405],
      [110.4498, -7.8399],
      [110.4509, -7.8388],
      [110.4513, -7.8384],
      [110.4514, -7.8381],
      [110.4512, -7.8373],
      [110.4512, -7.8368],
      [110.4512, -7.8362],
      [110.4515, -7.8354],
      [110.4519, -7.8346],
      [110.4517, -7.8339],
      [110.4516, -7.8329],
      [110.4517, -7.8324],
      [110.452, -7.8314],
      [110.4522, -7.8306],
      [110.4523, -7.8293],
      [110.4525, -7.8281],
      [110.4526, -7.8259],
      [110.4526, -7.8247]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PIYUNGAN',
    village: 'SRIMULYO',
    border: [
      [110.4695, -7.8286],
      [110.4692, -7.8285],
      [110.4683, -7.8283],
      [110.4677, -7.8281],
      [110.4669, -7.828],
      [110.4661, -7.8277],
      [110.4661, -7.8274],
      [110.4663, -7.826],
      [110.4665, -7.8249],
      [110.4661, -7.8248],
      [110.4658, -7.8248],
      [110.4656, -7.8246],
      [110.4655, -7.8237],
      [110.4653, -7.8225],
      [110.4651, -7.8221],
      [110.4646, -7.822],
      [110.464, -7.8219],
      [110.4633, -7.8216],
      [110.4627, -7.8214],
      [110.462, -7.8212],
      [110.4619, -7.8215],
      [110.4619, -7.8218],
      [110.462, -7.8225],
      [110.462, -7.8227],
      [110.4615, -7.8228],
      [110.4612, -7.8227],
      [110.4607, -7.8214],
      [110.4602, -7.8202],
      [110.46, -7.8198],
      [110.4588, -7.8202],
      [110.4579, -7.8205],
      [110.4576, -7.8206],
      [110.4572, -7.8207],
      [110.4566, -7.8211],
      [110.4561, -7.8214],
      [110.4547, -7.821],
      [110.4537, -7.8206],
      [110.4534, -7.8209],
      [110.4532, -7.8212],
      [110.453, -7.8217],
      [110.4528, -7.8224],
      [110.4527, -7.8233],
      [110.4526, -7.8247],
      [110.4526, -7.8259],
      [110.4525, -7.8281],
      [110.4523, -7.8293],
      [110.4522, -7.8306],
      [110.452, -7.8314],
      [110.4517, -7.8324],
      [110.4516, -7.8329],
      [110.4517, -7.8339],
      [110.4519, -7.8346],
      [110.4515, -7.8354],
      [110.4512, -7.8362],
      [110.4512, -7.8368],
      [110.4512, -7.8373],
      [110.4514, -7.8381],
      [110.4513, -7.8384],
      [110.4509, -7.8388],
      [110.4498, -7.8399],
      [110.4495, -7.8405],
      [110.4491, -7.841],
      [110.4485, -7.8415],
      [110.4481, -7.8416],
      [110.4475, -7.8416],
      [110.446, -7.8415],
      [110.4456, -7.8416],
      [110.4454, -7.8418],
      [110.4449, -7.8424],
      [110.4443, -7.8434],
      [110.4431, -7.8453],
      [110.4423, -7.8468],
      [110.4423, -7.8469],
      [110.4424, -7.8472],
      [110.4429, -7.8476],
      [110.4432, -7.8477],
      [110.4437, -7.8475],
      [110.4442, -7.8476],
      [110.4447, -7.8476],
      [110.4455, -7.8474],
      [110.4461, -7.8475],
      [110.4465, -7.8479],
      [110.4476, -7.8483],
      [110.4478, -7.8487],
      [110.4481, -7.8492],
      [110.4481, -7.8496],
      [110.4484, -7.85],
      [110.4488, -7.8502],
      [110.4494, -7.8502],
      [110.4498, -7.8505],
      [110.4504, -7.8508],
      [110.4509, -7.8512],
      [110.451, -7.8517],
      [110.451, -7.8522],
      [110.4509, -7.8527],
      [110.4501, -7.854],
      [110.4498, -7.8547],
      [110.4492, -7.8564],
      [110.4486, -7.8577],
      [110.4486, -7.8582],
      [110.4486, -7.8588],
      [110.4487, -7.8591],
      [110.4483, -7.8599],
      [110.4479, -7.8596],
      [110.4477, -7.8599],
      [110.4476, -7.8602],
      [110.4477, -7.8606],
      [110.4479, -7.8609],
      [110.4474, -7.8617],
      [110.4468, -7.8613],
      [110.4465, -7.8614],
      [110.4459, -7.8616],
      [110.4457, -7.8619],
      [110.4456, -7.8624],
      [110.4453, -7.8629],
      [110.4449, -7.8631],
      [110.4445, -7.8633],
      [110.4439, -7.8636],
      [110.4434, -7.864],
      [110.4431, -7.8644],
      [110.4431, -7.8649],
      [110.4433, -7.8654],
      [110.4436, -7.8656],
      [110.4439, -7.8659],
      [110.4439, -7.8665],
      [110.4441, -7.8668],
      [110.4442, -7.8672],
      [110.4442, -7.8676],
      [110.4444, -7.868],
      [110.4443, -7.8687],
      [110.4437, -7.869],
      [110.4436, -7.8691],
      [110.4448, -7.8703],
      [110.4459, -7.8712],
      [110.4466, -7.8716],
      [110.4478, -7.8715],
      [110.448, -7.8713],
      [110.4482, -7.8708],
      [110.4487, -7.8705],
      [110.4488, -7.871],
      [110.4492, -7.8711],
      [110.4508, -7.8713],
      [110.4519, -7.8715],
      [110.4529, -7.872],
      [110.4538, -7.8725],
      [110.4547, -7.8729],
      [110.4549, -7.8727],
      [110.456, -7.8724],
      [110.457, -7.8718],
      [110.4581, -7.871],
      [110.4589, -7.8704],
      [110.4595, -7.8698],
      [110.4602, -7.8693],
      [110.4605, -7.8694],
      [110.4608, -7.8698],
      [110.4609, -7.8699],
      [110.4613, -7.8694],
      [110.4614, -7.8687],
      [110.4615, -7.8684],
      [110.4617, -7.8679],
      [110.4623, -7.8679],
      [110.4628, -7.8679],
      [110.4633, -7.8676],
      [110.4639, -7.8669],
      [110.4641, -7.8668],
      [110.4646, -7.867],
      [110.4646, -7.8675],
      [110.4646, -7.8676],
      [110.4654, -7.8677],
      [110.4653, -7.8684],
      [110.4655, -7.8687],
      [110.4658, -7.8686],
      [110.4658, -7.8684],
      [110.4661, -7.8683],
      [110.4661, -7.868],
      [110.4662, -7.8674],
      [110.4668, -7.8673],
      [110.4669, -7.8673],
      [110.4669, -7.8669],
      [110.4673, -7.8668],
      [110.4676, -7.8669],
      [110.4676, -7.8668],
      [110.4677, -7.8666],
      [110.468, -7.866],
      [110.4685, -7.8656],
      [110.4689, -7.8649],
      [110.4694, -7.864],
      [110.4699, -7.8631],
      [110.4703, -7.8625],
      [110.4706, -7.8621],
      [110.4712, -7.8617],
      [110.4715, -7.8616],
      [110.4722, -7.8615],
      [110.4729, -7.8613],
      [110.473, -7.8611],
      [110.473, -7.8593],
      [110.473, -7.8579],
      [110.4731, -7.8574],
      [110.4733, -7.8561],
      [110.4735, -7.8555],
      [110.4736, -7.8548],
      [110.4739, -7.8543],
      [110.4743, -7.8539],
      [110.4747, -7.8533],
      [110.4752, -7.8526],
      [110.4753, -7.8521],
      [110.4755, -7.8516],
      [110.4767, -7.8507],
      [110.4774, -7.8503],
      [110.4775, -7.8497],
      [110.4778, -7.8491],
      [110.4782, -7.8487],
      [110.4787, -7.8478],
      [110.4791, -7.8473],
      [110.4795, -7.8465],
      [110.48, -7.8457],
      [110.4802, -7.8454],
      [110.4803, -7.845],
      [110.4802, -7.8448],
      [110.4799, -7.8446],
      [110.4799, -7.8441],
      [110.4801, -7.8439],
      [110.4807, -7.8435],
      [110.481, -7.843],
      [110.4816, -7.8422],
      [110.4817, -7.8419],
      [110.4816, -7.8414],
      [110.4814, -7.841],
      [110.4809, -7.8407],
      [110.481, -7.8404],
      [110.4807, -7.8404],
      [110.48, -7.8406],
      [110.4793, -7.8405],
      [110.4791, -7.8403],
      [110.479, -7.8401],
      [110.4787, -7.8401],
      [110.4784, -7.84],
      [110.4783, -7.8399],
      [110.4778, -7.8407],
      [110.4773, -7.8417],
      [110.4765, -7.8411],
      [110.476, -7.8406],
      [110.476, -7.8398],
      [110.4761, -7.8395],
      [110.476, -7.8388],
      [110.4759, -7.8379],
      [110.4765, -7.8366],
      [110.4733, -7.8357],
      [110.4723, -7.8355],
      [110.4725, -7.8341],
      [110.4708, -7.8336],
      [110.4707, -7.8327],
      [110.4706, -7.8325],
      [110.4701, -7.832],
      [110.4701, -7.8311],
      [110.4693, -7.8309],
      [110.4691, -7.8308],
      [110.4694, -7.8292],
      [110.4695, -7.8286]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'PIYUNGAN',
    village: 'SRIMARTANI',
    border: [
      [110.4854, -7.8185],
      [110.4847, -7.8183],
      [110.4827, -7.8179],
      [110.4814, -7.8175],
      [110.481, -7.819],
      [110.4804, -7.8215],
      [110.4798, -7.8235],
      [110.4794, -7.8249],
      [110.4775, -7.8242],
      [110.4771, -7.8241],
      [110.4769, -7.8254],
      [110.4756, -7.8251],
      [110.4759, -7.8238],
      [110.4749, -7.8234],
      [110.474, -7.8234],
      [110.4732, -7.8232],
      [110.4731, -7.8238],
      [110.4727, -7.8237],
      [110.4723, -7.8238],
      [110.4715, -7.8242],
      [110.4711, -7.8244],
      [110.4709, -7.825],
      [110.4709, -7.8253],
      [110.4702, -7.8258],
      [110.47, -7.8259],
      [110.4698, -7.826],
      [110.4694, -7.8264],
      [110.4689, -7.8269],
      [110.4686, -7.827],
      [110.4684, -7.8271],
      [110.4684, -7.8273],
      [110.4688, -7.8273],
      [110.4692, -7.8273],
      [110.4696, -7.8272],
      [110.4698, -7.8271],
      [110.4699, -7.8271],
      [110.4699, -7.8274],
      [110.4698, -7.8282],
      [110.4698, -7.8286],
      [110.4696, -7.8286],
      [110.4695, -7.8286],
      [110.4694, -7.8292],
      [110.4691, -7.8308],
      [110.4693, -7.8309],
      [110.4701, -7.8311],
      [110.4701, -7.832],
      [110.4706, -7.8325],
      [110.4707, -7.8327],
      [110.4708, -7.8336],
      [110.4725, -7.8341],
      [110.4723, -7.8355],
      [110.4733, -7.8357],
      [110.4765, -7.8366],
      [110.4759, -7.8379],
      [110.476, -7.8388],
      [110.4761, -7.8395],
      [110.476, -7.8398],
      [110.476, -7.8406],
      [110.4765, -7.8411],
      [110.4773, -7.8417],
      [110.4778, -7.8407],
      [110.4783, -7.8399],
      [110.4784, -7.84],
      [110.4787, -7.8401],
      [110.479, -7.8401],
      [110.4791, -7.8403],
      [110.4793, -7.8405],
      [110.48, -7.8406],
      [110.4807, -7.8404],
      [110.481, -7.8404],
      [110.4809, -7.8407],
      [110.4814, -7.841],
      [110.4816, -7.8414],
      [110.4817, -7.8419],
      [110.4816, -7.8422],
      [110.481, -7.843],
      [110.4807, -7.8435],
      [110.4801, -7.8439],
      [110.4799, -7.8441],
      [110.4799, -7.8446],
      [110.4802, -7.8448],
      [110.4803, -7.845],
      [110.4804, -7.8448],
      [110.4806, -7.8444],
      [110.4813, -7.844],
      [110.4822, -7.8434],
      [110.4831, -7.843],
      [110.4841, -7.8425],
      [110.4847, -7.8422],
      [110.4854, -7.8419],
      [110.4859, -7.8416],
      [110.4863, -7.8414],
      [110.4869, -7.841],
      [110.4874, -7.8404],
      [110.4877, -7.8398],
      [110.4878, -7.8395],
      [110.4879, -7.8392],
      [110.4881, -7.8383],
      [110.4883, -7.838],
      [110.4886, -7.8377],
      [110.4889, -7.8377],
      [110.4903, -7.8383],
      [110.4915, -7.8386],
      [110.4918, -7.8387],
      [110.4929, -7.8388],
      [110.4935, -7.8392],
      [110.4942, -7.8394],
      [110.4947, -7.8394],
      [110.4954, -7.839],
      [110.4961, -7.8385],
      [110.4964, -7.8382],
      [110.4972, -7.838],
      [110.4982, -7.838],
      [110.4989, -7.838],
      [110.5004, -7.8384],
      [110.5016, -7.8385],
      [110.5025, -7.8386],
      [110.5028, -7.8387],
      [110.5036, -7.8388],
      [110.5046, -7.8386],
      [110.5057, -7.8389],
      [110.507, -7.8391],
      [110.5074, -7.8392],
      [110.5079, -7.8389],
      [110.5088, -7.8384],
      [110.5097, -7.8378],
      [110.5113, -7.8376],
      [110.5123, -7.8374],
      [110.5131, -7.8374],
      [110.5141, -7.8374],
      [110.515, -7.8375],
      [110.5157, -7.8374],
      [110.5166, -7.837],
      [110.5177, -7.8365],
      [110.5188, -7.8361],
      [110.5196, -7.8358],
      [110.52, -7.8354],
      [110.5208, -7.8345],
      [110.5213, -7.8336],
      [110.5213, -7.833],
      [110.5213, -7.8329],
      [110.5212, -7.8322],
      [110.5208, -7.832],
      [110.5207, -7.8319],
      [110.5201, -7.8317],
      [110.5199, -7.8315],
      [110.5196, -7.831],
      [110.5195, -7.8309],
      [110.5194, -7.8309],
      [110.519, -7.831],
      [110.5186, -7.8309],
      [110.5184, -7.8308],
      [110.5181, -7.8306],
      [110.518, -7.8306],
      [110.5177, -7.8305],
      [110.5174, -7.8304],
      [110.5166, -7.8303],
      [110.516, -7.8304],
      [110.5154, -7.8303],
      [110.515, -7.8302],
      [110.5149, -7.8299],
      [110.515, -7.8294],
      [110.5148, -7.8291],
      [110.5148, -7.8286],
      [110.5147, -7.8282],
      [110.5144, -7.8281],
      [110.514, -7.8283],
      [110.5133, -7.8287],
      [110.5128, -7.829],
      [110.5123, -7.8291],
      [110.5117, -7.8291],
      [110.5112, -7.8289],
      [110.5111, -7.8284],
      [110.5111, -7.8278],
      [110.511, -7.8277],
      [110.5109, -7.8275],
      [110.511, -7.8272],
      [110.511, -7.8268],
      [110.5109, -7.8264],
      [110.5107, -7.8263],
      [110.5105, -7.8265],
      [110.5101, -7.8265],
      [110.51, -7.8262],
      [110.5101, -7.8258],
      [110.5104, -7.8256],
      [110.5105, -7.8254],
      [110.5106, -7.8252],
      [110.5105, -7.8242],
      [110.5103, -7.8235],
      [110.5102, -7.8232],
      [110.5099, -7.8227],
      [110.5097, -7.8224],
      [110.5093, -7.8221],
      [110.5082, -7.8218],
      [110.5074, -7.8216],
      [110.5069, -7.8216],
      [110.5068, -7.8218],
      [110.5068, -7.8222],
      [110.5069, -7.8224],
      [110.5072, -7.8226],
      [110.5073, -7.8228],
      [110.5071, -7.8231],
      [110.5066, -7.8231],
      [110.5059, -7.8233],
      [110.5053, -7.8236],
      [110.5047, -7.8235],
      [110.5046, -7.8235],
      [110.5042, -7.8234],
      [110.5027, -7.8228],
      [110.5018, -7.8225],
      [110.5015, -7.8224],
      [110.5012, -7.8224],
      [110.5009, -7.8225],
      [110.5005, -7.8227],
      [110.4999, -7.8229],
      [110.4993, -7.8229],
      [110.4986, -7.8228],
      [110.4978, -7.8228],
      [110.4973, -7.8229],
      [110.4967, -7.8229],
      [110.4961, -7.8229],
      [110.4952, -7.8229],
      [110.4939, -7.8229],
      [110.4934, -7.8229],
      [110.4927, -7.8229],
      [110.4923, -7.8229],
      [110.4911, -7.8225],
      [110.4903, -7.8223],
      [110.488, -7.8216],
      [110.487, -7.8213],
      [110.4865, -7.8213],
      [110.4854, -7.821],
      [110.4851, -7.8214],
      [110.4845, -7.8213],
      [110.4843, -7.8211],
      [110.4846, -7.8205],
      [110.4851, -7.8195],
      [110.4853, -7.819],
      [110.4854, -7.8185]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEWON',
    village: 'PENDOWOHARJO',
    border: [
      [110.3337, -7.8473],
      [110.3332, -7.8472],
      [110.332, -7.8468],
      [110.3316, -7.8469],
      [110.3313, -7.8472],
      [110.3313, -7.8478],
      [110.3317, -7.8487],
      [110.332, -7.8495],
      [110.3319, -7.8499],
      [110.3316, -7.8501],
      [110.3311, -7.8502],
      [110.3302, -7.8505],
      [110.3294, -7.8507],
      [110.3293, -7.8506],
      [110.3293, -7.8504],
      [110.3293, -7.8501],
      [110.3295, -7.8494],
      [110.3295, -7.8487],
      [110.3292, -7.8483],
      [110.3289, -7.8484],
      [110.3282, -7.8484],
      [110.3275, -7.8484],
      [110.3264, -7.8486],
      [110.3253, -7.8486],
      [110.3251, -7.8488],
      [110.3246, -7.8484],
      [110.3241, -7.8478],
      [110.3239, -7.8472],
      [110.3238, -7.8464],
      [110.3235, -7.8456],
      [110.3232, -7.8454],
      [110.3227, -7.8451],
      [110.3223, -7.8447],
      [110.3215, -7.8444],
      [110.3207, -7.8443],
      [110.3202, -7.8445],
      [110.3196, -7.8445],
      [110.3194, -7.8447],
      [110.319, -7.8447],
      [110.3181, -7.8447],
      [110.3179, -7.8453],
      [110.3176, -7.8465],
      [110.3213, -7.8468],
      [110.3213, -7.8475],
      [110.3213, -7.849],
      [110.3213, -7.8499],
      [110.3217, -7.85],
      [110.3219, -7.85],
      [110.322, -7.85],
      [110.3229, -7.8501],
      [110.3235, -7.8503],
      [110.3245, -7.8503],
      [110.3249, -7.8502],
      [110.3256, -7.8494],
      [110.3265, -7.8497],
      [110.3262, -7.8503],
      [110.3266, -7.8503],
      [110.3267, -7.8507],
      [110.3266, -7.851],
      [110.327, -7.8514],
      [110.3275, -7.8522],
      [110.3279, -7.8527],
      [110.3281, -7.8531],
      [110.3285, -7.8532],
      [110.3286, -7.8535],
      [110.3287, -7.8539],
      [110.3287, -7.8545],
      [110.3289, -7.8557],
      [110.3289, -7.8563],
      [110.3287, -7.8572],
      [110.3284, -7.8582],
      [110.3282, -7.859],
      [110.3278, -7.8593],
      [110.3271, -7.8593],
      [110.3265, -7.8593],
      [110.326, -7.8593],
      [110.3256, -7.8597],
      [110.3254, -7.8599],
      [110.325, -7.8602],
      [110.3248, -7.8604],
      [110.3246, -7.861],
      [110.3242, -7.8614],
      [110.3244, -7.8616],
      [110.325, -7.8622],
      [110.3257, -7.8629],
      [110.3261, -7.8631],
      [110.3268, -7.8634],
      [110.3274, -7.8636],
      [110.3275, -7.8637],
      [110.3278, -7.8644],
      [110.3281, -7.865],
      [110.3284, -7.8655],
      [110.3288, -7.8657],
      [110.3294, -7.8659],
      [110.3295, -7.8659],
      [110.3298, -7.8661],
      [110.3295, -7.8669],
      [110.3292, -7.8676],
      [110.3289, -7.8686],
      [110.3288, -7.869],
      [110.3289, -7.8692],
      [110.3293, -7.8693],
      [110.3295, -7.869],
      [110.3309, -7.8693],
      [110.3307, -7.8702],
      [110.3305, -7.8704],
      [110.3306, -7.8707],
      [110.3304, -7.871],
      [110.3308, -7.8716],
      [110.3312, -7.8723],
      [110.3315, -7.8725],
      [110.332, -7.8727],
      [110.3323, -7.873],
      [110.3327, -7.8735],
      [110.3334, -7.8744],
      [110.3337, -7.8754],
      [110.3338, -7.8763],
      [110.335, -7.8766],
      [110.3359, -7.8768],
      [110.3366, -7.8771],
      [110.3365, -7.8773],
      [110.3363, -7.8781],
      [110.3362, -7.8789],
      [110.3363, -7.879],
      [110.3366, -7.8795],
      [110.3368, -7.8794],
      [110.3373, -7.8793],
      [110.3374, -7.8793],
      [110.3375, -7.8794],
      [110.3385, -7.8796],
      [110.3389, -7.8796],
      [110.3392, -7.8808],
      [110.3403, -7.8811],
      [110.3404, -7.8812],
      [110.3424, -7.8816],
      [110.3429, -7.8799],
      [110.3431, -7.88],
      [110.3443, -7.8802],
      [110.3447, -7.8802],
      [110.3448, -7.8802],
      [110.345, -7.8802],
      [110.3455, -7.8797],
      [110.3462, -7.8793],
      [110.3464, -7.8793],
      [110.3462, -7.8787],
      [110.3461, -7.8784],
      [110.3457, -7.8782],
      [110.3453, -7.8779],
      [110.345, -7.8777],
      [110.3452, -7.8774],
      [110.3454, -7.877],
      [110.3452, -7.8769],
      [110.3453, -7.8759],
      [110.3455, -7.8748],
      [110.3456, -7.8744],
      [110.3459, -7.8743],
      [110.3461, -7.8741],
      [110.3466, -7.8742],
      [110.3465, -7.8739],
      [110.3462, -7.8736],
      [110.3459, -7.8717],
      [110.3458, -7.8708],
      [110.346, -7.8697],
      [110.3461, -7.8691],
      [110.3463, -7.8685],
      [110.3464, -7.8674],
      [110.3476, -7.8676],
      [110.3477, -7.8668],
      [110.3477, -7.8667],
      [110.3476, -7.8663],
      [110.3475, -7.8659],
      [110.3474, -7.8655],
      [110.3477, -7.8652],
      [110.348, -7.8647],
      [110.3481, -7.8643],
      [110.348, -7.8641],
      [110.3478, -7.8638],
      [110.3475, -7.8632],
      [110.3473, -7.8628],
      [110.3469, -7.8623],
      [110.3468, -7.8621],
      [110.3466, -7.8616],
      [110.3467, -7.8613],
      [110.3472, -7.8611],
      [110.3477, -7.861],
      [110.3478, -7.8608],
      [110.3479, -7.86],
      [110.3479, -7.8599],
      [110.3481, -7.859],
      [110.3481, -7.8581],
      [110.3485, -7.8574],
      [110.3484, -7.8572],
      [110.3484, -7.857],
      [110.3483, -7.8568],
      [110.3482, -7.8561],
      [110.3482, -7.8553],
      [110.3485, -7.8544],
      [110.3483, -7.854],
      [110.3482, -7.8539],
      [110.3479, -7.8534],
      [110.3479, -7.8525],
      [110.3479, -7.8518],
      [110.3476, -7.8513],
      [110.3475, -7.8508],
      [110.3476, -7.8504],
      [110.348, -7.8501],
      [110.3479, -7.8495],
      [110.3477, -7.8491],
      [110.3476, -7.8489],
      [110.3471, -7.8486],
      [110.3467, -7.8486],
      [110.3466, -7.8494],
      [110.3462, -7.8494],
      [110.3457, -7.8492],
      [110.3454, -7.8493],
      [110.3454, -7.8497],
      [110.3452, -7.8499],
      [110.345, -7.8502],
      [110.3446, -7.8497],
      [110.3439, -7.8494],
      [110.3436, -7.8501],
      [110.3435, -7.8507],
      [110.3427, -7.8505],
      [110.3428, -7.8501],
      [110.3418, -7.85],
      [110.3406, -7.8497],
      [110.3399, -7.8495],
      [110.3392, -7.8499],
      [110.3388, -7.8504],
      [110.3385, -7.8514],
      [110.3381, -7.8529],
      [110.338, -7.8537],
      [110.3381, -7.8554],
      [110.3379, -7.856],
      [110.3351, -7.8553],
      [110.3353, -7.854],
      [110.3357, -7.854],
      [110.3359, -7.8537],
      [110.335, -7.8532],
      [110.3352, -7.8526],
      [110.3357, -7.8525],
      [110.3357, -7.851],
      [110.335, -7.8509],
      [110.3341, -7.8506],
      [110.3331, -7.8505],
      [110.3329, -7.8502],
      [110.3327, -7.8499],
      [110.3328, -7.8495],
      [110.3329, -7.849],
      [110.3331, -7.8485],
      [110.3333, -7.8485],
      [110.3335, -7.8482],
      [110.3337, -7.8473]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEWON',
    village: 'TIMBULHARJO',
    border: [
      [110.3762, -7.8593],
      [110.3761, -7.8593],
      [110.3748, -7.8592],
      [110.3746, -7.8591],
      [110.3742, -7.859],
      [110.3741, -7.8583],
      [110.3726, -7.8584],
      [110.3724, -7.8595],
      [110.3722, -7.8615],
      [110.3716, -7.8624],
      [110.3711, -7.863],
      [110.3705, -7.8631],
      [110.3693, -7.8629],
      [110.368, -7.8627],
      [110.3678, -7.8631],
      [110.3671, -7.8631],
      [110.3668, -7.8633],
      [110.3667, -7.8633],
      [110.3665, -7.8634],
      [110.3665, -7.8639],
      [110.3664, -7.8648],
      [110.3663, -7.8651],
      [110.3662, -7.8657],
      [110.3656, -7.867],
      [110.3653, -7.867],
      [110.3649, -7.8671],
      [110.3643, -7.8673],
      [110.3637, -7.8672],
      [110.3636, -7.8671],
      [110.3637, -7.8667],
      [110.3639, -7.8664],
      [110.3634, -7.865],
      [110.3613, -7.865],
      [110.3615, -7.8642],
      [110.3616, -7.8636],
      [110.3605, -7.8635],
      [110.3598, -7.8631],
      [110.3592, -7.8628],
      [110.3583, -7.8628],
      [110.3582, -7.8628],
      [110.3555, -7.8623],
      [110.3533, -7.862],
      [110.3532, -7.8617],
      [110.352, -7.8616],
      [110.3519, -7.8606],
      [110.3512, -7.8605],
      [110.3498, -7.8603],
      [110.349, -7.8603],
      [110.3483, -7.86],
      [110.3479, -7.86],
      [110.3478, -7.8608],
      [110.3477, -7.861],
      [110.3472, -7.8611],
      [110.3467, -7.8613],
      [110.3466, -7.8616],
      [110.3468, -7.8621],
      [110.3469, -7.8623],
      [110.3473, -7.8628],
      [110.3475, -7.8632],
      [110.3478, -7.8638],
      [110.348, -7.8641],
      [110.3481, -7.8643],
      [110.348, -7.8647],
      [110.3477, -7.8652],
      [110.3474, -7.8655],
      [110.3475, -7.8659],
      [110.3476, -7.8663],
      [110.3477, -7.8667],
      [110.3477, -7.8668],
      [110.3476, -7.8676],
      [110.3464, -7.8674],
      [110.3463, -7.8685],
      [110.3461, -7.8691],
      [110.346, -7.8697],
      [110.3458, -7.8708],
      [110.3459, -7.8717],
      [110.3462, -7.8736],
      [110.3465, -7.8739],
      [110.3466, -7.8742],
      [110.3461, -7.8741],
      [110.3459, -7.8743],
      [110.3456, -7.8744],
      [110.3455, -7.8748],
      [110.3453, -7.8759],
      [110.3452, -7.8769],
      [110.3454, -7.877],
      [110.3452, -7.8774],
      [110.345, -7.8777],
      [110.3453, -7.8779],
      [110.3457, -7.8782],
      [110.3461, -7.8784],
      [110.3462, -7.8787],
      [110.3464, -7.8793],
      [110.3466, -7.8793],
      [110.3467, -7.8793],
      [110.3471, -7.8795],
      [110.3472, -7.8795],
      [110.3479, -7.8798],
      [110.348, -7.8799],
      [110.3482, -7.8801],
      [110.3481, -7.8814],
      [110.3487, -7.8821],
      [110.3487, -7.8823],
      [110.3484, -7.8829],
      [110.3481, -7.8833],
      [110.3478, -7.8834],
      [110.3481, -7.8845],
      [110.3483, -7.8849],
      [110.3487, -7.885],
      [110.349, -7.885],
      [110.3495, -7.8854],
      [110.3497, -7.8858],
      [110.3502, -7.8875],
      [110.3509, -7.8874],
      [110.351, -7.8881],
      [110.3515, -7.8881],
      [110.353, -7.8881],
      [110.3533, -7.888],
      [110.3533, -7.8879],
      [110.3533, -7.8877],
      [110.3532, -7.8876],
      [110.3535, -7.8872],
      [110.3536, -7.8864],
      [110.3536, -7.8855],
      [110.3545, -7.8856],
      [110.3552, -7.8856],
      [110.3553, -7.8856],
      [110.3554, -7.8851],
      [110.3552, -7.8851],
      [110.3551, -7.885],
      [110.355, -7.885],
      [110.3548, -7.8849],
      [110.3549, -7.8845],
      [110.3552, -7.8841],
      [110.3555, -7.8833],
      [110.3554, -7.883],
      [110.3555, -7.8825],
      [110.3555, -7.8821],
      [110.3551, -7.8818],
      [110.3562, -7.882],
      [110.3567, -7.8822],
      [110.3567, -7.8828],
      [110.3565, -7.8833],
      [110.3563, -7.884],
      [110.3563, -7.8848],
      [110.3563, -7.8861],
      [110.3566, -7.8862],
      [110.3569, -7.8865],
      [110.3579, -7.8867],
      [110.3581, -7.8859],
      [110.3595, -7.886],
      [110.3595, -7.8835],
      [110.3595, -7.8832],
      [110.3595, -7.8823],
      [110.3596, -7.8823],
      [110.3609, -7.8823],
      [110.361, -7.8823],
      [110.3612, -7.8815],
      [110.3611, -7.8827],
      [110.3611, -7.8832],
      [110.361, -7.8837],
      [110.3614, -7.8845],
      [110.3619, -7.8853],
      [110.3626, -7.8861],
      [110.3629, -7.8862],
      [110.3636, -7.8872],
      [110.3643, -7.8875],
      [110.3647, -7.8875],
      [110.3648, -7.8869],
      [110.3654, -7.8865],
      [110.3657, -7.8863],
      [110.3659, -7.8861],
      [110.3659, -7.8858],
      [110.3659, -7.8857],
      [110.3653, -7.8856],
      [110.3652, -7.8855],
      [110.3653, -7.885],
      [110.3655, -7.8847],
      [110.3657, -7.8843],
      [110.3658, -7.8839],
      [110.3655, -7.8835],
      [110.3655, -7.8831],
      [110.3657, -7.8826],
      [110.366, -7.8819],
      [110.3661, -7.8817],
      [110.3663, -7.8812],
      [110.3678, -7.8814],
      [110.3694, -7.8818],
      [110.3696, -7.8819],
      [110.3704, -7.8819],
      [110.3711, -7.8821],
      [110.3715, -7.8821],
      [110.3723, -7.8822],
      [110.3721, -7.8829],
      [110.3723, -7.8832],
      [110.3727, -7.8833],
      [110.3736, -7.8833],
      [110.3744, -7.8834],
      [110.3744, -7.8831],
      [110.3745, -7.8825],
      [110.3751, -7.8824],
      [110.3761, -7.8822],
      [110.3763, -7.8815],
      [110.3763, -7.8808],
      [110.3763, -7.8803],
      [110.3768, -7.879],
      [110.377, -7.8774],
      [110.3771, -7.8765],
      [110.3776, -7.8768],
      [110.3781, -7.8769],
      [110.3786, -7.877],
      [110.379, -7.8772],
      [110.3792, -7.8776],
      [110.3793, -7.8783],
      [110.3802, -7.8784],
      [110.3812, -7.8785],
      [110.3816, -7.8786],
      [110.3817, -7.8776],
      [110.3819, -7.8767],
      [110.3822, -7.876],
      [110.3823, -7.8757],
      [110.3821, -7.8753],
      [110.3818, -7.8746],
      [110.382, -7.8739],
      [110.3821, -7.8735],
      [110.3821, -7.873],
      [110.382, -7.8729],
      [110.3819, -7.8727],
      [110.3815, -7.8726],
      [110.3808, -7.8726],
      [110.3806, -7.8726],
      [110.3804, -7.8726],
      [110.38, -7.8725],
      [110.3797, -7.8721],
      [110.3796, -7.8717],
      [110.3797, -7.8713],
      [110.3798, -7.8707],
      [110.38, -7.8695],
      [110.3801, -7.8689],
      [110.3802, -7.8687],
      [110.3805, -7.8687],
      [110.3809, -7.8687],
      [110.3812, -7.868],
      [110.3813, -7.8673],
      [110.3814, -7.8668],
      [110.3814, -7.8666],
      [110.3809, -7.8666],
      [110.3804, -7.8666],
      [110.3798, -7.8668],
      [110.3792, -7.867],
      [110.3788, -7.8669],
      [110.3786, -7.8666],
      [110.3786, -7.8661],
      [110.3785, -7.8654],
      [110.3783, -7.8647],
      [110.3782, -7.864],
      [110.3785, -7.863],
      [110.3786, -7.8624],
      [110.3783, -7.8616],
      [110.3778, -7.8611],
      [110.3771, -7.8606],
      [110.3766, -7.8599],
      [110.3763, -7.8593],
      [110.3762, -7.8593]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEWON',
    village: 'BANGUNHARJO',
    border: [
      [110.375, -7.8246],
      [110.3744, -7.8245],
      [110.3739, -7.8244],
      [110.3737, -7.8247],
      [110.3735, -7.825],
      [110.3736, -7.8256],
      [110.3736, -7.8259],
      [110.3736, -7.8261],
      [110.3732, -7.8264],
      [110.3727, -7.8268],
      [110.3727, -7.8281],
      [110.372, -7.828],
      [110.3718, -7.8279],
      [110.3718, -7.8267],
      [110.3709, -7.826],
      [110.37, -7.8256],
      [110.3696, -7.8255],
      [110.3693, -7.825],
      [110.3689, -7.8256],
      [110.3686, -7.8265],
      [110.3686, -7.8271],
      [110.3682, -7.8271],
      [110.368, -7.8271],
      [110.3678, -7.8273],
      [110.3674, -7.8273],
      [110.3674, -7.8284],
      [110.3673, -7.8299],
      [110.3672, -7.8311],
      [110.3654, -7.8311],
      [110.3653, -7.8313],
      [110.3648, -7.8312],
      [110.3647, -7.8325],
      [110.3642, -7.8325],
      [110.3639, -7.8336],
      [110.3636, -7.835],
      [110.363, -7.8357],
      [110.3625, -7.8366],
      [110.3622, -7.8366],
      [110.362, -7.8368],
      [110.3618, -7.8373],
      [110.3618, -7.8384],
      [110.3618, -7.8397],
      [110.3625, -7.8398],
      [110.3635, -7.8404],
      [110.3632, -7.8412],
      [110.3628, -7.841],
      [110.3623, -7.8412],
      [110.3623, -7.8414],
      [110.3619, -7.8418],
      [110.3614, -7.8432],
      [110.362, -7.8436],
      [110.3613, -7.8448],
      [110.3602, -7.8475],
      [110.362, -7.8477],
      [110.3612, -7.8492],
      [110.3612, -7.8498],
      [110.3614, -7.8501],
      [110.3613, -7.8503],
      [110.3605, -7.8503],
      [110.36, -7.85],
      [110.3592, -7.8498],
      [110.3589, -7.8505],
      [110.3579, -7.8525],
      [110.3572, -7.854],
      [110.3576, -7.8541],
      [110.3579, -7.8543],
      [110.3587, -7.8543],
      [110.3586, -7.8551],
      [110.3586, -7.8562],
      [110.3585, -7.8569],
      [110.3582, -7.8576],
      [110.3581, -7.8583],
      [110.3583, -7.8593],
      [110.3593, -7.8597],
      [110.361, -7.8601],
      [110.361, -7.8608],
      [110.3609, -7.861],
      [110.3613, -7.8612],
      [110.3613, -7.8617],
      [110.3612, -7.8625],
      [110.3614, -7.8628],
      [110.3617, -7.863],
      [110.3616, -7.8636],
      [110.3615, -7.8642],
      [110.3613, -7.865],
      [110.3634, -7.865],
      [110.3639, -7.8664],
      [110.3637, -7.8667],
      [110.3636, -7.8671],
      [110.3637, -7.8672],
      [110.3643, -7.8673],
      [110.3649, -7.8671],
      [110.3653, -7.867],
      [110.3656, -7.867],
      [110.3662, -7.8657],
      [110.3663, -7.8651],
      [110.3664, -7.8648],
      [110.3665, -7.8639],
      [110.3665, -7.8634],
      [110.3667, -7.8633],
      [110.3668, -7.8633],
      [110.3671, -7.8631],
      [110.3678, -7.8631],
      [110.368, -7.8627],
      [110.3693, -7.8629],
      [110.3705, -7.8631],
      [110.3711, -7.863],
      [110.3716, -7.8624],
      [110.3722, -7.8615],
      [110.3724, -7.8595],
      [110.3726, -7.8584],
      [110.3741, -7.8583],
      [110.3742, -7.859],
      [110.3746, -7.8591],
      [110.3748, -7.8592],
      [110.3761, -7.8593],
      [110.3762, -7.8593],
      [110.3763, -7.8593],
      [110.3763, -7.8587],
      [110.3763, -7.8581],
      [110.3761, -7.8576],
      [110.3761, -7.8572],
      [110.3762, -7.8566],
      [110.376, -7.856],
      [110.376, -7.8555],
      [110.376, -7.8547],
      [110.3759, -7.8537],
      [110.3756, -7.853],
      [110.3754, -7.8523],
      [110.3753, -7.852],
      [110.3755, -7.8517],
      [110.3764, -7.8518],
      [110.377, -7.8519],
      [110.378, -7.8519],
      [110.3784, -7.852],
      [110.3786, -7.8522],
      [110.3796, -7.8522],
      [110.3801, -7.8522],
      [110.3802, -7.8521],
      [110.3802, -7.8517],
      [110.3802, -7.8501],
      [110.3803, -7.849],
      [110.3803, -7.8484],
      [110.3812, -7.8484],
      [110.3815, -7.8484],
      [110.3816, -7.8475],
      [110.3816, -7.8469],
      [110.3816, -7.8466],
      [110.3814, -7.8465],
      [110.3809, -7.8464],
      [110.3804, -7.8463],
      [110.3804, -7.8462],
      [110.3808, -7.8461],
      [110.3811, -7.8458],
      [110.3813, -7.8456],
      [110.3813, -7.845],
      [110.3812, -7.8442],
      [110.381, -7.8431],
      [110.3804, -7.8407],
      [110.3803, -7.8396],
      [110.3782, -7.8402],
      [110.3768, -7.8407],
      [110.3767, -7.8407],
      [110.3766, -7.8392],
      [110.3764, -7.8387],
      [110.3766, -7.8383],
      [110.3766, -7.8376],
      [110.3763, -7.8371],
      [110.3756, -7.8368],
      [110.3755, -7.8363],
      [110.3754, -7.8356],
      [110.3757, -7.8347],
      [110.3761, -7.8341],
      [110.3765, -7.8334],
      [110.3767, -7.8328],
      [110.3769, -7.8323],
      [110.3766, -7.8318],
      [110.3762, -7.8315],
      [110.3758, -7.8313],
      [110.3754, -7.831],
      [110.3753, -7.831],
      [110.3751, -7.8311],
      [110.3752, -7.8306],
      [110.3753, -7.8301],
      [110.3753, -7.8298],
      [110.3756, -7.8294],
      [110.3757, -7.829],
      [110.3757, -7.8288],
      [110.3756, -7.8284],
      [110.3757, -7.8281],
      [110.3757, -7.8277],
      [110.3757, -7.8273],
      [110.3757, -7.8269],
      [110.3755, -7.8266],
      [110.3752, -7.8263],
      [110.375, -7.826],
      [110.3749, -7.8256],
      [110.375, -7.8252],
      [110.375, -7.8246]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEWON',
    village: 'PANGGUNGHARJO',
    border: [
      [110.3556, -7.826],
      [110.3546, -7.826],
      [110.3532, -7.8258],
      [110.3522, -7.8256],
      [110.3519, -7.8256],
      [110.352, -7.8259],
      [110.3522, -7.8263],
      [110.3522, -7.8265],
      [110.352, -7.827],
      [110.3519, -7.8275],
      [110.3519, -7.8283],
      [110.3519, -7.829],
      [110.3517, -7.8293],
      [110.3518, -7.8297],
      [110.3518, -7.8305],
      [110.352, -7.8314],
      [110.3524, -7.8322],
      [110.3527, -7.8329],
      [110.3526, -7.8331],
      [110.3525, -7.8333],
      [110.3522, -7.8335],
      [110.3519, -7.8337],
      [110.351, -7.8344],
      [110.35, -7.8351],
      [110.3498, -7.8354],
      [110.3496, -7.836],
      [110.3498, -7.8364],
      [110.3502, -7.8368],
      [110.3501, -7.8373],
      [110.3501, -7.8377],
      [110.3501, -7.8381],
      [110.3505, -7.8385],
      [110.3509, -7.8391],
      [110.351, -7.8393],
      [110.3507, -7.8408],
      [110.3505, -7.8419],
      [110.3504, -7.8421],
      [110.3495, -7.8419],
      [110.349, -7.8418],
      [110.3493, -7.8409],
      [110.349, -7.8408],
      [110.3488, -7.8413],
      [110.3485, -7.8416],
      [110.3479, -7.8418],
      [110.3475, -7.8421],
      [110.3475, -7.8429],
      [110.3472, -7.8434],
      [110.3468, -7.8438],
      [110.3465, -7.845],
      [110.3465, -7.8458],
      [110.3463, -7.8463],
      [110.346, -7.8468],
      [110.3461, -7.8473],
      [110.3464, -7.8482],
      [110.3466, -7.8485],
      [110.3467, -7.8486],
      [110.3471, -7.8486],
      [110.3476, -7.8489],
      [110.3477, -7.8491],
      [110.3479, -7.8495],
      [110.348, -7.8501],
      [110.3476, -7.8504],
      [110.3475, -7.8508],
      [110.3476, -7.8513],
      [110.3479, -7.8518],
      [110.3479, -7.8525],
      [110.3479, -7.8534],
      [110.3482, -7.8539],
      [110.3483, -7.854],
      [110.3485, -7.8544],
      [110.3482, -7.8553],
      [110.3482, -7.8561],
      [110.3483, -7.8568],
      [110.3484, -7.857],
      [110.3484, -7.8572],
      [110.3485, -7.8574],
      [110.3481, -7.8581],
      [110.3481, -7.859],
      [110.3479, -7.8599],
      [110.3479, -7.86],
      [110.3483, -7.86],
      [110.349, -7.8603],
      [110.3498, -7.8603],
      [110.3512, -7.8605],
      [110.3519, -7.8606],
      [110.352, -7.8616],
      [110.3532, -7.8617],
      [110.3533, -7.862],
      [110.3555, -7.8623],
      [110.3582, -7.8628],
      [110.3583, -7.8628],
      [110.3592, -7.8628],
      [110.3598, -7.8631],
      [110.3605, -7.8635],
      [110.3616, -7.8636],
      [110.3617, -7.863],
      [110.3614, -7.8628],
      [110.3612, -7.8625],
      [110.3613, -7.8617],
      [110.3613, -7.8612],
      [110.3609, -7.861],
      [110.361, -7.8608],
      [110.361, -7.8601],
      [110.3593, -7.8597],
      [110.3583, -7.8593],
      [110.3581, -7.8583],
      [110.3582, -7.8576],
      [110.3585, -7.8569],
      [110.3586, -7.8562],
      [110.3586, -7.8551],
      [110.3587, -7.8543],
      [110.3579, -7.8543],
      [110.3576, -7.8541],
      [110.3572, -7.854],
      [110.3579, -7.8525],
      [110.3589, -7.8505],
      [110.3592, -7.8498],
      [110.36, -7.85],
      [110.3605, -7.8503],
      [110.3613, -7.8503],
      [110.3614, -7.8501],
      [110.3612, -7.8498],
      [110.3612, -7.8492],
      [110.362, -7.8477],
      [110.3602, -7.8475],
      [110.3613, -7.8448],
      [110.362, -7.8436],
      [110.3614, -7.8432],
      [110.3619, -7.8418],
      [110.3623, -7.8414],
      [110.3623, -7.8412],
      [110.3628, -7.841],
      [110.3632, -7.8412],
      [110.3635, -7.8404],
      [110.3625, -7.8398],
      [110.3618, -7.8397],
      [110.3618, -7.8384],
      [110.3618, -7.8373],
      [110.362, -7.8368],
      [110.3622, -7.8366],
      [110.3625, -7.8366],
      [110.363, -7.8357],
      [110.3636, -7.835],
      [110.3639, -7.8336],
      [110.3642, -7.8325],
      [110.3647, -7.8325],
      [110.3648, -7.8312],
      [110.3653, -7.8313],
      [110.3654, -7.8311],
      [110.3672, -7.8311],
      [110.3673, -7.8299],
      [110.3674, -7.8284],
      [110.3661, -7.8282],
      [110.3664, -7.8266],
      [110.3656, -7.8266],
      [110.3654, -7.8266],
      [110.3638, -7.8265],
      [110.362, -7.8264],
      [110.3615, -7.8264],
      [110.3609, -7.8263],
      [110.3609, -7.826],
      [110.3603, -7.8261],
      [110.3594, -7.8261],
      [110.3585, -7.8262],
      [110.3572, -7.8261],
      [110.3561, -7.826],
      [110.3556, -7.826]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KASIHAN',
    village: 'BANGUJIWO',
    border: [
      [110.3169, -7.8238],
      [110.3159, -7.8237],
      [110.3149, -7.8235],
      [110.3131, -7.8233],
      [110.3123, -7.8233],
      [110.3115, -7.8232],
      [110.3109, -7.8232],
      [110.3102, -7.8229],
      [110.3093, -7.8227],
      [110.3083, -7.8226],
      [110.3076, -7.8224],
      [110.3073, -7.8222],
      [110.3071, -7.8224],
      [110.3068, -7.8229],
      [110.3067, -7.8235],
      [110.3068, -7.824],
      [110.3068, -7.8249],
      [110.3066, -7.8256],
      [110.3064, -7.8262],
      [110.3063, -7.8265],
      [110.3064, -7.8268],
      [110.3066, -7.8272],
      [110.3066, -7.8276],
      [110.3071, -7.8276],
      [110.3072, -7.8282],
      [110.3072, -7.8285],
      [110.3069, -7.8286],
      [110.3067, -7.8287],
      [110.3067, -7.8291],
      [110.3062, -7.8289],
      [110.3061, -7.8288],
      [110.3055, -7.8286],
      [110.3049, -7.8283],
      [110.3049, -7.8282],
      [110.3047, -7.828],
      [110.3046, -7.828],
      [110.3041, -7.8279],
      [110.3031, -7.8277],
      [110.3016, -7.8275],
      [110.3014, -7.8275],
      [110.3007, -7.8276],
      [110.2997, -7.8276],
      [110.2987, -7.8275],
      [110.2981, -7.8274],
      [110.2979, -7.8278],
      [110.2977, -7.828],
      [110.2973, -7.8281],
      [110.297, -7.8285],
      [110.2967, -7.8293],
      [110.2963, -7.83],
      [110.2958, -7.8298],
      [110.2955, -7.8298],
      [110.2953, -7.8302],
      [110.2952, -7.8305],
      [110.2945, -7.8312],
      [110.2943, -7.8314],
      [110.2942, -7.8318],
      [110.2938, -7.8326],
      [110.2932, -7.8333],
      [110.2928, -7.834],
      [110.292, -7.834],
      [110.291, -7.8341],
      [110.2895, -7.834],
      [110.2887, -7.8339],
      [110.2885, -7.8336],
      [110.2879, -7.834],
      [110.2872, -7.8343],
      [110.2863, -7.8345],
      [110.2854, -7.8346],
      [110.2849, -7.8347],
      [110.2841, -7.8348],
      [110.2838, -7.8345],
      [110.2836, -7.8344],
      [110.283, -7.8343],
      [110.2824, -7.8342],
      [110.282, -7.8343],
      [110.2823, -7.8346],
      [110.2833, -7.8351],
      [110.2838, -7.8354],
      [110.284, -7.8358],
      [110.2847, -7.8368],
      [110.2847, -7.8378],
      [110.2846, -7.8393],
      [110.2841, -7.8404],
      [110.2837, -7.8411],
      [110.283, -7.8417],
      [110.2821, -7.8423],
      [110.2818, -7.8428],
      [110.282, -7.8435],
      [110.2818, -7.8442],
      [110.2814, -7.8449],
      [110.2806, -7.8458],
      [110.2803, -7.8466],
      [110.2804, -7.847],
      [110.2809, -7.8471],
      [110.2812, -7.8469],
      [110.2816, -7.8469],
      [110.2824, -7.847],
      [110.2833, -7.8473],
      [110.2846, -7.848],
      [110.2848, -7.8484],
      [110.2851, -7.8485],
      [110.2859, -7.8486],
      [110.287, -7.8488],
      [110.2876, -7.8488],
      [110.288, -7.8496],
      [110.2886, -7.8502],
      [110.2902, -7.8514],
      [110.2903, -7.8514],
      [110.2912, -7.8514],
      [110.2933, -7.8517],
      [110.2945, -7.8515],
      [110.2945, -7.8516],
      [110.2955, -7.852],
      [110.2966, -7.852],
      [110.2977, -7.852],
      [110.2984, -7.8519],
      [110.2993, -7.8522],
      [110.3006, -7.8526],
      [110.3009, -7.8534],
      [110.301, -7.8534],
      [110.3019, -7.8539],
      [110.3018, -7.8543],
      [110.3031, -7.8547],
      [110.3036, -7.8546],
      [110.3048, -7.8545],
      [110.3062, -7.8545],
      [110.3071, -7.8544],
      [110.3076, -7.8545],
      [110.3078, -7.8533],
      [110.3085, -7.8535],
      [110.3089, -7.8534],
      [110.3092, -7.8531],
      [110.3095, -7.8527],
      [110.3098, -7.853],
      [110.3105, -7.8533],
      [110.3107, -7.8531],
      [110.3124, -7.8539],
      [110.3124, -7.8541],
      [110.3137, -7.8542],
      [110.3152, -7.8544],
      [110.3163, -7.8549],
      [110.3165, -7.8552],
      [110.3163, -7.8559],
      [110.3163, -7.8565],
      [110.3173, -7.8569],
      [110.3172, -7.8582],
      [110.3181, -7.8586],
      [110.3187, -7.8599],
      [110.3192, -7.8601],
      [110.3197, -7.86],
      [110.3206, -7.8599],
      [110.3213, -7.86],
      [110.3225, -7.8604],
      [110.324, -7.8611],
      [110.3242, -7.8613],
      [110.3242, -7.8614],
      [110.3246, -7.861],
      [110.3248, -7.8604],
      [110.325, -7.8602],
      [110.3254, -7.8599],
      [110.3256, -7.8597],
      [110.326, -7.8593],
      [110.3265, -7.8593],
      [110.3271, -7.8593],
      [110.3278, -7.8593],
      [110.3282, -7.859],
      [110.3284, -7.8582],
      [110.3287, -7.8572],
      [110.3289, -7.8563],
      [110.3289, -7.8557],
      [110.3287, -7.8545],
      [110.3287, -7.8539],
      [110.3286, -7.8535],
      [110.3285, -7.8532],
      [110.3281, -7.8531],
      [110.3279, -7.8527],
      [110.3275, -7.8522],
      [110.327, -7.8514],
      [110.3266, -7.851],
      [110.3267, -7.8507],
      [110.3266, -7.8503],
      [110.3262, -7.8503],
      [110.3265, -7.8497],
      [110.3256, -7.8494],
      [110.3249, -7.8502],
      [110.3245, -7.8503],
      [110.3235, -7.8503],
      [110.3229, -7.8501],
      [110.322, -7.85],
      [110.3219, -7.85],
      [110.3217, -7.85],
      [110.3213, -7.8499],
      [110.3213, -7.849],
      [110.3213, -7.8475],
      [110.3213, -7.8468],
      [110.3176, -7.8465],
      [110.3179, -7.8453],
      [110.3181, -7.8447],
      [110.319, -7.8447],
      [110.3194, -7.8447],
      [110.3196, -7.8445],
      [110.3202, -7.8445],
      [110.3207, -7.8443],
      [110.3215, -7.8444],
      [110.3223, -7.8447],
      [110.3227, -7.8451],
      [110.3232, -7.8454],
      [110.3235, -7.8456],
      [110.3238, -7.8464],
      [110.3239, -7.8472],
      [110.3241, -7.8478],
      [110.3246, -7.8484],
      [110.3251, -7.8488],
      [110.3253, -7.8486],
      [110.3264, -7.8486],
      [110.3275, -7.8484],
      [110.3282, -7.8484],
      [110.3289, -7.8484],
      [110.3292, -7.8483],
      [110.3295, -7.8487],
      [110.3295, -7.8494],
      [110.3293, -7.8501],
      [110.3293, -7.8504],
      [110.3293, -7.8506],
      [110.3294, -7.8507],
      [110.3302, -7.8505],
      [110.3311, -7.8502],
      [110.3316, -7.8501],
      [110.3319, -7.8499],
      [110.332, -7.8495],
      [110.3317, -7.8487],
      [110.3313, -7.8478],
      [110.3313, -7.8472],
      [110.3316, -7.8469],
      [110.332, -7.8468],
      [110.3332, -7.8472],
      [110.3337, -7.8473],
      [110.3348, -7.8479],
      [110.3355, -7.8481],
      [110.3359, -7.848],
      [110.3365, -7.8475],
      [110.3385, -7.8454],
      [110.3387, -7.8449],
      [110.339, -7.8438],
      [110.3395, -7.8434],
      [110.3401, -7.8425],
      [110.3404, -7.8418],
      [110.3404, -7.8413],
      [110.3403, -7.8412],
      [110.3398, -7.8408],
      [110.3397, -7.8407],
      [110.3396, -7.8405],
      [110.3395, -7.8403],
      [110.3396, -7.84],
      [110.3402, -7.8376],
      [110.3407, -7.835],
      [110.3406, -7.8337],
      [110.3405, -7.8337],
      [110.3397, -7.8332],
      [110.3396, -7.833],
      [110.3393, -7.8324],
      [110.3391, -7.8321],
      [110.3391, -7.832],
      [110.3386, -7.8324],
      [110.3383, -7.8324],
      [110.338, -7.8325],
      [110.3372, -7.8327],
      [110.3361, -7.8329],
      [110.3356, -7.8333],
      [110.3337, -7.834],
      [110.3324, -7.8342],
      [110.3319, -7.8343],
      [110.3309, -7.8344],
      [110.3306, -7.8345],
      [110.3304, -7.8347],
      [110.3295, -7.8349],
      [110.3271, -7.8357],
      [110.3263, -7.8359],
      [110.3246, -7.837],
      [110.324, -7.8371],
      [110.3225, -7.8373],
      [110.3211, -7.8376],
      [110.3201, -7.8373],
      [110.3198, -7.837],
      [110.3198, -7.8364],
      [110.3199, -7.8357],
      [110.3201, -7.8351],
      [110.3195, -7.8341],
      [110.3188, -7.8329],
      [110.3178, -7.8311],
      [110.3177, -7.8305],
      [110.3176, -7.8303],
      [110.3174, -7.83],
      [110.3171, -7.8291],
      [110.3169, -7.8278],
      [110.3169, -7.8266],
      [110.3168, -7.8257],
      [110.3169, -7.8242],
      [110.3169, -7.8238]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KASIHAN',
    village: 'TIRTONIRMOLO',
    border: [
      [110.3514, -7.8174],
      [110.3513, -7.8173],
      [110.3509, -7.8171],
      [110.3506, -7.8169],
      [110.3499, -7.8167],
      [110.3487, -7.8165],
      [110.3484, -7.8175],
      [110.345, -7.8167],
      [110.3454, -7.8153],
      [110.3445, -7.8153],
      [110.3446, -7.814],
      [110.3402, -7.8131],
      [110.3375, -7.8125],
      [110.3355, -7.8109],
      [110.3352, -7.8116],
      [110.335, -7.8121],
      [110.3346, -7.812],
      [110.3342, -7.812],
      [110.3339, -7.8123],
      [110.3339, -7.8126],
      [110.3343, -7.8132],
      [110.3345, -7.8135],
      [110.3347, -7.8142],
      [110.3346, -7.8146],
      [110.3342, -7.8151],
      [110.334, -7.8154],
      [110.3339, -7.8158],
      [110.3339, -7.8162],
      [110.3341, -7.8166],
      [110.3342, -7.8169],
      [110.3344, -7.8172],
      [110.3345, -7.8175],
      [110.3345, -7.8177],
      [110.335, -7.8177],
      [110.3355, -7.8179],
      [110.3357, -7.818],
      [110.3358, -7.8181],
      [110.336, -7.8187],
      [110.3361, -7.819],
      [110.336, -7.8198],
      [110.3358, -7.8202],
      [110.3352, -7.8203],
      [110.3346, -7.8203],
      [110.3342, -7.8204],
      [110.3339, -7.8211],
      [110.3338, -7.8212],
      [110.3338, -7.8214],
      [110.3337, -7.8216],
      [110.3333, -7.8216],
      [110.333, -7.8218],
      [110.3329, -7.8222],
      [110.3329, -7.8229],
      [110.3331, -7.8235],
      [110.3332, -7.8236],
      [110.3334, -7.8238],
      [110.3337, -7.8236],
      [110.334, -7.8231],
      [110.3344, -7.8227],
      [110.3348, -7.8227],
      [110.335, -7.8224],
      [110.3355, -7.8223],
      [110.3358, -7.8224],
      [110.3361, -7.8226],
      [110.3364, -7.823],
      [110.3365, -7.8231],
      [110.3366, -7.8234],
      [110.3366, -7.824],
      [110.3365, -7.8248],
      [110.3363, -7.8255],
      [110.3363, -7.8257],
      [110.3364, -7.8258],
      [110.3372, -7.8264],
      [110.3377, -7.8264],
      [110.3383, -7.8262],
      [110.3386, -7.8263],
      [110.339, -7.8271],
      [110.3391, -7.8276],
      [110.3391, -7.8279],
      [110.339, -7.8284],
      [110.3387, -7.8288],
      [110.3384, -7.8292],
      [110.3387, -7.8297],
      [110.3388, -7.83],
      [110.3389, -7.8302],
      [110.339, -7.8305],
      [110.3391, -7.832],
      [110.3391, -7.8321],
      [110.3393, -7.8324],
      [110.3396, -7.833],
      [110.3397, -7.8332],
      [110.3405, -7.8337],
      [110.3406, -7.8337],
      [110.3407, -7.835],
      [110.3402, -7.8376],
      [110.3396, -7.84],
      [110.3395, -7.8403],
      [110.3396, -7.8405],
      [110.3397, -7.8407],
      [110.3398, -7.8408],
      [110.3403, -7.8412],
      [110.3404, -7.8413],
      [110.3404, -7.8418],
      [110.3401, -7.8425],
      [110.3395, -7.8434],
      [110.339, -7.8438],
      [110.3387, -7.8449],
      [110.3385, -7.8454],
      [110.3365, -7.8475],
      [110.3359, -7.848],
      [110.3355, -7.8481],
      [110.3348, -7.8479],
      [110.3337, -7.8473],
      [110.3335, -7.8482],
      [110.3333, -7.8485],
      [110.3331, -7.8485],
      [110.3329, -7.849],
      [110.3328, -7.8495],
      [110.3327, -7.8499],
      [110.3329, -7.8502],
      [110.3331, -7.8505],
      [110.3341, -7.8506],
      [110.335, -7.8509],
      [110.3357, -7.851],
      [110.3357, -7.8525],
      [110.3352, -7.8526],
      [110.335, -7.8532],
      [110.3359, -7.8537],
      [110.3357, -7.854],
      [110.3353, -7.854],
      [110.3351, -7.8553],
      [110.3379, -7.856],
      [110.3381, -7.8554],
      [110.338, -7.8537],
      [110.3381, -7.8529],
      [110.3385, -7.8514],
      [110.3388, -7.8504],
      [110.3392, -7.8499],
      [110.3399, -7.8495],
      [110.3406, -7.8497],
      [110.3418, -7.85],
      [110.3428, -7.8501],
      [110.3427, -7.8505],
      [110.3435, -7.8507],
      [110.3436, -7.8501],
      [110.3439, -7.8494],
      [110.3446, -7.8497],
      [110.345, -7.8502],
      [110.3452, -7.8499],
      [110.3454, -7.8497],
      [110.3454, -7.8493],
      [110.3457, -7.8492],
      [110.3462, -7.8494],
      [110.3466, -7.8494],
      [110.3467, -7.8486],
      [110.3466, -7.8485],
      [110.3464, -7.8482],
      [110.3461, -7.8473],
      [110.346, -7.8468],
      [110.3463, -7.8463],
      [110.3465, -7.8458],
      [110.3465, -7.845],
      [110.3468, -7.8438],
      [110.3472, -7.8434],
      [110.3475, -7.8429],
      [110.3475, -7.8421],
      [110.3479, -7.8418],
      [110.3485, -7.8416],
      [110.3488, -7.8413],
      [110.349, -7.8408],
      [110.3493, -7.8409],
      [110.349, -7.8418],
      [110.3495, -7.8419],
      [110.3504, -7.8421],
      [110.3505, -7.8419],
      [110.3507, -7.8408],
      [110.351, -7.8393],
      [110.3509, -7.8391],
      [110.3505, -7.8385],
      [110.3501, -7.8381],
      [110.3501, -7.8377],
      [110.3501, -7.8373],
      [110.3502, -7.8368],
      [110.3498, -7.8364],
      [110.3496, -7.836],
      [110.3498, -7.8354],
      [110.35, -7.8351],
      [110.351, -7.8344],
      [110.3519, -7.8337],
      [110.3522, -7.8335],
      [110.3525, -7.8333],
      [110.3526, -7.8331],
      [110.3527, -7.8329],
      [110.3524, -7.8322],
      [110.352, -7.8314],
      [110.3518, -7.8305],
      [110.3518, -7.8297],
      [110.3517, -7.8293],
      [110.3519, -7.829],
      [110.3519, -7.8283],
      [110.3519, -7.8275],
      [110.352, -7.827],
      [110.3522, -7.8265],
      [110.3522, -7.8263],
      [110.352, -7.8259],
      [110.3519, -7.8256],
      [110.3516, -7.8256],
      [110.3514, -7.8252],
      [110.3515, -7.8248],
      [110.3516, -7.8243],
      [110.3518, -7.824],
      [110.352, -7.8239],
      [110.3523, -7.8239],
      [110.3525, -7.8238],
      [110.3526, -7.8236],
      [110.3524, -7.8234],
      [110.3522, -7.823],
      [110.3517, -7.8225],
      [110.3515, -7.8224],
      [110.3513, -7.8222],
      [110.351, -7.822],
      [110.351, -7.8218],
      [110.3508, -7.8214],
      [110.3509, -7.8208],
      [110.351, -7.8198],
      [110.3511, -7.8189],
      [110.3511, -7.8183],
      [110.3512, -7.8178],
      [110.3513, -7.8175],
      [110.3514, -7.8174]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KASIHAN',
    village: 'TAMANTIRTO',
    border: [
      [110.3301, -7.8077],
      [110.3298, -7.8079],
      [110.3295, -7.8077],
      [110.3292, -7.8075],
      [110.3288, -7.8074],
      [110.3288, -7.8073],
      [110.3289, -7.8068],
      [110.3289, -7.8063],
      [110.3287, -7.8062],
      [110.3282, -7.8061],
      [110.3282, -7.8056],
      [110.3279, -7.8056],
      [110.3278, -7.805],
      [110.3276, -7.805],
      [110.3276, -7.8061],
      [110.3271, -7.8061],
      [110.3267, -7.806],
      [110.3267, -7.8063],
      [110.3259, -7.8064],
      [110.3257, -7.8064],
      [110.3252, -7.806],
      [110.3248, -7.8059],
      [110.3243, -7.8059],
      [110.3236, -7.8057],
      [110.3235, -7.8057],
      [110.3233, -7.8062],
      [110.3231, -7.8064],
      [110.3226, -7.8067],
      [110.3223, -7.8067],
      [110.3221, -7.8068],
      [110.3213, -7.8068],
      [110.3201, -7.8065],
      [110.3187, -7.8063],
      [110.3185, -7.8064],
      [110.3182, -7.8064],
      [110.3174, -7.8064],
      [110.3167, -7.8079],
      [110.3165, -7.8084],
      [110.3165, -7.8092],
      [110.3162, -7.8099],
      [110.3161, -7.8107],
      [110.316, -7.8119],
      [110.3159, -7.8125],
      [110.3156, -7.8135],
      [110.3156, -7.8137],
      [110.3158, -7.8142],
      [110.3158, -7.8148],
      [110.3158, -7.8152],
      [110.3156, -7.8156],
      [110.3154, -7.8157],
      [110.3153, -7.8159],
      [110.3153, -7.8165],
      [110.3152, -7.817],
      [110.3151, -7.8175],
      [110.3155, -7.8176],
      [110.3153, -7.8181],
      [110.3152, -7.8191],
      [110.3151, -7.8194],
      [110.3157, -7.8198],
      [110.3162, -7.8199],
      [110.3169, -7.8197],
      [110.3173, -7.8196],
      [110.3176, -7.8198],
      [110.3179, -7.8201],
      [110.3178, -7.8208],
      [110.3179, -7.8215],
      [110.3181, -7.822],
      [110.318, -7.8225],
      [110.3179, -7.8231],
      [110.3176, -7.8236],
      [110.317, -7.8238],
      [110.3169, -7.8238],
      [110.3169, -7.8242],
      [110.3168, -7.8257],
      [110.3169, -7.8266],
      [110.3169, -7.8278],
      [110.3171, -7.8291],
      [110.3174, -7.83],
      [110.3176, -7.8303],
      [110.3177, -7.8305],
      [110.3178, -7.8311],
      [110.3188, -7.8329],
      [110.3195, -7.8341],
      [110.3201, -7.8351],
      [110.3199, -7.8357],
      [110.3198, -7.8364],
      [110.3198, -7.837],
      [110.3201, -7.8373],
      [110.3211, -7.8376],
      [110.3225, -7.8373],
      [110.324, -7.8371],
      [110.3246, -7.837],
      [110.3263, -7.8359],
      [110.3271, -7.8357],
      [110.3295, -7.8349],
      [110.3304, -7.8347],
      [110.3306, -7.8345],
      [110.3309, -7.8344],
      [110.3319, -7.8343],
      [110.3324, -7.8342],
      [110.3337, -7.834],
      [110.3356, -7.8333],
      [110.3361, -7.8329],
      [110.3372, -7.8327],
      [110.338, -7.8325],
      [110.3383, -7.8324],
      [110.3386, -7.8324],
      [110.3391, -7.832],
      [110.339, -7.8305],
      [110.3389, -7.8302],
      [110.3388, -7.83],
      [110.3387, -7.8297],
      [110.3384, -7.8292],
      [110.3387, -7.8288],
      [110.339, -7.8284],
      [110.3391, -7.8279],
      [110.3391, -7.8276],
      [110.339, -7.8271],
      [110.3386, -7.8263],
      [110.3383, -7.8262],
      [110.3377, -7.8264],
      [110.3372, -7.8264],
      [110.3364, -7.8258],
      [110.3363, -7.8257],
      [110.3363, -7.8255],
      [110.3365, -7.8248],
      [110.3366, -7.824],
      [110.3366, -7.8234],
      [110.3365, -7.8231],
      [110.3364, -7.823],
      [110.3361, -7.8226],
      [110.3358, -7.8224],
      [110.3355, -7.8223],
      [110.335, -7.8224],
      [110.3348, -7.8227],
      [110.3344, -7.8227],
      [110.334, -7.8231],
      [110.3337, -7.8236],
      [110.3334, -7.8238],
      [110.3332, -7.8236],
      [110.3331, -7.8235],
      [110.3329, -7.8229],
      [110.3329, -7.8222],
      [110.333, -7.8218],
      [110.3333, -7.8216],
      [110.3337, -7.8216],
      [110.3338, -7.8214],
      [110.3338, -7.8212],
      [110.3339, -7.8211],
      [110.3342, -7.8204],
      [110.3346, -7.8203],
      [110.3352, -7.8203],
      [110.3358, -7.8202],
      [110.336, -7.8198],
      [110.3361, -7.819],
      [110.336, -7.8187],
      [110.3358, -7.8181],
      [110.3357, -7.818],
      [110.3355, -7.8179],
      [110.335, -7.8177],
      [110.3345, -7.8177],
      [110.3345, -7.8175],
      [110.3344, -7.8172],
      [110.3342, -7.8169],
      [110.3341, -7.8166],
      [110.3339, -7.8162],
      [110.3339, -7.8158],
      [110.334, -7.8154],
      [110.3342, -7.8151],
      [110.3346, -7.8146],
      [110.3347, -7.8142],
      [110.3345, -7.8135],
      [110.3343, -7.8132],
      [110.3339, -7.8126],
      [110.3336, -7.8125],
      [110.3332, -7.8129],
      [110.3328, -7.8134],
      [110.3324, -7.8137],
      [110.3322, -7.8137],
      [110.3318, -7.8134],
      [110.3315, -7.8128],
      [110.3315, -7.8127],
      [110.3314, -7.8124],
      [110.3315, -7.8121],
      [110.3317, -7.8118],
      [110.332, -7.8116],
      [110.3321, -7.8112],
      [110.3321, -7.8111],
      [110.332, -7.811],
      [110.3319, -7.8108],
      [110.3315, -7.8101],
      [110.3315, -7.81],
      [110.331, -7.8091],
      [110.3305, -7.8082],
      [110.3303, -7.808],
      [110.3301, -7.8077]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'KASIHAN',
    village: 'NGESTIHARJO',
    border: [
      [110.3511, -7.7697],
      [110.3511, -7.7696],
      [110.351, -7.7687],
      [110.3509, -7.768],
      [110.3503, -7.7684],
      [110.3501, -7.7686],
      [110.3498, -7.7694],
      [110.3491, -7.7699],
      [110.349, -7.77],
      [110.3489, -7.7702],
      [110.349, -7.7703],
      [110.349, -7.7705],
      [110.3491, -7.7708],
      [110.3491, -7.7723],
      [110.3487, -7.7736],
      [110.3484, -7.774],
      [110.3479, -7.7748],
      [110.3476, -7.7754],
      [110.3475, -7.7765],
      [110.3475, -7.7773],
      [110.3475, -7.7774],
      [110.3477, -7.7777],
      [110.3477, -7.7778],
      [110.3477, -7.7779],
      [110.3478, -7.7782],
      [110.3478, -7.7784],
      [110.3477, -7.7787],
      [110.3476, -7.7788],
      [110.3476, -7.7789],
      [110.3477, -7.7793],
      [110.3477, -7.7797],
      [110.3477, -7.7798],
      [110.3477, -7.7801],
      [110.3477, -7.7806],
      [110.3475, -7.7821],
      [110.3463, -7.7836],
      [110.3463, -7.7838],
      [110.3463, -7.7845],
      [110.3463, -7.7854],
      [110.3459, -7.7856],
      [110.3457, -7.7857],
      [110.3454, -7.7859],
      [110.3452, -7.7863],
      [110.3451, -7.7867],
      [110.345, -7.7871],
      [110.3449, -7.7874],
      [110.3448, -7.7879],
      [110.3447, -7.7889],
      [110.3447, -7.7891],
      [110.3445, -7.7893],
      [110.3444, -7.7902],
      [110.3438, -7.7916],
      [110.3436, -7.7919],
      [110.3429, -7.7918],
      [110.3425, -7.7918],
      [110.3422, -7.7921],
      [110.341, -7.7922],
      [110.3402, -7.7922],
      [110.3398, -7.7923],
      [110.3397, -7.793],
      [110.3396, -7.7935],
      [110.3398, -7.7942],
      [110.3392, -7.796],
      [110.339, -7.7965],
      [110.3387, -7.7974],
      [110.3386, -7.798],
      [110.3382, -7.7978],
      [110.3377, -7.7977],
      [110.3372, -7.7978],
      [110.3364, -7.7977],
      [110.3363, -7.7976],
      [110.3361, -7.7975],
      [110.336, -7.798],
      [110.3351, -7.798],
      [110.335, -7.7983],
      [110.3342, -7.7982],
      [110.3338, -7.8],
      [110.3331, -7.8001],
      [110.3331, -7.7998],
      [110.333, -7.7998],
      [110.333, -7.8],
      [110.3328, -7.8],
      [110.3327, -7.8005],
      [110.3324, -7.8005],
      [110.332, -7.8004],
      [110.3318, -7.8006],
      [110.3318, -7.8007],
      [110.3317, -7.8013],
      [110.3316, -7.8018],
      [110.3314, -7.8022],
      [110.3315, -7.8023],
      [110.332, -7.8028],
      [110.3322, -7.8031],
      [110.3323, -7.8033],
      [110.3322, -7.8036],
      [110.3321, -7.8037],
      [110.3317, -7.8037],
      [110.3313, -7.8037],
      [110.3309, -7.8039],
      [110.3306, -7.8042],
      [110.3302, -7.8047],
      [110.33, -7.805],
      [110.33, -7.8052],
      [110.3302, -7.8055],
      [110.3305, -7.8059],
      [110.3307, -7.806],
      [110.3308, -7.8062],
      [110.3308, -7.8064],
      [110.3304, -7.8068],
      [110.3303, -7.8072],
      [110.3302, -7.8076],
      [110.3301, -7.8077],
      [110.3303, -7.808],
      [110.3305, -7.8082],
      [110.331, -7.8091],
      [110.3315, -7.81],
      [110.3315, -7.8101],
      [110.3319, -7.8108],
      [110.332, -7.811],
      [110.3321, -7.8111],
      [110.3321, -7.8112],
      [110.332, -7.8116],
      [110.3317, -7.8118],
      [110.3315, -7.8121],
      [110.3314, -7.8124],
      [110.3315, -7.8127],
      [110.3315, -7.8128],
      [110.3318, -7.8134],
      [110.3322, -7.8137],
      [110.3324, -7.8137],
      [110.3328, -7.8134],
      [110.3332, -7.8129],
      [110.3336, -7.8125],
      [110.3339, -7.8126],
      [110.3339, -7.8123],
      [110.3342, -7.812],
      [110.3346, -7.812],
      [110.335, -7.8121],
      [110.3352, -7.8116],
      [110.3355, -7.8109],
      [110.3375, -7.8125],
      [110.3402, -7.8131],
      [110.3446, -7.814],
      [110.3445, -7.8153],
      [110.3454, -7.8153],
      [110.345, -7.8167],
      [110.3484, -7.8175],
      [110.3487, -7.8165],
      [110.3499, -7.8167],
      [110.3506, -7.8169],
      [110.3509, -7.8171],
      [110.3513, -7.8173],
      [110.3514, -7.8174],
      [110.3515, -7.8173],
      [110.3518, -7.8172],
      [110.3521, -7.8171],
      [110.3522, -7.8169],
      [110.3524, -7.8166],
      [110.3526, -7.8163],
      [110.3525, -7.8159],
      [110.3523, -7.8158],
      [110.3522, -7.8152],
      [110.3521, -7.8148],
      [110.3518, -7.8148],
      [110.3514, -7.8148],
      [110.3512, -7.8146],
      [110.3511, -7.8145],
      [110.3512, -7.8143],
      [110.3514, -7.8139],
      [110.3516, -7.8135],
      [110.352, -7.813],
      [110.3522, -7.8124],
      [110.3517, -7.8123],
      [110.3503, -7.8121],
      [110.3457, -7.8114],
      [110.3456, -7.8113],
      [110.3456, -7.811],
      [110.3458, -7.8106],
      [110.3457, -7.8102],
      [110.3456, -7.81],
      [110.3455, -7.8099],
      [110.3452, -7.8096],
      [110.3452, -7.8093],
      [110.3452, -7.8089],
      [110.3452, -7.8085],
      [110.3451, -7.8082],
      [110.345, -7.808],
      [110.3448, -7.8078],
      [110.3446, -7.8076],
      [110.3443, -7.8075],
      [110.3443, -7.8073],
      [110.3443, -7.807],
      [110.3445, -7.8069],
      [110.3446, -7.8068],
      [110.3451, -7.8065],
      [110.3453, -7.8059],
      [110.3457, -7.8049],
      [110.346, -7.8042],
      [110.3463, -7.8035],
      [110.3463, -7.8031],
      [110.3465, -7.8027],
      [110.3467, -7.8025],
      [110.3468, -7.8024],
      [110.3468, -7.8022],
      [110.3468, -7.802],
      [110.3467, -7.8019],
      [110.3467, -7.8011],
      [110.3468, -7.8008],
      [110.347, -7.7999],
      [110.3473, -7.799],
      [110.3469, -7.7987],
      [110.347, -7.798],
      [110.3467, -7.7979],
      [110.3468, -7.7977],
      [110.347, -7.7972],
      [110.3471, -7.7967],
      [110.3473, -7.7964],
      [110.3474, -7.7961],
      [110.3476, -7.796],
      [110.3476, -7.7957],
      [110.3475, -7.7952],
      [110.3489, -7.795],
      [110.3495, -7.795],
      [110.3495, -7.7942],
      [110.348, -7.7945],
      [110.3479, -7.7941],
      [110.3474, -7.7942],
      [110.3473, -7.7938],
      [110.3474, -7.7935],
      [110.3475, -7.7933],
      [110.348, -7.7933],
      [110.3483, -7.7933],
      [110.3487, -7.7934],
      [110.3487, -7.7932],
      [110.3488, -7.793],
      [110.3488, -7.7924],
      [110.3489, -7.7919],
      [110.3492, -7.7908],
      [110.3493, -7.7902],
      [110.3495, -7.7898],
      [110.3497, -7.7896],
      [110.3499, -7.7884],
      [110.3501, -7.787],
      [110.3504, -7.7844],
      [110.3507, -7.7817],
      [110.3508, -7.7813],
      [110.3508, -7.7811],
      [110.3509, -7.7808],
      [110.3511, -7.7803],
      [110.3511, -7.7793],
      [110.3509, -7.7766],
      [110.3509, -7.7763],
      [110.3508, -7.7755],
      [110.351, -7.775],
      [110.3511, -7.7743],
      [110.3511, -7.7735],
      [110.3511, -7.7732],
      [110.3511, -7.7731],
      [110.3512, -7.7726],
      [110.3513, -7.7722],
      [110.3513, -7.7718],
      [110.3512, -7.7712],
      [110.3511, -7.7707],
      [110.3511, -7.7703],
      [110.3511, -7.7697]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEDAYU',
    village: 'ARGODADI',
    border: [
      [110.2437, -7.8252],
      [110.2435, -7.8251],
      [110.2433, -7.825],
      [110.2433, -7.8242],
      [110.2426, -7.8239],
      [110.2429, -7.8232],
      [110.2437, -7.8213],
      [110.2436, -7.821],
      [110.2431, -7.8193],
      [110.2398, -7.8209],
      [110.2393, -7.821],
      [110.2393, -7.8215],
      [110.2389, -7.8215],
      [110.2389, -7.8225],
      [110.2391, -7.8227],
      [110.239, -7.8237],
      [110.2386, -7.8244],
      [110.2381, -7.825],
      [110.2379, -7.8256],
      [110.2381, -7.8259],
      [110.238, -7.8266],
      [110.2375, -7.8271],
      [110.2373, -7.8275],
      [110.2372, -7.8278],
      [110.2371, -7.8281],
      [110.237, -7.8288],
      [110.2368, -7.8291],
      [110.2363, -7.8294],
      [110.2356, -7.8294],
      [110.235, -7.8294],
      [110.2349, -7.8304],
      [110.2353, -7.8306],
      [110.2351, -7.831],
      [110.2346, -7.8311],
      [110.2344, -7.8312],
      [110.2337, -7.8314],
      [110.233, -7.8322],
      [110.2324, -7.8319],
      [110.232, -7.8315],
      [110.2319, -7.8312],
      [110.2315, -7.8311],
      [110.2312, -7.8307],
      [110.2311, -7.8306],
      [110.2307, -7.8301],
      [110.23, -7.8307],
      [110.2296, -7.8311],
      [110.2292, -7.8315],
      [110.229, -7.832],
      [110.2285, -7.8324],
      [110.2279, -7.8328],
      [110.2271, -7.8336],
      [110.2265, -7.8341],
      [110.2258, -7.8344],
      [110.2244, -7.8346],
      [110.2231, -7.8348],
      [110.2226, -7.8349],
      [110.2219, -7.8351],
      [110.2213, -7.8356],
      [110.221, -7.8363],
      [110.221, -7.8367],
      [110.2211, -7.8375],
      [110.222, -7.8388],
      [110.2227, -7.8398],
      [110.2227, -7.8398],
      [110.223, -7.8403],
      [110.2246, -7.8413],
      [110.2255, -7.842],
      [110.2264, -7.8427],
      [110.2278, -7.8433],
      [110.2295, -7.8437],
      [110.2308, -7.8442],
      [110.2313, -7.8443],
      [110.2318, -7.8449],
      [110.2325, -7.8455],
      [110.2331, -7.8465],
      [110.234, -7.848],
      [110.2347, -7.8497],
      [110.2357, -7.851],
      [110.2375, -7.853],
      [110.2409, -7.8554],
      [110.2419, -7.8562],
      [110.2424, -7.8566],
      [110.2427, -7.8568],
      [110.2436, -7.8573],
      [110.2442, -7.858],
      [110.2451, -7.8588],
      [110.2459, -7.8593],
      [110.2469, -7.86],
      [110.2476, -7.8604],
      [110.2483, -7.8608],
      [110.2494, -7.8617],
      [110.2499, -7.8623],
      [110.2505, -7.8626],
      [110.254, -7.864],
      [110.2563, -7.8656],
      [110.2564, -7.8658],
      [110.2573, -7.8646],
      [110.2574, -7.8632],
      [110.2575, -7.8619],
      [110.2593, -7.8618],
      [110.2591, -7.8602],
      [110.259, -7.8593],
      [110.2582, -7.8593],
      [110.2579, -7.8592],
      [110.2576, -7.8586],
      [110.2574, -7.8582],
      [110.2571, -7.858],
      [110.2574, -7.8568],
      [110.2575, -7.8556],
      [110.2578, -7.8544],
      [110.2584, -7.8534],
      [110.2591, -7.8529],
      [110.2597, -7.8525],
      [110.2604, -7.8523],
      [110.2608, -7.8522],
      [110.2612, -7.8513],
      [110.263, -7.8486],
      [110.264, -7.8468],
      [110.2648, -7.8457],
      [110.2656, -7.8443],
      [110.2661, -7.8433],
      [110.2663, -7.8431],
      [110.2667, -7.843],
      [110.2673, -7.8422],
      [110.2675, -7.8417],
      [110.268, -7.8411],
      [110.2681, -7.8406],
      [110.2687, -7.8402],
      [110.2691, -7.8395],
      [110.2695, -7.839],
      [110.2705, -7.8378],
      [110.2709, -7.8375],
      [110.2701, -7.8369],
      [110.2689, -7.8369],
      [110.2683, -7.8369],
      [110.2678, -7.8368],
      [110.2671, -7.8363],
      [110.2665, -7.8359],
      [110.2656, -7.8366],
      [110.2639, -7.8367],
      [110.2619, -7.8361],
      [110.2609, -7.836],
      [110.2592, -7.8354],
      [110.2579, -7.8354],
      [110.2566, -7.8356],
      [110.2553, -7.8356],
      [110.2548, -7.836],
      [110.2547, -7.836],
      [110.2539, -7.8358],
      [110.2538, -7.8359],
      [110.2535, -7.836],
      [110.2533, -7.8365],
      [110.2532, -7.8368],
      [110.2531, -7.837],
      [110.2529, -7.8372],
      [110.2529, -7.8372],
      [110.2526, -7.8374],
      [110.2523, -7.8373],
      [110.2521, -7.8369],
      [110.2522, -7.8366],
      [110.2524, -7.8361],
      [110.2522, -7.8358],
      [110.2513, -7.835],
      [110.2512, -7.8349],
      [110.25, -7.8339],
      [110.2492, -7.834],
      [110.2483, -7.8338],
      [110.2481, -7.8338],
      [110.2478, -7.8335],
      [110.2472, -7.8337],
      [110.2465, -7.834],
      [110.2454, -7.834],
      [110.2452, -7.8335],
      [110.2449, -7.833],
      [110.2449, -7.8315],
      [110.2455, -7.8305],
      [110.2457, -7.8293],
      [110.2457, -7.829],
      [110.2455, -7.8285],
      [110.2431, -7.8276],
      [110.2429, -7.8275],
      [110.2437, -7.8252]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEDAYU',
    village: 'ARGOREJO',
    border: [
      [110.2618, -7.8063],
      [110.2612, -7.8066],
      [110.2609, -7.8066],
      [110.2608, -7.8057],
      [110.2608, -7.8056],
      [110.2603, -7.8056],
      [110.2601, -7.8058],
      [110.2603, -7.8062],
      [110.2604, -7.8064],
      [110.26, -7.8063],
      [110.2594, -7.806],
      [110.2588, -7.8061],
      [110.258, -7.8063],
      [110.2579, -7.8067],
      [110.2573, -7.8064],
      [110.257, -7.8072],
      [110.257, -7.8076],
      [110.256, -7.8075],
      [110.2558, -7.8082],
      [110.2555, -7.8082],
      [110.2547, -7.8081],
      [110.2544, -7.8086],
      [110.2541, -7.8088],
      [110.2532, -7.8089],
      [110.253, -7.809],
      [110.2536, -7.8099],
      [110.2535, -7.8101],
      [110.2539, -7.8108],
      [110.2537, -7.8113],
      [110.2535, -7.8118],
      [110.2532, -7.8129],
      [110.2531, -7.8135],
      [110.252, -7.8131],
      [110.2519, -7.8133],
      [110.2518, -7.8139],
      [110.2512, -7.8139],
      [110.2513, -7.8151],
      [110.2515, -7.8152],
      [110.2527, -7.8149],
      [110.253, -7.8151],
      [110.253, -7.8163],
      [110.253, -7.8167],
      [110.2528, -7.8169],
      [110.2524, -7.8171],
      [110.2521, -7.8174],
      [110.2522, -7.8179],
      [110.2521, -7.8183],
      [110.2523, -7.8185],
      [110.2525, -7.8186],
      [110.2527, -7.8186],
      [110.2529, -7.8186],
      [110.2535, -7.8188],
      [110.2538, -7.8187],
      [110.254, -7.8187],
      [110.2541, -7.8192],
      [110.2539, -7.8199],
      [110.2537, -7.8206],
      [110.2536, -7.8214],
      [110.2534, -7.822],
      [110.2533, -7.822],
      [110.2532, -7.822],
      [110.2531, -7.8218],
      [110.2527, -7.8216],
      [110.2523, -7.8214],
      [110.2522, -7.8215],
      [110.2518, -7.8217],
      [110.2516, -7.8218],
      [110.2513, -7.8227],
      [110.2514, -7.8233],
      [110.2512, -7.8236],
      [110.251, -7.8235],
      [110.2506, -7.8232],
      [110.25, -7.8233],
      [110.2497, -7.8234],
      [110.2491, -7.8237],
      [110.2487, -7.824],
      [110.2487, -7.8244],
      [110.249, -7.8249],
      [110.2493, -7.8253],
      [110.2492, -7.8256],
      [110.2486, -7.8258],
      [110.2478, -7.8259],
      [110.2468, -7.8257],
      [110.245, -7.8255],
      [110.2437, -7.8252],
      [110.2429, -7.8275],
      [110.2431, -7.8276],
      [110.2455, -7.8285],
      [110.2457, -7.829],
      [110.2457, -7.8293],
      [110.2455, -7.8305],
      [110.2449, -7.8315],
      [110.2449, -7.833],
      [110.2452, -7.8335],
      [110.2454, -7.834],
      [110.2465, -7.834],
      [110.2472, -7.8337],
      [110.2478, -7.8335],
      [110.2481, -7.8338],
      [110.2483, -7.8338],
      [110.2492, -7.834],
      [110.25, -7.8339],
      [110.2512, -7.8349],
      [110.2513, -7.835],
      [110.2522, -7.8358],
      [110.2524, -7.8361],
      [110.2522, -7.8366],
      [110.2521, -7.8369],
      [110.2523, -7.8373],
      [110.2526, -7.8374],
      [110.2529, -7.8372],
      [110.2529, -7.8372],
      [110.2531, -7.837],
      [110.2532, -7.8368],
      [110.2533, -7.8365],
      [110.2535, -7.836],
      [110.2538, -7.8359],
      [110.2539, -7.8358],
      [110.2547, -7.836],
      [110.2548, -7.836],
      [110.2553, -7.8356],
      [110.2566, -7.8356],
      [110.2579, -7.8354],
      [110.2592, -7.8354],
      [110.2609, -7.836],
      [110.2619, -7.8361],
      [110.2639, -7.8367],
      [110.2656, -7.8366],
      [110.2665, -7.8359],
      [110.2671, -7.8363],
      [110.2678, -7.8368],
      [110.2683, -7.8369],
      [110.2689, -7.8369],
      [110.2701, -7.8369],
      [110.2709, -7.8375],
      [110.271, -7.8373],
      [110.2716, -7.8371],
      [110.273, -7.8373],
      [110.2742, -7.8374],
      [110.2752, -7.8376],
      [110.2764, -7.8376],
      [110.2773, -7.8373],
      [110.2779, -7.8366],
      [110.2783, -7.8359],
      [110.2783, -7.8357],
      [110.2774, -7.8352],
      [110.2771, -7.8347],
      [110.2763, -7.8344],
      [110.2758, -7.834],
      [110.2751, -7.833],
      [110.2752, -7.8315],
      [110.2754, -7.8297],
      [110.2761, -7.8286],
      [110.2763, -7.8281],
      [110.2763, -7.8277],
      [110.2758, -7.8279],
      [110.2756, -7.8277],
      [110.2755, -7.8277],
      [110.2755, -7.8273],
      [110.2757, -7.8272],
      [110.2757, -7.8269],
      [110.2755, -7.8269],
      [110.2752, -7.8269],
      [110.2752, -7.8266],
      [110.2753, -7.826],
      [110.2752, -7.8258],
      [110.2747, -7.8256],
      [110.2746, -7.8255],
      [110.2747, -7.8253],
      [110.2746, -7.8251],
      [110.2743, -7.8248],
      [110.274, -7.8247],
      [110.2739, -7.8242],
      [110.2737, -7.8239],
      [110.2735, -7.8238],
      [110.2733, -7.8238],
      [110.2729, -7.8238],
      [110.2723, -7.8232],
      [110.2724, -7.8228],
      [110.2724, -7.8226],
      [110.272, -7.8223],
      [110.2712, -7.8217],
      [110.2707, -7.8203],
      [110.2711, -7.8195],
      [110.2697, -7.8189],
      [110.2694, -7.8197],
      [110.269, -7.82],
      [110.2686, -7.8197],
      [110.2681, -7.8194],
      [110.2679, -7.819],
      [110.2679, -7.8185],
      [110.2679, -7.8184],
      [110.268, -7.8181],
      [110.2682, -7.8177],
      [110.2683, -7.8175],
      [110.2685, -7.8174],
      [110.2684, -7.817],
      [110.2683, -7.8167],
      [110.2678, -7.8165],
      [110.2677, -7.8161],
      [110.2683, -7.8147],
      [110.2693, -7.8124],
      [110.2691, -7.8124],
      [110.2683, -7.8124],
      [110.2686, -7.8115],
      [110.2689, -7.8109],
      [110.2686, -7.8104],
      [110.2686, -7.8091],
      [110.2686, -7.8079],
      [110.2644, -7.8077],
      [110.2634, -7.8077],
      [110.2619, -7.8076],
      [110.2622, -7.8073],
      [110.2623, -7.8068],
      [110.2621, -7.8065],
      [110.2618, -7.8063]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEDAYU',
    village: 'ARGOSARI',
    border: [
      [110.2678, -7.7958],
      [110.2673, -7.7956],
      [110.2667, -7.7953],
      [110.2653, -7.7946],
      [110.2645, -7.7942],
      [110.2644, -7.7942],
      [110.2642, -7.7941],
      [110.264, -7.7941],
      [110.2636, -7.7942],
      [110.2635, -7.7945],
      [110.2633, -7.7948],
      [110.2633, -7.7953],
      [110.2632, -7.7955],
      [110.2628, -7.7958],
      [110.2624, -7.7962],
      [110.2618, -7.7965],
      [110.2613, -7.7967],
      [110.261, -7.7968],
      [110.2607, -7.7969],
      [110.2606, -7.7972],
      [110.2605, -7.7975],
      [110.2599, -7.7975],
      [110.2596, -7.7976],
      [110.2594, -7.7979],
      [110.2592, -7.7985],
      [110.259, -7.7989],
      [110.2587, -7.7991],
      [110.2581, -7.7994],
      [110.2576, -7.7997],
      [110.2572, -7.7999],
      [110.2569, -7.8003],
      [110.2568, -7.8002],
      [110.2564, -7.8001],
      [110.2561, -7.8],
      [110.2558, -7.8001],
      [110.2557, -7.8001],
      [110.255, -7.8004],
      [110.2548, -7.8007],
      [110.2544, -7.801],
      [110.254, -7.8012],
      [110.2537, -7.8014],
      [110.2536, -7.8014],
      [110.2532, -7.8017],
      [110.2529, -7.8023],
      [110.2525, -7.8026],
      [110.2519, -7.803],
      [110.2515, -7.8033],
      [110.2513, -7.8035],
      [110.2496, -7.8036],
      [110.2481, -7.8037],
      [110.247, -7.8038],
      [110.2462, -7.8039],
      [110.2457, -7.8041],
      [110.2448, -7.8044],
      [110.2438, -7.8048],
      [110.2427, -7.8054],
      [110.2416, -7.8064],
      [110.2407, -7.8061],
      [110.2406, -7.8062],
      [110.2402, -7.8063],
      [110.24, -7.8065],
      [110.2399, -7.8068],
      [110.2396, -7.8069],
      [110.2395, -7.8072],
      [110.2395, -7.8074],
      [110.2392, -7.8078],
      [110.2389, -7.8081],
      [110.2386, -7.8084],
      [110.2384, -7.8085],
      [110.2381, -7.8085],
      [110.2379, -7.8085],
      [110.2378, -7.8087],
      [110.2375, -7.8089],
      [110.2373, -7.809],
      [110.2371, -7.809],
      [110.2369, -7.8092],
      [110.2368, -7.8094],
      [110.2368, -7.8097],
      [110.2366, -7.8101],
      [110.2363, -7.8105],
      [110.2359, -7.8109],
      [110.2359, -7.8112],
      [110.2356, -7.8114],
      [110.2352, -7.8116],
      [110.2351, -7.8119],
      [110.2349, -7.8122],
      [110.2348, -7.8124],
      [110.2345, -7.8126],
      [110.2344, -7.8128],
      [110.2343, -7.813],
      [110.2341, -7.8134],
      [110.2341, -7.8139],
      [110.234, -7.8143],
      [110.2335, -7.8147],
      [110.2332, -7.8149],
      [110.2334, -7.8153],
      [110.2337, -7.8158],
      [110.2341, -7.8165],
      [110.2342, -7.8169],
      [110.2341, -7.8173],
      [110.2339, -7.8181],
      [110.2337, -7.8186],
      [110.2335, -7.8196],
      [110.2335, -7.8198],
      [110.2335, -7.8206],
      [110.2336, -7.8216],
      [110.2337, -7.8224],
      [110.2337, -7.8226],
      [110.2337, -7.8231],
      [110.2335, -7.8238],
      [110.2333, -7.8244],
      [110.2329, -7.8257],
      [110.2324, -7.8268],
      [110.2319, -7.8282],
      [110.2312, -7.8293],
      [110.2307, -7.8301],
      [110.2311, -7.8306],
      [110.2312, -7.8307],
      [110.2315, -7.8311],
      [110.2319, -7.8312],
      [110.232, -7.8315],
      [110.2324, -7.8319],
      [110.233, -7.8322],
      [110.2337, -7.8314],
      [110.2344, -7.8312],
      [110.2346, -7.8311],
      [110.2351, -7.831],
      [110.2353, -7.8306],
      [110.2349, -7.8304],
      [110.235, -7.8294],
      [110.2356, -7.8294],
      [110.2363, -7.8294],
      [110.2368, -7.8291],
      [110.237, -7.8288],
      [110.2371, -7.8281],
      [110.2372, -7.8278],
      [110.2373, -7.8275],
      [110.2375, -7.8271],
      [110.238, -7.8266],
      [110.2381, -7.8259],
      [110.2379, -7.8256],
      [110.2381, -7.825],
      [110.2386, -7.8244],
      [110.239, -7.8237],
      [110.2391, -7.8227],
      [110.2389, -7.8225],
      [110.2389, -7.8215],
      [110.2393, -7.8215],
      [110.2393, -7.821],
      [110.2398, -7.8209],
      [110.2431, -7.8193],
      [110.2436, -7.821],
      [110.2437, -7.8213],
      [110.2429, -7.8232],
      [110.2426, -7.8239],
      [110.2433, -7.8242],
      [110.2433, -7.825],
      [110.2435, -7.8251],
      [110.2437, -7.8252],
      [110.245, -7.8255],
      [110.2468, -7.8257],
      [110.2478, -7.8259],
      [110.2486, -7.8258],
      [110.2492, -7.8256],
      [110.2493, -7.8253],
      [110.249, -7.8249],
      [110.2487, -7.8244],
      [110.2487, -7.824],
      [110.2491, -7.8237],
      [110.2497, -7.8234],
      [110.25, -7.8233],
      [110.2506, -7.8232],
      [110.251, -7.8235],
      [110.2512, -7.8236],
      [110.2514, -7.8233],
      [110.2513, -7.8227],
      [110.2516, -7.8218],
      [110.2518, -7.8217],
      [110.2522, -7.8215],
      [110.2523, -7.8214],
      [110.2527, -7.8216],
      [110.2531, -7.8218],
      [110.2532, -7.822],
      [110.2533, -7.822],
      [110.2534, -7.822],
      [110.2536, -7.8214],
      [110.2537, -7.8206],
      [110.2539, -7.8199],
      [110.2541, -7.8192],
      [110.254, -7.8187],
      [110.2538, -7.8187],
      [110.2535, -7.8188],
      [110.2529, -7.8186],
      [110.2527, -7.8186],
      [110.2525, -7.8186],
      [110.2523, -7.8185],
      [110.2521, -7.8183],
      [110.2522, -7.8179],
      [110.2521, -7.8174],
      [110.2524, -7.8171],
      [110.2528, -7.8169],
      [110.253, -7.8167],
      [110.253, -7.8163],
      [110.253, -7.8151],
      [110.2527, -7.8149],
      [110.2515, -7.8152],
      [110.2513, -7.8151],
      [110.2512, -7.8139],
      [110.2518, -7.8139],
      [110.2519, -7.8133],
      [110.252, -7.8131],
      [110.2531, -7.8135],
      [110.2532, -7.8129],
      [110.2535, -7.8118],
      [110.2537, -7.8113],
      [110.2539, -7.8108],
      [110.2535, -7.8101],
      [110.2536, -7.8099],
      [110.253, -7.809],
      [110.2532, -7.8089],
      [110.2541, -7.8088],
      [110.2544, -7.8086],
      [110.2547, -7.8081],
      [110.2555, -7.8082],
      [110.2558, -7.8082],
      [110.256, -7.8075],
      [110.257, -7.8076],
      [110.257, -7.8072],
      [110.2573, -7.8064],
      [110.2579, -7.8067],
      [110.258, -7.8063],
      [110.2588, -7.8061],
      [110.2594, -7.806],
      [110.26, -7.8063],
      [110.2604, -7.8064],
      [110.2603, -7.8062],
      [110.2601, -7.8058],
      [110.2603, -7.8056],
      [110.2608, -7.8056],
      [110.2608, -7.8057],
      [110.2609, -7.8066],
      [110.2612, -7.8066],
      [110.2618, -7.8063],
      [110.2619, -7.8057],
      [110.262, -7.8051],
      [110.262, -7.8046],
      [110.2623, -7.8042],
      [110.2622, -7.8034],
      [110.2622, -7.8027],
      [110.2626, -7.8014],
      [110.2635, -7.8008],
      [110.2636, -7.8007],
      [110.2638, -7.8006],
      [110.2643, -7.8002],
      [110.2648, -7.7993],
      [110.2651, -7.7988],
      [110.266, -7.7979],
      [110.2665, -7.7975],
      [110.2673, -7.797],
      [110.2677, -7.7959],
      [110.2678, -7.7958]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'BANTUL',
    sub_district: 'SEDAYU',
    village: 'ARGOMULYO',
    border: [
      [110.2862, -7.7847],
      [110.286, -7.7847],
      [110.2858, -7.785],
      [110.2857, -7.7853],
      [110.2856, -7.7858],
      [110.2851, -7.7859],
      [110.2848, -7.7859],
      [110.2842, -7.786],
      [110.284, -7.7864],
      [110.2836, -7.7866],
      [110.2831, -7.7869],
      [110.2817, -7.7867],
      [110.2806, -7.7865],
      [110.2802, -7.7864],
      [110.2798, -7.7863],
      [110.2794, -7.7861],
      [110.2789, -7.7861],
      [110.2786, -7.7863],
      [110.2778, -7.7874],
      [110.2773, -7.788],
      [110.2771, -7.7883],
      [110.2771, -7.7887],
      [110.2768, -7.7892],
      [110.2767, -7.7894],
      [110.2765, -7.7903],
      [110.2758, -7.7916],
      [110.2757, -7.7918],
      [110.2744, -7.7914],
      [110.2735, -7.7931],
      [110.2733, -7.7937],
      [110.2718, -7.7935],
      [110.2717, -7.7933],
      [110.2715, -7.7932],
      [110.2713, -7.7931],
      [110.271, -7.7933],
      [110.2709, -7.7933],
      [110.2705, -7.7932],
      [110.2703, -7.7934],
      [110.2702, -7.7936],
      [110.2702, -7.7938],
      [110.27, -7.794],
      [110.2697, -7.7939],
      [110.2692, -7.794],
      [110.2689, -7.7941],
      [110.2687, -7.7943],
      [110.2686, -7.7946],
      [110.2684, -7.7948],
      [110.2684, -7.795],
      [110.2684, -7.7953],
      [110.2683, -7.7955],
      [110.268, -7.7957],
      [110.2678, -7.7958],
      [110.2677, -7.7959],
      [110.2673, -7.797],
      [110.2665, -7.7975],
      [110.266, -7.7979],
      [110.2651, -7.7988],
      [110.2648, -7.7993],
      [110.2643, -7.8002],
      [110.2638, -7.8006],
      [110.2636, -7.8007],
      [110.2635, -7.8008],
      [110.2626, -7.8014],
      [110.2622, -7.8027],
      [110.2622, -7.8034],
      [110.2623, -7.8042],
      [110.262, -7.8046],
      [110.262, -7.8051],
      [110.2619, -7.8057],
      [110.2618, -7.8063],
      [110.2621, -7.8065],
      [110.2623, -7.8068],
      [110.2622, -7.8073],
      [110.2619, -7.8076],
      [110.2634, -7.8077],
      [110.2644, -7.8077],
      [110.2686, -7.8079],
      [110.2686, -7.8091],
      [110.2686, -7.8104],
      [110.2689, -7.8109],
      [110.2686, -7.8115],
      [110.2683, -7.8124],
      [110.2691, -7.8124],
      [110.2693, -7.8124],
      [110.2683, -7.8147],
      [110.2677, -7.8161],
      [110.2678, -7.8165],
      [110.2683, -7.8167],
      [110.2684, -7.817],
      [110.2685, -7.8174],
      [110.2683, -7.8175],
      [110.2682, -7.8177],
      [110.268, -7.8181],
      [110.2679, -7.8184],
      [110.2679, -7.8185],
      [110.2679, -7.819],
      [110.2681, -7.8194],
      [110.2686, -7.8197],
      [110.269, -7.82],
      [110.2694, -7.8197],
      [110.2697, -7.8189],
      [110.2711, -7.8195],
      [110.2707, -7.8203],
      [110.2712, -7.8217],
      [110.272, -7.8223],
      [110.2724, -7.8226],
      [110.2724, -7.8228],
      [110.2723, -7.8232],
      [110.2729, -7.8238],
      [110.2733, -7.8238],
      [110.2735, -7.8238],
      [110.2737, -7.8239],
      [110.2739, -7.8242],
      [110.274, -7.8247],
      [110.2743, -7.8248],
      [110.2746, -7.8251],
      [110.2747, -7.8253],
      [110.2746, -7.8255],
      [110.2747, -7.8256],
      [110.2752, -7.8258],
      [110.2753, -7.826],
      [110.2752, -7.8266],
      [110.2752, -7.8269],
      [110.2755, -7.8269],
      [110.2757, -7.8269],
      [110.2757, -7.8272],
      [110.2755, -7.8273],
      [110.2755, -7.8277],
      [110.2756, -7.8277],
      [110.2758, -7.8279],
      [110.2763, -7.8277],
      [110.2763, -7.8281],
      [110.2761, -7.8286],
      [110.2754, -7.8297],
      [110.2752, -7.8315],
      [110.2751, -7.833],
      [110.2758, -7.834],
      [110.2763, -7.8344],
      [110.2771, -7.8347],
      [110.2774, -7.8352],
      [110.2783, -7.8357],
      [110.2784, -7.8352],
      [110.2785, -7.8345],
      [110.2788, -7.8342],
      [110.2792, -7.8343],
      [110.2796, -7.8342],
      [110.2799, -7.8347],
      [110.2805, -7.8348],
      [110.2812, -7.8351],
      [110.2817, -7.8348],
      [110.282, -7.8343],
      [110.2824, -7.8342],
      [110.283, -7.8343],
      [110.2836, -7.8344],
      [110.2838, -7.8345],
      [110.2841, -7.8348],
      [110.2849, -7.8347],
      [110.2854, -7.8346],
      [110.2863, -7.8345],
      [110.2872, -7.8343],
      [110.2879, -7.834],
      [110.2885, -7.8336],
      [110.2883, -7.8333],
      [110.2881, -7.8328],
      [110.2878, -7.8321],
      [110.2876, -7.8316],
      [110.2875, -7.8311],
      [110.2876, -7.8307],
      [110.2872, -7.8306],
      [110.2865, -7.8301],
      [110.2859, -7.8299],
      [110.2852, -7.8297],
      [110.2851, -7.8297],
      [110.2852, -7.829],
      [110.2853, -7.8286],
      [110.2854, -7.8281],
      [110.2857, -7.8277],
      [110.2861, -7.8272],
      [110.2864, -7.827],
      [110.2867, -7.8268],
      [110.2871, -7.8265],
      [110.2872, -7.826],
      [110.2871, -7.8254],
      [110.2872, -7.825],
      [110.2874, -7.8246],
      [110.2875, -7.8242],
      [110.2876, -7.8236],
      [110.2876, -7.8232],
      [110.2876, -7.8227],
      [110.2876, -7.8222],
      [110.2875, -7.822],
      [110.2874, -7.8217],
      [110.2873, -7.8214],
      [110.287, -7.8211],
      [110.287, -7.8207],
      [110.2866, -7.8203],
      [110.2865, -7.8202],
      [110.2862, -7.82],
      [110.2859, -7.8199],
      [110.2858, -7.8196],
      [110.2858, -7.8193],
      [110.286, -7.819],
      [110.2859, -7.8189],
      [110.2857, -7.8187],
      [110.2854, -7.8183],
      [110.2851, -7.8179],
      [110.2851, -7.8179],
      [110.284, -7.8178],
      [110.2831, -7.8175],
      [110.2828, -7.8174],
      [110.2825, -7.8173],
      [110.2824, -7.8173],
      [110.282, -7.8171],
      [110.2822, -7.8168],
      [110.2824, -7.8164],
      [110.2826, -7.8162],
      [110.2828, -7.816],
      [110.2829, -7.8156],
      [110.2831, -7.8151],
      [110.2832, -7.8145],
      [110.2834, -7.8141],
      [110.2835, -7.8139],
      [110.2837, -7.8135],
      [110.2836, -7.8132],
      [110.2834, -7.8129],
      [110.2831, -7.8126],
      [110.2832, -7.8123],
      [110.2832, -7.8117],
      [110.2832, -7.8111],
      [110.2831, -7.8106],
      [110.2831, -7.8101],
      [110.283, -7.8097],
      [110.2831, -7.8092],
      [110.283, -7.8088],
      [110.2827, -7.8086],
      [110.2826, -7.8085],
      [110.2827, -7.8081],
      [110.2827, -7.8078],
      [110.2827, -7.8076],
      [110.2824, -7.8076],
      [110.282, -7.8076],
      [110.2818, -7.8075],
      [110.2817, -7.8075],
      [110.2816, -7.8073],
      [110.2815, -7.8072],
      [110.2815, -7.8071],
      [110.2815, -7.8068],
      [110.2819, -7.8065],
      [110.2822, -7.8063],
      [110.2825, -7.8061],
      [110.2827, -7.806],
      [110.283, -7.8057],
      [110.2833, -7.8055],
      [110.2837, -7.8052],
      [110.2841, -7.8052],
      [110.2844, -7.8052],
      [110.2846, -7.8052],
      [110.2849, -7.8054],
      [110.2851, -7.8052],
      [110.2853, -7.8047],
      [110.2854, -7.8045],
      [110.2858, -7.8044],
      [110.2862, -7.8043],
      [110.2862, -7.8041],
      [110.2861, -7.8038],
      [110.2864, -7.8036],
      [110.2865, -7.8033],
      [110.2863, -7.8029],
      [110.2861, -7.8027],
      [110.2859, -7.8024],
      [110.2859, -7.8021],
      [110.2861, -7.8019],
      [110.2863, -7.8018],
      [110.2864, -7.8015],
      [110.2865, -7.8012],
      [110.2865, -7.8008],
      [110.2865, -7.8006],
      [110.2867, -7.8004],
      [110.2869, -7.8001],
      [110.2871, -7.8001],
      [110.2875, -7.8],
      [110.2877, -7.7995],
      [110.288, -7.799],
      [110.2883, -7.7988],
      [110.2887, -7.7986],
      [110.2891, -7.7983],
      [110.2895, -7.7981],
      [110.2895, -7.7978],
      [110.2894, -7.7977],
      [110.2892, -7.7975],
      [110.289, -7.7974],
      [110.289, -7.797],
      [110.289, -7.7969],
      [110.289, -7.7966],
      [110.2892, -7.7963],
      [110.2894, -7.7958],
      [110.2895, -7.7953],
      [110.2895, -7.7949],
      [110.2897, -7.7946],
      [110.29, -7.7943],
      [110.2902, -7.794],
      [110.2902, -7.7938],
      [110.2897, -7.7934],
      [110.2887, -7.7926],
      [110.2878, -7.7918],
      [110.2877, -7.7917],
      [110.2872, -7.7912],
      [110.2867, -7.7906],
      [110.2863, -7.7901],
      [110.2864, -7.7894],
      [110.2865, -7.7883],
      [110.2865, -7.7882],
      [110.2862, -7.7879],
      [110.2871, -7.7876],
      [110.2882, -7.7873],
      [110.2884, -7.7871],
      [110.2884, -7.7865],
      [110.2887, -7.7856],
      [110.2882, -7.7854],
      [110.2871, -7.7852],
      [110.2866, -7.785],
      [110.2866, -7.7848],
      [110.2862, -7.7847]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'WONOSARI',
    border: [
      [110.615, -7.9521],
      [110.6146, -7.9522],
      [110.6139, -7.9523],
      [110.6126, -7.9521],
      [110.6109, -7.9517],
      [110.6098, -7.9517],
      [110.6089, -7.9521],
      [110.6085, -7.952],
      [110.6078, -7.9517],
      [110.6072, -7.9512],
      [110.6065, -7.9509],
      [110.6054, -7.9508],
      [110.6039, -7.9512],
      [110.6022, -7.9518],
      [110.6017, -7.9519],
      [110.6003, -7.9523],
      [110.5995, -7.9524],
      [110.5985, -7.9525],
      [110.5981, -7.9524],
      [110.5955, -7.9514],
      [110.5951, -7.9514],
      [110.595, -7.9515],
      [110.5948, -7.9517],
      [110.5948, -7.9522],
      [110.5951, -7.9527],
      [110.5956, -7.9531],
      [110.5956, -7.9546],
      [110.5959, -7.9548],
      [110.5963, -7.9546],
      [110.5963, -7.955],
      [110.5964, -7.9554],
      [110.5968, -7.9555],
      [110.597, -7.9557],
      [110.597, -7.9562],
      [110.5971, -7.9565],
      [110.5977, -7.9571],
      [110.5984, -7.9572],
      [110.5992, -7.9574],
      [110.6007, -7.9577],
      [110.6033, -7.9588],
      [110.6031, -7.9595],
      [110.6029, -7.9605],
      [110.6025, -7.9622],
      [110.602, -7.9623],
      [110.6007, -7.9614],
      [110.6003, -7.9618],
      [110.6003, -7.963],
      [110.6004, -7.9636],
      [110.6005, -7.964],
      [110.6006, -7.9644],
      [110.5998, -7.9652],
      [110.6004, -7.9657],
      [110.5996, -7.9664],
      [110.5982, -7.9677],
      [110.5978, -7.9685],
      [110.5977, -7.9688],
      [110.5985, -7.969],
      [110.5991, -7.9693],
      [110.5993, -7.9697],
      [110.5993, -7.9704],
      [110.5993, -7.9711],
      [110.5993, -7.9715],
      [110.6007, -7.9713],
      [110.6009, -7.9717],
      [110.6013, -7.9724],
      [110.6015, -7.9724],
      [110.6019, -7.9722],
      [110.6029, -7.9719],
      [110.6032, -7.9712],
      [110.6036, -7.9708],
      [110.6044, -7.9701],
      [110.6057, -7.9691],
      [110.6061, -7.969],
      [110.6069, -7.9692],
      [110.6072, -7.969],
      [110.6076, -7.9692],
      [110.608, -7.9694],
      [110.6088, -7.9695],
      [110.609, -7.9692],
      [110.6091, -7.9691],
      [110.6095, -7.9688],
      [110.6104, -7.9683],
      [110.6112, -7.9682],
      [110.612, -7.9685],
      [110.6128, -7.9688],
      [110.613, -7.9687],
      [110.6131, -7.9685],
      [110.613, -7.9683],
      [110.613, -7.9682],
      [110.6129, -7.9679],
      [110.6129, -7.9674],
      [110.6131, -7.9671],
      [110.6135, -7.9666],
      [110.6133, -7.9662],
      [110.6137, -7.9654],
      [110.6142, -7.9644],
      [110.6145, -7.9636],
      [110.6148, -7.9631],
      [110.6149, -7.963],
      [110.6152, -7.9623],
      [110.6156, -7.9616],
      [110.616, -7.9613],
      [110.6161, -7.9606],
      [110.6167, -7.9599],
      [110.6168, -7.9593],
      [110.6169, -7.9586],
      [110.617, -7.958],
      [110.617, -7.9575],
      [110.6173, -7.9571],
      [110.6177, -7.9561],
      [110.6179, -7.9547],
      [110.6179, -7.9543],
      [110.618, -7.9526],
      [110.618, -7.9524],
      [110.6177, -7.9523],
      [110.6174, -7.9519],
      [110.6165, -7.952],
      [110.6159, -7.9522],
      [110.6153, -7.9522],
      [110.615, -7.9521]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'KEPEK',
    border: [
      [110.6013, -7.9724],
      [110.6009, -7.9717],
      [110.6007, -7.9713],
      [110.5993, -7.9715],
      [110.5993, -7.9711],
      [110.5993, -7.9704],
      [110.5993, -7.9697],
      [110.5991, -7.9693],
      [110.5985, -7.969],
      [110.5977, -7.9688],
      [110.5978, -7.9685],
      [110.5982, -7.9677],
      [110.5996, -7.9664],
      [110.6004, -7.9657],
      [110.5998, -7.9652],
      [110.6006, -7.9644],
      [110.6005, -7.964],
      [110.6004, -7.9636],
      [110.6003, -7.963],
      [110.6003, -7.9618],
      [110.6007, -7.9614],
      [110.602, -7.9623],
      [110.6025, -7.9622],
      [110.6029, -7.9605],
      [110.6031, -7.9595],
      [110.6033, -7.9588],
      [110.6007, -7.9577],
      [110.5992, -7.9574],
      [110.5984, -7.9572],
      [110.5977, -7.9571],
      [110.5971, -7.9565],
      [110.597, -7.9562],
      [110.597, -7.9557],
      [110.5968, -7.9555],
      [110.5964, -7.9554],
      [110.5963, -7.955],
      [110.5963, -7.9546],
      [110.5959, -7.9548],
      [110.5956, -7.9546],
      [110.5956, -7.9531],
      [110.5951, -7.9527],
      [110.5948, -7.9522],
      [110.5948, -7.9517],
      [110.5938, -7.9517],
      [110.592, -7.9517],
      [110.5906, -7.9519],
      [110.5893, -7.9523],
      [110.5891, -7.9525],
      [110.5887, -7.9532],
      [110.5882, -7.9536],
      [110.5878, -7.9539],
      [110.5876, -7.9544],
      [110.5873, -7.9548],
      [110.587, -7.9552],
      [110.5864, -7.9553],
      [110.5861, -7.9552],
      [110.5861, -7.9555],
      [110.5857, -7.9563],
      [110.5854, -7.9569],
      [110.584, -7.9587],
      [110.5838, -7.9592],
      [110.5836, -7.9601],
      [110.5832, -7.9606],
      [110.5824, -7.9612],
      [110.5822, -7.9613],
      [110.5817, -7.9621],
      [110.5814, -7.9625],
      [110.5812, -7.9629],
      [110.5812, -7.9635],
      [110.5809, -7.9638],
      [110.5807, -7.9642],
      [110.5806, -7.9648],
      [110.5803, -7.9652],
      [110.5799, -7.9655],
      [110.5794, -7.9656],
      [110.5795, -7.9658],
      [110.58, -7.966],
      [110.5805, -7.9661],
      [110.5812, -7.9661],
      [110.5825, -7.9664],
      [110.5832, -7.9666],
      [110.5837, -7.9665],
      [110.584, -7.9662],
      [110.5844, -7.9661],
      [110.5849, -7.9662],
      [110.5852, -7.9666],
      [110.5855, -7.9669],
      [110.5858, -7.9669],
      [110.5856, -7.9672],
      [110.5857, -7.9681],
      [110.5866, -7.9686],
      [110.5866, -7.9698],
      [110.5869, -7.9698],
      [110.5891, -7.97],
      [110.5897, -7.9703],
      [110.5897, -7.9706],
      [110.5898, -7.9707],
      [110.5901, -7.9708],
      [110.5902, -7.9709],
      [110.5903, -7.9712],
      [110.5905, -7.9714],
      [110.5908, -7.9714],
      [110.5911, -7.9714],
      [110.5915, -7.9711],
      [110.5917, -7.9714],
      [110.5919, -7.9717],
      [110.5921, -7.9719],
      [110.5925, -7.9724],
      [110.593, -7.9725],
      [110.5934, -7.9726],
      [110.5935, -7.9727],
      [110.5937, -7.9729],
      [110.5938, -7.9729],
      [110.5939, -7.9728],
      [110.5941, -7.9724],
      [110.5942, -7.9722],
      [110.5944, -7.9723],
      [110.5947, -7.9726],
      [110.5949, -7.9727],
      [110.5951, -7.9727],
      [110.5953, -7.9727],
      [110.5954, -7.9727],
      [110.5957, -7.9727],
      [110.596, -7.9728],
      [110.5965, -7.9727],
      [110.5969, -7.9728],
      [110.5973, -7.9724],
      [110.5976, -7.9724],
      [110.5982, -7.9724],
      [110.5987, -7.9726],
      [110.5989, -7.9728],
      [110.5997, -7.9724],
      [110.5998, -7.9723],
      [110.6001, -7.9722],
      [110.6004, -7.9723],
      [110.601, -7.9726],
      [110.6013, -7.9724]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'PIYAMAN',
    border: [
      [110.6015, -7.931],
      [110.5993, -7.9294],
      [110.5982, -7.9286],
      [110.5967, -7.9283],
      [110.5944, -7.928],
      [110.5942, -7.9279],
      [110.5938, -7.9278],
      [110.5933, -7.9273],
      [110.5927, -7.9269],
      [110.5922, -7.9269],
      [110.5913, -7.9271],
      [110.5901, -7.9273],
      [110.5885, -7.9275],
      [110.5873, -7.9276],
      [110.5853, -7.9269],
      [110.5837, -7.9265],
      [110.5837, -7.9267],
      [110.5838, -7.9273],
      [110.584, -7.9288],
      [110.584, -7.9304],
      [110.5841, -7.9316],
      [110.5839, -7.9323],
      [110.5839, -7.9332],
      [110.5838, -7.9356],
      [110.5831, -7.9356],
      [110.583, -7.9364],
      [110.5831, -7.9371],
      [110.5835, -7.9373],
      [110.5835, -7.9379],
      [110.5834, -7.9383],
      [110.5833, -7.9388],
      [110.5832, -7.939],
      [110.5828, -7.94],
      [110.5823, -7.941],
      [110.5822, -7.9412],
      [110.5824, -7.9415],
      [110.5828, -7.9418],
      [110.5831, -7.9422],
      [110.5837, -7.9421],
      [110.584, -7.9425],
      [110.5844, -7.9427],
      [110.5849, -7.9427],
      [110.5853, -7.9429],
      [110.5862, -7.9435],
      [110.5873, -7.9452],
      [110.5889, -7.9481],
      [110.5891, -7.9489],
      [110.5896, -7.9515],
      [110.5893, -7.9522],
      [110.5893, -7.9523],
      [110.5906, -7.9519],
      [110.592, -7.9517],
      [110.5938, -7.9517],
      [110.5948, -7.9517],
      [110.595, -7.9515],
      [110.5951, -7.9514],
      [110.5955, -7.9514],
      [110.5981, -7.9524],
      [110.5985, -7.9525],
      [110.5995, -7.9524],
      [110.6003, -7.9523],
      [110.6017, -7.9519],
      [110.6022, -7.9518],
      [110.6039, -7.9512],
      [110.6054, -7.9508],
      [110.6065, -7.9509],
      [110.6072, -7.9512],
      [110.6078, -7.9517],
      [110.6085, -7.952],
      [110.6089, -7.9521],
      [110.6098, -7.9517],
      [110.6109, -7.9517],
      [110.6126, -7.9521],
      [110.6139, -7.9523],
      [110.6146, -7.9522],
      [110.615, -7.9521],
      [110.6146, -7.9515],
      [110.6143, -7.9506],
      [110.6134, -7.9492],
      [110.6127, -7.9483],
      [110.611, -7.9464],
      [110.6098, -7.9448],
      [110.6095, -7.9445],
      [110.6094, -7.9439],
      [110.6097, -7.9427],
      [110.6096, -7.9422],
      [110.6089, -7.9419],
      [110.6083, -7.9409],
      [110.6078, -7.9405],
      [110.6061, -7.9393],
      [110.6058, -7.9391],
      [110.6056, -7.9382],
      [110.6057, -7.9372],
      [110.6056, -7.936],
      [110.6057, -7.9357],
      [110.6059, -7.9351],
      [110.6061, -7.9345],
      [110.6059, -7.9345],
      [110.6049, -7.9346],
      [110.6041, -7.9346],
      [110.6039, -7.9339],
      [110.6038, -7.9337],
      [110.6029, -7.9335],
      [110.602, -7.9335],
      [110.6016, -7.9334],
      [110.6014, -7.9328],
      [110.6016, -7.9314],
      [110.6015, -7.931]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'GARI',
    border: [
      [110.6057, -7.9086],
      [110.6056, -7.9085],
      [110.6054, -7.9083],
      [110.6048, -7.9082],
      [110.604, -7.9084],
      [110.6033, -7.9084],
      [110.6027, -7.9089],
      [110.6026, -7.9089],
      [110.6025, -7.909],
      [110.6016, -7.9093],
      [110.6007, -7.9093],
      [110.6004, -7.9093],
      [110.5991, -7.909],
      [110.5984, -7.9083],
      [110.5975, -7.9075],
      [110.5966, -7.9073],
      [110.5961, -7.9074],
      [110.5958, -7.9074],
      [110.5958, -7.9074],
      [110.5955, -7.9073],
      [110.5947, -7.9071],
      [110.5936, -7.9066],
      [110.5928, -7.9061],
      [110.5921, -7.9055],
      [110.5916, -7.9051],
      [110.5911, -7.9048],
      [110.5908, -7.9045],
      [110.5907, -7.9044],
      [110.5906, -7.9043],
      [110.5901, -7.9036],
      [110.59, -7.9035],
      [110.5893, -7.9029],
      [110.5885, -7.9023],
      [110.5881, -7.902],
      [110.5881, -7.9016],
      [110.5883, -7.9011],
      [110.5883, -7.9007],
      [110.5883, -7.9005],
      [110.5882, -7.9003],
      [110.5877, -7.9002],
      [110.5872, -7.9004],
      [110.5871, -7.9004],
      [110.5866, -7.9009],
      [110.5862, -7.9016],
      [110.586, -7.9019],
      [110.5857, -7.9022],
      [110.5856, -7.9023],
      [110.5855, -7.9023],
      [110.5857, -7.9028],
      [110.5856, -7.9032],
      [110.5851, -7.9043],
      [110.5847, -7.9055],
      [110.5844, -7.9058],
      [110.5844, -7.9061],
      [110.5841, -7.9072],
      [110.584, -7.9077],
      [110.5836, -7.9082],
      [110.5834, -7.9089],
      [110.5833, -7.9093],
      [110.5828, -7.9095],
      [110.5823, -7.9095],
      [110.5819, -7.9096],
      [110.5818, -7.9096],
      [110.5816, -7.9097],
      [110.5814, -7.9098],
      [110.5812, -7.9101],
      [110.581, -7.9108],
      [110.5804, -7.9108],
      [110.5803, -7.9118],
      [110.5801, -7.9128],
      [110.58, -7.913],
      [110.5794, -7.9131],
      [110.5792, -7.9132],
      [110.5796, -7.9139],
      [110.5799, -7.9147],
      [110.5804, -7.9154],
      [110.5808, -7.9157],
      [110.5813, -7.9159],
      [110.5814, -7.9167],
      [110.5813, -7.9168],
      [110.5812, -7.9171],
      [110.5814, -7.9181],
      [110.5816, -7.9188],
      [110.5821, -7.9197],
      [110.5825, -7.9205],
      [110.583, -7.9211],
      [110.5832, -7.9221],
      [110.5832, -7.9235],
      [110.5836, -7.9247],
      [110.5837, -7.9254],
      [110.5837, -7.9265],
      [110.5853, -7.9269],
      [110.5873, -7.9276],
      [110.5885, -7.9275],
      [110.5901, -7.9273],
      [110.5913, -7.9271],
      [110.5922, -7.9269],
      [110.5927, -7.9269],
      [110.5933, -7.9273],
      [110.5938, -7.9278],
      [110.5942, -7.9279],
      [110.5944, -7.928],
      [110.5967, -7.9283],
      [110.5982, -7.9286],
      [110.5993, -7.9294],
      [110.6015, -7.931],
      [110.6018, -7.9309],
      [110.6025, -7.9309],
      [110.6028, -7.9308],
      [110.6026, -7.9305],
      [110.6028, -7.9302],
      [110.6041, -7.9305],
      [110.6065, -7.931],
      [110.6068, -7.9309],
      [110.6082, -7.9287],
      [110.6095, -7.9261],
      [110.61, -7.9247],
      [110.6097, -7.9236],
      [110.6094, -7.9227],
      [110.6086, -7.9224],
      [110.6075, -7.9224],
      [110.6071, -7.9226],
      [110.606, -7.9231],
      [110.6047, -7.9231],
      [110.6044, -7.9231],
      [110.6029, -7.9229],
      [110.6014, -7.9224],
      [110.5999, -7.9213],
      [110.5996, -7.9199],
      [110.5997, -7.9183],
      [110.6002, -7.9175],
      [110.6012, -7.9164],
      [110.6021, -7.916],
      [110.6033, -7.9154],
      [110.6037, -7.9149],
      [110.604, -7.9142],
      [110.604, -7.9132],
      [110.604, -7.913],
      [110.6039, -7.9124],
      [110.6041, -7.9122],
      [110.6046, -7.9118],
      [110.6048, -7.9117],
      [110.605, -7.9115],
      [110.6053, -7.9106],
      [110.6053, -7.91],
      [110.6055, -7.9096],
      [110.6055, -7.9089],
      [110.6057, -7.9086]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'KARANGTENGAH',
    border: [
      [110.6209, -7.9165],
      [110.6203, -7.9167],
      [110.6197, -7.9167],
      [110.6191, -7.9165],
      [110.6175, -7.9161],
      [110.6171, -7.9159],
      [110.6162, -7.9163],
      [110.6158, -7.9164],
      [110.6156, -7.9165],
      [110.6149, -7.9161],
      [110.6146, -7.916],
      [110.614, -7.9157],
      [110.6137, -7.9155],
      [110.613, -7.915],
      [110.6126, -7.9147],
      [110.6124, -7.9145],
      [110.6117, -7.914],
      [110.6117, -7.914],
      [110.6113, -7.9132],
      [110.6112, -7.913],
      [110.6108, -7.9127],
      [110.6103, -7.9127],
      [110.6102, -7.9126],
      [110.6099, -7.9125],
      [110.6096, -7.912],
      [110.6093, -7.9113],
      [110.6087, -7.9108],
      [110.6082, -7.9103],
      [110.6078, -7.9093],
      [110.6077, -7.9092],
      [110.6075, -7.9089],
      [110.6072, -7.9088],
      [110.6066, -7.9089],
      [110.6061, -7.9089],
      [110.6057, -7.9086],
      [110.6055, -7.9089],
      [110.6055, -7.9096],
      [110.6053, -7.91],
      [110.6053, -7.9106],
      [110.605, -7.9115],
      [110.6048, -7.9117],
      [110.6046, -7.9118],
      [110.6041, -7.9122],
      [110.6039, -7.9124],
      [110.604, -7.913],
      [110.604, -7.9132],
      [110.604, -7.9142],
      [110.6037, -7.9149],
      [110.6033, -7.9154],
      [110.6021, -7.916],
      [110.6012, -7.9164],
      [110.6002, -7.9175],
      [110.5997, -7.9183],
      [110.5996, -7.9199],
      [110.5999, -7.9213],
      [110.6014, -7.9224],
      [110.6029, -7.9229],
      [110.6044, -7.9231],
      [110.6047, -7.9231],
      [110.606, -7.9231],
      [110.6071, -7.9226],
      [110.6075, -7.9224],
      [110.6086, -7.9224],
      [110.6094, -7.9227],
      [110.6097, -7.9236],
      [110.61, -7.9247],
      [110.6095, -7.9261],
      [110.6082, -7.9287],
      [110.6068, -7.9309],
      [110.6065, -7.931],
      [110.6041, -7.9305],
      [110.6028, -7.9302],
      [110.6026, -7.9305],
      [110.6028, -7.9308],
      [110.6025, -7.9309],
      [110.6018, -7.9309],
      [110.6015, -7.931],
      [110.6016, -7.9314],
      [110.6014, -7.9328],
      [110.6016, -7.9334],
      [110.602, -7.9335],
      [110.6029, -7.9335],
      [110.6038, -7.9337],
      [110.6039, -7.9339],
      [110.6041, -7.9346],
      [110.6049, -7.9346],
      [110.6059, -7.9345],
      [110.6061, -7.9345],
      [110.6059, -7.9351],
      [110.6057, -7.9357],
      [110.6056, -7.936],
      [110.6057, -7.9372],
      [110.6056, -7.9382],
      [110.6058, -7.9391],
      [110.6061, -7.9393],
      [110.6078, -7.9405],
      [110.6083, -7.9409],
      [110.6089, -7.9419],
      [110.6096, -7.9422],
      [110.6097, -7.9427],
      [110.6094, -7.9439],
      [110.6095, -7.9445],
      [110.6098, -7.9448],
      [110.611, -7.9464],
      [110.6127, -7.9483],
      [110.6134, -7.9492],
      [110.6143, -7.9506],
      [110.6146, -7.9515],
      [110.615, -7.9521],
      [110.6154, -7.9506],
      [110.6155, -7.95],
      [110.6156, -7.9496],
      [110.6159, -7.9494],
      [110.6159, -7.9486],
      [110.6164, -7.9484],
      [110.6169, -7.9481],
      [110.6169, -7.9471],
      [110.6169, -7.9458],
      [110.6167, -7.945],
      [110.6168, -7.9445],
      [110.6169, -7.9435],
      [110.6167, -7.9406],
      [110.6166, -7.9385],
      [110.6168, -7.937],
      [110.6171, -7.9355],
      [110.6176, -7.9346],
      [110.6183, -7.9336],
      [110.6187, -7.9323],
      [110.6189, -7.9311],
      [110.6191, -7.9304],
      [110.6192, -7.9301],
      [110.6196, -7.93],
      [110.6207, -7.9303],
      [110.6208, -7.9294],
      [110.6211, -7.9283],
      [110.6213, -7.927],
      [110.6215, -7.926],
      [110.6213, -7.9254],
      [110.6207, -7.9249],
      [110.6205, -7.9243],
      [110.6208, -7.9235],
      [110.621, -7.9232],
      [110.6209, -7.9228],
      [110.6207, -7.9222],
      [110.6205, -7.9213],
      [110.6206, -7.9206],
      [110.6208, -7.9201],
      [110.6207, -7.9195],
      [110.6212, -7.9189],
      [110.6209, -7.9185],
      [110.6209, -7.9181],
      [110.6212, -7.9177],
      [110.6211, -7.917],
      [110.6209, -7.9165]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'SELANG',
    border: [
      [110.6241, -7.96],
      [110.624, -7.9599],
      [110.6233, -7.9595],
      [110.6221, -7.9591],
      [110.6217, -7.9586],
      [110.6217, -7.9584],
      [110.6217, -7.9582],
      [110.6218, -7.9575],
      [110.622, -7.9567],
      [110.6221, -7.9563],
      [110.6217, -7.9557],
      [110.6215, -7.9553],
      [110.6216, -7.9548],
      [110.6218, -7.9541],
      [110.622, -7.9532],
      [110.621, -7.9529],
      [110.6203, -7.9526],
      [110.6195, -7.9527],
      [110.6185, -7.9527],
      [110.618, -7.9524],
      [110.618, -7.9526],
      [110.6179, -7.9543],
      [110.6179, -7.9547],
      [110.6177, -7.9561],
      [110.6173, -7.9571],
      [110.617, -7.9575],
      [110.617, -7.958],
      [110.6169, -7.9586],
      [110.6168, -7.9593],
      [110.6167, -7.9599],
      [110.6161, -7.9606],
      [110.616, -7.9613],
      [110.6156, -7.9616],
      [110.6152, -7.9623],
      [110.6149, -7.963],
      [110.6148, -7.9631],
      [110.6145, -7.9636],
      [110.6142, -7.9644],
      [110.6137, -7.9654],
      [110.6133, -7.9662],
      [110.6135, -7.9666],
      [110.6131, -7.9671],
      [110.6129, -7.9674],
      [110.6129, -7.9679],
      [110.613, -7.9682],
      [110.613, -7.9683],
      [110.6131, -7.9685],
      [110.6136, -7.9686],
      [110.6138, -7.9687],
      [110.6143, -7.9687],
      [110.6148, -7.9687],
      [110.6151, -7.9692],
      [110.6155, -7.9698],
      [110.6159, -7.9701],
      [110.6162, -7.9705],
      [110.6165, -7.9707],
      [110.6169, -7.9717],
      [110.6174, -7.9719],
      [110.6182, -7.972],
      [110.6183, -7.972],
      [110.6187, -7.9725],
      [110.6191, -7.9727],
      [110.6202, -7.973],
      [110.6207, -7.9732],
      [110.6208, -7.9732],
      [110.6211, -7.9731],
      [110.6213, -7.9727],
      [110.6216, -7.9726],
      [110.6219, -7.9725],
      [110.6223, -7.9727],
      [110.6226, -7.9727],
      [110.6231, -7.9731],
      [110.6234, -7.9733],
      [110.6226, -7.9742],
      [110.6224, -7.9745],
      [110.6222, -7.975],
      [110.6222, -7.9759],
      [110.6222, -7.9773],
      [110.6222, -7.9802],
      [110.6223, -7.9821],
      [110.6227, -7.9831],
      [110.6232, -7.9841],
      [110.6234, -7.9848],
      [110.6236, -7.9853],
      [110.6262, -7.9865],
      [110.6266, -7.9855],
      [110.6269, -7.9843],
      [110.6272, -7.9837],
      [110.6273, -7.9836],
      [110.6275, -7.9833],
      [110.6281, -7.9832],
      [110.6283, -7.9826],
      [110.6292, -7.9824],
      [110.63, -7.982],
      [110.6301, -7.9821],
      [110.6302, -7.981],
      [110.6303, -7.9801],
      [110.6302, -7.9795],
      [110.6303, -7.9785],
      [110.6304, -7.9776],
      [110.6299, -7.9774],
      [110.6298, -7.9762],
      [110.6299, -7.9757],
      [110.6299, -7.9745],
      [110.6303, -7.9745],
      [110.6305, -7.9734],
      [110.63, -7.9728],
      [110.6293, -7.9722],
      [110.6289, -7.9719],
      [110.6287, -7.9712],
      [110.6287, -7.9711],
      [110.6286, -7.9705],
      [110.6285, -7.9703],
      [110.6286, -7.9698],
      [110.6287, -7.9695],
      [110.6287, -7.9687],
      [110.6272, -7.9689],
      [110.626, -7.9693],
      [110.6257, -7.9686],
      [110.6251, -7.9669],
      [110.6239, -7.9661],
      [110.6237, -7.9659],
      [110.6234, -7.9654],
      [110.6233, -7.965],
      [110.6235, -7.9637],
      [110.6238, -7.9621],
      [110.6239, -7.9612],
      [110.6241, -7.96]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'BALEHARJO',
    border: [
      [110.6131, -7.9685],
      [110.613, -7.9687],
      [110.6128, -7.9688],
      [110.612, -7.9685],
      [110.6112, -7.9682],
      [110.6104, -7.9683],
      [110.6095, -7.9688],
      [110.6091, -7.9691],
      [110.609, -7.9692],
      [110.6088, -7.9695],
      [110.608, -7.9694],
      [110.6076, -7.9692],
      [110.6072, -7.969],
      [110.6069, -7.9692],
      [110.6061, -7.969],
      [110.6057, -7.9691],
      [110.6044, -7.9701],
      [110.6036, -7.9708],
      [110.6032, -7.9712],
      [110.6029, -7.9719],
      [110.6019, -7.9722],
      [110.6015, -7.9724],
      [110.6019, -7.9727],
      [110.6021, -7.9728],
      [110.6026, -7.9729],
      [110.6028, -7.9731],
      [110.6029, -7.9735],
      [110.6035, -7.9737],
      [110.6036, -7.9738],
      [110.6037, -7.9739],
      [110.6038, -7.974],
      [110.6041, -7.9745],
      [110.604, -7.9748],
      [110.604, -7.9751],
      [110.6043, -7.9754],
      [110.6045, -7.976],
      [110.6045, -7.9766],
      [110.6045, -7.9777],
      [110.6044, -7.9791],
      [110.6044, -7.9791],
      [110.6046, -7.9797],
      [110.6047, -7.9805],
      [110.6047, -7.9811],
      [110.6049, -7.9814],
      [110.6054, -7.9826],
      [110.6061, -7.9826],
      [110.6067, -7.9827],
      [110.6074, -7.983],
      [110.6076, -7.9839],
      [110.6081, -7.986],
      [110.6085, -7.9876],
      [110.6087, -7.9887],
      [110.6088, -7.9887],
      [110.609, -7.9887],
      [110.6091, -7.9888],
      [110.6094, -7.9891],
      [110.6106, -7.99],
      [110.611, -7.9898],
      [110.6122, -7.9898],
      [110.6125, -7.9896],
      [110.6131, -7.9895],
      [110.6137, -7.9895],
      [110.6142, -7.9889],
      [110.6144, -7.9889],
      [110.6147, -7.9885],
      [110.6151, -7.9885],
      [110.6154, -7.9884],
      [110.6158, -7.9883],
      [110.6161, -7.9881],
      [110.6166, -7.9881],
      [110.6167, -7.9881],
      [110.6171, -7.9883],
      [110.6199, -7.9902],
      [110.6236, -7.9921],
      [110.6241, -7.9918],
      [110.6243, -7.9908],
      [110.6246, -7.9894],
      [110.6245, -7.9886],
      [110.6239, -7.988],
      [110.6236, -7.9875],
      [110.6234, -7.9864],
      [110.6236, -7.9857],
      [110.6236, -7.9853],
      [110.6234, -7.9848],
      [110.6232, -7.9841],
      [110.6227, -7.9831],
      [110.6223, -7.9821],
      [110.6222, -7.9802],
      [110.6222, -7.9773],
      [110.6222, -7.9759],
      [110.6222, -7.975],
      [110.6224, -7.9745],
      [110.6226, -7.9742],
      [110.6234, -7.9733],
      [110.6231, -7.9731],
      [110.6226, -7.9727],
      [110.6223, -7.9727],
      [110.6219, -7.9725],
      [110.6216, -7.9726],
      [110.6213, -7.9727],
      [110.6211, -7.9731],
      [110.6208, -7.9732],
      [110.6207, -7.9732],
      [110.6202, -7.973],
      [110.6191, -7.9727],
      [110.6187, -7.9725],
      [110.6183, -7.972],
      [110.6182, -7.972],
      [110.6174, -7.9719],
      [110.6169, -7.9717],
      [110.6165, -7.9707],
      [110.6162, -7.9705],
      [110.6159, -7.9701],
      [110.6155, -7.9698],
      [110.6151, -7.9692],
      [110.6148, -7.9687],
      [110.6143, -7.9687],
      [110.6138, -7.9687],
      [110.6136, -7.9686],
      [110.6131, -7.9685]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'SIRAMAN',
    border: [
      [110.5794, -7.9656],
      [110.5792, -7.9655],
      [110.5789, -7.9656],
      [110.5785, -7.966],
      [110.5783, -7.9664],
      [110.5772, -7.9665],
      [110.5766, -7.9667],
      [110.5762, -7.9668],
      [110.5761, -7.9668],
      [110.5758, -7.9668],
      [110.5756, -7.9668],
      [110.5755, -7.9668],
      [110.5751, -7.9668],
      [110.5745, -7.9667],
      [110.5739, -7.9666],
      [110.5737, -7.9665],
      [110.5729, -7.9666],
      [110.5723, -7.9667],
      [110.5719, -7.967],
      [110.5717, -7.967],
      [110.5716, -7.9669],
      [110.571, -7.9668],
      [110.5708, -7.9668],
      [110.5702, -7.9671],
      [110.57, -7.9675],
      [110.5699, -7.9676],
      [110.5698, -7.9679],
      [110.5699, -7.9685],
      [110.5698, -7.9689],
      [110.5696, -7.9695],
      [110.5695, -7.9697],
      [110.5693, -7.9699],
      [110.5692, -7.9705],
      [110.569, -7.9715],
      [110.5687, -7.9723],
      [110.5684, -7.9733],
      [110.5681, -7.9743],
      [110.5679, -7.9751],
      [110.5678, -7.9761],
      [110.5682, -7.9763],
      [110.5685, -7.9763],
      [110.5687, -7.9763],
      [110.5691, -7.9763],
      [110.5694, -7.9765],
      [110.5694, -7.9768],
      [110.5696, -7.9772],
      [110.57, -7.9773],
      [110.57, -7.9777],
      [110.5701, -7.9779],
      [110.5713, -7.9776],
      [110.572, -7.9774],
      [110.5726, -7.9771],
      [110.5732, -7.977],
      [110.5748, -7.977],
      [110.5758, -7.9771],
      [110.576, -7.9766],
      [110.5769, -7.9768],
      [110.5771, -7.9769],
      [110.5772, -7.9772],
      [110.5774, -7.9776],
      [110.578, -7.9779],
      [110.5788, -7.9788],
      [110.5789, -7.9789],
      [110.5796, -7.9804],
      [110.58, -7.9806],
      [110.5811, -7.9813],
      [110.5823, -7.9823],
      [110.5834, -7.9829],
      [110.5841, -7.9835],
      [110.5849, -7.9838],
      [110.5853, -7.9835],
      [110.5853, -7.9832],
      [110.5857, -7.9832],
      [110.5858, -7.9829],
      [110.5858, -7.9828],
      [110.5858, -7.9826],
      [110.5863, -7.9823],
      [110.5867, -7.9818],
      [110.5869, -7.9815],
      [110.5873, -7.9815],
      [110.5882, -7.9812],
      [110.5889, -7.9809],
      [110.5889, -7.9802],
      [110.5889, -7.9797],
      [110.5888, -7.9794],
      [110.5888, -7.9791],
      [110.5888, -7.9788],
      [110.5884, -7.9783],
      [110.5883, -7.9783],
      [110.5882, -7.9784],
      [110.588, -7.9784],
      [110.5879, -7.9784],
      [110.5876, -7.9779],
      [110.5877, -7.978],
      [110.5885, -7.9781],
      [110.5903, -7.978],
      [110.5904, -7.9775],
      [110.5909, -7.9777],
      [110.5918, -7.9775],
      [110.5924, -7.9777],
      [110.5933, -7.9776],
      [110.5939, -7.9764],
      [110.5944, -7.9754],
      [110.5948, -7.9755],
      [110.5953, -7.9762],
      [110.5956, -7.9765],
      [110.5966, -7.9772],
      [110.5969, -7.9782],
      [110.5971, -7.9788],
      [110.5974, -7.9783],
      [110.5978, -7.9776],
      [110.5982, -7.9773],
      [110.599, -7.9771],
      [110.6001, -7.9778],
      [110.6017, -7.9782],
      [110.6021, -7.9784],
      [110.6028, -7.9787],
      [110.6044, -7.9791],
      [110.6044, -7.9791],
      [110.6045, -7.9777],
      [110.6045, -7.9766],
      [110.6045, -7.976],
      [110.6043, -7.9754],
      [110.604, -7.9751],
      [110.604, -7.9748],
      [110.6041, -7.9745],
      [110.6038, -7.974],
      [110.6037, -7.9739],
      [110.6036, -7.9738],
      [110.6035, -7.9737],
      [110.6029, -7.9735],
      [110.6028, -7.9731],
      [110.6026, -7.9729],
      [110.6021, -7.9728],
      [110.6019, -7.9727],
      [110.6015, -7.9724],
      [110.6013, -7.9724],
      [110.601, -7.9726],
      [110.6004, -7.9723],
      [110.6001, -7.9722],
      [110.5998, -7.9723],
      [110.5997, -7.9724],
      [110.5989, -7.9728],
      [110.5987, -7.9726],
      [110.5982, -7.9724],
      [110.5976, -7.9724],
      [110.5973, -7.9724],
      [110.5969, -7.9728],
      [110.5965, -7.9727],
      [110.596, -7.9728],
      [110.5957, -7.9727],
      [110.5954, -7.9727],
      [110.5953, -7.9727],
      [110.5951, -7.9727],
      [110.5949, -7.9727],
      [110.5947, -7.9726],
      [110.5944, -7.9723],
      [110.5942, -7.9722],
      [110.5941, -7.9724],
      [110.5939, -7.9728],
      [110.5938, -7.9729],
      [110.5937, -7.9729],
      [110.5935, -7.9727],
      [110.5934, -7.9726],
      [110.593, -7.9725],
      [110.5925, -7.9724],
      [110.5921, -7.9719],
      [110.5919, -7.9717],
      [110.5917, -7.9714],
      [110.5915, -7.9711],
      [110.5911, -7.9714],
      [110.5908, -7.9714],
      [110.5905, -7.9714],
      [110.5903, -7.9712],
      [110.5902, -7.9709],
      [110.5901, -7.9708],
      [110.5898, -7.9707],
      [110.5897, -7.9706],
      [110.5897, -7.9703],
      [110.5891, -7.97],
      [110.5869, -7.9698],
      [110.5866, -7.9698],
      [110.5866, -7.9686],
      [110.5857, -7.9681],
      [110.5856, -7.9672],
      [110.5858, -7.9669],
      [110.5855, -7.9669],
      [110.5852, -7.9666],
      [110.5849, -7.9662],
      [110.5844, -7.9661],
      [110.584, -7.9662],
      [110.5837, -7.9665],
      [110.5832, -7.9666],
      [110.5825, -7.9664],
      [110.5812, -7.9661],
      [110.5805, -7.9661],
      [110.58, -7.966],
      [110.5795, -7.9658],
      [110.5794, -7.9656]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'PULUTAN',
    border: [
      [110.5849, -7.9838],
      [110.5841, -7.9835],
      [110.5834, -7.9829],
      [110.5823, -7.9823],
      [110.5811, -7.9813],
      [110.58, -7.9806],
      [110.5796, -7.9804],
      [110.5789, -7.9789],
      [110.5788, -7.9788],
      [110.578, -7.9779],
      [110.5774, -7.9776],
      [110.5772, -7.9772],
      [110.5771, -7.9769],
      [110.5769, -7.9768],
      [110.576, -7.9766],
      [110.5758, -7.9771],
      [110.5748, -7.977],
      [110.5732, -7.977],
      [110.5726, -7.9771],
      [110.572, -7.9774],
      [110.5713, -7.9776],
      [110.5701, -7.9779],
      [110.5692, -7.9787],
      [110.5684, -7.9789],
      [110.568, -7.9787],
      [110.5674, -7.9787],
      [110.5671, -7.9788],
      [110.5667, -7.979],
      [110.5664, -7.9789],
      [110.5661, -7.9789],
      [110.5659, -7.9791],
      [110.5659, -7.9793],
      [110.5659, -7.9795],
      [110.5655, -7.9797],
      [110.5649, -7.9796],
      [110.5644, -7.9797],
      [110.5641, -7.9802],
      [110.5637, -7.9803],
      [110.5631, -7.9802],
      [110.5627, -7.98],
      [110.5624, -7.98],
      [110.5623, -7.9803],
      [110.5619, -7.9802],
      [110.5617, -7.9805],
      [110.5616, -7.9808],
      [110.5611, -7.981],
      [110.5607, -7.9811],
      [110.5606, -7.981],
      [110.5605, -7.9808],
      [110.56, -7.9814],
      [110.5595, -7.9816],
      [110.5584, -7.9821],
      [110.5581, -7.9825],
      [110.5581, -7.9832],
      [110.5579, -7.9844],
      [110.5578, -7.9851],
      [110.5576, -7.9855],
      [110.5574, -7.9858],
      [110.5569, -7.9861],
      [110.5564, -7.9864],
      [110.5559, -7.9865],
      [110.5554, -7.9865],
      [110.5552, -7.987],
      [110.5552, -7.9876],
      [110.5553, -7.9879],
      [110.5557, -7.9884],
      [110.5561, -7.9885],
      [110.5582, -7.9884],
      [110.5596, -7.9882],
      [110.5607, -7.9883],
      [110.5608, -7.9884],
      [110.5609, -7.9892],
      [110.561, -7.9897],
      [110.5608, -7.99],
      [110.5605, -7.99],
      [110.5605, -7.9902],
      [110.5609, -7.9904],
      [110.5608, -7.9908],
      [110.5606, -7.9908],
      [110.5603, -7.9911],
      [110.5602, -7.9915],
      [110.5602, -7.9922],
      [110.5599, -7.9929],
      [110.5596, -7.9936],
      [110.5596, -7.9941],
      [110.5596, -7.9944],
      [110.5597, -7.9947],
      [110.5601, -7.995],
      [110.5602, -7.9951],
      [110.5608, -7.9951],
      [110.5642, -7.995],
      [110.5655, -7.9951],
      [110.5667, -7.9951],
      [110.5685, -7.9955],
      [110.5689, -7.9957],
      [110.5692, -7.9958],
      [110.5695, -7.9961],
      [110.5697, -7.9969],
      [110.5697, -7.9973],
      [110.5692, -7.9982],
      [110.569, -7.9987],
      [110.5689, -7.9988],
      [110.5688, -7.9989],
      [110.5689, -8.0],
      [110.569, -8.0003],
      [110.5691, -8.0006],
      [110.5692, -8.0007],
      [110.5696, -8.0014],
      [110.5698, -8.0016],
      [110.5701, -8.0021],
      [110.5708, -8.0034],
      [110.5709, -8.0047],
      [110.5715, -8.0057],
      [110.5723, -8.0062],
      [110.5734, -8.0069],
      [110.5743, -8.0069],
      [110.5755, -8.007],
      [110.5766, -8.0071],
      [110.5776, -8.0067],
      [110.5795, -8.0073],
      [110.5809, -8.0075],
      [110.5828, -8.0084],
      [110.5829, -8.0084],
      [110.5834, -8.0075],
      [110.5844, -8.0063],
      [110.5847, -8.0056],
      [110.5847, -8.0049],
      [110.5844, -8.0044],
      [110.5836, -8.0035],
      [110.5835, -8.0032],
      [110.5834, -8.0028],
      [110.5834, -8.0019],
      [110.5835, -8.0012],
      [110.5836, -8.0007],
      [110.5837, -8.0004],
      [110.5837, -8.0003],
      [110.5837, -7.9997],
      [110.5837, -7.9992],
      [110.5839, -7.9985],
      [110.5842, -7.997],
      [110.5843, -7.9948],
      [110.5843, -7.9947],
      [110.5845, -7.9942],
      [110.5846, -7.9937],
      [110.5847, -7.9931],
      [110.5846, -7.9927],
      [110.5845, -7.9922],
      [110.5846, -7.9917],
      [110.5849, -7.9914],
      [110.585, -7.9913],
      [110.5851, -7.9911],
      [110.5854, -7.9908],
      [110.5855, -7.9894],
      [110.5852, -7.9884],
      [110.5851, -7.9879],
      [110.5853, -7.9866],
      [110.5853, -7.986],
      [110.5854, -7.9851],
      [110.585, -7.9841],
      [110.5849, -7.9838]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'WARENG',
    border: [
      [110.5861, -7.9552],
      [110.586, -7.9551],
      [110.5855, -7.9545],
      [110.5847, -7.9536],
      [110.5836, -7.9529],
      [110.5827, -7.9523],
      [110.5818, -7.9518],
      [110.5817, -7.9518],
      [110.5812, -7.9518],
      [110.5808, -7.9516],
      [110.5805, -7.9514],
      [110.5798, -7.9515],
      [110.5793, -7.9514],
      [110.579, -7.9515],
      [110.5785, -7.9516],
      [110.5783, -7.9518],
      [110.578, -7.9521],
      [110.5777, -7.9524],
      [110.577, -7.953],
      [110.5767, -7.953],
      [110.5763, -7.9531],
      [110.5756, -7.9533],
      [110.575, -7.9535],
      [110.5747, -7.9535],
      [110.5744, -7.9541],
      [110.5742, -7.9543],
      [110.574, -7.9544],
      [110.5739, -7.9545],
      [110.5736, -7.9548],
      [110.5731, -7.9551],
      [110.5728, -7.955],
      [110.5723, -7.9551],
      [110.5722, -7.9552],
      [110.5717, -7.9554],
      [110.5715, -7.9555],
      [110.5714, -7.9556],
      [110.5709, -7.956],
      [110.5707, -7.9561],
      [110.5705, -7.9562],
      [110.5702, -7.9563],
      [110.5701, -7.9563],
      [110.5692, -7.9568],
      [110.569, -7.9569],
      [110.5687, -7.9569],
      [110.5685, -7.9569],
      [110.568, -7.9571],
      [110.5678, -7.9571],
      [110.5672, -7.957],
      [110.5669, -7.9571],
      [110.5667, -7.9573],
      [110.5665, -7.9574],
      [110.5664, -7.9576],
      [110.5661, -7.958],
      [110.5658, -7.9581],
      [110.5653, -7.9583],
      [110.5646, -7.9582],
      [110.564, -7.9578],
      [110.5638, -7.9568],
      [110.5638, -7.9562],
      [110.5641, -7.9557],
      [110.5642, -7.9551],
      [110.5643, -7.9546],
      [110.5644, -7.9542],
      [110.5639, -7.9534],
      [110.5632, -7.9527],
      [110.562, -7.9527],
      [110.5619, -7.953],
      [110.5618, -7.9532],
      [110.5616, -7.9535],
      [110.5613, -7.9537],
      [110.5611, -7.9539],
      [110.5612, -7.9544],
      [110.5614, -7.9548],
      [110.5615, -7.9552],
      [110.5614, -7.9558],
      [110.5612, -7.9565],
      [110.5612, -7.9572],
      [110.561, -7.9582],
      [110.5607, -7.9579],
      [110.5604, -7.9577],
      [110.5599, -7.9575],
      [110.5594, -7.9572],
      [110.5589, -7.957],
      [110.5584, -7.9569],
      [110.5579, -7.9567],
      [110.5579, -7.9578],
      [110.5577, -7.9581],
      [110.5573, -7.958],
      [110.5575, -7.9587],
      [110.5575, -7.959],
      [110.5571, -7.9589],
      [110.5569, -7.9594],
      [110.5571, -7.9597],
      [110.5576, -7.9602],
      [110.5577, -7.9605],
      [110.5572, -7.9612],
      [110.5567, -7.9619],
      [110.5571, -7.9622],
      [110.557, -7.9626],
      [110.557, -7.9632],
      [110.5569, -7.9642],
      [110.5572, -7.9643],
      [110.5572, -7.9645],
      [110.557, -7.965],
      [110.5564, -7.9649],
      [110.5562, -7.9655],
      [110.5561, -7.9658],
      [110.5559, -7.9663],
      [110.5555, -7.9669],
      [110.5553, -7.9672],
      [110.5551, -7.9676],
      [110.5551, -7.9679],
      [110.5547, -7.9685],
      [110.5544, -7.9692],
      [110.5546, -7.9693],
      [110.5543, -7.9699],
      [110.5541, -7.9705],
      [110.5531, -7.9706],
      [110.553, -7.9708],
      [110.5526, -7.9718],
      [110.5519, -7.9729],
      [110.5507, -7.9746],
      [110.5507, -7.9748],
      [110.5511, -7.9753],
      [110.5511, -7.9758],
      [110.5509, -7.9761],
      [110.5506, -7.9761],
      [110.5503, -7.9761],
      [110.5501, -7.9764],
      [110.5502, -7.9767],
      [110.5504, -7.9771],
      [110.5501, -7.9773],
      [110.5497, -7.9776],
      [110.5498, -7.9781],
      [110.5498, -7.9788],
      [110.5502, -7.979],
      [110.5506, -7.9788],
      [110.5508, -7.9784],
      [110.5512, -7.9786],
      [110.5516, -7.9783],
      [110.5523, -7.9784],
      [110.5527, -7.9783],
      [110.5533, -7.9782],
      [110.5537, -7.9784],
      [110.5545, -7.9786],
      [110.5552, -7.9787],
      [110.5566, -7.9789],
      [110.5571, -7.9792],
      [110.5575, -7.9793],
      [110.558, -7.9795],
      [110.5586, -7.9795],
      [110.5589, -7.9798],
      [110.5593, -7.9799],
      [110.5602, -7.98],
      [110.5603, -7.9806],
      [110.5605, -7.9808],
      [110.5606, -7.981],
      [110.5607, -7.9811],
      [110.5611, -7.981],
      [110.5616, -7.9808],
      [110.5617, -7.9805],
      [110.5619, -7.9802],
      [110.5623, -7.9803],
      [110.5624, -7.98],
      [110.5627, -7.98],
      [110.5631, -7.9802],
      [110.5637, -7.9803],
      [110.5641, -7.9802],
      [110.5644, -7.9797],
      [110.5649, -7.9796],
      [110.5655, -7.9797],
      [110.5659, -7.9795],
      [110.5659, -7.9793],
      [110.5659, -7.9791],
      [110.5661, -7.9789],
      [110.5664, -7.9789],
      [110.5667, -7.979],
      [110.5671, -7.9788],
      [110.5674, -7.9787],
      [110.568, -7.9787],
      [110.5684, -7.9789],
      [110.5692, -7.9787],
      [110.5701, -7.9779],
      [110.57, -7.9777],
      [110.57, -7.9773],
      [110.5696, -7.9772],
      [110.5694, -7.9768],
      [110.5694, -7.9765],
      [110.5691, -7.9763],
      [110.5687, -7.9763],
      [110.5685, -7.9763],
      [110.5682, -7.9763],
      [110.5678, -7.9761],
      [110.5679, -7.9751],
      [110.5681, -7.9743],
      [110.5684, -7.9733],
      [110.5687, -7.9723],
      [110.569, -7.9715],
      [110.5692, -7.9705],
      [110.5693, -7.9699],
      [110.5695, -7.9697],
      [110.5696, -7.9695],
      [110.5698, -7.9689],
      [110.5699, -7.9685],
      [110.5698, -7.9679],
      [110.5699, -7.9676],
      [110.57, -7.9675],
      [110.5702, -7.9671],
      [110.5708, -7.9668],
      [110.571, -7.9668],
      [110.5716, -7.9669],
      [110.5717, -7.967],
      [110.5719, -7.967],
      [110.5723, -7.9667],
      [110.5729, -7.9666],
      [110.5737, -7.9665],
      [110.5739, -7.9666],
      [110.5745, -7.9667],
      [110.5751, -7.9668],
      [110.5755, -7.9668],
      [110.5756, -7.9668],
      [110.5758, -7.9668],
      [110.5761, -7.9668],
      [110.5762, -7.9668],
      [110.5766, -7.9667],
      [110.5772, -7.9665],
      [110.5783, -7.9664],
      [110.5785, -7.966],
      [110.5789, -7.9656],
      [110.5792, -7.9655],
      [110.5794, -7.9656],
      [110.5799, -7.9655],
      [110.5803, -7.9652],
      [110.5806, -7.9648],
      [110.5807, -7.9642],
      [110.5809, -7.9638],
      [110.5812, -7.9635],
      [110.5812, -7.9629],
      [110.5814, -7.9625],
      [110.5817, -7.9621],
      [110.5822, -7.9613],
      [110.5824, -7.9612],
      [110.5832, -7.9606],
      [110.5836, -7.9601],
      [110.5838, -7.9592],
      [110.584, -7.9587],
      [110.5854, -7.9569],
      [110.5857, -7.9563],
      [110.5861, -7.9555],
      [110.5861, -7.9552]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'DUWET',
    border: [
      [110.6236, -7.9921],
      [110.6199, -7.9902],
      [110.6171, -7.9883],
      [110.6167, -7.9881],
      [110.6166, -7.9881],
      [110.6161, -7.9881],
      [110.6158, -7.9883],
      [110.6154, -7.9884],
      [110.6151, -7.9885],
      [110.6147, -7.9885],
      [110.6144, -7.9889],
      [110.6142, -7.9889],
      [110.6137, -7.9895],
      [110.6131, -7.9895],
      [110.6125, -7.9896],
      [110.6122, -7.9898],
      [110.6126, -7.9901],
      [110.6134, -7.9908],
      [110.6138, -7.9913],
      [110.614, -7.9924],
      [110.6138, -7.9929],
      [110.6136, -7.9933],
      [110.613, -7.9934],
      [110.6123, -7.9934],
      [110.6113, -7.9934],
      [110.6093, -7.993],
      [110.6072, -7.9926],
      [110.6052, -7.9923],
      [110.6043, -7.9923],
      [110.6041, -7.9924],
      [110.6037, -7.9925],
      [110.6033, -7.9935],
      [110.6033, -7.9952],
      [110.6032, -7.9956],
      [110.6028, -7.996],
      [110.6004, -7.996],
      [110.5987, -7.9958],
      [110.597, -7.9953],
      [110.5934, -7.9941],
      [110.5932, -7.9949],
      [110.5932, -7.995],
      [110.5908, -7.9933],
      [110.5883, -7.9915],
      [110.5877, -7.9913],
      [110.5855, -7.9909],
      [110.5854, -7.9908],
      [110.5851, -7.9911],
      [110.585, -7.9913],
      [110.5849, -7.9914],
      [110.5846, -7.9917],
      [110.5845, -7.9922],
      [110.5846, -7.9927],
      [110.5847, -7.9931],
      [110.5846, -7.9937],
      [110.5845, -7.9942],
      [110.5843, -7.9947],
      [110.5843, -7.9948],
      [110.5842, -7.997],
      [110.5839, -7.9985],
      [110.5837, -7.9992],
      [110.5837, -7.9997],
      [110.5837, -8.0003],
      [110.5837, -8.0004],
      [110.5836, -8.0007],
      [110.5835, -8.0012],
      [110.5834, -8.0019],
      [110.5834, -8.0028],
      [110.5835, -8.0032],
      [110.5836, -8.0035],
      [110.5844, -8.0044],
      [110.5847, -8.0049],
      [110.5857, -8.0039],
      [110.5867, -8.0029],
      [110.5875, -8.0022],
      [110.5878, -8.0019],
      [110.5895, -8.0014],
      [110.5909, -8.0014],
      [110.5918, -8.0015],
      [110.5926, -8.002],
      [110.5929, -8.0031],
      [110.5932, -8.0037],
      [110.5937, -8.0043],
      [110.5942, -8.0047],
      [110.5947, -8.0049],
      [110.5949, -8.005],
      [110.5951, -8.0048],
      [110.5957, -8.0048],
      [110.5972, -8.0054],
      [110.598, -8.0057],
      [110.5983, -8.0062],
      [110.5991, -8.0065],
      [110.6002, -8.0068],
      [110.6011, -8.0069],
      [110.6017, -8.0072],
      [110.6023, -8.0076],
      [110.6031, -8.0078],
      [110.6034, -8.008],
      [110.6048, -8.0082],
      [110.6057, -8.0086],
      [110.6063, -8.0089],
      [110.6071, -8.009],
      [110.6075, -8.0089],
      [110.6077, -8.0089],
      [110.608, -8.0085],
      [110.6089, -8.0067],
      [110.6099, -8.0046],
      [110.6104, -8.0033],
      [110.6109, -8.0019],
      [110.6109, -8.0013],
      [110.6109, -8.0009],
      [110.6111, -8.0002],
      [110.6117, -7.9996],
      [110.6125, -7.9987],
      [110.6144, -7.997],
      [110.6161, -7.9959],
      [110.6167, -7.9953],
      [110.6191, -7.9944],
      [110.6202, -7.9939],
      [110.6229, -7.9926],
      [110.6234, -7.9922],
      [110.6236, -7.9921]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'MULO',
    border: [
      [110.5949, -8.005],
      [110.5947, -8.0049],
      [110.5942, -8.0047],
      [110.5937, -8.0043],
      [110.5932, -8.0037],
      [110.5929, -8.0031],
      [110.5926, -8.002],
      [110.5918, -8.0015],
      [110.5909, -8.0014],
      [110.5895, -8.0014],
      [110.5878, -8.0019],
      [110.5875, -8.0022],
      [110.5867, -8.0029],
      [110.5857, -8.0039],
      [110.5847, -8.0049],
      [110.5847, -8.0056],
      [110.5844, -8.0063],
      [110.5834, -8.0075],
      [110.5829, -8.0084],
      [110.5829, -8.0087],
      [110.5828, -8.0091],
      [110.5829, -8.0094],
      [110.583, -8.0095],
      [110.5831, -8.0098],
      [110.5831, -8.0103],
      [110.5832, -8.0108],
      [110.5833, -8.0113],
      [110.5833, -8.0117],
      [110.5833, -8.0118],
      [110.5834, -8.012],
      [110.5835, -8.0122],
      [110.5835, -8.0124],
      [110.5836, -8.0126],
      [110.5833, -8.0129],
      [110.5833, -8.013],
      [110.5833, -8.0137],
      [110.5832, -8.0144],
      [110.5832, -8.0159],
      [110.5831, -8.0166],
      [110.5832, -8.0172],
      [110.5834, -8.0178],
      [110.5835, -8.0183],
      [110.5835, -8.0191],
      [110.5835, -8.0201],
      [110.5834, -8.0213],
      [110.5829, -8.0222],
      [110.5826, -8.0239],
      [110.5826, -8.0245],
      [110.5831, -8.0248],
      [110.5833, -8.0252],
      [110.5833, -8.0261],
      [110.5831, -8.0288],
      [110.5828, -8.0321],
      [110.5826, -8.0329],
      [110.5825, -8.0342],
      [110.5825, -8.0348],
      [110.5827, -8.0356],
      [110.5827, -8.0364],
      [110.5824, -8.037],
      [110.582, -8.0376],
      [110.5817, -8.0378],
      [110.5812, -8.0382],
      [110.5808, -8.0386],
      [110.5806, -8.0395],
      [110.5805, -8.0397],
      [110.5795, -8.0403],
      [110.5793, -8.0411],
      [110.5789, -8.0429],
      [110.5785, -8.0457],
      [110.5785, -8.0466],
      [110.5787, -8.047],
      [110.5797, -8.0475],
      [110.5797, -8.0477],
      [110.5796, -8.0483],
      [110.58, -8.0489],
      [110.5802, -8.0493],
      [110.5809, -8.0499],
      [110.5814, -8.0507],
      [110.5817, -8.0513],
      [110.5817, -8.0524],
      [110.5817, -8.0536],
      [110.5818, -8.0544],
      [110.5816, -8.0551],
      [110.5813, -8.0558],
      [110.5812, -8.0561],
      [110.5812, -8.0567],
      [110.581, -8.0572],
      [110.5809, -8.0574],
      [110.5807, -8.058],
      [110.5812, -8.0586],
      [110.5818, -8.0593],
      [110.5825, -8.0596],
      [110.5833, -8.0596],
      [110.5844, -8.0599],
      [110.5855, -8.0602],
      [110.5861, -8.0602],
      [110.5866, -8.0601],
      [110.5877, -8.0596],
      [110.5907, -8.0586],
      [110.5907, -8.0569],
      [110.5908, -8.0554],
      [110.5909, -8.0546],
      [110.5907, -8.0535],
      [110.5908, -8.0526],
      [110.5905, -8.052],
      [110.5897, -8.0507],
      [110.589, -8.0491],
      [110.5891, -8.0488],
      [110.5892, -8.0481],
      [110.5889, -8.0471],
      [110.5891, -8.0465],
      [110.5897, -8.046],
      [110.5901, -8.0455],
      [110.5901, -8.0448],
      [110.5903, -8.0436],
      [110.5903, -8.0427],
      [110.5904, -8.0418],
      [110.5908, -8.0409],
      [110.5911, -8.0402],
      [110.5909, -8.04],
      [110.5901, -8.0397],
      [110.5895, -8.0393],
      [110.5901, -8.0382],
      [110.5904, -8.0378],
      [110.5904, -8.0368],
      [110.5906, -8.036],
      [110.5909, -8.0353],
      [110.5911, -8.035],
      [110.5916, -8.0346],
      [110.5919, -8.0344],
      [110.592, -8.0343],
      [110.5921, -8.034],
      [110.5923, -8.0331],
      [110.5925, -8.0327],
      [110.5925, -8.0324],
      [110.5924, -8.0321],
      [110.5926, -8.0318],
      [110.5926, -8.0316],
      [110.5927, -8.0313],
      [110.5922, -8.031],
      [110.5921, -8.0309],
      [110.5921, -8.0306],
      [110.5921, -8.0304],
      [110.5917, -8.0301],
      [110.5916, -8.03],
      [110.5914, -8.0299],
      [110.5915, -8.0297],
      [110.5915, -8.0292],
      [110.5914, -8.0291],
      [110.5913, -8.0291],
      [110.5913, -8.0289],
      [110.5913, -8.0288],
      [110.5912, -8.0283],
      [110.5912, -8.0265],
      [110.5916, -8.0244],
      [110.5916, -8.0243],
      [110.5919, -8.0228],
      [110.5921, -8.0212],
      [110.5924, -8.017],
      [110.5925, -8.0139],
      [110.5925, -8.0113],
      [110.5925, -8.0106],
      [110.5923, -8.0096],
      [110.5923, -8.009],
      [110.5919, -8.0082],
      [110.5922, -8.0073],
      [110.5925, -8.0071],
      [110.5928, -8.0068],
      [110.5932, -8.0065],
      [110.5936, -8.0065],
      [110.5937, -8.0063],
      [110.594, -8.006],
      [110.5943, -8.0057],
      [110.5945, -8.0052],
      [110.5949, -8.005]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'WUNUNG',
    border: [
      [110.5829, -8.0084],
      [110.5828, -8.0084],
      [110.5809, -8.0075],
      [110.5795, -8.0073],
      [110.5776, -8.0067],
      [110.5766, -8.0071],
      [110.5755, -8.007],
      [110.5743, -8.0069],
      [110.5734, -8.0069],
      [110.5723, -8.0062],
      [110.5715, -8.0057],
      [110.5709, -8.0047],
      [110.5708, -8.0034],
      [110.5701, -8.0021],
      [110.5698, -8.0016],
      [110.5696, -8.0014],
      [110.5692, -8.0007],
      [110.5691, -8.0006],
      [110.569, -8.0003],
      [110.5689, -8.0],
      [110.5688, -7.9989],
      [110.5689, -7.9988],
      [110.569, -7.9987],
      [110.5692, -7.9982],
      [110.5697, -7.9973],
      [110.5697, -7.9969],
      [110.5695, -7.9961],
      [110.5692, -7.9958],
      [110.5689, -7.9957],
      [110.5685, -7.9955],
      [110.5667, -7.9951],
      [110.5655, -7.9951],
      [110.5642, -7.995],
      [110.5608, -7.9951],
      [110.5602, -7.9951],
      [110.5604, -7.9953],
      [110.5601, -7.9958],
      [110.5599, -7.9957],
      [110.5594, -7.9954],
      [110.5591, -7.9955],
      [110.5587, -7.9956],
      [110.5583, -7.9957],
      [110.558, -7.9955],
      [110.5577, -7.9953],
      [110.5575, -7.9953],
      [110.5571, -7.9962],
      [110.5568, -7.9972],
      [110.5574, -7.9981],
      [110.5578, -7.9985],
      [110.5581, -7.9988],
      [110.5583, -7.9991],
      [110.5585, -7.9996],
      [110.5586, -8.0002],
      [110.5591, -8.0006],
      [110.5596, -8.0008],
      [110.5607, -8.0013],
      [110.5617, -8.0015],
      [110.563, -8.0018],
      [110.5636, -8.0021],
      [110.5646, -8.0029],
      [110.5656, -8.0038],
      [110.5662, -8.0043],
      [110.5667, -8.0046],
      [110.5669, -8.0048],
      [110.5668, -8.0052],
      [110.5668, -8.0055],
      [110.5668, -8.0058],
      [110.567, -8.0061],
      [110.5673, -8.0065],
      [110.5673, -8.0069],
      [110.5671, -8.007],
      [110.567, -8.0071],
      [110.5668, -8.0072],
      [110.5666, -8.0073],
      [110.5662, -8.0075],
      [110.5661, -8.0076],
      [110.5661, -8.008],
      [110.5662, -8.0083],
      [110.5665, -8.0087],
      [110.5665, -8.0088],
      [110.5664, -8.0089],
      [110.5663, -8.009],
      [110.566, -8.0092],
      [110.5658, -8.0093],
      [110.5656, -8.0095],
      [110.5656, -8.0098],
      [110.5658, -8.01],
      [110.5659, -8.0102],
      [110.5661, -8.0105],
      [110.5661, -8.0108],
      [110.5661, -8.0109],
      [110.566, -8.0112],
      [110.5661, -8.0114],
      [110.5663, -8.0116],
      [110.5665, -8.0118],
      [110.5665, -8.0122],
      [110.5663, -8.0125],
      [110.566, -8.0127],
      [110.5658, -8.0128],
      [110.5651, -8.0131],
      [110.5645, -8.0133],
      [110.5643, -8.0136],
      [110.5643, -8.014],
      [110.5645, -8.0142],
      [110.5649, -8.0144],
      [110.5652, -8.0148],
      [110.5655, -8.0155],
      [110.5655, -8.0156],
      [110.5656, -8.0165],
      [110.5659, -8.0168],
      [110.5659, -8.0169],
      [110.5662, -8.0173],
      [110.5668, -8.0176],
      [110.5678, -8.0181],
      [110.5683, -8.0182],
      [110.5686, -8.0181],
      [110.5687, -8.0179],
      [110.5688, -8.0176],
      [110.5691, -8.0176],
      [110.5694, -8.0176],
      [110.5699, -8.018],
      [110.57, -8.0182],
      [110.5699, -8.0184],
      [110.5698, -8.0187],
      [110.5698, -8.0188],
      [110.5699, -8.0191],
      [110.5701, -8.0195],
      [110.5704, -8.0197],
      [110.5709, -8.02],
      [110.5715, -8.0202],
      [110.5717, -8.0203],
      [110.5717, -8.0203],
      [110.5717, -8.0206],
      [110.5715, -8.0208],
      [110.5713, -8.0211],
      [110.5709, -8.0214],
      [110.5705, -8.0217],
      [110.5705, -8.0218],
      [110.5706, -8.0219],
      [110.5707, -8.0221],
      [110.5709, -8.0222],
      [110.5713, -8.0222],
      [110.5717, -8.0221],
      [110.5721, -8.022],
      [110.5725, -8.022],
      [110.5725, -8.0222],
      [110.5726, -8.0224],
      [110.5727, -8.0231],
      [110.5728, -8.0239],
      [110.5728, -8.0243],
      [110.5726, -8.0243],
      [110.5724, -8.0244],
      [110.5719, -8.0243],
      [110.5715, -8.0241],
      [110.5711, -8.0239],
      [110.5701, -8.0233],
      [110.57, -8.0234],
      [110.5699, -8.0237],
      [110.5699, -8.0242],
      [110.5699, -8.0249],
      [110.5699, -8.0264],
      [110.5698, -8.028],
      [110.5694, -8.0288],
      [110.5689, -8.0296],
      [110.5684, -8.0303],
      [110.5684, -8.0304],
      [110.5682, -8.031],
      [110.5682, -8.0312],
      [110.5682, -8.0314],
      [110.5683, -8.0317],
      [110.5683, -8.032],
      [110.568, -8.0326],
      [110.5675, -8.0329],
      [110.5671, -8.033],
      [110.5666, -8.0331],
      [110.566, -8.0335],
      [110.5655, -8.0335],
      [110.5647, -8.0333],
      [110.5644, -8.034],
      [110.5643, -8.0346],
      [110.5639, -8.0348],
      [110.5638, -8.035],
      [110.5639, -8.036],
      [110.5639, -8.0368],
      [110.564, -8.0374],
      [110.5639, -8.0378],
      [110.5637, -8.038],
      [110.5632, -8.0385],
      [110.5627, -8.039],
      [110.5626, -8.0395],
      [110.5623, -8.0402],
      [110.562, -8.0404],
      [110.562, -8.0406],
      [110.5617, -8.0416],
      [110.5615, -8.0438],
      [110.5614, -8.0453],
      [110.5617, -8.046],
      [110.5624, -8.0472],
      [110.5626, -8.0481],
      [110.5632, -8.0499],
      [110.5633, -8.0499],
      [110.5654, -8.0499],
      [110.5685, -8.0499],
      [110.5695, -8.0502],
      [110.5718, -8.051],
      [110.5746, -8.0525],
      [110.578, -8.0549],
      [110.5787, -8.0555],
      [110.58, -8.0572],
      [110.5807, -8.058],
      [110.5809, -8.0574],
      [110.581, -8.0572],
      [110.5812, -8.0567],
      [110.5812, -8.0561],
      [110.5813, -8.0558],
      [110.5816, -8.0551],
      [110.5818, -8.0544],
      [110.5817, -8.0536],
      [110.5817, -8.0524],
      [110.5817, -8.0513],
      [110.5814, -8.0507],
      [110.5809, -8.0499],
      [110.5802, -8.0493],
      [110.58, -8.0489],
      [110.5796, -8.0483],
      [110.5797, -8.0477],
      [110.5797, -8.0475],
      [110.5787, -8.047],
      [110.5785, -8.0466],
      [110.5785, -8.0457],
      [110.5789, -8.0429],
      [110.5793, -8.0411],
      [110.5795, -8.0403],
      [110.5805, -8.0397],
      [110.5806, -8.0395],
      [110.5808, -8.0386],
      [110.5812, -8.0382],
      [110.5817, -8.0378],
      [110.582, -8.0376],
      [110.5824, -8.037],
      [110.5827, -8.0364],
      [110.5827, -8.0356],
      [110.5825, -8.0348],
      [110.5825, -8.0342],
      [110.5826, -8.0329],
      [110.5828, -8.0321],
      [110.5831, -8.0288],
      [110.5833, -8.0261],
      [110.5833, -8.0252],
      [110.5831, -8.0248],
      [110.5826, -8.0245],
      [110.5826, -8.0239],
      [110.5829, -8.0222],
      [110.5834, -8.0213],
      [110.5835, -8.0201],
      [110.5835, -8.0191],
      [110.5835, -8.0183],
      [110.5834, -8.0178],
      [110.5832, -8.0172],
      [110.5831, -8.0166],
      [110.5832, -8.0159],
      [110.5832, -8.0144],
      [110.5833, -8.0137],
      [110.5833, -8.013],
      [110.5833, -8.0129],
      [110.5836, -8.0126],
      [110.5835, -8.0124],
      [110.5835, -8.0122],
      [110.5834, -8.012],
      [110.5833, -8.0118],
      [110.5833, -8.0117],
      [110.5833, -8.0113],
      [110.5832, -8.0108],
      [110.5831, -8.0103],
      [110.5831, -8.0098],
      [110.583, -8.0095],
      [110.5829, -8.0094],
      [110.5828, -8.0091],
      [110.5829, -8.0087],
      [110.5829, -8.0084]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'WONOSARI',
    village: 'KARANGREJEK',
    border: [
      [110.6044, -7.9791],
      [110.6028, -7.9787],
      [110.6021, -7.9784],
      [110.6017, -7.9782],
      [110.6001, -7.9778],
      [110.599, -7.9771],
      [110.5982, -7.9773],
      [110.5978, -7.9776],
      [110.5974, -7.9783],
      [110.5971, -7.9788],
      [110.5969, -7.9782],
      [110.5966, -7.9772],
      [110.5956, -7.9765],
      [110.5953, -7.9762],
      [110.5948, -7.9755],
      [110.5944, -7.9754],
      [110.5939, -7.9764],
      [110.5933, -7.9776],
      [110.5924, -7.9777],
      [110.5918, -7.9775],
      [110.5909, -7.9777],
      [110.5904, -7.9775],
      [110.5903, -7.978],
      [110.5885, -7.9781],
      [110.5877, -7.978],
      [110.5876, -7.9779],
      [110.5879, -7.9784],
      [110.588, -7.9784],
      [110.5882, -7.9784],
      [110.5883, -7.9783],
      [110.5884, -7.9783],
      [110.5888, -7.9788],
      [110.5888, -7.9791],
      [110.5888, -7.9794],
      [110.5889, -7.9797],
      [110.5889, -7.9802],
      [110.5889, -7.9809],
      [110.5882, -7.9812],
      [110.5873, -7.9815],
      [110.5869, -7.9815],
      [110.5867, -7.9818],
      [110.5863, -7.9823],
      [110.5858, -7.9826],
      [110.5858, -7.9828],
      [110.5858, -7.9829],
      [110.5857, -7.9832],
      [110.5853, -7.9832],
      [110.5853, -7.9835],
      [110.5849, -7.9838],
      [110.585, -7.9841],
      [110.5854, -7.9851],
      [110.5853, -7.986],
      [110.5853, -7.9866],
      [110.5851, -7.9879],
      [110.5852, -7.9884],
      [110.5855, -7.9894],
      [110.5854, -7.9908],
      [110.5855, -7.9909],
      [110.5877, -7.9913],
      [110.5883, -7.9915],
      [110.5908, -7.9933],
      [110.5932, -7.995],
      [110.5932, -7.9949],
      [110.5934, -7.9941],
      [110.597, -7.9953],
      [110.5987, -7.9958],
      [110.6004, -7.996],
      [110.6028, -7.996],
      [110.6032, -7.9956],
      [110.6033, -7.9952],
      [110.6033, -7.9935],
      [110.6037, -7.9925],
      [110.6041, -7.9924],
      [110.6043, -7.9923],
      [110.6052, -7.9923],
      [110.6072, -7.9926],
      [110.6093, -7.993],
      [110.6113, -7.9934],
      [110.6123, -7.9934],
      [110.613, -7.9934],
      [110.6136, -7.9933],
      [110.6138, -7.9929],
      [110.614, -7.9924],
      [110.6138, -7.9913],
      [110.6134, -7.9908],
      [110.6126, -7.9901],
      [110.6122, -7.9898],
      [110.611, -7.9898],
      [110.6106, -7.99],
      [110.6094, -7.9891],
      [110.6091, -7.9888],
      [110.609, -7.9887],
      [110.6088, -7.9887],
      [110.6087, -7.9887],
      [110.6085, -7.9876],
      [110.6081, -7.986],
      [110.6076, -7.9839],
      [110.6074, -7.983],
      [110.6067, -7.9827],
      [110.6061, -7.9826],
      [110.6054, -7.9826],
      [110.6049, -7.9814],
      [110.6047, -7.9811],
      [110.6047, -7.9805],
      [110.6046, -7.9797],
      [110.6044, -7.9791]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGLIPAR',
    village: 'NATAH',
    border: [
      [110.6533, -7.8206],
      [110.6527, -7.8211],
      [110.6515, -7.8219],
      [110.6509, -7.8225],
      [110.6504, -7.8226],
      [110.65, -7.8225],
      [110.6496, -7.8221],
      [110.6495, -7.822],
      [110.6491, -7.8215],
      [110.6488, -7.8207],
      [110.6483, -7.8206],
      [110.6479, -7.8205],
      [110.6475, -7.8208],
      [110.6473, -7.8211],
      [110.6472, -7.8219],
      [110.6475, -7.823],
      [110.6474, -7.8236],
      [110.647, -7.8244],
      [110.6473, -7.8247],
      [110.6479, -7.8254],
      [110.6491, -7.8267],
      [110.6492, -7.8281],
      [110.6488, -7.8297],
      [110.6481, -7.8312],
      [110.6481, -7.8319],
      [110.6482, -7.8327],
      [110.6485, -7.8336],
      [110.6491, -7.8342],
      [110.6496, -7.8346],
      [110.6496, -7.8349],
      [110.6497, -7.8352],
      [110.6495, -7.8355],
      [110.6491, -7.836],
      [110.6486, -7.837],
      [110.6485, -7.8374],
      [110.6488, -7.8381],
      [110.6492, -7.8391],
      [110.6491, -7.8394],
      [110.6489, -7.8397],
      [110.6484, -7.8399],
      [110.6482, -7.8399],
      [110.6475, -7.8403],
      [110.6465, -7.8406],
      [110.6459, -7.8411],
      [110.6454, -7.842],
      [110.6454, -7.8425],
      [110.6461, -7.8446],
      [110.6463, -7.8453],
      [110.6466, -7.8464],
      [110.6467, -7.8472],
      [110.6472, -7.8485],
      [110.6473, -7.8486],
      [110.6475, -7.8494],
      [110.6474, -7.8504],
      [110.6476, -7.851],
      [110.6476, -7.8513],
      [110.6477, -7.8516],
      [110.6481, -7.8519],
      [110.6483, -7.8523],
      [110.6487, -7.8529],
      [110.649, -7.853],
      [110.6497, -7.8534],
      [110.6497, -7.8542],
      [110.6503, -7.8552],
      [110.6505, -7.8557],
      [110.6505, -7.856],
      [110.6505, -7.8563],
      [110.6506, -7.8566],
      [110.651, -7.8566],
      [110.6512, -7.8568],
      [110.6515, -7.8571],
      [110.6519, -7.8572],
      [110.652, -7.8575],
      [110.652, -7.8577],
      [110.6518, -7.8581],
      [110.6516, -7.8586],
      [110.6516, -7.8589],
      [110.6519, -7.859],
      [110.6522, -7.8593],
      [110.6524, -7.8595],
      [110.6524, -7.8596],
      [110.6529, -7.8596],
      [110.653, -7.8596],
      [110.6532, -7.8597],
      [110.6533, -7.8598],
      [110.6533, -7.8608],
      [110.6531, -7.861],
      [110.6528, -7.8613],
      [110.6527, -7.8615],
      [110.6528, -7.8619],
      [110.6528, -7.8622],
      [110.653, -7.8626],
      [110.6532, -7.8626],
      [110.6535, -7.8623],
      [110.654, -7.8624],
      [110.6543, -7.8627],
      [110.6543, -7.863],
      [110.6543, -7.8635],
      [110.6544, -7.8637],
      [110.6546, -7.8641],
      [110.6559, -7.8644],
      [110.6572, -7.8648],
      [110.6585, -7.8652],
      [110.6589, -7.8653],
      [110.661, -7.8653],
      [110.6623, -7.8653],
      [110.6634, -7.8653],
      [110.6635, -7.8653],
      [110.6639, -7.8649],
      [110.6644, -7.8639],
      [110.666, -7.8639],
      [110.6672, -7.8642],
      [110.6678, -7.8643],
      [110.6679, -7.8638],
      [110.6681, -7.8628],
      [110.6683, -7.8618],
      [110.6684, -7.8611],
      [110.6683, -7.86],
      [110.6681, -7.8591],
      [110.6679, -7.8585],
      [110.6674, -7.8577],
      [110.6676, -7.8567],
      [110.667, -7.855],
      [110.6666, -7.8543],
      [110.6656, -7.853],
      [110.6652, -7.852],
      [110.6652, -7.8515],
      [110.6653, -7.8502],
      [110.6654, -7.8494],
      [110.6655, -7.8482],
      [110.6656, -7.8473],
      [110.6658, -7.8463],
      [110.666, -7.8452],
      [110.666, -7.8434],
      [110.666, -7.8424],
      [110.6662, -7.8414],
      [110.6662, -7.8408],
      [110.6661, -7.8403],
      [110.666, -7.8402],
      [110.6659, -7.8398],
      [110.6656, -7.8393],
      [110.6653, -7.8388],
      [110.6649, -7.8385],
      [110.6646, -7.8382],
      [110.6645, -7.8381],
      [110.6632, -7.8374],
      [110.6623, -7.8368],
      [110.6617, -7.8362],
      [110.6615, -7.8356],
      [110.6616, -7.8347],
      [110.6616, -7.8344],
      [110.6616, -7.8333],
      [110.6615, -7.8321],
      [110.6615, -7.8317],
      [110.6617, -7.8312],
      [110.6621, -7.8305],
      [110.6622, -7.8302],
      [110.6624, -7.8299],
      [110.6625, -7.8295],
      [110.6627, -7.8287],
      [110.663, -7.8273],
      [110.6631, -7.8256],
      [110.663, -7.8253],
      [110.6629, -7.8251],
      [110.6627, -7.8249],
      [110.6621, -7.8249],
      [110.66, -7.8249],
      [110.6575, -7.825],
      [110.6558, -7.825],
      [110.6549, -7.8248],
      [110.6541, -7.8246],
      [110.6536, -7.8242],
      [110.6531, -7.8232],
      [110.6531, -7.8219],
      [110.6533, -7.8209],
      [110.6533, -7.8206]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGLIPAR',
    village: 'PILANGREJO',
    border: [
      [110.6544, -7.8637],
      [110.6543, -7.8635],
      [110.6543, -7.863],
      [110.6543, -7.8627],
      [110.654, -7.8624],
      [110.6535, -7.8623],
      [110.6532, -7.8626],
      [110.653, -7.8626],
      [110.6528, -7.8622],
      [110.6528, -7.8619],
      [110.6527, -7.8615],
      [110.6528, -7.8613],
      [110.6531, -7.861],
      [110.6533, -7.8608],
      [110.6533, -7.8598],
      [110.6532, -7.8597],
      [110.653, -7.8596],
      [110.6529, -7.8596],
      [110.6524, -7.8596],
      [110.6524, -7.8595],
      [110.6522, -7.8593],
      [110.6519, -7.859],
      [110.6516, -7.8589],
      [110.6516, -7.8586],
      [110.6518, -7.8581],
      [110.652, -7.8577],
      [110.652, -7.8575],
      [110.6519, -7.8572],
      [110.6515, -7.8571],
      [110.6512, -7.8568],
      [110.651, -7.8566],
      [110.6506, -7.8566],
      [110.6505, -7.8563],
      [110.6505, -7.856],
      [110.6505, -7.8557],
      [110.6503, -7.8552],
      [110.6497, -7.8542],
      [110.6497, -7.8534],
      [110.649, -7.853],
      [110.6487, -7.8529],
      [110.6483, -7.8523],
      [110.6481, -7.8519],
      [110.6477, -7.8516],
      [110.6476, -7.8513],
      [110.6476, -7.851],
      [110.6474, -7.8504],
      [110.6475, -7.8494],
      [110.6473, -7.8486],
      [110.6472, -7.8485],
      [110.6467, -7.8472],
      [110.6466, -7.8464],
      [110.6463, -7.8453],
      [110.6461, -7.8446],
      [110.6454, -7.8425],
      [110.6454, -7.842],
      [110.6459, -7.8411],
      [110.6465, -7.8406],
      [110.6475, -7.8403],
      [110.6482, -7.8399],
      [110.6484, -7.8399],
      [110.6489, -7.8397],
      [110.6491, -7.8394],
      [110.6492, -7.8391],
      [110.6488, -7.8381],
      [110.6485, -7.8374],
      [110.6486, -7.837],
      [110.6491, -7.836],
      [110.6495, -7.8355],
      [110.6497, -7.8352],
      [110.6496, -7.8349],
      [110.6496, -7.8346],
      [110.6491, -7.8342],
      [110.6485, -7.8336],
      [110.6482, -7.8327],
      [110.6481, -7.8319],
      [110.6481, -7.8312],
      [110.6488, -7.8297],
      [110.6492, -7.8281],
      [110.6491, -7.8267],
      [110.6479, -7.8254],
      [110.6473, -7.8247],
      [110.647, -7.8244],
      [110.647, -7.8245],
      [110.6463, -7.8255],
      [110.6453, -7.8266],
      [110.6444, -7.8272],
      [110.6423, -7.8287],
      [110.6405, -7.8298],
      [110.6385, -7.8303],
      [110.6361, -7.8307],
      [110.636, -7.8307],
      [110.6341, -7.8308],
      [110.6337, -7.8311],
      [110.6325, -7.8322],
      [110.6313, -7.8331],
      [110.6295, -7.8342],
      [110.6279, -7.8354],
      [110.627, -7.8358],
      [110.6263, -7.836],
      [110.6257, -7.836],
      [110.625, -7.8359],
      [110.6243, -7.8357],
      [110.6236, -7.8359],
      [110.6229, -7.8362],
      [110.6224, -7.8366],
      [110.6218, -7.8371],
      [110.6212, -7.8377],
      [110.6205, -7.8382],
      [110.6202, -7.8385],
      [110.6195, -7.8389],
      [110.6191, -7.8393],
      [110.6187, -7.8396],
      [110.6183, -7.84],
      [110.6178, -7.8403],
      [110.6183, -7.8409],
      [110.6185, -7.8411],
      [110.6188, -7.8431],
      [110.6189, -7.8433],
      [110.6191, -7.8439],
      [110.6191, -7.8445],
      [110.6194, -7.8447],
      [110.6192, -7.8454],
      [110.6194, -7.846],
      [110.6198, -7.8463],
      [110.6199, -7.8468],
      [110.6199, -7.8475],
      [110.6198, -7.8479],
      [110.62, -7.8484],
      [110.6203, -7.8485],
      [110.6208, -7.8485],
      [110.621, -7.8485],
      [110.6211, -7.8485],
      [110.6212, -7.8488],
      [110.6215, -7.8489],
      [110.622, -7.8489],
      [110.6223, -7.849],
      [110.6226, -7.8491],
      [110.6229, -7.849],
      [110.6233, -7.8488],
      [110.6236, -7.8486],
      [110.6239, -7.8487],
      [110.6241, -7.849],
      [110.6242, -7.8496],
      [110.6244, -7.85],
      [110.6247, -7.8503],
      [110.625, -7.8504],
      [110.6253, -7.8507],
      [110.6261, -7.8508],
      [110.6277, -7.8514],
      [110.6287, -7.8519],
      [110.6294, -7.8523],
      [110.6301, -7.8531],
      [110.6306, -7.8543],
      [110.6313, -7.8562],
      [110.6323, -7.8582],
      [110.6338, -7.8593],
      [110.635, -7.8594],
      [110.6372, -7.8595],
      [110.6371, -7.86],
      [110.6372, -7.8602],
      [110.6375, -7.8606],
      [110.6377, -7.8607],
      [110.638, -7.8605],
      [110.6382, -7.8605],
      [110.6384, -7.8604],
      [110.6387, -7.8605],
      [110.6388, -7.8607],
      [110.6389, -7.8608],
      [110.6388, -7.8609],
      [110.6385, -7.861],
      [110.6382, -7.8611],
      [110.6378, -7.8614],
      [110.6379, -7.8618],
      [110.638, -7.8624],
      [110.638, -7.8626],
      [110.638, -7.8631],
      [110.6378, -7.8636],
      [110.6376, -7.8642],
      [110.6376, -7.8649],
      [110.6379, -7.8654],
      [110.6384, -7.8656],
      [110.6388, -7.8656],
      [110.6397, -7.8654],
      [110.64, -7.8659],
      [110.64, -7.8662],
      [110.6401, -7.8663],
      [110.6402, -7.8663],
      [110.6405, -7.8664],
      [110.6408, -7.8664],
      [110.641, -7.8667],
      [110.641, -7.8674],
      [110.6413, -7.8676],
      [110.6417, -7.8676],
      [110.6422, -7.8672],
      [110.6425, -7.8671],
      [110.6427, -7.867],
      [110.643, -7.867],
      [110.6437, -7.8669],
      [110.6445, -7.8668],
      [110.6474, -7.8675],
      [110.649, -7.8678],
      [110.6501, -7.8679],
      [110.6511, -7.868],
      [110.6525, -7.8677],
      [110.6529, -7.8674],
      [110.6529, -7.8672],
      [110.6528, -7.8671],
      [110.6528, -7.867],
      [110.6528, -7.8667],
      [110.653, -7.8663],
      [110.6532, -7.8661],
      [110.6534, -7.8658],
      [110.6534, -7.8657],
      [110.6531, -7.8654],
      [110.6531, -7.8651],
      [110.6533, -7.8648],
      [110.6533, -7.8647],
      [110.654, -7.8643],
      [110.6543, -7.864],
      [110.6544, -7.8638],
      [110.6544, -7.8637]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGLIPAR',
    village: 'KEDUNGPOH',
    border: [
      [110.6831, -7.8975],
      [110.6829, -7.8974],
      [110.6796, -7.8973],
      [110.6777, -7.8972],
      [110.6761, -7.8968],
      [110.6748, -7.8963],
      [110.6722, -7.8953],
      [110.6717, -7.895],
      [110.6715, -7.895],
      [110.6703, -7.8949],
      [110.669, -7.8949],
      [110.6683, -7.8949],
      [110.6673, -7.8956],
      [110.6667, -7.8959],
      [110.666, -7.8957],
      [110.6644, -7.8946],
      [110.6624, -7.8937],
      [110.6609, -7.8935],
      [110.6606, -7.8933],
      [110.6599, -7.8926],
      [110.6594, -7.8916],
      [110.659, -7.8909],
      [110.6578, -7.8904],
      [110.657, -7.8904],
      [110.6566, -7.8899],
      [110.6571, -7.8883],
      [110.6573, -7.8875],
      [110.6575, -7.887],
      [110.6572, -7.8867],
      [110.657, -7.8865],
      [110.6567, -7.8864],
      [110.6561, -7.886],
      [110.6558, -7.8858],
      [110.6549, -7.8862],
      [110.6541, -7.8866],
      [110.654, -7.8866],
      [110.6534, -7.8867],
      [110.653, -7.8868],
      [110.6526, -7.8868],
      [110.6518, -7.8865],
      [110.6513, -7.8861],
      [110.651, -7.8855],
      [110.6512, -7.885],
      [110.6512, -7.8848],
      [110.6512, -7.8847],
      [110.6512, -7.8846],
      [110.6511, -7.8844],
      [110.651, -7.8837],
      [110.651, -7.8834],
      [110.6513, -7.8826],
      [110.6512, -7.8815],
      [110.6511, -7.8812],
      [110.6509, -7.8808],
      [110.6503, -7.8806],
      [110.6498, -7.8806],
      [110.6495, -7.8808],
      [110.6495, -7.881],
      [110.6488, -7.8809],
      [110.6483, -7.8804],
      [110.6475, -7.8799],
      [110.647, -7.8797],
      [110.647, -7.8794],
      [110.646, -7.8794],
      [110.6458, -7.8795],
      [110.6457, -7.8795],
      [110.6451, -7.8798],
      [110.6449, -7.8797],
      [110.6441, -7.88],
      [110.6427, -7.8803],
      [110.6412, -7.8804],
      [110.6411, -7.8805],
      [110.6407, -7.8805],
      [110.6397, -7.8809],
      [110.6398, -7.8803],
      [110.6397, -7.88],
      [110.6395, -7.8796],
      [110.6403, -7.8797],
      [110.6409, -7.8794],
      [110.6412, -7.8788],
      [110.6412, -7.8782],
      [110.6413, -7.878],
      [110.6427, -7.8774],
      [110.6426, -7.8763],
      [110.6425, -7.8752],
      [110.6422, -7.8751],
      [110.6414, -7.8735],
      [110.6412, -7.8733],
      [110.6389, -7.8709],
      [110.6387, -7.8708],
      [110.6385, -7.8706],
      [110.6381, -7.8703],
      [110.6378, -7.87],
      [110.6362, -7.8685],
      [110.6356, -7.8684],
      [110.6333, -7.8685],
      [110.6329, -7.8685],
      [110.6324, -7.8679],
      [110.6318, -7.8669],
      [110.6316, -7.8666],
      [110.6311, -7.8657],
      [110.6311, -7.8647],
      [110.6316, -7.8633],
      [110.6319, -7.8626],
      [110.6319, -7.862],
      [110.6315, -7.8614],
      [110.6311, -7.8609],
      [110.631, -7.8609],
      [110.6304, -7.8605],
      [110.6303, -7.8605],
      [110.629, -7.86],
      [110.6284, -7.8598],
      [110.6272, -7.8598],
      [110.6263, -7.8598],
      [110.6255, -7.8597],
      [110.6254, -7.8597],
      [110.6252, -7.8607],
      [110.625, -7.8613],
      [110.6249, -7.8613],
      [110.6242, -7.8618],
      [110.6234, -7.862],
      [110.6225, -7.862],
      [110.6215, -7.8619],
      [110.6213, -7.8617],
      [110.6205, -7.8613],
      [110.6199, -7.8604],
      [110.6188, -7.8593],
      [110.6175, -7.8588],
      [110.6172, -7.8583],
      [110.6168, -7.8573],
      [110.6165, -7.857],
      [110.6162, -7.8567],
      [110.6161, -7.8562],
      [110.6159, -7.8559],
      [110.6158, -7.8551],
      [110.6156, -7.855],
      [110.6156, -7.8543],
      [110.6153, -7.8535],
      [110.6152, -7.8529],
      [110.6153, -7.8525],
      [110.6148, -7.8509],
      [110.6146, -7.8505],
      [110.6146, -7.85],
      [110.6146, -7.8495],
      [110.6137, -7.8493],
      [110.6133, -7.8493],
      [110.613, -7.8488],
      [110.6128, -7.8487],
      [110.6126, -7.8483],
      [110.6127, -7.848],
      [110.6128, -7.8477],
      [110.6126, -7.8473],
      [110.6125, -7.8479],
      [110.612, -7.8481],
      [110.6114, -7.8483],
      [110.6109, -7.8486],
      [110.6104, -7.8488],
      [110.61, -7.8492],
      [110.6094, -7.8493],
      [110.6088, -7.8493],
      [110.6085, -7.8496],
      [110.608, -7.8499],
      [110.6075, -7.8499],
      [110.6071, -7.8501],
      [110.607, -7.8501],
      [110.6063, -7.8505],
      [110.6058, -7.8508],
      [110.6059, -7.8511],
      [110.6062, -7.8516],
      [110.6064, -7.8521],
      [110.6066, -7.8524],
      [110.6066, -7.8526],
      [110.6067, -7.8535],
      [110.6065, -7.8547],
      [110.6062, -7.8558],
      [110.606, -7.8575],
      [110.6063, -7.8578],
      [110.6065, -7.8582],
      [110.6067, -7.8585],
      [110.6069, -7.8591],
      [110.6073, -7.8597],
      [110.6075, -7.8599],
      [110.6079, -7.8602],
      [110.6078, -7.8608],
      [110.608, -7.8613],
      [110.608, -7.8618],
      [110.6078, -7.862],
      [110.6079, -7.8625],
      [110.6077, -7.863],
      [110.6076, -7.8634],
      [110.607, -7.8638],
      [110.6063, -7.8645],
      [110.606, -7.865],
      [110.6062, -7.8655],
      [110.6059, -7.8664],
      [110.6059, -7.8666],
      [110.6061, -7.8668],
      [110.6065, -7.867],
      [110.6066, -7.8671],
      [110.6069, -7.8679],
      [110.6068, -7.8684],
      [110.6069, -7.8686],
      [110.6072, -7.8686],
      [110.6074, -7.8685],
      [110.6076, -7.8684],
      [110.6078, -7.8685],
      [110.608, -7.8685],
      [110.6081, -7.8688],
      [110.6083, -7.8692],
      [110.6084, -7.8696],
      [110.6083, -7.8698],
      [110.6086, -7.8698],
      [110.6089, -7.8698],
      [110.6091, -7.87],
      [110.6091, -7.8703],
      [110.6088, -7.8705],
      [110.6089, -7.8706],
      [110.6095, -7.8707],
      [110.6096, -7.8706],
      [110.6099, -7.8702],
      [110.61, -7.8701],
      [110.6102, -7.8699],
      [110.6107, -7.8697],
      [110.6108, -7.8697],
      [110.611, -7.8698],
      [110.6111, -7.8698],
      [110.6113, -7.8698],
      [110.6116, -7.87],
      [110.6118, -7.8703],
      [110.6123, -7.8711],
      [110.6127, -7.8714],
      [110.6131, -7.8717],
      [110.6139, -7.8727],
      [110.614, -7.8733],
      [110.614, -7.8741],
      [110.614, -7.875],
      [110.6138, -7.8753],
      [110.614, -7.8758],
      [110.6145, -7.8766],
      [110.615, -7.8774],
      [110.6151, -7.8775],
      [110.6152, -7.8776],
      [110.6153, -7.8777],
      [110.6155, -7.8783],
      [110.6158, -7.8802],
      [110.6157, -7.8807],
      [110.6155, -7.8809],
      [110.6153, -7.8811],
      [110.6153, -7.8814],
      [110.6156, -7.8816],
      [110.6158, -7.8818],
      [110.6162, -7.8814],
      [110.6172, -7.8807],
      [110.6177, -7.8801],
      [110.6186, -7.8792],
      [110.6194, -7.8787],
      [110.6204, -7.8782],
      [110.6219, -7.8779],
      [110.6224, -7.8777],
      [110.6232, -7.8776],
      [110.6243, -7.8773],
      [110.6251, -7.8773],
      [110.6256, -7.8773],
      [110.6263, -7.8776],
      [110.6271, -7.8781],
      [110.6283, -7.8788],
      [110.6292, -7.8794],
      [110.6294, -7.8794],
      [110.6301, -7.879],
      [110.6305, -7.8791],
      [110.6316, -7.8795],
      [110.6321, -7.8798],
      [110.6324, -7.8802],
      [110.6326, -7.8803],
      [110.6328, -7.8804],
      [110.6333, -7.8811],
      [110.6336, -7.8811],
      [110.6337, -7.8813],
      [110.6339, -7.8813],
      [110.6345, -7.8816],
      [110.6346, -7.8819],
      [110.6348, -7.8826],
      [110.6348, -7.8827],
      [110.6347, -7.883],
      [110.6348, -7.8833],
      [110.6362, -7.8854],
      [110.6381, -7.8858],
      [110.641, -7.8862],
      [110.6418, -7.8863],
      [110.6426, -7.8862],
      [110.6435, -7.886],
      [110.6443, -7.8859],
      [110.6455, -7.886],
      [110.646, -7.886],
      [110.6462, -7.8861],
      [110.6465, -7.8865],
      [110.647, -7.8872],
      [110.6475, -7.8877],
      [110.6484, -7.8883],
      [110.649, -7.889],
      [110.6496, -7.8899],
      [110.6505, -7.8918],
      [110.6514, -7.894],
      [110.6517, -7.8945],
      [110.6519, -7.8948],
      [110.6524, -7.8949],
      [110.6536, -7.895],
      [110.6538, -7.895],
      [110.6541, -7.8963],
      [110.6543, -7.897],
      [110.6551, -7.8982],
      [110.6555, -7.8989],
      [110.6553, -7.8996],
      [110.6549, -7.8997],
      [110.6541, -7.8998],
      [110.6538, -7.8997],
      [110.6535, -7.8996],
      [110.6529, -7.8995],
      [110.6527, -7.8995],
      [110.6522, -7.8995],
      [110.652, -7.8997],
      [110.652, -7.9002],
      [110.6525, -7.901],
      [110.653, -7.9014],
      [110.6539, -7.9019],
      [110.6544, -7.9021],
      [110.6545, -7.902],
      [110.6547, -7.902],
      [110.6549, -7.9018],
      [110.655, -7.9016],
      [110.6556, -7.9012],
      [110.6562, -7.9016],
      [110.6567, -7.9019],
      [110.657, -7.9024],
      [110.6577, -7.9025],
      [110.6579, -7.9026],
      [110.6582, -7.9035],
      [110.6585, -7.9045],
      [110.6588, -7.9049],
      [110.6591, -7.9053],
      [110.6597, -7.9061],
      [110.6602, -7.9061],
      [110.6603, -7.9066],
      [110.6608, -7.906],
      [110.6614, -7.9056],
      [110.6619, -7.9052],
      [110.6626, -7.9048],
      [110.663, -7.9047],
      [110.6638, -7.9046],
      [110.664, -7.9045],
      [110.6642, -7.9039],
      [110.6644, -7.9033],
      [110.6654, -7.9042],
      [110.6672, -7.9044],
      [110.6686, -7.9043],
      [110.6692, -7.9043],
      [110.6705, -7.904],
      [110.6712, -7.9039],
      [110.6724, -7.9034],
      [110.6733, -7.9031],
      [110.6744, -7.9032],
      [110.6746, -7.9039],
      [110.675, -7.9051],
      [110.6753, -7.9058],
      [110.6754, -7.9059],
      [110.6755, -7.9061],
      [110.676, -7.9056],
      [110.6764, -7.9051],
      [110.6765, -7.9046],
      [110.6765, -7.904],
      [110.6765, -7.9036],
      [110.676, -7.9028],
      [110.6767, -7.9025],
      [110.6775, -7.902],
      [110.6777, -7.9018],
      [110.6776, -7.9015],
      [110.6773, -7.9003],
      [110.6773, -7.8998],
      [110.6777, -7.8994],
      [110.6807, -7.8994],
      [110.683, -7.8993],
      [110.6831, -7.8977],
      [110.6831, -7.8975]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGLIPAR',
    village: 'PENGKOL',
    border: [
      [110.6058, -7.8508],
      [110.6057, -7.8507],
      [110.6051, -7.8506],
      [110.6046, -7.8505],
      [110.6045, -7.8505],
      [110.6042, -7.8504],
      [110.6041, -7.8503],
      [110.6037, -7.8502],
      [110.6033, -7.8502],
      [110.6028, -7.8502],
      [110.6027, -7.8503],
      [110.6026, -7.8503],
      [110.6024, -7.8503],
      [110.6021, -7.8504],
      [110.602, -7.8504],
      [110.6017, -7.8504],
      [110.6015, -7.8503],
      [110.6013, -7.8505],
      [110.601, -7.8507],
      [110.6003, -7.8507],
      [110.5995, -7.8507],
      [110.599, -7.8508],
      [110.5989, -7.8509],
      [110.5988, -7.8511],
      [110.5987, -7.8516],
      [110.598, -7.8521],
      [110.5976, -7.8527],
      [110.596, -7.8541],
      [110.5952, -7.8549],
      [110.5945, -7.8556],
      [110.5937, -7.8563],
      [110.5931, -7.8569],
      [110.5926, -7.8574],
      [110.5915, -7.858],
      [110.5907, -7.8585],
      [110.5905, -7.8586],
      [110.5894, -7.8592],
      [110.589, -7.8597],
      [110.5883, -7.8606],
      [110.5876, -7.8618],
      [110.5868, -7.8635],
      [110.5863, -7.8654],
      [110.5863, -7.8657],
      [110.5863, -7.8672],
      [110.5863, -7.8679],
      [110.5862, -7.8691],
      [110.5867, -7.8697],
      [110.5872, -7.8706],
      [110.5876, -7.8718],
      [110.5876, -7.8724],
      [110.5874, -7.8732],
      [110.5873, -7.8739],
      [110.5874, -7.8748],
      [110.5873, -7.8752],
      [110.5872, -7.8762],
      [110.587, -7.877],
      [110.5867, -7.8777],
      [110.5866, -7.8779],
      [110.5864, -7.8788],
      [110.5864, -7.8794],
      [110.5864, -7.8796],
      [110.5864, -7.8804],
      [110.5867, -7.8807],
      [110.5872, -7.8811],
      [110.5876, -7.8815],
      [110.5883, -7.8823],
      [110.5889, -7.883],
      [110.5893, -7.8834],
      [110.5901, -7.8824],
      [110.5908, -7.8814],
      [110.5914, -7.8809],
      [110.5918, -7.8804],
      [110.5922, -7.8802],
      [110.5929, -7.88],
      [110.5931, -7.8798],
      [110.5932, -7.8795],
      [110.5933, -7.8792],
      [110.5937, -7.8787],
      [110.5943, -7.8782],
      [110.5943, -7.8778],
      [110.5944, -7.8778],
      [110.5955, -7.8776],
      [110.5959, -7.8782],
      [110.5965, -7.8783],
      [110.5975, -7.8783],
      [110.5979, -7.8784],
      [110.5988, -7.8791],
      [110.5994, -7.8794],
      [110.6, -7.8795],
      [110.6005, -7.8797],
      [110.6012, -7.8802],
      [110.6011, -7.8813],
      [110.6012, -7.8816],
      [110.6018, -7.8823],
      [110.6023, -7.8826],
      [110.6033, -7.8832],
      [110.6037, -7.8836],
      [110.6038, -7.8837],
      [110.6041, -7.8839],
      [110.6043, -7.8846],
      [110.6045, -7.8847],
      [110.605, -7.8848],
      [110.6056, -7.8848],
      [110.606, -7.8848],
      [110.6068, -7.8848],
      [110.6072, -7.8848],
      [110.6076, -7.8845],
      [110.6077, -7.8841],
      [110.6081, -7.884],
      [110.6089, -7.8841],
      [110.6092, -7.8843],
      [110.6102, -7.8851],
      [110.6104, -7.8855],
      [110.6108, -7.8858],
      [110.6111, -7.8858],
      [110.6117, -7.8858],
      [110.6118, -7.8857],
      [110.6123, -7.8854],
      [110.6128, -7.8849],
      [110.6131, -7.8845],
      [110.6135, -7.8837],
      [110.614, -7.8833],
      [110.6157, -7.8818],
      [110.6156, -7.8816],
      [110.6153, -7.8814],
      [110.6153, -7.8811],
      [110.6155, -7.8809],
      [110.6157, -7.8807],
      [110.6158, -7.8802],
      [110.6155, -7.8783],
      [110.6153, -7.8777],
      [110.6152, -7.8776],
      [110.6151, -7.8775],
      [110.615, -7.8774],
      [110.6145, -7.8766],
      [110.614, -7.8758],
      [110.6138, -7.8753],
      [110.614, -7.875],
      [110.614, -7.8741],
      [110.614, -7.8733],
      [110.6139, -7.8727],
      [110.6131, -7.8717],
      [110.6127, -7.8714],
      [110.6123, -7.8711],
      [110.6118, -7.8703],
      [110.6116, -7.87],
      [110.6113, -7.8698],
      [110.6111, -7.8698],
      [110.611, -7.8698],
      [110.6108, -7.8697],
      [110.6107, -7.8697],
      [110.6102, -7.8699],
      [110.61, -7.8701],
      [110.6099, -7.8702],
      [110.6096, -7.8706],
      [110.6095, -7.8707],
      [110.6089, -7.8706],
      [110.6088, -7.8705],
      [110.6091, -7.8703],
      [110.6091, -7.87],
      [110.6089, -7.8698],
      [110.6086, -7.8698],
      [110.6083, -7.8698],
      [110.6084, -7.8696],
      [110.6083, -7.8692],
      [110.6081, -7.8688],
      [110.608, -7.8685],
      [110.6078, -7.8685],
      [110.6076, -7.8684],
      [110.6074, -7.8685],
      [110.6072, -7.8686],
      [110.6069, -7.8686],
      [110.6068, -7.8684],
      [110.6069, -7.8679],
      [110.6066, -7.8671],
      [110.6065, -7.867],
      [110.6061, -7.8668],
      [110.6059, -7.8666],
      [110.6059, -7.8664],
      [110.6062, -7.8655],
      [110.606, -7.865],
      [110.6063, -7.8645],
      [110.607, -7.8638],
      [110.6076, -7.8634],
      [110.6077, -7.863],
      [110.6079, -7.8625],
      [110.6078, -7.862],
      [110.608, -7.8618],
      [110.608, -7.8613],
      [110.6078, -7.8608],
      [110.6079, -7.8602],
      [110.6075, -7.8599],
      [110.6073, -7.8597],
      [110.6069, -7.8591],
      [110.6067, -7.8585],
      [110.6065, -7.8582],
      [110.6063, -7.8578],
      [110.606, -7.8575],
      [110.6062, -7.8558],
      [110.6065, -7.8547],
      [110.6067, -7.8535],
      [110.6066, -7.8526],
      [110.6066, -7.8524],
      [110.6064, -7.8521],
      [110.6062, -7.8516],
      [110.6059, -7.8511],
      [110.6058, -7.8508]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGLIPAR',
    village: 'KEDUNGKERIS',
    border: [
      [110.6118, -7.8857],
      [110.6117, -7.8858],
      [110.6111, -7.8858],
      [110.6108, -7.8858],
      [110.6104, -7.8855],
      [110.6102, -7.8851],
      [110.6092, -7.8843],
      [110.6089, -7.8841],
      [110.6081, -7.884],
      [110.6077, -7.8841],
      [110.6076, -7.8845],
      [110.6072, -7.8848],
      [110.6068, -7.8848],
      [110.606, -7.8848],
      [110.6056, -7.8848],
      [110.605, -7.8848],
      [110.6045, -7.8847],
      [110.6043, -7.8846],
      [110.6041, -7.8839],
      [110.6038, -7.8837],
      [110.6037, -7.8836],
      [110.6033, -7.8832],
      [110.6023, -7.8826],
      [110.6018, -7.8823],
      [110.6012, -7.8816],
      [110.6011, -7.8813],
      [110.6012, -7.8802],
      [110.6005, -7.8797],
      [110.6, -7.8795],
      [110.5994, -7.8794],
      [110.5988, -7.8791],
      [110.5979, -7.8784],
      [110.5975, -7.8783],
      [110.5965, -7.8783],
      [110.5959, -7.8782],
      [110.5955, -7.8776],
      [110.5944, -7.8778],
      [110.5943, -7.8778],
      [110.5943, -7.8782],
      [110.5937, -7.8787],
      [110.5933, -7.8792],
      [110.5932, -7.8795],
      [110.5931, -7.8798],
      [110.5929, -7.88],
      [110.5922, -7.8802],
      [110.5918, -7.8804],
      [110.5914, -7.8809],
      [110.5908, -7.8814],
      [110.5901, -7.8824],
      [110.5893, -7.8834],
      [110.5899, -7.884],
      [110.5909, -7.8848],
      [110.591, -7.8853],
      [110.5908, -7.8858],
      [110.5905, -7.8863],
      [110.5901, -7.8869],
      [110.5902, -7.8875],
      [110.5904, -7.8883],
      [110.5904, -7.8892],
      [110.5902, -7.8898],
      [110.5899, -7.8906],
      [110.5897, -7.8918],
      [110.5896, -7.8931],
      [110.5892, -7.8934],
      [110.5886, -7.8941],
      [110.5885, -7.8945],
      [110.5884, -7.895],
      [110.5885, -7.8954],
      [110.5886, -7.896],
      [110.5885, -7.8966],
      [110.5886, -7.8971],
      [110.5885, -7.8974],
      [110.5882, -7.8978],
      [110.588, -7.8983],
      [110.5877, -7.8989],
      [110.5873, -7.8998],
      [110.5873, -7.9],
      [110.5871, -7.9004],
      [110.5872, -7.9004],
      [110.5877, -7.9002],
      [110.5882, -7.9003],
      [110.5883, -7.9005],
      [110.5883, -7.9007],
      [110.5883, -7.9011],
      [110.5881, -7.9016],
      [110.5881, -7.902],
      [110.5885, -7.9023],
      [110.5893, -7.9029],
      [110.59, -7.9035],
      [110.5901, -7.9036],
      [110.5906, -7.9043],
      [110.5907, -7.9044],
      [110.5908, -7.9045],
      [110.5911, -7.9048],
      [110.5916, -7.9051],
      [110.5921, -7.9055],
      [110.5928, -7.9061],
      [110.5936, -7.9066],
      [110.5947, -7.9071],
      [110.5955, -7.9073],
      [110.5958, -7.9074],
      [110.5958, -7.9074],
      [110.5961, -7.9074],
      [110.5966, -7.9073],
      [110.5975, -7.9075],
      [110.5984, -7.9083],
      [110.5991, -7.909],
      [110.6004, -7.9093],
      [110.6007, -7.9093],
      [110.6016, -7.9093],
      [110.6025, -7.909],
      [110.6026, -7.9089],
      [110.6027, -7.9089],
      [110.6033, -7.9084],
      [110.604, -7.9084],
      [110.6048, -7.9082],
      [110.6054, -7.9083],
      [110.6056, -7.9085],
      [110.6057, -7.9086],
      [110.6061, -7.9089],
      [110.6066, -7.9089],
      [110.6072, -7.9088],
      [110.6075, -7.9089],
      [110.6077, -7.9092],
      [110.6078, -7.9093],
      [110.6082, -7.9103],
      [110.6087, -7.9108],
      [110.6093, -7.9113],
      [110.6096, -7.912],
      [110.6099, -7.9125],
      [110.6102, -7.9126],
      [110.6103, -7.9127],
      [110.6108, -7.9127],
      [110.6112, -7.913],
      [110.6113, -7.9132],
      [110.6117, -7.914],
      [110.6117, -7.914],
      [110.6124, -7.9145],
      [110.6126, -7.9147],
      [110.613, -7.915],
      [110.6137, -7.9155],
      [110.614, -7.9157],
      [110.6146, -7.916],
      [110.6149, -7.9161],
      [110.6156, -7.9165],
      [110.6158, -7.9164],
      [110.6162, -7.9163],
      [110.6171, -7.9159],
      [110.6175, -7.9161],
      [110.6191, -7.9165],
      [110.6197, -7.9167],
      [110.6203, -7.9167],
      [110.6209, -7.9165],
      [110.6215, -7.9165],
      [110.6222, -7.9163],
      [110.6231, -7.916],
      [110.6242, -7.9157],
      [110.6249, -7.9154],
      [110.6256, -7.9153],
      [110.6266, -7.915],
      [110.6268, -7.9149],
      [110.6274, -7.9149],
      [110.6277, -7.9146],
      [110.6277, -7.9143],
      [110.6278, -7.9138],
      [110.6277, -7.9131],
      [110.6275, -7.9124],
      [110.6275, -7.9122],
      [110.6277, -7.9117],
      [110.6279, -7.9111],
      [110.6279, -7.911],
      [110.6283, -7.9105],
      [110.6285, -7.9105],
      [110.6286, -7.9104],
      [110.6291, -7.9104],
      [110.6291, -7.9103],
      [110.6292, -7.9096],
      [110.6296, -7.9088],
      [110.6297, -7.9084],
      [110.6291, -7.9073],
      [110.6285, -7.9064],
      [110.6283, -7.9055],
      [110.6285, -7.9044],
      [110.6283, -7.9036],
      [110.6284, -7.9031],
      [110.6282, -7.9017],
      [110.6273, -7.8999],
      [110.6268, -7.8988],
      [110.6265, -7.8982],
      [110.6263, -7.8978],
      [110.626, -7.8973],
      [110.6254, -7.8971],
      [110.6247, -7.8965],
      [110.6244, -7.8963],
      [110.6241, -7.8963],
      [110.6237, -7.8968],
      [110.6234, -7.897],
      [110.6228, -7.8983],
      [110.6223, -7.8993],
      [110.6216, -7.9004],
      [110.6212, -7.9014],
      [110.621, -7.9014],
      [110.6205, -7.9011],
      [110.6201, -7.9007],
      [110.6197, -7.9004],
      [110.619, -7.9002],
      [110.6183, -7.9001],
      [110.6175, -7.9],
      [110.6167, -7.8996],
      [110.6157, -7.8991],
      [110.6153, -7.8989],
      [110.6149, -7.8986],
      [110.614, -7.898],
      [110.6132, -7.8972],
      [110.6129, -7.8963],
      [110.6127, -7.8958],
      [110.6128, -7.895],
      [110.6128, -7.8941],
      [110.6128, -7.8933],
      [110.6126, -7.8922],
      [110.6119, -7.8917],
      [110.6125, -7.8909],
      [110.6127, -7.8907],
      [110.6128, -7.8906],
      [110.6137, -7.8905],
      [110.6136, -7.8902],
      [110.6136, -7.89],
      [110.6127, -7.8891],
      [110.6122, -7.8885],
      [110.6119, -7.8876],
      [110.612, -7.8871],
      [110.612, -7.8864],
      [110.6118, -7.8857]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGLIPAR',
    village: 'NGLIPAR',
    border: [
      [110.6603, -7.9066],
      [110.6602, -7.9061],
      [110.6597, -7.9061],
      [110.6591, -7.9053],
      [110.6588, -7.9049],
      [110.6585, -7.9045],
      [110.6582, -7.9035],
      [110.6579, -7.9026],
      [110.6577, -7.9025],
      [110.657, -7.9024],
      [110.6567, -7.9019],
      [110.6562, -7.9016],
      [110.6556, -7.9012],
      [110.655, -7.9016],
      [110.6549, -7.9018],
      [110.6547, -7.902],
      [110.6545, -7.902],
      [110.6544, -7.9021],
      [110.6539, -7.9019],
      [110.653, -7.9014],
      [110.6525, -7.901],
      [110.652, -7.9002],
      [110.652, -7.8997],
      [110.6522, -7.8995],
      [110.6527, -7.8995],
      [110.6529, -7.8995],
      [110.6535, -7.8996],
      [110.6538, -7.8997],
      [110.6541, -7.8998],
      [110.6549, -7.8997],
      [110.6553, -7.8996],
      [110.6555, -7.8989],
      [110.6551, -7.8982],
      [110.6543, -7.897],
      [110.6541, -7.8963],
      [110.6538, -7.895],
      [110.6536, -7.895],
      [110.6524, -7.8949],
      [110.6519, -7.8948],
      [110.6517, -7.8945],
      [110.6514, -7.894],
      [110.6505, -7.8918],
      [110.6496, -7.8899],
      [110.649, -7.889],
      [110.6484, -7.8883],
      [110.6475, -7.8877],
      [110.647, -7.8872],
      [110.6465, -7.8865],
      [110.6462, -7.8861],
      [110.646, -7.886],
      [110.6455, -7.886],
      [110.6443, -7.8859],
      [110.6435, -7.886],
      [110.6426, -7.8862],
      [110.6418, -7.8863],
      [110.641, -7.8862],
      [110.6381, -7.8858],
      [110.6362, -7.8854],
      [110.6348, -7.8833],
      [110.6347, -7.883],
      [110.6348, -7.8827],
      [110.6348, -7.8826],
      [110.6346, -7.8819],
      [110.6345, -7.8816],
      [110.6339, -7.8813],
      [110.6337, -7.8813],
      [110.6336, -7.8811],
      [110.6333, -7.8811],
      [110.6328, -7.8804],
      [110.6326, -7.8803],
      [110.6324, -7.8802],
      [110.6321, -7.8798],
      [110.6316, -7.8795],
      [110.6305, -7.8791],
      [110.6301, -7.879],
      [110.6294, -7.8794],
      [110.6292, -7.8794],
      [110.6283, -7.8788],
      [110.6271, -7.8781],
      [110.6263, -7.8776],
      [110.6256, -7.8773],
      [110.6251, -7.8773],
      [110.6243, -7.8773],
      [110.6232, -7.8776],
      [110.6224, -7.8777],
      [110.6219, -7.8779],
      [110.6204, -7.8782],
      [110.6194, -7.8787],
      [110.6186, -7.8792],
      [110.6177, -7.8801],
      [110.6172, -7.8807],
      [110.6162, -7.8814],
      [110.6158, -7.8818],
      [110.6157, -7.8818],
      [110.614, -7.8833],
      [110.6135, -7.8837],
      [110.6131, -7.8845],
      [110.6128, -7.8849],
      [110.6123, -7.8854],
      [110.6118, -7.8857],
      [110.612, -7.8864],
      [110.612, -7.8871],
      [110.6119, -7.8876],
      [110.6122, -7.8885],
      [110.6127, -7.8891],
      [110.6136, -7.89],
      [110.6136, -7.8902],
      [110.6137, -7.8905],
      [110.6128, -7.8906],
      [110.6127, -7.8907],
      [110.6125, -7.8909],
      [110.6119, -7.8917],
      [110.6126, -7.8922],
      [110.6128, -7.8933],
      [110.6128, -7.8941],
      [110.6128, -7.895],
      [110.6127, -7.8958],
      [110.6129, -7.8963],
      [110.6132, -7.8972],
      [110.614, -7.898],
      [110.6149, -7.8986],
      [110.6153, -7.8989],
      [110.6157, -7.8991],
      [110.6167, -7.8996],
      [110.6175, -7.9],
      [110.6183, -7.9001],
      [110.619, -7.9002],
      [110.6197, -7.9004],
      [110.6201, -7.9007],
      [110.6205, -7.9011],
      [110.621, -7.9014],
      [110.6212, -7.9014],
      [110.6216, -7.9004],
      [110.6223, -7.8993],
      [110.6228, -7.8983],
      [110.6234, -7.897],
      [110.6237, -7.8968],
      [110.6241, -7.8963],
      [110.6244, -7.8963],
      [110.6247, -7.8965],
      [110.6254, -7.8971],
      [110.626, -7.8973],
      [110.6263, -7.8978],
      [110.6265, -7.8982],
      [110.6268, -7.8988],
      [110.6273, -7.8999],
      [110.6282, -7.9017],
      [110.6284, -7.9031],
      [110.6283, -7.9036],
      [110.6285, -7.9044],
      [110.6283, -7.9055],
      [110.6285, -7.9064],
      [110.6291, -7.9073],
      [110.6297, -7.9084],
      [110.6296, -7.9088],
      [110.6292, -7.9096],
      [110.6291, -7.9103],
      [110.6291, -7.9104],
      [110.6296, -7.9105],
      [110.6299, -7.9105],
      [110.6301, -7.9106],
      [110.6306, -7.9104],
      [110.631, -7.9101],
      [110.6312, -7.9099],
      [110.6316, -7.9097],
      [110.6318, -7.9096],
      [110.6319, -7.9095],
      [110.6323, -7.9097],
      [110.6327, -7.91],
      [110.633, -7.9104],
      [110.6332, -7.9114],
      [110.6334, -7.9124],
      [110.6337, -7.9127],
      [110.6343, -7.9131],
      [110.6344, -7.9132],
      [110.6349, -7.913],
      [110.6351, -7.9129],
      [110.6353, -7.9129],
      [110.6357, -7.913],
      [110.636, -7.9132],
      [110.6363, -7.9134],
      [110.6367, -7.9135],
      [110.6369, -7.9134],
      [110.6372, -7.9134],
      [110.6374, -7.9134],
      [110.6376, -7.9134],
      [110.6379, -7.9135],
      [110.6382, -7.9139],
      [110.6384, -7.9143],
      [110.6389, -7.9146],
      [110.6393, -7.9146],
      [110.6396, -7.9145],
      [110.6397, -7.9143],
      [110.6397, -7.914],
      [110.6399, -7.9136],
      [110.6404, -7.9134],
      [110.6409, -7.9132],
      [110.6414, -7.9134],
      [110.6418, -7.9135],
      [110.6425, -7.9139],
      [110.6426, -7.914],
      [110.643, -7.9141],
      [110.6432, -7.9142],
      [110.6435, -7.9144],
      [110.6436, -7.9149],
      [110.6438, -7.9159],
      [110.6439, -7.9165],
      [110.6442, -7.9168],
      [110.6442, -7.9169],
      [110.645, -7.9172],
      [110.6467, -7.9173],
      [110.6482, -7.9173],
      [110.6488, -7.9169],
      [110.6489, -7.917],
      [110.6491, -7.9172],
      [110.6494, -7.9173],
      [110.6498, -7.9173],
      [110.65, -7.9173],
      [110.6502, -7.9172],
      [110.651, -7.917],
      [110.6514, -7.9167],
      [110.6517, -7.9167],
      [110.6519, -7.9167],
      [110.6522, -7.9168],
      [110.6523, -7.9168],
      [110.653, -7.9169],
      [110.6541, -7.9169],
      [110.6544, -7.9163],
      [110.6548, -7.9155],
      [110.6552, -7.9148],
      [110.6554, -7.9139],
      [110.6554, -7.9126],
      [110.6557, -7.9122],
      [110.6558, -7.9121],
      [110.6563, -7.9114],
      [110.6566, -7.911],
      [110.6572, -7.9112],
      [110.6574, -7.9112],
      [110.6574, -7.9109],
      [110.6577, -7.9108],
      [110.6579, -7.9105],
      [110.6582, -7.9105],
      [110.6583, -7.9101],
      [110.6584, -7.9099],
      [110.6588, -7.9097],
      [110.6593, -7.9095],
      [110.6596, -7.9094],
      [110.6596, -7.9092],
      [110.6595, -7.9086],
      [110.6593, -7.9077],
      [110.6591, -7.9068],
      [110.6598, -7.9067],
      [110.6602, -7.9067],
      [110.6603, -7.9066]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGLIPAR',
    village: 'KATONGAN',
    border: [
      [110.6544, -7.8637],
      [110.6544, -7.8638],
      [110.6543, -7.864],
      [110.654, -7.8643],
      [110.6533, -7.8647],
      [110.6533, -7.8648],
      [110.6531, -7.8651],
      [110.6531, -7.8654],
      [110.6534, -7.8657],
      [110.6534, -7.8658],
      [110.6532, -7.8661],
      [110.653, -7.8663],
      [110.6528, -7.8667],
      [110.6528, -7.867],
      [110.6528, -7.8671],
      [110.6529, -7.8672],
      [110.6529, -7.8674],
      [110.6525, -7.8677],
      [110.6511, -7.868],
      [110.6501, -7.8679],
      [110.649, -7.8678],
      [110.6474, -7.8675],
      [110.6445, -7.8668],
      [110.6437, -7.8669],
      [110.643, -7.867],
      [110.6427, -7.867],
      [110.6425, -7.8671],
      [110.6422, -7.8672],
      [110.6417, -7.8676],
      [110.6413, -7.8676],
      [110.641, -7.8674],
      [110.641, -7.8667],
      [110.6408, -7.8664],
      [110.6405, -7.8664],
      [110.6402, -7.8663],
      [110.6401, -7.8663],
      [110.64, -7.8662],
      [110.64, -7.8659],
      [110.6397, -7.8654],
      [110.6388, -7.8656],
      [110.6384, -7.8656],
      [110.6379, -7.8654],
      [110.6376, -7.8649],
      [110.6376, -7.8642],
      [110.6378, -7.8636],
      [110.638, -7.8631],
      [110.638, -7.8626],
      [110.638, -7.8624],
      [110.6379, -7.8618],
      [110.6378, -7.8614],
      [110.6382, -7.8611],
      [110.6385, -7.861],
      [110.6388, -7.8609],
      [110.6389, -7.8608],
      [110.6388, -7.8607],
      [110.6387, -7.8605],
      [110.6384, -7.8604],
      [110.6382, -7.8605],
      [110.638, -7.8605],
      [110.6377, -7.8607],
      [110.6375, -7.8606],
      [110.6372, -7.8602],
      [110.6371, -7.86],
      [110.6372, -7.8595],
      [110.635, -7.8594],
      [110.6338, -7.8593],
      [110.6323, -7.8582],
      [110.6313, -7.8562],
      [110.6306, -7.8543],
      [110.6301, -7.8531],
      [110.6294, -7.8523],
      [110.6287, -7.8519],
      [110.6277, -7.8514],
      [110.6261, -7.8508],
      [110.6253, -7.8507],
      [110.625, -7.8504],
      [110.6247, -7.8503],
      [110.6244, -7.85],
      [110.6242, -7.8496],
      [110.6241, -7.849],
      [110.6239, -7.8487],
      [110.6236, -7.8486],
      [110.6233, -7.8488],
      [110.6229, -7.849],
      [110.6226, -7.8491],
      [110.6223, -7.849],
      [110.622, -7.8489],
      [110.6215, -7.8489],
      [110.6212, -7.8488],
      [110.6211, -7.8485],
      [110.621, -7.8485],
      [110.6208, -7.8485],
      [110.6203, -7.8485],
      [110.62, -7.8484],
      [110.6198, -7.8479],
      [110.6199, -7.8475],
      [110.6199, -7.8468],
      [110.6198, -7.8463],
      [110.6194, -7.846],
      [110.6192, -7.8454],
      [110.6194, -7.8447],
      [110.6191, -7.8445],
      [110.6191, -7.8439],
      [110.6189, -7.8433],
      [110.6188, -7.8431],
      [110.6185, -7.8411],
      [110.6183, -7.8409],
      [110.6178, -7.8403],
      [110.617, -7.8406],
      [110.6166, -7.8408],
      [110.6159, -7.8409],
      [110.6153, -7.8411],
      [110.615, -7.841],
      [110.6146, -7.8415],
      [110.6142, -7.8417],
      [110.614, -7.842],
      [110.6135, -7.8425],
      [110.613, -7.8425],
      [110.6126, -7.8427],
      [110.612, -7.8427],
      [110.6116, -7.8428],
      [110.6113, -7.8434],
      [110.6111, -7.844],
      [110.6114, -7.8447],
      [110.6118, -7.8458],
      [110.6126, -7.8473],
      [110.6128, -7.8477],
      [110.6127, -7.848],
      [110.6126, -7.8483],
      [110.6128, -7.8487],
      [110.613, -7.8488],
      [110.6133, -7.8493],
      [110.6137, -7.8493],
      [110.6146, -7.8495],
      [110.6146, -7.85],
      [110.6146, -7.8505],
      [110.6148, -7.8509],
      [110.6153, -7.8525],
      [110.6152, -7.8529],
      [110.6153, -7.8535],
      [110.6156, -7.8543],
      [110.6156, -7.855],
      [110.6158, -7.8551],
      [110.6159, -7.8559],
      [110.6161, -7.8562],
      [110.6162, -7.8567],
      [110.6165, -7.857],
      [110.6168, -7.8573],
      [110.6172, -7.8583],
      [110.6175, -7.8588],
      [110.6188, -7.8593],
      [110.6199, -7.8604],
      [110.6205, -7.8613],
      [110.6213, -7.8617],
      [110.6215, -7.8619],
      [110.6225, -7.862],
      [110.6234, -7.862],
      [110.6242, -7.8618],
      [110.6249, -7.8613],
      [110.625, -7.8613],
      [110.6252, -7.8607],
      [110.6254, -7.8597],
      [110.6255, -7.8597],
      [110.6263, -7.8598],
      [110.6272, -7.8598],
      [110.6284, -7.8598],
      [110.629, -7.86],
      [110.6303, -7.8605],
      [110.6304, -7.8605],
      [110.631, -7.8609],
      [110.6311, -7.8609],
      [110.6315, -7.8614],
      [110.6319, -7.862],
      [110.6319, -7.8626],
      [110.6316, -7.8633],
      [110.6311, -7.8647],
      [110.6311, -7.8657],
      [110.6316, -7.8666],
      [110.6318, -7.8669],
      [110.6324, -7.8679],
      [110.6329, -7.8685],
      [110.6333, -7.8685],
      [110.6356, -7.8684],
      [110.6362, -7.8685],
      [110.6378, -7.87],
      [110.6381, -7.8703],
      [110.6385, -7.8706],
      [110.6387, -7.8708],
      [110.6389, -7.8709],
      [110.6412, -7.8733],
      [110.6414, -7.8735],
      [110.6422, -7.8751],
      [110.6425, -7.8752],
      [110.6426, -7.8763],
      [110.6427, -7.8774],
      [110.6413, -7.878],
      [110.6412, -7.8782],
      [110.6412, -7.8788],
      [110.6409, -7.8794],
      [110.6403, -7.8797],
      [110.6395, -7.8796],
      [110.6397, -7.88],
      [110.6398, -7.8803],
      [110.6397, -7.8809],
      [110.6407, -7.8805],
      [110.6411, -7.8805],
      [110.6412, -7.8804],
      [110.6427, -7.8803],
      [110.6441, -7.88],
      [110.6449, -7.8797],
      [110.6451, -7.8798],
      [110.6457, -7.8795],
      [110.6458, -7.8795],
      [110.646, -7.8794],
      [110.647, -7.8794],
      [110.647, -7.8797],
      [110.6475, -7.8799],
      [110.6483, -7.8804],
      [110.6488, -7.8809],
      [110.6495, -7.881],
      [110.6495, -7.8808],
      [110.6498, -7.8806],
      [110.6503, -7.8806],
      [110.6509, -7.8808],
      [110.6511, -7.8812],
      [110.6512, -7.8815],
      [110.6513, -7.8826],
      [110.651, -7.8834],
      [110.651, -7.8837],
      [110.6511, -7.8844],
      [110.6512, -7.8846],
      [110.6512, -7.8847],
      [110.6512, -7.8848],
      [110.6512, -7.885],
      [110.651, -7.8855],
      [110.6513, -7.8861],
      [110.6518, -7.8865],
      [110.6526, -7.8868],
      [110.653, -7.8868],
      [110.6534, -7.8867],
      [110.654, -7.8866],
      [110.6541, -7.8866],
      [110.6549, -7.8862],
      [110.6558, -7.8858],
      [110.6561, -7.886],
      [110.6567, -7.8864],
      [110.657, -7.8865],
      [110.6572, -7.8867],
      [110.6575, -7.887],
      [110.6573, -7.8875],
      [110.6571, -7.8883],
      [110.6566, -7.8899],
      [110.657, -7.8904],
      [110.6578, -7.8904],
      [110.659, -7.8909],
      [110.6594, -7.8916],
      [110.6599, -7.8926],
      [110.6606, -7.8933],
      [110.6609, -7.8935],
      [110.6624, -7.8937],
      [110.6644, -7.8946],
      [110.666, -7.8957],
      [110.6667, -7.8959],
      [110.6673, -7.8956],
      [110.6683, -7.8949],
      [110.669, -7.8949],
      [110.6703, -7.8949],
      [110.6715, -7.895],
      [110.6717, -7.895],
      [110.6722, -7.8953],
      [110.6748, -7.8963],
      [110.6761, -7.8968],
      [110.6777, -7.8972],
      [110.6796, -7.8973],
      [110.6829, -7.8974],
      [110.6831, -7.8975],
      [110.6831, -7.8973],
      [110.6832, -7.8957],
      [110.6801, -7.8955],
      [110.6799, -7.8955],
      [110.6799, -7.8945],
      [110.6805, -7.8941],
      [110.681, -7.8939],
      [110.6833, -7.8925],
      [110.6841, -7.8921],
      [110.6845, -7.8901],
      [110.6847, -7.8897],
      [110.6848, -7.8896],
      [110.6847, -7.8895],
      [110.6846, -7.8894],
      [110.6843, -7.8897],
      [110.6838, -7.89],
      [110.6827, -7.8896],
      [110.681, -7.887],
      [110.6804, -7.8861],
      [110.6812, -7.8843],
      [110.6813, -7.884],
      [110.6812, -7.8833],
      [110.6811, -7.8831],
      [110.6804, -7.8831],
      [110.6795, -7.8829],
      [110.6788, -7.8827],
      [110.6779, -7.8824],
      [110.6774, -7.8821],
      [110.677, -7.8817],
      [110.6765, -7.8811],
      [110.6761, -7.881],
      [110.6756, -7.8801],
      [110.6748, -7.879],
      [110.6746, -7.8787],
      [110.6743, -7.8787],
      [110.6742, -7.878],
      [110.6742, -7.8776],
      [110.6735, -7.8771],
      [110.6729, -7.8768],
      [110.6724, -7.8764],
      [110.6719, -7.8762],
      [110.6714, -7.8756],
      [110.6708, -7.875],
      [110.6702, -7.8744],
      [110.67, -7.8736],
      [110.6698, -7.873],
      [110.6697, -7.8725],
      [110.6694, -7.8723],
      [110.6692, -7.8721],
      [110.6689, -7.8709],
      [110.6688, -7.8696],
      [110.6689, -7.8689],
      [110.6684, -7.8687],
      [110.6684, -7.8684],
      [110.668, -7.8682],
      [110.668, -7.8675],
      [110.6677, -7.8674],
      [110.6678, -7.867],
      [110.6676, -7.8669],
      [110.6677, -7.866],
      [110.6678, -7.8656],
      [110.6678, -7.8654],
      [110.6678, -7.8653],
      [110.6678, -7.8644],
      [110.6678, -7.8643],
      [110.6672, -7.8642],
      [110.666, -7.8639],
      [110.6644, -7.8639],
      [110.6639, -7.8649],
      [110.6635, -7.8653],
      [110.6634, -7.8653],
      [110.6623, -7.8653],
      [110.661, -7.8653],
      [110.6589, -7.8653],
      [110.6585, -7.8652],
      [110.6572, -7.8648],
      [110.6559, -7.8644],
      [110.6546, -7.8641],
      [110.6544, -7.8637]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'BANYUSOCO',
    border: [
      [110.4755, -7.9627],
      [110.4756, -7.963],
      [110.476, -7.9647],
      [110.4759, -7.9652],
      [110.4755, -7.9663],
      [110.4754, -7.9664],
      [110.4751, -7.9668],
      [110.4743, -7.9669],
      [110.4735, -7.9668],
      [110.473, -7.9667],
      [110.4728, -7.9669],
      [110.4727, -7.967],
      [110.4727, -7.9674],
      [110.4728, -7.9675],
      [110.4729, -7.9676],
      [110.473, -7.9678],
      [110.4739, -7.9682],
      [110.4747, -7.9686],
      [110.4759, -7.9692],
      [110.4769, -7.9698],
      [110.4771, -7.9702],
      [110.4772, -7.9703],
      [110.4773, -7.9704],
      [110.4774, -7.9707],
      [110.4774, -7.971],
      [110.4774, -7.9711],
      [110.4773, -7.9712],
      [110.4771, -7.9714],
      [110.4768, -7.9716],
      [110.4762, -7.9718],
      [110.4756, -7.9719],
      [110.4747, -7.9719],
      [110.4744, -7.9717],
      [110.4741, -7.9712],
      [110.4736, -7.9704],
      [110.4734, -7.9703],
      [110.4732, -7.9703],
      [110.4731, -7.9702],
      [110.4723, -7.97],
      [110.4717, -7.9701],
      [110.471, -7.97],
      [110.4698, -7.9699],
      [110.4692, -7.9698],
      [110.4689, -7.97],
      [110.4685, -7.9705],
      [110.4681, -7.9709],
      [110.4677, -7.9711],
      [110.4675, -7.9712],
      [110.4672, -7.9711],
      [110.4671, -7.9707],
      [110.4669, -7.9701],
      [110.4667, -7.9698],
      [110.4661, -7.9698],
      [110.4654, -7.9696],
      [110.4644, -7.9692],
      [110.4636, -7.9686],
      [110.4633, -7.9683],
      [110.4634, -7.9677],
      [110.4636, -7.9673],
      [110.4641, -7.9668],
      [110.4646, -7.9665],
      [110.4656, -7.9663],
      [110.4659, -7.9665],
      [110.4661, -7.9666],
      [110.4667, -7.9668],
      [110.4671, -7.9668],
      [110.4676, -7.9666],
      [110.4679, -7.9661],
      [110.4681, -7.9656],
      [110.4684, -7.9648],
      [110.4686, -7.9646],
      [110.4697, -7.964],
      [110.4703, -7.9636],
      [110.4706, -7.9629],
      [110.4706, -7.9623],
      [110.4703, -7.9619],
      [110.4698, -7.9616],
      [110.4688, -7.9612],
      [110.4674, -7.9607],
      [110.4673, -7.9606],
      [110.4667, -7.9603],
      [110.4661, -7.96],
      [110.4652, -7.9596],
      [110.4647, -7.9588],
      [110.4644, -7.9579],
      [110.4645, -7.9572],
      [110.4648, -7.9569],
      [110.4657, -7.9565],
      [110.4661, -7.9563],
      [110.4666, -7.9562],
      [110.4672, -7.9556],
      [110.4673, -7.9551],
      [110.467, -7.9546],
      [110.4666, -7.9542],
      [110.4661, -7.9541],
      [110.466, -7.9541],
      [110.4655, -7.9543],
      [110.465, -7.9546],
      [110.4646, -7.9551],
      [110.4642, -7.9552],
      [110.4634, -7.9552],
      [110.4625, -7.9552],
      [110.4621, -7.9553],
      [110.4614, -7.9555],
      [110.4607, -7.956],
      [110.46, -7.9565],
      [110.4591, -7.9567],
      [110.4581, -7.9569],
      [110.4575, -7.9572],
      [110.4571, -7.9575],
      [110.4567, -7.958],
      [110.4565, -7.9585],
      [110.4564, -7.9592],
      [110.4566, -7.9597],
      [110.4567, -7.9598],
      [110.4568, -7.96],
      [110.4569, -7.9601],
      [110.457, -7.9602],
      [110.4571, -7.9603],
      [110.4576, -7.9605],
      [110.4579, -7.9609],
      [110.458, -7.9614],
      [110.4578, -7.9619],
      [110.4575, -7.9626],
      [110.4575, -7.9633],
      [110.4574, -7.9637],
      [110.4574, -7.9641],
      [110.4574, -7.9652],
      [110.4573, -7.9656],
      [110.4571, -7.966],
      [110.4568, -7.9663],
      [110.4564, -7.9667],
      [110.4559, -7.9671],
      [110.4553, -7.9674],
      [110.4547, -7.9674],
      [110.4545, -7.967],
      [110.4543, -7.966],
      [110.4538, -7.965],
      [110.4534, -7.9642],
      [110.4527, -7.9637],
      [110.4519, -7.963],
      [110.4509, -7.9622],
      [110.4508, -7.9614],
      [110.4506, -7.9608],
      [110.4507, -7.9603],
      [110.4507, -7.9601],
      [110.4508, -7.9598],
      [110.4511, -7.9589],
      [110.4512, -7.9581],
      [110.4512, -7.9563],
      [110.4512, -7.9555],
      [110.4514, -7.955],
      [110.4521, -7.9547],
      [110.4533, -7.9541],
      [110.4538, -7.9537],
      [110.4545, -7.953],
      [110.4549, -7.9523],
      [110.4548, -7.9515],
      [110.4543, -7.9508],
      [110.4537, -7.9497],
      [110.4526, -7.9482],
      [110.4519, -7.9475],
      [110.4513, -7.9472],
      [110.4504, -7.947],
      [110.4494, -7.9469],
      [110.449, -7.9471],
      [110.4487, -7.9472],
      [110.4485, -7.9474],
      [110.4482, -7.9479],
      [110.4478, -7.9483],
      [110.447, -7.9486],
      [110.4461, -7.9489],
      [110.4455, -7.949],
      [110.4449, -7.9497],
      [110.4445, -7.9501],
      [110.4441, -7.9506],
      [110.4436, -7.9515],
      [110.4436, -7.9522],
      [110.4438, -7.9527],
      [110.4443, -7.954],
      [110.4445, -7.9551],
      [110.4446, -7.956],
      [110.4446, -7.9567],
      [110.4447, -7.9575],
      [110.4445, -7.9586],
      [110.4445, -7.9602],
      [110.4445, -7.9613],
      [110.4444, -7.9625],
      [110.4442, -7.9641],
      [110.4441, -7.9654],
      [110.4438, -7.9662],
      [110.4433, -7.967],
      [110.4425, -7.9673],
      [110.4412, -7.9673],
      [110.4408, -7.9673],
      [110.441, -7.9676],
      [110.4412, -7.9684],
      [110.4414, -7.9695],
      [110.4414, -7.9707],
      [110.4413, -7.9717],
      [110.4412, -7.9725],
      [110.4412, -7.9726],
      [110.4411, -7.9735],
      [110.4413, -7.9746],
      [110.4416, -7.9752],
      [110.4421, -7.9763],
      [110.4427, -7.9772],
      [110.4433, -7.9777],
      [110.4437, -7.9783],
      [110.4444, -7.9791],
      [110.4449, -7.9797],
      [110.4459, -7.9807],
      [110.447, -7.9819],
      [110.4477, -7.9831],
      [110.4489, -7.9845],
      [110.4496, -7.9851],
      [110.4503, -7.9859],
      [110.4509, -7.9871],
      [110.4516, -7.9887],
      [110.4522, -7.9896],
      [110.4533, -7.9908],
      [110.4545, -7.9921],
      [110.4554, -7.9928],
      [110.4569, -7.9937],
      [110.458, -7.9943],
      [110.4593, -7.9953],
      [110.4607, -7.9957],
      [110.462, -7.9956],
      [110.4634, -7.9952],
      [110.4641, -7.9948],
      [110.4645, -7.994],
      [110.4647, -7.993],
      [110.4647, -7.9928],
      [110.4647, -7.9926],
      [110.4647, -7.9922],
      [110.4646, -7.9915],
      [110.4642, -7.9908],
      [110.4638, -7.9899],
      [110.4635, -7.989],
      [110.4634, -7.9882],
      [110.4633, -7.9879],
      [110.4635, -7.9873],
      [110.4637, -7.9866],
      [110.4642, -7.986],
      [110.4644, -7.986],
      [110.466, -7.9858],
      [110.4689, -7.9861],
      [110.4716, -7.9865],
      [110.4728, -7.9865],
      [110.4739, -7.9863],
      [110.4755, -7.9862],
      [110.4761, -7.986],
      [110.4777, -7.9858],
      [110.4788, -7.9859],
      [110.4811, -7.9862],
      [110.4826, -7.9862],
      [110.4837, -7.986],
      [110.4862, -7.9857],
      [110.4877, -7.9859],
      [110.4876, -7.9849],
      [110.4878, -7.9838],
      [110.488, -7.9827],
      [110.4882, -7.9817],
      [110.4886, -7.9813],
      [110.4892, -7.9809],
      [110.4902, -7.9811],
      [110.4914, -7.9811],
      [110.4928, -7.9813],
      [110.4953, -7.9817],
      [110.496, -7.9818],
      [110.4967, -7.9819],
      [110.4979, -7.9816],
      [110.4989, -7.9815],
      [110.5, -7.9817],
      [110.5006, -7.9818],
      [110.5014, -7.9815],
      [110.5019, -7.9809],
      [110.5024, -7.9803],
      [110.5028, -7.9801],
      [110.5035, -7.9799],
      [110.5043, -7.9802],
      [110.5052, -7.9802],
      [110.5059, -7.98],
      [110.5061, -7.9798],
      [110.5067, -7.9796],
      [110.5075, -7.9791],
      [110.5073, -7.9783],
      [110.5078, -7.9764],
      [110.5085, -7.9757],
      [110.509, -7.9743],
      [110.5093, -7.9725],
      [110.5091, -7.9711],
      [110.5084, -7.9707],
      [110.5069, -7.9703],
      [110.5058, -7.9696],
      [110.5043, -7.9685],
      [110.5038, -7.9684],
      [110.503, -7.9684],
      [110.5019, -7.968],
      [110.4999, -7.9669],
      [110.4979, -7.9661],
      [110.4974, -7.9657],
      [110.4965, -7.965],
      [110.496, -7.9649],
      [110.4951, -7.9646],
      [110.4936, -7.9634],
      [110.4922, -7.9629],
      [110.4914, -7.9629],
      [110.4908, -7.9631],
      [110.4898, -7.9634],
      [110.4887, -7.9641],
      [110.4883, -7.9641],
      [110.4871, -7.9635],
      [110.4867, -7.9635],
      [110.486, -7.9637],
      [110.4854, -7.9637],
      [110.4848, -7.9635],
      [110.484, -7.9628],
      [110.4832, -7.9619],
      [110.4827, -7.962],
      [110.4824, -7.9622],
      [110.4822, -7.9622],
      [110.4817, -7.9629],
      [110.4816, -7.963],
      [110.4814, -7.963],
      [110.4813, -7.963],
      [110.4804, -7.9626],
      [110.4802, -7.9626],
      [110.4795, -7.9626],
      [110.4786, -7.9622],
      [110.4777, -7.9616],
      [110.4766, -7.9616],
      [110.4761, -7.9619],
      [110.4757, -7.9626],
      [110.4755, -7.9627]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'PLEMBUTAN',
    border: [
      [110.5618, -7.9532],
      [110.5615, -7.9532],
      [110.5611, -7.9536],
      [110.5607, -7.9537],
      [110.5603, -7.9537],
      [110.5599, -7.9543],
      [110.5584, -7.9542],
      [110.557, -7.9541],
      [110.5563, -7.9538],
      [110.5559, -7.954],
      [110.5555, -7.9536],
      [110.5541, -7.953],
      [110.5539, -7.9553],
      [110.5545, -7.9553],
      [110.5545, -7.9559],
      [110.5543, -7.9582],
      [110.5529, -7.9582],
      [110.5528, -7.9585],
      [110.5523, -7.9585],
      [110.5517, -7.9584],
      [110.5516, -7.9585],
      [110.551, -7.9584],
      [110.5506, -7.9584],
      [110.5507, -7.9577],
      [110.5491, -7.957],
      [110.549, -7.9561],
      [110.5474, -7.956],
      [110.5476, -7.955],
      [110.5477, -7.9546],
      [110.5474, -7.9547],
      [110.5471, -7.9562],
      [110.5463, -7.9562],
      [110.5462, -7.9569],
      [110.5457, -7.9564],
      [110.5452, -7.9564],
      [110.545, -7.9567],
      [110.5445, -7.9567],
      [110.5444, -7.9574],
      [110.5437, -7.9573],
      [110.5434, -7.9574],
      [110.5427, -7.9572],
      [110.5423, -7.9571],
      [110.542, -7.9578],
      [110.5406, -7.9573],
      [110.5394, -7.957],
      [110.5385, -7.957],
      [110.5382, -7.9563],
      [110.5382, -7.9555],
      [110.5384, -7.9548],
      [110.5387, -7.9542],
      [110.5384, -7.9539],
      [110.5379, -7.9536],
      [110.5371, -7.9541],
      [110.5366, -7.9542],
      [110.5365, -7.9547],
      [110.5365, -7.9558],
      [110.5364, -7.9559],
      [110.5355, -7.9562],
      [110.5346, -7.9566],
      [110.5344, -7.9571],
      [110.5343, -7.9577],
      [110.5339, -7.9583],
      [110.5336, -7.9585],
      [110.5336, -7.9588],
      [110.5336, -7.9593],
      [110.5339, -7.9595],
      [110.5342, -7.9596],
      [110.5346, -7.9597],
      [110.5347, -7.9598],
      [110.5346, -7.9601],
      [110.5344, -7.9604],
      [110.5344, -7.9607],
      [110.5345, -7.9609],
      [110.5352, -7.9615],
      [110.5357, -7.962],
      [110.5359, -7.9626],
      [110.5359, -7.9637],
      [110.5357, -7.9646],
      [110.5353, -7.9656],
      [110.534, -7.9678],
      [110.5324, -7.9703],
      [110.5318, -7.9714],
      [110.5313, -7.9727],
      [110.5305, -7.9736],
      [110.5292, -7.974],
      [110.5276, -7.9739],
      [110.527, -7.9737],
      [110.5268, -7.9735],
      [110.5264, -7.9729],
      [110.5259, -7.9726],
      [110.5245, -7.9724],
      [110.5236, -7.9722],
      [110.5228, -7.9732],
      [110.522, -7.9742],
      [110.5218, -7.9751],
      [110.5211, -7.9751],
      [110.5209, -7.9751],
      [110.5208, -7.9759],
      [110.5209, -7.9765],
      [110.5207, -7.9776],
      [110.5213, -7.9779],
      [110.5216, -7.9784],
      [110.5219, -7.979],
      [110.522, -7.9794],
      [110.5222, -7.9802],
      [110.5225, -7.9805],
      [110.5226, -7.9813],
      [110.5231, -7.9818],
      [110.5231, -7.9824],
      [110.5234, -7.9825],
      [110.5236, -7.9826],
      [110.5242, -7.9827],
      [110.5246, -7.9828],
      [110.5248, -7.9825],
      [110.5255, -7.9821],
      [110.5258, -7.9821],
      [110.5264, -7.9821],
      [110.5266, -7.9821],
      [110.5268, -7.9816],
      [110.527, -7.981],
      [110.5274, -7.9808],
      [110.5275, -7.9804],
      [110.5276, -7.9801],
      [110.5278, -7.9799],
      [110.5277, -7.9798],
      [110.5277, -7.9797],
      [110.5276, -7.9794],
      [110.5276, -7.9791],
      [110.5277, -7.9785],
      [110.5277, -7.9782],
      [110.5276, -7.9777],
      [110.5277, -7.9772],
      [110.5277, -7.9768],
      [110.5275, -7.9766],
      [110.5274, -7.9765],
      [110.5276, -7.9762],
      [110.5282, -7.976],
      [110.5291, -7.9758],
      [110.5298, -7.9756],
      [110.5308, -7.9754],
      [110.5316, -7.9752],
      [110.5323, -7.9748],
      [110.5329, -7.9746],
      [110.5338, -7.9747],
      [110.5342, -7.9758],
      [110.5346, -7.9763],
      [110.5355, -7.9765],
      [110.5361, -7.9769],
      [110.5369, -7.9768],
      [110.5376, -7.9765],
      [110.5381, -7.9762],
      [110.5384, -7.9755],
      [110.5389, -7.9749],
      [110.5396, -7.9743],
      [110.5401, -7.9739],
      [110.5409, -7.9742],
      [110.5417, -7.9746],
      [110.5426, -7.9747],
      [110.5432, -7.975],
      [110.5437, -7.9752],
      [110.5438, -7.9759],
      [110.5439, -7.9763],
      [110.544, -7.9769],
      [110.5439, -7.9775],
      [110.544, -7.978],
      [110.5446, -7.9782],
      [110.5452, -7.9785],
      [110.5454, -7.9789],
      [110.5455, -7.9794],
      [110.5454, -7.98],
      [110.5454, -7.9806],
      [110.5456, -7.9811],
      [110.5459, -7.9814],
      [110.5459, -7.9815],
      [110.5466, -7.9818],
      [110.5474, -7.9817],
      [110.5481, -7.9814],
      [110.5484, -7.9809],
      [110.5486, -7.9804],
      [110.5485, -7.9803],
      [110.5483, -7.9801],
      [110.5482, -7.9798],
      [110.549, -7.9795],
      [110.5496, -7.979],
      [110.5498, -7.9788],
      [110.5498, -7.9781],
      [110.5497, -7.9776],
      [110.5501, -7.9773],
      [110.5504, -7.9771],
      [110.5502, -7.9767],
      [110.5501, -7.9764],
      [110.5503, -7.9761],
      [110.5506, -7.9761],
      [110.5509, -7.9761],
      [110.5511, -7.9758],
      [110.5511, -7.9753],
      [110.5507, -7.9748],
      [110.5507, -7.9746],
      [110.5519, -7.9729],
      [110.5526, -7.9718],
      [110.553, -7.9708],
      [110.5531, -7.9706],
      [110.5541, -7.9705],
      [110.5543, -7.9699],
      [110.5546, -7.9693],
      [110.5544, -7.9692],
      [110.5547, -7.9685],
      [110.5551, -7.9679],
      [110.5551, -7.9676],
      [110.5553, -7.9672],
      [110.5555, -7.9669],
      [110.5559, -7.9663],
      [110.5561, -7.9658],
      [110.5562, -7.9655],
      [110.5564, -7.9649],
      [110.557, -7.965],
      [110.5572, -7.9645],
      [110.5572, -7.9643],
      [110.5569, -7.9642],
      [110.557, -7.9632],
      [110.557, -7.9626],
      [110.5571, -7.9622],
      [110.5567, -7.9619],
      [110.5572, -7.9612],
      [110.5577, -7.9605],
      [110.5576, -7.9602],
      [110.5571, -7.9597],
      [110.5569, -7.9594],
      [110.5571, -7.9589],
      [110.5575, -7.959],
      [110.5575, -7.9587],
      [110.5573, -7.958],
      [110.5577, -7.9581],
      [110.5579, -7.9578],
      [110.5579, -7.9567],
      [110.5584, -7.9569],
      [110.5589, -7.957],
      [110.5594, -7.9572],
      [110.5599, -7.9575],
      [110.5604, -7.9577],
      [110.5607, -7.9579],
      [110.561, -7.9582],
      [110.5612, -7.9572],
      [110.5612, -7.9565],
      [110.5614, -7.9558],
      [110.5615, -7.9552],
      [110.5614, -7.9548],
      [110.5612, -7.9544],
      [110.5611, -7.9539],
      [110.5613, -7.9537],
      [110.5616, -7.9535],
      [110.5618, -7.9532]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'BLEBERAN',
    border: [
      [110.5155, -7.9484],
      [110.5142, -7.9479],
      [110.5133, -7.9477],
      [110.5119, -7.947],
      [110.5103, -7.9466],
      [110.5063, -7.9454],
      [110.5051, -7.9456],
      [110.5044, -7.9457],
      [110.5038, -7.9455],
      [110.5032, -7.9453],
      [110.5025, -7.9447],
      [110.502, -7.9446],
      [110.5014, -7.9443],
      [110.5008, -7.9441],
      [110.5, -7.9441],
      [110.4994, -7.9442],
      [110.4991, -7.9441],
      [110.4985, -7.9437],
      [110.4973, -7.9433],
      [110.4959, -7.9425],
      [110.4952, -7.9424],
      [110.4945, -7.9422],
      [110.4934, -7.9421],
      [110.4926, -7.9421],
      [110.492, -7.942],
      [110.4913, -7.9419],
      [110.4905, -7.9416],
      [110.4895, -7.9414],
      [110.4896, -7.9416],
      [110.4896, -7.9418],
      [110.4896, -7.942],
      [110.4895, -7.9425],
      [110.4889, -7.9438],
      [110.4885, -7.9446],
      [110.4883, -7.945],
      [110.4879, -7.9451],
      [110.4873, -7.9447],
      [110.4866, -7.944],
      [110.4857, -7.9432],
      [110.485, -7.943],
      [110.4841, -7.9429],
      [110.4836, -7.9427],
      [110.4828, -7.9423],
      [110.482, -7.9421],
      [110.4818, -7.9421],
      [110.4814, -7.9424],
      [110.4807, -7.9426],
      [110.48, -7.9428],
      [110.4791, -7.9428],
      [110.4785, -7.9426],
      [110.4781, -7.9424],
      [110.4774, -7.9422],
      [110.4769, -7.9424],
      [110.4765, -7.9426],
      [110.4762, -7.9429],
      [110.4762, -7.9433],
      [110.4763, -7.9441],
      [110.4765, -7.9448],
      [110.4767, -7.9457],
      [110.4769, -7.9467],
      [110.4772, -7.9477],
      [110.4776, -7.9485],
      [110.4779, -7.9492],
      [110.478, -7.9496],
      [110.4781, -7.9503],
      [110.4778, -7.9506],
      [110.4775, -7.9508],
      [110.4769, -7.951],
      [110.4757, -7.9514],
      [110.4742, -7.9518],
      [110.4738, -7.952],
      [110.4734, -7.9525],
      [110.473, -7.9531],
      [110.4726, -7.9535],
      [110.4725, -7.9541],
      [110.4726, -7.9546],
      [110.4728, -7.9552],
      [110.473, -7.9557],
      [110.4736, -7.9569],
      [110.4739, -7.9579],
      [110.4741, -7.9585],
      [110.4744, -7.9592],
      [110.4744, -7.9594],
      [110.4746, -7.9602],
      [110.4755, -7.9627],
      [110.4757, -7.9626],
      [110.4761, -7.9619],
      [110.4766, -7.9616],
      [110.4777, -7.9616],
      [110.4786, -7.9622],
      [110.4795, -7.9626],
      [110.4802, -7.9626],
      [110.4804, -7.9626],
      [110.4813, -7.963],
      [110.4814, -7.963],
      [110.4816, -7.963],
      [110.4817, -7.9629],
      [110.4822, -7.9622],
      [110.4824, -7.9622],
      [110.4827, -7.962],
      [110.4832, -7.9619],
      [110.484, -7.9628],
      [110.4848, -7.9635],
      [110.4854, -7.9637],
      [110.486, -7.9637],
      [110.4867, -7.9635],
      [110.4871, -7.9635],
      [110.4883, -7.9641],
      [110.4887, -7.9641],
      [110.4898, -7.9634],
      [110.4908, -7.9631],
      [110.4914, -7.9629],
      [110.4922, -7.9629],
      [110.4936, -7.9634],
      [110.4951, -7.9646],
      [110.496, -7.9649],
      [110.4965, -7.965],
      [110.4974, -7.9657],
      [110.4979, -7.9661],
      [110.4999, -7.9669],
      [110.5019, -7.968],
      [110.503, -7.9684],
      [110.5038, -7.9684],
      [110.5043, -7.9685],
      [110.5058, -7.9696],
      [110.5069, -7.9703],
      [110.5084, -7.9707],
      [110.5091, -7.9711],
      [110.5093, -7.9725],
      [110.509, -7.9743],
      [110.5085, -7.9757],
      [110.5078, -7.9764],
      [110.5073, -7.9783],
      [110.5075, -7.9791],
      [110.5076, -7.9791],
      [110.5083, -7.9787],
      [110.5095, -7.9787],
      [110.5103, -7.9785],
      [110.5109, -7.9783],
      [110.5113, -7.9778],
      [110.5127, -7.9776],
      [110.5137, -7.9776],
      [110.5145, -7.9776],
      [110.5157, -7.9777],
      [110.5158, -7.9777],
      [110.5164, -7.9778],
      [110.5171, -7.9783],
      [110.5181, -7.9791],
      [110.5191, -7.9801],
      [110.5204, -7.9815],
      [110.5216, -7.9831],
      [110.5221, -7.984],
      [110.5223, -7.9843],
      [110.5232, -7.9837],
      [110.5237, -7.9835],
      [110.5243, -7.9832],
      [110.5246, -7.9828],
      [110.5242, -7.9827],
      [110.5236, -7.9826],
      [110.5234, -7.9825],
      [110.5231, -7.9824],
      [110.5231, -7.9818],
      [110.5226, -7.9813],
      [110.5225, -7.9805],
      [110.5222, -7.9802],
      [110.522, -7.9794],
      [110.5219, -7.979],
      [110.5216, -7.9784],
      [110.5213, -7.9779],
      [110.5207, -7.9776],
      [110.5209, -7.9765],
      [110.5208, -7.9759],
      [110.5209, -7.9751],
      [110.5211, -7.9751],
      [110.5218, -7.9751],
      [110.522, -7.9742],
      [110.5228, -7.9732],
      [110.5236, -7.9722],
      [110.5245, -7.9724],
      [110.5259, -7.9726],
      [110.5264, -7.9729],
      [110.5268, -7.9735],
      [110.527, -7.9737],
      [110.5276, -7.9739],
      [110.5292, -7.974],
      [110.5305, -7.9736],
      [110.5313, -7.9727],
      [110.5318, -7.9714],
      [110.5324, -7.9703],
      [110.534, -7.9678],
      [110.5353, -7.9656],
      [110.5357, -7.9646],
      [110.5359, -7.9637],
      [110.5359, -7.9626],
      [110.5357, -7.962],
      [110.5352, -7.9615],
      [110.5345, -7.9609],
      [110.5344, -7.9607],
      [110.5344, -7.9604],
      [110.5346, -7.9601],
      [110.5347, -7.9598],
      [110.5346, -7.9597],
      [110.5342, -7.9596],
      [110.5339, -7.9595],
      [110.5336, -7.9593],
      [110.5336, -7.9588],
      [110.5336, -7.9585],
      [110.5339, -7.9583],
      [110.5338, -7.9581],
      [110.5336, -7.958],
      [110.5329, -7.9578],
      [110.5315, -7.9572],
      [110.5306, -7.9571],
      [110.5308, -7.9556],
      [110.5298, -7.9556],
      [110.5294, -7.9566],
      [110.5287, -7.9589],
      [110.5274, -7.9587],
      [110.5266, -7.9583],
      [110.5258, -7.958],
      [110.5241, -7.9574],
      [110.5232, -7.9571],
      [110.5231, -7.956],
      [110.5228, -7.955],
      [110.5228, -7.9537],
      [110.5223, -7.9537],
      [110.5219, -7.9539],
      [110.5215, -7.9539],
      [110.5209, -7.9538],
      [110.5203, -7.9538],
      [110.5203, -7.9534],
      [110.5203, -7.9528],
      [110.5203, -7.9527],
      [110.5195, -7.9524],
      [110.5191, -7.9521],
      [110.5192, -7.9513],
      [110.5188, -7.9509],
      [110.5186, -7.9507],
      [110.5184, -7.9502],
      [110.5172, -7.9502],
      [110.5168, -7.9501],
      [110.5159, -7.9502],
      [110.5153, -7.9495],
      [110.5155, -7.9484]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'GETAS',
    border: [
      [110.5244, -7.9339],
      [110.5233, -7.9329],
      [110.5224, -7.9322],
      [110.5212, -7.9326],
      [110.5201, -7.9326],
      [110.5196, -7.9327],
      [110.5195, -7.9322],
      [110.519, -7.9319],
      [110.517, -7.931],
      [110.5159, -7.9303],
      [110.5143, -7.9288],
      [110.5125, -7.9269],
      [110.5113, -7.9248],
      [110.5106, -7.9236],
      [110.5101, -7.9223],
      [110.5094, -7.9207],
      [110.509, -7.9199],
      [110.507, -7.9202],
      [110.5061, -7.9205],
      [110.5052, -7.9205],
      [110.5037, -7.9203],
      [110.5025, -7.9202],
      [110.5016, -7.9209],
      [110.5012, -7.9209],
      [110.5004, -7.9199],
      [110.4997, -7.9188],
      [110.499, -7.9177],
      [110.4984, -7.9169],
      [110.4974, -7.916],
      [110.4971, -7.9159],
      [110.4968, -7.9156],
      [110.4957, -7.9152],
      [110.4954, -7.9152],
      [110.4953, -7.9152],
      [110.4952, -7.9156],
      [110.4951, -7.9163],
      [110.4949, -7.9168],
      [110.4946, -7.9172],
      [110.4942, -7.9177],
      [110.4936, -7.9185],
      [110.4929, -7.9194],
      [110.4926, -7.9202],
      [110.4926, -7.9204],
      [110.4926, -7.9205],
      [110.4929, -7.9209],
      [110.4929, -7.9211],
      [110.4927, -7.922],
      [110.4927, -7.9221],
      [110.4926, -7.9222],
      [110.4924, -7.9226],
      [110.4918, -7.9232],
      [110.4917, -7.9234],
      [110.4915, -7.9244],
      [110.4913, -7.9253],
      [110.4912, -7.9265],
      [110.4912, -7.9274],
      [110.4916, -7.928],
      [110.4921, -7.9291],
      [110.4929, -7.931],
      [110.4932, -7.9316],
      [110.4931, -7.9322],
      [110.4929, -7.9328],
      [110.4929, -7.9329],
      [110.4926, -7.9337],
      [110.4922, -7.9344],
      [110.492, -7.935],
      [110.4919, -7.9357],
      [110.4919, -7.9362],
      [110.492, -7.9366],
      [110.4921, -7.9369],
      [110.4922, -7.9374],
      [110.4922, -7.9377],
      [110.4921, -7.9381],
      [110.4918, -7.9386],
      [110.4913, -7.939],
      [110.4907, -7.9394],
      [110.4901, -7.9393],
      [110.4899, -7.9392],
      [110.4896, -7.9391],
      [110.4893, -7.939],
      [110.4889, -7.939],
      [110.4886, -7.9393],
      [110.4886, -7.9394],
      [110.4886, -7.9397],
      [110.4888, -7.9402],
      [110.4891, -7.9407],
      [110.4893, -7.941],
      [110.4894, -7.9412],
      [110.4895, -7.9414],
      [110.4905, -7.9416],
      [110.4913, -7.9419],
      [110.492, -7.942],
      [110.4926, -7.9421],
      [110.4934, -7.9421],
      [110.4945, -7.9422],
      [110.4952, -7.9424],
      [110.4959, -7.9425],
      [110.4973, -7.9433],
      [110.4985, -7.9437],
      [110.4991, -7.9441],
      [110.4994, -7.9442],
      [110.5, -7.9441],
      [110.5008, -7.9441],
      [110.5014, -7.9443],
      [110.502, -7.9446],
      [110.5025, -7.9447],
      [110.5032, -7.9453],
      [110.5038, -7.9455],
      [110.5044, -7.9457],
      [110.5051, -7.9456],
      [110.5063, -7.9454],
      [110.5103, -7.9466],
      [110.5119, -7.947],
      [110.5133, -7.9477],
      [110.5142, -7.9479],
      [110.5155, -7.9484],
      [110.5158, -7.9478],
      [110.5163, -7.9473],
      [110.517, -7.9468],
      [110.5177, -7.9464],
      [110.5177, -7.9459],
      [110.518, -7.9458],
      [110.5182, -7.9449],
      [110.5191, -7.9451],
      [110.5201, -7.9454],
      [110.5208, -7.9452],
      [110.5215, -7.9448],
      [110.5218, -7.9438],
      [110.522, -7.9436],
      [110.5225, -7.9424],
      [110.5223, -7.9411],
      [110.522, -7.9408],
      [110.522, -7.9399],
      [110.5218, -7.9393],
      [110.522, -7.939],
      [110.5219, -7.9383],
      [110.5221, -7.9382],
      [110.5225, -7.9379],
      [110.5225, -7.9373],
      [110.5228, -7.9372],
      [110.523, -7.9371],
      [110.5234, -7.9369],
      [110.5237, -7.9364],
      [110.5246, -7.9356],
      [110.5245, -7.9349],
      [110.5244, -7.9339]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'DENGOK',
    border: [
      [110.5261, -7.9273],
      [110.5257, -7.9273],
      [110.5247, -7.9273],
      [110.5245, -7.9273],
      [110.5247, -7.9286],
      [110.5244, -7.9299],
      [110.5243, -7.9313],
      [110.5243, -7.9326],
      [110.5244, -7.9339],
      [110.5245, -7.9349],
      [110.5246, -7.9356],
      [110.5237, -7.9364],
      [110.5234, -7.9369],
      [110.523, -7.9371],
      [110.5228, -7.9372],
      [110.5225, -7.9373],
      [110.5225, -7.9379],
      [110.5221, -7.9382],
      [110.5219, -7.9383],
      [110.522, -7.939],
      [110.5218, -7.9393],
      [110.522, -7.9399],
      [110.522, -7.9408],
      [110.5223, -7.9411],
      [110.5225, -7.9424],
      [110.522, -7.9436],
      [110.5218, -7.9438],
      [110.5215, -7.9448],
      [110.5208, -7.9452],
      [110.5201, -7.9454],
      [110.5191, -7.9451],
      [110.5182, -7.9449],
      [110.518, -7.9458],
      [110.5177, -7.9459],
      [110.5177, -7.9464],
      [110.517, -7.9468],
      [110.5163, -7.9473],
      [110.5158, -7.9478],
      [110.5155, -7.9484],
      [110.5153, -7.9495],
      [110.5159, -7.9502],
      [110.5168, -7.9501],
      [110.5172, -7.9502],
      [110.5184, -7.9502],
      [110.5186, -7.9507],
      [110.5188, -7.9509],
      [110.5192, -7.9513],
      [110.5191, -7.9521],
      [110.5195, -7.9524],
      [110.5203, -7.9527],
      [110.5203, -7.9528],
      [110.5203, -7.9534],
      [110.5203, -7.9538],
      [110.5209, -7.9538],
      [110.5215, -7.9539],
      [110.5219, -7.9539],
      [110.5223, -7.9537],
      [110.5228, -7.9537],
      [110.5228, -7.955],
      [110.5231, -7.956],
      [110.5232, -7.9571],
      [110.5241, -7.9574],
      [110.5258, -7.958],
      [110.5266, -7.9583],
      [110.5274, -7.9587],
      [110.5287, -7.9589],
      [110.5294, -7.9566],
      [110.5298, -7.9556],
      [110.5308, -7.9556],
      [110.5306, -7.9571],
      [110.5315, -7.9572],
      [110.5329, -7.9578],
      [110.5336, -7.958],
      [110.5338, -7.9581],
      [110.5339, -7.9583],
      [110.5343, -7.9577],
      [110.5344, -7.9571],
      [110.5346, -7.9566],
      [110.5355, -7.9562],
      [110.5364, -7.9559],
      [110.5365, -7.9558],
      [110.5365, -7.9547],
      [110.5366, -7.9542],
      [110.5371, -7.9541],
      [110.5379, -7.9536],
      [110.5381, -7.9528],
      [110.5382, -7.9522],
      [110.538, -7.9517],
      [110.5372, -7.9504],
      [110.5366, -7.9499],
      [110.5356, -7.9491],
      [110.536, -7.9483],
      [110.536, -7.9479],
      [110.5358, -7.9477],
      [110.5351, -7.9471],
      [110.535, -7.9465],
      [110.5347, -7.9454],
      [110.5346, -7.9453],
      [110.534, -7.9442],
      [110.5336, -7.944],
      [110.5327, -7.9433],
      [110.5318, -7.9427],
      [110.5317, -7.9421],
      [110.5313, -7.9419],
      [110.531, -7.9415],
      [110.5307, -7.9414],
      [110.5306, -7.9412],
      [110.5302, -7.9409],
      [110.5298, -7.9404],
      [110.5295, -7.9394],
      [110.5299, -7.9381],
      [110.5282, -7.938],
      [110.5283, -7.9375],
      [110.5282, -7.9371],
      [110.5285, -7.9358],
      [110.5284, -7.9344],
      [110.5284, -7.9336],
      [110.5289, -7.9331],
      [110.5287, -7.9325],
      [110.5288, -7.9317],
      [110.5291, -7.9286],
      [110.5277, -7.9282],
      [110.5268, -7.928],
      [110.5263, -7.9279],
      [110.5261, -7.9275],
      [110.5261, -7.9273]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'NGUNUT',
    border: [
      [110.542, -7.9287],
      [110.5418, -7.9289],
      [110.5415, -7.9295],
      [110.5407, -7.9296],
      [110.5394, -7.9297],
      [110.5387, -7.9297],
      [110.5381, -7.9306],
      [110.5368, -7.93],
      [110.5352, -7.9297],
      [110.5346, -7.9295],
      [110.5337, -7.9298],
      [110.533, -7.9299],
      [110.5321, -7.9297],
      [110.5313, -7.9293],
      [110.5306, -7.9287],
      [110.5296, -7.9286],
      [110.5291, -7.9286],
      [110.5288, -7.9317],
      [110.5287, -7.9325],
      [110.5289, -7.9331],
      [110.5284, -7.9336],
      [110.5284, -7.9344],
      [110.5285, -7.9358],
      [110.5282, -7.9371],
      [110.5283, -7.9375],
      [110.5282, -7.938],
      [110.5299, -7.9381],
      [110.5295, -7.9394],
      [110.5298, -7.9404],
      [110.5302, -7.9409],
      [110.5306, -7.9412],
      [110.5307, -7.9414],
      [110.531, -7.9415],
      [110.5313, -7.9419],
      [110.5317, -7.9421],
      [110.5318, -7.9427],
      [110.5327, -7.9433],
      [110.5336, -7.944],
      [110.534, -7.9442],
      [110.5346, -7.9453],
      [110.5347, -7.9454],
      [110.535, -7.9465],
      [110.5351, -7.9471],
      [110.5358, -7.9477],
      [110.536, -7.9479],
      [110.536, -7.9483],
      [110.5356, -7.9491],
      [110.5366, -7.9499],
      [110.5372, -7.9504],
      [110.538, -7.9517],
      [110.5382, -7.9522],
      [110.5381, -7.9528],
      [110.5379, -7.9536],
      [110.5384, -7.9539],
      [110.5387, -7.9542],
      [110.539, -7.9541],
      [110.5392, -7.9541],
      [110.5395, -7.9541],
      [110.5397, -7.9541],
      [110.5398, -7.954],
      [110.5402, -7.9537],
      [110.5402, -7.9531],
      [110.5412, -7.9523],
      [110.5411, -7.9519],
      [110.5411, -7.9512],
      [110.5412, -7.9505],
      [110.5418, -7.9503],
      [110.5421, -7.9501],
      [110.5426, -7.9495],
      [110.5429, -7.9492],
      [110.5432, -7.9489],
      [110.5435, -7.9487],
      [110.544, -7.9483],
      [110.5445, -7.9483],
      [110.5448, -7.9483],
      [110.5458, -7.9477],
      [110.5462, -7.9469],
      [110.5467, -7.9458],
      [110.547, -7.9456],
      [110.5472, -7.9454],
      [110.5445, -7.9453],
      [110.5426, -7.9447],
      [110.5429, -7.9435],
      [110.5433, -7.9422],
      [110.5433, -7.941],
      [110.5433, -7.9396],
      [110.5431, -7.9392],
      [110.543, -7.9377],
      [110.5427, -7.9374],
      [110.5419, -7.9369],
      [110.5419, -7.9362],
      [110.5418, -7.9359],
      [110.541, -7.9342],
      [110.5411, -7.9336],
      [110.5416, -7.9328],
      [110.5422, -7.9319],
      [110.5423, -7.9307],
      [110.542, -7.9287]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'PLAYEN',
    border: [
      [110.5723, -7.9418],
      [110.572, -7.9418],
      [110.5705, -7.9413],
      [110.5687, -7.9408],
      [110.5679, -7.9407],
      [110.5671, -7.9406],
      [110.5654, -7.9409],
      [110.5646, -7.9405],
      [110.5629, -7.9404],
      [110.5622, -7.9403],
      [110.5612, -7.9385],
      [110.5606, -7.9382],
      [110.5596, -7.9377],
      [110.5591, -7.9372],
      [110.5588, -7.9365],
      [110.5586, -7.9356],
      [110.5577, -7.9359],
      [110.5561, -7.9365],
      [110.5559, -7.9366],
      [110.5559, -7.937],
      [110.5559, -7.9373],
      [110.5559, -7.9374],
      [110.5562, -7.9377],
      [110.5559, -7.9384],
      [110.5543, -7.9404],
      [110.5547, -7.9405],
      [110.5544, -7.9415],
      [110.5534, -7.9437],
      [110.553, -7.9449],
      [110.552, -7.9444],
      [110.5518, -7.9457],
      [110.5514, -7.9458],
      [110.5506, -7.9457],
      [110.5493, -7.9455],
      [110.5477, -7.9452],
      [110.5472, -7.9454],
      [110.547, -7.9456],
      [110.5467, -7.9458],
      [110.5462, -7.9469],
      [110.5458, -7.9477],
      [110.5448, -7.9483],
      [110.5445, -7.9483],
      [110.544, -7.9483],
      [110.5435, -7.9487],
      [110.5432, -7.9489],
      [110.5429, -7.9492],
      [110.5426, -7.9495],
      [110.5421, -7.9501],
      [110.5418, -7.9503],
      [110.5412, -7.9505],
      [110.5411, -7.9512],
      [110.5411, -7.9519],
      [110.5412, -7.9523],
      [110.5402, -7.9531],
      [110.5402, -7.9537],
      [110.5398, -7.954],
      [110.5397, -7.9541],
      [110.5395, -7.9541],
      [110.5392, -7.9541],
      [110.539, -7.9541],
      [110.5387, -7.9542],
      [110.5384, -7.9548],
      [110.5382, -7.9555],
      [110.5382, -7.9563],
      [110.5385, -7.957],
      [110.5394, -7.957],
      [110.5406, -7.9573],
      [110.542, -7.9578],
      [110.5423, -7.9571],
      [110.5427, -7.9572],
      [110.5434, -7.9574],
      [110.5437, -7.9573],
      [110.5444, -7.9574],
      [110.5445, -7.9567],
      [110.545, -7.9567],
      [110.5452, -7.9564],
      [110.5457, -7.9564],
      [110.5462, -7.9569],
      [110.5463, -7.9562],
      [110.5471, -7.9562],
      [110.5474, -7.9547],
      [110.5477, -7.9546],
      [110.5476, -7.955],
      [110.5474, -7.956],
      [110.549, -7.9561],
      [110.5491, -7.957],
      [110.5507, -7.9577],
      [110.5506, -7.9584],
      [110.551, -7.9584],
      [110.5516, -7.9585],
      [110.5517, -7.9584],
      [110.5523, -7.9585],
      [110.5528, -7.9585],
      [110.5529, -7.9582],
      [110.5543, -7.9582],
      [110.5545, -7.9559],
      [110.5545, -7.9553],
      [110.5539, -7.9553],
      [110.5541, -7.953],
      [110.5555, -7.9536],
      [110.5559, -7.954],
      [110.5563, -7.9538],
      [110.557, -7.9541],
      [110.5584, -7.9542],
      [110.5599, -7.9543],
      [110.5603, -7.9537],
      [110.5607, -7.9537],
      [110.5611, -7.9536],
      [110.5615, -7.9532],
      [110.5618, -7.9532],
      [110.5619, -7.953],
      [110.562, -7.9527],
      [110.5632, -7.9527],
      [110.5639, -7.9534],
      [110.5644, -7.9542],
      [110.5643, -7.9546],
      [110.5642, -7.9551],
      [110.5641, -7.9557],
      [110.5638, -7.9562],
      [110.5638, -7.9568],
      [110.564, -7.9578],
      [110.5646, -7.9582],
      [110.5653, -7.9583],
      [110.5658, -7.9581],
      [110.5661, -7.958],
      [110.5664, -7.9576],
      [110.5665, -7.9574],
      [110.5664, -7.9572],
      [110.566, -7.9565],
      [110.5665, -7.9556],
      [110.567, -7.9547],
      [110.5676, -7.9542],
      [110.5679, -7.9541],
      [110.5677, -7.9536],
      [110.5678, -7.9531],
      [110.5681, -7.9528],
      [110.5682, -7.9522],
      [110.5684, -7.9521],
      [110.5688, -7.9521],
      [110.5686, -7.9516],
      [110.5687, -7.9513],
      [110.5695, -7.9489],
      [110.5699, -7.9488],
      [110.5702, -7.9486],
      [110.5704, -7.9483],
      [110.5705, -7.9478],
      [110.5706, -7.9467],
      [110.5709, -7.9461],
      [110.5709, -7.9455],
      [110.5709, -7.9453],
      [110.5711, -7.9452],
      [110.5713, -7.9452],
      [110.5715, -7.945],
      [110.5716, -7.9448],
      [110.5717, -7.9448],
      [110.5719, -7.9448],
      [110.572, -7.9444],
      [110.5723, -7.9439],
      [110.5723, -7.9436],
      [110.5722, -7.9431],
      [110.5721, -7.9426],
      [110.5725, -7.9421],
      [110.5723, -7.9418]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'NGAWU',
    border: [
      [110.5721, -7.9209],
      [110.5707, -7.9206],
      [110.5699, -7.9204],
      [110.5691, -7.9198],
      [110.5683, -7.9194],
      [110.5669, -7.9188],
      [110.5662, -7.9187],
      [110.5654, -7.9185],
      [110.5643, -7.9186],
      [110.5636, -7.9185],
      [110.563, -7.9183],
      [110.5624, -7.9182],
      [110.5615, -7.9182],
      [110.5601, -7.9186],
      [110.5594, -7.9187],
      [110.5592, -7.9185],
      [110.5582, -7.919],
      [110.5579, -7.9192],
      [110.5572, -7.9184],
      [110.5569, -7.921],
      [110.5555, -7.9216],
      [110.5548, -7.9221],
      [110.5542, -7.9222],
      [110.5542, -7.922],
      [110.554, -7.9221],
      [110.5535, -7.9232],
      [110.5535, -7.9251],
      [110.5535, -7.927],
      [110.5539, -7.928],
      [110.5539, -7.9285],
      [110.5541, -7.9302],
      [110.5545, -7.9317],
      [110.5544, -7.9326],
      [110.5546, -7.9332],
      [110.5544, -7.9342],
      [110.5548, -7.9345],
      [110.5542, -7.9356],
      [110.5541, -7.9357],
      [110.5551, -7.9361],
      [110.5556, -7.9361],
      [110.5559, -7.9366],
      [110.5561, -7.9365],
      [110.5577, -7.9359],
      [110.5586, -7.9356],
      [110.5588, -7.9365],
      [110.5591, -7.9372],
      [110.5596, -7.9377],
      [110.5606, -7.9382],
      [110.5612, -7.9385],
      [110.5622, -7.9403],
      [110.5629, -7.9404],
      [110.5646, -7.9405],
      [110.5654, -7.9409],
      [110.5671, -7.9406],
      [110.5679, -7.9407],
      [110.5687, -7.9408],
      [110.5705, -7.9413],
      [110.572, -7.9418],
      [110.5723, -7.9418],
      [110.5725, -7.9413],
      [110.5728, -7.9409],
      [110.5727, -7.9404],
      [110.5729, -7.94],
      [110.5729, -7.9385],
      [110.5729, -7.9378],
      [110.5721, -7.9374],
      [110.571, -7.9371],
      [110.571, -7.9368],
      [110.571, -7.9356],
      [110.5716, -7.9355],
      [110.5716, -7.9353],
      [110.5716, -7.9345],
      [110.5697, -7.9324],
      [110.5697, -7.9321],
      [110.5698, -7.9315],
      [110.5699, -7.9299],
      [110.5699, -7.9289],
      [110.5708, -7.9266],
      [110.5712, -7.9254],
      [110.5713, -7.9248],
      [110.5716, -7.9237],
      [110.5721, -7.9217],
      [110.5721, -7.9209]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'BANDUNG',
    border: [
      [110.5622, -7.8935],
      [110.5619, -7.8938],
      [110.5616, -7.8947],
      [110.5616, -7.8957],
      [110.5617, -7.8964],
      [110.5618, -7.8972],
      [110.5619, -7.8976],
      [110.5618, -7.8979],
      [110.5613, -7.898],
      [110.5608, -7.8977],
      [110.5608, -7.8976],
      [110.5604, -7.8966],
      [110.5604, -7.8965],
      [110.5602, -7.8961],
      [110.5601, -7.8959],
      [110.5599, -7.8956],
      [110.559, -7.8952],
      [110.5586, -7.8951],
      [110.5584, -7.895],
      [110.5576, -7.8946],
      [110.5569, -7.8944],
      [110.5562, -7.8945],
      [110.5557, -7.8947],
      [110.5555, -7.8947],
      [110.5546, -7.8949],
      [110.5541, -7.8948],
      [110.5537, -7.8946],
      [110.5536, -7.8945],
      [110.5531, -7.8934],
      [110.5529, -7.8931],
      [110.5528, -7.8931],
      [110.5523, -7.8931],
      [110.5519, -7.8932],
      [110.5515, -7.8934],
      [110.5513, -7.8934],
      [110.5507, -7.8933],
      [110.5505, -7.8931],
      [110.5499, -7.8926],
      [110.5496, -7.8922],
      [110.5487, -7.8915],
      [110.5481, -7.8912],
      [110.5475, -7.8913],
      [110.5472, -7.8914],
      [110.547, -7.8915],
      [110.5465, -7.892],
      [110.5459, -7.8927],
      [110.5452, -7.8933],
      [110.5446, -7.8941],
      [110.5438, -7.8946],
      [110.5436, -7.8946],
      [110.5437, -7.8971],
      [110.5437, -7.8981],
      [110.5432, -7.8987],
      [110.5431, -7.8994],
      [110.5425, -7.8998],
      [110.5421, -7.9008],
      [110.5419, -7.902],
      [110.5419, -7.9026],
      [110.5415, -7.9033],
      [110.5415, -7.9048],
      [110.5427, -7.9054],
      [110.5431, -7.9057],
      [110.5443, -7.9075],
      [110.5448, -7.9081],
      [110.5451, -7.909],
      [110.5433, -7.9105],
      [110.5428, -7.9114],
      [110.5416, -7.914],
      [110.5415, -7.9148],
      [110.5421, -7.9167],
      [110.5434, -7.9188],
      [110.5435, -7.9189],
      [110.5437, -7.9188],
      [110.5457, -7.9183],
      [110.5465, -7.9182],
      [110.5485, -7.9186],
      [110.5496, -7.9187],
      [110.5504, -7.9187],
      [110.5508, -7.919],
      [110.5511, -7.9197],
      [110.5513, -7.92],
      [110.5518, -7.9202],
      [110.5525, -7.9204],
      [110.5534, -7.9207],
      [110.5538, -7.9211],
      [110.5542, -7.922],
      [110.5542, -7.9222],
      [110.5548, -7.9221],
      [110.5555, -7.9216],
      [110.5569, -7.921],
      [110.5572, -7.9184],
      [110.5579, -7.9192],
      [110.5582, -7.919],
      [110.5592, -7.9185],
      [110.5594, -7.9187],
      [110.5601, -7.9186],
      [110.5615, -7.9182],
      [110.5624, -7.9182],
      [110.563, -7.9183],
      [110.5636, -7.9185],
      [110.5643, -7.9186],
      [110.5654, -7.9185],
      [110.5662, -7.9187],
      [110.5669, -7.9188],
      [110.5683, -7.9194],
      [110.5691, -7.9198],
      [110.5699, -7.9204],
      [110.5707, -7.9206],
      [110.5721, -7.9209],
      [110.5722, -7.9179],
      [110.5725, -7.9178],
      [110.5732, -7.9179],
      [110.5738, -7.9182],
      [110.574, -7.9182],
      [110.5752, -7.9183],
      [110.576, -7.9184],
      [110.577, -7.9186],
      [110.5775, -7.9185],
      [110.5778, -7.9182],
      [110.5783, -7.9175],
      [110.5785, -7.917],
      [110.5789, -7.9168],
      [110.5793, -7.9168],
      [110.5814, -7.9167],
      [110.5813, -7.9159],
      [110.5808, -7.9157],
      [110.5804, -7.9154],
      [110.5799, -7.9147],
      [110.5796, -7.9139],
      [110.5792, -7.9132],
      [110.5794, -7.9131],
      [110.58, -7.913],
      [110.5801, -7.9128],
      [110.5803, -7.9118],
      [110.5804, -7.9108],
      [110.581, -7.9108],
      [110.5812, -7.9101],
      [110.5814, -7.9098],
      [110.5816, -7.9097],
      [110.5818, -7.9096],
      [110.5819, -7.9096],
      [110.5823, -7.9095],
      [110.5828, -7.9095],
      [110.5833, -7.9093],
      [110.5834, -7.9089],
      [110.5836, -7.9082],
      [110.584, -7.9077],
      [110.5841, -7.9072],
      [110.5844, -7.9061],
      [110.5844, -7.9058],
      [110.5847, -7.9055],
      [110.5851, -7.9043],
      [110.5856, -7.9032],
      [110.5857, -7.9028],
      [110.5855, -7.9023],
      [110.5849, -7.9026],
      [110.5838, -7.9024],
      [110.5827, -7.9016],
      [110.5823, -7.9013],
      [110.582, -7.9011],
      [110.581, -7.9003],
      [110.5805, -7.8998],
      [110.5799, -7.8991],
      [110.5797, -7.8986],
      [110.5799, -7.898],
      [110.58, -7.8974],
      [110.5798, -7.8967],
      [110.5797, -7.8965],
      [110.5794, -7.896],
      [110.5792, -7.8959],
      [110.5787, -7.8953],
      [110.5786, -7.8952],
      [110.5781, -7.895],
      [110.5776, -7.8949],
      [110.5771, -7.8954],
      [110.5758, -7.8964],
      [110.5757, -7.8965],
      [110.5753, -7.8967],
      [110.5749, -7.8968],
      [110.5743, -7.8967],
      [110.5741, -7.8967],
      [110.5739, -7.8962],
      [110.5738, -7.8954],
      [110.5738, -7.8952],
      [110.5737, -7.8949],
      [110.5735, -7.8944],
      [110.5733, -7.8941],
      [110.5727, -7.8936],
      [110.5719, -7.8933],
      [110.5708, -7.8932],
      [110.5706, -7.8932],
      [110.5705, -7.8932],
      [110.5698, -7.8938],
      [110.5693, -7.8941],
      [110.5688, -7.8942],
      [110.5686, -7.8943],
      [110.5681, -7.8944],
      [110.5679, -7.8945],
      [110.5673, -7.8947],
      [110.5668, -7.8948],
      [110.5655, -7.8953],
      [110.5653, -7.8954],
      [110.5647, -7.8951],
      [110.5642, -7.8935],
      [110.5641, -7.8933],
      [110.5639, -7.8928],
      [110.5638, -7.8926],
      [110.5637, -7.8926],
      [110.5633, -7.8925],
      [110.563, -7.8927],
      [110.5628, -7.8928],
      [110.5622, -7.8935]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'LOGANDENG',
    border: [
      [110.5837, -7.9265],
      [110.5837, -7.9254],
      [110.5836, -7.9247],
      [110.5832, -7.9235],
      [110.5832, -7.9221],
      [110.583, -7.9211],
      [110.5825, -7.9205],
      [110.5821, -7.9197],
      [110.5816, -7.9188],
      [110.5814, -7.9181],
      [110.5812, -7.9171],
      [110.5813, -7.9168],
      [110.5814, -7.9167],
      [110.5793, -7.9168],
      [110.5789, -7.9168],
      [110.5785, -7.917],
      [110.5783, -7.9175],
      [110.5778, -7.9182],
      [110.5775, -7.9185],
      [110.577, -7.9186],
      [110.576, -7.9184],
      [110.5752, -7.9183],
      [110.574, -7.9182],
      [110.5738, -7.9182],
      [110.5732, -7.9179],
      [110.5725, -7.9178],
      [110.5722, -7.9179],
      [110.5721, -7.9209],
      [110.5721, -7.9217],
      [110.5716, -7.9237],
      [110.5713, -7.9248],
      [110.5712, -7.9254],
      [110.5708, -7.9266],
      [110.5699, -7.9289],
      [110.5699, -7.9299],
      [110.5698, -7.9315],
      [110.5697, -7.9321],
      [110.5697, -7.9324],
      [110.5716, -7.9345],
      [110.5716, -7.9353],
      [110.5716, -7.9355],
      [110.571, -7.9356],
      [110.571, -7.9368],
      [110.571, -7.9371],
      [110.5721, -7.9374],
      [110.5729, -7.9378],
      [110.5729, -7.9385],
      [110.5729, -7.94],
      [110.5727, -7.9404],
      [110.5728, -7.9409],
      [110.5725, -7.9413],
      [110.5723, -7.9418],
      [110.5725, -7.9421],
      [110.5721, -7.9426],
      [110.5722, -7.9431],
      [110.5723, -7.9436],
      [110.5723, -7.9439],
      [110.572, -7.9444],
      [110.5719, -7.9448],
      [110.5717, -7.9448],
      [110.5716, -7.9448],
      [110.5715, -7.945],
      [110.5713, -7.9452],
      [110.5711, -7.9452],
      [110.5709, -7.9453],
      [110.5709, -7.9455],
      [110.5709, -7.9461],
      [110.5706, -7.9467],
      [110.5705, -7.9478],
      [110.5704, -7.9483],
      [110.5702, -7.9486],
      [110.5699, -7.9488],
      [110.5695, -7.9489],
      [110.5687, -7.9513],
      [110.5686, -7.9516],
      [110.5688, -7.9521],
      [110.5684, -7.9521],
      [110.5682, -7.9522],
      [110.5681, -7.9528],
      [110.5678, -7.9531],
      [110.5677, -7.9536],
      [110.5679, -7.9541],
      [110.5676, -7.9542],
      [110.567, -7.9547],
      [110.5665, -7.9556],
      [110.566, -7.9565],
      [110.5664, -7.9572],
      [110.5665, -7.9574],
      [110.5667, -7.9573],
      [110.5669, -7.9571],
      [110.5672, -7.957],
      [110.5678, -7.9571],
      [110.568, -7.9571],
      [110.5685, -7.9569],
      [110.5687, -7.9569],
      [110.569, -7.9569],
      [110.5692, -7.9568],
      [110.5701, -7.9563],
      [110.5702, -7.9563],
      [110.5705, -7.9562],
      [110.5707, -7.9561],
      [110.5709, -7.956],
      [110.5714, -7.9556],
      [110.5715, -7.9555],
      [110.5717, -7.9554],
      [110.5722, -7.9552],
      [110.5723, -7.9551],
      [110.5728, -7.955],
      [110.5731, -7.9551],
      [110.5736, -7.9548],
      [110.5739, -7.9545],
      [110.574, -7.9544],
      [110.5742, -7.9543],
      [110.5744, -7.9541],
      [110.5747, -7.9535],
      [110.575, -7.9535],
      [110.5756, -7.9533],
      [110.5763, -7.9531],
      [110.5767, -7.953],
      [110.577, -7.953],
      [110.5777, -7.9524],
      [110.578, -7.9521],
      [110.5783, -7.9518],
      [110.5785, -7.9516],
      [110.579, -7.9515],
      [110.5793, -7.9514],
      [110.5798, -7.9515],
      [110.5805, -7.9514],
      [110.5808, -7.9516],
      [110.5812, -7.9518],
      [110.5817, -7.9518],
      [110.5818, -7.9518],
      [110.5827, -7.9523],
      [110.5836, -7.9529],
      [110.5847, -7.9536],
      [110.5855, -7.9545],
      [110.586, -7.9551],
      [110.5861, -7.9552],
      [110.5864, -7.9553],
      [110.587, -7.9552],
      [110.5873, -7.9548],
      [110.5876, -7.9544],
      [110.5878, -7.9539],
      [110.5882, -7.9536],
      [110.5887, -7.9532],
      [110.5891, -7.9525],
      [110.5893, -7.9523],
      [110.5893, -7.9522],
      [110.5896, -7.9515],
      [110.5891, -7.9489],
      [110.5889, -7.9481],
      [110.5873, -7.9452],
      [110.5862, -7.9435],
      [110.5853, -7.9429],
      [110.5849, -7.9427],
      [110.5844, -7.9427],
      [110.584, -7.9425],
      [110.5837, -7.9421],
      [110.5831, -7.9422],
      [110.5828, -7.9418],
      [110.5824, -7.9415],
      [110.5822, -7.9412],
      [110.5823, -7.941],
      [110.5828, -7.94],
      [110.5832, -7.939],
      [110.5833, -7.9388],
      [110.5834, -7.9383],
      [110.5835, -7.9379],
      [110.5835, -7.9373],
      [110.5831, -7.9371],
      [110.583, -7.9364],
      [110.5831, -7.9356],
      [110.5838, -7.9356],
      [110.5839, -7.9332],
      [110.5839, -7.9323],
      [110.5841, -7.9316],
      [110.584, -7.9304],
      [110.584, -7.9288],
      [110.5838, -7.9273],
      [110.5837, -7.9267],
      [110.5837, -7.9265]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'GADING',
    border: [
      [110.5131, -7.8883],
      [110.5131, -7.8878],
      [110.513, -7.8877],
      [110.5126, -7.8872],
      [110.5126, -7.8872],
      [110.5116, -7.8859],
      [110.5115, -7.8857],
      [110.5113, -7.8854],
      [110.511, -7.8843],
      [110.5108, -7.8836],
      [110.5104, -7.884],
      [110.5092, -7.8844],
      [110.5083, -7.8846],
      [110.5079, -7.8846],
      [110.5074, -7.8843],
      [110.5063, -7.884],
      [110.5058, -7.8838],
      [110.5054, -7.884],
      [110.5053, -7.8842],
      [110.5052, -7.8846],
      [110.5049, -7.8849],
      [110.5045, -7.8849],
      [110.5038, -7.8849],
      [110.5033, -7.8848],
      [110.5029, -7.8847],
      [110.5027, -7.885],
      [110.5026, -7.8852],
      [110.5022, -7.8852],
      [110.5018, -7.8851],
      [110.5013, -7.8852],
      [110.5012, -7.8852],
      [110.5009, -7.8852],
      [110.5011, -7.8859],
      [110.5011, -7.8859],
      [110.5018, -7.8867],
      [110.5021, -7.8873],
      [110.5022, -7.8875],
      [110.5022, -7.8879],
      [110.502, -7.8882],
      [110.5016, -7.8882],
      [110.5009, -7.8881],
      [110.4997, -7.8879],
      [110.4995, -7.8879],
      [110.499, -7.8879],
      [110.4981, -7.8878],
      [110.4975, -7.8877],
      [110.4972, -7.8877],
      [110.4969, -7.8876],
      [110.4965, -7.8874],
      [110.4951, -7.8874],
      [110.4944, -7.8875],
      [110.4933, -7.8878],
      [110.4925, -7.8882],
      [110.4921, -7.8888],
      [110.492, -7.8894],
      [110.4921, -7.8898],
      [110.4923, -7.8897],
      [110.4927, -7.8899],
      [110.4935, -7.8902],
      [110.4947, -7.8906],
      [110.4949, -7.8907],
      [110.4951, -7.8908],
      [110.4958, -7.8915],
      [110.4963, -7.8924],
      [110.4963, -7.8935],
      [110.496, -7.8947],
      [110.4957, -7.8958],
      [110.4957, -7.8964],
      [110.4959, -7.8968],
      [110.4967, -7.8973],
      [110.4972, -7.898],
      [110.4976, -7.8992],
      [110.4978, -7.9001],
      [110.4978, -7.9011],
      [110.4975, -7.902],
      [110.4975, -7.9026],
      [110.4973, -7.9033],
      [110.4974, -7.9046],
      [110.4976, -7.9055],
      [110.4974, -7.9061],
      [110.4968, -7.908],
      [110.4965, -7.909],
      [110.4963, -7.9102],
      [110.496, -7.9114],
      [110.4959, -7.9119],
      [110.496, -7.9128],
      [110.496, -7.9135],
      [110.4959, -7.9141],
      [110.4957, -7.9145],
      [110.4955, -7.9148],
      [110.4953, -7.9152],
      [110.4954, -7.9152],
      [110.4957, -7.9152],
      [110.4968, -7.9156],
      [110.4971, -7.9159],
      [110.4974, -7.916],
      [110.4984, -7.9169],
      [110.499, -7.9177],
      [110.4997, -7.9188],
      [110.5004, -7.9199],
      [110.5012, -7.9209],
      [110.5016, -7.9209],
      [110.5025, -7.9202],
      [110.5037, -7.9203],
      [110.5052, -7.9205],
      [110.5061, -7.9205],
      [110.507, -7.9202],
      [110.509, -7.9199],
      [110.5094, -7.9207],
      [110.5101, -7.9223],
      [110.5106, -7.9236],
      [110.5113, -7.9248],
      [110.5125, -7.9269],
      [110.5143, -7.9288],
      [110.5159, -7.9303],
      [110.517, -7.931],
      [110.519, -7.9319],
      [110.5195, -7.9322],
      [110.5196, -7.9327],
      [110.5201, -7.9326],
      [110.5212, -7.9326],
      [110.5224, -7.9322],
      [110.5233, -7.9329],
      [110.5244, -7.9339],
      [110.5243, -7.9326],
      [110.5243, -7.9313],
      [110.5244, -7.9299],
      [110.5247, -7.9286],
      [110.5245, -7.9273],
      [110.5247, -7.9273],
      [110.5257, -7.9273],
      [110.5261, -7.9273],
      [110.5261, -7.9271],
      [110.5259, -7.9265],
      [110.5253, -7.9256],
      [110.5251, -7.9248],
      [110.5248, -7.9236],
      [110.5246, -7.9229],
      [110.5247, -7.9224],
      [110.5247, -7.9208],
      [110.5232, -7.9192],
      [110.5232, -7.9189],
      [110.5231, -7.9175],
      [110.5227, -7.9168],
      [110.522, -7.915],
      [110.521, -7.9134],
      [110.5206, -7.9121],
      [110.5199, -7.9112],
      [110.5199, -7.9097],
      [110.52, -7.9089],
      [110.5189, -7.9091],
      [110.5187, -7.9091],
      [110.5183, -7.9093],
      [110.5181, -7.9093],
      [110.518, -7.9094],
      [110.5168, -7.9092],
      [110.5151, -7.9093],
      [110.5147, -7.9092],
      [110.5147, -7.9091],
      [110.5144, -7.9085],
      [110.5138, -7.9078],
      [110.5137, -7.9076],
      [110.5136, -7.9071],
      [110.5138, -7.9065],
      [110.514, -7.9063],
      [110.5146, -7.9052],
      [110.5148, -7.9043],
      [110.5147, -7.9037],
      [110.5143, -7.9018],
      [110.5143, -7.9016],
      [110.5141, -7.9005],
      [110.5143, -7.9002],
      [110.5145, -7.8996],
      [110.5143, -7.899],
      [110.5137, -7.8979],
      [110.5136, -7.8974],
      [110.5138, -7.8968],
      [110.5139, -7.8966],
      [110.5139, -7.8965],
      [110.5138, -7.8961],
      [110.5134, -7.8952],
      [110.5131, -7.8941],
      [110.5131, -7.894],
      [110.5132, -7.8927],
      [110.5131, -7.8913],
      [110.5131, -7.8911],
      [110.5131, -7.8904],
      [110.5132, -7.8901],
      [110.5131, -7.8899],
      [110.5131, -7.8887],
      [110.5131, -7.8883]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'BANARAN',
    border: [
      [110.5436, -7.8946],
      [110.5435, -7.8946],
      [110.5433, -7.8946],
      [110.5429, -7.8943],
      [110.5421, -7.8941],
      [110.542, -7.8941],
      [110.5416, -7.8942],
      [110.5414, -7.8945],
      [110.5413, -7.8951],
      [110.5408, -7.8954],
      [110.5407, -7.8955],
      [110.5402, -7.8957],
      [110.5402, -7.8956],
      [110.5398, -7.8956],
      [110.5391, -7.8955],
      [110.5387, -7.8954],
      [110.5381, -7.8953],
      [110.5375, -7.8953],
      [110.5372, -7.8953],
      [110.5361, -7.8955],
      [110.5359, -7.8955],
      [110.5353, -7.8955],
      [110.5351, -7.8955],
      [110.5343, -7.8953],
      [110.5341, -7.8953],
      [110.534, -7.8954],
      [110.5331, -7.8959],
      [110.5328, -7.896],
      [110.5321, -7.8964],
      [110.5316, -7.8966],
      [110.531, -7.8963],
      [110.5308, -7.8963],
      [110.5302, -7.8956],
      [110.5293, -7.8949],
      [110.5287, -7.8946],
      [110.5286, -7.8947],
      [110.5283, -7.8949],
      [110.528, -7.8954],
      [110.5276, -7.8961],
      [110.5274, -7.8966],
      [110.5268, -7.8969],
      [110.5265, -7.8974],
      [110.5262, -7.8982],
      [110.5255, -7.8988],
      [110.5247, -7.8999],
      [110.5244, -7.9006],
      [110.5243, -7.9012],
      [110.5246, -7.9025],
      [110.5246, -7.9026],
      [110.525, -7.9037],
      [110.5258, -7.904],
      [110.5262, -7.9042],
      [110.5263, -7.9046],
      [110.5262, -7.9054],
      [110.5257, -7.9062],
      [110.5253, -7.9068],
      [110.5251, -7.9069],
      [110.5243, -7.907],
      [110.5236, -7.9073],
      [110.523, -7.908],
      [110.5223, -7.9084],
      [110.5217, -7.9087],
      [110.5213, -7.9089],
      [110.521, -7.9089],
      [110.5202, -7.9089],
      [110.52, -7.9089],
      [110.5199, -7.9097],
      [110.5199, -7.9112],
      [110.5206, -7.9121],
      [110.521, -7.9134],
      [110.522, -7.915],
      [110.5227, -7.9168],
      [110.5231, -7.9175],
      [110.5232, -7.9189],
      [110.5232, -7.9192],
      [110.5247, -7.9208],
      [110.5247, -7.9224],
      [110.5246, -7.9229],
      [110.5248, -7.9236],
      [110.5251, -7.9248],
      [110.5253, -7.9256],
      [110.5259, -7.9265],
      [110.5261, -7.9271],
      [110.5261, -7.9273],
      [110.5261, -7.9275],
      [110.5263, -7.9279],
      [110.5268, -7.928],
      [110.5277, -7.9282],
      [110.5291, -7.9286],
      [110.5296, -7.9286],
      [110.5306, -7.9287],
      [110.5313, -7.9293],
      [110.5321, -7.9297],
      [110.533, -7.9299],
      [110.5337, -7.9298],
      [110.5346, -7.9295],
      [110.5352, -7.9297],
      [110.5368, -7.93],
      [110.5381, -7.9306],
      [110.5387, -7.9297],
      [110.5394, -7.9297],
      [110.5407, -7.9296],
      [110.5415, -7.9295],
      [110.5418, -7.9289],
      [110.542, -7.9287],
      [110.542, -7.9286],
      [110.5423, -7.927],
      [110.5429, -7.926],
      [110.5427, -7.9254],
      [110.5427, -7.925],
      [110.5421, -7.9248],
      [110.5422, -7.9243],
      [110.5426, -7.9239],
      [110.5429, -7.9232],
      [110.5437, -7.9226],
      [110.5439, -7.9212],
      [110.5438, -7.9198],
      [110.5435, -7.9189],
      [110.5434, -7.9188],
      [110.5421, -7.9167],
      [110.5415, -7.9148],
      [110.5416, -7.914],
      [110.5428, -7.9114],
      [110.5433, -7.9105],
      [110.5451, -7.909],
      [110.5448, -7.9081],
      [110.5443, -7.9075],
      [110.5431, -7.9057],
      [110.5427, -7.9054],
      [110.5415, -7.9048],
      [110.5415, -7.9033],
      [110.5419, -7.9026],
      [110.5419, -7.902],
      [110.5421, -7.9008],
      [110.5425, -7.8998],
      [110.5431, -7.8994],
      [110.5432, -7.8987],
      [110.5437, -7.8981],
      [110.5437, -7.8971],
      [110.5436, -7.8946]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PLAYEN',
    village: 'NGLERI',
    border: [
      [110.5542, -7.922],
      [110.5538, -7.9211],
      [110.5534, -7.9207],
      [110.5525, -7.9204],
      [110.5518, -7.9202],
      [110.5513, -7.92],
      [110.5511, -7.9197],
      [110.5508, -7.919],
      [110.5504, -7.9187],
      [110.5496, -7.9187],
      [110.5485, -7.9186],
      [110.5465, -7.9182],
      [110.5457, -7.9183],
      [110.5437, -7.9188],
      [110.5435, -7.9189],
      [110.5438, -7.9198],
      [110.5439, -7.9212],
      [110.5437, -7.9226],
      [110.5429, -7.9232],
      [110.5426, -7.9239],
      [110.5422, -7.9243],
      [110.5421, -7.9248],
      [110.5427, -7.925],
      [110.5427, -7.9254],
      [110.5429, -7.926],
      [110.5423, -7.927],
      [110.542, -7.9286],
      [110.542, -7.9287],
      [110.5423, -7.9307],
      [110.5422, -7.9319],
      [110.5416, -7.9328],
      [110.5411, -7.9336],
      [110.541, -7.9342],
      [110.5418, -7.9359],
      [110.5419, -7.9362],
      [110.5419, -7.9369],
      [110.5427, -7.9374],
      [110.543, -7.9377],
      [110.5431, -7.9392],
      [110.5433, -7.9396],
      [110.5433, -7.941],
      [110.5433, -7.9422],
      [110.5429, -7.9435],
      [110.5426, -7.9447],
      [110.5445, -7.9453],
      [110.5472, -7.9454],
      [110.5477, -7.9452],
      [110.5493, -7.9455],
      [110.5506, -7.9457],
      [110.5514, -7.9458],
      [110.5518, -7.9457],
      [110.552, -7.9444],
      [110.553, -7.9449],
      [110.5534, -7.9437],
      [110.5544, -7.9415],
      [110.5547, -7.9405],
      [110.5543, -7.9404],
      [110.5559, -7.9384],
      [110.5562, -7.9377],
      [110.5559, -7.9374],
      [110.5559, -7.9373],
      [110.5559, -7.937],
      [110.5559, -7.9366],
      [110.5556, -7.9361],
      [110.5551, -7.9361],
      [110.5541, -7.9357],
      [110.5542, -7.9356],
      [110.5548, -7.9345],
      [110.5544, -7.9342],
      [110.5546, -7.9332],
      [110.5544, -7.9326],
      [110.5545, -7.9317],
      [110.5541, -7.9302],
      [110.5539, -7.9285],
      [110.5539, -7.928],
      [110.5535, -7.927],
      [110.5535, -7.9251],
      [110.5535, -7.9232],
      [110.554, -7.9221],
      [110.5542, -7.922]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'BUNDER',
    border: [
      [110.5523, -7.8663],
      [110.5516, -7.8661],
      [110.5513, -7.866],
      [110.551, -7.8664],
      [110.551, -7.8668],
      [110.5507, -7.8669],
      [110.5503, -7.8671],
      [110.5499, -7.8677],
      [110.5498, -7.8681],
      [110.5498, -7.8684],
      [110.5494, -7.8684],
      [110.549, -7.8684],
      [110.5488, -7.8683],
      [110.5484, -7.8683],
      [110.5481, -7.8683],
      [110.5478, -7.8682],
      [110.5479, -7.8686],
      [110.5477, -7.8689],
      [110.5475, -7.8691],
      [110.547, -7.8691],
      [110.5466, -7.8691],
      [110.5461, -7.8691],
      [110.5459, -7.8689],
      [110.5456, -7.8689],
      [110.5455, -7.8692],
      [110.5454, -7.8692],
      [110.545, -7.8691],
      [110.5442, -7.8691],
      [110.5439, -7.8691],
      [110.5435, -7.8693],
      [110.5423, -7.869],
      [110.5406, -7.8688],
      [110.5399, -7.8687],
      [110.5373, -7.8688],
      [110.5367, -7.8691],
      [110.5363, -7.8695],
      [110.536, -7.8698],
      [110.5358, -7.8701],
      [110.5358, -7.8707],
      [110.5357, -7.871],
      [110.5356, -7.8714],
      [110.5353, -7.8718],
      [110.5352, -7.8722],
      [110.5353, -7.8727],
      [110.5352, -7.873],
      [110.5345, -7.8728],
      [110.5344, -7.8737],
      [110.534, -7.8746],
      [110.5341, -7.8748],
      [110.5341, -7.875],
      [110.5337, -7.8752],
      [110.5336, -7.8752],
      [110.5334, -7.8753],
      [110.5334, -7.8757],
      [110.5333, -7.8757],
      [110.5331, -7.8757],
      [110.5331, -7.8761],
      [110.5331, -7.8762],
      [110.5328, -7.8763],
      [110.5326, -7.8764],
      [110.5326, -7.8768],
      [110.5323, -7.877],
      [110.5323, -7.8774],
      [110.5323, -7.8776],
      [110.5324, -7.8782],
      [110.5322, -7.878],
      [110.5321, -7.8779],
      [110.5317, -7.8778],
      [110.5315, -7.8782],
      [110.5312, -7.8781],
      [110.531, -7.8781],
      [110.5307, -7.8788],
      [110.5305, -7.8793],
      [110.5303, -7.8798],
      [110.5303, -7.8801],
      [110.5312, -7.8806],
      [110.5308, -7.8808],
      [110.5303, -7.8811],
      [110.5301, -7.8814],
      [110.5298, -7.8814],
      [110.5293, -7.8814],
      [110.5289, -7.882],
      [110.5283, -7.8822],
      [110.5282, -7.8827],
      [110.5277, -7.8834],
      [110.5259, -7.8851],
      [110.5249, -7.8839],
      [110.5238, -7.8839],
      [110.5235, -7.8842],
      [110.5232, -7.8848],
      [110.5227, -7.885],
      [110.5224, -7.8851],
      [110.5218, -7.8851],
      [110.5214, -7.8855],
      [110.521, -7.8859],
      [110.5207, -7.8869],
      [110.5207, -7.8877],
      [110.5204, -7.8883],
      [110.5196, -7.8883],
      [110.5182, -7.888],
      [110.5176, -7.8878],
      [110.5166, -7.8878],
      [110.5156, -7.8878],
      [110.5153, -7.8878],
      [110.515, -7.8882],
      [110.5146, -7.8884],
      [110.5142, -7.8884],
      [110.5136, -7.8883],
      [110.5132, -7.8883],
      [110.5131, -7.8883],
      [110.5131, -7.8887],
      [110.5131, -7.8899],
      [110.5132, -7.8901],
      [110.5131, -7.8904],
      [110.5131, -7.8911],
      [110.5131, -7.8913],
      [110.5132, -7.8927],
      [110.5131, -7.894],
      [110.5131, -7.8941],
      [110.5134, -7.8952],
      [110.5138, -7.8961],
      [110.5139, -7.8965],
      [110.5139, -7.8966],
      [110.5138, -7.8968],
      [110.5136, -7.8974],
      [110.5137, -7.8979],
      [110.5143, -7.899],
      [110.5145, -7.8996],
      [110.5143, -7.9002],
      [110.5141, -7.9005],
      [110.5143, -7.9016],
      [110.5143, -7.9018],
      [110.5147, -7.9037],
      [110.5148, -7.9043],
      [110.5146, -7.9052],
      [110.514, -7.9063],
      [110.5138, -7.9065],
      [110.5136, -7.9071],
      [110.5137, -7.9076],
      [110.5138, -7.9078],
      [110.5144, -7.9085],
      [110.5147, -7.9091],
      [110.5147, -7.9092],
      [110.5151, -7.9093],
      [110.5168, -7.9092],
      [110.518, -7.9094],
      [110.5181, -7.9093],
      [110.5183, -7.9093],
      [110.5187, -7.9091],
      [110.5189, -7.9091],
      [110.52, -7.9089],
      [110.5202, -7.9089],
      [110.521, -7.9089],
      [110.5213, -7.9089],
      [110.5217, -7.9087],
      [110.5223, -7.9084],
      [110.523, -7.908],
      [110.5236, -7.9073],
      [110.5243, -7.907],
      [110.5251, -7.9069],
      [110.5253, -7.9068],
      [110.5257, -7.9062],
      [110.5262, -7.9054],
      [110.5263, -7.9046],
      [110.5262, -7.9042],
      [110.5258, -7.904],
      [110.525, -7.9037],
      [110.5246, -7.9026],
      [110.5246, -7.9025],
      [110.5243, -7.9012],
      [110.5244, -7.9006],
      [110.5247, -7.8999],
      [110.5255, -7.8988],
      [110.5262, -7.8982],
      [110.5265, -7.8974],
      [110.5268, -7.8969],
      [110.5274, -7.8966],
      [110.5276, -7.8961],
      [110.528, -7.8954],
      [110.5283, -7.8949],
      [110.5286, -7.8947],
      [110.5287, -7.8946],
      [110.5293, -7.8949],
      [110.5302, -7.8956],
      [110.5308, -7.8963],
      [110.531, -7.8963],
      [110.5316, -7.8966],
      [110.5321, -7.8964],
      [110.5328, -7.896],
      [110.5331, -7.8959],
      [110.534, -7.8954],
      [110.5341, -7.8953],
      [110.5343, -7.8953],
      [110.5351, -7.8955],
      [110.5353, -7.8955],
      [110.5359, -7.8955],
      [110.5361, -7.8955],
      [110.5372, -7.8953],
      [110.5375, -7.8953],
      [110.5381, -7.8953],
      [110.5387, -7.8954],
      [110.5391, -7.8955],
      [110.5398, -7.8956],
      [110.5402, -7.8956],
      [110.5402, -7.8957],
      [110.5407, -7.8955],
      [110.5408, -7.8954],
      [110.5413, -7.8951],
      [110.5414, -7.8945],
      [110.5416, -7.8942],
      [110.542, -7.8941],
      [110.5421, -7.8941],
      [110.5429, -7.8943],
      [110.5433, -7.8946],
      [110.5435, -7.8946],
      [110.5436, -7.8946],
      [110.5438, -7.8946],
      [110.5446, -7.8941],
      [110.5452, -7.8933],
      [110.5459, -7.8927],
      [110.5465, -7.892],
      [110.547, -7.8915],
      [110.5472, -7.8914],
      [110.5475, -7.8913],
      [110.5481, -7.8912],
      [110.5485, -7.891],
      [110.5495, -7.8907],
      [110.5505, -7.8903],
      [110.5518, -7.8887],
      [110.553, -7.8871],
      [110.5536, -7.8861],
      [110.5539, -7.8853],
      [110.5538, -7.8843],
      [110.5539, -7.8834],
      [110.5542, -7.8826],
      [110.5545, -7.882],
      [110.5545, -7.8816],
      [110.5542, -7.8804],
      [110.5541, -7.8799],
      [110.554, -7.8794],
      [110.5536, -7.8788],
      [110.5531, -7.8778],
      [110.5527, -7.8773],
      [110.5525, -7.8772],
      [110.552, -7.8767],
      [110.5515, -7.8762],
      [110.5514, -7.8756],
      [110.5513, -7.8748],
      [110.5509, -7.8744],
      [110.5508, -7.874],
      [110.5507, -7.8734],
      [110.5507, -7.873],
      [110.5506, -7.8723],
      [110.551, -7.8714],
      [110.5514, -7.8707],
      [110.5518, -7.8698],
      [110.552, -7.8687],
      [110.552, -7.8674],
      [110.5522, -7.8669],
      [110.5523, -7.8663]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'BEJI',
    border: [
      [110.5099, -7.8665],
      [110.5092, -7.8663],
      [110.5087, -7.8666],
      [110.5085, -7.8671],
      [110.5083, -7.8675],
      [110.5077, -7.8681],
      [110.5074, -7.8685],
      [110.5074, -7.8687],
      [110.5073, -7.8692],
      [110.5071, -7.8693],
      [110.5069, -7.8695],
      [110.5066, -7.87],
      [110.5065, -7.8705],
      [110.5065, -7.871],
      [110.5058, -7.8715],
      [110.5054, -7.8718],
      [110.5046, -7.873],
      [110.5036, -7.8741],
      [110.503, -7.8749],
      [110.5025, -7.8752],
      [110.5024, -7.8753],
      [110.5025, -7.8757],
      [110.5029, -7.8761],
      [110.5033, -7.8769],
      [110.5032, -7.8774],
      [110.5031, -7.8777],
      [110.5031, -7.8778],
      [110.5031, -7.8781],
      [110.5034, -7.8783],
      [110.504, -7.8786],
      [110.5047, -7.8788],
      [110.5048, -7.8793],
      [110.5049, -7.8798],
      [110.5041, -7.8799],
      [110.5037, -7.8806],
      [110.5037, -7.881],
      [110.5039, -7.8816],
      [110.5041, -7.8822],
      [110.504, -7.8827],
      [110.5038, -7.8831],
      [110.5035, -7.8833],
      [110.5032, -7.8834],
      [110.5025, -7.8834],
      [110.5021, -7.883],
      [110.5017, -7.8831],
      [110.5014, -7.8831],
      [110.5013, -7.8836],
      [110.5012, -7.8837],
      [110.5012, -7.8843],
      [110.5009, -7.8852],
      [110.5012, -7.8852],
      [110.5013, -7.8852],
      [110.5018, -7.8851],
      [110.5022, -7.8852],
      [110.5026, -7.8852],
      [110.5027, -7.885],
      [110.5029, -7.8847],
      [110.5033, -7.8848],
      [110.5038, -7.8849],
      [110.5045, -7.8849],
      [110.5049, -7.8849],
      [110.5052, -7.8846],
      [110.5053, -7.8842],
      [110.5054, -7.884],
      [110.5058, -7.8838],
      [110.5063, -7.884],
      [110.5074, -7.8843],
      [110.5079, -7.8846],
      [110.5083, -7.8846],
      [110.5092, -7.8844],
      [110.5104, -7.884],
      [110.5108, -7.8836],
      [110.511, -7.8843],
      [110.5113, -7.8854],
      [110.5115, -7.8857],
      [110.5116, -7.8859],
      [110.5126, -7.8872],
      [110.5126, -7.8872],
      [110.513, -7.8877],
      [110.5131, -7.8878],
      [110.5131, -7.8883],
      [110.5132, -7.8883],
      [110.5136, -7.8883],
      [110.5142, -7.8884],
      [110.5146, -7.8884],
      [110.515, -7.8882],
      [110.5153, -7.8878],
      [110.5156, -7.8878],
      [110.5166, -7.8878],
      [110.5176, -7.8878],
      [110.5182, -7.888],
      [110.5196, -7.8883],
      [110.5204, -7.8883],
      [110.5207, -7.8877],
      [110.5207, -7.8869],
      [110.521, -7.8859],
      [110.5214, -7.8855],
      [110.5218, -7.8851],
      [110.5224, -7.8851],
      [110.5227, -7.885],
      [110.5232, -7.8848],
      [110.5235, -7.8842],
      [110.5238, -7.8839],
      [110.5249, -7.8839],
      [110.5259, -7.8851],
      [110.5277, -7.8834],
      [110.5282, -7.8827],
      [110.5283, -7.8822],
      [110.5289, -7.882],
      [110.5293, -7.8814],
      [110.5298, -7.8814],
      [110.5301, -7.8814],
      [110.5303, -7.8811],
      [110.5308, -7.8808],
      [110.5312, -7.8806],
      [110.5303, -7.8801],
      [110.5303, -7.8798],
      [110.5305, -7.8793],
      [110.5307, -7.8788],
      [110.531, -7.8781],
      [110.5312, -7.8781],
      [110.5315, -7.8782],
      [110.5317, -7.8778],
      [110.5321, -7.8779],
      [110.5322, -7.878],
      [110.5324, -7.8782],
      [110.5323, -7.8776],
      [110.5323, -7.8774],
      [110.5323, -7.877],
      [110.5326, -7.8768],
      [110.5326, -7.8764],
      [110.5328, -7.8763],
      [110.5331, -7.8762],
      [110.5331, -7.8761],
      [110.5331, -7.8757],
      [110.5333, -7.8757],
      [110.5334, -7.8757],
      [110.5334, -7.8753],
      [110.5336, -7.8752],
      [110.5337, -7.8752],
      [110.5341, -7.875],
      [110.5341, -7.8748],
      [110.534, -7.8746],
      [110.5344, -7.8737],
      [110.5345, -7.8728],
      [110.5331, -7.8729],
      [110.5328, -7.8729],
      [110.5273, -7.8732],
      [110.5255, -7.8733],
      [110.5244, -7.8734],
      [110.5228, -7.8735],
      [110.522, -7.8734],
      [110.5219, -7.8734],
      [110.5217, -7.8734],
      [110.5198, -7.8734],
      [110.5187, -7.8732],
      [110.5186, -7.8732],
      [110.5175, -7.8728],
      [110.5164, -7.872],
      [110.5161, -7.8713],
      [110.5153, -7.8703],
      [110.5149, -7.8702],
      [110.5144, -7.8695],
      [110.5141, -7.8692],
      [110.5137, -7.8688],
      [110.5129, -7.8688],
      [110.5129, -7.8682],
      [110.5129, -7.8676],
      [110.5127, -7.8673],
      [110.5125, -7.8672],
      [110.512, -7.8673],
      [110.5115, -7.8668],
      [110.5111, -7.8667],
      [110.5099, -7.8665]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'PENGKOK',
    border: [
      [110.5025, -7.8752],
      [110.5019, -7.8748],
      [110.5003, -7.8737],
      [110.4994, -7.8732],
      [110.4987, -7.8729],
      [110.4984, -7.8726],
      [110.4986, -7.8711],
      [110.4987, -7.8703],
      [110.4987, -7.8701],
      [110.4987, -7.8698],
      [110.4985, -7.8694],
      [110.4985, -7.8692],
      [110.4984, -7.8689],
      [110.4987, -7.8687],
      [110.4992, -7.8684],
      [110.4985, -7.8681],
      [110.4982, -7.8679],
      [110.4975, -7.8679],
      [110.4972, -7.8677],
      [110.4969, -7.8676],
      [110.4964, -7.8677],
      [110.4962, -7.8677],
      [110.4961, -7.8675],
      [110.4961, -7.867],
      [110.4956, -7.8665],
      [110.495, -7.8659],
      [110.4948, -7.8655],
      [110.4948, -7.8648],
      [110.4943, -7.8646],
      [110.4943, -7.8643],
      [110.4948, -7.864],
      [110.4948, -7.8637],
      [110.4947, -7.8636],
      [110.4943, -7.8633],
      [110.4942, -7.863],
      [110.494, -7.8628],
      [110.4936, -7.8627],
      [110.4935, -7.8625],
      [110.4932, -7.862],
      [110.4932, -7.8616],
      [110.4933, -7.8611],
      [110.4933, -7.8606],
      [110.4931, -7.8603],
      [110.4927, -7.8603],
      [110.4921, -7.8606],
      [110.4916, -7.8611],
      [110.4915, -7.8608],
      [110.4915, -7.8602],
      [110.4915, -7.86],
      [110.4916, -7.8598],
      [110.4916, -7.8596],
      [110.4913, -7.8595],
      [110.4907, -7.8596],
      [110.4905, -7.8597],
      [110.4894, -7.8601],
      [110.4886, -7.8602],
      [110.4884, -7.86],
      [110.4873, -7.86],
      [110.4881, -7.8614],
      [110.4887, -7.8617],
      [110.4891, -7.8615],
      [110.4891, -7.8616],
      [110.4888, -7.8619],
      [110.4888, -7.8621],
      [110.4885, -7.8621],
      [110.4873, -7.8654],
      [110.4877, -7.8654],
      [110.4881, -7.8654],
      [110.4885, -7.8658],
      [110.4889, -7.8662],
      [110.4891, -7.8664],
      [110.4895, -7.8665],
      [110.4898, -7.8671],
      [110.4899, -7.8672],
      [110.4899, -7.868],
      [110.4904, -7.868],
      [110.4906, -7.8681],
      [110.4905, -7.8686],
      [110.4907, -7.8688],
      [110.4915, -7.8693],
      [110.492, -7.8696],
      [110.492, -7.8699],
      [110.4918, -7.8701],
      [110.4915, -7.8703],
      [110.491, -7.8702],
      [110.4908, -7.8699],
      [110.4905, -7.8701],
      [110.4906, -7.8703],
      [110.4908, -7.8707],
      [110.4912, -7.8708],
      [110.4913, -7.8709],
      [110.4913, -7.8713],
      [110.4913, -7.8716],
      [110.4914, -7.8727],
      [110.491, -7.8727],
      [110.4908, -7.8727],
      [110.4906, -7.873],
      [110.4905, -7.8734],
      [110.4899, -7.8733],
      [110.4893, -7.8731],
      [110.4889, -7.8727],
      [110.4885, -7.8725],
      [110.4881, -7.8725],
      [110.4877, -7.8726],
      [110.4875, -7.8729],
      [110.4875, -7.8733],
      [110.4873, -7.8736],
      [110.4871, -7.8744],
      [110.4868, -7.8745],
      [110.4868, -7.8749],
      [110.4863, -7.8755],
      [110.4862, -7.8755],
      [110.4855, -7.8759],
      [110.4854, -7.8759],
      [110.4856, -7.8767],
      [110.4855, -7.877],
      [110.4851, -7.8775],
      [110.4848, -7.8781],
      [110.4845, -7.8785],
      [110.4843, -7.8789],
      [110.4843, -7.8793],
      [110.4845, -7.8798],
      [110.4851, -7.88],
      [110.4855, -7.8808],
      [110.4857, -7.8815],
      [110.4856, -7.8824],
      [110.4853, -7.8838],
      [110.4851, -7.8844],
      [110.4847, -7.885],
      [110.4835, -7.887],
      [110.4838, -7.8877],
      [110.4842, -7.8888],
      [110.4846, -7.889],
      [110.485, -7.8893],
      [110.4855, -7.89],
      [110.4861, -7.8901],
      [110.4883, -7.89],
      [110.4915, -7.89],
      [110.492, -7.8898],
      [110.4921, -7.8898],
      [110.492, -7.8894],
      [110.4921, -7.8888],
      [110.4925, -7.8882],
      [110.4933, -7.8878],
      [110.4944, -7.8875],
      [110.4951, -7.8874],
      [110.4965, -7.8874],
      [110.4969, -7.8876],
      [110.4972, -7.8877],
      [110.4975, -7.8877],
      [110.4981, -7.8878],
      [110.499, -7.8879],
      [110.4995, -7.8879],
      [110.4997, -7.8879],
      [110.5009, -7.8881],
      [110.5016, -7.8882],
      [110.502, -7.8882],
      [110.5022, -7.8879],
      [110.5022, -7.8875],
      [110.5021, -7.8873],
      [110.5018, -7.8867],
      [110.5011, -7.8859],
      [110.5011, -7.8859],
      [110.5009, -7.8852],
      [110.5012, -7.8843],
      [110.5012, -7.8837],
      [110.5013, -7.8836],
      [110.5014, -7.8831],
      [110.5017, -7.8831],
      [110.5021, -7.883],
      [110.5025, -7.8834],
      [110.5032, -7.8834],
      [110.5035, -7.8833],
      [110.5038, -7.8831],
      [110.504, -7.8827],
      [110.5041, -7.8822],
      [110.5039, -7.8816],
      [110.5037, -7.881],
      [110.5037, -7.8806],
      [110.5041, -7.8799],
      [110.5049, -7.8798],
      [110.5048, -7.8793],
      [110.5047, -7.8788],
      [110.504, -7.8786],
      [110.5034, -7.8783],
      [110.5031, -7.8781],
      [110.5031, -7.8778],
      [110.5031, -7.8777],
      [110.5032, -7.8774],
      [110.5033, -7.8769],
      [110.5029, -7.8761],
      [110.5025, -7.8757],
      [110.5024, -7.8753],
      [110.5025, -7.8752]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'SEMOYO',
    border: [
      [110.4786, -7.8512],
      [110.4778, -7.8507],
      [110.4774, -7.8503],
      [110.4767, -7.8507],
      [110.4755, -7.8516],
      [110.4753, -7.8521],
      [110.4752, -7.8526],
      [110.4747, -7.8533],
      [110.4743, -7.8539],
      [110.4739, -7.8543],
      [110.4736, -7.8548],
      [110.4735, -7.8555],
      [110.4733, -7.8561],
      [110.4731, -7.8574],
      [110.473, -7.8579],
      [110.473, -7.8593],
      [110.473, -7.8611],
      [110.4729, -7.8613],
      [110.4722, -7.8615],
      [110.4715, -7.8616],
      [110.4712, -7.8617],
      [110.4706, -7.8621],
      [110.4703, -7.8625],
      [110.4699, -7.8631],
      [110.4694, -7.864],
      [110.4689, -7.8649],
      [110.4685, -7.8656],
      [110.468, -7.866],
      [110.4677, -7.8666],
      [110.4676, -7.8668],
      [110.4676, -7.8669],
      [110.4674, -7.8676],
      [110.4673, -7.8683],
      [110.4671, -7.8698],
      [110.4676, -7.8712],
      [110.4684, -7.8728],
      [110.4689, -7.8737],
      [110.4692, -7.8742],
      [110.4693, -7.8743],
      [110.4698, -7.8749],
      [110.4702, -7.8759],
      [110.4708, -7.8772],
      [110.4714, -7.8781],
      [110.4715, -7.8787],
      [110.4716, -7.8789],
      [110.4719, -7.8793],
      [110.4724, -7.88],
      [110.473, -7.8821],
      [110.4734, -7.8827],
      [110.4735, -7.8828],
      [110.4738, -7.8836],
      [110.474, -7.8838],
      [110.4749, -7.8848],
      [110.475, -7.8863],
      [110.4755, -7.8891],
      [110.476, -7.8898],
      [110.4761, -7.8899],
      [110.4762, -7.8899],
      [110.4773, -7.8897],
      [110.4774, -7.8897],
      [110.4776, -7.8898],
      [110.4783, -7.8902],
      [110.4785, -7.8903],
      [110.4787, -7.8903],
      [110.4804, -7.89],
      [110.4834, -7.8897],
      [110.4844, -7.8899],
      [110.4855, -7.89],
      [110.485, -7.8893],
      [110.4846, -7.889],
      [110.4842, -7.8888],
      [110.4838, -7.8877],
      [110.4835, -7.887],
      [110.4847, -7.885],
      [110.4851, -7.8844],
      [110.4853, -7.8838],
      [110.4856, -7.8824],
      [110.4857, -7.8815],
      [110.4855, -7.8808],
      [110.4851, -7.88],
      [110.4845, -7.8798],
      [110.4843, -7.8793],
      [110.4843, -7.8789],
      [110.4845, -7.8785],
      [110.4848, -7.8781],
      [110.4851, -7.8775],
      [110.4855, -7.877],
      [110.4856, -7.8767],
      [110.4854, -7.8759],
      [110.4855, -7.8759],
      [110.4862, -7.8755],
      [110.4863, -7.8755],
      [110.4868, -7.8749],
      [110.4868, -7.8745],
      [110.4871, -7.8744],
      [110.4873, -7.8736],
      [110.4875, -7.8733],
      [110.4875, -7.8729],
      [110.4877, -7.8726],
      [110.4881, -7.8725],
      [110.4885, -7.8725],
      [110.4889, -7.8727],
      [110.4893, -7.8731],
      [110.4899, -7.8733],
      [110.4905, -7.8734],
      [110.4906, -7.873],
      [110.4908, -7.8727],
      [110.491, -7.8727],
      [110.4914, -7.8727],
      [110.4913, -7.8716],
      [110.4913, -7.8713],
      [110.4913, -7.8709],
      [110.4912, -7.8708],
      [110.4908, -7.8707],
      [110.4906, -7.8703],
      [110.4905, -7.8701],
      [110.4908, -7.8699],
      [110.491, -7.8702],
      [110.4915, -7.8703],
      [110.4918, -7.8701],
      [110.492, -7.8699],
      [110.492, -7.8696],
      [110.4915, -7.8693],
      [110.4907, -7.8688],
      [110.4905, -7.8686],
      [110.4906, -7.8681],
      [110.4904, -7.868],
      [110.4899, -7.868],
      [110.4899, -7.8672],
      [110.4898, -7.8671],
      [110.4895, -7.8665],
      [110.4891, -7.8664],
      [110.4889, -7.8662],
      [110.4885, -7.8658],
      [110.4881, -7.8654],
      [110.4877, -7.8654],
      [110.4873, -7.8654],
      [110.4872, -7.8653],
      [110.487, -7.8648],
      [110.4863, -7.8643],
      [110.4859, -7.8643],
      [110.4856, -7.8637],
      [110.4852, -7.8635],
      [110.4846, -7.8633],
      [110.4839, -7.8629],
      [110.4833, -7.8627],
      [110.4832, -7.8624],
      [110.4831, -7.8618],
      [110.4827, -7.8615],
      [110.4823, -7.8608],
      [110.4819, -7.8606],
      [110.4815, -7.8604],
      [110.4811, -7.86],
      [110.4806, -7.8598],
      [110.4803, -7.8596],
      [110.48, -7.8591],
      [110.4795, -7.8589],
      [110.4791, -7.8587],
      [110.4788, -7.8585],
      [110.4785, -7.8583],
      [110.4787, -7.8581],
      [110.4792, -7.8582],
      [110.4797, -7.8582],
      [110.4797, -7.8577],
      [110.4793, -7.8574],
      [110.4792, -7.8576],
      [110.4786, -7.8576],
      [110.4782, -7.8574],
      [110.4781, -7.8572],
      [110.4782, -7.8569],
      [110.4784, -7.8565],
      [110.4788, -7.8564],
      [110.4788, -7.8559],
      [110.4784, -7.856],
      [110.4784, -7.8558],
      [110.4787, -7.8557],
      [110.4786, -7.8554],
      [110.4782, -7.8551],
      [110.4781, -7.8549],
      [110.4782, -7.8538],
      [110.4789, -7.854],
      [110.4793, -7.8539],
      [110.4793, -7.8534],
      [110.4788, -7.8533],
      [110.4788, -7.8532],
      [110.4787, -7.8529],
      [110.4786, -7.8519],
      [110.4786, -7.8512]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'SALAM',
    border: [
      [110.502, -7.8452],
      [110.5013, -7.8449],
      [110.5009, -7.8449],
      [110.5001, -7.8453],
      [110.4998, -7.8452],
      [110.4993, -7.8453],
      [110.4987, -7.8455],
      [110.4985, -7.8456],
      [110.4982, -7.8459],
      [110.4977, -7.8465],
      [110.4971, -7.8469],
      [110.496, -7.8471],
      [110.495, -7.8474],
      [110.4938, -7.8478],
      [110.4937, -7.848],
      [110.4937, -7.8492],
      [110.4937, -7.8499],
      [110.4939, -7.8523],
      [110.4938, -7.8523],
      [110.4928, -7.8522],
      [110.4921, -7.8523],
      [110.4917, -7.8525],
      [110.4911, -7.853],
      [110.4907, -7.8531],
      [110.4901, -7.853],
      [110.4895, -7.8528],
      [110.489, -7.8521],
      [110.4883, -7.8521],
      [110.4882, -7.8521],
      [110.4873, -7.8522],
      [110.4871, -7.8519],
      [110.4868, -7.8519],
      [110.4865, -7.8517],
      [110.486, -7.8513],
      [110.4854, -7.8513],
      [110.4848, -7.851],
      [110.4841, -7.851],
      [110.4832, -7.8509],
      [110.4827, -7.8507],
      [110.482, -7.8508],
      [110.4815, -7.851],
      [110.4808, -7.851],
      [110.4803, -7.8512],
      [110.4797, -7.851],
      [110.4787, -7.8513],
      [110.4786, -7.8512],
      [110.4786, -7.8519],
      [110.4787, -7.8529],
      [110.4788, -7.8532],
      [110.4788, -7.8533],
      [110.4793, -7.8534],
      [110.4793, -7.8539],
      [110.4789, -7.854],
      [110.4782, -7.8538],
      [110.4781, -7.8549],
      [110.4782, -7.8551],
      [110.4786, -7.8554],
      [110.4787, -7.8557],
      [110.4784, -7.8558],
      [110.4784, -7.856],
      [110.4788, -7.8559],
      [110.4788, -7.8564],
      [110.4784, -7.8565],
      [110.4782, -7.8569],
      [110.4781, -7.8572],
      [110.4782, -7.8574],
      [110.4786, -7.8576],
      [110.4792, -7.8576],
      [110.4793, -7.8574],
      [110.4797, -7.8577],
      [110.4797, -7.8582],
      [110.4792, -7.8582],
      [110.4787, -7.8581],
      [110.4785, -7.8583],
      [110.4788, -7.8585],
      [110.4791, -7.8587],
      [110.4795, -7.8589],
      [110.48, -7.8591],
      [110.4803, -7.8596],
      [110.4806, -7.8598],
      [110.4811, -7.86],
      [110.4815, -7.8604],
      [110.4819, -7.8606],
      [110.4823, -7.8608],
      [110.4827, -7.8615],
      [110.4831, -7.8618],
      [110.4832, -7.8624],
      [110.4833, -7.8627],
      [110.4839, -7.8629],
      [110.4846, -7.8633],
      [110.4852, -7.8635],
      [110.4856, -7.8637],
      [110.4859, -7.8643],
      [110.4863, -7.8643],
      [110.487, -7.8648],
      [110.4872, -7.8653],
      [110.4873, -7.8654],
      [110.4885, -7.8621],
      [110.4888, -7.8621],
      [110.4888, -7.8619],
      [110.4891, -7.8616],
      [110.4891, -7.8615],
      [110.4887, -7.8617],
      [110.4881, -7.8614],
      [110.4873, -7.86],
      [110.4884, -7.86],
      [110.4886, -7.8602],
      [110.4894, -7.8601],
      [110.4905, -7.8597],
      [110.4907, -7.8596],
      [110.4913, -7.8595],
      [110.4916, -7.8596],
      [110.4916, -7.8598],
      [110.4915, -7.86],
      [110.4915, -7.8602],
      [110.4915, -7.8608],
      [110.4916, -7.8611],
      [110.4921, -7.8606],
      [110.4927, -7.8603],
      [110.4931, -7.8603],
      [110.4933, -7.8606],
      [110.4933, -7.8611],
      [110.4932, -7.8616],
      [110.4932, -7.862],
      [110.4935, -7.8625],
      [110.4936, -7.8627],
      [110.494, -7.8628],
      [110.4942, -7.863],
      [110.4943, -7.8633],
      [110.4947, -7.8636],
      [110.4948, -7.8637],
      [110.4948, -7.864],
      [110.4943, -7.8643],
      [110.4943, -7.8646],
      [110.4948, -7.8648],
      [110.4948, -7.8655],
      [110.495, -7.8659],
      [110.4956, -7.8665],
      [110.4961, -7.867],
      [110.4961, -7.8675],
      [110.4962, -7.8677],
      [110.4964, -7.8677],
      [110.4969, -7.8676],
      [110.4972, -7.8677],
      [110.4975, -7.8679],
      [110.4982, -7.8679],
      [110.4985, -7.8681],
      [110.4992, -7.8684],
      [110.4987, -7.8687],
      [110.4984, -7.8689],
      [110.4985, -7.8692],
      [110.4985, -7.8694],
      [110.4987, -7.8698],
      [110.4987, -7.8701],
      [110.4987, -7.8703],
      [110.4986, -7.8711],
      [110.4984, -7.8726],
      [110.4987, -7.8729],
      [110.4994, -7.8732],
      [110.5003, -7.8737],
      [110.5019, -7.8748],
      [110.5025, -7.8752],
      [110.503, -7.8749],
      [110.5036, -7.8741],
      [110.5046, -7.873],
      [110.5054, -7.8718],
      [110.5058, -7.8715],
      [110.5065, -7.871],
      [110.5065, -7.8705],
      [110.5066, -7.87],
      [110.5069, -7.8695],
      [110.5071, -7.8693],
      [110.5073, -7.8692],
      [110.5074, -7.8687],
      [110.5074, -7.8685],
      [110.5077, -7.8681],
      [110.5083, -7.8675],
      [110.5085, -7.8671],
      [110.5087, -7.8666],
      [110.5092, -7.8663],
      [110.5075, -7.8659],
      [110.5055, -7.8654],
      [110.5034, -7.8655],
      [110.5033, -7.8655],
      [110.5024, -7.8646],
      [110.5021, -7.864],
      [110.5013, -7.8635],
      [110.5007, -7.8629],
      [110.4999, -7.8623],
      [110.4989, -7.8612],
      [110.4983, -7.8603],
      [110.4981, -7.8596],
      [110.4979, -7.8589],
      [110.498, -7.8588],
      [110.4986, -7.8586],
      [110.499, -7.8586],
      [110.4993, -7.8584],
      [110.4997, -7.8577],
      [110.4999, -7.8572],
      [110.5002, -7.857],
      [110.5006, -7.8572],
      [110.5009, -7.8573],
      [110.5015, -7.8573],
      [110.5017, -7.8576],
      [110.502, -7.8577],
      [110.5021, -7.8574],
      [110.5023, -7.8569],
      [110.5026, -7.8567],
      [110.5032, -7.8564],
      [110.5043, -7.8563],
      [110.5045, -7.8563],
      [110.505, -7.8569],
      [110.5052, -7.857],
      [110.5053, -7.857],
      [110.5063, -7.8569],
      [110.5064, -7.8568],
      [110.5064, -7.8564],
      [110.5063, -7.8562],
      [110.5061, -7.856],
      [110.5056, -7.8552],
      [110.5057, -7.8546],
      [110.5052, -7.854],
      [110.5047, -7.8534],
      [110.5046, -7.8525],
      [110.5044, -7.8517],
      [110.5042, -7.8513],
      [110.5041, -7.8509],
      [110.504, -7.851],
      [110.5035, -7.8511],
      [110.5029, -7.8511],
      [110.5026, -7.8509],
      [110.5029, -7.8502],
      [110.5024, -7.8498],
      [110.5021, -7.8495],
      [110.5022, -7.8494],
      [110.5024, -7.8493],
      [110.5025, -7.849],
      [110.5028, -7.8489],
      [110.5029, -7.8487],
      [110.5031, -7.8483],
      [110.5032, -7.8478],
      [110.503, -7.8477],
      [110.5027, -7.8473],
      [110.5026, -7.8468],
      [110.5022, -7.8465],
      [110.502, -7.8462],
      [110.5018, -7.8457],
      [110.502, -7.8452]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'PATUK',
    border: [
      [110.5028, -7.8387],
      [110.5025, -7.8386],
      [110.5016, -7.8385],
      [110.5004, -7.8384],
      [110.4989, -7.838],
      [110.4982, -7.838],
      [110.4972, -7.838],
      [110.4964, -7.8382],
      [110.4961, -7.8385],
      [110.4954, -7.839],
      [110.4947, -7.8394],
      [110.4942, -7.8394],
      [110.4935, -7.8392],
      [110.4929, -7.8388],
      [110.4918, -7.8387],
      [110.4915, -7.8386],
      [110.4903, -7.8383],
      [110.4889, -7.8377],
      [110.4886, -7.8377],
      [110.4883, -7.838],
      [110.4881, -7.8383],
      [110.4879, -7.8392],
      [110.4878, -7.8395],
      [110.4877, -7.8398],
      [110.4874, -7.8404],
      [110.4869, -7.841],
      [110.4863, -7.8414],
      [110.4859, -7.8416],
      [110.4854, -7.8419],
      [110.4847, -7.8422],
      [110.4841, -7.8425],
      [110.4831, -7.843],
      [110.4822, -7.8434],
      [110.4813, -7.844],
      [110.4806, -7.8444],
      [110.4804, -7.8448],
      [110.4803, -7.845],
      [110.4802, -7.8454],
      [110.48, -7.8457],
      [110.4795, -7.8465],
      [110.4791, -7.8473],
      [110.4787, -7.8478],
      [110.4782, -7.8487],
      [110.4778, -7.8491],
      [110.4775, -7.8497],
      [110.4774, -7.8503],
      [110.4778, -7.8507],
      [110.4786, -7.8512],
      [110.4787, -7.8513],
      [110.4797, -7.851],
      [110.4803, -7.8512],
      [110.4808, -7.851],
      [110.4815, -7.851],
      [110.482, -7.8508],
      [110.4827, -7.8507],
      [110.4832, -7.8509],
      [110.4841, -7.851],
      [110.4848, -7.851],
      [110.4854, -7.8513],
      [110.486, -7.8513],
      [110.4865, -7.8517],
      [110.4868, -7.8519],
      [110.4871, -7.8519],
      [110.4873, -7.8522],
      [110.4882, -7.8521],
      [110.4883, -7.8521],
      [110.489, -7.8521],
      [110.4895, -7.8528],
      [110.4901, -7.853],
      [110.4907, -7.8531],
      [110.4911, -7.853],
      [110.4917, -7.8525],
      [110.4921, -7.8523],
      [110.4928, -7.8522],
      [110.4938, -7.8523],
      [110.4939, -7.8523],
      [110.4937, -7.8499],
      [110.4937, -7.8492],
      [110.4937, -7.848],
      [110.4938, -7.8478],
      [110.495, -7.8474],
      [110.496, -7.8471],
      [110.4971, -7.8469],
      [110.4977, -7.8465],
      [110.4982, -7.8459],
      [110.4985, -7.8456],
      [110.4987, -7.8455],
      [110.4993, -7.8453],
      [110.4998, -7.8452],
      [110.5001, -7.8453],
      [110.5009, -7.8449],
      [110.5013, -7.8449],
      [110.502, -7.8452],
      [110.5022, -7.8448],
      [110.5022, -7.8444],
      [110.5024, -7.8438],
      [110.5026, -7.8431],
      [110.5027, -7.8427],
      [110.5027, -7.8418],
      [110.5027, -7.8413],
      [110.5028, -7.8405],
      [110.5029, -7.8397],
      [110.5028, -7.839],
      [110.5028, -7.8387]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'NGORO-ORO',
    border: [
      [110.5405, -7.8242],
      [110.5402, -7.8241],
      [110.5399, -7.824],
      [110.5397, -7.8239],
      [110.5394, -7.8238],
      [110.5389, -7.8238],
      [110.5386, -7.8235],
      [110.5385, -7.8235],
      [110.5385, -7.8237],
      [110.5381, -7.8238],
      [110.5377, -7.8241],
      [110.5374, -7.8238],
      [110.5373, -7.8237],
      [110.5371, -7.8235],
      [110.5367, -7.8234],
      [110.536, -7.8234],
      [110.5358, -7.8234],
      [110.5354, -7.8233],
      [110.5351, -7.8228],
      [110.535, -7.8227],
      [110.5347, -7.8225],
      [110.5346, -7.8226],
      [110.5343, -7.8228],
      [110.5341, -7.8228],
      [110.534, -7.8229],
      [110.5337, -7.823],
      [110.5332, -7.8231],
      [110.533, -7.8232],
      [110.5329, -7.8234],
      [110.5328, -7.8237],
      [110.5329, -7.824],
      [110.5329, -7.8243],
      [110.5328, -7.8244],
      [110.5327, -7.8245],
      [110.5325, -7.8247],
      [110.5324, -7.8246],
      [110.5323, -7.8245],
      [110.5323, -7.8242],
      [110.5323, -7.8239],
      [110.5324, -7.8235],
      [110.5323, -7.8234],
      [110.532, -7.8234],
      [110.5314, -7.8236],
      [110.5309, -7.8238],
      [110.5303, -7.8241],
      [110.5301, -7.8238],
      [110.5299, -7.8237],
      [110.5297, -7.8238],
      [110.5297, -7.824],
      [110.5297, -7.8243],
      [110.5302, -7.8247],
      [110.5306, -7.8251],
      [110.5307, -7.8252],
      [110.5309, -7.8254],
      [110.5311, -7.8256],
      [110.5311, -7.8258],
      [110.5309, -7.8258],
      [110.5307, -7.8258],
      [110.5305, -7.8257],
      [110.5303, -7.8256],
      [110.5302, -7.8256],
      [110.5301, -7.8255],
      [110.53, -7.8254],
      [110.5296, -7.8257],
      [110.5293, -7.8258],
      [110.529, -7.8259],
      [110.5285, -7.8258],
      [110.5283, -7.8261],
      [110.5282, -7.8262],
      [110.5282, -7.8263],
      [110.5283, -7.8266],
      [110.5284, -7.8269],
      [110.5283, -7.8271],
      [110.528, -7.8271],
      [110.5279, -7.8268],
      [110.5277, -7.8267],
      [110.5274, -7.8267],
      [110.5272, -7.8268],
      [110.5271, -7.8271],
      [110.5271, -7.8275],
      [110.527, -7.8278],
      [110.5268, -7.8278],
      [110.5266, -7.8278],
      [110.5263, -7.8276],
      [110.526, -7.8274],
      [110.5257, -7.8274],
      [110.5251, -7.8276],
      [110.5249, -7.8277],
      [110.5246, -7.8275],
      [110.5244, -7.8275],
      [110.5241, -7.8276],
      [110.5237, -7.828],
      [110.5236, -7.8282],
      [110.5233, -7.8282],
      [110.5229, -7.828],
      [110.5226, -7.8281],
      [110.5225, -7.8283],
      [110.5224, -7.8284],
      [110.5217, -7.8286],
      [110.5216, -7.8289],
      [110.5214, -7.8293],
      [110.5209, -7.8299],
      [110.5206, -7.8302],
      [110.5201, -7.8306],
      [110.5195, -7.8309],
      [110.5196, -7.831],
      [110.5199, -7.8315],
      [110.5201, -7.8317],
      [110.5207, -7.8319],
      [110.5208, -7.832],
      [110.5212, -7.8322],
      [110.5213, -7.8329],
      [110.5213, -7.833],
      [110.5213, -7.8336],
      [110.5208, -7.8345],
      [110.52, -7.8354],
      [110.5196, -7.8358],
      [110.5188, -7.8361],
      [110.5177, -7.8365],
      [110.5166, -7.837],
      [110.5157, -7.8374],
      [110.515, -7.8375],
      [110.5141, -7.8374],
      [110.5131, -7.8374],
      [110.5123, -7.8374],
      [110.5113, -7.8376],
      [110.5097, -7.8378],
      [110.5088, -7.8384],
      [110.5079, -7.8389],
      [110.5074, -7.8392],
      [110.507, -7.8391],
      [110.5057, -7.8389],
      [110.5046, -7.8386],
      [110.5036, -7.8388],
      [110.5028, -7.8387],
      [110.5028, -7.839],
      [110.5029, -7.8397],
      [110.5028, -7.8405],
      [110.5027, -7.8413],
      [110.5027, -7.8418],
      [110.5027, -7.8427],
      [110.5026, -7.8431],
      [110.5024, -7.8438],
      [110.5022, -7.8444],
      [110.5022, -7.8448],
      [110.502, -7.8452],
      [110.5018, -7.8457],
      [110.502, -7.8462],
      [110.5022, -7.8465],
      [110.5026, -7.8468],
      [110.5027, -7.8473],
      [110.503, -7.8477],
      [110.5032, -7.8478],
      [110.5031, -7.8483],
      [110.5029, -7.8487],
      [110.5028, -7.8489],
      [110.5025, -7.849],
      [110.5024, -7.8493],
      [110.5022, -7.8494],
      [110.5021, -7.8495],
      [110.5024, -7.8498],
      [110.5029, -7.8502],
      [110.5026, -7.8509],
      [110.5029, -7.8511],
      [110.5035, -7.8511],
      [110.504, -7.851],
      [110.5041, -7.8509],
      [110.5046, -7.8506],
      [110.5051, -7.8501],
      [110.5057, -7.8499],
      [110.5065, -7.8497],
      [110.5069, -7.8492],
      [110.5075, -7.8491],
      [110.5081, -7.849],
      [110.509, -7.8491],
      [110.5097, -7.8494],
      [110.5099, -7.8495],
      [110.5101, -7.85],
      [110.5103, -7.8504],
      [110.5106, -7.8507],
      [110.5109, -7.851],
      [110.5113, -7.851],
      [110.5138, -7.851],
      [110.5159, -7.8511],
      [110.5175, -7.8516],
      [110.5182, -7.8516],
      [110.5194, -7.8515],
      [110.5201, -7.8515],
      [110.5205, -7.8515],
      [110.5209, -7.8514],
      [110.5217, -7.8509],
      [110.5226, -7.851],
      [110.5235, -7.8512],
      [110.5252, -7.8492],
      [110.5253, -7.8486],
      [110.5254, -7.8484],
      [110.5258, -7.8481],
      [110.5257, -7.8478],
      [110.5256, -7.8472],
      [110.5258, -7.847],
      [110.5261, -7.8471],
      [110.5265, -7.8471],
      [110.5266, -7.8469],
      [110.5265, -7.8466],
      [110.5263, -7.8463],
      [110.5265, -7.8456],
      [110.5264, -7.8453],
      [110.5262, -7.8446],
      [110.5265, -7.8444],
      [110.5268, -7.8444],
      [110.5274, -7.8446],
      [110.5292, -7.8448],
      [110.5297, -7.8449],
      [110.5302, -7.8449],
      [110.5308, -7.8449],
      [110.5313, -7.8451],
      [110.5318, -7.8455],
      [110.532, -7.8448],
      [110.5326, -7.844],
      [110.5332, -7.8435],
      [110.5337, -7.8426],
      [110.5342, -7.8421],
      [110.5346, -7.8415],
      [110.5351, -7.8415],
      [110.5363, -7.8417],
      [110.5373, -7.8417],
      [110.5402, -7.8417],
      [110.5428, -7.8414],
      [110.543, -7.8413],
      [110.543, -7.8405],
      [110.5432, -7.8383],
      [110.5432, -7.8373],
      [110.5432, -7.8357],
      [110.5432, -7.834],
      [110.5432, -7.8334],
      [110.5432, -7.8328],
      [110.5422, -7.8301],
      [110.5421, -7.8296],
      [110.5415, -7.8282],
      [110.5415, -7.8275],
      [110.5414, -7.8268],
      [110.5414, -7.8264],
      [110.5413, -7.8262],
      [110.541, -7.826],
      [110.541, -7.8259],
      [110.541, -7.8256],
      [110.5409, -7.8256],
      [110.5406, -7.8254],
      [110.5405, -7.8252],
      [110.5404, -7.8249],
      [110.5405, -7.8242]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'NGLANGGERAN',
    border: [
      [110.5596, -7.8372],
      [110.5594, -7.8371],
      [110.5587, -7.8371],
      [110.5579, -7.8373],
      [110.5574, -7.8376],
      [110.5564, -7.8379],
      [110.5545, -7.8385],
      [110.5519, -7.8393],
      [110.5491, -7.8401],
      [110.5446, -7.8408],
      [110.543, -7.8413],
      [110.5428, -7.8414],
      [110.5402, -7.8417],
      [110.5373, -7.8417],
      [110.5363, -7.8417],
      [110.5351, -7.8415],
      [110.5346, -7.8415],
      [110.5342, -7.8421],
      [110.5337, -7.8426],
      [110.5332, -7.8435],
      [110.5326, -7.844],
      [110.532, -7.8448],
      [110.5318, -7.8455],
      [110.5313, -7.8451],
      [110.5308, -7.8449],
      [110.5302, -7.8449],
      [110.5297, -7.8449],
      [110.5292, -7.8448],
      [110.5274, -7.8446],
      [110.5268, -7.8444],
      [110.5265, -7.8444],
      [110.5262, -7.8446],
      [110.5264, -7.8453],
      [110.5265, -7.8456],
      [110.5263, -7.8463],
      [110.5265, -7.8466],
      [110.5266, -7.8469],
      [110.5265, -7.8471],
      [110.5261, -7.8471],
      [110.5258, -7.847],
      [110.5256, -7.8472],
      [110.5257, -7.8478],
      [110.5258, -7.8481],
      [110.5254, -7.8484],
      [110.5253, -7.8486],
      [110.5252, -7.8492],
      [110.5235, -7.8512],
      [110.5226, -7.851],
      [110.5217, -7.8509],
      [110.5209, -7.8514],
      [110.5205, -7.8515],
      [110.5201, -7.8515],
      [110.5194, -7.8515],
      [110.5182, -7.8516],
      [110.5175, -7.8516],
      [110.5159, -7.8511],
      [110.5138, -7.851],
      [110.5113, -7.851],
      [110.5109, -7.851],
      [110.5106, -7.8507],
      [110.5103, -7.8504],
      [110.5101, -7.85],
      [110.5099, -7.8495],
      [110.5097, -7.8494],
      [110.509, -7.8491],
      [110.5081, -7.849],
      [110.5075, -7.8491],
      [110.5069, -7.8492],
      [110.5065, -7.8497],
      [110.5057, -7.8499],
      [110.5051, -7.8501],
      [110.5046, -7.8506],
      [110.5041, -7.8509],
      [110.5042, -7.8513],
      [110.5044, -7.8517],
      [110.5046, -7.8525],
      [110.5047, -7.8534],
      [110.5052, -7.854],
      [110.5057, -7.8546],
      [110.5056, -7.8552],
      [110.5061, -7.856],
      [110.5063, -7.8562],
      [110.5064, -7.8564],
      [110.5064, -7.8568],
      [110.5063, -7.8569],
      [110.5053, -7.857],
      [110.5052, -7.857],
      [110.505, -7.8569],
      [110.5045, -7.8563],
      [110.5043, -7.8563],
      [110.5032, -7.8564],
      [110.5026, -7.8567],
      [110.5023, -7.8569],
      [110.5021, -7.8574],
      [110.502, -7.8577],
      [110.5017, -7.8576],
      [110.5015, -7.8573],
      [110.5009, -7.8573],
      [110.5006, -7.8572],
      [110.5002, -7.857],
      [110.4999, -7.8572],
      [110.4997, -7.8577],
      [110.4993, -7.8584],
      [110.499, -7.8586],
      [110.4986, -7.8586],
      [110.498, -7.8588],
      [110.4979, -7.8589],
      [110.4981, -7.8596],
      [110.4983, -7.8603],
      [110.4989, -7.8612],
      [110.4999, -7.8623],
      [110.5007, -7.8629],
      [110.5013, -7.8635],
      [110.5021, -7.864],
      [110.5024, -7.8646],
      [110.5033, -7.8655],
      [110.5034, -7.8655],
      [110.5055, -7.8654],
      [110.5075, -7.8659],
      [110.5092, -7.8663],
      [110.5099, -7.8665],
      [110.51, -7.8664],
      [110.5102, -7.8661],
      [110.5103, -7.8659],
      [110.5101, -7.8657],
      [110.5102, -7.8652],
      [110.5098, -7.8651],
      [110.5099, -7.8648],
      [110.5098, -7.8646],
      [110.5099, -7.864],
      [110.5097, -7.8639],
      [110.5096, -7.8636],
      [110.5101, -7.8627],
      [110.5103, -7.8623],
      [110.5108, -7.8614],
      [110.5111, -7.8612],
      [110.5119, -7.8605],
      [110.5126, -7.8602],
      [110.5134, -7.8602],
      [110.5137, -7.8603],
      [110.5143, -7.8606],
      [110.5149, -7.8608],
      [110.5154, -7.8609],
      [110.5159, -7.8605],
      [110.5163, -7.8603],
      [110.5169, -7.8602],
      [110.5174, -7.86],
      [110.5181, -7.8597],
      [110.5189, -7.8597],
      [110.5191, -7.8596],
      [110.5203, -7.8586],
      [110.5206, -7.8584],
      [110.5209, -7.8582],
      [110.5212, -7.858],
      [110.5224, -7.8574],
      [110.5234, -7.8571],
      [110.5243, -7.8569],
      [110.5249, -7.8568],
      [110.5268, -7.8567],
      [110.5275, -7.8569],
      [110.5278, -7.8571],
      [110.5282, -7.8574],
      [110.5288, -7.8582],
      [110.5291, -7.8587],
      [110.5297, -7.8593],
      [110.5299, -7.8597],
      [110.5304, -7.8599],
      [110.5309, -7.8602],
      [110.5313, -7.8598],
      [110.5317, -7.8593],
      [110.532, -7.8589],
      [110.5321, -7.8585],
      [110.5324, -7.858],
      [110.5328, -7.8581],
      [110.5332, -7.8582],
      [110.5334, -7.8581],
      [110.5334, -7.8577],
      [110.5337, -7.8577],
      [110.5341, -7.858],
      [110.5343, -7.858],
      [110.5348, -7.8579],
      [110.5353, -7.858],
      [110.5354, -7.8578],
      [110.5354, -7.8575],
      [110.5353, -7.8571],
      [110.5354, -7.8564],
      [110.5357, -7.8562],
      [110.5363, -7.856],
      [110.5365, -7.8558],
      [110.5365, -7.8554],
      [110.5366, -7.8552],
      [110.5377, -7.8545],
      [110.5379, -7.8543],
      [110.5381, -7.8533],
      [110.5388, -7.8519],
      [110.5391, -7.8515],
      [110.5405, -7.8502],
      [110.5414, -7.8498],
      [110.5426, -7.8499],
      [110.5429, -7.8501],
      [110.5436, -7.8504],
      [110.5439, -7.8506],
      [110.5444, -7.8507],
      [110.5447, -7.8509],
      [110.5451, -7.851],
      [110.5457, -7.8514],
      [110.546, -7.8516],
      [110.5461, -7.8515],
      [110.5473, -7.8512],
      [110.5487, -7.8507],
      [110.549, -7.8506],
      [110.5494, -7.8506],
      [110.5504, -7.8505],
      [110.5526, -7.8508],
      [110.5541, -7.851],
      [110.5566, -7.8514],
      [110.5568, -7.8514],
      [110.557, -7.8511],
      [110.557, -7.8508],
      [110.5572, -7.8504],
      [110.5574, -7.8502],
      [110.5576, -7.8499],
      [110.5577, -7.8495],
      [110.5578, -7.8489],
      [110.5578, -7.8487],
      [110.5579, -7.8484],
      [110.558, -7.8482],
      [110.5579, -7.8477],
      [110.5582, -7.8475],
      [110.5586, -7.8472],
      [110.5588, -7.8469],
      [110.5591, -7.8465],
      [110.5595, -7.846],
      [110.5595, -7.8455],
      [110.5596, -7.845],
      [110.5598, -7.8444],
      [110.5598, -7.8441],
      [110.5595, -7.8438],
      [110.5594, -7.8432],
      [110.5593, -7.8426],
      [110.559, -7.842],
      [110.559, -7.8408],
      [110.5588, -7.8405],
      [110.5587, -7.8404],
      [110.5587, -7.8399],
      [110.559, -7.839],
      [110.5591, -7.8383],
      [110.5592, -7.838],
      [110.5594, -7.8376],
      [110.5595, -7.8373],
      [110.5596, -7.8372]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'PUTAT',
    border: [
      [110.5568, -7.8514],
      [110.5566, -7.8514],
      [110.5541, -7.851],
      [110.5526, -7.8508],
      [110.5504, -7.8505],
      [110.5494, -7.8506],
      [110.549, -7.8506],
      [110.5487, -7.8507],
      [110.5473, -7.8512],
      [110.5461, -7.8515],
      [110.546, -7.8516],
      [110.5457, -7.8514],
      [110.5451, -7.851],
      [110.5447, -7.8509],
      [110.5444, -7.8507],
      [110.5439, -7.8506],
      [110.5436, -7.8504],
      [110.5429, -7.8501],
      [110.5426, -7.8499],
      [110.5414, -7.8498],
      [110.5405, -7.8502],
      [110.5391, -7.8515],
      [110.5388, -7.8519],
      [110.5381, -7.8533],
      [110.5379, -7.8543],
      [110.5377, -7.8545],
      [110.5366, -7.8552],
      [110.5365, -7.8554],
      [110.5365, -7.8558],
      [110.5363, -7.856],
      [110.5357, -7.8562],
      [110.5354, -7.8564],
      [110.5353, -7.8571],
      [110.5354, -7.8575],
      [110.5354, -7.8578],
      [110.5353, -7.858],
      [110.5348, -7.8579],
      [110.5343, -7.858],
      [110.5341, -7.858],
      [110.5337, -7.8577],
      [110.5334, -7.8577],
      [110.5334, -7.8581],
      [110.5332, -7.8582],
      [110.5328, -7.8581],
      [110.5324, -7.858],
      [110.5321, -7.8585],
      [110.532, -7.8589],
      [110.5317, -7.8593],
      [110.5313, -7.8598],
      [110.5309, -7.8602],
      [110.5304, -7.8599],
      [110.5299, -7.8597],
      [110.5297, -7.8593],
      [110.5291, -7.8587],
      [110.5288, -7.8582],
      [110.5282, -7.8574],
      [110.5278, -7.8571],
      [110.5275, -7.8569],
      [110.5268, -7.8567],
      [110.5249, -7.8568],
      [110.5243, -7.8569],
      [110.5234, -7.8571],
      [110.5224, -7.8574],
      [110.5212, -7.858],
      [110.5209, -7.8582],
      [110.5206, -7.8584],
      [110.5203, -7.8586],
      [110.5191, -7.8596],
      [110.5189, -7.8597],
      [110.5181, -7.8597],
      [110.5174, -7.86],
      [110.5169, -7.8602],
      [110.5163, -7.8603],
      [110.5159, -7.8605],
      [110.5154, -7.8609],
      [110.5149, -7.8608],
      [110.5143, -7.8606],
      [110.5137, -7.8603],
      [110.5134, -7.8602],
      [110.5126, -7.8602],
      [110.5119, -7.8605],
      [110.5111, -7.8612],
      [110.5108, -7.8614],
      [110.5103, -7.8623],
      [110.5101, -7.8627],
      [110.5096, -7.8636],
      [110.5097, -7.8639],
      [110.5099, -7.864],
      [110.5098, -7.8646],
      [110.5099, -7.8648],
      [110.5098, -7.8651],
      [110.5102, -7.8652],
      [110.5101, -7.8657],
      [110.5103, -7.8659],
      [110.5102, -7.8661],
      [110.51, -7.8664],
      [110.5099, -7.8665],
      [110.5111, -7.8667],
      [110.5115, -7.8668],
      [110.512, -7.8673],
      [110.5125, -7.8672],
      [110.5127, -7.8673],
      [110.5129, -7.8676],
      [110.5129, -7.8682],
      [110.5129, -7.8688],
      [110.5137, -7.8688],
      [110.5141, -7.8692],
      [110.5144, -7.8695],
      [110.5149, -7.8702],
      [110.5153, -7.8703],
      [110.5161, -7.8713],
      [110.5164, -7.872],
      [110.5175, -7.8728],
      [110.5186, -7.8732],
      [110.5187, -7.8732],
      [110.5198, -7.8734],
      [110.5217, -7.8734],
      [110.5219, -7.8734],
      [110.522, -7.8734],
      [110.5228, -7.8735],
      [110.5244, -7.8734],
      [110.5255, -7.8733],
      [110.5273, -7.8732],
      [110.5328, -7.8729],
      [110.5331, -7.8729],
      [110.5345, -7.8728],
      [110.5352, -7.873],
      [110.5353, -7.8727],
      [110.5352, -7.8722],
      [110.5353, -7.8718],
      [110.5356, -7.8714],
      [110.5357, -7.871],
      [110.5358, -7.8707],
      [110.5358, -7.8701],
      [110.536, -7.8698],
      [110.5363, -7.8695],
      [110.5367, -7.8691],
      [110.5373, -7.8688],
      [110.5399, -7.8687],
      [110.5406, -7.8688],
      [110.5423, -7.869],
      [110.5435, -7.8693],
      [110.5439, -7.8691],
      [110.5442, -7.8691],
      [110.545, -7.8691],
      [110.5454, -7.8692],
      [110.5455, -7.8692],
      [110.5456, -7.8689],
      [110.5459, -7.8689],
      [110.5461, -7.8691],
      [110.5466, -7.8691],
      [110.547, -7.8691],
      [110.5475, -7.8691],
      [110.5477, -7.8689],
      [110.5479, -7.8686],
      [110.5478, -7.8682],
      [110.5481, -7.8683],
      [110.5484, -7.8683],
      [110.5488, -7.8683],
      [110.549, -7.8684],
      [110.5494, -7.8684],
      [110.5498, -7.8684],
      [110.5498, -7.8681],
      [110.5499, -7.8677],
      [110.5503, -7.8671],
      [110.5507, -7.8669],
      [110.551, -7.8668],
      [110.551, -7.8664],
      [110.5513, -7.866],
      [110.5516, -7.8661],
      [110.5523, -7.8663],
      [110.5525, -7.8664],
      [110.5528, -7.8662],
      [110.5529, -7.8655],
      [110.5532, -7.8649],
      [110.5532, -7.8642],
      [110.5534, -7.8641],
      [110.5535, -7.8637],
      [110.5538, -7.8636],
      [110.5542, -7.8631],
      [110.5545, -7.8629],
      [110.5549, -7.8631],
      [110.5553, -7.8634],
      [110.5557, -7.8636],
      [110.5559, -7.8634],
      [110.5561, -7.8629],
      [110.5561, -7.8626],
      [110.5557, -7.8622],
      [110.5558, -7.8618],
      [110.5558, -7.8615],
      [110.5559, -7.8612],
      [110.5558, -7.8608],
      [110.556, -7.8606],
      [110.5561, -7.8602],
      [110.5564, -7.8598],
      [110.5567, -7.8596],
      [110.5569, -7.8592],
      [110.5568, -7.8591],
      [110.5566, -7.859],
      [110.5564, -7.8585],
      [110.5564, -7.8577],
      [110.5563, -7.8573],
      [110.5565, -7.8567],
      [110.5563, -7.8563],
      [110.5562, -7.856],
      [110.5567, -7.8548],
      [110.557, -7.8541],
      [110.5569, -7.8534],
      [110.5569, -7.8524],
      [110.5568, -7.8516],
      [110.5568, -7.8514]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'NGLEGI',
    border: [
      [110.5714, -7.8369],
      [110.571, -7.8367],
      [110.5705, -7.8364],
      [110.5701, -7.8357],
      [110.5698, -7.8352],
      [110.5696, -7.8349],
      [110.5688, -7.8346],
      [110.5685, -7.8346],
      [110.5683, -7.8343],
      [110.5675, -7.8343],
      [110.5671, -7.8338],
      [110.5667, -7.8335],
      [110.5661, -7.8334],
      [110.5657, -7.8327],
      [110.5653, -7.8328],
      [110.5647, -7.8333],
      [110.5635, -7.8341],
      [110.5621, -7.8355],
      [110.5615, -7.8362],
      [110.5614, -7.8367],
      [110.5609, -7.8368],
      [110.5607, -7.837],
      [110.5605, -7.8372],
      [110.5596, -7.8372],
      [110.5595, -7.8373],
      [110.5594, -7.8376],
      [110.5592, -7.838],
      [110.5591, -7.8383],
      [110.559, -7.839],
      [110.5587, -7.8399],
      [110.5587, -7.8404],
      [110.5588, -7.8405],
      [110.559, -7.8408],
      [110.559, -7.842],
      [110.5593, -7.8426],
      [110.5594, -7.8432],
      [110.5595, -7.8438],
      [110.5598, -7.8441],
      [110.5598, -7.8444],
      [110.5596, -7.845],
      [110.5595, -7.8455],
      [110.5595, -7.846],
      [110.5591, -7.8465],
      [110.5588, -7.8469],
      [110.5586, -7.8472],
      [110.5582, -7.8475],
      [110.5579, -7.8477],
      [110.558, -7.8482],
      [110.5579, -7.8484],
      [110.5578, -7.8487],
      [110.5578, -7.8489],
      [110.5577, -7.8495],
      [110.5576, -7.8499],
      [110.5574, -7.8502],
      [110.5572, -7.8504],
      [110.557, -7.8508],
      [110.557, -7.8511],
      [110.5568, -7.8514],
      [110.5568, -7.8516],
      [110.5569, -7.8524],
      [110.5569, -7.8534],
      [110.557, -7.8541],
      [110.5567, -7.8548],
      [110.5562, -7.856],
      [110.5563, -7.8563],
      [110.5565, -7.8567],
      [110.5563, -7.8573],
      [110.5564, -7.8577],
      [110.5564, -7.8585],
      [110.5566, -7.859],
      [110.5568, -7.8591],
      [110.5569, -7.8592],
      [110.5567, -7.8596],
      [110.5564, -7.8598],
      [110.5561, -7.8602],
      [110.556, -7.8606],
      [110.5558, -7.8608],
      [110.5559, -7.8612],
      [110.5558, -7.8615],
      [110.5558, -7.8618],
      [110.5557, -7.8622],
      [110.5561, -7.8626],
      [110.5561, -7.8629],
      [110.5559, -7.8634],
      [110.5557, -7.8636],
      [110.5553, -7.8634],
      [110.5549, -7.8631],
      [110.5545, -7.8629],
      [110.5542, -7.8631],
      [110.5538, -7.8636],
      [110.5535, -7.8637],
      [110.5534, -7.8641],
      [110.5532, -7.8642],
      [110.5532, -7.8649],
      [110.5529, -7.8655],
      [110.5528, -7.8662],
      [110.5525, -7.8664],
      [110.5523, -7.8663],
      [110.5522, -7.8669],
      [110.552, -7.8674],
      [110.552, -7.8687],
      [110.5518, -7.8698],
      [110.5514, -7.8707],
      [110.551, -7.8714],
      [110.5506, -7.8723],
      [110.5507, -7.873],
      [110.5507, -7.8734],
      [110.5508, -7.874],
      [110.5509, -7.8744],
      [110.5513, -7.8748],
      [110.5514, -7.8756],
      [110.5515, -7.8762],
      [110.552, -7.8767],
      [110.5525, -7.8772],
      [110.5527, -7.8773],
      [110.5531, -7.8778],
      [110.5536, -7.8788],
      [110.554, -7.8794],
      [110.5541, -7.8799],
      [110.5542, -7.8804],
      [110.5545, -7.8816],
      [110.5545, -7.882],
      [110.5542, -7.8826],
      [110.5539, -7.8834],
      [110.5538, -7.8843],
      [110.5539, -7.8853],
      [110.5536, -7.8861],
      [110.553, -7.8871],
      [110.5518, -7.8887],
      [110.5505, -7.8903],
      [110.5495, -7.8907],
      [110.5485, -7.891],
      [110.5481, -7.8912],
      [110.5487, -7.8915],
      [110.5496, -7.8922],
      [110.5499, -7.8926],
      [110.5505, -7.8931],
      [110.5507, -7.8933],
      [110.5513, -7.8934],
      [110.5515, -7.8934],
      [110.5519, -7.8932],
      [110.5523, -7.8931],
      [110.5528, -7.8931],
      [110.5529, -7.8931],
      [110.5531, -7.8934],
      [110.5536, -7.8945],
      [110.5537, -7.8946],
      [110.5541, -7.8948],
      [110.5546, -7.8949],
      [110.5555, -7.8947],
      [110.5557, -7.8947],
      [110.5562, -7.8945],
      [110.5569, -7.8944],
      [110.5576, -7.8946],
      [110.5584, -7.895],
      [110.5586, -7.8951],
      [110.559, -7.8952],
      [110.5599, -7.8956],
      [110.5601, -7.8959],
      [110.5602, -7.8961],
      [110.5604, -7.8965],
      [110.5604, -7.8966],
      [110.5608, -7.8976],
      [110.5608, -7.8977],
      [110.5613, -7.898],
      [110.5618, -7.8979],
      [110.5619, -7.8976],
      [110.5618, -7.8972],
      [110.5617, -7.8964],
      [110.5616, -7.8957],
      [110.5616, -7.8947],
      [110.5619, -7.8938],
      [110.5622, -7.8935],
      [110.5622, -7.8932],
      [110.5622, -7.8926],
      [110.5622, -7.8922],
      [110.5625, -7.8915],
      [110.5627, -7.8906],
      [110.5629, -7.89],
      [110.5629, -7.8895],
      [110.5629, -7.8887],
      [110.5629, -7.888],
      [110.5626, -7.887],
      [110.5628, -7.8856],
      [110.5629, -7.8853],
      [110.563, -7.8849],
      [110.5628, -7.8838],
      [110.5626, -7.8829],
      [110.5627, -7.8821],
      [110.5628, -7.8806],
      [110.5629, -7.8805],
      [110.5631, -7.8795],
      [110.5633, -7.8783],
      [110.5638, -7.877],
      [110.5643, -7.8758],
      [110.5646, -7.8753],
      [110.5648, -7.875],
      [110.565, -7.8746],
      [110.5655, -7.874],
      [110.5657, -7.8736],
      [110.5658, -7.8734],
      [110.5659, -7.8729],
      [110.5661, -7.8725],
      [110.5665, -7.8723],
      [110.5667, -7.8716],
      [110.5669, -7.871],
      [110.567, -7.8707],
      [110.567, -7.8704],
      [110.5672, -7.87],
      [110.5674, -7.8695],
      [110.5677, -7.8691],
      [110.5673, -7.8687],
      [110.5672, -7.8684],
      [110.5672, -7.8682],
      [110.5668, -7.8673],
      [110.5665, -7.8666],
      [110.5662, -7.8659],
      [110.5662, -7.8655],
      [110.5664, -7.8653],
      [110.5666, -7.8652],
      [110.5667, -7.8648],
      [110.5666, -7.8646],
      [110.5666, -7.8642],
      [110.5666, -7.8639],
      [110.5668, -7.8635],
      [110.5668, -7.863],
      [110.5669, -7.8626],
      [110.5669, -7.8622],
      [110.5667, -7.862],
      [110.5667, -7.8617],
      [110.5671, -7.8615],
      [110.5674, -7.8614],
      [110.5675, -7.8609],
      [110.5677, -7.8606],
      [110.568, -7.8603],
      [110.5681, -7.8599],
      [110.5682, -7.8592],
      [110.5684, -7.8584],
      [110.5688, -7.8577],
      [110.5692, -7.8569],
      [110.5696, -7.8562],
      [110.5693, -7.8558],
      [110.5697, -7.8554],
      [110.5696, -7.8547],
      [110.5694, -7.8538],
      [110.5691, -7.8527],
      [110.5692, -7.8518],
      [110.569, -7.8509],
      [110.5687, -7.8498],
      [110.5687, -7.8485],
      [110.5689, -7.8469],
      [110.5692, -7.8462],
      [110.5698, -7.8455],
      [110.5706, -7.8447],
      [110.5708, -7.8435],
      [110.571, -7.8426],
      [110.5714, -7.8415],
      [110.5713, -7.8405],
      [110.5713, -7.8397],
      [110.5712, -7.8386],
      [110.5713, -7.8377],
      [110.5714, -7.8369]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PATUK',
    village: 'TERBAH',
    border: [
      [110.571, -7.8254],
      [110.5707, -7.825],
      [110.5705, -7.8248],
      [110.5698, -7.8248],
      [110.5695, -7.8252],
      [110.5692, -7.8255],
      [110.5688, -7.8253],
      [110.5684, -7.8248],
      [110.5681, -7.8242],
      [110.5672, -7.8233],
      [110.5666, -7.8228],
      [110.5661, -7.8224],
      [110.5652, -7.8217],
      [110.5648, -7.8215],
      [110.5648, -7.8214],
      [110.5646, -7.8209],
      [110.5643, -7.8209],
      [110.5639, -7.8208],
      [110.5638, -7.8206],
      [110.5639, -7.8201],
      [110.564, -7.8198],
      [110.564, -7.8192],
      [110.564, -7.8188],
      [110.5636, -7.8182],
      [110.5631, -7.8177],
      [110.5625, -7.8173],
      [110.562, -7.817],
      [110.5616, -7.817],
      [110.5605, -7.8168],
      [110.5598, -7.8167],
      [110.5594, -7.8167],
      [110.5592, -7.817],
      [110.559, -7.8174],
      [110.5585, -7.8183],
      [110.5584, -7.8184],
      [110.558, -7.8192],
      [110.558, -7.8194],
      [110.5574, -7.8193],
      [110.5569, -7.8192],
      [110.5561, -7.8192],
      [110.5552, -7.8191],
      [110.5547, -7.8187],
      [110.5539, -7.8183],
      [110.5533, -7.818],
      [110.5528, -7.8178],
      [110.5517, -7.8181],
      [110.5511, -7.8184],
      [110.5504, -7.8189],
      [110.55, -7.8192],
      [110.5497, -7.8195],
      [110.5497, -7.8198],
      [110.5499, -7.8201],
      [110.55, -7.8202],
      [110.5499, -7.8205],
      [110.5496, -7.8208],
      [110.5496, -7.8215],
      [110.5496, -7.8219],
      [110.5496, -7.822],
      [110.5491, -7.8218],
      [110.5483, -7.8216],
      [110.5477, -7.8214],
      [110.5473, -7.8211],
      [110.547, -7.8209],
      [110.547, -7.8211],
      [110.5469, -7.8213],
      [110.5465, -7.8213],
      [110.546, -7.8211],
      [110.5453, -7.821],
      [110.5442, -7.8208],
      [110.544, -7.8212],
      [110.5435, -7.8216],
      [110.5431, -7.8215],
      [110.5429, -7.8215],
      [110.5426, -7.8217],
      [110.5427, -7.8218],
      [110.5424, -7.8223],
      [110.5425, -7.8228],
      [110.5426, -7.8233],
      [110.5425, -7.8235],
      [110.5424, -7.8237],
      [110.5419, -7.8236],
      [110.5415, -7.8237],
      [110.5414, -7.8238],
      [110.5412, -7.8241],
      [110.5408, -7.8242],
      [110.5405, -7.8242],
      [110.5404, -7.8249],
      [110.5405, -7.8252],
      [110.5406, -7.8254],
      [110.5409, -7.8256],
      [110.541, -7.8256],
      [110.541, -7.8259],
      [110.541, -7.826],
      [110.5413, -7.8262],
      [110.5414, -7.8264],
      [110.5414, -7.8268],
      [110.5415, -7.8275],
      [110.5415, -7.8282],
      [110.5421, -7.8296],
      [110.5422, -7.8301],
      [110.5432, -7.8328],
      [110.5432, -7.8334],
      [110.5432, -7.834],
      [110.5432, -7.8357],
      [110.5432, -7.8373],
      [110.5432, -7.8383],
      [110.543, -7.8405],
      [110.543, -7.8413],
      [110.5446, -7.8408],
      [110.5491, -7.8401],
      [110.5519, -7.8393],
      [110.5545, -7.8385],
      [110.5564, -7.8379],
      [110.5574, -7.8376],
      [110.5579, -7.8373],
      [110.5587, -7.8371],
      [110.5594, -7.8371],
      [110.5596, -7.8372],
      [110.5605, -7.8372],
      [110.5607, -7.837],
      [110.5609, -7.8368],
      [110.5614, -7.8367],
      [110.5615, -7.8362],
      [110.5621, -7.8355],
      [110.5635, -7.8341],
      [110.5647, -7.8333],
      [110.5653, -7.8328],
      [110.5657, -7.8327],
      [110.5662, -7.8324],
      [110.5669, -7.8319],
      [110.5674, -7.8317],
      [110.5678, -7.8312],
      [110.5683, -7.8307],
      [110.5686, -7.8302],
      [110.569, -7.8296],
      [110.5689, -7.8289],
      [110.5691, -7.8284],
      [110.5694, -7.8277],
      [110.5695, -7.8272],
      [110.5696, -7.8267],
      [110.5699, -7.8263],
      [110.5702, -7.826],
      [110.5704, -7.8256],
      [110.5707, -7.8255],
      [110.571, -7.8254]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PALIYAN',
    village: 'SODO',
    border: [
      [110.5645, -8.0133],
      [110.5651, -8.0131],
      [110.5658, -8.0128],
      [110.566, -8.0127],
      [110.5663, -8.0125],
      [110.5665, -8.0122],
      [110.5665, -8.0118],
      [110.5663, -8.0116],
      [110.5661, -8.0114],
      [110.566, -8.0112],
      [110.5661, -8.0109],
      [110.5661, -8.0108],
      [110.5661, -8.0105],
      [110.5659, -8.0102],
      [110.5658, -8.01],
      [110.5656, -8.0098],
      [110.5656, -8.0095],
      [110.5658, -8.0093],
      [110.566, -8.0092],
      [110.5663, -8.009],
      [110.5664, -8.0089],
      [110.5665, -8.0088],
      [110.5665, -8.0087],
      [110.5662, -8.0083],
      [110.5661, -8.008],
      [110.5661, -8.0076],
      [110.5662, -8.0075],
      [110.5666, -8.0073],
      [110.5668, -8.0072],
      [110.567, -8.0071],
      [110.5671, -8.007],
      [110.5673, -8.0069],
      [110.5673, -8.0065],
      [110.567, -8.0061],
      [110.5668, -8.0058],
      [110.5668, -8.0055],
      [110.5668, -8.0052],
      [110.5669, -8.0048],
      [110.5667, -8.0046],
      [110.5662, -8.0043],
      [110.5656, -8.0038],
      [110.5646, -8.0029],
      [110.5636, -8.0021],
      [110.563, -8.0018],
      [110.5617, -8.0015],
      [110.5607, -8.0013],
      [110.5596, -8.0008],
      [110.5591, -8.0006],
      [110.5586, -8.0002],
      [110.5585, -7.9996],
      [110.5583, -7.9991],
      [110.5581, -7.9988],
      [110.5556, -8.0002],
      [110.5528, -8.0008],
      [110.5508, -8.0026],
      [110.5507, -8.003],
      [110.5505, -8.0035],
      [110.5513, -8.0039],
      [110.552, -8.0043],
      [110.5522, -8.0045],
      [110.5524, -8.0043],
      [110.5523, -8.0027],
      [110.5524, -8.0023],
      [110.5527, -8.0022],
      [110.554, -8.0024],
      [110.5551, -8.0028],
      [110.5553, -8.0034],
      [110.556, -8.0035],
      [110.5561, -8.0039],
      [110.5561, -8.0063],
      [110.5555, -8.0077],
      [110.5553, -8.008],
      [110.5557, -8.0083],
      [110.5557, -8.0088],
      [110.5553, -8.0105],
      [110.5552, -8.0111],
      [110.5548, -8.0119],
      [110.5546, -8.0124],
      [110.555, -8.0126],
      [110.5563, -8.0131],
      [110.5566, -8.013],
      [110.5569, -8.0128],
      [110.5572, -8.0126],
      [110.5579, -8.0128],
      [110.5586, -8.0129],
      [110.5601, -8.013],
      [110.5607, -8.013],
      [110.5611, -8.0132],
      [110.5623, -8.013],
      [110.5632, -8.0129],
      [110.564, -8.013],
      [110.5644, -8.0133],
      [110.5645, -8.0133]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PALIYAN',
    village: 'PAMPANG',
    border: [
      [110.5605, -7.9808],
      [110.5603, -7.9806],
      [110.5602, -7.98],
      [110.5593, -7.9799],
      [110.5589, -7.9798],
      [110.5586, -7.9795],
      [110.558, -7.9795],
      [110.5575, -7.9793],
      [110.5571, -7.9792],
      [110.5566, -7.9789],
      [110.5552, -7.9787],
      [110.5545, -7.9786],
      [110.5537, -7.9784],
      [110.5533, -7.9782],
      [110.5527, -7.9783],
      [110.5523, -7.9784],
      [110.5516, -7.9783],
      [110.5512, -7.9786],
      [110.5508, -7.9784],
      [110.5506, -7.9788],
      [110.5502, -7.979],
      [110.5498, -7.9788],
      [110.5496, -7.979],
      [110.549, -7.9795],
      [110.5482, -7.9798],
      [110.5483, -7.9801],
      [110.5485, -7.9803],
      [110.5486, -7.9804],
      [110.5484, -7.9809],
      [110.5481, -7.9814],
      [110.5474, -7.9817],
      [110.5466, -7.9818],
      [110.5459, -7.9815],
      [110.5459, -7.9814],
      [110.5455, -7.9818],
      [110.5453, -7.9824],
      [110.5454, -7.9831],
      [110.5449, -7.9834],
      [110.5447, -7.9835],
      [110.5439, -7.9839],
      [110.5437, -7.9838],
      [110.5432, -7.9837],
      [110.5431, -7.9837],
      [110.5427, -7.9841],
      [110.5425, -7.9844],
      [110.5423, -7.9852],
      [110.5415, -7.9857],
      [110.5411, -7.9858],
      [110.541, -7.9858],
      [110.5403, -7.9856],
      [110.54, -7.9855],
      [110.5391, -7.9854],
      [110.539, -7.9854],
      [110.5388, -7.9853],
      [110.5384, -7.9855],
      [110.5382, -7.9858],
      [110.5381, -7.9864],
      [110.5381, -7.9868],
      [110.538, -7.9883],
      [110.5379, -7.9903],
      [110.5377, -7.9908],
      [110.5374, -7.9914],
      [110.5374, -7.9919],
      [110.5374, -7.9925],
      [110.5372, -7.9935],
      [110.5369, -7.994],
      [110.5368, -7.9941],
      [110.5365, -7.9942],
      [110.5357, -7.994],
      [110.5345, -7.9938],
      [110.5336, -7.9938],
      [110.5336, -7.9939],
      [110.5336, -7.9941],
      [110.5336, -7.9942],
      [110.5338, -7.9944],
      [110.5344, -7.9952],
      [110.5344, -7.9955],
      [110.5343, -7.9958],
      [110.5343, -7.9959],
      [110.5342, -7.9963],
      [110.534, -7.9966],
      [110.534, -7.9969],
      [110.5341, -7.9971],
      [110.5343, -7.9974],
      [110.5345, -7.9976],
      [110.5346, -7.9978],
      [110.5347, -7.998],
      [110.5346, -7.9984],
      [110.5348, -7.999],
      [110.5351, -7.9995],
      [110.5354, -7.9997],
      [110.536, -7.9998],
      [110.537, -7.9993],
      [110.5377, -7.9984],
      [110.5389, -7.9973],
      [110.5394, -7.997],
      [110.5406, -7.9966],
      [110.5414, -7.9966],
      [110.5436, -7.9968],
      [110.5459, -7.9966],
      [110.547, -7.9961],
      [110.5481, -7.9957],
      [110.5485, -7.9958],
      [110.5489, -7.9961],
      [110.5489, -7.9965],
      [110.5488, -7.9968],
      [110.549, -7.9973],
      [110.5489, -7.9976],
      [110.5486, -7.9984],
      [110.5485, -7.9987],
      [110.548, -7.9996],
      [110.5478, -7.9999],
      [110.547, -7.9999],
      [110.5465, -8.0],
      [110.5463, -8.0002],
      [110.5467, -8.0002],
      [110.5473, -8.0001],
      [110.548, -8.0002],
      [110.5494, -8.0002],
      [110.5503, -8.0002],
      [110.5504, -8.0005],
      [110.5504, -8.0007],
      [110.5503, -8.0011],
      [110.5498, -8.0013],
      [110.5497, -8.0015],
      [110.5498, -8.0021],
      [110.5508, -8.0023],
      [110.5508, -8.0026],
      [110.5528, -8.0008],
      [110.5556, -8.0002],
      [110.5581, -7.9988],
      [110.5578, -7.9985],
      [110.5574, -7.9981],
      [110.5568, -7.9972],
      [110.5571, -7.9962],
      [110.5575, -7.9953],
      [110.5577, -7.9953],
      [110.558, -7.9955],
      [110.5583, -7.9957],
      [110.5587, -7.9956],
      [110.5591, -7.9955],
      [110.5594, -7.9954],
      [110.5599, -7.9957],
      [110.5601, -7.9958],
      [110.5604, -7.9953],
      [110.5602, -7.9951],
      [110.5601, -7.995],
      [110.5597, -7.9947],
      [110.5596, -7.9944],
      [110.5596, -7.9941],
      [110.5596, -7.9936],
      [110.5599, -7.9929],
      [110.5602, -7.9922],
      [110.5602, -7.9915],
      [110.5603, -7.9911],
      [110.5606, -7.9908],
      [110.5608, -7.9908],
      [110.5609, -7.9904],
      [110.5605, -7.9902],
      [110.5605, -7.99],
      [110.5608, -7.99],
      [110.561, -7.9897],
      [110.5609, -7.9892],
      [110.5608, -7.9884],
      [110.5607, -7.9883],
      [110.5596, -7.9882],
      [110.5582, -7.9884],
      [110.5561, -7.9885],
      [110.5557, -7.9884],
      [110.5553, -7.9879],
      [110.5552, -7.9876],
      [110.5552, -7.987],
      [110.5554, -7.9865],
      [110.5559, -7.9865],
      [110.5564, -7.9864],
      [110.5569, -7.9861],
      [110.5574, -7.9858],
      [110.5576, -7.9855],
      [110.5578, -7.9851],
      [110.5579, -7.9844],
      [110.5581, -7.9832],
      [110.5581, -7.9825],
      [110.5584, -7.9821],
      [110.5595, -7.9816],
      [110.56, -7.9814],
      [110.5605, -7.9808]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PALIYAN',
    village: 'GROGOL',
    border: [
      [110.5459, -7.9814],
      [110.5456, -7.9811],
      [110.5454, -7.9806],
      [110.5454, -7.98],
      [110.5455, -7.9794],
      [110.5454, -7.9789],
      [110.5452, -7.9785],
      [110.5446, -7.9782],
      [110.544, -7.978],
      [110.5439, -7.9775],
      [110.544, -7.9769],
      [110.5439, -7.9763],
      [110.5438, -7.9759],
      [110.5437, -7.9752],
      [110.5432, -7.975],
      [110.5426, -7.9747],
      [110.5417, -7.9746],
      [110.5409, -7.9742],
      [110.5401, -7.9739],
      [110.5396, -7.9743],
      [110.5389, -7.9749],
      [110.5384, -7.9755],
      [110.5381, -7.9762],
      [110.5376, -7.9765],
      [110.5369, -7.9768],
      [110.5361, -7.9769],
      [110.5355, -7.9765],
      [110.5346, -7.9763],
      [110.5342, -7.9758],
      [110.5338, -7.9747],
      [110.5329, -7.9746],
      [110.5323, -7.9748],
      [110.5316, -7.9752],
      [110.5308, -7.9754],
      [110.5298, -7.9756],
      [110.5291, -7.9758],
      [110.5282, -7.976],
      [110.5276, -7.9762],
      [110.5274, -7.9765],
      [110.5275, -7.9766],
      [110.5277, -7.9768],
      [110.5277, -7.9772],
      [110.5276, -7.9777],
      [110.5277, -7.9782],
      [110.5277, -7.9785],
      [110.5276, -7.9791],
      [110.5276, -7.9794],
      [110.5277, -7.9797],
      [110.5277, -7.9798],
      [110.5278, -7.9799],
      [110.5276, -7.9801],
      [110.5275, -7.9804],
      [110.5274, -7.9808],
      [110.527, -7.981],
      [110.5268, -7.9816],
      [110.5266, -7.9821],
      [110.5264, -7.9821],
      [110.5258, -7.9821],
      [110.5255, -7.9821],
      [110.5248, -7.9825],
      [110.5246, -7.9828],
      [110.5243, -7.9832],
      [110.5237, -7.9835],
      [110.5232, -7.9837],
      [110.5223, -7.9843],
      [110.5221, -7.984],
      [110.5216, -7.9831],
      [110.5204, -7.9815],
      [110.5191, -7.9801],
      [110.5181, -7.9791],
      [110.5171, -7.9783],
      [110.5164, -7.9778],
      [110.5158, -7.9777],
      [110.516, -7.9782],
      [110.5159, -7.9788],
      [110.5155, -7.9802],
      [110.5146, -7.9812],
      [110.5129, -7.9818],
      [110.5125, -7.9818],
      [110.5127, -7.9834],
      [110.5129, -7.9844],
      [110.5131, -7.9846],
      [110.5136, -7.9851],
      [110.5139, -7.9883],
      [110.5142, -7.99],
      [110.5144, -7.9902],
      [110.5149, -7.9906],
      [110.5154, -7.9911],
      [110.5155, -7.9917],
      [110.5171, -7.9926],
      [110.5175, -7.9924],
      [110.5177, -7.9923],
      [110.5179, -7.9921],
      [110.5185, -7.9921],
      [110.5189, -7.9923],
      [110.5195, -7.993],
      [110.5196, -7.993],
      [110.5197, -7.993],
      [110.5203, -7.9927],
      [110.5205, -7.9926],
      [110.5219, -7.992],
      [110.5239, -7.9913],
      [110.5248, -7.9913],
      [110.5271, -7.9911],
      [110.5276, -7.9911],
      [110.5293, -7.9919],
      [110.5296, -7.9921],
      [110.53, -7.9922],
      [110.5306, -7.992],
      [110.5307, -7.9919],
      [110.5309, -7.992],
      [110.5313, -7.9921],
      [110.5315, -7.9919],
      [110.5318, -7.9919],
      [110.5325, -7.9922],
      [110.5327, -7.9925],
      [110.5328, -7.993],
      [110.5329, -7.9932],
      [110.533, -7.9934],
      [110.5331, -7.9937],
      [110.5334, -7.9938],
      [110.5335, -7.9939],
      [110.5336, -7.9938],
      [110.5345, -7.9938],
      [110.5357, -7.994],
      [110.5365, -7.9942],
      [110.5368, -7.9941],
      [110.5369, -7.994],
      [110.5372, -7.9935],
      [110.5374, -7.9925],
      [110.5374, -7.9919],
      [110.5374, -7.9914],
      [110.5377, -7.9908],
      [110.5379, -7.9903],
      [110.538, -7.9883],
      [110.5381, -7.9868],
      [110.5381, -7.9864],
      [110.5382, -7.9858],
      [110.5384, -7.9855],
      [110.5388, -7.9853],
      [110.539, -7.9854],
      [110.5391, -7.9854],
      [110.54, -7.9855],
      [110.5403, -7.9856],
      [110.541, -7.9858],
      [110.5411, -7.9858],
      [110.5415, -7.9857],
      [110.5423, -7.9852],
      [110.5425, -7.9844],
      [110.5427, -7.9841],
      [110.5431, -7.9837],
      [110.5432, -7.9837],
      [110.5437, -7.9838],
      [110.5439, -7.9839],
      [110.5447, -7.9835],
      [110.5449, -7.9834],
      [110.5454, -7.9831],
      [110.5453, -7.9824],
      [110.5455, -7.9818],
      [110.5459, -7.9814]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PALIYAN',
    village: 'KARANGDUWET',
    border: [
      [110.5158, -7.9777],
      [110.5157, -7.9777],
      [110.5145, -7.9776],
      [110.5137, -7.9776],
      [110.5127, -7.9776],
      [110.5113, -7.9778],
      [110.5109, -7.9783],
      [110.5103, -7.9785],
      [110.5095, -7.9787],
      [110.5083, -7.9787],
      [110.5076, -7.9791],
      [110.5075, -7.9791],
      [110.5067, -7.9796],
      [110.5061, -7.9798],
      [110.5059, -7.98],
      [110.5052, -7.9802],
      [110.5043, -7.9802],
      [110.5035, -7.9799],
      [110.5028, -7.9801],
      [110.5024, -7.9803],
      [110.5019, -7.9809],
      [110.5014, -7.9815],
      [110.5006, -7.9818],
      [110.5, -7.9817],
      [110.4989, -7.9815],
      [110.4979, -7.9816],
      [110.4967, -7.9819],
      [110.496, -7.9818],
      [110.4953, -7.9817],
      [110.4928, -7.9813],
      [110.4914, -7.9811],
      [110.4902, -7.9811],
      [110.4892, -7.9809],
      [110.4886, -7.9813],
      [110.4882, -7.9817],
      [110.488, -7.9827],
      [110.4878, -7.9838],
      [110.4876, -7.9849],
      [110.4877, -7.9859],
      [110.4879, -7.9871],
      [110.4879, -7.9876],
      [110.4882, -7.9883],
      [110.4886, -7.9897],
      [110.4886, -7.9907],
      [110.4881, -7.9921],
      [110.4879, -7.9933],
      [110.4875, -7.9958],
      [110.487, -7.9971],
      [110.4865, -7.9981],
      [110.4857, -7.9978],
      [110.4847, -7.9973],
      [110.484, -7.9972],
      [110.483, -7.9972],
      [110.4827, -7.9972],
      [110.4816, -7.9972],
      [110.4796, -7.9973],
      [110.4786, -7.9969],
      [110.4775, -7.9972],
      [110.4764, -7.9976],
      [110.4754, -7.9978],
      [110.4744, -7.9977],
      [110.4742, -7.9982],
      [110.474, -7.9987],
      [110.4739, -7.9989],
      [110.4738, -7.9993],
      [110.4735, -7.9999],
      [110.4735, -8.0001],
      [110.4736, -8.0005],
      [110.4743, -8.0011],
      [110.4751, -8.0017],
      [110.4752, -8.0018],
      [110.4753, -8.0019],
      [110.4761, -8.0025],
      [110.4768, -8.0031],
      [110.4773, -8.0039],
      [110.4781, -8.0048],
      [110.4786, -8.0052],
      [110.4794, -8.0061],
      [110.4812, -8.0075],
      [110.4819, -8.0081],
      [110.4824, -8.0093],
      [110.4827, -8.0098],
      [110.4834, -8.0109],
      [110.4843, -8.0125],
      [110.4852, -8.0135],
      [110.4857, -8.0139],
      [110.4861, -8.0143],
      [110.487, -8.0146],
      [110.4877, -8.0149],
      [110.4892, -8.015],
      [110.4899, -8.0152],
      [110.4905, -8.0159],
      [110.4907, -8.0162],
      [110.4916, -8.0166],
      [110.4926, -8.0171],
      [110.4936, -8.0178],
      [110.4952, -8.0181],
      [110.4963, -8.0183],
      [110.4979, -8.0189],
      [110.4991, -8.0197],
      [110.4996, -8.0201],
      [110.5002, -8.0207],
      [110.5006, -8.0214],
      [110.5013, -8.0227],
      [110.5021, -8.0243],
      [110.5027, -8.0259],
      [110.5031, -8.0279],
      [110.5043, -8.0294],
      [110.5053, -8.0306],
      [110.5056, -8.0316],
      [110.506, -8.0328],
      [110.5063, -8.0332],
      [110.5069, -8.0341],
      [110.5071, -8.0349],
      [110.5074, -8.0349],
      [110.5084, -8.0349],
      [110.5095, -8.0344],
      [110.5107, -8.0337],
      [110.5114, -8.0334],
      [110.5121, -8.0332],
      [110.5132, -8.0331],
      [110.5141, -8.0331],
      [110.5144, -8.0329],
      [110.5141, -8.0312],
      [110.5137, -8.0297],
      [110.5137, -8.0277],
      [110.5143, -8.0276],
      [110.5145, -8.0273],
      [110.5143, -8.0267],
      [110.5135, -8.0251],
      [110.5132, -8.0246],
      [110.5132, -8.0235],
      [110.5134, -8.0231],
      [110.5139, -8.0226],
      [110.5141, -8.022],
      [110.5145, -8.0212],
      [110.5146, -8.0199],
      [110.515, -8.0187],
      [110.5155, -8.018],
      [110.5156, -8.0176],
      [110.5157, -8.0173],
      [110.5159, -8.0169],
      [110.5162, -8.0165],
      [110.5164, -8.0156],
      [110.5165, -8.0152],
      [110.5168, -8.0148],
      [110.5168, -8.0141],
      [110.5168, -8.014],
      [110.5167, -8.0138],
      [110.5165, -8.0129],
      [110.5166, -8.0124],
      [110.5171, -8.0122],
      [110.5173, -8.0121],
      [110.5174, -8.0118],
      [110.5173, -8.0114],
      [110.517, -8.0109],
      [110.517, -8.0105],
      [110.5171, -8.0098],
      [110.5176, -8.0094],
      [110.5178, -8.0089],
      [110.5178, -8.0086],
      [110.5178, -8.0083],
      [110.5177, -8.0077],
      [110.5174, -8.0072],
      [110.5171, -8.0067],
      [110.5171, -8.0063],
      [110.5171, -8.0052],
      [110.5172, -8.0049],
      [110.5175, -8.0039],
      [110.5175, -8.0033],
      [110.5172, -8.0027],
      [110.5169, -8.0014],
      [110.5169, -8.0012],
      [110.5163, -8.0002],
      [110.5158, -8.0001],
      [110.5153, -7.9997],
      [110.515, -7.9995],
      [110.5146, -7.9993],
      [110.5146, -7.999],
      [110.5148, -7.9988],
      [110.5143, -7.998],
      [110.5143, -7.9972],
      [110.5141, -7.996],
      [110.514, -7.9952],
      [110.514, -7.9936],
      [110.5141, -7.9928],
      [110.5141, -7.9926],
      [110.5142, -7.9917],
      [110.5142, -7.9902],
      [110.5142, -7.99],
      [110.5139, -7.9883],
      [110.5136, -7.9851],
      [110.5131, -7.9846],
      [110.5129, -7.9844],
      [110.5127, -7.9834],
      [110.5125, -7.9818],
      [110.5129, -7.9818],
      [110.5146, -7.9812],
      [110.5155, -7.9802],
      [110.5159, -7.9788],
      [110.516, -7.9782],
      [110.5158, -7.9777]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PALIYAN',
    village: 'KARANGASEM',
    border: [
      [110.5335, -7.9939],
      [110.5334, -7.9938],
      [110.5331, -7.9937],
      [110.533, -7.9934],
      [110.5329, -7.9932],
      [110.5328, -7.993],
      [110.5327, -7.9925],
      [110.5325, -7.9922],
      [110.5318, -7.9919],
      [110.5315, -7.9919],
      [110.5313, -7.9921],
      [110.5309, -7.992],
      [110.5307, -7.9919],
      [110.5306, -7.992],
      [110.53, -7.9922],
      [110.5296, -7.9921],
      [110.5293, -7.9919],
      [110.5276, -7.9911],
      [110.5271, -7.9911],
      [110.5248, -7.9913],
      [110.5239, -7.9913],
      [110.5219, -7.992],
      [110.5205, -7.9926],
      [110.5203, -7.9927],
      [110.5197, -7.993],
      [110.5196, -7.993],
      [110.5195, -7.993],
      [110.5189, -7.9923],
      [110.5185, -7.9921],
      [110.5179, -7.9921],
      [110.5177, -7.9923],
      [110.5175, -7.9924],
      [110.5171, -7.9926],
      [110.5155, -7.9917],
      [110.5154, -7.9911],
      [110.5149, -7.9906],
      [110.5144, -7.9902],
      [110.5142, -7.99],
      [110.5142, -7.9902],
      [110.5142, -7.9917],
      [110.5141, -7.9926],
      [110.5141, -7.9928],
      [110.514, -7.9936],
      [110.514, -7.9952],
      [110.5141, -7.996],
      [110.5143, -7.9972],
      [110.5143, -7.998],
      [110.5148, -7.9988],
      [110.5146, -7.999],
      [110.5146, -7.9993],
      [110.515, -7.9995],
      [110.5153, -7.9997],
      [110.5158, -8.0001],
      [110.5163, -8.0002],
      [110.5169, -8.0012],
      [110.5169, -8.0014],
      [110.5172, -8.0027],
      [110.5175, -8.0033],
      [110.5175, -8.0039],
      [110.5172, -8.0049],
      [110.5171, -8.0052],
      [110.5171, -8.0063],
      [110.5171, -8.0067],
      [110.5174, -8.0072],
      [110.5177, -8.0077],
      [110.5178, -8.0083],
      [110.5178, -8.0086],
      [110.5178, -8.0089],
      [110.5176, -8.0094],
      [110.5171, -8.0098],
      [110.517, -8.0105],
      [110.517, -8.0109],
      [110.5173, -8.0114],
      [110.5174, -8.0118],
      [110.5173, -8.0121],
      [110.5171, -8.0122],
      [110.5166, -8.0124],
      [110.5165, -8.0129],
      [110.5167, -8.0138],
      [110.5168, -8.014],
      [110.5168, -8.0141],
      [110.5168, -8.0148],
      [110.5165, -8.0152],
      [110.5164, -8.0156],
      [110.5162, -8.0165],
      [110.5159, -8.0169],
      [110.5157, -8.0173],
      [110.5156, -8.0176],
      [110.5155, -8.018],
      [110.515, -8.0187],
      [110.5146, -8.0199],
      [110.5145, -8.0212],
      [110.5141, -8.022],
      [110.5139, -8.0226],
      [110.5134, -8.0231],
      [110.5132, -8.0235],
      [110.5132, -8.0246],
      [110.5135, -8.0251],
      [110.5143, -8.0267],
      [110.5145, -8.0273],
      [110.5143, -8.0276],
      [110.5137, -8.0277],
      [110.5137, -8.0297],
      [110.5141, -8.0312],
      [110.5144, -8.0329],
      [110.5145, -8.0333],
      [110.5146, -8.0342],
      [110.5147, -8.0349],
      [110.5145, -8.0352],
      [110.5144, -8.0359],
      [110.514, -8.0362],
      [110.5146, -8.0368],
      [110.5151, -8.0371],
      [110.5153, -8.0375],
      [110.5157, -8.038],
      [110.5162, -8.0384],
      [110.5165, -8.0389],
      [110.5167, -8.0396],
      [110.5165, -8.0401],
      [110.5163, -8.0405],
      [110.5163, -8.0411],
      [110.5165, -8.0416],
      [110.5168, -8.0423],
      [110.5168, -8.0433],
      [110.517, -8.0442],
      [110.5172, -8.0457],
      [110.5174, -8.0468],
      [110.5173, -8.0479],
      [110.5173, -8.0485],
      [110.5173, -8.0494],
      [110.5175, -8.0501],
      [110.5178, -8.0503],
      [110.5178, -8.0504],
      [110.5184, -8.0506],
      [110.5188, -8.0511],
      [110.5193, -8.0513],
      [110.5197, -8.052],
      [110.5201, -8.0525],
      [110.5204, -8.0536],
      [110.5209, -8.0543],
      [110.5215, -8.0546],
      [110.5226, -8.0549],
      [110.523, -8.0549],
      [110.5232, -8.0549],
      [110.5238, -8.0546],
      [110.5244, -8.0541],
      [110.5253, -8.0538],
      [110.526, -8.0536],
      [110.527, -8.0537],
      [110.5275, -8.0534],
      [110.5278, -8.0529],
      [110.5279, -8.0522],
      [110.5279, -8.0514],
      [110.5278, -8.0489],
      [110.5278, -8.0481],
      [110.5278, -8.0471],
      [110.5278, -8.0458],
      [110.5279, -8.0446],
      [110.528, -8.0439],
      [110.528, -8.0433],
      [110.5277, -8.0426],
      [110.5276, -8.0422],
      [110.5277, -8.0418],
      [110.5279, -8.0413],
      [110.5281, -8.041],
      [110.5281, -8.0404],
      [110.5281, -8.04],
      [110.5283, -8.0397],
      [110.5281, -8.0392],
      [110.5279, -8.0388],
      [110.5281, -8.0384],
      [110.5282, -8.0379],
      [110.5283, -8.0376],
      [110.5282, -8.037],
      [110.5284, -8.0365],
      [110.5287, -8.0361],
      [110.5293, -8.0354],
      [110.5298, -8.035],
      [110.5304, -8.0349],
      [110.5307, -8.0348],
      [110.5316, -8.0345],
      [110.5319, -8.0342],
      [110.5322, -8.0338],
      [110.5323, -8.0331],
      [110.5325, -8.0328],
      [110.5336, -8.0315],
      [110.5343, -8.0308],
      [110.5338, -8.0296],
      [110.5335, -8.0287],
      [110.5336, -8.028],
      [110.5338, -8.0273],
      [110.5342, -8.0267],
      [110.5349, -8.0264],
      [110.5355, -8.0256],
      [110.5358, -8.025],
      [110.536, -8.0246],
      [110.5361, -8.024],
      [110.5362, -8.0225],
      [110.5363, -8.0216],
      [110.5365, -8.0207],
      [110.5367, -8.0197],
      [110.5358, -8.0196],
      [110.5355, -8.0191],
      [110.5349, -8.0186],
      [110.535, -8.0182],
      [110.5359, -8.0166],
      [110.5361, -8.016],
      [110.5362, -8.016],
      [110.5368, -8.0146],
      [110.5371, -8.0139],
      [110.5372, -8.0133],
      [110.5373, -8.0124],
      [110.5374, -8.0121],
      [110.5376, -8.0118],
      [110.5378, -8.0106],
      [110.5386, -8.0105],
      [110.5394, -8.0103],
      [110.5394, -8.0091],
      [110.5394, -8.0084],
      [110.5393, -8.0081],
      [110.5391, -8.0079],
      [110.5391, -8.0074],
      [110.539, -8.0068],
      [110.5387, -8.0066],
      [110.5387, -8.0064],
      [110.5389, -8.0061],
      [110.5391, -8.0058],
      [110.5391, -8.0053],
      [110.5388, -8.0046],
      [110.5387, -8.0046],
      [110.5386, -8.0045],
      [110.5384, -8.0044],
      [110.5383, -8.0038],
      [110.5382, -8.0034],
      [110.5382, -8.0033],
      [110.5382, -8.0032],
      [110.5381, -8.0029],
      [110.5387, -8.0026],
      [110.54, -8.0027],
      [110.5411, -8.0028],
      [110.5423, -8.0026],
      [110.5439, -8.0019],
      [110.5441, -8.0018],
      [110.5453, -8.001],
      [110.5456, -8.0007],
      [110.546, -8.0004],
      [110.5463, -8.0002],
      [110.5465, -8.0],
      [110.547, -7.9999],
      [110.5478, -7.9999],
      [110.548, -7.9996],
      [110.5485, -7.9987],
      [110.5486, -7.9984],
      [110.5489, -7.9976],
      [110.549, -7.9973],
      [110.5488, -7.9968],
      [110.5489, -7.9965],
      [110.5489, -7.9961],
      [110.5485, -7.9958],
      [110.5481, -7.9957],
      [110.547, -7.9961],
      [110.5459, -7.9966],
      [110.5436, -7.9968],
      [110.5414, -7.9966],
      [110.5406, -7.9966],
      [110.5394, -7.997],
      [110.5389, -7.9973],
      [110.5377, -7.9984],
      [110.537, -7.9993],
      [110.536, -7.9998],
      [110.5354, -7.9997],
      [110.5351, -7.9995],
      [110.5348, -7.999],
      [110.5346, -7.9984],
      [110.5347, -7.998],
      [110.5346, -7.9978],
      [110.5345, -7.9976],
      [110.5343, -7.9974],
      [110.5341, -7.9971],
      [110.534, -7.9969],
      [110.534, -7.9966],
      [110.5342, -7.9963],
      [110.5343, -7.9959],
      [110.5343, -7.9958],
      [110.5344, -7.9955],
      [110.5344, -7.9952],
      [110.5338, -7.9944],
      [110.5336, -7.9942],
      [110.5336, -7.9941],
      [110.5335, -7.9939]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PALIYAN',
    village: 'MULUSAN',
    border: [
      [110.5508, -8.0026],
      [110.5508, -8.0023],
      [110.5498, -8.0021],
      [110.5497, -8.0015],
      [110.5498, -8.0013],
      [110.5503, -8.0011],
      [110.5504, -8.0007],
      [110.5504, -8.0005],
      [110.5503, -8.0002],
      [110.5494, -8.0002],
      [110.548, -8.0002],
      [110.5473, -8.0001],
      [110.5467, -8.0002],
      [110.5463, -8.0002],
      [110.546, -8.0004],
      [110.5456, -8.0007],
      [110.5453, -8.001],
      [110.5441, -8.0018],
      [110.5439, -8.0019],
      [110.5423, -8.0026],
      [110.5411, -8.0028],
      [110.54, -8.0027],
      [110.5387, -8.0026],
      [110.5381, -8.0029],
      [110.5382, -8.0032],
      [110.5382, -8.0033],
      [110.5382, -8.0034],
      [110.5383, -8.0038],
      [110.5384, -8.0044],
      [110.5386, -8.0045],
      [110.5387, -8.0046],
      [110.5388, -8.0046],
      [110.5391, -8.0053],
      [110.5391, -8.0058],
      [110.5389, -8.0061],
      [110.5387, -8.0064],
      [110.5387, -8.0066],
      [110.539, -8.0068],
      [110.5391, -8.0074],
      [110.5391, -8.0079],
      [110.5393, -8.0081],
      [110.5394, -8.0084],
      [110.5394, -8.0091],
      [110.5394, -8.0103],
      [110.5386, -8.0105],
      [110.5378, -8.0106],
      [110.5376, -8.0118],
      [110.5374, -8.0121],
      [110.5373, -8.0124],
      [110.5372, -8.0133],
      [110.5371, -8.0139],
      [110.5368, -8.0146],
      [110.5362, -8.016],
      [110.5361, -8.016],
      [110.5359, -8.0166],
      [110.535, -8.0182],
      [110.5349, -8.0186],
      [110.5355, -8.0191],
      [110.5358, -8.0196],
      [110.5367, -8.0197],
      [110.5365, -8.0207],
      [110.5363, -8.0216],
      [110.5362, -8.0225],
      [110.5361, -8.024],
      [110.536, -8.0246],
      [110.5358, -8.025],
      [110.5355, -8.0256],
      [110.5349, -8.0264],
      [110.5342, -8.0267],
      [110.5338, -8.0273],
      [110.5336, -8.028],
      [110.5335, -8.0287],
      [110.5338, -8.0296],
      [110.5343, -8.0308],
      [110.535, -8.0315],
      [110.5365, -8.0323],
      [110.5377, -8.0328],
      [110.5386, -8.0336],
      [110.5393, -8.0342],
      [110.5398, -8.0342],
      [110.5406, -8.0341],
      [110.5412, -8.0342],
      [110.5415, -8.0339],
      [110.5417, -8.0336],
      [110.5425, -8.0336],
      [110.5434, -8.0334],
      [110.5441, -8.0332],
      [110.5451, -8.0324],
      [110.5459, -8.0316],
      [110.5472, -8.0296],
      [110.5481, -8.0284],
      [110.5487, -8.0284],
      [110.5488, -8.0281],
      [110.5488, -8.028],
      [110.549, -8.0279],
      [110.5492, -8.0279],
      [110.5494, -8.0273],
      [110.5497, -8.0269],
      [110.5501, -8.0265],
      [110.5503, -8.0264],
      [110.5504, -8.0264],
      [110.5503, -8.0257],
      [110.5501, -8.0252],
      [110.5496, -8.025],
      [110.5493, -8.0247],
      [110.5492, -8.0243],
      [110.5494, -8.0237],
      [110.5496, -8.0233],
      [110.5498, -8.023],
      [110.5502, -8.0229],
      [110.5506, -8.0228],
      [110.5507, -8.0227],
      [110.5506, -8.0224],
      [110.5502, -8.0223],
      [110.5498, -8.0222],
      [110.5494, -8.022],
      [110.5493, -8.0219],
      [110.5494, -8.0217],
      [110.5494, -8.0216],
      [110.5497, -8.0211],
      [110.5498, -8.0208],
      [110.5499, -8.0207],
      [110.55, -8.0202],
      [110.5497, -8.0202],
      [110.5494, -8.0202],
      [110.5492, -8.0198],
      [110.5491, -8.0194],
      [110.549, -8.0191],
      [110.5486, -8.0188],
      [110.5487, -8.0185],
      [110.5488, -8.0184],
      [110.549, -8.018],
      [110.5491, -8.0176],
      [110.5491, -8.0174],
      [110.5492, -8.0172],
      [110.5496, -8.017],
      [110.55, -8.017],
      [110.5508, -8.0174],
      [110.5514, -8.0176],
      [110.5524, -8.0178],
      [110.5531, -8.0178],
      [110.5535, -8.0176],
      [110.5539, -8.0171],
      [110.5538, -8.0159],
      [110.5539, -8.0145],
      [110.5543, -8.0135],
      [110.5546, -8.0124],
      [110.5548, -8.0119],
      [110.5552, -8.0111],
      [110.5553, -8.0105],
      [110.5557, -8.0088],
      [110.5557, -8.0083],
      [110.5553, -8.008],
      [110.5555, -8.0077],
      [110.5561, -8.0063],
      [110.5561, -8.0039],
      [110.556, -8.0035],
      [110.5553, -8.0034],
      [110.5551, -8.0028],
      [110.554, -8.0024],
      [110.5527, -8.0022],
      [110.5524, -8.0023],
      [110.5523, -8.0027],
      [110.5524, -8.0043],
      [110.5522, -8.0045],
      [110.552, -8.0043],
      [110.5513, -8.0039],
      [110.5505, -8.0035],
      [110.5507, -8.003],
      [110.5508, -8.0026]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PALIYAN',
    village: 'GIRING',
    border: [
      [110.5645, -8.0133],
      [110.5644, -8.0133],
      [110.564, -8.013],
      [110.5632, -8.0129],
      [110.5623, -8.013],
      [110.5611, -8.0132],
      [110.5607, -8.013],
      [110.5601, -8.013],
      [110.5586, -8.0129],
      [110.5579, -8.0128],
      [110.5572, -8.0126],
      [110.5569, -8.0128],
      [110.5566, -8.013],
      [110.5563, -8.0131],
      [110.555, -8.0126],
      [110.5546, -8.0124],
      [110.5543, -8.0135],
      [110.5539, -8.0145],
      [110.5538, -8.0159],
      [110.5539, -8.0171],
      [110.5535, -8.0176],
      [110.5531, -8.0178],
      [110.5524, -8.0178],
      [110.5514, -8.0176],
      [110.5508, -8.0174],
      [110.55, -8.017],
      [110.5496, -8.017],
      [110.5492, -8.0172],
      [110.5491, -8.0174],
      [110.5491, -8.0176],
      [110.549, -8.018],
      [110.5488, -8.0184],
      [110.5487, -8.0185],
      [110.5486, -8.0188],
      [110.549, -8.0191],
      [110.5491, -8.0194],
      [110.5492, -8.0198],
      [110.5494, -8.0202],
      [110.5497, -8.0202],
      [110.55, -8.0202],
      [110.5499, -8.0207],
      [110.5498, -8.0208],
      [110.5497, -8.0211],
      [110.5494, -8.0216],
      [110.5494, -8.0217],
      [110.5493, -8.0219],
      [110.5494, -8.022],
      [110.5498, -8.0222],
      [110.5502, -8.0223],
      [110.5506, -8.0224],
      [110.5507, -8.0227],
      [110.5506, -8.0228],
      [110.5502, -8.0229],
      [110.5498, -8.023],
      [110.5496, -8.0233],
      [110.5494, -8.0237],
      [110.5492, -8.0243],
      [110.5493, -8.0247],
      [110.5496, -8.025],
      [110.5501, -8.0252],
      [110.5503, -8.0257],
      [110.5504, -8.0264],
      [110.5503, -8.0264],
      [110.5501, -8.0265],
      [110.5497, -8.0269],
      [110.5494, -8.0273],
      [110.5492, -8.0279],
      [110.549, -8.0279],
      [110.5488, -8.028],
      [110.5488, -8.0281],
      [110.5487, -8.0284],
      [110.5486, -8.029],
      [110.5487, -8.0298],
      [110.549, -8.0304],
      [110.5503, -8.0325],
      [110.5513, -8.0344],
      [110.5516, -8.0352],
      [110.5521, -8.0361],
      [110.5524, -8.0369],
      [110.5524, -8.0376],
      [110.5522, -8.0381],
      [110.5519, -8.0382],
      [110.5514, -8.0384],
      [110.5514, -8.0392],
      [110.5512, -8.0397],
      [110.5508, -8.0404],
      [110.5503, -8.0409],
      [110.5502, -8.0412],
      [110.5504, -8.0414],
      [110.5511, -8.0415],
      [110.5519, -8.0417],
      [110.5519, -8.042],
      [110.5517, -8.0426],
      [110.5516, -8.0434],
      [110.5515, -8.044],
      [110.5516, -8.0449],
      [110.5519, -8.0459],
      [110.5519, -8.0466],
      [110.5516, -8.0471],
      [110.5512, -8.0478],
      [110.5513, -8.049],
      [110.5512, -8.0501],
      [110.5511, -8.0508],
      [110.5508, -8.0517],
      [110.5503, -8.0528],
      [110.5502, -8.0535],
      [110.5504, -8.0543],
      [110.5502, -8.0548],
      [110.5498, -8.0558],
      [110.5498, -8.0562],
      [110.5499, -8.0568],
      [110.5496, -8.0577],
      [110.5493, -8.059],
      [110.5488, -8.06],
      [110.548, -8.0608],
      [110.5474, -8.0616],
      [110.547, -8.0622],
      [110.547, -8.0623],
      [110.5472, -8.0633],
      [110.5474, -8.0644],
      [110.5477, -8.0664],
      [110.5478, -8.0677],
      [110.5476, -8.0692],
      [110.5474, -8.0703],
      [110.5471, -8.0709],
      [110.5471, -8.0712],
      [110.5471, -8.0716],
      [110.5471, -8.0721],
      [110.5474, -8.0729],
      [110.5478, -8.0738],
      [110.5482, -8.0751],
      [110.5482, -8.0756],
      [110.548, -8.0768],
      [110.5476, -8.0781],
      [110.5476, -8.079],
      [110.5478, -8.0798],
      [110.5482, -8.0809],
      [110.5493, -8.0819],
      [110.5502, -8.0826],
      [110.5508, -8.0832],
      [110.5513, -8.0839],
      [110.5519, -8.0846],
      [110.5525, -8.0855],
      [110.5531, -8.0859],
      [110.5543, -8.0862],
      [110.5544, -8.0863],
      [110.5552, -8.0863],
      [110.5554, -8.0863],
      [110.5567, -8.0849],
      [110.557, -8.0847],
      [110.5579, -8.0839],
      [110.558, -8.0837],
      [110.5581, -8.0834],
      [110.5582, -8.0832],
      [110.5586, -8.0828],
      [110.5587, -8.0827],
      [110.5593, -8.0823],
      [110.5593, -8.082],
      [110.5588, -8.0805],
      [110.5587, -8.0797],
      [110.5587, -8.0787],
      [110.559, -8.078],
      [110.5591, -8.0777],
      [110.5593, -8.0772],
      [110.5593, -8.0765],
      [110.5594, -8.076],
      [110.5596, -8.0757],
      [110.5597, -8.0755],
      [110.56, -8.0752],
      [110.5604, -8.0747],
      [110.5609, -8.074],
      [110.5611, -8.0735],
      [110.5612, -8.0727],
      [110.5613, -8.0715],
      [110.5612, -8.0712],
      [110.5611, -8.0705],
      [110.561, -8.0692],
      [110.5609, -8.0681],
      [110.5607, -8.0672],
      [110.5604, -8.0663],
      [110.5599, -8.0652],
      [110.5593, -8.0641],
      [110.5592, -8.0636],
      [110.5589, -8.0628],
      [110.5587, -8.0623],
      [110.5588, -8.0617],
      [110.5589, -8.0612],
      [110.559, -8.0605],
      [110.5592, -8.0601],
      [110.5595, -8.0592],
      [110.5595, -8.0585],
      [110.5595, -8.0577],
      [110.5593, -8.0569],
      [110.5598, -8.056],
      [110.5604, -8.0552],
      [110.5615, -8.0538],
      [110.5624, -8.0525],
      [110.5627, -8.0519],
      [110.563, -8.0512],
      [110.5631, -8.0502],
      [110.5632, -8.0499],
      [110.5626, -8.0481],
      [110.5624, -8.0472],
      [110.5617, -8.046],
      [110.5614, -8.0453],
      [110.5615, -8.0438],
      [110.5617, -8.0416],
      [110.562, -8.0406],
      [110.562, -8.0404],
      [110.5623, -8.0402],
      [110.5626, -8.0395],
      [110.5627, -8.039],
      [110.5632, -8.0385],
      [110.5637, -8.038],
      [110.5639, -8.0378],
      [110.564, -8.0374],
      [110.5639, -8.0368],
      [110.5639, -8.036],
      [110.5638, -8.035],
      [110.5639, -8.0348],
      [110.5643, -8.0346],
      [110.5644, -8.034],
      [110.5647, -8.0333],
      [110.5655, -8.0335],
      [110.566, -8.0335],
      [110.5666, -8.0331],
      [110.5671, -8.033],
      [110.5675, -8.0329],
      [110.568, -8.0326],
      [110.5683, -8.032],
      [110.5683, -8.0317],
      [110.5682, -8.0314],
      [110.5682, -8.0312],
      [110.5682, -8.031],
      [110.5684, -8.0304],
      [110.5684, -8.0303],
      [110.5689, -8.0296],
      [110.5694, -8.0288],
      [110.5698, -8.028],
      [110.5699, -8.0264],
      [110.5699, -8.0249],
      [110.5699, -8.0242],
      [110.5699, -8.0237],
      [110.57, -8.0234],
      [110.5701, -8.0233],
      [110.5711, -8.0239],
      [110.5715, -8.0241],
      [110.5719, -8.0243],
      [110.5724, -8.0244],
      [110.5726, -8.0243],
      [110.5728, -8.0243],
      [110.5728, -8.0239],
      [110.5727, -8.0231],
      [110.5726, -8.0224],
      [110.5725, -8.0222],
      [110.5725, -8.022],
      [110.5721, -8.022],
      [110.5717, -8.0221],
      [110.5713, -8.0222],
      [110.5709, -8.0222],
      [110.5707, -8.0221],
      [110.5706, -8.0219],
      [110.5705, -8.0218],
      [110.5705, -8.0217],
      [110.5709, -8.0214],
      [110.5713, -8.0211],
      [110.5715, -8.0208],
      [110.5717, -8.0206],
      [110.5717, -8.0203],
      [110.5717, -8.0203],
      [110.5715, -8.0202],
      [110.5709, -8.02],
      [110.5704, -8.0197],
      [110.5701, -8.0195],
      [110.5699, -8.0191],
      [110.5698, -8.0188],
      [110.5698, -8.0187],
      [110.5699, -8.0184],
      [110.57, -8.0182],
      [110.5699, -8.018],
      [110.5694, -8.0176],
      [110.5691, -8.0176],
      [110.5688, -8.0176],
      [110.5687, -8.0179],
      [110.5686, -8.0181],
      [110.5683, -8.0182],
      [110.5678, -8.0181],
      [110.5668, -8.0176],
      [110.5662, -8.0173],
      [110.5659, -8.0169],
      [110.5659, -8.0168],
      [110.5656, -8.0165],
      [110.5655, -8.0156],
      [110.5655, -8.0155],
      [110.5652, -8.0148],
      [110.5649, -8.0144],
      [110.5645, -8.0142],
      [110.5643, -8.014],
      [110.5643, -8.0136],
      [110.5645, -8.0133]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PANGGANG',
    village: 'GIRIKARTO',
    border: [
      [110.4464, -8.0615],
      [110.4457, -8.0614],
      [110.4442, -8.0603],
      [110.443, -8.0592],
      [110.4419, -8.0585],
      [110.4408, -8.0601],
      [110.4404, -8.0609],
      [110.44, -8.0618],
      [110.4389, -8.0631],
      [110.4377, -8.0638],
      [110.437, -8.0642],
      [110.4366, -8.0644],
      [110.4353, -8.0646],
      [110.4347, -8.065],
      [110.4344, -8.0655],
      [110.4338, -8.0664],
      [110.4333, -8.0675],
      [110.4333, -8.0686],
      [110.4331, -8.0706],
      [110.4327, -8.0712],
      [110.4327, -8.0714],
      [110.4317, -8.0731],
      [110.4315, -8.074],
      [110.4324, -8.0766],
      [110.4325, -8.0776],
      [110.4328, -8.0792],
      [110.4331, -8.0812],
      [110.4332, -8.0825],
      [110.4335, -8.0854],
      [110.4339, -8.0864],
      [110.4349, -8.0883],
      [110.435, -8.0888],
      [110.4346, -8.0895],
      [110.4346, -8.0899],
      [110.4348, -8.0905],
      [110.4351, -8.091],
      [110.4355, -8.0913],
      [110.4355, -8.0917],
      [110.4351, -8.0927],
      [110.4346, -8.0935],
      [110.4347, -8.0939],
      [110.4347, -8.0942],
      [110.4346, -8.0947],
      [110.4345, -8.095],
      [110.4343, -8.0953],
      [110.4344, -8.0958],
      [110.4345, -8.0959],
      [110.4347, -8.0962],
      [110.4349, -8.0968],
      [110.435, -8.097],
      [110.4349, -8.0973],
      [110.4348, -8.0974],
      [110.4347, -8.0975],
      [110.4347, -8.0977],
      [110.4348, -8.0978],
      [110.4348, -8.0979],
      [110.4347, -8.098],
      [110.4344, -8.098],
      [110.4341, -8.098],
      [110.4339, -8.0982],
      [110.4339, -8.0984],
      [110.4339, -8.0987],
      [110.4341, -8.0988],
      [110.4342, -8.0989],
      [110.4344, -8.099],
      [110.4346, -8.0989],
      [110.4349, -8.0987],
      [110.4351, -8.0986],
      [110.4353, -8.0985],
      [110.4354, -8.0983],
      [110.4355, -8.0983],
      [110.4358, -8.0983],
      [110.4358, -8.0984],
      [110.4358, -8.0987],
      [110.4356, -8.0989],
      [110.4354, -8.0989],
      [110.4353, -8.0991],
      [110.4353, -8.0993],
      [110.4355, -8.0994],
      [110.4356, -8.0993],
      [110.4357, -8.0992],
      [110.4359, -8.0992],
      [110.4361, -8.0993],
      [110.4361, -8.0995],
      [110.436, -8.0998],
      [110.4362, -8.1],
      [110.4363, -8.1001],
      [110.4364, -8.0999],
      [110.4366, -8.0997],
      [110.4367, -8.0996],
      [110.4369, -8.0996],
      [110.4371, -8.0997],
      [110.4374, -8.0996],
      [110.4378, -8.0995],
      [110.4379, -8.0995],
      [110.438, -8.0994],
      [110.4382, -8.0996],
      [110.4383, -8.0999],
      [110.4383, -8.1003],
      [110.4383, -8.1006],
      [110.4386, -8.1006],
      [110.4388, -8.1005],
      [110.4389, -8.1002],
      [110.439, -8.0999],
      [110.439, -8.0998],
      [110.4392, -8.0997],
      [110.4394, -8.0998],
      [110.4394, -8.0999],
      [110.4395, -8.1],
      [110.4396, -8.1001],
      [110.4398, -8.1001],
      [110.4401, -8.0999],
      [110.4403, -8.0998],
      [110.4403, -8.0994],
      [110.4404, -8.0992],
      [110.4405, -8.099],
      [110.4406, -8.099],
      [110.4407, -8.0992],
      [110.4408, -8.0996],
      [110.4411, -8.0999],
      [110.4412, -8.1001],
      [110.4412, -8.1003],
      [110.4412, -8.1005],
      [110.4413, -8.1007],
      [110.4416, -8.1009],
      [110.4418, -8.1009],
      [110.442, -8.1009],
      [110.4425, -8.1009],
      [110.4428, -8.1009],
      [110.4431, -8.101],
      [110.4435, -8.1011],
      [110.4436, -8.1009],
      [110.4439, -8.1005],
      [110.4442, -8.1001],
      [110.4444, -8.1],
      [110.4446, -8.1],
      [110.4448, -8.1001],
      [110.4451, -8.1],
      [110.4453, -8.1],
      [110.4455, -8.1],
      [110.4457, -8.1001],
      [110.446, -8.1003],
      [110.4461, -8.1003],
      [110.4465, -8.1006],
      [110.4467, -8.1008],
      [110.4469, -8.1009],
      [110.447, -8.1012],
      [110.4471, -8.1013],
      [110.4473, -8.1013],
      [110.4476, -8.1012],
      [110.4479, -8.1009],
      [110.4482, -8.1007],
      [110.4483, -8.1007],
      [110.4486, -8.1008],
      [110.4488, -8.1009],
      [110.449, -8.1008],
      [110.4492, -8.1007],
      [110.4493, -8.1005],
      [110.4495, -8.1005],
      [110.4498, -8.1005],
      [110.4502, -8.1005],
      [110.4504, -8.1006],
      [110.4507, -8.1008],
      [110.4509, -8.101],
      [110.4512, -8.101],
      [110.4514, -8.1011],
      [110.4516, -8.1013],
      [110.4519, -8.1016],
      [110.4521, -8.1016],
      [110.4522, -8.1015],
      [110.4525, -8.1015],
      [110.4527, -8.1017],
      [110.4529, -8.102],
      [110.4532, -8.102],
      [110.4539, -8.102],
      [110.4541, -8.102],
      [110.4542, -8.1019],
      [110.4543, -8.1019],
      [110.4545, -8.1021],
      [110.4545, -8.1022],
      [110.4549, -8.1022],
      [110.4551, -8.1024],
      [110.4553, -8.1025],
      [110.4555, -8.1027],
      [110.4556, -8.1028],
      [110.4558, -8.103],
      [110.456, -8.1031],
      [110.4561, -8.1036],
      [110.4562, -8.1037],
      [110.4564, -8.1039],
      [110.4566, -8.1044],
      [110.4568, -8.1044],
      [110.4573, -8.1043],
      [110.4576, -8.1044],
      [110.4583, -8.1048],
      [110.4586, -8.105],
      [110.459, -8.1052],
      [110.4592, -8.1054],
      [110.4594, -8.1056],
      [110.4595, -8.1057],
      [110.46, -8.1059],
      [110.4604, -8.106],
      [110.4607, -8.1062],
      [110.4609, -8.1063],
      [110.4614, -8.1064],
      [110.4617, -8.1063],
      [110.4618, -8.1063],
      [110.4621, -8.1064],
      [110.4623, -8.1067],
      [110.4625, -8.107],
      [110.4625, -8.1072],
      [110.4628, -8.1074],
      [110.4629, -8.1075],
      [110.463, -8.1077],
      [110.463, -8.108],
      [110.4632, -8.1081],
      [110.4634, -8.1083],
      [110.4637, -8.1087],
      [110.4639, -8.1088],
      [110.4644, -8.1088],
      [110.4647, -8.1088],
      [110.4648, -8.1088],
      [110.4651, -8.1088],
      [110.4654, -8.1089],
      [110.4657, -8.109],
      [110.4659, -8.1092],
      [110.4662, -8.1097],
      [110.4665, -8.1099],
      [110.4666, -8.11],
      [110.4668, -8.1102],
      [110.4671, -8.1104],
      [110.4672, -8.1104],
      [110.4673, -8.1103],
      [110.4674, -8.11],
      [110.4676, -8.1098],
      [110.4677, -8.1098],
      [110.4678, -8.1097],
      [110.468, -8.1096],
      [110.4682, -8.1096],
      [110.4683, -8.1097],
      [110.4682, -8.1099],
      [110.4682, -8.11],
      [110.4683, -8.1103],
      [110.4684, -8.1104],
      [110.4687, -8.1106],
      [110.4688, -8.1107],
      [110.4689, -8.1107],
      [110.4691, -8.1106],
      [110.4691, -8.1103],
      [110.4691, -8.11],
      [110.4692, -8.1099],
      [110.4694, -8.1098],
      [110.4694, -8.1096],
      [110.4693, -8.1094],
      [110.469, -8.1092],
      [110.4688, -8.1091],
      [110.4688, -8.1088],
      [110.4686, -8.1086],
      [110.4685, -8.1085],
      [110.4685, -8.1084],
      [110.4686, -8.1081],
      [110.4687, -8.1081],
      [110.4684, -8.1075],
      [110.468, -8.1068],
      [110.4675, -8.1064],
      [110.4668, -8.1059],
      [110.4662, -8.1055],
      [110.4661, -8.1051],
      [110.4662, -8.1043],
      [110.4664, -8.1036],
      [110.4668, -8.1025],
      [110.4668, -8.1017],
      [110.4669, -8.1009],
      [110.467, -8.1001],
      [110.4668, -8.0994],
      [110.4664, -8.0987],
      [110.4656, -8.0979],
      [110.4647, -8.0971],
      [110.4639, -8.0961],
      [110.4632, -8.0954],
      [110.4628, -8.0946],
      [110.4628, -8.0939],
      [110.4627, -8.0931],
      [110.4629, -8.0924],
      [110.463, -8.0919],
      [110.4629, -8.0913],
      [110.4625, -8.0905],
      [110.4621, -8.0898],
      [110.462, -8.0897],
      [110.4619, -8.0896],
      [110.4617, -8.089],
      [110.4614, -8.0881],
      [110.4614, -8.0874],
      [110.4613, -8.0868],
      [110.4611, -8.0862],
      [110.4608, -8.0857],
      [110.4607, -8.085],
      [110.4609, -8.0845],
      [110.4611, -8.0842],
      [110.4612, -8.0833],
      [110.4612, -8.0823],
      [110.4612, -8.081],
      [110.4613, -8.0801],
      [110.4612, -8.0793],
      [110.4611, -8.0785],
      [110.4611, -8.0775],
      [110.4609, -8.0763],
      [110.4609, -8.0756],
      [110.4609, -8.0744],
      [110.4607, -8.0727],
      [110.4605, -8.0717],
      [110.4602, -8.0712],
      [110.4599, -8.0707],
      [110.4595, -8.07],
      [110.4593, -8.0699],
      [110.4587, -8.0696],
      [110.4582, -8.069],
      [110.4573, -8.0674],
      [110.4567, -8.0662],
      [110.4562, -8.0656],
      [110.4556, -8.0656],
      [110.4551, -8.0657],
      [110.4539, -8.066],
      [110.4532, -8.0665],
      [110.4527, -8.0669],
      [110.4521, -8.0672],
      [110.4506, -8.0671],
      [110.449, -8.0671],
      [110.4478, -8.0673],
      [110.4471, -8.0672],
      [110.4466, -8.0668],
      [110.4465, -8.0661],
      [110.4465, -8.0647],
      [110.4464, -8.0615]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PANGGANG',
    village: 'GIRISEKAR',
    border: [
      [110.4907, -8.0162],
      [110.4905, -8.0159],
      [110.4899, -8.0152],
      [110.4892, -8.015],
      [110.4877, -8.0149],
      [110.487, -8.0146],
      [110.4861, -8.0143],
      [110.4857, -8.0139],
      [110.4852, -8.0135],
      [110.4843, -8.0125],
      [110.4834, -8.0109],
      [110.4827, -8.0098],
      [110.4824, -8.0093],
      [110.4819, -8.0081],
      [110.4812, -8.0075],
      [110.4794, -8.0061],
      [110.4786, -8.0052],
      [110.4781, -8.0048],
      [110.4771, -8.0055],
      [110.476, -8.0064],
      [110.4751, -8.0071],
      [110.4745, -8.0083],
      [110.4742, -8.0095],
      [110.474, -8.0114],
      [110.4735, -8.0122],
      [110.4729, -8.0125],
      [110.4719, -8.0128],
      [110.4712, -8.0129],
      [110.4707, -8.0133],
      [110.4705, -8.0138],
      [110.4703, -8.0147],
      [110.4702, -8.0159],
      [110.4696, -8.0174],
      [110.4687, -8.0185],
      [110.4678, -8.0192],
      [110.4667, -8.02],
      [110.4661, -8.0205],
      [110.4658, -8.0208],
      [110.4636, -8.0224],
      [110.462, -8.0231],
      [110.461, -8.0235],
      [110.4608, -8.0236],
      [110.4605, -8.0237],
      [110.4602, -8.0238],
      [110.46, -8.0238],
      [110.4592, -8.0238],
      [110.4584, -8.0238],
      [110.4576, -8.024],
      [110.4561, -8.0241],
      [110.4554, -8.0243],
      [110.4537, -8.0244],
      [110.4528, -8.0246],
      [110.452, -8.0247],
      [110.4509, -8.025],
      [110.4496, -8.0253],
      [110.4485, -8.0256],
      [110.4482, -8.0265],
      [110.4478, -8.0281],
      [110.4477, -8.0292],
      [110.4471, -8.0309],
      [110.4471, -8.0316],
      [110.4472, -8.0323],
      [110.4477, -8.033],
      [110.4492, -8.0343],
      [110.4506, -8.0349],
      [110.4511, -8.0349],
      [110.4513, -8.0355],
      [110.4516, -8.0356],
      [110.4518, -8.0362],
      [110.4527, -8.0364],
      [110.4531, -8.037],
      [110.4534, -8.038],
      [110.4531, -8.0387],
      [110.4519, -8.0413],
      [110.4518, -8.0431],
      [110.4514, -8.0446],
      [110.45, -8.048],
      [110.45, -8.0503],
      [110.4499, -8.0546],
      [110.4497, -8.0562],
      [110.4495, -8.0573],
      [110.4492, -8.0593],
      [110.4486, -8.0608],
      [110.4479, -8.0613],
      [110.4467, -8.0616],
      [110.4464, -8.0615],
      [110.4465, -8.0647],
      [110.4465, -8.0661],
      [110.4466, -8.0668],
      [110.4471, -8.0672],
      [110.4478, -8.0673],
      [110.449, -8.0671],
      [110.4506, -8.0671],
      [110.4521, -8.0672],
      [110.4527, -8.0669],
      [110.4532, -8.0665],
      [110.4539, -8.066],
      [110.4551, -8.0657],
      [110.4556, -8.0656],
      [110.4562, -8.0656],
      [110.4567, -8.0662],
      [110.4573, -8.0674],
      [110.4582, -8.069],
      [110.4587, -8.0696],
      [110.4593, -8.0699],
      [110.4595, -8.07],
      [110.4596, -8.0693],
      [110.4598, -8.0686],
      [110.4601, -8.0679],
      [110.4602, -8.0672],
      [110.4603, -8.0669],
      [110.46, -8.0663],
      [110.4596, -8.0653],
      [110.4593, -8.0646],
      [110.4597, -8.0638],
      [110.461, -8.0632],
      [110.4618, -8.063],
      [110.4625, -8.063],
      [110.4634, -8.063],
      [110.4642, -8.063],
      [110.4652, -8.0631],
      [110.4661, -8.0632],
      [110.4672, -8.0634],
      [110.4682, -8.0636],
      [110.4688, -8.0634],
      [110.469, -8.0631],
      [110.4692, -8.0626],
      [110.469, -8.0617],
      [110.4689, -8.0612],
      [110.4691, -8.0606],
      [110.4693, -8.06],
      [110.4694, -8.0599],
      [110.4695, -8.0592],
      [110.4694, -8.0586],
      [110.4691, -8.0579],
      [110.4691, -8.0573],
      [110.4695, -8.056],
      [110.4702, -8.0548],
      [110.471, -8.0539],
      [110.4721, -8.0525],
      [110.4731, -8.051],
      [110.4736, -8.0507],
      [110.4739, -8.0499],
      [110.4745, -8.0488],
      [110.4748, -8.0481],
      [110.4752, -8.0467],
      [110.4756, -8.0461],
      [110.4773, -8.0436],
      [110.4784, -8.042],
      [110.4785, -8.0406],
      [110.4784, -8.0389],
      [110.4783, -8.0379],
      [110.4783, -8.0372],
      [110.4779, -8.0362],
      [110.477, -8.0344],
      [110.4762, -8.0328],
      [110.4758, -8.0311],
      [110.4757, -8.0304],
      [110.476, -8.0297],
      [110.4768, -8.0286],
      [110.4779, -8.0277],
      [110.4786, -8.027],
      [110.4789, -8.0269],
      [110.4801, -8.0261],
      [110.4824, -8.0251],
      [110.4845, -8.0238],
      [110.4853, -8.0233],
      [110.486, -8.0231],
      [110.4867, -8.0223],
      [110.4872, -8.0216],
      [110.4877, -8.021],
      [110.4883, -8.0206],
      [110.4894, -8.0199],
      [110.4907, -8.0187],
      [110.4912, -8.0184],
      [110.4914, -8.0178],
      [110.4913, -8.0174],
      [110.4909, -8.0165],
      [110.4907, -8.0162]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PANGGANG',
    village: 'GIRIMULYO',
    border: [
      [110.4485, -8.0256],
      [110.4475, -8.026],
      [110.4466, -8.0264],
      [110.4454, -8.027],
      [110.4444, -8.0265],
      [110.4432, -8.0263],
      [110.4414, -8.0253],
      [110.4412, -8.0238],
      [110.4414, -8.0234],
      [110.4416, -8.0219],
      [110.4415, -8.0205],
      [110.4409, -8.0194],
      [110.4404, -8.0176],
      [110.4393, -8.0163],
      [110.4379, -8.0154],
      [110.4357, -8.0143],
      [110.4344, -8.0131],
      [110.434, -8.0129],
      [110.434, -8.0138],
      [110.4334, -8.0166],
      [110.4331, -8.0171],
      [110.4329, -8.0183],
      [110.4325, -8.0191],
      [110.4323, -8.0197],
      [110.431, -8.0211],
      [110.4302, -8.0229],
      [110.4294, -8.0243],
      [110.4278, -8.0267],
      [110.4267, -8.0298],
      [110.4264, -8.0312],
      [110.4265, -8.0325],
      [110.4268, -8.0338],
      [110.427, -8.0377],
      [110.427, -8.0403],
      [110.427, -8.044],
      [110.4268, -8.0457],
      [110.4256, -8.0474],
      [110.4247, -8.0486],
      [110.424, -8.0509],
      [110.4221, -8.0533],
      [110.4208, -8.055],
      [110.4203, -8.056],
      [110.4203, -8.0571],
      [110.4202, -8.058],
      [110.4203, -8.0595],
      [110.4194, -8.0607],
      [110.4187, -8.0614],
      [110.4182, -8.0627],
      [110.4177, -8.0637],
      [110.4169, -8.0652],
      [110.4166, -8.0664],
      [110.417, -8.0683],
      [110.4167, -8.0695],
      [110.4165, -8.0701],
      [110.416, -8.0708],
      [110.4159, -8.0712],
      [110.4158, -8.0716],
      [110.4156, -8.0722],
      [110.4156, -8.0727],
      [110.416, -8.073],
      [110.4165, -8.0732],
      [110.4174, -8.0733],
      [110.4187, -8.0734],
      [110.4195, -8.0734],
      [110.4208, -8.0734],
      [110.422, -8.0733],
      [110.4227, -8.0732],
      [110.4235, -8.0733],
      [110.4242, -8.0736],
      [110.4247, -8.0737],
      [110.4251, -8.0734],
      [110.4253, -8.0723],
      [110.4255, -8.0712],
      [110.4256, -8.071],
      [110.4258, -8.0699],
      [110.4272, -8.0673],
      [110.4277, -8.066],
      [110.4281, -8.0651],
      [110.4288, -8.0636],
      [110.4291, -8.0626],
      [110.4301, -8.0609],
      [110.4309, -8.0596],
      [110.432, -8.0582],
      [110.4326, -8.0572],
      [110.4334, -8.0568],
      [110.4343, -8.0566],
      [110.4364, -8.0567],
      [110.4377, -8.057],
      [110.4384, -8.057],
      [110.4393, -8.0573],
      [110.4402, -8.0576],
      [110.4413, -8.0582],
      [110.4419, -8.0585],
      [110.443, -8.0592],
      [110.4442, -8.0603],
      [110.4457, -8.0614],
      [110.4464, -8.0615],
      [110.4467, -8.0616],
      [110.4479, -8.0613],
      [110.4486, -8.0608],
      [110.4492, -8.0593],
      [110.4495, -8.0573],
      [110.4497, -8.0562],
      [110.4499, -8.0546],
      [110.45, -8.0503],
      [110.45, -8.048],
      [110.4514, -8.0446],
      [110.4518, -8.0431],
      [110.4519, -8.0413],
      [110.4531, -8.0387],
      [110.4534, -8.038],
      [110.4531, -8.037],
      [110.4527, -8.0364],
      [110.4518, -8.0362],
      [110.4516, -8.0356],
      [110.4513, -8.0355],
      [110.4511, -8.0349],
      [110.4506, -8.0349],
      [110.4492, -8.0343],
      [110.4477, -8.033],
      [110.4472, -8.0323],
      [110.4471, -8.0316],
      [110.4471, -8.0309],
      [110.4477, -8.0292],
      [110.4478, -8.0281],
      [110.4482, -8.0265],
      [110.4485, -8.0256]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PANGGANG',
    village: 'GIRIWUNGU',
    border: [
      [110.4419, -8.0585],
      [110.4413, -8.0582],
      [110.4402, -8.0576],
      [110.4393, -8.0573],
      [110.4384, -8.057],
      [110.4377, -8.057],
      [110.4364, -8.0567],
      [110.4343, -8.0566],
      [110.4334, -8.0568],
      [110.4326, -8.0572],
      [110.432, -8.0582],
      [110.4309, -8.0596],
      [110.4301, -8.0609],
      [110.4291, -8.0626],
      [110.4288, -8.0636],
      [110.4281, -8.0651],
      [110.4277, -8.066],
      [110.4272, -8.0673],
      [110.4258, -8.0699],
      [110.4256, -8.071],
      [110.4255, -8.0712],
      [110.4253, -8.0723],
      [110.4251, -8.0734],
      [110.4247, -8.0737],
      [110.4242, -8.0736],
      [110.4235, -8.0733],
      [110.4227, -8.0732],
      [110.422, -8.0733],
      [110.4208, -8.0734],
      [110.4195, -8.0734],
      [110.4187, -8.0734],
      [110.4174, -8.0733],
      [110.4165, -8.0732],
      [110.416, -8.073],
      [110.4156, -8.0727],
      [110.4156, -8.0722],
      [110.4158, -8.0716],
      [110.4159, -8.0712],
      [110.416, -8.0708],
      [110.4165, -8.0701],
      [110.4167, -8.0695],
      [110.417, -8.0683],
      [110.4166, -8.0664],
      [110.4169, -8.0652],
      [110.4177, -8.0637],
      [110.4182, -8.0627],
      [110.4187, -8.0614],
      [110.4194, -8.0607],
      [110.4203, -8.0595],
      [110.4202, -8.058],
      [110.4203, -8.0571],
      [110.4203, -8.056],
      [110.4208, -8.055],
      [110.4221, -8.0533],
      [110.424, -8.0509],
      [110.4247, -8.0486],
      [110.4256, -8.0474],
      [110.4268, -8.0457],
      [110.427, -8.044],
      [110.427, -8.0403],
      [110.427, -8.0377],
      [110.4268, -8.0338],
      [110.4265, -8.0325],
      [110.4264, -8.0312],
      [110.4267, -8.0298],
      [110.4278, -8.0267],
      [110.4294, -8.0243],
      [110.4302, -8.0229],
      [110.431, -8.0211],
      [110.4323, -8.0197],
      [110.4325, -8.0191],
      [110.4329, -8.0183],
      [110.4331, -8.0171],
      [110.4334, -8.0166],
      [110.434, -8.0138],
      [110.434, -8.0129],
      [110.4338, -8.013],
      [110.4326, -8.0139],
      [110.4311, -8.0143],
      [110.4304, -8.0144],
      [110.4294, -8.0152],
      [110.429, -8.0156],
      [110.4283, -8.0159],
      [110.4269, -8.0161],
      [110.4267, -8.016],
      [110.4251, -8.0163],
      [110.425, -8.0164],
      [110.4238, -8.0168],
      [110.4232, -8.0175],
      [110.423, -8.018],
      [110.4229, -8.0184],
      [110.4226, -8.019],
      [110.4217, -8.0201],
      [110.4198, -8.0212],
      [110.4188, -8.0219],
      [110.4178, -8.0229],
      [110.4174, -8.0233],
      [110.4168, -8.0244],
      [110.4162, -8.0254],
      [110.4159, -8.0266],
      [110.4157, -8.0277],
      [110.4155, -8.0311],
      [110.4155, -8.0312],
      [110.4156, -8.0329],
      [110.4156, -8.0339],
      [110.4158, -8.035],
      [110.4161, -8.0364],
      [110.4158, -8.0373],
      [110.4153, -8.0379],
      [110.4145, -8.0389],
      [110.4141, -8.0394],
      [110.4138, -8.0404],
      [110.4131, -8.0419],
      [110.413, -8.0426],
      [110.4128, -8.0438],
      [110.4126, -8.0447],
      [110.4123, -8.0455],
      [110.4122, -8.0464],
      [110.4117, -8.0479],
      [110.4113, -8.0491],
      [110.4109, -8.0501],
      [110.4102, -8.0511],
      [110.4097, -8.0519],
      [110.4093, -8.0529],
      [110.4089, -8.0538],
      [110.4082, -8.0554],
      [110.4077, -8.0567],
      [110.4072, -8.0578],
      [110.4068, -8.0594],
      [110.4064, -8.0607],
      [110.406, -8.0616],
      [110.4057, -8.0626],
      [110.4053, -8.0635],
      [110.405, -8.0644],
      [110.4045, -8.0654],
      [110.4037, -8.066],
      [110.4028, -8.0668],
      [110.4018, -8.0675],
      [110.4009, -8.0684],
      [110.4, -8.069],
      [110.3991, -8.07],
      [110.3983, -8.0708],
      [110.3979, -8.0712],
      [110.3974, -8.0718],
      [110.3959, -8.0727],
      [110.3944, -8.0739],
      [110.3933, -8.0752],
      [110.3924, -8.0764],
      [110.3926, -8.0765],
      [110.3929, -8.0766],
      [110.3934, -8.0769],
      [110.3937, -8.0771],
      [110.3941, -8.0772],
      [110.3944, -8.0772],
      [110.3948, -8.0771],
      [110.3952, -8.077],
      [110.3955, -8.0769],
      [110.3959, -8.0768],
      [110.396, -8.0769],
      [110.3962, -8.0768],
      [110.3964, -8.0768],
      [110.3965, -8.0769],
      [110.3966, -8.0771],
      [110.3968, -8.0776],
      [110.3969, -8.0777],
      [110.397, -8.0781],
      [110.3971, -8.0784],
      [110.3974, -8.0785],
      [110.3983, -8.0788],
      [110.3987, -8.0788],
      [110.3989, -8.0787],
      [110.399, -8.0785],
      [110.3993, -8.0784],
      [110.3995, -8.0782],
      [110.3997, -8.0781],
      [110.4002, -8.0781],
      [110.4003, -8.0781],
      [110.4006, -8.078],
      [110.401, -8.0779],
      [110.4012, -8.0778],
      [110.4015, -8.078],
      [110.4017, -8.078],
      [110.402, -8.0781],
      [110.4022, -8.0781],
      [110.4024, -8.0782],
      [110.4026, -8.0784],
      [110.4027, -8.0787],
      [110.4028, -8.079],
      [110.4031, -8.0791],
      [110.4034, -8.0792],
      [110.4035, -8.0792],
      [110.4039, -8.0797],
      [110.4041, -8.0799],
      [110.4042, -8.0799],
      [110.4044, -8.0799],
      [110.4045, -8.0797],
      [110.4046, -8.0797],
      [110.4048, -8.0796],
      [110.4049, -8.0794],
      [110.4051, -8.0793],
      [110.4055, -8.0792],
      [110.4058, -8.0792],
      [110.4061, -8.0792],
      [110.4066, -8.0792],
      [110.4071, -8.0793],
      [110.4075, -8.0793],
      [110.4078, -8.0793],
      [110.4081, -8.0792],
      [110.4084, -8.0792],
      [110.4086, -8.0792],
      [110.4087, -8.0793],
      [110.4089, -8.0798],
      [110.409, -8.0799],
      [110.4091, -8.08],
      [110.4092, -8.0802],
      [110.4093, -8.0808],
      [110.4095, -8.0812],
      [110.4096, -8.0814],
      [110.4097, -8.0814],
      [110.4099, -8.0814],
      [110.4101, -8.0813],
      [110.4104, -8.0813],
      [110.4106, -8.0814],
      [110.4111, -8.0818],
      [110.4115, -8.082],
      [110.4119, -8.0822],
      [110.4123, -8.0823],
      [110.4126, -8.0822],
      [110.4131, -8.0821],
      [110.4134, -8.0819],
      [110.414, -8.0821],
      [110.4145, -8.0826],
      [110.4148, -8.0828],
      [110.4152, -8.0828],
      [110.4155, -8.0828],
      [110.4158, -8.0826],
      [110.4161, -8.0826],
      [110.4163, -8.0826],
      [110.4164, -8.0828],
      [110.4163, -8.0831],
      [110.4162, -8.0832],
      [110.4161, -8.0835],
      [110.416, -8.0839],
      [110.4159, -8.0841],
      [110.416, -8.0844],
      [110.4161, -8.0845],
      [110.4165, -8.0845],
      [110.417, -8.0845],
      [110.4173, -8.0844],
      [110.4176, -8.0844],
      [110.418, -8.0847],
      [110.4183, -8.085],
      [110.4184, -8.0856],
      [110.4185, -8.086],
      [110.4184, -8.0862],
      [110.4184, -8.0864],
      [110.4185, -8.0866],
      [110.4187, -8.0867],
      [110.419, -8.0867],
      [110.4192, -8.0869],
      [110.4193, -8.087],
      [110.4196, -8.0869],
      [110.42, -8.0868],
      [110.4202, -8.0866],
      [110.4204, -8.0864],
      [110.4208, -8.0864],
      [110.421, -8.0865],
      [110.4215, -8.0869],
      [110.4218, -8.0872],
      [110.4218, -8.0876],
      [110.422, -8.0877],
      [110.4222, -8.0878],
      [110.4224, -8.0879],
      [110.4227, -8.088],
      [110.4229, -8.088],
      [110.4231, -8.0879],
      [110.4232, -8.0875],
      [110.4233, -8.0872],
      [110.4237, -8.0871],
      [110.424, -8.087],
      [110.4243, -8.087],
      [110.4246, -8.0872],
      [110.4248, -8.0872],
      [110.4249, -8.0873],
      [110.4251, -8.0874],
      [110.4252, -8.0877],
      [110.4255, -8.0879],
      [110.4256, -8.0879],
      [110.4258, -8.0883],
      [110.4261, -8.0883],
      [110.4264, -8.0884],
      [110.4266, -8.0885],
      [110.4269, -8.0884],
      [110.4271, -8.0887],
      [110.4276, -8.0888],
      [110.4282, -8.0889],
      [110.4287, -8.0888],
      [110.429, -8.0888],
      [110.4298, -8.0889],
      [110.43, -8.089],
      [110.4303, -8.0892],
      [110.4308, -8.0899],
      [110.4312, -8.0906],
      [110.4312, -8.0907],
      [110.4313, -8.0909],
      [110.4314, -8.0912],
      [110.4315, -8.0914],
      [110.4317, -8.0915],
      [110.432, -8.0918],
      [110.4322, -8.092],
      [110.4324, -8.0922],
      [110.4326, -8.0924],
      [110.4327, -8.0926],
      [110.4332, -8.0928],
      [110.4336, -8.0929],
      [110.4339, -8.0931],
      [110.4343, -8.0933],
      [110.4346, -8.0935],
      [110.4351, -8.0927],
      [110.4355, -8.0917],
      [110.4355, -8.0913],
      [110.4351, -8.091],
      [110.4348, -8.0905],
      [110.4346, -8.0899],
      [110.4346, -8.0895],
      [110.435, -8.0888],
      [110.4349, -8.0883],
      [110.4339, -8.0864],
      [110.4335, -8.0854],
      [110.4332, -8.0825],
      [110.4331, -8.0812],
      [110.4328, -8.0792],
      [110.4325, -8.0776],
      [110.4324, -8.0766],
      [110.4315, -8.074],
      [110.4317, -8.0731],
      [110.4327, -8.0714],
      [110.4327, -8.0712],
      [110.4331, -8.0706],
      [110.4333, -8.0686],
      [110.4333, -8.0675],
      [110.4338, -8.0664],
      [110.4344, -8.0655],
      [110.4347, -8.065],
      [110.4353, -8.0646],
      [110.4366, -8.0644],
      [110.437, -8.0642],
      [110.4377, -8.0638],
      [110.4389, -8.0631],
      [110.44, -8.0618],
      [110.4404, -8.0609],
      [110.4408, -8.0601],
      [110.4419, -8.0585]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PANGGANG',
    village: 'GIRIHARJO',
    border: [
      [110.4115, -7.9717],
      [110.4112, -7.9717],
      [110.4105, -7.9716],
      [110.4096, -7.9718],
      [110.4084, -7.9715],
      [110.407, -7.9709],
      [110.4065, -7.9705],
      [110.4065, -7.9703],
      [110.4064, -7.9698],
      [110.4061, -7.9694],
      [110.4056, -7.9689],
      [110.4045, -7.9682],
      [110.4042, -7.9683],
      [110.4036, -7.9683],
      [110.4031, -7.9682],
      [110.4027, -7.9681],
      [110.4019, -7.968],
      [110.4012, -7.9679],
      [110.4007, -7.9678],
      [110.3997, -7.9678],
      [110.3994, -7.9679],
      [110.3993, -7.968],
      [110.3991, -7.968],
      [110.3986, -7.9679],
      [110.3974, -7.9672],
      [110.3971, -7.9671],
      [110.3966, -7.9675],
      [110.3959, -7.9679],
      [110.3957, -7.9682],
      [110.3956, -7.9686],
      [110.3958, -7.9691],
      [110.3963, -7.9697],
      [110.3962, -7.9701],
      [110.3962, -7.9704],
      [110.3962, -7.9711],
      [110.3963, -7.9719],
      [110.3963, -7.973],
      [110.3963, -7.9737],
      [110.3965, -7.9742],
      [110.397, -7.9744],
      [110.3974, -7.9746],
      [110.3978, -7.9754],
      [110.3984, -7.9766],
      [110.3994, -7.9788],
      [110.3991, -7.9794],
      [110.3986, -7.9799],
      [110.3981, -7.9804],
      [110.3976, -7.9807],
      [110.3967, -7.9809],
      [110.3951, -7.9811],
      [110.3931, -7.9812],
      [110.3916, -7.9812],
      [110.3915, -7.9812],
      [110.3915, -7.9817],
      [110.3918, -7.9826],
      [110.3922, -7.9838],
      [110.3928, -7.9848],
      [110.3932, -7.9859],
      [110.3936, -7.9867],
      [110.3942, -7.9874],
      [110.3948, -7.9881],
      [110.3951, -7.9889],
      [110.3956, -7.9897],
      [110.3964, -7.9906],
      [110.3967, -7.9911],
      [110.397, -7.9921],
      [110.3971, -7.9935],
      [110.397, -7.9951],
      [110.3969, -7.9961],
      [110.3969, -7.9977],
      [110.397, -7.9985],
      [110.3971, -7.9991],
      [110.3974, -7.9999],
      [110.3975, -8.0009],
      [110.3979, -8.002],
      [110.398, -8.0023],
      [110.3981, -8.0028],
      [110.3981, -8.0037],
      [110.3981, -8.004],
      [110.3983, -8.0049],
      [110.3985, -8.0061],
      [110.3988, -8.006],
      [110.4001, -8.0058],
      [110.4018, -8.0056],
      [110.4032, -8.0056],
      [110.4045, -8.006],
      [110.4057, -8.0068],
      [110.4077, -8.0081],
      [110.4087, -8.0095],
      [110.4089, -8.0102],
      [110.4091, -8.0113],
      [110.4092, -8.0126],
      [110.4092, -8.0129],
      [110.4092, -8.0132],
      [110.4093, -8.0141],
      [110.4096, -8.0154],
      [110.4103, -8.017],
      [110.4114, -8.0187],
      [110.412, -8.0198],
      [110.4127, -8.0202],
      [110.4152, -8.022],
      [110.4174, -8.0233],
      [110.4178, -8.0229],
      [110.4188, -8.0219],
      [110.4198, -8.0212],
      [110.4217, -8.0201],
      [110.4226, -8.019],
      [110.4229, -8.0184],
      [110.423, -8.018],
      [110.4232, -8.0175],
      [110.4238, -8.0168],
      [110.425, -8.0164],
      [110.4251, -8.0163],
      [110.4267, -8.016],
      [110.4269, -8.0161],
      [110.4283, -8.0159],
      [110.429, -8.0156],
      [110.4294, -8.0152],
      [110.4304, -8.0144],
      [110.4311, -8.0143],
      [110.4326, -8.0139],
      [110.4338, -8.013],
      [110.434, -8.0129],
      [110.4338, -8.0124],
      [110.4326, -8.0111],
      [110.4303, -8.0096],
      [110.429, -8.0092],
      [110.4282, -8.0087],
      [110.4278, -8.0084],
      [110.4273, -8.0081],
      [110.426, -8.0072],
      [110.4241, -8.0068],
      [110.4232, -8.0068],
      [110.4224, -8.0067],
      [110.4216, -8.006],
      [110.4211, -8.0054],
      [110.4209, -8.004],
      [110.4212, -8.0027],
      [110.421, -8.0015],
      [110.4211, -8.0008],
      [110.4213, -8.0],
      [110.4211, -7.9993],
      [110.4205, -7.9968],
      [110.4199, -7.9938],
      [110.4198, -7.9922],
      [110.4196, -7.9917],
      [110.4195, -7.9909],
      [110.4192, -7.9895],
      [110.4183, -7.9866],
      [110.4182, -7.9865],
      [110.4181, -7.9863],
      [110.4169, -7.9847],
      [110.4149, -7.9818],
      [110.4144, -7.9794],
      [110.4138, -7.9782],
      [110.4133, -7.9767],
      [110.4128, -7.9744],
      [110.4118, -7.9729],
      [110.4115, -7.9717]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PANGGANG',
    village: 'GIRISUKO',
    border: [
      [110.4408, -7.9673],
      [110.4401, -7.9673],
      [110.4375, -7.9668],
      [110.4367, -7.9668],
      [110.4358, -7.9668],
      [110.4352, -7.9666],
      [110.4345, -7.9662],
      [110.4333, -7.965],
      [110.4326, -7.9638],
      [110.4322, -7.9632],
      [110.4316, -7.963],
      [110.4315, -7.9631],
      [110.4309, -7.9634],
      [110.4306, -7.9638],
      [110.4304, -7.9642],
      [110.4305, -7.9649],
      [110.4302, -7.9656],
      [110.4295, -7.9665],
      [110.4281, -7.9677],
      [110.4274, -7.9685],
      [110.4269, -7.9689],
      [110.4253, -7.9696],
      [110.4242, -7.9701],
      [110.4235, -7.9709],
      [110.4221, -7.9722],
      [110.4209, -7.9734],
      [110.4193, -7.9727],
      [110.4187, -7.9726],
      [110.4175, -7.9726],
      [110.4168, -7.9726],
      [110.4159, -7.9727],
      [110.4158, -7.9726],
      [110.4149, -7.9721],
      [110.4145, -7.9721],
      [110.4137, -7.9721],
      [110.413, -7.9719],
      [110.4124, -7.9716],
      [110.4115, -7.9717],
      [110.4118, -7.9729],
      [110.4128, -7.9744],
      [110.4133, -7.9767],
      [110.4138, -7.9782],
      [110.4144, -7.9794],
      [110.4149, -7.9818],
      [110.4169, -7.9847],
      [110.4181, -7.9863],
      [110.4182, -7.9865],
      [110.4183, -7.9866],
      [110.4192, -7.9895],
      [110.4195, -7.9909],
      [110.4196, -7.9917],
      [110.4198, -7.9922],
      [110.4199, -7.9938],
      [110.4205, -7.9968],
      [110.4211, -7.9993],
      [110.4213, -8.0],
      [110.4211, -8.0008],
      [110.421, -8.0015],
      [110.4212, -8.0027],
      [110.4209, -8.004],
      [110.4211, -8.0054],
      [110.4216, -8.006],
      [110.4224, -8.0067],
      [110.4232, -8.0068],
      [110.4241, -8.0068],
      [110.426, -8.0072],
      [110.4273, -8.0081],
      [110.4278, -8.0084],
      [110.4282, -8.0087],
      [110.429, -8.0092],
      [110.4303, -8.0096],
      [110.4326, -8.0111],
      [110.4338, -8.0124],
      [110.434, -8.0129],
      [110.4344, -8.0131],
      [110.4357, -8.0143],
      [110.4379, -8.0154],
      [110.4393, -8.0163],
      [110.4404, -8.0176],
      [110.4409, -8.0194],
      [110.4415, -8.0205],
      [110.4416, -8.0219],
      [110.4414, -8.0234],
      [110.4412, -8.0238],
      [110.4414, -8.0253],
      [110.4432, -8.0263],
      [110.4444, -8.0265],
      [110.4454, -8.027],
      [110.4466, -8.0264],
      [110.4475, -8.026],
      [110.4485, -8.0256],
      [110.4496, -8.0253],
      [110.4509, -8.025],
      [110.452, -8.0247],
      [110.4528, -8.0246],
      [110.4537, -8.0244],
      [110.4554, -8.0243],
      [110.4561, -8.0241],
      [110.4576, -8.024],
      [110.4584, -8.0238],
      [110.4592, -8.0238],
      [110.46, -8.0238],
      [110.4602, -8.0238],
      [110.4605, -8.0237],
      [110.4608, -8.0236],
      [110.461, -8.0235],
      [110.462, -8.0231],
      [110.4636, -8.0224],
      [110.4658, -8.0208],
      [110.4661, -8.0205],
      [110.4667, -8.02],
      [110.4678, -8.0192],
      [110.4687, -8.0185],
      [110.4696, -8.0174],
      [110.4702, -8.0159],
      [110.4703, -8.0147],
      [110.4705, -8.0138],
      [110.4707, -8.0133],
      [110.4712, -8.0129],
      [110.4719, -8.0128],
      [110.4729, -8.0125],
      [110.4735, -8.0122],
      [110.474, -8.0114],
      [110.4742, -8.0095],
      [110.4745, -8.0083],
      [110.4751, -8.0071],
      [110.476, -8.0064],
      [110.4771, -8.0055],
      [110.4781, -8.0048],
      [110.4773, -8.0039],
      [110.4768, -8.0031],
      [110.4761, -8.0025],
      [110.4753, -8.0019],
      [110.4752, -8.0018],
      [110.4751, -8.0017],
      [110.4743, -8.0011],
      [110.4736, -8.0005],
      [110.4735, -8.0001],
      [110.4735, -7.9999],
      [110.4738, -7.9993],
      [110.4739, -7.9989],
      [110.474, -7.9987],
      [110.4742, -7.9982],
      [110.4744, -7.9977],
      [110.4754, -7.9978],
      [110.4764, -7.9976],
      [110.4775, -7.9972],
      [110.4786, -7.9969],
      [110.4796, -7.9973],
      [110.4816, -7.9972],
      [110.4827, -7.9972],
      [110.483, -7.9972],
      [110.484, -7.9972],
      [110.4847, -7.9973],
      [110.4857, -7.9978],
      [110.4865, -7.9981],
      [110.487, -7.9971],
      [110.4875, -7.9958],
      [110.4879, -7.9933],
      [110.4881, -7.9921],
      [110.4886, -7.9907],
      [110.4886, -7.9897],
      [110.4882, -7.9883],
      [110.4879, -7.9876],
      [110.4879, -7.9871],
      [110.4877, -7.9859],
      [110.4862, -7.9857],
      [110.4837, -7.986],
      [110.4826, -7.9862],
      [110.4811, -7.9862],
      [110.4788, -7.9859],
      [110.4777, -7.9858],
      [110.4761, -7.986],
      [110.4755, -7.9862],
      [110.4739, -7.9863],
      [110.4728, -7.9865],
      [110.4716, -7.9865],
      [110.4689, -7.9861],
      [110.466, -7.9858],
      [110.4644, -7.986],
      [110.4642, -7.986],
      [110.4637, -7.9866],
      [110.4635, -7.9873],
      [110.4633, -7.9879],
      [110.4634, -7.9882],
      [110.4635, -7.989],
      [110.4638, -7.9899],
      [110.4642, -7.9908],
      [110.4646, -7.9915],
      [110.4647, -7.9922],
      [110.4647, -7.9926],
      [110.4647, -7.9928],
      [110.4647, -7.993],
      [110.4645, -7.994],
      [110.4641, -7.9948],
      [110.4634, -7.9952],
      [110.462, -7.9956],
      [110.4607, -7.9957],
      [110.4593, -7.9953],
      [110.458, -7.9943],
      [110.4569, -7.9937],
      [110.4554, -7.9928],
      [110.4545, -7.9921],
      [110.4533, -7.9908],
      [110.4522, -7.9896],
      [110.4516, -7.9887],
      [110.4509, -7.9871],
      [110.4503, -7.9859],
      [110.4496, -7.9851],
      [110.4489, -7.9845],
      [110.4477, -7.9831],
      [110.447, -7.9819],
      [110.4459, -7.9807],
      [110.4449, -7.9797],
      [110.4444, -7.9791],
      [110.4437, -7.9783],
      [110.4433, -7.9777],
      [110.4427, -7.9772],
      [110.4421, -7.9763],
      [110.4416, -7.9752],
      [110.4413, -7.9746],
      [110.4411, -7.9735],
      [110.4412, -7.9726],
      [110.4412, -7.9725],
      [110.4413, -7.9717],
      [110.4414, -7.9707],
      [110.4414, -7.9695],
      [110.4412, -7.9684],
      [110.441, -7.9676],
      [110.4408, -7.9673]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TEPUS',
    village: 'GIRIPANGGUNG',
    border: [
      [110.7064, -8.0971],
      [110.7063, -8.0969],
      [110.7055, -8.0961],
      [110.7046, -8.0956],
      [110.7035, -8.0944],
      [110.703, -8.094],
      [110.7022, -8.0934],
      [110.7011, -8.0924],
      [110.6997, -8.0916],
      [110.6987, -8.091],
      [110.6981, -8.0904],
      [110.6976, -8.0899],
      [110.6972, -8.0894],
      [110.6967, -8.0892],
      [110.696, -8.0885],
      [110.6953, -8.0882],
      [110.6944, -8.0869],
      [110.6937, -8.0864],
      [110.693, -8.0854],
      [110.6925, -8.0848],
      [110.6923, -8.084],
      [110.6912, -8.0825],
      [110.6904, -8.0814],
      [110.6895, -8.081],
      [110.6888, -8.0807],
      [110.6886, -8.0806],
      [110.688, -8.08],
      [110.687, -8.0791],
      [110.6865, -8.079],
      [110.6859, -8.079],
      [110.6853, -8.0793],
      [110.6849, -8.0798],
      [110.6842, -8.0797],
      [110.6829, -8.0796],
      [110.6817, -8.0796],
      [110.6812, -8.0797],
      [110.6805, -8.08],
      [110.6796, -8.0804],
      [110.6789, -8.0805],
      [110.6781, -8.0805],
      [110.6774, -8.0808],
      [110.6765, -8.0812],
      [110.676, -8.0813],
      [110.6752, -8.0811],
      [110.6738, -8.0806],
      [110.6737, -8.0817],
      [110.6733, -8.0828],
      [110.6728, -8.0839],
      [110.6725, -8.0847],
      [110.6721, -8.0852],
      [110.6718, -8.086],
      [110.6714, -8.0871],
      [110.6714, -8.0879],
      [110.6715, -8.089],
      [110.6721, -8.0923],
      [110.6729, -8.0936],
      [110.6731, -8.0951],
      [110.6738, -8.0961],
      [110.6747, -8.0982],
      [110.6747, -8.0993],
      [110.6743, -8.0997],
      [110.6737, -8.1001],
      [110.6733, -8.1005],
      [110.6729, -8.1013],
      [110.6727, -8.1023],
      [110.6731, -8.1034],
      [110.6734, -8.1041],
      [110.6739, -8.1046],
      [110.675, -8.1056],
      [110.6759, -8.1068],
      [110.6757, -8.108],
      [110.6754, -8.1095],
      [110.6747, -8.1115],
      [110.6744, -8.1124],
      [110.6745, -8.1134],
      [110.6747, -8.1146],
      [110.6749, -8.1152],
      [110.6747, -8.1158],
      [110.6746, -8.1164],
      [110.6743, -8.1184],
      [110.6739, -8.1194],
      [110.6733, -8.1203],
      [110.673, -8.1209],
      [110.6724, -8.1222],
      [110.6718, -8.1225],
      [110.671, -8.1232],
      [110.6687, -8.1245],
      [110.6679, -8.1246],
      [110.6669, -8.1245],
      [110.6657, -8.1241],
      [110.6643, -8.1235],
      [110.6646, -8.1238],
      [110.6653, -8.1244],
      [110.6663, -8.1247],
      [110.6665, -8.1251],
      [110.6668, -8.1254],
      [110.6669, -8.1265],
      [110.6669, -8.1285],
      [110.6671, -8.1285],
      [110.671, -8.1291],
      [110.6718, -8.1285],
      [110.6725, -8.1281],
      [110.6741, -8.128],
      [110.6752, -8.1279],
      [110.6771, -8.1279],
      [110.6801, -8.1284],
      [110.6812, -8.1286],
      [110.6829, -8.1283],
      [110.6841, -8.128],
      [110.6848, -8.1274],
      [110.6853, -8.1269],
      [110.6861, -8.1267],
      [110.6867, -8.1267],
      [110.6871, -8.1263],
      [110.6876, -8.1257],
      [110.6876, -8.1252],
      [110.6883, -8.1246],
      [110.689, -8.1235],
      [110.6902, -8.122],
      [110.6909, -8.1211],
      [110.6913, -8.1209],
      [110.692, -8.1208],
      [110.6927, -8.1209],
      [110.6931, -8.1209],
      [110.6939, -8.1207],
      [110.6943, -8.1207],
      [110.6948, -8.121],
      [110.6953, -8.1212],
      [110.6964, -8.1214],
      [110.6969, -8.1216],
      [110.6973, -8.122],
      [110.6974, -8.1225],
      [110.6979, -8.1232],
      [110.6985, -8.1235],
      [110.6991, -8.1237],
      [110.6995, -8.1237],
      [110.6996, -8.1236],
      [110.7001, -8.1233],
      [110.7013, -8.1232],
      [110.7028, -8.1231],
      [110.7038, -8.1228],
      [110.7043, -8.1228],
      [110.705, -8.1223],
      [110.7057, -8.1215],
      [110.7062, -8.1207],
      [110.707, -8.1198],
      [110.7074, -8.1194],
      [110.7082, -8.1189],
      [110.7088, -8.1185],
      [110.7094, -8.118],
      [110.7099, -8.1174],
      [110.7104, -8.1169],
      [110.711, -8.1163],
      [110.7111, -8.1158],
      [110.7114, -8.1151],
      [110.7119, -8.1143],
      [110.7128, -8.1136],
      [110.7135, -8.113],
      [110.714, -8.1126],
      [110.7143, -8.1121],
      [110.7146, -8.1113],
      [110.7147, -8.1106],
      [110.7147, -8.1097],
      [110.7147, -8.1085],
      [110.7145, -8.1071],
      [110.7139, -8.105],
      [110.7131, -8.1035],
      [110.713, -8.1032],
      [110.7125, -8.1029],
      [110.7121, -8.1026],
      [110.7117, -8.1019],
      [110.7114, -8.101],
      [110.7111, -8.1007],
      [110.7092, -8.1001],
      [110.7081, -8.0995],
      [110.7072, -8.0984],
      [110.7064, -8.0971]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TEPUS',
    village: 'SUMBERWUNGU',
    border: [
      [110.6457, -8.0571],
      [110.6454, -8.0571],
      [110.645, -8.0569],
      [110.6445, -8.0566],
      [110.6432, -8.0563],
      [110.6424, -8.0564],
      [110.6414, -8.0565],
      [110.6406, -8.0565],
      [110.64, -8.0562],
      [110.6395, -8.056],
      [110.6382, -8.0549],
      [110.6375, -8.0541],
      [110.637, -8.0536],
      [110.6363, -8.0536],
      [110.635, -8.054],
      [110.6341, -8.0542],
      [110.6327, -8.0544],
      [110.6319, -8.0546],
      [110.6313, -8.0549],
      [110.6305, -8.0552],
      [110.6299, -8.0558],
      [110.6293, -8.0564],
      [110.6289, -8.057],
      [110.6288, -8.0574],
      [110.6286, -8.0582],
      [110.6282, -8.0586],
      [110.6281, -8.059],
      [110.6277, -8.0601],
      [110.6281, -8.0605],
      [110.6288, -8.0613],
      [110.6299, -8.062],
      [110.6308, -8.0623],
      [110.6317, -8.0628],
      [110.6328, -8.0633],
      [110.6338, -8.0638],
      [110.6346, -8.0643],
      [110.6355, -8.0651],
      [110.6362, -8.0662],
      [110.6364, -8.067],
      [110.6363, -8.0676],
      [110.6363, -8.0683],
      [110.6365, -8.0687],
      [110.6369, -8.0691],
      [110.6371, -8.0696],
      [110.6379, -8.0708],
      [110.6378, -8.0712],
      [110.6378, -8.0713],
      [110.6377, -8.0716],
      [110.6371, -8.0723],
      [110.6366, -8.0729],
      [110.6359, -8.0735],
      [110.6354, -8.0737],
      [110.6347, -8.0741],
      [110.6338, -8.0743],
      [110.6334, -8.0745],
      [110.6329, -8.0748],
      [110.6326, -8.0752],
      [110.631, -8.0771],
      [110.6301, -8.0783],
      [110.6303, -8.0785],
      [110.6308, -8.0796],
      [110.6314, -8.0809],
      [110.6315, -8.0811],
      [110.6319, -8.0816],
      [110.6322, -8.0817],
      [110.6324, -8.0818],
      [110.6334, -8.0819],
      [110.6343, -8.0817],
      [110.6351, -8.082],
      [110.636, -8.0824],
      [110.6369, -8.083],
      [110.6377, -8.0836],
      [110.6379, -8.0839],
      [110.6383, -8.0851],
      [110.6385, -8.0856],
      [110.6391, -8.0858],
      [110.6399, -8.086],
      [110.641, -8.086],
      [110.6416, -8.086],
      [110.6419, -8.0855],
      [110.6423, -8.0845],
      [110.6427, -8.0842],
      [110.6432, -8.084],
      [110.6437, -8.0841],
      [110.6446, -8.0845],
      [110.6448, -8.0849],
      [110.6448, -8.0854],
      [110.6452, -8.0871],
      [110.6456, -8.0878],
      [110.6465, -8.0885],
      [110.647, -8.0894],
      [110.6472, -8.0903],
      [110.6473, -8.0904],
      [110.6475, -8.0911],
      [110.6478, -8.0914],
      [110.6488, -8.092],
      [110.6494, -8.0925],
      [110.65, -8.0938],
      [110.6501, -8.0951],
      [110.6498, -8.0967],
      [110.6496, -8.0973],
      [110.6495, -8.0988],
      [110.6491, -8.0995],
      [110.6489, -8.1004],
      [110.6485, -8.1021],
      [110.6482, -8.1027],
      [110.6474, -8.1037],
      [110.6469, -8.1044],
      [110.6463, -8.1051],
      [110.6459, -8.1059],
      [110.6461, -8.1064],
      [110.6466, -8.1073],
      [110.648, -8.1084],
      [110.6489, -8.1092],
      [110.6502, -8.1097],
      [110.6508, -8.11],
      [110.6517, -8.1102],
      [110.6529, -8.11],
      [110.6543, -8.1096],
      [110.6546, -8.1097],
      [110.6548, -8.1102],
      [110.6556, -8.1115],
      [110.6561, -8.1123],
      [110.6566, -8.1138],
      [110.6567, -8.1155],
      [110.6569, -8.1169],
      [110.657, -8.1176],
      [110.6572, -8.1184],
      [110.6573, -8.1195],
      [110.6576, -8.1199],
      [110.6581, -8.1209],
      [110.6584, -8.1213],
      [110.6588, -8.1215],
      [110.6594, -8.1218],
      [110.6601, -8.1219],
      [110.6612, -8.1223],
      [110.662, -8.1228],
      [110.663, -8.1236],
      [110.6636, -8.1236],
      [110.6643, -8.1235],
      [110.6657, -8.1241],
      [110.6669, -8.1245],
      [110.6679, -8.1246],
      [110.6687, -8.1245],
      [110.671, -8.1232],
      [110.6718, -8.1225],
      [110.6724, -8.1222],
      [110.673, -8.1209],
      [110.6733, -8.1203],
      [110.6739, -8.1194],
      [110.6743, -8.1184],
      [110.6746, -8.1164],
      [110.6747, -8.1158],
      [110.6749, -8.1152],
      [110.6747, -8.1146],
      [110.6745, -8.1134],
      [110.6744, -8.1124],
      [110.6747, -8.1115],
      [110.6754, -8.1095],
      [110.6757, -8.108],
      [110.6759, -8.1068],
      [110.675, -8.1056],
      [110.6739, -8.1046],
      [110.6734, -8.1041],
      [110.6731, -8.1034],
      [110.6727, -8.1023],
      [110.6729, -8.1013],
      [110.6733, -8.1005],
      [110.6737, -8.1001],
      [110.6743, -8.0997],
      [110.6747, -8.0993],
      [110.6747, -8.0982],
      [110.6738, -8.0961],
      [110.6731, -8.0951],
      [110.6729, -8.0936],
      [110.6721, -8.0923],
      [110.6715, -8.089],
      [110.6714, -8.0879],
      [110.6714, -8.0871],
      [110.6718, -8.086],
      [110.6721, -8.0852],
      [110.6725, -8.0847],
      [110.6728, -8.0839],
      [110.6733, -8.0828],
      [110.6737, -8.0817],
      [110.6738, -8.0806],
      [110.6736, -8.0805],
      [110.6728, -8.0808],
      [110.6715, -8.0809],
      [110.6703, -8.081],
      [110.67, -8.0808],
      [110.6688, -8.08],
      [110.6677, -8.0795],
      [110.6673, -8.079],
      [110.6667, -8.0787],
      [110.6663, -8.0783],
      [110.6657, -8.0777],
      [110.6651, -8.0775],
      [110.6647, -8.0772],
      [110.6638, -8.076],
      [110.663, -8.0749],
      [110.6625, -8.0744],
      [110.6625, -8.0742],
      [110.6627, -8.0738],
      [110.6632, -8.073],
      [110.6634, -8.0721],
      [110.6635, -8.0712],
      [110.6635, -8.0711],
      [110.6634, -8.07],
      [110.663, -8.0688],
      [110.6626, -8.0677],
      [110.6621, -8.0666],
      [110.6616, -8.0659],
      [110.6608, -8.0655],
      [110.6598, -8.0651],
      [110.6586, -8.065],
      [110.6579, -8.065],
      [110.657, -8.0649],
      [110.6561, -8.0649],
      [110.6556, -8.0646],
      [110.655, -8.0643],
      [110.6543, -8.0638],
      [110.6536, -8.0637],
      [110.6526, -8.0637],
      [110.6512, -8.0637],
      [110.65, -8.0631],
      [110.6491, -8.0621],
      [110.6485, -8.0615],
      [110.6481, -8.0604],
      [110.6478, -8.0593],
      [110.6474, -8.0585],
      [110.647, -8.0582],
      [110.6465, -8.0579],
      [110.6458, -8.0574],
      [110.6457, -8.0571]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TEPUS',
    village: 'SIDOHARJO',
    border: [
      [
        [110.6301, -8.0783],
        [110.6294, -8.0774],
        [110.6288, -8.0769],
        [110.6278, -8.0769],
        [110.6271, -8.0769],
        [110.6268, -8.0768],
        [110.6261, -8.077],
        [110.6253, -8.0772],
        [110.625, -8.0772],
        [110.6244, -8.0771],
        [110.624, -8.0769],
        [110.6235, -8.077],
        [110.622, -8.0768],
        [110.62, -8.0772],
        [110.6189, -8.0776],
        [110.6179, -8.0784],
        [110.6172, -8.0792],
        [110.6166, -8.0799],
        [110.6163, -8.0808],
        [110.6162, -8.0815],
        [110.6161, -8.0828],
        [110.6161, -8.0835],
        [110.6167, -8.0848],
        [110.6175, -8.0863],
        [110.6183, -8.0876],
        [110.6186, -8.0886],
        [110.619, -8.0899],
        [110.6194, -8.0908],
        [110.6199, -8.0918],
        [110.6206, -8.0931],
        [110.6211, -8.0939],
        [110.6214, -8.0949],
        [110.6216, -8.0955],
        [110.6219, -8.096],
        [110.6219, -8.0968],
        [110.6217, -8.0974],
        [110.6208, -8.099],
        [110.6202, -8.1007],
        [110.6198, -8.1018],
        [110.6193, -8.1028],
        [110.6188, -8.1037],
        [110.6184, -8.104],
        [110.6182, -8.1041],
        [110.6181, -8.1042],
        [110.6172, -8.1047],
        [110.6165, -8.1053],
        [110.616, -8.1059],
        [110.6155, -8.1067],
        [110.6152, -8.1076],
        [110.6147, -8.1085],
        [110.6139, -8.1093],
        [110.6129, -8.1101],
        [110.6119, -8.1108],
        [110.6108, -8.1112],
        [110.6098, -8.1116],
        [110.609, -8.1124],
        [110.6086, -8.1132],
        [110.6081, -8.1137],
        [110.6076, -8.1144],
        [110.6075, -8.115],
        [110.6075, -8.1159],
        [110.6078, -8.1164],
        [110.6082, -8.1172],
        [110.6084, -8.118],
        [110.6082, -8.1183],
        [110.6073, -8.1187],
        [110.6067, -8.119],
        [110.6061, -8.1197],
        [110.6054, -8.1202],
        [110.6048, -8.121],
        [110.6042, -8.1217],
        [110.6038, -8.1225],
        [110.6035, -8.1233],
        [110.603, -8.124],
        [110.6024, -8.1245],
        [110.6021, -8.1252],
        [110.6022, -8.1258],
        [110.6013, -8.1263],
        [110.6007, -8.1266],
        [110.6004, -8.1269],
        [110.6003, -8.1274],
        [110.6005, -8.1281],
        [110.6006, -8.1289],
        [110.6008, -8.1304],
        [110.6009, -8.1317],
        [110.6013, -8.1331],
        [110.6014, -8.1342],
        [110.6016, -8.1348],
        [110.6017, -8.1362],
        [110.6018, -8.1374],
        [110.6017, -8.1384],
        [110.6014, -8.1391],
        [110.601, -8.1398],
        [110.6001, -8.1408],
        [110.5994, -8.1414],
        [110.599, -8.1419],
        [110.599, -8.1424],
        [110.5993, -8.1433],
        [110.5999, -8.1446],
        [110.6, -8.1453],
        [110.6001, -8.1453],
        [110.6007, -8.1453],
        [110.6014, -8.1454],
        [110.602, -8.1454],
        [110.6023, -8.1455],
        [110.6025, -8.1456],
        [110.6028, -8.1457],
        [110.6029, -8.1457],
        [110.6029, -8.1455],
        [110.6029, -8.1453],
        [110.6031, -8.1453],
        [110.6032, -8.1454],
        [110.6033, -8.1455],
        [110.6035, -8.1455],
        [110.6037, -8.1456],
        [110.6043, -8.1461],
        [110.6048, -8.1466],
        [110.6051, -8.1468],
        [110.6053, -8.1469],
        [110.6056, -8.1468],
        [110.6058, -8.1468],
        [110.606, -8.1467],
        [110.6062, -8.1467],
        [110.6063, -8.147],
        [110.6065, -8.1471],
        [110.6067, -8.1471],
        [110.607, -8.147],
        [110.6071, -8.147],
        [110.6077, -8.1473],
        [110.6081, -8.1474],
        [110.6083, -8.1476],
        [110.6089, -8.1468],
        [110.6096, -8.1462],
        [110.6101, -8.1455],
        [110.6107, -8.1448],
        [110.611, -8.1442],
        [110.6111, -8.1435],
        [110.6116, -8.143],
        [110.6121, -8.1421],
        [110.6124, -8.141],
        [110.6128, -8.1403],
        [110.6133, -8.1395],
        [110.6141, -8.1391],
        [110.6153, -8.1386],
        [110.6164, -8.1382],
        [110.6172, -8.1379],
        [110.6187, -8.1368],
        [110.6205, -8.1352],
        [110.6214, -8.1346],
        [110.622, -8.1338],
        [110.6224, -8.1331],
        [110.6228, -8.1306],
        [110.6231, -8.129],
        [110.6234, -8.1285],
        [110.6237, -8.1271],
        [110.6241, -8.1261],
        [110.6244, -8.1255],
        [110.6251, -8.1249],
        [110.626, -8.1243],
        [110.6271, -8.124],
        [110.628, -8.124],
        [110.6292, -8.1244],
        [110.6302, -8.1246],
        [110.6311, -8.1245],
        [110.632, -8.1241],
        [110.6328, -8.1233],
        [110.6337, -8.1222],
        [110.6344, -8.121],
        [110.6346, -8.1198],
        [110.6346, -8.1179],
        [110.6345, -8.1166],
        [110.6346, -8.1158],
        [110.6351, -8.1146],
        [110.6353, -8.1139],
        [110.6351, -8.1122],
        [110.6351, -8.112],
        [110.6351, -8.1114],
        [110.6351, -8.1102],
        [110.6351, -8.1096],
        [110.6348, -8.1086],
        [110.6349, -8.1077],
        [110.6351, -8.1067],
        [110.6352, -8.1058],
        [110.6351, -8.1051],
        [110.6348, -8.1046],
        [110.6344, -8.1043],
        [110.6338, -8.1034],
        [110.6333, -8.103],
        [110.6323, -8.1026],
        [110.631, -8.1026],
        [110.6308, -8.1022],
        [110.6305, -8.1016],
        [110.6305, -8.0999],
        [110.6305, -8.0983],
        [110.6306, -8.0976],
        [110.631, -8.0971],
        [110.6321, -8.0963],
        [110.6331, -8.0958],
        [110.6341, -8.0957],
        [110.6345, -8.0956],
        [110.6348, -8.0953],
        [110.6352, -8.0951],
        [110.6364, -8.0941],
        [110.6376, -8.0932],
        [110.6382, -8.0928],
        [110.6386, -8.0926],
        [110.6389, -8.0926],
        [110.6394, -8.0928],
        [110.6398, -8.093],
        [110.6403, -8.0928],
        [110.6413, -8.0927],
        [110.6418, -8.0925],
        [110.6431, -8.0925],
        [110.644, -8.0925],
        [110.6451, -8.0922],
        [110.6455, -8.0921],
        [110.646, -8.0916],
        [110.6466, -8.0909],
        [110.6472, -8.0903],
        [110.647, -8.0894],
        [110.6465, -8.0885],
        [110.6456, -8.0878],
        [110.6452, -8.0871],
        [110.6448, -8.0854],
        [110.6448, -8.0849],
        [110.6446, -8.0845],
        [110.6437, -8.0841],
        [110.6432, -8.084],
        [110.6427, -8.0842],
        [110.6423, -8.0845],
        [110.6419, -8.0855],
        [110.6416, -8.086],
        [110.641, -8.086],
        [110.6399, -8.086],
        [110.6391, -8.0858],
        [110.6385, -8.0856],
        [110.6383, -8.0851],
        [110.6379, -8.0839],
        [110.6377, -8.0836],
        [110.6369, -8.083],
        [110.636, -8.0824],
        [110.6351, -8.082],
        [110.6343, -8.0817],
        [110.6334, -8.0819],
        [110.6324, -8.0818],
        [110.6322, -8.0817],
        [110.6319, -8.0816],
        [110.6315, -8.0811],
        [110.6314, -8.0809],
        [110.6308, -8.0796],
        [110.6303, -8.0785],
        [110.6301, -8.0783]
      ]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TEPUS',
    village: 'TEPUS',
    border: [
      [110.6643, -8.1235],
      [110.6636, -8.1236],
      [110.663, -8.1236],
      [110.662, -8.1228],
      [110.6612, -8.1223],
      [110.6601, -8.1219],
      [110.6594, -8.1218],
      [110.6588, -8.1215],
      [110.6584, -8.1213],
      [110.6581, -8.1209],
      [110.6576, -8.1199],
      [110.6573, -8.1195],
      [110.6572, -8.1184],
      [110.657, -8.1176],
      [110.6569, -8.1169],
      [110.6567, -8.1155],
      [110.6566, -8.1138],
      [110.6561, -8.1123],
      [110.6556, -8.1115],
      [110.6548, -8.1102],
      [110.6546, -8.1097],
      [110.6543, -8.1096],
      [110.6529, -8.11],
      [110.6517, -8.1102],
      [110.6508, -8.11],
      [110.6502, -8.1097],
      [110.6489, -8.1092],
      [110.648, -8.1084],
      [110.6466, -8.1073],
      [110.6461, -8.1064],
      [110.6459, -8.1059],
      [110.6463, -8.1051],
      [110.6469, -8.1044],
      [110.6474, -8.1037],
      [110.6482, -8.1027],
      [110.6485, -8.1021],
      [110.6489, -8.1004],
      [110.6491, -8.0995],
      [110.6495, -8.0988],
      [110.6496, -8.0973],
      [110.6498, -8.0967],
      [110.6501, -8.0951],
      [110.65, -8.0938],
      [110.6494, -8.0925],
      [110.6488, -8.092],
      [110.6478, -8.0914],
      [110.6475, -8.0911],
      [110.6473, -8.0904],
      [110.6472, -8.0903],
      [110.6466, -8.0909],
      [110.646, -8.0916],
      [110.6455, -8.0921],
      [110.6451, -8.0922],
      [110.644, -8.0925],
      [110.6431, -8.0925],
      [110.6418, -8.0925],
      [110.6413, -8.0927],
      [110.6403, -8.0928],
      [110.6398, -8.093],
      [110.6394, -8.0928],
      [110.6389, -8.0926],
      [110.6386, -8.0926],
      [110.6382, -8.0928],
      [110.6376, -8.0932],
      [110.6364, -8.0941],
      [110.6352, -8.0951],
      [110.6348, -8.0953],
      [110.6345, -8.0956],
      [110.6341, -8.0957],
      [110.6331, -8.0958],
      [110.6321, -8.0963],
      [110.631, -8.0971],
      [110.6306, -8.0976],
      [110.6305, -8.0983],
      [110.6305, -8.0999],
      [110.6305, -8.1016],
      [110.6308, -8.1022],
      [110.631, -8.1026],
      [110.6323, -8.1026],
      [110.6333, -8.103],
      [110.6338, -8.1034],
      [110.6344, -8.1043],
      [110.6348, -8.1046],
      [110.6351, -8.1051],
      [110.6352, -8.1058],
      [110.6351, -8.1067],
      [110.6349, -8.1077],
      [110.6348, -8.1086],
      [110.6351, -8.1096],
      [110.6351, -8.1102],
      [110.6351, -8.1114],
      [110.6351, -8.112],
      [110.6351, -8.1122],
      [110.6353, -8.1139],
      [110.6351, -8.1146],
      [110.6346, -8.1158],
      [110.6345, -8.1166],
      [110.6346, -8.1179],
      [110.6346, -8.1198],
      [110.6344, -8.121],
      [110.6337, -8.1222],
      [110.6328, -8.1233],
      [110.632, -8.1241],
      [110.6311, -8.1245],
      [110.6302, -8.1246],
      [110.6292, -8.1244],
      [110.628, -8.124],
      [110.6271, -8.124],
      [110.626, -8.1243],
      [110.6251, -8.1249],
      [110.6244, -8.1255],
      [110.6241, -8.1261],
      [110.6237, -8.1271],
      [110.6234, -8.1285],
      [110.6231, -8.129],
      [110.6228, -8.1306],
      [110.6224, -8.1331],
      [110.622, -8.1338],
      [110.6214, -8.1346],
      [110.6205, -8.1352],
      [110.6187, -8.1368],
      [110.6172, -8.1379],
      [110.6164, -8.1382],
      [110.6153, -8.1386],
      [110.6141, -8.1391],
      [110.6133, -8.1395],
      [110.6128, -8.1403],
      [110.6124, -8.141],
      [110.6121, -8.1421],
      [110.6116, -8.143],
      [110.6111, -8.1435],
      [110.611, -8.1442],
      [110.6107, -8.1448],
      [110.6101, -8.1455],
      [110.6096, -8.1462],
      [110.6089, -8.1468],
      [110.6083, -8.1476],
      [110.6085, -8.1477],
      [110.6086, -8.1478],
      [110.6086, -8.1479],
      [110.6086, -8.1481],
      [110.6086, -8.1483],
      [110.6087, -8.1485],
      [110.609, -8.1486],
      [110.6091, -8.1487],
      [110.6095, -8.1491],
      [110.6097, -8.1494],
      [110.6101, -8.1496],
      [110.6105, -8.1498],
      [110.6108, -8.1499],
      [110.6111, -8.15],
      [110.6112, -8.1501],
      [110.6112, -8.1504],
      [110.6113, -8.1504],
      [110.6116, -8.1504],
      [110.6119, -8.1506],
      [110.6124, -8.1508],
      [110.6127, -8.151],
      [110.6132, -8.1512],
      [110.6132, -8.1513],
      [110.6133, -8.1515],
      [110.6131, -8.1516],
      [110.613, -8.1517],
      [110.6131, -8.1519],
      [110.6133, -8.1521],
      [110.6135, -8.1522],
      [110.6138, -8.1523],
      [110.614, -8.1523],
      [110.6142, -8.1523],
      [110.6143, -8.1522],
      [110.6144, -8.1521],
      [110.6146, -8.1521],
      [110.6148, -8.1523],
      [110.6151, -8.1524],
      [110.6153, -8.1525],
      [110.6155, -8.1526],
      [110.6156, -8.1526],
      [110.6158, -8.1527],
      [110.6158, -8.1529],
      [110.616, -8.1532],
      [110.6162, -8.1533],
      [110.6164, -8.1535],
      [110.6167, -8.1535],
      [110.6169, -8.1537],
      [110.6173, -8.1538],
      [110.6177, -8.1538],
      [110.6178, -8.1539],
      [110.6181, -8.1541],
      [110.6185, -8.1543],
      [110.6189, -8.1544],
      [110.6192, -8.1545],
      [110.6197, -8.1546],
      [110.6201, -8.1547],
      [110.6209, -8.155],
      [110.6213, -8.1552],
      [110.6218, -8.1556],
      [110.6223, -8.156],
      [110.6225, -8.1562],
      [110.6226, -8.1563],
      [110.6226, -8.1566],
      [110.6226, -8.1568],
      [110.6228, -8.1569],
      [110.623, -8.157],
      [110.6231, -8.157],
      [110.6232, -8.1571],
      [110.6235, -8.1574],
      [110.6238, -8.1575],
      [110.6241, -8.1577],
      [110.6242, -8.1577],
      [110.6244, -8.1577],
      [110.6247, -8.1579],
      [110.6249, -8.158],
      [110.625, -8.1583],
      [110.6252, -8.1584],
      [110.6254, -8.1584],
      [110.6256, -8.1585],
      [110.6257, -8.1585],
      [110.6259, -8.1586],
      [110.6262, -8.1586],
      [110.6264, -8.1586],
      [110.6266, -8.1586],
      [110.6267, -8.1586],
      [110.6269, -8.1588],
      [110.6269, -8.159],
      [110.6271, -8.159],
      [110.6275, -8.1591],
      [110.628, -8.1592],
      [110.6287, -8.1594],
      [110.6289, -8.1595],
      [110.6292, -8.1596],
      [110.6295, -8.1596],
      [110.6299, -8.1596],
      [110.6302, -8.1597],
      [110.6306, -8.1599],
      [110.6308, -8.16],
      [110.6311, -8.16],
      [110.6315, -8.1601],
      [110.6319, -8.1601],
      [110.6321, -8.1602],
      [110.6324, -8.1603],
      [110.6327, -8.1605],
      [110.6331, -8.1606],
      [110.6333, -8.1608],
      [110.6334, -8.1608],
      [110.6335, -8.161],
      [110.6336, -8.1611],
      [110.6338, -8.161],
      [110.634, -8.161],
      [110.6342, -8.1611],
      [110.6344, -8.1612],
      [110.6345, -8.1612],
      [110.6347, -8.1613],
      [110.635, -8.1614],
      [110.6353, -8.1615],
      [110.6355, -8.1615],
      [110.6357, -8.1615],
      [110.6359, -8.1618],
      [110.6361, -8.1619],
      [110.6363, -8.162],
      [110.6365, -8.162],
      [110.6366, -8.162],
      [110.6368, -8.162],
      [110.6368, -8.1624],
      [110.6368, -8.1627],
      [110.6368, -8.163],
      [110.637, -8.1632],
      [110.6372, -8.1632],
      [110.6373, -8.1633],
      [110.6376, -8.1633],
      [110.6379, -8.1633],
      [110.6381, -8.1632],
      [110.6384, -8.1632],
      [110.6387, -8.1634],
      [110.6389, -8.1636],
      [110.639, -8.1638],
      [110.6392, -8.164],
      [110.6394, -8.1643],
      [110.6396, -8.1647],
      [110.6398, -8.1649],
      [110.6401, -8.1649],
      [110.6403, -8.1649],
      [110.6405, -8.1649],
      [110.6407, -8.1649],
      [110.6409, -8.1649],
      [110.6411, -8.165],
      [110.6413, -8.1653],
      [110.6414, -8.1655],
      [110.6414, -8.1657],
      [110.6414, -8.1658],
      [110.6416, -8.1659],
      [110.6418, -8.166],
      [110.6419, -8.166],
      [110.642, -8.166],
      [110.6422, -8.166],
      [110.6423, -8.166],
      [110.6425, -8.166],
      [110.6426, -8.1662],
      [110.6426, -8.1663],
      [110.6428, -8.1664],
      [110.643, -8.1664],
      [110.6429, -8.1667],
      [110.6428, -8.1668],
      [110.6427, -8.1669],
      [110.6427, -8.167],
      [110.6429, -8.1671],
      [110.6431, -8.1671],
      [110.6433, -8.1671],
      [110.6435, -8.1673],
      [110.6437, -8.1675],
      [110.6437, -8.1679],
      [110.6438, -8.1681],
      [110.6439, -8.1682],
      [110.6442, -8.1683],
      [110.6444, -8.1685],
      [110.6445, -8.1685],
      [110.6448, -8.1684],
      [110.645, -8.1682],
      [110.6453, -8.1682],
      [110.6457, -8.1681],
      [110.6461, -8.1681],
      [110.6463, -8.1681],
      [110.6463, -8.1683],
      [110.6465, -8.1683],
      [110.6468, -8.1683],
      [110.6472, -8.1685],
      [110.6475, -8.1688],
      [110.6476, -8.1691],
      [110.6478, -8.1694],
      [110.648, -8.1696],
      [110.6482, -8.1697],
      [110.6484, -8.1696],
      [110.6485, -8.1695],
      [110.6487, -8.1693],
      [110.6489, -8.1691],
      [110.6492, -8.169],
      [110.6496, -8.169],
      [110.6498, -8.169],
      [110.6501, -8.1692],
      [110.6501, -8.1694],
      [110.6501, -8.1696],
      [110.6503, -8.1697],
      [110.6505, -8.1697],
      [110.6505, -8.1696],
      [110.6506, -8.1695],
      [110.6508, -8.1695],
      [110.6511, -8.1697],
      [110.6515, -8.17],
      [110.6517, -8.1701],
      [110.6521, -8.1701],
      [110.6523, -8.1701],
      [110.6524, -8.1701],
      [110.6525, -8.1701],
      [110.653, -8.1698],
      [110.6533, -8.1692],
      [110.653, -8.1677],
      [110.6527, -8.1668],
      [110.6529, -8.1661],
      [110.6533, -8.1656],
      [110.6533, -8.165],
      [110.6531, -8.1641],
      [110.6533, -8.163],
      [110.6542, -8.1618],
      [110.6556, -8.1599],
      [110.6558, -8.1597],
      [110.6565, -8.1589],
      [110.6567, -8.1585],
      [110.658, -8.1547],
      [110.659, -8.1518],
      [110.6595, -8.1505],
      [110.6598, -8.1499],
      [110.66, -8.1499],
      [110.6603, -8.1497],
      [110.6613, -8.1497],
      [110.6622, -8.15],
      [110.663, -8.1504],
      [110.6634, -8.1504],
      [110.6638, -8.1499],
      [110.6641, -8.1494],
      [110.6647, -8.1495],
      [110.6657, -8.1493],
      [110.6664, -8.149],
      [110.667, -8.1486],
      [110.6674, -8.1479],
      [110.6685, -8.1469],
      [110.6707, -8.1448],
      [110.6709, -8.1444],
      [110.6711, -8.1435],
      [110.6713, -8.1425],
      [110.6712, -8.1415],
      [110.6711, -8.1411],
      [110.6714, -8.1407],
      [110.6717, -8.1404],
      [110.6717, -8.1392],
      [110.6715, -8.1387],
      [110.671, -8.1381],
      [110.6708, -8.1374],
      [110.6679, -8.1354],
      [110.6671, -8.1345],
      [110.6668, -8.1338],
      [110.6667, -8.1324],
      [110.6668, -8.1314],
      [110.667, -8.1302],
      [110.6669, -8.1285],
      [110.6669, -8.1265],
      [110.6668, -8.1254],
      [110.6665, -8.1251],
      [110.6663, -8.1247],
      [110.6653, -8.1244],
      [110.6646, -8.1238],
      [110.6643, -8.1235]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TEPUS',
    village: 'PURWODADI',
    border: [
      [
        [110.6813, -8.1826],
        [110.6815, -8.1825],
        [110.6819, -8.1825],
        [110.6823, -8.1823],
        [110.6823, -8.1822],
        [110.6824, -8.182],
        [110.6825, -8.1819],
        [110.6827, -8.1819],
        [110.6833, -8.1821],
        [110.6835, -8.1822],
        [110.6838, -8.1824],
        [110.684, -8.1826],
        [110.684, -8.1828],
        [110.684, -8.1831],
        [110.684, -8.1832],
        [110.6839, -8.1833],
        [110.6837, -8.1833],
        [110.6836, -8.1833],
        [110.6836, -8.1834],
        [110.6837, -8.1836],
        [110.6838, -8.1838],
        [110.684, -8.184],
        [110.684, -8.1841],
        [110.6838, -8.1842],
        [110.6838, -8.1844],
        [110.6839, -8.1845],
        [110.6841, -8.1845],
        [110.6844, -8.1844],
        [110.6847, -8.1844],
        [110.6849, -8.1844],
        [110.6851, -8.1845],
        [110.6852, -8.1846],
        [110.6855, -8.1847],
        [110.6858, -8.1847],
        [110.6861, -8.1847],
        [110.6862, -8.1848],
        [110.6864, -8.185],
        [110.6864, -8.1851],
        [110.6864, -8.1852],
        [110.6863, -8.1853],
        [110.6863, -8.1855],
        [110.6864, -8.1855],
        [110.6866, -8.1855],
        [110.6869, -8.1854],
        [110.6873, -8.1853],
        [110.6876, -8.1853],
        [110.6877, -8.1851],
        [110.6879, -8.185],
        [110.6882, -8.185],
        [110.6886, -8.1849],
        [110.689, -8.1849],
        [110.6892, -8.1849],
        [110.6892, -8.1846],
        [110.689, -8.1844],
        [110.6888, -8.1842],
        [110.6888, -8.1838],
        [110.6888, -8.1832],
        [110.6888, -8.1828],
        [110.6887, -8.1826],
        [110.6885, -8.1822],
        [110.6886, -8.1821],
        [110.6887, -8.182],
        [110.6888, -8.1818],
        [110.6886, -8.1815],
        [110.6886, -8.1815],
        [110.6885, -8.1813],
        [110.6887, -8.1811],
        [110.6888, -8.1811],
        [110.6887, -8.1806],
        [110.6887, -8.1803],
        [110.689, -8.1803],
        [110.6891, -8.1802],
        [110.6892, -8.1801],
        [110.6895, -8.1798],
        [110.6898, -8.1795],
        [110.6901, -8.1793],
        [110.6905, -8.179],
        [110.6908, -8.1787],
        [110.6911, -8.1785],
        [110.6913, -8.1781],
        [110.6914, -8.1777],
        [110.6914, -8.1774],
        [110.6914, -8.177],
        [110.6915, -8.1763],
        [110.6916, -8.1756],
        [110.6917, -8.1749],
        [110.6918, -8.1741],
        [110.6918, -8.1735],
        [110.692, -8.1728],
        [110.6922, -8.1722],
        [110.6927, -8.1715],
        [110.6931, -8.1713],
        [110.6938, -8.171],
        [110.6945, -8.1708],
        [110.6947, -8.1703],
        [110.6949, -8.1693],
        [110.6952, -8.1682],
        [110.6957, -8.1673],
        [110.696, -8.1665],
        [110.6963, -8.1661],
        [110.6963, -8.1655],
        [110.6962, -8.165],
        [110.6961, -8.1647],
        [110.696, -8.1642],
        [110.6956, -8.1639],
        [110.6937, -8.1634],
        [110.6921, -8.163],
        [110.6917, -8.1626],
        [110.6915, -8.1623],
        [110.6914, -8.1619],
        [110.6918, -8.1612],
        [110.6922, -8.1605],
        [110.6926, -8.1598],
        [110.6931, -8.159],
        [110.6933, -8.1585],
        [110.6932, -8.1579],
        [110.6928, -8.1566],
        [110.6925, -8.1553],
        [110.6926, -8.1542],
        [110.6928, -8.153],
        [110.693, -8.1524],
        [110.6931, -8.1517],
        [110.6931, -8.1509],
        [110.693, -8.1499],
        [110.6929, -8.1496],
        [110.6929, -8.1492],
        [110.6928, -8.1485],
        [110.6927, -8.1476],
        [110.6925, -8.1466],
        [110.6924, -8.1458],
        [110.6924, -8.1455],
        [110.6927, -8.1451],
        [110.6928, -8.1445],
        [110.6928, -8.144],
        [110.693, -8.1435],
        [110.6934, -8.1428],
        [110.6935, -8.1423],
        [110.6937, -8.1419],
        [110.6942, -8.1418],
        [110.6945, -8.1415],
        [110.6949, -8.1411],
        [110.6952, -8.1407],
        [110.6953, -8.1404],
        [110.6951, -8.1399],
        [110.6948, -8.1394],
        [110.6948, -8.1391],
        [110.695, -8.1385],
        [110.6954, -8.138],
        [110.6957, -8.1374],
        [110.6956, -8.1366],
        [110.6958, -8.136],
        [110.6961, -8.1357],
        [110.6964, -8.1354],
        [110.6968, -8.1352],
        [110.6974, -8.1345],
        [110.6977, -8.134],
        [110.698, -8.1335],
        [110.698, -8.1331],
        [110.6976, -8.1325],
        [110.6973, -8.1318],
        [110.697, -8.1312],
        [110.6969, -8.1307],
        [110.6969, -8.1302],
        [110.697, -8.1297],
        [110.6973, -8.1293],
        [110.6975, -8.1288],
        [110.6976, -8.1284],
        [110.6976, -8.1279],
        [110.6975, -8.1273],
        [110.6974, -8.1268],
        [110.6974, -8.1266],
        [110.6974, -8.1263],
        [110.6979, -8.1255],
        [110.6985, -8.1248],
        [110.6991, -8.1241],
        [110.6995, -8.1237],
        [110.6991, -8.1237],
        [110.6985, -8.1235],
        [110.6979, -8.1232],
        [110.6974, -8.1225],
        [110.6973, -8.122],
        [110.6969, -8.1216],
        [110.6964, -8.1214],
        [110.6953, -8.1212],
        [110.6948, -8.121],
        [110.6943, -8.1207],
        [110.6939, -8.1207],
        [110.6931, -8.1209],
        [110.6927, -8.1209],
        [110.692, -8.1208],
        [110.6913, -8.1209],
        [110.6909, -8.1211],
        [110.6902, -8.122],
        [110.689, -8.1235],
        [110.6883, -8.1246],
        [110.6876, -8.1252],
        [110.6876, -8.1257],
        [110.6871, -8.1263],
        [110.6867, -8.1267],
        [110.6861, -8.1267],
        [110.6853, -8.1269],
        [110.6848, -8.1274],
        [110.6841, -8.128],
        [110.6829, -8.1283],
        [110.6812, -8.1286],
        [110.6801, -8.1284],
        [110.6771, -8.1279],
        [110.6752, -8.1279],
        [110.6741, -8.128],
        [110.6725, -8.1281],
        [110.6718, -8.1285],
        [110.671, -8.1291],
        [110.6671, -8.1285],
        [110.6669, -8.1285],
        [110.667, -8.1302],
        [110.6668, -8.1314],
        [110.6667, -8.1324],
        [110.6668, -8.1338],
        [110.6671, -8.1345],
        [110.6679, -8.1354],
        [110.6708, -8.1374],
        [110.671, -8.1381],
        [110.6715, -8.1387],
        [110.6717, -8.1392],
        [110.6717, -8.1404],
        [110.6714, -8.1407],
        [110.6711, -8.1411],
        [110.6712, -8.1415],
        [110.6713, -8.1425],
        [110.6711, -8.1435],
        [110.6709, -8.1444],
        [110.6707, -8.1448],
        [110.6685, -8.1469],
        [110.6674, -8.1479],
        [110.667, -8.1486],
        [110.6664, -8.149],
        [110.6657, -8.1493],
        [110.6647, -8.1495],
        [110.6641, -8.1494],
        [110.6638, -8.1499],
        [110.6634, -8.1504],
        [110.663, -8.1504],
        [110.6622, -8.15],
        [110.6613, -8.1497],
        [110.6603, -8.1497],
        [110.66, -8.1499],
        [110.6598, -8.1499],
        [110.6595, -8.1505],
        [110.659, -8.1518],
        [110.658, -8.1547],
        [110.6567, -8.1585],
        [110.6565, -8.1589],
        [110.6558, -8.1597],
        [110.6556, -8.1599],
        [110.6542, -8.1618],
        [110.6533, -8.163],
        [110.6531, -8.1641],
        [110.6533, -8.165],
        [110.6533, -8.1656],
        [110.6529, -8.1661],
        [110.6527, -8.1668],
        [110.653, -8.1677],
        [110.6533, -8.1692],
        [110.653, -8.1698],
        [110.6525, -8.1701],
        [110.6526, -8.1702],
        [110.6526, -8.1704],
        [110.6526, -8.1705],
        [110.6524, -8.1707],
        [110.6522, -8.1708],
        [110.652, -8.1709],
        [110.6519, -8.171],
        [110.6517, -8.1712],
        [110.6517, -8.1714],
        [110.6517, -8.1715],
        [110.6517, -8.1716],
        [110.6519, -8.1716],
        [110.652, -8.1716],
        [110.6521, -8.1715],
        [110.6522, -8.1715],
        [110.6524, -8.1715],
        [110.6526, -8.1715],
        [110.6526, -8.1716],
        [110.6526, -8.1716],
        [110.6527, -8.1718],
        [110.6527, -8.1719],
        [110.6529, -8.1719],
        [110.6531, -8.172],
        [110.6534, -8.172],
        [110.6535, -8.1721],
        [110.6536, -8.1723],
        [110.6538, -8.1725],
        [110.654, -8.1725],
        [110.6542, -8.1726],
        [110.6542, -8.1728],
        [110.6542, -8.1731],
        [110.6542, -8.1732],
        [110.6545, -8.1732],
        [110.6548, -8.1732],
        [110.655, -8.1731],
        [110.6553, -8.1731],
        [110.6555, -8.1731],
        [110.6556, -8.1732],
        [110.6558, -8.1734],
        [110.656, -8.1735],
        [110.6562, -8.1736],
        [110.6564, -8.1737],
        [110.6566, -8.1737],
        [110.6568, -8.1735],
        [110.657, -8.1733],
        [110.6573, -8.173],
        [110.6577, -8.1727],
        [110.6579, -8.1726],
        [110.6583, -8.1726],
        [110.6587, -8.1727],
        [110.6592, -8.1729],
        [110.6598, -8.1732],
        [110.66, -8.1735],
        [110.6602, -8.1739],
        [110.6603, -8.1741],
        [110.6603, -8.1749],
        [110.6604, -8.1751],
        [110.6606, -8.1753],
        [110.6608, -8.1753],
        [110.661, -8.1752],
        [110.6612, -8.175],
        [110.6614, -8.1749],
        [110.6616, -8.1748],
        [110.6618, -8.1748],
        [110.6619, -8.175],
        [110.6618, -8.1752],
        [110.6618, -8.1754],
        [110.6618, -8.1757],
        [110.6618, -8.176],
        [110.6618, -8.1762],
        [110.662, -8.1764],
        [110.6622, -8.1767],
        [110.6625, -8.1767],
        [110.6627, -8.1766],
        [110.6629, -8.1764],
        [110.663, -8.1763],
        [110.663, -8.1761],
        [110.6631, -8.1759],
        [110.6633, -8.1758],
        [110.6635, -8.1758],
        [110.6636, -8.1758],
        [110.6638, -8.1759],
        [110.6638, -8.1762],
        [110.6639, -8.1765],
        [110.664, -8.1767],
        [110.6642, -8.1768],
        [110.6644, -8.1769],
        [110.6646, -8.177],
        [110.6649, -8.177],
        [110.6652, -8.1771],
        [110.6656, -8.1771],
        [110.666, -8.1771],
        [110.6663, -8.177],
        [110.6665, -8.177],
        [110.6666, -8.1769],
        [110.6667, -8.1768],
        [110.6669, -8.1767],
        [110.6672, -8.1766],
        [110.6675, -8.1766],
        [110.6677, -8.1766],
        [110.6678, -8.1766],
        [110.6679, -8.1766],
        [110.6681, -8.1767],
        [110.6683, -8.1767],
        [110.6685, -8.1767],
        [110.6686, -8.1768],
        [110.6687, -8.1768],
        [110.6689, -8.1769],
        [110.6691, -8.1773],
        [110.6692, -8.1775],
        [110.6694, -8.1776],
        [110.6696, -8.178],
        [110.6697, -8.1781],
        [110.67, -8.1781],
        [110.6702, -8.1781],
        [110.6704, -8.178],
        [110.6706, -8.178],
        [110.6709, -8.1783],
        [110.6712, -8.1786],
        [110.6714, -8.1788],
        [110.6715, -8.179],
        [110.6715, -8.1794],
        [110.6717, -8.1795],
        [110.6718, -8.1796],
        [110.672, -8.1795],
        [110.6721, -8.1793],
        [110.6723, -8.1791],
        [110.6725, -8.179],
        [110.6728, -8.1791],
        [110.6731, -8.1793],
        [110.6732, -8.1796],
        [110.6734, -8.1799],
        [110.6735, -8.1799],
        [110.6737, -8.1801],
        [110.6738, -8.1803],
        [110.6738, -8.1804],
        [110.6739, -8.1805],
        [110.6737, -8.1807],
        [110.6736, -8.1809],
        [110.6736, -8.1812],
        [110.6737, -8.1813],
        [110.6737, -8.1815],
        [110.6739, -8.1816],
        [110.6741, -8.1814],
        [110.6742, -8.1813],
        [110.6744, -8.1811],
        [110.6746, -8.1808],
        [110.6747, -8.1806],
        [110.6749, -8.1806],
        [110.6751, -8.1806],
        [110.6752, -8.1806],
        [110.6754, -8.1807],
        [110.6755, -8.1808],
        [110.6757, -8.1808],
        [110.6758, -8.1807],
        [110.6761, -8.1806],
        [110.6764, -8.1806],
        [110.6766, -8.1807],
        [110.6767, -8.181],
        [110.6769, -8.1813],
        [110.6769, -8.1815],
        [110.677, -8.1817],
        [110.6772, -8.1819],
        [110.6773, -8.182],
        [110.6775, -8.1821],
        [110.6777, -8.1822],
        [110.6779, -8.1823],
        [110.6781, -8.1823],
        [110.6783, -8.1823],
        [110.6785, -8.1822],
        [110.6786, -8.1821],
        [110.6787, -8.182],
        [110.6789, -8.182],
        [110.679, -8.1822],
        [110.6791, -8.1823],
        [110.6792, -8.1824],
        [110.6792, -8.1826],
        [110.6792, -8.1828],
        [110.6792, -8.1829],
        [110.6793, -8.183],
        [110.6794, -8.183],
        [110.6796, -8.183],
        [110.6797, -8.1828],
        [110.6798, -8.1827],
        [110.68, -8.1827],
        [110.6802, -8.1827],
        [110.6804, -8.1827],
        [110.6808, -8.1827],
        [110.6812, -8.1826],
        [110.6813, -8.1826]
      ]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMANU',
    village: 'NGEPOSARI',
    border: [
      [110.683, -7.9805],
      [110.6828, -7.9804],
      [110.6822, -7.9803],
      [110.6817, -7.9802],
      [110.6809, -7.98],
      [110.6804, -7.98],
      [110.6803, -7.9797],
      [110.6804, -7.9796],
      [110.6805, -7.9794],
      [110.6805, -7.9792],
      [110.6805, -7.9791],
      [110.6805, -7.9788],
      [110.6794, -7.9784],
      [110.6792, -7.9782],
      [110.6792, -7.9778],
      [110.6792, -7.9775],
      [110.6789, -7.9772],
      [110.6784, -7.9769],
      [110.6779, -7.977],
      [110.6765, -7.9765],
      [110.6761, -7.9758],
      [110.6757, -7.9757],
      [110.6757, -7.9756],
      [110.6756, -7.9755],
      [110.6747, -7.9749],
      [110.6744, -7.975],
      [110.674, -7.9749],
      [110.6735, -7.9753],
      [110.6735, -7.9754],
      [110.6727, -7.9751],
      [110.6726, -7.9752],
      [110.6725, -7.9753],
      [110.6724, -7.9754],
      [110.6719, -7.9753],
      [110.6717, -7.9754],
      [110.6714, -7.9755],
      [110.6711, -7.9757],
      [110.6707, -7.9758],
      [110.6705, -7.9758],
      [110.6698, -7.9755],
      [110.6694, -7.9756],
      [110.669, -7.9759],
      [110.6685, -7.9758],
      [110.6681, -7.9759],
      [110.6679, -7.976],
      [110.6674, -7.976],
      [110.667, -7.976],
      [110.6665, -7.9762],
      [110.6663, -7.9768],
      [110.6662, -7.9778],
      [110.6659, -7.9796],
      [110.6655, -7.9825],
      [110.6657, -7.9827],
      [110.6657, -7.9834],
      [110.6657, -7.984],
      [110.6654, -7.9853],
      [110.6652, -7.9862],
      [110.6648, -7.9874],
      [110.6641, -7.9894],
      [110.6637, -7.9901],
      [110.6635, -7.9911],
      [110.6632, -7.9915],
      [110.6627, -7.9919],
      [110.6621, -7.9923],
      [110.6617, -7.9928],
      [110.6617, -7.9935],
      [110.6618, -7.9949],
      [110.6619, -7.9963],
      [110.662, -7.9971],
      [110.6623, -7.9978],
      [110.6627, -7.9985],
      [110.6629, -7.9992],
      [110.6629, -7.9996],
      [110.663, -8.0002],
      [110.6631, -8.0017],
      [110.6632, -8.0043],
      [110.6633, -8.0081],
      [110.6634, -8.0099],
      [110.6629, -8.011],
      [110.6625, -8.0118],
      [110.6623, -8.0126],
      [110.662, -8.0134],
      [110.6608, -8.0152],
      [110.6595, -8.0173],
      [110.6579, -8.019],
      [110.6575, -8.0195],
      [110.6568, -8.0206],
      [110.6555, -8.0221],
      [110.6542, -8.0237],
      [110.6543, -8.0239],
      [110.6549, -8.0251],
      [110.655, -8.0258],
      [110.6553, -8.0268],
      [110.6561, -8.0283],
      [110.6566, -8.0287],
      [110.6571, -8.0289],
      [110.6581, -8.0289],
      [110.6598, -8.0287],
      [110.6602, -8.0288],
      [110.6616, -8.029],
      [110.6619, -8.029],
      [110.663, -8.0296],
      [110.6636, -8.0303],
      [110.664, -8.0312],
      [110.6643, -8.0323],
      [110.6649, -8.0336],
      [110.6656, -8.0355],
      [110.6657, -8.0366],
      [110.6659, -8.0374],
      [110.6663, -8.0381],
      [110.667, -8.0385],
      [110.6685, -8.039],
      [110.669, -8.039],
      [110.6699, -8.0389],
      [110.671, -8.0386],
      [110.6713, -8.0386],
      [110.6714, -8.0385],
      [110.6718, -8.0374],
      [110.6722, -8.0358],
      [110.6733, -8.0333],
      [110.6746, -8.0314],
      [110.6748, -8.0311],
      [110.6753, -8.03],
      [110.6756, -8.0294],
      [110.6761, -8.0289],
      [110.6764, -8.0287],
      [110.677, -8.0286],
      [110.6777, -8.0284],
      [110.6786, -8.0279],
      [110.6794, -8.0273],
      [110.6801, -8.0266],
      [110.6798, -8.0264],
      [110.6795, -8.0263],
      [110.6795, -8.0259],
      [110.6795, -8.0251],
      [110.6796, -8.0241],
      [110.6795, -8.0233],
      [110.6795, -8.0229],
      [110.6796, -8.0224],
      [110.6797, -8.022],
      [110.6797, -8.0213],
      [110.6797, -8.0208],
      [110.6794, -8.0202],
      [110.6791, -8.0193],
      [110.679, -8.0187],
      [110.6791, -8.018],
      [110.6792, -8.0172],
      [110.6795, -8.0165],
      [110.6801, -8.0159],
      [110.681, -8.0151],
      [110.6816, -8.0144],
      [110.6822, -8.0133],
      [110.6832, -8.0122],
      [110.6837, -8.0116],
      [110.6839, -8.0112],
      [110.6841, -8.0105],
      [110.6843, -8.01],
      [110.6843, -8.0095],
      [110.6842, -8.0091],
      [110.6837, -8.0084],
      [110.6833, -8.008],
      [110.6826, -8.0074],
      [110.6822, -8.0069],
      [110.6821, -8.0058],
      [110.682, -8.0047],
      [110.6819, -8.0043],
      [110.6819, -8.0041],
      [110.6814, -8.0032],
      [110.6805, -8.0026],
      [110.6798, -8.0019],
      [110.6796, -8.001],
      [110.6795, -8.0001],
      [110.68, -7.9996],
      [110.6816, -7.9985],
      [110.6829, -7.9973],
      [110.683, -7.9971],
      [110.6832, -7.9959],
      [110.6824, -7.9959],
      [110.6823, -7.9955],
      [110.6818, -7.9954],
      [110.6818, -7.9949],
      [110.6808, -7.995],
      [110.6808, -7.9954],
      [110.6803, -7.9953],
      [110.6801, -7.9955],
      [110.6796, -7.9955],
      [110.6795, -7.9961],
      [110.6792, -7.9961],
      [110.6792, -7.9963],
      [110.6784, -7.9961],
      [110.6778, -7.9959],
      [110.6775, -7.9959],
      [110.6777, -7.9941],
      [110.678, -7.9925],
      [110.6782, -7.9917],
      [110.6782, -7.9907],
      [110.6782, -7.9902],
      [110.6782, -7.99],
      [110.6782, -7.9893],
      [110.6783, -7.9888],
      [110.6783, -7.9887],
      [110.6783, -7.9884],
      [110.6783, -7.9882],
      [110.6782, -7.9876],
      [110.678, -7.9871],
      [110.677, -7.9863],
      [110.6766, -7.986],
      [110.6758, -7.9858],
      [110.6758, -7.985],
      [110.6759, -7.9848],
      [110.6766, -7.9849],
      [110.6771, -7.9846],
      [110.6775, -7.9846],
      [110.6778, -7.9842],
      [110.6778, -7.9836],
      [110.6773, -7.9833],
      [110.6772, -7.9832],
      [110.6768, -7.9827],
      [110.6772, -7.9816],
      [110.6775, -7.9808],
      [110.6787, -7.9812],
      [110.6793, -7.9813],
      [110.6792, -7.9825],
      [110.6798, -7.9828],
      [110.6798, -7.9829],
      [110.68, -7.9832],
      [110.681, -7.9829],
      [110.6817, -7.9828],
      [110.6821, -7.9826],
      [110.6823, -7.9821],
      [110.6825, -7.9813],
      [110.683, -7.9805]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMANU',
    village: 'SEMANU',
    border: [
      [110.6529, -7.972],
      [110.6525, -7.9718],
      [110.6506, -7.9714],
      [110.6504, -7.9717],
      [110.6487, -7.9716],
      [110.6471, -7.9713],
      [110.6439, -7.9711],
      [110.6423, -7.9711],
      [110.6419, -7.9719],
      [110.6414, -7.9729],
      [110.6413, -7.9734],
      [110.6414, -7.9739],
      [110.6416, -7.9746],
      [110.6414, -7.975],
      [110.641, -7.977],
      [110.6407, -7.9785],
      [110.6402, -7.9786],
      [110.6396, -7.9787],
      [110.6394, -7.9792],
      [110.6394, -7.9798],
      [110.6394, -7.9803],
      [110.6394, -7.9814],
      [110.6393, -7.9826],
      [110.6391, -7.9832],
      [110.6386, -7.9837],
      [110.6379, -7.984],
      [110.6375, -7.984],
      [110.636, -7.9836],
      [110.6357, -7.9835],
      [110.6351, -7.9828],
      [110.635, -7.9827],
      [110.6346, -7.9827],
      [110.6343, -7.9826],
      [110.6339, -7.9825],
      [110.6335, -7.9827],
      [110.6334, -7.9833],
      [110.633, -7.9832],
      [110.6327, -7.983],
      [110.6326, -7.9829],
      [110.6322, -7.9833],
      [110.6317, -7.9835],
      [110.6309, -7.9828],
      [110.6301, -7.9821],
      [110.63, -7.982],
      [110.6292, -7.9824],
      [110.6283, -7.9826],
      [110.6281, -7.9832],
      [110.6275, -7.9833],
      [110.6273, -7.9836],
      [110.6272, -7.9837],
      [110.6269, -7.9843],
      [110.6266, -7.9855],
      [110.6262, -7.9865],
      [110.6236, -7.9853],
      [110.6236, -7.9857],
      [110.6234, -7.9864],
      [110.6236, -7.9875],
      [110.6239, -7.988],
      [110.6245, -7.9886],
      [110.6246, -7.9894],
      [110.6243, -7.9908],
      [110.6241, -7.9918],
      [110.6236, -7.9921],
      [110.6234, -7.9922],
      [110.6242, -7.9927],
      [110.625, -7.9933],
      [110.6252, -7.9936],
      [110.6261, -7.9947],
      [110.6281, -7.9973],
      [110.6298, -7.9978],
      [110.6319, -7.9985],
      [110.6324, -7.9987],
      [110.633, -7.9989],
      [110.6332, -7.999],
      [110.6332, -7.9992],
      [110.6332, -7.9999],
      [110.633, -8.0],
      [110.6329, -8.0008],
      [110.633, -8.0017],
      [110.6336, -8.0028],
      [110.6338, -8.0032],
      [110.6351, -8.004],
      [110.6362, -8.0044],
      [110.6369, -8.0044],
      [110.6378, -8.0044],
      [110.6386, -8.0044],
      [110.6393, -8.0046],
      [110.6398, -8.0048],
      [110.6403, -8.0051],
      [110.6406, -8.0056],
      [110.6406, -8.0057],
      [110.6407, -8.0064],
      [110.6408, -8.0068],
      [110.6406, -8.007],
      [110.6405, -8.0074],
      [110.6402, -8.0077],
      [110.6397, -8.0081],
      [110.6394, -8.0084],
      [110.6399, -8.0084],
      [110.64, -8.0085],
      [110.64, -8.0089],
      [110.6398, -8.0092],
      [110.6394, -8.0095],
      [110.6392, -8.0104],
      [110.6389, -8.011],
      [110.6383, -8.0118],
      [110.6383, -8.0119],
      [110.6379, -8.0125],
      [110.6379, -8.0139],
      [110.6381, -8.0141],
      [110.6389, -8.0144],
      [110.64, -8.0147],
      [110.6403, -8.0154],
      [110.6403, -8.0159],
      [110.64, -8.0164],
      [110.6393, -8.0171],
      [110.6388, -8.0177],
      [110.6387, -8.0182],
      [110.6387, -8.0195],
      [110.6388, -8.0209],
      [110.6384, -8.0218],
      [110.6387, -8.0229],
      [110.639, -8.0243],
      [110.6398, -8.0251],
      [110.6402, -8.0259],
      [110.6405, -8.0257],
      [110.6416, -8.0256],
      [110.6422, -8.0257],
      [110.6426, -8.0262],
      [110.6432, -8.0268],
      [110.6438, -8.0269],
      [110.6448, -8.0263],
      [110.6467, -8.025],
      [110.6478, -8.0244],
      [110.6492, -8.0235],
      [110.6507, -8.0229],
      [110.652, -8.0228],
      [110.653, -8.023],
      [110.6539, -8.0232],
      [110.6542, -8.0237],
      [110.6555, -8.0221],
      [110.6568, -8.0206],
      [110.6575, -8.0195],
      [110.6579, -8.019],
      [110.6595, -8.0173],
      [110.6608, -8.0152],
      [110.662, -8.0134],
      [110.6623, -8.0126],
      [110.6625, -8.0118],
      [110.6629, -8.011],
      [110.6634, -8.0099],
      [110.6633, -8.0081],
      [110.6632, -8.0043],
      [110.6631, -8.0017],
      [110.663, -8.0002],
      [110.6629, -7.9996],
      [110.6629, -7.9992],
      [110.6627, -7.9985],
      [110.6623, -7.9978],
      [110.662, -7.9971],
      [110.6619, -7.9963],
      [110.6618, -7.9949],
      [110.6617, -7.9935],
      [110.6617, -7.9928],
      [110.6621, -7.9923],
      [110.6627, -7.9919],
      [110.6632, -7.9915],
      [110.6635, -7.9911],
      [110.6637, -7.9901],
      [110.6641, -7.9894],
      [110.6648, -7.9874],
      [110.6652, -7.9862],
      [110.6654, -7.9853],
      [110.6657, -7.984],
      [110.6657, -7.9834],
      [110.6657, -7.9827],
      [110.6655, -7.9825],
      [110.6652, -7.9824],
      [110.6644, -7.982],
      [110.6639, -7.9818],
      [110.6631, -7.9828],
      [110.6619, -7.9824],
      [110.6609, -7.9823],
      [110.6606, -7.982],
      [110.6615, -7.9822],
      [110.6615, -7.9821],
      [110.662, -7.9821],
      [110.6621, -7.9809],
      [110.662, -7.98],
      [110.6616, -7.9788],
      [110.6614, -7.9776],
      [110.6612, -7.9776],
      [110.6609, -7.9775],
      [110.6604, -7.9779],
      [110.6601, -7.9782],
      [110.6599, -7.9788],
      [110.6598, -7.9791],
      [110.6598, -7.9792],
      [110.6593, -7.9786],
      [110.6592, -7.9782],
      [110.6585, -7.9779],
      [110.6579, -7.9775],
      [110.6576, -7.9776],
      [110.6571, -7.9775],
      [110.6561, -7.9771],
      [110.6559, -7.9769],
      [110.6556, -7.9766],
      [110.6553, -7.9764],
      [110.6552, -7.9762],
      [110.6551, -7.9761],
      [110.6547, -7.9759],
      [110.6541, -7.9756],
      [110.6533, -7.9753],
      [110.6532, -7.9743],
      [110.6533, -7.974],
      [110.6533, -7.9737],
      [110.6533, -7.9736],
      [110.6531, -7.9732],
      [110.6532, -7.973],
      [110.6532, -7.9728],
      [110.6531, -7.972],
      [110.6529, -7.972]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMANU',
    village: 'PACAREJO',
    border: [
      [110.6402, -8.0259],
      [110.6398, -8.0251],
      [110.639, -8.0243],
      [110.6387, -8.0229],
      [110.6384, -8.0218],
      [110.6388, -8.0209],
      [110.6387, -8.0195],
      [110.6387, -8.0182],
      [110.6388, -8.0177],
      [110.6393, -8.0171],
      [110.64, -8.0164],
      [110.6403, -8.0159],
      [110.6403, -8.0154],
      [110.64, -8.0147],
      [110.6389, -8.0144],
      [110.6381, -8.0141],
      [110.6379, -8.0139],
      [110.6379, -8.0125],
      [110.6383, -8.0119],
      [110.6383, -8.0118],
      [110.6389, -8.011],
      [110.6392, -8.0104],
      [110.6394, -8.0095],
      [110.6398, -8.0092],
      [110.64, -8.0089],
      [110.64, -8.0085],
      [110.6399, -8.0084],
      [110.6394, -8.0084],
      [110.6397, -8.0081],
      [110.6402, -8.0077],
      [110.6405, -8.0074],
      [110.6406, -8.007],
      [110.6408, -8.0068],
      [110.6407, -8.0064],
      [110.6406, -8.0057],
      [110.6406, -8.0056],
      [110.6403, -8.0051],
      [110.6398, -8.0048],
      [110.6393, -8.0046],
      [110.6386, -8.0044],
      [110.6378, -8.0044],
      [110.6369, -8.0044],
      [110.6362, -8.0044],
      [110.6351, -8.004],
      [110.6338, -8.0032],
      [110.6336, -8.0028],
      [110.633, -8.0017],
      [110.6329, -8.0008],
      [110.633, -8.0],
      [110.6332, -7.9999],
      [110.6332, -7.9992],
      [110.6332, -7.999],
      [110.633, -7.9989],
      [110.6324, -7.9987],
      [110.6319, -7.9985],
      [110.6298, -7.9978],
      [110.6281, -7.9973],
      [110.6261, -7.9947],
      [110.6252, -7.9936],
      [110.625, -7.9933],
      [110.6242, -7.9927],
      [110.6234, -7.9922],
      [110.6229, -7.9926],
      [110.6202, -7.9939],
      [110.6191, -7.9944],
      [110.6167, -7.9953],
      [110.6161, -7.9959],
      [110.6144, -7.997],
      [110.6125, -7.9987],
      [110.6117, -7.9996],
      [110.6111, -8.0002],
      [110.6109, -8.0009],
      [110.6109, -8.0013],
      [110.6109, -8.0019],
      [110.6104, -8.0033],
      [110.6099, -8.0046],
      [110.6089, -8.0067],
      [110.608, -8.0085],
      [110.6077, -8.0089],
      [110.6075, -8.0089],
      [110.6071, -8.009],
      [110.6063, -8.0089],
      [110.6057, -8.0086],
      [110.6048, -8.0082],
      [110.6034, -8.008],
      [110.6031, -8.0078],
      [110.6023, -8.0076],
      [110.6017, -8.0072],
      [110.6011, -8.0069],
      [110.6002, -8.0068],
      [110.5991, -8.0065],
      [110.5983, -8.0062],
      [110.598, -8.0057],
      [110.5972, -8.0054],
      [110.5957, -8.0048],
      [110.5951, -8.0048],
      [110.5949, -8.005],
      [110.5945, -8.0052],
      [110.5943, -8.0057],
      [110.594, -8.006],
      [110.5937, -8.0063],
      [110.5936, -8.0065],
      [110.5932, -8.0065],
      [110.5928, -8.0068],
      [110.5925, -8.0071],
      [110.5922, -8.0073],
      [110.5919, -8.0082],
      [110.5923, -8.009],
      [110.5923, -8.0096],
      [110.5925, -8.0106],
      [110.5925, -8.0113],
      [110.5925, -8.0139],
      [110.5924, -8.017],
      [110.5921, -8.0212],
      [110.5919, -8.0228],
      [110.5916, -8.0243],
      [110.5916, -8.0244],
      [110.5912, -8.0265],
      [110.5912, -8.0283],
      [110.5913, -8.0288],
      [110.5913, -8.0289],
      [110.5913, -8.0291],
      [110.5914, -8.0291],
      [110.5915, -8.0292],
      [110.5915, -8.0297],
      [110.5914, -8.0299],
      [110.5916, -8.03],
      [110.5917, -8.0301],
      [110.5921, -8.0304],
      [110.5921, -8.0306],
      [110.5921, -8.0309],
      [110.5922, -8.031],
      [110.5927, -8.0313],
      [110.5926, -8.0316],
      [110.5926, -8.0318],
      [110.5924, -8.0321],
      [110.5925, -8.0324],
      [110.5925, -8.0327],
      [110.5923, -8.0331],
      [110.5921, -8.034],
      [110.592, -8.0343],
      [110.5919, -8.0344],
      [110.5916, -8.0346],
      [110.5911, -8.035],
      [110.5909, -8.0353],
      [110.5906, -8.036],
      [110.5904, -8.0368],
      [110.5904, -8.0378],
      [110.5901, -8.0382],
      [110.5895, -8.0393],
      [110.5901, -8.0397],
      [110.5909, -8.04],
      [110.5911, -8.0402],
      [110.5908, -8.0409],
      [110.5904, -8.0418],
      [110.5903, -8.0427],
      [110.5903, -8.0436],
      [110.5901, -8.0448],
      [110.5901, -8.0455],
      [110.5897, -8.046],
      [110.5891, -8.0465],
      [110.5889, -8.0471],
      [110.5892, -8.0481],
      [110.5891, -8.0488],
      [110.589, -8.0491],
      [110.5897, -8.0507],
      [110.5905, -8.052],
      [110.5908, -8.0526],
      [110.5907, -8.0535],
      [110.5909, -8.0546],
      [110.5908, -8.0554],
      [110.5907, -8.0569],
      [110.5907, -8.0586],
      [110.5918, -8.0581],
      [110.593, -8.0573],
      [110.5953, -8.0564],
      [110.5961, -8.0564],
      [110.5964, -8.0564],
      [110.5982, -8.0568],
      [110.5992, -8.0567],
      [110.6022, -8.0567],
      [110.6029, -8.0566],
      [110.6054, -8.0565],
      [110.6058, -8.0562],
      [110.6069, -8.056],
      [110.6071, -8.0559],
      [110.6074, -8.0554],
      [110.6075, -8.0547],
      [110.6073, -8.0536],
      [110.6071, -8.0528],
      [110.6065, -8.0524],
      [110.6059, -8.0519],
      [110.6054, -8.0513],
      [110.6054, -8.0502],
      [110.6053, -8.0494],
      [110.6057, -8.0484],
      [110.6061, -8.0476],
      [110.6078, -8.0465],
      [110.6085, -8.0458],
      [110.6098, -8.0441],
      [110.6105, -8.0433],
      [110.612, -8.0419],
      [110.613, -8.0417],
      [110.6145, -8.0416],
      [110.6174, -8.0416],
      [110.6188, -8.0416],
      [110.6199, -8.0421],
      [110.6209, -8.0428],
      [110.6221, -8.0433],
      [110.6225, -8.0436],
      [110.6237, -8.044],
      [110.6247, -8.0443],
      [110.6253, -8.0444],
      [110.6257, -8.045],
      [110.6262, -8.0455],
      [110.6268, -8.046],
      [110.6275, -8.0463],
      [110.6285, -8.0463],
      [110.6291, -8.0463],
      [110.6301, -8.0463],
      [110.6316, -8.0461],
      [110.6325, -8.0459],
      [110.6331, -8.0459],
      [110.6339, -8.0459],
      [110.6349, -8.0458],
      [110.635, -8.0458],
      [110.6353, -8.0456],
      [110.6356, -8.0454],
      [110.6358, -8.0453],
      [110.6361, -8.0452],
      [110.6373, -8.0447],
      [110.6381, -8.0446],
      [110.6385, -8.0446],
      [110.6387, -8.0447],
      [110.6395, -8.0452],
      [110.6406, -8.046],
      [110.6407, -8.0455],
      [110.6406, -8.0446],
      [110.6407, -8.0434],
      [110.641, -8.0427],
      [110.641, -8.042],
      [110.6411, -8.0411],
      [110.6412, -8.0403],
      [110.6412, -8.0384],
      [110.6412, -8.0361],
      [110.6414, -8.0346],
      [110.6415, -8.0336],
      [110.6414, -8.0324],
      [110.6415, -8.0311],
      [110.6414, -8.0295],
      [110.6409, -8.0276],
      [110.6402, -8.0259]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMANU',
    village: 'CANDIREJO',
    border: [
      [110.6542, -8.0237],
      [110.6539, -8.0232],
      [110.653, -8.023],
      [110.652, -8.0228],
      [110.6507, -8.0229],
      [110.6492, -8.0235],
      [110.6478, -8.0244],
      [110.6467, -8.025],
      [110.6448, -8.0263],
      [110.6438, -8.0269],
      [110.6432, -8.0268],
      [110.6426, -8.0262],
      [110.6422, -8.0257],
      [110.6416, -8.0256],
      [110.6405, -8.0257],
      [110.6402, -8.0259],
      [110.6409, -8.0276],
      [110.6414, -8.0295],
      [110.6415, -8.0311],
      [110.6414, -8.0324],
      [110.6415, -8.0336],
      [110.6414, -8.0346],
      [110.6412, -8.0361],
      [110.6412, -8.0384],
      [110.6412, -8.0403],
      [110.6411, -8.0411],
      [110.641, -8.042],
      [110.641, -8.0427],
      [110.6407, -8.0434],
      [110.6406, -8.0446],
      [110.6407, -8.0455],
      [110.6406, -8.046],
      [110.6416, -8.0467],
      [110.6426, -8.0476],
      [110.643, -8.048],
      [110.6437, -8.0491],
      [110.6441, -8.05],
      [110.6446, -8.0512],
      [110.645, -8.0526],
      [110.6454, -8.0545],
      [110.6455, -8.0558],
      [110.6457, -8.0571],
      [110.6458, -8.0574],
      [110.6465, -8.0579],
      [110.647, -8.0582],
      [110.6474, -8.0585],
      [110.6478, -8.0593],
      [110.6481, -8.0604],
      [110.6485, -8.0615],
      [110.6491, -8.0621],
      [110.65, -8.0631],
      [110.6512, -8.0637],
      [110.6526, -8.0637],
      [110.6536, -8.0637],
      [110.6543, -8.0638],
      [110.655, -8.0643],
      [110.6556, -8.0646],
      [110.6561, -8.0649],
      [110.657, -8.0649],
      [110.6579, -8.065],
      [110.6586, -8.065],
      [110.6598, -8.0651],
      [110.6608, -8.0655],
      [110.6616, -8.0659],
      [110.6621, -8.0666],
      [110.6626, -8.0677],
      [110.663, -8.0688],
      [110.6634, -8.07],
      [110.6635, -8.0711],
      [110.6635, -8.0712],
      [110.6634, -8.0721],
      [110.6632, -8.073],
      [110.6627, -8.0738],
      [110.6625, -8.0742],
      [110.6625, -8.0744],
      [110.663, -8.0749],
      [110.6638, -8.076],
      [110.6647, -8.0772],
      [110.6651, -8.0775],
      [110.6657, -8.0777],
      [110.6663, -8.0783],
      [110.6667, -8.0787],
      [110.6673, -8.079],
      [110.6677, -8.0795],
      [110.6688, -8.08],
      [110.67, -8.0808],
      [110.6703, -8.081],
      [110.6715, -8.0809],
      [110.6728, -8.0808],
      [110.6736, -8.0805],
      [110.6738, -8.0806],
      [110.6752, -8.0811],
      [110.676, -8.0813],
      [110.6765, -8.0812],
      [110.6774, -8.0808],
      [110.6781, -8.0805],
      [110.6789, -8.0805],
      [110.6796, -8.0804],
      [110.6805, -8.08],
      [110.6812, -8.0797],
      [110.6817, -8.0796],
      [110.6829, -8.0796],
      [110.6842, -8.0797],
      [110.6849, -8.0798],
      [110.6853, -8.0793],
      [110.6859, -8.079],
      [110.6865, -8.079],
      [110.687, -8.0791],
      [110.688, -8.08],
      [110.6886, -8.0806],
      [110.6888, -8.0807],
      [110.6895, -8.081],
      [110.6904, -8.0814],
      [110.6912, -8.0825],
      [110.6923, -8.084],
      [110.6925, -8.0848],
      [110.693, -8.0854],
      [110.6937, -8.0864],
      [110.6944, -8.0869],
      [110.6953, -8.0882],
      [110.696, -8.0885],
      [110.6967, -8.0892],
      [110.6972, -8.0894],
      [110.6976, -8.0899],
      [110.6981, -8.0904],
      [110.6987, -8.091],
      [110.6997, -8.0916],
      [110.7011, -8.0924],
      [110.7022, -8.0934],
      [110.703, -8.094],
      [110.7035, -8.0944],
      [110.7046, -8.0956],
      [110.7055, -8.0961],
      [110.7063, -8.0969],
      [110.7064, -8.0971],
      [110.7065, -8.0969],
      [110.7069, -8.0955],
      [110.7072, -8.0942],
      [110.7072, -8.0929],
      [110.7071, -8.0918],
      [110.7068, -8.09],
      [110.7064, -8.0891],
      [110.7063, -8.0885],
      [110.7067, -8.0866],
      [110.7068, -8.0855],
      [110.7069, -8.0811],
      [110.7069, -8.0799],
      [110.7069, -8.0795],
      [110.7063, -8.0787],
      [110.7057, -8.0782],
      [110.7041, -8.0777],
      [110.7025, -8.0771],
      [110.7015, -8.0768],
      [110.7004, -8.0761],
      [110.6994, -8.0754],
      [110.6985, -8.0742],
      [110.6982, -8.0738],
      [110.6972, -8.0734],
      [110.6959, -8.0729],
      [110.6951, -8.0724],
      [110.6946, -8.0722],
      [110.694, -8.0714],
      [110.6939, -8.0712],
      [110.6936, -8.0705],
      [110.6934, -8.0691],
      [110.6935, -8.0676],
      [110.6934, -8.0669],
      [110.6931, -8.0662],
      [110.6922, -8.065],
      [110.691, -8.064],
      [110.6897, -8.0634],
      [110.6885, -8.0631],
      [110.6881, -8.0629],
      [110.6871, -8.0624],
      [110.686, -8.0617],
      [110.6854, -8.0612],
      [110.6849, -8.0609],
      [110.6842, -8.0608],
      [110.6832, -8.0611],
      [110.6823, -8.0611],
      [110.682, -8.0611],
      [110.6812, -8.0607],
      [110.6798, -8.0598],
      [110.679, -8.059],
      [110.6783, -8.0585],
      [110.6779, -8.0583],
      [110.6773, -8.058],
      [110.6763, -8.0577],
      [110.6755, -8.0574],
      [110.675, -8.057],
      [110.6742, -8.0564],
      [110.6737, -8.0558],
      [110.6732, -8.0549],
      [110.6725, -8.0537],
      [110.6724, -8.0527],
      [110.6724, -8.0514],
      [110.6726, -8.0497],
      [110.6726, -8.0487],
      [110.6721, -8.0472],
      [110.6716, -8.0457],
      [110.6714, -8.0441],
      [110.671, -8.0425],
      [110.6709, -8.0408],
      [110.6713, -8.0387],
      [110.6713, -8.0386],
      [110.671, -8.0386],
      [110.6699, -8.0389],
      [110.669, -8.039],
      [110.6685, -8.039],
      [110.667, -8.0385],
      [110.6663, -8.0381],
      [110.6659, -8.0374],
      [110.6657, -8.0366],
      [110.6656, -8.0355],
      [110.6649, -8.0336],
      [110.6643, -8.0323],
      [110.664, -8.0312],
      [110.6636, -8.0303],
      [110.663, -8.0296],
      [110.6619, -8.029],
      [110.6616, -8.029],
      [110.6602, -8.0288],
      [110.6598, -8.0287],
      [110.6581, -8.0289],
      [110.6571, -8.0289],
      [110.6566, -8.0287],
      [110.6561, -8.0283],
      [110.6553, -8.0268],
      [110.655, -8.0258],
      [110.6549, -8.0251],
      [110.6543, -8.0239],
      [110.6542, -8.0237]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMANU',
    village: 'DADAPAYU',
    border: [
      [110.7041, -8.0329],
      [110.7025, -8.0327],
      [110.7002, -8.0328],
      [110.6987, -8.0329],
      [110.6983, -8.0328],
      [110.6978, -8.0329],
      [110.6968, -8.0331],
      [110.6962, -8.0332],
      [110.6959, -8.0332],
      [110.6955, -8.0328],
      [110.6949, -8.0322],
      [110.6944, -8.0314],
      [110.694, -8.0305],
      [110.6934, -8.0295],
      [110.6929, -8.0288],
      [110.6925, -8.0283],
      [110.6922, -8.0281],
      [110.6914, -8.028],
      [110.6904, -8.028],
      [110.6893, -8.028],
      [110.6886, -8.028],
      [110.6877, -8.0281],
      [110.6868, -8.0283],
      [110.6859, -8.0283],
      [110.6854, -8.0286],
      [110.6848, -8.0287],
      [110.6844, -8.0289],
      [110.684, -8.029],
      [110.6832, -8.0286],
      [110.6825, -8.0281],
      [110.6818, -8.0275],
      [110.6812, -8.0272],
      [110.6807, -8.027],
      [110.6801, -8.0266],
      [110.6794, -8.0273],
      [110.6786, -8.0279],
      [110.6777, -8.0284],
      [110.677, -8.0286],
      [110.6764, -8.0287],
      [110.6761, -8.0289],
      [110.6756, -8.0294],
      [110.6753, -8.03],
      [110.6748, -8.0311],
      [110.6746, -8.0314],
      [110.6733, -8.0333],
      [110.6722, -8.0358],
      [110.6718, -8.0374],
      [110.6714, -8.0385],
      [110.6713, -8.0386],
      [110.6713, -8.0387],
      [110.6709, -8.0408],
      [110.671, -8.0425],
      [110.6714, -8.0441],
      [110.6716, -8.0457],
      [110.6721, -8.0472],
      [110.6726, -8.0487],
      [110.6726, -8.0497],
      [110.6724, -8.0514],
      [110.6724, -8.0527],
      [110.6725, -8.0537],
      [110.6732, -8.0549],
      [110.6737, -8.0558],
      [110.6742, -8.0564],
      [110.675, -8.057],
      [110.6755, -8.0574],
      [110.6763, -8.0577],
      [110.6773, -8.058],
      [110.6779, -8.0583],
      [110.6783, -8.0585],
      [110.679, -8.059],
      [110.6798, -8.0598],
      [110.6812, -8.0607],
      [110.682, -8.0611],
      [110.6823, -8.0611],
      [110.6832, -8.0611],
      [110.6842, -8.0608],
      [110.6849, -8.0609],
      [110.6854, -8.0612],
      [110.686, -8.0617],
      [110.6871, -8.0624],
      [110.6881, -8.0629],
      [110.6885, -8.0631],
      [110.6897, -8.0634],
      [110.691, -8.064],
      [110.6922, -8.065],
      [110.6931, -8.0662],
      [110.6934, -8.0669],
      [110.6935, -8.0676],
      [110.6934, -8.0691],
      [110.6936, -8.0705],
      [110.6939, -8.0712],
      [110.694, -8.0714],
      [110.6946, -8.0722],
      [110.6951, -8.0724],
      [110.6959, -8.0729],
      [110.6972, -8.0734],
      [110.6982, -8.0738],
      [110.6985, -8.0742],
      [110.6994, -8.0754],
      [110.7004, -8.0761],
      [110.7015, -8.0768],
      [110.7025, -8.0771],
      [110.7041, -8.0777],
      [110.7057, -8.0782],
      [110.7063, -8.0787],
      [110.7069, -8.0795],
      [110.7069, -8.0799],
      [110.7069, -8.0811],
      [110.7068, -8.0855],
      [110.7067, -8.0866],
      [110.7063, -8.0885],
      [110.7064, -8.0891],
      [110.7068, -8.09],
      [110.7071, -8.0918],
      [110.7072, -8.0929],
      [110.7072, -8.0942],
      [110.7069, -8.0955],
      [110.7065, -8.0969],
      [110.7064, -8.0971],
      [110.7072, -8.0984],
      [110.7081, -8.0995],
      [110.7092, -8.1001],
      [110.7111, -8.1007],
      [110.7114, -8.101],
      [110.7117, -8.1019],
      [110.7121, -8.1026],
      [110.7125, -8.1029],
      [110.713, -8.1032],
      [110.7134, -8.1028],
      [110.714, -8.1024],
      [110.7147, -8.1019],
      [110.7152, -8.1013],
      [110.7155, -8.1008],
      [110.7157, -8.1002],
      [110.7159, -8.0994],
      [110.716, -8.0989],
      [110.7164, -8.0984],
      [110.7168, -8.0982],
      [110.7176, -8.098],
      [110.7181, -8.0978],
      [110.7182, -8.0975],
      [110.7182, -8.0969],
      [110.7181, -8.0959],
      [110.7178, -8.0951],
      [110.7177, -8.0943],
      [110.7177, -8.0936],
      [110.7177, -8.0934],
      [110.7179, -8.0921],
      [110.7178, -8.0909],
      [110.7171, -8.0892],
      [110.7165, -8.0878],
      [110.7161, -8.0869],
      [110.7158, -8.0861],
      [110.7157, -8.0854],
      [110.7158, -8.0845],
      [110.7162, -8.0833],
      [110.7165, -8.0819],
      [110.7165, -8.0812],
      [110.7165, -8.0804],
      [110.7161, -8.0799],
      [110.7155, -8.0792],
      [110.7143, -8.0784],
      [110.7134, -8.0774],
      [110.713, -8.0766],
      [110.7126, -8.0761],
      [110.7124, -8.0742],
      [110.7124, -8.0732],
      [110.7124, -8.0722],
      [110.7124, -8.0712],
      [110.7126, -8.0706],
      [110.7132, -8.0695],
      [110.7139, -8.0683],
      [110.7147, -8.0671],
      [110.7153, -8.0663],
      [110.7166, -8.0645],
      [110.717, -8.0637],
      [110.7174, -8.063],
      [110.718, -8.0625],
      [110.7183, -8.0618],
      [110.7187, -8.061],
      [110.7193, -8.0604],
      [110.7201, -8.0597],
      [110.7206, -8.0593],
      [110.7214, -8.0588],
      [110.7218, -8.0586],
      [110.7226, -8.058],
      [110.7231, -8.0572],
      [110.7233, -8.0569],
      [110.7238, -8.0561],
      [110.7241, -8.0553],
      [110.7243, -8.0545],
      [110.7243, -8.0536],
      [110.7243, -8.0527],
      [110.7243, -8.0519],
      [110.7244, -8.051],
      [110.7244, -8.0502],
      [110.7241, -8.0496],
      [110.7236, -8.049],
      [110.7227, -8.0485],
      [110.7214, -8.0478],
      [110.7205, -8.0471],
      [110.7201, -8.0469],
      [110.7198, -8.0466],
      [110.7192, -8.0462],
      [110.7187, -8.0461],
      [110.7182, -8.0462],
      [110.7177, -8.0464],
      [110.7174, -8.0466],
      [110.7167, -8.0466],
      [110.7159, -8.0466],
      [110.7152, -8.0468],
      [110.7146, -8.0468],
      [110.7139, -8.0466],
      [110.7137, -8.0464],
      [110.7133, -8.0459],
      [110.7128, -8.0454],
      [110.7124, -8.0451],
      [110.712, -8.0449],
      [110.7117, -8.0447],
      [110.7114, -8.0443],
      [110.7111, -8.0435],
      [110.7107, -8.0426],
      [110.7104, -8.0422],
      [110.7101, -8.0417],
      [110.7097, -8.0412],
      [110.7094, -8.0404],
      [110.7092, -8.0397],
      [110.7089, -8.0392],
      [110.7086, -8.0391],
      [110.7073, -8.0393],
      [110.7065, -8.0391],
      [110.7058, -8.0388],
      [110.7051, -8.0384],
      [110.7045, -8.0379],
      [110.7043, -8.0376],
      [110.7039, -8.0369],
      [110.7038, -8.0362],
      [110.7038, -8.0356],
      [110.7038, -8.0352],
      [110.7039, -8.0343],
      [110.704, -8.0334],
      [110.7041, -8.0329]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'BEJIHARJO',
    border: [
      [110.6518, -7.9424],
      [110.6516, -7.942],
      [110.6514, -7.9417],
      [110.6512, -7.9415],
      [110.6517, -7.9412],
      [110.652, -7.9413],
      [110.6524, -7.9413],
      [110.6528, -7.9416],
      [110.6536, -7.9418],
      [110.6545, -7.9419],
      [110.6552, -7.9419],
      [110.6557, -7.9417],
      [110.6566, -7.9417],
      [110.6572, -7.9416],
      [110.6575, -7.9414],
      [110.6582, -7.9402],
      [110.6587, -7.9392],
      [110.6591, -7.9376],
      [110.6593, -7.937],
      [110.6596, -7.9359],
      [110.6596, -7.9343],
      [110.6596, -7.9324],
      [110.6597, -7.9312],
      [110.6599, -7.9308],
      [110.6602, -7.9294],
      [110.6606, -7.9278],
      [110.6611, -7.9272],
      [110.6614, -7.9267],
      [110.6621, -7.926],
      [110.6624, -7.9254],
      [110.6624, -7.9249],
      [110.6623, -7.9241],
      [110.6623, -7.9237],
      [110.6625, -7.9234],
      [110.6625, -7.9232],
      [110.6625, -7.9228],
      [110.6623, -7.9225],
      [110.6623, -7.9218],
      [110.6623, -7.9216],
      [110.6623, -7.9208],
      [110.6627, -7.9199],
      [110.6633, -7.9187],
      [110.6638, -7.918],
      [110.664, -7.9176],
      [110.6647, -7.9168],
      [110.6647, -7.9164],
      [110.6647, -7.9158],
      [110.6649, -7.9149],
      [110.6651, -7.9137],
      [110.6653, -7.9131],
      [110.6655, -7.912],
      [110.6657, -7.9108],
      [110.6655, -7.91],
      [110.6651, -7.9091],
      [110.6649, -7.9085],
      [110.665, -7.9077],
      [110.6652, -7.9066],
      [110.6656, -7.9056],
      [110.6645, -7.9048],
      [110.664, -7.9045],
      [110.6638, -7.9046],
      [110.663, -7.9047],
      [110.6626, -7.9048],
      [110.6619, -7.9052],
      [110.6614, -7.9056],
      [110.6608, -7.906],
      [110.6603, -7.9066],
      [110.6602, -7.9067],
      [110.6598, -7.9067],
      [110.6591, -7.9068],
      [110.6593, -7.9077],
      [110.6595, -7.9086],
      [110.6596, -7.9092],
      [110.6596, -7.9094],
      [110.6593, -7.9095],
      [110.6588, -7.9097],
      [110.6584, -7.9099],
      [110.6583, -7.9101],
      [110.6582, -7.9105],
      [110.6579, -7.9105],
      [110.6577, -7.9108],
      [110.6574, -7.9109],
      [110.6574, -7.9112],
      [110.6572, -7.9112],
      [110.6566, -7.911],
      [110.6563, -7.9114],
      [110.6558, -7.9121],
      [110.6557, -7.9122],
      [110.6554, -7.9126],
      [110.6554, -7.9139],
      [110.6552, -7.9148],
      [110.6548, -7.9155],
      [110.6544, -7.9163],
      [110.6541, -7.9169],
      [110.653, -7.9169],
      [110.6523, -7.9168],
      [110.6522, -7.9168],
      [110.6519, -7.9167],
      [110.6517, -7.9167],
      [110.6514, -7.9167],
      [110.651, -7.917],
      [110.6502, -7.9172],
      [110.65, -7.9173],
      [110.6498, -7.9173],
      [110.6494, -7.9173],
      [110.6491, -7.9172],
      [110.6489, -7.917],
      [110.6488, -7.9169],
      [110.6482, -7.9173],
      [110.6467, -7.9173],
      [110.645, -7.9172],
      [110.6442, -7.9169],
      [110.6442, -7.9168],
      [110.6439, -7.9165],
      [110.6438, -7.9159],
      [110.6436, -7.9149],
      [110.6435, -7.9144],
      [110.6432, -7.9142],
      [110.643, -7.9141],
      [110.6426, -7.914],
      [110.6425, -7.9139],
      [110.6418, -7.9135],
      [110.6414, -7.9134],
      [110.6409, -7.9132],
      [110.6404, -7.9134],
      [110.6399, -7.9136],
      [110.6397, -7.914],
      [110.6397, -7.9143],
      [110.6396, -7.9145],
      [110.6393, -7.9146],
      [110.6389, -7.9146],
      [110.6384, -7.9143],
      [110.6382, -7.9139],
      [110.6379, -7.9135],
      [110.6376, -7.9134],
      [110.6374, -7.9134],
      [110.6372, -7.9134],
      [110.6369, -7.9134],
      [110.6367, -7.9135],
      [110.6363, -7.9134],
      [110.636, -7.9132],
      [110.6357, -7.913],
      [110.6353, -7.9129],
      [110.6351, -7.9129],
      [110.6349, -7.913],
      [110.6344, -7.9132],
      [110.6343, -7.9131],
      [110.6337, -7.9127],
      [110.6334, -7.9124],
      [110.6332, -7.9114],
      [110.633, -7.9104],
      [110.6327, -7.91],
      [110.6323, -7.9097],
      [110.6319, -7.9095],
      [110.6318, -7.9096],
      [110.6316, -7.9097],
      [110.6312, -7.9099],
      [110.631, -7.9101],
      [110.6306, -7.9104],
      [110.6301, -7.9106],
      [110.6299, -7.9105],
      [110.6296, -7.9105],
      [110.6291, -7.9104],
      [110.6286, -7.9104],
      [110.6285, -7.9105],
      [110.6283, -7.9105],
      [110.6279, -7.911],
      [110.6279, -7.9111],
      [110.6277, -7.9117],
      [110.6275, -7.9122],
      [110.6275, -7.9124],
      [110.6277, -7.9131],
      [110.6278, -7.9138],
      [110.6277, -7.9143],
      [110.6277, -7.9146],
      [110.6274, -7.9149],
      [110.6268, -7.9149],
      [110.6266, -7.915],
      [110.6256, -7.9153],
      [110.6249, -7.9154],
      [110.6242, -7.9157],
      [110.6231, -7.916],
      [110.6222, -7.9163],
      [110.6215, -7.9165],
      [110.6209, -7.9165],
      [110.6211, -7.917],
      [110.6212, -7.9177],
      [110.6209, -7.9181],
      [110.6209, -7.9185],
      [110.6212, -7.9189],
      [110.6207, -7.9195],
      [110.6208, -7.9201],
      [110.6206, -7.9206],
      [110.6205, -7.9213],
      [110.6207, -7.9222],
      [110.6209, -7.9228],
      [110.621, -7.9232],
      [110.6208, -7.9235],
      [110.6205, -7.9243],
      [110.6207, -7.9249],
      [110.6213, -7.9254],
      [110.6215, -7.926],
      [110.6213, -7.927],
      [110.6211, -7.9283],
      [110.6208, -7.9294],
      [110.6207, -7.9303],
      [110.6196, -7.93],
      [110.6192, -7.9301],
      [110.6191, -7.9304],
      [110.6189, -7.9311],
      [110.6187, -7.9323],
      [110.6183, -7.9336],
      [110.6176, -7.9346],
      [110.6171, -7.9355],
      [110.6168, -7.937],
      [110.6166, -7.9385],
      [110.6167, -7.9406],
      [110.6169, -7.9435],
      [110.6168, -7.9445],
      [110.6167, -7.945],
      [110.6169, -7.9458],
      [110.6169, -7.9471],
      [110.6169, -7.9481],
      [110.6164, -7.9484],
      [110.6159, -7.9486],
      [110.6159, -7.9494],
      [110.6156, -7.9496],
      [110.6155, -7.95],
      [110.6154, -7.9506],
      [110.615, -7.9521],
      [110.6153, -7.9522],
      [110.6159, -7.9522],
      [110.6165, -7.952],
      [110.6174, -7.9519],
      [110.6177, -7.9523],
      [110.618, -7.9524],
      [110.6185, -7.9527],
      [110.6195, -7.9527],
      [110.6203, -7.9526],
      [110.621, -7.9529],
      [110.622, -7.9532],
      [110.6218, -7.9541],
      [110.6216, -7.9548],
      [110.6215, -7.9553],
      [110.6217, -7.9557],
      [110.6221, -7.9563],
      [110.622, -7.9567],
      [110.6218, -7.9575],
      [110.6217, -7.9582],
      [110.6217, -7.9584],
      [110.6217, -7.9586],
      [110.6221, -7.9591],
      [110.6233, -7.9595],
      [110.624, -7.9599],
      [110.6241, -7.96],
      [110.6249, -7.96],
      [110.6255, -7.9585],
      [110.626, -7.9573],
      [110.6262, -7.9539],
      [110.6269, -7.9536],
      [110.6269, -7.9534],
      [110.6269, -7.953],
      [110.6268, -7.9527],
      [110.6269, -7.9519],
      [110.6271, -7.9515],
      [110.6275, -7.9518],
      [110.6297, -7.9521],
      [110.6313, -7.9526],
      [110.6322, -7.953],
      [110.6329, -7.9533],
      [110.6335, -7.9533],
      [110.6337, -7.9528],
      [110.6341, -7.9526],
      [110.6359, -7.9528],
      [110.638, -7.9531],
      [110.6384, -7.9526],
      [110.6387, -7.9522],
      [110.6393, -7.9521],
      [110.6401, -7.9519],
      [110.6401, -7.9527],
      [110.6412, -7.9528],
      [110.6424, -7.9532],
      [110.6427, -7.9532],
      [110.643, -7.9526],
      [110.6433, -7.9526],
      [110.6434, -7.9526],
      [110.6436, -7.9525],
      [110.6441, -7.9527],
      [110.6447, -7.9525],
      [110.6448, -7.9522],
      [110.645, -7.9517],
      [110.6453, -7.9515],
      [110.6457, -7.9515],
      [110.646, -7.9514],
      [110.6461, -7.9508],
      [110.646, -7.9507],
      [110.6463, -7.9503],
      [110.6466, -7.9499],
      [110.6466, -7.9497],
      [110.6461, -7.9496],
      [110.646, -7.9494],
      [110.6462, -7.9493],
      [110.6461, -7.9488],
      [110.646, -7.9483],
      [110.646, -7.948],
      [110.6462, -7.9476],
      [110.6464, -7.9474],
      [110.6463, -7.9471],
      [110.6464, -7.9466],
      [110.6465, -7.9461],
      [110.6468, -7.946],
      [110.647, -7.9461],
      [110.6471, -7.9454],
      [110.6472, -7.9446],
      [110.6488, -7.9448],
      [110.6491, -7.9447],
      [110.6491, -7.9443],
      [110.6491, -7.9438],
      [110.6494, -7.9436],
      [110.6495, -7.9431],
      [110.6496, -7.9427],
      [110.6499, -7.9422],
      [110.6509, -7.9424],
      [110.6515, -7.9424],
      [110.6518, -7.9424]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'WILADEG',
    border: [
      [110.6707, -7.9524],
      [110.6699, -7.9524],
      [110.6676, -7.9519],
      [110.6631, -7.9515],
      [110.6626, -7.9515],
      [110.6611, -7.952],
      [110.6599, -7.9523],
      [110.6594, -7.9505],
      [110.6585, -7.9504],
      [110.6558, -7.95],
      [110.6558, -7.9509],
      [110.6557, -7.9512],
      [110.6557, -7.9515],
      [110.6552, -7.9514],
      [110.6553, -7.9515],
      [110.6553, -7.9516],
      [110.6554, -7.9517],
      [110.6554, -7.9519],
      [110.6554, -7.9521],
      [110.6555, -7.9528],
      [110.6554, -7.9532],
      [110.6551, -7.9534],
      [110.655, -7.9538],
      [110.6549, -7.9544],
      [110.6546, -7.9547],
      [110.6545, -7.9554],
      [110.6544, -7.9559],
      [110.6546, -7.9563],
      [110.6547, -7.9566],
      [110.6548, -7.9569],
      [110.6549, -7.9574],
      [110.6546, -7.9578],
      [110.6546, -7.9579],
      [110.6546, -7.9585],
      [110.6546, -7.9592],
      [110.6544, -7.9595],
      [110.6544, -7.9598],
      [110.6543, -7.96],
      [110.6543, -7.9602],
      [110.6542, -7.9604],
      [110.6539, -7.9606],
      [110.6537, -7.961],
      [110.6535, -7.9616],
      [110.6533, -7.9618],
      [110.6533, -7.9621],
      [110.6532, -7.9627],
      [110.6533, -7.9631],
      [110.6533, -7.9635],
      [110.6532, -7.9639],
      [110.6528, -7.9647],
      [110.6523, -7.9655],
      [110.6522, -7.9662],
      [110.652, -7.9671],
      [110.6519, -7.9678],
      [110.6516, -7.9691],
      [110.6517, -7.9698],
      [110.6522, -7.9707],
      [110.6527, -7.9714],
      [110.6529, -7.972],
      [110.6531, -7.972],
      [110.6532, -7.9728],
      [110.6532, -7.973],
      [110.6531, -7.9732],
      [110.6533, -7.9736],
      [110.6533, -7.9737],
      [110.6533, -7.974],
      [110.6532, -7.9743],
      [110.6533, -7.9753],
      [110.6541, -7.9756],
      [110.6547, -7.9759],
      [110.6551, -7.9761],
      [110.6552, -7.9762],
      [110.6553, -7.9764],
      [110.6556, -7.9766],
      [110.6559, -7.9769],
      [110.6561, -7.9771],
      [110.6571, -7.9775],
      [110.6576, -7.9776],
      [110.6579, -7.9775],
      [110.6585, -7.9779],
      [110.6592, -7.9782],
      [110.6593, -7.9786],
      [110.6598, -7.9792],
      [110.6598, -7.9791],
      [110.6599, -7.9788],
      [110.6601, -7.9782],
      [110.6604, -7.9779],
      [110.6609, -7.9775],
      [110.6612, -7.9776],
      [110.6614, -7.9776],
      [110.6616, -7.9788],
      [110.662, -7.98],
      [110.6621, -7.9809],
      [110.662, -7.9821],
      [110.6615, -7.9821],
      [110.6615, -7.9822],
      [110.6606, -7.982],
      [110.6609, -7.9823],
      [110.6619, -7.9824],
      [110.6631, -7.9828],
      [110.6639, -7.9818],
      [110.6644, -7.982],
      [110.6652, -7.9824],
      [110.6655, -7.9825],
      [110.6659, -7.9796],
      [110.6662, -7.9778],
      [110.6663, -7.9768],
      [110.6654, -7.9763],
      [110.6647, -7.9761],
      [110.6643, -7.976],
      [110.6642, -7.976],
      [110.6639, -7.9761],
      [110.6638, -7.9761],
      [110.6638, -7.976],
      [110.6638, -7.9759],
      [110.6638, -7.9756],
      [110.6638, -7.9754],
      [110.6635, -7.9752],
      [110.6636, -7.975],
      [110.6637, -7.9747],
      [110.6637, -7.9744],
      [110.6637, -7.9742],
      [110.6636, -7.9739],
      [110.6635, -7.9736],
      [110.6637, -7.9732],
      [110.6641, -7.9732],
      [110.6646, -7.973],
      [110.6648, -7.9728],
      [110.665, -7.9724],
      [110.6653, -7.9723],
      [110.6654, -7.9718],
      [110.6655, -7.9714],
      [110.6656, -7.9713],
      [110.6657, -7.9708],
      [110.6659, -7.9704],
      [110.6659, -7.9698],
      [110.6663, -7.9691],
      [110.6664, -7.9687],
      [110.6666, -7.9684],
      [110.6669, -7.9682],
      [110.6673, -7.968],
      [110.6678, -7.9679],
      [110.6679, -7.9674],
      [110.6682, -7.9667],
      [110.6686, -7.9668],
      [110.6688, -7.9668],
      [110.669, -7.9666],
      [110.6691, -7.9662],
      [110.6694, -7.9662],
      [110.6697, -7.9663],
      [110.6697, -7.966],
      [110.6696, -7.9648],
      [110.6696, -7.963],
      [110.6699, -7.9624],
      [110.6698, -7.9615],
      [110.6699, -7.9612],
      [110.6706, -7.9607],
      [110.6706, -7.9599],
      [110.6713, -7.9603],
      [110.6714, -7.9601],
      [110.6718, -7.96],
      [110.672, -7.9596],
      [110.6721, -7.959],
      [110.6722, -7.9587],
      [110.6716, -7.9584],
      [110.6712, -7.9579],
      [110.6711, -7.9577],
      [110.6713, -7.9567],
      [110.6719, -7.9552],
      [110.6721, -7.9547],
      [110.6717, -7.954],
      [110.6705, -7.9536],
      [110.6707, -7.9527],
      [110.6707, -7.9524]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'BENDUNGAN',
    border: [
      [110.6518, -7.9424],
      [110.6515, -7.9424],
      [110.6509, -7.9424],
      [110.6499, -7.9422],
      [110.6496, -7.9427],
      [110.6495, -7.9431],
      [110.6494, -7.9436],
      [110.6491, -7.9438],
      [110.6491, -7.9443],
      [110.6491, -7.9447],
      [110.6488, -7.9448],
      [110.6472, -7.9446],
      [110.6471, -7.9454],
      [110.647, -7.9461],
      [110.6468, -7.946],
      [110.6465, -7.9461],
      [110.6464, -7.9466],
      [110.6463, -7.9471],
      [110.6464, -7.9474],
      [110.6462, -7.9476],
      [110.646, -7.948],
      [110.646, -7.9483],
      [110.6461, -7.9488],
      [110.6462, -7.9493],
      [110.646, -7.9494],
      [110.6461, -7.9496],
      [110.6466, -7.9497],
      [110.6466, -7.9499],
      [110.6463, -7.9503],
      [110.646, -7.9507],
      [110.6461, -7.9508],
      [110.646, -7.9514],
      [110.6457, -7.9515],
      [110.6453, -7.9515],
      [110.645, -7.9517],
      [110.6448, -7.9522],
      [110.6447, -7.9525],
      [110.6441, -7.9527],
      [110.6436, -7.9525],
      [110.6434, -7.9526],
      [110.6433, -7.9526],
      [110.643, -7.9526],
      [110.6427, -7.9532],
      [110.6424, -7.9532],
      [110.6412, -7.9528],
      [110.6401, -7.9527],
      [110.6401, -7.9519],
      [110.6393, -7.9521],
      [110.6387, -7.9522],
      [110.6384, -7.9526],
      [110.638, -7.9531],
      [110.638, -7.9536],
      [110.6373, -7.9551],
      [110.637, -7.9554],
      [110.6356, -7.9553],
      [110.6353, -7.9566],
      [110.6352, -7.9572],
      [110.635, -7.9575],
      [110.6345, -7.9579],
      [110.6343, -7.9581],
      [110.634, -7.9581],
      [110.6339, -7.9585],
      [110.6337, -7.9588],
      [110.6334, -7.959],
      [110.6329, -7.959],
      [110.6326, -7.9594],
      [110.6324, -7.9596],
      [110.6322, -7.9603],
      [110.6321, -7.9605],
      [110.6319, -7.9611],
      [110.6319, -7.9614],
      [110.6315, -7.962],
      [110.6315, -7.9625],
      [110.6314, -7.9632],
      [110.6314, -7.9633],
      [110.6312, -7.9635],
      [110.6311, -7.9649],
      [110.631, -7.9654],
      [110.6309, -7.9658],
      [110.6308, -7.966],
      [110.6305, -7.9662],
      [110.63, -7.9663],
      [110.6298, -7.9664],
      [110.6296, -7.9666],
      [110.6297, -7.9668],
      [110.6299, -7.9671],
      [110.6301, -7.9673],
      [110.6307, -7.9674],
      [110.6313, -7.9672],
      [110.6324, -7.9669],
      [110.6334, -7.9665],
      [110.634, -7.9661],
      [110.635, -7.9657],
      [110.6356, -7.9655],
      [110.6371, -7.9655],
      [110.6385, -7.9657],
      [110.6395, -7.9658],
      [110.6404, -7.9659],
      [110.6411, -7.9661],
      [110.6417, -7.9668],
      [110.6422, -7.9676],
      [110.6423, -7.9687],
      [110.6422, -7.97],
      [110.6423, -7.9705],
      [110.6423, -7.9711],
      [110.6439, -7.9711],
      [110.6471, -7.9713],
      [110.6487, -7.9716],
      [110.6504, -7.9717],
      [110.6506, -7.9714],
      [110.6525, -7.9718],
      [110.6529, -7.972],
      [110.6527, -7.9714],
      [110.6522, -7.9707],
      [110.6517, -7.9698],
      [110.6516, -7.9691],
      [110.6519, -7.9678],
      [110.652, -7.9671],
      [110.6522, -7.9662],
      [110.6523, -7.9655],
      [110.6528, -7.9647],
      [110.6532, -7.9639],
      [110.6533, -7.9635],
      [110.6533, -7.9631],
      [110.6532, -7.9627],
      [110.6533, -7.9621],
      [110.6533, -7.9618],
      [110.6535, -7.9616],
      [110.6537, -7.961],
      [110.6539, -7.9606],
      [110.6542, -7.9604],
      [110.6543, -7.9602],
      [110.6543, -7.96],
      [110.6544, -7.9598],
      [110.6544, -7.9595],
      [110.6546, -7.9592],
      [110.6546, -7.9585],
      [110.6546, -7.9579],
      [110.6546, -7.9578],
      [110.6549, -7.9574],
      [110.6548, -7.9569],
      [110.6547, -7.9566],
      [110.6546, -7.9563],
      [110.6544, -7.9559],
      [110.6545, -7.9554],
      [110.6546, -7.9547],
      [110.6549, -7.9544],
      [110.655, -7.9538],
      [110.6551, -7.9534],
      [110.6554, -7.9532],
      [110.6555, -7.9528],
      [110.6554, -7.9521],
      [110.6554, -7.9519],
      [110.6554, -7.9517],
      [110.6553, -7.9516],
      [110.6553, -7.9515],
      [110.6552, -7.9514],
      [110.6557, -7.9515],
      [110.6557, -7.9512],
      [110.6558, -7.9509],
      [110.6558, -7.95],
      [110.6557, -7.9477],
      [110.6557, -7.9445],
      [110.6549, -7.9441],
      [110.6547, -7.9441],
      [110.6545, -7.944],
      [110.6545, -7.9439],
      [110.6543, -7.9436],
      [110.6542, -7.9436],
      [110.6541, -7.9436],
      [110.6539, -7.9435],
      [110.6537, -7.9436],
      [110.6536, -7.9437],
      [110.6534, -7.944],
      [110.6532, -7.944],
      [110.6532, -7.9439],
      [110.6532, -7.9436],
      [110.6531, -7.9434],
      [110.6525, -7.9432],
      [110.6522, -7.9429],
      [110.6519, -7.9426],
      [110.6518, -7.9424]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'KELOR',
    border: [
      [110.6835, -7.9603],
      [110.6828, -7.9596],
      [110.6823, -7.9595],
      [110.6821, -7.9594],
      [110.6819, -7.9592],
      [110.6821, -7.959],
      [110.6821, -7.9586],
      [110.6821, -7.9584],
      [110.6817, -7.9582],
      [110.6813, -7.9582],
      [110.6811, -7.9578],
      [110.6805, -7.9577],
      [110.6802, -7.9574],
      [110.6799, -7.9572],
      [110.6799, -7.9571],
      [110.68, -7.9568],
      [110.6799, -7.9564],
      [110.6799, -7.9563],
      [110.6798, -7.9557],
      [110.6789, -7.9556],
      [110.6776, -7.9552],
      [110.6769, -7.955],
      [110.6741, -7.9542],
      [110.6737, -7.9544],
      [110.6717, -7.954],
      [110.6721, -7.9547],
      [110.6719, -7.9552],
      [110.6713, -7.9567],
      [110.6711, -7.9577],
      [110.6712, -7.9579],
      [110.6716, -7.9584],
      [110.6722, -7.9587],
      [110.6721, -7.959],
      [110.672, -7.9596],
      [110.6718, -7.96],
      [110.6714, -7.9601],
      [110.6713, -7.9603],
      [110.6706, -7.9599],
      [110.6706, -7.9607],
      [110.6699, -7.9612],
      [110.6698, -7.9615],
      [110.6699, -7.9624],
      [110.6696, -7.963],
      [110.6696, -7.9648],
      [110.6697, -7.966],
      [110.6697, -7.9663],
      [110.6694, -7.9662],
      [110.6691, -7.9662],
      [110.669, -7.9666],
      [110.6688, -7.9668],
      [110.6686, -7.9668],
      [110.6682, -7.9667],
      [110.6679, -7.9674],
      [110.6678, -7.9679],
      [110.6673, -7.968],
      [110.6669, -7.9682],
      [110.6666, -7.9684],
      [110.6664, -7.9687],
      [110.6663, -7.9691],
      [110.6659, -7.9698],
      [110.6659, -7.9704],
      [110.6657, -7.9708],
      [110.6656, -7.9713],
      [110.6655, -7.9714],
      [110.6654, -7.9718],
      [110.6653, -7.9723],
      [110.665, -7.9724],
      [110.6648, -7.9728],
      [110.6646, -7.973],
      [110.6641, -7.9732],
      [110.6637, -7.9732],
      [110.6635, -7.9736],
      [110.6636, -7.9739],
      [110.6637, -7.9742],
      [110.6637, -7.9744],
      [110.6637, -7.9747],
      [110.6636, -7.975],
      [110.6635, -7.9752],
      [110.6638, -7.9754],
      [110.6638, -7.9756],
      [110.6638, -7.9759],
      [110.6638, -7.976],
      [110.6638, -7.9761],
      [110.6639, -7.9761],
      [110.6642, -7.976],
      [110.6643, -7.976],
      [110.6647, -7.9761],
      [110.6654, -7.9763],
      [110.6663, -7.9768],
      [110.6665, -7.9762],
      [110.667, -7.976],
      [110.6674, -7.976],
      [110.6679, -7.976],
      [110.6681, -7.9759],
      [110.6685, -7.9758],
      [110.669, -7.9759],
      [110.6694, -7.9756],
      [110.6698, -7.9755],
      [110.6705, -7.9758],
      [110.6707, -7.9758],
      [110.6711, -7.9757],
      [110.6714, -7.9755],
      [110.6717, -7.9754],
      [110.6719, -7.9753],
      [110.6724, -7.9754],
      [110.6725, -7.9753],
      [110.6726, -7.9752],
      [110.6727, -7.9751],
      [110.6735, -7.9754],
      [110.6735, -7.9753],
      [110.674, -7.9749],
      [110.6744, -7.975],
      [110.6747, -7.9749],
      [110.6756, -7.9755],
      [110.6757, -7.9756],
      [110.6757, -7.9757],
      [110.6761, -7.9758],
      [110.6765, -7.9765],
      [110.6779, -7.977],
      [110.6784, -7.9769],
      [110.6789, -7.9772],
      [110.6792, -7.9775],
      [110.6792, -7.9778],
      [110.6792, -7.9782],
      [110.6794, -7.9784],
      [110.6805, -7.9788],
      [110.6805, -7.9791],
      [110.6805, -7.9792],
      [110.6805, -7.9794],
      [110.6804, -7.9796],
      [110.6803, -7.9797],
      [110.6804, -7.98],
      [110.6809, -7.98],
      [110.6817, -7.9802],
      [110.6822, -7.9803],
      [110.6828, -7.9804],
      [110.683, -7.9805],
      [110.6849, -7.9801],
      [110.6865, -7.9798],
      [110.6892, -7.9796],
      [110.6912, -7.9798],
      [110.6926, -7.9801],
      [110.6927, -7.9797],
      [110.693, -7.9797],
      [110.6932, -7.9795],
      [110.6936, -7.9795],
      [110.6937, -7.9792],
      [110.6943, -7.9792],
      [110.6943, -7.9789],
      [110.6945, -7.9788],
      [110.6947, -7.9788],
      [110.695, -7.9784],
      [110.6952, -7.9784],
      [110.6955, -7.9783],
      [110.6961, -7.9784],
      [110.6965, -7.9786],
      [110.6974, -7.9788],
      [110.698, -7.9788],
      [110.6986, -7.9787],
      [110.6993, -7.979],
      [110.7004, -7.9791],
      [110.7012, -7.9792],
      [110.7014, -7.9785],
      [110.7012, -7.9779],
      [110.7014, -7.9772],
      [110.7018, -7.9767],
      [110.7019, -7.9764],
      [110.7022, -7.9759],
      [110.7022, -7.9755],
      [110.702, -7.9749],
      [110.7018, -7.9748],
      [110.7016, -7.9746],
      [110.7016, -7.9742],
      [110.7016, -7.9737],
      [110.701, -7.9737],
      [110.7007, -7.9739],
      [110.7006, -7.9742],
      [110.7006, -7.9745],
      [110.7001, -7.9746],
      [110.6997, -7.9746],
      [110.6994, -7.9748],
      [110.6988, -7.9748],
      [110.6982, -7.9748],
      [110.6979, -7.9746],
      [110.6979, -7.9742],
      [110.6981, -7.9736],
      [110.6982, -7.9729],
      [110.6983, -7.9724],
      [110.6986, -7.9711],
      [110.6984, -7.9711],
      [110.6971, -7.971],
      [110.6968, -7.9707],
      [110.6961, -7.9706],
      [110.6955, -7.9704],
      [110.6945, -7.9701],
      [110.6938, -7.9697],
      [110.693, -7.9693],
      [110.6924, -7.9692],
      [110.6924, -7.9694],
      [110.6914, -7.9692],
      [110.6913, -7.9692],
      [110.6912, -7.9693],
      [110.6906, -7.9689],
      [110.6903, -7.9689],
      [110.6901, -7.9692],
      [110.6901, -7.9693],
      [110.69, -7.9694],
      [110.6897, -7.9693],
      [110.6894, -7.9693],
      [110.6893, -7.9694],
      [110.6891, -7.9695],
      [110.6888, -7.9694],
      [110.6881, -7.9691],
      [110.6879, -7.9689],
      [110.6872, -7.9686],
      [110.6869, -7.9685],
      [110.6866, -7.9684],
      [110.6863, -7.9683],
      [110.6857, -7.9683],
      [110.6852, -7.9681],
      [110.6848, -7.9681],
      [110.684, -7.9677],
      [110.6832, -7.9679],
      [110.6827, -7.9679],
      [110.6823, -7.9677],
      [110.6819, -7.9675],
      [110.6816, -7.9672],
      [110.6811, -7.9671],
      [110.6807, -7.9667],
      [110.6805, -7.9666],
      [110.6815, -7.9639],
      [110.682, -7.9623],
      [110.6823, -7.9614],
      [110.6825, -7.9611],
      [110.683, -7.9609],
      [110.6835, -7.9605],
      [110.6835, -7.9603]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'NGIPAK',
    border: [
      [110.638, -7.9531],
      [110.6359, -7.9528],
      [110.6341, -7.9526],
      [110.6337, -7.9528],
      [110.6335, -7.9533],
      [110.6329, -7.9533],
      [110.6322, -7.953],
      [110.6313, -7.9526],
      [110.6297, -7.9521],
      [110.6275, -7.9518],
      [110.6271, -7.9515],
      [110.6269, -7.9519],
      [110.6268, -7.9527],
      [110.6269, -7.953],
      [110.6269, -7.9534],
      [110.6269, -7.9536],
      [110.6262, -7.9539],
      [110.626, -7.9573],
      [110.6255, -7.9585],
      [110.6249, -7.96],
      [110.6241, -7.96],
      [110.6239, -7.9612],
      [110.6238, -7.9621],
      [110.6235, -7.9637],
      [110.6233, -7.965],
      [110.6234, -7.9654],
      [110.6237, -7.9659],
      [110.6239, -7.9661],
      [110.6251, -7.9669],
      [110.6257, -7.9686],
      [110.626, -7.9693],
      [110.6272, -7.9689],
      [110.6287, -7.9687],
      [110.6287, -7.9695],
      [110.6286, -7.9698],
      [110.6285, -7.9703],
      [110.6286, -7.9705],
      [110.6287, -7.9711],
      [110.6287, -7.9712],
      [110.6289, -7.9719],
      [110.6293, -7.9722],
      [110.63, -7.9728],
      [110.6305, -7.9734],
      [110.6303, -7.9745],
      [110.6299, -7.9745],
      [110.6299, -7.9757],
      [110.6298, -7.9762],
      [110.6299, -7.9774],
      [110.6304, -7.9776],
      [110.6303, -7.9785],
      [110.6302, -7.9795],
      [110.6303, -7.9801],
      [110.6302, -7.981],
      [110.6301, -7.9821],
      [110.6309, -7.9828],
      [110.6317, -7.9835],
      [110.6322, -7.9833],
      [110.6326, -7.9829],
      [110.6327, -7.983],
      [110.633, -7.9832],
      [110.6334, -7.9833],
      [110.6335, -7.9827],
      [110.6339, -7.9825],
      [110.6343, -7.9826],
      [110.6346, -7.9827],
      [110.635, -7.9827],
      [110.6351, -7.9828],
      [110.6357, -7.9835],
      [110.636, -7.9836],
      [110.6375, -7.984],
      [110.6379, -7.984],
      [110.6386, -7.9837],
      [110.6391, -7.9832],
      [110.6393, -7.9826],
      [110.6394, -7.9814],
      [110.6394, -7.9803],
      [110.6394, -7.9798],
      [110.6394, -7.9792],
      [110.6396, -7.9787],
      [110.6402, -7.9786],
      [110.6407, -7.9785],
      [110.641, -7.977],
      [110.6414, -7.975],
      [110.6416, -7.9746],
      [110.6414, -7.9739],
      [110.6413, -7.9734],
      [110.6414, -7.9729],
      [110.6419, -7.9719],
      [110.6423, -7.9711],
      [110.6423, -7.9705],
      [110.6422, -7.97],
      [110.6423, -7.9687],
      [110.6422, -7.9676],
      [110.6417, -7.9668],
      [110.6411, -7.9661],
      [110.6404, -7.9659],
      [110.6395, -7.9658],
      [110.6385, -7.9657],
      [110.6371, -7.9655],
      [110.6356, -7.9655],
      [110.635, -7.9657],
      [110.634, -7.9661],
      [110.6334, -7.9665],
      [110.6324, -7.9669],
      [110.6313, -7.9672],
      [110.6307, -7.9674],
      [110.6301, -7.9673],
      [110.6299, -7.9671],
      [110.6297, -7.9668],
      [110.6296, -7.9666],
      [110.6298, -7.9664],
      [110.63, -7.9663],
      [110.6305, -7.9662],
      [110.6308, -7.966],
      [110.6309, -7.9658],
      [110.631, -7.9654],
      [110.6311, -7.9649],
      [110.6312, -7.9635],
      [110.6314, -7.9633],
      [110.6314, -7.9632],
      [110.6315, -7.9625],
      [110.6315, -7.962],
      [110.6319, -7.9614],
      [110.6319, -7.9611],
      [110.6321, -7.9605],
      [110.6322, -7.9603],
      [110.6324, -7.9596],
      [110.6326, -7.9594],
      [110.6329, -7.959],
      [110.6334, -7.959],
      [110.6337, -7.9588],
      [110.6339, -7.9585],
      [110.634, -7.9581],
      [110.6343, -7.9581],
      [110.6345, -7.9579],
      [110.635, -7.9575],
      [110.6352, -7.9572],
      [110.6353, -7.9566],
      [110.6356, -7.9553],
      [110.637, -7.9554],
      [110.6373, -7.9551],
      [110.638, -7.9536],
      [110.638, -7.9531]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'KARANGMOJO',
    border: [
      [110.709, -7.9292],
      [110.7087, -7.9292],
      [110.7083, -7.9297],
      [110.7078, -7.9301],
      [110.707, -7.9304],
      [110.7063, -7.9307],
      [110.7059, -7.931],
      [110.705, -7.931],
      [110.7047, -7.9309],
      [110.7042, -7.9304],
      [110.7036, -7.9304],
      [110.7026, -7.9303],
      [110.7021, -7.9307],
      [110.7013, -7.9311],
      [110.7004, -7.9313],
      [110.6995, -7.9318],
      [110.6987, -7.9318],
      [110.6979, -7.932],
      [110.6972, -7.9318],
      [110.6956, -7.9313],
      [110.694, -7.9309],
      [110.6938, -7.9308],
      [110.6939, -7.9306],
      [110.694, -7.9305],
      [110.6941, -7.9303],
      [110.6942, -7.93],
      [110.6943, -7.9299],
      [110.6943, -7.9295],
      [110.6938, -7.9291],
      [110.6934, -7.9288],
      [110.6927, -7.9287],
      [110.6929, -7.9278],
      [110.6932, -7.9267],
      [110.6935, -7.9265],
      [110.6935, -7.926],
      [110.6934, -7.9257],
      [110.6932, -7.9257],
      [110.6928, -7.9256],
      [110.6922, -7.9253],
      [110.6911, -7.925],
      [110.6898, -7.9249],
      [110.6888, -7.9246],
      [110.6889, -7.9243],
      [110.6892, -7.9239],
      [110.6893, -7.9236],
      [110.6894, -7.9233],
      [110.6898, -7.923],
      [110.6898, -7.9227],
      [110.6897, -7.9226],
      [110.6896, -7.9223],
      [110.6895, -7.9222],
      [110.6895, -7.922],
      [110.6891, -7.9218],
      [110.689, -7.9217],
      [110.6889, -7.9214],
      [110.6888, -7.9213],
      [110.6887, -7.9209],
      [110.6882, -7.9207],
      [110.6877, -7.9204],
      [110.6872, -7.9206],
      [110.6866, -7.9205],
      [110.6863, -7.9209],
      [110.6862, -7.9211],
      [110.686, -7.9213],
      [110.6856, -7.9213],
      [110.6852, -7.9212],
      [110.6847, -7.9212],
      [110.6843, -7.9211],
      [110.6838, -7.9207],
      [110.6834, -7.9208],
      [110.683, -7.921],
      [110.683, -7.9211],
      [110.6828, -7.9215],
      [110.6827, -7.9219],
      [110.6824, -7.9219],
      [110.6823, -7.9217],
      [110.6816, -7.9215],
      [110.6815, -7.9216],
      [110.6811, -7.9217],
      [110.6807, -7.9219],
      [110.6801, -7.9219],
      [110.68, -7.922],
      [110.68, -7.9223],
      [110.6796, -7.9222],
      [110.6793, -7.9223],
      [110.679, -7.9227],
      [110.679, -7.9231],
      [110.679, -7.9232],
      [110.6782, -7.9235],
      [110.6781, -7.9234],
      [110.6777, -7.9232],
      [110.6773, -7.9232],
      [110.6769, -7.9234],
      [110.6761, -7.9225],
      [110.6759, -7.9226],
      [110.6754, -7.9226],
      [110.6753, -7.9224],
      [110.6754, -7.9221],
      [110.6755, -7.9219],
      [110.6754, -7.9217],
      [110.675, -7.9217],
      [110.6745, -7.9217],
      [110.6734, -7.9215],
      [110.673, -7.9214],
      [110.6728, -7.9217],
      [110.6727, -7.9223],
      [110.6729, -7.9227],
      [110.6728, -7.9232],
      [110.6726, -7.9238],
      [110.6726, -7.9241],
      [110.6724, -7.9248],
      [110.672, -7.9254],
      [110.6718, -7.9261],
      [110.6713, -7.9261],
      [110.6711, -7.9263],
      [110.6711, -7.9265],
      [110.6711, -7.9267],
      [110.6715, -7.9266],
      [110.672, -7.9265],
      [110.6724, -7.9265],
      [110.6729, -7.9268],
      [110.6736, -7.9274],
      [110.6745, -7.928],
      [110.6752, -7.9284],
      [110.6755, -7.9289],
      [110.6759, -7.9292],
      [110.676, -7.9292],
      [110.6766, -7.9292],
      [110.6775, -7.9295],
      [110.6779, -7.9295],
      [110.6783, -7.9299],
      [110.6787, -7.9305],
      [110.6787, -7.931],
      [110.6784, -7.9314],
      [110.6782, -7.9322],
      [110.6782, -7.9327],
      [110.6784, -7.9332],
      [110.6788, -7.9336],
      [110.6788, -7.9339],
      [110.6786, -7.9341],
      [110.678, -7.9342],
      [110.6778, -7.9338],
      [110.6775, -7.9334],
      [110.6768, -7.9334],
      [110.6764, -7.9337],
      [110.6756, -7.9335],
      [110.6746, -7.9334],
      [110.6738, -7.9333],
      [110.6734, -7.9329],
      [110.673, -7.9326],
      [110.6727, -7.9327],
      [110.6725, -7.9337],
      [110.6723, -7.935],
      [110.6726, -7.9362],
      [110.673, -7.9366],
      [110.6733, -7.9374],
      [110.6738, -7.9391],
      [110.6743, -7.9403],
      [110.6741, -7.941],
      [110.6735, -7.9434],
      [110.6733, -7.9435],
      [110.6732, -7.9439],
      [110.6725, -7.9437],
      [110.672, -7.9443],
      [110.6713, -7.9451],
      [110.6709, -7.9458],
      [110.6708, -7.9463],
      [110.6707, -7.9466],
      [110.6707, -7.9471],
      [110.6707, -7.9473],
      [110.6708, -7.9478],
      [110.6708, -7.9479],
      [110.6707, -7.9483],
      [110.6709, -7.9488],
      [110.6711, -7.949],
      [110.671, -7.9495],
      [110.6709, -7.95],
      [110.6711, -7.9504],
      [110.6709, -7.9513],
      [110.6707, -7.9522],
      [110.6707, -7.9524],
      [110.6707, -7.9527],
      [110.6705, -7.9536],
      [110.6717, -7.954],
      [110.6737, -7.9544],
      [110.6741, -7.9542],
      [110.6769, -7.955],
      [110.6776, -7.9552],
      [110.6789, -7.9556],
      [110.6798, -7.9557],
      [110.6799, -7.9563],
      [110.6799, -7.9564],
      [110.68, -7.9568],
      [110.6799, -7.9571],
      [110.6799, -7.9572],
      [110.6802, -7.9574],
      [110.6805, -7.9577],
      [110.6811, -7.9578],
      [110.6813, -7.9582],
      [110.6817, -7.9582],
      [110.6821, -7.9584],
      [110.6821, -7.9586],
      [110.6821, -7.959],
      [110.6819, -7.9592],
      [110.6821, -7.9594],
      [110.6823, -7.9595],
      [110.6828, -7.9596],
      [110.6835, -7.9603],
      [110.6835, -7.9601],
      [110.6839, -7.957],
      [110.684, -7.9563],
      [110.684, -7.9558],
      [110.6842, -7.955],
      [110.6843, -7.9542],
      [110.6855, -7.9531],
      [110.6859, -7.9523],
      [110.6862, -7.9516],
      [110.6861, -7.9513],
      [110.6863, -7.9509],
      [110.6866, -7.9503],
      [110.6862, -7.9498],
      [110.6861, -7.9497],
      [110.6859, -7.9482],
      [110.6858, -7.9449],
      [110.6857, -7.9435],
      [110.6859, -7.9422],
      [110.6864, -7.9411],
      [110.6866, -7.9401],
      [110.687, -7.9387],
      [110.6873, -7.9382],
      [110.6872, -7.9377],
      [110.6872, -7.9372],
      [110.6873, -7.9367],
      [110.6877, -7.9366],
      [110.6882, -7.9366],
      [110.6887, -7.9363],
      [110.689, -7.9366],
      [110.6893, -7.9366],
      [110.6898, -7.9366],
      [110.6897, -7.9372],
      [110.6896, -7.9375],
      [110.6895, -7.9376],
      [110.6901, -7.9379],
      [110.6907, -7.9382],
      [110.6912, -7.9384],
      [110.6917, -7.9384],
      [110.6927, -7.9381],
      [110.6932, -7.9383],
      [110.693, -7.9387],
      [110.6929, -7.9393],
      [110.6932, -7.9395],
      [110.6934, -7.9397],
      [110.6955, -7.9404],
      [110.6955, -7.9407],
      [110.6961, -7.9408],
      [110.6962, -7.9403],
      [110.6974, -7.9403],
      [110.6976, -7.9397],
      [110.6981, -7.9402],
      [110.699, -7.9401],
      [110.6995, -7.9397],
      [110.6997, -7.9397],
      [110.7016, -7.9405],
      [110.7031, -7.9411],
      [110.7032, -7.9413],
      [110.7035, -7.9415],
      [110.7033, -7.9425],
      [110.7035, -7.9425],
      [110.7038, -7.9426],
      [110.7044, -7.9426],
      [110.7048, -7.9428],
      [110.7054, -7.9429],
      [110.7058, -7.9429],
      [110.7068, -7.9424],
      [110.7077, -7.9422],
      [110.7089, -7.9418],
      [110.7102, -7.941],
      [110.7112, -7.9399],
      [110.7118, -7.9395],
      [110.7127, -7.9389],
      [110.713, -7.9385],
      [110.7127, -7.9381],
      [110.7123, -7.9377],
      [110.7121, -7.9369],
      [110.7121, -7.9365],
      [110.7119, -7.9356],
      [110.7115, -7.9352],
      [110.7106, -7.9348],
      [110.7101, -7.9344],
      [110.7101, -7.9342],
      [110.7101, -7.9341],
      [110.7102, -7.9337],
      [110.7099, -7.9331],
      [110.7097, -7.9323],
      [110.7094, -7.932],
      [110.7093, -7.9315],
      [110.7094, -7.931],
      [110.7095, -7.9306],
      [110.7091, -7.9302],
      [110.709, -7.9296],
      [110.709, -7.9292]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'GEDANGREJO',
    border: [
      [110.7054, -7.9429],
      [110.7048, -7.9428],
      [110.7044, -7.9426],
      [110.7038, -7.9426],
      [110.7035, -7.9425],
      [110.7033, -7.9425],
      [110.7035, -7.9415],
      [110.7032, -7.9413],
      [110.7031, -7.9411],
      [110.7016, -7.9405],
      [110.6997, -7.9397],
      [110.6995, -7.9397],
      [110.699, -7.9401],
      [110.6981, -7.9402],
      [110.6976, -7.9397],
      [110.6974, -7.9403],
      [110.6962, -7.9403],
      [110.6961, -7.9408],
      [110.6955, -7.9407],
      [110.6955, -7.9404],
      [110.6934, -7.9397],
      [110.6932, -7.9395],
      [110.6929, -7.9393],
      [110.693, -7.9387],
      [110.6932, -7.9383],
      [110.6927, -7.9381],
      [110.6917, -7.9384],
      [110.6912, -7.9384],
      [110.6907, -7.9382],
      [110.6901, -7.9379],
      [110.6895, -7.9376],
      [110.6896, -7.9375],
      [110.6897, -7.9372],
      [110.6898, -7.9366],
      [110.6893, -7.9366],
      [110.689, -7.9366],
      [110.6887, -7.9363],
      [110.6882, -7.9366],
      [110.6877, -7.9366],
      [110.6873, -7.9367],
      [110.6872, -7.9372],
      [110.6872, -7.9377],
      [110.6873, -7.9382],
      [110.687, -7.9387],
      [110.6866, -7.9401],
      [110.6864, -7.9411],
      [110.6859, -7.9422],
      [110.6857, -7.9435],
      [110.6858, -7.9449],
      [110.6859, -7.9482],
      [110.6861, -7.9497],
      [110.6862, -7.9498],
      [110.6866, -7.9503],
      [110.6863, -7.9509],
      [110.6861, -7.9513],
      [110.6862, -7.9516],
      [110.6859, -7.9523],
      [110.6855, -7.9531],
      [110.6843, -7.9542],
      [110.6842, -7.955],
      [110.684, -7.9558],
      [110.684, -7.9563],
      [110.6839, -7.957],
      [110.6835, -7.9601],
      [110.6835, -7.9603],
      [110.6835, -7.9605],
      [110.683, -7.9609],
      [110.6825, -7.9611],
      [110.6823, -7.9614],
      [110.682, -7.9623],
      [110.6815, -7.9639],
      [110.6805, -7.9666],
      [110.6807, -7.9667],
      [110.6811, -7.9671],
      [110.6816, -7.9672],
      [110.6819, -7.9675],
      [110.6823, -7.9677],
      [110.6827, -7.9679],
      [110.6832, -7.9679],
      [110.684, -7.9677],
      [110.6848, -7.9681],
      [110.6852, -7.9681],
      [110.6857, -7.9683],
      [110.6863, -7.9683],
      [110.6866, -7.9684],
      [110.6869, -7.9685],
      [110.6872, -7.9686],
      [110.6879, -7.9689],
      [110.6881, -7.9691],
      [110.6888, -7.9694],
      [110.6891, -7.9695],
      [110.6893, -7.9694],
      [110.6894, -7.9693],
      [110.6897, -7.9693],
      [110.69, -7.9694],
      [110.6901, -7.9693],
      [110.6901, -7.9692],
      [110.6903, -7.9689],
      [110.6906, -7.9689],
      [110.6912, -7.9693],
      [110.6913, -7.9692],
      [110.6914, -7.9692],
      [110.6924, -7.9694],
      [110.6924, -7.9692],
      [110.693, -7.9693],
      [110.6938, -7.9697],
      [110.6945, -7.9701],
      [110.6955, -7.9704],
      [110.6961, -7.9706],
      [110.6968, -7.9707],
      [110.6971, -7.971],
      [110.6984, -7.9711],
      [110.6986, -7.9711],
      [110.6988, -7.9701],
      [110.6991, -7.9698],
      [110.7, -7.9692],
      [110.7009, -7.9686],
      [110.7011, -7.968],
      [110.7013, -7.9673],
      [110.7017, -7.9672],
      [110.702, -7.9672],
      [110.7022, -7.9671],
      [110.7025, -7.967],
      [110.7028, -7.9671],
      [110.7029, -7.9668],
      [110.703, -7.9661],
      [110.703, -7.9659],
      [110.703, -7.9653],
      [110.7029, -7.9642],
      [110.7029, -7.9641],
      [110.7028, -7.9624],
      [110.7025, -7.9592],
      [110.7023, -7.9576],
      [110.7023, -7.9571],
      [110.7024, -7.9568],
      [110.7025, -7.9565],
      [110.7027, -7.956],
      [110.7027, -7.9555],
      [110.7026, -7.9551],
      [110.7029, -7.9549],
      [110.7028, -7.9544],
      [110.7028, -7.954],
      [110.7025, -7.9536],
      [110.7019, -7.9531],
      [110.7017, -7.9524],
      [110.7015, -7.9515],
      [110.7013, -7.9511],
      [110.7012, -7.9508],
      [110.7004, -7.9506],
      [110.7006, -7.9501],
      [110.7008, -7.9495],
      [110.7006, -7.9489],
      [110.7006, -7.9488],
      [110.7007, -7.9485],
      [110.7008, -7.9484],
      [110.7008, -7.9482],
      [110.7009, -7.9479],
      [110.7012, -7.9474],
      [110.7013, -7.9468],
      [110.7014, -7.9465],
      [110.7014, -7.946],
      [110.7017, -7.9457],
      [110.702, -7.9458],
      [110.7021, -7.9456],
      [110.7026, -7.9456],
      [110.7029, -7.9445],
      [110.7031, -7.9445],
      [110.7033, -7.9446],
      [110.7035, -7.9446],
      [110.7036, -7.9446],
      [110.7038, -7.9444],
      [110.7039, -7.9444],
      [110.7044, -7.9444],
      [110.7045, -7.9443],
      [110.7045, -7.9441],
      [110.7049, -7.9441],
      [110.705, -7.9439],
      [110.7051, -7.9437],
      [110.7052, -7.9437],
      [110.7053, -7.9436],
      [110.7054, -7.9435],
      [110.7055, -7.9433],
      [110.7057, -7.943],
      [110.7054, -7.9429]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'NGAWIS',
    border: [
      [110.6754, -7.9059],
      [110.6753, -7.9058],
      [110.675, -7.9051],
      [110.6746, -7.9039],
      [110.6744, -7.9032],
      [110.6733, -7.9031],
      [110.6724, -7.9034],
      [110.6712, -7.9039],
      [110.6705, -7.904],
      [110.6692, -7.9043],
      [110.6686, -7.9043],
      [110.6672, -7.9044],
      [110.6654, -7.9042],
      [110.6644, -7.9033],
      [110.6642, -7.9039],
      [110.664, -7.9045],
      [110.6645, -7.9048],
      [110.6656, -7.9056],
      [110.6652, -7.9066],
      [110.665, -7.9077],
      [110.6649, -7.9085],
      [110.6651, -7.9091],
      [110.6655, -7.91],
      [110.6657, -7.9108],
      [110.6655, -7.912],
      [110.6653, -7.9131],
      [110.6651, -7.9137],
      [110.6649, -7.9149],
      [110.6647, -7.9158],
      [110.6647, -7.9164],
      [110.6647, -7.9168],
      [110.664, -7.9176],
      [110.6638, -7.918],
      [110.6633, -7.9187],
      [110.6627, -7.9199],
      [110.6623, -7.9208],
      [110.6623, -7.9216],
      [110.6623, -7.9218],
      [110.6623, -7.9225],
      [110.6625, -7.9228],
      [110.6625, -7.9232],
      [110.6625, -7.9234],
      [110.6623, -7.9237],
      [110.6623, -7.9241],
      [110.6624, -7.9249],
      [110.6624, -7.9254],
      [110.6621, -7.926],
      [110.6614, -7.9267],
      [110.6611, -7.9272],
      [110.6606, -7.9278],
      [110.6602, -7.9294],
      [110.6599, -7.9308],
      [110.6597, -7.9312],
      [110.6596, -7.9324],
      [110.6596, -7.9343],
      [110.6596, -7.9359],
      [110.6593, -7.937],
      [110.6591, -7.9376],
      [110.6587, -7.9392],
      [110.6582, -7.9402],
      [110.6575, -7.9414],
      [110.6572, -7.9416],
      [110.6566, -7.9417],
      [110.6557, -7.9417],
      [110.6552, -7.9419],
      [110.6545, -7.9419],
      [110.6536, -7.9418],
      [110.6528, -7.9416],
      [110.6524, -7.9413],
      [110.652, -7.9413],
      [110.6517, -7.9412],
      [110.6512, -7.9415],
      [110.6514, -7.9417],
      [110.6516, -7.942],
      [110.6518, -7.9424],
      [110.6519, -7.9426],
      [110.6522, -7.9429],
      [110.6525, -7.9432],
      [110.6531, -7.9434],
      [110.6532, -7.9436],
      [110.6532, -7.9439],
      [110.6532, -7.944],
      [110.6534, -7.944],
      [110.6536, -7.9437],
      [110.6537, -7.9436],
      [110.6539, -7.9435],
      [110.6541, -7.9436],
      [110.6542, -7.9436],
      [110.6543, -7.9436],
      [110.6545, -7.9439],
      [110.6545, -7.944],
      [110.6547, -7.9441],
      [110.6549, -7.9441],
      [110.6557, -7.9445],
      [110.6557, -7.9477],
      [110.6558, -7.95],
      [110.6585, -7.9504],
      [110.6594, -7.9505],
      [110.6599, -7.9523],
      [110.6611, -7.952],
      [110.6626, -7.9515],
      [110.6631, -7.9515],
      [110.6676, -7.9519],
      [110.6699, -7.9524],
      [110.6707, -7.9524],
      [110.6707, -7.9522],
      [110.6709, -7.9513],
      [110.6711, -7.9504],
      [110.6709, -7.95],
      [110.671, -7.9495],
      [110.6711, -7.949],
      [110.6709, -7.9488],
      [110.6707, -7.9483],
      [110.6708, -7.9479],
      [110.6708, -7.9478],
      [110.6707, -7.9473],
      [110.6707, -7.9471],
      [110.6707, -7.9466],
      [110.6708, -7.9463],
      [110.6709, -7.9458],
      [110.6713, -7.9451],
      [110.672, -7.9443],
      [110.6725, -7.9437],
      [110.6732, -7.9439],
      [110.6733, -7.9435],
      [110.6735, -7.9434],
      [110.6741, -7.941],
      [110.6743, -7.9403],
      [110.6738, -7.9391],
      [110.6733, -7.9374],
      [110.673, -7.9366],
      [110.6726, -7.9362],
      [110.6723, -7.935],
      [110.6725, -7.9337],
      [110.6727, -7.9327],
      [110.673, -7.9326],
      [110.6734, -7.9329],
      [110.6738, -7.9333],
      [110.6746, -7.9334],
      [110.6756, -7.9335],
      [110.6764, -7.9337],
      [110.6768, -7.9334],
      [110.6775, -7.9334],
      [110.6778, -7.9338],
      [110.678, -7.9342],
      [110.6786, -7.9341],
      [110.6788, -7.9339],
      [110.6788, -7.9336],
      [110.6784, -7.9332],
      [110.6782, -7.9327],
      [110.6782, -7.9322],
      [110.6784, -7.9314],
      [110.6787, -7.931],
      [110.6787, -7.9305],
      [110.6783, -7.9299],
      [110.6779, -7.9295],
      [110.6775, -7.9295],
      [110.6766, -7.9292],
      [110.676, -7.9292],
      [110.6759, -7.9292],
      [110.6755, -7.9289],
      [110.6752, -7.9284],
      [110.6745, -7.928],
      [110.6736, -7.9274],
      [110.6729, -7.9268],
      [110.6724, -7.9265],
      [110.672, -7.9265],
      [110.6715, -7.9266],
      [110.6711, -7.9267],
      [110.6711, -7.9265],
      [110.6711, -7.9263],
      [110.6713, -7.9261],
      [110.6718, -7.9261],
      [110.672, -7.9254],
      [110.6724, -7.9248],
      [110.6726, -7.9241],
      [110.6726, -7.9238],
      [110.6728, -7.9232],
      [110.6729, -7.9227],
      [110.6727, -7.9223],
      [110.6728, -7.9217],
      [110.673, -7.9214],
      [110.673, -7.9209],
      [110.6732, -7.9198],
      [110.6735, -7.9196],
      [110.6736, -7.9181],
      [110.6737, -7.9171],
      [110.6735, -7.917],
      [110.6731, -7.917],
      [110.6725, -7.9168],
      [110.6722, -7.9166],
      [110.672, -7.9163],
      [110.6721, -7.9154],
      [110.6724, -7.9142],
      [110.6727, -7.9134],
      [110.6732, -7.9125],
      [110.6737, -7.9113],
      [110.6749, -7.9089],
      [110.6747, -7.9085],
      [110.6744, -7.9079],
      [110.6744, -7.9075],
      [110.6745, -7.9069],
      [110.6749, -7.9064],
      [110.6754, -7.9059]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'KARANGMOJO',
    village: 'JATIAYU',
    border: [
      [110.6912, -7.8868],
      [110.6908, -7.8867],
      [110.6902, -7.8867],
      [110.6897, -7.8866],
      [110.6885, -7.8863],
      [110.6872, -7.8858],
      [110.6865, -7.8852],
      [110.6861, -7.885],
      [110.6851, -7.8843],
      [110.6836, -7.8837],
      [110.6829, -7.8833],
      [110.6823, -7.8831],
      [110.6819, -7.883],
      [110.6811, -7.8831],
      [110.6812, -7.8833],
      [110.6813, -7.884],
      [110.6812, -7.8843],
      [110.6804, -7.8861],
      [110.681, -7.887],
      [110.6827, -7.8896],
      [110.6838, -7.89],
      [110.6843, -7.8897],
      [110.6846, -7.8894],
      [110.6847, -7.8895],
      [110.6848, -7.8896],
      [110.6847, -7.8897],
      [110.6845, -7.8901],
      [110.6841, -7.8921],
      [110.6833, -7.8925],
      [110.681, -7.8939],
      [110.6805, -7.8941],
      [110.6799, -7.8945],
      [110.6799, -7.8955],
      [110.6801, -7.8955],
      [110.6832, -7.8957],
      [110.6831, -7.8973],
      [110.6831, -7.8975],
      [110.6831, -7.8977],
      [110.683, -7.8993],
      [110.6807, -7.8994],
      [110.6777, -7.8994],
      [110.6773, -7.8998],
      [110.6773, -7.9003],
      [110.6776, -7.9015],
      [110.6777, -7.9018],
      [110.6775, -7.902],
      [110.6767, -7.9025],
      [110.676, -7.9028],
      [110.6765, -7.9036],
      [110.6765, -7.904],
      [110.6765, -7.9046],
      [110.6764, -7.9051],
      [110.676, -7.9056],
      [110.6755, -7.9061],
      [110.6754, -7.9059],
      [110.6749, -7.9064],
      [110.6745, -7.9069],
      [110.6744, -7.9075],
      [110.6744, -7.9079],
      [110.6747, -7.9085],
      [110.6749, -7.9089],
      [110.6737, -7.9113],
      [110.6732, -7.9125],
      [110.6727, -7.9134],
      [110.6724, -7.9142],
      [110.6721, -7.9154],
      [110.672, -7.9163],
      [110.6722, -7.9166],
      [110.6725, -7.9168],
      [110.6731, -7.917],
      [110.6735, -7.917],
      [110.6737, -7.9171],
      [110.6736, -7.9181],
      [110.6735, -7.9196],
      [110.6732, -7.9198],
      [110.673, -7.9209],
      [110.673, -7.9214],
      [110.6734, -7.9215],
      [110.6745, -7.9217],
      [110.675, -7.9217],
      [110.6754, -7.9217],
      [110.6755, -7.9219],
      [110.6754, -7.9221],
      [110.6753, -7.9224],
      [110.6754, -7.9226],
      [110.6759, -7.9226],
      [110.6761, -7.9225],
      [110.6769, -7.9234],
      [110.6773, -7.9232],
      [110.6777, -7.9232],
      [110.6781, -7.9234],
      [110.6782, -7.9235],
      [110.679, -7.9232],
      [110.679, -7.9231],
      [110.679, -7.9227],
      [110.6793, -7.9223],
      [110.6796, -7.9222],
      [110.68, -7.9223],
      [110.68, -7.922],
      [110.6801, -7.9219],
      [110.6807, -7.9219],
      [110.6811, -7.9217],
      [110.6815, -7.9216],
      [110.6816, -7.9215],
      [110.6823, -7.9217],
      [110.6824, -7.9219],
      [110.6827, -7.9219],
      [110.6828, -7.9215],
      [110.683, -7.9211],
      [110.683, -7.921],
      [110.6834, -7.9208],
      [110.6838, -7.9207],
      [110.6843, -7.9211],
      [110.6847, -7.9212],
      [110.6852, -7.9212],
      [110.6856, -7.9213],
      [110.686, -7.9213],
      [110.6862, -7.9211],
      [110.6863, -7.9209],
      [110.6866, -7.9205],
      [110.6872, -7.9206],
      [110.6877, -7.9204],
      [110.6882, -7.9207],
      [110.6887, -7.9209],
      [110.6888, -7.9213],
      [110.6889, -7.9214],
      [110.689, -7.9217],
      [110.6891, -7.9218],
      [110.6895, -7.922],
      [110.6895, -7.9222],
      [110.6896, -7.9223],
      [110.6897, -7.9226],
      [110.6898, -7.9227],
      [110.6898, -7.923],
      [110.6894, -7.9233],
      [110.6893, -7.9236],
      [110.6892, -7.9239],
      [110.6889, -7.9243],
      [110.6888, -7.9246],
      [110.6898, -7.9249],
      [110.6911, -7.925],
      [110.6922, -7.9253],
      [110.6928, -7.9256],
      [110.6932, -7.9257],
      [110.6934, -7.9257],
      [110.6935, -7.926],
      [110.6935, -7.9265],
      [110.6932, -7.9267],
      [110.6929, -7.9278],
      [110.6927, -7.9287],
      [110.6934, -7.9288],
      [110.6938, -7.9291],
      [110.6943, -7.9295],
      [110.6943, -7.9299],
      [110.6942, -7.93],
      [110.6941, -7.9303],
      [110.694, -7.9305],
      [110.6939, -7.9306],
      [110.6938, -7.9308],
      [110.694, -7.9309],
      [110.6956, -7.9313],
      [110.6972, -7.9318],
      [110.6979, -7.932],
      [110.6987, -7.9318],
      [110.6995, -7.9318],
      [110.7004, -7.9313],
      [110.7013, -7.9311],
      [110.7021, -7.9307],
      [110.7026, -7.9303],
      [110.7036, -7.9304],
      [110.7042, -7.9304],
      [110.7047, -7.9309],
      [110.705, -7.931],
      [110.7059, -7.931],
      [110.7063, -7.9307],
      [110.707, -7.9304],
      [110.7078, -7.9301],
      [110.7083, -7.9297],
      [110.7087, -7.9292],
      [110.709, -7.9292],
      [110.709, -7.929],
      [110.7089, -7.9286],
      [110.709, -7.9282],
      [110.7092, -7.9278],
      [110.7093, -7.9278],
      [110.7096, -7.9275],
      [110.7092, -7.9265],
      [110.7081, -7.9241],
      [110.7083, -7.9239],
      [110.7086, -7.9239],
      [110.7088, -7.9235],
      [110.7089, -7.9233],
      [110.7088, -7.9229],
      [110.7103, -7.9229],
      [110.7103, -7.9215],
      [110.7104, -7.9192],
      [110.7104, -7.9184],
      [110.7102, -7.9168],
      [110.71, -7.9164],
      [110.7098, -7.916],
      [110.7098, -7.9156],
      [110.7095, -7.9152],
      [110.7089, -7.9152],
      [110.7086, -7.915],
      [110.7084, -7.9145],
      [110.7085, -7.9141],
      [110.7083, -7.9137],
      [110.7078, -7.9134],
      [110.7074, -7.9135],
      [110.7073, -7.9134],
      [110.7069, -7.9131],
      [110.7071, -7.9129],
      [110.7073, -7.9127],
      [110.7075, -7.9125],
      [110.7078, -7.9125],
      [110.7081, -7.9122],
      [110.7083, -7.9119],
      [110.7083, -7.9115],
      [110.7085, -7.9112],
      [110.7087, -7.9111],
      [110.709, -7.9112],
      [110.7092, -7.9112],
      [110.7092, -7.9109],
      [110.7092, -7.9106],
      [110.7092, -7.9104],
      [110.7092, -7.91],
      [110.7093, -7.9095],
      [110.7093, -7.9089],
      [110.7092, -7.9084],
      [110.709, -7.9079],
      [110.7099, -7.9078],
      [110.71, -7.9054],
      [110.71, -7.9046],
      [110.7103, -7.9039],
      [110.7103, -7.903],
      [110.7102, -7.9025],
      [110.71, -7.902],
      [110.7098, -7.9018],
      [110.7096, -7.9018],
      [110.7095, -7.9021],
      [110.7093, -7.9023],
      [110.7089, -7.9023],
      [110.7088, -7.9019],
      [110.7086, -7.9013],
      [110.7084, -7.9009],
      [110.7084, -7.9001],
      [110.7083, -7.8995],
      [110.708, -7.8986],
      [110.708, -7.8985],
      [110.7078, -7.898],
      [110.7076, -7.8975],
      [110.7073, -7.8967],
      [110.7072, -7.8961],
      [110.7072, -7.8958],
      [110.7066, -7.8955],
      [110.7063, -7.8953],
      [110.7059, -7.8947],
      [110.7059, -7.8941],
      [110.7056, -7.8938],
      [110.7052, -7.8934],
      [110.7047, -7.8929],
      [110.7044, -7.8925],
      [110.7037, -7.8921],
      [110.7032, -7.8919],
      [110.703, -7.8913],
      [110.7029, -7.891],
      [110.7025, -7.8908],
      [110.7023, -7.8904],
      [110.7023, -7.8903],
      [110.7023, -7.89],
      [110.7023, -7.89],
      [110.7026, -7.8897],
      [110.703, -7.8895],
      [110.7036, -7.8891],
      [110.7039, -7.8888],
      [110.7039, -7.8884],
      [110.7042, -7.888],
      [110.7042, -7.8876],
      [110.7042, -7.8872],
      [110.7037, -7.8869],
      [110.7031, -7.8866],
      [110.7028, -7.8863],
      [110.7026, -7.8862],
      [110.7024, -7.8861],
      [110.7016, -7.8862],
      [110.7013, -7.8862],
      [110.701, -7.8869],
      [110.7008, -7.887],
      [110.6998, -7.8866],
      [110.6997, -7.8866],
      [110.6992, -7.8874],
      [110.6983, -7.8887],
      [110.6976, -7.8895],
      [110.6965, -7.89],
      [110.6956, -7.8903],
      [110.6948, -7.8909],
      [110.6942, -7.8914],
      [110.6936, -7.8917],
      [110.6929, -7.8919],
      [110.6927, -7.8914],
      [110.6925, -7.8905],
      [110.6919, -7.8902],
      [110.6914, -7.8896],
      [110.6912, -7.8896],
      [110.6913, -7.8891],
      [110.6914, -7.8886],
      [110.6913, -7.8875],
      [110.6912, -7.8868]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'UMBULREJO',
    border: [
      [110.7322, -7.9061],
      [110.7322, -7.9062],
      [110.7318, -7.9063],
      [110.7314, -7.9065],
      [110.7312, -7.9065],
      [110.7312, -7.9062],
      [110.731, -7.9063],
      [110.7307, -7.9065],
      [110.7302, -7.9067],
      [110.73, -7.907],
      [110.7296, -7.9072],
      [110.7288, -7.907],
      [110.7284, -7.9068],
      [110.7282, -7.9064],
      [110.7281, -7.9059],
      [110.7279, -7.9054],
      [110.7276, -7.9054],
      [110.7272, -7.9053],
      [110.7267, -7.9055],
      [110.7267, -7.9053],
      [110.7267, -7.9049],
      [110.7265, -7.9047],
      [110.7263, -7.9044],
      [110.7261, -7.9044],
      [110.7259, -7.9043],
      [110.7256, -7.9045],
      [110.7254, -7.9047],
      [110.725, -7.9047],
      [110.7243, -7.9046],
      [110.7239, -7.9046],
      [110.7233, -7.9045],
      [110.7232, -7.9042],
      [110.7226, -7.9045],
      [110.7221, -7.9045],
      [110.7214, -7.9045],
      [110.7209, -7.9045],
      [110.7208, -7.9045],
      [110.7207, -7.9046],
      [110.7204, -7.9046],
      [110.72, -7.9043],
      [110.7199, -7.9039],
      [110.7197, -7.9034],
      [110.7197, -7.9028],
      [110.7197, -7.9024],
      [110.7199, -7.9019],
      [110.7197, -7.9017],
      [110.7195, -7.9016],
      [110.7191, -7.9017],
      [110.7189, -7.9019],
      [110.7191, -7.9023],
      [110.7191, -7.9023],
      [110.7188, -7.9024],
      [110.7183, -7.9023],
      [110.718, -7.9031],
      [110.7173, -7.9032],
      [110.7164, -7.9033],
      [110.7162, -7.9032],
      [110.7156, -7.9033],
      [110.7151, -7.9035],
      [110.7147, -7.9038],
      [110.7143, -7.9039],
      [110.7142, -7.9036],
      [110.7137, -7.904],
      [110.7134, -7.9037],
      [110.7131, -7.9036],
      [110.7126, -7.9033],
      [110.7127, -7.903],
      [110.7127, -7.9027],
      [110.712, -7.9024],
      [110.7118, -7.9025],
      [110.7102, -7.9025],
      [110.7103, -7.903],
      [110.7103, -7.9039],
      [110.71, -7.9046],
      [110.71, -7.9054],
      [110.7099, -7.9078],
      [110.709, -7.9079],
      [110.7092, -7.9084],
      [110.7093, -7.9089],
      [110.7093, -7.9095],
      [110.7092, -7.91],
      [110.7092, -7.9104],
      [110.7092, -7.9106],
      [110.7092, -7.9109],
      [110.7092, -7.9112],
      [110.709, -7.9112],
      [110.7087, -7.9111],
      [110.7085, -7.9112],
      [110.7083, -7.9115],
      [110.7083, -7.9119],
      [110.7081, -7.9122],
      [110.7078, -7.9125],
      [110.7075, -7.9125],
      [110.7073, -7.9127],
      [110.7071, -7.9129],
      [110.7069, -7.9131],
      [110.7073, -7.9134],
      [110.7074, -7.9135],
      [110.7078, -7.9134],
      [110.7083, -7.9137],
      [110.7085, -7.9141],
      [110.7084, -7.9145],
      [110.7086, -7.915],
      [110.7089, -7.9152],
      [110.7095, -7.9152],
      [110.7098, -7.9156],
      [110.7098, -7.916],
      [110.71, -7.9164],
      [110.7102, -7.9168],
      [110.7104, -7.9184],
      [110.7104, -7.9192],
      [110.7103, -7.9215],
      [110.7103, -7.9229],
      [110.7088, -7.9229],
      [110.7089, -7.9233],
      [110.7088, -7.9235],
      [110.7086, -7.9239],
      [110.7083, -7.9239],
      [110.7081, -7.9241],
      [110.7092, -7.9265],
      [110.7096, -7.9275],
      [110.7093, -7.9278],
      [110.7092, -7.9278],
      [110.709, -7.9282],
      [110.7089, -7.9286],
      [110.709, -7.929],
      [110.709, -7.9292],
      [110.709, -7.9296],
      [110.7091, -7.9302],
      [110.7095, -7.9306],
      [110.7094, -7.931],
      [110.7093, -7.9315],
      [110.7094, -7.932],
      [110.7097, -7.9323],
      [110.7099, -7.9331],
      [110.7102, -7.9337],
      [110.7101, -7.9341],
      [110.7101, -7.9342],
      [110.7101, -7.9344],
      [110.7106, -7.9348],
      [110.7115, -7.9352],
      [110.7119, -7.9356],
      [110.7121, -7.9365],
      [110.7121, -7.9369],
      [110.7123, -7.9377],
      [110.7127, -7.9381],
      [110.713, -7.9385],
      [110.7127, -7.9389],
      [110.7118, -7.9395],
      [110.7112, -7.9399],
      [110.7102, -7.941],
      [110.7089, -7.9418],
      [110.7077, -7.9422],
      [110.7068, -7.9424],
      [110.7058, -7.9429],
      [110.7055, -7.9433],
      [110.7054, -7.9435],
      [110.7053, -7.9436],
      [110.7052, -7.9437],
      [110.7051, -7.9437],
      [110.705, -7.9439],
      [110.7049, -7.9441],
      [110.7045, -7.9441],
      [110.7045, -7.9443],
      [110.7044, -7.9444],
      [110.7039, -7.9444],
      [110.7038, -7.9444],
      [110.7036, -7.9446],
      [110.7035, -7.9446],
      [110.7033, -7.9446],
      [110.7031, -7.9445],
      [110.7029, -7.9445],
      [110.7026, -7.9456],
      [110.7021, -7.9456],
      [110.702, -7.9458],
      [110.7017, -7.9457],
      [110.7014, -7.946],
      [110.7023, -7.9462],
      [110.7034, -7.9463],
      [110.7041, -7.9465],
      [110.7059, -7.9468],
      [110.7065, -7.9468],
      [110.7071, -7.9466],
      [110.7079, -7.9465],
      [110.7088, -7.9465],
      [110.7099, -7.9464],
      [110.7107, -7.946],
      [110.7114, -7.9457],
      [110.7118, -7.9457],
      [110.7137, -7.9472],
      [110.7139, -7.9474],
      [110.7138, -7.9476],
      [110.7137, -7.9477],
      [110.7138, -7.9484],
      [110.7142, -7.9486],
      [110.7143, -7.9492],
      [110.7151, -7.9517],
      [110.7152, -7.9527],
      [110.7152, -7.9535],
      [110.7143, -7.9537],
      [110.714, -7.9538],
      [110.7137, -7.9541],
      [110.7133, -7.9542],
      [110.7127, -7.9543],
      [110.7123, -7.9546],
      [110.7119, -7.9551],
      [110.7117, -7.9556],
      [110.7114, -7.956],
      [110.711, -7.9563],
      [110.7111, -7.9567],
      [110.7113, -7.9569],
      [110.7115, -7.9568],
      [110.7119, -7.9564],
      [110.7123, -7.9561],
      [110.7129, -7.9561],
      [110.7132, -7.9561],
      [110.7134, -7.9563],
      [110.7135, -7.9565],
      [110.7136, -7.957],
      [110.7135, -7.9576],
      [110.7142, -7.9578],
      [110.7162, -7.9585],
      [110.7175, -7.9588],
      [110.7184, -7.9581],
      [110.7182, -7.9578],
      [110.7184, -7.9575],
      [110.7185, -7.9575],
      [110.719, -7.9575],
      [110.7195, -7.9573],
      [110.7199, -7.9574],
      [110.7204, -7.9573],
      [110.721, -7.9566],
      [110.7216, -7.9562],
      [110.7217, -7.9555],
      [110.722, -7.9548],
      [110.7225, -7.9542],
      [110.7227, -7.9541],
      [110.7229, -7.9541],
      [110.7231, -7.954],
      [110.7233, -7.9539],
      [110.7243, -7.9532],
      [110.7251, -7.9528],
      [110.7251, -7.9528],
      [110.7256, -7.9526],
      [110.7264, -7.9523],
      [110.7275, -7.9516],
      [110.7303, -7.9501],
      [110.7331, -7.9485],
      [110.7338, -7.9486],
      [110.7346, -7.9486],
      [110.735, -7.9483],
      [110.7359, -7.9475],
      [110.7362, -7.9466],
      [110.7366, -7.9458],
      [110.7371, -7.9451],
      [110.7371, -7.9441],
      [110.7371, -7.9433],
      [110.7373, -7.9427],
      [110.7378, -7.9418],
      [110.7375, -7.941],
      [110.7368, -7.9402],
      [110.736, -7.9395],
      [110.7358, -7.939],
      [110.7356, -7.9387],
      [110.7354, -7.9382],
      [110.7356, -7.9377],
      [110.7356, -7.9376],
      [110.7355, -7.9365],
      [110.7355, -7.9363],
      [110.7352, -7.935],
      [110.7352, -7.9338],
      [110.7353, -7.9323],
      [110.7355, -7.9312],
      [110.7353, -7.9307],
      [110.735, -7.9302],
      [110.7346, -7.9301],
      [110.7342, -7.9301],
      [110.7339, -7.93],
      [110.7336, -7.9296],
      [110.7334, -7.9286],
      [110.7332, -7.9284],
      [110.7319, -7.928],
      [110.7314, -7.9278],
      [110.7307, -7.9276],
      [110.7303, -7.9271],
      [110.7302, -7.9265],
      [110.7303, -7.9259],
      [110.7306, -7.9255],
      [110.7306, -7.9248],
      [110.731, -7.9246],
      [110.7317, -7.9245],
      [110.7325, -7.9243],
      [110.7329, -7.9237],
      [110.7334, -7.923],
      [110.7335, -7.9225],
      [110.7333, -7.9222],
      [110.733, -7.9219],
      [110.7327, -7.9215],
      [110.7323, -7.9202],
      [110.7322, -7.919],
      [110.7325, -7.9179],
      [110.7324, -7.9167],
      [110.7324, -7.9159],
      [110.7326, -7.9152],
      [110.7328, -7.9145],
      [110.7331, -7.9143],
      [110.7336, -7.9141],
      [110.7343, -7.9141],
      [110.7346, -7.9138],
      [110.7348, -7.9135],
      [110.7353, -7.9126],
      [110.7355, -7.9119],
      [110.7353, -7.9118],
      [110.7346, -7.9114],
      [110.7337, -7.9111],
      [110.7332, -7.9107],
      [110.733, -7.9103],
      [110.7331, -7.9099],
      [110.7332, -7.9095],
      [110.7334, -7.9088],
      [110.7334, -7.9083],
      [110.7332, -7.908],
      [110.733, -7.9077],
      [110.7327, -7.9072],
      [110.7324, -7.9065],
      [110.7322, -7.9061]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'SAWAHAN',
    border: [
      [110.7513, -7.9048],
      [110.7512, -7.9049],
      [110.751, -7.905],
      [110.7507, -7.9054],
      [110.7504, -7.9057],
      [110.75, -7.9059],
      [110.7498, -7.9061],
      [110.7498, -7.9064],
      [110.7498, -7.9069],
      [110.7491, -7.9069],
      [110.7488, -7.9072],
      [110.7485, -7.9075],
      [110.7481, -7.9079],
      [110.7476, -7.908],
      [110.7471, -7.9079],
      [110.7467, -7.9077],
      [110.7461, -7.9075],
      [110.7458, -7.9075],
      [110.7454, -7.9076],
      [110.7451, -7.9077],
      [110.745, -7.9078],
      [110.7446, -7.9076],
      [110.7442, -7.9075],
      [110.7436, -7.9075],
      [110.7433, -7.9076],
      [110.7428, -7.9075],
      [110.7426, -7.9071],
      [110.7421, -7.907],
      [110.7415, -7.9071],
      [110.7407, -7.9071],
      [110.7406, -7.9068],
      [110.7404, -7.9062],
      [110.7402, -7.9058],
      [110.7401, -7.9052],
      [110.7399, -7.905],
      [110.7396, -7.9048],
      [110.7393, -7.9047],
      [110.7391, -7.9046],
      [110.7391, -7.9043],
      [110.7392, -7.9041],
      [110.7393, -7.9038],
      [110.7391, -7.9036],
      [110.739, -7.9035],
      [110.739, -7.9032],
      [110.739, -7.903],
      [110.7388, -7.9027],
      [110.7385, -7.9027],
      [110.7384, -7.9028],
      [110.738, -7.9029],
      [110.7376, -7.9029],
      [110.7371, -7.903],
      [110.7366, -7.903],
      [110.7361, -7.903],
      [110.7356, -7.9032],
      [110.7352, -7.9034],
      [110.735, -7.9039],
      [110.7347, -7.9042],
      [110.7343, -7.9046],
      [110.7337, -7.9049],
      [110.7333, -7.905],
      [110.733, -7.9051],
      [110.7328, -7.9053],
      [110.7328, -7.9055],
      [110.733, -7.9055],
      [110.7333, -7.9056],
      [110.7332, -7.906],
      [110.733, -7.9062],
      [110.7328, -7.906],
      [110.7326, -7.906],
      [110.7322, -7.9059],
      [110.7322, -7.9061],
      [110.7324, -7.9065],
      [110.7327, -7.9072],
      [110.733, -7.9077],
      [110.7332, -7.908],
      [110.7334, -7.9083],
      [110.7334, -7.9088],
      [110.7332, -7.9095],
      [110.7331, -7.9099],
      [110.733, -7.9103],
      [110.7332, -7.9107],
      [110.7337, -7.9111],
      [110.7346, -7.9114],
      [110.7353, -7.9118],
      [110.7355, -7.9119],
      [110.7353, -7.9126],
      [110.7348, -7.9135],
      [110.7346, -7.9138],
      [110.7343, -7.9141],
      [110.7336, -7.9141],
      [110.7331, -7.9143],
      [110.7328, -7.9145],
      [110.7326, -7.9152],
      [110.7324, -7.9159],
      [110.7324, -7.9167],
      [110.7325, -7.9179],
      [110.7322, -7.919],
      [110.7323, -7.9202],
      [110.7327, -7.9215],
      [110.733, -7.9219],
      [110.7333, -7.9222],
      [110.7335, -7.9225],
      [110.7334, -7.923],
      [110.7329, -7.9237],
      [110.7325, -7.9243],
      [110.7317, -7.9245],
      [110.731, -7.9246],
      [110.7306, -7.9248],
      [110.7306, -7.9255],
      [110.7303, -7.9259],
      [110.7302, -7.9265],
      [110.7303, -7.9271],
      [110.7307, -7.9276],
      [110.7314, -7.9278],
      [110.7319, -7.928],
      [110.7332, -7.9284],
      [110.7334, -7.9286],
      [110.7336, -7.9296],
      [110.7339, -7.93],
      [110.7342, -7.9301],
      [110.7346, -7.9301],
      [110.735, -7.9302],
      [110.7353, -7.9307],
      [110.7355, -7.9312],
      [110.7353, -7.9323],
      [110.7352, -7.9338],
      [110.7352, -7.935],
      [110.7355, -7.9363],
      [110.7355, -7.9365],
      [110.7356, -7.9376],
      [110.7356, -7.9377],
      [110.7354, -7.9382],
      [110.7356, -7.9387],
      [110.7358, -7.939],
      [110.736, -7.9395],
      [110.7368, -7.9402],
      [110.7375, -7.941],
      [110.7378, -7.9418],
      [110.7373, -7.9427],
      [110.7371, -7.9433],
      [110.7371, -7.9441],
      [110.7371, -7.9451],
      [110.7366, -7.9458],
      [110.7362, -7.9466],
      [110.7359, -7.9475],
      [110.7371, -7.9474],
      [110.7378, -7.9474],
      [110.7381, -7.9481],
      [110.7385, -7.9489],
      [110.7389, -7.95],
      [110.7396, -7.9509],
      [110.7403, -7.9515],
      [110.7405, -7.9516],
      [110.741, -7.9519],
      [110.741, -7.9516],
      [110.7412, -7.9514],
      [110.7413, -7.9511],
      [110.7414, -7.9506],
      [110.7414, -7.9502],
      [110.7415, -7.9499],
      [110.7415, -7.9494],
      [110.742, -7.9492],
      [110.7424, -7.9488],
      [110.7427, -7.9485],
      [110.7428, -7.9482],
      [110.7429, -7.9476],
      [110.743, -7.947],
      [110.7445, -7.9448],
      [110.745, -7.9442],
      [110.7455, -7.9438],
      [110.7463, -7.9435],
      [110.7472, -7.9433],
      [110.7473, -7.9438],
      [110.7475, -7.9443],
      [110.7477, -7.9445],
      [110.7479, -7.9447],
      [110.7481, -7.9446],
      [110.7483, -7.9441],
      [110.7486, -7.9438],
      [110.749, -7.9433],
      [110.7499, -7.9427],
      [110.7501, -7.9426],
      [110.7508, -7.9418],
      [110.7512, -7.9408],
      [110.7519, -7.9396],
      [110.7526, -7.9384],
      [110.7538, -7.9368],
      [110.754, -7.9361],
      [110.7543, -7.9338],
      [110.7545, -7.9315],
      [110.7546, -7.9301],
      [110.7546, -7.9287],
      [110.7546, -7.9285],
      [110.7546, -7.9276],
      [110.755, -7.926],
      [110.7551, -7.9257],
      [110.7557, -7.9254],
      [110.7565, -7.9251],
      [110.758, -7.9252],
      [110.7596, -7.9252],
      [110.76, -7.9252],
      [110.7608, -7.925],
      [110.7622, -7.9239],
      [110.7625, -7.9234],
      [110.7635, -7.9211],
      [110.7636, -7.921],
      [110.7638, -7.9206],
      [110.7644, -7.9203],
      [110.7656, -7.9198],
      [110.7672, -7.9191],
      [110.7677, -7.9185],
      [110.7686, -7.9173],
      [110.7692, -7.916],
      [110.7693, -7.9159],
      [110.7694, -7.9159],
      [110.7693, -7.9154],
      [110.7694, -7.9151],
      [110.7696, -7.9145],
      [110.7698, -7.914],
      [110.7698, -7.9134],
      [110.77, -7.9127],
      [110.7703, -7.9086],
      [110.7699, -7.9086],
      [110.7695, -7.9087],
      [110.7683, -7.9089],
      [110.7675, -7.909],
      [110.7667, -7.909],
      [110.7661, -7.9091],
      [110.766, -7.9091],
      [110.7656, -7.909],
      [110.765, -7.9089],
      [110.7644, -7.9086],
      [110.7638, -7.9084],
      [110.7634, -7.9082],
      [110.7629, -7.908],
      [110.7624, -7.9078],
      [110.7621, -7.9075],
      [110.7618, -7.9073],
      [110.7614, -7.907],
      [110.761, -7.9067],
      [110.7607, -7.9068],
      [110.7604, -7.9068],
      [110.7602, -7.9066],
      [110.7601, -7.9063],
      [110.7598, -7.9062],
      [110.7593, -7.906],
      [110.7592, -7.9058],
      [110.7592, -7.9055],
      [110.7593, -7.9052],
      [110.7594, -7.9049],
      [110.7592, -7.9047],
      [110.759, -7.9045],
      [110.7588, -7.9043],
      [110.7586, -7.9041],
      [110.7582, -7.9038],
      [110.7576, -7.9035],
      [110.7571, -7.9035],
      [110.7566, -7.9034],
      [110.7557, -7.9031],
      [110.7554, -7.9031],
      [110.7549, -7.9034],
      [110.7541, -7.9037],
      [110.753, -7.9039],
      [110.7525, -7.9041],
      [110.7519, -7.9045],
      [110.7513, -7.9048]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'TAMBAKROMO',
    border: [
      [110.7714, -7.9296],
      [110.7716, -7.9294],
      [110.7718, -7.9292],
      [110.772, -7.9289],
      [110.772, -7.9287],
      [110.7721, -7.9284],
      [110.7722, -7.9282],
      [110.772, -7.9279],
      [110.772, -7.9277],
      [110.772, -7.9274],
      [110.772, -7.9271],
      [110.7721, -7.9268],
      [110.7713, -7.9238],
      [110.771, -7.9229],
      [110.7709, -7.9221],
      [110.7707, -7.9212],
      [110.7705, -7.9205],
      [110.7703, -7.9194],
      [110.7704, -7.9193],
      [110.7704, -7.9191],
      [110.7704, -7.919],
      [110.7701, -7.9189],
      [110.7699, -7.9187],
      [110.77, -7.9174],
      [110.7697, -7.9166],
      [110.7695, -7.9159],
      [110.7693, -7.9159],
      [110.7692, -7.916],
      [110.7686, -7.9173],
      [110.7677, -7.9185],
      [110.7672, -7.9191],
      [110.7656, -7.9198],
      [110.7644, -7.9203],
      [110.7638, -7.9206],
      [110.7636, -7.921],
      [110.7635, -7.9211],
      [110.7625, -7.9234],
      [110.7622, -7.9239],
      [110.7608, -7.925],
      [110.76, -7.9252],
      [110.7596, -7.9252],
      [110.758, -7.9252],
      [110.7565, -7.9251],
      [110.7557, -7.9254],
      [110.7551, -7.9257],
      [110.755, -7.926],
      [110.7546, -7.9276],
      [110.7546, -7.9285],
      [110.7546, -7.9287],
      [110.7546, -7.9301],
      [110.7545, -7.9315],
      [110.7543, -7.9338],
      [110.754, -7.9361],
      [110.7538, -7.9368],
      [110.7526, -7.9384],
      [110.7519, -7.9396],
      [110.7512, -7.9408],
      [110.7508, -7.9418],
      [110.7501, -7.9426],
      [110.7502, -7.9432],
      [110.7507, -7.9441],
      [110.7512, -7.9445],
      [110.7523, -7.9457],
      [110.7536, -7.9475],
      [110.7537, -7.9503],
      [110.7536, -7.952],
      [110.7538, -7.9533],
      [110.7539, -7.9538],
      [110.7544, -7.9544],
      [110.7556, -7.9572],
      [110.7564, -7.9589],
      [110.7571, -7.96],
      [110.7583, -7.9612],
      [110.7587, -7.9618],
      [110.759, -7.9622],
      [110.7595, -7.9626],
      [110.76, -7.9631],
      [110.7601, -7.9641],
      [110.7604, -7.9647],
      [110.7613, -7.9646],
      [110.762, -7.9646],
      [110.7627, -7.9647],
      [110.7636, -7.9654],
      [110.7648, -7.9659],
      [110.7662, -7.966],
      [110.7676, -7.9659],
      [110.7679, -7.9674],
      [110.7681, -7.9688],
      [110.7684, -7.9677],
      [110.7687, -7.9665],
      [110.7687, -7.9661],
      [110.7688, -7.9658],
      [110.7692, -7.9616],
      [110.769, -7.9597],
      [110.7688, -7.9584],
      [110.7687, -7.9575],
      [110.7685, -7.9564],
      [110.7685, -7.9558],
      [110.7684, -7.9554],
      [110.7684, -7.9551],
      [110.7686, -7.9549],
      [110.7685, -7.9545],
      [110.7686, -7.9541],
      [110.7687, -7.9535],
      [110.7688, -7.9531],
      [110.7689, -7.9528],
      [110.7691, -7.9523],
      [110.7693, -7.952],
      [110.7695, -7.9517],
      [110.7696, -7.9515],
      [110.7697, -7.9512],
      [110.7702, -7.9502],
      [110.7706, -7.9493],
      [110.7706, -7.9492],
      [110.7709, -7.9485],
      [110.771, -7.9482],
      [110.7713, -7.9474],
      [110.7714, -7.9472],
      [110.7714, -7.9471],
      [110.7716, -7.9464],
      [110.7717, -7.9454],
      [110.7717, -7.9445],
      [110.7717, -7.9443],
      [110.7717, -7.9441],
      [110.7717, -7.9439],
      [110.7716, -7.942],
      [110.7715, -7.9411],
      [110.7717, -7.9389],
      [110.7717, -7.9365],
      [110.7712, -7.9336],
      [110.771, -7.9309],
      [110.771, -7.9308],
      [110.7711, -7.9307],
      [110.7712, -7.9305],
      [110.771, -7.9304],
      [110.771, -7.9301],
      [110.7711, -7.9299],
      [110.7712, -7.9298],
      [110.7714, -7.9296]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'KENTENG',
    border: [
      [110.7681, -7.9688],
      [110.7679, -7.9674],
      [110.7676, -7.9659],
      [110.7662, -7.966],
      [110.7648, -7.9659],
      [110.7636, -7.9654],
      [110.7627, -7.9647],
      [110.762, -7.9646],
      [110.7613, -7.9646],
      [110.7604, -7.9647],
      [110.7601, -7.9641],
      [110.76, -7.9631],
      [110.7595, -7.9626],
      [110.759, -7.9622],
      [110.7587, -7.9618],
      [110.7583, -7.9612],
      [110.7571, -7.96],
      [110.7564, -7.9589],
      [110.7556, -7.9572],
      [110.7544, -7.9544],
      [110.7539, -7.9538],
      [110.7538, -7.9533],
      [110.7536, -7.952],
      [110.7537, -7.9503],
      [110.7536, -7.9475],
      [110.7523, -7.9457],
      [110.7512, -7.9445],
      [110.7507, -7.9441],
      [110.7502, -7.9432],
      [110.7501, -7.9426],
      [110.7499, -7.9427],
      [110.749, -7.9433],
      [110.7486, -7.9438],
      [110.7483, -7.9441],
      [110.7481, -7.9446],
      [110.7479, -7.9447],
      [110.7477, -7.9445],
      [110.7475, -7.9443],
      [110.7473, -7.9438],
      [110.7472, -7.9433],
      [110.7463, -7.9435],
      [110.7455, -7.9438],
      [110.745, -7.9442],
      [110.7445, -7.9448],
      [110.743, -7.947],
      [110.7429, -7.9476],
      [110.7428, -7.9482],
      [110.7427, -7.9485],
      [110.7424, -7.9488],
      [110.742, -7.9492],
      [110.7415, -7.9494],
      [110.7415, -7.9499],
      [110.7414, -7.9502],
      [110.7414, -7.9506],
      [110.7413, -7.9511],
      [110.7412, -7.9514],
      [110.741, -7.9516],
      [110.741, -7.9519],
      [110.7412, -7.952],
      [110.7417, -7.952],
      [110.7421, -7.952],
      [110.7424, -7.9521],
      [110.7425, -7.9527],
      [110.7425, -7.9533],
      [110.7425, -7.9539],
      [110.7424, -7.9546],
      [110.7431, -7.9548],
      [110.7433, -7.9551],
      [110.7438, -7.9554],
      [110.7441, -7.9557],
      [110.7442, -7.9563],
      [110.7442, -7.9569],
      [110.7441, -7.9576],
      [110.7443, -7.9582],
      [110.7448, -7.959],
      [110.7453, -7.9595],
      [110.7453, -7.9601],
      [110.7452, -7.9611],
      [110.7455, -7.9614],
      [110.7457, -7.9616],
      [110.746, -7.9618],
      [110.746, -7.9622],
      [110.7464, -7.9623],
      [110.7471, -7.9626],
      [110.7472, -7.963],
      [110.7472, -7.9638],
      [110.7471, -7.9647],
      [110.7474, -7.9654],
      [110.7479, -7.9662],
      [110.749, -7.9671],
      [110.7497, -7.9677],
      [110.7497, -7.9684],
      [110.7497, -7.9689],
      [110.7494, -7.9691],
      [110.7493, -7.9699],
      [110.7494, -7.9704],
      [110.7493, -7.9708],
      [110.7489, -7.9709],
      [110.7489, -7.9711],
      [110.7488, -7.9713],
      [110.7485, -7.9713],
      [110.7483, -7.9714],
      [110.748, -7.9717],
      [110.7475, -7.9723],
      [110.7471, -7.9725],
      [110.7463, -7.9724],
      [110.746, -7.9725],
      [110.7459, -7.9728],
      [110.7456, -7.9728],
      [110.7457, -7.9731],
      [110.7459, -7.9734],
      [110.7463, -7.9734],
      [110.7468, -7.9734],
      [110.7474, -7.9734],
      [110.7481, -7.9735],
      [110.7486, -7.9739],
      [110.7491, -7.9745],
      [110.7501, -7.9751],
      [110.7506, -7.9753],
      [110.7509, -7.9757],
      [110.7507, -7.9762],
      [110.7513, -7.9765],
      [110.7513, -7.9769],
      [110.7514, -7.9771],
      [110.7521, -7.9776],
      [110.7523, -7.9783],
      [110.7523, -7.9791],
      [110.7525, -7.9799],
      [110.7526, -7.9812],
      [110.7527, -7.9825],
      [110.7525, -7.983],
      [110.752, -7.9836],
      [110.7513, -7.9845],
      [110.7509, -7.9857],
      [110.7511, -7.9866],
      [110.7512, -7.9875],
      [110.7511, -7.9883],
      [110.7508, -7.9889],
      [110.7504, -7.9898],
      [110.7502, -7.9905],
      [110.7506, -7.9917],
      [110.7506, -7.992],
      [110.751, -7.9929],
      [110.7514, -7.9931],
      [110.7522, -7.9935],
      [110.7529, -7.9939],
      [110.7536, -7.9944],
      [110.7548, -7.9956],
      [110.7559, -7.9962],
      [110.7565, -7.9968],
      [110.757, -7.9972],
      [110.7587, -7.9977],
      [110.76, -7.9981],
      [110.7605, -7.9981],
      [110.7613, -7.9982],
      [110.7615, -7.9972],
      [110.762, -7.9964],
      [110.7624, -7.9958],
      [110.7631, -7.9953],
      [110.7633, -7.9951],
      [110.7633, -7.995],
      [110.7633, -7.9943],
      [110.7633, -7.9942],
      [110.7633, -7.9935],
      [110.7633, -7.9928],
      [110.7635, -7.9923],
      [110.7644, -7.9917],
      [110.7647, -7.991],
      [110.7649, -7.9909],
      [110.7652, -7.9907],
      [110.7654, -7.9905],
      [110.7655, -7.9904],
      [110.7657, -7.9903],
      [110.7659, -7.9901],
      [110.766, -7.99],
      [110.7662, -7.9896],
      [110.7663, -7.9894],
      [110.7664, -7.9892],
      [110.7665, -7.9889],
      [110.7668, -7.9882],
      [110.767, -7.9873],
      [110.767, -7.987],
      [110.7671, -7.9862],
      [110.7671, -7.9852],
      [110.7671, -7.9843],
      [110.7672, -7.9838],
      [110.7673, -7.9835],
      [110.7674, -7.9825],
      [110.7675, -7.9818],
      [110.768, -7.9809],
      [110.7683, -7.9802],
      [110.7684, -7.9795],
      [110.7683, -7.9767],
      [110.7683, -7.9756],
      [110.7682, -7.9741],
      [110.7682, -7.9739],
      [110.7681, -7.9727],
      [110.768, -7.9717],
      [110.7679, -7.9711],
      [110.7679, -7.9703],
      [110.7679, -7.9699],
      [110.768, -7.9692],
      [110.7681, -7.9688]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'SUMBERGIRI',
    border: [
      [110.741, -7.9519],
      [110.7405, -7.9516],
      [110.7403, -7.9515],
      [110.7396, -7.9509],
      [110.7389, -7.95],
      [110.7385, -7.9489],
      [110.7381, -7.9481],
      [110.7378, -7.9474],
      [110.7371, -7.9474],
      [110.7359, -7.9475],
      [110.735, -7.9483],
      [110.7346, -7.9486],
      [110.7338, -7.9486],
      [110.7331, -7.9485],
      [110.7303, -7.9501],
      [110.7275, -7.9516],
      [110.7264, -7.9523],
      [110.7256, -7.9526],
      [110.7251, -7.9528],
      [110.7251, -7.9528],
      [110.7243, -7.9532],
      [110.7233, -7.9539],
      [110.7231, -7.954],
      [110.7229, -7.9541],
      [110.7227, -7.9541],
      [110.7225, -7.9542],
      [110.722, -7.9548],
      [110.7217, -7.9555],
      [110.7216, -7.9562],
      [110.721, -7.9566],
      [110.7204, -7.9573],
      [110.7205, -7.9573],
      [110.7206, -7.9574],
      [110.721, -7.9575],
      [110.7213, -7.9576],
      [110.7214, -7.958],
      [110.7215, -7.9583],
      [110.7213, -7.9589],
      [110.721, -7.9595],
      [110.72, -7.961],
      [110.7196, -7.9616],
      [110.7194, -7.962],
      [110.719, -7.9629],
      [110.7189, -7.964],
      [110.7188, -7.9653],
      [110.7189, -7.9657],
      [110.7192, -7.9659],
      [110.7195, -7.9664],
      [110.7204, -7.9669],
      [110.7208, -7.967],
      [110.7205, -7.9672],
      [110.7207, -7.9678],
      [110.7209, -7.9679],
      [110.7213, -7.9679],
      [110.7213, -7.9681],
      [110.7209, -7.9681],
      [110.7209, -7.9688],
      [110.7212, -7.9688],
      [110.7212, -7.969],
      [110.7215, -7.9692],
      [110.7215, -7.9693],
      [110.7206, -7.9691],
      [110.7205, -7.9694],
      [110.7201, -7.9714],
      [110.722, -7.9718],
      [110.7219, -7.9725],
      [110.7222, -7.9731],
      [110.7222, -7.9735],
      [110.722, -7.9739],
      [110.7219, -7.974],
      [110.724, -7.975],
      [110.7254, -7.9757],
      [110.728, -7.9775],
      [110.7293, -7.9783],
      [110.732, -7.9793],
      [110.7325, -7.9795],
      [110.7335, -7.9799],
      [110.7346, -7.9801],
      [110.7352, -7.9805],
      [110.7354, -7.9808],
      [110.7353, -7.9815],
      [110.7351, -7.9823],
      [110.7347, -7.9828],
      [110.7346, -7.983],
      [110.7355, -7.9841],
      [110.7361, -7.9851],
      [110.7358, -7.9855],
      [110.7356, -7.9859],
      [110.7353, -7.9861],
      [110.7347, -7.9864],
      [110.7348, -7.9869],
      [110.7353, -7.9874],
      [110.7357, -7.9876],
      [110.7363, -7.9875],
      [110.7367, -7.9879],
      [110.7369, -7.9881],
      [110.7375, -7.9882],
      [110.7381, -7.9881],
      [110.7386, -7.9879],
      [110.7391, -7.9888],
      [110.7396, -7.9894],
      [110.7402, -7.9897],
      [110.741, -7.9898],
      [110.7416, -7.9902],
      [110.7417, -7.9907],
      [110.7417, -7.9911],
      [110.7421, -7.9912],
      [110.7426, -7.9914],
      [110.743, -7.9917],
      [110.7432, -7.9921],
      [110.7436, -7.9924],
      [110.7442, -7.9923],
      [110.7449, -7.9924],
      [110.7453, -7.9924],
      [110.7462, -7.9923],
      [110.7468, -7.9923],
      [110.747, -7.9923],
      [110.7471, -7.9928],
      [110.7471, -7.9932],
      [110.7475, -7.9933],
      [110.7479, -7.9933],
      [110.7484, -7.9933],
      [110.7487, -7.9931],
      [110.7491, -7.9927],
      [110.7496, -7.9928],
      [110.75, -7.9927],
      [110.7504, -7.9928],
      [110.751, -7.9929],
      [110.7506, -7.992],
      [110.7506, -7.9917],
      [110.7502, -7.9905],
      [110.7504, -7.9898],
      [110.7508, -7.9889],
      [110.7511, -7.9883],
      [110.7512, -7.9875],
      [110.7511, -7.9866],
      [110.7509, -7.9857],
      [110.7513, -7.9845],
      [110.752, -7.9836],
      [110.7525, -7.983],
      [110.7527, -7.9825],
      [110.7526, -7.9812],
      [110.7525, -7.9799],
      [110.7523, -7.9791],
      [110.7523, -7.9783],
      [110.7521, -7.9776],
      [110.7514, -7.9771],
      [110.7513, -7.9769],
      [110.7513, -7.9765],
      [110.7507, -7.9762],
      [110.7509, -7.9757],
      [110.7506, -7.9753],
      [110.7501, -7.9751],
      [110.7491, -7.9745],
      [110.7486, -7.9739],
      [110.7481, -7.9735],
      [110.7474, -7.9734],
      [110.7468, -7.9734],
      [110.7463, -7.9734],
      [110.7459, -7.9734],
      [110.7457, -7.9731],
      [110.7456, -7.9728],
      [110.7459, -7.9728],
      [110.746, -7.9725],
      [110.7463, -7.9724],
      [110.7471, -7.9725],
      [110.7475, -7.9723],
      [110.748, -7.9717],
      [110.7483, -7.9714],
      [110.7485, -7.9713],
      [110.7488, -7.9713],
      [110.7489, -7.9711],
      [110.7489, -7.9709],
      [110.7493, -7.9708],
      [110.7494, -7.9704],
      [110.7493, -7.9699],
      [110.7494, -7.9691],
      [110.7497, -7.9689],
      [110.7497, -7.9684],
      [110.7497, -7.9677],
      [110.749, -7.9671],
      [110.7479, -7.9662],
      [110.7474, -7.9654],
      [110.7471, -7.9647],
      [110.7472, -7.9638],
      [110.7472, -7.963],
      [110.7471, -7.9626],
      [110.7464, -7.9623],
      [110.746, -7.9622],
      [110.746, -7.9618],
      [110.7457, -7.9616],
      [110.7455, -7.9614],
      [110.7452, -7.9611],
      [110.7453, -7.9601],
      [110.7453, -7.9595],
      [110.7448, -7.959],
      [110.7443, -7.9582],
      [110.7441, -7.9576],
      [110.7442, -7.9569],
      [110.7442, -7.9563],
      [110.7441, -7.9557],
      [110.7438, -7.9554],
      [110.7433, -7.9551],
      [110.7431, -7.9548],
      [110.7424, -7.9546],
      [110.7425, -7.9539],
      [110.7425, -7.9533],
      [110.7425, -7.9527],
      [110.7424, -7.9521],
      [110.7421, -7.952],
      [110.7417, -7.952],
      [110.7412, -7.952],
      [110.741, -7.9519]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'GENJAHAN',
    border: [
      [110.7204, -7.9573],
      [110.7199, -7.9574],
      [110.7195, -7.9573],
      [110.719, -7.9575],
      [110.7185, -7.9575],
      [110.7184, -7.9575],
      [110.7182, -7.9578],
      [110.7184, -7.9581],
      [110.7175, -7.9588],
      [110.7162, -7.9585],
      [110.7142, -7.9578],
      [110.7135, -7.9576],
      [110.7136, -7.957],
      [110.7135, -7.9565],
      [110.7134, -7.9563],
      [110.7132, -7.9561],
      [110.7129, -7.9561],
      [110.7123, -7.9561],
      [110.7119, -7.9564],
      [110.7115, -7.9568],
      [110.7113, -7.9569],
      [110.7111, -7.9567],
      [110.711, -7.9563],
      [110.7114, -7.956],
      [110.7117, -7.9556],
      [110.7119, -7.9551],
      [110.7123, -7.9546],
      [110.7127, -7.9543],
      [110.7133, -7.9542],
      [110.7137, -7.9541],
      [110.714, -7.9538],
      [110.7143, -7.9537],
      [110.7152, -7.9535],
      [110.7152, -7.9527],
      [110.7151, -7.9517],
      [110.7143, -7.9492],
      [110.7142, -7.9486],
      [110.7138, -7.9484],
      [110.7137, -7.9477],
      [110.7138, -7.9476],
      [110.7139, -7.9474],
      [110.7137, -7.9472],
      [110.7118, -7.9457],
      [110.7114, -7.9457],
      [110.7107, -7.946],
      [110.7099, -7.9464],
      [110.7088, -7.9465],
      [110.7079, -7.9465],
      [110.7071, -7.9466],
      [110.7065, -7.9468],
      [110.7059, -7.9468],
      [110.7041, -7.9465],
      [110.7034, -7.9463],
      [110.7023, -7.9462],
      [110.7014, -7.946],
      [110.7014, -7.9465],
      [110.7013, -7.9468],
      [110.7012, -7.9474],
      [110.7009, -7.9479],
      [110.7008, -7.9482],
      [110.7008, -7.9484],
      [110.7007, -7.9485],
      [110.7006, -7.9488],
      [110.7006, -7.9489],
      [110.7008, -7.9495],
      [110.7006, -7.9501],
      [110.7004, -7.9506],
      [110.7012, -7.9508],
      [110.7013, -7.9511],
      [110.7015, -7.9515],
      [110.7017, -7.9524],
      [110.7019, -7.9531],
      [110.7025, -7.9536],
      [110.7028, -7.954],
      [110.7028, -7.9544],
      [110.7029, -7.9549],
      [110.7026, -7.9551],
      [110.7027, -7.9555],
      [110.7027, -7.956],
      [110.7025, -7.9565],
      [110.7024, -7.9568],
      [110.7023, -7.9571],
      [110.7023, -7.9576],
      [110.7025, -7.9592],
      [110.7028, -7.9624],
      [110.7029, -7.9641],
      [110.7029, -7.9642],
      [110.703, -7.9653],
      [110.703, -7.9659],
      [110.703, -7.9661],
      [110.7029, -7.9668],
      [110.7028, -7.9671],
      [110.7025, -7.967],
      [110.7022, -7.9671],
      [110.702, -7.9672],
      [110.7017, -7.9672],
      [110.7013, -7.9673],
      [110.7011, -7.968],
      [110.7009, -7.9686],
      [110.7, -7.9692],
      [110.6991, -7.9698],
      [110.6988, -7.9701],
      [110.6986, -7.9711],
      [110.6983, -7.9724],
      [110.6982, -7.9729],
      [110.6981, -7.9736],
      [110.6979, -7.9742],
      [110.6979, -7.9746],
      [110.6982, -7.9748],
      [110.6988, -7.9748],
      [110.6994, -7.9748],
      [110.6997, -7.9746],
      [110.7001, -7.9746],
      [110.7006, -7.9745],
      [110.7006, -7.9742],
      [110.7007, -7.9739],
      [110.701, -7.9737],
      [110.7016, -7.9737],
      [110.7019, -7.9734],
      [110.7021, -7.9733],
      [110.7027, -7.9733],
      [110.7034, -7.9736],
      [110.7044, -7.9736],
      [110.7044, -7.9746],
      [110.7049, -7.9746],
      [110.7049, -7.9748],
      [110.7058, -7.9746],
      [110.7061, -7.9751],
      [110.7069, -7.9753],
      [110.7081, -7.9753],
      [110.7086, -7.9753],
      [110.7092, -7.9755],
      [110.7123, -7.9759],
      [110.7125, -7.9753],
      [110.7131, -7.9754],
      [110.7142, -7.9755],
      [110.715, -7.9754],
      [110.7168, -7.9751],
      [110.7179, -7.9748],
      [110.7181, -7.9744],
      [110.7192, -7.9747],
      [110.7198, -7.9733],
      [110.7215, -7.9737],
      [110.7217, -7.9739],
      [110.722, -7.9739],
      [110.7222, -7.9735],
      [110.7222, -7.9731],
      [110.7219, -7.9725],
      [110.722, -7.9718],
      [110.7201, -7.9714],
      [110.7205, -7.9694],
      [110.7206, -7.9691],
      [110.7215, -7.9693],
      [110.7215, -7.9692],
      [110.7212, -7.969],
      [110.7212, -7.9688],
      [110.7209, -7.9688],
      [110.7209, -7.9681],
      [110.7213, -7.9681],
      [110.7213, -7.9679],
      [110.7209, -7.9679],
      [110.7207, -7.9678],
      [110.7205, -7.9672],
      [110.7208, -7.967],
      [110.7204, -7.9669],
      [110.7195, -7.9664],
      [110.7192, -7.9659],
      [110.7189, -7.9657],
      [110.7188, -7.9653],
      [110.7189, -7.964],
      [110.719, -7.9629],
      [110.7194, -7.962],
      [110.7196, -7.9616],
      [110.72, -7.961],
      [110.721, -7.9595],
      [110.7213, -7.9589],
      [110.7215, -7.9583],
      [110.7214, -7.958],
      [110.7213, -7.9576],
      [110.721, -7.9575],
      [110.7206, -7.9574],
      [110.7205, -7.9573],
      [110.7204, -7.9573]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'PONJONG',
    border: [
      [110.722, -7.9739],
      [110.7217, -7.9739],
      [110.7215, -7.9737],
      [110.7198, -7.9733],
      [110.7192, -7.9747],
      [110.7181, -7.9744],
      [110.7179, -7.9748],
      [110.7168, -7.9751],
      [110.715, -7.9754],
      [110.7142, -7.9755],
      [110.7131, -7.9754],
      [110.7125, -7.9753],
      [110.7123, -7.9759],
      [110.7092, -7.9755],
      [110.7086, -7.9753],
      [110.7081, -7.9753],
      [110.7069, -7.9753],
      [110.7061, -7.9751],
      [110.7058, -7.9746],
      [110.7049, -7.9748],
      [110.7049, -7.9746],
      [110.7044, -7.9746],
      [110.7044, -7.9736],
      [110.7034, -7.9736],
      [110.7027, -7.9733],
      [110.7021, -7.9733],
      [110.7019, -7.9734],
      [110.7016, -7.9737],
      [110.7016, -7.9742],
      [110.7016, -7.9746],
      [110.7018, -7.9748],
      [110.702, -7.9749],
      [110.7022, -7.9755],
      [110.7022, -7.9759],
      [110.7019, -7.9764],
      [110.7018, -7.9767],
      [110.7014, -7.9772],
      [110.7012, -7.9779],
      [110.7014, -7.9785],
      [110.7019, -7.9785],
      [110.7023, -7.9787],
      [110.7026, -7.9789],
      [110.7026, -7.9793],
      [110.7027, -7.9796],
      [110.7027, -7.9799],
      [110.7031, -7.98],
      [110.7033, -7.9802],
      [110.7035, -7.9804],
      [110.7037, -7.9807],
      [110.7032, -7.9822],
      [110.7029, -7.9829],
      [110.7027, -7.9835],
      [110.7028, -7.9842],
      [110.7028, -7.9848],
      [110.7031, -7.985],
      [110.7036, -7.9856],
      [110.704, -7.986],
      [110.704, -7.986],
      [110.7041, -7.9863],
      [110.7041, -7.9869],
      [110.7041, -7.9871],
      [110.7043, -7.9874],
      [110.7045, -7.9877],
      [110.7051, -7.9878],
      [110.7053, -7.9877],
      [110.7058, -7.9878],
      [110.7065, -7.988],
      [110.7073, -7.9879],
      [110.7083, -7.9886],
      [110.7085, -7.9885],
      [110.7088, -7.9883],
      [110.7091, -7.9884],
      [110.7096, -7.9886],
      [110.7097, -7.9885],
      [110.7099, -7.9882],
      [110.71, -7.9882],
      [110.7103, -7.9882],
      [110.7106, -7.9884],
      [110.7111, -7.9894],
      [110.7114, -7.9896],
      [110.7111, -7.9907],
      [110.7122, -7.9909],
      [110.7122, -7.9913],
      [110.7124, -7.9913],
      [110.7124, -7.9917],
      [110.7123, -7.9925],
      [110.7129, -7.9929],
      [110.7132, -7.9931],
      [110.7141, -7.9936],
      [110.7141, -7.9939],
      [110.7145, -7.9941],
      [110.7142, -7.9947],
      [110.7148, -7.9951],
      [110.7152, -7.9952],
      [110.7159, -7.9955],
      [110.7154, -7.9969],
      [110.715, -7.9976],
      [110.715, -7.9978],
      [110.7153, -7.9985],
      [110.7154, -7.9992],
      [110.7152, -7.9997],
      [110.7149, -8.0002],
      [110.7152, -8.0012],
      [110.7158, -8.002],
      [110.7166, -8.003],
      [110.7168, -8.0034],
      [110.7169, -8.0042],
      [110.7171, -8.0053],
      [110.7173, -8.0054],
      [110.7178, -8.0054],
      [110.7185, -8.005],
      [110.7196, -8.0046],
      [110.7202, -8.0045],
      [110.7207, -8.0046],
      [110.721, -8.0045],
      [110.7216, -8.0044],
      [110.7225, -8.0043],
      [110.7233, -8.0043],
      [110.724, -8.0045],
      [110.7249, -8.0046],
      [110.7257, -8.0046],
      [110.7261, -8.0043],
      [110.7258, -8.0039],
      [110.7255, -8.0034],
      [110.7253, -8.003],
      [110.7251, -8.0021],
      [110.7252, -8.0012],
      [110.7256, -8.0006],
      [110.7257, -8.0001],
      [110.7251, -7.9994],
      [110.7249, -7.9988],
      [110.7244, -7.9974],
      [110.7241, -7.9966],
      [110.7238, -7.9961],
      [110.7238, -7.996],
      [110.7237, -7.9951],
      [110.7241, -7.9945],
      [110.7248, -7.9933],
      [110.7253, -7.9916],
      [110.7258, -7.99],
      [110.7263, -7.9894],
      [110.7279, -7.9874],
      [110.7302, -7.9877],
      [110.731, -7.9866],
      [110.7321, -7.9851],
      [110.7327, -7.9845],
      [110.7346, -7.983],
      [110.7347, -7.9828],
      [110.7351, -7.9823],
      [110.7353, -7.9815],
      [110.7354, -7.9808],
      [110.7352, -7.9805],
      [110.7346, -7.9801],
      [110.7335, -7.9799],
      [110.7325, -7.9795],
      [110.732, -7.9793],
      [110.7293, -7.9783],
      [110.728, -7.9775],
      [110.7254, -7.9757],
      [110.724, -7.975],
      [110.7219, -7.974],
      [110.722, -7.9739]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'KARANGASEM',
    border: [
      [110.751, -7.9929],
      [110.7504, -7.9928],
      [110.75, -7.9927],
      [110.7496, -7.9928],
      [110.7491, -7.9927],
      [110.7487, -7.9931],
      [110.7484, -7.9933],
      [110.7479, -7.9933],
      [110.7475, -7.9933],
      [110.7471, -7.9932],
      [110.7471, -7.9928],
      [110.747, -7.9923],
      [110.7468, -7.9923],
      [110.7462, -7.9923],
      [110.7453, -7.9924],
      [110.7449, -7.9924],
      [110.7442, -7.9923],
      [110.7436, -7.9924],
      [110.7432, -7.9921],
      [110.743, -7.9917],
      [110.7426, -7.9914],
      [110.7421, -7.9912],
      [110.7417, -7.9911],
      [110.7417, -7.9907],
      [110.7416, -7.9902],
      [110.741, -7.9898],
      [110.7402, -7.9897],
      [110.7396, -7.9894],
      [110.7391, -7.9888],
      [110.7386, -7.9879],
      [110.7381, -7.9881],
      [110.7375, -7.9882],
      [110.7369, -7.9881],
      [110.7367, -7.9879],
      [110.7363, -7.9875],
      [110.7357, -7.9876],
      [110.7353, -7.9874],
      [110.7348, -7.9869],
      [110.7347, -7.9864],
      [110.7353, -7.9861],
      [110.7356, -7.9859],
      [110.7358, -7.9855],
      [110.7361, -7.9851],
      [110.7355, -7.9841],
      [110.7346, -7.983],
      [110.7327, -7.9845],
      [110.7321, -7.9851],
      [110.731, -7.9866],
      [110.7302, -7.9877],
      [110.7279, -7.9874],
      [110.7263, -7.9894],
      [110.7258, -7.99],
      [110.7253, -7.9916],
      [110.7248, -7.9933],
      [110.7241, -7.9945],
      [110.7237, -7.9951],
      [110.7238, -7.996],
      [110.7238, -7.9961],
      [110.7241, -7.9966],
      [110.7244, -7.9974],
      [110.7249, -7.9988],
      [110.7251, -7.9994],
      [110.7257, -8.0001],
      [110.7256, -8.0006],
      [110.7252, -8.0012],
      [110.7251, -8.0021],
      [110.7253, -8.003],
      [110.7261, -8.0028],
      [110.7268, -8.0025],
      [110.7278, -8.0025],
      [110.7283, -8.0027],
      [110.7285, -8.0027],
      [110.7288, -8.0028],
      [110.7292, -8.0031],
      [110.7303, -8.0032],
      [110.7313, -8.0036],
      [110.732, -8.0041],
      [110.7327, -8.0047],
      [110.7331, -8.0047],
      [110.7339, -8.005],
      [110.7349, -8.0054],
      [110.7356, -8.0057],
      [110.7366, -8.0062],
      [110.7375, -8.0067],
      [110.7388, -8.0071],
      [110.7404, -8.0078],
      [110.7406, -8.008],
      [110.741, -8.0082],
      [110.7416, -8.0088],
      [110.7423, -8.0093],
      [110.7427, -8.0099],
      [110.7436, -8.0112],
      [110.7439, -8.012],
      [110.7443, -8.0127],
      [110.7448, -8.0136],
      [110.745, -8.0149],
      [110.7451, -8.0155],
      [110.7454, -8.016],
      [110.7462, -8.0163],
      [110.747, -8.0167],
      [110.7471, -8.0176],
      [110.7469, -8.0186],
      [110.7468, -8.019],
      [110.7467, -8.0191],
      [110.7467, -8.0192],
      [110.7466, -8.0195],
      [110.7469, -8.02],
      [110.7473, -8.0207],
      [110.7479, -8.0214],
      [110.7479, -8.0219],
      [110.7477, -8.0226],
      [110.7475, -8.0234],
      [110.7478, -8.0239],
      [110.748, -8.024],
      [110.7487, -8.0247],
      [110.7496, -8.0253],
      [110.7502, -8.0255],
      [110.7513, -8.0256],
      [110.753, -8.0258],
      [110.7542, -8.0258],
      [110.756, -8.0265],
      [110.7556, -8.0247],
      [110.7557, -8.0238],
      [110.756, -8.0225],
      [110.7562, -8.0211],
      [110.7563, -8.02],
      [110.7565, -8.0182],
      [110.7571, -8.017],
      [110.7574, -8.0154],
      [110.7581, -8.014],
      [110.7585, -8.0125],
      [110.7585, -8.011],
      [110.7585, -8.0096],
      [110.7586, -8.0084],
      [110.7594, -8.0067],
      [110.7617, -8.0035],
      [110.7615, -8.0031],
      [110.7614, -8.0029],
      [110.7613, -8.0026],
      [110.7612, -8.0024],
      [110.7611, -8.0019],
      [110.761, -8.0016],
      [110.7613, -8.0009],
      [110.7612, -7.9992],
      [110.7613, -7.9982],
      [110.7605, -7.9981],
      [110.76, -7.9981],
      [110.7587, -7.9977],
      [110.757, -7.9972],
      [110.7565, -7.9968],
      [110.7559, -7.9962],
      [110.7548, -7.9956],
      [110.7536, -7.9944],
      [110.7529, -7.9939],
      [110.7522, -7.9935],
      [110.7514, -7.9931],
      [110.751, -7.9929]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'BEDOYO',
    border: [
      [110.756, -8.0265],
      [110.7542, -8.0258],
      [110.753, -8.0258],
      [110.7513, -8.0256],
      [110.7502, -8.0255],
      [110.7496, -8.0253],
      [110.7487, -8.0247],
      [110.748, -8.024],
      [110.7478, -8.0239],
      [110.7475, -8.0234],
      [110.7477, -8.0226],
      [110.7479, -8.0219],
      [110.7479, -8.0214],
      [110.7473, -8.0207],
      [110.7469, -8.02],
      [110.7466, -8.0195],
      [110.7467, -8.0192],
      [110.7467, -8.0191],
      [110.7468, -8.019],
      [110.7469, -8.0186],
      [110.7471, -8.0176],
      [110.747, -8.0167],
      [110.7462, -8.0163],
      [110.7454, -8.016],
      [110.7451, -8.0155],
      [110.745, -8.0149],
      [110.7448, -8.0136],
      [110.7443, -8.0127],
      [110.7439, -8.012],
      [110.7436, -8.0112],
      [110.7427, -8.0099],
      [110.7423, -8.0093],
      [110.7416, -8.0088],
      [110.741, -8.0082],
      [110.7406, -8.008],
      [110.7404, -8.0078],
      [110.7388, -8.0071],
      [110.7375, -8.0067],
      [110.7366, -8.0062],
      [110.7356, -8.0057],
      [110.7349, -8.0054],
      [110.7339, -8.005],
      [110.7331, -8.0047],
      [110.7327, -8.0047],
      [110.732, -8.0041],
      [110.7313, -8.0036],
      [110.7303, -8.0032],
      [110.7292, -8.0031],
      [110.7288, -8.0028],
      [110.7285, -8.0027],
      [110.7283, -8.0027],
      [110.7278, -8.0025],
      [110.7268, -8.0025],
      [110.7261, -8.0028],
      [110.7253, -8.003],
      [110.7255, -8.0034],
      [110.7258, -8.0039],
      [110.7261, -8.0043],
      [110.7257, -8.0046],
      [110.7249, -8.0046],
      [110.724, -8.0045],
      [110.7233, -8.0043],
      [110.7225, -8.0043],
      [110.7216, -8.0044],
      [110.721, -8.0045],
      [110.7207, -8.0046],
      [110.7202, -8.0045],
      [110.7196, -8.0046],
      [110.7197, -8.0053],
      [110.72, -8.006],
      [110.72, -8.0069],
      [110.7199, -8.008],
      [110.7199, -8.0087],
      [110.7203, -8.0099],
      [110.7207, -8.0107],
      [110.721, -8.0117],
      [110.7209, -8.0133],
      [110.7205, -8.0141],
      [110.7192, -8.0155],
      [110.7178, -8.0169],
      [110.7175, -8.0174],
      [110.718, -8.0177],
      [110.7194, -8.018],
      [110.7201, -8.018],
      [110.721, -8.0177],
      [110.7219, -8.0172],
      [110.7223, -8.0168],
      [110.7227, -8.0166],
      [110.7233, -8.0166],
      [110.724, -8.017],
      [110.7246, -8.0175],
      [110.725, -8.0179],
      [110.7252, -8.0183],
      [110.7255, -8.0193],
      [110.7256, -8.02],
      [110.7256, -8.0207],
      [110.7256, -8.0211],
      [110.7258, -8.0215],
      [110.7261, -8.0219],
      [110.7267, -8.0219],
      [110.7277, -8.0218],
      [110.7281, -8.0218],
      [110.7285, -8.022],
      [110.7289, -8.0223],
      [110.7291, -8.0227],
      [110.7294, -8.023],
      [110.7295, -8.0236],
      [110.7297, -8.0243],
      [110.7297, -8.0248],
      [110.7296, -8.0253],
      [110.7293, -8.0259],
      [110.7288, -8.0265],
      [110.7282, -8.0272],
      [110.7278, -8.0278],
      [110.7276, -8.0283],
      [110.7276, -8.0291],
      [110.7279, -8.0304],
      [110.7281, -8.0315],
      [110.7281, -8.033],
      [110.728, -8.0343],
      [110.728, -8.035],
      [110.7276, -8.0357],
      [110.7275, -8.0363],
      [110.7275, -8.0369],
      [110.7276, -8.0374],
      [110.7281, -8.0382],
      [110.7286, -8.0386],
      [110.7293, -8.0388],
      [110.73, -8.0386],
      [110.7308, -8.0381],
      [110.7311, -8.0379],
      [110.7317, -8.0376],
      [110.7326, -8.0368],
      [110.7337, -8.0356],
      [110.7344, -8.035],
      [110.7349, -8.0346],
      [110.7354, -8.0343],
      [110.7362, -8.0341],
      [110.7368, -8.0341],
      [110.7375, -8.034],
      [110.7381, -8.0341],
      [110.7389, -8.0347],
      [110.7399, -8.0358],
      [110.7408, -8.0367],
      [110.7413, -8.0369],
      [110.7418, -8.037],
      [110.7424, -8.0373],
      [110.7425, -8.0374],
      [110.7429, -8.0376],
      [110.7437, -8.0379],
      [110.7444, -8.0378],
      [110.7449, -8.0377],
      [110.7454, -8.0374],
      [110.7456, -8.0368],
      [110.7457, -8.0365],
      [110.7461, -8.036],
      [110.7463, -8.0356],
      [110.7465, -8.0352],
      [110.7467, -8.0349],
      [110.7472, -8.0347],
      [110.7479, -8.0345],
      [110.749, -8.0345],
      [110.7498, -8.0347],
      [110.7509, -8.0346],
      [110.7518, -8.0345],
      [110.7529, -8.0344],
      [110.7538, -8.0343],
      [110.7549, -8.0342],
      [110.7565, -8.0342],
      [110.7578, -8.0342],
      [110.7588, -8.0342],
      [110.7587, -8.0339],
      [110.7581, -8.0329],
      [110.7575, -8.0305],
      [110.7572, -8.0297],
      [110.7571, -8.0293],
      [110.7567, -8.0281],
      [110.756, -8.0265]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'SIDOREJO',
    border: [
      [110.7014, -7.9785],
      [110.7012, -7.9792],
      [110.7004, -7.9791],
      [110.6993, -7.979],
      [110.6986, -7.9787],
      [110.698, -7.9788],
      [110.6974, -7.9788],
      [110.6965, -7.9786],
      [110.6961, -7.9784],
      [110.6955, -7.9783],
      [110.6952, -7.9784],
      [110.695, -7.9784],
      [110.6947, -7.9788],
      [110.6945, -7.9788],
      [110.6943, -7.9789],
      [110.6943, -7.9792],
      [110.6937, -7.9792],
      [110.6936, -7.9795],
      [110.6932, -7.9795],
      [110.693, -7.9797],
      [110.6927, -7.9797],
      [110.6926, -7.9801],
      [110.6912, -7.9798],
      [110.6892, -7.9796],
      [110.6865, -7.9798],
      [110.6849, -7.9801],
      [110.683, -7.9805],
      [110.6825, -7.9813],
      [110.6823, -7.9821],
      [110.6821, -7.9826],
      [110.6817, -7.9828],
      [110.681, -7.9829],
      [110.68, -7.9832],
      [110.6798, -7.9829],
      [110.6798, -7.9828],
      [110.6792, -7.9825],
      [110.6793, -7.9813],
      [110.6787, -7.9812],
      [110.6775, -7.9808],
      [110.6772, -7.9816],
      [110.6768, -7.9827],
      [110.6772, -7.9832],
      [110.6773, -7.9833],
      [110.6778, -7.9836],
      [110.6778, -7.9842],
      [110.6775, -7.9846],
      [110.6771, -7.9846],
      [110.6766, -7.9849],
      [110.6759, -7.9848],
      [110.6758, -7.985],
      [110.6758, -7.9858],
      [110.6766, -7.986],
      [110.677, -7.9863],
      [110.678, -7.9871],
      [110.6782, -7.9876],
      [110.6783, -7.9882],
      [110.6783, -7.9884],
      [110.6783, -7.9887],
      [110.6783, -7.9888],
      [110.6782, -7.9893],
      [110.6782, -7.99],
      [110.6782, -7.9902],
      [110.6782, -7.9907],
      [110.6782, -7.9917],
      [110.678, -7.9925],
      [110.6777, -7.9941],
      [110.6775, -7.9959],
      [110.6778, -7.9959],
      [110.6784, -7.9961],
      [110.6792, -7.9963],
      [110.6792, -7.9961],
      [110.6795, -7.9961],
      [110.6796, -7.9955],
      [110.6801, -7.9955],
      [110.6803, -7.9953],
      [110.6808, -7.9954],
      [110.6808, -7.995],
      [110.6818, -7.9949],
      [110.6818, -7.9954],
      [110.6823, -7.9955],
      [110.6824, -7.9959],
      [110.6832, -7.9959],
      [110.683, -7.9971],
      [110.6829, -7.9973],
      [110.6816, -7.9985],
      [110.68, -7.9996],
      [110.6795, -8.0001],
      [110.6796, -8.001],
      [110.6798, -8.0019],
      [110.6805, -8.0026],
      [110.6814, -8.0032],
      [110.6819, -8.0041],
      [110.6819, -8.0043],
      [110.682, -8.0047],
      [110.6821, -8.0058],
      [110.6822, -8.0069],
      [110.6826, -8.0074],
      [110.6833, -8.008],
      [110.6837, -8.0084],
      [110.6842, -8.0091],
      [110.6843, -8.0095],
      [110.685, -8.0091],
      [110.686, -8.0088],
      [110.6878, -8.0086],
      [110.6886, -8.0089],
      [110.6898, -8.0088],
      [110.691, -8.0084],
      [110.692, -8.008],
      [110.6926, -8.0081],
      [110.6934, -8.0081],
      [110.6945, -8.0081],
      [110.6955, -8.0082],
      [110.6967, -8.0082],
      [110.6981, -8.008],
      [110.6991, -8.0081],
      [110.7006, -8.0081],
      [110.7025, -8.0085],
      [110.7038, -8.009],
      [110.7046, -8.0096],
      [110.7054, -8.0101],
      [110.7059, -8.0102],
      [110.7067, -8.0102],
      [110.7082, -8.0102],
      [110.7089, -8.0106],
      [110.7096, -8.0112],
      [110.7101, -8.0118],
      [110.7104, -8.0125],
      [110.7103, -8.0133],
      [110.7101, -8.0143],
      [110.7096, -8.0158],
      [110.7095, -8.0167],
      [110.7098, -8.0178],
      [110.7102, -8.0181],
      [110.7117, -8.0188],
      [110.7131, -8.0187],
      [110.7135, -8.0189],
      [110.7143, -8.0191],
      [110.7152, -8.019],
      [110.7161, -8.0189],
      [110.7168, -8.0186],
      [110.7172, -8.0181],
      [110.7175, -8.0174],
      [110.7178, -8.0169],
      [110.7192, -8.0155],
      [110.7205, -8.0141],
      [110.7209, -8.0133],
      [110.721, -8.0117],
      [110.7207, -8.0107],
      [110.7203, -8.0099],
      [110.7199, -8.0087],
      [110.7199, -8.008],
      [110.72, -8.0069],
      [110.72, -8.006],
      [110.7197, -8.0053],
      [110.7196, -8.0046],
      [110.7185, -8.005],
      [110.7178, -8.0054],
      [110.7173, -8.0054],
      [110.7171, -8.0053],
      [110.7169, -8.0042],
      [110.7168, -8.0034],
      [110.7166, -8.003],
      [110.7158, -8.002],
      [110.7152, -8.0012],
      [110.7149, -8.0002],
      [110.7152, -7.9997],
      [110.7154, -7.9992],
      [110.7153, -7.9985],
      [110.715, -7.9978],
      [110.715, -7.9976],
      [110.7154, -7.9969],
      [110.7159, -7.9955],
      [110.7152, -7.9952],
      [110.7148, -7.9951],
      [110.7142, -7.9947],
      [110.7145, -7.9941],
      [110.7141, -7.9939],
      [110.7141, -7.9936],
      [110.7132, -7.9931],
      [110.7129, -7.9929],
      [110.7123, -7.9925],
      [110.7124, -7.9917],
      [110.7124, -7.9913],
      [110.7122, -7.9913],
      [110.7122, -7.9909],
      [110.7111, -7.9907],
      [110.7114, -7.9896],
      [110.7111, -7.9894],
      [110.7106, -7.9884],
      [110.7103, -7.9882],
      [110.71, -7.9882],
      [110.7099, -7.9882],
      [110.7097, -7.9885],
      [110.7096, -7.9886],
      [110.7091, -7.9884],
      [110.7088, -7.9883],
      [110.7085, -7.9885],
      [110.7083, -7.9886],
      [110.7073, -7.9879],
      [110.7065, -7.988],
      [110.7058, -7.9878],
      [110.7053, -7.9877],
      [110.7051, -7.9878],
      [110.7045, -7.9877],
      [110.7043, -7.9874],
      [110.7041, -7.9871],
      [110.7041, -7.9869],
      [110.7041, -7.9863],
      [110.704, -7.986],
      [110.704, -7.986],
      [110.7036, -7.9856],
      [110.7031, -7.985],
      [110.7028, -7.9848],
      [110.7028, -7.9842],
      [110.7027, -7.9835],
      [110.7029, -7.9829],
      [110.7032, -7.9822],
      [110.7037, -7.9807],
      [110.7035, -7.9804],
      [110.7033, -7.9802],
      [110.7031, -7.98],
      [110.7027, -7.9799],
      [110.7027, -7.9796],
      [110.7026, -7.9793],
      [110.7026, -7.9789],
      [110.7023, -7.9787],
      [110.7019, -7.9785],
      [110.7014, -7.9785]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PONJONG',
    village: 'GOMBANG',
    border: [
      [110.7131, -8.0187],
      [110.7117, -8.0188],
      [110.7102, -8.0181],
      [110.7098, -8.0178],
      [110.7095, -8.0167],
      [110.7096, -8.0158],
      [110.7101, -8.0143],
      [110.7103, -8.0133],
      [110.7104, -8.0125],
      [110.7101, -8.0118],
      [110.7096, -8.0112],
      [110.7089, -8.0106],
      [110.7082, -8.0102],
      [110.7067, -8.0102],
      [110.7059, -8.0102],
      [110.7054, -8.0101],
      [110.7046, -8.0096],
      [110.7038, -8.009],
      [110.7025, -8.0085],
      [110.7006, -8.0081],
      [110.6991, -8.0081],
      [110.6981, -8.008],
      [110.6967, -8.0082],
      [110.6955, -8.0082],
      [110.6945, -8.0081],
      [110.6934, -8.0081],
      [110.6926, -8.0081],
      [110.692, -8.008],
      [110.691, -8.0084],
      [110.6898, -8.0088],
      [110.6886, -8.0089],
      [110.6878, -8.0086],
      [110.686, -8.0088],
      [110.685, -8.0091],
      [110.6843, -8.0095],
      [110.6843, -8.01],
      [110.6841, -8.0105],
      [110.6839, -8.0112],
      [110.6837, -8.0116],
      [110.6832, -8.0122],
      [110.6822, -8.0133],
      [110.6816, -8.0144],
      [110.681, -8.0151],
      [110.6801, -8.0159],
      [110.6795, -8.0165],
      [110.6792, -8.0172],
      [110.6791, -8.018],
      [110.679, -8.0187],
      [110.6791, -8.0193],
      [110.6794, -8.0202],
      [110.6797, -8.0208],
      [110.6797, -8.0213],
      [110.6797, -8.022],
      [110.6796, -8.0224],
      [110.6795, -8.0229],
      [110.6795, -8.0233],
      [110.6796, -8.0241],
      [110.6795, -8.0251],
      [110.6795, -8.0259],
      [110.6795, -8.0263],
      [110.6798, -8.0264],
      [110.6801, -8.0266],
      [110.6807, -8.027],
      [110.6812, -8.0272],
      [110.6818, -8.0275],
      [110.6825, -8.0281],
      [110.6832, -8.0286],
      [110.684, -8.029],
      [110.6844, -8.0289],
      [110.6848, -8.0287],
      [110.6854, -8.0286],
      [110.6859, -8.0283],
      [110.6868, -8.0283],
      [110.6877, -8.0281],
      [110.6886, -8.028],
      [110.6893, -8.028],
      [110.6904, -8.028],
      [110.6914, -8.028],
      [110.6922, -8.0281],
      [110.6925, -8.0283],
      [110.6929, -8.0288],
      [110.6934, -8.0295],
      [110.694, -8.0305],
      [110.6944, -8.0314],
      [110.6949, -8.0322],
      [110.6955, -8.0328],
      [110.6959, -8.0332],
      [110.6962, -8.0332],
      [110.6968, -8.0331],
      [110.6978, -8.0329],
      [110.6983, -8.0328],
      [110.6987, -8.0329],
      [110.7002, -8.0328],
      [110.7025, -8.0327],
      [110.7041, -8.0329],
      [110.7049, -8.0326],
      [110.7055, -8.0323],
      [110.7066, -8.0324],
      [110.707, -8.0326],
      [110.7082, -8.0333],
      [110.7089, -8.0336],
      [110.7095, -8.0337],
      [110.71, -8.0338],
      [110.7104, -8.0336],
      [110.7109, -8.0334],
      [110.7114, -8.0333],
      [110.7117, -8.0329],
      [110.712, -8.0324],
      [110.7122, -8.032],
      [110.7123, -8.0314],
      [110.7126, -8.0309],
      [110.7131, -8.0304],
      [110.7136, -8.03],
      [110.7147, -8.0295],
      [110.7154, -8.0291],
      [110.7158, -8.029],
      [110.7166, -8.0285],
      [110.717, -8.0281],
      [110.7177, -8.0277],
      [110.7179, -8.0273],
      [110.718, -8.0267],
      [110.718, -8.0262],
      [110.7177, -8.0256],
      [110.7175, -8.025],
      [110.7173, -8.0245],
      [110.7169, -8.0241],
      [110.7161, -8.0235],
      [110.7151, -8.0231],
      [110.7146, -8.0228],
      [110.714, -8.0223],
      [110.7137, -8.0221],
      [110.7133, -8.0216],
      [110.713, -8.021],
      [110.7129, -8.0202],
      [110.7128, -8.0195],
      [110.7128, -8.0191],
      [110.7131, -8.0187]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'BOHOL',
    border: [
      [110.7577, -8.1157],
      [110.7556, -8.115],
      [110.7537, -8.1138],
      [110.7523, -8.1125],
      [110.7515, -8.1116],
      [110.7509, -8.1109],
      [110.7503, -8.1106],
      [110.7495, -8.1108],
      [110.7479, -8.1119],
      [110.7473, -8.1125],
      [110.747, -8.1125],
      [110.7465, -8.1125],
      [110.745, -8.1132],
      [110.7439, -8.1138],
      [110.7427, -8.1146],
      [110.7415, -8.1154],
      [110.7409, -8.116],
      [110.7394, -8.1168],
      [110.7385, -8.1175],
      [110.7378, -8.1178],
      [110.737, -8.1183],
      [110.7349, -8.1192],
      [110.734, -8.1198],
      [110.7329, -8.1203],
      [110.7326, -8.1204],
      [110.7321, -8.1205],
      [110.7314, -8.1204],
      [110.7304, -8.1202],
      [110.7299, -8.1201],
      [110.7299, -8.1215],
      [110.7299, -8.1225],
      [110.731, -8.1243],
      [110.7318, -8.1251],
      [110.7325, -8.1257],
      [110.7345, -8.1282],
      [110.735, -8.1288],
      [110.7351, -8.1289],
      [110.7356, -8.1295],
      [110.7359, -8.1299],
      [110.7364, -8.1304],
      [110.7369, -8.1307],
      [110.7373, -8.1309],
      [110.7379, -8.1311],
      [110.7383, -8.1311],
      [110.7384, -8.1312],
      [110.7386, -8.1314],
      [110.7388, -8.1317],
      [110.739, -8.1325],
      [110.7394, -8.1327],
      [110.7405, -8.1331],
      [110.741, -8.1336],
      [110.7415, -8.1342],
      [110.7418, -8.1351],
      [110.7421, -8.1361],
      [110.7424, -8.1368],
      [110.7425, -8.1369],
      [110.743, -8.1374],
      [110.7436, -8.1377],
      [110.7441, -8.138],
      [110.7442, -8.1386],
      [110.7441, -8.1389],
      [110.7438, -8.1394],
      [110.7432, -8.1404],
      [110.7428, -8.1409],
      [110.7425, -8.1415],
      [110.7421, -8.142],
      [110.7418, -8.1424],
      [110.7419, -8.1427],
      [110.742, -8.1431],
      [110.742, -8.1434],
      [110.742, -8.1436],
      [110.7416, -8.1438],
      [110.7415, -8.1442],
      [110.7414, -8.1445],
      [110.7415, -8.1449],
      [110.7417, -8.1453],
      [110.742, -8.1454],
      [110.7424, -8.1452],
      [110.7432, -8.1447],
      [110.7441, -8.144],
      [110.7445, -8.1437],
      [110.7449, -8.1431],
      [110.7452, -8.1425],
      [110.7453, -8.1421],
      [110.7456, -8.1416],
      [110.7459, -8.1412],
      [110.7462, -8.1409],
      [110.7465, -8.1411],
      [110.7475, -8.1417],
      [110.7482, -8.1422],
      [110.7483, -8.1424],
      [110.7482, -8.143],
      [110.748, -8.1437],
      [110.7479, -8.144],
      [110.7479, -8.1443],
      [110.7484, -8.1446],
      [110.7499, -8.1454],
      [110.7505, -8.1455],
      [110.751, -8.1455],
      [110.7522, -8.145],
      [110.7532, -8.1444],
      [110.7542, -8.1438],
      [110.755, -8.1429],
      [110.7555, -8.1422],
      [110.7556, -8.1419],
      [110.7556, -8.1413],
      [110.7553, -8.1405],
      [110.7551, -8.1396],
      [110.755, -8.1383],
      [110.7551, -8.1366],
      [110.7551, -8.1356],
      [110.7549, -8.1344],
      [110.7548, -8.1337],
      [110.755, -8.133],
      [110.7552, -8.1319],
      [110.7554, -8.1309],
      [110.7557, -8.1301],
      [110.7558, -8.1293],
      [110.7558, -8.1276],
      [110.7555, -8.1259],
      [110.7553, -8.1249],
      [110.7553, -8.1243],
      [110.7555, -8.1235],
      [110.7568, -8.12],
      [110.7573, -8.1183],
      [110.7576, -8.1173],
      [110.7577, -8.1157]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'PRINGOMBO',
    border: [
      [110.7623, -8.0944],
      [110.7618, -8.0943],
      [110.7611, -8.0941],
      [110.7606, -8.0938],
      [110.7594, -8.0931],
      [110.7586, -8.0926],
      [110.7577, -8.092],
      [110.7561, -8.0906],
      [110.7554, -8.0901],
      [110.7542, -8.0879],
      [110.7536, -8.0866],
      [110.753, -8.0859],
      [110.7523, -8.0851],
      [110.7514, -8.0845],
      [110.7509, -8.0844],
      [110.7504, -8.0842],
      [110.7498, -8.0842],
      [110.749, -8.0839],
      [110.7486, -8.0836],
      [110.7476, -8.0831],
      [110.7468, -8.0828],
      [110.7456, -8.0821],
      [110.7447, -8.0809],
      [110.7444, -8.0801],
      [110.7437, -8.0784],
      [110.7435, -8.0782],
      [110.7429, -8.0778],
      [110.7419, -8.0774],
      [110.7415, -8.0773],
      [110.7406, -8.0773],
      [110.74, -8.078],
      [110.7395, -8.0785],
      [110.739, -8.0789],
      [110.7383, -8.0795],
      [110.738, -8.0802],
      [110.738, -8.0806],
      [110.7383, -8.0815],
      [110.7387, -8.0821],
      [110.7391, -8.0829],
      [110.7396, -8.0834],
      [110.7399, -8.0836],
      [110.741, -8.0843],
      [110.7417, -8.0848],
      [110.7422, -8.0854],
      [110.7427, -8.0862],
      [110.7433, -8.0873],
      [110.7437, -8.0887],
      [110.744, -8.0897],
      [110.7445, -8.0907],
      [110.7448, -8.092],
      [110.7449, -8.0931],
      [110.745, -8.0932],
      [110.7456, -8.0944],
      [110.7463, -8.0964],
      [110.7466, -8.0983],
      [110.7464, -8.0992],
      [110.7462, -8.1001],
      [110.7459, -8.1006],
      [110.7458, -8.1008],
      [110.745, -8.102],
      [110.7444, -8.1027],
      [110.7436, -8.1033],
      [110.7433, -8.1038],
      [110.7431, -8.1048],
      [110.7426, -8.1061],
      [110.742, -8.1071],
      [110.7414, -8.1083],
      [110.7405, -8.1096],
      [110.7398, -8.1103],
      [110.7389, -8.1109],
      [110.7382, -8.1112],
      [110.7369, -8.1113],
      [110.736, -8.1112],
      [110.7352, -8.1112],
      [110.7343, -8.1113],
      [110.732, -8.1112],
      [110.7311, -8.1112],
      [110.731, -8.1116],
      [110.7307, -8.1129],
      [110.7305, -8.1137],
      [110.7305, -8.1145],
      [110.7307, -8.1158],
      [110.7306, -8.1172],
      [110.7299, -8.1191],
      [110.7299, -8.1201],
      [110.7304, -8.1202],
      [110.7314, -8.1204],
      [110.7321, -8.1205],
      [110.7326, -8.1204],
      [110.7329, -8.1203],
      [110.734, -8.1198],
      [110.7349, -8.1192],
      [110.737, -8.1183],
      [110.7378, -8.1178],
      [110.7385, -8.1175],
      [110.7394, -8.1168],
      [110.7409, -8.116],
      [110.7415, -8.1154],
      [110.7427, -8.1146],
      [110.7439, -8.1138],
      [110.745, -8.1132],
      [110.7465, -8.1125],
      [110.747, -8.1125],
      [110.7473, -8.1125],
      [110.7479, -8.1119],
      [110.7495, -8.1108],
      [110.7503, -8.1106],
      [110.7509, -8.1109],
      [110.7515, -8.1116],
      [110.7523, -8.1125],
      [110.7537, -8.1138],
      [110.7556, -8.115],
      [110.7577, -8.1157],
      [110.7577, -8.1151],
      [110.7577, -8.1141],
      [110.7577, -8.1133],
      [110.7574, -8.1123],
      [110.7573, -8.1112],
      [110.7573, -8.11],
      [110.7575, -8.109],
      [110.7582, -8.1091],
      [110.7592, -8.1087],
      [110.76, -8.1084],
      [110.7607, -8.1083],
      [110.7619, -8.1085],
      [110.7621, -8.1077],
      [110.7621, -8.1069],
      [110.762, -8.1067],
      [110.7625, -8.1052],
      [110.7629, -8.1038],
      [110.764, -8.1003],
      [110.7646, -8.0985],
      [110.7644, -8.0978],
      [110.764, -8.097],
      [110.7631, -8.0957],
      [110.7623, -8.0945],
      [110.7623, -8.0944]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'BOTODAYAAN',
    border: [
      [110.7311, -8.1112],
      [110.7311, -8.111],
      [110.7311, -8.1083],
      [110.7311, -8.1076],
      [110.7313, -8.1069],
      [110.7318, -8.1061],
      [110.7324, -8.1053],
      [110.7327, -8.1047],
      [110.7327, -8.1042],
      [110.7324, -8.1028],
      [110.7324, -8.1022],
      [110.7328, -8.1009],
      [110.7331, -8.0996],
      [110.7332, -8.0989],
      [110.7329, -8.0981],
      [110.7325, -8.0968],
      [110.7324, -8.0952],
      [110.7323, -8.0939],
      [110.7321, -8.0926],
      [110.7319, -8.0911],
      [110.7315, -8.0907],
      [110.7314, -8.09],
      [110.7311, -8.0893],
      [110.7303, -8.0886],
      [110.7299, -8.0883],
      [110.7298, -8.088],
      [110.7286, -8.0877],
      [110.7278, -8.0878],
      [110.7269, -8.0883],
      [110.7261, -8.0889],
      [110.7258, -8.0892],
      [110.7254, -8.0904],
      [110.7252, -8.0913],
      [110.7247, -8.0922],
      [110.7244, -8.0927],
      [110.724, -8.0932],
      [110.7233, -8.0933],
      [110.7225, -8.0933],
      [110.7218, -8.0934],
      [110.7207, -8.0931],
      [110.7201, -8.093],
      [110.7199, -8.0931],
      [110.7197, -8.0931],
      [110.7189, -8.0933],
      [110.7177, -8.0934],
      [110.7177, -8.0936],
      [110.7177, -8.0943],
      [110.7178, -8.0951],
      [110.7181, -8.0959],
      [110.7182, -8.0969],
      [110.7182, -8.0975],
      [110.7181, -8.0978],
      [110.7176, -8.098],
      [110.7168, -8.0982],
      [110.7164, -8.0984],
      [110.716, -8.0989],
      [110.7159, -8.0994],
      [110.7157, -8.1002],
      [110.7155, -8.1008],
      [110.7152, -8.1013],
      [110.7147, -8.1019],
      [110.714, -8.1024],
      [110.7134, -8.1028],
      [110.713, -8.1032],
      [110.7131, -8.1035],
      [110.7139, -8.105],
      [110.7145, -8.1071],
      [110.7147, -8.1085],
      [110.7147, -8.1097],
      [110.7147, -8.1106],
      [110.7146, -8.1113],
      [110.7143, -8.1121],
      [110.714, -8.1126],
      [110.7135, -8.113],
      [110.7128, -8.1136],
      [110.7119, -8.1143],
      [110.7114, -8.1151],
      [110.7111, -8.1158],
      [110.711, -8.1163],
      [110.7104, -8.1169],
      [110.7099, -8.1174],
      [110.7094, -8.118],
      [110.7088, -8.1185],
      [110.7082, -8.1189],
      [110.7074, -8.1194],
      [110.707, -8.1198],
      [110.7062, -8.1207],
      [110.7057, -8.1215],
      [110.705, -8.1223],
      [110.7043, -8.1228],
      [110.7038, -8.1228],
      [110.7028, -8.1231],
      [110.7013, -8.1232],
      [110.7001, -8.1233],
      [110.6996, -8.1236],
      [110.6995, -8.1237],
      [110.6991, -8.1241],
      [110.6985, -8.1248],
      [110.6979, -8.1255],
      [110.6974, -8.1263],
      [110.6974, -8.1266],
      [110.6979, -8.1265],
      [110.6988, -8.1263],
      [110.6995, -8.1263],
      [110.7001, -8.1263],
      [110.7004, -8.1264],
      [110.701, -8.1272],
      [110.7017, -8.1281],
      [110.7031, -8.1289],
      [110.7039, -8.1295],
      [110.7047, -8.1304],
      [110.7055, -8.1312],
      [110.7075, -8.1319],
      [110.7092, -8.1325],
      [110.7104, -8.1327],
      [110.712, -8.133],
      [110.713, -8.1332],
      [110.7137, -8.1333],
      [110.7142, -8.1338],
      [110.7143, -8.134],
      [110.714, -8.1367],
      [110.7137, -8.1382],
      [110.7135, -8.1392],
      [110.7128, -8.1398],
      [110.712, -8.141],
      [110.7117, -8.1414],
      [110.7144, -8.1421],
      [110.7164, -8.1425],
      [110.7167, -8.1427],
      [110.7171, -8.143],
      [110.7174, -8.1434],
      [110.7176, -8.1436],
      [110.718, -8.1435],
      [110.7196, -8.1425],
      [110.7203, -8.142],
      [110.7213, -8.1415],
      [110.7217, -8.1412],
      [110.7222, -8.1412],
      [110.7226, -8.1414],
      [110.723, -8.1414],
      [110.7233, -8.1412],
      [110.7238, -8.1412],
      [110.7242, -8.1413],
      [110.7246, -8.1414],
      [110.7249, -8.1413],
      [110.7257, -8.1408],
      [110.7267, -8.1402],
      [110.728, -8.1394],
      [110.729, -8.1387],
      [110.7298, -8.1383],
      [110.7301, -8.1379],
      [110.7303, -8.1373],
      [110.7303, -8.1366],
      [110.7305, -8.136],
      [110.7308, -8.1356],
      [110.7312, -8.1354],
      [110.7318, -8.1351],
      [110.7324, -8.1349],
      [110.7324, -8.1345],
      [110.7326, -8.1336],
      [110.7328, -8.133],
      [110.733, -8.1321],
      [110.7333, -8.1316],
      [110.7336, -8.1312],
      [110.7341, -8.1312],
      [110.7347, -8.1313],
      [110.7352, -8.1313],
      [110.7356, -8.1312],
      [110.736, -8.131],
      [110.7365, -8.131],
      [110.7369, -8.1307],
      [110.7364, -8.1304],
      [110.7359, -8.1299],
      [110.7356, -8.1295],
      [110.7351, -8.1289],
      [110.735, -8.1288],
      [110.7345, -8.1282],
      [110.7325, -8.1257],
      [110.7318, -8.1251],
      [110.731, -8.1243],
      [110.7299, -8.1225],
      [110.7299, -8.1215],
      [110.7299, -8.1201],
      [110.7299, -8.1191],
      [110.7306, -8.1172],
      [110.7307, -8.1158],
      [110.7305, -8.1145],
      [110.7305, -8.1137],
      [110.7307, -8.1129],
      [110.731, -8.1116],
      [110.7311, -8.1112]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'PETIR',
    border: [
      [110.7418, -8.0696],
      [110.7414, -8.0695],
      [110.7405, -8.0692],
      [110.7399, -8.069],
      [110.7388, -8.0686],
      [110.7379, -8.0679],
      [110.7373, -8.0677],
      [110.7359, -8.0672],
      [110.7348, -8.0673],
      [110.7338, -8.0674],
      [110.7329, -8.0678],
      [110.7324, -8.0679],
      [110.7316, -8.0679],
      [110.7311, -8.068],
      [110.7304, -8.0681],
      [110.7287, -8.0685],
      [110.7284, -8.0685],
      [110.7282, -8.0681],
      [110.7282, -8.0678],
      [110.7285, -8.0674],
      [110.7289, -8.0665],
      [110.7297, -8.0655],
      [110.7302, -8.0648],
      [110.7303, -8.0644],
      [110.7301, -8.0642],
      [110.7296, -8.064],
      [110.7279, -8.0639],
      [110.7263, -8.064],
      [110.7245, -8.0622],
      [110.7236, -8.0617],
      [110.7232, -8.0611],
      [110.722, -8.0594],
      [110.7214, -8.0588],
      [110.7206, -8.0593],
      [110.7201, -8.0597],
      [110.7193, -8.0604],
      [110.7187, -8.061],
      [110.7183, -8.0618],
      [110.718, -8.0625],
      [110.7174, -8.063],
      [110.717, -8.0637],
      [110.7166, -8.0645],
      [110.7153, -8.0663],
      [110.7147, -8.0671],
      [110.7139, -8.0683],
      [110.7132, -8.0695],
      [110.7126, -8.0706],
      [110.7124, -8.0712],
      [110.7124, -8.0722],
      [110.7124, -8.0732],
      [110.7124, -8.0742],
      [110.7126, -8.0761],
      [110.713, -8.0766],
      [110.7134, -8.0774],
      [110.7143, -8.0784],
      [110.7155, -8.0792],
      [110.7161, -8.0799],
      [110.7165, -8.0804],
      [110.7165, -8.0812],
      [110.7165, -8.0819],
      [110.7162, -8.0833],
      [110.7158, -8.0845],
      [110.7157, -8.0854],
      [110.7158, -8.0861],
      [110.7161, -8.0869],
      [110.7165, -8.0878],
      [110.7171, -8.0892],
      [110.7178, -8.0909],
      [110.7179, -8.0921],
      [110.7177, -8.0934],
      [110.7189, -8.0933],
      [110.7197, -8.0931],
      [110.7199, -8.0931],
      [110.7201, -8.093],
      [110.7207, -8.0931],
      [110.7218, -8.0934],
      [110.7225, -8.0933],
      [110.7233, -8.0933],
      [110.724, -8.0932],
      [110.7244, -8.0927],
      [110.7247, -8.0922],
      [110.7252, -8.0913],
      [110.7254, -8.0904],
      [110.7258, -8.0892],
      [110.7261, -8.0889],
      [110.7269, -8.0883],
      [110.7278, -8.0878],
      [110.7286, -8.0877],
      [110.7298, -8.088],
      [110.7299, -8.0883],
      [110.7303, -8.0886],
      [110.7311, -8.0893],
      [110.7314, -8.09],
      [110.7315, -8.0907],
      [110.7319, -8.0911],
      [110.7321, -8.0926],
      [110.7323, -8.0939],
      [110.7324, -8.0952],
      [110.7325, -8.0968],
      [110.7329, -8.0981],
      [110.7332, -8.0989],
      [110.7331, -8.0996],
      [110.7328, -8.1009],
      [110.7324, -8.1022],
      [110.7324, -8.1028],
      [110.7327, -8.1042],
      [110.7327, -8.1047],
      [110.7324, -8.1053],
      [110.7318, -8.1061],
      [110.7313, -8.1069],
      [110.7311, -8.1076],
      [110.7311, -8.1083],
      [110.7311, -8.111],
      [110.7311, -8.1112],
      [110.732, -8.1112],
      [110.7343, -8.1113],
      [110.7352, -8.1112],
      [110.736, -8.1112],
      [110.7369, -8.1113],
      [110.7382, -8.1112],
      [110.7389, -8.1109],
      [110.7398, -8.1103],
      [110.7405, -8.1096],
      [110.7414, -8.1083],
      [110.742, -8.1071],
      [110.7426, -8.1061],
      [110.7431, -8.1048],
      [110.7433, -8.1038],
      [110.7436, -8.1033],
      [110.7444, -8.1027],
      [110.745, -8.102],
      [110.7458, -8.1008],
      [110.7459, -8.1006],
      [110.7462, -8.1001],
      [110.7464, -8.0992],
      [110.7466, -8.0983],
      [110.7463, -8.0964],
      [110.7456, -8.0944],
      [110.745, -8.0932],
      [110.7449, -8.0931],
      [110.7448, -8.092],
      [110.7445, -8.0907],
      [110.744, -8.0897],
      [110.7437, -8.0887],
      [110.7433, -8.0873],
      [110.7427, -8.0862],
      [110.7422, -8.0854],
      [110.7417, -8.0848],
      [110.741, -8.0843],
      [110.7399, -8.0836],
      [110.7396, -8.0834],
      [110.7391, -8.0829],
      [110.7387, -8.0821],
      [110.7383, -8.0815],
      [110.738, -8.0806],
      [110.738, -8.0802],
      [110.7383, -8.0795],
      [110.739, -8.0789],
      [110.7395, -8.0785],
      [110.74, -8.078],
      [110.7406, -8.0773],
      [110.7409, -8.0769],
      [110.7411, -8.076],
      [110.7414, -8.0741],
      [110.7415, -8.0732],
      [110.7413, -8.0721],
      [110.7412, -8.0714],
      [110.7412, -8.0712],
      [110.7415, -8.0703],
      [110.7418, -8.0696]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'PUCANGANOM',
    border: [
      [110.7425, -8.0374],
      [110.7424, -8.0373],
      [110.7418, -8.037],
      [110.7413, -8.0369],
      [110.7408, -8.0367],
      [110.7399, -8.0358],
      [110.7389, -8.0347],
      [110.7381, -8.0341],
      [110.7375, -8.034],
      [110.7368, -8.0341],
      [110.7362, -8.0341],
      [110.7354, -8.0343],
      [110.7349, -8.0346],
      [110.7344, -8.035],
      [110.7337, -8.0356],
      [110.7326, -8.0368],
      [110.7317, -8.0376],
      [110.7311, -8.0379],
      [110.7308, -8.0381],
      [110.73, -8.0386],
      [110.7293, -8.0388],
      [110.7286, -8.0386],
      [110.7281, -8.0382],
      [110.7276, -8.0374],
      [110.7275, -8.0369],
      [110.7275, -8.0363],
      [110.7276, -8.0357],
      [110.728, -8.035],
      [110.728, -8.0343],
      [110.7281, -8.033],
      [110.7281, -8.0315],
      [110.7279, -8.0304],
      [110.7276, -8.0291],
      [110.7276, -8.0283],
      [110.7278, -8.0278],
      [110.7282, -8.0272],
      [110.7288, -8.0265],
      [110.7293, -8.0259],
      [110.7296, -8.0253],
      [110.7297, -8.0248],
      [110.7297, -8.0243],
      [110.7295, -8.0236],
      [110.7294, -8.023],
      [110.7291, -8.0227],
      [110.7289, -8.0223],
      [110.7285, -8.022],
      [110.7281, -8.0218],
      [110.7277, -8.0218],
      [110.7267, -8.0219],
      [110.7261, -8.0219],
      [110.7258, -8.0215],
      [110.7256, -8.0211],
      [110.7256, -8.0207],
      [110.7256, -8.02],
      [110.7255, -8.0193],
      [110.7252, -8.0183],
      [110.725, -8.0179],
      [110.7246, -8.0175],
      [110.724, -8.017],
      [110.7233, -8.0166],
      [110.7227, -8.0166],
      [110.7223, -8.0168],
      [110.7219, -8.0172],
      [110.721, -8.0177],
      [110.7201, -8.018],
      [110.7194, -8.018],
      [110.718, -8.0177],
      [110.7175, -8.0174],
      [110.7172, -8.0181],
      [110.7168, -8.0186],
      [110.7161, -8.0189],
      [110.7152, -8.019],
      [110.7143, -8.0191],
      [110.7135, -8.0189],
      [110.7131, -8.0187],
      [110.7128, -8.0191],
      [110.7128, -8.0195],
      [110.7129, -8.0202],
      [110.713, -8.021],
      [110.7133, -8.0216],
      [110.7137, -8.0221],
      [110.714, -8.0223],
      [110.7146, -8.0228],
      [110.7151, -8.0231],
      [110.7161, -8.0235],
      [110.7169, -8.0241],
      [110.7173, -8.0245],
      [110.7175, -8.025],
      [110.7177, -8.0256],
      [110.718, -8.0262],
      [110.718, -8.0267],
      [110.7179, -8.0273],
      [110.7177, -8.0277],
      [110.717, -8.0281],
      [110.7166, -8.0285],
      [110.7158, -8.029],
      [110.7154, -8.0291],
      [110.7147, -8.0295],
      [110.7136, -8.03],
      [110.7131, -8.0304],
      [110.7126, -8.0309],
      [110.7123, -8.0314],
      [110.7122, -8.032],
      [110.712, -8.0324],
      [110.7117, -8.0329],
      [110.7114, -8.0333],
      [110.7109, -8.0334],
      [110.7104, -8.0336],
      [110.71, -8.0338],
      [110.7095, -8.0337],
      [110.7089, -8.0336],
      [110.7082, -8.0333],
      [110.707, -8.0326],
      [110.7066, -8.0324],
      [110.7055, -8.0323],
      [110.7049, -8.0326],
      [110.7041, -8.0329],
      [110.704, -8.0334],
      [110.7039, -8.0343],
      [110.7038, -8.0352],
      [110.7038, -8.0356],
      [110.7038, -8.0362],
      [110.7039, -8.0369],
      [110.7043, -8.0376],
      [110.7045, -8.0379],
      [110.7051, -8.0384],
      [110.7058, -8.0388],
      [110.7065, -8.0391],
      [110.7073, -8.0393],
      [110.7086, -8.0391],
      [110.7089, -8.0392],
      [110.7092, -8.0397],
      [110.7094, -8.0404],
      [110.7097, -8.0412],
      [110.7101, -8.0417],
      [110.7104, -8.0422],
      [110.7107, -8.0426],
      [110.7111, -8.0435],
      [110.7114, -8.0443],
      [110.7117, -8.0447],
      [110.712, -8.0449],
      [110.7124, -8.0451],
      [110.7128, -8.0454],
      [110.7133, -8.0459],
      [110.7137, -8.0464],
      [110.7139, -8.0466],
      [110.7146, -8.0468],
      [110.7152, -8.0468],
      [110.7159, -8.0466],
      [110.7167, -8.0466],
      [110.7174, -8.0466],
      [110.7177, -8.0464],
      [110.7182, -8.0462],
      [110.7187, -8.0461],
      [110.7192, -8.0462],
      [110.7198, -8.0466],
      [110.7201, -8.0469],
      [110.7205, -8.0471],
      [110.7214, -8.0478],
      [110.7227, -8.0485],
      [110.7236, -8.049],
      [110.7241, -8.0496],
      [110.7244, -8.0502],
      [110.7244, -8.051],
      [110.7243, -8.0519],
      [110.7243, -8.0527],
      [110.7243, -8.0536],
      [110.7243, -8.0545],
      [110.7241, -8.0553],
      [110.7238, -8.0561],
      [110.7233, -8.0569],
      [110.7231, -8.0572],
      [110.7226, -8.058],
      [110.7218, -8.0586],
      [110.7214, -8.0588],
      [110.722, -8.0594],
      [110.7232, -8.0611],
      [110.7236, -8.0617],
      [110.7245, -8.0622],
      [110.7263, -8.064],
      [110.7279, -8.0639],
      [110.7296, -8.064],
      [110.7301, -8.0642],
      [110.7303, -8.0644],
      [110.7302, -8.0648],
      [110.7297, -8.0655],
      [110.7289, -8.0665],
      [110.7285, -8.0674],
      [110.7282, -8.0678],
      [110.7282, -8.0681],
      [110.7284, -8.0685],
      [110.7287, -8.0685],
      [110.7304, -8.0681],
      [110.7311, -8.068],
      [110.7316, -8.0679],
      [110.7324, -8.0679],
      [110.7329, -8.0678],
      [110.7338, -8.0674],
      [110.7348, -8.0673],
      [110.7359, -8.0672],
      [110.7373, -8.0677],
      [110.7379, -8.0679],
      [110.7388, -8.0686],
      [110.7399, -8.069],
      [110.7405, -8.0692],
      [110.7414, -8.0695],
      [110.7418, -8.0696],
      [110.7419, -8.0692],
      [110.7422, -8.0682],
      [110.7422, -8.067],
      [110.7417, -8.0657],
      [110.7415, -8.0649],
      [110.7415, -8.0639],
      [110.7415, -8.0621],
      [110.7418, -8.0602],
      [110.7416, -8.0587],
      [110.7416, -8.0579],
      [110.7418, -8.0575],
      [110.742, -8.0572],
      [110.743, -8.0565],
      [110.7435, -8.056],
      [110.7438, -8.0552],
      [110.744, -8.0548],
      [110.7456, -8.054],
      [110.7466, -8.0534],
      [110.7469, -8.0531],
      [110.7469, -8.0527],
      [110.7467, -8.0522],
      [110.7459, -8.0518],
      [110.7451, -8.0515],
      [110.7436, -8.0517],
      [110.7429, -8.0515],
      [110.7421, -8.0505],
      [110.7416, -8.0494],
      [110.7415, -8.0493],
      [110.7415, -8.0486],
      [110.7415, -8.0471],
      [110.7414, -8.0462],
      [110.7408, -8.0447],
      [110.7405, -8.0441],
      [110.7401, -8.0433],
      [110.7401, -8.0432],
      [110.7398, -8.0424],
      [110.7402, -8.0412],
      [110.7406, -8.0399],
      [110.741, -8.039],
      [110.7413, -8.0387],
      [110.742, -8.0379],
      [110.7425, -8.0374]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'SEMUGIH',
    border: [
      [110.7749, -8.0868],
      [110.7735, -8.0855],
      [110.7732, -8.0855],
      [110.7729, -8.0847],
      [110.7715, -8.0839],
      [110.7706, -8.0834],
      [110.7702, -8.0829],
      [110.7699, -8.0814],
      [110.7695, -8.0803],
      [110.7688, -8.0801],
      [110.7677, -8.0803],
      [110.766, -8.0802],
      [110.765, -8.0801],
      [110.763, -8.0797],
      [110.7619, -8.0795],
      [110.7613, -8.079],
      [110.7597, -8.0777],
      [110.7591, -8.0772],
      [110.7589, -8.0769],
      [110.759, -8.0763],
      [110.759, -8.0757],
      [110.7594, -8.0751],
      [110.7601, -8.0737],
      [110.7616, -8.0736],
      [110.7621, -8.0736],
      [110.7632, -8.0738],
      [110.7642, -8.0738],
      [110.765, -8.0738],
      [110.7661, -8.0735],
      [110.7663, -8.0724],
      [110.7666, -8.0712],
      [110.7674, -8.0709],
      [110.7677, -8.0708],
      [110.7681, -8.0706],
      [110.7683, -8.0696],
      [110.7688, -8.0691],
      [110.7687, -8.0683],
      [110.7683, -8.0677],
      [110.7672, -8.0674],
      [110.7654, -8.0669],
      [110.7647, -8.0663],
      [110.764, -8.0657],
      [110.7636, -8.0649],
      [110.7629, -8.0631],
      [110.7629, -8.0626],
      [110.7632, -8.0614],
      [110.7632, -8.0606],
      [110.7634, -8.0594],
      [110.764, -8.0587],
      [110.7659, -8.0568],
      [110.7663, -8.0559],
      [110.7662, -8.0554],
      [110.7658, -8.0548],
      [110.7651, -8.0539],
      [110.7639, -8.0523],
      [110.7634, -8.0519],
      [110.7621, -8.0516],
      [110.7605, -8.0512],
      [110.7601, -8.0516],
      [110.7596, -8.0519],
      [110.7589, -8.0522],
      [110.7582, -8.0524],
      [110.7571, -8.0521],
      [110.7567, -8.0518],
      [110.7561, -8.0508],
      [110.7553, -8.0495],
      [110.7549, -8.0491],
      [110.7535, -8.0483],
      [110.7517, -8.0468],
      [110.7511, -8.0463],
      [110.7509, -8.0461],
      [110.7504, -8.0457],
      [110.75, -8.0456],
      [110.7488, -8.0455],
      [110.7469, -8.0457],
      [110.746, -8.0454],
      [110.7448, -8.0447],
      [110.7438, -8.044],
      [110.7432, -8.0433],
      [110.7426, -8.0428],
      [110.7417, -8.0428],
      [110.7414, -8.0429],
      [110.7409, -8.0431],
      [110.7401, -8.0433],
      [110.7405, -8.0441],
      [110.7408, -8.0447],
      [110.7414, -8.0462],
      [110.7415, -8.0471],
      [110.7415, -8.0486],
      [110.7415, -8.0493],
      [110.7416, -8.0494],
      [110.7421, -8.0505],
      [110.7429, -8.0515],
      [110.7436, -8.0517],
      [110.7451, -8.0515],
      [110.7459, -8.0518],
      [110.7467, -8.0522],
      [110.7469, -8.0527],
      [110.7469, -8.0531],
      [110.7466, -8.0534],
      [110.7456, -8.054],
      [110.744, -8.0548],
      [110.7438, -8.0552],
      [110.7435, -8.056],
      [110.743, -8.0565],
      [110.742, -8.0572],
      [110.7418, -8.0575],
      [110.7416, -8.0579],
      [110.7416, -8.0587],
      [110.7418, -8.0602],
      [110.7415, -8.0621],
      [110.7415, -8.0639],
      [110.7415, -8.0649],
      [110.7417, -8.0657],
      [110.7422, -8.067],
      [110.7422, -8.0682],
      [110.7419, -8.0692],
      [110.7418, -8.0696],
      [110.7415, -8.0703],
      [110.7412, -8.0712],
      [110.7412, -8.0714],
      [110.7413, -8.0721],
      [110.7415, -8.0732],
      [110.7414, -8.0741],
      [110.7411, -8.076],
      [110.7409, -8.0769],
      [110.7406, -8.0773],
      [110.7415, -8.0773],
      [110.7419, -8.0774],
      [110.7429, -8.0778],
      [110.7435, -8.0782],
      [110.7437, -8.0784],
      [110.7444, -8.0801],
      [110.7447, -8.0809],
      [110.7456, -8.0821],
      [110.7468, -8.0828],
      [110.7476, -8.0831],
      [110.7486, -8.0836],
      [110.749, -8.0839],
      [110.7498, -8.0842],
      [110.7504, -8.0842],
      [110.7509, -8.0844],
      [110.7514, -8.0845],
      [110.7523, -8.0851],
      [110.753, -8.0859],
      [110.7536, -8.0866],
      [110.7542, -8.0879],
      [110.7554, -8.0901],
      [110.7561, -8.0906],
      [110.7577, -8.092],
      [110.7586, -8.0926],
      [110.7594, -8.0931],
      [110.7606, -8.0938],
      [110.7611, -8.0941],
      [110.7618, -8.0943],
      [110.7623, -8.0944],
      [110.7625, -8.0944],
      [110.7639, -8.094],
      [110.7645, -8.0938],
      [110.7649, -8.0935],
      [110.7654, -8.0929],
      [110.7662, -8.0918],
      [110.7671, -8.0905],
      [110.7677, -8.09],
      [110.7683, -8.0897],
      [110.769, -8.0899],
      [110.7697, -8.0902],
      [110.7704, -8.0904],
      [110.7707, -8.0902],
      [110.7717, -8.0896],
      [110.7744, -8.0872],
      [110.7749, -8.0868]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'MELIKAN',
    border: [
      [110.7812, -8.0879],
      [110.7803, -8.0867],
      [110.7801, -8.0863],
      [110.7799, -8.0859],
      [110.7795, -8.0862],
      [110.7792, -8.0863],
      [110.7786, -8.0867],
      [110.7783, -8.0867],
      [110.7777, -8.0867],
      [110.7771, -8.0869],
      [110.7764, -8.0869],
      [110.7759, -8.0869],
      [110.7755, -8.087],
      [110.7749, -8.0868],
      [110.7744, -8.0872],
      [110.7717, -8.0896],
      [110.7707, -8.0902],
      [110.7704, -8.0904],
      [110.7697, -8.0902],
      [110.769, -8.0899],
      [110.7683, -8.0897],
      [110.7677, -8.09],
      [110.7671, -8.0905],
      [110.7662, -8.0918],
      [110.7654, -8.0929],
      [110.7649, -8.0935],
      [110.7645, -8.0938],
      [110.7639, -8.094],
      [110.7625, -8.0944],
      [110.7623, -8.0945],
      [110.7631, -8.0957],
      [110.764, -8.097],
      [110.7644, -8.0978],
      [110.7646, -8.0985],
      [110.764, -8.1003],
      [110.7629, -8.1038],
      [110.7625, -8.1052],
      [110.762, -8.1067],
      [110.7621, -8.1069],
      [110.7621, -8.1077],
      [110.7619, -8.1085],
      [110.7607, -8.1083],
      [110.76, -8.1084],
      [110.7592, -8.1087],
      [110.7582, -8.1091],
      [110.7575, -8.109],
      [110.7573, -8.11],
      [110.7573, -8.1112],
      [110.7574, -8.1123],
      [110.7577, -8.1133],
      [110.7577, -8.1141],
      [110.7577, -8.1151],
      [110.7577, -8.1157],
      [110.7604, -8.1163],
      [110.764, -8.1169],
      [110.7673, -8.1177],
      [110.7696, -8.1187],
      [110.7715, -8.1191],
      [110.7727, -8.1196],
      [110.7735, -8.1202],
      [110.7741, -8.1211],
      [110.7746, -8.1217],
      [110.775, -8.1223],
      [110.7751, -8.123],
      [110.7752, -8.1241],
      [110.7751, -8.125],
      [110.775, -8.1255],
      [110.7729, -8.1263],
      [110.7724, -8.1268],
      [110.7722, -8.127],
      [110.772, -8.1275],
      [110.7719, -8.128],
      [110.7718, -8.1284],
      [110.7716, -8.1296],
      [110.7715, -8.1308],
      [110.7725, -8.1314],
      [110.7733, -8.132],
      [110.774, -8.1322],
      [110.7751, -8.1322],
      [110.7758, -8.1322],
      [110.7767, -8.1325],
      [110.7773, -8.1329],
      [110.7776, -8.1331],
      [110.7781, -8.1331],
      [110.779, -8.1327],
      [110.7794, -8.1323],
      [110.7799, -8.1317],
      [110.7804, -8.1314],
      [110.781, -8.1312],
      [110.7817, -8.1315],
      [110.7821, -8.1317],
      [110.7822, -8.1318],
      [110.7824, -8.1314],
      [110.7829, -8.131],
      [110.7838, -8.1308],
      [110.7852, -8.1306],
      [110.7865, -8.1304],
      [110.7874, -8.1304],
      [110.7876, -8.1304],
      [110.7876, -8.1303],
      [110.7877, -8.1295],
      [110.7878, -8.1288],
      [110.788, -8.128],
      [110.788, -8.1278],
      [110.7882, -8.1275],
      [110.7885, -8.1271],
      [110.789, -8.1267],
      [110.7894, -8.1262],
      [110.7898, -8.1258],
      [110.7899, -8.1255],
      [110.7898, -8.1252],
      [110.7898, -8.125],
      [110.7897, -8.1242],
      [110.7896, -8.1233],
      [110.7894, -8.1223],
      [110.7893, -8.1213],
      [110.7893, -8.1202],
      [110.7893, -8.118],
      [110.7892, -8.1147],
      [110.7887, -8.1129],
      [110.7886, -8.1117],
      [110.7885, -8.1106],
      [110.7885, -8.1096],
      [110.7884, -8.1086],
      [110.7882, -8.1077],
      [110.788, -8.1067],
      [110.7878, -8.1059],
      [110.7877, -8.1049],
      [110.7877, -8.104],
      [110.7876, -8.1032],
      [110.7873, -8.1023],
      [110.7871, -8.1015],
      [110.7867, -8.1005],
      [110.7862, -8.0992],
      [110.7859, -8.0984],
      [110.7855, -8.0974],
      [110.7849, -8.0962],
      [110.7845, -8.0953],
      [110.7839, -8.0944],
      [110.7835, -8.0936],
      [110.7832, -8.0928],
      [110.7832, -8.0917],
      [110.783, -8.0907],
      [110.7826, -8.09],
      [110.782, -8.0891],
      [110.7812, -8.0879]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'RONGKOP',
    village: 'KARANGWUNI',
    border: [
      [110.7588, -8.0342],
      [110.7578, -8.0342],
      [110.7565, -8.0342],
      [110.7549, -8.0342],
      [110.7538, -8.0343],
      [110.7529, -8.0344],
      [110.7518, -8.0345],
      [110.7509, -8.0346],
      [110.7498, -8.0347],
      [110.749, -8.0345],
      [110.7479, -8.0345],
      [110.7472, -8.0347],
      [110.7467, -8.0349],
      [110.7465, -8.0352],
      [110.7463, -8.0356],
      [110.7461, -8.036],
      [110.7457, -8.0365],
      [110.7456, -8.0368],
      [110.7454, -8.0374],
      [110.7449, -8.0377],
      [110.7444, -8.0378],
      [110.7437, -8.0379],
      [110.7429, -8.0376],
      [110.7425, -8.0374],
      [110.742, -8.0379],
      [110.7413, -8.0387],
      [110.741, -8.039],
      [110.7406, -8.0399],
      [110.7402, -8.0412],
      [110.7398, -8.0424],
      [110.7401, -8.0432],
      [110.7401, -8.0433],
      [110.7409, -8.0431],
      [110.7414, -8.0429],
      [110.7417, -8.0428],
      [110.7426, -8.0428],
      [110.7432, -8.0433],
      [110.7438, -8.044],
      [110.7448, -8.0447],
      [110.746, -8.0454],
      [110.7469, -8.0457],
      [110.7488, -8.0455],
      [110.75, -8.0456],
      [110.7504, -8.0457],
      [110.7509, -8.0461],
      [110.7511, -8.0463],
      [110.7517, -8.0468],
      [110.7535, -8.0483],
      [110.7549, -8.0491],
      [110.7553, -8.0495],
      [110.7561, -8.0508],
      [110.7567, -8.0518],
      [110.7571, -8.0521],
      [110.7582, -8.0524],
      [110.7589, -8.0522],
      [110.7596, -8.0519],
      [110.7601, -8.0516],
      [110.7605, -8.0512],
      [110.7621, -8.0516],
      [110.7634, -8.0519],
      [110.7639, -8.0523],
      [110.7651, -8.0539],
      [110.7658, -8.0548],
      [110.7662, -8.0554],
      [110.7663, -8.0559],
      [110.7659, -8.0568],
      [110.764, -8.0587],
      [110.7634, -8.0594],
      [110.7632, -8.0606],
      [110.7632, -8.0614],
      [110.7629, -8.0626],
      [110.7629, -8.0631],
      [110.7636, -8.0649],
      [110.764, -8.0657],
      [110.7647, -8.0663],
      [110.7654, -8.0669],
      [110.7672, -8.0674],
      [110.7683, -8.0677],
      [110.7687, -8.0683],
      [110.7688, -8.0691],
      [110.7683, -8.0696],
      [110.7681, -8.0706],
      [110.7677, -8.0708],
      [110.7674, -8.0709],
      [110.7666, -8.0712],
      [110.7663, -8.0724],
      [110.7661, -8.0735],
      [110.765, -8.0738],
      [110.7642, -8.0738],
      [110.7632, -8.0738],
      [110.7621, -8.0736],
      [110.7616, -8.0736],
      [110.7601, -8.0737],
      [110.7594, -8.0751],
      [110.759, -8.0757],
      [110.759, -8.0763],
      [110.7589, -8.0769],
      [110.7591, -8.0772],
      [110.7597, -8.0777],
      [110.7613, -8.079],
      [110.7619, -8.0795],
      [110.763, -8.0797],
      [110.765, -8.0801],
      [110.766, -8.0802],
      [110.7677, -8.0803],
      [110.7688, -8.0801],
      [110.7695, -8.0803],
      [110.7699, -8.0814],
      [110.7702, -8.0829],
      [110.7706, -8.0834],
      [110.7715, -8.0839],
      [110.7729, -8.0847],
      [110.7732, -8.0855],
      [110.7735, -8.0855],
      [110.7749, -8.0868],
      [110.7755, -8.087],
      [110.7759, -8.0869],
      [110.7764, -8.0869],
      [110.7771, -8.0869],
      [110.7777, -8.0867],
      [110.7783, -8.0867],
      [110.7786, -8.0867],
      [110.7792, -8.0863],
      [110.7795, -8.0862],
      [110.7799, -8.0859],
      [110.7788, -8.0842],
      [110.7787, -8.0822],
      [110.7787, -8.0812],
      [110.7787, -8.0806],
      [110.7794, -8.0777],
      [110.7796, -8.0766],
      [110.7796, -8.0757],
      [110.7795, -8.0745],
      [110.7794, -8.073],
      [110.7792, -8.0718],
      [110.7792, -8.0712],
      [110.7791, -8.0709],
      [110.7789, -8.0697],
      [110.7787, -8.0687],
      [110.7787, -8.0685],
      [110.7785, -8.0676],
      [110.7784, -8.0675],
      [110.7783, -8.0672],
      [110.7778, -8.0665],
      [110.7772, -8.0655],
      [110.7753, -8.0624],
      [110.7746, -8.0607],
      [110.774, -8.0592],
      [110.7732, -8.0583],
      [110.7717, -8.0574],
      [110.7715, -8.0572],
      [110.7713, -8.0567],
      [110.7711, -8.0564],
      [110.771, -8.0561],
      [110.7705, -8.0552],
      [110.7705, -8.055],
      [110.7704, -8.0549],
      [110.7702, -8.0546],
      [110.7701, -8.0542],
      [110.77, -8.0537],
      [110.7697, -8.0532],
      [110.7696, -8.0528],
      [110.7694, -8.0527],
      [110.7693, -8.0526],
      [110.7692, -8.0522],
      [110.769, -8.0519],
      [110.769, -8.0517],
      [110.7687, -8.051],
      [110.7686, -8.0504],
      [110.7685, -8.0499],
      [110.7685, -8.0495],
      [110.7684, -8.0489],
      [110.7683, -8.048],
      [110.7683, -8.0478],
      [110.7682, -8.0477],
      [110.7681, -8.0471],
      [110.7681, -8.047],
      [110.7681, -8.0467],
      [110.768, -8.0466],
      [110.7674, -8.0457],
      [110.7674, -8.0455],
      [110.7666, -8.0441],
      [110.7614, -8.0365],
      [110.7604, -8.0351],
      [110.7588, -8.0342]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'KALITEKUK',
    border: [
      [110.7116, -7.848],
      [110.7116, -7.8477],
      [110.7111, -7.8476],
      [110.7109, -7.8472],
      [110.7109, -7.8469],
      [110.7108, -7.8466],
      [110.7105, -7.8459],
      [110.7107, -7.8455],
      [110.7109, -7.8451],
      [110.7109, -7.8447],
      [110.7109, -7.8442],
      [110.711, -7.8436],
      [110.7107, -7.8433],
      [110.7106, -7.843],
      [110.7106, -7.8427],
      [110.7108, -7.8424],
      [110.7108, -7.8419],
      [110.7107, -7.8415],
      [110.7107, -7.8411],
      [110.7107, -7.8407],
      [110.7106, -7.8402],
      [110.7101, -7.8401],
      [110.7095, -7.8397],
      [110.7091, -7.8394],
      [110.7089, -7.8391],
      [110.7083, -7.8389],
      [110.7078, -7.8388],
      [110.7075, -7.8386],
      [110.707, -7.8385],
      [110.7067, -7.8381],
      [110.7066, -7.8377],
      [110.7062, -7.8376],
      [110.7057, -7.8373],
      [110.7052, -7.8371],
      [110.7052, -7.8366],
      [110.7053, -7.8362],
      [110.7052, -7.8357],
      [110.7051, -7.8354],
      [110.705, -7.8356],
      [110.7047, -7.8365],
      [110.7048, -7.8374],
      [110.7051, -7.8381],
      [110.7056, -7.8397],
      [110.7057, -7.8409],
      [110.7057, -7.8417],
      [110.7061, -7.8431],
      [110.7063, -7.8441],
      [110.7064, -7.8454],
      [110.7064, -7.846],
      [110.7062, -7.8462],
      [110.7058, -7.8472],
      [110.7052, -7.8485],
      [110.7046, -7.8483],
      [110.7041, -7.8483],
      [110.7037, -7.8482],
      [110.7026, -7.8475],
      [110.7021, -7.8479],
      [110.7016, -7.8481],
      [110.7015, -7.8485],
      [110.7015, -7.8487],
      [110.7012, -7.8486],
      [110.7009, -7.8488],
      [110.7006, -7.849],
      [110.7002, -7.8496],
      [110.7, -7.8501],
      [110.7, -7.8504],
      [110.6998, -7.8506],
      [110.6997, -7.8508],
      [110.6996, -7.8511],
      [110.6993, -7.8511],
      [110.6991, -7.8513],
      [110.699, -7.8516],
      [110.699, -7.8518],
      [110.6996, -7.8519],
      [110.6999, -7.8521],
      [110.7, -7.8525],
      [110.6998, -7.8529],
      [110.7001, -7.8532],
      [110.7003, -7.8535],
      [110.7003, -7.8538],
      [110.7003, -7.8541],
      [110.7003, -7.8542],
      [110.7004, -7.8546],
      [110.7003, -7.8547],
      [110.7002, -7.8548],
      [110.6996, -7.856],
      [110.6993, -7.8568],
      [110.699, -7.8571],
      [110.6982, -7.8587],
      [110.6981, -7.859],
      [110.6981, -7.8591],
      [110.698, -7.8602],
      [110.6981, -7.8614],
      [110.6983, -7.8638],
      [110.6984, -7.8656],
      [110.6984, -7.8659],
      [110.6984, -7.8662],
      [110.6981, -7.867],
      [110.6979, -7.8676],
      [110.6977, -7.8684],
      [110.6973, -7.8691],
      [110.697, -7.8696],
      [110.6965, -7.8706],
      [110.696, -7.8715],
      [110.6962, -7.8717],
      [110.6967, -7.872],
      [110.6972, -7.8724],
      [110.6978, -7.8728],
      [110.6978, -7.8729],
      [110.6979, -7.8731],
      [110.6976, -7.8734],
      [110.6974, -7.8736],
      [110.6969, -7.8738],
      [110.6962, -7.8739],
      [110.6955, -7.8741],
      [110.6948, -7.8742],
      [110.6942, -7.8742],
      [110.6937, -7.8744],
      [110.6935, -7.8749],
      [110.6933, -7.8753],
      [110.6929, -7.8757],
      [110.6928, -7.8761],
      [110.6926, -7.8767],
      [110.6925, -7.8769],
      [110.6922, -7.8769],
      [110.6917, -7.8769],
      [110.6917, -7.8773],
      [110.6918, -7.8778],
      [110.6916, -7.8779],
      [110.6917, -7.8789],
      [110.6919, -7.8797],
      [110.6921, -7.8806],
      [110.6921, -7.8816],
      [110.6918, -7.8827],
      [110.6914, -7.8838],
      [110.6914, -7.8848],
      [110.6913, -7.8858],
      [110.6912, -7.8868],
      [110.6913, -7.8875],
      [110.6914, -7.8886],
      [110.6913, -7.8891],
      [110.6912, -7.8896],
      [110.6914, -7.8896],
      [110.6919, -7.8902],
      [110.6925, -7.8905],
      [110.6927, -7.8914],
      [110.6929, -7.8919],
      [110.6936, -7.8917],
      [110.6942, -7.8914],
      [110.6948, -7.8909],
      [110.6956, -7.8903],
      [110.6965, -7.89],
      [110.6976, -7.8895],
      [110.6983, -7.8887],
      [110.6992, -7.8874],
      [110.6997, -7.8866],
      [110.6998, -7.8866],
      [110.7008, -7.887],
      [110.701, -7.8869],
      [110.7013, -7.8862],
      [110.7016, -7.8862],
      [110.7024, -7.8861],
      [110.7026, -7.8862],
      [110.7028, -7.8863],
      [110.7031, -7.8866],
      [110.7037, -7.8869],
      [110.7042, -7.8872],
      [110.7042, -7.8876],
      [110.7042, -7.888],
      [110.7039, -7.8884],
      [110.7039, -7.8888],
      [110.7036, -7.8891],
      [110.703, -7.8895],
      [110.7026, -7.8897],
      [110.7023, -7.89],
      [110.7023, -7.89],
      [110.7023, -7.8903],
      [110.7023, -7.8904],
      [110.7025, -7.8908],
      [110.7029, -7.891],
      [110.703, -7.8913],
      [110.7032, -7.8919],
      [110.7037, -7.8921],
      [110.7044, -7.8925],
      [110.7047, -7.8929],
      [110.7052, -7.8934],
      [110.7056, -7.8938],
      [110.7059, -7.8941],
      [110.7059, -7.8947],
      [110.7063, -7.8953],
      [110.7066, -7.8955],
      [110.7072, -7.8958],
      [110.7072, -7.8961],
      [110.7073, -7.8967],
      [110.7076, -7.8975],
      [110.7078, -7.898],
      [110.708, -7.8985],
      [110.708, -7.8986],
      [110.7083, -7.8995],
      [110.7084, -7.9001],
      [110.7084, -7.9009],
      [110.7086, -7.9013],
      [110.7088, -7.9019],
      [110.7089, -7.9023],
      [110.7093, -7.9023],
      [110.7095, -7.9021],
      [110.7096, -7.9018],
      [110.7098, -7.9018],
      [110.71, -7.902],
      [110.7102, -7.9025],
      [110.7118, -7.9025],
      [110.712, -7.9024],
      [110.7127, -7.9027],
      [110.7127, -7.903],
      [110.7126, -7.9033],
      [110.7131, -7.9036],
      [110.7134, -7.9037],
      [110.7137, -7.904],
      [110.7142, -7.9036],
      [110.7143, -7.9039],
      [110.7147, -7.9038],
      [110.7151, -7.9035],
      [110.7156, -7.9033],
      [110.7162, -7.9032],
      [110.7164, -7.9033],
      [110.7173, -7.9032],
      [110.718, -7.9031],
      [110.7183, -7.9023],
      [110.7188, -7.9024],
      [110.7191, -7.9023],
      [110.7191, -7.9023],
      [110.7189, -7.9019],
      [110.7191, -7.9017],
      [110.7189, -7.9016],
      [110.718, -7.9016],
      [110.7168, -7.9016],
      [110.7165, -7.9013],
      [110.7162, -7.901],
      [110.7161, -7.9006],
      [110.7161, -7.9002],
      [110.7162, -7.8998],
      [110.7164, -7.8995],
      [110.7164, -7.8991],
      [110.7162, -7.8984],
      [110.7159, -7.8976],
      [110.7156, -7.8974],
      [110.7155, -7.8971],
      [110.7154, -7.8966],
      [110.715, -7.8953],
      [110.7151, -7.8952],
      [110.7153, -7.895],
      [110.7158, -7.8947],
      [110.7159, -7.894],
      [110.716, -7.8934],
      [110.7162, -7.8929],
      [110.7162, -7.8927],
      [110.7162, -7.8921],
      [110.7163, -7.8915],
      [110.7166, -7.891],
      [110.7169, -7.8905],
      [110.7171, -7.89],
      [110.7171, -7.8896],
      [110.7168, -7.8894],
      [110.7164, -7.889],
      [110.7163, -7.8885],
      [110.7162, -7.8883],
      [110.7161, -7.887],
      [110.7158, -7.8869],
      [110.7149, -7.8867],
      [110.7148, -7.8864],
      [110.714, -7.886],
      [110.7136, -7.8862],
      [110.7129, -7.8864],
      [110.7119, -7.8865],
      [110.7114, -7.8864],
      [110.7107, -7.8857],
      [110.7101, -7.8852],
      [110.7097, -7.885],
      [110.7097, -7.8848],
      [110.7102, -7.8847],
      [110.7102, -7.8846],
      [110.71, -7.884],
      [110.7103, -7.8838],
      [110.7103, -7.8836],
      [110.7104, -7.8835],
      [110.7104, -7.8833],
      [110.7104, -7.8828],
      [110.7106, -7.8823],
      [110.7106, -7.8814],
      [110.7107, -7.8805],
      [110.7107, -7.8801],
      [110.7107, -7.8792],
      [110.711, -7.8788],
      [110.7114, -7.8784],
      [110.7113, -7.8778],
      [110.7113, -7.8775],
      [110.7113, -7.877],
      [110.7113, -7.8766],
      [110.7109, -7.8761],
      [110.7108, -7.876],
      [110.7103, -7.8753],
      [110.7106, -7.8747],
      [110.7108, -7.8736],
      [110.7113, -7.8727],
      [110.7115, -7.8722],
      [110.7113, -7.8715],
      [110.7111, -7.8708],
      [110.7109, -7.8702],
      [110.7107, -7.8699],
      [110.7107, -7.8697],
      [110.7108, -7.8692],
      [110.7105, -7.869],
      [110.7103, -7.8686],
      [110.71, -7.8685],
      [110.7098, -7.868],
      [110.7098, -7.8677],
      [110.71, -7.8673],
      [110.7103, -7.8671],
      [110.7104, -7.8666],
      [110.7104, -7.866],
      [110.7103, -7.8653],
      [110.71, -7.8649],
      [110.7097, -7.8649],
      [110.709, -7.865],
      [110.7076, -7.8648],
      [110.7069, -7.8646],
      [110.7062, -7.8645],
      [110.7061, -7.8642],
      [110.706, -7.8637],
      [110.7057, -7.8636],
      [110.7054, -7.8635],
      [110.705, -7.8635],
      [110.7048, -7.864],
      [110.7045, -7.8644],
      [110.7043, -7.8646],
      [110.704, -7.8647],
      [110.7037, -7.8645],
      [110.7035, -7.8642],
      [110.7033, -7.8639],
      [110.7033, -7.8635],
      [110.7035, -7.8631],
      [110.7038, -7.8626],
      [110.7041, -7.862],
      [110.7047, -7.8617],
      [110.7052, -7.8612],
      [110.7062, -7.8603],
      [110.7073, -7.8593],
      [110.7083, -7.8581],
      [110.7088, -7.8575],
      [110.709, -7.8573],
      [110.7092, -7.8571],
      [110.71, -7.8555],
      [110.7103, -7.8543],
      [110.7105, -7.8532],
      [110.7109, -7.8527],
      [110.7116, -7.852],
      [110.7122, -7.8516],
      [110.7122, -7.8516],
      [110.7122, -7.8514],
      [110.7121, -7.851],
      [110.7121, -7.8503],
      [110.7122, -7.8497],
      [110.7118, -7.8494],
      [110.7118, -7.8491],
      [110.7119, -7.8487],
      [110.7116, -7.8486],
      [110.7116, -7.848]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'KEMEJING',
    border: [
      [110.7152, -7.8607],
      [110.7148, -7.8602],
      [110.7147, -7.8599],
      [110.7145, -7.8594],
      [110.7143, -7.8589],
      [110.7143, -7.8588],
      [110.7144, -7.8586],
      [110.7145, -7.8584],
      [110.7146, -7.8578],
      [110.7149, -7.8575],
      [110.715, -7.8575],
      [110.7152, -7.8572],
      [110.7153, -7.8565],
      [110.7154, -7.8564],
      [110.7158, -7.8562],
      [110.7161, -7.8559],
      [110.7162, -7.8554],
      [110.7165, -7.8551],
      [110.7167, -7.8551],
      [110.7166, -7.8545],
      [110.7163, -7.8539],
      [110.7153, -7.8528],
      [110.7137, -7.8519],
      [110.7125, -7.8514],
      [110.7123, -7.8516],
      [110.7122, -7.8516],
      [110.7116, -7.852],
      [110.7109, -7.8527],
      [110.7105, -7.8532],
      [110.7103, -7.8543],
      [110.71, -7.8555],
      [110.7092, -7.8571],
      [110.709, -7.8573],
      [110.7088, -7.8575],
      [110.7083, -7.8581],
      [110.7073, -7.8593],
      [110.7062, -7.8603],
      [110.7052, -7.8612],
      [110.7047, -7.8617],
      [110.7041, -7.862],
      [110.7038, -7.8626],
      [110.7035, -7.8631],
      [110.7033, -7.8635],
      [110.7033, -7.8639],
      [110.7035, -7.8642],
      [110.7037, -7.8645],
      [110.704, -7.8647],
      [110.7043, -7.8646],
      [110.7045, -7.8644],
      [110.7048, -7.864],
      [110.705, -7.8635],
      [110.7054, -7.8635],
      [110.7057, -7.8636],
      [110.706, -7.8637],
      [110.7061, -7.8642],
      [110.7062, -7.8645],
      [110.7069, -7.8646],
      [110.7076, -7.8648],
      [110.709, -7.865],
      [110.7097, -7.8649],
      [110.71, -7.8649],
      [110.7103, -7.8653],
      [110.7104, -7.866],
      [110.7104, -7.8666],
      [110.7103, -7.8671],
      [110.71, -7.8673],
      [110.7098, -7.8677],
      [110.7098, -7.868],
      [110.71, -7.8685],
      [110.7103, -7.8686],
      [110.7105, -7.869],
      [110.7108, -7.8692],
      [110.7107, -7.8697],
      [110.7107, -7.8699],
      [110.7109, -7.8702],
      [110.7111, -7.8708],
      [110.7113, -7.8715],
      [110.7115, -7.8722],
      [110.7113, -7.8727],
      [110.7108, -7.8736],
      [110.7106, -7.8747],
      [110.7103, -7.8753],
      [110.7108, -7.876],
      [110.7109, -7.8761],
      [110.7113, -7.8766],
      [110.7113, -7.877],
      [110.7113, -7.8775],
      [110.7113, -7.8778],
      [110.7114, -7.8784],
      [110.711, -7.8788],
      [110.7107, -7.8792],
      [110.7107, -7.8801],
      [110.7107, -7.8805],
      [110.7106, -7.8814],
      [110.7106, -7.8823],
      [110.7104, -7.8828],
      [110.7104, -7.8833],
      [110.7104, -7.8835],
      [110.7103, -7.8836],
      [110.7103, -7.8838],
      [110.71, -7.884],
      [110.7102, -7.8846],
      [110.7102, -7.8847],
      [110.7097, -7.8848],
      [110.7097, -7.885],
      [110.7101, -7.8852],
      [110.7107, -7.8857],
      [110.7114, -7.8864],
      [110.7119, -7.8865],
      [110.7129, -7.8864],
      [110.7136, -7.8862],
      [110.714, -7.886],
      [110.7148, -7.8864],
      [110.7149, -7.8867],
      [110.7158, -7.8869],
      [110.7158, -7.8866],
      [110.716, -7.8864],
      [110.7166, -7.8864],
      [110.7169, -7.8862],
      [110.717, -7.8857],
      [110.7173, -7.8856],
      [110.7171, -7.8846],
      [110.7169, -7.8843],
      [110.7167, -7.8842],
      [110.7169, -7.8828],
      [110.717, -7.8822],
      [110.7168, -7.8821],
      [110.7166, -7.8819],
      [110.7167, -7.8813],
      [110.7169, -7.8805],
      [110.7172, -7.8796],
      [110.7171, -7.8794],
      [110.7171, -7.8791],
      [110.7172, -7.879],
      [110.7177, -7.8791],
      [110.7187, -7.8795],
      [110.7194, -7.8797],
      [110.7202, -7.8795],
      [110.7204, -7.8793],
      [110.721, -7.8787],
      [110.7209, -7.8783],
      [110.7206, -7.8776],
      [110.7206, -7.8772],
      [110.721, -7.8769],
      [110.7212, -7.8768],
      [110.7222, -7.8765],
      [110.7224, -7.8764],
      [110.7229, -7.8763],
      [110.7232, -7.8762],
      [110.7233, -7.8757],
      [110.7235, -7.875],
      [110.7238, -7.8745],
      [110.7239, -7.874],
      [110.7241, -7.8732],
      [110.7242, -7.8725],
      [110.7247, -7.8725],
      [110.7249, -7.8725],
      [110.7249, -7.8724],
      [110.7249, -7.8721],
      [110.7252, -7.8707],
      [110.7258, -7.8696],
      [110.726, -7.8686],
      [110.726, -7.868],
      [110.7258, -7.8677],
      [110.7253, -7.8675],
      [110.7249, -7.8674],
      [110.7244, -7.8672],
      [110.724, -7.8667],
      [110.724, -7.8663],
      [110.7244, -7.8657],
      [110.725, -7.8653],
      [110.7257, -7.8646],
      [110.7264, -7.8636],
      [110.7267, -7.8626],
      [110.7268, -7.8617],
      [110.7263, -7.8617],
      [110.7256, -7.8618],
      [110.7251, -7.8618],
      [110.7248, -7.8617],
      [110.7243, -7.8612],
      [110.7239, -7.8608],
      [110.7236, -7.8605],
      [110.7233, -7.8607],
      [110.7229, -7.861],
      [110.7225, -7.8611],
      [110.7211, -7.8605],
      [110.7203, -7.8598],
      [110.7195, -7.8595],
      [110.7188, -7.8594],
      [110.7185, -7.8595],
      [110.7177, -7.8602],
      [110.7172, -7.8606],
      [110.717, -7.8608],
      [110.716, -7.8609],
      [110.7152, -7.8607]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'BULUREJO',
    border: [
      [110.7358, -7.8528],
      [110.7356, -7.8528],
      [110.7351, -7.8525],
      [110.7334, -7.8513],
      [110.7327, -7.8509],
      [110.7312, -7.8504],
      [110.7309, -7.8511],
      [110.7305, -7.852],
      [110.7304, -7.8531],
      [110.7306, -7.8538],
      [110.7307, -7.8547],
      [110.7307, -7.855],
      [110.7307, -7.8553],
      [110.7303, -7.8561],
      [110.7297, -7.8564],
      [110.7289, -7.8569],
      [110.7282, -7.8572],
      [110.7282, -7.8579],
      [110.7283, -7.8583],
      [110.7283, -7.8589],
      [110.728, -7.8597],
      [110.7273, -7.8609],
      [110.7268, -7.8617],
      [110.7267, -7.8626],
      [110.7264, -7.8636],
      [110.7257, -7.8646],
      [110.725, -7.8653],
      [110.7244, -7.8657],
      [110.724, -7.8663],
      [110.724, -7.8667],
      [110.7244, -7.8672],
      [110.7249, -7.8674],
      [110.7253, -7.8675],
      [110.7258, -7.8677],
      [110.726, -7.868],
      [110.726, -7.8686],
      [110.7258, -7.8696],
      [110.7252, -7.8707],
      [110.7249, -7.8721],
      [110.7249, -7.8724],
      [110.7249, -7.8725],
      [110.7253, -7.8727],
      [110.726, -7.8731],
      [110.7262, -7.8729],
      [110.7262, -7.8724],
      [110.7261, -7.8722],
      [110.7257, -7.8718],
      [110.7256, -7.8713],
      [110.7259, -7.8712],
      [110.726, -7.8712],
      [110.7264, -7.8713],
      [110.727, -7.8715],
      [110.7271, -7.8715],
      [110.7276, -7.8713],
      [110.7279, -7.8709],
      [110.7282, -7.8705],
      [110.7284, -7.8701],
      [110.7287, -7.8698],
      [110.7288, -7.8697],
      [110.7299, -7.8696],
      [110.7305, -7.8692],
      [110.7306, -7.869],
      [110.7307, -7.8687],
      [110.731, -7.8685],
      [110.7314, -7.8683],
      [110.7316, -7.8679],
      [110.7317, -7.8674],
      [110.7318, -7.867],
      [110.7319, -7.8665],
      [110.7323, -7.8657],
      [110.7325, -7.8653],
      [110.7332, -7.865],
      [110.7336, -7.8646],
      [110.7336, -7.8642],
      [110.7335, -7.8637],
      [110.7332, -7.8635],
      [110.7331, -7.8631],
      [110.7333, -7.8626],
      [110.7336, -7.8622],
      [110.7339, -7.8622],
      [110.734, -7.8622],
      [110.7345, -7.8623],
      [110.735, -7.8624],
      [110.7355, -7.8624],
      [110.7363, -7.8625],
      [110.7371, -7.8623],
      [110.7374, -7.8623],
      [110.7378, -7.8622],
      [110.7384, -7.862],
      [110.7391, -7.8618],
      [110.7393, -7.8622],
      [110.7397, -7.8626],
      [110.7398, -7.8627],
      [110.7402, -7.8627],
      [110.7408, -7.8624],
      [110.7417, -7.8618],
      [110.7423, -7.8612],
      [110.7429, -7.8614],
      [110.7434, -7.8617],
      [110.7434, -7.8622],
      [110.7433, -7.8629],
      [110.7433, -7.8633],
      [110.7436, -7.8636],
      [110.7437, -7.8634],
      [110.7443, -7.8631],
      [110.7451, -7.8624],
      [110.7457, -7.8619],
      [110.746, -7.862],
      [110.746, -7.8618],
      [110.746, -7.8615],
      [110.7458, -7.8609],
      [110.7458, -7.8608],
      [110.7458, -7.8603],
      [110.7458, -7.8595],
      [110.7457, -7.8591],
      [110.7455, -7.8589],
      [110.7448, -7.8584],
      [110.7436, -7.858],
      [110.7427, -7.8579],
      [110.7416, -7.8579],
      [110.7411, -7.8577],
      [110.7401, -7.8573],
      [110.7397, -7.8567],
      [110.7395, -7.8562],
      [110.7395, -7.8556],
      [110.7395, -7.8551],
      [110.7396, -7.8546],
      [110.7398, -7.8538],
      [110.7396, -7.8535],
      [110.7394, -7.8534],
      [110.7393, -7.8533],
      [110.739, -7.8533],
      [110.7383, -7.8533],
      [110.7376, -7.8532],
      [110.7374, -7.8531],
      [110.737, -7.8529],
      [110.7369, -7.8529],
      [110.736, -7.8529],
      [110.7358, -7.8528]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'SUMBEREJO',
    border: [
      [110.7382, -7.8268],
      [110.7377, -7.827],
      [110.7374, -7.8272],
      [110.7369, -7.8276],
      [110.7364, -7.8284],
      [110.7363, -7.8293],
      [110.736, -7.8309],
      [110.7356, -7.8317],
      [110.735, -7.832],
      [110.7333, -7.8322],
      [110.7324, -7.832],
      [110.7312, -7.8315],
      [110.7309, -7.8308],
      [110.7308, -7.83],
      [110.7311, -7.8292],
      [110.7317, -7.8274],
      [110.732, -7.8254],
      [110.732, -7.8248],
      [110.7317, -7.8244],
      [110.7312, -7.824],
      [110.7301, -7.8236],
      [110.7288, -7.8232],
      [110.7259, -7.8225],
      [110.7238, -7.8219],
      [110.7225, -7.8218],
      [110.7216, -7.8216],
      [110.7199, -7.822],
      [110.7188, -7.8229],
      [110.7175, -7.824],
      [110.7169, -7.8245],
      [110.7152, -7.8248],
      [110.7146, -7.8245],
      [110.714, -7.8243],
      [110.7123, -7.8236],
      [110.7122, -7.824],
      [110.712, -7.8243],
      [110.7116, -7.8249],
      [110.7115, -7.8256],
      [110.7113, -7.8263],
      [110.7114, -7.8271],
      [110.7116, -7.8277],
      [110.7117, -7.8282],
      [110.7116, -7.8288],
      [110.7115, -7.8292],
      [110.7118, -7.8296],
      [110.7121, -7.83],
      [110.7124, -7.8304],
      [110.7122, -7.8309],
      [110.7123, -7.8313],
      [110.7124, -7.8317],
      [110.7125, -7.8321],
      [110.7125, -7.8322],
      [110.7126, -7.8326],
      [110.7128, -7.8331],
      [110.7134, -7.8332],
      [110.7139, -7.8333],
      [110.7143, -7.8339],
      [110.7145, -7.8343],
      [110.7148, -7.8343],
      [110.715, -7.8343],
      [110.7154, -7.834],
      [110.7158, -7.8338],
      [110.7166, -7.8338],
      [110.7167, -7.834],
      [110.7166, -7.8342],
      [110.7167, -7.8348],
      [110.7161, -7.8348],
      [110.716, -7.8352],
      [110.7159, -7.8356],
      [110.7158, -7.836],
      [110.7158, -7.8362],
      [110.7161, -7.8365],
      [110.7161, -7.8368],
      [110.7161, -7.8373],
      [110.7163, -7.8376],
      [110.7165, -7.838],
      [110.7164, -7.8384],
      [110.7164, -7.8387],
      [110.7166, -7.8392],
      [110.717, -7.8395],
      [110.7175, -7.8399],
      [110.7179, -7.8397],
      [110.718, -7.84],
      [110.7182, -7.8403],
      [110.7185, -7.8404],
      [110.7183, -7.8407],
      [110.7183, -7.8408],
      [110.7182, -7.841],
      [110.7181, -7.8414],
      [110.7181, -7.8417],
      [110.718, -7.8421],
      [110.7182, -7.8423],
      [110.7185, -7.8425],
      [110.7184, -7.8427],
      [110.7183, -7.8429],
      [110.7184, -7.8431],
      [110.7187, -7.8436],
      [110.7188, -7.8437],
      [110.7188, -7.8442],
      [110.719, -7.8447],
      [110.7196, -7.8459],
      [110.7199, -7.8462],
      [110.7204, -7.8471],
      [110.7215, -7.8472],
      [110.7233, -7.8472],
      [110.7251, -7.8473],
      [110.7261, -7.8474],
      [110.7268, -7.8478],
      [110.7273, -7.8484],
      [110.728, -7.8488],
      [110.7291, -7.8492],
      [110.7303, -7.8499],
      [110.7312, -7.8504],
      [110.7327, -7.8509],
      [110.7334, -7.8513],
      [110.7351, -7.8525],
      [110.7356, -7.8528],
      [110.7358, -7.8528],
      [110.7361, -7.8526],
      [110.7368, -7.8519],
      [110.7379, -7.8508],
      [110.7387, -7.8499],
      [110.7402, -7.8508],
      [110.7419, -7.8515],
      [110.7432, -7.8518],
      [110.7436, -7.8516],
      [110.7438, -7.8511],
      [110.7441, -7.8507],
      [110.7444, -7.8501],
      [110.745, -7.8501],
      [110.7457, -7.85],
      [110.7463, -7.8497],
      [110.7468, -7.8491],
      [110.7468, -7.8487],
      [110.7466, -7.8482],
      [110.7469, -7.8476],
      [110.7473, -7.8466],
      [110.748, -7.8457],
      [110.7484, -7.8455],
      [110.7492, -7.8449],
      [110.7496, -7.8439],
      [110.7497, -7.8434],
      [110.7499, -7.8427],
      [110.75, -7.8421],
      [110.7502, -7.8414],
      [110.7499, -7.841],
      [110.7496, -7.8402],
      [110.7492, -7.8388],
      [110.7491, -7.8381],
      [110.749, -7.838],
      [110.7486, -7.8373],
      [110.7483, -7.8367],
      [110.7478, -7.8364],
      [110.7471, -7.836],
      [110.7464, -7.8357],
      [110.7458, -7.8353],
      [110.7455, -7.8348],
      [110.7454, -7.8343],
      [110.7455, -7.8338],
      [110.7455, -7.8336],
      [110.7456, -7.8327],
      [110.7457, -7.8322],
      [110.7448, -7.832],
      [110.7442, -7.8317],
      [110.7438, -7.8313],
      [110.7429, -7.8309],
      [110.7421, -7.8305],
      [110.7418, -7.8303],
      [110.7416, -7.83],
      [110.741, -7.8297],
      [110.7406, -7.8292],
      [110.7403, -7.8286],
      [110.7398, -7.828],
      [110.7395, -7.8276],
      [110.7389, -7.8273],
      [110.7382, -7.8268]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'BENDUNG',
    border: [
      [110.7123, -7.8236],
      [110.7113, -7.8233],
      [110.7104, -7.8234],
      [110.7101, -7.8237],
      [110.7096, -7.8243],
      [110.709, -7.8251],
      [110.7082, -7.8267],
      [110.707, -7.829],
      [110.7067, -7.8302],
      [110.7065, -7.8312],
      [110.7064, -7.8324],
      [110.7061, -7.8332],
      [110.7057, -7.8343],
      [110.7051, -7.8354],
      [110.7052, -7.8357],
      [110.7053, -7.8362],
      [110.7052, -7.8366],
      [110.7052, -7.8371],
      [110.7057, -7.8373],
      [110.7062, -7.8376],
      [110.7066, -7.8377],
      [110.7067, -7.8381],
      [110.707, -7.8385],
      [110.7075, -7.8386],
      [110.7078, -7.8388],
      [110.7083, -7.8389],
      [110.7089, -7.8391],
      [110.7091, -7.8394],
      [110.7095, -7.8397],
      [110.7101, -7.8401],
      [110.7106, -7.8402],
      [110.7107, -7.8407],
      [110.7107, -7.8411],
      [110.7107, -7.8415],
      [110.7108, -7.8419],
      [110.7108, -7.8424],
      [110.7106, -7.8427],
      [110.7106, -7.843],
      [110.7107, -7.8433],
      [110.711, -7.8436],
      [110.7109, -7.8442],
      [110.7109, -7.8447],
      [110.7109, -7.8451],
      [110.7107, -7.8455],
      [110.7105, -7.8459],
      [110.7108, -7.8466],
      [110.7109, -7.8469],
      [110.7109, -7.8472],
      [110.7111, -7.8476],
      [110.7116, -7.8477],
      [110.7116, -7.848],
      [110.7116, -7.8486],
      [110.7119, -7.8487],
      [110.7118, -7.8491],
      [110.7118, -7.8494],
      [110.7122, -7.8497],
      [110.7121, -7.8503],
      [110.7121, -7.851],
      [110.7122, -7.8514],
      [110.7123, -7.8516],
      [110.7125, -7.8514],
      [110.7137, -7.8519],
      [110.7153, -7.8528],
      [110.7163, -7.8539],
      [110.7166, -7.8545],
      [110.7167, -7.8551],
      [110.7165, -7.8551],
      [110.7162, -7.8554],
      [110.7161, -7.8559],
      [110.7158, -7.8562],
      [110.7154, -7.8564],
      [110.7153, -7.8565],
      [110.7152, -7.8572],
      [110.715, -7.8575],
      [110.7149, -7.8575],
      [110.7146, -7.8578],
      [110.7145, -7.8584],
      [110.7144, -7.8586],
      [110.7143, -7.8588],
      [110.7143, -7.8589],
      [110.7145, -7.8594],
      [110.7147, -7.8599],
      [110.7148, -7.8602],
      [110.7152, -7.8607],
      [110.716, -7.8609],
      [110.717, -7.8608],
      [110.7172, -7.8606],
      [110.7177, -7.8602],
      [110.7185, -7.8595],
      [110.7188, -7.8594],
      [110.7195, -7.8595],
      [110.7203, -7.8598],
      [110.7211, -7.8605],
      [110.7225, -7.8611],
      [110.7229, -7.861],
      [110.7233, -7.8607],
      [110.7236, -7.8605],
      [110.7239, -7.8608],
      [110.7243, -7.8612],
      [110.7248, -7.8617],
      [110.7251, -7.8618],
      [110.7256, -7.8618],
      [110.7263, -7.8617],
      [110.7268, -7.8617],
      [110.7273, -7.8609],
      [110.728, -7.8597],
      [110.7283, -7.8589],
      [110.7283, -7.8583],
      [110.7282, -7.8579],
      [110.7282, -7.8572],
      [110.7289, -7.8569],
      [110.7297, -7.8564],
      [110.7303, -7.8561],
      [110.7307, -7.8553],
      [110.7307, -7.855],
      [110.7307, -7.8547],
      [110.7306, -7.8538],
      [110.7304, -7.8531],
      [110.7305, -7.852],
      [110.7309, -7.8511],
      [110.7312, -7.8504],
      [110.7303, -7.8499],
      [110.7291, -7.8492],
      [110.728, -7.8488],
      [110.7273, -7.8484],
      [110.7268, -7.8478],
      [110.7261, -7.8474],
      [110.7251, -7.8473],
      [110.7233, -7.8472],
      [110.7215, -7.8472],
      [110.7204, -7.8471],
      [110.7199, -7.8462],
      [110.7196, -7.8459],
      [110.719, -7.8447],
      [110.7188, -7.8442],
      [110.7188, -7.8437],
      [110.7187, -7.8436],
      [110.7184, -7.8431],
      [110.7183, -7.8429],
      [110.7184, -7.8427],
      [110.7185, -7.8425],
      [110.7182, -7.8423],
      [110.718, -7.8421],
      [110.7181, -7.8417],
      [110.7181, -7.8414],
      [110.7182, -7.841],
      [110.7183, -7.8408],
      [110.7183, -7.8407],
      [110.7185, -7.8404],
      [110.7182, -7.8403],
      [110.718, -7.84],
      [110.7179, -7.8397],
      [110.7175, -7.8399],
      [110.717, -7.8395],
      [110.7166, -7.8392],
      [110.7164, -7.8387],
      [110.7164, -7.8384],
      [110.7165, -7.838],
      [110.7163, -7.8376],
      [110.7161, -7.8373],
      [110.7161, -7.8368],
      [110.7161, -7.8365],
      [110.7158, -7.8362],
      [110.7158, -7.836],
      [110.7159, -7.8356],
      [110.716, -7.8352],
      [110.7161, -7.8348],
      [110.7167, -7.8348],
      [110.7166, -7.8342],
      [110.7167, -7.834],
      [110.7166, -7.8338],
      [110.7158, -7.8338],
      [110.7154, -7.834],
      [110.715, -7.8343],
      [110.7148, -7.8343],
      [110.7145, -7.8343],
      [110.7143, -7.8339],
      [110.7139, -7.8333],
      [110.7134, -7.8332],
      [110.7128, -7.8331],
      [110.7126, -7.8326],
      [110.7125, -7.8322],
      [110.7125, -7.8321],
      [110.7124, -7.8317],
      [110.7123, -7.8313],
      [110.7122, -7.8309],
      [110.7124, -7.8304],
      [110.7121, -7.83],
      [110.7118, -7.8296],
      [110.7115, -7.8292],
      [110.7116, -7.8288],
      [110.7117, -7.8282],
      [110.7116, -7.8277],
      [110.7114, -7.8271],
      [110.7113, -7.8263],
      [110.7115, -7.8256],
      [110.7116, -7.8249],
      [110.712, -7.8243],
      [110.7122, -7.824],
      [110.7123, -7.8236]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'CANDIREJO',
    border: [
      [110.7644, -7.8098],
      [110.7639, -7.8108],
      [110.7637, -7.8114],
      [110.7633, -7.8124],
      [110.7632, -7.8126],
      [110.7633, -7.8129],
      [110.7637, -7.8131],
      [110.7641, -7.8132],
      [110.7645, -7.8132],
      [110.7649, -7.8133],
      [110.7649, -7.8135],
      [110.7648, -7.8137],
      [110.7647, -7.8138],
      [110.7645, -7.8139],
      [110.7638, -7.8138],
      [110.7636, -7.814],
      [110.7634, -7.8144],
      [110.7632, -7.8147],
      [110.7631, -7.8147],
      [110.763, -7.8147],
      [110.7627, -7.8151],
      [110.7627, -7.8153],
      [110.7626, -7.8154],
      [110.7627, -7.8155],
      [110.7628, -7.8157],
      [110.7628, -7.8161],
      [110.7628, -7.8162],
      [110.7628, -7.8169],
      [110.7626, -7.8176],
      [110.7626, -7.8177],
      [110.7626, -7.818],
      [110.7627, -7.8182],
      [110.7628, -7.8184],
      [110.7632, -7.8187],
      [110.7637, -7.819],
      [110.7641, -7.8192],
      [110.764, -7.8196],
      [110.7639, -7.8205],
      [110.7639, -7.8209],
      [110.7637, -7.8217],
      [110.7637, -7.8222],
      [110.7636, -7.8224],
      [110.7636, -7.8225],
      [110.7635, -7.823],
      [110.7635, -7.8235],
      [110.7634, -7.824],
      [110.7629, -7.824],
      [110.7623, -7.824],
      [110.7619, -7.8241],
      [110.7614, -7.8239],
      [110.761, -7.8238],
      [110.7601, -7.8237],
      [110.7598, -7.8237],
      [110.7597, -7.8237],
      [110.7592, -7.8239],
      [110.7592, -7.8242],
      [110.7593, -7.8244],
      [110.7591, -7.8247],
      [110.7588, -7.8248],
      [110.7582, -7.825],
      [110.7579, -7.825],
      [110.7577, -7.8248],
      [110.7574, -7.8247],
      [110.7569, -7.8246],
      [110.7562, -7.8246],
      [110.7555, -7.8247],
      [110.7549, -7.8248],
      [110.7546, -7.8248],
      [110.7542, -7.8246],
      [110.754, -7.8245],
      [110.7536, -7.8245],
      [110.7532, -7.8246],
      [110.7529, -7.8244],
      [110.7526, -7.8242],
      [110.7522, -7.8239],
      [110.7521, -7.8238],
      [110.7519, -7.8234],
      [110.7517, -7.8229],
      [110.7517, -7.8226],
      [110.7517, -7.8222],
      [110.7518, -7.8216],
      [110.7518, -7.8208],
      [110.7519, -7.8205],
      [110.752, -7.8204],
      [110.7519, -7.8199],
      [110.7517, -7.8194],
      [110.7516, -7.8189],
      [110.7516, -7.8183],
      [110.7516, -7.8177],
      [110.7516, -7.8173],
      [110.7518, -7.8171],
      [110.752, -7.817],
      [110.7519, -7.8167],
      [110.7516, -7.8161],
      [110.7512, -7.8154],
      [110.7509, -7.8147],
      [110.7503, -7.814],
      [110.7494, -7.8131],
      [110.7488, -7.8124],
      [110.7472, -7.811],
      [110.7465, -7.8103],
      [110.7452, -7.809],
      [110.7437, -7.8076],
      [110.7426, -7.8066],
      [110.7424, -7.8065],
      [110.7428, -7.8071],
      [110.743, -7.8079],
      [110.743, -7.8082],
      [110.743, -7.8084],
      [110.743, -7.8087],
      [110.743, -7.8097],
      [110.743, -7.8099],
      [110.7429, -7.8105],
      [110.7427, -7.811],
      [110.7422, -7.8113],
      [110.7416, -7.8116],
      [110.7413, -7.812],
      [110.7414, -7.8124],
      [110.7417, -7.813],
      [110.7419, -7.8135],
      [110.7418, -7.8139],
      [110.7414, -7.8143],
      [110.741, -7.8146],
      [110.7404, -7.8149],
      [110.7399, -7.8151],
      [110.7395, -7.8154],
      [110.7392, -7.8158],
      [110.739, -7.8163],
      [110.7389, -7.8164],
      [110.7387, -7.8172],
      [110.7384, -7.8175],
      [110.738, -7.8178],
      [110.7377, -7.8179],
      [110.7371, -7.8179],
      [110.737, -7.8179],
      [110.7362, -7.818],
      [110.7355, -7.8182],
      [110.735, -7.8186],
      [110.7349, -7.8191],
      [110.735, -7.8198],
      [110.7357, -7.8207],
      [110.7361, -7.8214],
      [110.7363, -7.8218],
      [110.7365, -7.8228],
      [110.7368, -7.8245],
      [110.7374, -7.8248],
      [110.7383, -7.8256],
      [110.7385, -7.8263],
      [110.7382, -7.8268],
      [110.7389, -7.8273],
      [110.7395, -7.8276],
      [110.7398, -7.828],
      [110.7403, -7.8286],
      [110.7406, -7.8292],
      [110.741, -7.8297],
      [110.7416, -7.83],
      [110.7418, -7.8303],
      [110.7421, -7.8305],
      [110.7429, -7.8309],
      [110.7438, -7.8313],
      [110.7442, -7.8317],
      [110.7448, -7.832],
      [110.7457, -7.8322],
      [110.7456, -7.8327],
      [110.7455, -7.8336],
      [110.7455, -7.8338],
      [110.7454, -7.8343],
      [110.7455, -7.8348],
      [110.7458, -7.8353],
      [110.7464, -7.8357],
      [110.7471, -7.836],
      [110.7478, -7.8364],
      [110.7483, -7.8367],
      [110.7486, -7.8373],
      [110.749, -7.838],
      [110.7491, -7.8381],
      [110.7492, -7.8388],
      [110.7496, -7.8402],
      [110.7499, -7.841],
      [110.7502, -7.8414],
      [110.7503, -7.8415],
      [110.7511, -7.8424],
      [110.7516, -7.8434],
      [110.7519, -7.8443],
      [110.7521, -7.8449],
      [110.7526, -7.846],
      [110.7534, -7.8469],
      [110.7537, -7.8469],
      [110.7547, -7.8461],
      [110.755, -7.8455],
      [110.7558, -7.8441],
      [110.756, -7.8441],
      [110.7562, -7.844],
      [110.7567, -7.844],
      [110.7572, -7.8441],
      [110.7583, -7.8445],
      [110.7587, -7.845],
      [110.759, -7.845],
      [110.7594, -7.8448],
      [110.7599, -7.8449],
      [110.7602, -7.8455],
      [110.7606, -7.8461],
      [110.761, -7.8467],
      [110.7614, -7.8469],
      [110.7622, -7.8469],
      [110.7632, -7.8467],
      [110.7643, -7.8465],
      [110.7653, -7.846],
      [110.766, -7.8458],
      [110.7666, -7.8458],
      [110.7672, -7.846],
      [110.7682, -7.8463],
      [110.7687, -7.8464],
      [110.7694, -7.8461],
      [110.7704, -7.8454],
      [110.7711, -7.8448],
      [110.7726, -7.8438],
      [110.7728, -7.8436],
      [110.7738, -7.843],
      [110.774, -7.8428],
      [110.7746, -7.8421],
      [110.775, -7.8408],
      [110.7752, -7.8396],
      [110.7753, -7.8389],
      [110.7753, -7.8382],
      [110.7754, -7.8366],
      [110.7754, -7.8361],
      [110.775, -7.8355],
      [110.775, -7.8347],
      [110.7754, -7.8339],
      [110.7754, -7.8332],
      [110.7751, -7.8329],
      [110.7741, -7.8326],
      [110.7733, -7.8322],
      [110.7729, -7.8314],
      [110.773, -7.8304],
      [110.7733, -7.8299],
      [110.7737, -7.8297],
      [110.7742, -7.8297],
      [110.7746, -7.8296],
      [110.7755, -7.8291],
      [110.7758, -7.8287],
      [110.776, -7.8285],
      [110.776, -7.8282],
      [110.7755, -7.8278],
      [110.7753, -7.8274],
      [110.7756, -7.8266],
      [110.7759, -7.8263],
      [110.7762, -7.8261],
      [110.7765, -7.8259],
      [110.7768, -7.8258],
      [110.7771, -7.8256],
      [110.7779, -7.8249],
      [110.778, -7.8246],
      [110.7778, -7.824],
      [110.7779, -7.8238],
      [110.7784, -7.8235],
      [110.7788, -7.8234],
      [110.7789, -7.8231],
      [110.7794, -7.8225],
      [110.7798, -7.8222],
      [110.7805, -7.8223],
      [110.7805, -7.8219],
      [110.7809, -7.8216],
      [110.781, -7.8214],
      [110.7812, -7.821],
      [110.7817, -7.8206],
      [110.7827, -7.8203],
      [110.783, -7.8203],
      [110.7838, -7.8204],
      [110.7849, -7.8205],
      [110.7852, -7.8205],
      [110.7853, -7.8205],
      [110.7853, -7.8203],
      [110.7854, -7.8199],
      [110.7855, -7.8194],
      [110.7858, -7.8189],
      [110.7858, -7.8187],
      [110.7858, -7.8182],
      [110.7857, -7.818],
      [110.7857, -7.8174],
      [110.7857, -7.817],
      [110.7856, -7.8169],
      [110.7856, -7.8168],
      [110.7855, -7.8167],
      [110.7848, -7.8163],
      [110.7847, -7.8162],
      [110.7841, -7.8159],
      [110.7826, -7.8154],
      [110.782, -7.8152],
      [110.7814, -7.8149],
      [110.7811, -7.8146],
      [110.7808, -7.8144],
      [110.7805, -7.8141],
      [110.78, -7.8137],
      [110.7798, -7.8139],
      [110.7797, -7.8141],
      [110.7794, -7.8144],
      [110.7791, -7.8147],
      [110.7787, -7.8151],
      [110.7786, -7.8152],
      [110.7783, -7.8153],
      [110.778, -7.8151],
      [110.7774, -7.8144],
      [110.7771, -7.8141],
      [110.7769, -7.814],
      [110.7761, -7.8138],
      [110.7758, -7.8138],
      [110.7756, -7.8137],
      [110.7754, -7.8136],
      [110.7756, -7.8133],
      [110.7757, -7.8129],
      [110.7756, -7.8124],
      [110.7756, -7.8121],
      [110.7756, -7.8119],
      [110.7756, -7.8117],
      [110.7755, -7.8112],
      [110.7754, -7.8107],
      [110.775, -7.8107],
      [110.7749, -7.8106],
      [110.7744, -7.8105],
      [110.7742, -7.8105],
      [110.7742, -7.8101],
      [110.7742, -7.8097],
      [110.7738, -7.8092],
      [110.7731, -7.8087],
      [110.773, -7.8086],
      [110.7727, -7.8084],
      [110.7722, -7.8082],
      [110.7717, -7.8082],
      [110.7709, -7.808],
      [110.7703, -7.8079],
      [110.7693, -7.8082],
      [110.7691, -7.8083],
      [110.7682, -7.8085],
      [110.7674, -7.8087],
      [110.7664, -7.8091],
      [110.7659, -7.8092],
      [110.7653, -7.8094],
      [110.7644, -7.8097],
      [110.7644, -7.8098]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'REJOSARI',
    border: [
      [110.7853, -7.8205],
      [110.7852, -7.8205],
      [110.7849, -7.8205],
      [110.7838, -7.8204],
      [110.783, -7.8203],
      [110.7827, -7.8203],
      [110.7817, -7.8206],
      [110.7812, -7.821],
      [110.781, -7.8214],
      [110.7809, -7.8216],
      [110.7805, -7.8219],
      [110.7805, -7.8223],
      [110.7798, -7.8222],
      [110.7794, -7.8225],
      [110.7789, -7.8231],
      [110.7788, -7.8234],
      [110.7784, -7.8235],
      [110.7779, -7.8238],
      [110.7778, -7.824],
      [110.778, -7.8246],
      [110.7779, -7.8249],
      [110.7771, -7.8256],
      [110.7768, -7.8258],
      [110.7765, -7.8259],
      [110.7762, -7.8261],
      [110.7759, -7.8263],
      [110.7756, -7.8266],
      [110.7753, -7.8274],
      [110.7755, -7.8278],
      [110.776, -7.8282],
      [110.776, -7.8285],
      [110.7758, -7.8287],
      [110.7755, -7.8291],
      [110.7746, -7.8296],
      [110.7742, -7.8297],
      [110.7737, -7.8297],
      [110.7733, -7.8299],
      [110.773, -7.8304],
      [110.7729, -7.8314],
      [110.7733, -7.8322],
      [110.7741, -7.8326],
      [110.7751, -7.8329],
      [110.7754, -7.8332],
      [110.7754, -7.8339],
      [110.775, -7.8347],
      [110.775, -7.8355],
      [110.7754, -7.8361],
      [110.7754, -7.8366],
      [110.7753, -7.8382],
      [110.7753, -7.8389],
      [110.7752, -7.8396],
      [110.775, -7.8408],
      [110.7746, -7.8421],
      [110.774, -7.8428],
      [110.7738, -7.843],
      [110.7728, -7.8436],
      [110.7726, -7.8438],
      [110.7711, -7.8448],
      [110.7704, -7.8454],
      [110.7694, -7.8461],
      [110.7687, -7.8464],
      [110.7682, -7.8463],
      [110.7672, -7.846],
      [110.7666, -7.8458],
      [110.766, -7.8458],
      [110.7653, -7.846],
      [110.7643, -7.8465],
      [110.7632, -7.8467],
      [110.7622, -7.8469],
      [110.7614, -7.8469],
      [110.761, -7.8467],
      [110.7606, -7.8461],
      [110.7602, -7.8455],
      [110.7599, -7.8449],
      [110.7594, -7.8448],
      [110.759, -7.845],
      [110.7587, -7.845],
      [110.7583, -7.8445],
      [110.7572, -7.8441],
      [110.7567, -7.844],
      [110.7562, -7.844],
      [110.756, -7.8441],
      [110.7558, -7.8441],
      [110.755, -7.8455],
      [110.7547, -7.8461],
      [110.7537, -7.8469],
      [110.7534, -7.8469],
      [110.7526, -7.846],
      [110.7521, -7.8449],
      [110.7519, -7.8443],
      [110.7516, -7.8434],
      [110.7511, -7.8424],
      [110.7503, -7.8415],
      [110.7502, -7.8414],
      [110.75, -7.8421],
      [110.7499, -7.8427],
      [110.7497, -7.8434],
      [110.7496, -7.8439],
      [110.7492, -7.8449],
      [110.7484, -7.8455],
      [110.748, -7.8457],
      [110.7473, -7.8466],
      [110.7469, -7.8476],
      [110.7466, -7.8482],
      [110.7468, -7.8487],
      [110.7468, -7.8491],
      [110.7463, -7.8497],
      [110.7457, -7.85],
      [110.745, -7.8501],
      [110.7444, -7.8501],
      [110.7441, -7.8507],
      [110.7438, -7.8511],
      [110.7436, -7.8516],
      [110.7432, -7.8518],
      [110.7419, -7.8515],
      [110.7402, -7.8508],
      [110.7387, -7.8499],
      [110.7379, -7.8508],
      [110.7368, -7.8519],
      [110.7361, -7.8526],
      [110.7358, -7.8528],
      [110.736, -7.8529],
      [110.7369, -7.8529],
      [110.737, -7.8529],
      [110.7374, -7.8531],
      [110.7376, -7.8532],
      [110.7383, -7.8533],
      [110.739, -7.8533],
      [110.7393, -7.8533],
      [110.7394, -7.8534],
      [110.7396, -7.8535],
      [110.7398, -7.8538],
      [110.7396, -7.8546],
      [110.7395, -7.8551],
      [110.7395, -7.8556],
      [110.7395, -7.8562],
      [110.7397, -7.8567],
      [110.7401, -7.8573],
      [110.7411, -7.8577],
      [110.7416, -7.8579],
      [110.7427, -7.8579],
      [110.7436, -7.858],
      [110.7448, -7.8584],
      [110.7455, -7.8589],
      [110.7457, -7.8591],
      [110.7458, -7.8595],
      [110.7458, -7.8603],
      [110.7458, -7.8608],
      [110.7458, -7.8609],
      [110.746, -7.8615],
      [110.746, -7.8618],
      [110.746, -7.862],
      [110.7463, -7.862],
      [110.7467, -7.8623],
      [110.7469, -7.8626],
      [110.7471, -7.8627],
      [110.7476, -7.8629],
      [110.748, -7.8631],
      [110.7485, -7.8631],
      [110.7487, -7.863],
      [110.7491, -7.8629],
      [110.7494, -7.8627],
      [110.7496, -7.8626],
      [110.7502, -7.8625],
      [110.7504, -7.8625],
      [110.7506, -7.8625],
      [110.7514, -7.8627],
      [110.752, -7.8631],
      [110.7524, -7.8633],
      [110.7528, -7.8635],
      [110.7535, -7.8637],
      [110.7542, -7.8636],
      [110.7545, -7.8633],
      [110.7548, -7.8629],
      [110.755, -7.8629],
      [110.7554, -7.8633],
      [110.7558, -7.864],
      [110.7564, -7.8644],
      [110.7573, -7.8647],
      [110.7577, -7.8644],
      [110.7583, -7.8641],
      [110.7586, -7.8641],
      [110.7598, -7.8644],
      [110.76, -7.8648],
      [110.7599, -7.8657],
      [110.7598, -7.8663],
      [110.7601, -7.867],
      [110.7607, -7.8673],
      [110.7613, -7.8673],
      [110.7621, -7.8669],
      [110.7628, -7.8668],
      [110.764, -7.8667],
      [110.7648, -7.8664],
      [110.7654, -7.8657],
      [110.7661, -7.8652],
      [110.7666, -7.8649],
      [110.7673, -7.8649],
      [110.7678, -7.8646],
      [110.768, -7.8641],
      [110.768, -7.8632],
      [110.7681, -7.8628],
      [110.7689, -7.8621],
      [110.7697, -7.8614],
      [110.7702, -7.8611],
      [110.7707, -7.8603],
      [110.7709, -7.8599],
      [110.7715, -7.8594],
      [110.7723, -7.8591],
      [110.7731, -7.8589],
      [110.7735, -7.8591],
      [110.7738, -7.8597],
      [110.7741, -7.8608],
      [110.7747, -7.8621],
      [110.7752, -7.863],
      [110.7757, -7.8633],
      [110.7763, -7.8634],
      [110.7769, -7.8632],
      [110.777, -7.8627],
      [110.7768, -7.8621],
      [110.7762, -7.8613],
      [110.776, -7.8608],
      [110.7762, -7.8603],
      [110.7769, -7.8602],
      [110.7777, -7.8604],
      [110.7784, -7.8607],
      [110.7788, -7.8605],
      [110.7793, -7.8599],
      [110.7799, -7.859],
      [110.7806, -7.8585],
      [110.7815, -7.8582],
      [110.7828, -7.8578],
      [110.7834, -7.8579],
      [110.7841, -7.858],
      [110.7843, -7.8581],
      [110.7842, -7.8578],
      [110.7843, -7.8577],
      [110.7844, -7.8574],
      [110.7846, -7.8568],
      [110.7848, -7.8561],
      [110.7848, -7.8559],
      [110.7851, -7.855],
      [110.7853, -7.8532],
      [110.7854, -7.8528],
      [110.7848, -7.8521],
      [110.7845, -7.8518],
      [110.7844, -7.8517],
      [110.784, -7.8517],
      [110.7839, -7.8517],
      [110.7834, -7.8517],
      [110.7832, -7.8516],
      [110.7831, -7.8514],
      [110.7832, -7.8509],
      [110.7834, -7.8506],
      [110.7839, -7.8505],
      [110.7845, -7.8505],
      [110.7843, -7.8499],
      [110.7842, -7.8493],
      [110.7838, -7.8492],
      [110.7836, -7.849],
      [110.7837, -7.8488],
      [110.784, -7.8484],
      [110.7843, -7.848],
      [110.7845, -7.8476],
      [110.7847, -7.8473],
      [110.7843, -7.8471],
      [110.7839, -7.8469],
      [110.7838, -7.8468],
      [110.7837, -7.8465],
      [110.7835, -7.8464],
      [110.783, -7.8463],
      [110.7826, -7.8462],
      [110.7828, -7.8457],
      [110.7829, -7.8451],
      [110.7831, -7.8445],
      [110.7832, -7.844],
      [110.783, -7.843],
      [110.7829, -7.8423],
      [110.7828, -7.8419],
      [110.7829, -7.8411],
      [110.783, -7.8404],
      [110.783, -7.8401],
      [110.783, -7.8396],
      [110.783, -7.8389],
      [110.783, -7.8384],
      [110.7833, -7.8379],
      [110.7834, -7.8372],
      [110.7834, -7.8363],
      [110.7836, -7.8358],
      [110.7836, -7.8354],
      [110.7837, -7.8346],
      [110.7837, -7.8343],
      [110.7837, -7.8342],
      [110.7838, -7.834],
      [110.7838, -7.8334],
      [110.7838, -7.8328],
      [110.7836, -7.8324],
      [110.7833, -7.8315],
      [110.7831, -7.8309],
      [110.7831, -7.8303],
      [110.7831, -7.8299],
      [110.7833, -7.8296],
      [110.7837, -7.8294],
      [110.784, -7.8292],
      [110.7842, -7.8288],
      [110.7843, -7.8283],
      [110.7844, -7.828],
      [110.7847, -7.8273],
      [110.7849, -7.8266],
      [110.7849, -7.8258],
      [110.785, -7.8254],
      [110.7851, -7.825],
      [110.7851, -7.8247],
      [110.7851, -7.8242],
      [110.7853, -7.8233],
      [110.7854, -7.8225],
      [110.7855, -7.8223],
      [110.7855, -7.8221],
      [110.7856, -7.8214],
      [110.7856, -7.8212],
      [110.7854, -7.8209],
      [110.7853, -7.8205]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'KARANGSARI',
    border: [
      [110.7843, -7.8581],
      [110.7841, -7.858],
      [110.7834, -7.8579],
      [110.7828, -7.8578],
      [110.7815, -7.8582],
      [110.7806, -7.8585],
      [110.7799, -7.859],
      [110.7793, -7.8599],
      [110.7788, -7.8605],
      [110.7784, -7.8607],
      [110.7777, -7.8604],
      [110.7769, -7.8602],
      [110.7762, -7.8603],
      [110.776, -7.8608],
      [110.7762, -7.8613],
      [110.7768, -7.8621],
      [110.777, -7.8627],
      [110.7769, -7.8632],
      [110.7763, -7.8634],
      [110.7757, -7.8633],
      [110.7752, -7.863],
      [110.7747, -7.8621],
      [110.7741, -7.8608],
      [110.7738, -7.8597],
      [110.7735, -7.8591],
      [110.7731, -7.8589],
      [110.7723, -7.8591],
      [110.7715, -7.8594],
      [110.7709, -7.8599],
      [110.7707, -7.8603],
      [110.7702, -7.8611],
      [110.7697, -7.8614],
      [110.7689, -7.8621],
      [110.7681, -7.8628],
      [110.768, -7.8632],
      [110.768, -7.8641],
      [110.7678, -7.8646],
      [110.7673, -7.8649],
      [110.7666, -7.8649],
      [110.7661, -7.8652],
      [110.7654, -7.8657],
      [110.7648, -7.8664],
      [110.764, -7.8667],
      [110.7628, -7.8668],
      [110.7621, -7.8669],
      [110.7613, -7.8673],
      [110.7607, -7.8673],
      [110.7601, -7.867],
      [110.7598, -7.8663],
      [110.7599, -7.8657],
      [110.76, -7.8648],
      [110.7598, -7.8644],
      [110.7586, -7.8641],
      [110.7583, -7.8641],
      [110.7577, -7.8644],
      [110.7573, -7.8647],
      [110.7564, -7.8644],
      [110.7558, -7.864],
      [110.7554, -7.8633],
      [110.755, -7.8629],
      [110.7548, -7.8629],
      [110.7545, -7.8633],
      [110.7542, -7.8636],
      [110.7535, -7.8637],
      [110.7528, -7.8635],
      [110.7524, -7.8633],
      [110.752, -7.8631],
      [110.7514, -7.8627],
      [110.7506, -7.8625],
      [110.7504, -7.8625],
      [110.7502, -7.8625],
      [110.7496, -7.8626],
      [110.7494, -7.8627],
      [110.7494, -7.8628],
      [110.7493, -7.8635],
      [110.7492, -7.8649],
      [110.749, -7.866],
      [110.7494, -7.8662],
      [110.7493, -7.8666],
      [110.7495, -7.867],
      [110.7501, -7.8677],
      [110.7503, -7.8681],
      [110.7506, -7.8684],
      [110.7507, -7.869],
      [110.7509, -7.8695],
      [110.7511, -7.8698],
      [110.7519, -7.871],
      [110.752, -7.8715],
      [110.7521, -7.8737],
      [110.7521, -7.875],
      [110.7523, -7.8757],
      [110.7528, -7.8766],
      [110.7538, -7.8777],
      [110.7544, -7.8781],
      [110.7553, -7.8788],
      [110.7559, -7.8794],
      [110.7579, -7.8813],
      [110.7602, -7.8834],
      [110.7608, -7.8838],
      [110.7615, -7.8843],
      [110.7633, -7.8856],
      [110.7639, -7.8861],
      [110.7651, -7.8875],
      [110.766, -7.8884],
      [110.7665, -7.8889],
      [110.7681, -7.8905],
      [110.7694, -7.8922],
      [110.7702, -7.8931],
      [110.7714, -7.894],
      [110.7726, -7.8949],
      [110.7738, -7.8958],
      [110.774, -7.8959],
      [110.7741, -7.896],
      [110.7744, -7.8956],
      [110.7743, -7.8955],
      [110.7746, -7.8955],
      [110.7751, -7.8954],
      [110.7756, -7.8951],
      [110.7761, -7.8948],
      [110.7765, -7.8945],
      [110.7768, -7.8943],
      [110.777, -7.8941],
      [110.7771, -7.8938],
      [110.7771, -7.8934],
      [110.7771, -7.8931],
      [110.7774, -7.8928],
      [110.7774, -7.8926],
      [110.7776, -7.8923],
      [110.7778, -7.8919],
      [110.7779, -7.8914],
      [110.7778, -7.891],
      [110.7778, -7.8908],
      [110.7776, -7.8903],
      [110.7774, -7.8898],
      [110.7773, -7.8895],
      [110.7771, -7.8891],
      [110.777, -7.889],
      [110.7768, -7.8888],
      [110.7766, -7.8886],
      [110.7763, -7.8881],
      [110.7759, -7.8874],
      [110.776, -7.8874],
      [110.7757, -7.8872],
      [110.7756, -7.8868],
      [110.7757, -7.8864],
      [110.7759, -7.886],
      [110.776, -7.8857],
      [110.7759, -7.8854],
      [110.7757, -7.8853],
      [110.7762, -7.8843],
      [110.7764, -7.8839],
      [110.7764, -7.8837],
      [110.7765, -7.8836],
      [110.7767, -7.8828],
      [110.777, -7.8821],
      [110.7772, -7.8816],
      [110.7772, -7.8806],
      [110.7773, -7.8799],
      [110.7776, -7.879],
      [110.7778, -7.8786],
      [110.7779, -7.8785],
      [110.7781, -7.8779],
      [110.7784, -7.8774],
      [110.7785, -7.8771],
      [110.7788, -7.8769],
      [110.7791, -7.8766],
      [110.7793, -7.8765],
      [110.7799, -7.8761],
      [110.7805, -7.876],
      [110.7809, -7.8759],
      [110.7812, -7.8758],
      [110.7822, -7.8753],
      [110.7827, -7.8752],
      [110.7832, -7.8746],
      [110.7835, -7.8744],
      [110.7837, -7.8739],
      [110.7837, -7.8737],
      [110.7837, -7.8735],
      [110.7838, -7.873],
      [110.784, -7.8725],
      [110.7841, -7.8724],
      [110.7841, -7.8722],
      [110.7836, -7.872],
      [110.7834, -7.8718],
      [110.7832, -7.8716],
      [110.783, -7.8713],
      [110.7832, -7.871],
      [110.7832, -7.8709],
      [110.7835, -7.871],
      [110.7836, -7.871],
      [110.7838, -7.871],
      [110.7839, -7.8706],
      [110.7841, -7.8701],
      [110.7842, -7.8695],
      [110.7841, -7.8688],
      [110.7839, -7.8683],
      [110.7838, -7.868],
      [110.7836, -7.8677],
      [110.7832, -7.8675],
      [110.7831, -7.8674],
      [110.783, -7.8672],
      [110.783, -7.8669],
      [110.7833, -7.8665],
      [110.7836, -7.8664],
      [110.7837, -7.8664],
      [110.784, -7.8663],
      [110.7842, -7.8663],
      [110.7839, -7.8661],
      [110.7837, -7.8658],
      [110.7837, -7.8655],
      [110.7834, -7.8653],
      [110.783, -7.8651],
      [110.7829, -7.865],
      [110.7829, -7.8649],
      [110.783, -7.8645],
      [110.7831, -7.8641],
      [110.783, -7.8638],
      [110.7827, -7.8633],
      [110.7827, -7.863],
      [110.7827, -7.8626],
      [110.7829, -7.8623],
      [110.7831, -7.8619],
      [110.7833, -7.8617],
      [110.7836, -7.8615],
      [110.7837, -7.8614],
      [110.7839, -7.8614],
      [110.7843, -7.861],
      [110.7844, -7.8604],
      [110.7845, -7.8596],
      [110.7845, -7.8589],
      [110.7844, -7.8582],
      [110.7843, -7.8581]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'PUNDUNGSARI',
    border: [
      [110.746, -7.862],
      [110.7457, -7.8619],
      [110.7451, -7.8624],
      [110.7443, -7.8631],
      [110.7437, -7.8634],
      [110.7436, -7.8636],
      [110.7433, -7.8633],
      [110.7433, -7.8629],
      [110.7434, -7.8622],
      [110.7434, -7.8617],
      [110.7429, -7.8614],
      [110.7423, -7.8612],
      [110.7417, -7.8618],
      [110.7408, -7.8624],
      [110.7402, -7.8627],
      [110.7398, -7.8627],
      [110.7397, -7.8626],
      [110.7397, -7.8627],
      [110.7396, -7.8637],
      [110.7396, -7.8647],
      [110.7396, -7.8655],
      [110.7399, -7.8661],
      [110.7404, -7.8668],
      [110.7407, -7.8676],
      [110.7406, -7.8686],
      [110.7407, -7.8707],
      [110.7405, -7.8721],
      [110.7403, -7.8738],
      [110.74, -7.8751],
      [110.7403, -7.8758],
      [110.7406, -7.8763],
      [110.741, -7.8768],
      [110.7413, -7.8772],
      [110.7418, -7.8776],
      [110.742, -7.8779],
      [110.7423, -7.8785],
      [110.7427, -7.879],
      [110.7429, -7.8791],
      [110.7433, -7.8791],
      [110.7436, -7.8796],
      [110.7436, -7.8797],
      [110.7441, -7.8804],
      [110.7445, -7.8806],
      [110.7448, -7.8806],
      [110.745, -7.8807],
      [110.7454, -7.8807],
      [110.7458, -7.8805],
      [110.7461, -7.8805],
      [110.7466, -7.8807],
      [110.7472, -7.8806],
      [110.7478, -7.8806],
      [110.7484, -7.8806],
      [110.7488, -7.881],
      [110.7491, -7.8808],
      [110.7492, -7.8808],
      [110.7497, -7.881],
      [110.7499, -7.8812],
      [110.7502, -7.8816],
      [110.7502, -7.8814],
      [110.7504, -7.8812],
      [110.7505, -7.8809],
      [110.7507, -7.8809],
      [110.7511, -7.8809],
      [110.7514, -7.881],
      [110.7517, -7.8813],
      [110.7525, -7.8819],
      [110.7534, -7.8823],
      [110.7545, -7.8824],
      [110.7547, -7.8825],
      [110.7552, -7.8828],
      [110.756, -7.8833],
      [110.7561, -7.8835],
      [110.7566, -7.8845],
      [110.7567, -7.8856],
      [110.7564, -7.8863],
      [110.756, -7.887],
      [110.7553, -7.8874],
      [110.7549, -7.8877],
      [110.7537, -7.8886],
      [110.7527, -7.8898],
      [110.7525, -7.8904],
      [110.7523, -7.8914],
      [110.752, -7.8933],
      [110.7516, -7.8939],
      [110.7511, -7.8945],
      [110.7507, -7.8951],
      [110.7506, -7.8953],
      [110.751, -7.8961],
      [110.7516, -7.8972],
      [110.7525, -7.8982],
      [110.7527, -7.8987],
      [110.7527, -7.8994],
      [110.7523, -7.9004],
      [110.752, -7.9011],
      [110.7515, -7.9018],
      [110.7514, -7.9019],
      [110.7508, -7.9025],
      [110.7502, -7.9029],
      [110.7501, -7.9035],
      [110.7501, -7.9038],
      [110.7506, -7.9043],
      [110.7511, -7.9045],
      [110.7513, -7.9048],
      [110.7519, -7.9045],
      [110.7525, -7.9041],
      [110.753, -7.9039],
      [110.7541, -7.9037],
      [110.7549, -7.9034],
      [110.7554, -7.9031],
      [110.7557, -7.9031],
      [110.7566, -7.9034],
      [110.7571, -7.9035],
      [110.7576, -7.9035],
      [110.7582, -7.9038],
      [110.7586, -7.9041],
      [110.7588, -7.9043],
      [110.759, -7.9045],
      [110.7592, -7.9047],
      [110.7594, -7.9049],
      [110.7593, -7.9052],
      [110.7592, -7.9055],
      [110.7592, -7.9058],
      [110.7593, -7.906],
      [110.7598, -7.9062],
      [110.7601, -7.9063],
      [110.7602, -7.9066],
      [110.7604, -7.9068],
      [110.7607, -7.9068],
      [110.761, -7.9067],
      [110.7614, -7.907],
      [110.7618, -7.9073],
      [110.7621, -7.9075],
      [110.7624, -7.9078],
      [110.7629, -7.908],
      [110.7634, -7.9082],
      [110.7638, -7.9084],
      [110.7644, -7.9086],
      [110.765, -7.9089],
      [110.7656, -7.909],
      [110.766, -7.9091],
      [110.7661, -7.9091],
      [110.7667, -7.909],
      [110.7675, -7.909],
      [110.7683, -7.9089],
      [110.7695, -7.9087],
      [110.7699, -7.9086],
      [110.7703, -7.9086],
      [110.7703, -7.906],
      [110.7706, -7.9049],
      [110.7709, -7.903],
      [110.7711, -7.902],
      [110.7716, -7.901],
      [110.7716, -7.9009],
      [110.7721, -7.9001],
      [110.7722, -7.9],
      [110.7722, -7.8999],
      [110.7727, -7.8993],
      [110.7731, -7.8987],
      [110.7734, -7.898],
      [110.7739, -7.8973],
      [110.774, -7.8967],
      [110.774, -7.8962],
      [110.7741, -7.896],
      [110.774, -7.8959],
      [110.7738, -7.8958],
      [110.7726, -7.8949],
      [110.7714, -7.894],
      [110.7702, -7.8931],
      [110.7694, -7.8922],
      [110.7681, -7.8905],
      [110.7665, -7.8889],
      [110.766, -7.8884],
      [110.7651, -7.8875],
      [110.7639, -7.8861],
      [110.7633, -7.8856],
      [110.7615, -7.8843],
      [110.7608, -7.8838],
      [110.7602, -7.8834],
      [110.7579, -7.8813],
      [110.7559, -7.8794],
      [110.7553, -7.8788],
      [110.7544, -7.8781],
      [110.7538, -7.8777],
      [110.7528, -7.8766],
      [110.7523, -7.8757],
      [110.7521, -7.875],
      [110.7521, -7.8737],
      [110.752, -7.8715],
      [110.7519, -7.871],
      [110.7511, -7.8698],
      [110.7509, -7.8695],
      [110.7507, -7.869],
      [110.7506, -7.8684],
      [110.7503, -7.8681],
      [110.7501, -7.8677],
      [110.7495, -7.867],
      [110.7493, -7.8666],
      [110.7494, -7.8662],
      [110.749, -7.866],
      [110.7492, -7.8649],
      [110.7493, -7.8635],
      [110.7494, -7.8628],
      [110.7494, -7.8627],
      [110.7491, -7.8629],
      [110.7487, -7.863],
      [110.7485, -7.8631],
      [110.748, -7.8631],
      [110.7476, -7.8629],
      [110.7471, -7.8627],
      [110.7469, -7.8626],
      [110.7467, -7.8623],
      [110.7463, -7.862],
      [110.746, -7.862]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SEMIN',
    village: 'SEMIN',
    border: [
      [110.7397, -7.8626],
      [110.7393, -7.8622],
      [110.7391, -7.8618],
      [110.7384, -7.862],
      [110.7378, -7.8622],
      [110.7374, -7.8623],
      [110.7371, -7.8623],
      [110.7363, -7.8625],
      [110.7355, -7.8624],
      [110.735, -7.8624],
      [110.7345, -7.8623],
      [110.734, -7.8622],
      [110.7339, -7.8622],
      [110.7336, -7.8622],
      [110.7333, -7.8626],
      [110.7331, -7.8631],
      [110.7332, -7.8635],
      [110.7335, -7.8637],
      [110.7336, -7.8642],
      [110.7336, -7.8646],
      [110.7332, -7.865],
      [110.7325, -7.8653],
      [110.7323, -7.8657],
      [110.7319, -7.8665],
      [110.7318, -7.867],
      [110.7317, -7.8674],
      [110.7316, -7.8679],
      [110.7314, -7.8683],
      [110.731, -7.8685],
      [110.7307, -7.8687],
      [110.7306, -7.869],
      [110.7305, -7.8692],
      [110.7299, -7.8696],
      [110.7288, -7.8697],
      [110.7287, -7.8698],
      [110.7284, -7.8701],
      [110.7282, -7.8705],
      [110.7279, -7.8709],
      [110.7276, -7.8713],
      [110.7271, -7.8715],
      [110.727, -7.8715],
      [110.7264, -7.8713],
      [110.726, -7.8712],
      [110.7259, -7.8712],
      [110.7256, -7.8713],
      [110.7257, -7.8718],
      [110.7261, -7.8722],
      [110.7262, -7.8724],
      [110.7262, -7.8729],
      [110.726, -7.8731],
      [110.7253, -7.8727],
      [110.7249, -7.8725],
      [110.7247, -7.8725],
      [110.7242, -7.8725],
      [110.7241, -7.8732],
      [110.7239, -7.874],
      [110.7238, -7.8745],
      [110.7235, -7.875],
      [110.7233, -7.8757],
      [110.7232, -7.8762],
      [110.7229, -7.8763],
      [110.7224, -7.8764],
      [110.7222, -7.8765],
      [110.7212, -7.8768],
      [110.721, -7.8769],
      [110.7206, -7.8772],
      [110.7206, -7.8776],
      [110.7209, -7.8783],
      [110.721, -7.8787],
      [110.7204, -7.8793],
      [110.7202, -7.8795],
      [110.7194, -7.8797],
      [110.7187, -7.8795],
      [110.7177, -7.8791],
      [110.7172, -7.879],
      [110.7171, -7.8791],
      [110.7171, -7.8794],
      [110.7172, -7.8796],
      [110.7169, -7.8805],
      [110.7167, -7.8813],
      [110.7166, -7.8819],
      [110.7168, -7.8821],
      [110.717, -7.8822],
      [110.7169, -7.8828],
      [110.7167, -7.8842],
      [110.7169, -7.8843],
      [110.7171, -7.8846],
      [110.7173, -7.8856],
      [110.717, -7.8857],
      [110.7169, -7.8862],
      [110.7166, -7.8864],
      [110.716, -7.8864],
      [110.7158, -7.8866],
      [110.7158, -7.8869],
      [110.7161, -7.887],
      [110.7162, -7.8883],
      [110.7163, -7.8885],
      [110.7164, -7.889],
      [110.7168, -7.8894],
      [110.7171, -7.8896],
      [110.7171, -7.89],
      [110.7169, -7.8905],
      [110.7166, -7.891],
      [110.7163, -7.8915],
      [110.7162, -7.8921],
      [110.7162, -7.8927],
      [110.7162, -7.8929],
      [110.716, -7.8934],
      [110.7159, -7.894],
      [110.7158, -7.8947],
      [110.7153, -7.895],
      [110.7151, -7.8952],
      [110.715, -7.8953],
      [110.7154, -7.8966],
      [110.7155, -7.8971],
      [110.7156, -7.8974],
      [110.7159, -7.8976],
      [110.7162, -7.8984],
      [110.7164, -7.8991],
      [110.7164, -7.8995],
      [110.7162, -7.8998],
      [110.7161, -7.9002],
      [110.7161, -7.9006],
      [110.7162, -7.901],
      [110.7165, -7.9013],
      [110.7168, -7.9016],
      [110.718, -7.9016],
      [110.7189, -7.9016],
      [110.7191, -7.9017],
      [110.7195, -7.9016],
      [110.7197, -7.9017],
      [110.7199, -7.9019],
      [110.7197, -7.9024],
      [110.7197, -7.9028],
      [110.7197, -7.9034],
      [110.7199, -7.9039],
      [110.72, -7.9043],
      [110.7204, -7.9046],
      [110.7207, -7.9046],
      [110.7208, -7.9045],
      [110.7209, -7.9045],
      [110.7214, -7.9045],
      [110.7221, -7.9045],
      [110.7226, -7.9045],
      [110.7232, -7.9042],
      [110.7233, -7.9045],
      [110.7239, -7.9046],
      [110.7243, -7.9046],
      [110.725, -7.9047],
      [110.7254, -7.9047],
      [110.7256, -7.9045],
      [110.7259, -7.9043],
      [110.7261, -7.9044],
      [110.7263, -7.9044],
      [110.7265, -7.9047],
      [110.7267, -7.9049],
      [110.7267, -7.9053],
      [110.7267, -7.9055],
      [110.7272, -7.9053],
      [110.7276, -7.9054],
      [110.7279, -7.9054],
      [110.7281, -7.9059],
      [110.7282, -7.9064],
      [110.7284, -7.9068],
      [110.7288, -7.907],
      [110.7296, -7.9072],
      [110.73, -7.907],
      [110.7302, -7.9067],
      [110.7307, -7.9065],
      [110.731, -7.9063],
      [110.7312, -7.9062],
      [110.7312, -7.9065],
      [110.7314, -7.9065],
      [110.7318, -7.9063],
      [110.7322, -7.9062],
      [110.7322, -7.9061],
      [110.7322, -7.9059],
      [110.7326, -7.906],
      [110.7328, -7.906],
      [110.733, -7.9062],
      [110.7332, -7.906],
      [110.7333, -7.9056],
      [110.733, -7.9055],
      [110.7328, -7.9055],
      [110.7328, -7.9053],
      [110.733, -7.9051],
      [110.7333, -7.905],
      [110.7337, -7.9049],
      [110.7343, -7.9046],
      [110.7347, -7.9042],
      [110.735, -7.9039],
      [110.7352, -7.9034],
      [110.7356, -7.9032],
      [110.7361, -7.903],
      [110.7366, -7.903],
      [110.7371, -7.903],
      [110.7376, -7.9029],
      [110.738, -7.9029],
      [110.7384, -7.9028],
      [110.7385, -7.9027],
      [110.7388, -7.9027],
      [110.739, -7.903],
      [110.739, -7.9032],
      [110.739, -7.9035],
      [110.7391, -7.9036],
      [110.7393, -7.9038],
      [110.7392, -7.9041],
      [110.7391, -7.9043],
      [110.7391, -7.9046],
      [110.7393, -7.9047],
      [110.7396, -7.9048],
      [110.7399, -7.905],
      [110.7401, -7.9052],
      [110.7402, -7.9058],
      [110.7404, -7.9062],
      [110.7406, -7.9068],
      [110.7407, -7.9071],
      [110.7415, -7.9071],
      [110.7421, -7.907],
      [110.7426, -7.9071],
      [110.7428, -7.9075],
      [110.7433, -7.9076],
      [110.7436, -7.9075],
      [110.7442, -7.9075],
      [110.7446, -7.9076],
      [110.745, -7.9078],
      [110.7451, -7.9077],
      [110.7454, -7.9076],
      [110.7458, -7.9075],
      [110.7461, -7.9075],
      [110.7467, -7.9077],
      [110.7471, -7.9079],
      [110.7476, -7.908],
      [110.7481, -7.9079],
      [110.7485, -7.9075],
      [110.7488, -7.9072],
      [110.7491, -7.9069],
      [110.7498, -7.9069],
      [110.7498, -7.9064],
      [110.7498, -7.9061],
      [110.75, -7.9059],
      [110.7504, -7.9057],
      [110.7507, -7.9054],
      [110.751, -7.905],
      [110.7512, -7.9049],
      [110.7513, -7.9048],
      [110.7511, -7.9045],
      [110.7506, -7.9043],
      [110.7501, -7.9038],
      [110.7501, -7.9035],
      [110.7502, -7.9029],
      [110.7508, -7.9025],
      [110.7514, -7.9019],
      [110.7515, -7.9018],
      [110.752, -7.9011],
      [110.7523, -7.9004],
      [110.7527, -7.8994],
      [110.7527, -7.8987],
      [110.7525, -7.8982],
      [110.7516, -7.8972],
      [110.751, -7.8961],
      [110.7506, -7.8953],
      [110.7507, -7.8951],
      [110.7511, -7.8945],
      [110.7516, -7.8939],
      [110.752, -7.8933],
      [110.7523, -7.8914],
      [110.7525, -7.8904],
      [110.7527, -7.8898],
      [110.7537, -7.8886],
      [110.7549, -7.8877],
      [110.7553, -7.8874],
      [110.756, -7.887],
      [110.7564, -7.8863],
      [110.7567, -7.8856],
      [110.7566, -7.8845],
      [110.7561, -7.8835],
      [110.756, -7.8833],
      [110.7552, -7.8828],
      [110.7547, -7.8825],
      [110.7545, -7.8824],
      [110.7534, -7.8823],
      [110.7525, -7.8819],
      [110.7517, -7.8813],
      [110.7514, -7.881],
      [110.7511, -7.8809],
      [110.7507, -7.8809],
      [110.7505, -7.8809],
      [110.7504, -7.8812],
      [110.7502, -7.8814],
      [110.7502, -7.8816],
      [110.7499, -7.8812],
      [110.7497, -7.881],
      [110.7492, -7.8808],
      [110.7491, -7.8808],
      [110.7488, -7.881],
      [110.7484, -7.8806],
      [110.7478, -7.8806],
      [110.7472, -7.8806],
      [110.7466, -7.8807],
      [110.7461, -7.8805],
      [110.7458, -7.8805],
      [110.7454, -7.8807],
      [110.745, -7.8807],
      [110.7448, -7.8806],
      [110.7445, -7.8806],
      [110.7441, -7.8804],
      [110.7436, -7.8797],
      [110.7436, -7.8796],
      [110.7433, -7.8791],
      [110.7429, -7.8791],
      [110.7427, -7.879],
      [110.7423, -7.8785],
      [110.742, -7.8779],
      [110.7418, -7.8776],
      [110.7413, -7.8772],
      [110.741, -7.8768],
      [110.7406, -7.8763],
      [110.7403, -7.8758],
      [110.74, -7.8751],
      [110.7403, -7.8738],
      [110.7405, -7.8721],
      [110.7407, -7.8707],
      [110.7406, -7.8686],
      [110.7407, -7.8676],
      [110.7404, -7.8668],
      [110.7399, -7.8661],
      [110.7396, -7.8655],
      [110.7396, -7.8647],
      [110.7396, -7.8637],
      [110.7397, -7.8627],
      [110.7397, -7.8626]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGAWEN',
    village: 'TANCEP',
    border: [
      [110.6752, -7.7928],
      [110.6754, -7.7925],
      [110.6757, -7.792],
      [110.6762, -7.7918],
      [110.6766, -7.7913],
      [110.6764, -7.7904],
      [110.6762, -7.7904],
      [110.6761, -7.7903],
      [110.6758, -7.7902],
      [110.6758, -7.7898],
      [110.6754, -7.7891],
      [110.6751, -7.7889],
      [110.6747, -7.7883],
      [110.6743, -7.7878],
      [110.6742, -7.7876],
      [110.6736, -7.7872],
      [110.6729, -7.7866],
      [110.6728, -7.7865],
      [110.6726, -7.7865],
      [110.6723, -7.7864],
      [110.6721, -7.7863],
      [110.6706, -7.7859],
      [110.6699, -7.7865],
      [110.6696, -7.7867],
      [110.6696, -7.7871],
      [110.6695, -7.7873],
      [110.6694, -7.7879],
      [110.6691, -7.7888],
      [110.6682, -7.7901],
      [110.6681, -7.7903],
      [110.6675, -7.7917],
      [110.6672, -7.7919],
      [110.6669, -7.7923],
      [110.6667, -7.7929],
      [110.6659, -7.7925],
      [110.6658, -7.7924],
      [110.6655, -7.7923],
      [110.6651, -7.7924],
      [110.6644, -7.7926],
      [110.664, -7.7931],
      [110.6639, -7.7933],
      [110.6636, -7.7938],
      [110.6636, -7.7948],
      [110.6636, -7.7951],
      [110.6634, -7.7955],
      [110.6627, -7.796],
      [110.6622, -7.7964],
      [110.662, -7.7969],
      [110.6619, -7.7976],
      [110.6614, -7.7994],
      [110.661, -7.7999],
      [110.6608, -7.8003],
      [110.6604, -7.8007],
      [110.6605, -7.8012],
      [110.6604, -7.8015],
      [110.6605, -7.8018],
      [110.6605, -7.8021],
      [110.6603, -7.8023],
      [110.6595, -7.8034],
      [110.6588, -7.8041],
      [110.6583, -7.8044],
      [110.6577, -7.8048],
      [110.6577, -7.805],
      [110.658, -7.8056],
      [110.6581, -7.8057],
      [110.6588, -7.8071],
      [110.6589, -7.8079],
      [110.6588, -7.8084],
      [110.6585, -7.8091],
      [110.6583, -7.8097],
      [110.6578, -7.8107],
      [110.6576, -7.8113],
      [110.6568, -7.8129],
      [110.6557, -7.8149],
      [110.6546, -7.817],
      [110.6538, -7.8187],
      [110.6535, -7.8195],
      [110.6538, -7.8193],
      [110.6567, -7.8179],
      [110.657, -7.8177],
      [110.6577, -7.8174],
      [110.66, -7.8166],
      [110.661, -7.8162],
      [110.6625, -7.816],
      [110.6631, -7.8155],
      [110.6645, -7.814],
      [110.6656, -7.8124],
      [110.6664, -7.8114],
      [110.667, -7.8107],
      [110.6682, -7.8098],
      [110.669, -7.8094],
      [110.6697, -7.8093],
      [110.6721, -7.8097],
      [110.6731, -7.8099],
      [110.6745, -7.8101],
      [110.6768, -7.8103],
      [110.6779, -7.8104],
      [110.6793, -7.8105],
      [110.6804, -7.8106],
      [110.6821, -7.8107],
      [110.683, -7.8107],
      [110.6838, -7.8109],
      [110.6849, -7.811],
      [110.6862, -7.8113],
      [110.6874, -7.8116],
      [110.6884, -7.8119],
      [110.6891, -7.8121],
      [110.6909, -7.813],
      [110.6926, -7.814],
      [110.6943, -7.815],
      [110.6953, -7.8159],
      [110.6962, -7.8166],
      [110.6968, -7.8169],
      [110.6978, -7.8171],
      [110.6995, -7.8175],
      [110.7004, -7.8175],
      [110.7008, -7.8176],
      [110.7017, -7.8174],
      [110.703, -7.817],
      [110.7033, -7.8167],
      [110.7036, -7.8164],
      [110.7039, -7.8161],
      [110.7043, -7.8158],
      [110.7046, -7.8159],
      [110.7049, -7.8159],
      [110.7052, -7.8165],
      [110.7057, -7.8165],
      [110.7061, -7.8165],
      [110.7064, -7.8165],
      [110.7068, -7.8167],
      [110.7072, -7.8169],
      [110.7078, -7.8167],
      [110.7083, -7.8167],
      [110.709, -7.8167],
      [110.709, -7.8163],
      [110.7093, -7.816],
      [110.7094, -7.8159],
      [110.7102, -7.8155],
      [110.7107, -7.8151],
      [110.7111, -7.8149],
      [110.7121, -7.8147],
      [110.7124, -7.8145],
      [110.7126, -7.8137],
      [110.7125, -7.8129],
      [110.7121, -7.8123],
      [110.7116, -7.8115],
      [110.7111, -7.8104],
      [110.7109, -7.8095],
      [110.7109, -7.8087],
      [110.711, -7.8081],
      [110.7118, -7.8073],
      [110.7123, -7.8068],
      [110.7127, -7.8064],
      [110.713, -7.8061],
      [110.7133, -7.8057],
      [110.7132, -7.8049],
      [110.713, -7.804],
      [110.7129, -7.8033],
      [110.7126, -7.8023],
      [110.7124, -7.8018],
      [110.7123, -7.8019],
      [110.7121, -7.8023],
      [110.712, -7.8024],
      [110.7105, -7.8036],
      [110.7105, -7.8037],
      [110.7102, -7.804],
      [110.7098, -7.8045],
      [110.7095, -7.8048],
      [110.7089, -7.8052],
      [110.7087, -7.8053],
      [110.7075, -7.8056],
      [110.707, -7.8056],
      [110.7065, -7.8057],
      [110.7063, -7.8057],
      [110.7061, -7.8057],
      [110.7059, -7.8057],
      [110.7054, -7.8057],
      [110.7049, -7.8057],
      [110.7045, -7.8057],
      [110.704, -7.8056],
      [110.7037, -7.8056],
      [110.7033, -7.8056],
      [110.7032, -7.8056],
      [110.7027, -7.8057],
      [110.7017, -7.8062],
      [110.7012, -7.8065],
      [110.7012, -7.8066],
      [110.7011, -7.8066],
      [110.7002, -7.8072],
      [110.6995, -7.8075],
      [110.6993, -7.8076],
      [110.6992, -7.8076],
      [110.6991, -7.8076],
      [110.6988, -7.8077],
      [110.6965, -7.8075],
      [110.6955, -7.8076],
      [110.6944, -7.8076],
      [110.6943, -7.8076],
      [110.6941, -7.8076],
      [110.6941, -7.8077],
      [110.694, -7.8078],
      [110.6935, -7.8078],
      [110.6927, -7.8079],
      [110.6926, -7.8082],
      [110.6925, -7.8082],
      [110.6923, -7.8083],
      [110.692, -7.8083],
      [110.6919, -7.8083],
      [110.6914, -7.8083],
      [110.6909, -7.8081],
      [110.6909, -7.8078],
      [110.6908, -7.8075],
      [110.6905, -7.8075],
      [110.6904, -7.8075],
      [110.6899, -7.8074],
      [110.6897, -7.8074],
      [110.6887, -7.8073],
      [110.6882, -7.8073],
      [110.6881, -7.8073],
      [110.6875, -7.8072],
      [110.6872, -7.8072],
      [110.6864, -7.8069],
      [110.6857, -7.8067],
      [110.6854, -7.8066],
      [110.6848, -7.8064],
      [110.6844, -7.8064],
      [110.6841, -7.8065],
      [110.6837, -7.8066],
      [110.6831, -7.8067],
      [110.6825, -7.8066],
      [110.6818, -7.8065],
      [110.6817, -7.8064],
      [110.6815, -7.8064],
      [110.6814, -7.8063],
      [110.6813, -7.8062],
      [110.681, -7.8059],
      [110.6805, -7.8052],
      [110.6794, -7.8044],
      [110.6792, -7.8043],
      [110.6781, -7.8023],
      [110.6777, -7.8016],
      [110.6764, -7.8008],
      [110.6734, -7.8],
      [110.673, -7.7994],
      [110.6728, -7.7992],
      [110.6728, -7.7991],
      [110.6728, -7.799],
      [110.6728, -7.7989],
      [110.6728, -7.7987],
      [110.6728, -7.7986],
      [110.6723, -7.7976],
      [110.6721, -7.7974],
      [110.6721, -7.7973],
      [110.6721, -7.797],
      [110.6723, -7.7962],
      [110.6723, -7.796],
      [110.6731, -7.7952],
      [110.6737, -7.7948],
      [110.6743, -7.7944],
      [110.6745, -7.7937],
      [110.6746, -7.7936],
      [110.6748, -7.7932],
      [110.6751, -7.793],
      [110.6752, -7.7928]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGAWEN',
    village: 'SAMBIREJO',
    border: [
      [110.7131, -7.79],
      [110.7124, -7.79],
      [110.7123, -7.7901],
      [110.7122, -7.7901],
      [110.7118, -7.7904],
      [110.7118, -7.7905],
      [110.7118, -7.7908],
      [110.7118, -7.7914],
      [110.7119, -7.7917],
      [110.7121, -7.7922],
      [110.7122, -7.7925],
      [110.7125, -7.7931],
      [110.7126, -7.7936],
      [110.7127, -7.794],
      [110.7127, -7.7946],
      [110.7128, -7.7949],
      [110.7131, -7.7955],
      [110.7137, -7.7966],
      [110.714, -7.7972],
      [110.714, -7.7975],
      [110.714, -7.7979],
      [110.714, -7.798],
      [110.7142, -7.7984],
      [110.7143, -7.7991],
      [110.7139, -7.7999],
      [110.7137, -7.8001],
      [110.7131, -7.801],
      [110.7126, -7.8016],
      [110.7124, -7.8018],
      [110.7126, -7.8023],
      [110.7129, -7.8033],
      [110.713, -7.804],
      [110.7132, -7.8049],
      [110.7133, -7.8057],
      [110.713, -7.8061],
      [110.7127, -7.8064],
      [110.7123, -7.8068],
      [110.7118, -7.8073],
      [110.711, -7.8081],
      [110.7109, -7.8087],
      [110.7109, -7.8095],
      [110.7111, -7.8104],
      [110.7116, -7.8115],
      [110.7121, -7.8123],
      [110.7125, -7.8129],
      [110.7126, -7.8137],
      [110.7124, -7.8145],
      [110.7121, -7.8147],
      [110.7111, -7.8149],
      [110.7107, -7.8151],
      [110.7102, -7.8155],
      [110.7094, -7.8159],
      [110.7093, -7.816],
      [110.709, -7.8163],
      [110.709, -7.8167],
      [110.7091, -7.8171],
      [110.7091, -7.8176],
      [110.7091, -7.8182],
      [110.709, -7.8195],
      [110.709, -7.8218],
      [110.7108, -7.8219],
      [110.7113, -7.8223],
      [110.7118, -7.8228],
      [110.7123, -7.8236],
      [110.714, -7.8243],
      [110.7146, -7.8245],
      [110.7152, -7.8248],
      [110.7169, -7.8245],
      [110.7175, -7.824],
      [110.7188, -7.8229],
      [110.7199, -7.822],
      [110.7216, -7.8216],
      [110.7225, -7.8218],
      [110.7238, -7.8219],
      [110.7259, -7.8225],
      [110.7288, -7.8232],
      [110.7301, -7.8236],
      [110.7312, -7.824],
      [110.7317, -7.8244],
      [110.732, -7.8248],
      [110.732, -7.8254],
      [110.7317, -7.8274],
      [110.7311, -7.8292],
      [110.7308, -7.83],
      [110.7309, -7.8308],
      [110.7312, -7.8315],
      [110.7324, -7.832],
      [110.7333, -7.8322],
      [110.735, -7.832],
      [110.7356, -7.8317],
      [110.736, -7.8309],
      [110.7363, -7.8293],
      [110.7364, -7.8284],
      [110.7369, -7.8276],
      [110.7374, -7.8272],
      [110.7377, -7.827],
      [110.7382, -7.8268],
      [110.7385, -7.8263],
      [110.7383, -7.8256],
      [110.7374, -7.8248],
      [110.7368, -7.8245],
      [110.7365, -7.8228],
      [110.7363, -7.8218],
      [110.7361, -7.8214],
      [110.7357, -7.8207],
      [110.735, -7.8198],
      [110.7349, -7.8191],
      [110.735, -7.8186],
      [110.7355, -7.8182],
      [110.7362, -7.818],
      [110.737, -7.8179],
      [110.7371, -7.8179],
      [110.7377, -7.8179],
      [110.738, -7.8178],
      [110.7384, -7.8175],
      [110.7387, -7.8172],
      [110.7389, -7.8164],
      [110.739, -7.8163],
      [110.7392, -7.8158],
      [110.7395, -7.8154],
      [110.7399, -7.8151],
      [110.7404, -7.8149],
      [110.741, -7.8146],
      [110.7414, -7.8143],
      [110.7418, -7.8139],
      [110.7419, -7.8135],
      [110.7417, -7.813],
      [110.7414, -7.8124],
      [110.7413, -7.812],
      [110.7416, -7.8116],
      [110.7422, -7.8113],
      [110.7427, -7.811],
      [110.7429, -7.8105],
      [110.743, -7.8099],
      [110.743, -7.8097],
      [110.743, -7.8087],
      [110.743, -7.8084],
      [110.743, -7.8082],
      [110.743, -7.8079],
      [110.7428, -7.8071],
      [110.7424, -7.8065],
      [110.7419, -7.8062],
      [110.7416, -7.8059],
      [110.7414, -7.8059],
      [110.7409, -7.8056],
      [110.7396, -7.8046],
      [110.7376, -7.8033],
      [110.7374, -7.8032],
      [110.7364, -7.8026],
      [110.7361, -7.8022],
      [110.735, -7.8016],
      [110.7339, -7.801],
      [110.7307, -7.7985],
      [110.7305, -7.7983],
      [110.7289, -7.7967],
      [110.7272, -7.7957],
      [110.7263, -7.7952],
      [110.7257, -7.7947],
      [110.7234, -7.7928],
      [110.719, -7.7923],
      [110.7181, -7.7922],
      [110.7167, -7.7919],
      [110.7161, -7.7917],
      [110.7148, -7.7913],
      [110.7134, -7.7903],
      [110.7133, -7.7901],
      [110.7131, -7.79]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGAWEN',
    village: 'JURANGJERO',
    border: [
      [110.709, -7.8167],
      [110.7083, -7.8167],
      [110.7078, -7.8167],
      [110.7072, -7.8169],
      [110.7068, -7.8167],
      [110.7064, -7.8165],
      [110.7061, -7.8165],
      [110.7057, -7.8165],
      [110.7052, -7.8165],
      [110.7049, -7.8159],
      [110.7046, -7.8159],
      [110.7043, -7.8158],
      [110.7039, -7.8161],
      [110.7036, -7.8164],
      [110.7033, -7.8167],
      [110.703, -7.817],
      [110.7017, -7.8174],
      [110.7008, -7.8176],
      [110.7004, -7.8175],
      [110.6995, -7.8175],
      [110.6978, -7.8171],
      [110.6968, -7.8169],
      [110.6962, -7.8166],
      [110.6953, -7.8159],
      [110.6943, -7.815],
      [110.6926, -7.814],
      [110.6909, -7.813],
      [110.6891, -7.8121],
      [110.6884, -7.8119],
      [110.6874, -7.8116],
      [110.6862, -7.8113],
      [110.6849, -7.811],
      [110.6838, -7.8109],
      [110.683, -7.8107],
      [110.6821, -7.8107],
      [110.6804, -7.8106],
      [110.6793, -7.8105],
      [110.6779, -7.8104],
      [110.6768, -7.8103],
      [110.6745, -7.8101],
      [110.6731, -7.8099],
      [110.6721, -7.8097],
      [110.6697, -7.8093],
      [110.669, -7.8094],
      [110.6682, -7.8098],
      [110.667, -7.8107],
      [110.6664, -7.8114],
      [110.6656, -7.8124],
      [110.6645, -7.814],
      [110.6631, -7.8155],
      [110.6625, -7.816],
      [110.661, -7.8162],
      [110.66, -7.8166],
      [110.6577, -7.8174],
      [110.657, -7.8177],
      [110.6567, -7.8179],
      [110.6538, -7.8193],
      [110.6535, -7.8195],
      [110.6535, -7.8196],
      [110.6533, -7.8206],
      [110.6533, -7.8209],
      [110.6531, -7.8219],
      [110.6531, -7.8232],
      [110.6536, -7.8242],
      [110.6541, -7.8246],
      [110.6549, -7.8248],
      [110.6558, -7.825],
      [110.6575, -7.825],
      [110.66, -7.8249],
      [110.6621, -7.8249],
      [110.6627, -7.8249],
      [110.6629, -7.8251],
      [110.6646, -7.8244],
      [110.6674, -7.8232],
      [110.6696, -7.8222],
      [110.672, -7.8212],
      [110.6747, -7.8199],
      [110.6769, -7.8191],
      [110.6788, -7.8184],
      [110.6807, -7.8178],
      [110.6824, -7.8173],
      [110.683, -7.8173],
      [110.6839, -7.8173],
      [110.6844, -7.8174],
      [110.6848, -7.8175],
      [110.6852, -7.8177],
      [110.6853, -7.8178],
      [110.6853, -7.8182],
      [110.6857, -7.819],
      [110.6863, -7.8192],
      [110.6869, -7.8199],
      [110.6874, -7.8201],
      [110.6875, -7.8202],
      [110.6884, -7.8207],
      [110.6889, -7.821],
      [110.6891, -7.8214],
      [110.6897, -7.8217],
      [110.6911, -7.8233],
      [110.6919, -7.8244],
      [110.6923, -7.8254],
      [110.6926, -7.8256],
      [110.6934, -7.8259],
      [110.6936, -7.8262],
      [110.694, -7.8265],
      [110.6945, -7.8279],
      [110.6943, -7.8285],
      [110.6949, -7.8291],
      [110.6955, -7.8296],
      [110.6959, -7.83],
      [110.6962, -7.8303],
      [110.6968, -7.8305],
      [110.6973, -7.8302],
      [110.6977, -7.8302],
      [110.6977, -7.8306],
      [110.6976, -7.8314],
      [110.6974, -7.8321],
      [110.6973, -7.8322],
      [110.697, -7.8327],
      [110.6968, -7.8331],
      [110.6968, -7.8336],
      [110.6968, -7.8341],
      [110.6969, -7.8347],
      [110.6979, -7.8349],
      [110.6982, -7.8348],
      [110.6991, -7.8348],
      [110.6998, -7.835],
      [110.7004, -7.8348],
      [110.7008, -7.8347],
      [110.7009, -7.8335],
      [110.7023, -7.8338],
      [110.7024, -7.8338],
      [110.7036, -7.8319],
      [110.7042, -7.8309],
      [110.7037, -7.8309],
      [110.7039, -7.8291],
      [110.7042, -7.8266],
      [110.7052, -7.8236],
      [110.7054, -7.8223],
      [110.7055, -7.8222],
      [110.7069, -7.8217],
      [110.709, -7.8218],
      [110.709, -7.8195],
      [110.7091, -7.8182],
      [110.7091, -7.8176],
      [110.7091, -7.8171],
      [110.709, -7.8167]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGAWEN',
    village: 'KAMPUNG',
    border: [
      [110.709, -7.8218],
      [110.7069, -7.8217],
      [110.7055, -7.8222],
      [110.7054, -7.8223],
      [110.7052, -7.8236],
      [110.7042, -7.8266],
      [110.7039, -7.8291],
      [110.7037, -7.8309],
      [110.7042, -7.8309],
      [110.7036, -7.8319],
      [110.7024, -7.8338],
      [110.7023, -7.8338],
      [110.7009, -7.8335],
      [110.7008, -7.8347],
      [110.7004, -7.8348],
      [110.6998, -7.835],
      [110.6991, -7.8348],
      [110.6982, -7.8348],
      [110.6979, -7.8349],
      [110.6969, -7.8347],
      [110.6968, -7.8341],
      [110.6968, -7.8336],
      [110.6968, -7.8331],
      [110.697, -7.8327],
      [110.6973, -7.8322],
      [110.6974, -7.8321],
      [110.6976, -7.8314],
      [110.6977, -7.8306],
      [110.6977, -7.8302],
      [110.6973, -7.8302],
      [110.6968, -7.8305],
      [110.6962, -7.8303],
      [110.6959, -7.83],
      [110.6955, -7.8296],
      [110.6949, -7.8291],
      [110.6943, -7.8285],
      [110.6945, -7.8279],
      [110.694, -7.8265],
      [110.6936, -7.8262],
      [110.6934, -7.8259],
      [110.6926, -7.8256],
      [110.6923, -7.8254],
      [110.6919, -7.8244],
      [110.6911, -7.8233],
      [110.6897, -7.8217],
      [110.6891, -7.8214],
      [110.6889, -7.821],
      [110.6884, -7.8207],
      [110.6875, -7.8202],
      [110.6874, -7.8201],
      [110.6869, -7.8199],
      [110.6863, -7.8192],
      [110.6857, -7.819],
      [110.6853, -7.8182],
      [110.6853, -7.8178],
      [110.6852, -7.8177],
      [110.6848, -7.8175],
      [110.6844, -7.8174],
      [110.6839, -7.8173],
      [110.683, -7.8173],
      [110.6824, -7.8173],
      [110.6807, -7.8178],
      [110.6788, -7.8184],
      [110.6769, -7.8191],
      [110.6747, -7.8199],
      [110.672, -7.8212],
      [110.6696, -7.8222],
      [110.6674, -7.8232],
      [110.6646, -7.8244],
      [110.6629, -7.8251],
      [110.663, -7.8253],
      [110.6631, -7.8256],
      [110.663, -7.8273],
      [110.6627, -7.8287],
      [110.6625, -7.8295],
      [110.6624, -7.8299],
      [110.6622, -7.8302],
      [110.6621, -7.8305],
      [110.6617, -7.8312],
      [110.6615, -7.8317],
      [110.6615, -7.8321],
      [110.6616, -7.8333],
      [110.6616, -7.8344],
      [110.6616, -7.8347],
      [110.6618, -7.8347],
      [110.6623, -7.8345],
      [110.6631, -7.8344],
      [110.6633, -7.8343],
      [110.6636, -7.8341],
      [110.6651, -7.8326],
      [110.6659, -7.8319],
      [110.667, -7.8309],
      [110.668, -7.8301],
      [110.6689, -7.8296],
      [110.6695, -7.8294],
      [110.6707, -7.8295],
      [110.6714, -7.83],
      [110.6724, -7.8305],
      [110.6734, -7.831],
      [110.6737, -7.8311],
      [110.674, -7.8311],
      [110.6744, -7.8313],
      [110.6751, -7.8321],
      [110.6754, -7.8327],
      [110.676, -7.8332],
      [110.6764, -7.8335],
      [110.6766, -7.8337],
      [110.6769, -7.834],
      [110.677, -7.8341],
      [110.6771, -7.8343],
      [110.6777, -7.8346],
      [110.6778, -7.8353],
      [110.6779, -7.8357],
      [110.6783, -7.8357],
      [110.6784, -7.8363],
      [110.6793, -7.8364],
      [110.6803, -7.8365],
      [110.6806, -7.8364],
      [110.681, -7.8363],
      [110.6813, -7.8362],
      [110.6826, -7.8362],
      [110.6838, -7.8361],
      [110.6852, -7.836],
      [110.6847, -7.8373],
      [110.6844, -7.838],
      [110.6844, -7.8381],
      [110.6847, -7.8382],
      [110.6849, -7.8383],
      [110.6853, -7.8386],
      [110.6858, -7.8384],
      [110.6859, -7.8385],
      [110.6863, -7.8391],
      [110.6866, -7.8392],
      [110.6869, -7.8401],
      [110.6871, -7.8408],
      [110.6872, -7.8412],
      [110.6867, -7.842],
      [110.6865, -7.8425],
      [110.6868, -7.8427],
      [110.6871, -7.8432],
      [110.6874, -7.8438],
      [110.6875, -7.844],
      [110.6874, -7.8442],
      [110.6875, -7.8444],
      [110.6878, -7.8444],
      [110.688, -7.8444],
      [110.6882, -7.8449],
      [110.6885, -7.8451],
      [110.6887, -7.8454],
      [110.6888, -7.8457],
      [110.689, -7.8463],
      [110.6895, -7.8464],
      [110.6897, -7.8466],
      [110.6901, -7.8477],
      [110.6906, -7.8483],
      [110.6904, -7.8486],
      [110.6903, -7.849],
      [110.6905, -7.8494],
      [110.6907, -7.8497],
      [110.6902, -7.8507],
      [110.6902, -7.8512],
      [110.6907, -7.8528],
      [110.6912, -7.8532],
      [110.6918, -7.8536],
      [110.692, -7.8537],
      [110.6925, -7.8539],
      [110.6926, -7.8535],
      [110.6927, -7.8534],
      [110.6927, -7.8528],
      [110.6929, -7.8525],
      [110.693, -7.8524],
      [110.6933, -7.8523],
      [110.6935, -7.8524],
      [110.6937, -7.8527],
      [110.6937, -7.853],
      [110.6938, -7.8531],
      [110.6939, -7.8531],
      [110.6941, -7.853],
      [110.6943, -7.8529],
      [110.6944, -7.8529],
      [110.6945, -7.8529],
      [110.6946, -7.8533],
      [110.6946, -7.8534],
      [110.6948, -7.8535],
      [110.6954, -7.8535],
      [110.6956, -7.8535],
      [110.6958, -7.8534],
      [110.6961, -7.8531],
      [110.6961, -7.853],
      [110.6963, -7.8528],
      [110.6966, -7.8527],
      [110.6967, -7.8527],
      [110.697, -7.8527],
      [110.6973, -7.8528],
      [110.6975, -7.8527],
      [110.6976, -7.8521],
      [110.6977, -7.8517],
      [110.6979, -7.8515],
      [110.6983, -7.8516],
      [110.6987, -7.8518],
      [110.699, -7.8518],
      [110.699, -7.8516],
      [110.6991, -7.8513],
      [110.6993, -7.8511],
      [110.6996, -7.8511],
      [110.6997, -7.8508],
      [110.6998, -7.8506],
      [110.7, -7.8504],
      [110.7, -7.8501],
      [110.7002, -7.8496],
      [110.7006, -7.849],
      [110.7009, -7.8488],
      [110.7012, -7.8486],
      [110.7015, -7.8487],
      [110.7015, -7.8485],
      [110.7016, -7.8481],
      [110.7021, -7.8479],
      [110.7026, -7.8475],
      [110.7037, -7.8482],
      [110.7041, -7.8483],
      [110.7046, -7.8483],
      [110.7052, -7.8485],
      [110.7058, -7.8472],
      [110.7062, -7.8462],
      [110.7064, -7.846],
      [110.7064, -7.8454],
      [110.7063, -7.8441],
      [110.7061, -7.8431],
      [110.7057, -7.8417],
      [110.7057, -7.8409],
      [110.7056, -7.8397],
      [110.7051, -7.8381],
      [110.7048, -7.8374],
      [110.7047, -7.8365],
      [110.705, -7.8356],
      [110.7051, -7.8354],
      [110.7057, -7.8343],
      [110.7061, -7.8332],
      [110.7064, -7.8324],
      [110.7065, -7.8312],
      [110.7067, -7.8302],
      [110.707, -7.829],
      [110.7082, -7.8267],
      [110.709, -7.8251],
      [110.7096, -7.8243],
      [110.7101, -7.8237],
      [110.7104, -7.8234],
      [110.7113, -7.8233],
      [110.7123, -7.8236],
      [110.7118, -7.8228],
      [110.7113, -7.8223],
      [110.7108, -7.8219],
      [110.709, -7.8218]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGAWEN',
    village: 'BEJI',
    border: [
      [110.699, -7.8518],
      [110.6987, -7.8518],
      [110.6983, -7.8516],
      [110.6979, -7.8515],
      [110.6977, -7.8517],
      [110.6976, -7.8521],
      [110.6975, -7.8527],
      [110.6973, -7.8528],
      [110.697, -7.8527],
      [110.6967, -7.8527],
      [110.6966, -7.8527],
      [110.6963, -7.8528],
      [110.6961, -7.853],
      [110.6961, -7.8531],
      [110.6958, -7.8534],
      [110.6956, -7.8535],
      [110.6954, -7.8535],
      [110.6948, -7.8535],
      [110.6946, -7.8534],
      [110.6946, -7.8533],
      [110.6945, -7.8529],
      [110.6944, -7.8529],
      [110.6943, -7.8529],
      [110.6941, -7.853],
      [110.6939, -7.8531],
      [110.6938, -7.8531],
      [110.6937, -7.853],
      [110.6937, -7.8527],
      [110.6935, -7.8524],
      [110.6933, -7.8523],
      [110.693, -7.8524],
      [110.6929, -7.8525],
      [110.6927, -7.8528],
      [110.6927, -7.8534],
      [110.6926, -7.8535],
      [110.6925, -7.8539],
      [110.692, -7.8537],
      [110.6918, -7.8536],
      [110.6912, -7.8532],
      [110.6907, -7.8528],
      [110.6902, -7.8512],
      [110.6902, -7.8507],
      [110.6907, -7.8497],
      [110.6905, -7.8494],
      [110.6903, -7.849],
      [110.6904, -7.8486],
      [110.6906, -7.8483],
      [110.6901, -7.8477],
      [110.6897, -7.8466],
      [110.6895, -7.8464],
      [110.689, -7.8463],
      [110.6888, -7.8457],
      [110.6887, -7.8454],
      [110.6885, -7.8451],
      [110.6882, -7.8449],
      [110.688, -7.8444],
      [110.6878, -7.8444],
      [110.6875, -7.8444],
      [110.6874, -7.8442],
      [110.6875, -7.844],
      [110.6874, -7.8438],
      [110.6871, -7.8432],
      [110.6868, -7.8427],
      [110.6865, -7.8425],
      [110.6867, -7.842],
      [110.6872, -7.8412],
      [110.6871, -7.8408],
      [110.6869, -7.8401],
      [110.6866, -7.8392],
      [110.6863, -7.8391],
      [110.6859, -7.8385],
      [110.6858, -7.8384],
      [110.6853, -7.8386],
      [110.6849, -7.8383],
      [110.6847, -7.8382],
      [110.6844, -7.8381],
      [110.6844, -7.838],
      [110.6847, -7.8373],
      [110.6852, -7.836],
      [110.6838, -7.8361],
      [110.6826, -7.8362],
      [110.6813, -7.8362],
      [110.681, -7.8363],
      [110.6806, -7.8364],
      [110.6803, -7.8365],
      [110.6793, -7.8364],
      [110.6784, -7.8363],
      [110.6783, -7.8357],
      [110.6779, -7.8357],
      [110.6778, -7.8353],
      [110.6777, -7.8346],
      [110.6771, -7.8343],
      [110.677, -7.8341],
      [110.6769, -7.834],
      [110.6766, -7.8337],
      [110.6764, -7.8335],
      [110.676, -7.8332],
      [110.6754, -7.8327],
      [110.6751, -7.8321],
      [110.6744, -7.8313],
      [110.674, -7.8311],
      [110.6737, -7.8311],
      [110.6734, -7.831],
      [110.6724, -7.8305],
      [110.6714, -7.83],
      [110.6707, -7.8295],
      [110.6695, -7.8294],
      [110.6689, -7.8296],
      [110.668, -7.8301],
      [110.667, -7.8309],
      [110.6659, -7.8319],
      [110.6651, -7.8326],
      [110.6636, -7.8341],
      [110.6633, -7.8343],
      [110.6631, -7.8344],
      [110.6623, -7.8345],
      [110.6618, -7.8347],
      [110.6616, -7.8347],
      [110.6615, -7.8356],
      [110.6617, -7.8362],
      [110.6623, -7.8368],
      [110.6632, -7.8374],
      [110.6645, -7.8381],
      [110.6646, -7.8382],
      [110.6649, -7.8385],
      [110.6653, -7.8388],
      [110.6656, -7.8393],
      [110.6659, -7.8398],
      [110.666, -7.8402],
      [110.6661, -7.8403],
      [110.6662, -7.8408],
      [110.6662, -7.8414],
      [110.666, -7.8424],
      [110.666, -7.8434],
      [110.666, -7.8452],
      [110.6658, -7.8463],
      [110.6656, -7.8473],
      [110.6655, -7.8482],
      [110.6654, -7.8494],
      [110.6653, -7.8502],
      [110.6652, -7.8515],
      [110.6652, -7.852],
      [110.6656, -7.853],
      [110.6666, -7.8543],
      [110.667, -7.855],
      [110.6672, -7.855],
      [110.6684, -7.8552],
      [110.6692, -7.8552],
      [110.6696, -7.8551],
      [110.6699, -7.8551],
      [110.6701, -7.8553],
      [110.6706, -7.8564],
      [110.6709, -7.8568],
      [110.6716, -7.8574],
      [110.6721, -7.8575],
      [110.6729, -7.858],
      [110.6733, -7.8584],
      [110.6738, -7.8585],
      [110.6751, -7.8587],
      [110.6765, -7.8586],
      [110.677, -7.8585],
      [110.6771, -7.8588],
      [110.6773, -7.8591],
      [110.6777, -7.8593],
      [110.678, -7.8593],
      [110.6787, -7.8589],
      [110.6792, -7.8586],
      [110.6793, -7.8586],
      [110.6797, -7.8585],
      [110.68, -7.8585],
      [110.6805, -7.8586],
      [110.6812, -7.8588],
      [110.6817, -7.8589],
      [110.6821, -7.8589],
      [110.6828, -7.8583],
      [110.6835, -7.8577],
      [110.6842, -7.8572],
      [110.6846, -7.8569],
      [110.685, -7.8569],
      [110.6852, -7.8568],
      [110.6863, -7.8568],
      [110.6866, -7.8567],
      [110.6876, -7.8574],
      [110.6886, -7.8579],
      [110.6892, -7.8582],
      [110.6901, -7.8591],
      [110.6908, -7.8594],
      [110.6929, -7.8597],
      [110.6952, -7.8597],
      [110.6965, -7.8595],
      [110.6972, -7.8594],
      [110.6981, -7.859],
      [110.6982, -7.8587],
      [110.699, -7.8571],
      [110.6993, -7.8568],
      [110.6996, -7.856],
      [110.7002, -7.8548],
      [110.7003, -7.8547],
      [110.7004, -7.8546],
      [110.7003, -7.8542],
      [110.7003, -7.8541],
      [110.7003, -7.8538],
      [110.7003, -7.8535],
      [110.7001, -7.8532],
      [110.6998, -7.8529],
      [110.7, -7.8525],
      [110.6999, -7.8521],
      [110.6996, -7.8519],
      [110.699, -7.8518]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'NGAWEN',
    village: 'WATUSIGAR',
    border: [
      [110.6981, -7.859],
      [110.6972, -7.8594],
      [110.6965, -7.8595],
      [110.6952, -7.8597],
      [110.6929, -7.8597],
      [110.6908, -7.8594],
      [110.6901, -7.8591],
      [110.6892, -7.8582],
      [110.6886, -7.8579],
      [110.6876, -7.8574],
      [110.6866, -7.8567],
      [110.6863, -7.8568],
      [110.6852, -7.8568],
      [110.685, -7.8569],
      [110.6846, -7.8569],
      [110.6842, -7.8572],
      [110.6835, -7.8577],
      [110.6828, -7.8583],
      [110.6821, -7.8589],
      [110.6817, -7.8589],
      [110.6812, -7.8588],
      [110.6805, -7.8586],
      [110.68, -7.8585],
      [110.6797, -7.8585],
      [110.6793, -7.8586],
      [110.6792, -7.8586],
      [110.6787, -7.8589],
      [110.678, -7.8593],
      [110.6777, -7.8593],
      [110.6773, -7.8591],
      [110.6771, -7.8588],
      [110.677, -7.8585],
      [110.6765, -7.8586],
      [110.6751, -7.8587],
      [110.6738, -7.8585],
      [110.6733, -7.8584],
      [110.6729, -7.858],
      [110.6721, -7.8575],
      [110.6716, -7.8574],
      [110.6709, -7.8568],
      [110.6706, -7.8564],
      [110.6701, -7.8553],
      [110.6699, -7.8551],
      [110.6696, -7.8551],
      [110.6692, -7.8552],
      [110.6684, -7.8552],
      [110.6672, -7.855],
      [110.667, -7.855],
      [110.6676, -7.8567],
      [110.6674, -7.8577],
      [110.6679, -7.8585],
      [110.6681, -7.8591],
      [110.6683, -7.86],
      [110.6684, -7.8611],
      [110.6683, -7.8618],
      [110.6681, -7.8628],
      [110.6679, -7.8638],
      [110.6678, -7.8643],
      [110.6678, -7.8644],
      [110.6678, -7.8653],
      [110.6678, -7.8654],
      [110.6678, -7.8656],
      [110.6677, -7.866],
      [110.6676, -7.8669],
      [110.6678, -7.867],
      [110.6677, -7.8674],
      [110.668, -7.8675],
      [110.668, -7.8682],
      [110.6684, -7.8684],
      [110.6684, -7.8687],
      [110.6689, -7.8689],
      [110.6688, -7.8696],
      [110.6689, -7.8709],
      [110.6692, -7.8721],
      [110.6694, -7.8723],
      [110.6697, -7.8725],
      [110.6698, -7.873],
      [110.67, -7.8736],
      [110.6702, -7.8744],
      [110.6708, -7.875],
      [110.6714, -7.8756],
      [110.6719, -7.8762],
      [110.6724, -7.8764],
      [110.6729, -7.8768],
      [110.6735, -7.8771],
      [110.6742, -7.8776],
      [110.6742, -7.878],
      [110.6743, -7.8787],
      [110.6746, -7.8787],
      [110.6748, -7.879],
      [110.6756, -7.8801],
      [110.6761, -7.881],
      [110.6765, -7.8811],
      [110.677, -7.8817],
      [110.6774, -7.8821],
      [110.6779, -7.8824],
      [110.6788, -7.8827],
      [110.6795, -7.8829],
      [110.6804, -7.8831],
      [110.6811, -7.8831],
      [110.6811, -7.8831],
      [110.6819, -7.883],
      [110.6823, -7.8831],
      [110.6829, -7.8833],
      [110.6836, -7.8837],
      [110.6851, -7.8843],
      [110.6861, -7.885],
      [110.6865, -7.8852],
      [110.6872, -7.8858],
      [110.6885, -7.8863],
      [110.6897, -7.8866],
      [110.6902, -7.8867],
      [110.6908, -7.8867],
      [110.6912, -7.8868],
      [110.6913, -7.8858],
      [110.6914, -7.8848],
      [110.6914, -7.8838],
      [110.6918, -7.8827],
      [110.6921, -7.8816],
      [110.6921, -7.8806],
      [110.6919, -7.8797],
      [110.6917, -7.8789],
      [110.6916, -7.8779],
      [110.6918, -7.8778],
      [110.6917, -7.8773],
      [110.6917, -7.8769],
      [110.6922, -7.8769],
      [110.6925, -7.8769],
      [110.6926, -7.8767],
      [110.6928, -7.8761],
      [110.6929, -7.8757],
      [110.6933, -7.8753],
      [110.6935, -7.8749],
      [110.6937, -7.8744],
      [110.6942, -7.8742],
      [110.6948, -7.8742],
      [110.6955, -7.8741],
      [110.6962, -7.8739],
      [110.6969, -7.8738],
      [110.6974, -7.8736],
      [110.6976, -7.8734],
      [110.6979, -7.8731],
      [110.6978, -7.8729],
      [110.6978, -7.8728],
      [110.6972, -7.8724],
      [110.6967, -7.872],
      [110.6962, -7.8717],
      [110.696, -7.8715],
      [110.6965, -7.8706],
      [110.697, -7.8696],
      [110.6973, -7.8691],
      [110.6977, -7.8684],
      [110.6979, -7.8676],
      [110.6981, -7.867],
      [110.6984, -7.8662],
      [110.6984, -7.8659],
      [110.6984, -7.8656],
      [110.6983, -7.8638],
      [110.6981, -7.8614],
      [110.698, -7.8602],
      [110.6981, -7.8591],
      [110.6981, -7.859]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GEDANGSARI',
    village: 'HARGOMULYO',
    border: [
      [110.6014, -7.8201],
      [110.601, -7.8201],
      [110.6001, -7.8199],
      [110.5996, -7.8202],
      [110.5988, -7.8204],
      [110.5983, -7.8201],
      [110.5973, -7.8206],
      [110.5966, -7.8211],
      [110.596, -7.8211],
      [110.5953, -7.8213],
      [110.5951, -7.8212],
      [110.5935, -7.8209],
      [110.5925, -7.8205],
      [110.5912, -7.82],
      [110.5895, -7.8197],
      [110.5888, -7.8197],
      [110.5881, -7.8197],
      [110.5864, -7.8196],
      [110.5847, -7.8198],
      [110.5835, -7.8202],
      [110.583, -7.8201],
      [110.5829, -7.8206],
      [110.5828, -7.8207],
      [110.5822, -7.8209],
      [110.581, -7.8213],
      [110.5803, -7.822],
      [110.5796, -7.8227],
      [110.5792, -7.8234],
      [110.578, -7.8236],
      [110.5771, -7.8237],
      [110.5766, -7.8235],
      [110.576, -7.8232],
      [110.5755, -7.8231],
      [110.5749, -7.8231],
      [110.5744, -7.8234],
      [110.574, -7.8238],
      [110.574, -7.8245],
      [110.5739, -7.8248],
      [110.5734, -7.8252],
      [110.5732, -7.8255],
      [110.5729, -7.8258],
      [110.5724, -7.8257],
      [110.572, -7.8255],
      [110.5717, -7.8255],
      [110.5713, -7.8255],
      [110.571, -7.8254],
      [110.5707, -7.8255],
      [110.5704, -7.8256],
      [110.5702, -7.826],
      [110.5699, -7.8263],
      [110.5696, -7.8267],
      [110.5695, -7.8272],
      [110.5694, -7.8277],
      [110.5691, -7.8284],
      [110.5689, -7.8289],
      [110.569, -7.8296],
      [110.5686, -7.8302],
      [110.5683, -7.8307],
      [110.5678, -7.8312],
      [110.5674, -7.8317],
      [110.5669, -7.8319],
      [110.5662, -7.8324],
      [110.5657, -7.8327],
      [110.5661, -7.8334],
      [110.5667, -7.8335],
      [110.5671, -7.8338],
      [110.5675, -7.8343],
      [110.5683, -7.8343],
      [110.5685, -7.8346],
      [110.5688, -7.8346],
      [110.5696, -7.8349],
      [110.5698, -7.8352],
      [110.5701, -7.8357],
      [110.5705, -7.8364],
      [110.571, -7.8367],
      [110.5714, -7.8369],
      [110.5717, -7.837],
      [110.5729, -7.8377],
      [110.5745, -7.8387],
      [110.5758, -7.8399],
      [110.5765, -7.8403],
      [110.5775, -7.8413],
      [110.5785, -7.8424],
      [110.5798, -7.8441],
      [110.5799, -7.8444],
      [110.5806, -7.8454],
      [110.5807, -7.8459],
      [110.5806, -7.8462],
      [110.5806, -7.8464],
      [110.5809, -7.8467],
      [110.5811, -7.8469],
      [110.5813, -7.847],
      [110.5818, -7.8478],
      [110.582, -7.848],
      [110.5826, -7.8482],
      [110.5827, -7.8483],
      [110.5827, -7.8487],
      [110.583, -7.8489],
      [110.5834, -7.8492],
      [110.5837, -7.8494],
      [110.5839, -7.8495],
      [110.584, -7.8496],
      [110.5841, -7.8497],
      [110.5844, -7.8497],
      [110.5845, -7.8498],
      [110.5849, -7.8498],
      [110.5852, -7.8499],
      [110.5853, -7.8499],
      [110.5857, -7.8499],
      [110.5865, -7.85],
      [110.5876, -7.8502],
      [110.5887, -7.8505],
      [110.5889, -7.8505],
      [110.5897, -7.8507],
      [110.5903, -7.8507],
      [110.5908, -7.8508],
      [110.591, -7.8509],
      [110.5912, -7.8512],
      [110.5916, -7.8511],
      [110.5917, -7.8511],
      [110.5918, -7.8511],
      [110.592, -7.8508],
      [110.5923, -7.8507],
      [110.5926, -7.8506],
      [110.593, -7.8505],
      [110.5933, -7.8505],
      [110.5935, -7.8502],
      [110.5938, -7.8502],
      [110.5939, -7.8502],
      [110.5946, -7.8503],
      [110.5951, -7.8504],
      [110.5952, -7.8501],
      [110.5953, -7.85],
      [110.5954, -7.8499],
      [110.5957, -7.85],
      [110.5958, -7.85],
      [110.5962, -7.8499],
      [110.5964, -7.8499],
      [110.5965, -7.8502],
      [110.5968, -7.8502],
      [110.597, -7.8504],
      [110.5972, -7.8504],
      [110.5975, -7.8505],
      [110.5979, -7.8507],
      [110.5981, -7.8508],
      [110.5983, -7.8509],
      [110.5987, -7.8509],
      [110.5988, -7.8511],
      [110.5989, -7.8509],
      [110.599, -7.8508],
      [110.5995, -7.8507],
      [110.6003, -7.8507],
      [110.601, -7.8507],
      [110.6013, -7.8505],
      [110.6015, -7.8503],
      [110.6017, -7.8504],
      [110.602, -7.8504],
      [110.6021, -7.8504],
      [110.6024, -7.8503],
      [110.6026, -7.8503],
      [110.6027, -7.8503],
      [110.6028, -7.8502],
      [110.6033, -7.8502],
      [110.6037, -7.8502],
      [110.6041, -7.8503],
      [110.6042, -7.8504],
      [110.6045, -7.8505],
      [110.6046, -7.8505],
      [110.6051, -7.8506],
      [110.6057, -7.8507],
      [110.6058, -7.8508],
      [110.6063, -7.8505],
      [110.607, -7.8501],
      [110.6071, -7.8501],
      [110.6075, -7.8499],
      [110.608, -7.8499],
      [110.6085, -7.8496],
      [110.6088, -7.8493],
      [110.6094, -7.8493],
      [110.61, -7.8492],
      [110.6104, -7.8488],
      [110.6109, -7.8486],
      [110.6114, -7.8483],
      [110.612, -7.8481],
      [110.6125, -7.8479],
      [110.6126, -7.8473],
      [110.6118, -7.8458],
      [110.6114, -7.8447],
      [110.6111, -7.844],
      [110.6113, -7.8434],
      [110.6116, -7.8428],
      [110.612, -7.8427],
      [110.612, -7.8426],
      [110.6117, -7.8425],
      [110.6114, -7.8425],
      [110.6111, -7.8418],
      [110.6108, -7.8416],
      [110.6101, -7.8415],
      [110.6094, -7.8414],
      [110.609, -7.8411],
      [110.6086, -7.8409],
      [110.6084, -7.8407],
      [110.6081, -7.8406],
      [110.6077, -7.8407],
      [110.6072, -7.841],
      [110.6068, -7.8408],
      [110.607, -7.8407],
      [110.6073, -7.8406],
      [110.607, -7.84],
      [110.607, -7.8396],
      [110.6073, -7.839],
      [110.6071, -7.8387],
      [110.607, -7.8381],
      [110.6069, -7.8377],
      [110.6068, -7.8373],
      [110.6069, -7.8369],
      [110.6067, -7.8367],
      [110.6065, -7.8366],
      [110.6063, -7.8366],
      [110.6059, -7.8364],
      [110.6057, -7.8364],
      [110.6053, -7.8363],
      [110.6051, -7.8362],
      [110.6051, -7.8355],
      [110.605, -7.8348],
      [110.6051, -7.8336],
      [110.6051, -7.8334],
      [110.6052, -7.833],
      [110.6049, -7.8326],
      [110.6049, -7.832],
      [110.6046, -7.8317],
      [110.6042, -7.8312],
      [110.6041, -7.8308],
      [110.6039, -7.8303],
      [110.6036, -7.8301],
      [110.6036, -7.8299],
      [110.6036, -7.8295],
      [110.603, -7.8293],
      [110.6027, -7.8288],
      [110.6024, -7.8283],
      [110.602, -7.8282],
      [110.6016, -7.828],
      [110.6006, -7.828],
      [110.5998, -7.8282],
      [110.5991, -7.8281],
      [110.5984, -7.8286],
      [110.599, -7.8278],
      [110.5993, -7.827],
      [110.5998, -7.8264],
      [110.6006, -7.8257],
      [110.6011, -7.8249],
      [110.6013, -7.8246],
      [110.6021, -7.8239],
      [110.6022, -7.8236],
      [110.6024, -7.8231],
      [110.6026, -7.8225],
      [110.6023, -7.822],
      [110.6021, -7.8216],
      [110.6023, -7.8209],
      [110.6015, -7.8206],
      [110.6014, -7.8201]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GEDANGSARI',
    village: 'MERTELU',
    border: [
      [110.636, -7.8307],
      [110.6359, -7.8301],
      [110.6359, -7.8284],
      [110.6358, -7.8271],
      [110.6357, -7.8266],
      [110.6357, -7.8261],
      [110.6347, -7.8255],
      [110.6337, -7.8253],
      [110.6324, -7.8247],
      [110.6312, -7.8239],
      [110.6307, -7.8228],
      [110.6308, -7.8219],
      [110.6316, -7.8205],
      [110.6317, -7.8201],
      [110.6317, -7.8189],
      [110.6316, -7.8182],
      [110.6312, -7.8173],
      [110.6306, -7.8164],
      [110.6303, -7.8157],
      [110.6302, -7.8145],
      [110.6295, -7.8146],
      [110.6292, -7.8149],
      [110.6285, -7.815],
      [110.628, -7.8146],
      [110.6276, -7.814],
      [110.6274, -7.8136],
      [110.6273, -7.8128],
      [110.6273, -7.812],
      [110.6274, -7.8116],
      [110.6276, -7.8115],
      [110.6278, -7.8113],
      [110.6278, -7.8109],
      [110.6276, -7.8104],
      [110.6272, -7.8099],
      [110.6268, -7.8099],
      [110.6263, -7.8099],
      [110.6259, -7.8099],
      [110.6256, -7.8095],
      [110.6255, -7.808],
      [110.6248, -7.8078],
      [110.6238, -7.8089],
      [110.6232, -7.8094],
      [110.6223, -7.8095],
      [110.622, -7.8096],
      [110.622, -7.8088],
      [110.6218, -7.8086],
      [110.6214, -7.8083],
      [110.6208, -7.8085],
      [110.6201, -7.8083],
      [110.6197, -7.8083],
      [110.6191, -7.8086],
      [110.619, -7.8087],
      [110.6181, -7.8092],
      [110.6167, -7.8095],
      [110.6156, -7.8099],
      [110.6148, -7.8101],
      [110.614, -7.81],
      [110.6126, -7.8095],
      [110.6123, -7.8095],
      [110.6119, -7.8098],
      [110.6116, -7.81],
      [110.6113, -7.81],
      [110.6109, -7.8099],
      [110.6105, -7.8097],
      [110.6101, -7.8097],
      [110.6101, -7.8099],
      [110.6102, -7.8104],
      [110.61, -7.8106],
      [110.6098, -7.8109],
      [110.6099, -7.8124],
      [110.6099, -7.8128],
      [110.6097, -7.8137],
      [110.6095, -7.8144],
      [110.6089, -7.8153],
      [110.6086, -7.8159],
      [110.6085, -7.817],
      [110.6082, -7.8175],
      [110.6071, -7.8178],
      [110.6065, -7.8181],
      [110.6059, -7.8188],
      [110.6054, -7.8189],
      [110.605, -7.8196],
      [110.6045, -7.8195],
      [110.6038, -7.8194],
      [110.6028, -7.8197],
      [110.6014, -7.8201],
      [110.6015, -7.8206],
      [110.6023, -7.8209],
      [110.6021, -7.8216],
      [110.6023, -7.822],
      [110.6026, -7.8225],
      [110.6024, -7.8231],
      [110.6022, -7.8236],
      [110.6021, -7.8239],
      [110.6013, -7.8246],
      [110.6011, -7.8249],
      [110.6006, -7.8257],
      [110.5998, -7.8264],
      [110.5993, -7.827],
      [110.599, -7.8278],
      [110.5984, -7.8286],
      [110.5991, -7.8281],
      [110.5998, -7.8282],
      [110.6006, -7.828],
      [110.6016, -7.828],
      [110.602, -7.8282],
      [110.6024, -7.8283],
      [110.6027, -7.8288],
      [110.603, -7.8293],
      [110.6036, -7.8295],
      [110.6036, -7.8299],
      [110.6036, -7.8301],
      [110.6039, -7.8303],
      [110.6041, -7.8308],
      [110.6042, -7.8312],
      [110.6046, -7.8317],
      [110.6049, -7.832],
      [110.6049, -7.8326],
      [110.6052, -7.833],
      [110.6051, -7.8334],
      [110.6051, -7.8336],
      [110.605, -7.8348],
      [110.6051, -7.8355],
      [110.6051, -7.8362],
      [110.6053, -7.8363],
      [110.6057, -7.8364],
      [110.6059, -7.8364],
      [110.6063, -7.8366],
      [110.6065, -7.8366],
      [110.6067, -7.8367],
      [110.6069, -7.8369],
      [110.6068, -7.8373],
      [110.6069, -7.8377],
      [110.607, -7.8381],
      [110.6071, -7.8387],
      [110.6073, -7.839],
      [110.607, -7.8396],
      [110.607, -7.84],
      [110.6073, -7.8406],
      [110.607, -7.8407],
      [110.6068, -7.8408],
      [110.6072, -7.841],
      [110.6077, -7.8407],
      [110.6081, -7.8406],
      [110.6084, -7.8407],
      [110.6086, -7.8409],
      [110.609, -7.8411],
      [110.6094, -7.8414],
      [110.6101, -7.8415],
      [110.6108, -7.8416],
      [110.6111, -7.8418],
      [110.6114, -7.8425],
      [110.6117, -7.8425],
      [110.612, -7.8426],
      [110.612, -7.8427],
      [110.6126, -7.8427],
      [110.613, -7.8425],
      [110.6135, -7.8425],
      [110.614, -7.842],
      [110.6142, -7.8417],
      [110.6146, -7.8415],
      [110.615, -7.841],
      [110.6153, -7.8411],
      [110.6159, -7.8409],
      [110.6166, -7.8408],
      [110.617, -7.8406],
      [110.6178, -7.8403],
      [110.6183, -7.84],
      [110.6187, -7.8396],
      [110.6191, -7.8393],
      [110.6195, -7.8389],
      [110.6202, -7.8385],
      [110.6205, -7.8382],
      [110.6212, -7.8377],
      [110.6218, -7.8371],
      [110.6224, -7.8366],
      [110.6229, -7.8362],
      [110.6236, -7.8359],
      [110.6243, -7.8357],
      [110.625, -7.8359],
      [110.6257, -7.836],
      [110.6263, -7.836],
      [110.627, -7.8358],
      [110.6279, -7.8354],
      [110.6295, -7.8342],
      [110.6313, -7.8331],
      [110.6325, -7.8322],
      [110.6337, -7.8311],
      [110.6341, -7.8308],
      [110.636, -7.8307]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GEDANGSARI',
    village: 'WATUGAJAH',
    border: [
      [110.6185, -7.7989],
      [110.6182, -7.7991],
      [110.6179, -7.7995],
      [110.6176, -7.8001],
      [110.6172, -7.8005],
      [110.6169, -7.8011],
      [110.6165, -7.8014],
      [110.6162, -7.8015],
      [110.6161, -7.8016],
      [110.6155, -7.8015],
      [110.6151, -7.8015],
      [110.6147, -7.8012],
      [110.6141, -7.8008],
      [110.6134, -7.8],
      [110.6127, -7.7991],
      [110.6123, -7.7987],
      [110.6115, -7.7984],
      [110.6109, -7.7982],
      [110.6101, -7.7981],
      [110.6094, -7.7979],
      [110.6088, -7.7979],
      [110.6081, -7.798],
      [110.6079, -7.7981],
      [110.6077, -7.7981],
      [110.6072, -7.7982],
      [110.6071, -7.7983],
      [110.6064, -7.7984],
      [110.6062, -7.7985],
      [110.6058, -7.7985],
      [110.6054, -7.7985],
      [110.6049, -7.7982],
      [110.6044, -7.7982],
      [110.604, -7.7982],
      [110.6035, -7.7985],
      [110.6028, -7.7988],
      [110.6023, -7.7992],
      [110.6019, -7.7993],
      [110.6012, -7.7993],
      [110.6007, -7.7993],
      [110.5997, -7.7996],
      [110.599, -7.7997],
      [110.5988, -7.7999],
      [110.5988, -7.8001],
      [110.5988, -7.8004],
      [110.5991, -7.8016],
      [110.5992, -7.8017],
      [110.5996, -7.8024],
      [110.6001, -7.8032],
      [110.6003, -7.8038],
      [110.6004, -7.8044],
      [110.6006, -7.8049],
      [110.6005, -7.8053],
      [110.6004, -7.8056],
      [110.6004, -7.8059],
      [110.6002, -7.8063],
      [110.5998, -7.8066],
      [110.5993, -7.8067],
      [110.599, -7.8067],
      [110.5986, -7.8066],
      [110.598, -7.8065],
      [110.5974, -7.8062],
      [110.5969, -7.8062],
      [110.5963, -7.8063],
      [110.5957, -7.8063],
      [110.595, -7.8061],
      [110.5945, -7.8059],
      [110.5937, -7.8063],
      [110.5922, -7.8068],
      [110.5915, -7.807],
      [110.5907, -7.807],
      [110.5898, -7.8069],
      [110.589, -7.8071],
      [110.5885, -7.8074],
      [110.5878, -7.8076],
      [110.5874, -7.8079],
      [110.5872, -7.8079],
      [110.5869, -7.8079],
      [110.5864, -7.8082],
      [110.5859, -7.8083],
      [110.5858, -7.8081],
      [110.5857, -7.8079],
      [110.586, -7.8077],
      [110.5864, -7.8075],
      [110.587, -7.8073],
      [110.5875, -7.807],
      [110.5878, -7.8065],
      [110.5881, -7.806],
      [110.5883, -7.8055],
      [110.5884, -7.805],
      [110.5881, -7.8045],
      [110.5876, -7.8041],
      [110.5874, -7.8036],
      [110.5871, -7.8032],
      [110.5867, -7.8029],
      [110.5864, -7.8027],
      [110.5859, -7.8027],
      [110.5855, -7.8027],
      [110.5848, -7.8029],
      [110.5841, -7.8035],
      [110.5833, -7.804],
      [110.5831, -7.8042],
      [110.5826, -7.8047],
      [110.5821, -7.8052],
      [110.581, -7.8053],
      [110.5802, -7.8056],
      [110.5801, -7.8056],
      [110.5797, -7.8058],
      [110.5791, -7.8058],
      [110.5792, -7.806],
      [110.5792, -7.8061],
      [110.5793, -7.8067],
      [110.5794, -7.8074],
      [110.5795, -7.808],
      [110.5796, -7.8085],
      [110.5798, -7.8087],
      [110.5802, -7.8087],
      [110.5807, -7.8085],
      [110.5812, -7.8091],
      [110.5812, -7.8095],
      [110.5812, -7.8097],
      [110.5805, -7.8097],
      [110.5805, -7.8098],
      [110.5803, -7.8104],
      [110.5798, -7.8108],
      [110.5794, -7.8112],
      [110.5788, -7.8115],
      [110.5784, -7.8115],
      [110.5778, -7.8118],
      [110.5772, -7.8123],
      [110.577, -7.8127],
      [110.5771, -7.813],
      [110.5772, -7.8134],
      [110.5769, -7.8138],
      [110.5769, -7.814],
      [110.5769, -7.8146],
      [110.577, -7.8151],
      [110.5768, -7.8155],
      [110.5768, -7.8158],
      [110.5768, -7.8162],
      [110.5767, -7.8167],
      [110.5765, -7.8171],
      [110.5766, -7.8175],
      [110.5767, -7.818],
      [110.5765, -7.8184],
      [110.5766, -7.8187],
      [110.5762, -7.8189],
      [110.5756, -7.8192],
      [110.5753, -7.8193],
      [110.5749, -7.8192],
      [110.5747, -7.8195],
      [110.5746, -7.8199],
      [110.575, -7.8203],
      [110.5749, -7.8207],
      [110.5746, -7.8213],
      [110.5742, -7.8218],
      [110.5738, -7.8222],
      [110.574, -7.8226],
      [110.574, -7.823],
      [110.574, -7.8238],
      [110.5744, -7.8234],
      [110.5749, -7.8231],
      [110.5755, -7.8231],
      [110.576, -7.8232],
      [110.5766, -7.8235],
      [110.5771, -7.8237],
      [110.578, -7.8236],
      [110.5792, -7.8234],
      [110.5796, -7.8227],
      [110.5803, -7.822],
      [110.581, -7.8213],
      [110.5822, -7.8209],
      [110.5828, -7.8207],
      [110.5829, -7.8206],
      [110.583, -7.8201],
      [110.5835, -7.8202],
      [110.5847, -7.8198],
      [110.5864, -7.8196],
      [110.5881, -7.8197],
      [110.5888, -7.8197],
      [110.5895, -7.8197],
      [110.5912, -7.82],
      [110.5925, -7.8205],
      [110.5935, -7.8209],
      [110.5951, -7.8212],
      [110.5953, -7.8213],
      [110.596, -7.8211],
      [110.5966, -7.8211],
      [110.5973, -7.8206],
      [110.5983, -7.8201],
      [110.5988, -7.8204],
      [110.5996, -7.8202],
      [110.6001, -7.8199],
      [110.601, -7.8201],
      [110.6014, -7.8201],
      [110.6028, -7.8197],
      [110.6038, -7.8194],
      [110.6045, -7.8195],
      [110.605, -7.8196],
      [110.6054, -7.8189],
      [110.6059, -7.8188],
      [110.6065, -7.8181],
      [110.6071, -7.8178],
      [110.6082, -7.8175],
      [110.6085, -7.817],
      [110.6086, -7.8159],
      [110.6089, -7.8153],
      [110.6095, -7.8144],
      [110.6097, -7.8137],
      [110.6099, -7.8128],
      [110.6099, -7.8124],
      [110.6098, -7.8109],
      [110.61, -7.8106],
      [110.6102, -7.8104],
      [110.6101, -7.8099],
      [110.6101, -7.8097],
      [110.6105, -7.8097],
      [110.6109, -7.8099],
      [110.6113, -7.81],
      [110.6116, -7.81],
      [110.6119, -7.8098],
      [110.6123, -7.8095],
      [110.6126, -7.8095],
      [110.614, -7.81],
      [110.6148, -7.8101],
      [110.6156, -7.8099],
      [110.6167, -7.8095],
      [110.6181, -7.8092],
      [110.619, -7.8087],
      [110.6191, -7.8086],
      [110.619, -7.8084],
      [110.6188, -7.8079],
      [110.6185, -7.8078],
      [110.6185, -7.8074],
      [110.6184, -7.8071],
      [110.6186, -7.8068],
      [110.6188, -7.8065],
      [110.6192, -7.8065],
      [110.6195, -7.8065],
      [110.6197, -7.8063],
      [110.6199, -7.806],
      [110.6198, -7.8054],
      [110.6198, -7.8049],
      [110.6197, -7.8045],
      [110.6194, -7.8041],
      [110.6192, -7.804],
      [110.619, -7.8039],
      [110.6184, -7.8035],
      [110.6184, -7.8033],
      [110.6184, -7.8029],
      [110.6185, -7.8025],
      [110.6188, -7.802],
      [110.6191, -7.8012],
      [110.6191, -7.8002],
      [110.6191, -7.7991],
      [110.6191, -7.799],
      [110.6192, -7.7988],
      [110.6189, -7.7988],
      [110.6185, -7.7989]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GEDANGSARI',
    village: 'SAMPANG',
    border: [
      [110.5808, -7.7893],
      [110.5806, -7.7893],
      [110.5793, -7.7894],
      [110.5789, -7.7894],
      [110.5779, -7.7896],
      [110.5776, -7.7894],
      [110.5774, -7.7892],
      [110.5771, -7.789],
      [110.5766, -7.789],
      [110.5762, -7.7886],
      [110.5758, -7.7883],
      [110.5753, -7.7881],
      [110.5752, -7.788],
      [110.5751, -7.7879],
      [110.5748, -7.7879],
      [110.574, -7.7877],
      [110.572, -7.7866],
      [110.5722, -7.7869],
      [110.5723, -7.7873],
      [110.5725, -7.7877],
      [110.5726, -7.7881],
      [110.5733, -7.7886],
      [110.5732, -7.7888],
      [110.573, -7.789],
      [110.5726, -7.789],
      [110.5724, -7.7893],
      [110.5722, -7.7897],
      [110.5717, -7.7901],
      [110.5716, -7.7907],
      [110.5715, -7.7908],
      [110.5715, -7.7911],
      [110.5715, -7.7912],
      [110.5713, -7.7914],
      [110.571, -7.7917],
      [110.5709, -7.7917],
      [110.5707, -7.7917],
      [110.5703, -7.7915],
      [110.5702, -7.7913],
      [110.57, -7.7908],
      [110.5696, -7.7906],
      [110.5693, -7.7906],
      [110.5691, -7.7909],
      [110.5689, -7.7913],
      [110.5685, -7.792],
      [110.5684, -7.7927],
      [110.5681, -7.7934],
      [110.5678, -7.7937],
      [110.5675, -7.7939],
      [110.5673, -7.7941],
      [110.567, -7.7942],
      [110.5668, -7.7944],
      [110.5664, -7.7946],
      [110.5662, -7.7948],
      [110.566, -7.7949],
      [110.5656, -7.7951],
      [110.5655, -7.7954],
      [110.5655, -7.7958],
      [110.5652, -7.7961],
      [110.5649, -7.7962],
      [110.565, -7.7965],
      [110.5654, -7.7966],
      [110.5657, -7.7967],
      [110.566, -7.7973],
      [110.5661, -7.7979],
      [110.5661, -7.7985],
      [110.5661, -7.7989],
      [110.5663, -7.7992],
      [110.5665, -7.7995],
      [110.5664, -7.7998],
      [110.5663, -7.8001],
      [110.5664, -7.8006],
      [110.5664, -7.801],
      [110.5664, -7.8015],
      [110.5662, -7.8018],
      [110.5662, -7.8021],
      [110.5666, -7.8023],
      [110.5668, -7.8027],
      [110.5671, -7.803],
      [110.5671, -7.8034],
      [110.5669, -7.8037],
      [110.5667, -7.804],
      [110.5665, -7.8041],
      [110.5662, -7.8041],
      [110.5659, -7.8043],
      [110.5658, -7.8046],
      [110.5656, -7.8049],
      [110.5653, -7.805],
      [110.5652, -7.8051],
      [110.5651, -7.8054],
      [110.5648, -7.8053],
      [110.5644, -7.8054],
      [110.5641, -7.8055],
      [110.5639, -7.8058],
      [110.5638, -7.8059],
      [110.5635, -7.8062],
      [110.5634, -7.8064],
      [110.5634, -7.8066],
      [110.5629, -7.8067],
      [110.5626, -7.8069],
      [110.5623, -7.8073],
      [110.5623, -7.808],
      [110.5622, -7.8087],
      [110.5619, -7.8097],
      [110.5616, -7.8101],
      [110.5613, -7.8102],
      [110.5613, -7.8106],
      [110.5609, -7.8107],
      [110.5608, -7.8109],
      [110.561, -7.8112],
      [110.5608, -7.8113],
      [110.5607, -7.8114],
      [110.5606, -7.8115],
      [110.5606, -7.8118],
      [110.5606, -7.812],
      [110.5604, -7.8122],
      [110.5605, -7.8124],
      [110.5605, -7.8125],
      [110.5608, -7.8129],
      [110.5613, -7.8136],
      [110.5618, -7.8138],
      [110.5619, -7.8142],
      [110.5617, -7.8145],
      [110.5612, -7.8149],
      [110.5607, -7.8151],
      [110.5603, -7.8152],
      [110.5599, -7.8156],
      [110.5595, -7.8162],
      [110.5591, -7.8166],
      [110.5592, -7.817],
      [110.5594, -7.8167],
      [110.5598, -7.8167],
      [110.5605, -7.8168],
      [110.5616, -7.817],
      [110.562, -7.817],
      [110.5625, -7.8173],
      [110.5631, -7.8177],
      [110.5636, -7.8182],
      [110.564, -7.8188],
      [110.564, -7.8192],
      [110.564, -7.8198],
      [110.5639, -7.8201],
      [110.5638, -7.8206],
      [110.5639, -7.8208],
      [110.5643, -7.8209],
      [110.5646, -7.8209],
      [110.5648, -7.8214],
      [110.5648, -7.8215],
      [110.5652, -7.8217],
      [110.5661, -7.8224],
      [110.5666, -7.8228],
      [110.5672, -7.8233],
      [110.5681, -7.8242],
      [110.5684, -7.8248],
      [110.5688, -7.8253],
      [110.5692, -7.8255],
      [110.5695, -7.8252],
      [110.5698, -7.8248],
      [110.5705, -7.8248],
      [110.5707, -7.825],
      [110.571, -7.8254],
      [110.5713, -7.8255],
      [110.5717, -7.8255],
      [110.572, -7.8255],
      [110.5724, -7.8257],
      [110.5729, -7.8258],
      [110.5732, -7.8255],
      [110.5734, -7.8252],
      [110.5739, -7.8248],
      [110.574, -7.8245],
      [110.574, -7.8238],
      [110.574, -7.823],
      [110.574, -7.8226],
      [110.5738, -7.8222],
      [110.5742, -7.8218],
      [110.5746, -7.8213],
      [110.5749, -7.8207],
      [110.575, -7.8203],
      [110.5746, -7.8199],
      [110.5747, -7.8195],
      [110.5749, -7.8192],
      [110.5753, -7.8193],
      [110.5756, -7.8192],
      [110.5762, -7.8189],
      [110.5766, -7.8187],
      [110.5765, -7.8184],
      [110.5767, -7.818],
      [110.5766, -7.8175],
      [110.5765, -7.8171],
      [110.5767, -7.8167],
      [110.5768, -7.8162],
      [110.5768, -7.8158],
      [110.5768, -7.8155],
      [110.577, -7.8151],
      [110.5769, -7.8146],
      [110.5769, -7.814],
      [110.5769, -7.8138],
      [110.5772, -7.8134],
      [110.5771, -7.813],
      [110.577, -7.8127],
      [110.5772, -7.8123],
      [110.5778, -7.8118],
      [110.5784, -7.8115],
      [110.5788, -7.8115],
      [110.5794, -7.8112],
      [110.5798, -7.8108],
      [110.5803, -7.8104],
      [110.5805, -7.8098],
      [110.5805, -7.8097],
      [110.5812, -7.8097],
      [110.5812, -7.8095],
      [110.5812, -7.8091],
      [110.5807, -7.8085],
      [110.5802, -7.8087],
      [110.5798, -7.8087],
      [110.5796, -7.8085],
      [110.5795, -7.808],
      [110.5794, -7.8074],
      [110.5793, -7.8067],
      [110.5792, -7.8061],
      [110.5792, -7.806],
      [110.5791, -7.8058],
      [110.5789, -7.8059],
      [110.5779, -7.8059],
      [110.5773, -7.8059],
      [110.5767, -7.806],
      [110.5764, -7.8059],
      [110.5761, -7.8057],
      [110.5759, -7.8051],
      [110.5758, -7.8049],
      [110.5758, -7.8045],
      [110.5759, -7.8039],
      [110.576, -7.8031],
      [110.5761, -7.8026],
      [110.5761, -7.8022],
      [110.5762, -7.8019],
      [110.5764, -7.8017],
      [110.5769, -7.8014],
      [110.5775, -7.8013],
      [110.5784, -7.8011],
      [110.5793, -7.8007],
      [110.5799, -7.8005],
      [110.5801, -7.8005],
      [110.5804, -7.8004],
      [110.5809, -7.8001],
      [110.5814, -7.7996],
      [110.582, -7.7993],
      [110.5826, -7.7989],
      [110.5834, -7.7988],
      [110.5839, -7.7985],
      [110.5841, -7.7981],
      [110.5842, -7.7974],
      [110.5843, -7.7969],
      [110.5843, -7.7968],
      [110.5841, -7.7963],
      [110.5838, -7.7962],
      [110.5837, -7.7961],
      [110.5831, -7.796],
      [110.583, -7.7959],
      [110.5828, -7.7957],
      [110.5824, -7.7952],
      [110.5821, -7.795],
      [110.5816, -7.7948],
      [110.5814, -7.7947],
      [110.5814, -7.7942],
      [110.5815, -7.7936],
      [110.5816, -7.7931],
      [110.5819, -7.7929],
      [110.5821, -7.7926],
      [110.5823, -7.7922],
      [110.5824, -7.7918],
      [110.5823, -7.7914],
      [110.5823, -7.7911],
      [110.5822, -7.7904],
      [110.5822, -7.7902],
      [110.5822, -7.7897],
      [110.5822, -7.7894],
      [110.5816, -7.7892],
      [110.5811, -7.7892],
      [110.5808, -7.7893]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GEDANGSARI',
    village: 'SERUT',
    border: [
      [110.572, -7.7866],
      [110.5717, -7.786],
      [110.5712, -7.7857],
      [110.5708, -7.7855],
      [110.5706, -7.7853],
      [110.5704, -7.785],
      [110.5701, -7.7847],
      [110.57, -7.7844],
      [110.5697, -7.784],
      [110.5694, -7.7839],
      [110.569, -7.7838],
      [110.5685, -7.7839],
      [110.5681, -7.7839],
      [110.5675, -7.7838],
      [110.5669, -7.7836],
      [110.5665, -7.7834],
      [110.5661, -7.783],
      [110.5658, -7.7828],
      [110.5656, -7.7824],
      [110.5654, -7.7822],
      [110.565, -7.782],
      [110.5643, -7.782],
      [110.5636, -7.782],
      [110.5629, -7.7821],
      [110.5623, -7.7822],
      [110.5621, -7.7822],
      [110.5616, -7.7824],
      [110.5611, -7.7825],
      [110.5606, -7.7826],
      [110.5603, -7.7829],
      [110.5601, -7.7831],
      [110.5595, -7.7835],
      [110.559, -7.7835],
      [110.5588, -7.7832],
      [110.5585, -7.7831],
      [110.5578, -7.7833],
      [110.5574, -7.7834],
      [110.5571, -7.7836],
      [110.5569, -7.7837],
      [110.5559, -7.7841],
      [110.5557, -7.7844],
      [110.5555, -7.785],
      [110.5555, -7.7855],
      [110.5555, -7.786],
      [110.5556, -7.7863],
      [110.5555, -7.7866],
      [110.5554, -7.7868],
      [110.5548, -7.7868],
      [110.5541, -7.7869],
      [110.5537, -7.787],
      [110.5535, -7.7871],
      [110.5535, -7.7873],
      [110.5535, -7.7879],
      [110.5536, -7.7884],
      [110.5536, -7.7886],
      [110.5537, -7.7891],
      [110.5539, -7.7896],
      [110.554, -7.7896],
      [110.5542, -7.7898],
      [110.5547, -7.7901],
      [110.555, -7.7902],
      [110.5551, -7.7902],
      [110.5551, -7.7906],
      [110.5552, -7.7909],
      [110.5554, -7.7912],
      [110.5558, -7.7914],
      [110.5562, -7.7918],
      [110.5563, -7.7923],
      [110.5563, -7.7928],
      [110.5561, -7.7931],
      [110.5557, -7.7934],
      [110.5555, -7.7939],
      [110.5554, -7.7942],
      [110.555, -7.7943],
      [110.5547, -7.7942],
      [110.5545, -7.794],
      [110.554, -7.794],
      [110.5536, -7.794],
      [110.5532, -7.794],
      [110.5526, -7.794],
      [110.5525, -7.794],
      [110.5517, -7.7945],
      [110.5512, -7.7947],
      [110.5509, -7.7942],
      [110.5505, -7.7939],
      [110.5505, -7.7934],
      [110.5506, -7.7924],
      [110.5499, -7.792],
      [110.5499, -7.7917],
      [110.5497, -7.7918],
      [110.5496, -7.792],
      [110.5494, -7.7922],
      [110.5492, -7.7924],
      [110.5489, -7.7929],
      [110.5488, -7.7933],
      [110.5486, -7.7938],
      [110.5485, -7.7944],
      [110.5484, -7.7949],
      [110.5484, -7.7959],
      [110.5484, -7.7965],
      [110.5486, -7.7974],
      [110.5489, -7.7983],
      [110.5489, -7.7986],
      [110.5486, -7.7987],
      [110.5483, -7.7988],
      [110.5481, -7.7989],
      [110.5479, -7.7991],
      [110.5478, -7.7995],
      [110.5478, -7.7999],
      [110.5479, -7.8003],
      [110.5478, -7.8006],
      [110.5474, -7.8006],
      [110.547, -7.8006],
      [110.5466, -7.8008],
      [110.5463, -7.8011],
      [110.5458, -7.8015],
      [110.5455, -7.8021],
      [110.5452, -7.8026],
      [110.5449, -7.8031],
      [110.5448, -7.8036],
      [110.5448, -7.8036],
      [110.5447, -7.8043],
      [110.5447, -7.8047],
      [110.5449, -7.805],
      [110.5447, -7.8053],
      [110.5445, -7.8055],
      [110.5447, -7.8058],
      [110.5449, -7.8061],
      [110.5447, -7.8065],
      [110.5447, -7.8069],
      [110.5445, -7.8071],
      [110.5445, -7.8075],
      [110.5448, -7.8078],
      [110.545, -7.808],
      [110.545, -7.8082],
      [110.5452, -7.8086],
      [110.5455, -7.8092],
      [110.5458, -7.8095],
      [110.546, -7.8097],
      [110.5463, -7.8099],
      [110.5458, -7.8101],
      [110.5455, -7.8104],
      [110.5453, -7.8107],
      [110.5452, -7.811],
      [110.5451, -7.8112],
      [110.5451, -7.8118],
      [110.5453, -7.8121],
      [110.5454, -7.8125],
      [110.5454, -7.8128],
      [110.5451, -7.8131],
      [110.5451, -7.8135],
      [110.5451, -7.814],
      [110.5453, -7.8143],
      [110.5455, -7.8147],
      [110.5455, -7.8158],
      [110.5452, -7.8163],
      [110.5448, -7.8168],
      [110.5442, -7.8174],
      [110.5435, -7.8177],
      [110.5429, -7.8181],
      [110.5424, -7.8189],
      [110.5421, -7.8194],
      [110.542, -7.8198],
      [110.5421, -7.8203],
      [110.5421, -7.8208],
      [110.5424, -7.8213],
      [110.5426, -7.8217],
      [110.5429, -7.8215],
      [110.5431, -7.8215],
      [110.5435, -7.8216],
      [110.544, -7.8212],
      [110.5442, -7.8208],
      [110.5453, -7.821],
      [110.546, -7.8211],
      [110.5465, -7.8213],
      [110.5469, -7.8213],
      [110.547, -7.8211],
      [110.547, -7.8209],
      [110.5473, -7.8211],
      [110.5477, -7.8214],
      [110.5483, -7.8216],
      [110.5491, -7.8218],
      [110.5496, -7.822],
      [110.5496, -7.8219],
      [110.5496, -7.8215],
      [110.5496, -7.8208],
      [110.5499, -7.8205],
      [110.55, -7.8202],
      [110.5499, -7.8201],
      [110.5497, -7.8198],
      [110.5497, -7.8195],
      [110.55, -7.8192],
      [110.5504, -7.8189],
      [110.5511, -7.8184],
      [110.5517, -7.8181],
      [110.5528, -7.8178],
      [110.5533, -7.818],
      [110.5539, -7.8183],
      [110.5547, -7.8187],
      [110.5552, -7.8191],
      [110.5561, -7.8192],
      [110.5569, -7.8192],
      [110.5574, -7.8193],
      [110.558, -7.8194],
      [110.558, -7.8192],
      [110.5584, -7.8184],
      [110.5585, -7.8183],
      [110.559, -7.8174],
      [110.5592, -7.817],
      [110.5591, -7.8166],
      [110.5595, -7.8162],
      [110.5599, -7.8156],
      [110.5603, -7.8152],
      [110.5607, -7.8151],
      [110.5612, -7.8149],
      [110.5617, -7.8145],
      [110.5619, -7.8142],
      [110.5618, -7.8138],
      [110.5613, -7.8136],
      [110.5608, -7.8129],
      [110.5605, -7.8125],
      [110.5605, -7.8124],
      [110.5604, -7.8122],
      [110.5606, -7.812],
      [110.5606, -7.8118],
      [110.5606, -7.8115],
      [110.5607, -7.8114],
      [110.5608, -7.8113],
      [110.561, -7.8112],
      [110.5608, -7.8109],
      [110.5609, -7.8107],
      [110.5613, -7.8106],
      [110.5613, -7.8102],
      [110.5616, -7.8101],
      [110.5619, -7.8097],
      [110.5622, -7.8087],
      [110.5623, -7.808],
      [110.5623, -7.8073],
      [110.5626, -7.8069],
      [110.5629, -7.8067],
      [110.5634, -7.8066],
      [110.5634, -7.8064],
      [110.5635, -7.8062],
      [110.5638, -7.8059],
      [110.5639, -7.8058],
      [110.5641, -7.8055],
      [110.5644, -7.8054],
      [110.5648, -7.8053],
      [110.5651, -7.8054],
      [110.5652, -7.8051],
      [110.5653, -7.805],
      [110.5656, -7.8049],
      [110.5658, -7.8046],
      [110.5659, -7.8043],
      [110.5662, -7.8041],
      [110.5665, -7.8041],
      [110.5667, -7.804],
      [110.5669, -7.8037],
      [110.5671, -7.8034],
      [110.5671, -7.803],
      [110.5668, -7.8027],
      [110.5666, -7.8023],
      [110.5662, -7.8021],
      [110.5662, -7.8018],
      [110.5664, -7.8015],
      [110.5664, -7.801],
      [110.5664, -7.8006],
      [110.5663, -7.8001],
      [110.5664, -7.7998],
      [110.5665, -7.7995],
      [110.5663, -7.7992],
      [110.5661, -7.7989],
      [110.5661, -7.7985],
      [110.5661, -7.7979],
      [110.566, -7.7973],
      [110.5657, -7.7967],
      [110.5654, -7.7966],
      [110.565, -7.7965],
      [110.5649, -7.7962],
      [110.5652, -7.7961],
      [110.5655, -7.7958],
      [110.5655, -7.7954],
      [110.5656, -7.7951],
      [110.566, -7.7949],
      [110.5662, -7.7948],
      [110.5664, -7.7946],
      [110.5668, -7.7944],
      [110.567, -7.7942],
      [110.5673, -7.7941],
      [110.5675, -7.7939],
      [110.5678, -7.7937],
      [110.5681, -7.7934],
      [110.5684, -7.7927],
      [110.5685, -7.792],
      [110.5689, -7.7913],
      [110.5691, -7.7909],
      [110.5693, -7.7906],
      [110.5696, -7.7906],
      [110.57, -7.7908],
      [110.5702, -7.7913],
      [110.5703, -7.7915],
      [110.5707, -7.7917],
      [110.5709, -7.7917],
      [110.571, -7.7917],
      [110.5713, -7.7914],
      [110.5715, -7.7912],
      [110.5715, -7.7911],
      [110.5715, -7.7908],
      [110.5716, -7.7907],
      [110.5717, -7.7901],
      [110.5722, -7.7897],
      [110.5724, -7.7893],
      [110.5726, -7.789],
      [110.573, -7.789],
      [110.5732, -7.7888],
      [110.5733, -7.7886],
      [110.5726, -7.7881],
      [110.5725, -7.7877],
      [110.5723, -7.7873],
      [110.5722, -7.7869],
      [110.572, -7.7866]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GEDANGSARI',
    village: 'NGALANG',
    border: [
      [110.5988, -7.8511],
      [110.5987, -7.8509],
      [110.5983, -7.8509],
      [110.5981, -7.8508],
      [110.5979, -7.8507],
      [110.5975, -7.8505],
      [110.5972, -7.8504],
      [110.597, -7.8504],
      [110.5968, -7.8502],
      [110.5965, -7.8502],
      [110.5964, -7.8499],
      [110.5962, -7.8499],
      [110.5958, -7.85],
      [110.5957, -7.85],
      [110.5954, -7.8499],
      [110.5953, -7.85],
      [110.5952, -7.8501],
      [110.5951, -7.8504],
      [110.5946, -7.8503],
      [110.5939, -7.8502],
      [110.5938, -7.8502],
      [110.5935, -7.8502],
      [110.5933, -7.8505],
      [110.593, -7.8505],
      [110.5926, -7.8506],
      [110.5923, -7.8507],
      [110.592, -7.8508],
      [110.5918, -7.8511],
      [110.5917, -7.8511],
      [110.5916, -7.8511],
      [110.5912, -7.8512],
      [110.591, -7.8509],
      [110.5908, -7.8508],
      [110.5903, -7.8507],
      [110.5897, -7.8507],
      [110.5889, -7.8505],
      [110.5887, -7.8505],
      [110.5876, -7.8502],
      [110.5865, -7.85],
      [110.5857, -7.8499],
      [110.5853, -7.8499],
      [110.5852, -7.8499],
      [110.5849, -7.8498],
      [110.5845, -7.8498],
      [110.5844, -7.8497],
      [110.5841, -7.8497],
      [110.584, -7.8496],
      [110.5839, -7.8495],
      [110.5837, -7.8494],
      [110.5834, -7.8492],
      [110.583, -7.8489],
      [110.5827, -7.8487],
      [110.5827, -7.8483],
      [110.5826, -7.8482],
      [110.582, -7.848],
      [110.5818, -7.8478],
      [110.5813, -7.847],
      [110.5811, -7.8469],
      [110.5809, -7.8467],
      [110.5806, -7.8464],
      [110.5806, -7.8462],
      [110.5807, -7.8459],
      [110.5806, -7.8454],
      [110.5799, -7.8444],
      [110.5798, -7.8441],
      [110.5785, -7.8424],
      [110.5775, -7.8413],
      [110.5765, -7.8403],
      [110.5758, -7.8399],
      [110.5745, -7.8387],
      [110.5729, -7.8377],
      [110.5717, -7.837],
      [110.5714, -7.8369],
      [110.5713, -7.8377],
      [110.5712, -7.8386],
      [110.5713, -7.8397],
      [110.5713, -7.8405],
      [110.5714, -7.8415],
      [110.571, -7.8426],
      [110.5708, -7.8435],
      [110.5706, -7.8447],
      [110.5698, -7.8455],
      [110.5692, -7.8462],
      [110.5689, -7.8469],
      [110.5687, -7.8485],
      [110.5687, -7.8498],
      [110.569, -7.8509],
      [110.5692, -7.8518],
      [110.5691, -7.8527],
      [110.5694, -7.8538],
      [110.5696, -7.8547],
      [110.5697, -7.8554],
      [110.5693, -7.8558],
      [110.5696, -7.8562],
      [110.5692, -7.8569],
      [110.5688, -7.8577],
      [110.5684, -7.8584],
      [110.5682, -7.8592],
      [110.5681, -7.8599],
      [110.568, -7.8603],
      [110.5677, -7.8606],
      [110.5675, -7.8609],
      [110.5674, -7.8614],
      [110.5671, -7.8615],
      [110.5667, -7.8617],
      [110.5667, -7.862],
      [110.5669, -7.8622],
      [110.5669, -7.8626],
      [110.5668, -7.863],
      [110.5668, -7.8635],
      [110.5666, -7.8639],
      [110.5666, -7.8642],
      [110.5666, -7.8646],
      [110.5667, -7.8648],
      [110.5666, -7.8652],
      [110.5664, -7.8653],
      [110.5662, -7.8655],
      [110.5662, -7.8659],
      [110.5665, -7.8666],
      [110.5668, -7.8673],
      [110.5672, -7.8682],
      [110.5672, -7.8684],
      [110.5673, -7.8687],
      [110.5677, -7.8691],
      [110.5674, -7.8695],
      [110.5672, -7.87],
      [110.567, -7.8704],
      [110.567, -7.8707],
      [110.5669, -7.871],
      [110.5667, -7.8716],
      [110.5665, -7.8723],
      [110.5661, -7.8725],
      [110.5659, -7.8729],
      [110.5658, -7.8734],
      [110.5657, -7.8736],
      [110.5655, -7.874],
      [110.565, -7.8746],
      [110.5648, -7.875],
      [110.5646, -7.8753],
      [110.5643, -7.8758],
      [110.5638, -7.877],
      [110.5633, -7.8783],
      [110.5631, -7.8795],
      [110.5629, -7.8805],
      [110.5628, -7.8806],
      [110.5627, -7.8821],
      [110.5626, -7.8829],
      [110.5628, -7.8838],
      [110.563, -7.8849],
      [110.5629, -7.8853],
      [110.5628, -7.8856],
      [110.5626, -7.887],
      [110.5629, -7.888],
      [110.5629, -7.8887],
      [110.5629, -7.8895],
      [110.5629, -7.89],
      [110.5627, -7.8906],
      [110.5625, -7.8915],
      [110.5622, -7.8922],
      [110.5622, -7.8926],
      [110.5622, -7.8932],
      [110.5622, -7.8935],
      [110.5628, -7.8928],
      [110.563, -7.8927],
      [110.5633, -7.8925],
      [110.5637, -7.8926],
      [110.5638, -7.8926],
      [110.5639, -7.8928],
      [110.5641, -7.8933],
      [110.5642, -7.8935],
      [110.5647, -7.8951],
      [110.5653, -7.8954],
      [110.5655, -7.8953],
      [110.5668, -7.8948],
      [110.5673, -7.8947],
      [110.5679, -7.8945],
      [110.5681, -7.8944],
      [110.5686, -7.8943],
      [110.5688, -7.8942],
      [110.5693, -7.8941],
      [110.5698, -7.8938],
      [110.5705, -7.8932],
      [110.5706, -7.8932],
      [110.5708, -7.8932],
      [110.5719, -7.8933],
      [110.5727, -7.8936],
      [110.5733, -7.8941],
      [110.5735, -7.8944],
      [110.5737, -7.8949],
      [110.5738, -7.8952],
      [110.5738, -7.8954],
      [110.5739, -7.8962],
      [110.5741, -7.8967],
      [110.5743, -7.8967],
      [110.5749, -7.8968],
      [110.5753, -7.8967],
      [110.5757, -7.8965],
      [110.5758, -7.8964],
      [110.5771, -7.8954],
      [110.5776, -7.8949],
      [110.5781, -7.895],
      [110.5786, -7.8952],
      [110.5787, -7.8953],
      [110.5792, -7.8959],
      [110.5794, -7.896],
      [110.5797, -7.8965],
      [110.5798, -7.8967],
      [110.58, -7.8974],
      [110.5799, -7.898],
      [110.5797, -7.8986],
      [110.5799, -7.8991],
      [110.5805, -7.8998],
      [110.581, -7.9003],
      [110.582, -7.9011],
      [110.5823, -7.9013],
      [110.5827, -7.9016],
      [110.5838, -7.9024],
      [110.5849, -7.9026],
      [110.5855, -7.9023],
      [110.5856, -7.9023],
      [110.5857, -7.9022],
      [110.586, -7.9019],
      [110.5862, -7.9016],
      [110.5866, -7.9009],
      [110.5871, -7.9004],
      [110.5873, -7.9],
      [110.5873, -7.8998],
      [110.5877, -7.8989],
      [110.588, -7.8983],
      [110.5882, -7.8978],
      [110.5885, -7.8974],
      [110.5886, -7.8971],
      [110.5885, -7.8966],
      [110.5886, -7.896],
      [110.5885, -7.8954],
      [110.5884, -7.895],
      [110.5885, -7.8945],
      [110.5886, -7.8941],
      [110.5892, -7.8934],
      [110.5896, -7.8931],
      [110.5897, -7.8918],
      [110.5899, -7.8906],
      [110.5902, -7.8898],
      [110.5904, -7.8892],
      [110.5904, -7.8883],
      [110.5902, -7.8875],
      [110.5901, -7.8869],
      [110.5905, -7.8863],
      [110.5908, -7.8858],
      [110.591, -7.8853],
      [110.5909, -7.8848],
      [110.5899, -7.884],
      [110.5893, -7.8834],
      [110.5889, -7.883],
      [110.5883, -7.8823],
      [110.5876, -7.8815],
      [110.5872, -7.8811],
      [110.5867, -7.8807],
      [110.5864, -7.8804],
      [110.5864, -7.8796],
      [110.5864, -7.8794],
      [110.5864, -7.8788],
      [110.5866, -7.8779],
      [110.5867, -7.8777],
      [110.587, -7.877],
      [110.5872, -7.8762],
      [110.5873, -7.8752],
      [110.5874, -7.8748],
      [110.5873, -7.8739],
      [110.5874, -7.8732],
      [110.5876, -7.8724],
      [110.5876, -7.8718],
      [110.5872, -7.8706],
      [110.5867, -7.8697],
      [110.5862, -7.8691],
      [110.5863, -7.8679],
      [110.5863, -7.8672],
      [110.5863, -7.8657],
      [110.5863, -7.8654],
      [110.5868, -7.8635],
      [110.5876, -7.8618],
      [110.5883, -7.8606],
      [110.589, -7.8597],
      [110.5894, -7.8592],
      [110.5905, -7.8586],
      [110.5907, -7.8585],
      [110.5915, -7.858],
      [110.5926, -7.8574],
      [110.5931, -7.8569],
      [110.5937, -7.8563],
      [110.5945, -7.8556],
      [110.5952, -7.8549],
      [110.596, -7.8541],
      [110.5976, -7.8527],
      [110.598, -7.8521],
      [110.5987, -7.8516],
      [110.5988, -7.8511]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GEDANGSARI',
    village: 'TEGALREJO',
    border: [
      [110.6551, -7.799],
      [110.6549, -7.7984],
      [110.6546, -7.7961],
      [110.6542, -7.7956],
      [110.6529, -7.794],
      [110.6517, -7.7933],
      [110.6516, -7.7933],
      [110.6511, -7.7932],
      [110.6498, -7.7933],
      [110.6482, -7.7941],
      [110.6481, -7.7942],
      [110.6458, -7.7957],
      [110.6433, -7.7971],
      [110.6419, -7.798],
      [110.6418, -7.798],
      [110.6417, -7.7981],
      [110.6416, -7.7981],
      [110.6415, -7.7983],
      [110.6412, -7.7985],
      [110.641, -7.7987],
      [110.6409, -7.7988],
      [110.6408, -7.7989],
      [110.6407, -7.7989],
      [110.6405, -7.799],
      [110.6403, -7.7992],
      [110.6402, -7.7992],
      [110.64, -7.7993],
      [110.6399, -7.7994],
      [110.6396, -7.7997],
      [110.6396, -7.7997],
      [110.6394, -7.7997],
      [110.6394, -7.7999],
      [110.6392, -7.8],
      [110.6389, -7.8],
      [110.6389, -7.8001],
      [110.6387, -7.8001],
      [110.6383, -7.8001],
      [110.6367, -7.8004],
      [110.6357, -7.8006],
      [110.6355, -7.8007],
      [110.6354, -7.8007],
      [110.6346, -7.8009],
      [110.6342, -7.8009],
      [110.6337, -7.801],
      [110.6335, -7.801],
      [110.6332, -7.8011],
      [110.6326, -7.8011],
      [110.6322, -7.8009],
      [110.6319, -7.8007],
      [110.6321, -7.8003],
      [110.6324, -7.8],
      [110.6328, -7.7995],
      [110.6331, -7.7991],
      [110.6334, -7.7986],
      [110.6333, -7.7981],
      [110.6329, -7.7978],
      [110.6326, -7.7976],
      [110.632, -7.7975],
      [110.6312, -7.7977],
      [110.6304, -7.798],
      [110.6297, -7.7984],
      [110.6295, -7.7988],
      [110.6297, -7.7992],
      [110.6295, -7.7996],
      [110.6292, -7.8],
      [110.6289, -7.8004],
      [110.6288, -7.8005],
      [110.6287, -7.8007],
      [110.6284, -7.8009],
      [110.6283, -7.801],
      [110.628, -7.8015],
      [110.6279, -7.8017],
      [110.6275, -7.8019],
      [110.6273, -7.8021],
      [110.6268, -7.8023],
      [110.6268, -7.8028],
      [110.6264, -7.8031],
      [110.6258, -7.8032],
      [110.6256, -7.8033],
      [110.6253, -7.8034],
      [110.625, -7.8033],
      [110.6249, -7.8033],
      [110.6247, -7.803],
      [110.6247, -7.8028],
      [110.6246, -7.8025],
      [110.6246, -7.8024],
      [110.6245, -7.802],
      [110.6244, -7.8018],
      [110.6242, -7.8016],
      [110.6241, -7.8015],
      [110.6238, -7.8011],
      [110.6236, -7.8007],
      [110.6235, -7.8005],
      [110.6233, -7.8002],
      [110.6225, -7.8],
      [110.6222, -7.7999],
      [110.6221, -7.7997],
      [110.6218, -7.7994],
      [110.6214, -7.7993],
      [110.621, -7.7992],
      [110.6206, -7.7993],
      [110.62, -7.799],
      [110.6195, -7.7988],
      [110.6192, -7.7988],
      [110.6191, -7.799],
      [110.6191, -7.7991],
      [110.6191, -7.8002],
      [110.6191, -7.8012],
      [110.6188, -7.802],
      [110.6185, -7.8025],
      [110.6184, -7.8029],
      [110.6184, -7.8033],
      [110.6184, -7.8035],
      [110.619, -7.8039],
      [110.6192, -7.804],
      [110.6194, -7.8041],
      [110.6197, -7.8045],
      [110.6198, -7.8049],
      [110.6198, -7.8054],
      [110.6199, -7.806],
      [110.6197, -7.8063],
      [110.6195, -7.8065],
      [110.6192, -7.8065],
      [110.6188, -7.8065],
      [110.6186, -7.8068],
      [110.6184, -7.8071],
      [110.6185, -7.8074],
      [110.6185, -7.8078],
      [110.6188, -7.8079],
      [110.619, -7.8084],
      [110.6191, -7.8086],
      [110.6197, -7.8083],
      [110.6201, -7.8083],
      [110.6208, -7.8085],
      [110.6214, -7.8083],
      [110.6218, -7.8086],
      [110.622, -7.8088],
      [110.622, -7.8096],
      [110.6223, -7.8095],
      [110.6232, -7.8094],
      [110.6238, -7.8089],
      [110.6248, -7.8078],
      [110.6255, -7.808],
      [110.6256, -7.8095],
      [110.6259, -7.8099],
      [110.6263, -7.8099],
      [110.6268, -7.8099],
      [110.6272, -7.8099],
      [110.6276, -7.8104],
      [110.6278, -7.8109],
      [110.6278, -7.8113],
      [110.6276, -7.8115],
      [110.6274, -7.8116],
      [110.6273, -7.812],
      [110.6273, -7.8128],
      [110.6274, -7.8136],
      [110.6276, -7.814],
      [110.628, -7.8146],
      [110.6285, -7.815],
      [110.6292, -7.8149],
      [110.6295, -7.8146],
      [110.6302, -7.8145],
      [110.6303, -7.8157],
      [110.6306, -7.8164],
      [110.6312, -7.8173],
      [110.6316, -7.8182],
      [110.6317, -7.8189],
      [110.6317, -7.8201],
      [110.6316, -7.8205],
      [110.6308, -7.8219],
      [110.6307, -7.8228],
      [110.6312, -7.8239],
      [110.6324, -7.8247],
      [110.6337, -7.8253],
      [110.6347, -7.8255],
      [110.6357, -7.8261],
      [110.6357, -7.8266],
      [110.6358, -7.8271],
      [110.6359, -7.8284],
      [110.6359, -7.8301],
      [110.636, -7.8307],
      [110.6361, -7.8307],
      [110.6385, -7.8303],
      [110.6405, -7.8298],
      [110.6423, -7.8287],
      [110.6444, -7.8272],
      [110.6453, -7.8266],
      [110.6463, -7.8255],
      [110.647, -7.8245],
      [110.647, -7.8244],
      [110.6474, -7.8236],
      [110.6475, -7.823],
      [110.6472, -7.8219],
      [110.6473, -7.8211],
      [110.6475, -7.8208],
      [110.6479, -7.8205],
      [110.6483, -7.8206],
      [110.6488, -7.8207],
      [110.6491, -7.8215],
      [110.6495, -7.822],
      [110.6496, -7.8221],
      [110.65, -7.8225],
      [110.6504, -7.8226],
      [110.6509, -7.8225],
      [110.6515, -7.8219],
      [110.6527, -7.8211],
      [110.6533, -7.8206],
      [110.6535, -7.8196],
      [110.6535, -7.8195],
      [110.6538, -7.8187],
      [110.6546, -7.817],
      [110.6557, -7.8149],
      [110.6568, -7.8129],
      [110.6576, -7.8113],
      [110.6578, -7.8107],
      [110.6583, -7.8097],
      [110.6585, -7.8091],
      [110.6588, -7.8084],
      [110.6589, -7.8079],
      [110.6588, -7.8071],
      [110.6581, -7.8057],
      [110.658, -7.8056],
      [110.6577, -7.805],
      [110.6577, -7.8048],
      [110.6576, -7.8047],
      [110.6571, -7.8045],
      [110.6564, -7.8038],
      [110.6554, -7.8023],
      [110.6551, -7.8014],
      [110.6551, -7.799]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SAPTOSARI',
    village: 'KRAMBILSAWIT',
    border: [
      [110.4836, -8.0602],
      [110.4832, -8.0602],
      [110.4824, -8.0599],
      [110.4818, -8.0595],
      [110.4812, -8.0596],
      [110.4809, -8.06],
      [110.4807, -8.0605],
      [110.4803, -8.0609],
      [110.4796, -8.0611],
      [110.479, -8.061],
      [110.4786, -8.0612],
      [110.4774, -8.0617],
      [110.4763, -8.062],
      [110.4757, -8.0618],
      [110.4747, -8.0612],
      [110.4737, -8.0605],
      [110.4727, -8.0605],
      [110.4707, -8.0601],
      [110.4693, -8.06],
      [110.4691, -8.0606],
      [110.4689, -8.0612],
      [110.469, -8.0617],
      [110.4692, -8.0626],
      [110.469, -8.0631],
      [110.4688, -8.0634],
      [110.4682, -8.0636],
      [110.4672, -8.0634],
      [110.4661, -8.0632],
      [110.4652, -8.0631],
      [110.4642, -8.063],
      [110.4634, -8.063],
      [110.4625, -8.063],
      [110.4618, -8.063],
      [110.461, -8.0632],
      [110.4597, -8.0638],
      [110.4593, -8.0646],
      [110.4596, -8.0653],
      [110.46, -8.0663],
      [110.4603, -8.0669],
      [110.4602, -8.0672],
      [110.4601, -8.0679],
      [110.4598, -8.0686],
      [110.4596, -8.0693],
      [110.4595, -8.07],
      [110.4599, -8.0707],
      [110.4602, -8.0712],
      [110.4605, -8.0717],
      [110.4607, -8.0727],
      [110.4609, -8.0744],
      [110.4609, -8.0756],
      [110.4609, -8.0763],
      [110.4611, -8.0775],
      [110.4611, -8.0785],
      [110.4612, -8.0793],
      [110.4613, -8.0801],
      [110.4612, -8.081],
      [110.4612, -8.0823],
      [110.4612, -8.0833],
      [110.4611, -8.0842],
      [110.4609, -8.0845],
      [110.4607, -8.085],
      [110.4608, -8.0857],
      [110.4611, -8.0862],
      [110.4613, -8.0868],
      [110.4614, -8.0874],
      [110.4614, -8.0881],
      [110.4617, -8.089],
      [110.4619, -8.0896],
      [110.462, -8.0897],
      [110.4621, -8.0898],
      [110.4625, -8.0905],
      [110.4629, -8.0913],
      [110.463, -8.0919],
      [110.4629, -8.0924],
      [110.4627, -8.0931],
      [110.4628, -8.0939],
      [110.4628, -8.0946],
      [110.4632, -8.0954],
      [110.4639, -8.0961],
      [110.4647, -8.0971],
      [110.4656, -8.0979],
      [110.4664, -8.0987],
      [110.4668, -8.0994],
      [110.467, -8.1001],
      [110.4669, -8.1009],
      [110.4668, -8.1017],
      [110.4668, -8.1025],
      [110.4664, -8.1036],
      [110.4662, -8.1043],
      [110.4661, -8.1051],
      [110.4662, -8.1055],
      [110.4668, -8.1059],
      [110.4675, -8.1064],
      [110.468, -8.1068],
      [110.4684, -8.1075],
      [110.4687, -8.1081],
      [110.4689, -8.108],
      [110.4691, -8.1081],
      [110.4694, -8.1084],
      [110.4697, -8.1084],
      [110.4699, -8.1085],
      [110.47, -8.1086],
      [110.47, -8.1088],
      [110.47, -8.109],
      [110.4701, -8.109],
      [110.4703, -8.1091],
      [110.4706, -8.1092],
      [110.4709, -8.1093],
      [110.4711, -8.1092],
      [110.4714, -8.1092],
      [110.4716, -8.1093],
      [110.4718, -8.1095],
      [110.4718, -8.1098],
      [110.4717, -8.11],
      [110.4716, -8.1101],
      [110.4715, -8.1103],
      [110.4715, -8.1104],
      [110.4716, -8.1105],
      [110.4718, -8.1105],
      [110.4719, -8.1106],
      [110.4721, -8.1107],
      [110.4724, -8.1107],
      [110.4728, -8.1105],
      [110.4732, -8.1104],
      [110.4735, -8.1102],
      [110.4736, -8.1101],
      [110.4741, -8.1103],
      [110.4743, -8.1104],
      [110.4747, -8.1104],
      [110.4751, -8.1103],
      [110.4754, -8.1102],
      [110.4756, -8.1102],
      [110.4759, -8.1101],
      [110.4762, -8.1099],
      [110.4763, -8.1099],
      [110.4769, -8.1103],
      [110.4774, -8.1107],
      [110.4776, -8.1109],
      [110.4779, -8.111],
      [110.4782, -8.111],
      [110.4784, -8.111],
      [110.4786, -8.111],
      [110.4788, -8.1109],
      [110.479, -8.1107],
      [110.4791, -8.1108],
      [110.4793, -8.1108],
      [110.4794, -8.1111],
      [110.4795, -8.1112],
      [110.4798, -8.1114],
      [110.4801, -8.1117],
      [110.4804, -8.1118],
      [110.4806, -8.1119],
      [110.481, -8.1119],
      [110.4811, -8.112],
      [110.4815, -8.1123],
      [110.4817, -8.1121],
      [110.482, -8.1118],
      [110.4822, -8.1117],
      [110.4825, -8.1116],
      [110.4828, -8.1117],
      [110.483, -8.1118],
      [110.4832, -8.1119],
      [110.4833, -8.112],
      [110.4835, -8.1119],
      [110.4836, -8.1118],
      [110.4839, -8.1119],
      [110.4841, -8.112],
      [110.4843, -8.1121],
      [110.4846, -8.1121],
      [110.4849, -8.112],
      [110.4853, -8.112],
      [110.4857, -8.1121],
      [110.4858, -8.1122],
      [110.4858, -8.1125],
      [110.4857, -8.113],
      [110.4856, -8.1133],
      [110.4857, -8.1135],
      [110.4859, -8.1136],
      [110.4862, -8.1135],
      [110.4865, -8.1135],
      [110.4869, -8.1135],
      [110.4874, -8.1134],
      [110.4878, -8.1134],
      [110.4882, -8.1135],
      [110.4887, -8.1137],
      [110.4891, -8.1139],
      [110.4893, -8.1141],
      [110.4893, -8.1145],
      [110.4893, -8.1151],
      [110.4894, -8.1153],
      [110.4896, -8.1157],
      [110.4897, -8.1159],
      [110.4901, -8.1162],
      [110.4902, -8.1162],
      [110.4904, -8.1161],
      [110.4908, -8.116],
      [110.4911, -8.116],
      [110.4912, -8.1161],
      [110.4913, -8.1164],
      [110.4913, -8.1166],
      [110.4911, -8.1168],
      [110.4909, -8.117],
      [110.491, -8.1171],
      [110.4912, -8.1172],
      [110.4913, -8.1172],
      [110.4915, -8.1171],
      [110.4917, -8.117],
      [110.4919, -8.1169],
      [110.4922, -8.1169],
      [110.4925, -8.1169],
      [110.4928, -8.117],
      [110.4931, -8.1171],
      [110.4932, -8.1172],
      [110.4934, -8.1171],
      [110.4935, -8.1169],
      [110.4936, -8.1169],
      [110.4937, -8.1171],
      [110.4936, -8.1173],
      [110.4934, -8.1174],
      [110.4934, -8.1176],
      [110.4934, -8.1177],
      [110.4936, -8.1177],
      [110.4937, -8.1176],
      [110.494, -8.1173],
      [110.4941, -8.1172],
      [110.4943, -8.1172],
      [110.4947, -8.1169],
      [110.4949, -8.1168],
      [110.4951, -8.1166],
      [110.4954, -8.1166],
      [110.4959, -8.1166],
      [110.4962, -8.1167],
      [110.4963, -8.1167],
      [110.4964, -8.1172],
      [110.4967, -8.1175],
      [110.4968, -8.1178],
      [110.4967, -8.118],
      [110.497, -8.118],
      [110.4973, -8.118],
      [110.4977, -8.1181],
      [110.4981, -8.1182],
      [110.4984, -8.1183],
      [110.4986, -8.1183],
      [110.4987, -8.1182],
      [110.4988, -8.1181],
      [110.4991, -8.1181],
      [110.4993, -8.1181],
      [110.4997, -8.1184],
      [110.4999, -8.1186],
      [110.5001, -8.1187],
      [110.5003, -8.1188],
      [110.5005, -8.119],
      [110.5007, -8.119],
      [110.5011, -8.119],
      [110.5014, -8.1189],
      [110.5016, -8.1188],
      [110.5017, -8.1187],
      [110.502, -8.1186],
      [110.5023, -8.1186],
      [110.5026, -8.1186],
      [110.5029, -8.1186],
      [110.503, -8.1187],
      [110.5031, -8.1184],
      [110.5033, -8.1174],
      [110.5034, -8.1164],
      [110.5035, -8.1153],
      [110.5036, -8.1141],
      [110.5041, -8.1135],
      [110.5048, -8.1128],
      [110.5055, -8.1124],
      [110.5059, -8.1119],
      [110.5061, -8.1116],
      [110.5061, -8.1112],
      [110.5059, -8.1106],
      [110.5055, -8.1098],
      [110.5053, -8.1095],
      [110.5055, -8.1091],
      [110.5057, -8.1085],
      [110.5057, -8.108],
      [110.5049, -8.1063],
      [110.5035, -8.1045],
      [110.5027, -8.1036],
      [110.502, -8.1027],
      [110.5008, -8.1016],
      [110.4999, -8.1009],
      [110.4992, -8.1008],
      [110.4982, -8.1008],
      [110.4972, -8.1009],
      [110.4953, -8.1014],
      [110.4941, -8.1016],
      [110.4932, -8.1014],
      [110.4922, -8.1009],
      [110.4913, -8.1005],
      [110.4907, -8.1004],
      [110.4893, -8.1005],
      [110.488, -8.1002],
      [110.487, -8.0999],
      [110.4859, -8.0993],
      [110.4847, -8.0983],
      [110.4842, -8.0974],
      [110.484, -8.0964],
      [110.4842, -8.0955],
      [110.4845, -8.0948],
      [110.4847, -8.0943],
      [110.4845, -8.0935],
      [110.4846, -8.0927],
      [110.4849, -8.0917],
      [110.4854, -8.091],
      [110.4858, -8.0898],
      [110.486, -8.0894],
      [110.4865, -8.0893],
      [110.487, -8.0891],
      [110.4874, -8.0893],
      [110.4879, -8.09],
      [110.488, -8.0905],
      [110.4876, -8.0917],
      [110.4873, -8.093],
      [110.4873, -8.0938],
      [110.4875, -8.0946],
      [110.488, -8.095],
      [110.4887, -8.0953],
      [110.4894, -8.0955],
      [110.49, -8.0957],
      [110.4905, -8.0957],
      [110.491, -8.0956],
      [110.4914, -8.0955],
      [110.4917, -8.0952],
      [110.4917, -8.0948],
      [110.4911, -8.0942],
      [110.4907, -8.0938],
      [110.4899, -8.0934],
      [110.4892, -8.0929],
      [110.4888, -8.0924],
      [110.4888, -8.0921],
      [110.4888, -8.0915],
      [110.4891, -8.0911],
      [110.4894, -8.0905],
      [110.4892, -8.09],
      [110.4887, -8.0898],
      [110.4881, -8.089],
      [110.488, -8.0882],
      [110.4881, -8.0873],
      [110.4881, -8.0864],
      [110.4884, -8.0858],
      [110.4885, -8.0856],
      [110.4887, -8.0852],
      [110.489, -8.0847],
      [110.4898, -8.0837],
      [110.4908, -8.0824],
      [110.4918, -8.0807],
      [110.4921, -8.0799],
      [110.4929, -8.079],
      [110.4933, -8.0781],
      [110.4941, -8.0767],
      [110.4944, -8.0762],
      [110.4949, -8.0752],
      [110.4954, -8.0744],
      [110.4947, -8.0738],
      [110.4931, -8.0718],
      [110.4927, -8.0712],
      [110.4921, -8.0705],
      [110.4918, -8.0701],
      [110.4906, -8.0695],
      [110.4899, -8.0695],
      [110.4889, -8.0694],
      [110.4879, -8.0695],
      [110.4873, -8.0696],
      [110.4865, -8.0694],
      [110.4856, -8.0689],
      [110.4853, -8.0685],
      [110.4852, -8.068],
      [110.4854, -8.0661],
      [110.4856, -8.0647],
      [110.4854, -8.0639],
      [110.485, -8.0626],
      [110.4848, -8.0619],
      [110.4844, -8.0611],
      [110.4836, -8.0602]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SAPTOSARI',
    village: 'NGLORO',
    border: [
      [110.5055, -8.0717],
      [110.5055, -8.0712],
      [110.5054, -8.0704],
      [110.5056, -8.069],
      [110.5056, -8.0683],
      [110.5051, -8.0666],
      [110.505, -8.0661],
      [110.5052, -8.0655],
      [110.5053, -8.0648],
      [110.5052, -8.0641],
      [110.5049, -8.0632],
      [110.5041, -8.0623],
      [110.5034, -8.0618],
      [110.503, -8.0612],
      [110.5029, -8.0606],
      [110.5032, -8.0599],
      [110.5042, -8.0589],
      [110.5046, -8.0582],
      [110.5047, -8.0576],
      [110.5046, -8.0571],
      [110.5045, -8.0569],
      [110.5039, -8.0559],
      [110.5037, -8.0557],
      [110.5033, -8.0552],
      [110.5027, -8.055],
      [110.5021, -8.0548],
      [110.5012, -8.0547],
      [110.5009, -8.0544],
      [110.5009, -8.054],
      [110.5013, -8.0536],
      [110.5017, -8.0531],
      [110.5019, -8.0525],
      [110.5016, -8.0517],
      [110.5016, -8.0513],
      [110.5017, -8.0507],
      [110.5017, -8.0505],
      [110.5016, -8.0503],
      [110.5009, -8.0503],
      [110.5005, -8.0497],
      [110.4999, -8.0493],
      [110.4995, -8.0491],
      [110.4988, -8.0491],
      [110.4974, -8.0493],
      [110.497, -8.0493],
      [110.4956, -8.0493],
      [110.4947, -8.049],
      [110.4935, -8.0485],
      [110.4923, -8.0482],
      [110.491, -8.0482],
      [110.4903, -8.0482],
      [110.4901, -8.0483],
      [110.4888, -8.0487],
      [110.4873, -8.0494],
      [110.4859, -8.0504],
      [110.4847, -8.0513],
      [110.4839, -8.0516],
      [110.4839, -8.0524],
      [110.484, -8.0529],
      [110.4842, -8.0541],
      [110.4842, -8.0553],
      [110.4838, -8.0563],
      [110.4833, -8.0567],
      [110.4829, -8.0576],
      [110.4828, -8.0585],
      [110.483, -8.0594],
      [110.4836, -8.0602],
      [110.4844, -8.0611],
      [110.4848, -8.0619],
      [110.485, -8.0626],
      [110.4854, -8.0639],
      [110.4856, -8.0647],
      [110.4854, -8.0661],
      [110.4852, -8.068],
      [110.4853, -8.0685],
      [110.4856, -8.0689],
      [110.4865, -8.0694],
      [110.4873, -8.0696],
      [110.4879, -8.0695],
      [110.4889, -8.0694],
      [110.4899, -8.0695],
      [110.4906, -8.0695],
      [110.4918, -8.0701],
      [110.4921, -8.0705],
      [110.4927, -8.0712],
      [110.4931, -8.0718],
      [110.4947, -8.0738],
      [110.4954, -8.0744],
      [110.4961, -8.0747],
      [110.4967, -8.0745],
      [110.4974, -8.0745],
      [110.4979, -8.0744],
      [110.4984, -8.0745],
      [110.4992, -8.0749],
      [110.5008, -8.0755],
      [110.502, -8.0756],
      [110.5031, -8.0757],
      [110.5043, -8.0756],
      [110.5051, -8.0747],
      [110.5054, -8.0742],
      [110.5057, -8.0735],
      [110.5056, -8.0725],
      [110.5055, -8.0717]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SAPTOSARI',
    village: 'JETIS',
    border: [
      [110.5071, -8.0349],
      [110.5069, -8.0341],
      [110.5063, -8.0332],
      [110.506, -8.0328],
      [110.5056, -8.0316],
      [110.5053, -8.0306],
      [110.5043, -8.0294],
      [110.5031, -8.0279],
      [110.5027, -8.0259],
      [110.5021, -8.0243],
      [110.5013, -8.0227],
      [110.5006, -8.0214],
      [110.5002, -8.0207],
      [110.4996, -8.0201],
      [110.4991, -8.0197],
      [110.4979, -8.0189],
      [110.4963, -8.0183],
      [110.4952, -8.0181],
      [110.4936, -8.0178],
      [110.4926, -8.0171],
      [110.4916, -8.0166],
      [110.4907, -8.0162],
      [110.4909, -8.0165],
      [110.4913, -8.0174],
      [110.4914, -8.0178],
      [110.4912, -8.0184],
      [110.4907, -8.0187],
      [110.4894, -8.0199],
      [110.4883, -8.0206],
      [110.4877, -8.021],
      [110.4872, -8.0216],
      [110.4867, -8.0223],
      [110.486, -8.0231],
      [110.4853, -8.0233],
      [110.4845, -8.0238],
      [110.4824, -8.0251],
      [110.4801, -8.0261],
      [110.4789, -8.0269],
      [110.4786, -8.027],
      [110.4779, -8.0277],
      [110.4768, -8.0286],
      [110.476, -8.0297],
      [110.4757, -8.0304],
      [110.4758, -8.0311],
      [110.4762, -8.0328],
      [110.477, -8.0344],
      [110.4779, -8.0362],
      [110.4783, -8.0372],
      [110.4783, -8.0379],
      [110.4784, -8.0389],
      [110.4785, -8.0406],
      [110.4784, -8.042],
      [110.4773, -8.0436],
      [110.4756, -8.0461],
      [110.4752, -8.0467],
      [110.4748, -8.0481],
      [110.4745, -8.0488],
      [110.4739, -8.0499],
      [110.4736, -8.0507],
      [110.4731, -8.051],
      [110.4721, -8.0525],
      [110.471, -8.0539],
      [110.4702, -8.0548],
      [110.4695, -8.056],
      [110.4691, -8.0573],
      [110.4691, -8.0579],
      [110.4694, -8.0586],
      [110.4695, -8.0592],
      [110.4694, -8.0599],
      [110.4693, -8.06],
      [110.4707, -8.0601],
      [110.4727, -8.0605],
      [110.4737, -8.0605],
      [110.4747, -8.0612],
      [110.4757, -8.0618],
      [110.4763, -8.062],
      [110.4774, -8.0617],
      [110.4786, -8.0612],
      [110.479, -8.061],
      [110.4796, -8.0611],
      [110.4803, -8.0609],
      [110.4807, -8.0605],
      [110.4809, -8.06],
      [110.4812, -8.0596],
      [110.4818, -8.0595],
      [110.4824, -8.0599],
      [110.4832, -8.0602],
      [110.4836, -8.0602],
      [110.483, -8.0594],
      [110.4828, -8.0585],
      [110.4829, -8.0576],
      [110.4833, -8.0567],
      [110.4838, -8.0563],
      [110.4842, -8.0553],
      [110.4842, -8.0541],
      [110.484, -8.0529],
      [110.4839, -8.0524],
      [110.4839, -8.0516],
      [110.4847, -8.0513],
      [110.4859, -8.0504],
      [110.4873, -8.0494],
      [110.4888, -8.0487],
      [110.4901, -8.0483],
      [110.4903, -8.0482],
      [110.4903, -8.0468],
      [110.4904, -8.0456],
      [110.4917, -8.044],
      [110.4924, -8.0429],
      [110.493, -8.0424],
      [110.4938, -8.041],
      [110.4943, -8.0395],
      [110.4947, -8.0376],
      [110.4949, -8.0368],
      [110.4953, -8.0365],
      [110.496, -8.0364],
      [110.4966, -8.0364],
      [110.497, -8.0368],
      [110.4995, -8.0378],
      [110.5007, -8.0384],
      [110.5013, -8.0387],
      [110.5023, -8.0399],
      [110.503, -8.0403],
      [110.5039, -8.0412],
      [110.5037, -8.0419],
      [110.5033, -8.0437],
      [110.5025, -8.0458],
      [110.5025, -8.046],
      [110.5031, -8.0471],
      [110.5043, -8.0478],
      [110.5053, -8.0483],
      [110.5061, -8.0483],
      [110.5068, -8.0465],
      [110.5052, -8.0461],
      [110.5054, -8.0455],
      [110.5058, -8.0447],
      [110.5061, -8.0445],
      [110.5066, -8.0435],
      [110.5076, -8.0427],
      [110.508, -8.0424],
      [110.5081, -8.0415],
      [110.5086, -8.0408],
      [110.5086, -8.0404],
      [110.5079, -8.0396],
      [110.5072, -8.0385],
      [110.507, -8.0379],
      [110.507, -8.0367],
      [110.507, -8.0353],
      [110.5071, -8.0349]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SAPTOSARI',
    village: 'KEPEK',
    border: [
      [110.523, -8.0549],
      [110.5226, -8.0549],
      [110.5215, -8.0546],
      [110.5209, -8.0543],
      [110.5204, -8.0536],
      [110.5201, -8.0525],
      [110.5197, -8.052],
      [110.5193, -8.0513],
      [110.5188, -8.0511],
      [110.5184, -8.0506],
      [110.5178, -8.0504],
      [110.5178, -8.0503],
      [110.5175, -8.0501],
      [110.5173, -8.0494],
      [110.5173, -8.0485],
      [110.5173, -8.0479],
      [110.5174, -8.0468],
      [110.5172, -8.0457],
      [110.517, -8.0442],
      [110.5168, -8.0433],
      [110.5168, -8.0423],
      [110.5165, -8.0416],
      [110.5163, -8.0411],
      [110.5163, -8.0405],
      [110.5165, -8.0401],
      [110.5167, -8.0396],
      [110.5165, -8.0389],
      [110.5162, -8.0384],
      [110.5157, -8.038],
      [110.5153, -8.0375],
      [110.5151, -8.0371],
      [110.5146, -8.0368],
      [110.514, -8.0362],
      [110.5144, -8.0359],
      [110.5145, -8.0352],
      [110.5147, -8.0349],
      [110.5146, -8.0342],
      [110.5145, -8.0333],
      [110.5144, -8.0329],
      [110.5141, -8.0331],
      [110.5132, -8.0331],
      [110.5121, -8.0332],
      [110.5114, -8.0334],
      [110.5107, -8.0337],
      [110.5095, -8.0344],
      [110.5084, -8.0349],
      [110.5074, -8.0349],
      [110.5071, -8.0349],
      [110.507, -8.0353],
      [110.507, -8.0367],
      [110.507, -8.0379],
      [110.5072, -8.0385],
      [110.5079, -8.0396],
      [110.5086, -8.0404],
      [110.5086, -8.0408],
      [110.5081, -8.0415],
      [110.508, -8.0424],
      [110.5076, -8.0427],
      [110.5066, -8.0435],
      [110.5061, -8.0445],
      [110.5058, -8.0447],
      [110.5054, -8.0455],
      [110.5052, -8.0461],
      [110.5068, -8.0465],
      [110.5061, -8.0483],
      [110.5053, -8.0483],
      [110.5043, -8.0478],
      [110.5031, -8.0471],
      [110.5025, -8.046],
      [110.5025, -8.0458],
      [110.5033, -8.0437],
      [110.5037, -8.0419],
      [110.5039, -8.0412],
      [110.503, -8.0403],
      [110.5023, -8.0399],
      [110.5013, -8.0387],
      [110.5007, -8.0384],
      [110.4995, -8.0378],
      [110.497, -8.0368],
      [110.4966, -8.0364],
      [110.496, -8.0364],
      [110.4953, -8.0365],
      [110.4949, -8.0368],
      [110.4947, -8.0376],
      [110.4943, -8.0395],
      [110.4938, -8.041],
      [110.493, -8.0424],
      [110.4924, -8.0429],
      [110.4917, -8.044],
      [110.4904, -8.0456],
      [110.4903, -8.0468],
      [110.4903, -8.0482],
      [110.491, -8.0482],
      [110.4923, -8.0482],
      [110.4935, -8.0485],
      [110.4947, -8.049],
      [110.4956, -8.0493],
      [110.497, -8.0493],
      [110.4974, -8.0493],
      [110.4988, -8.0491],
      [110.4995, -8.0491],
      [110.4999, -8.0493],
      [110.5005, -8.0497],
      [110.5009, -8.0503],
      [110.5016, -8.0503],
      [110.5017, -8.0505],
      [110.5017, -8.0507],
      [110.5016, -8.0513],
      [110.5016, -8.0517],
      [110.5019, -8.0525],
      [110.5017, -8.0531],
      [110.5013, -8.0536],
      [110.5009, -8.054],
      [110.5009, -8.0544],
      [110.5012, -8.0547],
      [110.5021, -8.0548],
      [110.5027, -8.055],
      [110.5033, -8.0552],
      [110.5037, -8.0557],
      [110.5039, -8.0559],
      [110.5045, -8.0569],
      [110.5046, -8.0571],
      [110.5047, -8.0576],
      [110.5046, -8.0582],
      [110.5042, -8.0589],
      [110.5032, -8.0599],
      [110.5029, -8.0606],
      [110.503, -8.0612],
      [110.5034, -8.0618],
      [110.5041, -8.0623],
      [110.5049, -8.0632],
      [110.5052, -8.0641],
      [110.5053, -8.0648],
      [110.5052, -8.0655],
      [110.505, -8.0661],
      [110.5051, -8.0666],
      [110.5056, -8.0683],
      [110.5056, -8.069],
      [110.5054, -8.0704],
      [110.5055, -8.0712],
      [110.5055, -8.0717],
      [110.5058, -8.0716],
      [110.5064, -8.0712],
      [110.507, -8.0708],
      [110.5084, -8.0702],
      [110.5087, -8.0701],
      [110.5092, -8.0695],
      [110.51, -8.0689],
      [110.5107, -8.0686],
      [110.5119, -8.0686],
      [110.5128, -8.0683],
      [110.5138, -8.0678],
      [110.5148, -8.0675],
      [110.517, -8.0672],
      [110.518, -8.067],
      [110.5188, -8.0666],
      [110.52, -8.0653],
      [110.521, -8.0643],
      [110.522, -8.0637],
      [110.5228, -8.0633],
      [110.5243, -8.0635],
      [110.525, -8.0641],
      [110.5261, -8.0656],
      [110.5275, -8.0667],
      [110.5281, -8.0669],
      [110.5284, -8.067],
      [110.5288, -8.0667],
      [110.5292, -8.0663],
      [110.5294, -8.0655],
      [110.5296, -8.0652],
      [110.5299, -8.0643],
      [110.5299, -8.0636],
      [110.5297, -8.0628],
      [110.5294, -8.0621],
      [110.5294, -8.0618],
      [110.5292, -8.0613],
      [110.529, -8.0608],
      [110.5291, -8.0605],
      [110.5294, -8.0603],
      [110.5295, -8.06],
      [110.5293, -8.0597],
      [110.5288, -8.0592],
      [110.5275, -8.0582],
      [110.5264, -8.0578],
      [110.5253, -8.0577],
      [110.5249, -8.0573],
      [110.5247, -8.0569],
      [110.5241, -8.0568],
      [110.5238, -8.0563],
      [110.5235, -8.0561],
      [110.5233, -8.056],
      [110.523, -8.0551],
      [110.523, -8.0549]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SAPTOSARI',
    village: 'KANIGORO',
    border: [
      [110.5466, -8.118],
      [110.5464, -8.1173],
      [110.5461, -8.1169],
      [110.5459, -8.1158],
      [110.5456, -8.1147],
      [110.5451, -8.1138],
      [110.5451, -8.1129],
      [110.5449, -8.1108],
      [110.5446, -8.1099],
      [110.544, -8.1086],
      [110.5438, -8.1079],
      [110.5437, -8.1076],
      [110.5434, -8.1067],
      [110.543, -8.1063],
      [110.5427, -8.1059],
      [110.5407, -8.1036],
      [110.5387, -8.1015],
      [110.5379, -8.1004],
      [110.5375, -8.0996],
      [110.5373, -8.0983],
      [110.5374, -8.0965],
      [110.5377, -8.0954],
      [110.5382, -8.0943],
      [110.5384, -8.0936],
      [110.5385, -8.093],
      [110.5382, -8.0922],
      [110.5375, -8.0915],
      [110.5362, -8.0907],
      [110.5344, -8.0903],
      [110.5336, -8.0901],
      [110.5332, -8.0895],
      [110.533, -8.0885],
      [110.533, -8.087],
      [110.5331, -8.0845],
      [110.533, -8.0824],
      [110.5326, -8.0812],
      [110.5321, -8.0794],
      [110.5316, -8.0784],
      [110.5308, -8.0775],
      [110.53, -8.077],
      [110.5292, -8.0764],
      [110.5287, -8.0759],
      [110.5281, -8.0758],
      [110.5266, -8.0756],
      [110.5261, -8.0757],
      [110.5251, -8.0762],
      [110.5245, -8.0763],
      [110.5234, -8.0757],
      [110.5223, -8.0746],
      [110.5209, -8.0732],
      [110.5201, -8.072],
      [110.52, -8.0712],
      [110.52, -8.0711],
      [110.5201, -8.0705],
      [110.5207, -8.0688],
      [110.5212, -8.0667],
      [110.5212, -8.0651],
      [110.521, -8.0643],
      [110.52, -8.0653],
      [110.5188, -8.0666],
      [110.518, -8.067],
      [110.517, -8.0672],
      [110.5148, -8.0675],
      [110.5138, -8.0678],
      [110.5128, -8.0683],
      [110.5119, -8.0686],
      [110.5107, -8.0686],
      [110.51, -8.0689],
      [110.5092, -8.0695],
      [110.5087, -8.0701],
      [110.5084, -8.0702],
      [110.507, -8.0708],
      [110.5064, -8.0712],
      [110.5058, -8.0716],
      [110.5055, -8.0717],
      [110.5056, -8.0725],
      [110.5057, -8.0735],
      [110.5054, -8.0742],
      [110.5051, -8.0747],
      [110.5043, -8.0756],
      [110.5031, -8.0757],
      [110.502, -8.0756],
      [110.5008, -8.0755],
      [110.4992, -8.0749],
      [110.4984, -8.0745],
      [110.4979, -8.0744],
      [110.4974, -8.0745],
      [110.4967, -8.0745],
      [110.4961, -8.0747],
      [110.4954, -8.0744],
      [110.4949, -8.0752],
      [110.4944, -8.0762],
      [110.4941, -8.0767],
      [110.4933, -8.0781],
      [110.4929, -8.079],
      [110.4921, -8.0799],
      [110.4918, -8.0807],
      [110.4908, -8.0824],
      [110.4898, -8.0837],
      [110.489, -8.0847],
      [110.4887, -8.0852],
      [110.4885, -8.0856],
      [110.4884, -8.0858],
      [110.4881, -8.0864],
      [110.4881, -8.0873],
      [110.488, -8.0882],
      [110.4881, -8.089],
      [110.4887, -8.0898],
      [110.4892, -8.09],
      [110.4894, -8.0905],
      [110.4891, -8.0911],
      [110.4888, -8.0915],
      [110.4888, -8.0921],
      [110.4888, -8.0924],
      [110.4892, -8.0929],
      [110.4899, -8.0934],
      [110.4907, -8.0938],
      [110.4911, -8.0942],
      [110.4917, -8.0948],
      [110.4917, -8.0952],
      [110.4914, -8.0955],
      [110.491, -8.0956],
      [110.4905, -8.0957],
      [110.49, -8.0957],
      [110.4894, -8.0955],
      [110.4887, -8.0953],
      [110.488, -8.095],
      [110.4875, -8.0946],
      [110.4873, -8.0938],
      [110.4873, -8.093],
      [110.4876, -8.0917],
      [110.488, -8.0905],
      [110.4879, -8.09],
      [110.4874, -8.0893],
      [110.487, -8.0891],
      [110.4865, -8.0893],
      [110.486, -8.0894],
      [110.4858, -8.0898],
      [110.4854, -8.091],
      [110.4849, -8.0917],
      [110.4846, -8.0927],
      [110.4845, -8.0935],
      [110.4847, -8.0943],
      [110.4845, -8.0948],
      [110.4842, -8.0955],
      [110.484, -8.0964],
      [110.4842, -8.0974],
      [110.4847, -8.0983],
      [110.4859, -8.0993],
      [110.487, -8.0999],
      [110.488, -8.1002],
      [110.4893, -8.1005],
      [110.4907, -8.1004],
      [110.4913, -8.1005],
      [110.4922, -8.1009],
      [110.4932, -8.1014],
      [110.4941, -8.1016],
      [110.4953, -8.1014],
      [110.4972, -8.1009],
      [110.4982, -8.1008],
      [110.4992, -8.1008],
      [110.4999, -8.1009],
      [110.5008, -8.1016],
      [110.502, -8.1027],
      [110.5027, -8.1036],
      [110.5035, -8.1045],
      [110.5049, -8.1063],
      [110.5057, -8.108],
      [110.5057, -8.1085],
      [110.5055, -8.1091],
      [110.5053, -8.1095],
      [110.5055, -8.1098],
      [110.5059, -8.1106],
      [110.5061, -8.1112],
      [110.5061, -8.1116],
      [110.5059, -8.1119],
      [110.5055, -8.1124],
      [110.5048, -8.1128],
      [110.5041, -8.1135],
      [110.5036, -8.1141],
      [110.5035, -8.1153],
      [110.5034, -8.1164],
      [110.5033, -8.1174],
      [110.5031, -8.1184],
      [110.503, -8.1187],
      [110.5031, -8.1187],
      [110.5032, -8.1189],
      [110.5033, -8.119],
      [110.5032, -8.1193],
      [110.5032, -8.1195],
      [110.5032, -8.1197],
      [110.5034, -8.1198],
      [110.5035, -8.1197],
      [110.5037, -8.1196],
      [110.5038, -8.1195],
      [110.5041, -8.1195],
      [110.5043, -8.1195],
      [110.5044, -8.1195],
      [110.5046, -8.1196],
      [110.5049, -8.1195],
      [110.5052, -8.1195],
      [110.5054, -8.1196],
      [110.5055, -8.1198],
      [110.5057, -8.12],
      [110.5058, -8.1202],
      [110.5059, -8.1204],
      [110.506, -8.1206],
      [110.5062, -8.1208],
      [110.5063, -8.121],
      [110.5065, -8.121],
      [110.5071, -8.1211],
      [110.5078, -8.1211],
      [110.5081, -8.1211],
      [110.5084, -8.1212],
      [110.5087, -8.1213],
      [110.5089, -8.1213],
      [110.509, -8.1213],
      [110.5091, -8.1212],
      [110.5092, -8.1211],
      [110.5095, -8.1211],
      [110.5096, -8.1212],
      [110.5097, -8.1214],
      [110.51, -8.1216],
      [110.5102, -8.1219],
      [110.5103, -8.122],
      [110.5108, -8.1223],
      [110.5113, -8.1224],
      [110.5116, -8.1225],
      [110.5119, -8.1226],
      [110.512, -8.1227],
      [110.5122, -8.1227],
      [110.5122, -8.1229],
      [110.5122, -8.1234],
      [110.5122, -8.1237],
      [110.5123, -8.1239],
      [110.5124, -8.124],
      [110.5126, -8.124],
      [110.5127, -8.124],
      [110.5128, -8.1237],
      [110.5131, -8.1231],
      [110.5132, -8.1228],
      [110.5133, -8.1227],
      [110.5134, -8.1226],
      [110.5134, -8.1225],
      [110.5134, -8.1223],
      [110.5135, -8.1222],
      [110.5135, -8.122],
      [110.5136, -8.1217],
      [110.5137, -8.1215],
      [110.5138, -8.1214],
      [110.514, -8.1213],
      [110.5141, -8.1214],
      [110.5143, -8.1214],
      [110.5144, -8.1214],
      [110.5144, -8.1216],
      [110.5144, -8.1217],
      [110.5143, -8.1219],
      [110.5142, -8.1221],
      [110.5142, -8.1223],
      [110.5141, -8.1225],
      [110.514, -8.1228],
      [110.514, -8.1231],
      [110.514, -8.1233],
      [110.5142, -8.1234],
      [110.5143, -8.1235],
      [110.5144, -8.1236],
      [110.5147, -8.1236],
      [110.5148, -8.1234],
      [110.5149, -8.1233],
      [110.5151, -8.1233],
      [110.5153, -8.1234],
      [110.5155, -8.1234],
      [110.5157, -8.1232],
      [110.5158, -8.123],
      [110.5159, -8.1229],
      [110.5161, -8.1228],
      [110.5162, -8.1228],
      [110.5162, -8.1232],
      [110.5163, -8.1237],
      [110.5164, -8.1239],
      [110.5165, -8.1241],
      [110.5166, -8.1242],
      [110.5166, -8.1244],
      [110.5165, -8.1246],
      [110.5164, -8.1247],
      [110.5165, -8.1249],
      [110.5166, -8.125],
      [110.5167, -8.125],
      [110.5169, -8.125],
      [110.5171, -8.1249],
      [110.5172, -8.1249],
      [110.5172, -8.125],
      [110.5173, -8.1252],
      [110.5174, -8.1253],
      [110.5176, -8.1253],
      [110.5178, -8.1252],
      [110.5179, -8.1251],
      [110.5181, -8.125],
      [110.5183, -8.1251],
      [110.5185, -8.1255],
      [110.5186, -8.1257],
      [110.5188, -8.1259],
      [110.519, -8.126],
      [110.5192, -8.126],
      [110.5194, -8.1259],
      [110.5196, -8.1257],
      [110.5196, -8.1255],
      [110.5197, -8.1253],
      [110.5199, -8.1253],
      [110.5201, -8.1253],
      [110.5202, -8.1254],
      [110.5202, -8.1256],
      [110.5203, -8.1258],
      [110.5205, -8.1258],
      [110.5208, -8.1258],
      [110.5209, -8.1258],
      [110.5212, -8.1258],
      [110.5213, -8.1258],
      [110.5215, -8.1259],
      [110.5216, -8.1261],
      [110.5218, -8.1262],
      [110.5221, -8.1264],
      [110.5222, -8.1265],
      [110.5223, -8.1267],
      [110.5223, -8.1269],
      [110.5223, -8.1273],
      [110.5222, -8.1277],
      [110.5221, -8.128],
      [110.5222, -8.1281],
      [110.5224, -8.1283],
      [110.5225, -8.1284],
      [110.5226, -8.1286],
      [110.5226, -8.1288],
      [110.5228, -8.1289],
      [110.5231, -8.1288],
      [110.5233, -8.1287],
      [110.5236, -8.1286],
      [110.5237, -8.1285],
      [110.5238, -8.1286],
      [110.5239, -8.1285],
      [110.524, -8.1284],
      [110.524, -8.1282],
      [110.524, -8.128],
      [110.524, -8.1279],
      [110.524, -8.1278],
      [110.5242, -8.1278],
      [110.5244, -8.1279],
      [110.5245, -8.1281],
      [110.5247, -8.1283],
      [110.5248, -8.1286],
      [110.5248, -8.1288],
      [110.5248, -8.129],
      [110.5251, -8.129],
      [110.5254, -8.129],
      [110.5257, -8.129],
      [110.526, -8.1288],
      [110.5261, -8.1288],
      [110.5263, -8.1289],
      [110.5262, -8.1291],
      [110.526, -8.1292],
      [110.5258, -8.1293],
      [110.5257, -8.1294],
      [110.5255, -8.1297],
      [110.5254, -8.1299],
      [110.5254, -8.13],
      [110.5254, -8.1301],
      [110.5256, -8.1301],
      [110.5257, -8.13],
      [110.5259, -8.13],
      [110.5262, -8.1301],
      [110.5263, -8.1302],
      [110.5265, -8.1301],
      [110.5267, -8.13],
      [110.5268, -8.1297],
      [110.5269, -8.1295],
      [110.5269, -8.1294],
      [110.5272, -8.1295],
      [110.5273, -8.1297],
      [110.5275, -8.1297],
      [110.5276, -8.1296],
      [110.5277, -8.1297],
      [110.5276, -8.1301],
      [110.5274, -8.1304],
      [110.5272, -8.1306],
      [110.5271, -8.1309],
      [110.5271, -8.1311],
      [110.5271, -8.1312],
      [110.5272, -8.1313],
      [110.5274, -8.1314],
      [110.5274, -8.1313],
      [110.5277, -8.1312],
      [110.5279, -8.1311],
      [110.5281, -8.1311],
      [110.5283, -8.1314],
      [110.5286, -8.1316],
      [110.5288, -8.1317],
      [110.529, -8.1317],
      [110.5292, -8.1318],
      [110.5293, -8.1317],
      [110.5294, -8.1315],
      [110.5294, -8.1312],
      [110.5294, -8.131],
      [110.5292, -8.1307],
      [110.5291, -8.1306],
      [110.5292, -8.1304],
      [110.5294, -8.1304],
      [110.5296, -8.1304],
      [110.5298, -8.1305],
      [110.5299, -8.1307],
      [110.53, -8.1307],
      [110.5302, -8.1306],
      [110.5303, -8.1304],
      [110.5303, -8.1302],
      [110.5304, -8.1302],
      [110.5305, -8.1303],
      [110.5306, -8.1305],
      [110.5307, -8.1306],
      [110.5308, -8.1307],
      [110.531, -8.1307],
      [110.5312, -8.1306],
      [110.5314, -8.1306],
      [110.5314, -8.1307],
      [110.5314, -8.131],
      [110.5313, -8.1311],
      [110.5313, -8.1313],
      [110.5314, -8.1314],
      [110.5316, -8.1315],
      [110.5319, -8.1315],
      [110.5321, -8.1315],
      [110.5323, -8.1315],
      [110.5325, -8.1315],
      [110.5327, -8.1313],
      [110.5328, -8.1311],
      [110.533, -8.1311],
      [110.5332, -8.1311],
      [110.5334, -8.1311],
      [110.5335, -8.131],
      [110.5335, -8.1308],
      [110.5336, -8.1306],
      [110.5338, -8.1306],
      [110.5339, -8.1305],
      [110.5339, -8.1304],
      [110.5339, -8.1302],
      [110.5339, -8.13],
      [110.5341, -8.1299],
      [110.5342, -8.1298],
      [110.5344, -8.1298],
      [110.5345, -8.1301],
      [110.5345, -8.1303],
      [110.5346, -8.1305],
      [110.5348, -8.1306],
      [110.535, -8.1308],
      [110.535, -8.1309],
      [110.535, -8.1312],
      [110.5351, -8.1313],
      [110.5353, -8.1313],
      [110.5356, -8.1313],
      [110.5358, -8.1314],
      [110.5361, -8.1316],
      [110.5363, -8.1318],
      [110.5364, -8.1318],
      [110.5366, -8.1318],
      [110.5368, -8.1317],
      [110.5368, -8.1315],
      [110.5368, -8.1314],
      [110.5368, -8.1313],
      [110.5368, -8.1312],
      [110.5369, -8.1312],
      [110.5371, -8.1313],
      [110.5373, -8.1314],
      [110.5374, -8.1314],
      [110.5375, -8.1315],
      [110.5374, -8.1315],
      [110.5373, -8.1317],
      [110.5373, -8.1318],
      [110.5373, -8.132],
      [110.5375, -8.132],
      [110.5376, -8.132],
      [110.5379, -8.132],
      [110.5381, -8.132],
      [110.5383, -8.132],
      [110.5384, -8.1322],
      [110.5386, -8.1324],
      [110.5387, -8.1326],
      [110.5387, -8.1327],
      [110.5388, -8.1329],
      [110.5389, -8.1329],
      [110.5392, -8.133],
      [110.5393, -8.133],
      [110.5396, -8.133],
      [110.5397, -8.133],
      [110.5398, -8.133],
      [110.54, -8.1331],
      [110.5401, -8.1332],
      [110.5403, -8.1333],
      [110.5405, -8.1332],
      [110.5406, -8.1331],
      [110.5405, -8.1329],
      [110.5405, -8.1327],
      [110.5407, -8.1327],
      [110.5408, -8.1327],
      [110.5409, -8.1328],
      [110.5409, -8.133],
      [110.5409, -8.1332],
      [110.5411, -8.1333],
      [110.5414, -8.1333],
      [110.5417, -8.1335],
      [110.5419, -8.1335],
      [110.5421, -8.1335],
      [110.5423, -8.1337],
      [110.5424, -8.1338],
      [110.5425, -8.1339],
      [110.5427, -8.1341],
      [110.543, -8.1342],
      [110.5431, -8.1344],
      [110.5433, -8.1347],
      [110.5434, -8.1348],
      [110.5435, -8.1349],
      [110.5436, -8.135],
      [110.5437, -8.1349],
      [110.5438, -8.1347],
      [110.5438, -8.1344],
      [110.5439, -8.1342],
      [110.544, -8.1341],
      [110.5443, -8.1341],
      [110.5446, -8.1341],
      [110.5449, -8.1342],
      [110.545, -8.1343],
      [110.5451, -8.1344],
      [110.5453, -8.1346],
      [110.5453, -8.1349],
      [110.5454, -8.1351],
      [110.5454, -8.1352],
      [110.5456, -8.1352],
      [110.5456, -8.135],
      [110.5457, -8.1347],
      [110.5457, -8.1344],
      [110.5455, -8.134],
      [110.5454, -8.1338],
      [110.5453, -8.1336],
      [110.5452, -8.1332],
      [110.5452, -8.1329],
      [110.5451, -8.1327],
      [110.5449, -8.1321],
      [110.5448, -8.1319],
      [110.545, -8.1319],
      [110.5451, -8.1321],
      [110.5453, -8.1323],
      [110.5455, -8.1324],
      [110.5457, -8.1324],
      [110.5459, -8.1324],
      [110.5461, -8.1323],
      [110.5463, -8.132],
      [110.5465, -8.1319],
      [110.5465, -8.1318],
      [110.5464, -8.1314],
      [110.5464, -8.1312],
      [110.5463, -8.1309],
      [110.5463, -8.1307],
      [110.5464, -8.1306],
      [110.5467, -8.1305],
      [110.547, -8.1303],
      [110.5473, -8.1302],
      [110.5474, -8.13],
      [110.5475, -8.1297],
      [110.5476, -8.1294],
      [110.5477, -8.1292],
      [110.5479, -8.129],
      [110.5479, -8.1289],
      [110.5478, -8.1286],
      [110.5469, -8.1282],
      [110.5462, -8.1276],
      [110.5455, -8.1269],
      [110.5454, -8.1267],
      [110.5454, -8.1262],
      [110.5449, -8.1257],
      [110.5444, -8.1252],
      [110.5441, -8.1247],
      [110.5437, -8.1238],
      [110.5433, -8.123],
      [110.5435, -8.122],
      [110.544, -8.121],
      [110.5447, -8.1199],
      [110.5452, -8.1194],
      [110.5459, -8.1185],
      [110.5466, -8.118]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SAPTOSARI',
    village: 'MONGGOL',
    border: [
      [110.5487, -8.0284],
      [110.5481, -8.0284],
      [110.5472, -8.0296],
      [110.5459, -8.0316],
      [110.5451, -8.0324],
      [110.5441, -8.0332],
      [110.5434, -8.0334],
      [110.5425, -8.0336],
      [110.5417, -8.0336],
      [110.5415, -8.0339],
      [110.5412, -8.0342],
      [110.5406, -8.0341],
      [110.5398, -8.0342],
      [110.5393, -8.0342],
      [110.5386, -8.0336],
      [110.5377, -8.0328],
      [110.5365, -8.0323],
      [110.535, -8.0315],
      [110.5343, -8.0308],
      [110.5336, -8.0315],
      [110.5325, -8.0328],
      [110.5323, -8.0331],
      [110.5322, -8.0338],
      [110.5319, -8.0342],
      [110.5316, -8.0345],
      [110.5307, -8.0348],
      [110.5304, -8.0349],
      [110.5298, -8.035],
      [110.5293, -8.0354],
      [110.5287, -8.0361],
      [110.5284, -8.0365],
      [110.5282, -8.037],
      [110.5283, -8.0376],
      [110.5282, -8.0379],
      [110.5281, -8.0384],
      [110.5279, -8.0388],
      [110.5281, -8.0392],
      [110.5283, -8.0397],
      [110.5281, -8.04],
      [110.5281, -8.0404],
      [110.5281, -8.041],
      [110.5279, -8.0413],
      [110.5277, -8.0418],
      [110.5276, -8.0422],
      [110.5277, -8.0426],
      [110.528, -8.0433],
      [110.528, -8.0439],
      [110.5279, -8.0446],
      [110.5278, -8.0458],
      [110.5278, -8.0471],
      [110.5278, -8.0481],
      [110.5278, -8.0489],
      [110.5279, -8.0514],
      [110.5279, -8.0522],
      [110.5278, -8.0529],
      [110.5275, -8.0534],
      [110.527, -8.0537],
      [110.526, -8.0536],
      [110.5253, -8.0538],
      [110.5244, -8.0541],
      [110.5238, -8.0546],
      [110.5232, -8.0549],
      [110.523, -8.0549],
      [110.523, -8.0551],
      [110.5233, -8.056],
      [110.5235, -8.0561],
      [110.5238, -8.0563],
      [110.5241, -8.0568],
      [110.5247, -8.0569],
      [110.5249, -8.0573],
      [110.5253, -8.0577],
      [110.5264, -8.0578],
      [110.5275, -8.0582],
      [110.5288, -8.0592],
      [110.5293, -8.0597],
      [110.5295, -8.06],
      [110.5294, -8.0603],
      [110.5291, -8.0605],
      [110.529, -8.0608],
      [110.5292, -8.0613],
      [110.5294, -8.0618],
      [110.5294, -8.0621],
      [110.5297, -8.0628],
      [110.5299, -8.0636],
      [110.5299, -8.0643],
      [110.5296, -8.0652],
      [110.5306, -8.0645],
      [110.532, -8.0642],
      [110.534, -8.0637],
      [110.5363, -8.0633],
      [110.5378, -8.0629],
      [110.5392, -8.0627],
      [110.5408, -8.0628],
      [110.5423, -8.0625],
      [110.5433, -8.0623],
      [110.5447, -8.0623],
      [110.5461, -8.0623],
      [110.547, -8.0622],
      [110.5474, -8.0616],
      [110.548, -8.0608],
      [110.5488, -8.06],
      [110.5493, -8.059],
      [110.5496, -8.0577],
      [110.5499, -8.0568],
      [110.5498, -8.0562],
      [110.5498, -8.0558],
      [110.5502, -8.0548],
      [110.5504, -8.0543],
      [110.5502, -8.0535],
      [110.5503, -8.0528],
      [110.5508, -8.0517],
      [110.5511, -8.0508],
      [110.5512, -8.0501],
      [110.5513, -8.049],
      [110.5512, -8.0478],
      [110.5516, -8.0471],
      [110.5519, -8.0466],
      [110.5519, -8.0459],
      [110.5516, -8.0449],
      [110.5515, -8.044],
      [110.5516, -8.0434],
      [110.5517, -8.0426],
      [110.5519, -8.042],
      [110.5519, -8.0417],
      [110.5511, -8.0415],
      [110.5504, -8.0414],
      [110.5502, -8.0412],
      [110.5503, -8.0409],
      [110.5508, -8.0404],
      [110.5512, -8.0397],
      [110.5514, -8.0392],
      [110.5514, -8.0384],
      [110.5519, -8.0382],
      [110.5522, -8.0381],
      [110.5524, -8.0376],
      [110.5524, -8.0369],
      [110.5521, -8.0361],
      [110.5516, -8.0352],
      [110.5513, -8.0344],
      [110.5503, -8.0325],
      [110.549, -8.0304],
      [110.5487, -8.0298],
      [110.5486, -8.029],
      [110.5487, -8.0284]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'SAPTOSARI',
    village: 'PLANJAN',
    border: [
      [110.5593, -8.0823],
      [110.5587, -8.0827],
      [110.5586, -8.0828],
      [110.5582, -8.0832],
      [110.5581, -8.0834],
      [110.558, -8.0837],
      [110.5579, -8.0839],
      [110.557, -8.0847],
      [110.5567, -8.0849],
      [110.5554, -8.0863],
      [110.5552, -8.0863],
      [110.5544, -8.0863],
      [110.5543, -8.0862],
      [110.5531, -8.0859],
      [110.5525, -8.0855],
      [110.5519, -8.0846],
      [110.5513, -8.0839],
      [110.5508, -8.0832],
      [110.5502, -8.0826],
      [110.5493, -8.0819],
      [110.5482, -8.0809],
      [110.5478, -8.0798],
      [110.5476, -8.079],
      [110.5476, -8.0781],
      [110.548, -8.0768],
      [110.5482, -8.0756],
      [110.5482, -8.0751],
      [110.5478, -8.0738],
      [110.5474, -8.0729],
      [110.5471, -8.0721],
      [110.5471, -8.0716],
      [110.5471, -8.0712],
      [110.5471, -8.0709],
      [110.5474, -8.0703],
      [110.5476, -8.0692],
      [110.5478, -8.0677],
      [110.5477, -8.0664],
      [110.5474, -8.0644],
      [110.5472, -8.0633],
      [110.547, -8.0623],
      [110.5461, -8.0623],
      [110.5447, -8.0623],
      [110.5433, -8.0623],
      [110.5423, -8.0625],
      [110.5408, -8.0628],
      [110.5392, -8.0627],
      [110.5378, -8.0629],
      [110.5363, -8.0633],
      [110.534, -8.0637],
      [110.532, -8.0642],
      [110.5306, -8.0645],
      [110.5296, -8.0652],
      [110.5294, -8.0655],
      [110.5292, -8.0663],
      [110.5288, -8.0667],
      [110.5284, -8.067],
      [110.5281, -8.0669],
      [110.5275, -8.0667],
      [110.5261, -8.0656],
      [110.525, -8.0641],
      [110.5243, -8.0635],
      [110.5228, -8.0633],
      [110.522, -8.0637],
      [110.521, -8.0643],
      [110.5212, -8.0651],
      [110.5212, -8.0667],
      [110.5207, -8.0688],
      [110.5201, -8.0705],
      [110.52, -8.0711],
      [110.52, -8.0712],
      [110.5201, -8.072],
      [110.5209, -8.0732],
      [110.5223, -8.0746],
      [110.5234, -8.0757],
      [110.5245, -8.0763],
      [110.5251, -8.0762],
      [110.5261, -8.0757],
      [110.5266, -8.0756],
      [110.5281, -8.0758],
      [110.5287, -8.0759],
      [110.5292, -8.0764],
      [110.53, -8.077],
      [110.5308, -8.0775],
      [110.5316, -8.0784],
      [110.5321, -8.0794],
      [110.5326, -8.0812],
      [110.533, -8.0824],
      [110.5331, -8.0845],
      [110.533, -8.087],
      [110.533, -8.0885],
      [110.5332, -8.0895],
      [110.5336, -8.0901],
      [110.5344, -8.0903],
      [110.5362, -8.0907],
      [110.5375, -8.0915],
      [110.5382, -8.0922],
      [110.5385, -8.093],
      [110.5384, -8.0936],
      [110.5382, -8.0943],
      [110.5377, -8.0954],
      [110.5374, -8.0965],
      [110.5373, -8.0983],
      [110.5375, -8.0996],
      [110.5379, -8.1004],
      [110.5387, -8.1015],
      [110.5407, -8.1036],
      [110.5427, -8.1059],
      [110.543, -8.1063],
      [110.5434, -8.1067],
      [110.5437, -8.1076],
      [110.5438, -8.1079],
      [110.544, -8.1086],
      [110.5446, -8.1099],
      [110.5449, -8.1108],
      [110.5451, -8.1129],
      [110.5451, -8.1138],
      [110.5456, -8.1147],
      [110.5459, -8.1158],
      [110.5461, -8.1169],
      [110.5464, -8.1173],
      [110.5466, -8.118],
      [110.5468, -8.1179],
      [110.5473, -8.1172],
      [110.5477, -8.1165],
      [110.5481, -8.1152],
      [110.5486, -8.114],
      [110.5492, -8.1132],
      [110.5496, -8.1126],
      [110.55, -8.112],
      [110.5505, -8.1117],
      [110.5511, -8.1112],
      [110.5516, -8.1105],
      [110.5519, -8.1098],
      [110.5529, -8.1073],
      [110.5535, -8.1059],
      [110.5541, -8.1044],
      [110.5547, -8.1034],
      [110.5555, -8.102],
      [110.5571, -8.1],
      [110.5582, -8.0984],
      [110.5591, -8.0956],
      [110.5594, -8.0941],
      [110.5591, -8.0927],
      [110.5591, -8.0915],
      [110.5593, -8.0894],
      [110.5593, -8.0873],
      [110.5594, -8.0851],
      [110.5596, -8.0837],
      [110.5595, -8.0829],
      [110.5593, -8.0823]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'BALONG',
    border: [
      [110.7117, -8.1414],
      [110.712, -8.141],
      [110.7128, -8.1398],
      [110.7135, -8.1392],
      [110.7137, -8.1382],
      [110.714, -8.1367],
      [110.7143, -8.134],
      [110.7142, -8.1338],
      [110.7137, -8.1333],
      [110.713, -8.1332],
      [110.712, -8.133],
      [110.7104, -8.1327],
      [110.7092, -8.1325],
      [110.7075, -8.1319],
      [110.7055, -8.1312],
      [110.7047, -8.1304],
      [110.7039, -8.1295],
      [110.7031, -8.1289],
      [110.7017, -8.1281],
      [110.701, -8.1272],
      [110.7004, -8.1264],
      [110.7001, -8.1263],
      [110.6995, -8.1263],
      [110.6988, -8.1263],
      [110.6979, -8.1265],
      [110.6974, -8.1266],
      [110.6974, -8.1268],
      [110.6975, -8.1273],
      [110.6976, -8.1279],
      [110.6976, -8.1284],
      [110.6975, -8.1288],
      [110.6973, -8.1293],
      [110.697, -8.1297],
      [110.6969, -8.1302],
      [110.6969, -8.1307],
      [110.697, -8.1312],
      [110.6973, -8.1318],
      [110.6976, -8.1325],
      [110.698, -8.1331],
      [110.698, -8.1335],
      [110.6977, -8.134],
      [110.6974, -8.1345],
      [110.6968, -8.1352],
      [110.6964, -8.1354],
      [110.6961, -8.1357],
      [110.6958, -8.136],
      [110.6956, -8.1366],
      [110.6957, -8.1374],
      [110.6954, -8.138],
      [110.695, -8.1385],
      [110.6948, -8.1391],
      [110.6948, -8.1394],
      [110.6951, -8.1399],
      [110.6953, -8.1404],
      [110.6952, -8.1407],
      [110.6949, -8.1411],
      [110.6945, -8.1415],
      [110.6942, -8.1418],
      [110.6937, -8.1419],
      [110.6935, -8.1423],
      [110.6934, -8.1428],
      [110.693, -8.1435],
      [110.6928, -8.144],
      [110.6928, -8.1445],
      [110.6927, -8.1451],
      [110.6924, -8.1455],
      [110.6924, -8.1458],
      [110.6925, -8.1466],
      [110.6927, -8.1476],
      [110.6928, -8.1485],
      [110.6929, -8.1492],
      [110.6929, -8.1496],
      [110.693, -8.1499],
      [110.6931, -8.1509],
      [110.6931, -8.1517],
      [110.693, -8.1524],
      [110.6928, -8.153],
      [110.6926, -8.1542],
      [110.6925, -8.1553],
      [110.6928, -8.1566],
      [110.6932, -8.1579],
      [110.6933, -8.1585],
      [110.6931, -8.159],
      [110.6926, -8.1598],
      [110.6922, -8.1605],
      [110.6918, -8.1612],
      [110.6914, -8.1619],
      [110.6915, -8.1623],
      [110.6917, -8.1626],
      [110.6921, -8.163],
      [110.6937, -8.1634],
      [110.6956, -8.1639],
      [110.696, -8.1642],
      [110.6961, -8.1647],
      [110.6962, -8.165],
      [110.6963, -8.1655],
      [110.6963, -8.1661],
      [110.696, -8.1665],
      [110.6957, -8.1673],
      [110.6952, -8.1682],
      [110.6949, -8.1693],
      [110.6947, -8.1703],
      [110.6945, -8.1708],
      [110.6938, -8.171],
      [110.6931, -8.1713],
      [110.6927, -8.1715],
      [110.6922, -8.1722],
      [110.692, -8.1728],
      [110.6918, -8.1735],
      [110.6918, -8.1741],
      [110.6917, -8.1749],
      [110.6916, -8.1756],
      [110.6915, -8.1763],
      [110.6914, -8.177],
      [110.6914, -8.1774],
      [110.6914, -8.1777],
      [110.6913, -8.1781],
      [110.6911, -8.1785],
      [110.6908, -8.1787],
      [110.6905, -8.179],
      [110.6901, -8.1793],
      [110.6898, -8.1795],
      [110.6895, -8.1798],
      [110.6892, -8.1801],
      [110.6891, -8.1802],
      [110.689, -8.1803],
      [110.6887, -8.1803],
      [110.6887, -8.1806],
      [110.6888, -8.1811],
      [110.6887, -8.1811],
      [110.6885, -8.1813],
      [110.6886, -8.1815],
      [110.6886, -8.1815],
      [110.6888, -8.1818],
      [110.6887, -8.182],
      [110.6886, -8.1821],
      [110.6885, -8.1822],
      [110.6887, -8.1826],
      [110.6888, -8.1828],
      [110.6888, -8.1832],
      [110.6888, -8.1838],
      [110.6888, -8.1842],
      [110.689, -8.1844],
      [110.6892, -8.1846],
      [110.6892, -8.1849],
      [110.6893, -8.1849],
      [110.6893, -8.1851],
      [110.6894, -8.1853],
      [110.6891, -8.1854],
      [110.6889, -8.1855],
      [110.6888, -8.1857],
      [110.6889, -8.1858],
      [110.689, -8.1859],
      [110.6892, -8.1859],
      [110.6894, -8.1859],
      [110.6896, -8.1859],
      [110.6897, -8.186],
      [110.6899, -8.1861],
      [110.6901, -8.1862],
      [110.6903, -8.1863],
      [110.6904, -8.1864],
      [110.6904, -8.1866],
      [110.6906, -8.1866],
      [110.6907, -8.1868],
      [110.6908, -8.1868],
      [110.6908, -8.187],
      [110.6909, -8.1871],
      [110.6911, -8.1871],
      [110.6914, -8.1871],
      [110.6916, -8.1869],
      [110.6918, -8.1868],
      [110.6922, -8.1865],
      [110.6924, -8.1863],
      [110.6926, -8.1862],
      [110.693, -8.1862],
      [110.6933, -8.1862],
      [110.6935, -8.1862],
      [110.6936, -8.1863],
      [110.6938, -8.1864],
      [110.694, -8.1864],
      [110.6942, -8.1865],
      [110.6943, -8.1866],
      [110.6944, -8.1868],
      [110.6945, -8.1869],
      [110.6949, -8.187],
      [110.6952, -8.1871],
      [110.6955, -8.1872],
      [110.6958, -8.1872],
      [110.696, -8.1871],
      [110.696, -8.187],
      [110.6959, -8.1869],
      [110.6961, -8.1868],
      [110.6962, -8.1869],
      [110.6963, -8.187],
      [110.6965, -8.1871],
      [110.6967, -8.1871],
      [110.697, -8.187],
      [110.6971, -8.1869],
      [110.6971, -8.1868],
      [110.6973, -8.1866],
      [110.6975, -8.1865],
      [110.6976, -8.1864],
      [110.6976, -8.1862],
      [110.6978, -8.1862],
      [110.698, -8.1862],
      [110.6983, -8.1862],
      [110.6985, -8.1861],
      [110.6987, -8.1859],
      [110.6989, -8.1858],
      [110.699, -8.1858],
      [110.6992, -8.1858],
      [110.6995, -8.1858],
      [110.6997, -8.1859],
      [110.6998, -8.1861],
      [110.6999, -8.1863],
      [110.6996, -8.1869],
      [110.6995, -8.1871],
      [110.6995, -8.1873],
      [110.6996, -8.1873],
      [110.6998, -8.1872],
      [110.7002, -8.1872],
      [110.7003, -8.1871],
      [110.7005, -8.1871],
      [110.7006, -8.187],
      [110.7005, -8.1868],
      [110.7004, -8.1865],
      [110.7005, -8.1863],
      [110.7006, -8.1861],
      [110.7008, -8.186],
      [110.7009, -8.1859],
      [110.701, -8.1858],
      [110.701, -8.1857],
      [110.7008, -8.1856],
      [110.7006, -8.1856],
      [110.7006, -8.1854],
      [110.7007, -8.1853],
      [110.7008, -8.1852],
      [110.7009, -8.185],
      [110.701, -8.185],
      [110.7012, -8.185],
      [110.7013, -8.1849],
      [110.7014, -8.1848],
      [110.7014, -8.1846],
      [110.7016, -8.1846],
      [110.7017, -8.1846],
      [110.7018, -8.1847],
      [110.7019, -8.1848],
      [110.702, -8.1847],
      [110.7021, -8.1846],
      [110.7022, -8.1845],
      [110.7023, -8.1845],
      [110.7025, -8.1844],
      [110.7027, -8.1847],
      [110.703, -8.1847],
      [110.7032, -8.1848],
      [110.7035, -8.1848],
      [110.7037, -8.1849],
      [110.7038, -8.1851],
      [110.7037, -8.1855],
      [110.7038, -8.1858],
      [110.704, -8.1859],
      [110.7041, -8.186],
      [110.7043, -8.186],
      [110.7045, -8.1859],
      [110.7046, -8.1858],
      [110.7046, -8.1857],
      [110.7045, -8.1855],
      [110.7046, -8.1854],
      [110.7047, -8.1853],
      [110.7048, -8.1852],
      [110.705, -8.1852],
      [110.7051, -8.1851],
      [110.7052, -8.185],
      [110.7054, -8.1847],
      [110.7055, -8.1844],
      [110.7056, -8.1843],
      [110.7058, -8.1839],
      [110.7059, -8.1839],
      [110.706, -8.1837],
      [110.7061, -8.1836],
      [110.7062, -8.1833],
      [110.7066, -8.1834],
      [110.7068, -8.1835],
      [110.7072, -8.1836],
      [110.7074, -8.1837],
      [110.7077, -8.1839],
      [110.708, -8.1841],
      [110.7083, -8.1844],
      [110.7087, -8.1847],
      [110.7089, -8.1849],
      [110.7092, -8.1853],
      [110.7093, -8.1854],
      [110.7092, -8.1855],
      [110.7092, -8.1856],
      [110.7092, -8.1857],
      [110.7094, -8.1858],
      [110.7095, -8.1858],
      [110.7096, -8.1859],
      [110.7097, -8.186],
      [110.7096, -8.1861],
      [110.7096, -8.1863],
      [110.7097, -8.1864],
      [110.7099, -8.1865],
      [110.7099, -8.1866],
      [110.7102, -8.1864],
      [110.7108, -8.1862],
      [110.7112, -8.186],
      [110.7114, -8.1857],
      [110.7113, -8.1854],
      [110.7109, -8.1844],
      [110.7108, -8.184],
      [110.7108, -8.1834],
      [110.7106, -8.1831],
      [110.71, -8.1828],
      [110.7096, -8.1827],
      [110.7092, -8.1826],
      [110.7087, -8.1816],
      [110.7084, -8.1809],
      [110.7084, -8.1803],
      [110.7084, -8.1797],
      [110.7084, -8.1792],
      [110.7081, -8.1787],
      [110.7083, -8.1782],
      [110.7084, -8.1775],
      [110.7085, -8.1768],
      [110.7085, -8.1758],
      [110.7087, -8.1753],
      [110.7088, -8.1751],
      [110.7088, -8.1745],
      [110.7088, -8.1739],
      [110.709, -8.1735],
      [110.7094, -8.1734],
      [110.7096, -8.1733],
      [110.7099, -8.1727],
      [110.7101, -8.1721],
      [110.7101, -8.1717],
      [110.7097, -8.1713],
      [110.7095, -8.1709],
      [110.7094, -8.1705],
      [110.7095, -8.1699],
      [110.7098, -8.169],
      [110.71, -8.168],
      [110.7102, -8.1674],
      [110.7106, -8.1668],
      [110.7108, -8.166],
      [110.7107, -8.1654],
      [110.7106, -8.165],
      [110.7107, -8.1646],
      [110.7108, -8.1642],
      [110.7108, -8.1634],
      [110.7108, -8.163],
      [110.7108, -8.1627],
      [110.7107, -8.1623],
      [110.7105, -8.162],
      [110.7106, -8.1616],
      [110.7106, -8.1615],
      [110.7106, -8.1612],
      [110.7104, -8.1608],
      [110.7102, -8.1604],
      [110.7099, -8.1601],
      [110.7098, -8.1596],
      [110.7098, -8.159],
      [110.7099, -8.1586],
      [110.7101, -8.1584],
      [110.7101, -8.158],
      [110.71, -8.1576],
      [110.71, -8.1573],
      [110.7102, -8.1571],
      [110.7104, -8.1569],
      [110.7105, -8.1562],
      [110.7104, -8.1558],
      [110.7104, -8.1555],
      [110.7105, -8.155],
      [110.7099, -8.1548],
      [110.7084, -8.1545],
      [110.7077, -8.1544],
      [110.7072, -8.1543],
      [110.7068, -8.154],
      [110.7067, -8.1538],
      [110.7066, -8.1534],
      [110.7068, -8.1531],
      [110.7071, -8.1526],
      [110.7075, -8.1523],
      [110.7077, -8.152],
      [110.7078, -8.1511],
      [110.7079, -8.1507],
      [110.7081, -8.1499],
      [110.7083, -8.1494],
      [110.7083, -8.1484],
      [110.7085, -8.1474],
      [110.7085, -8.1449],
      [110.7086, -8.1442],
      [110.7088, -8.1437],
      [110.7092, -8.1432],
      [110.71, -8.1427],
      [110.7107, -8.1421],
      [110.7116, -8.1415],
      [110.7117, -8.1414]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'JEPITU',
    border: [
      [
        [110.7424, -8.1368],
        [110.7421, -8.1361],
        [110.7418, -8.1351],
        [110.7415, -8.1342],
        [110.741, -8.1336],
        [110.7405, -8.1331],
        [110.7394, -8.1327],
        [110.739, -8.1325],
        [110.7388, -8.1317],
        [110.7386, -8.1314],
        [110.7384, -8.1312],
        [110.7383, -8.1311],
        [110.7379, -8.1311],
        [110.7373, -8.1309],
        [110.7369, -8.1307],
        [110.7365, -8.131],
        [110.736, -8.131],
        [110.7356, -8.1312],
        [110.7352, -8.1313],
        [110.7347, -8.1313],
        [110.7341, -8.1312],
        [110.7336, -8.1312],
        [110.7333, -8.1316],
        [110.733, -8.1321],
        [110.7328, -8.133],
        [110.7326, -8.1336],
        [110.7324, -8.1345],
        [110.7324, -8.1349],
        [110.7318, -8.1351],
        [110.7312, -8.1354],
        [110.7308, -8.1356],
        [110.7305, -8.136],
        [110.7303, -8.1366],
        [110.7303, -8.1373],
        [110.7301, -8.1379],
        [110.7298, -8.1383],
        [110.729, -8.1387],
        [110.728, -8.1394],
        [110.7267, -8.1402],
        [110.7257, -8.1408],
        [110.7249, -8.1413],
        [110.7246, -8.1414],
        [110.7242, -8.1413],
        [110.7238, -8.1412],
        [110.7233, -8.1412],
        [110.723, -8.1414],
        [110.7226, -8.1414],
        [110.7222, -8.1412],
        [110.7217, -8.1412],
        [110.7213, -8.1415],
        [110.7203, -8.142],
        [110.7196, -8.1425],
        [110.718, -8.1435],
        [110.7176, -8.1436],
        [110.7174, -8.1434],
        [110.7171, -8.143],
        [110.7167, -8.1427],
        [110.7164, -8.1425],
        [110.7144, -8.1421],
        [110.7117, -8.1414],
        [110.7116, -8.1415],
        [110.7107, -8.1421],
        [110.71, -8.1427],
        [110.7092, -8.1432],
        [110.7088, -8.1437],
        [110.7086, -8.1442],
        [110.7085, -8.1449],
        [110.7085, -8.1474],
        [110.7083, -8.1484],
        [110.7083, -8.1494],
        [110.7081, -8.1499],
        [110.7079, -8.1507],
        [110.7078, -8.1511],
        [110.7077, -8.152],
        [110.7075, -8.1523],
        [110.7071, -8.1526],
        [110.7068, -8.1531],
        [110.7066, -8.1534],
        [110.7067, -8.1538],
        [110.7068, -8.154],
        [110.7072, -8.1543],
        [110.7077, -8.1544],
        [110.7084, -8.1545],
        [110.7099, -8.1548],
        [110.7105, -8.155],
        [110.7104, -8.1555],
        [110.7104, -8.1558],
        [110.7105, -8.1562],
        [110.7104, -8.1569],
        [110.7102, -8.1571],
        [110.71, -8.1573],
        [110.71, -8.1576],
        [110.7101, -8.158],
        [110.7101, -8.1584],
        [110.7099, -8.1586],
        [110.7098, -8.159],
        [110.7098, -8.1596],
        [110.7099, -8.1601],
        [110.7102, -8.1604],
        [110.7104, -8.1608],
        [110.7106, -8.1612],
        [110.7106, -8.1615],
        [110.7106, -8.1616],
        [110.7105, -8.162],
        [110.7107, -8.1623],
        [110.7108, -8.1627],
        [110.7108, -8.163],
        [110.7108, -8.1634],
        [110.7108, -8.1642],
        [110.7107, -8.1646],
        [110.7106, -8.165],
        [110.7107, -8.1654],
        [110.7108, -8.166],
        [110.7106, -8.1668],
        [110.7102, -8.1674],
        [110.71, -8.168],
        [110.7098, -8.169],
        [110.7095, -8.1699],
        [110.7094, -8.1705],
        [110.7095, -8.1709],
        [110.7097, -8.1713],
        [110.7101, -8.1717],
        [110.7101, -8.1721],
        [110.7099, -8.1727],
        [110.7096, -8.1733],
        [110.7094, -8.1734],
        [110.709, -8.1735],
        [110.7088, -8.1739],
        [110.7088, -8.1745],
        [110.7088, -8.1751],
        [110.7087, -8.1753],
        [110.7085, -8.1758],
        [110.7085, -8.1768],
        [110.7084, -8.1775],
        [110.7083, -8.1782],
        [110.7081, -8.1787],
        [110.7084, -8.1792],
        [110.7084, -8.1797],
        [110.7084, -8.1803],
        [110.7084, -8.1809],
        [110.7087, -8.1816],
        [110.7092, -8.1826],
        [110.7096, -8.1827],
        [110.71, -8.1828],
        [110.7106, -8.1831],
        [110.7108, -8.1834],
        [110.7108, -8.184],
        [110.7109, -8.1844],
        [110.7113, -8.1854],
        [110.7114, -8.1857],
        [110.7112, -8.186],
        [110.7108, -8.1862],
        [110.7102, -8.1864],
        [110.7099, -8.1866],
        [110.71, -8.1868],
        [110.71, -8.1873],
        [110.7101, -8.1876],
        [110.7101, -8.1878],
        [110.71, -8.188],
        [110.71, -8.1882],
        [110.7102, -8.1884],
        [110.7102, -8.1889],
        [110.7102, -8.1892],
        [110.7102, -8.1895],
        [110.7103, -8.1896],
        [110.7103, -8.19],
        [110.7103, -8.1905],
        [110.7102, -8.1909],
        [110.7101, -8.1913],
        [110.71, -8.1915],
        [110.71, -8.1916],
        [110.7101, -8.1918],
        [110.7101, -8.1919],
        [110.7102, -8.1921],
        [110.7101, -8.1925],
        [110.7099, -8.1928],
        [110.7097, -8.1931],
        [110.7094, -8.1933],
        [110.7092, -8.1932],
        [110.7089, -8.193],
        [110.7086, -8.1929],
        [110.7083, -8.1929],
        [110.7081, -8.193],
        [110.708, -8.1931],
        [110.7078, -8.193],
        [110.7077, -8.1929],
        [110.7075, -8.193],
        [110.7074, -8.1932],
        [110.7073, -8.1933],
        [110.7074, -8.1934],
        [110.7075, -8.1935],
        [110.7074, -8.1936],
        [110.7072, -8.1935],
        [110.7071, -8.1937],
        [110.7072, -8.1938],
        [110.7073, -8.1938],
        [110.7075, -8.1938],
        [110.7077, -8.1938],
        [110.7074, -8.194],
        [110.7071, -8.194],
        [110.707, -8.194],
        [110.707, -8.1942],
        [110.707, -8.1944],
        [110.7068, -8.1945],
        [110.7067, -8.1953],
        [110.7067, -8.1954],
        [110.7067, -8.1955],
        [110.7065, -8.1953],
        [110.7064, -8.1952],
        [110.7063, -8.1952],
        [110.7062, -8.1953],
        [110.7062, -8.1954],
        [110.7062, -8.1956],
        [110.7061, -8.1957],
        [110.7059, -8.1958],
        [110.7059, -8.1959],
        [110.7059, -8.1965],
        [110.7058, -8.1969],
        [110.7058, -8.1971],
        [110.7058, -8.1973],
        [110.7058, -8.1975],
        [110.7059, -8.1977],
        [110.7059, -8.1978],
        [110.7061, -8.1978],
        [110.7063, -8.1977],
        [110.7064, -8.1978],
        [110.7064, -8.198],
        [110.7065, -8.1982],
        [110.7065, -8.1984],
        [110.7064, -8.1987],
        [110.7063, -8.1989],
        [110.7061, -8.199],
        [110.706, -8.1992],
        [110.7059, -8.1993],
        [110.7059, -8.1996],
        [110.7059, -8.1998],
        [110.706, -8.2],
        [110.7061, -8.2001],
        [110.7063, -8.2001],
        [110.7065, -8.2],
        [110.7066, -8.2],
        [110.7069, -8.2001],
        [110.7072, -8.2001],
        [110.7074, -8.2002],
        [110.7075, -8.2004],
        [110.7077, -8.2005],
        [110.708, -8.2006],
        [110.7081, -8.2006],
        [110.7083, -8.2007],
        [110.7084, -8.2008],
        [110.7086, -8.2009],
        [110.7088, -8.2009],
        [110.709, -8.201],
        [110.7092, -8.201],
        [110.7094, -8.201],
        [110.7095, -8.201],
        [110.7097, -8.201],
        [110.7098, -8.2009],
        [110.7099, -8.2007],
        [110.7101, -8.2005],
        [110.7103, -8.2004],
        [110.7105, -8.2003],
        [110.7105, -8.2001],
        [110.7106, -8.1999],
        [110.7107, -8.2],
        [110.7109, -8.2001],
        [110.711, -8.2001],
        [110.7112, -8.2],
        [110.7114, -8.1999],
        [110.7115, -8.1998],
        [110.7115, -8.1996],
        [110.7116, -8.1995],
        [110.7118, -8.1994],
        [110.7118, -8.1994],
        [110.7117, -8.1992],
        [110.7115, -8.1991],
        [110.7115, -8.199],
        [110.7117, -8.1988],
        [110.712, -8.1984],
        [110.7123, -8.1982],
        [110.7124, -8.1981],
        [110.7126, -8.1979],
        [110.7129, -8.1978],
        [110.7131, -8.1978],
        [110.7134, -8.1979],
        [110.7135, -8.1981],
        [110.7135, -8.1983],
        [110.7135, -8.1984],
        [110.7135, -8.1985],
        [110.7135, -8.1986],
        [110.7135, -8.1988],
        [110.7135, -8.1989],
        [110.7137, -8.199],
        [110.7138, -8.199],
        [110.714, -8.199],
        [110.7143, -8.1988],
        [110.7143, -8.1986],
        [110.7143, -8.1983],
        [110.7143, -8.1982],
        [110.7145, -8.1979],
        [110.7145, -8.1977],
        [110.7146, -8.1976],
        [110.7149, -8.1976],
        [110.7151, -8.1976],
        [110.7154, -8.1979],
        [110.7155, -8.198],
        [110.7157, -8.1984],
        [110.7159, -8.1985],
        [110.716, -8.1986],
        [110.7161, -8.1984],
        [110.7162, -8.1983],
        [110.7163, -8.1983],
        [110.7167, -8.1984],
        [110.7168, -8.1985],
        [110.717, -8.1985],
        [110.7171, -8.1983],
        [110.7171, -8.198],
        [110.7172, -8.1978],
        [110.7174, -8.1979],
        [110.7174, -8.198],
        [110.7174, -8.1982],
        [110.7175, -8.1984],
        [110.7176, -8.1985],
        [110.7178, -8.1984],
        [110.718, -8.1983],
        [110.7181, -8.1983],
        [110.7183, -8.1984],
        [110.7184, -8.1985],
        [110.7183, -8.1988],
        [110.718, -8.199],
        [110.7179, -8.1993],
        [110.7178, -8.1995],
        [110.7178, -8.1997],
        [110.7179, -8.1999],
        [110.718, -8.1999],
        [110.7182, -8.1997],
        [110.7184, -8.1997],
        [110.7187, -8.1997],
        [110.7189, -8.1996],
        [110.7191, -8.1993],
        [110.7193, -8.1992],
        [110.7195, -8.1991],
        [110.7197, -8.199],
        [110.7198, -8.1989],
        [110.72, -8.1991],
        [110.72, -8.1993],
        [110.72, -8.1997],
        [110.7201, -8.1998],
        [110.7202, -8.1998],
        [110.7203, -8.1997],
        [110.7204, -8.1994],
        [110.7205, -8.1993],
        [110.7206, -8.1991],
        [110.7206, -8.1989],
        [110.7206, -8.1987],
        [110.7206, -8.1986],
        [110.7206, -8.1984],
        [110.7207, -8.1984],
        [110.7208, -8.1984],
        [110.721, -8.1987],
        [110.7212, -8.1988],
        [110.7215, -8.1989],
        [110.7217, -8.1989],
        [110.7217, -8.1987],
        [110.7217, -8.1985],
        [110.7217, -8.1984],
        [110.7218, -8.1983],
        [110.7219, -8.1982],
        [110.7219, -8.198],
        [110.7218, -8.1978],
        [110.7218, -8.1976],
        [110.722, -8.1974],
        [110.722, -8.1972],
        [110.7222, -8.1971],
        [110.7224, -8.1971],
        [110.7225, -8.1972],
        [110.7225, -8.1974],
        [110.7227, -8.1976],
        [110.7228, -8.1977],
        [110.723, -8.198],
        [110.7232, -8.1983],
        [110.7234, -8.1985],
        [110.7235, -8.1985],
        [110.7236, -8.1984],
        [110.7238, -8.1983],
        [110.724, -8.1983],
        [110.7242, -8.1983],
        [110.7244, -8.1985],
        [110.7245, -8.1987],
        [110.7246, -8.199],
        [110.7247, -8.1992],
        [110.7247, -8.1994],
        [110.7248, -8.1996],
        [110.7248, -8.1997],
        [110.7249, -8.1997],
        [110.725, -8.1997],
        [110.725, -8.1995],
        [110.7251, -8.1992],
        [110.7251, -8.199],
        [110.7253, -8.1989],
        [110.7255, -8.1988],
        [110.7257, -8.1986],
        [110.7259, -8.1984],
        [110.7259, -8.1982],
        [110.7259, -8.198],
        [110.726, -8.1979],
        [110.7263, -8.1978],
        [110.7265, -8.1976],
        [110.7267, -8.1974],
        [110.7269, -8.1973],
        [110.727, -8.1971],
        [110.727, -8.1968],
        [110.727, -8.1967],
        [110.7272, -8.1964],
        [110.7272, -8.1963],
        [110.7273, -8.1962],
        [110.7275, -8.1962],
        [110.7276, -8.1962],
        [110.7277, -8.1959],
        [110.7279, -8.1957],
        [110.7281, -8.1953],
        [110.7282, -8.1951],
        [110.7285, -8.1948],
        [110.7286, -8.1946],
        [110.7287, -8.1944],
        [110.7289, -8.1944],
        [110.729, -8.1944],
        [110.7291, -8.1941],
        [110.7294, -8.1941],
        [110.7299, -8.1941],
        [110.7301, -8.1941],
        [110.7301, -8.1936],
        [110.7303, -8.1923],
        [110.7307, -8.1914],
        [110.7316, -8.1904],
        [110.732, -8.19],
        [110.7322, -8.1893],
        [110.7323, -8.1888],
        [110.7327, -8.1881],
        [110.7334, -8.1877],
        [110.7344, -8.187],
        [110.735, -8.186],
        [110.7351, -8.1856],
        [110.7352, -8.1847],
        [110.7357, -8.1841],
        [110.7362, -8.1837],
        [110.7371, -8.1834],
        [110.7385, -8.1834],
        [110.7391, -8.1832],
        [110.7395, -8.1827],
        [110.7399, -8.1821],
        [110.7401, -8.1815],
        [110.7395, -8.1805],
        [110.7392, -8.1794],
        [110.7387, -8.1779],
        [110.7382, -8.1761],
        [110.7384, -8.1756],
        [110.7384, -8.1742],
        [110.7383, -8.1741],
        [110.7382, -8.1736],
        [110.7378, -8.1724],
        [110.7373, -8.1705],
        [110.7373, -8.17],
        [110.7364, -8.17],
        [110.7359, -8.1701],
        [110.7352, -8.17],
        [110.7342, -8.1698],
        [110.7335, -8.1697],
        [110.7333, -8.1697],
        [110.7327, -8.1693],
        [110.7321, -8.1685],
        [110.7317, -8.1671],
        [110.7315, -8.1664],
        [110.7311, -8.1659],
        [110.7307, -8.1659],
        [110.7298, -8.1666],
        [110.729, -8.1673],
        [110.7286, -8.1673],
        [110.7283, -8.1673],
        [110.7274, -8.1658],
        [110.7267, -8.1646],
        [110.7268, -8.1643],
        [110.727, -8.1641],
        [110.7289, -8.1633],
        [110.7297, -8.1629],
        [110.7301, -8.1625],
        [110.7302, -8.1621],
        [110.73, -8.1616],
        [110.7298, -8.1612],
        [110.7294, -8.1608],
        [110.7286, -8.1599],
        [110.7281, -8.1593],
        [110.7278, -8.1589],
        [110.7279, -8.1586],
        [110.7282, -8.1582],
        [110.7286, -8.1581],
        [110.7296, -8.158],
        [110.7301, -8.1576],
        [110.731, -8.1566],
        [110.7314, -8.1562],
        [110.7317, -8.1561],
        [110.732, -8.1558],
        [110.7319, -8.1552],
        [110.7318, -8.1549],
        [110.7314, -8.1545],
        [110.7309, -8.1543],
        [110.7305, -8.1538],
        [110.7303, -8.1533],
        [110.7292, -8.1526],
        [110.7284, -8.1523],
        [110.7283, -8.1521],
        [110.7282, -8.1512],
        [110.7283, -8.1499],
        [110.7284, -8.1482],
        [110.7284, -8.1475],
        [110.7287, -8.1466],
        [110.729, -8.1459],
        [110.73, -8.1448],
        [110.7318, -8.1437],
        [110.7329, -8.1437],
        [110.7344, -8.1431],
        [110.7358, -8.1427],
        [110.7369, -8.1415],
        [110.7384, -8.1405],
        [110.7389, -8.14],
        [110.739, -8.1392],
        [110.7391, -8.1385],
        [110.7395, -8.1378],
        [110.7396, -8.1377],
        [110.7401, -8.1374],
        [110.7404, -8.1372],
        [110.7418, -8.137],
        [110.7424, -8.1368]
      ]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'KARANGAWEN',
    border: [
      [110.7556, -8.1419],
      [110.7555, -8.1422],
      [110.755, -8.1429],
      [110.7542, -8.1438],
      [110.7532, -8.1444],
      [110.7522, -8.145],
      [110.751, -8.1455],
      [110.7505, -8.1455],
      [110.7499, -8.1454],
      [110.7484, -8.1446],
      [110.7479, -8.1443],
      [110.7479, -8.144],
      [110.748, -8.1437],
      [110.7482, -8.143],
      [110.7483, -8.1424],
      [110.7482, -8.1422],
      [110.7475, -8.1417],
      [110.7465, -8.1411],
      [110.7462, -8.1409],
      [110.7459, -8.1412],
      [110.7456, -8.1416],
      [110.7453, -8.1421],
      [110.7452, -8.1425],
      [110.7449, -8.1431],
      [110.7445, -8.1437],
      [110.7441, -8.144],
      [110.7432, -8.1447],
      [110.7424, -8.1452],
      [110.742, -8.1454],
      [110.7417, -8.1453],
      [110.7415, -8.1449],
      [110.7414, -8.1445],
      [110.7415, -8.1442],
      [110.7416, -8.1438],
      [110.742, -8.1436],
      [110.742, -8.1434],
      [110.742, -8.1431],
      [110.7419, -8.1427],
      [110.7418, -8.1424],
      [110.7421, -8.142],
      [110.7425, -8.1415],
      [110.7428, -8.1409],
      [110.7432, -8.1404],
      [110.7438, -8.1394],
      [110.7441, -8.1389],
      [110.7442, -8.1386],
      [110.7441, -8.138],
      [110.7436, -8.1377],
      [110.743, -8.1374],
      [110.7425, -8.1369],
      [110.7424, -8.1368],
      [110.7418, -8.137],
      [110.7404, -8.1372],
      [110.7401, -8.1374],
      [110.7396, -8.1377],
      [110.7395, -8.1378],
      [110.7391, -8.1385],
      [110.739, -8.1392],
      [110.7389, -8.14],
      [110.7384, -8.1405],
      [110.7369, -8.1415],
      [110.7358, -8.1427],
      [110.7344, -8.1431],
      [110.7329, -8.1437],
      [110.7318, -8.1437],
      [110.73, -8.1448],
      [110.729, -8.1459],
      [110.7287, -8.1466],
      [110.7284, -8.1475],
      [110.7284, -8.1482],
      [110.7283, -8.1499],
      [110.7282, -8.1512],
      [110.7283, -8.1521],
      [110.7284, -8.1523],
      [110.7292, -8.1526],
      [110.7303, -8.1533],
      [110.7305, -8.1538],
      [110.7309, -8.1543],
      [110.7314, -8.1545],
      [110.7318, -8.1549],
      [110.7319, -8.1552],
      [110.732, -8.1558],
      [110.7317, -8.1561],
      [110.7314, -8.1562],
      [110.731, -8.1566],
      [110.7301, -8.1576],
      [110.7296, -8.158],
      [110.7286, -8.1581],
      [110.7282, -8.1582],
      [110.7279, -8.1586],
      [110.7278, -8.1589],
      [110.7281, -8.1593],
      [110.7286, -8.1599],
      [110.7294, -8.1608],
      [110.7298, -8.1612],
      [110.73, -8.1616],
      [110.7302, -8.1621],
      [110.7301, -8.1625],
      [110.7297, -8.1629],
      [110.7289, -8.1633],
      [110.727, -8.1641],
      [110.7268, -8.1643],
      [110.7267, -8.1646],
      [110.7274, -8.1658],
      [110.7283, -8.1673],
      [110.7286, -8.1673],
      [110.729, -8.1673],
      [110.7298, -8.1666],
      [110.7307, -8.1659],
      [110.7311, -8.1659],
      [110.7315, -8.1664],
      [110.7317, -8.1671],
      [110.7321, -8.1685],
      [110.7327, -8.1693],
      [110.7333, -8.1697],
      [110.7335, -8.1697],
      [110.7342, -8.1698],
      [110.7352, -8.17],
      [110.7359, -8.1701],
      [110.7364, -8.17],
      [110.7373, -8.1699],
      [110.7374, -8.1698],
      [110.7378, -8.1695],
      [110.7382, -8.1696],
      [110.7393, -8.1696],
      [110.7406, -8.1697],
      [110.7409, -8.1697],
      [110.7419, -8.1698],
      [110.7442, -8.1694],
      [110.7449, -8.1693],
      [110.7461, -8.1689],
      [110.7468, -8.1683],
      [110.7478, -8.1669],
      [110.749, -8.1646],
      [110.7499, -8.163],
      [110.7505, -8.1627],
      [110.7508, -8.1621],
      [110.7508, -8.1606],
      [110.7507, -8.1596],
      [110.7509, -8.1585],
      [110.7511, -8.1571],
      [110.7516, -8.1555],
      [110.7521, -8.1544],
      [110.752, -8.1534],
      [110.752, -8.1528],
      [110.7526, -8.1517],
      [110.7529, -8.1511],
      [110.7533, -8.1508],
      [110.7536, -8.1505],
      [110.7547, -8.1495],
      [110.7551, -8.1482],
      [110.7553, -8.1463],
      [110.7555, -8.145],
      [110.7556, -8.1438],
      [110.7557, -8.1426],
      [110.7556, -8.1419]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'NGLINDUR',
    border: [
      [110.7715, -8.1308],
      [110.7716, -8.1296],
      [110.7718, -8.1284],
      [110.7719, -8.128],
      [110.772, -8.1275],
      [110.7722, -8.127],
      [110.7724, -8.1268],
      [110.7729, -8.1263],
      [110.775, -8.1255],
      [110.7751, -8.125],
      [110.7752, -8.1241],
      [110.7751, -8.123],
      [110.775, -8.1223],
      [110.7746, -8.1217],
      [110.7741, -8.1211],
      [110.7735, -8.1202],
      [110.7727, -8.1196],
      [110.7715, -8.1191],
      [110.7696, -8.1187],
      [110.7673, -8.1177],
      [110.764, -8.1169],
      [110.7604, -8.1163],
      [110.7577, -8.1157],
      [110.7576, -8.1173],
      [110.7573, -8.1183],
      [110.7568, -8.12],
      [110.7555, -8.1235],
      [110.7553, -8.1243],
      [110.7553, -8.1249],
      [110.7555, -8.1259],
      [110.7558, -8.1276],
      [110.7558, -8.1293],
      [110.7557, -8.1301],
      [110.7554, -8.1309],
      [110.7552, -8.1319],
      [110.755, -8.133],
      [110.7548, -8.1337],
      [110.7549, -8.1344],
      [110.7551, -8.1356],
      [110.7551, -8.1366],
      [110.755, -8.1383],
      [110.7551, -8.1396],
      [110.7553, -8.1405],
      [110.7556, -8.1413],
      [110.7556, -8.1419],
      [110.7557, -8.1426],
      [110.7556, -8.1438],
      [110.7555, -8.145],
      [110.7553, -8.1463],
      [110.7551, -8.1482],
      [110.7547, -8.1495],
      [110.7536, -8.1505],
      [110.7533, -8.1508],
      [110.7529, -8.1511],
      [110.7534, -8.1511],
      [110.7538, -8.1511],
      [110.7547, -8.1516],
      [110.7557, -8.152],
      [110.7568, -8.1523],
      [110.7577, -8.1525],
      [110.7586, -8.1523],
      [110.7592, -8.1521],
      [110.7606, -8.152],
      [110.7619, -8.1516],
      [110.7629, -8.1509],
      [110.7637, -8.15],
      [110.7642, -8.149],
      [110.7646, -8.148],
      [110.7648, -8.148],
      [110.7652, -8.1477],
      [110.766, -8.1472],
      [110.7666, -8.1466],
      [110.7673, -8.1456],
      [110.768, -8.1426],
      [110.7681, -8.1408],
      [110.7683, -8.1399],
      [110.7685, -8.1378],
      [110.7691, -8.1361],
      [110.7703, -8.1337],
      [110.7708, -8.1326],
      [110.7715, -8.1308]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'JERUKWUDEL',
    border: [
      [110.7821, -8.1317],
      [110.7817, -8.1315],
      [110.781, -8.1312],
      [110.7804, -8.1314],
      [110.7799, -8.1317],
      [110.7794, -8.1323],
      [110.779, -8.1327],
      [110.7781, -8.1331],
      [110.7776, -8.1331],
      [110.7773, -8.1329],
      [110.7767, -8.1325],
      [110.7758, -8.1322],
      [110.7751, -8.1322],
      [110.774, -8.1322],
      [110.7733, -8.132],
      [110.7725, -8.1314],
      [110.7715, -8.1308],
      [110.7708, -8.1326],
      [110.7703, -8.1337],
      [110.7691, -8.1361],
      [110.7685, -8.1378],
      [110.7683, -8.1399],
      [110.7681, -8.1408],
      [110.768, -8.1426],
      [110.7673, -8.1456],
      [110.7666, -8.1466],
      [110.766, -8.1472],
      [110.7652, -8.1477],
      [110.7648, -8.148],
      [110.7649, -8.1498],
      [110.7652, -8.152],
      [110.7658, -8.1536],
      [110.7662, -8.1547],
      [110.7662, -8.1557],
      [110.7662, -8.1574],
      [110.7661, -8.1582],
      [110.7662, -8.1593],
      [110.7664, -8.1595],
      [110.7676, -8.1611],
      [110.7684, -8.1625],
      [110.7686, -8.1631],
      [110.7688, -8.1648],
      [110.7691, -8.1657],
      [110.7693, -8.166],
      [110.7698, -8.1663],
      [110.7705, -8.1664],
      [110.7711, -8.1667],
      [110.7715, -8.1666],
      [110.772, -8.1663],
      [110.7723, -8.1662],
      [110.773, -8.166],
      [110.7736, -8.1657],
      [110.7745, -8.1652],
      [110.7755, -8.1645],
      [110.776, -8.1645],
      [110.7764, -8.1642],
      [110.777, -8.1639],
      [110.7787, -8.1629],
      [110.7792, -8.1629],
      [110.78, -8.1633],
      [110.7805, -8.1634],
      [110.7812, -8.163],
      [110.7814, -8.163],
      [110.7816, -8.1619],
      [110.7819, -8.161],
      [110.7825, -8.16],
      [110.7831, -8.159],
      [110.7832, -8.1578],
      [110.7834, -8.1573],
      [110.7827, -8.1555],
      [110.7823, -8.1543],
      [110.7816, -8.1531],
      [110.7812, -8.1527],
      [110.7806, -8.1524],
      [110.7802, -8.1525],
      [110.7797, -8.1525],
      [110.7794, -8.1522],
      [110.7794, -8.1517],
      [110.7793, -8.1513],
      [110.7789, -8.151],
      [110.7781, -8.1506],
      [110.7785, -8.1497],
      [110.7785, -8.1495],
      [110.7782, -8.1491],
      [110.7778, -8.1483],
      [110.7776, -8.148],
      [110.777, -8.1476],
      [110.7769, -8.1472],
      [110.7772, -8.1459],
      [110.777, -8.1452],
      [110.7768, -8.1446],
      [110.7766, -8.1441],
      [110.7766, -8.1435],
      [110.7772, -8.1423],
      [110.7782, -8.1404],
      [110.7786, -8.1398],
      [110.7791, -8.1391],
      [110.7795, -8.1387],
      [110.7799, -8.1383],
      [110.7805, -8.1383],
      [110.7813, -8.1382],
      [110.7818, -8.1382],
      [110.7823, -8.1381],
      [110.7827, -8.1379],
      [110.7827, -8.1374],
      [110.7828, -8.1371],
      [110.7827, -8.1364],
      [110.7826, -8.1359],
      [110.7825, -8.1352],
      [110.7823, -8.1342],
      [110.7819, -8.1328],
      [110.7819, -8.1324],
      [110.7821, -8.132],
      [110.7821, -8.1317]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'TILENG',
    border: [
      [110.7814, -8.163],
      [110.7812, -8.163],
      [110.7805, -8.1634],
      [110.78, -8.1633],
      [110.7792, -8.1629],
      [110.7787, -8.1629],
      [110.777, -8.1639],
      [110.7764, -8.1642],
      [110.776, -8.1645],
      [110.7755, -8.1645],
      [110.7745, -8.1652],
      [110.7736, -8.1657],
      [110.773, -8.166],
      [110.7723, -8.1662],
      [110.772, -8.1663],
      [110.7715, -8.1666],
      [110.7711, -8.1667],
      [110.7705, -8.1664],
      [110.7698, -8.1663],
      [110.7693, -8.166],
      [110.7691, -8.1657],
      [110.7688, -8.1648],
      [110.7686, -8.1631],
      [110.7684, -8.1625],
      [110.7676, -8.1611],
      [110.7664, -8.1595],
      [110.7662, -8.1593],
      [110.7661, -8.1582],
      [110.7662, -8.1574],
      [110.7662, -8.1557],
      [110.7662, -8.1547],
      [110.7658, -8.1536],
      [110.7652, -8.152],
      [110.7649, -8.1498],
      [110.7648, -8.148],
      [110.7646, -8.148],
      [110.7642, -8.149],
      [110.7637, -8.15],
      [110.7629, -8.1509],
      [110.7619, -8.1516],
      [110.7606, -8.152],
      [110.7592, -8.1521],
      [110.7586, -8.1523],
      [110.7577, -8.1525],
      [110.7568, -8.1523],
      [110.7557, -8.152],
      [110.7547, -8.1516],
      [110.7538, -8.1511],
      [110.7534, -8.1511],
      [110.7529, -8.1511],
      [110.7526, -8.1517],
      [110.752, -8.1528],
      [110.752, -8.1534],
      [110.7521, -8.1544],
      [110.7516, -8.1555],
      [110.7511, -8.1571],
      [110.7509, -8.1585],
      [110.7507, -8.1596],
      [110.7508, -8.1606],
      [110.7508, -8.1621],
      [110.7505, -8.1627],
      [110.7499, -8.163],
      [110.749, -8.1646],
      [110.7478, -8.1669],
      [110.7468, -8.1683],
      [110.7461, -8.1689],
      [110.7449, -8.1693],
      [110.7442, -8.1694],
      [110.7419, -8.1698],
      [110.7409, -8.1697],
      [110.7406, -8.1697],
      [110.7393, -8.1696],
      [110.7382, -8.1696],
      [110.7378, -8.1695],
      [110.7374, -8.1698],
      [110.7373, -8.1699],
      [110.7373, -8.17],
      [110.7373, -8.1705],
      [110.7378, -8.1724],
      [110.7382, -8.1736],
      [110.7383, -8.1741],
      [110.7384, -8.1742],
      [110.7384, -8.1756],
      [110.7382, -8.1761],
      [110.7387, -8.1779],
      [110.7392, -8.1794],
      [110.7395, -8.1805],
      [110.7401, -8.1815],
      [110.7399, -8.1821],
      [110.7395, -8.1827],
      [110.7391, -8.1832],
      [110.7385, -8.1834],
      [110.7371, -8.1834],
      [110.7362, -8.1837],
      [110.7357, -8.1841],
      [110.7352, -8.1847],
      [110.7351, -8.1856],
      [110.735, -8.186],
      [110.7344, -8.187],
      [110.7334, -8.1877],
      [110.7327, -8.1881],
      [110.7323, -8.1888],
      [110.7322, -8.1893],
      [110.732, -8.19],
      [110.7316, -8.1904],
      [110.7307, -8.1914],
      [110.7303, -8.1923],
      [110.7301, -8.1936],
      [110.7301, -8.1941],
      [110.7303, -8.1941],
      [110.7304, -8.1938],
      [110.7305, -8.1935],
      [110.7306, -8.1933],
      [110.7307, -8.1932],
      [110.7309, -8.1931],
      [110.7312, -8.193],
      [110.7315, -8.1929],
      [110.7318, -8.1929],
      [110.7322, -8.1929],
      [110.7325, -8.1929],
      [110.7329, -8.1929],
      [110.733, -8.1929],
      [110.7334, -8.1932],
      [110.7336, -8.1933],
      [110.7338, -8.1934],
      [110.734, -8.1935],
      [110.7344, -8.1934],
      [110.7348, -8.1932],
      [110.7354, -8.193],
      [110.7357, -8.1929],
      [110.736, -8.1927],
      [110.7364, -8.1926],
      [110.7365, -8.1926],
      [110.7368, -8.1927],
      [110.7371, -8.1927],
      [110.7372, -8.1927],
      [110.7373, -8.1926],
      [110.7375, -8.1927],
      [110.7375, -8.1927],
      [110.7379, -8.1928],
      [110.7388, -8.1931],
      [110.7393, -8.1934],
      [110.7394, -8.1935],
      [110.7396, -8.1936],
      [110.7398, -8.1936],
      [110.74, -8.1937],
      [110.7401, -8.1938],
      [110.7403, -8.1937],
      [110.7405, -8.1936],
      [110.7406, -8.1936],
      [110.741, -8.1935],
      [110.7414, -8.1936],
      [110.7416, -8.1938],
      [110.7421, -8.194],
      [110.7422, -8.194],
      [110.7424, -8.1941],
      [110.7427, -8.1943],
      [110.7429, -8.1943],
      [110.743, -8.1944],
      [110.7433, -8.1947],
      [110.7435, -8.195],
      [110.7438, -8.1952],
      [110.7441, -8.1953],
      [110.7443, -8.1953],
      [110.7444, -8.1954],
      [110.7447, -8.1955],
      [110.7449, -8.1956],
      [110.7451, -8.1959],
      [110.7453, -8.196],
      [110.7455, -8.1961],
      [110.7457, -8.196],
      [110.7462, -8.196],
      [110.7467, -8.196],
      [110.747, -8.196],
      [110.7473, -8.1962],
      [110.7477, -8.1964],
      [110.7479, -8.1967],
      [110.748, -8.1969],
      [110.7481, -8.197],
      [110.7483, -8.1972],
      [110.7485, -8.1972],
      [110.7487, -8.1971],
      [110.7487, -8.1969],
      [110.7488, -8.1968],
      [110.749, -8.1964],
      [110.7492, -8.1961],
      [110.7495, -8.1959],
      [110.7499, -8.1958],
      [110.7503, -8.1957],
      [110.7506, -8.1957],
      [110.751, -8.1956],
      [110.7513, -8.1956],
      [110.7516, -8.1956],
      [110.7517, -8.1956],
      [110.7519, -8.1956],
      [110.752, -8.1957],
      [110.7522, -8.1958],
      [110.7523, -8.1957],
      [110.7524, -8.1955],
      [110.7525, -8.1954],
      [110.7528, -8.1953],
      [110.7533, -8.1953],
      [110.7537, -8.1952],
      [110.7539, -8.1953],
      [110.7544, -8.1954],
      [110.7547, -8.1957],
      [110.7549, -8.1959],
      [110.7549, -8.1964],
      [110.7547, -8.1967],
      [110.7547, -8.197],
      [110.7549, -8.1973],
      [110.755, -8.1974],
      [110.7552, -8.1973],
      [110.7552, -8.1969],
      [110.7553, -8.1967],
      [110.7555, -8.1967],
      [110.7557, -8.1965],
      [110.7562, -8.1963],
      [110.7565, -8.1961],
      [110.7568, -8.1961],
      [110.7571, -8.1962],
      [110.7572, -8.1963],
      [110.7574, -8.1964],
      [110.7575, -8.1964],
      [110.7576, -8.1964],
      [110.7581, -8.1964],
      [110.7587, -8.1964],
      [110.7588, -8.1964],
      [110.7591, -8.196],
      [110.7594, -8.1958],
      [110.7599, -8.1958],
      [110.7605, -8.1957],
      [110.761, -8.1956],
      [110.7619, -8.1953],
      [110.7621, -8.1953],
      [110.7625, -8.1955],
      [110.7628, -8.1954],
      [110.7633, -8.1939],
      [110.7638, -8.1916],
      [110.7646, -8.1896],
      [110.7651, -8.1888],
      [110.7658, -8.1881],
      [110.7666, -8.1873],
      [110.7671, -8.1865],
      [110.7672, -8.1863],
      [110.7676, -8.1851],
      [110.7679, -8.1829],
      [110.7681, -8.181],
      [110.7684, -8.18],
      [110.7691, -8.1782],
      [110.7695, -8.1776],
      [110.77, -8.1773],
      [110.7706, -8.1769],
      [110.7719, -8.1771],
      [110.7727, -8.1773],
      [110.7736, -8.1772],
      [110.7743, -8.177],
      [110.7752, -8.1762],
      [110.7756, -8.1757],
      [110.7765, -8.1743],
      [110.7772, -8.1729],
      [110.7774, -8.1714],
      [110.778, -8.1699],
      [110.7784, -8.1694],
      [110.7793, -8.1683],
      [110.7796, -8.168],
      [110.7806, -8.1659],
      [110.7812, -8.1647],
      [110.7813, -8.1636],
      [110.7814, -8.163]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'PUCUNG',
    border: [
      [110.8005, -8.1614],
      [110.8001, -8.1616],
      [110.7991, -8.162],
      [110.7984, -8.1622],
      [110.7975, -8.1622],
      [110.7964, -8.162],
      [110.794, -8.1609],
      [110.7933, -8.1605],
      [110.7923, -8.1598],
      [110.7912, -8.1591],
      [110.7903, -8.1583],
      [110.7897, -8.1574],
      [110.789, -8.1564],
      [110.7885, -8.1556],
      [110.788, -8.1547],
      [110.7875, -8.1539],
      [110.7872, -8.1533],
      [110.7869, -8.1528],
      [110.7869, -8.1526],
      [110.7867, -8.1519],
      [110.7865, -8.1514],
      [110.7861, -8.1506],
      [110.7858, -8.1494],
      [110.7857, -8.1488],
      [110.7858, -8.1485],
      [110.7859, -8.1478],
      [110.786, -8.1469],
      [110.786, -8.1461],
      [110.7859, -8.1452],
      [110.7861, -8.1436],
      [110.786, -8.1417],
      [110.7859, -8.141],
      [110.7859, -8.1398],
      [110.7859, -8.139],
      [110.7862, -8.1373],
      [110.7866, -8.1364],
      [110.7868, -8.1357],
      [110.7869, -8.1349],
      [110.7871, -8.1344],
      [110.7872, -8.1336],
      [110.7875, -8.1323],
      [110.7876, -8.1316],
      [110.7876, -8.1304],
      [110.7874, -8.1304],
      [110.7865, -8.1304],
      [110.7852, -8.1306],
      [110.7838, -8.1308],
      [110.7829, -8.131],
      [110.7824, -8.1314],
      [110.7822, -8.1318],
      [110.7821, -8.1317],
      [110.7821, -8.132],
      [110.7819, -8.1324],
      [110.7819, -8.1328],
      [110.7823, -8.1342],
      [110.7825, -8.1352],
      [110.7826, -8.1359],
      [110.7827, -8.1364],
      [110.7828, -8.1371],
      [110.7827, -8.1374],
      [110.7827, -8.1379],
      [110.7823, -8.1381],
      [110.7818, -8.1382],
      [110.7813, -8.1382],
      [110.7805, -8.1383],
      [110.7799, -8.1383],
      [110.7795, -8.1387],
      [110.7791, -8.1391],
      [110.7786, -8.1398],
      [110.7782, -8.1404],
      [110.7772, -8.1423],
      [110.7766, -8.1435],
      [110.7766, -8.1441],
      [110.7768, -8.1446],
      [110.777, -8.1452],
      [110.7772, -8.1459],
      [110.7769, -8.1472],
      [110.777, -8.1476],
      [110.7776, -8.148],
      [110.7778, -8.1483],
      [110.7782, -8.1491],
      [110.7785, -8.1495],
      [110.7785, -8.1497],
      [110.7781, -8.1506],
      [110.7789, -8.151],
      [110.7793, -8.1513],
      [110.7794, -8.1517],
      [110.7794, -8.1522],
      [110.7797, -8.1525],
      [110.7802, -8.1525],
      [110.7806, -8.1524],
      [110.7812, -8.1527],
      [110.7816, -8.1531],
      [110.7823, -8.1543],
      [110.7827, -8.1555],
      [110.7834, -8.1573],
      [110.7832, -8.1578],
      [110.7831, -8.159],
      [110.7825, -8.16],
      [110.7819, -8.161],
      [110.7816, -8.1619],
      [110.7814, -8.163],
      [110.7813, -8.1636],
      [110.7812, -8.1647],
      [110.7806, -8.1659],
      [110.7796, -8.168],
      [110.7793, -8.1683],
      [110.7784, -8.1694],
      [110.778, -8.1699],
      [110.7774, -8.1714],
      [110.7772, -8.1729],
      [110.7765, -8.1743],
      [110.7756, -8.1757],
      [110.7752, -8.1762],
      [110.7743, -8.177],
      [110.7736, -8.1772],
      [110.7727, -8.1773],
      [110.7719, -8.1771],
      [110.7706, -8.1769],
      [110.77, -8.1773],
      [110.7695, -8.1776],
      [110.7691, -8.1782],
      [110.7684, -8.18],
      [110.7681, -8.181],
      [110.7679, -8.1829],
      [110.7676, -8.1851],
      [110.7672, -8.1863],
      [110.7671, -8.1865],
      [110.7666, -8.1873],
      [110.7658, -8.1881],
      [110.7651, -8.1888],
      [110.7646, -8.1896],
      [110.7638, -8.1916],
      [110.7633, -8.1939],
      [110.7628, -8.1954],
      [110.7632, -8.1953],
      [110.7634, -8.1952],
      [110.7637, -8.1952],
      [110.7638, -8.1952],
      [110.7641, -8.1955],
      [110.7642, -8.1957],
      [110.7646, -8.1958],
      [110.7649, -8.1959],
      [110.7651, -8.1959],
      [110.7655, -8.1963],
      [110.7659, -8.1964],
      [110.7662, -8.1965],
      [110.7665, -8.1967],
      [110.7666, -8.1968],
      [110.7668, -8.1967],
      [110.7668, -8.1965],
      [110.7668, -8.1962],
      [110.7668, -8.196],
      [110.7669, -8.1959],
      [110.7673, -8.1959],
      [110.7676, -8.1959],
      [110.7678, -8.1961],
      [110.768, -8.1963],
      [110.7682, -8.1965],
      [110.7684, -8.1965],
      [110.7687, -8.1965],
      [110.7696, -8.1961],
      [110.7699, -8.196],
      [110.7701, -8.196],
      [110.7702, -8.1959],
      [110.7702, -8.1958],
      [110.7703, -8.1958],
      [110.7704, -8.1959],
      [110.7704, -8.1961],
      [110.7706, -8.1961],
      [110.7709, -8.196],
      [110.771, -8.196],
      [110.7712, -8.1958],
      [110.7714, -8.1957],
      [110.7715, -8.1956],
      [110.7717, -8.1956],
      [110.7719, -8.1955],
      [110.7721, -8.1954],
      [110.7721, -8.1954],
      [110.7722, -8.1954],
      [110.7724, -8.1954],
      [110.7725, -8.1954],
      [110.7728, -8.1953],
      [110.773, -8.1953],
      [110.7731, -8.1955],
      [110.7731, -8.1959],
      [110.7732, -8.1961],
      [110.7732, -8.1964],
      [110.7733, -8.1965],
      [110.7735, -8.1966],
      [110.7737, -8.1968],
      [110.7739, -8.1967],
      [110.7741, -8.1967],
      [110.7743, -8.1968],
      [110.7745, -8.1968],
      [110.7746, -8.1966],
      [110.7747, -8.1965],
      [110.7751, -8.1964],
      [110.7752, -8.1963],
      [110.7754, -8.196],
      [110.7756, -8.1959],
      [110.7758, -8.1957],
      [110.7761, -8.1956],
      [110.7765, -8.1957],
      [110.7768, -8.1958],
      [110.777, -8.196],
      [110.7772, -8.1962],
      [110.7775, -8.1961],
      [110.7777, -8.1961],
      [110.778, -8.1961],
      [110.7783, -8.1962],
      [110.7785, -8.1961],
      [110.7786, -8.1959],
      [110.7787, -8.1958],
      [110.7792, -8.1959],
      [110.7797, -8.1958],
      [110.78, -8.1958],
      [110.7802, -8.1957],
      [110.7804, -8.1958],
      [110.7806, -8.1958],
      [110.7807, -8.1958],
      [110.781, -8.1962],
      [110.7811, -8.1963],
      [110.7812, -8.1964],
      [110.7815, -8.1963],
      [110.7818, -8.196],
      [110.7822, -8.1958],
      [110.7824, -8.1956],
      [110.7827, -8.1954],
      [110.7829, -8.1953],
      [110.7831, -8.195],
      [110.7832, -8.1948],
      [110.7833, -8.1946],
      [110.7835, -8.1946],
      [110.7837, -8.1946],
      [110.7837, -8.1947],
      [110.7837, -8.1948],
      [110.7838, -8.1948],
      [110.784, -8.1948],
      [110.7841, -8.1948],
      [110.7842, -8.1947],
      [110.7842, -8.1945],
      [110.7843, -8.1944],
      [110.7844, -8.1942],
      [110.7846, -8.1941],
      [110.7847, -8.194],
      [110.7849, -8.1939],
      [110.7851, -8.1937],
      [110.7852, -8.1937],
      [110.7854, -8.1937],
      [110.7856, -8.1937],
      [110.7858, -8.1936],
      [110.7859, -8.1936],
      [110.7861, -8.1936],
      [110.7863, -8.1936],
      [110.7865, -8.1935],
      [110.7865, -8.1933],
      [110.7864, -8.193],
      [110.7865, -8.1928],
      [110.7866, -8.1928],
      [110.7869, -8.1927],
      [110.787, -8.1926],
      [110.7872, -8.1925],
      [110.7872, -8.1926],
      [110.7873, -8.193],
      [110.7874, -8.1931],
      [110.7878, -8.1933],
      [110.7881, -8.1934],
      [110.7883, -8.1935],
      [110.7885, -8.1936],
      [110.7886, -8.1939],
      [110.7887, -8.1939],
      [110.789, -8.194],
      [110.7892, -8.1941],
      [110.7894, -8.1941],
      [110.7895, -8.194],
      [110.7895, -8.1939],
      [110.7897, -8.1936],
      [110.7898, -8.1934],
      [110.79, -8.1933],
      [110.7903, -8.1933],
      [110.7904, -8.1932],
      [110.7906, -8.1931],
      [110.7906, -8.1929],
      [110.7906, -8.1928],
      [110.7906, -8.1927],
      [110.7907, -8.1926],
      [110.7908, -8.1928],
      [110.7908, -8.1929],
      [110.7909, -8.1931],
      [110.7911, -8.1933],
      [110.7913, -8.1934],
      [110.7918, -8.1935],
      [110.7923, -8.1935],
      [110.7928, -8.1935],
      [110.7931, -8.1935],
      [110.7933, -8.1934],
      [110.7936, -8.1934],
      [110.7939, -8.1932],
      [110.7941, -8.1931],
      [110.7944, -8.193],
      [110.7948, -8.1931],
      [110.7949, -8.193],
      [110.7949, -8.1927],
      [110.7951, -8.1925],
      [110.7953, -8.1924],
      [110.7955, -8.1923],
      [110.7956, -8.1921],
      [110.7956, -8.1919],
      [110.7957, -8.1917],
      [110.7959, -8.1917],
      [110.7961, -8.1918],
      [110.7963, -8.1918],
      [110.7965, -8.1918],
      [110.7967, -8.1917],
      [110.797, -8.1916],
      [110.7971, -8.1914],
      [110.7972, -8.1912],
      [110.7972, -8.191],
      [110.7973, -8.1908],
      [110.7973, -8.1907],
      [110.7973, -8.1904],
      [110.7975, -8.1903],
      [110.7978, -8.1901],
      [110.7979, -8.1899],
      [110.7982, -8.1898],
      [110.7984, -8.1898],
      [110.7985, -8.1899],
      [110.7986, -8.1897],
      [110.7998, -8.1882],
      [110.8008, -8.1862],
      [110.8014, -8.1849],
      [110.8015, -8.1847],
      [110.802, -8.1825],
      [110.8025, -8.1821],
      [110.8026, -8.1817],
      [110.8029, -8.1795],
      [110.8031, -8.1791],
      [110.8035, -8.1785],
      [110.8039, -8.1778],
      [110.8043, -8.1772],
      [110.8052, -8.1763],
      [110.8057, -8.1755],
      [110.8061, -8.1728],
      [110.8057, -8.1715],
      [110.8051, -8.1704],
      [110.8045, -8.1694],
      [110.8041, -8.169],
      [110.8033, -8.1687],
      [110.8025, -8.1685],
      [110.8017, -8.1682],
      [110.8009, -8.1677],
      [110.8005, -8.1672],
      [110.7996, -8.1667],
      [110.7995, -8.1663],
      [110.7995, -8.1656],
      [110.7997, -8.1645],
      [110.8001, -8.1635],
      [110.8003, -8.1628],
      [110.8004, -8.1623],
      [110.8005, -8.1619],
      [110.8005, -8.1617],
      [110.8005, -8.1614]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'GIRISUBO',
    village: 'SONGBANYU',
    border: [
      [
        [110.8295, -8.14],
        [110.8296, -8.1395],
        [110.83, -8.1362],
        [110.8284, -8.1361],
        [110.8249, -8.1369],
        [110.8245, -8.137],
        [110.8216, -8.1379],
        [110.8207, -8.138],
        [110.817, -8.1385],
        [110.8144, -8.1384],
        [110.8139, -8.1395],
        [110.814, -8.141],
        [110.814, -8.1411],
        [110.8144, -8.143],
        [110.8143, -8.1454],
        [110.8143, -8.1455],
        [110.8139, -8.1458],
        [110.8138, -8.1457],
        [110.8135, -8.1459],
        [110.8134, -8.146],
        [110.8134, -8.1462],
        [110.8134, -8.1463],
        [110.8122, -8.1466],
        [110.8119, -8.147],
        [110.8116, -8.1474],
        [110.8116, -8.1476],
        [110.8118, -8.1481],
        [110.8119, -8.1484],
        [110.8119, -8.1487],
        [110.8117, -8.1492],
        [110.8113, -8.1497],
        [110.811, -8.15],
        [110.8109, -8.1504],
        [110.8108, -8.151],
        [110.8107, -8.1515],
        [110.8106, -8.1519],
        [110.8106, -8.1528],
        [110.8104, -8.1536],
        [110.8101, -8.154],
        [110.8098, -8.1544],
        [110.8097, -8.1549],
        [110.8097, -8.1554],
        [110.8095, -8.1556],
        [110.8088, -8.1561],
        [110.8084, -8.1565],
        [110.8077, -8.1566],
        [110.8072, -8.1568],
        [110.8068, -8.1571],
        [110.8062, -8.1575],
        [110.8057, -8.158],
        [110.805, -8.1585],
        [110.8042, -8.1592],
        [110.8037, -8.1595],
        [110.8032, -8.1598],
        [110.8027, -8.16],
        [110.8022, -8.1602],
        [110.8005, -8.1614],
        [110.8005, -8.1617],
        [110.8005, -8.1619],
        [110.8004, -8.1623],
        [110.8003, -8.1628],
        [110.8001, -8.1635],
        [110.7997, -8.1645],
        [110.7995, -8.1656],
        [110.7995, -8.1663],
        [110.7996, -8.1667],
        [110.8005, -8.1672],
        [110.8009, -8.1677],
        [110.8017, -8.1682],
        [110.8025, -8.1685],
        [110.8033, -8.1687],
        [110.8041, -8.169],
        [110.8045, -8.1694],
        [110.8051, -8.1704],
        [110.8057, -8.1715],
        [110.8061, -8.1728],
        [110.8057, -8.1755],
        [110.8052, -8.1763],
        [110.8043, -8.1772],
        [110.8039, -8.1778],
        [110.8035, -8.1785],
        [110.8031, -8.1791],
        [110.8029, -8.1795],
        [110.8026, -8.1817],
        [110.8025, -8.1821],
        [110.802, -8.1825],
        [110.8015, -8.1847],
        [110.8014, -8.1849],
        [110.8008, -8.1862],
        [110.7998, -8.1882],
        [110.7986, -8.1897],
        [110.7985, -8.1899],
        [110.7987, -8.19],
        [110.7988, -8.1901],
        [110.7991, -8.1903],
        [110.7992, -8.1904],
        [110.7993, -8.1906],
        [110.7994, -8.191],
        [110.7993, -8.1914],
        [110.7992, -8.1916],
        [110.799, -8.1918],
        [110.7987, -8.192],
        [110.7985, -8.1921],
        [110.7984, -8.1922],
        [110.7983, -8.1924],
        [110.7981, -8.1926],
        [110.798, -8.1927],
        [110.7979, -8.1929],
        [110.7978, -8.1932],
        [110.7977, -8.1934],
        [110.7977, -8.1936],
        [110.7977, -8.1937],
        [110.7978, -8.1938],
        [110.7979, -8.1939],
        [110.7981, -8.194],
        [110.7982, -8.1942],
        [110.7983, -8.1943],
        [110.7981, -8.1945],
        [110.798, -8.1946],
        [110.7979, -8.1947],
        [110.7978, -8.1949],
        [110.7977, -8.1951],
        [110.7976, -8.1952],
        [110.7975, -8.1953],
        [110.7974, -8.1954],
        [110.7974, -8.1955],
        [110.7974, -8.1956],
        [110.7974, -8.1958],
        [110.7975, -8.1959],
        [110.7977, -8.196],
        [110.7979, -8.1961],
        [110.7981, -8.1961],
        [110.7981, -8.1963],
        [110.7981, -8.1964],
        [110.7982, -8.1965],
        [110.7983, -8.1968],
        [110.7983, -8.1971],
        [110.7985, -8.1971],
        [110.7986, -8.1971],
        [110.7989, -8.197],
        [110.799, -8.1969],
        [110.7992, -8.1969],
        [110.7993, -8.197],
        [110.7993, -8.1972],
        [110.7994, -8.1975],
        [110.7994, -8.1977],
        [110.7995, -8.1979],
        [110.7996, -8.1979],
        [110.7998, -8.1978],
        [110.8, -8.1977],
        [110.8003, -8.1976],
        [110.8005, -8.1975],
        [110.8007, -8.1975],
        [110.801, -8.1975],
        [110.8013, -8.1976],
        [110.8015, -8.1977],
        [110.8017, -8.1978],
        [110.8019, -8.1979],
        [110.8022, -8.1979],
        [110.8024, -8.1979],
        [110.8025, -8.1978],
        [110.8025, -8.1975],
        [110.8027, -8.1973],
        [110.8029, -8.1973],
        [110.8032, -8.1975],
        [110.8034, -8.1976],
        [110.8035, -8.1978],
        [110.8036, -8.198],
        [110.8038, -8.198],
        [110.804, -8.198],
        [110.8042, -8.1979],
        [110.8044, -8.198],
        [110.8045, -8.1981],
        [110.8047, -8.1982],
        [110.8049, -8.1982],
        [110.8051, -8.1982],
        [110.8053, -8.1983],
        [110.8055, -8.1983],
        [110.8056, -8.1983],
        [110.8058, -8.1984],
        [110.8059, -8.1986],
        [110.8059, -8.1989],
        [110.8059, -8.1991],
        [110.8059, -8.1993],
        [110.8058, -8.1994],
        [110.8057, -8.1997],
        [110.8057, -8.1999],
        [110.8058, -8.2],
        [110.8061, -8.2],
        [110.8064, -8.2],
        [110.8067, -8.2],
        [110.8068, -8.2002],
        [110.8069, -8.2003],
        [110.8071, -8.2003],
        [110.8072, -8.2005],
        [110.8073, -8.2005],
        [110.8074, -8.2006],
        [110.8076, -8.2005],
        [110.8077, -8.2004],
        [110.8078, -8.2001],
        [110.808, -8.1999],
        [110.8082, -8.1997],
        [110.8083, -8.1996],
        [110.8086, -8.1995],
        [110.8087, -8.1995],
        [110.8089, -8.1994],
        [110.8091, -8.1994],
        [110.8093, -8.1994],
        [110.8095, -8.1993],
        [110.8096, -8.1991],
        [110.8098, -8.1991],
        [110.8101, -8.199],
        [110.8103, -8.1991],
        [110.8105, -8.1992],
        [110.8107, -8.1993],
        [110.811, -8.1993],
        [110.8111, -8.1996],
        [110.8111, -8.1997],
        [110.8111, -8.1998],
        [110.8113, -8.1999],
        [110.8116, -8.1999],
        [110.8119, -8.1999],
        [110.8121, -8.1998],
        [110.8123, -8.1999],
        [110.8127, -8.2],
        [110.8132, -8.2],
        [110.8135, -8.2],
        [110.8136, -8.2],
        [110.8139, -8.2],
        [110.8141, -8.1997],
        [110.8142, -8.1996],
        [110.8144, -8.1995],
        [110.8145, -8.1996],
        [110.8147, -8.1998],
        [110.815, -8.1999],
        [110.8153, -8.1999],
        [110.8155, -8.2],
        [110.8157, -8.2],
        [110.8159, -8.2],
        [110.8161, -8.1999],
        [110.8163, -8.2],
        [110.8167, -8.2],
        [110.817, -8.2001],
        [110.8173, -8.2],
        [110.8177, -8.2],
        [110.818, -8.2],
        [110.8182, -8.2],
        [110.8184, -8.2001],
        [110.8186, -8.2001],
        [110.8187, -8.2004],
        [110.8188, -8.2005],
        [110.8188, -8.2008],
        [110.8188, -8.2011],
        [110.8188, -8.2013],
        [110.8189, -8.2016],
        [110.819, -8.2019],
        [110.8192, -8.202],
        [110.8194, -8.2022],
        [110.8195, -8.2023],
        [110.8196, -8.2024],
        [110.8197, -8.2027],
        [110.8197, -8.2028],
        [110.8198, -8.2028],
        [110.8199, -8.2028],
        [110.82, -8.2026],
        [110.8201, -8.2023],
        [110.8203, -8.2022],
        [110.8205, -8.2022],
        [110.8206, -8.2022],
        [110.8209, -8.2022],
        [110.8211, -8.2023],
        [110.8212, -8.2024],
        [110.8212, -8.2025],
        [110.8212, -8.2027],
        [110.8209, -8.2028],
        [110.8207, -8.2029],
        [110.8206, -8.2031],
        [110.8206, -8.2032],
        [110.8207, -8.2034],
        [110.8209, -8.2034],
        [110.8212, -8.2033],
        [110.8213, -8.2033],
        [110.8217, -8.2032],
        [110.8219, -8.2031],
        [110.8221, -8.2031],
        [110.8223, -8.2032],
        [110.8224, -8.2032],
        [110.8226, -8.2032],
        [110.8228, -8.2031],
        [110.823, -8.203],
        [110.8231, -8.2028],
        [110.8233, -8.2027],
        [110.8235, -8.2026],
        [110.8237, -8.2025],
        [110.8241, -8.2025],
        [110.8243, -8.2025],
        [110.8246, -8.2025],
        [110.8247, -8.2026],
        [110.8249, -8.2026],
        [110.825, -8.2026],
        [110.8252, -8.2027],
        [110.8253, -8.2029],
        [110.8255, -8.203],
        [110.8256, -8.2032],
        [110.8258, -8.2032],
        [110.826, -8.2031],
        [110.8262, -8.203],
        [110.8264, -8.203],
        [110.8267, -8.203],
        [110.8269, -8.2031],
        [110.827, -8.2033],
        [110.8271, -8.2034],
        [110.8272, -8.2035],
        [110.8272, -8.2036],
        [110.8273, -8.2037],
        [110.8274, -8.2038],
        [110.8276, -8.2037],
        [110.8277, -8.2037],
        [110.8278, -8.2038],
        [110.828, -8.204],
        [110.8282, -8.2042],
        [110.8285, -8.2043],
        [110.8286, -8.2043],
        [110.8289, -8.2042],
        [110.8291, -8.2039],
        [110.8293, -8.2036],
        [110.8294, -8.2034],
        [110.8295, -8.2034],
        [110.8297, -8.2034],
        [110.8299, -8.2033],
        [110.8306, -8.204],
        [110.8322, -8.2042],
        [110.8331, -8.2042],
        [110.8344, -8.2035],
        [110.8345, -8.2034],
        [110.8347, -8.2032],
        [110.8348, -8.2029],
        [110.8337, -8.2006],
        [110.8331, -8.1986],
        [110.8333, -8.1966],
        [110.8333, -8.1955],
        [110.8341, -8.1941],
        [110.8347, -8.1917],
        [110.8347, -8.1901],
        [110.8348, -8.1895],
        [110.8347, -8.1892],
        [110.8347, -8.1887],
        [110.8345, -8.1878],
        [110.8343, -8.1875],
        [110.8351, -8.1861],
        [110.8366, -8.1848],
        [110.8383, -8.182],
        [110.8386, -8.1799],
        [110.8386, -8.1794],
        [110.8387, -8.179],
        [110.8387, -8.178],
        [110.8381, -8.1775],
        [110.8374, -8.1765],
        [110.8363, -8.1762],
        [110.8337, -8.1741],
        [110.8336, -8.174],
        [110.8332, -8.1736],
        [110.8327, -8.1733],
        [110.8326, -8.1717],
        [110.832, -8.1701],
        [110.8314, -8.1689],
        [110.83, -8.168],
        [110.8281, -8.1678],
        [110.8251, -8.1676],
        [110.8246, -8.1667],
        [110.8243, -8.1659],
        [110.824, -8.1654],
        [110.8237, -8.1642],
        [110.8236, -8.1633],
        [110.8235, -8.1625],
        [110.8231, -8.1613],
        [110.8227, -8.161],
        [110.8221, -8.1608],
        [110.8218, -8.1607],
        [110.8216, -8.1606],
        [110.8212, -8.1603],
        [110.8208, -8.16],
        [110.8204, -8.1597],
        [110.8201, -8.1595],
        [110.8194, -8.1593],
        [110.8184, -8.1586],
        [110.8176, -8.1563],
        [110.8182, -8.1547],
        [110.8193, -8.1523],
        [110.82, -8.1515],
        [110.8218, -8.1495],
        [110.8237, -8.1491],
        [110.8261, -8.1482],
        [110.8284, -8.1454],
        [110.8288, -8.1434],
        [110.829, -8.1417],
        [110.8295, -8.14]
      ]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TANJUNGSARI',
    village: 'HARGOSARI',
    border: [
      [110.6406, -8.046],
      [110.6395, -8.0452],
      [110.6387, -8.0447],
      [110.6385, -8.0446],
      [110.6381, -8.0446],
      [110.6373, -8.0447],
      [110.6361, -8.0452],
      [110.6358, -8.0453],
      [110.6356, -8.0454],
      [110.6353, -8.0456],
      [110.635, -8.0458],
      [110.6349, -8.0458],
      [110.6339, -8.0459],
      [110.6331, -8.0459],
      [110.6325, -8.0459],
      [110.6316, -8.0461],
      [110.6301, -8.0463],
      [110.6291, -8.0463],
      [110.6285, -8.0463],
      [110.6275, -8.0463],
      [110.6268, -8.046],
      [110.6262, -8.0455],
      [110.6257, -8.045],
      [110.6253, -8.0444],
      [110.6247, -8.0443],
      [110.6237, -8.044],
      [110.6225, -8.0436],
      [110.6221, -8.0433],
      [110.6209, -8.0428],
      [110.6199, -8.0421],
      [110.6188, -8.0416],
      [110.6174, -8.0416],
      [110.6145, -8.0416],
      [110.613, -8.0417],
      [110.612, -8.0419],
      [110.6105, -8.0433],
      [110.6098, -8.0441],
      [110.6085, -8.0458],
      [110.6078, -8.0465],
      [110.6061, -8.0476],
      [110.6057, -8.0484],
      [110.6053, -8.0494],
      [110.6054, -8.0502],
      [110.6054, -8.0513],
      [110.6059, -8.0519],
      [110.6065, -8.0524],
      [110.6071, -8.0528],
      [110.6073, -8.0536],
      [110.6075, -8.0547],
      [110.6074, -8.0554],
      [110.6071, -8.0559],
      [110.6069, -8.056],
      [110.6072, -8.0564],
      [110.6074, -8.0569],
      [110.6074, -8.0576],
      [110.6075, -8.0581],
      [110.6075, -8.0585],
      [110.6074, -8.0591],
      [110.6076, -8.0595],
      [110.6077, -8.0597],
      [110.6092, -8.0606],
      [110.6099, -8.0618],
      [110.6104, -8.0632],
      [110.6109, -8.0642],
      [110.6116, -8.0652],
      [110.6127, -8.066],
      [110.614, -8.0662],
      [110.6159, -8.066],
      [110.6172, -8.066],
      [110.6192, -8.0664],
      [110.6229, -8.068],
      [110.6251, -8.0689],
      [110.6258, -8.0694],
      [110.6265, -8.0698],
      [110.627, -8.0698],
      [110.6279, -8.0696],
      [110.6281, -8.0696],
      [110.6291, -8.0697],
      [110.6299, -8.0701],
      [110.6313, -8.0706],
      [110.6324, -8.0712],
      [110.6328, -8.0715],
      [110.6343, -8.0729],
      [110.635, -8.0733],
      [110.6354, -8.0737],
      [110.6359, -8.0735],
      [110.6366, -8.0729],
      [110.6371, -8.0723],
      [110.6377, -8.0716],
      [110.6378, -8.0713],
      [110.6378, -8.0712],
      [110.6379, -8.0708],
      [110.6371, -8.0696],
      [110.6369, -8.0691],
      [110.6365, -8.0687],
      [110.6363, -8.0683],
      [110.6363, -8.0676],
      [110.6364, -8.067],
      [110.6362, -8.0662],
      [110.6355, -8.0651],
      [110.6346, -8.0643],
      [110.6338, -8.0638],
      [110.6328, -8.0633],
      [110.6317, -8.0628],
      [110.6308, -8.0623],
      [110.6299, -8.062],
      [110.6288, -8.0613],
      [110.6281, -8.0605],
      [110.6277, -8.0601],
      [110.6281, -8.059],
      [110.6282, -8.0586],
      [110.6286, -8.0582],
      [110.6288, -8.0574],
      [110.6289, -8.057],
      [110.6293, -8.0564],
      [110.6299, -8.0558],
      [110.6305, -8.0552],
      [110.6313, -8.0549],
      [110.6319, -8.0546],
      [110.6327, -8.0544],
      [110.6341, -8.0542],
      [110.635, -8.054],
      [110.6363, -8.0536],
      [110.637, -8.0536],
      [110.6375, -8.0541],
      [110.6382, -8.0549],
      [110.6395, -8.056],
      [110.64, -8.0562],
      [110.6406, -8.0565],
      [110.6414, -8.0565],
      [110.6424, -8.0564],
      [110.6432, -8.0563],
      [110.6445, -8.0566],
      [110.645, -8.0569],
      [110.6454, -8.0571],
      [110.6457, -8.0571],
      [110.6455, -8.0558],
      [110.6454, -8.0545],
      [110.645, -8.0526],
      [110.6446, -8.0512],
      [110.6441, -8.05],
      [110.6437, -8.0491],
      [110.643, -8.048],
      [110.6426, -8.0476],
      [110.6416, -8.0467],
      [110.6406, -8.046]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TANJUNGSARI',
    village: 'KEMIRI',
    border: [
      [110.5807, -8.058],
      [110.58, -8.0572],
      [110.5787, -8.0555],
      [110.578, -8.0549],
      [110.5746, -8.0525],
      [110.5718, -8.051],
      [110.5695, -8.0502],
      [110.5685, -8.0499],
      [110.5654, -8.0499],
      [110.5633, -8.0499],
      [110.5632, -8.0499],
      [110.5631, -8.0502],
      [110.563, -8.0512],
      [110.5627, -8.0519],
      [110.5624, -8.0525],
      [110.5615, -8.0538],
      [110.5604, -8.0552],
      [110.5598, -8.056],
      [110.5593, -8.0569],
      [110.5595, -8.0577],
      [110.5595, -8.0585],
      [110.5595, -8.0592],
      [110.5592, -8.0601],
      [110.559, -8.0605],
      [110.5589, -8.0612],
      [110.5588, -8.0617],
      [110.5587, -8.0623],
      [110.5589, -8.0628],
      [110.5592, -8.0636],
      [110.5593, -8.0641],
      [110.5599, -8.0652],
      [110.5604, -8.0663],
      [110.5607, -8.0672],
      [110.5609, -8.0681],
      [110.561, -8.0692],
      [110.5611, -8.0705],
      [110.5612, -8.0712],
      [110.5613, -8.0715],
      [110.5612, -8.0727],
      [110.5611, -8.0735],
      [110.5609, -8.074],
      [110.5604, -8.0747],
      [110.56, -8.0752],
      [110.5597, -8.0755],
      [110.5596, -8.0757],
      [110.5597, -8.0757],
      [110.5603, -8.0758],
      [110.5617, -8.0766],
      [110.5637, -8.0779],
      [110.5662, -8.0791],
      [110.5676, -8.0803],
      [110.5686, -8.081],
      [110.5702, -8.0822],
      [110.5724, -8.0839],
      [110.5744, -8.0855],
      [110.575, -8.0859],
      [110.5759, -8.0861],
      [110.5773, -8.0862],
      [110.5796, -8.0864],
      [110.5818, -8.0865],
      [110.5825, -8.0866],
      [110.5827, -8.0855],
      [110.5839, -8.083],
      [110.5849, -8.0811],
      [110.5855, -8.0801],
      [110.5857, -8.079],
      [110.5859, -8.0784],
      [110.586, -8.0776],
      [110.5862, -8.0769],
      [110.5865, -8.0764],
      [110.5872, -8.076],
      [110.5877, -8.0757],
      [110.5879, -8.0752],
      [110.5877, -8.074],
      [110.5874, -8.0728],
      [110.5873, -8.0723],
      [110.5876, -8.072],
      [110.5878, -8.0714],
      [110.5879, -8.0712],
      [110.588, -8.071],
      [110.5888, -8.0706],
      [110.5908, -8.0702],
      [110.5934, -8.0695],
      [110.5944, -8.0693],
      [110.5954, -8.0693],
      [110.5964, -8.0693],
      [110.5971, -8.0693],
      [110.5979, -8.0692],
      [110.5985, -8.0687],
      [110.5988, -8.0678],
      [110.599, -8.0671],
      [110.5988, -8.0655],
      [110.5989, -8.0648],
      [110.5991, -8.0638],
      [110.5992, -8.0631],
      [110.5992, -8.0626],
      [110.599, -8.0622],
      [110.5984, -8.0622],
      [110.5981, -8.0618],
      [110.598, -8.0608],
      [110.5973, -8.0591],
      [110.5962, -8.057],
      [110.5961, -8.0564],
      [110.5953, -8.0564],
      [110.593, -8.0573],
      [110.5918, -8.0581],
      [110.5907, -8.0586],
      [110.5877, -8.0596],
      [110.5866, -8.0601],
      [110.5861, -8.0602],
      [110.5855, -8.0602],
      [110.5844, -8.0599],
      [110.5833, -8.0596],
      [110.5825, -8.0596],
      [110.5818, -8.0593],
      [110.5812, -8.0586],
      [110.5807, -8.058]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TANJUNGSARI',
    village: 'KEMADANG',
    border: [
      [
        [110.5825, -8.0866],
        [110.5818, -8.0865],
        [110.5796, -8.0864],
        [110.5773, -8.0862],
        [110.5759, -8.0861],
        [110.575, -8.0859],
        [110.5744, -8.0855],
        [110.5724, -8.0839],
        [110.5702, -8.0822],
        [110.5686, -8.081],
        [110.5676, -8.0803],
        [110.5662, -8.0791],
        [110.5637, -8.0779],
        [110.5617, -8.0766],
        [110.5603, -8.0758],
        [110.5597, -8.0757],
        [110.5596, -8.0757],
        [110.5594, -8.076],
        [110.5593, -8.0765],
        [110.5593, -8.0772],
        [110.5591, -8.0777],
        [110.559, -8.078],
        [110.5587, -8.0787],
        [110.5587, -8.0797],
        [110.5588, -8.0805],
        [110.5593, -8.082],
        [110.5593, -8.0823],
        [110.5595, -8.0829],
        [110.5596, -8.0837],
        [110.5594, -8.0851],
        [110.5593, -8.0873],
        [110.5593, -8.0894],
        [110.5591, -8.0915],
        [110.5591, -8.0927],
        [110.5594, -8.0941],
        [110.5591, -8.0956],
        [110.5582, -8.0984],
        [110.5571, -8.1],
        [110.5555, -8.102],
        [110.5547, -8.1034],
        [110.5541, -8.1044],
        [110.5535, -8.1059],
        [110.5529, -8.1073],
        [110.5519, -8.1098],
        [110.5516, -8.1105],
        [110.5511, -8.1112],
        [110.5505, -8.1117],
        [110.55, -8.112],
        [110.5496, -8.1126],
        [110.5492, -8.1132],
        [110.5486, -8.114],
        [110.5481, -8.1152],
        [110.5477, -8.1165],
        [110.5473, -8.1172],
        [110.5468, -8.1179],
        [110.5466, -8.118],
        [110.5459, -8.1185],
        [110.5452, -8.1194],
        [110.5447, -8.1199],
        [110.544, -8.121],
        [110.5435, -8.122],
        [110.5433, -8.123],
        [110.5437, -8.1238],
        [110.5441, -8.1247],
        [110.5444, -8.1252],
        [110.5449, -8.1257],
        [110.5454, -8.1262],
        [110.5454, -8.1267],
        [110.5455, -8.1269],
        [110.5462, -8.1276],
        [110.5469, -8.1282],
        [110.5478, -8.1286],
        [110.5478, -8.1284],
        [110.5479, -8.1284],
        [110.548, -8.1285],
        [110.5482, -8.1286],
        [110.5484, -8.1291],
        [110.5483, -8.1294],
        [110.5483, -8.1296],
        [110.5484, -8.1299],
        [110.5485, -8.1301],
        [110.5485, -8.1303],
        [110.5485, -8.1305],
        [110.5485, -8.1306],
        [110.5484, -8.1312],
        [110.5484, -8.1314],
        [110.5485, -8.1318],
        [110.5486, -8.1319],
        [110.5487, -8.132],
        [110.5489, -8.1322],
        [110.549, -8.1323],
        [110.5491, -8.1325],
        [110.5492, -8.1326],
        [110.5493, -8.133],
        [110.5494, -8.1332],
        [110.5493, -8.1334],
        [110.5492, -8.1336],
        [110.5491, -8.1338],
        [110.5491, -8.134],
        [110.5494, -8.1341],
        [110.5496, -8.1341],
        [110.5497, -8.1341],
        [110.55, -8.1341],
        [110.55, -8.1345],
        [110.5501, -8.1346],
        [110.5502, -8.1345],
        [110.5504, -8.1344],
        [110.5507, -8.1345],
        [110.5508, -8.1345],
        [110.551, -8.1344],
        [110.5513, -8.1344],
        [110.5512, -8.1342],
        [110.5512, -8.134],
        [110.5514, -8.1339],
        [110.5515, -8.1338],
        [110.5517, -8.1337],
        [110.5518, -8.1337],
        [110.5518, -8.1339],
        [110.5519, -8.134],
        [110.5521, -8.134],
        [110.5522, -8.1339],
        [110.5522, -8.1338],
        [110.5522, -8.1336],
        [110.5523, -8.1335],
        [110.5526, -8.1335],
        [110.5529, -8.1334],
        [110.553, -8.1335],
        [110.5532, -8.1336],
        [110.5534, -8.1335],
        [110.5535, -8.1335],
        [110.5538, -8.1337],
        [110.5541, -8.1337],
        [110.5546, -8.1338],
        [110.5551, -8.134],
        [110.5555, -8.1342],
        [110.5558, -8.1342],
        [110.5563, -8.1342],
        [110.5567, -8.1342],
        [110.5569, -8.1342],
        [110.5571, -8.1342],
        [110.5575, -8.1342],
        [110.5577, -8.1343],
        [110.5579, -8.1345],
        [110.558, -8.1346],
        [110.5583, -8.1347],
        [110.5586, -8.1348],
        [110.5589, -8.1349],
        [110.5591, -8.1352],
        [110.5593, -8.1352],
        [110.5595, -8.1353],
        [110.5597, -8.1354],
        [110.5598, -8.1354],
        [110.56, -8.1354],
        [110.5602, -8.1354],
        [110.5603, -8.1357],
        [110.5604, -8.1358],
        [110.5606, -8.1359],
        [110.5609, -8.1359],
        [110.5613, -8.1359],
        [110.5615, -8.1359],
        [110.5617, -8.136],
        [110.5618, -8.1361],
        [110.5618, -8.1363],
        [110.5619, -8.1363],
        [110.562, -8.1363],
        [110.5622, -8.1363],
        [110.5624, -8.1364],
        [110.5628, -8.1364],
        [110.563, -8.1365],
        [110.5631, -8.1367],
        [110.5633, -8.1367],
        [110.5635, -8.1367],
        [110.5637, -8.1368],
        [110.5639, -8.1367],
        [110.5642, -8.1367],
        [110.5648, -8.1369],
        [110.5652, -8.137],
        [110.566, -8.1372],
        [110.5665, -8.1373],
        [110.567, -8.1374],
        [110.5675, -8.1375],
        [110.568, -8.1375],
        [110.5684, -8.1375],
        [110.5688, -8.1375],
        [110.5692, -8.1375],
        [110.5694, -8.1375],
        [110.5696, -8.1376],
        [110.5697, -8.1376],
        [110.5698, -8.1375],
        [110.5698, -8.1374],
        [110.57, -8.1374],
        [110.5703, -8.1374],
        [110.5707, -8.1375],
        [110.571, -8.1375],
        [110.5713, -8.1374],
        [110.5715, -8.1374],
        [110.5719, -8.1374],
        [110.5721, -8.1374],
        [110.5722, -8.1374],
        [110.5723, -8.1375],
        [110.5725, -8.1375],
        [110.5727, -8.1375],
        [110.573, -8.1375],
        [110.5731, -8.1374],
        [110.5732, -8.1374],
        [110.5734, -8.1373],
        [110.5738, -8.1376],
        [110.5743, -8.1377],
        [110.5748, -8.1377],
        [110.5751, -8.1378],
        [110.5755, -8.1378],
        [110.5757, -8.1379],
        [110.5757, -8.1381],
        [110.5758, -8.1383],
        [110.5758, -8.1384],
        [110.576, -8.1383],
        [110.5761, -8.1381],
        [110.5761, -8.138],
        [110.5762, -8.1376],
        [110.5762, -8.1366],
        [110.5762, -8.1354],
        [110.5761, -8.1344],
        [110.5764, -8.1335],
        [110.5768, -8.1329],
        [110.5773, -8.1313],
        [110.5781, -8.1294],
        [110.5785, -8.128],
        [110.5782, -8.1276],
        [110.5764, -8.1268],
        [110.574, -8.1264],
        [110.5712, -8.1261],
        [110.5688, -8.1258],
        [110.5679, -8.1255],
        [110.5673, -8.1251],
        [110.5675, -8.1246],
        [110.5675, -8.1239],
        [110.5674, -8.1232],
        [110.5674, -8.1227],
        [110.5678, -8.1224],
        [110.5679, -8.1218],
        [110.568, -8.1212],
        [110.5682, -8.1209],
        [110.5689, -8.1209],
        [110.5694, -8.1208],
        [110.5697, -8.1207],
        [110.5698, -8.12],
        [110.5697, -8.1194],
        [110.5698, -8.1185],
        [110.5702, -8.1174],
        [110.5706, -8.1166],
        [110.571, -8.1161],
        [110.5711, -8.1154],
        [110.5712, -8.1149],
        [110.5714, -8.1136],
        [110.5718, -8.1128],
        [110.5721, -8.1124],
        [110.5729, -8.1122],
        [110.5749, -8.1117],
        [110.5774, -8.111],
        [110.5782, -8.1104],
        [110.5786, -8.1101],
        [110.58, -8.1085],
        [110.5813, -8.1062],
        [110.5823, -8.1048],
        [110.5838, -8.1031],
        [110.5842, -8.1024],
        [110.5851, -8.1006],
        [110.5852, -8.0989],
        [110.5848, -8.0983],
        [110.5844, -8.0975],
        [110.5843, -8.097],
        [110.5844, -8.096],
        [110.584, -8.0958],
        [110.5832, -8.0947],
        [110.5822, -8.0939],
        [110.5819, -8.0933],
        [110.5819, -8.0924],
        [110.5817, -8.0917],
        [110.5814, -8.0912],
        [110.5813, -8.0908],
        [110.5815, -8.0899],
        [110.5816, -8.0894],
        [110.582, -8.0888],
        [110.5822, -8.0883],
        [110.5825, -8.0876],
        [110.5825, -8.0866]
      ]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TANJUNGSARI',
    village: 'BANJAREJO',
    border: [
      [
        [110.6127, -8.066],
        [110.6116, -8.0652],
        [110.6109, -8.0642],
        [110.6104, -8.0632],
        [110.6099, -8.0618],
        [110.6092, -8.0606],
        [110.6077, -8.0597],
        [110.6076, -8.0595],
        [110.6074, -8.0591],
        [110.6075, -8.0585],
        [110.6075, -8.0581],
        [110.6074, -8.0576],
        [110.6074, -8.0569],
        [110.6072, -8.0564],
        [110.6069, -8.056],
        [110.6058, -8.0562],
        [110.6054, -8.0565],
        [110.6029, -8.0566],
        [110.6022, -8.0567],
        [110.5992, -8.0567],
        [110.5982, -8.0568],
        [110.5964, -8.0564],
        [110.5961, -8.0564],
        [110.5962, -8.057],
        [110.5973, -8.0591],
        [110.598, -8.0608],
        [110.5981, -8.0618],
        [110.5984, -8.0622],
        [110.599, -8.0622],
        [110.5992, -8.0626],
        [110.5992, -8.0631],
        [110.5991, -8.0638],
        [110.5989, -8.0648],
        [110.5988, -8.0655],
        [110.599, -8.0671],
        [110.5988, -8.0678],
        [110.5985, -8.0687],
        [110.5979, -8.0692],
        [110.5971, -8.0693],
        [110.5964, -8.0693],
        [110.5954, -8.0693],
        [110.5944, -8.0693],
        [110.5934, -8.0695],
        [110.5908, -8.0702],
        [110.5888, -8.0706],
        [110.588, -8.071],
        [110.5879, -8.0712],
        [110.5878, -8.0714],
        [110.5876, -8.072],
        [110.5873, -8.0723],
        [110.5874, -8.0728],
        [110.5877, -8.074],
        [110.5879, -8.0752],
        [110.5877, -8.0757],
        [110.5872, -8.076],
        [110.5865, -8.0764],
        [110.5862, -8.0769],
        [110.586, -8.0776],
        [110.5859, -8.0784],
        [110.5857, -8.079],
        [110.5855, -8.0801],
        [110.5849, -8.0811],
        [110.5839, -8.083],
        [110.5827, -8.0855],
        [110.5825, -8.0866],
        [110.5825, -8.0876],
        [110.5822, -8.0883],
        [110.582, -8.0888],
        [110.5816, -8.0894],
        [110.5815, -8.0899],
        [110.5813, -8.0908],
        [110.5814, -8.0912],
        [110.5817, -8.0917],
        [110.5819, -8.0924],
        [110.5819, -8.0933],
        [110.5822, -8.0939],
        [110.5832, -8.0947],
        [110.584, -8.0958],
        [110.5844, -8.096],
        [110.5843, -8.097],
        [110.5844, -8.0975],
        [110.5848, -8.0983],
        [110.5852, -8.0989],
        [110.5851, -8.1006],
        [110.5842, -8.1024],
        [110.5838, -8.1031],
        [110.5823, -8.1048],
        [110.5813, -8.1062],
        [110.58, -8.1085],
        [110.5786, -8.1101],
        [110.5782, -8.1104],
        [110.5774, -8.111],
        [110.5749, -8.1117],
        [110.5729, -8.1122],
        [110.5721, -8.1124],
        [110.5718, -8.1128],
        [110.5714, -8.1136],
        [110.5712, -8.1149],
        [110.5711, -8.1154],
        [110.571, -8.1161],
        [110.5706, -8.1166],
        [110.5702, -8.1174],
        [110.5698, -8.1185],
        [110.5697, -8.1194],
        [110.5698, -8.12],
        [110.5697, -8.1207],
        [110.5694, -8.1208],
        [110.5689, -8.1209],
        [110.5682, -8.1209],
        [110.568, -8.1212],
        [110.5679, -8.1218],
        [110.5678, -8.1224],
        [110.5674, -8.1227],
        [110.5674, -8.1232],
        [110.5675, -8.1239],
        [110.5675, -8.1246],
        [110.5673, -8.1251],
        [110.5679, -8.1255],
        [110.5688, -8.1258],
        [110.5712, -8.1261],
        [110.574, -8.1264],
        [110.5764, -8.1268],
        [110.5782, -8.1276],
        [110.5785, -8.128],
        [110.5781, -8.1294],
        [110.5773, -8.1313],
        [110.5768, -8.1329],
        [110.5764, -8.1335],
        [110.5761, -8.1344],
        [110.5762, -8.1354],
        [110.5762, -8.1366],
        [110.5762, -8.1376],
        [110.5761, -8.138],
        [110.5761, -8.1381],
        [110.5763, -8.1381],
        [110.5766, -8.1382],
        [110.5768, -8.1384],
        [110.577, -8.1385],
        [110.5773, -8.1387],
        [110.5775, -8.1388],
        [110.5777, -8.1388],
        [110.5779, -8.1388],
        [110.5781, -8.1385],
        [110.5782, -8.1384],
        [110.5784, -8.1383],
        [110.5787, -8.1382],
        [110.5788, -8.1382],
        [110.579, -8.1386],
        [110.579, -8.1388],
        [110.5792, -8.1389],
        [110.5793, -8.139],
        [110.5795, -8.139],
        [110.5797, -8.139],
        [110.5798, -8.1392],
        [110.5799, -8.1394],
        [110.5801, -8.1395],
        [110.5803, -8.1395],
        [110.5805, -8.1395],
        [110.5806, -8.1397],
        [110.5808, -8.1398],
        [110.581, -8.1398],
        [110.581, -8.1391],
        [110.5813, -8.1386],
        [110.5819, -8.1383],
        [110.5825, -8.1377],
        [110.5831, -8.1373],
        [110.5836, -8.1367],
        [110.584, -8.1363],
        [110.5846, -8.1359],
        [110.5851, -8.1355],
        [110.5853, -8.1352],
        [110.5854, -8.1346],
        [110.5857, -8.134],
        [110.5861, -8.1337],
        [110.5863, -8.1335],
        [110.5866, -8.133],
        [110.587, -8.1322],
        [110.5873, -8.1319],
        [110.5882, -8.1315],
        [110.5885, -8.131],
        [110.5888, -8.1303],
        [110.5885, -8.1296],
        [110.5874, -8.1284],
        [110.5868, -8.1277],
        [110.5863, -8.1271],
        [110.5859, -8.1264],
        [110.5856, -8.1258],
        [110.5859, -8.1251],
        [110.5864, -8.1247],
        [110.5865, -8.1246],
        [110.5868, -8.1246],
        [110.5871, -8.1242],
        [110.5875, -8.1239],
        [110.5881, -8.1236],
        [110.589, -8.1227],
        [110.5897, -8.1218],
        [110.5899, -8.1214],
        [110.5903, -8.1206],
        [110.5904, -8.1196],
        [110.5906, -8.1187],
        [110.5912, -8.1174],
        [110.5918, -8.1163],
        [110.5927, -8.1153],
        [110.5936, -8.1146],
        [110.5943, -8.1143],
        [110.5949, -8.114],
        [110.5963, -8.1128],
        [110.5968, -8.1125],
        [110.597, -8.1119],
        [110.5975, -8.111],
        [110.5979, -8.111],
        [110.5981, -8.1106],
        [110.5986, -8.1103],
        [110.5989, -8.11],
        [110.599, -8.109],
        [110.5992, -8.1087],
        [110.5995, -8.1085],
        [110.5997, -8.1081],
        [110.5998, -8.1073],
        [110.5998, -8.1066],
        [110.5998, -8.1061],
        [110.6, -8.1053],
        [110.6004, -8.1045],
        [110.6007, -8.1036],
        [110.6011, -8.1026],
        [110.6021, -8.1016],
        [110.6036, -8.1004],
        [110.6047, -8.0983],
        [110.6052, -8.0971],
        [110.6056, -8.095],
        [110.6056, -8.0931],
        [110.6054, -8.0906],
        [110.6051, -8.0891],
        [110.6045, -8.0882],
        [110.6043, -8.0879],
        [110.6043, -8.0874],
        [110.6046, -8.087],
        [110.6054, -8.0865],
        [110.6062, -8.0859],
        [110.6066, -8.0852],
        [110.6067, -8.0834],
        [110.6068, -8.0828],
        [110.6075, -8.0809],
        [110.608, -8.0791],
        [110.608, -8.0768],
        [110.6079, -8.0761],
        [110.6078, -8.0751],
        [110.6077, -8.0745],
        [110.608, -8.0729],
        [110.608, -8.072],
        [110.6079, -8.0712],
        [110.6078, -8.0711],
        [110.6077, -8.0707],
        [110.6077, -8.0705],
        [110.6077, -8.0704],
        [110.6077, -8.0702],
        [110.6074, -8.0695],
        [110.6073, -8.0686],
        [110.6071, -8.0681],
        [110.6076, -8.0677],
        [110.6084, -8.0676],
        [110.6096, -8.0672],
        [110.6103, -8.0669],
        [110.6113, -8.0667],
        [110.6121, -8.0665],
        [110.6127, -8.066]
      ]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'TANJUNGSARI',
    village: 'NGESTIREJO',
    border: [
      [
        [110.6354, -8.0737],
        [110.635, -8.0733],
        [110.6343, -8.0729],
        [110.6328, -8.0715],
        [110.6324, -8.0712],
        [110.6313, -8.0706],
        [110.6299, -8.0701],
        [110.6291, -8.0697],
        [110.6281, -8.0696],
        [110.6279, -8.0696],
        [110.627, -8.0698],
        [110.6265, -8.0698],
        [110.6258, -8.0694],
        [110.6251, -8.0689],
        [110.6229, -8.068],
        [110.6192, -8.0664],
        [110.6172, -8.066],
        [110.6159, -8.066],
        [110.614, -8.0662],
        [110.6127, -8.066],
        [110.6121, -8.0665],
        [110.6113, -8.0667],
        [110.6103, -8.0669],
        [110.6096, -8.0672],
        [110.6084, -8.0676],
        [110.6076, -8.0677],
        [110.6071, -8.0681],
        [110.6073, -8.0686],
        [110.6074, -8.0695],
        [110.6077, -8.0702],
        [110.6077, -8.0704],
        [110.6077, -8.0705],
        [110.6077, -8.0707],
        [110.6078, -8.0711],
        [110.6079, -8.0712],
        [110.608, -8.072],
        [110.608, -8.0729],
        [110.6077, -8.0745],
        [110.6078, -8.0751],
        [110.6079, -8.0761],
        [110.608, -8.0768],
        [110.608, -8.0791],
        [110.6075, -8.0809],
        [110.6068, -8.0828],
        [110.6067, -8.0834],
        [110.6066, -8.0852],
        [110.6062, -8.0859],
        [110.6054, -8.0865],
        [110.6046, -8.087],
        [110.6043, -8.0874],
        [110.6043, -8.0879],
        [110.6045, -8.0882],
        [110.6051, -8.0891],
        [110.6054, -8.0906],
        [110.6056, -8.0931],
        [110.6056, -8.095],
        [110.6052, -8.0971],
        [110.6047, -8.0983],
        [110.6036, -8.1004],
        [110.6021, -8.1016],
        [110.6011, -8.1026],
        [110.6007, -8.1036],
        [110.6004, -8.1045],
        [110.6, -8.1053],
        [110.5998, -8.1061],
        [110.5998, -8.1066],
        [110.5998, -8.1073],
        [110.5997, -8.1081],
        [110.5995, -8.1085],
        [110.5992, -8.1087],
        [110.599, -8.109],
        [110.5989, -8.11],
        [110.5986, -8.1103],
        [110.5981, -8.1106],
        [110.5979, -8.111],
        [110.5975, -8.111],
        [110.597, -8.1119],
        [110.5968, -8.1125],
        [110.5963, -8.1128],
        [110.5949, -8.114],
        [110.5943, -8.1143],
        [110.5936, -8.1146],
        [110.5927, -8.1153],
        [110.5918, -8.1163],
        [110.5912, -8.1174],
        [110.5906, -8.1187],
        [110.5904, -8.1196],
        [110.5903, -8.1206],
        [110.5899, -8.1214],
        [110.5897, -8.1218],
        [110.589, -8.1227],
        [110.5881, -8.1236],
        [110.5875, -8.1239],
        [110.5871, -8.1242],
        [110.5868, -8.1246],
        [110.5865, -8.1246],
        [110.5864, -8.1247],
        [110.5859, -8.1251],
        [110.5856, -8.1258],
        [110.5859, -8.1264],
        [110.5863, -8.1271],
        [110.5868, -8.1277],
        [110.5874, -8.1284],
        [110.5885, -8.1296],
        [110.5888, -8.1303],
        [110.5885, -8.131],
        [110.5882, -8.1315],
        [110.5873, -8.1319],
        [110.587, -8.1322],
        [110.5866, -8.133],
        [110.5863, -8.1335],
        [110.5861, -8.1337],
        [110.5857, -8.134],
        [110.5854, -8.1346],
        [110.5853, -8.1352],
        [110.5851, -8.1355],
        [110.5846, -8.1359],
        [110.584, -8.1363],
        [110.5836, -8.1367],
        [110.5831, -8.1373],
        [110.5825, -8.1377],
        [110.5819, -8.1383],
        [110.5813, -8.1386],
        [110.581, -8.1391],
        [110.581, -8.1398],
        [110.5812, -8.1398],
        [110.5813, -8.1398],
        [110.5821, -8.1401],
        [110.5824, -8.1402],
        [110.5829, -8.1406],
        [110.5832, -8.1408],
        [110.5835, -8.141],
        [110.5837, -8.1413],
        [110.584, -8.142],
        [110.5842, -8.1424],
        [110.5844, -8.1426],
        [110.5846, -8.1427],
        [110.5847, -8.1429],
        [110.5848, -8.1431],
        [110.5849, -8.1433],
        [110.585, -8.144],
        [110.585, -8.1442],
        [110.5849, -8.1444],
        [110.5849, -8.1445],
        [110.585, -8.1447],
        [110.5852, -8.1448],
        [110.5853, -8.1449],
        [110.5855, -8.145],
        [110.5857, -8.145],
        [110.5858, -8.1451],
        [110.586, -8.1453],
        [110.5861, -8.1453],
        [110.5865, -8.1453],
        [110.5868, -8.1453],
        [110.5871, -8.1453],
        [110.5872, -8.1454],
        [110.5873, -8.1456],
        [110.5875, -8.1457],
        [110.5876, -8.1459],
        [110.5877, -8.146],
        [110.5878, -8.1462],
        [110.588, -8.1463],
        [110.5883, -8.1465],
        [110.5885, -8.1465],
        [110.5888, -8.1466],
        [110.5889, -8.1466],
        [110.5892, -8.1465],
        [110.5894, -8.1467],
        [110.5895, -8.1467],
        [110.5897, -8.1468],
        [110.5899, -8.1468],
        [110.59, -8.1469],
        [110.59, -8.1471],
        [110.5902, -8.1471],
        [110.5903, -8.1471],
        [110.5904, -8.147],
        [110.5906, -8.147],
        [110.5907, -8.1471],
        [110.5908, -8.1472],
        [110.5909, -8.1474],
        [110.5911, -8.1474],
        [110.5913, -8.1475],
        [110.5915, -8.1475],
        [110.5916, -8.1475],
        [110.5917, -8.1473],
        [110.5917, -8.1472],
        [110.5918, -8.1472],
        [110.5919, -8.1473],
        [110.5921, -8.1475],
        [110.5922, -8.1476],
        [110.5924, -8.1477],
        [110.5926, -8.1476],
        [110.5928, -8.1475],
        [110.5928, -8.1474],
        [110.5929, -8.1472],
        [110.5931, -8.1473],
        [110.5932, -8.1474],
        [110.5933, -8.1475],
        [110.5933, -8.1478],
        [110.5934, -8.1481],
        [110.5936, -8.1481],
        [110.5939, -8.1482],
        [110.5942, -8.1482],
        [110.5942, -8.148],
        [110.5942, -8.1479],
        [110.5943, -8.1477],
        [110.5944, -8.1477],
        [110.5946, -8.1477],
        [110.5946, -8.1477],
        [110.5947, -8.1476],
        [110.5947, -8.1474],
        [110.5946, -8.1473],
        [110.5947, -8.1472],
        [110.5949, -8.1471],
        [110.5952, -8.1475],
        [110.5954, -8.1477],
        [110.5955, -8.1477],
        [110.5953, -8.1478],
        [110.5951, -8.148],
        [110.595, -8.1482],
        [110.595, -8.1484],
        [110.5951, -8.1487],
        [110.5953, -8.1488],
        [110.5953, -8.149],
        [110.5954, -8.1492],
        [110.5955, -8.1494],
        [110.5959, -8.1494],
        [110.5963, -8.1494],
        [110.5966, -8.1494],
        [110.5966, -8.1492],
        [110.5965, -8.1489],
        [110.5964, -8.1487],
        [110.5965, -8.1486],
        [110.5966, -8.1485],
        [110.5968, -8.1485],
        [110.5969, -8.1486],
        [110.5971, -8.1486],
        [110.5971, -8.1484],
        [110.597, -8.1482],
        [110.5969, -8.148],
        [110.5968, -8.1478],
        [110.5968, -8.1476],
        [110.5968, -8.1475],
        [110.597, -8.1474],
        [110.5971, -8.1472],
        [110.5973, -8.1471],
        [110.5975, -8.1471],
        [110.5976, -8.1472],
        [110.5976, -8.1474],
        [110.5977, -8.1476],
        [110.5978, -8.1477],
        [110.5979, -8.1477],
        [110.598, -8.1476],
        [110.5982, -8.1475],
        [110.5982, -8.1473],
        [110.5982, -8.1471],
        [110.5982, -8.147],
        [110.5984, -8.1468],
        [110.5984, -8.1467],
        [110.5983, -8.1465],
        [110.5982, -8.1463],
        [110.5982, -8.1462],
        [110.5983, -8.1459],
        [110.5986, -8.1458],
        [110.5989, -8.1456],
        [110.5992, -8.1454],
        [110.5996, -8.1453],
        [110.6, -8.1453],
        [110.5999, -8.1446],
        [110.5993, -8.1433],
        [110.599, -8.1424],
        [110.599, -8.1419],
        [110.5994, -8.1414],
        [110.6001, -8.1408],
        [110.601, -8.1398],
        [110.6014, -8.1391],
        [110.6017, -8.1384],
        [110.6018, -8.1374],
        [110.6017, -8.1362],
        [110.6016, -8.1348],
        [110.6014, -8.1342],
        [110.6013, -8.1331],
        [110.6009, -8.1317],
        [110.6008, -8.1304],
        [110.6006, -8.1289],
        [110.6005, -8.1281],
        [110.6003, -8.1274],
        [110.6004, -8.1269],
        [110.6007, -8.1266],
        [110.6013, -8.1263],
        [110.6022, -8.1258],
        [110.6021, -8.1252],
        [110.6024, -8.1245],
        [110.603, -8.124],
        [110.6035, -8.1233],
        [110.6038, -8.1225],
        [110.6042, -8.1217],
        [110.6048, -8.121],
        [110.6054, -8.1202],
        [110.6061, -8.1197],
        [110.6067, -8.119],
        [110.6073, -8.1187],
        [110.6082, -8.1183],
        [110.6084, -8.118],
        [110.6082, -8.1172],
        [110.6078, -8.1164],
        [110.6075, -8.1159],
        [110.6075, -8.115],
        [110.6076, -8.1144],
        [110.6081, -8.1137],
        [110.6086, -8.1132],
        [110.609, -8.1124],
        [110.6098, -8.1116],
        [110.6108, -8.1112],
        [110.6119, -8.1108],
        [110.6129, -8.1101],
        [110.6139, -8.1093],
        [110.6147, -8.1085],
        [110.6152, -8.1076],
        [110.6155, -8.1067],
        [110.616, -8.1059],
        [110.6165, -8.1053],
        [110.6172, -8.1047],
        [110.6181, -8.1042],
        [110.6182, -8.1041],
        [110.6184, -8.104],
        [110.6188, -8.1037],
        [110.6193, -8.1028],
        [110.6198, -8.1018],
        [110.6202, -8.1007],
        [110.6208, -8.099],
        [110.6217, -8.0974],
        [110.6219, -8.0968],
        [110.6219, -8.096],
        [110.6216, -8.0955],
        [110.6214, -8.0949],
        [110.6211, -8.0939],
        [110.6206, -8.0931],
        [110.6199, -8.0918],
        [110.6194, -8.0908],
        [110.619, -8.0899],
        [110.6186, -8.0886],
        [110.6183, -8.0876],
        [110.6175, -8.0863],
        [110.6167, -8.0848],
        [110.6161, -8.0835],
        [110.6161, -8.0828],
        [110.6162, -8.0815],
        [110.6163, -8.0808],
        [110.6166, -8.0799],
        [110.6172, -8.0792],
        [110.6179, -8.0784],
        [110.6189, -8.0776],
        [110.62, -8.0772],
        [110.622, -8.0768],
        [110.6235, -8.077],
        [110.624, -8.0769],
        [110.6244, -8.0771],
        [110.625, -8.0772],
        [110.6253, -8.0772],
        [110.6261, -8.077],
        [110.6268, -8.0768],
        [110.6271, -8.0769],
        [110.6278, -8.0769],
        [110.6288, -8.0769],
        [110.6294, -8.0774],
        [110.6301, -8.0783],
        [110.631, -8.0771],
        [110.6326, -8.0752],
        [110.6329, -8.0748],
        [110.6334, -8.0745],
        [110.6338, -8.0743],
        [110.6347, -8.0741],
        [110.6354, -8.0737]
      ]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PURWOSARI',
    village: 'GIRIPURWO',
    border: [
      [110.4174, -8.0233],
      [110.4152, -8.022],
      [110.4127, -8.0202],
      [110.412, -8.0198],
      [110.4114, -8.0187],
      [110.4103, -8.017],
      [110.4096, -8.0154],
      [110.4093, -8.0141],
      [110.4092, -8.0132],
      [110.4092, -8.0129],
      [110.4092, -8.0126],
      [110.4091, -8.0113],
      [110.4089, -8.0102],
      [110.4087, -8.0095],
      [110.4077, -8.0081],
      [110.4057, -8.0068],
      [110.4045, -8.006],
      [110.4032, -8.0056],
      [110.4018, -8.0056],
      [110.4001, -8.0058],
      [110.3988, -8.006],
      [110.3985, -8.0061],
      [110.3983, -8.0073],
      [110.3984, -8.0076],
      [110.3985, -8.0084],
      [110.3984, -8.0092],
      [110.3987, -8.0113],
      [110.399, -8.0126],
      [110.3987, -8.0132],
      [110.3983, -8.0143],
      [110.3971, -8.0156],
      [110.3962, -8.0163],
      [110.3951, -8.0168],
      [110.3932, -8.0169],
      [110.3915, -8.0165],
      [110.3907, -8.016],
      [110.3894, -8.0152],
      [110.3884, -8.0142],
      [110.388, -8.0135],
      [110.3866, -8.0125],
      [110.3858, -8.012],
      [110.3854, -8.0118],
      [110.3838, -8.0119],
      [110.3823, -8.0119],
      [110.3812, -8.0121],
      [110.3798, -8.0124],
      [110.3783, -8.0125],
      [110.3771, -8.0123],
      [110.3757, -8.0117],
      [110.375, -8.011],
      [110.3748, -8.0106],
      [110.3741, -8.01],
      [110.3736, -8.0097],
      [110.3732, -8.0096],
      [110.3728, -8.0098],
      [110.3716, -8.0104],
      [110.3715, -8.0105],
      [110.3711, -8.0107],
      [110.3707, -8.0109],
      [110.3704, -8.0113],
      [110.3706, -8.0118],
      [110.3707, -8.0128],
      [110.3711, -8.014],
      [110.3715, -8.0154],
      [110.3717, -8.0165],
      [110.3718, -8.0189],
      [110.3719, -8.02],
      [110.3722, -8.0216],
      [110.3723, -8.0227],
      [110.3724, -8.0229],
      [110.3724, -8.0233],
      [110.3727, -8.0241],
      [110.3728, -8.0246],
      [110.3734, -8.0266],
      [110.374, -8.0285],
      [110.3741, -8.0296],
      [110.3741, -8.0304],
      [110.3739, -8.0309],
      [110.3733, -8.0317],
      [110.3729, -8.0322],
      [110.3728, -8.0327],
      [110.3729, -8.0331],
      [110.3734, -8.0338],
      [110.3739, -8.0342],
      [110.3748, -8.0349],
      [110.375, -8.0351],
      [110.3756, -8.0354],
      [110.3767, -8.036],
      [110.3779, -8.0368],
      [110.3793, -8.038],
      [110.3801, -8.0391],
      [110.3807, -8.0401],
      [110.3812, -8.0415],
      [110.3815, -8.0429],
      [110.3817, -8.0443],
      [110.3815, -8.0447],
      [110.3807, -8.0466],
      [110.3801, -8.0485],
      [110.3797, -8.05],
      [110.3793, -8.0505],
      [110.3784, -8.0517],
      [110.3777, -8.0531],
      [110.3766, -8.0541],
      [110.3759, -8.0543],
      [110.3749, -8.0548],
      [110.3733, -8.0557],
      [110.3722, -8.0564],
      [110.3712, -8.0578],
      [110.369, -8.0593],
      [110.3675, -8.06],
      [110.3669, -8.0606],
      [110.367, -8.0607],
      [110.3674, -8.0608],
      [110.3677, -8.0609],
      [110.368, -8.061],
      [110.3683, -8.0613],
      [110.3685, -8.062],
      [110.3687, -8.0628],
      [110.3687, -8.0631],
      [110.3687, -8.0632],
      [110.3689, -8.0636],
      [110.3691, -8.0639],
      [110.3692, -8.0643],
      [110.3693, -8.0645],
      [110.3696, -8.0649],
      [110.3702, -8.0652],
      [110.3705, -8.0653],
      [110.3708, -8.0655],
      [110.371, -8.0656],
      [110.3713, -8.0658],
      [110.3716, -8.0662],
      [110.3719, -8.0666],
      [110.3721, -8.0669],
      [110.3721, -8.0672],
      [110.3722, -8.0677],
      [110.3722, -8.0678],
      [110.3725, -8.068],
      [110.373, -8.0682],
      [110.3734, -8.0687],
      [110.3736, -8.0691],
      [110.3736, -8.0696],
      [110.3736, -8.0697],
      [110.3739, -8.07],
      [110.3742, -8.0703],
      [110.3743, -8.0705],
      [110.3744, -8.0707],
      [110.3745, -8.0709],
      [110.3746, -8.071],
      [110.3748, -8.0712],
      [110.3749, -8.0712],
      [110.375, -8.0714],
      [110.3752, -8.0716],
      [110.3754, -8.0716],
      [110.3757, -8.0715],
      [110.376, -8.0715],
      [110.3762, -8.0715],
      [110.377, -8.0717],
      [110.3772, -8.0718],
      [110.3773, -8.0718],
      [110.3777, -8.0721],
      [110.3779, -8.0723],
      [110.3782, -8.0723],
      [110.3785, -8.0724],
      [110.3789, -8.0722],
      [110.3793, -8.0721],
      [110.3795, -8.0719],
      [110.3804, -8.072],
      [110.3807, -8.0721],
      [110.381, -8.0723],
      [110.3814, -8.0724],
      [110.382, -8.0724],
      [110.3825, -8.0725],
      [110.383, -8.0725],
      [110.3834, -8.0724],
      [110.3838, -8.0722],
      [110.3841, -8.0722],
      [110.3845, -8.0722],
      [110.3848, -8.0722],
      [110.3852, -8.0722],
      [110.3857, -8.0723],
      [110.386, -8.0724],
      [110.3869, -8.0725],
      [110.3872, -8.0725],
      [110.3874, -8.0726],
      [110.3875, -8.0727],
      [110.3878, -8.0731],
      [110.3879, -8.0733],
      [110.388, -8.0734],
      [110.3882, -8.0735],
      [110.3885, -8.0735],
      [110.3886, -8.0734],
      [110.3889, -8.0734],
      [110.389, -8.0737],
      [110.3892, -8.0738],
      [110.3897, -8.0741],
      [110.39, -8.0742],
      [110.3904, -8.0746],
      [110.3906, -8.0749],
      [110.3908, -8.0753],
      [110.3912, -8.0759],
      [110.3916, -8.0762],
      [110.3921, -8.0763],
      [110.3924, -8.0764],
      [110.3933, -8.0752],
      [110.3944, -8.0739],
      [110.3959, -8.0727],
      [110.3974, -8.0718],
      [110.3979, -8.0712],
      [110.3983, -8.0708],
      [110.3991, -8.07],
      [110.4, -8.069],
      [110.4009, -8.0684],
      [110.4018, -8.0675],
      [110.4028, -8.0668],
      [110.4037, -8.066],
      [110.4045, -8.0654],
      [110.405, -8.0644],
      [110.4053, -8.0635],
      [110.4057, -8.0626],
      [110.406, -8.0616],
      [110.4064, -8.0607],
      [110.4068, -8.0594],
      [110.4072, -8.0578],
      [110.4077, -8.0567],
      [110.4082, -8.0554],
      [110.4089, -8.0538],
      [110.4093, -8.0529],
      [110.4097, -8.0519],
      [110.4102, -8.0511],
      [110.4109, -8.0501],
      [110.4113, -8.0491],
      [110.4117, -8.0479],
      [110.4122, -8.0464],
      [110.4123, -8.0455],
      [110.4126, -8.0447],
      [110.4128, -8.0438],
      [110.413, -8.0426],
      [110.4131, -8.0419],
      [110.4138, -8.0404],
      [110.4141, -8.0394],
      [110.4145, -8.0389],
      [110.4153, -8.0379],
      [110.4158, -8.0373],
      [110.4161, -8.0364],
      [110.4158, -8.035],
      [110.4156, -8.0339],
      [110.4156, -8.0329],
      [110.4155, -8.0312],
      [110.4155, -8.0311],
      [110.4157, -8.0277],
      [110.4159, -8.0266],
      [110.4162, -8.0254],
      [110.4168, -8.0244],
      [110.4174, -8.0233]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PURWOSARI',
    village: 'GIRICAHYO',
    border: [
      [110.3669, -8.0606],
      [110.3675, -8.06],
      [110.369, -8.0593],
      [110.3712, -8.0578],
      [110.3722, -8.0564],
      [110.3733, -8.0557],
      [110.3749, -8.0548],
      [110.3759, -8.0543],
      [110.3766, -8.0541],
      [110.3777, -8.0531],
      [110.3784, -8.0517],
      [110.3793, -8.0505],
      [110.3797, -8.05],
      [110.3801, -8.0485],
      [110.3807, -8.0466],
      [110.3815, -8.0447],
      [110.3817, -8.0443],
      [110.3815, -8.0429],
      [110.3812, -8.0415],
      [110.3807, -8.0401],
      [110.3801, -8.0391],
      [110.3793, -8.038],
      [110.3779, -8.0368],
      [110.3767, -8.036],
      [110.3756, -8.0354],
      [110.375, -8.0351],
      [110.3748, -8.0349],
      [110.3739, -8.0342],
      [110.3734, -8.0338],
      [110.3729, -8.0331],
      [110.3728, -8.0327],
      [110.3729, -8.0322],
      [110.3733, -8.0317],
      [110.3739, -8.0309],
      [110.3741, -8.0304],
      [110.3741, -8.0296],
      [110.374, -8.0285],
      [110.3734, -8.0266],
      [110.3728, -8.0246],
      [110.3727, -8.0241],
      [110.3724, -8.0233],
      [110.3724, -8.0229],
      [110.3723, -8.0227],
      [110.3722, -8.0216],
      [110.3719, -8.02],
      [110.3718, -8.0189],
      [110.3717, -8.0165],
      [110.3715, -8.0154],
      [110.3711, -8.014],
      [110.3707, -8.0128],
      [110.3706, -8.0118],
      [110.3704, -8.0113],
      [110.3701, -8.0113],
      [110.3692, -8.0117],
      [110.3675, -8.0124],
      [110.3664, -8.0129],
      [110.3659, -8.0129],
      [110.3652, -8.0132],
      [110.363, -8.0132],
      [110.362, -8.0133],
      [110.3617, -8.0133],
      [110.3615, -8.0132],
      [110.3605, -8.013],
      [110.3589, -8.0128],
      [110.3567, -8.0121],
      [110.355, -8.0117],
      [110.3535, -8.0117],
      [110.3521, -8.0117],
      [110.3499, -8.0118],
      [110.3482, -8.0117],
      [110.3474, -8.0116],
      [110.3472, -8.0117],
      [110.3472, -8.013],
      [110.3474, -8.0141],
      [110.3473, -8.015],
      [110.3472, -8.0163],
      [110.3467, -8.0183],
      [110.3466, -8.0189],
      [110.3467, -8.0197],
      [110.3469, -8.0204],
      [110.347, -8.0212],
      [110.3467, -8.0223],
      [110.3463, -8.0248],
      [110.3458, -8.0262],
      [110.3455, -8.0272],
      [110.3456, -8.0281],
      [110.3457, -8.0295],
      [110.3463, -8.0314],
      [110.3469, -8.0332],
      [110.3471, -8.0346],
      [110.347, -8.0353],
      [110.3467, -8.0358],
      [110.3467, -8.0365],
      [110.3469, -8.0371],
      [110.3471, -8.038],
      [110.3472, -8.0383],
      [110.3475, -8.0389],
      [110.3476, -8.0391],
      [110.3477, -8.0393],
      [110.3478, -8.0395],
      [110.3479, -8.0395],
      [110.3483, -8.0401],
      [110.3484, -8.0403],
      [110.3483, -8.0406],
      [110.3482, -8.0408],
      [110.3481, -8.0409],
      [110.3485, -8.0414],
      [110.3493, -8.0422],
      [110.3498, -8.0428],
      [110.35, -8.043],
      [110.3502, -8.0434],
      [110.3504, -8.0437],
      [110.3505, -8.0438],
      [110.3507, -8.0439],
      [110.3509, -8.0441],
      [110.3509, -8.0447],
      [110.3511, -8.0452],
      [110.3514, -8.0456],
      [110.3516, -8.0458],
      [110.3516, -8.0466],
      [110.3518, -8.0468],
      [110.3522, -8.0472],
      [110.3523, -8.0474],
      [110.3524, -8.0477],
      [110.3525, -8.0481],
      [110.3527, -8.0485],
      [110.3528, -8.0487],
      [110.3527, -8.0489],
      [110.3526, -8.0491],
      [110.3526, -8.0492],
      [110.3527, -8.0493],
      [110.3528, -8.0491],
      [110.3529, -8.049],
      [110.353, -8.049],
      [110.3535, -8.0493],
      [110.354, -8.0498],
      [110.3542, -8.05],
      [110.3544, -8.0505],
      [110.3545, -8.0507],
      [110.3545, -8.0509],
      [110.3545, -8.0512],
      [110.3545, -8.0515],
      [110.3546, -8.0518],
      [110.3547, -8.0519],
      [110.3548, -8.052],
      [110.3552, -8.0524],
      [110.3555, -8.0527],
      [110.3558, -8.0531],
      [110.3559, -8.0532],
      [110.3567, -8.0539],
      [110.3572, -8.0543],
      [110.3576, -8.055],
      [110.3578, -8.0554],
      [110.358, -8.0556],
      [110.3586, -8.0559],
      [110.3588, -8.056],
      [110.3589, -8.0563],
      [110.3593, -8.0567],
      [110.3599, -8.0571],
      [110.3605, -8.0575],
      [110.3607, -8.0576],
      [110.361, -8.0576],
      [110.3612, -8.0578],
      [110.3615, -8.058],
      [110.362, -8.058],
      [110.3623, -8.0583],
      [110.3625, -8.0585],
      [110.3626, -8.0587],
      [110.3628, -8.0587],
      [110.363, -8.0593],
      [110.3633, -8.0595],
      [110.3635, -8.0596],
      [110.3636, -8.0599],
      [110.3638, -8.0601],
      [110.364, -8.0602],
      [110.3642, -8.06],
      [110.3643, -8.0598],
      [110.3647, -8.0601],
      [110.3651, -8.0602],
      [110.3654, -8.0602],
      [110.3656, -8.0601],
      [110.3656, -8.0601],
      [110.3659, -8.0603],
      [110.3661, -8.0604],
      [110.3664, -8.0605],
      [110.3669, -8.0606]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PURWOSARI',
    village: 'GIRIJATI',
    border: [
      [110.3611, -7.9823],
      [110.3605, -7.9824],
      [110.3596, -7.9823],
      [110.3588, -7.9821],
      [110.3584, -7.982],
      [110.3581, -7.9818],
      [110.3579, -7.9815],
      [110.3576, -7.9807],
      [110.3573, -7.9806],
      [110.357, -7.9805],
      [110.3565, -7.98],
      [110.3562, -7.9797],
      [110.3559, -7.9797],
      [110.3558, -7.9795],
      [110.3559, -7.9794],
      [110.3559, -7.9791],
      [110.3558, -7.979],
      [110.3556, -7.9787],
      [110.3556, -7.9784],
      [110.3554, -7.9782],
      [110.3553, -7.9781],
      [110.3551, -7.9781],
      [110.355, -7.9783],
      [110.355, -7.9784],
      [110.3549, -7.9786],
      [110.3546, -7.9786],
      [110.3542, -7.9784],
      [110.3537, -7.9784],
      [110.3536, -7.9784],
      [110.3532, -7.9785],
      [110.3528, -7.9784],
      [110.3527, -7.9781],
      [110.3526, -7.9776],
      [110.3524, -7.9774],
      [110.3519, -7.9773],
      [110.3517, -7.977],
      [110.3515, -7.9767],
      [110.3513, -7.9764],
      [110.3512, -7.9762],
      [110.3508, -7.9753],
      [110.3506, -7.9751],
      [110.3504, -7.9744],
      [110.3501, -7.9743],
      [110.3496, -7.9743],
      [110.3492, -7.9742],
      [110.3488, -7.9743],
      [110.3485, -7.9743],
      [110.3482, -7.9741],
      [110.348, -7.9742],
      [110.348, -7.9745],
      [110.348, -7.9749],
      [110.348, -7.9753],
      [110.3481, -7.9758],
      [110.3484, -7.9759],
      [110.3487, -7.9762],
      [110.3489, -7.9766],
      [110.349, -7.977],
      [110.3493, -7.9772],
      [110.3498, -7.9774],
      [110.35, -7.9777],
      [110.3502, -7.978],
      [110.3503, -7.9782],
      [110.3506, -7.9782],
      [110.3508, -7.9784],
      [110.351, -7.9788],
      [110.3512, -7.979],
      [110.3516, -7.9793],
      [110.3519, -7.9797],
      [110.352, -7.9799],
      [110.3522, -7.9801],
      [110.3524, -7.9804],
      [110.3527, -7.9808],
      [110.3526, -7.9812],
      [110.3524, -7.9818],
      [110.3523, -7.9822],
      [110.3521, -7.9822],
      [110.352, -7.9822],
      [110.3518, -7.9827],
      [110.3516, -7.983],
      [110.3515, -7.9833],
      [110.3515, -7.9835],
      [110.351, -7.9837],
      [110.3504, -7.9839],
      [110.3502, -7.984],
      [110.3502, -7.9843],
      [110.3502, -7.9846],
      [110.3504, -7.985],
      [110.3504, -7.9854],
      [110.3503, -7.9856],
      [110.3501, -7.9858],
      [110.3498, -7.9861],
      [110.3497, -7.9866],
      [110.3497, -7.9871],
      [110.3495, -7.988],
      [110.3495, -7.9881],
      [110.3494, -7.9884],
      [110.3495, -7.9889],
      [110.3499, -7.9895],
      [110.3506, -7.9899],
      [110.3507, -7.9896],
      [110.351, -7.9891],
      [110.3516, -7.9885],
      [110.3522, -7.988],
      [110.3526, -7.9877],
      [110.3529, -7.9876],
      [110.3531, -7.9876],
      [110.3533, -7.9881],
      [110.3533, -7.9888],
      [110.3531, -7.9905],
      [110.3529, -7.9922],
      [110.3525, -7.9932],
      [110.3522, -7.9937],
      [110.3519, -7.9939],
      [110.3511, -7.994],
      [110.3496, -7.9938],
      [110.3489, -7.9937],
      [110.3485, -7.9936],
      [110.3473, -7.9932],
      [110.3455, -7.9926],
      [110.3434, -7.992],
      [110.3427, -7.9918],
      [110.3425, -7.9918],
      [110.3418, -7.9917],
      [110.3405, -7.9917],
      [110.34, -7.992],
      [110.3391, -7.9924],
      [110.3384, -7.9929],
      [110.338, -7.9935],
      [110.3374, -7.9938],
      [110.3368, -7.9943],
      [110.3363, -7.9948],
      [110.336, -7.9949],
      [110.3351, -7.9952],
      [110.3343, -7.9955],
      [110.3342, -7.9955],
      [110.3337, -7.9958],
      [110.3329, -7.9963],
      [110.3326, -7.9967],
      [110.3323, -7.9971],
      [110.3319, -7.9977],
      [110.3315, -7.9987],
      [110.3313, -7.9994],
      [110.3314, -8.0001],
      [110.3315, -8.0007],
      [110.3313, -8.0013],
      [110.3312, -8.0018],
      [110.3312, -8.0025],
      [110.331, -8.0031],
      [110.3309, -8.0041],
      [110.3307, -8.0049],
      [110.3306, -8.0057],
      [110.3306, -8.0062],
      [110.3307, -8.0072],
      [110.3307, -8.0074],
      [110.3307, -8.0079],
      [110.3311, -8.0087],
      [110.3316, -8.0094],
      [110.332, -8.0101],
      [110.3321, -8.0105],
      [110.3323, -8.0115],
      [110.3326, -8.0128],
      [110.3328, -8.0137],
      [110.3331, -8.0144],
      [110.3335, -8.0155],
      [110.3336, -8.016],
      [110.3337, -8.0166],
      [110.334, -8.0174],
      [110.3342, -8.0182],
      [110.3344, -8.0186],
      [110.3347, -8.0191],
      [110.3351, -8.0195],
      [110.3355, -8.02],
      [110.3358, -8.0203],
      [110.3358, -8.0207],
      [110.3359, -8.0211],
      [110.3358, -8.0216],
      [110.3359, -8.0219],
      [110.3359, -8.0222],
      [110.3363, -8.0224],
      [110.3367, -8.0225],
      [110.337, -8.0226],
      [110.3371, -8.023],
      [110.3372, -8.0236],
      [110.3373, -8.0238],
      [110.3377, -8.0242],
      [110.3378, -8.0245],
      [110.3378, -8.0249],
      [110.338, -8.0251],
      [110.3386, -8.0255],
      [110.339, -8.0263],
      [110.3394, -8.0271],
      [110.3399, -8.0282],
      [110.3401, -8.0282],
      [110.3407, -8.0286],
      [110.341, -8.0288],
      [110.3415, -8.0293],
      [110.3418, -8.0295],
      [110.3422, -8.0298],
      [110.3425, -8.0299],
      [110.3428, -8.0299],
      [110.3429, -8.0301],
      [110.3435, -8.0302],
      [110.3441, -8.0302],
      [110.3444, -8.0303],
      [110.3445, -8.0304],
      [110.3447, -8.0308],
      [110.3449, -8.0311],
      [110.345, -8.0315],
      [110.3451, -8.0318],
      [110.3454, -8.0321],
      [110.3455, -8.0323],
      [110.346, -8.0332],
      [110.3461, -8.0335],
      [110.3462, -8.0342],
      [110.3464, -8.0346],
      [110.3467, -8.0352],
      [110.3468, -8.0355],
      [110.3467, -8.0358],
      [110.347, -8.0353],
      [110.3471, -8.0346],
      [110.3469, -8.0332],
      [110.3463, -8.0314],
      [110.3457, -8.0295],
      [110.3456, -8.0281],
      [110.3455, -8.0272],
      [110.3458, -8.0262],
      [110.3463, -8.0248],
      [110.3467, -8.0223],
      [110.347, -8.0212],
      [110.3469, -8.0204],
      [110.3467, -8.0197],
      [110.3466, -8.0189],
      [110.3467, -8.0183],
      [110.3472, -8.0163],
      [110.3473, -8.015],
      [110.3474, -8.0141],
      [110.3472, -8.013],
      [110.3472, -8.0117],
      [110.3474, -8.0116],
      [110.3484, -8.0109],
      [110.3499, -8.0098],
      [110.3509, -8.0088],
      [110.3521, -8.007],
      [110.3527, -8.0056],
      [110.353, -8.005],
      [110.3534, -8.0038],
      [110.3535, -8.0018],
      [110.3535, -7.9998],
      [110.3534, -7.9989],
      [110.3534, -7.9988],
      [110.3532, -7.9981],
      [110.3532, -7.9977],
      [110.3531, -7.9974],
      [110.353, -7.9956],
      [110.3531, -7.9954],
      [110.3531, -7.9947],
      [110.3532, -7.9945],
      [110.3538, -7.9928],
      [110.3547, -7.9919],
      [110.3563, -7.9909],
      [110.3578, -7.9898],
      [110.3594, -7.989],
      [110.3604, -7.9878],
      [110.3617, -7.9863],
      [110.3619, -7.9861],
      [110.3616, -7.986],
      [110.3612, -7.9856],
      [110.3609, -7.9856],
      [110.3605, -7.9852],
      [110.3605, -7.9849],
      [110.3607, -7.9841],
      [110.3606, -7.9835],
      [110.3609, -7.9831],
      [110.3611, -7.9827],
      [110.3611, -7.9823]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PURWOSARI',
    village: 'GIRIASIH',
    border: [
      [110.3732, -8.0096],
      [110.3734, -8.0091],
      [110.3735, -8.0078],
      [110.3735, -8.0075],
      [110.3734, -8.0065],
      [110.3734, -8.0063],
      [110.3729, -8.0049],
      [110.3725, -8.0038],
      [110.3722, -8.0024],
      [110.3718, -8.0012],
      [110.3715, -8.0003],
      [110.3716, -7.9998],
      [110.3717, -7.9989],
      [110.3721, -7.9972],
      [110.3725, -7.9959],
      [110.3722, -7.9953],
      [110.3719, -7.9947],
      [110.3716, -7.994],
      [110.3709, -7.9937],
      [110.3699, -7.9933],
      [110.369, -7.9931],
      [110.3684, -7.9929],
      [110.3679, -7.9922],
      [110.3677, -7.9908],
      [110.3674, -7.9893],
      [110.3673, -7.9889],
      [110.3662, -7.9878],
      [110.3657, -7.9876],
      [110.3636, -7.9871],
      [110.3629, -7.9867],
      [110.3619, -7.9861],
      [110.3617, -7.9863],
      [110.3604, -7.9878],
      [110.3594, -7.989],
      [110.3578, -7.9898],
      [110.3563, -7.9909],
      [110.3547, -7.9919],
      [110.3538, -7.9928],
      [110.3532, -7.9945],
      [110.3531, -7.9947],
      [110.3531, -7.9954],
      [110.353, -7.9956],
      [110.3531, -7.9974],
      [110.3532, -7.9977],
      [110.3532, -7.9981],
      [110.3534, -7.9988],
      [110.3534, -7.9989],
      [110.3535, -7.9998],
      [110.3535, -8.0018],
      [110.3534, -8.0038],
      [110.353, -8.005],
      [110.3527, -8.0056],
      [110.3521, -8.007],
      [110.3509, -8.0088],
      [110.3499, -8.0098],
      [110.3484, -8.0109],
      [110.3474, -8.0116],
      [110.3482, -8.0117],
      [110.3499, -8.0118],
      [110.3521, -8.0117],
      [110.3535, -8.0117],
      [110.355, -8.0117],
      [110.3567, -8.0121],
      [110.3589, -8.0128],
      [110.3605, -8.013],
      [110.3615, -8.0132],
      [110.3617, -8.0133],
      [110.362, -8.0133],
      [110.363, -8.0132],
      [110.3652, -8.0132],
      [110.3659, -8.0129],
      [110.3664, -8.0129],
      [110.3675, -8.0124],
      [110.3692, -8.0117],
      [110.3701, -8.0113],
      [110.3704, -8.0113],
      [110.3707, -8.0109],
      [110.3711, -8.0107],
      [110.3715, -8.0105],
      [110.3716, -8.0104],
      [110.3728, -8.0098],
      [110.3732, -8.0096]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'GUNUNGKIDUL',
    sub_district: 'PURWOSARI',
    village: 'GIRITIRTO',
    border: [
      [110.3675, -7.9737],
      [110.3671, -7.9732],
      [110.3665, -7.9726],
      [110.3656, -7.9724],
      [110.3652, -7.9727],
      [110.3648, -7.9734],
      [110.3646, -7.9741],
      [110.3642, -7.9749],
      [110.3641, -7.9757],
      [110.3641, -7.9768],
      [110.3637, -7.9772],
      [110.3633, -7.9776],
      [110.3633, -7.9786],
      [110.3632, -7.9792],
      [110.3631, -7.9798],
      [110.363, -7.9815],
      [110.3625, -7.9819],
      [110.3619, -7.9821],
      [110.3611, -7.9823],
      [110.3611, -7.9827],
      [110.3609, -7.9831],
      [110.3606, -7.9835],
      [110.3607, -7.9841],
      [110.3605, -7.9849],
      [110.3605, -7.9852],
      [110.3609, -7.9856],
      [110.3612, -7.9856],
      [110.3616, -7.986],
      [110.3619, -7.9861],
      [110.3629, -7.9867],
      [110.3636, -7.9871],
      [110.3657, -7.9876],
      [110.3662, -7.9878],
      [110.3673, -7.9889],
      [110.3674, -7.9893],
      [110.3677, -7.9908],
      [110.3679, -7.9922],
      [110.3684, -7.9929],
      [110.369, -7.9931],
      [110.3699, -7.9933],
      [110.3709, -7.9937],
      [110.3716, -7.994],
      [110.3719, -7.9947],
      [110.3722, -7.9953],
      [110.3725, -7.9959],
      [110.3721, -7.9972],
      [110.3717, -7.9989],
      [110.3716, -7.9998],
      [110.3715, -8.0003],
      [110.3718, -8.0012],
      [110.3722, -8.0024],
      [110.3725, -8.0038],
      [110.3729, -8.0049],
      [110.3734, -8.0063],
      [110.3734, -8.0065],
      [110.3735, -8.0075],
      [110.3735, -8.0078],
      [110.3734, -8.0091],
      [110.3732, -8.0096],
      [110.3736, -8.0097],
      [110.3741, -8.01],
      [110.3748, -8.0106],
      [110.375, -8.011],
      [110.3757, -8.0117],
      [110.3771, -8.0123],
      [110.3783, -8.0125],
      [110.3798, -8.0124],
      [110.3812, -8.0121],
      [110.3823, -8.0119],
      [110.3838, -8.0119],
      [110.3854, -8.0118],
      [110.3858, -8.012],
      [110.3866, -8.0125],
      [110.388, -8.0135],
      [110.3884, -8.0142],
      [110.3894, -8.0152],
      [110.3907, -8.016],
      [110.3915, -8.0165],
      [110.3932, -8.0169],
      [110.3951, -8.0168],
      [110.3962, -8.0163],
      [110.3971, -8.0156],
      [110.3983, -8.0143],
      [110.3987, -8.0132],
      [110.399, -8.0126],
      [110.3987, -8.0113],
      [110.3984, -8.0092],
      [110.3985, -8.0084],
      [110.3984, -8.0076],
      [110.3983, -8.0073],
      [110.3985, -8.0061],
      [110.3983, -8.0049],
      [110.3981, -8.004],
      [110.3981, -8.0037],
      [110.3981, -8.0028],
      [110.398, -8.0023],
      [110.3979, -8.002],
      [110.3975, -8.0009],
      [110.3974, -7.9999],
      [110.3971, -7.9991],
      [110.397, -7.9985],
      [110.3969, -7.9977],
      [110.3969, -7.9961],
      [110.397, -7.9951],
      [110.3971, -7.9935],
      [110.397, -7.9921],
      [110.3967, -7.9911],
      [110.3964, -7.9906],
      [110.3956, -7.9897],
      [110.3951, -7.9889],
      [110.3948, -7.9881],
      [110.3942, -7.9874],
      [110.3936, -7.9867],
      [110.3932, -7.9859],
      [110.3928, -7.9848],
      [110.3922, -7.9838],
      [110.3918, -7.9826],
      [110.3915, -7.9817],
      [110.3915, -7.9812],
      [110.3903, -7.9809],
      [110.3885, -7.9805],
      [110.3877, -7.9801],
      [110.3865, -7.9795],
      [110.3858, -7.9794],
      [110.3849, -7.9795],
      [110.3841, -7.9797],
      [110.3834, -7.9797],
      [110.383, -7.9795],
      [110.382, -7.9794],
      [110.3813, -7.9793],
      [110.3811, -7.9795],
      [110.3809, -7.9799],
      [110.3807, -7.9799],
      [110.38, -7.9795],
      [110.3799, -7.9796],
      [110.3796, -7.9799],
      [110.3793, -7.9797],
      [110.3782, -7.9795],
      [110.377, -7.9794],
      [110.3761, -7.9793],
      [110.3755, -7.9795],
      [110.3739, -7.9793],
      [110.3726, -7.9784],
      [110.3717, -7.9772],
      [110.3712, -7.9767],
      [110.3706, -7.9764],
      [110.3704, -7.9759],
      [110.37, -7.9756],
      [110.3684, -7.9744],
      [110.3675, -7.9738],
      [110.3675, -7.9737]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GAMPING',
    village: 'BALECATUR',
    border: [
      [110.3045, -7.7915],
      [110.3036, -7.7914],
      [110.3027, -7.7912],
      [110.3025, -7.7928],
      [110.3022, -7.795],
      [110.3018, -7.7982],
      [110.3015, -7.8],
      [110.3001, -7.7998],
      [110.2995, -7.7997],
      [110.2992, -7.7996],
      [110.2997, -7.7965],
      [110.2996, -7.7966],
      [110.2994, -7.7976],
      [110.2993, -7.7976],
      [110.2992, -7.7979],
      [110.2975, -7.7976],
      [110.2964, -7.7974],
      [110.2965, -7.7971],
      [110.2967, -7.7968],
      [110.2961, -7.7965],
      [110.2961, -7.7959],
      [110.2958, -7.7957],
      [110.2958, -7.7953],
      [110.295, -7.7953],
      [110.2932, -7.7953],
      [110.2942, -7.7941],
      [110.2947, -7.794],
      [110.2952, -7.7939],
      [110.2954, -7.7937],
      [110.2954, -7.7933],
      [110.2954, -7.793],
      [110.2955, -7.7929],
      [110.2957, -7.7924],
      [110.2955, -7.7917],
      [110.2951, -7.7916],
      [110.2945, -7.7915],
      [110.2945, -7.7912],
      [110.2943, -7.7912],
      [110.2942, -7.7915],
      [110.294, -7.792],
      [110.2938, -7.7926],
      [110.293, -7.7937],
      [110.2924, -7.7938],
      [110.292, -7.7947],
      [110.2918, -7.7946],
      [110.2902, -7.794],
      [110.29, -7.7943],
      [110.2897, -7.7946],
      [110.2895, -7.7949],
      [110.2895, -7.7953],
      [110.2894, -7.7958],
      [110.2892, -7.7963],
      [110.289, -7.7966],
      [110.289, -7.7969],
      [110.289, -7.797],
      [110.289, -7.7974],
      [110.2892, -7.7975],
      [110.2894, -7.7977],
      [110.2895, -7.7978],
      [110.2895, -7.7981],
      [110.2891, -7.7983],
      [110.2887, -7.7986],
      [110.2883, -7.7988],
      [110.288, -7.799],
      [110.2877, -7.7995],
      [110.2875, -7.8],
      [110.2871, -7.8001],
      [110.2869, -7.8001],
      [110.2867, -7.8004],
      [110.2865, -7.8006],
      [110.2865, -7.8008],
      [110.2865, -7.8012],
      [110.2864, -7.8015],
      [110.2863, -7.8018],
      [110.2861, -7.8019],
      [110.2859, -7.8021],
      [110.2859, -7.8024],
      [110.2861, -7.8027],
      [110.2863, -7.8029],
      [110.2865, -7.8033],
      [110.2864, -7.8036],
      [110.2861, -7.8038],
      [110.2862, -7.8041],
      [110.2862, -7.8043],
      [110.2858, -7.8044],
      [110.2854, -7.8045],
      [110.2853, -7.8047],
      [110.2851, -7.8052],
      [110.2849, -7.8054],
      [110.2846, -7.8052],
      [110.2844, -7.8052],
      [110.2841, -7.8052],
      [110.2837, -7.8052],
      [110.2833, -7.8055],
      [110.283, -7.8057],
      [110.2827, -7.806],
      [110.2825, -7.8061],
      [110.2822, -7.8063],
      [110.2819, -7.8065],
      [110.2815, -7.8068],
      [110.2815, -7.8071],
      [110.2815, -7.8072],
      [110.2816, -7.8073],
      [110.2817, -7.8075],
      [110.2818, -7.8075],
      [110.282, -7.8076],
      [110.2824, -7.8076],
      [110.2827, -7.8076],
      [110.2827, -7.8078],
      [110.2827, -7.8081],
      [110.2826, -7.8085],
      [110.2827, -7.8086],
      [110.283, -7.8088],
      [110.2831, -7.8092],
      [110.283, -7.8097],
      [110.2831, -7.8101],
      [110.2831, -7.8106],
      [110.2832, -7.8111],
      [110.2832, -7.8117],
      [110.2832, -7.8123],
      [110.2831, -7.8126],
      [110.2834, -7.8129],
      [110.2836, -7.8132],
      [110.2837, -7.8135],
      [110.2835, -7.8139],
      [110.2834, -7.8141],
      [110.2832, -7.8145],
      [110.2831, -7.8151],
      [110.2829, -7.8156],
      [110.2828, -7.816],
      [110.2826, -7.8162],
      [110.2824, -7.8164],
      [110.2822, -7.8168],
      [110.282, -7.8171],
      [110.2824, -7.8173],
      [110.2825, -7.8173],
      [110.2828, -7.8174],
      [110.2831, -7.8175],
      [110.284, -7.8178],
      [110.2851, -7.8179],
      [110.2851, -7.8179],
      [110.2854, -7.8183],
      [110.2857, -7.8187],
      [110.2859, -7.8189],
      [110.286, -7.819],
      [110.2858, -7.8193],
      [110.2858, -7.8196],
      [110.2859, -7.8199],
      [110.2862, -7.82],
      [110.2865, -7.8202],
      [110.2866, -7.8203],
      [110.287, -7.8207],
      [110.287, -7.8211],
      [110.2873, -7.8214],
      [110.2874, -7.8217],
      [110.2875, -7.822],
      [110.2876, -7.8222],
      [110.2876, -7.8227],
      [110.2876, -7.8232],
      [110.2876, -7.8236],
      [110.2875, -7.8242],
      [110.2874, -7.8246],
      [110.2872, -7.825],
      [110.2871, -7.8254],
      [110.2872, -7.826],
      [110.2871, -7.8265],
      [110.2867, -7.8268],
      [110.2864, -7.827],
      [110.2861, -7.8272],
      [110.2857, -7.8277],
      [110.2854, -7.8281],
      [110.2853, -7.8286],
      [110.2852, -7.829],
      [110.2851, -7.8297],
      [110.2852, -7.8297],
      [110.2859, -7.8299],
      [110.2865, -7.8301],
      [110.2872, -7.8306],
      [110.2876, -7.8307],
      [110.2875, -7.8311],
      [110.2876, -7.8316],
      [110.2878, -7.8321],
      [110.2881, -7.8328],
      [110.2883, -7.8333],
      [110.2885, -7.8336],
      [110.2887, -7.8339],
      [110.2895, -7.834],
      [110.291, -7.8341],
      [110.292, -7.834],
      [110.2928, -7.834],
      [110.2932, -7.8333],
      [110.2938, -7.8326],
      [110.2942, -7.8318],
      [110.2943, -7.8314],
      [110.2945, -7.8312],
      [110.2952, -7.8305],
      [110.2953, -7.8302],
      [110.2955, -7.8298],
      [110.2958, -7.8298],
      [110.2963, -7.83],
      [110.2967, -7.8293],
      [110.297, -7.8285],
      [110.2973, -7.8281],
      [110.2977, -7.828],
      [110.2979, -7.8278],
      [110.2981, -7.8274],
      [110.2987, -7.8275],
      [110.2997, -7.8276],
      [110.3007, -7.8276],
      [110.3014, -7.8275],
      [110.3017, -7.8262],
      [110.3019, -7.8254],
      [110.3017, -7.825],
      [110.302, -7.8248],
      [110.3018, -7.8244],
      [110.3019, -7.8237],
      [110.3021, -7.8227],
      [110.302, -7.8216],
      [110.3023, -7.8209],
      [110.3024, -7.82],
      [110.3026, -7.8191],
      [110.3029, -7.8186],
      [110.3033, -7.8182],
      [110.304, -7.8177],
      [110.3045, -7.8172],
      [110.3047, -7.8168],
      [110.3056, -7.8173],
      [110.3057, -7.8166],
      [110.306, -7.8161],
      [110.3063, -7.8159],
      [110.3072, -7.8158],
      [110.3082, -7.8159],
      [110.309, -7.8162],
      [110.3091, -7.8162],
      [110.3092, -7.8156],
      [110.308, -7.8147],
      [110.3086, -7.8123],
      [110.309, -7.8106],
      [110.3078, -7.8102],
      [110.3077, -7.8095],
      [110.3077, -7.809],
      [110.3081, -7.8074],
      [110.3082, -7.806],
      [110.308, -7.8053],
      [110.3075, -7.8053],
      [110.307, -7.805],
      [110.307, -7.8047],
      [110.3071, -7.8034],
      [110.3091, -7.8031],
      [110.3101, -7.8029],
      [110.3101, -7.7994],
      [110.3101, -7.7979],
      [110.3102, -7.7978],
      [110.3105, -7.7967],
      [110.3107, -7.7958],
      [110.3107, -7.7949],
      [110.3106, -7.7945],
      [110.3103, -7.7942],
      [110.3101, -7.7939],
      [110.31, -7.7937],
      [110.3098, -7.7932],
      [110.3099, -7.7924],
      [110.3096, -7.7924],
      [110.309, -7.7925],
      [110.3086, -7.7924],
      [110.3084, -7.7924],
      [110.3081, -7.7922],
      [110.308, -7.7919],
      [110.3082, -7.7914],
      [110.3069, -7.7914],
      [110.3045, -7.7915]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GAMPING',
    village: 'AMBARKETAWANG',
    border: [
      [110.3261, -7.7831],
      [110.3261, -7.7838],
      [110.3256, -7.7839],
      [110.3252, -7.7838],
      [110.3247, -7.7838],
      [110.3234, -7.7838],
      [110.3227, -7.7838],
      [110.3213, -7.7835],
      [110.3203, -7.7835],
      [110.32, -7.7834],
      [110.3201, -7.7821],
      [110.3196, -7.782],
      [110.3185, -7.7817],
      [110.3183, -7.7822],
      [110.3182, -7.7829],
      [110.3181, -7.7838],
      [110.3181, -7.7842],
      [110.3182, -7.7848],
      [110.3183, -7.7862],
      [110.3183, -7.7876],
      [110.3169, -7.7877],
      [110.3169, -7.7882],
      [110.3167, -7.7882],
      [110.3167, -7.7888],
      [110.3164, -7.7891],
      [110.3162, -7.7894],
      [110.316, -7.7897],
      [110.316, -7.7901],
      [110.3164, -7.7908],
      [110.3165, -7.791],
      [110.3163, -7.7917],
      [110.3163, -7.7919],
      [110.3161, -7.7924],
      [110.316, -7.7926],
      [110.3156, -7.7927],
      [110.313, -7.7932],
      [110.3102, -7.7937],
      [110.31, -7.7937],
      [110.3101, -7.7939],
      [110.3103, -7.7942],
      [110.3106, -7.7945],
      [110.3107, -7.7949],
      [110.3107, -7.7958],
      [110.3105, -7.7967],
      [110.3102, -7.7978],
      [110.3101, -7.7979],
      [110.3101, -7.7994],
      [110.3101, -7.8029],
      [110.3091, -7.8031],
      [110.3071, -7.8034],
      [110.307, -7.8047],
      [110.307, -7.805],
      [110.3075, -7.8053],
      [110.308, -7.8053],
      [110.3082, -7.806],
      [110.3081, -7.8074],
      [110.3077, -7.809],
      [110.3077, -7.8095],
      [110.3078, -7.8102],
      [110.309, -7.8106],
      [110.3086, -7.8123],
      [110.308, -7.8147],
      [110.3092, -7.8156],
      [110.3091, -7.8162],
      [110.309, -7.8162],
      [110.3082, -7.8159],
      [110.3072, -7.8158],
      [110.3063, -7.8159],
      [110.306, -7.8161],
      [110.3057, -7.8166],
      [110.3056, -7.8173],
      [110.3047, -7.8168],
      [110.3045, -7.8172],
      [110.304, -7.8177],
      [110.3033, -7.8182],
      [110.3029, -7.8186],
      [110.3026, -7.8191],
      [110.3024, -7.82],
      [110.3023, -7.8209],
      [110.302, -7.8216],
      [110.3021, -7.8227],
      [110.3019, -7.8237],
      [110.3018, -7.8244],
      [110.302, -7.8248],
      [110.3017, -7.825],
      [110.3019, -7.8254],
      [110.3017, -7.8262],
      [110.3014, -7.8275],
      [110.3016, -7.8275],
      [110.3031, -7.8277],
      [110.3041, -7.8279],
      [110.3046, -7.828],
      [110.3047, -7.828],
      [110.3049, -7.8282],
      [110.3049, -7.8283],
      [110.3055, -7.8286],
      [110.3061, -7.8288],
      [110.3062, -7.8289],
      [110.3067, -7.8291],
      [110.3067, -7.8287],
      [110.3069, -7.8286],
      [110.3072, -7.8285],
      [110.3072, -7.8282],
      [110.3071, -7.8276],
      [110.3066, -7.8276],
      [110.3066, -7.8272],
      [110.3064, -7.8268],
      [110.3063, -7.8265],
      [110.3064, -7.8262],
      [110.3066, -7.8256],
      [110.3068, -7.8249],
      [110.3068, -7.824],
      [110.3067, -7.8235],
      [110.3068, -7.8229],
      [110.3071, -7.8224],
      [110.3073, -7.8222],
      [110.3076, -7.8224],
      [110.3083, -7.8226],
      [110.3093, -7.8227],
      [110.3102, -7.8229],
      [110.3109, -7.8232],
      [110.3115, -7.8232],
      [110.3123, -7.8233],
      [110.3131, -7.8233],
      [110.3149, -7.8235],
      [110.3159, -7.8237],
      [110.3169, -7.8238],
      [110.317, -7.8238],
      [110.3176, -7.8236],
      [110.3179, -7.8231],
      [110.318, -7.8225],
      [110.3181, -7.822],
      [110.3179, -7.8215],
      [110.3178, -7.8208],
      [110.3179, -7.8201],
      [110.3176, -7.8198],
      [110.3173, -7.8196],
      [110.3169, -7.8197],
      [110.3162, -7.8199],
      [110.3157, -7.8198],
      [110.3151, -7.8194],
      [110.3152, -7.8191],
      [110.3153, -7.8181],
      [110.3155, -7.8176],
      [110.3151, -7.8175],
      [110.3152, -7.817],
      [110.3153, -7.8165],
      [110.3153, -7.8159],
      [110.3154, -7.8157],
      [110.3156, -7.8156],
      [110.3158, -7.8152],
      [110.3158, -7.8148],
      [110.3158, -7.8142],
      [110.3156, -7.8137],
      [110.3156, -7.8135],
      [110.3159, -7.8125],
      [110.316, -7.8119],
      [110.3161, -7.8107],
      [110.3162, -7.8099],
      [110.3165, -7.8092],
      [110.3165, -7.8084],
      [110.3167, -7.8079],
      [110.3174, -7.8064],
      [110.3182, -7.8064],
      [110.3185, -7.8064],
      [110.3187, -7.8063],
      [110.3201, -7.8065],
      [110.3213, -7.8068],
      [110.3221, -7.8068],
      [110.3223, -7.8067],
      [110.3226, -7.8067],
      [110.3231, -7.8064],
      [110.3233, -7.8062],
      [110.3235, -7.8057],
      [110.3236, -7.8057],
      [110.3243, -7.8059],
      [110.3248, -7.8059],
      [110.3252, -7.806],
      [110.3257, -7.8064],
      [110.3259, -7.8064],
      [110.3267, -7.8063],
      [110.3267, -7.806],
      [110.3271, -7.8061],
      [110.3276, -7.8061],
      [110.3276, -7.805],
      [110.3278, -7.805],
      [110.3279, -7.8056],
      [110.3282, -7.8056],
      [110.3282, -7.8061],
      [110.3287, -7.8062],
      [110.3289, -7.8063],
      [110.3289, -7.8068],
      [110.3288, -7.8073],
      [110.3288, -7.8074],
      [110.3292, -7.8075],
      [110.3295, -7.8077],
      [110.3298, -7.8079],
      [110.3301, -7.8077],
      [110.3302, -7.8076],
      [110.3303, -7.8072],
      [110.3304, -7.8068],
      [110.3308, -7.8064],
      [110.3308, -7.8062],
      [110.3307, -7.806],
      [110.3305, -7.8059],
      [110.3302, -7.8055],
      [110.33, -7.8052],
      [110.33, -7.805],
      [110.3302, -7.8047],
      [110.3306, -7.8042],
      [110.3309, -7.8039],
      [110.3313, -7.8037],
      [110.3317, -7.8037],
      [110.3321, -7.8037],
      [110.3322, -7.8036],
      [110.3323, -7.8033],
      [110.3322, -7.8031],
      [110.332, -7.8028],
      [110.3315, -7.8023],
      [110.3314, -7.8022],
      [110.3312, -7.802],
      [110.3305, -7.8015],
      [110.3303, -7.8014],
      [110.3291, -7.8004],
      [110.3289, -7.8002],
      [110.3284, -7.7999],
      [110.3276, -7.7995],
      [110.3275, -7.7993],
      [110.3273, -7.799],
      [110.3274, -7.7986],
      [110.3278, -7.7979],
      [110.3281, -7.7968],
      [110.3282, -7.7963],
      [110.3283, -7.7959],
      [110.3285, -7.795],
      [110.3285, -7.7949],
      [110.3286, -7.7947],
      [110.3286, -7.7944],
      [110.3286, -7.7942],
      [110.3286, -7.7934],
      [110.3286, -7.7933],
      [110.3283, -7.7928],
      [110.3279, -7.7923],
      [110.3277, -7.7921],
      [110.3274, -7.7918],
      [110.3272, -7.7914],
      [110.3272, -7.7913],
      [110.3274, -7.7903],
      [110.3273, -7.7899],
      [110.3272, -7.7897],
      [110.3272, -7.7896],
      [110.3272, -7.789],
      [110.3274, -7.7887],
      [110.328, -7.7884],
      [110.3281, -7.7883],
      [110.3282, -7.788],
      [110.3282, -7.7879],
      [110.3279, -7.7877],
      [110.3277, -7.7876],
      [110.3276, -7.7875],
      [110.3275, -7.7872],
      [110.3277, -7.787],
      [110.3278, -7.7867],
      [110.3277, -7.7865],
      [110.3274, -7.7865],
      [110.327, -7.7864],
      [110.3268, -7.7862],
      [110.3268, -7.786],
      [110.327, -7.7858],
      [110.3274, -7.7857],
      [110.3279, -7.7855],
      [110.3282, -7.7854],
      [110.3282, -7.7851],
      [110.3282, -7.785],
      [110.3281, -7.7845],
      [110.3278, -7.784],
      [110.3273, -7.7836],
      [110.3267, -7.7834],
      [110.3264, -7.7833],
      [110.3261, -7.7831]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GAMPING',
    village: 'BANYURADEN',
    border: [
      [110.3461, -7.7836],
      [110.3457, -7.7834],
      [110.3454, -7.7818],
      [110.3457, -7.781],
      [110.345, -7.7807],
      [110.3438, -7.7805],
      [110.3439, -7.7801],
      [110.3439, -7.78],
      [110.3409, -7.7794],
      [110.3396, -7.7791],
      [110.3366, -7.7784],
      [110.3366, -7.7781],
      [110.3366, -7.7779],
      [110.3367, -7.7777],
      [110.3369, -7.777],
      [110.3372, -7.7763],
      [110.3375, -7.7759],
      [110.3376, -7.7756],
      [110.337, -7.7753],
      [110.3374, -7.7738],
      [110.335, -7.7732],
      [110.3341, -7.7778],
      [110.3318, -7.7774],
      [110.3315, -7.7773],
      [110.3309, -7.7789],
      [110.3283, -7.7784],
      [110.327, -7.7782],
      [110.3269, -7.7782],
      [110.3271, -7.7788],
      [110.3275, -7.7793],
      [110.3279, -7.7796],
      [110.3284, -7.7799],
      [110.3286, -7.7803],
      [110.3284, -7.7808],
      [110.328, -7.7811],
      [110.3273, -7.781],
      [110.3266, -7.7811],
      [110.3262, -7.7815],
      [110.3261, -7.7819],
      [110.326, -7.7823],
      [110.3261, -7.7828],
      [110.3261, -7.783],
      [110.3261, -7.7831],
      [110.3264, -7.7833],
      [110.3267, -7.7834],
      [110.3273, -7.7836],
      [110.3278, -7.784],
      [110.3281, -7.7845],
      [110.3282, -7.785],
      [110.3282, -7.7851],
      [110.3282, -7.7854],
      [110.3279, -7.7855],
      [110.3274, -7.7857],
      [110.327, -7.7858],
      [110.3268, -7.786],
      [110.3268, -7.7862],
      [110.327, -7.7864],
      [110.3274, -7.7865],
      [110.3277, -7.7865],
      [110.3278, -7.7867],
      [110.3277, -7.787],
      [110.3275, -7.7872],
      [110.3276, -7.7875],
      [110.3277, -7.7876],
      [110.3279, -7.7877],
      [110.3282, -7.7879],
      [110.3282, -7.788],
      [110.3281, -7.7883],
      [110.328, -7.7884],
      [110.3274, -7.7887],
      [110.3272, -7.789],
      [110.3272, -7.7896],
      [110.3272, -7.7897],
      [110.3273, -7.7899],
      [110.3274, -7.7903],
      [110.3272, -7.7913],
      [110.3272, -7.7914],
      [110.3274, -7.7918],
      [110.3277, -7.7921],
      [110.3279, -7.7923],
      [110.3283, -7.7928],
      [110.3286, -7.7933],
      [110.3286, -7.7934],
      [110.3286, -7.7942],
      [110.3286, -7.7944],
      [110.3286, -7.7947],
      [110.3285, -7.7949],
      [110.3285, -7.795],
      [110.3283, -7.7959],
      [110.3282, -7.7963],
      [110.3281, -7.7968],
      [110.3278, -7.7979],
      [110.3274, -7.7986],
      [110.3273, -7.799],
      [110.3275, -7.7993],
      [110.3276, -7.7995],
      [110.3284, -7.7999],
      [110.3289, -7.8002],
      [110.3291, -7.8004],
      [110.3303, -7.8014],
      [110.3305, -7.8015],
      [110.3312, -7.802],
      [110.3314, -7.8022],
      [110.3316, -7.8018],
      [110.3317, -7.8013],
      [110.3318, -7.8007],
      [110.3318, -7.8006],
      [110.332, -7.8004],
      [110.3324, -7.8005],
      [110.3327, -7.8005],
      [110.3328, -7.8],
      [110.333, -7.8],
      [110.333, -7.7998],
      [110.3331, -7.7998],
      [110.3331, -7.8001],
      [110.3338, -7.8],
      [110.3342, -7.7982],
      [110.335, -7.7983],
      [110.3351, -7.798],
      [110.336, -7.798],
      [110.3361, -7.7975],
      [110.3363, -7.7976],
      [110.3364, -7.7977],
      [110.3372, -7.7978],
      [110.3377, -7.7977],
      [110.3382, -7.7978],
      [110.3386, -7.798],
      [110.3387, -7.7974],
      [110.339, -7.7965],
      [110.3392, -7.796],
      [110.3398, -7.7942],
      [110.3396, -7.7935],
      [110.3397, -7.793],
      [110.3398, -7.7923],
      [110.3402, -7.7922],
      [110.341, -7.7922],
      [110.3422, -7.7921],
      [110.3425, -7.7918],
      [110.3429, -7.7918],
      [110.3436, -7.7919],
      [110.3438, -7.7916],
      [110.3444, -7.7902],
      [110.3445, -7.7893],
      [110.3447, -7.7891],
      [110.3447, -7.7889],
      [110.3448, -7.7879],
      [110.3449, -7.7874],
      [110.345, -7.7871],
      [110.3451, -7.7867],
      [110.3452, -7.7863],
      [110.3454, -7.7859],
      [110.3457, -7.7857],
      [110.3459, -7.7856],
      [110.3463, -7.7854],
      [110.3463, -7.7845],
      [110.3463, -7.7838],
      [110.3463, -7.7836],
      [110.3461, -7.7836]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GAMPING',
    village: 'NOGOTIRTO',
    border: [
      [110.3325, -7.7578],
      [110.3323, -7.7576],
      [110.3322, -7.7574],
      [110.3321, -7.7571],
      [110.3315, -7.7569],
      [110.3313, -7.757],
      [110.3309, -7.7573],
      [110.3307, -7.7574],
      [110.3305, -7.7576],
      [110.3305, -7.7586],
      [110.3304, -7.7592],
      [110.33, -7.76],
      [110.3298, -7.7602],
      [110.3294, -7.7606],
      [110.3293, -7.7611],
      [110.3292, -7.7614],
      [110.3292, -7.7615],
      [110.3289, -7.7627],
      [110.3287, -7.7636],
      [110.3287, -7.7637],
      [110.329, -7.7645],
      [110.3291, -7.7648],
      [110.3294, -7.7655],
      [110.3293, -7.7663],
      [110.3293, -7.7675],
      [110.3293, -7.7681],
      [110.3292, -7.7681],
      [110.3287, -7.7683],
      [110.3286, -7.7684],
      [110.3282, -7.7686],
      [110.3281, -7.7687],
      [110.328, -7.7688],
      [110.3281, -7.769],
      [110.3282, -7.7693],
      [110.3284, -7.7699],
      [110.3285, -7.7701],
      [110.3288, -7.7714],
      [110.3292, -7.772],
      [110.3297, -7.7727],
      [110.3298, -7.7728],
      [110.3299, -7.7733],
      [110.33, -7.7736],
      [110.33, -7.7741],
      [110.33, -7.7742],
      [110.3299, -7.7742],
      [110.3298, -7.7743],
      [110.3295, -7.7744],
      [110.3293, -7.7744],
      [110.3287, -7.7744],
      [110.328, -7.7742],
      [110.3279, -7.7742],
      [110.3277, -7.7745],
      [110.3277, -7.7747],
      [110.3277, -7.7749],
      [110.3279, -7.7752],
      [110.3283, -7.7759],
      [110.3288, -7.7763],
      [110.329, -7.7765],
      [110.3291, -7.7767],
      [110.3291, -7.777],
      [110.3291, -7.7774],
      [110.3291, -7.7775],
      [110.3286, -7.7777],
      [110.3274, -7.7776],
      [110.327, -7.7778],
      [110.3269, -7.778],
      [110.3269, -7.7782],
      [110.327, -7.7782],
      [110.3283, -7.7784],
      [110.3309, -7.7789],
      [110.3315, -7.7773],
      [110.3318, -7.7774],
      [110.3341, -7.7778],
      [110.335, -7.7732],
      [110.3374, -7.7738],
      [110.337, -7.7753],
      [110.3376, -7.7756],
      [110.3375, -7.7759],
      [110.3372, -7.7763],
      [110.3369, -7.777],
      [110.3367, -7.7777],
      [110.3366, -7.7779],
      [110.3366, -7.7781],
      [110.3366, -7.7784],
      [110.3396, -7.7791],
      [110.3409, -7.7794],
      [110.3439, -7.78],
      [110.3439, -7.7801],
      [110.3438, -7.7805],
      [110.345, -7.7807],
      [110.3457, -7.781],
      [110.3454, -7.7818],
      [110.3457, -7.7834],
      [110.3461, -7.7836],
      [110.3463, -7.7836],
      [110.3475, -7.7821],
      [110.3477, -7.7806],
      [110.3477, -7.7801],
      [110.3477, -7.7798],
      [110.3477, -7.7797],
      [110.3477, -7.7793],
      [110.3476, -7.7789],
      [110.3476, -7.7788],
      [110.3477, -7.7787],
      [110.3478, -7.7784],
      [110.3478, -7.7782],
      [110.3477, -7.7779],
      [110.3477, -7.7778],
      [110.3477, -7.7777],
      [110.3475, -7.7774],
      [110.3475, -7.7773],
      [110.3475, -7.7765],
      [110.3476, -7.7754],
      [110.3479, -7.7748],
      [110.3484, -7.774],
      [110.3487, -7.7736],
      [110.3491, -7.7723],
      [110.3491, -7.7708],
      [110.349, -7.7705],
      [110.349, -7.7703],
      [110.3489, -7.7702],
      [110.3488, -7.7702],
      [110.3479, -7.7706],
      [110.3478, -7.7708],
      [110.3465, -7.7704],
      [110.3464, -7.7711],
      [110.3462, -7.7712],
      [110.3457, -7.7726],
      [110.3448, -7.7719],
      [110.3432, -7.7705],
      [110.3437, -7.7681],
      [110.343, -7.7679],
      [110.3417, -7.7676],
      [110.341, -7.7674],
      [110.3404, -7.767],
      [110.34, -7.767],
      [110.3392, -7.7668],
      [110.3387, -7.7666],
      [110.3383, -7.7661],
      [110.338, -7.7659],
      [110.3378, -7.7658],
      [110.3374, -7.7651],
      [110.3356, -7.7647],
      [110.3363, -7.7624],
      [110.3369, -7.761],
      [110.3376, -7.7586],
      [110.3375, -7.7584],
      [110.3368, -7.7582],
      [110.3357, -7.7581],
      [110.3345, -7.7581],
      [110.3338, -7.7584],
      [110.3333, -7.7585],
      [110.3329, -7.7585],
      [110.3328, -7.758],
      [110.3325, -7.7578]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GAMPING',
    village: 'TRIHANGGO',
    border: [
      [110.3533, -7.7466],
      [110.3534, -7.7463],
      [110.3536, -7.746],
      [110.3538, -7.7457],
      [110.3537, -7.7452],
      [110.354, -7.7451],
      [110.3541, -7.7449],
      [110.3541, -7.7445],
      [110.3542, -7.744],
      [110.3543, -7.7434],
      [110.3548, -7.7429],
      [110.355, -7.7425],
      [110.355, -7.742],
      [110.3549, -7.7417],
      [110.3551, -7.7408],
      [110.3551, -7.7403],
      [110.3547, -7.7395],
      [110.3545, -7.7391],
      [110.3545, -7.7385],
      [110.3544, -7.7384],
      [110.3542, -7.738],
      [110.3532, -7.7375],
      [110.3524, -7.737],
      [110.351, -7.7363],
      [110.3504, -7.7358],
      [110.3499, -7.7353],
      [110.3495, -7.7347],
      [110.3493, -7.7349],
      [110.349, -7.7356],
      [110.348, -7.7361],
      [110.3474, -7.7367],
      [110.3473, -7.7368],
      [110.3465, -7.7375],
      [110.346, -7.738],
      [110.3458, -7.7386],
      [110.3457, -7.7386],
      [110.3455, -7.7387],
      [110.3451, -7.739],
      [110.345, -7.7391],
      [110.3448, -7.7393],
      [110.3449, -7.7402],
      [110.3454, -7.7411],
      [110.3455, -7.7417],
      [110.3455, -7.7423],
      [110.3454, -7.7425],
      [110.3454, -7.7426],
      [110.3452, -7.7427],
      [110.3449, -7.7429],
      [110.3434, -7.7434],
      [110.3427, -7.7436],
      [110.3424, -7.7439],
      [110.3418, -7.7445],
      [110.3415, -7.7453],
      [110.3412, -7.7461],
      [110.3409, -7.7465],
      [110.3405, -7.7467],
      [110.3399, -7.7468],
      [110.3395, -7.7467],
      [110.3392, -7.7464],
      [110.3388, -7.7464],
      [110.3384, -7.7466],
      [110.3379, -7.7468],
      [110.3373, -7.747],
      [110.3372, -7.7471],
      [110.3371, -7.7474],
      [110.3369, -7.748],
      [110.3368, -7.7482],
      [110.3366, -7.7487],
      [110.3366, -7.7488],
      [110.3366, -7.7491],
      [110.3366, -7.7496],
      [110.3365, -7.7501],
      [110.3365, -7.7504],
      [110.3368, -7.7508],
      [110.3368, -7.751],
      [110.3367, -7.7513],
      [110.3358, -7.7516],
      [110.335, -7.752],
      [110.3347, -7.7521],
      [110.3342, -7.7523],
      [110.334, -7.7524],
      [110.3337, -7.7526],
      [110.3336, -7.7529],
      [110.3339, -7.7533],
      [110.334, -7.7536],
      [110.334, -7.754],
      [110.334, -7.7542],
      [110.3339, -7.7544],
      [110.3338, -7.7546],
      [110.3338, -7.7546],
      [110.3335, -7.7548],
      [110.333, -7.7548],
      [110.3329, -7.7552],
      [110.3328, -7.7555],
      [110.3325, -7.756],
      [110.3323, -7.7566],
      [110.3327, -7.7571],
      [110.3326, -7.7575],
      [110.3326, -7.7577],
      [110.3325, -7.7578],
      [110.3328, -7.758],
      [110.3329, -7.7585],
      [110.3333, -7.7585],
      [110.3338, -7.7584],
      [110.3345, -7.7581],
      [110.3357, -7.7581],
      [110.3368, -7.7582],
      [110.3375, -7.7584],
      [110.3376, -7.7586],
      [110.3369, -7.761],
      [110.3363, -7.7624],
      [110.3356, -7.7647],
      [110.3374, -7.7651],
      [110.3378, -7.7658],
      [110.338, -7.7659],
      [110.3383, -7.7661],
      [110.3387, -7.7666],
      [110.3392, -7.7668],
      [110.34, -7.767],
      [110.3404, -7.767],
      [110.341, -7.7674],
      [110.3417, -7.7676],
      [110.343, -7.7679],
      [110.3437, -7.7681],
      [110.3432, -7.7705],
      [110.3448, -7.7719],
      [110.3457, -7.7726],
      [110.3462, -7.7712],
      [110.3464, -7.7711],
      [110.3465, -7.7704],
      [110.3478, -7.7708],
      [110.3479, -7.7706],
      [110.3488, -7.7702],
      [110.3489, -7.7702],
      [110.349, -7.77],
      [110.3491, -7.7699],
      [110.3498, -7.7694],
      [110.3501, -7.7686],
      [110.3503, -7.7684],
      [110.3509, -7.768],
      [110.351, -7.7687],
      [110.3511, -7.7696],
      [110.3511, -7.7697],
      [110.3514, -7.7697],
      [110.3517, -7.7704],
      [110.3518, -7.7709],
      [110.3519, -7.7711],
      [110.3519, -7.7716],
      [110.3521, -7.7719],
      [110.3525, -7.7722],
      [110.3528, -7.7723],
      [110.3533, -7.7722],
      [110.3535, -7.7718],
      [110.3534, -7.7713],
      [110.3533, -7.7709],
      [110.3531, -7.7704],
      [110.3526, -7.7686],
      [110.3526, -7.7684],
      [110.3526, -7.7681],
      [110.3528, -7.7677],
      [110.3528, -7.7673],
      [110.3526, -7.7671],
      [110.3522, -7.7667],
      [110.3522, -7.7666],
      [110.3522, -7.7665],
      [110.3517, -7.7662],
      [110.3516, -7.7662],
      [110.3513, -7.766],
      [110.3512, -7.7657],
      [110.3513, -7.7651],
      [110.3513, -7.7643],
      [110.3512, -7.7639],
      [110.3514, -7.7624],
      [110.3516, -7.7619],
      [110.3512, -7.761],
      [110.3512, -7.7606],
      [110.3511, -7.7603],
      [110.351, -7.7598],
      [110.3512, -7.7598],
      [110.3515, -7.7597],
      [110.3519, -7.7598],
      [110.3521, -7.7599],
      [110.3524, -7.7598],
      [110.3527, -7.7594],
      [110.3528, -7.759],
      [110.3527, -7.7582],
      [110.3532, -7.7567],
      [110.3533, -7.7558],
      [110.3532, -7.7551],
      [110.3532, -7.7544],
      [110.3531, -7.7538],
      [110.3527, -7.7534],
      [110.3523, -7.7531],
      [110.3523, -7.7527],
      [110.3523, -7.7522],
      [110.3524, -7.7519],
      [110.3522, -7.7514],
      [110.3521, -7.751],
      [110.3522, -7.7504],
      [110.3522, -7.7499],
      [110.3523, -7.749],
      [110.3525, -7.7485],
      [110.3527, -7.748],
      [110.3527, -7.7473],
      [110.353, -7.7471],
      [110.3533, -7.747],
      [110.3533, -7.7466]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GODEAN',
    village: 'SIDOREJO',
    border: [
      [110.2757, -7.7378],
      [110.2756, -7.7379],
      [110.2752, -7.738],
      [110.2747, -7.738],
      [110.2742, -7.7379],
      [110.2733, -7.7373],
      [110.2722, -7.7365],
      [110.2709, -7.7356],
      [110.2699, -7.7349],
      [110.2668, -7.7349],
      [110.2651, -7.7349],
      [110.2643, -7.7349],
      [110.2641, -7.7355],
      [110.2643, -7.7355],
      [110.2647, -7.7353],
      [110.2652, -7.7352],
      [110.2658, -7.7351],
      [110.2661, -7.7352],
      [110.266, -7.7358],
      [110.2655, -7.7361],
      [110.2651, -7.7363],
      [110.2647, -7.7368],
      [110.2647, -7.7373],
      [110.2647, -7.7378],
      [110.2646, -7.738],
      [110.2645, -7.7383],
      [110.2644, -7.7385],
      [110.2643, -7.7387],
      [110.264, -7.739],
      [110.2638, -7.7393],
      [110.2644, -7.7395],
      [110.2647, -7.7396],
      [110.2648, -7.74],
      [110.2648, -7.7406],
      [110.2646, -7.7409],
      [110.2645, -7.7411],
      [110.2644, -7.7416],
      [110.2643, -7.7421],
      [110.2645, -7.7426],
      [110.2647, -7.743],
      [110.2652, -7.743],
      [110.2655, -7.743],
      [110.2656, -7.7431],
      [110.2656, -7.7436],
      [110.2653, -7.7438],
      [110.2649, -7.7439],
      [110.2648, -7.7441],
      [110.2647, -7.7444],
      [110.2649, -7.7447],
      [110.2653, -7.7453],
      [110.2658, -7.7459],
      [110.2666, -7.7465],
      [110.2666, -7.746],
      [110.2669, -7.7457],
      [110.2673, -7.7453],
      [110.2676, -7.7449],
      [110.2683, -7.7446],
      [110.2688, -7.7446],
      [110.2693, -7.7448],
      [110.2696, -7.745],
      [110.2711, -7.7455],
      [110.2709, -7.746],
      [110.2706, -7.7464],
      [110.2705, -7.7467],
      [110.27, -7.7475],
      [110.2698, -7.748],
      [110.2695, -7.7486],
      [110.2692, -7.7491],
      [110.2688, -7.7499],
      [110.2687, -7.7503],
      [110.2683, -7.7512],
      [110.2682, -7.7516],
      [110.2677, -7.7524],
      [110.2673, -7.7532],
      [110.2668, -7.754],
      [110.2667, -7.7543],
      [110.2665, -7.7542],
      [110.266, -7.7541],
      [110.2659, -7.7537],
      [110.2655, -7.7536],
      [110.2648, -7.7559],
      [110.2643, -7.7572],
      [110.2647, -7.7574],
      [110.2651, -7.7575],
      [110.2652, -7.7576],
      [110.2649, -7.7581],
      [110.2647, -7.7585],
      [110.2642, -7.7594],
      [110.2636, -7.7607],
      [110.265, -7.761],
      [110.2646, -7.7621],
      [110.2644, -7.7627],
      [110.2639, -7.7643],
      [110.2637, -7.7648],
      [110.2636, -7.7652],
      [110.263, -7.7668],
      [110.2629, -7.7673],
      [110.2628, -7.7684],
      [110.263, -7.7684],
      [110.2638, -7.7686],
      [110.264, -7.7686],
      [110.264, -7.7688],
      [110.264, -7.769],
      [110.264, -7.7693],
      [110.2639, -7.7696],
      [110.2641, -7.7699],
      [110.264, -7.7704],
      [110.264, -7.7709],
      [110.2641, -7.7712],
      [110.2643, -7.7718],
      [110.2645, -7.7726],
      [110.2646, -7.7727],
      [110.2652, -7.7727],
      [110.2654, -7.7736],
      [110.2655, -7.7753],
      [110.2656, -7.7764],
      [110.266, -7.7763],
      [110.2667, -7.7763],
      [110.2665, -7.7756],
      [110.2664, -7.7752],
      [110.2664, -7.7747],
      [110.2666, -7.7738],
      [110.2662, -7.7738],
      [110.2661, -7.7728],
      [110.2661, -7.772],
      [110.2662, -7.7716],
      [110.2667, -7.7717],
      [110.2672, -7.7718],
      [110.2682, -7.7719],
      [110.2683, -7.7719],
      [110.2685, -7.7719],
      [110.2685, -7.7714],
      [110.2689, -7.77],
      [110.2692, -7.7685],
      [110.2695, -7.7682],
      [110.2698, -7.7677],
      [110.27, -7.7677],
      [110.2702, -7.7649],
      [110.2704, -7.7634],
      [110.2707, -7.7632],
      [110.2721, -7.7636],
      [110.2724, -7.7623],
      [110.2733, -7.7624],
      [110.2733, -7.7621],
      [110.2736, -7.7608],
      [110.2738, -7.7599],
      [110.2739, -7.7598],
      [110.2759, -7.7602],
      [110.2757, -7.7609],
      [110.2772, -7.7614],
      [110.2773, -7.761],
      [110.2776, -7.7609],
      [110.2776, -7.7606],
      [110.2775, -7.7604],
      [110.2774, -7.7603],
      [110.2772, -7.7601],
      [110.2771, -7.7599],
      [110.2766, -7.7599],
      [110.2764, -7.7596],
      [110.2763, -7.7593],
      [110.2759, -7.7592],
      [110.2757, -7.759],
      [110.2756, -7.7587],
      [110.2754, -7.7585],
      [110.2754, -7.7584],
      [110.275, -7.7581],
      [110.2749, -7.7577],
      [110.2748, -7.7574],
      [110.2748, -7.7572],
      [110.2748, -7.7569],
      [110.2753, -7.7569],
      [110.2754, -7.7565],
      [110.2754, -7.7564],
      [110.2754, -7.7561],
      [110.2757, -7.756],
      [110.2761, -7.7563],
      [110.2762, -7.7565],
      [110.2773, -7.7569],
      [110.2777, -7.7563],
      [110.2791, -7.757],
      [110.2794, -7.7563],
      [110.2798, -7.7563],
      [110.2803, -7.7565],
      [110.2806, -7.7564],
      [110.2813, -7.7568],
      [110.2816, -7.757],
      [110.282, -7.7571],
      [110.2832, -7.7564],
      [110.2839, -7.7561],
      [110.2843, -7.7562],
      [110.2843, -7.7564],
      [110.2843, -7.7567],
      [110.2853, -7.757],
      [110.2864, -7.7574],
      [110.2869, -7.7574],
      [110.2869, -7.7572],
      [110.288, -7.7576],
      [110.2883, -7.7576],
      [110.2884, -7.7576],
      [110.2888, -7.7572],
      [110.2892, -7.7568],
      [110.2895, -7.7566],
      [110.2898, -7.7564],
      [110.2897, -7.7562],
      [110.2894, -7.7558],
      [110.2891, -7.7553],
      [110.2889, -7.7546],
      [110.2887, -7.7541],
      [110.2888, -7.7536],
      [110.2889, -7.7533],
      [110.2881, -7.7532],
      [110.288, -7.7525],
      [110.2877, -7.7524],
      [110.2872, -7.7522],
      [110.2871, -7.7522],
      [110.2868, -7.7521],
      [110.2867, -7.7515],
      [110.2869, -7.7511],
      [110.2871, -7.7507],
      [110.2868, -7.7503],
      [110.2865, -7.7501],
      [110.2864, -7.7496],
      [110.286, -7.7492],
      [110.2856, -7.7492],
      [110.2852, -7.7495],
      [110.285, -7.7498],
      [110.2852, -7.7501],
      [110.2847, -7.7507],
      [110.2843, -7.751],
      [110.2834, -7.7512],
      [110.2828, -7.7512],
      [110.2823, -7.7506],
      [110.2819, -7.7501],
      [110.2817, -7.7496],
      [110.2816, -7.7491],
      [110.2815, -7.7486],
      [110.2815, -7.7482],
      [110.2817, -7.7479],
      [110.2819, -7.7474],
      [110.2819, -7.7468],
      [110.2819, -7.7462],
      [110.2813, -7.7453],
      [110.2806, -7.7446],
      [110.28, -7.7439],
      [110.2795, -7.7433],
      [110.2788, -7.743],
      [110.2782, -7.7427],
      [110.2778, -7.7423],
      [110.2776, -7.7415],
      [110.2776, -7.7407],
      [110.2778, -7.7396],
      [110.2782, -7.739],
      [110.2784, -7.7385],
      [110.2784, -7.7382],
      [110.2782, -7.738],
      [110.2781, -7.7378],
      [110.2777, -7.738],
      [110.2773, -7.7376],
      [110.2771, -7.7375],
      [110.2767, -7.7374],
      [110.2764, -7.7375],
      [110.2763, -7.7378],
      [110.276, -7.7378],
      [110.2759, -7.7378],
      [110.2757, -7.7378]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GODEAN',
    village: 'SIDOLUHUR',
    border: [
      [110.2884, -7.7576],
      [110.2883, -7.7576],
      [110.288, -7.7576],
      [110.2869, -7.7572],
      [110.2869, -7.7574],
      [110.2864, -7.7574],
      [110.2853, -7.757],
      [110.2843, -7.7567],
      [110.2843, -7.7564],
      [110.2843, -7.7562],
      [110.2839, -7.7561],
      [110.2832, -7.7564],
      [110.282, -7.7571],
      [110.2816, -7.757],
      [110.2813, -7.7568],
      [110.2806, -7.7564],
      [110.2803, -7.7565],
      [110.2798, -7.7563],
      [110.2794, -7.7563],
      [110.2791, -7.757],
      [110.2777, -7.7563],
      [110.2773, -7.7569],
      [110.2762, -7.7565],
      [110.2761, -7.7563],
      [110.2757, -7.756],
      [110.2754, -7.7561],
      [110.2754, -7.7564],
      [110.2754, -7.7565],
      [110.2753, -7.7569],
      [110.2748, -7.7569],
      [110.2748, -7.7572],
      [110.2748, -7.7574],
      [110.2749, -7.7577],
      [110.275, -7.7581],
      [110.2754, -7.7584],
      [110.2754, -7.7585],
      [110.2756, -7.7587],
      [110.2757, -7.759],
      [110.2759, -7.7592],
      [110.2763, -7.7593],
      [110.2764, -7.7596],
      [110.2766, -7.7599],
      [110.2771, -7.7599],
      [110.2772, -7.7601],
      [110.2774, -7.7603],
      [110.2775, -7.7604],
      [110.2776, -7.7606],
      [110.2776, -7.7609],
      [110.2773, -7.761],
      [110.2772, -7.7614],
      [110.2757, -7.7609],
      [110.2759, -7.7602],
      [110.2739, -7.7598],
      [110.2738, -7.7599],
      [110.2736, -7.7608],
      [110.2733, -7.7621],
      [110.2733, -7.7624],
      [110.2724, -7.7623],
      [110.2721, -7.7636],
      [110.2707, -7.7632],
      [110.2704, -7.7634],
      [110.2702, -7.7649],
      [110.27, -7.7677],
      [110.2706, -7.7678],
      [110.2715, -7.768],
      [110.2718, -7.7682],
      [110.2715, -7.7688],
      [110.2714, -7.7691],
      [110.2712, -7.7703],
      [110.2711, -7.7712],
      [110.2704, -7.7718],
      [110.2701, -7.772],
      [110.2702, -7.7724],
      [110.2706, -7.7721],
      [110.271, -7.772],
      [110.2711, -7.7719],
      [110.2717, -7.7721],
      [110.2713, -7.7731],
      [110.2709, -7.7742],
      [110.2709, -7.7747],
      [110.2709, -7.7751],
      [110.2711, -7.7751],
      [110.272, -7.7751],
      [110.2721, -7.7761],
      [110.2721, -7.7764],
      [110.2732, -7.7769],
      [110.274, -7.7774],
      [110.2738, -7.7779],
      [110.2745, -7.7782],
      [110.2739, -7.7796],
      [110.2747, -7.7799],
      [110.2768, -7.7806],
      [110.2803, -7.7817],
      [110.2813, -7.782],
      [110.2819, -7.7821],
      [110.2822, -7.7816],
      [110.2827, -7.7813],
      [110.2832, -7.7814],
      [110.2837, -7.7815],
      [110.2838, -7.7813],
      [110.2846, -7.7803],
      [110.2852, -7.7806],
      [110.2853, -7.7805],
      [110.2857, -7.7806],
      [110.2863, -7.7809],
      [110.2869, -7.7814],
      [110.2873, -7.7805],
      [110.2869, -7.7803],
      [110.287, -7.7798],
      [110.2878, -7.78],
      [110.2875, -7.781],
      [110.2879, -7.7812],
      [110.2882, -7.7807],
      [110.2882, -7.7804],
      [110.2882, -7.7802],
      [110.2882, -7.7799],
      [110.2881, -7.7793],
      [110.2884, -7.7788],
      [110.2884, -7.7782],
      [110.2888, -7.7777],
      [110.2891, -7.7772],
      [110.2893, -7.7768],
      [110.2895, -7.7767],
      [110.2898, -7.7765],
      [110.29, -7.776],
      [110.2904, -7.7756],
      [110.2905, -7.7755],
      [110.2909, -7.7754],
      [110.2913, -7.7754],
      [110.2915, -7.7752],
      [110.2916, -7.7751],
      [110.2916, -7.775],
      [110.2917, -7.7748],
      [110.2918, -7.7742],
      [110.2918, -7.7741],
      [110.2912, -7.7733],
      [110.2914, -7.773],
      [110.2914, -7.7729],
      [110.2914, -7.7727],
      [110.2918, -7.7722],
      [110.292, -7.7719],
      [110.292, -7.7712],
      [110.2916, -7.7708],
      [110.291, -7.7704],
      [110.2908, -7.7704],
      [110.2909, -7.7699],
      [110.2914, -7.7694],
      [110.2916, -7.7691],
      [110.2917, -7.7688],
      [110.2915, -7.7685],
      [110.2908, -7.7683],
      [110.291, -7.7678],
      [110.2914, -7.767],
      [110.292, -7.7654],
      [110.2906, -7.7647],
      [110.2907, -7.7636],
      [110.2909, -7.7627],
      [110.2914, -7.7617],
      [110.2904, -7.7613],
      [110.2905, -7.7609],
      [110.2905, -7.7606],
      [110.2902, -7.7603],
      [110.29, -7.7602],
      [110.29, -7.76],
      [110.2896, -7.7599],
      [110.2899, -7.7588],
      [110.29, -7.7583],
      [110.29, -7.7581],
      [110.2897, -7.7581],
      [110.2895, -7.7584],
      [110.2892, -7.758],
      [110.2887, -7.7578],
      [110.2885, -7.7577],
      [110.2884, -7.7576]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GODEAN',
    village: 'SIDOMULYO',
    border: [
      [110.2951, -7.7757],
      [110.2946, -7.7756],
      [110.294, -7.7753],
      [110.2937, -7.7751],
      [110.2931, -7.7747],
      [110.2926, -7.7744],
      [110.2925, -7.774],
      [110.2923, -7.7741],
      [110.2921, -7.7739],
      [110.2918, -7.7741],
      [110.2918, -7.7742],
      [110.2917, -7.7748],
      [110.2916, -7.775],
      [110.2916, -7.7751],
      [110.2915, -7.7752],
      [110.2913, -7.7754],
      [110.2909, -7.7754],
      [110.2905, -7.7755],
      [110.2904, -7.7756],
      [110.29, -7.776],
      [110.2898, -7.7765],
      [110.2895, -7.7767],
      [110.2893, -7.7768],
      [110.2891, -7.7772],
      [110.2888, -7.7777],
      [110.2884, -7.7782],
      [110.2884, -7.7788],
      [110.2881, -7.7793],
      [110.2882, -7.7799],
      [110.2882, -7.7802],
      [110.2882, -7.7804],
      [110.2882, -7.7807],
      [110.2879, -7.7812],
      [110.2875, -7.781],
      [110.2878, -7.78],
      [110.287, -7.7798],
      [110.2869, -7.7803],
      [110.2873, -7.7805],
      [110.2869, -7.7814],
      [110.2872, -7.7816],
      [110.2874, -7.7818],
      [110.2877, -7.7816],
      [110.2882, -7.7822],
      [110.2878, -7.7824],
      [110.2875, -7.7829],
      [110.2869, -7.7834],
      [110.2864, -7.7842],
      [110.2862, -7.7847],
      [110.2866, -7.7848],
      [110.2866, -7.785],
      [110.2871, -7.7852],
      [110.2882, -7.7854],
      [110.2887, -7.7856],
      [110.2884, -7.7865],
      [110.2884, -7.7871],
      [110.2882, -7.7873],
      [110.2871, -7.7876],
      [110.2862, -7.7879],
      [110.2865, -7.7882],
      [110.2865, -7.7883],
      [110.2864, -7.7894],
      [110.2863, -7.7901],
      [110.2867, -7.7906],
      [110.2872, -7.7912],
      [110.2877, -7.7917],
      [110.2878, -7.7918],
      [110.2887, -7.7926],
      [110.2897, -7.7934],
      [110.2902, -7.7938],
      [110.2902, -7.794],
      [110.2918, -7.7946],
      [110.292, -7.7947],
      [110.2924, -7.7938],
      [110.293, -7.7937],
      [110.2938, -7.7926],
      [110.294, -7.792],
      [110.2942, -7.7915],
      [110.2943, -7.7912],
      [110.2945, -7.7912],
      [110.2945, -7.7915],
      [110.2951, -7.7916],
      [110.2955, -7.7917],
      [110.2957, -7.7924],
      [110.2955, -7.7929],
      [110.2954, -7.793],
      [110.2954, -7.7933],
      [110.2954, -7.7937],
      [110.2952, -7.7939],
      [110.2947, -7.794],
      [110.2942, -7.7941],
      [110.2932, -7.7953],
      [110.295, -7.7953],
      [110.2958, -7.7953],
      [110.2958, -7.7957],
      [110.2961, -7.7959],
      [110.2961, -7.7965],
      [110.2967, -7.7968],
      [110.2965, -7.7971],
      [110.2964, -7.7974],
      [110.2975, -7.7976],
      [110.2992, -7.7979],
      [110.2993, -7.7976],
      [110.2994, -7.7976],
      [110.2996, -7.7966],
      [110.2997, -7.7965],
      [110.2992, -7.7996],
      [110.2995, -7.7997],
      [110.3001, -7.7998],
      [110.3015, -7.8],
      [110.3018, -7.7982],
      [110.3022, -7.795],
      [110.3025, -7.7928],
      [110.3027, -7.7912],
      [110.3036, -7.7914],
      [110.3045, -7.7915],
      [110.3047, -7.79],
      [110.3051, -7.7866],
      [110.3034, -7.7859],
      [110.3011, -7.7849],
      [110.3001, -7.7845],
      [110.2997, -7.7843],
      [110.2972, -7.7827],
      [110.2938, -7.7822],
      [110.2941, -7.7814],
      [110.2938, -7.781],
      [110.2934, -7.7809],
      [110.2947, -7.7789],
      [110.2935, -7.7783],
      [110.2937, -7.7778],
      [110.2944, -7.7781],
      [110.2952, -7.778],
      [110.2951, -7.7772],
      [110.2946, -7.7773],
      [110.2951, -7.7757]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GODEAN',
    village: 'SIDOAGUNG',
    border: [
      [110.3056, -7.7649],
      [110.3055, -7.7647],
      [110.3059, -7.7641],
      [110.3061, -7.7636],
      [110.3063, -7.7633],
      [110.3062, -7.7629],
      [110.3059, -7.7621],
      [110.3057, -7.7616],
      [110.3055, -7.7613],
      [110.3053, -7.761],
      [110.3055, -7.7608],
      [110.3056, -7.7605],
      [110.3062, -7.7598],
      [110.3066, -7.7595],
      [110.3071, -7.7592],
      [110.3074, -7.759],
      [110.307, -7.7585],
      [110.3053, -7.7576],
      [110.3041, -7.7572],
      [110.3042, -7.7569],
      [110.3031, -7.7566],
      [110.3018, -7.7563],
      [110.301, -7.7561],
      [110.3005, -7.756],
      [110.2998, -7.7565],
      [110.2995, -7.7572],
      [110.2985, -7.7569],
      [110.298, -7.7567],
      [110.2978, -7.7575],
      [110.2975, -7.7585],
      [110.2972, -7.7593],
      [110.2954, -7.7589],
      [110.2947, -7.7587],
      [110.2945, -7.7594],
      [110.2942, -7.7592],
      [110.2925, -7.7585],
      [110.2918, -7.7608],
      [110.2915, -7.7618],
      [110.2914, -7.7617],
      [110.2909, -7.7627],
      [110.2907, -7.7636],
      [110.2906, -7.7647],
      [110.292, -7.7654],
      [110.2914, -7.767],
      [110.291, -7.7678],
      [110.2908, -7.7683],
      [110.2915, -7.7685],
      [110.2917, -7.7688],
      [110.2916, -7.7691],
      [110.2914, -7.7694],
      [110.2909, -7.7699],
      [110.2908, -7.7704],
      [110.291, -7.7704],
      [110.2916, -7.7708],
      [110.292, -7.7712],
      [110.292, -7.7719],
      [110.2918, -7.7722],
      [110.2914, -7.7727],
      [110.2914, -7.7729],
      [110.2914, -7.773],
      [110.2912, -7.7733],
      [110.2918, -7.7741],
      [110.2921, -7.7739],
      [110.2923, -7.7741],
      [110.2925, -7.774],
      [110.2926, -7.7744],
      [110.2931, -7.7747],
      [110.2937, -7.7751],
      [110.294, -7.7753],
      [110.2946, -7.7756],
      [110.2951, -7.7757],
      [110.2953, -7.775],
      [110.2968, -7.7752],
      [110.2991, -7.7757],
      [110.3006, -7.7697],
      [110.3017, -7.7699],
      [110.3031, -7.7703],
      [110.3038, -7.7687],
      [110.3056, -7.7649]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GODEAN',
    village: 'SIDOKARTO',
    border: [
      [110.3128, -7.7616],
      [110.3123, -7.7617],
      [110.3121, -7.7615],
      [110.3113, -7.7614],
      [110.3112, -7.7619],
      [110.3109, -7.7624],
      [110.3102, -7.7626],
      [110.3101, -7.763],
      [110.3097, -7.7629],
      [110.3096, -7.7631],
      [110.3099, -7.7636],
      [110.3091, -7.7653],
      [110.3082, -7.7674],
      [110.3077, -7.7674],
      [110.3071, -7.7673],
      [110.3067, -7.7668],
      [110.3063, -7.7659],
      [110.3059, -7.7655],
      [110.3057, -7.765],
      [110.3056, -7.7649],
      [110.3038, -7.7687],
      [110.3031, -7.7703],
      [110.3017, -7.7699],
      [110.3006, -7.7697],
      [110.2991, -7.7757],
      [110.2968, -7.7752],
      [110.2953, -7.775],
      [110.2951, -7.7757],
      [110.2946, -7.7773],
      [110.2951, -7.7772],
      [110.2952, -7.778],
      [110.2944, -7.7781],
      [110.2937, -7.7778],
      [110.2935, -7.7783],
      [110.2947, -7.7789],
      [110.2934, -7.7809],
      [110.2938, -7.781],
      [110.2941, -7.7814],
      [110.2938, -7.7822],
      [110.2972, -7.7827],
      [110.2997, -7.7843],
      [110.3001, -7.7845],
      [110.3011, -7.7849],
      [110.3034, -7.7859],
      [110.3051, -7.7866],
      [110.3047, -7.79],
      [110.3045, -7.7915],
      [110.3069, -7.7914],
      [110.3082, -7.7914],
      [110.3082, -7.7908],
      [110.3082, -7.7904],
      [110.3082, -7.7903],
      [110.308, -7.7896],
      [110.308, -7.7893],
      [110.3079, -7.7888],
      [110.3079, -7.7881],
      [110.3079, -7.788],
      [110.308, -7.788],
      [110.3081, -7.7876],
      [110.3086, -7.7866],
      [110.3093, -7.7858],
      [110.3094, -7.7854],
      [110.3096, -7.7847],
      [110.3098, -7.7841],
      [110.3099, -7.7839],
      [110.3101, -7.7837],
      [110.3108, -7.7833],
      [110.3108, -7.7831],
      [110.3102, -7.7828],
      [110.3098, -7.7826],
      [110.309, -7.7822],
      [110.3087, -7.7818],
      [110.3069, -7.7813],
      [110.3072, -7.7804],
      [110.3079, -7.779],
      [110.3088, -7.7794],
      [110.3092, -7.7792],
      [110.3106, -7.7796],
      [110.3115, -7.7796],
      [110.3119, -7.7787],
      [110.3125, -7.7787],
      [110.3128, -7.7784],
      [110.3129, -7.7783],
      [110.313, -7.7774],
      [110.3135, -7.7769],
      [110.3139, -7.7767],
      [110.3138, -7.7765],
      [110.3138, -7.7764],
      [110.313, -7.7761],
      [110.3129, -7.776],
      [110.3128, -7.7759],
      [110.3127, -7.7754],
      [110.3126, -7.7752],
      [110.3128, -7.775],
      [110.3131, -7.7745],
      [110.3137, -7.7734],
      [110.3141, -7.7731],
      [110.3144, -7.7727],
      [110.3143, -7.7724],
      [110.3143, -7.7722],
      [110.314, -7.7716],
      [110.3138, -7.7711],
      [110.3137, -7.771],
      [110.3135, -7.7703],
      [110.3132, -7.7699],
      [110.3132, -7.7698],
      [110.3136, -7.7692],
      [110.3138, -7.7686],
      [110.3145, -7.7675],
      [110.3145, -7.7674],
      [110.3143, -7.7668],
      [110.3142, -7.7662],
      [110.3142, -7.7659],
      [110.314, -7.765],
      [110.3138, -7.7645],
      [110.3134, -7.7643],
      [110.3133, -7.7641],
      [110.3132, -7.7638],
      [110.3134, -7.7633],
      [110.3135, -7.763],
      [110.3132, -7.7624],
      [110.3128, -7.7616]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GODEAN',
    village: 'SIDOARUM',
    border: [
      [110.329, -7.7645],
      [110.3277, -7.7651],
      [110.3271, -7.7654],
      [110.3266, -7.7654],
      [110.3262, -7.7653],
      [110.3257, -7.7649],
      [110.325, -7.7643],
      [110.3247, -7.7641],
      [110.3247, -7.7636],
      [110.3246, -7.7637],
      [110.3241, -7.7641],
      [110.3238, -7.7649],
      [110.3236, -7.7654],
      [110.3232, -7.7655],
      [110.3231, -7.766],
      [110.3222, -7.7656],
      [110.3216, -7.7674],
      [110.3214, -7.7681],
      [110.3209, -7.7696],
      [110.3207, -7.77],
      [110.3208, -7.7703],
      [110.3202, -7.7728],
      [110.3197, -7.7728],
      [110.319, -7.7727],
      [110.3186, -7.7725],
      [110.3176, -7.773],
      [110.3166, -7.7728],
      [110.3165, -7.7744],
      [110.3162, -7.7766],
      [110.3153, -7.7764],
      [110.3144, -7.7761],
      [110.3141, -7.7762],
      [110.3138, -7.7764],
      [110.3138, -7.7765],
      [110.3139, -7.7767],
      [110.3135, -7.7769],
      [110.313, -7.7774],
      [110.3129, -7.7783],
      [110.3128, -7.7784],
      [110.3125, -7.7787],
      [110.3119, -7.7787],
      [110.3115, -7.7796],
      [110.3106, -7.7796],
      [110.3092, -7.7792],
      [110.3088, -7.7794],
      [110.3079, -7.779],
      [110.3072, -7.7804],
      [110.3069, -7.7813],
      [110.3087, -7.7818],
      [110.309, -7.7822],
      [110.3098, -7.7826],
      [110.3102, -7.7828],
      [110.3108, -7.7831],
      [110.3108, -7.7833],
      [110.3101, -7.7837],
      [110.3099, -7.7839],
      [110.3098, -7.7841],
      [110.3096, -7.7847],
      [110.3094, -7.7854],
      [110.3093, -7.7858],
      [110.3086, -7.7866],
      [110.3081, -7.7876],
      [110.308, -7.788],
      [110.3079, -7.788],
      [110.3079, -7.7881],
      [110.3079, -7.7888],
      [110.308, -7.7893],
      [110.308, -7.7896],
      [110.3082, -7.7903],
      [110.3082, -7.7904],
      [110.3082, -7.7908],
      [110.3082, -7.7914],
      [110.308, -7.7919],
      [110.3081, -7.7922],
      [110.3084, -7.7924],
      [110.3086, -7.7924],
      [110.309, -7.7925],
      [110.3096, -7.7924],
      [110.3099, -7.7924],
      [110.3098, -7.7932],
      [110.31, -7.7937],
      [110.3102, -7.7937],
      [110.313, -7.7932],
      [110.3156, -7.7927],
      [110.316, -7.7926],
      [110.3161, -7.7924],
      [110.3163, -7.7919],
      [110.3163, -7.7917],
      [110.3165, -7.791],
      [110.3164, -7.7908],
      [110.316, -7.7901],
      [110.316, -7.7897],
      [110.3162, -7.7894],
      [110.3164, -7.7891],
      [110.3167, -7.7888],
      [110.3167, -7.7882],
      [110.3169, -7.7882],
      [110.3169, -7.7877],
      [110.3183, -7.7876],
      [110.3183, -7.7862],
      [110.3182, -7.7848],
      [110.3181, -7.7842],
      [110.3181, -7.7838],
      [110.3182, -7.7829],
      [110.3183, -7.7822],
      [110.3185, -7.7817],
      [110.3196, -7.782],
      [110.3201, -7.7821],
      [110.32, -7.7834],
      [110.3203, -7.7835],
      [110.3213, -7.7835],
      [110.3227, -7.7838],
      [110.3234, -7.7838],
      [110.3247, -7.7838],
      [110.3252, -7.7838],
      [110.3256, -7.7839],
      [110.3261, -7.7838],
      [110.3261, -7.7831],
      [110.3261, -7.783],
      [110.3261, -7.7828],
      [110.326, -7.7823],
      [110.3261, -7.7819],
      [110.3262, -7.7815],
      [110.3266, -7.7811],
      [110.3273, -7.781],
      [110.328, -7.7811],
      [110.3284, -7.7808],
      [110.3286, -7.7803],
      [110.3284, -7.7799],
      [110.3279, -7.7796],
      [110.3275, -7.7793],
      [110.3271, -7.7788],
      [110.3269, -7.7782],
      [110.3269, -7.778],
      [110.327, -7.7778],
      [110.3274, -7.7776],
      [110.3286, -7.7777],
      [110.3291, -7.7775],
      [110.3291, -7.7774],
      [110.3291, -7.777],
      [110.3291, -7.7767],
      [110.329, -7.7765],
      [110.3288, -7.7763],
      [110.3283, -7.7759],
      [110.3279, -7.7752],
      [110.3277, -7.7749],
      [110.3277, -7.7747],
      [110.3277, -7.7745],
      [110.3279, -7.7742],
      [110.328, -7.7742],
      [110.3287, -7.7744],
      [110.3293, -7.7744],
      [110.3295, -7.7744],
      [110.3298, -7.7743],
      [110.3299, -7.7742],
      [110.33, -7.7742],
      [110.33, -7.7741],
      [110.33, -7.7736],
      [110.3299, -7.7733],
      [110.3298, -7.7728],
      [110.3297, -7.7727],
      [110.3292, -7.772],
      [110.3288, -7.7714],
      [110.3285, -7.7701],
      [110.3284, -7.7699],
      [110.3282, -7.7693],
      [110.3281, -7.769],
      [110.328, -7.7688],
      [110.3281, -7.7687],
      [110.3282, -7.7686],
      [110.3286, -7.7684],
      [110.3287, -7.7683],
      [110.3292, -7.7681],
      [110.3293, -7.7681],
      [110.3293, -7.7675],
      [110.3293, -7.7663],
      [110.3294, -7.7655],
      [110.3291, -7.7648],
      [110.329, -7.7645]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'GODEAN',
    village: 'SIDOMOYO',
    border: [
      [110.3247, -7.7636],
      [110.3245, -7.7631],
      [110.3245, -7.7629],
      [110.324, -7.7624],
      [110.3237, -7.7615],
      [110.3236, -7.7612],
      [110.3234, -7.761],
      [110.3231, -7.7605],
      [110.3228, -7.7598],
      [110.3226, -7.7587],
      [110.3223, -7.7581],
      [110.3225, -7.7578],
      [110.3223, -7.7571],
      [110.3223, -7.7567],
      [110.3225, -7.7561],
      [110.3224, -7.7559],
      [110.3223, -7.7557],
      [110.3225, -7.7555],
      [110.3228, -7.7552],
      [110.3229, -7.7547],
      [110.3232, -7.7543],
      [110.3237, -7.7538],
      [110.3242, -7.7531],
      [110.3245, -7.7524],
      [110.3247, -7.7521],
      [110.3247, -7.7517],
      [110.3244, -7.7515],
      [110.3241, -7.7513],
      [110.3242, -7.751],
      [110.3245, -7.7507],
      [110.3247, -7.7501],
      [110.3246, -7.7498],
      [110.3244, -7.7494],
      [110.3245, -7.7491],
      [110.3248, -7.7487],
      [110.3248, -7.7486],
      [110.3246, -7.748],
      [110.3246, -7.7477],
      [110.3246, -7.7473],
      [110.3245, -7.7468],
      [110.3242, -7.7467],
      [110.3239, -7.7467],
      [110.3235, -7.7469],
      [110.3233, -7.7471],
      [110.3229, -7.747],
      [110.323, -7.7464],
      [110.3233, -7.7453],
      [110.3235, -7.7443],
      [110.3236, -7.744],
      [110.3237, -7.7438],
      [110.3237, -7.7435],
      [110.3235, -7.7435],
      [110.3234, -7.7441],
      [110.3232, -7.7441],
      [110.3228, -7.7449],
      [110.3225, -7.745],
      [110.3221, -7.7466],
      [110.3211, -7.7464],
      [110.3208, -7.7469],
      [110.3205, -7.7469],
      [110.3204, -7.7469],
      [110.3198, -7.7467],
      [110.3195, -7.7465],
      [110.3188, -7.7465],
      [110.3184, -7.7465],
      [110.3182, -7.7465],
      [110.3183, -7.747],
      [110.3183, -7.7473],
      [110.3177, -7.7473],
      [110.3166, -7.7468],
      [110.3159, -7.7463],
      [110.3157, -7.7459],
      [110.3155, -7.7455],
      [110.3153, -7.7452],
      [110.3151, -7.7456],
      [110.3149, -7.7459],
      [110.3147, -7.7462],
      [110.3149, -7.7464],
      [110.3153, -7.7468],
      [110.3153, -7.7473],
      [110.3151, -7.7477],
      [110.3153, -7.7483],
      [110.3154, -7.7488],
      [110.3152, -7.7494],
      [110.3152, -7.7497],
      [110.3153, -7.7504],
      [110.3155, -7.7509],
      [110.3157, -7.7513],
      [110.3156, -7.7515],
      [110.3154, -7.7517],
      [110.3149, -7.7519],
      [110.3141, -7.7518],
      [110.3137, -7.7518],
      [110.3135, -7.7519],
      [110.3134, -7.7521],
      [110.3136, -7.7526],
      [110.314, -7.753],
      [110.3141, -7.7537],
      [110.3139, -7.7543],
      [110.3133, -7.7555],
      [110.313, -7.7561],
      [110.313, -7.7564],
      [110.313, -7.7572],
      [110.3129, -7.7576],
      [110.3126, -7.7579],
      [110.3122, -7.7581],
      [110.3123, -7.7585],
      [110.3126, -7.7589],
      [110.3129, -7.759],
      [110.3134, -7.7592],
      [110.3137, -7.7594],
      [110.3137, -7.7599],
      [110.3135, -7.7603],
      [110.3131, -7.7609],
      [110.3129, -7.7612],
      [110.3128, -7.7614],
      [110.3128, -7.7616],
      [110.3132, -7.7624],
      [110.3135, -7.763],
      [110.3134, -7.7633],
      [110.3132, -7.7638],
      [110.3133, -7.7641],
      [110.3134, -7.7643],
      [110.3138, -7.7645],
      [110.314, -7.765],
      [110.3142, -7.7659],
      [110.3142, -7.7662],
      [110.3143, -7.7668],
      [110.3145, -7.7674],
      [110.3145, -7.7675],
      [110.3138, -7.7686],
      [110.3136, -7.7692],
      [110.3132, -7.7698],
      [110.3132, -7.7699],
      [110.3135, -7.7703],
      [110.3137, -7.771],
      [110.3138, -7.7711],
      [110.314, -7.7716],
      [110.3143, -7.7722],
      [110.3143, -7.7724],
      [110.3144, -7.7727],
      [110.3141, -7.7731],
      [110.3137, -7.7734],
      [110.3131, -7.7745],
      [110.3128, -7.775],
      [110.3126, -7.7752],
      [110.3127, -7.7754],
      [110.3128, -7.7759],
      [110.3129, -7.776],
      [110.313, -7.7761],
      [110.3138, -7.7764],
      [110.3141, -7.7762],
      [110.3144, -7.7761],
      [110.3153, -7.7764],
      [110.3162, -7.7766],
      [110.3165, -7.7744],
      [110.3166, -7.7728],
      [110.3176, -7.773],
      [110.3186, -7.7725],
      [110.319, -7.7727],
      [110.3197, -7.7728],
      [110.3202, -7.7728],
      [110.3208, -7.7703],
      [110.3207, -7.77],
      [110.3209, -7.7696],
      [110.3214, -7.7681],
      [110.3216, -7.7674],
      [110.3222, -7.7656],
      [110.3231, -7.766],
      [110.3232, -7.7655],
      [110.3236, -7.7654],
      [110.3238, -7.7649],
      [110.3241, -7.7641],
      [110.3246, -7.7637],
      [110.3247, -7.7636]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MOYUDAN',
    village: 'SUMBERAHAYU',
    border: [
      [110.2584, -7.787],
      [110.258, -7.7868],
      [110.2568, -7.7867],
      [110.256, -7.7866],
      [110.2553, -7.7864],
      [110.2544, -7.7861],
      [110.2532, -7.7856],
      [110.2522, -7.7851],
      [110.252, -7.7849],
      [110.2513, -7.7843],
      [110.2508, -7.7839],
      [110.2505, -7.7838],
      [110.2498, -7.7837],
      [110.2471, -7.7827],
      [110.2468, -7.7826],
      [110.2454, -7.7821],
      [110.2452, -7.7821],
      [110.2435, -7.7815],
      [110.2425, -7.7817],
      [110.2419, -7.7814],
      [110.2414, -7.7809],
      [110.241, -7.7809],
      [110.2404, -7.7806],
      [110.2403, -7.7805],
      [110.2394, -7.7802],
      [110.2387, -7.78],
      [110.2383, -7.7798],
      [110.2377, -7.7797],
      [110.2374, -7.78],
      [110.2372, -7.7801],
      [110.237, -7.7802],
      [110.2369, -7.7803],
      [110.2367, -7.7804],
      [110.2365, -7.7807],
      [110.2366, -7.7815],
      [110.2366, -7.782],
      [110.2365, -7.7826],
      [110.2364, -7.7827],
      [110.2362, -7.7828],
      [110.2362, -7.7832],
      [110.2364, -7.7835],
      [110.2366, -7.7837],
      [110.2364, -7.7842],
      [110.2362, -7.7847],
      [110.2362, -7.7856],
      [110.236, -7.7859],
      [110.2359, -7.786],
      [110.2356, -7.7861],
      [110.2358, -7.7865],
      [110.2359, -7.7868],
      [110.2355, -7.7868],
      [110.2354, -7.7869],
      [110.2353, -7.7871],
      [110.235, -7.7872],
      [110.2348, -7.7872],
      [110.2347, -7.7873],
      [110.2346, -7.7877],
      [110.2347, -7.7881],
      [110.2345, -7.7881],
      [110.2342, -7.7881],
      [110.234, -7.7882],
      [110.2339, -7.7882],
      [110.2337, -7.7885],
      [110.2337, -7.7892],
      [110.2335, -7.7897],
      [110.2332, -7.7901],
      [110.2327, -7.7908],
      [110.233, -7.7911],
      [110.2331, -7.7918],
      [110.2332, -7.7928],
      [110.2334, -7.7936],
      [110.2341, -7.795],
      [110.2345, -7.7959],
      [110.235, -7.7967],
      [110.2353, -7.7971],
      [110.2358, -7.7977],
      [110.236, -7.7983],
      [110.236, -7.7988],
      [110.2357, -7.7996],
      [110.2351, -7.8004],
      [110.2339, -7.8013],
      [110.2333, -7.8017],
      [110.2328, -7.802],
      [110.2322, -7.8022],
      [110.2313, -7.8024],
      [110.2304, -7.8028],
      [110.2299, -7.8031],
      [110.2292, -7.8038],
      [110.2288, -7.8044],
      [110.2285, -7.8051],
      [110.2283, -7.8056],
      [110.2283, -7.8063],
      [110.2285, -7.8067],
      [110.2288, -7.8072],
      [110.2292, -7.8075],
      [110.2296, -7.8078],
      [110.2303, -7.808],
      [110.2307, -7.8081],
      [110.2314, -7.8089],
      [110.2319, -7.8095],
      [110.2323, -7.81],
      [110.2324, -7.8106],
      [110.2326, -7.8113],
      [110.2325, -7.8125],
      [110.2325, -7.8136],
      [110.2326, -7.814],
      [110.2331, -7.8147],
      [110.2332, -7.8149],
      [110.2335, -7.8147],
      [110.234, -7.8143],
      [110.2341, -7.8139],
      [110.2341, -7.8134],
      [110.2343, -7.813],
      [110.2344, -7.8128],
      [110.2345, -7.8126],
      [110.2348, -7.8124],
      [110.2349, -7.8122],
      [110.2351, -7.8119],
      [110.2352, -7.8116],
      [110.2356, -7.8114],
      [110.2359, -7.8112],
      [110.2359, -7.8109],
      [110.2363, -7.8105],
      [110.2366, -7.8101],
      [110.2368, -7.8097],
      [110.2368, -7.8094],
      [110.2369, -7.8092],
      [110.2371, -7.809],
      [110.2373, -7.809],
      [110.2375, -7.8089],
      [110.2378, -7.8087],
      [110.2379, -7.8085],
      [110.2381, -7.8085],
      [110.2384, -7.8085],
      [110.2386, -7.8084],
      [110.2389, -7.8081],
      [110.2392, -7.8078],
      [110.2395, -7.8074],
      [110.2395, -7.8072],
      [110.2396, -7.8069],
      [110.2399, -7.8068],
      [110.24, -7.8065],
      [110.2402, -7.8063],
      [110.2406, -7.8062],
      [110.2407, -7.8061],
      [110.2416, -7.8064],
      [110.2427, -7.8054],
      [110.2438, -7.8048],
      [110.2448, -7.8044],
      [110.2457, -7.8041],
      [110.2462, -7.8039],
      [110.247, -7.8038],
      [110.2481, -7.8037],
      [110.2496, -7.8036],
      [110.2513, -7.8035],
      [110.2515, -7.8033],
      [110.2519, -7.803],
      [110.2525, -7.8026],
      [110.2529, -7.8023],
      [110.2532, -7.8017],
      [110.2536, -7.8014],
      [110.2537, -7.8014],
      [110.254, -7.8012],
      [110.2544, -7.801],
      [110.2548, -7.8007],
      [110.255, -7.8004],
      [110.2557, -7.8001],
      [110.2558, -7.8001],
      [110.2561, -7.8],
      [110.2564, -7.8001],
      [110.2568, -7.8002],
      [110.2569, -7.8003],
      [110.2572, -7.7999],
      [110.2576, -7.7997],
      [110.2581, -7.7994],
      [110.2587, -7.7991],
      [110.259, -7.7989],
      [110.2592, -7.7985],
      [110.2594, -7.7979],
      [110.2596, -7.7976],
      [110.2594, -7.7969],
      [110.2592, -7.7964],
      [110.2588, -7.7959],
      [110.2585, -7.7957],
      [110.2576, -7.7957],
      [110.2582, -7.7921],
      [110.2565, -7.7917],
      [110.2568, -7.7907],
      [110.258, -7.7909],
      [110.258, -7.7901],
      [110.2586, -7.7899],
      [110.2593, -7.7898],
      [110.2594, -7.7898],
      [110.2593, -7.7895],
      [110.2589, -7.7891],
      [110.2588, -7.7889],
      [110.2587, -7.7887],
      [110.2586, -7.7886],
      [110.2586, -7.7882],
      [110.2587, -7.7874],
      [110.2584, -7.787]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MOYUDAN',
    village: 'SUMBERSARI',
    border: [
      [110.27, -7.7677],
      [110.2698, -7.7677],
      [110.2695, -7.7682],
      [110.2692, -7.7685],
      [110.2689, -7.77],
      [110.2685, -7.7714],
      [110.2685, -7.7719],
      [110.2683, -7.7719],
      [110.2682, -7.7719],
      [110.2672, -7.7718],
      [110.2667, -7.7717],
      [110.2662, -7.7716],
      [110.2661, -7.772],
      [110.2661, -7.7728],
      [110.2662, -7.7738],
      [110.2666, -7.7738],
      [110.2664, -7.7747],
      [110.2664, -7.7752],
      [110.2665, -7.7756],
      [110.2667, -7.7763],
      [110.266, -7.7763],
      [110.2656, -7.7764],
      [110.2655, -7.7753],
      [110.2654, -7.7736],
      [110.2652, -7.7727],
      [110.2646, -7.7727],
      [110.2645, -7.7726],
      [110.2643, -7.7718],
      [110.2641, -7.7712],
      [110.264, -7.7709],
      [110.264, -7.7704],
      [110.2641, -7.7699],
      [110.2639, -7.7696],
      [110.264, -7.7693],
      [110.264, -7.769],
      [110.264, -7.7688],
      [110.2638, -7.7687],
      [110.2631, -7.7686],
      [110.263, -7.7686],
      [110.2624, -7.7685],
      [110.2616, -7.7683],
      [110.2615, -7.7683],
      [110.2602, -7.768],
      [110.2595, -7.768],
      [110.2593, -7.7688],
      [110.2588, -7.7688],
      [110.2588, -7.7703],
      [110.2586, -7.771],
      [110.2586, -7.7713],
      [110.2587, -7.7715],
      [110.2591, -7.7718],
      [110.2592, -7.7719],
      [110.2592, -7.7721],
      [110.2588, -7.7723],
      [110.2586, -7.7724],
      [110.2586, -7.7726],
      [110.259, -7.7732],
      [110.2591, -7.7732],
      [110.2587, -7.7737],
      [110.259, -7.7746],
      [110.259, -7.7756],
      [110.2591, -7.7763],
      [110.2588, -7.777],
      [110.2589, -7.7772],
      [110.259, -7.7773],
      [110.2593, -7.7778],
      [110.2593, -7.7782],
      [110.2593, -7.7787],
      [110.2592, -7.779],
      [110.2592, -7.7793],
      [110.2589, -7.7795],
      [110.2589, -7.7797],
      [110.259, -7.7798],
      [110.2591, -7.78],
      [110.2592, -7.7802],
      [110.259, -7.7805],
      [110.2587, -7.7808],
      [110.2588, -7.7809],
      [110.2589, -7.7811],
      [110.259, -7.7813],
      [110.2588, -7.7814],
      [110.2585, -7.7815],
      [110.2584, -7.7816],
      [110.2584, -7.7819],
      [110.2585, -7.7821],
      [110.2589, -7.7826],
      [110.259, -7.7829],
      [110.2588, -7.7831],
      [110.2586, -7.7833],
      [110.2585, -7.7834],
      [110.2588, -7.7837],
      [110.2588, -7.7838],
      [110.2589, -7.7839],
      [110.2589, -7.7843],
      [110.2586, -7.7845],
      [110.2586, -7.7849],
      [110.2587, -7.7854],
      [110.2586, -7.786],
      [110.2583, -7.7866],
      [110.2584, -7.787],
      [110.2587, -7.7874],
      [110.2586, -7.7882],
      [110.2586, -7.7886],
      [110.2587, -7.7887],
      [110.2588, -7.7889],
      [110.2589, -7.7891],
      [110.2593, -7.7895],
      [110.2594, -7.7898],
      [110.2593, -7.7898],
      [110.2586, -7.7899],
      [110.258, -7.7901],
      [110.258, -7.7909],
      [110.2568, -7.7907],
      [110.2565, -7.7917],
      [110.2582, -7.7921],
      [110.2576, -7.7957],
      [110.2585, -7.7957],
      [110.2588, -7.7959],
      [110.2592, -7.7964],
      [110.2594, -7.7969],
      [110.2596, -7.7976],
      [110.2599, -7.7975],
      [110.2605, -7.7975],
      [110.2606, -7.7972],
      [110.2607, -7.7969],
      [110.261, -7.7968],
      [110.2613, -7.7967],
      [110.2618, -7.7965],
      [110.2624, -7.7962],
      [110.2628, -7.7958],
      [110.2632, -7.7955],
      [110.2633, -7.7953],
      [110.2633, -7.7948],
      [110.2635, -7.7945],
      [110.2636, -7.7942],
      [110.264, -7.7941],
      [110.2642, -7.7941],
      [110.2644, -7.7942],
      [110.2645, -7.7942],
      [110.2653, -7.7946],
      [110.2667, -7.7953],
      [110.2673, -7.7956],
      [110.2678, -7.7958],
      [110.268, -7.7957],
      [110.2683, -7.7955],
      [110.2684, -7.7953],
      [110.2684, -7.795],
      [110.2684, -7.7948],
      [110.2686, -7.7946],
      [110.2687, -7.7943],
      [110.2689, -7.7941],
      [110.2692, -7.794],
      [110.2697, -7.7939],
      [110.27, -7.794],
      [110.2702, -7.7938],
      [110.2702, -7.7936],
      [110.2703, -7.7934],
      [110.2705, -7.7932],
      [110.2709, -7.7933],
      [110.271, -7.7933],
      [110.2713, -7.7931],
      [110.2715, -7.7932],
      [110.2717, -7.7933],
      [110.2718, -7.7935],
      [110.2733, -7.7937],
      [110.2735, -7.7931],
      [110.2744, -7.7914],
      [110.2757, -7.7918],
      [110.2758, -7.7916],
      [110.2765, -7.7903],
      [110.2767, -7.7894],
      [110.2768, -7.7892],
      [110.2771, -7.7887],
      [110.2771, -7.7883],
      [110.2773, -7.788],
      [110.2778, -7.7874],
      [110.2786, -7.7863],
      [110.2789, -7.7861],
      [110.2794, -7.7861],
      [110.2798, -7.7863],
      [110.2802, -7.7864],
      [110.2806, -7.7865],
      [110.2817, -7.7867],
      [110.2831, -7.7869],
      [110.2836, -7.7866],
      [110.284, -7.7864],
      [110.2842, -7.786],
      [110.2848, -7.7859],
      [110.2851, -7.7859],
      [110.2856, -7.7858],
      [110.2857, -7.7853],
      [110.2858, -7.785],
      [110.286, -7.7847],
      [110.2862, -7.7847],
      [110.2864, -7.7842],
      [110.2869, -7.7834],
      [110.2875, -7.7829],
      [110.2878, -7.7824],
      [110.2882, -7.7822],
      [110.2877, -7.7816],
      [110.2874, -7.7818],
      [110.2872, -7.7816],
      [110.2869, -7.7814],
      [110.2863, -7.7809],
      [110.2857, -7.7806],
      [110.2853, -7.7805],
      [110.2852, -7.7806],
      [110.2846, -7.7803],
      [110.2838, -7.7813],
      [110.2837, -7.7815],
      [110.2832, -7.7814],
      [110.2827, -7.7813],
      [110.2822, -7.7816],
      [110.2819, -7.7821],
      [110.2813, -7.782],
      [110.2803, -7.7817],
      [110.2768, -7.7806],
      [110.2747, -7.7799],
      [110.2739, -7.7796],
      [110.2745, -7.7782],
      [110.2738, -7.7779],
      [110.274, -7.7774],
      [110.2732, -7.7769],
      [110.2721, -7.7764],
      [110.2721, -7.7761],
      [110.272, -7.7751],
      [110.2711, -7.7751],
      [110.2709, -7.7751],
      [110.2709, -7.7747],
      [110.2709, -7.7742],
      [110.2713, -7.7731],
      [110.2717, -7.7721],
      [110.2711, -7.7719],
      [110.271, -7.772],
      [110.2706, -7.7721],
      [110.2702, -7.7724],
      [110.2701, -7.772],
      [110.2704, -7.7718],
      [110.2711, -7.7712],
      [110.2712, -7.7703],
      [110.2714, -7.7691],
      [110.2715, -7.7688],
      [110.2718, -7.7682],
      [110.2715, -7.768],
      [110.2706, -7.7678],
      [110.27, -7.7677]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MOYUDAN',
    village: 'SUMBERAGUNG',
    border: [
      [110.2646, -7.7409],
      [110.2645, -7.7409],
      [110.2632, -7.7404],
      [110.2635, -7.7392],
      [110.2631, -7.7392],
      [110.262, -7.7432],
      [110.2618, -7.7439],
      [110.2617, -7.7441],
      [110.2615, -7.7446],
      [110.2611, -7.746],
      [110.2605, -7.7485],
      [110.26, -7.7498],
      [110.2594, -7.7525],
      [110.2579, -7.7573],
      [110.2573, -7.7597],
      [110.2562, -7.7595],
      [110.2554, -7.7592],
      [110.2533, -7.7587],
      [110.2527, -7.7586],
      [110.2505, -7.758],
      [110.2501, -7.7578],
      [110.2491, -7.7576],
      [110.2478, -7.7574],
      [110.2472, -7.7572],
      [110.2464, -7.7571],
      [110.2459, -7.757],
      [110.2452, -7.7572],
      [110.2442, -7.7569],
      [110.2433, -7.7567],
      [110.2423, -7.7565],
      [110.2417, -7.757],
      [110.2416, -7.7572],
      [110.2414, -7.7577],
      [110.2412, -7.7582],
      [110.2412, -7.7588],
      [110.241, -7.7589],
      [110.2409, -7.759],
      [110.241, -7.7595],
      [110.2407, -7.7601],
      [110.2407, -7.7604],
      [110.2411, -7.7603],
      [110.2415, -7.7602],
      [110.2422, -7.7604],
      [110.2426, -7.7603],
      [110.2433, -7.7602],
      [110.2435, -7.7603],
      [110.2429, -7.762],
      [110.2435, -7.7624],
      [110.2429, -7.7631],
      [110.2419, -7.7628],
      [110.2414, -7.7632],
      [110.2416, -7.7637],
      [110.242, -7.7639],
      [110.2413, -7.7658],
      [110.2442, -7.767],
      [110.2443, -7.7673],
      [110.2444, -7.7676],
      [110.2436, -7.7692],
      [110.2426, -7.7712],
      [110.2422, -7.7712],
      [110.2419, -7.7726],
      [110.2415, -7.7725],
      [110.2412, -7.7732],
      [110.2407, -7.7743],
      [110.2403, -7.774],
      [110.2398, -7.7737],
      [110.2397, -7.7737],
      [110.2393, -7.7741],
      [110.2392, -7.7744],
      [110.2389, -7.7747],
      [110.2386, -7.775],
      [110.2387, -7.7757],
      [110.2386, -7.776],
      [110.2385, -7.7762],
      [110.2387, -7.7765],
      [110.2387, -7.777],
      [110.2384, -7.7779],
      [110.2383, -7.7784],
      [110.2383, -7.7785],
      [110.2382, -7.7787],
      [110.238, -7.7789],
      [110.2376, -7.7792],
      [110.2377, -7.7797],
      [110.2383, -7.7798],
      [110.2387, -7.78],
      [110.2394, -7.7802],
      [110.2403, -7.7805],
      [110.2404, -7.7806],
      [110.241, -7.7809],
      [110.2414, -7.7809],
      [110.2419, -7.7814],
      [110.2425, -7.7817],
      [110.2435, -7.7815],
      [110.2452, -7.7821],
      [110.2454, -7.7821],
      [110.2468, -7.7826],
      [110.2471, -7.7827],
      [110.2498, -7.7837],
      [110.2505, -7.7838],
      [110.2508, -7.7839],
      [110.2513, -7.7843],
      [110.252, -7.7849],
      [110.2522, -7.7851],
      [110.2532, -7.7856],
      [110.2544, -7.7861],
      [110.2553, -7.7864],
      [110.256, -7.7866],
      [110.2568, -7.7867],
      [110.258, -7.7868],
      [110.2584, -7.787],
      [110.2583, -7.7866],
      [110.2586, -7.786],
      [110.2587, -7.7854],
      [110.2586, -7.7849],
      [110.2586, -7.7845],
      [110.2589, -7.7843],
      [110.2589, -7.7839],
      [110.2588, -7.7838],
      [110.2588, -7.7837],
      [110.2585, -7.7834],
      [110.2586, -7.7833],
      [110.2588, -7.7831],
      [110.259, -7.7829],
      [110.2589, -7.7826],
      [110.2585, -7.7821],
      [110.2584, -7.7819],
      [110.2584, -7.7816],
      [110.2585, -7.7815],
      [110.2588, -7.7814],
      [110.259, -7.7813],
      [110.2589, -7.7811],
      [110.2588, -7.7809],
      [110.2587, -7.7808],
      [110.259, -7.7805],
      [110.2592, -7.7802],
      [110.2591, -7.78],
      [110.259, -7.7798],
      [110.2589, -7.7797],
      [110.2589, -7.7795],
      [110.2592, -7.7793],
      [110.2592, -7.779],
      [110.2593, -7.7787],
      [110.2593, -7.7782],
      [110.2593, -7.7778],
      [110.259, -7.7773],
      [110.2589, -7.7772],
      [110.2588, -7.777],
      [110.2591, -7.7763],
      [110.259, -7.7756],
      [110.259, -7.7746],
      [110.2587, -7.7737],
      [110.2591, -7.7732],
      [110.259, -7.7732],
      [110.2586, -7.7726],
      [110.2586, -7.7724],
      [110.2588, -7.7723],
      [110.2592, -7.7721],
      [110.2592, -7.7719],
      [110.2591, -7.7718],
      [110.2587, -7.7715],
      [110.2586, -7.7713],
      [110.2586, -7.771],
      [110.2588, -7.7703],
      [110.2588, -7.7688],
      [110.2593, -7.7688],
      [110.2595, -7.768],
      [110.2602, -7.768],
      [110.2615, -7.7683],
      [110.2616, -7.7683],
      [110.2624, -7.7685],
      [110.263, -7.7686],
      [110.2631, -7.7686],
      [110.2638, -7.7687],
      [110.264, -7.7688],
      [110.264, -7.7686],
      [110.2638, -7.7686],
      [110.263, -7.7684],
      [110.2628, -7.7684],
      [110.2629, -7.7673],
      [110.263, -7.7668],
      [110.2636, -7.7652],
      [110.2637, -7.7648],
      [110.2639, -7.7643],
      [110.2644, -7.7627],
      [110.2646, -7.7621],
      [110.265, -7.761],
      [110.2636, -7.7607],
      [110.2642, -7.7594],
      [110.2647, -7.7585],
      [110.2649, -7.7581],
      [110.2652, -7.7576],
      [110.2651, -7.7575],
      [110.2647, -7.7574],
      [110.2643, -7.7572],
      [110.2648, -7.7559],
      [110.2655, -7.7536],
      [110.2659, -7.7537],
      [110.266, -7.7541],
      [110.2665, -7.7542],
      [110.2667, -7.7543],
      [110.2668, -7.754],
      [110.2673, -7.7532],
      [110.2677, -7.7524],
      [110.2682, -7.7516],
      [110.2683, -7.7512],
      [110.2687, -7.7503],
      [110.2688, -7.7499],
      [110.2692, -7.7491],
      [110.2695, -7.7486],
      [110.2698, -7.748],
      [110.27, -7.7475],
      [110.2705, -7.7467],
      [110.2706, -7.7464],
      [110.2709, -7.746],
      [110.2711, -7.7455],
      [110.2696, -7.745],
      [110.2693, -7.7448],
      [110.2688, -7.7446],
      [110.2683, -7.7446],
      [110.2676, -7.7449],
      [110.2673, -7.7453],
      [110.2669, -7.7457],
      [110.2666, -7.746],
      [110.2666, -7.7465],
      [110.2658, -7.7459],
      [110.2653, -7.7453],
      [110.2649, -7.7447],
      [110.2647, -7.7444],
      [110.2648, -7.7441],
      [110.2649, -7.7439],
      [110.2653, -7.7438],
      [110.2656, -7.7436],
      [110.2656, -7.7431],
      [110.2655, -7.743],
      [110.2652, -7.743],
      [110.2647, -7.743],
      [110.2645, -7.7426],
      [110.2643, -7.7421],
      [110.2644, -7.7416],
      [110.2645, -7.7411],
      [110.2646, -7.7409]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MOYUDAN',
    village: 'SUMBERARUM',
    border: [
      [110.2423, -7.7565],
      [110.2422, -7.7565],
      [110.2416, -7.7563],
      [110.2409, -7.7561],
      [110.2396, -7.7558],
      [110.2388, -7.7556],
      [110.2371, -7.7553],
      [110.2368, -7.7552],
      [110.2359, -7.755],
      [110.2353, -7.7548],
      [110.2344, -7.7545],
      [110.2334, -7.7543],
      [110.2318, -7.754],
      [110.2297, -7.7535],
      [110.2289, -7.7532],
      [110.2276, -7.7528],
      [110.2269, -7.7525],
      [110.2265, -7.7525],
      [110.225, -7.7529],
      [110.2245, -7.753],
      [110.2235, -7.7532],
      [110.2231, -7.7533],
      [110.2214, -7.7533],
      [110.2206, -7.7535],
      [110.2202, -7.7536],
      [110.2201, -7.7537],
      [110.2195, -7.7538],
      [110.22, -7.7543],
      [110.2202, -7.7547],
      [110.2202, -7.7549],
      [110.2195, -7.7559],
      [110.2191, -7.7569],
      [110.2189, -7.7571],
      [110.2186, -7.7576],
      [110.2185, -7.7581],
      [110.2187, -7.7586],
      [110.2191, -7.7587],
      [110.2197, -7.7587],
      [110.2206, -7.7591],
      [110.221, -7.7593],
      [110.2216, -7.7596],
      [110.222, -7.7599],
      [110.2219, -7.7608],
      [110.2218, -7.7615],
      [110.2214, -7.7619],
      [110.2209, -7.7622],
      [110.2203, -7.7626],
      [110.2197, -7.7629],
      [110.2195, -7.763],
      [110.2195, -7.7635],
      [110.2197, -7.7641],
      [110.2202, -7.7646],
      [110.2205, -7.7649],
      [110.221, -7.7649],
      [110.2212, -7.7648],
      [110.2215, -7.7647],
      [110.2218, -7.7648],
      [110.2219, -7.765],
      [110.2221, -7.7653],
      [110.2222, -7.7663],
      [110.2224, -7.7672],
      [110.2224, -7.7678],
      [110.2224, -7.7683],
      [110.2221, -7.7691],
      [110.2218, -7.77],
      [110.2217, -7.7706],
      [110.2217, -7.7716],
      [110.2218, -7.7723],
      [110.2218, -7.7728],
      [110.2218, -7.7732],
      [110.2214, -7.7736],
      [110.2207, -7.774],
      [110.2204, -7.7744],
      [110.22, -7.7748],
      [110.2198, -7.7751],
      [110.2199, -7.7759],
      [110.22, -7.7764],
      [110.2202, -7.7769],
      [110.2205, -7.7774],
      [110.2209, -7.7779],
      [110.2215, -7.7784],
      [110.222, -7.7788],
      [110.2225, -7.7791],
      [110.2229, -7.7793],
      [110.2233, -7.7795],
      [110.224, -7.7809],
      [110.2248, -7.7822],
      [110.2252, -7.7828],
      [110.2261, -7.7834],
      [110.2273, -7.7842],
      [110.228, -7.7851],
      [110.2284, -7.786],
      [110.2286, -7.7866],
      [110.2287, -7.7872],
      [110.2287, -7.7882],
      [110.2288, -7.7885],
      [110.229, -7.7888],
      [110.2294, -7.7892],
      [110.23, -7.7895],
      [110.2309, -7.7897],
      [110.2316, -7.7898],
      [110.2321, -7.79],
      [110.2325, -7.7904],
      [110.2327, -7.7908],
      [110.2332, -7.7901],
      [110.2335, -7.7897],
      [110.2337, -7.7892],
      [110.2337, -7.7885],
      [110.2339, -7.7882],
      [110.234, -7.7882],
      [110.2342, -7.7881],
      [110.2345, -7.7881],
      [110.2347, -7.7881],
      [110.2346, -7.7877],
      [110.2347, -7.7873],
      [110.2348, -7.7872],
      [110.235, -7.7872],
      [110.2353, -7.7871],
      [110.2354, -7.7869],
      [110.2355, -7.7868],
      [110.2359, -7.7868],
      [110.2358, -7.7865],
      [110.2356, -7.7861],
      [110.2359, -7.786],
      [110.236, -7.7859],
      [110.2362, -7.7856],
      [110.2362, -7.7847],
      [110.2364, -7.7842],
      [110.2366, -7.7837],
      [110.2364, -7.7835],
      [110.2362, -7.7832],
      [110.2362, -7.7828],
      [110.2364, -7.7827],
      [110.2365, -7.7826],
      [110.2366, -7.782],
      [110.2366, -7.7815],
      [110.2365, -7.7807],
      [110.2367, -7.7804],
      [110.2369, -7.7803],
      [110.237, -7.7802],
      [110.2372, -7.7801],
      [110.2374, -7.78],
      [110.2377, -7.7797],
      [110.2376, -7.7792],
      [110.238, -7.7789],
      [110.2382, -7.7787],
      [110.2383, -7.7785],
      [110.2383, -7.7784],
      [110.2384, -7.7779],
      [110.2387, -7.777],
      [110.2387, -7.7765],
      [110.2385, -7.7762],
      [110.2386, -7.776],
      [110.2387, -7.7757],
      [110.2386, -7.775],
      [110.2389, -7.7747],
      [110.2392, -7.7744],
      [110.2393, -7.7741],
      [110.2397, -7.7737],
      [110.2398, -7.7737],
      [110.2403, -7.774],
      [110.2407, -7.7743],
      [110.2412, -7.7732],
      [110.2415, -7.7725],
      [110.2419, -7.7726],
      [110.2422, -7.7712],
      [110.2426, -7.7712],
      [110.2436, -7.7692],
      [110.2444, -7.7676],
      [110.2443, -7.7673],
      [110.2442, -7.767],
      [110.2413, -7.7658],
      [110.242, -7.7639],
      [110.2416, -7.7637],
      [110.2414, -7.7632],
      [110.2419, -7.7628],
      [110.2429, -7.7631],
      [110.2435, -7.7624],
      [110.2429, -7.762],
      [110.2435, -7.7603],
      [110.2433, -7.7602],
      [110.2426, -7.7603],
      [110.2422, -7.7604],
      [110.2415, -7.7602],
      [110.2411, -7.7603],
      [110.2407, -7.7604],
      [110.2407, -7.7601],
      [110.241, -7.7595],
      [110.2409, -7.759],
      [110.241, -7.7589],
      [110.2412, -7.7588],
      [110.2412, -7.7582],
      [110.2414, -7.7577],
      [110.2416, -7.7572],
      [110.2417, -7.757],
      [110.2423, -7.7565]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MINGGIR',
    village: 'SENDANGARUM',
    border: [
      [110.2577, -7.735],
      [110.2574, -7.7349],
      [110.2555, -7.734],
      [110.2552, -7.7347],
      [110.2541, -7.7341],
      [110.2543, -7.7336],
      [110.2548, -7.7338],
      [110.255, -7.7331],
      [110.2549, -7.7323],
      [110.2551, -7.7317],
      [110.2548, -7.7313],
      [110.2544, -7.7312],
      [110.254, -7.7313],
      [110.2538, -7.7316],
      [110.2538, -7.732],
      [110.2538, -7.7323],
      [110.2536, -7.7324],
      [110.2533, -7.7324],
      [110.2532, -7.7327],
      [110.2532, -7.7331],
      [110.253, -7.7332],
      [110.2528, -7.733],
      [110.2525, -7.7337],
      [110.2522, -7.734],
      [110.2529, -7.7344],
      [110.2526, -7.7345],
      [110.2523, -7.7346],
      [110.2518, -7.7346],
      [110.2513, -7.7351],
      [110.2511, -7.7354],
      [110.2511, -7.736],
      [110.2509, -7.7363],
      [110.2507, -7.7364],
      [110.2504, -7.7365],
      [110.2503, -7.7369],
      [110.2503, -7.7372],
      [110.2506, -7.7378],
      [110.2508, -7.7382],
      [110.2509, -7.7386],
      [110.2506, -7.7389],
      [110.25, -7.7389],
      [110.2497, -7.7393],
      [110.2496, -7.7397],
      [110.2492, -7.74],
      [110.2491, -7.7402],
      [110.2494, -7.7404],
      [110.2495, -7.7407],
      [110.2494, -7.741],
      [110.2488, -7.7408],
      [110.2486, -7.741],
      [110.2487, -7.7414],
      [110.2487, -7.7416],
      [110.2486, -7.7418],
      [110.2483, -7.7419],
      [110.2485, -7.7423],
      [110.2485, -7.7425],
      [110.2483, -7.7428],
      [110.2479, -7.7428],
      [110.2481, -7.7432],
      [110.2482, -7.7434],
      [110.248, -7.7434],
      [110.2475, -7.7434],
      [110.2477, -7.7437],
      [110.2477, -7.7441],
      [110.2477, -7.7444],
      [110.2477, -7.7449],
      [110.2476, -7.7451],
      [110.2474, -7.7451],
      [110.2471, -7.7451],
      [110.2472, -7.7455],
      [110.2475, -7.7457],
      [110.2475, -7.7459],
      [110.2473, -7.7462],
      [110.2471, -7.7464],
      [110.2475, -7.7466],
      [110.2475, -7.7468],
      [110.2474, -7.747],
      [110.247, -7.7472],
      [110.2471, -7.7475],
      [110.2471, -7.7477],
      [110.2468, -7.748],
      [110.2467, -7.7481],
      [110.2468, -7.7483],
      [110.2471, -7.7485],
      [110.2472, -7.7488],
      [110.2472, -7.7491],
      [110.2468, -7.7493],
      [110.2465, -7.7494],
      [110.2466, -7.7496],
      [110.2467, -7.75],
      [110.2467, -7.7503],
      [110.2466, -7.7504],
      [110.2467, -7.7506],
      [110.2467, -7.7508],
      [110.2468, -7.751],
      [110.2465, -7.7512],
      [110.2465, -7.7513],
      [110.2463, -7.7516],
      [110.2462, -7.7517],
      [110.2459, -7.7517],
      [110.2457, -7.7522],
      [110.2456, -7.7525],
      [110.2454, -7.7528],
      [110.2454, -7.7531],
      [110.2454, -7.7535],
      [110.2456, -7.7538],
      [110.2452, -7.7539],
      [110.2451, -7.7541],
      [110.2449, -7.7544],
      [110.2448, -7.7545],
      [110.2446, -7.7546],
      [110.2446, -7.7548],
      [110.2446, -7.755],
      [110.2446, -7.7551],
      [110.2443, -7.7554],
      [110.2442, -7.7558],
      [110.2443, -7.7564],
      [110.2442, -7.7566],
      [110.2442, -7.7569],
      [110.2452, -7.7572],
      [110.2459, -7.757],
      [110.2464, -7.7571],
      [110.2472, -7.7572],
      [110.2478, -7.7574],
      [110.2491, -7.7576],
      [110.2501, -7.7578],
      [110.2505, -7.758],
      [110.2527, -7.7586],
      [110.2533, -7.7587],
      [110.2554, -7.7592],
      [110.2562, -7.7595],
      [110.2573, -7.7597],
      [110.2579, -7.7573],
      [110.2594, -7.7525],
      [110.26, -7.7498],
      [110.2605, -7.7485],
      [110.2611, -7.746],
      [110.2615, -7.7446],
      [110.2599, -7.744],
      [110.2594, -7.7459],
      [110.2584, -7.7453],
      [110.2585, -7.745],
      [110.2589, -7.7443],
      [110.259, -7.7438],
      [110.2594, -7.7432],
      [110.2593, -7.7428],
      [110.2593, -7.7425],
      [110.2593, -7.7419],
      [110.2596, -7.7417],
      [110.2596, -7.7408],
      [110.2595, -7.7407],
      [110.2595, -7.7403],
      [110.2598, -7.7396],
      [110.2601, -7.7394],
      [110.2606, -7.7392],
      [110.2607, -7.739],
      [110.2606, -7.7388],
      [110.2603, -7.7388],
      [110.2602, -7.7387],
      [110.2601, -7.739],
      [110.2598, -7.739],
      [110.2595, -7.7393],
      [110.259, -7.7393],
      [110.2587, -7.739],
      [110.2586, -7.7386],
      [110.2588, -7.7377],
      [110.259, -7.7375],
      [110.2589, -7.7371],
      [110.2589, -7.7368],
      [110.2591, -7.7365],
      [110.2589, -7.7364],
      [110.2587, -7.7362],
      [110.2585, -7.7358],
      [110.2583, -7.7359],
      [110.2582, -7.736],
      [110.258, -7.7363],
      [110.2578, -7.7363],
      [110.2577, -7.7361],
      [110.2578, -7.7357],
      [110.2575, -7.7355],
      [110.2577, -7.735]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MINGGIR',
    village: 'SENDANGMULYO',
    border: [
      [110.2503, -7.7372],
      [110.25, -7.7377],
      [110.2497, -7.7375],
      [110.249, -7.7371],
      [110.25, -7.7354],
      [110.25, -7.7348],
      [110.2504, -7.7336],
      [110.25, -7.7334],
      [110.2499, -7.7333],
      [110.2494, -7.7343],
      [110.2493, -7.7345],
      [110.2491, -7.7347],
      [110.2485, -7.7349],
      [110.248, -7.7351],
      [110.2478, -7.735],
      [110.2477, -7.7349],
      [110.2475, -7.7347],
      [110.2477, -7.7343],
      [110.2479, -7.734],
      [110.2479, -7.7327],
      [110.2478, -7.7318],
      [110.2488, -7.7302],
      [110.2494, -7.7291],
      [110.2481, -7.7284],
      [110.2475, -7.7292],
      [110.247, -7.7297],
      [110.2468, -7.7301],
      [110.2465, -7.7302],
      [110.2462, -7.73],
      [110.2459, -7.7299],
      [110.2456, -7.7299],
      [110.2448, -7.7313],
      [110.244, -7.7327],
      [110.2426, -7.7316],
      [110.2409, -7.7334],
      [110.2398, -7.7328],
      [110.239, -7.7324],
      [110.2397, -7.7313],
      [110.2387, -7.7305],
      [110.2384, -7.7308],
      [110.2376, -7.7306],
      [110.2367, -7.7304],
      [110.2366, -7.7304],
      [110.2364, -7.731],
      [110.2361, -7.7313],
      [110.2362, -7.7319],
      [110.2359, -7.7319],
      [110.2362, -7.7328],
      [110.236, -7.7332],
      [110.2356, -7.7334],
      [110.2353, -7.7336],
      [110.235, -7.7335],
      [110.235, -7.7334],
      [110.2354, -7.7324],
      [110.2346, -7.7322],
      [110.2349, -7.7312],
      [110.2344, -7.7311],
      [110.2337, -7.7322],
      [110.2335, -7.7322],
      [110.2333, -7.7324],
      [110.233, -7.7323],
      [110.2324, -7.7333],
      [110.2318, -7.7335],
      [110.2315, -7.7341],
      [110.231, -7.735],
      [110.2315, -7.7355],
      [110.231, -7.7383],
      [110.2304, -7.7404],
      [110.229, -7.7391],
      [110.2285, -7.7387],
      [110.2273, -7.7379],
      [110.2262, -7.7394],
      [110.2254, -7.7401],
      [110.2251, -7.7402],
      [110.2249, -7.7403],
      [110.2245, -7.7407],
      [110.2244, -7.7408],
      [110.2243, -7.7408],
      [110.224, -7.7409],
      [110.2239, -7.7413],
      [110.2235, -7.7415],
      [110.2232, -7.7423],
      [110.223, -7.7425],
      [110.2227, -7.7425],
      [110.2226, -7.7425],
      [110.2225, -7.7426],
      [110.2224, -7.7428],
      [110.2221, -7.7429],
      [110.2221, -7.7431],
      [110.2221, -7.7432],
      [110.2218, -7.7433],
      [110.2214, -7.7435],
      [110.2209, -7.7436],
      [110.2207, -7.7436],
      [110.2206, -7.7438],
      [110.2205, -7.744],
      [110.2197, -7.7443],
      [110.2195, -7.7443],
      [110.2188, -7.7443],
      [110.218, -7.7443],
      [110.2176, -7.7443],
      [110.2171, -7.7442],
      [110.2174, -7.7445],
      [110.218, -7.745],
      [110.2186, -7.7457],
      [110.2191, -7.7468],
      [110.2193, -7.7472],
      [110.2195, -7.748],
      [110.2193, -7.7493],
      [110.2191, -7.75],
      [110.2184, -7.7504],
      [110.2178, -7.7513],
      [110.2177, -7.7517],
      [110.2178, -7.7523],
      [110.218, -7.7526],
      [110.2185, -7.753],
      [110.2189, -7.7533],
      [110.2193, -7.7536],
      [110.2195, -7.7538],
      [110.2201, -7.7537],
      [110.2202, -7.7536],
      [110.2206, -7.7535],
      [110.2214, -7.7533],
      [110.2231, -7.7533],
      [110.2235, -7.7532],
      [110.2245, -7.753],
      [110.225, -7.7529],
      [110.2265, -7.7525],
      [110.2269, -7.7525],
      [110.2276, -7.7528],
      [110.2289, -7.7532],
      [110.2297, -7.7535],
      [110.2318, -7.754],
      [110.2334, -7.7543],
      [110.2344, -7.7545],
      [110.2353, -7.7548],
      [110.2359, -7.755],
      [110.2368, -7.7552],
      [110.2371, -7.7553],
      [110.2388, -7.7556],
      [110.2396, -7.7558],
      [110.2409, -7.7561],
      [110.2416, -7.7563],
      [110.2422, -7.7565],
      [110.2423, -7.7565],
      [110.2433, -7.7567],
      [110.2442, -7.7569],
      [110.2442, -7.7566],
      [110.2443, -7.7564],
      [110.2442, -7.7558],
      [110.2443, -7.7554],
      [110.2446, -7.7551],
      [110.2446, -7.755],
      [110.2446, -7.7548],
      [110.2446, -7.7546],
      [110.2448, -7.7545],
      [110.2449, -7.7544],
      [110.2451, -7.7541],
      [110.2452, -7.7539],
      [110.2456, -7.7538],
      [110.2454, -7.7535],
      [110.2454, -7.7531],
      [110.2454, -7.7528],
      [110.2456, -7.7525],
      [110.2457, -7.7522],
      [110.2459, -7.7517],
      [110.2462, -7.7517],
      [110.2463, -7.7516],
      [110.2465, -7.7513],
      [110.2465, -7.7512],
      [110.2468, -7.751],
      [110.2467, -7.7508],
      [110.2467, -7.7506],
      [110.2466, -7.7504],
      [110.2467, -7.7503],
      [110.2467, -7.75],
      [110.2466, -7.7496],
      [110.2465, -7.7494],
      [110.2468, -7.7493],
      [110.2472, -7.7491],
      [110.2472, -7.7488],
      [110.2471, -7.7485],
      [110.2468, -7.7483],
      [110.2467, -7.7481],
      [110.2468, -7.748],
      [110.2471, -7.7477],
      [110.2471, -7.7475],
      [110.247, -7.7472],
      [110.2474, -7.747],
      [110.2475, -7.7468],
      [110.2475, -7.7466],
      [110.2471, -7.7464],
      [110.2473, -7.7462],
      [110.2475, -7.7459],
      [110.2475, -7.7457],
      [110.2472, -7.7455],
      [110.2471, -7.7451],
      [110.2474, -7.7451],
      [110.2476, -7.7451],
      [110.2477, -7.7449],
      [110.2477, -7.7444],
      [110.2477, -7.7441],
      [110.2477, -7.7437],
      [110.2475, -7.7434],
      [110.248, -7.7434],
      [110.2482, -7.7434],
      [110.2481, -7.7432],
      [110.2479, -7.7428],
      [110.2483, -7.7428],
      [110.2485, -7.7425],
      [110.2485, -7.7423],
      [110.2483, -7.7419],
      [110.2486, -7.7418],
      [110.2487, -7.7416],
      [110.2487, -7.7414],
      [110.2486, -7.741],
      [110.2488, -7.7408],
      [110.2494, -7.741],
      [110.2495, -7.7407],
      [110.2494, -7.7404],
      [110.2491, -7.7402],
      [110.2492, -7.74],
      [110.2496, -7.7397],
      [110.2497, -7.7393],
      [110.25, -7.7389],
      [110.2506, -7.7389],
      [110.2509, -7.7386],
      [110.2508, -7.7382],
      [110.2506, -7.7378],
      [110.2503, -7.7372]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MINGGIR',
    village: 'SENDANGAGUNG',
    border: [
      [110.2478, -7.7099],
      [110.2472, -7.7101],
      [110.2463, -7.71],
      [110.2454, -7.7099],
      [110.2444, -7.71],
      [110.2438, -7.7101],
      [110.2416, -7.7113],
      [110.2403, -7.712],
      [110.2387, -7.7129],
      [110.2373, -7.7134],
      [110.236, -7.7136],
      [110.2353, -7.7136],
      [110.2342, -7.7134],
      [110.2339, -7.7133],
      [110.2335, -7.7133],
      [110.233, -7.7134],
      [110.233, -7.7135],
      [110.2325, -7.7138],
      [110.2321, -7.7143],
      [110.2319, -7.715],
      [110.2316, -7.7166],
      [110.2315, -7.7176],
      [110.2315, -7.7187],
      [110.2316, -7.7196],
      [110.2319, -7.7211],
      [110.2319, -7.7217],
      [110.232, -7.7222],
      [110.2318, -7.7226],
      [110.2314, -7.7229],
      [110.2307, -7.7233],
      [110.2293, -7.7235],
      [110.2277, -7.7233],
      [110.2269, -7.7231],
      [110.2261, -7.7231],
      [110.2254, -7.7232],
      [110.2249, -7.7235],
      [110.2246, -7.7241],
      [110.2241, -7.7251],
      [110.2235, -7.7264],
      [110.2232, -7.7269],
      [110.2222, -7.7298],
      [110.2218, -7.7324],
      [110.2215, -7.7338],
      [110.2208, -7.7352],
      [110.2204, -7.7355],
      [110.2197, -7.7359],
      [110.2188, -7.7365],
      [110.2175, -7.7371],
      [110.2165, -7.7383],
      [110.2162, -7.7394],
      [110.216, -7.7408],
      [110.2159, -7.7417],
      [110.2162, -7.7428],
      [110.2169, -7.744],
      [110.2171, -7.7442],
      [110.2176, -7.7443],
      [110.218, -7.7443],
      [110.2188, -7.7443],
      [110.2195, -7.7443],
      [110.2197, -7.7443],
      [110.2205, -7.744],
      [110.2206, -7.7438],
      [110.2207, -7.7436],
      [110.2209, -7.7436],
      [110.2214, -7.7435],
      [110.2218, -7.7433],
      [110.2221, -7.7432],
      [110.2221, -7.7431],
      [110.2221, -7.7429],
      [110.2224, -7.7428],
      [110.2225, -7.7426],
      [110.2226, -7.7425],
      [110.2227, -7.7425],
      [110.223, -7.7425],
      [110.2232, -7.7423],
      [110.2235, -7.7415],
      [110.2239, -7.7413],
      [110.224, -7.7409],
      [110.2243, -7.7408],
      [110.2244, -7.7408],
      [110.2245, -7.7407],
      [110.2249, -7.7403],
      [110.2251, -7.7402],
      [110.2254, -7.7401],
      [110.2262, -7.7394],
      [110.2273, -7.7379],
      [110.2285, -7.7387],
      [110.229, -7.7391],
      [110.2304, -7.7404],
      [110.231, -7.7383],
      [110.2315, -7.7355],
      [110.231, -7.735],
      [110.2315, -7.7341],
      [110.2318, -7.7335],
      [110.2324, -7.7333],
      [110.233, -7.7323],
      [110.2333, -7.7324],
      [110.2335, -7.7322],
      [110.2337, -7.7322],
      [110.2344, -7.7311],
      [110.2349, -7.7312],
      [110.2346, -7.7322],
      [110.2354, -7.7324],
      [110.235, -7.7334],
      [110.235, -7.7335],
      [110.2353, -7.7336],
      [110.2356, -7.7334],
      [110.236, -7.7332],
      [110.2362, -7.7328],
      [110.2359, -7.7319],
      [110.2362, -7.7319],
      [110.2361, -7.7313],
      [110.2364, -7.731],
      [110.2366, -7.7304],
      [110.2367, -7.7304],
      [110.2376, -7.7306],
      [110.2384, -7.7308],
      [110.2387, -7.7305],
      [110.2397, -7.7313],
      [110.239, -7.7324],
      [110.2398, -7.7328],
      [110.2409, -7.7334],
      [110.2426, -7.7316],
      [110.244, -7.7327],
      [110.2448, -7.7313],
      [110.2456, -7.7299],
      [110.2459, -7.7299],
      [110.2475, -7.7271],
      [110.2492, -7.7241],
      [110.2493, -7.7236],
      [110.2492, -7.7229],
      [110.249, -7.722],
      [110.249, -7.7216],
      [110.2494, -7.7197],
      [110.248, -7.7205],
      [110.2472, -7.721],
      [110.2458, -7.7211],
      [110.2455, -7.7208],
      [110.2468, -7.719],
      [110.2477, -7.7178],
      [110.2477, -7.7177],
      [110.2467, -7.7178],
      [110.2459, -7.7175],
      [110.2452, -7.7172],
      [110.2448, -7.7171],
      [110.2443, -7.7172],
      [110.2439, -7.717],
      [110.2443, -7.7168],
      [110.2445, -7.7164],
      [110.2445, -7.716],
      [110.2442, -7.7159],
      [110.2444, -7.7153],
      [110.2438, -7.715],
      [110.2441, -7.7144],
      [110.244, -7.7141],
      [110.245, -7.7144],
      [110.2455, -7.7144],
      [110.2461, -7.7147],
      [110.2473, -7.714],
      [110.2479, -7.7142],
      [110.2485, -7.714],
      [110.2487, -7.7139],
      [110.249, -7.7138],
      [110.2496, -7.7134],
      [110.2496, -7.7128],
      [110.2492, -7.7125],
      [110.2489, -7.7118],
      [110.2493, -7.7116],
      [110.2496, -7.7111],
      [110.2498, -7.7107],
      [110.2498, -7.7102],
      [110.249, -7.71],
      [110.2483, -7.71],
      [110.2482, -7.71],
      [110.2478, -7.7099]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MINGGIR',
    village: 'SENDANGSARI',
    border: [
      [110.2678, -7.7073],
      [110.2673, -7.7072],
      [110.265, -7.7063],
      [110.2635, -7.7057],
      [110.2606, -7.7046],
      [110.2597, -7.7043],
      [110.2594, -7.7042],
      [110.2584, -7.7043],
      [110.2571, -7.7046],
      [110.2562, -7.7052],
      [110.2555, -7.7061],
      [110.2545, -7.7076],
      [110.2538, -7.7082],
      [110.253, -7.7086],
      [110.2516, -7.7086],
      [110.2508, -7.7084],
      [110.2502, -7.7084],
      [110.2498, -7.7086],
      [110.2496, -7.7087],
      [110.2494, -7.7087],
      [110.2489, -7.709],
      [110.2486, -7.7094],
      [110.2482, -7.7096],
      [110.2478, -7.7099],
      [110.2482, -7.71],
      [110.2483, -7.71],
      [110.249, -7.71],
      [110.2498, -7.7102],
      [110.2498, -7.7107],
      [110.2496, -7.7111],
      [110.2493, -7.7116],
      [110.2489, -7.7118],
      [110.2492, -7.7125],
      [110.2496, -7.7128],
      [110.2496, -7.7134],
      [110.249, -7.7138],
      [110.2487, -7.7139],
      [110.2485, -7.714],
      [110.2479, -7.7142],
      [110.2473, -7.714],
      [110.2461, -7.7147],
      [110.2455, -7.7144],
      [110.245, -7.7144],
      [110.244, -7.7141],
      [110.2441, -7.7144],
      [110.2438, -7.715],
      [110.2444, -7.7153],
      [110.2442, -7.7159],
      [110.2445, -7.716],
      [110.2445, -7.7164],
      [110.2443, -7.7168],
      [110.2439, -7.717],
      [110.2443, -7.7172],
      [110.2448, -7.7171],
      [110.2452, -7.7172],
      [110.2459, -7.7175],
      [110.2467, -7.7178],
      [110.2477, -7.7177],
      [110.2477, -7.7178],
      [110.2468, -7.719],
      [110.2455, -7.7208],
      [110.2458, -7.7211],
      [110.2472, -7.721],
      [110.248, -7.7205],
      [110.2494, -7.7197],
      [110.249, -7.7216],
      [110.249, -7.722],
      [110.2492, -7.7229],
      [110.2493, -7.7236],
      [110.2492, -7.7241],
      [110.2475, -7.7271],
      [110.2459, -7.7299],
      [110.2462, -7.73],
      [110.2465, -7.7302],
      [110.2468, -7.7301],
      [110.247, -7.7297],
      [110.2475, -7.7292],
      [110.2481, -7.7284],
      [110.2494, -7.7291],
      [110.2488, -7.7302],
      [110.2478, -7.7318],
      [110.2479, -7.7327],
      [110.2479, -7.734],
      [110.2477, -7.7343],
      [110.2475, -7.7347],
      [110.2477, -7.7349],
      [110.2478, -7.735],
      [110.248, -7.7351],
      [110.2485, -7.7349],
      [110.2491, -7.7347],
      [110.2493, -7.7345],
      [110.2494, -7.7343],
      [110.2499, -7.7333],
      [110.25, -7.7334],
      [110.2504, -7.7336],
      [110.25, -7.7348],
      [110.25, -7.7354],
      [110.249, -7.7371],
      [110.2497, -7.7375],
      [110.25, -7.7377],
      [110.2503, -7.7372],
      [110.2503, -7.7369],
      [110.2504, -7.7365],
      [110.2507, -7.7364],
      [110.2509, -7.7363],
      [110.2511, -7.736],
      [110.2511, -7.7354],
      [110.2513, -7.7351],
      [110.2518, -7.7346],
      [110.2523, -7.7346],
      [110.2526, -7.7345],
      [110.2529, -7.7344],
      [110.2522, -7.734],
      [110.2525, -7.7337],
      [110.2528, -7.733],
      [110.253, -7.7332],
      [110.2532, -7.7331],
      [110.2532, -7.7327],
      [110.2533, -7.7324],
      [110.2536, -7.7324],
      [110.2538, -7.7323],
      [110.2538, -7.732],
      [110.2538, -7.7316],
      [110.254, -7.7313],
      [110.2544, -7.7312],
      [110.2548, -7.7313],
      [110.2551, -7.7317],
      [110.2549, -7.7323],
      [110.255, -7.7331],
      [110.2548, -7.7338],
      [110.2543, -7.7336],
      [110.2541, -7.7341],
      [110.2552, -7.7347],
      [110.2555, -7.734],
      [110.2574, -7.7349],
      [110.2577, -7.735],
      [110.2583, -7.7351],
      [110.2588, -7.7346],
      [110.2589, -7.7338],
      [110.2589, -7.7335],
      [110.2593, -7.7329],
      [110.2597, -7.7324],
      [110.2598, -7.7319],
      [110.26, -7.7314],
      [110.2599, -7.7306],
      [110.2599, -7.73],
      [110.2601, -7.7298],
      [110.2606, -7.7294],
      [110.2608, -7.7286],
      [110.2607, -7.7286],
      [110.2603, -7.7285],
      [110.2601, -7.7286],
      [110.2595, -7.7286],
      [110.2593, -7.7292],
      [110.2591, -7.7292],
      [110.2593, -7.7286],
      [110.2587, -7.7284],
      [110.2583, -7.7294],
      [110.258, -7.7298],
      [110.2578, -7.7298],
      [110.2573, -7.7296],
      [110.2569, -7.7293],
      [110.2565, -7.7287],
      [110.2561, -7.7283],
      [110.2563, -7.7281],
      [110.2571, -7.7284],
      [110.2575, -7.7279],
      [110.258, -7.7268],
      [110.2583, -7.7259],
      [110.2585, -7.7254],
      [110.2583, -7.7254],
      [110.2572, -7.7255],
      [110.2596, -7.7212],
      [110.2604, -7.7197],
      [110.2609, -7.7195],
      [110.2618, -7.7179],
      [110.2629, -7.718],
      [110.2629, -7.7179],
      [110.2641, -7.7157],
      [110.2647, -7.7146],
      [110.2641, -7.7137],
      [110.2645, -7.7124],
      [110.2643, -7.7121],
      [110.2652, -7.7115],
      [110.266, -7.7116],
      [110.2666, -7.711],
      [110.2669, -7.7106],
      [110.2673, -7.7105],
      [110.2674, -7.7102],
      [110.2673, -7.71],
      [110.2673, -7.7096],
      [110.2682, -7.7097],
      [110.2682, -7.7095],
      [110.2685, -7.7092],
      [110.2686, -7.7091],
      [110.269, -7.7086],
      [110.2688, -7.7085],
      [110.2683, -7.7081],
      [110.2681, -7.7078],
      [110.2679, -7.7076],
      [110.2678, -7.7073]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MINGGIR',
    village: 'SENDANGREJO',
    border: [
      [110.2745, -7.7092],
      [110.2744, -7.7092],
      [110.274, -7.7094],
      [110.2738, -7.7094],
      [110.2734, -7.7094],
      [110.2729, -7.709],
      [110.2719, -7.7089],
      [110.2711, -7.7093],
      [110.2706, -7.7096],
      [110.2701, -7.7095],
      [110.2695, -7.709],
      [110.269, -7.7086],
      [110.2686, -7.7091],
      [110.2685, -7.7092],
      [110.2682, -7.7095],
      [110.2682, -7.7097],
      [110.2673, -7.7096],
      [110.2673, -7.71],
      [110.2674, -7.7102],
      [110.2673, -7.7105],
      [110.2669, -7.7106],
      [110.2666, -7.711],
      [110.266, -7.7116],
      [110.2652, -7.7115],
      [110.2643, -7.7121],
      [110.2645, -7.7124],
      [110.2641, -7.7137],
      [110.2647, -7.7146],
      [110.2641, -7.7157],
      [110.2629, -7.7179],
      [110.2629, -7.718],
      [110.2618, -7.7179],
      [110.2609, -7.7195],
      [110.2604, -7.7197],
      [110.2596, -7.7212],
      [110.2572, -7.7255],
      [110.2583, -7.7254],
      [110.2585, -7.7254],
      [110.2583, -7.7259],
      [110.258, -7.7268],
      [110.2575, -7.7279],
      [110.2571, -7.7284],
      [110.2563, -7.7281],
      [110.2561, -7.7283],
      [110.2565, -7.7287],
      [110.2569, -7.7293],
      [110.2573, -7.7296],
      [110.2578, -7.7298],
      [110.258, -7.7298],
      [110.2583, -7.7294],
      [110.2587, -7.7284],
      [110.2593, -7.7286],
      [110.2591, -7.7292],
      [110.2593, -7.7292],
      [110.2595, -7.7286],
      [110.2601, -7.7286],
      [110.2603, -7.7285],
      [110.2607, -7.7286],
      [110.2608, -7.7286],
      [110.2606, -7.7294],
      [110.2601, -7.7298],
      [110.2599, -7.73],
      [110.2599, -7.7306],
      [110.26, -7.7314],
      [110.2598, -7.7319],
      [110.2597, -7.7324],
      [110.2593, -7.7329],
      [110.2589, -7.7335],
      [110.2589, -7.7338],
      [110.2588, -7.7346],
      [110.2583, -7.7351],
      [110.2577, -7.735],
      [110.2575, -7.7355],
      [110.2578, -7.7357],
      [110.2577, -7.7361],
      [110.2578, -7.7363],
      [110.258, -7.7363],
      [110.2582, -7.736],
      [110.2583, -7.7359],
      [110.2585, -7.7358],
      [110.2587, -7.7362],
      [110.2589, -7.7364],
      [110.2591, -7.7365],
      [110.2589, -7.7368],
      [110.2589, -7.7371],
      [110.259, -7.7375],
      [110.2588, -7.7377],
      [110.2586, -7.7386],
      [110.2587, -7.739],
      [110.259, -7.7393],
      [110.2595, -7.7393],
      [110.2598, -7.739],
      [110.2601, -7.739],
      [110.2602, -7.7387],
      [110.2603, -7.7388],
      [110.2606, -7.7388],
      [110.2607, -7.739],
      [110.2606, -7.7392],
      [110.2601, -7.7394],
      [110.2598, -7.7396],
      [110.2595, -7.7403],
      [110.2595, -7.7407],
      [110.2596, -7.7408],
      [110.2596, -7.7417],
      [110.2593, -7.7419],
      [110.2593, -7.7425],
      [110.2593, -7.7428],
      [110.2594, -7.7432],
      [110.259, -7.7438],
      [110.2589, -7.7443],
      [110.2585, -7.745],
      [110.2584, -7.7453],
      [110.2594, -7.7459],
      [110.2599, -7.744],
      [110.2615, -7.7446],
      [110.2617, -7.7441],
      [110.2618, -7.7439],
      [110.262, -7.7432],
      [110.2631, -7.7392],
      [110.2635, -7.7392],
      [110.2632, -7.7404],
      [110.2645, -7.7409],
      [110.2646, -7.7409],
      [110.2648, -7.7406],
      [110.2648, -7.74],
      [110.2647, -7.7396],
      [110.2644, -7.7395],
      [110.2638, -7.7393],
      [110.264, -7.739],
      [110.2643, -7.7387],
      [110.2644, -7.7385],
      [110.2645, -7.7383],
      [110.2646, -7.738],
      [110.2647, -7.7378],
      [110.2647, -7.7373],
      [110.2647, -7.7368],
      [110.2651, -7.7363],
      [110.2655, -7.7361],
      [110.266, -7.7358],
      [110.2661, -7.7352],
      [110.2658, -7.7351],
      [110.2652, -7.7352],
      [110.2647, -7.7353],
      [110.2643, -7.7355],
      [110.2641, -7.7355],
      [110.2643, -7.7349],
      [110.2651, -7.7349],
      [110.2668, -7.7349],
      [110.2699, -7.7349],
      [110.2709, -7.7356],
      [110.2722, -7.7365],
      [110.2733, -7.7373],
      [110.2742, -7.7379],
      [110.2747, -7.738],
      [110.2752, -7.738],
      [110.2756, -7.7379],
      [110.2757, -7.7378],
      [110.276, -7.7375],
      [110.278, -7.7356],
      [110.2788, -7.7348],
      [110.279, -7.7346],
      [110.2788, -7.7344],
      [110.2789, -7.734],
      [110.2797, -7.733],
      [110.2799, -7.7326],
      [110.2799, -7.7321],
      [110.2792, -7.7317],
      [110.2794, -7.7313],
      [110.2795, -7.7305],
      [110.2795, -7.73],
      [110.2792, -7.7294],
      [110.2798, -7.7296],
      [110.2802, -7.7295],
      [110.2806, -7.7292],
      [110.2806, -7.7285],
      [110.2808, -7.728],
      [110.2811, -7.7274],
      [110.2812, -7.727],
      [110.2807, -7.7263],
      [110.2799, -7.7257],
      [110.2794, -7.7253],
      [110.2798, -7.7248],
      [110.2801, -7.7241],
      [110.2805, -7.7228],
      [110.2785, -7.722],
      [110.2778, -7.7234],
      [110.2762, -7.7227],
      [110.2752, -7.725],
      [110.2737, -7.7245],
      [110.2733, -7.7251],
      [110.2718, -7.7249],
      [110.2727, -7.7216],
      [110.2731, -7.7203],
      [110.2737, -7.7182],
      [110.2738, -7.7179],
      [110.2737, -7.7174],
      [110.2739, -7.7172],
      [110.2739, -7.7168],
      [110.2733, -7.7167],
      [110.2731, -7.7166],
      [110.273, -7.7163],
      [110.2731, -7.7158],
      [110.2733, -7.7156],
      [110.2736, -7.7157],
      [110.2739, -7.7156],
      [110.2742, -7.715],
      [110.2746, -7.7151],
      [110.2748, -7.7154],
      [110.2752, -7.7157],
      [110.2757, -7.7157],
      [110.2777, -7.7169],
      [110.2786, -7.7174],
      [110.2788, -7.7174],
      [110.2792, -7.7165],
      [110.2803, -7.7144],
      [110.2814, -7.7122],
      [110.2819, -7.7112],
      [110.2818, -7.7111],
      [110.2812, -7.7111],
      [110.2776, -7.7109],
      [110.2773, -7.7109],
      [110.2769, -7.7109],
      [110.2762, -7.7122],
      [110.2761, -7.7125],
      [110.2755, -7.7122],
      [110.2754, -7.712],
      [110.2754, -7.7118],
      [110.2761, -7.7119],
      [110.276, -7.7108],
      [110.276, -7.7103],
      [110.2757, -7.7102],
      [110.2757, -7.7099],
      [110.2747, -7.7095],
      [110.2745, -7.7092]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SEYEGAN',
    village: 'MARGOLUWIH',
    border: [
      [110.305, -7.7412],
      [110.3049, -7.7412],
      [110.3047, -7.7414],
      [110.3045, -7.7418],
      [110.3037, -7.742],
      [110.303, -7.7422],
      [110.3028, -7.7436],
      [110.3027, -7.7437],
      [110.3023, -7.7438],
      [110.3022, -7.7433],
      [110.3023, -7.7426],
      [110.302, -7.7424],
      [110.3018, -7.7421],
      [110.3019, -7.7416],
      [110.3016, -7.7414],
      [110.3013, -7.7414],
      [110.3011, -7.7419],
      [110.3007, -7.7422],
      [110.3002, -7.7425],
      [110.2997, -7.7426],
      [110.299, -7.7425],
      [110.2985, -7.7421],
      [110.2983, -7.7417],
      [110.2979, -7.7414],
      [110.2969, -7.7406],
      [110.2964, -7.7411],
      [110.2956, -7.7408],
      [110.295, -7.7403],
      [110.2944, -7.7401],
      [110.2939, -7.7403],
      [110.2933, -7.7401],
      [110.2928, -7.7403],
      [110.2923, -7.7403],
      [110.2918, -7.7401],
      [110.2914, -7.7389],
      [110.2912, -7.7386],
      [110.2906, -7.7384],
      [110.2905, -7.7388],
      [110.2905, -7.7389],
      [110.2905, -7.7391],
      [110.2905, -7.7392],
      [110.2902, -7.7396],
      [110.29, -7.7398],
      [110.29, -7.7402],
      [110.2901, -7.7404],
      [110.2905, -7.7405],
      [110.2912, -7.7408],
      [110.2919, -7.7413],
      [110.2925, -7.7414],
      [110.2932, -7.7413],
      [110.2936, -7.7417],
      [110.2939, -7.742],
      [110.2935, -7.7432],
      [110.293, -7.7438],
      [110.291, -7.7455],
      [110.2903, -7.7466],
      [110.29, -7.7472],
      [110.2893, -7.7475],
      [110.2891, -7.7477],
      [110.289, -7.7486],
      [110.2887, -7.7497],
      [110.2884, -7.75],
      [110.2883, -7.75],
      [110.2881, -7.7501],
      [110.2878, -7.7501],
      [110.2877, -7.7501],
      [110.2873, -7.7503],
      [110.2871, -7.7507],
      [110.2869, -7.7511],
      [110.2867, -7.7515],
      [110.2868, -7.7521],
      [110.2871, -7.7522],
      [110.2872, -7.7522],
      [110.2877, -7.7524],
      [110.288, -7.7525],
      [110.2881, -7.7532],
      [110.2889, -7.7533],
      [110.2888, -7.7536],
      [110.2887, -7.7541],
      [110.2889, -7.7546],
      [110.2891, -7.7553],
      [110.2894, -7.7558],
      [110.2897, -7.7562],
      [110.2898, -7.7564],
      [110.2895, -7.7566],
      [110.2892, -7.7568],
      [110.2888, -7.7572],
      [110.2884, -7.7576],
      [110.2885, -7.7577],
      [110.2887, -7.7578],
      [110.2892, -7.758],
      [110.2895, -7.7584],
      [110.2897, -7.7581],
      [110.29, -7.7581],
      [110.29, -7.7583],
      [110.2899, -7.7588],
      [110.2896, -7.7599],
      [110.29, -7.76],
      [110.29, -7.7602],
      [110.2902, -7.7603],
      [110.2905, -7.7606],
      [110.2905, -7.7609],
      [110.2904, -7.7613],
      [110.2914, -7.7617],
      [110.2915, -7.7618],
      [110.2918, -7.7608],
      [110.2925, -7.7585],
      [110.2942, -7.7592],
      [110.2945, -7.7594],
      [110.2947, -7.7587],
      [110.2954, -7.7589],
      [110.2972, -7.7593],
      [110.2975, -7.7585],
      [110.2978, -7.7575],
      [110.298, -7.7567],
      [110.2985, -7.7569],
      [110.2995, -7.7572],
      [110.2998, -7.7565],
      [110.3005, -7.756],
      [110.301, -7.7561],
      [110.3018, -7.7563],
      [110.3031, -7.7566],
      [110.3042, -7.7569],
      [110.3041, -7.7572],
      [110.3053, -7.7576],
      [110.307, -7.7585],
      [110.3074, -7.759],
      [110.3071, -7.7592],
      [110.3066, -7.7595],
      [110.3062, -7.7598],
      [110.3056, -7.7605],
      [110.3055, -7.7608],
      [110.3053, -7.761],
      [110.3055, -7.7613],
      [110.3057, -7.7616],
      [110.3059, -7.7621],
      [110.3062, -7.7629],
      [110.3063, -7.7633],
      [110.3061, -7.7636],
      [110.3059, -7.7641],
      [110.3055, -7.7647],
      [110.3056, -7.7649],
      [110.3057, -7.765],
      [110.3059, -7.7655],
      [110.3063, -7.7659],
      [110.3067, -7.7668],
      [110.3071, -7.7673],
      [110.3077, -7.7674],
      [110.3082, -7.7674],
      [110.3091, -7.7653],
      [110.3099, -7.7636],
      [110.3096, -7.7631],
      [110.3097, -7.7629],
      [110.3101, -7.763],
      [110.3102, -7.7626],
      [110.3109, -7.7624],
      [110.3112, -7.7619],
      [110.3113, -7.7614],
      [110.3121, -7.7615],
      [110.3123, -7.7617],
      [110.3128, -7.7616],
      [110.3128, -7.7614],
      [110.3129, -7.7612],
      [110.3131, -7.7609],
      [110.3135, -7.7603],
      [110.3137, -7.7599],
      [110.3137, -7.7594],
      [110.3134, -7.7592],
      [110.3129, -7.759],
      [110.3126, -7.7589],
      [110.3123, -7.7585],
      [110.3122, -7.7581],
      [110.3126, -7.7579],
      [110.3129, -7.7576],
      [110.313, -7.7572],
      [110.313, -7.7564],
      [110.313, -7.7561],
      [110.3133, -7.7555],
      [110.3139, -7.7543],
      [110.3141, -7.7537],
      [110.314, -7.753],
      [110.3136, -7.7526],
      [110.3134, -7.7521],
      [110.3135, -7.7519],
      [110.3137, -7.7518],
      [110.3141, -7.7518],
      [110.3149, -7.7519],
      [110.3154, -7.7517],
      [110.3156, -7.7515],
      [110.3157, -7.7513],
      [110.3155, -7.7509],
      [110.3153, -7.7504],
      [110.3152, -7.7497],
      [110.3152, -7.7494],
      [110.3154, -7.7488],
      [110.3153, -7.7483],
      [110.3151, -7.7477],
      [110.3153, -7.7473],
      [110.3153, -7.7468],
      [110.3149, -7.7464],
      [110.3147, -7.7462],
      [110.3149, -7.7459],
      [110.3151, -7.7456],
      [110.3153, -7.7452],
      [110.3152, -7.7451],
      [110.3151, -7.745],
      [110.3147, -7.7451],
      [110.3144, -7.745],
      [110.3141, -7.7446],
      [110.3138, -7.7444],
      [110.3133, -7.7444],
      [110.3131, -7.7441],
      [110.3128, -7.744],
      [110.3124, -7.744],
      [110.3119, -7.7444],
      [110.3116, -7.7448],
      [110.311, -7.7454],
      [110.3105, -7.7459],
      [110.3103, -7.7464],
      [110.31, -7.7467],
      [110.3098, -7.7466],
      [110.3096, -7.7466],
      [110.3091, -7.7465],
      [110.3088, -7.7469],
      [110.3086, -7.7469],
      [110.3085, -7.7468],
      [110.3086, -7.746],
      [110.3088, -7.7449],
      [110.3082, -7.7449],
      [110.3078, -7.745],
      [110.3077, -7.7444],
      [110.3078, -7.7438],
      [110.3078, -7.7431],
      [110.3081, -7.7428],
      [110.3082, -7.7422],
      [110.3083, -7.7418],
      [110.3082, -7.7416],
      [110.3078, -7.7415],
      [110.3078, -7.7412],
      [110.307, -7.7411],
      [110.3069, -7.7416],
      [110.3063, -7.7416],
      [110.3058, -7.7416],
      [110.3054, -7.7415],
      [110.305, -7.7412]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SEYEGAN',
    village: 'MARGODADI',
    border: [
      [110.3072, -7.7162],
      [110.3069, -7.7162],
      [110.3065, -7.7153],
      [110.3061, -7.7153],
      [110.3057, -7.7155],
      [110.3051, -7.7155],
      [110.3042, -7.7152],
      [110.3041, -7.7153],
      [110.3036, -7.7162],
      [110.3031, -7.7167],
      [110.3028, -7.7175],
      [110.3016, -7.7192],
      [110.3018, -7.7193],
      [110.3016, -7.7201],
      [110.3013, -7.7201],
      [110.3009, -7.7215],
      [110.3006, -7.7219],
      [110.3004, -7.7228],
      [110.3001, -7.7229],
      [110.3, -7.7234],
      [110.2993, -7.7239],
      [110.2989, -7.7247],
      [110.2982, -7.7253],
      [110.2972, -7.7255],
      [110.2967, -7.7258],
      [110.2968, -7.7263],
      [110.2963, -7.7271],
      [110.296, -7.7279],
      [110.2949, -7.7288],
      [110.2941, -7.7295],
      [110.2936, -7.7297],
      [110.2934, -7.7295],
      [110.2931, -7.7292],
      [110.2928, -7.729],
      [110.2925, -7.729],
      [110.2919, -7.7292],
      [110.2914, -7.7289],
      [110.2911, -7.7288],
      [110.2906, -7.7288],
      [110.2901, -7.7289],
      [110.2895, -7.7289],
      [110.2893, -7.7288],
      [110.289, -7.7284],
      [110.2887, -7.7284],
      [110.2883, -7.7286],
      [110.2873, -7.7292],
      [110.2871, -7.7308],
      [110.2867, -7.7309],
      [110.2866, -7.7312],
      [110.2855, -7.7312],
      [110.285, -7.7313],
      [110.284, -7.7311],
      [110.2828, -7.7323],
      [110.2799, -7.7344],
      [110.2791, -7.735],
      [110.2788, -7.7348],
      [110.278, -7.7356],
      [110.276, -7.7375],
      [110.2757, -7.7378],
      [110.2759, -7.7378],
      [110.276, -7.7378],
      [110.2763, -7.7378],
      [110.2764, -7.7375],
      [110.2767, -7.7374],
      [110.2771, -7.7375],
      [110.2773, -7.7376],
      [110.2777, -7.738],
      [110.2781, -7.7378],
      [110.2782, -7.738],
      [110.2784, -7.7382],
      [110.2784, -7.7385],
      [110.2782, -7.739],
      [110.2778, -7.7396],
      [110.2776, -7.7407],
      [110.2776, -7.7415],
      [110.2778, -7.7423],
      [110.2782, -7.7427],
      [110.2788, -7.743],
      [110.2795, -7.7433],
      [110.28, -7.7439],
      [110.2806, -7.7446],
      [110.2813, -7.7453],
      [110.2819, -7.7462],
      [110.2819, -7.7468],
      [110.2819, -7.7474],
      [110.2817, -7.7479],
      [110.2815, -7.7482],
      [110.2815, -7.7486],
      [110.2816, -7.7491],
      [110.2817, -7.7496],
      [110.2819, -7.7501],
      [110.2823, -7.7506],
      [110.2828, -7.7512],
      [110.2834, -7.7512],
      [110.2843, -7.751],
      [110.2847, -7.7507],
      [110.2852, -7.7501],
      [110.285, -7.7498],
      [110.2852, -7.7495],
      [110.2856, -7.7492],
      [110.286, -7.7492],
      [110.2864, -7.7496],
      [110.2865, -7.7501],
      [110.2868, -7.7503],
      [110.2871, -7.7507],
      [110.2873, -7.7503],
      [110.2877, -7.7501],
      [110.2878, -7.7501],
      [110.2881, -7.7501],
      [110.2883, -7.75],
      [110.2884, -7.75],
      [110.2887, -7.7497],
      [110.289, -7.7486],
      [110.2891, -7.7477],
      [110.2893, -7.7475],
      [110.29, -7.7472],
      [110.2903, -7.7466],
      [110.291, -7.7455],
      [110.293, -7.7438],
      [110.2935, -7.7432],
      [110.2939, -7.742],
      [110.2936, -7.7417],
      [110.2932, -7.7413],
      [110.2925, -7.7414],
      [110.2919, -7.7413],
      [110.2912, -7.7408],
      [110.2905, -7.7405],
      [110.2901, -7.7404],
      [110.29, -7.7402],
      [110.29, -7.7398],
      [110.2902, -7.7396],
      [110.2905, -7.7392],
      [110.2905, -7.7391],
      [110.2905, -7.7389],
      [110.2905, -7.7388],
      [110.2906, -7.7384],
      [110.2912, -7.7386],
      [110.2914, -7.7389],
      [110.2918, -7.7401],
      [110.2923, -7.7403],
      [110.2928, -7.7403],
      [110.2933, -7.7401],
      [110.2939, -7.7403],
      [110.2944, -7.7401],
      [110.295, -7.7403],
      [110.2956, -7.7408],
      [110.2964, -7.7411],
      [110.2969, -7.7406],
      [110.2979, -7.7414],
      [110.2983, -7.7417],
      [110.2985, -7.7421],
      [110.299, -7.7425],
      [110.2997, -7.7426],
      [110.3002, -7.7425],
      [110.3007, -7.7422],
      [110.3011, -7.7419],
      [110.3013, -7.7414],
      [110.3016, -7.7414],
      [110.3019, -7.7416],
      [110.3018, -7.7421],
      [110.302, -7.7424],
      [110.3023, -7.7426],
      [110.3022, -7.7433],
      [110.3023, -7.7438],
      [110.3027, -7.7437],
      [110.3028, -7.7436],
      [110.303, -7.7422],
      [110.3037, -7.742],
      [110.3045, -7.7418],
      [110.3047, -7.7414],
      [110.3049, -7.7412],
      [110.305, -7.7412],
      [110.3051, -7.7407],
      [110.3046, -7.7398],
      [110.3045, -7.7394],
      [110.3048, -7.739],
      [110.3052, -7.7386],
      [110.3052, -7.7383],
      [110.3052, -7.7381],
      [110.3049, -7.7373],
      [110.3051, -7.7371],
      [110.3051, -7.7369],
      [110.305, -7.7364],
      [110.3051, -7.736],
      [110.3055, -7.7358],
      [110.3059, -7.7356],
      [110.306, -7.7353],
      [110.3059, -7.7349],
      [110.306, -7.7348],
      [110.3064, -7.7346],
      [110.3065, -7.7343],
      [110.307, -7.7325],
      [110.3074, -7.7313],
      [110.3079, -7.7306],
      [110.3083, -7.7303],
      [110.3083, -7.7302],
      [110.3075, -7.7291],
      [110.3068, -7.7292],
      [110.3065, -7.7287],
      [110.306, -7.7286],
      [110.3068, -7.7275],
      [110.3081, -7.7233],
      [110.3083, -7.7224],
      [110.307, -7.7218],
      [110.3062, -7.7211],
      [110.3057, -7.7207],
      [110.305, -7.7203],
      [110.3056, -7.7187],
      [110.306, -7.7184],
      [110.3066, -7.7177],
      [110.3073, -7.7179],
      [110.3079, -7.7178],
      [110.3078, -7.7177],
      [110.3072, -7.7162]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SEYEGAN',
    village: 'MARGOKATON',
    border: [
      [110.2905, -7.7083],
      [110.2901, -7.7083],
      [110.2895, -7.708],
      [110.2891, -7.7082],
      [110.2884, -7.7078],
      [110.2878, -7.7077],
      [110.2874, -7.7079],
      [110.2869, -7.7083],
      [110.2862, -7.7085],
      [110.2851, -7.7086],
      [110.2841, -7.7089],
      [110.2838, -7.7095],
      [110.2834, -7.7097],
      [110.2827, -7.7102],
      [110.2822, -7.7102],
      [110.2817, -7.7101],
      [110.2816, -7.7101],
      [110.2811, -7.7101],
      [110.2807, -7.7102],
      [110.2803, -7.7102],
      [110.2794, -7.7099],
      [110.2791, -7.7097],
      [110.2789, -7.7094],
      [110.2786, -7.7091],
      [110.2783, -7.7091],
      [110.278, -7.7092],
      [110.2776, -7.7096],
      [110.2774, -7.7098],
      [110.2767, -7.7097],
      [110.2765, -7.7097],
      [110.2761, -7.7097],
      [110.2756, -7.7096],
      [110.2748, -7.7091],
      [110.2745, -7.7092],
      [110.2747, -7.7095],
      [110.2757, -7.7099],
      [110.2757, -7.7102],
      [110.276, -7.7103],
      [110.276, -7.7108],
      [110.2761, -7.7119],
      [110.2754, -7.7118],
      [110.2754, -7.712],
      [110.2755, -7.7122],
      [110.2761, -7.7125],
      [110.2762, -7.7122],
      [110.2769, -7.7109],
      [110.2773, -7.7109],
      [110.2776, -7.7109],
      [110.2812, -7.7111],
      [110.2818, -7.7111],
      [110.2819, -7.7112],
      [110.2814, -7.7122],
      [110.2803, -7.7144],
      [110.2792, -7.7165],
      [110.2788, -7.7174],
      [110.2786, -7.7174],
      [110.2777, -7.7169],
      [110.2757, -7.7157],
      [110.2752, -7.7157],
      [110.2748, -7.7154],
      [110.2746, -7.7151],
      [110.2742, -7.715],
      [110.2739, -7.7156],
      [110.2736, -7.7157],
      [110.2733, -7.7156],
      [110.2731, -7.7158],
      [110.273, -7.7163],
      [110.2731, -7.7166],
      [110.2733, -7.7167],
      [110.2739, -7.7168],
      [110.2739, -7.7172],
      [110.2737, -7.7174],
      [110.2738, -7.7179],
      [110.2737, -7.7182],
      [110.2731, -7.7203],
      [110.2727, -7.7216],
      [110.2718, -7.7249],
      [110.2733, -7.7251],
      [110.2737, -7.7245],
      [110.2752, -7.725],
      [110.2762, -7.7227],
      [110.2778, -7.7234],
      [110.2785, -7.722],
      [110.2805, -7.7228],
      [110.2801, -7.7241],
      [110.2798, -7.7248],
      [110.2794, -7.7253],
      [110.2799, -7.7257],
      [110.2807, -7.7263],
      [110.2812, -7.727],
      [110.2811, -7.7274],
      [110.2808, -7.728],
      [110.2806, -7.7285],
      [110.2806, -7.7292],
      [110.2802, -7.7295],
      [110.2798, -7.7296],
      [110.2792, -7.7294],
      [110.2795, -7.73],
      [110.2795, -7.7305],
      [110.2794, -7.7313],
      [110.2792, -7.7317],
      [110.2799, -7.7321],
      [110.2799, -7.7326],
      [110.2797, -7.733],
      [110.2789, -7.734],
      [110.2788, -7.7344],
      [110.279, -7.7346],
      [110.2788, -7.7348],
      [110.2791, -7.735],
      [110.2799, -7.7344],
      [110.2828, -7.7323],
      [110.284, -7.7311],
      [110.285, -7.7313],
      [110.2855, -7.7312],
      [110.2866, -7.7312],
      [110.2867, -7.7309],
      [110.2871, -7.7308],
      [110.2873, -7.7292],
      [110.2883, -7.7286],
      [110.2887, -7.7284],
      [110.289, -7.7284],
      [110.2893, -7.7288],
      [110.2895, -7.7289],
      [110.2901, -7.7289],
      [110.2906, -7.7288],
      [110.2911, -7.7288],
      [110.2914, -7.7289],
      [110.2919, -7.7292],
      [110.2925, -7.729],
      [110.2928, -7.729],
      [110.2931, -7.7292],
      [110.2934, -7.7295],
      [110.2936, -7.7297],
      [110.2941, -7.7295],
      [110.2949, -7.7288],
      [110.296, -7.7279],
      [110.2963, -7.7271],
      [110.2968, -7.7263],
      [110.2967, -7.7258],
      [110.2972, -7.7255],
      [110.2982, -7.7253],
      [110.2989, -7.7247],
      [110.2993, -7.7239],
      [110.3, -7.7234],
      [110.3001, -7.7229],
      [110.3004, -7.7228],
      [110.3006, -7.7219],
      [110.3009, -7.7215],
      [110.3013, -7.7201],
      [110.3016, -7.7201],
      [110.3018, -7.7193],
      [110.3016, -7.7192],
      [110.3028, -7.7175],
      [110.3031, -7.7167],
      [110.3036, -7.7162],
      [110.3041, -7.7153],
      [110.303, -7.7153],
      [110.3023, -7.7153],
      [110.3013, -7.7152],
      [110.3013, -7.7154],
      [110.3008, -7.7156],
      [110.3003, -7.7158],
      [110.3003, -7.7164],
      [110.2998, -7.7166],
      [110.2993, -7.7166],
      [110.299, -7.717],
      [110.2984, -7.7169],
      [110.2978, -7.7171],
      [110.2977, -7.717],
      [110.2971, -7.7169],
      [110.2963, -7.7162],
      [110.2957, -7.7151],
      [110.2945, -7.7151],
      [110.2936, -7.7151],
      [110.2927, -7.7154],
      [110.292, -7.7154],
      [110.291, -7.7149],
      [110.2909, -7.7147],
      [110.2907, -7.7141],
      [110.2906, -7.7141],
      [110.29, -7.7138],
      [110.2896, -7.7134],
      [110.2893, -7.7128],
      [110.2895, -7.7126],
      [110.2896, -7.7124],
      [110.2895, -7.7115],
      [110.2902, -7.7108],
      [110.2904, -7.7093],
      [110.291, -7.7094],
      [110.291, -7.7091],
      [110.2907, -7.7085],
      [110.2905, -7.7083]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SEYEGAN',
    village: 'MARGOMULYO',
    border: [
      [110.3252, -7.7017],
      [110.3254, -7.7013],
      [110.3259, -7.7006],
      [110.3265, -7.7005],
      [110.3265, -7.7],
      [110.3264, -7.6998],
      [110.326, -7.6997],
      [110.3254, -7.6998],
      [110.3247, -7.6998],
      [110.3229, -7.6988],
      [110.3215, -7.698],
      [110.3212, -7.6981],
      [110.3211, -7.6983],
      [110.3211, -7.699],
      [110.3207, -7.6998],
      [110.3203, -7.7004],
      [110.3199, -7.7022],
      [110.3191, -7.7035],
      [110.318, -7.7048],
      [110.3175, -7.7048],
      [110.3171, -7.7051],
      [110.3163, -7.7051],
      [110.315, -7.7056],
      [110.3148, -7.7063],
      [110.3148, -7.7069],
      [110.3145, -7.7071],
      [110.314, -7.7073],
      [110.3139, -7.7079],
      [110.3139, -7.7083],
      [110.3136, -7.7087],
      [110.3133, -7.709],
      [110.3132, -7.709],
      [110.3129, -7.7089],
      [110.3127, -7.7088],
      [110.3124, -7.7088],
      [110.3124, -7.7093],
      [110.3124, -7.7095],
      [110.3121, -7.7095],
      [110.3117, -7.7093],
      [110.3113, -7.7091],
      [110.3108, -7.7093],
      [110.3102, -7.7103],
      [110.3105, -7.7107],
      [110.3102, -7.7115],
      [110.311, -7.7118],
      [110.3109, -7.7123],
      [110.3111, -7.7124],
      [110.3115, -7.7121],
      [110.3117, -7.7127],
      [110.3118, -7.7133],
      [110.3117, -7.7135],
      [110.3115, -7.714],
      [110.3113, -7.7153],
      [110.3111, -7.7153],
      [110.3105, -7.7152],
      [110.3106, -7.7162],
      [110.3103, -7.7171],
      [110.3097, -7.7175],
      [110.3092, -7.7173],
      [110.3094, -7.7164],
      [110.308, -7.7155],
      [110.3076, -7.7159],
      [110.3072, -7.7162],
      [110.3078, -7.7177],
      [110.3079, -7.7178],
      [110.3073, -7.7179],
      [110.3066, -7.7177],
      [110.306, -7.7184],
      [110.3056, -7.7187],
      [110.305, -7.7203],
      [110.3057, -7.7207],
      [110.3062, -7.7211],
      [110.307, -7.7218],
      [110.3083, -7.7224],
      [110.3081, -7.7233],
      [110.3068, -7.7275],
      [110.306, -7.7286],
      [110.3065, -7.7287],
      [110.3068, -7.7292],
      [110.3075, -7.7291],
      [110.3083, -7.7302],
      [110.3083, -7.7303],
      [110.3079, -7.7306],
      [110.3074, -7.7313],
      [110.307, -7.7325],
      [110.3065, -7.7343],
      [110.3064, -7.7346],
      [110.306, -7.7348],
      [110.3059, -7.7349],
      [110.306, -7.7353],
      [110.3059, -7.7356],
      [110.306, -7.7358],
      [110.3062, -7.736],
      [110.3065, -7.7363],
      [110.3065, -7.7366],
      [110.3066, -7.7367],
      [110.3065, -7.7372],
      [110.3065, -7.7376],
      [110.3068, -7.7381],
      [110.3071, -7.7381],
      [110.3074, -7.7376],
      [110.3075, -7.7374],
      [110.3079, -7.7365],
      [110.3083, -7.735],
      [110.3094, -7.7353],
      [110.3093, -7.7354],
      [110.309, -7.7365],
      [110.3089, -7.7372],
      [110.3091, -7.7372],
      [110.3093, -7.7375],
      [110.3109, -7.7379],
      [110.3111, -7.7376],
      [110.3112, -7.7371],
      [110.3112, -7.7367],
      [110.3114, -7.7365],
      [110.3117, -7.7361],
      [110.3117, -7.7358],
      [110.3122, -7.736],
      [110.3127, -7.736],
      [110.3129, -7.7357],
      [110.3132, -7.7353],
      [110.3133, -7.7345],
      [110.3136, -7.7342],
      [110.3141, -7.734],
      [110.3146, -7.7338],
      [110.3151, -7.7341],
      [110.316, -7.7326],
      [110.3164, -7.7326],
      [110.317, -7.7318],
      [110.3176, -7.7307],
      [110.3176, -7.7305],
      [110.3175, -7.7303],
      [110.3181, -7.7307],
      [110.3182, -7.7305],
      [110.3185, -7.73],
      [110.3184, -7.7296],
      [110.3189, -7.7292],
      [110.3191, -7.7288],
      [110.3193, -7.7278],
      [110.3195, -7.7264],
      [110.3196, -7.7251],
      [110.3199, -7.7235],
      [110.3206, -7.7239],
      [110.3223, -7.7244],
      [110.3225, -7.7233],
      [110.3228, -7.7226],
      [110.3228, -7.7223],
      [110.3229, -7.7218],
      [110.3231, -7.7208],
      [110.3235, -7.7197],
      [110.3235, -7.719],
      [110.3242, -7.7187],
      [110.3242, -7.7184],
      [110.3244, -7.7174],
      [110.3244, -7.7168],
      [110.3243, -7.7166],
      [110.3235, -7.7161],
      [110.3235, -7.7155],
      [110.3231, -7.7154],
      [110.3231, -7.7152],
      [110.3234, -7.7148],
      [110.3237, -7.7141],
      [110.3239, -7.7134],
      [110.3235, -7.7126],
      [110.3233, -7.7119],
      [110.3226, -7.7116],
      [110.3225, -7.7111],
      [110.3225, -7.7104],
      [110.3221, -7.7104],
      [110.3223, -7.7101],
      [110.3223, -7.7097],
      [110.3226, -7.7093],
      [110.3229, -7.709],
      [110.3235, -7.7085],
      [110.3237, -7.7082],
      [110.3237, -7.7081],
      [110.3236, -7.7079],
      [110.3236, -7.7077],
      [110.3238, -7.7071],
      [110.3243, -7.7073],
      [110.3246, -7.7074],
      [110.3251, -7.7076],
      [110.3254, -7.7078],
      [110.3261, -7.7068],
      [110.3257, -7.7065],
      [110.3257, -7.7052],
      [110.325, -7.7049],
      [110.3245, -7.7048],
      [110.3242, -7.7046],
      [110.3243, -7.7044],
      [110.3246, -7.704],
      [110.3251, -7.7036],
      [110.3252, -7.7034],
      [110.3252, -7.7018],
      [110.3252, -7.7017]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SEYEGAN',
    village: 'MARGOAGUNG',
    border: [
      [110.3067, -7.6947],
      [110.3065, -7.695],
      [110.3062, -7.6955],
      [110.3059, -7.6962],
      [110.3056, -7.6965],
      [110.3053, -7.6965],
      [110.3051, -7.6963],
      [110.3042, -7.6949],
      [110.3038, -7.6945],
      [110.3035, -7.6953],
      [110.3029, -7.6953],
      [110.3023, -7.6959],
      [110.3017, -7.6967],
      [110.3013, -7.6968],
      [110.3008, -7.6975],
      [110.2999, -7.6986],
      [110.2987, -7.6987],
      [110.2983, -7.6989],
      [110.2978, -7.6987],
      [110.2972, -7.6993],
      [110.2969, -7.6994],
      [110.2966, -7.6998],
      [110.2984, -7.7016],
      [110.2968, -7.703],
      [110.296, -7.7037],
      [110.2949, -7.7034],
      [110.2926, -7.7024],
      [110.2921, -7.7028],
      [110.2921, -7.7034],
      [110.2923, -7.7037],
      [110.2923, -7.7041],
      [110.292, -7.7048],
      [110.292, -7.7057],
      [110.2917, -7.706],
      [110.2912, -7.7063],
      [110.2909, -7.7071],
      [110.291, -7.7073],
      [110.2911, -7.7075],
      [110.2905, -7.7083],
      [110.2907, -7.7085],
      [110.291, -7.7091],
      [110.291, -7.7094],
      [110.2904, -7.7093],
      [110.2902, -7.7108],
      [110.2895, -7.7115],
      [110.2896, -7.7124],
      [110.2895, -7.7126],
      [110.2893, -7.7128],
      [110.2896, -7.7134],
      [110.29, -7.7138],
      [110.2906, -7.7141],
      [110.2907, -7.7141],
      [110.2909, -7.7147],
      [110.291, -7.7149],
      [110.292, -7.7154],
      [110.2927, -7.7154],
      [110.2936, -7.7151],
      [110.2945, -7.7151],
      [110.2957, -7.7151],
      [110.2963, -7.7162],
      [110.2971, -7.7169],
      [110.2977, -7.717],
      [110.2978, -7.7171],
      [110.2984, -7.7169],
      [110.299, -7.717],
      [110.2993, -7.7166],
      [110.2998, -7.7166],
      [110.3003, -7.7164],
      [110.3003, -7.7158],
      [110.3008, -7.7156],
      [110.3013, -7.7154],
      [110.3013, -7.7152],
      [110.3023, -7.7153],
      [110.303, -7.7153],
      [110.3041, -7.7153],
      [110.3042, -7.7152],
      [110.3051, -7.7155],
      [110.3057, -7.7155],
      [110.3061, -7.7153],
      [110.3065, -7.7153],
      [110.3069, -7.7162],
      [110.3072, -7.7162],
      [110.3076, -7.7159],
      [110.308, -7.7155],
      [110.3094, -7.7164],
      [110.3092, -7.7173],
      [110.3097, -7.7175],
      [110.3103, -7.7171],
      [110.3106, -7.7162],
      [110.3105, -7.7152],
      [110.3111, -7.7153],
      [110.3113, -7.7153],
      [110.3115, -7.714],
      [110.3117, -7.7135],
      [110.3118, -7.7133],
      [110.3117, -7.7127],
      [110.3115, -7.7121],
      [110.3111, -7.7124],
      [110.3109, -7.7123],
      [110.311, -7.7118],
      [110.3102, -7.7115],
      [110.3105, -7.7107],
      [110.3102, -7.7103],
      [110.3108, -7.7093],
      [110.3113, -7.7091],
      [110.3117, -7.7093],
      [110.3121, -7.7095],
      [110.3124, -7.7095],
      [110.3124, -7.7093],
      [110.3124, -7.7088],
      [110.3127, -7.7088],
      [110.3129, -7.7089],
      [110.3132, -7.709],
      [110.3133, -7.709],
      [110.3136, -7.7087],
      [110.3139, -7.7083],
      [110.3139, -7.7079],
      [110.314, -7.7073],
      [110.3145, -7.7071],
      [110.3148, -7.7069],
      [110.3148, -7.7063],
      [110.315, -7.7056],
      [110.3163, -7.7051],
      [110.3171, -7.7051],
      [110.3175, -7.7048],
      [110.318, -7.7048],
      [110.3191, -7.7035],
      [110.3199, -7.7022],
      [110.3203, -7.7004],
      [110.3207, -7.6998],
      [110.3211, -7.699],
      [110.3211, -7.6983],
      [110.3212, -7.6981],
      [110.3209, -7.6982],
      [110.3202, -7.6983],
      [110.3198, -7.6983],
      [110.319, -7.6981],
      [110.3182, -7.6979],
      [110.3173, -7.6977],
      [110.3163, -7.6971],
      [110.3161, -7.6971],
      [110.3157, -7.697],
      [110.3151, -7.697],
      [110.3144, -7.6964],
      [110.3139, -7.6966],
      [110.3134, -7.6969],
      [110.3131, -7.6974],
      [110.3127, -7.6982],
      [110.3123, -7.6986],
      [110.3116, -7.6988],
      [110.3107, -7.6989],
      [110.3097, -7.6987],
      [110.3093, -7.6984],
      [110.3089, -7.698],
      [110.3078, -7.6955],
      [110.3075, -7.6952],
      [110.3067, -7.6947]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MLATI',
    village: 'SINDUADI',
    border: [
      [110.3808, -7.7438],
      [110.3801, -7.7445],
      [110.3794, -7.7463],
      [110.3786, -7.7487],
      [110.3784, -7.7491],
      [110.3777, -7.7492],
      [110.3771, -7.7494],
      [110.3767, -7.7496],
      [110.3765, -7.7499],
      [110.376, -7.7507],
      [110.3755, -7.7511],
      [110.3752, -7.7517],
      [110.3748, -7.7523],
      [110.3746, -7.7529],
      [110.3742, -7.7535],
      [110.3741, -7.754],
      [110.3741, -7.7542],
      [110.3734, -7.7541],
      [110.3727, -7.754],
      [110.3725, -7.7546],
      [110.3714, -7.7543],
      [110.37, -7.7539],
      [110.3699, -7.7543],
      [110.3687, -7.7544],
      [110.3681, -7.7543],
      [110.3681, -7.7539],
      [110.3677, -7.7536],
      [110.3679, -7.7524],
      [110.3686, -7.7526],
      [110.3688, -7.752],
      [110.369, -7.752],
      [110.3696, -7.7522],
      [110.3698, -7.7517],
      [110.37, -7.7512],
      [110.3703, -7.7508],
      [110.3705, -7.7505],
      [110.3707, -7.7504],
      [110.3709, -7.7502],
      [110.3712, -7.7501],
      [110.3714, -7.7498],
      [110.3718, -7.7492],
      [110.3711, -7.7489],
      [110.3696, -7.7483],
      [110.3691, -7.7483],
      [110.3686, -7.7481],
      [110.3682, -7.7478],
      [110.3685, -7.7475],
      [110.3687, -7.7472],
      [110.3689, -7.7466],
      [110.369, -7.7462],
      [110.3692, -7.7456],
      [110.3695, -7.7453],
      [110.3698, -7.745],
      [110.3698, -7.744],
      [110.3698, -7.7433],
      [110.3696, -7.7429],
      [110.3681, -7.743],
      [110.3672, -7.7431],
      [110.3662, -7.7433],
      [110.3657, -7.7439],
      [110.3653, -7.744],
      [110.3651, -7.7444],
      [110.365, -7.7447],
      [110.3645, -7.745],
      [110.3638, -7.7446],
      [110.3633, -7.7447],
      [110.3628, -7.7448],
      [110.3627, -7.7449],
      [110.3625, -7.7476],
      [110.3624, -7.7487],
      [110.3614, -7.749],
      [110.361, -7.749],
      [110.3604, -7.7488],
      [110.3602, -7.7486],
      [110.3598, -7.7486],
      [110.3594, -7.7488],
      [110.3587, -7.7491],
      [110.3582, -7.7494],
      [110.3579, -7.7494],
      [110.3576, -7.7483],
      [110.3572, -7.7482],
      [110.3567, -7.7482],
      [110.3564, -7.7486],
      [110.3559, -7.7487],
      [110.3551, -7.7488],
      [110.3545, -7.7488],
      [110.3549, -7.7472],
      [110.3533, -7.7466],
      [110.3533, -7.747],
      [110.353, -7.7471],
      [110.3527, -7.7473],
      [110.3527, -7.748],
      [110.3525, -7.7485],
      [110.3523, -7.749],
      [110.3522, -7.7499],
      [110.3522, -7.7504],
      [110.3521, -7.751],
      [110.3522, -7.7514],
      [110.3524, -7.7519],
      [110.3523, -7.7522],
      [110.3523, -7.7527],
      [110.3523, -7.7531],
      [110.3527, -7.7534],
      [110.3531, -7.7538],
      [110.3532, -7.7544],
      [110.3532, -7.7551],
      [110.3533, -7.7558],
      [110.3532, -7.7567],
      [110.3527, -7.7582],
      [110.3528, -7.759],
      [110.3527, -7.7594],
      [110.3524, -7.7598],
      [110.3521, -7.7599],
      [110.3519, -7.7598],
      [110.3515, -7.7597],
      [110.3512, -7.7598],
      [110.351, -7.7598],
      [110.3511, -7.7603],
      [110.3512, -7.7606],
      [110.3512, -7.761],
      [110.3516, -7.7619],
      [110.3514, -7.7624],
      [110.3512, -7.7639],
      [110.3513, -7.7643],
      [110.3513, -7.7651],
      [110.3512, -7.7657],
      [110.3513, -7.766],
      [110.3516, -7.7662],
      [110.3517, -7.7662],
      [110.3522, -7.7665],
      [110.3525, -7.7666],
      [110.3537, -7.7673],
      [110.3545, -7.7679],
      [110.3557, -7.7688],
      [110.356, -7.7682],
      [110.3564, -7.7684],
      [110.3572, -7.7687],
      [110.359, -7.769],
      [110.3597, -7.7692],
      [110.3607, -7.7692],
      [110.3606, -7.7686],
      [110.3613, -7.7686],
      [110.3618, -7.7686],
      [110.3626, -7.7685],
      [110.3628, -7.7688],
      [110.3631, -7.7688],
      [110.3634, -7.7687],
      [110.3637, -7.7684],
      [110.3639, -7.7685],
      [110.3645, -7.7685],
      [110.3652, -7.7685],
      [110.3655, -7.7688],
      [110.3656, -7.769],
      [110.3656, -7.7693],
      [110.3653, -7.7695],
      [110.3648, -7.7698],
      [110.3647, -7.7701],
      [110.3648, -7.7702],
      [110.3649, -7.7703],
      [110.365, -7.7704],
      [110.3651, -7.7705],
      [110.3653, -7.7706],
      [110.3657, -7.7706],
      [110.366, -7.7705],
      [110.366, -7.7701],
      [110.3666, -7.7703],
      [110.3666, -7.7706],
      [110.3665, -7.7711],
      [110.3666, -7.7713],
      [110.3666, -7.7716],
      [110.3669, -7.7717],
      [110.3676, -7.7717],
      [110.3677, -7.7714],
      [110.3679, -7.7701],
      [110.3681, -7.7693],
      [110.3689, -7.7695],
      [110.369, -7.7697],
      [110.3691, -7.77],
      [110.3695, -7.7697],
      [110.3701, -7.7697],
      [110.3704, -7.7688],
      [110.3706, -7.7683],
      [110.3708, -7.768],
      [110.3711, -7.7678],
      [110.3712, -7.7673],
      [110.3712, -7.7669],
      [110.3714, -7.7665],
      [110.3717, -7.7663],
      [110.3721, -7.7665],
      [110.3725, -7.767],
      [110.3727, -7.7672],
      [110.3732, -7.7672],
      [110.3736, -7.7672],
      [110.3739, -7.7669],
      [110.3742, -7.7668],
      [110.3749, -7.7668],
      [110.3754, -7.7665],
      [110.3755, -7.7657],
      [110.3756, -7.7648],
      [110.3757, -7.7646],
      [110.3765, -7.7632],
      [110.3774, -7.7622],
      [110.38, -7.7625],
      [110.3812, -7.7599],
      [110.3817, -7.7585],
      [110.3774, -7.7568],
      [110.3773, -7.7564],
      [110.3776, -7.755],
      [110.3781, -7.753],
      [110.3783, -7.7519],
      [110.3794, -7.7512],
      [110.382, -7.7516],
      [110.3814, -7.7535],
      [110.3817, -7.7537],
      [110.3828, -7.7538],
      [110.384, -7.7539],
      [110.3855, -7.7515],
      [110.3863, -7.75],
      [110.3872, -7.7485],
      [110.3848, -7.7475],
      [110.3839, -7.7473],
      [110.384, -7.7448],
      [110.3834, -7.7447],
      [110.3823, -7.7444],
      [110.3808, -7.7438]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MLATI',
    village: 'SENDANGADI',
    border: [
      [110.3683, -7.7202],
      [110.3681, -7.7201],
      [110.3679, -7.7197],
      [110.3679, -7.719],
      [110.3671, -7.7192],
      [110.3667, -7.7192],
      [110.3664, -7.7197],
      [110.3663, -7.7201],
      [110.3663, -7.7207],
      [110.3662, -7.7208],
      [110.3659, -7.721],
      [110.3655, -7.7211],
      [110.3653, -7.7211],
      [110.3651, -7.7216],
      [110.3651, -7.7223],
      [110.365, -7.7229],
      [110.3649, -7.7236],
      [110.3644, -7.7242],
      [110.3642, -7.7245],
      [110.364, -7.7247],
      [110.3637, -7.7247],
      [110.3632, -7.7242],
      [110.3629, -7.7241],
      [110.3622, -7.7241],
      [110.3619, -7.7235],
      [110.3613, -7.7231],
      [110.3607, -7.7232],
      [110.3603, -7.7229],
      [110.3595, -7.7224],
      [110.3589, -7.7222],
      [110.3583, -7.7221],
      [110.3581, -7.7226],
      [110.358, -7.7231],
      [110.3579, -7.7236],
      [110.3576, -7.7242],
      [110.3571, -7.7252],
      [110.356, -7.7273],
      [110.3555, -7.7286],
      [110.3549, -7.7283],
      [110.354, -7.7279],
      [110.3543, -7.7272],
      [110.354, -7.7269],
      [110.3536, -7.7267],
      [110.3534, -7.7265],
      [110.3532, -7.726],
      [110.3535, -7.7254],
      [110.3538, -7.725],
      [110.3544, -7.7243],
      [110.3549, -7.7236],
      [110.3553, -7.7232],
      [110.3555, -7.7227],
      [110.3556, -7.7221],
      [110.3556, -7.7219],
      [110.3556, -7.7216],
      [110.3553, -7.7215],
      [110.3536, -7.7213],
      [110.3519, -7.7214],
      [110.351, -7.7214],
      [110.3507, -7.7215],
      [110.3502, -7.7214],
      [110.3501, -7.7213],
      [110.35, -7.7217],
      [110.3498, -7.7219],
      [110.3496, -7.7225],
      [110.3501, -7.7239],
      [110.3505, -7.7244],
      [110.3513, -7.7248],
      [110.3516, -7.7253],
      [110.3521, -7.7258],
      [110.3525, -7.7261],
      [110.3526, -7.7262],
      [110.3527, -7.7264],
      [110.3526, -7.7266],
      [110.3521, -7.7269],
      [110.3517, -7.7272],
      [110.3516, -7.7293],
      [110.3515, -7.7301],
      [110.3509, -7.731],
      [110.3508, -7.7321],
      [110.3504, -7.7326],
      [110.3496, -7.7332],
      [110.3493, -7.7338],
      [110.3493, -7.7342],
      [110.3495, -7.7347],
      [110.3499, -7.7353],
      [110.3504, -7.7358],
      [110.351, -7.7363],
      [110.3524, -7.737],
      [110.3532, -7.7375],
      [110.3542, -7.738],
      [110.3544, -7.7384],
      [110.3545, -7.7385],
      [110.3545, -7.7391],
      [110.3547, -7.7395],
      [110.3551, -7.7403],
      [110.3551, -7.7408],
      [110.3549, -7.7417],
      [110.355, -7.742],
      [110.355, -7.7425],
      [110.3548, -7.7429],
      [110.3543, -7.7434],
      [110.3542, -7.744],
      [110.3541, -7.7445],
      [110.3541, -7.7449],
      [110.354, -7.7451],
      [110.3537, -7.7452],
      [110.3538, -7.7457],
      [110.3536, -7.746],
      [110.3534, -7.7463],
      [110.3533, -7.7466],
      [110.3549, -7.7472],
      [110.3545, -7.7488],
      [110.3551, -7.7488],
      [110.3559, -7.7487],
      [110.3564, -7.7486],
      [110.3567, -7.7482],
      [110.3572, -7.7482],
      [110.3576, -7.7483],
      [110.3579, -7.7494],
      [110.3582, -7.7494],
      [110.3587, -7.7491],
      [110.3594, -7.7488],
      [110.3598, -7.7486],
      [110.3602, -7.7486],
      [110.3604, -7.7488],
      [110.361, -7.749],
      [110.3614, -7.749],
      [110.3624, -7.7487],
      [110.3625, -7.7476],
      [110.3627, -7.7449],
      [110.3628, -7.7448],
      [110.3633, -7.7447],
      [110.3638, -7.7446],
      [110.3645, -7.745],
      [110.365, -7.7447],
      [110.3651, -7.7444],
      [110.3653, -7.744],
      [110.3657, -7.7439],
      [110.3662, -7.7433],
      [110.3662, -7.7427],
      [110.3666, -7.7423],
      [110.3671, -7.742],
      [110.3675, -7.7415],
      [110.3677, -7.741],
      [110.3679, -7.7405],
      [110.3676, -7.74],
      [110.3672, -7.7398],
      [110.367, -7.7397],
      [110.3669, -7.7394],
      [110.3671, -7.7392],
      [110.3674, -7.739],
      [110.3678, -7.7386],
      [110.3681, -7.7384],
      [110.3687, -7.7383],
      [110.3691, -7.7383],
      [110.3695, -7.7381],
      [110.37, -7.7377],
      [110.3704, -7.7375],
      [110.3706, -7.7371],
      [110.3711, -7.737],
      [110.3714, -7.7366],
      [110.3719, -7.7365],
      [110.3724, -7.7365],
      [110.3727, -7.7365],
      [110.3731, -7.7364],
      [110.3734, -7.7362],
      [110.3736, -7.7358],
      [110.3742, -7.7355],
      [110.3748, -7.735],
      [110.3753, -7.7348],
      [110.3755, -7.7342],
      [110.3754, -7.7336],
      [110.3756, -7.7325],
      [110.3758, -7.7318],
      [110.3759, -7.7311],
      [110.3759, -7.7308],
      [110.3758, -7.7296],
      [110.3755, -7.7294],
      [110.3751, -7.7291],
      [110.3754, -7.7285],
      [110.3754, -7.7281],
      [110.375, -7.7278],
      [110.3744, -7.7277],
      [110.3729, -7.7277],
      [110.3722, -7.728],
      [110.372, -7.7283],
      [110.3714, -7.7284],
      [110.3713, -7.7281],
      [110.3715, -7.7276],
      [110.3716, -7.7271],
      [110.3716, -7.7268],
      [110.3714, -7.7265],
      [110.3716, -7.726],
      [110.3721, -7.7254],
      [110.3725, -7.7247],
      [110.3727, -7.7242],
      [110.373, -7.7236],
      [110.3731, -7.7232],
      [110.3734, -7.7227],
      [110.3735, -7.7223],
      [110.3733, -7.7219],
      [110.3729, -7.7217],
      [110.3725, -7.7215],
      [110.3717, -7.7216],
      [110.3715, -7.7218],
      [110.3711, -7.7221],
      [110.3709, -7.722],
      [110.3703, -7.7215],
      [110.3699, -7.7212],
      [110.3691, -7.721],
      [110.3686, -7.7208],
      [110.3683, -7.7206],
      [110.3683, -7.7202]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MLATI',
    village: 'TLOGOADI',
    border: [
      [110.3501, -7.7213],
      [110.3499, -7.7212],
      [110.3495, -7.7209],
      [110.3494, -7.7207],
      [110.3491, -7.7203],
      [110.3488, -7.7201],
      [110.3483, -7.7204],
      [110.3478, -7.7203],
      [110.3473, -7.7207],
      [110.3469, -7.721],
      [110.3465, -7.7214],
      [110.3463, -7.722],
      [110.3461, -7.7223],
      [110.3462, -7.7228],
      [110.3456, -7.7226],
      [110.3449, -7.7224],
      [110.3447, -7.7223],
      [110.3447, -7.7217],
      [110.3446, -7.721],
      [110.3449, -7.7207],
      [110.3449, -7.7205],
      [110.3446, -7.7201],
      [110.3444, -7.7198],
      [110.3447, -7.7183],
      [110.3448, -7.7174],
      [110.3451, -7.7159],
      [110.3454, -7.7154],
      [110.345, -7.715],
      [110.345, -7.7147],
      [110.3449, -7.7143],
      [110.3448, -7.7139],
      [110.3443, -7.7138],
      [110.3441, -7.7138],
      [110.3439, -7.7133],
      [110.3436, -7.7133],
      [110.3431, -7.7138],
      [110.3424, -7.7145],
      [110.3421, -7.7147],
      [110.3421, -7.7149],
      [110.3423, -7.7154],
      [110.3421, -7.716],
      [110.3431, -7.7161],
      [110.343, -7.7166],
      [110.3425, -7.717],
      [110.3416, -7.7173],
      [110.3411, -7.7175],
      [110.3399, -7.7186],
      [110.3396, -7.7197],
      [110.3395, -7.7201],
      [110.3401, -7.7205],
      [110.3397, -7.7214],
      [110.3407, -7.7218],
      [110.3416, -7.7217],
      [110.3419, -7.7212],
      [110.3424, -7.7216],
      [110.3419, -7.7227],
      [110.3425, -7.723],
      [110.3423, -7.7235],
      [110.3426, -7.7245],
      [110.3416, -7.7242],
      [110.3404, -7.7245],
      [110.3385, -7.7241],
      [110.3383, -7.7247],
      [110.3384, -7.7252],
      [110.3383, -7.7259],
      [110.3383, -7.7265],
      [110.3376, -7.7292],
      [110.3369, -7.7292],
      [110.3359, -7.7323],
      [110.3358, -7.7326],
      [110.3352, -7.7327],
      [110.3338, -7.7321],
      [110.3322, -7.7317],
      [110.3308, -7.7312],
      [110.3297, -7.7306],
      [110.3294, -7.7315],
      [110.3292, -7.7318],
      [110.3293, -7.7321],
      [110.3285, -7.7334],
      [110.3284, -7.7337],
      [110.3279, -7.7337],
      [110.3276, -7.7337],
      [110.3274, -7.7344],
      [110.3273, -7.7347],
      [110.3272, -7.735],
      [110.3274, -7.7356],
      [110.3279, -7.7351],
      [110.3282, -7.7348],
      [110.3286, -7.735],
      [110.3289, -7.7354],
      [110.3292, -7.7362],
      [110.3296, -7.7369],
      [110.33, -7.7374],
      [110.33, -7.7368],
      [110.3302, -7.7365],
      [110.3305, -7.7366],
      [110.3308, -7.7368],
      [110.3309, -7.7376],
      [110.331, -7.7385],
      [110.3324, -7.7401],
      [110.3337, -7.7413],
      [110.3334, -7.7419],
      [110.3329, -7.7425],
      [110.3325, -7.7425],
      [110.3326, -7.743],
      [110.3325, -7.7434],
      [110.3326, -7.7441],
      [110.3323, -7.7443],
      [110.3317, -7.7446],
      [110.3318, -7.745],
      [110.3316, -7.7456],
      [110.3313, -7.7458],
      [110.331, -7.7464],
      [110.331, -7.7466],
      [110.3318, -7.7468],
      [110.3325, -7.747],
      [110.3323, -7.7484],
      [110.3337, -7.7485],
      [110.3338, -7.7479],
      [110.334, -7.7477],
      [110.3351, -7.7477],
      [110.3362, -7.7482],
      [110.3366, -7.7482],
      [110.3368, -7.7482],
      [110.3369, -7.748],
      [110.3371, -7.7474],
      [110.3372, -7.7471],
      [110.3373, -7.747],
      [110.3379, -7.7468],
      [110.3384, -7.7466],
      [110.3388, -7.7464],
      [110.3392, -7.7464],
      [110.3395, -7.7467],
      [110.3399, -7.7468],
      [110.3405, -7.7467],
      [110.3409, -7.7465],
      [110.3412, -7.7461],
      [110.3415, -7.7453],
      [110.3418, -7.7445],
      [110.3424, -7.7439],
      [110.3427, -7.7436],
      [110.3434, -7.7434],
      [110.3449, -7.7429],
      [110.3452, -7.7427],
      [110.3454, -7.7426],
      [110.3454, -7.7425],
      [110.3455, -7.7423],
      [110.3455, -7.7417],
      [110.3454, -7.7411],
      [110.3449, -7.7402],
      [110.3448, -7.7393],
      [110.345, -7.7391],
      [110.3451, -7.739],
      [110.3455, -7.7387],
      [110.3457, -7.7386],
      [110.3458, -7.7386],
      [110.346, -7.738],
      [110.3465, -7.7375],
      [110.3473, -7.7368],
      [110.3474, -7.7367],
      [110.348, -7.7361],
      [110.349, -7.7356],
      [110.3493, -7.7349],
      [110.3495, -7.7347],
      [110.3493, -7.7342],
      [110.3493, -7.7338],
      [110.3496, -7.7332],
      [110.3504, -7.7326],
      [110.3508, -7.7321],
      [110.3509, -7.731],
      [110.3515, -7.7301],
      [110.3516, -7.7293],
      [110.3517, -7.7272],
      [110.3521, -7.7269],
      [110.3526, -7.7266],
      [110.3527, -7.7264],
      [110.3526, -7.7262],
      [110.3525, -7.7261],
      [110.3521, -7.7258],
      [110.3516, -7.7253],
      [110.3513, -7.7248],
      [110.3505, -7.7244],
      [110.3501, -7.7239],
      [110.3496, -7.7225],
      [110.3498, -7.7219],
      [110.35, -7.7217],
      [110.3501, -7.7213]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MLATI',
    village: 'TIRTOADI',
    border: [
      [110.3182, -7.7305],
      [110.3181, -7.7307],
      [110.3175, -7.7303],
      [110.3176, -7.7305],
      [110.3176, -7.7307],
      [110.317, -7.7318],
      [110.3164, -7.7326],
      [110.316, -7.7326],
      [110.3151, -7.7341],
      [110.3146, -7.7338],
      [110.3141, -7.734],
      [110.3136, -7.7342],
      [110.3133, -7.7345],
      [110.3132, -7.7353],
      [110.3129, -7.7357],
      [110.3127, -7.736],
      [110.3122, -7.736],
      [110.3117, -7.7358],
      [110.3117, -7.7361],
      [110.3114, -7.7365],
      [110.3112, -7.7367],
      [110.3112, -7.7371],
      [110.3111, -7.7376],
      [110.3109, -7.7379],
      [110.3093, -7.7375],
      [110.3091, -7.7372],
      [110.3089, -7.7372],
      [110.309, -7.7365],
      [110.3093, -7.7354],
      [110.3094, -7.7353],
      [110.3083, -7.735],
      [110.3079, -7.7365],
      [110.3075, -7.7374],
      [110.3074, -7.7376],
      [110.3071, -7.7381],
      [110.3068, -7.7381],
      [110.3065, -7.7376],
      [110.3065, -7.7372],
      [110.3066, -7.7367],
      [110.3065, -7.7366],
      [110.3065, -7.7363],
      [110.3062, -7.736],
      [110.306, -7.7358],
      [110.3059, -7.7356],
      [110.3055, -7.7358],
      [110.3051, -7.736],
      [110.305, -7.7364],
      [110.3051, -7.7369],
      [110.3051, -7.7371],
      [110.3049, -7.7373],
      [110.3052, -7.7381],
      [110.3052, -7.7383],
      [110.3052, -7.7386],
      [110.3048, -7.739],
      [110.3045, -7.7394],
      [110.3046, -7.7398],
      [110.3051, -7.7407],
      [110.305, -7.7412],
      [110.3054, -7.7415],
      [110.3058, -7.7416],
      [110.3063, -7.7416],
      [110.3069, -7.7416],
      [110.307, -7.7411],
      [110.3078, -7.7412],
      [110.3078, -7.7415],
      [110.3082, -7.7416],
      [110.3083, -7.7418],
      [110.3082, -7.7422],
      [110.3081, -7.7428],
      [110.3078, -7.7431],
      [110.3078, -7.7438],
      [110.3077, -7.7444],
      [110.3078, -7.745],
      [110.3082, -7.7449],
      [110.3088, -7.7449],
      [110.3086, -7.746],
      [110.3085, -7.7468],
      [110.3086, -7.7469],
      [110.3088, -7.7469],
      [110.3091, -7.7465],
      [110.3096, -7.7466],
      [110.3098, -7.7466],
      [110.31, -7.7467],
      [110.3103, -7.7464],
      [110.3105, -7.7459],
      [110.311, -7.7454],
      [110.3116, -7.7448],
      [110.3119, -7.7444],
      [110.3124, -7.744],
      [110.3128, -7.744],
      [110.3131, -7.7441],
      [110.3133, -7.7444],
      [110.3138, -7.7444],
      [110.3141, -7.7446],
      [110.3144, -7.745],
      [110.3147, -7.7451],
      [110.3151, -7.745],
      [110.3152, -7.7451],
      [110.3153, -7.7452],
      [110.3155, -7.7455],
      [110.3157, -7.7459],
      [110.3159, -7.7463],
      [110.3166, -7.7468],
      [110.3177, -7.7473],
      [110.3183, -7.7473],
      [110.3183, -7.747],
      [110.3182, -7.7465],
      [110.3184, -7.7465],
      [110.3188, -7.7465],
      [110.3195, -7.7465],
      [110.3198, -7.7467],
      [110.3204, -7.7469],
      [110.3205, -7.7469],
      [110.3208, -7.7469],
      [110.3211, -7.7464],
      [110.3221, -7.7466],
      [110.3225, -7.745],
      [110.3228, -7.7449],
      [110.3232, -7.7441],
      [110.3234, -7.7441],
      [110.3235, -7.7435],
      [110.3237, -7.7435],
      [110.3237, -7.7438],
      [110.3236, -7.744],
      [110.3235, -7.7443],
      [110.3233, -7.7453],
      [110.323, -7.7464],
      [110.3229, -7.747],
      [110.3233, -7.7471],
      [110.3235, -7.7469],
      [110.3239, -7.7467],
      [110.3242, -7.7467],
      [110.3245, -7.7468],
      [110.3246, -7.7473],
      [110.3246, -7.7477],
      [110.3246, -7.748],
      [110.3248, -7.7486],
      [110.3248, -7.7487],
      [110.3245, -7.7491],
      [110.3244, -7.7494],
      [110.3246, -7.7498],
      [110.3247, -7.7501],
      [110.3245, -7.7507],
      [110.3242, -7.751],
      [110.3241, -7.7513],
      [110.3244, -7.7515],
      [110.3247, -7.7517],
      [110.3247, -7.7521],
      [110.3245, -7.7524],
      [110.3242, -7.7531],
      [110.3237, -7.7538],
      [110.3232, -7.7543],
      [110.3229, -7.7547],
      [110.3228, -7.7552],
      [110.3225, -7.7555],
      [110.3223, -7.7557],
      [110.3224, -7.7559],
      [110.3225, -7.7561],
      [110.3223, -7.7567],
      [110.3223, -7.7571],
      [110.3225, -7.7578],
      [110.3223, -7.7581],
      [110.3226, -7.7587],
      [110.3228, -7.7598],
      [110.3231, -7.7605],
      [110.3234, -7.761],
      [110.3236, -7.7612],
      [110.3237, -7.7615],
      [110.324, -7.7624],
      [110.3245, -7.7629],
      [110.3245, -7.7631],
      [110.3247, -7.7636],
      [110.3247, -7.7641],
      [110.325, -7.7643],
      [110.3257, -7.7649],
      [110.3262, -7.7653],
      [110.3266, -7.7654],
      [110.3271, -7.7654],
      [110.3277, -7.7651],
      [110.329, -7.7645],
      [110.3287, -7.7637],
      [110.3287, -7.7636],
      [110.3289, -7.7627],
      [110.3292, -7.7615],
      [110.3292, -7.7614],
      [110.3293, -7.7611],
      [110.3294, -7.7606],
      [110.3298, -7.7602],
      [110.33, -7.76],
      [110.3304, -7.7592],
      [110.3305, -7.7586],
      [110.3305, -7.7576],
      [110.3307, -7.7574],
      [110.3309, -7.7573],
      [110.3313, -7.757],
      [110.3315, -7.7569],
      [110.3321, -7.7571],
      [110.3322, -7.7574],
      [110.3323, -7.7576],
      [110.3325, -7.7578],
      [110.3326, -7.7577],
      [110.3326, -7.7575],
      [110.3327, -7.7571],
      [110.3323, -7.7566],
      [110.3325, -7.756],
      [110.3328, -7.7555],
      [110.3329, -7.7552],
      [110.333, -7.7548],
      [110.3335, -7.7548],
      [110.3338, -7.7546],
      [110.3338, -7.7546],
      [110.3339, -7.7544],
      [110.334, -7.7542],
      [110.334, -7.754],
      [110.334, -7.7536],
      [110.3339, -7.7533],
      [110.3336, -7.7529],
      [110.3337, -7.7526],
      [110.334, -7.7524],
      [110.3342, -7.7523],
      [110.3347, -7.7521],
      [110.335, -7.752],
      [110.3358, -7.7516],
      [110.3367, -7.7513],
      [110.3368, -7.751],
      [110.3368, -7.7508],
      [110.3365, -7.7504],
      [110.3365, -7.7501],
      [110.3366, -7.7496],
      [110.3366, -7.7491],
      [110.3366, -7.7488],
      [110.3366, -7.7487],
      [110.3368, -7.7482],
      [110.3366, -7.7482],
      [110.3362, -7.7482],
      [110.3351, -7.7477],
      [110.334, -7.7477],
      [110.3338, -7.7479],
      [110.3337, -7.7485],
      [110.3323, -7.7484],
      [110.3325, -7.747],
      [110.3318, -7.7468],
      [110.331, -7.7466],
      [110.331, -7.7464],
      [110.3313, -7.7458],
      [110.3316, -7.7456],
      [110.3318, -7.745],
      [110.3317, -7.7446],
      [110.3323, -7.7443],
      [110.3326, -7.7441],
      [110.3325, -7.7434],
      [110.3326, -7.743],
      [110.3325, -7.7425],
      [110.3329, -7.7425],
      [110.3334, -7.7419],
      [110.3337, -7.7413],
      [110.3324, -7.7401],
      [110.331, -7.7385],
      [110.3309, -7.7376],
      [110.3308, -7.7368],
      [110.3305, -7.7366],
      [110.3302, -7.7365],
      [110.33, -7.7368],
      [110.33, -7.7374],
      [110.3296, -7.7369],
      [110.3292, -7.7362],
      [110.3289, -7.7354],
      [110.3286, -7.735],
      [110.3282, -7.7348],
      [110.3279, -7.7351],
      [110.3274, -7.7356],
      [110.3272, -7.735],
      [110.3268, -7.7351],
      [110.3263, -7.735],
      [110.3255, -7.7358],
      [110.3253, -7.7358],
      [110.3249, -7.7358],
      [110.3246, -7.7364],
      [110.3242, -7.7368],
      [110.3245, -7.7377],
      [110.3246, -7.7378],
      [110.3247, -7.738],
      [110.3244, -7.7385],
      [110.3242, -7.7389],
      [110.3213, -7.7376],
      [110.3205, -7.7372],
      [110.3195, -7.7368],
      [110.3194, -7.7366],
      [110.3195, -7.7361],
      [110.3195, -7.736],
      [110.3194, -7.7357],
      [110.3196, -7.7357],
      [110.3198, -7.7357],
      [110.32, -7.7353],
      [110.3202, -7.7347],
      [110.3204, -7.7344],
      [110.3208, -7.7342],
      [110.3208, -7.7338],
      [110.3197, -7.7334],
      [110.3193, -7.7336],
      [110.3185, -7.7334],
      [110.3178, -7.7331],
      [110.3181, -7.7318],
      [110.3184, -7.7307],
      [110.3182, -7.7305]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'MLATI',
    village: 'SUMBERADI',
    border: [
      [110.3317, -7.7004],
      [110.3315, -7.7004],
      [110.3312, -7.7008],
      [110.3307, -7.7011],
      [110.3305, -7.7013],
      [110.3305, -7.7017],
      [110.3302, -7.7022],
      [110.3298, -7.7027],
      [110.3294, -7.7032],
      [110.3294, -7.7036],
      [110.3296, -7.7045],
      [110.3296, -7.7049],
      [110.3291, -7.705],
      [110.3281, -7.7045],
      [110.3276, -7.7042],
      [110.3266, -7.7036],
      [110.3264, -7.7034],
      [110.3265, -7.7032],
      [110.3266, -7.7029],
      [110.3259, -7.7024],
      [110.3258, -7.702],
      [110.3252, -7.7017],
      [110.3252, -7.7018],
      [110.3252, -7.7034],
      [110.3251, -7.7036],
      [110.3246, -7.704],
      [110.3243, -7.7044],
      [110.3242, -7.7046],
      [110.3245, -7.7048],
      [110.325, -7.7049],
      [110.3257, -7.7052],
      [110.3257, -7.7065],
      [110.3261, -7.7068],
      [110.3254, -7.7078],
      [110.3251, -7.7076],
      [110.3246, -7.7074],
      [110.3243, -7.7073],
      [110.3238, -7.7071],
      [110.3236, -7.7077],
      [110.3236, -7.7079],
      [110.3237, -7.7081],
      [110.3237, -7.7082],
      [110.3235, -7.7085],
      [110.3229, -7.709],
      [110.3226, -7.7093],
      [110.3223, -7.7097],
      [110.3223, -7.7101],
      [110.3221, -7.7104],
      [110.3225, -7.7104],
      [110.3225, -7.7111],
      [110.3226, -7.7116],
      [110.3233, -7.7119],
      [110.3235, -7.7126],
      [110.3239, -7.7134],
      [110.3237, -7.7141],
      [110.3234, -7.7148],
      [110.3231, -7.7152],
      [110.3231, -7.7154],
      [110.3235, -7.7155],
      [110.3235, -7.7161],
      [110.3243, -7.7166],
      [110.3244, -7.7168],
      [110.3244, -7.7174],
      [110.3242, -7.7184],
      [110.3242, -7.7187],
      [110.3235, -7.719],
      [110.3235, -7.7197],
      [110.3231, -7.7208],
      [110.3229, -7.7218],
      [110.3228, -7.7223],
      [110.3228, -7.7226],
      [110.3225, -7.7233],
      [110.3223, -7.7244],
      [110.3206, -7.7239],
      [110.3199, -7.7235],
      [110.3196, -7.7251],
      [110.3195, -7.7264],
      [110.3193, -7.7278],
      [110.3191, -7.7288],
      [110.3189, -7.7292],
      [110.3184, -7.7296],
      [110.3185, -7.73],
      [110.3182, -7.7305],
      [110.3184, -7.7307],
      [110.3181, -7.7318],
      [110.3178, -7.7331],
      [110.3185, -7.7334],
      [110.3193, -7.7336],
      [110.3197, -7.7334],
      [110.3208, -7.7338],
      [110.3208, -7.7342],
      [110.3204, -7.7344],
      [110.3202, -7.7347],
      [110.32, -7.7353],
      [110.3198, -7.7357],
      [110.3196, -7.7357],
      [110.3194, -7.7357],
      [110.3195, -7.736],
      [110.3195, -7.7361],
      [110.3194, -7.7366],
      [110.3195, -7.7368],
      [110.3205, -7.7372],
      [110.3213, -7.7376],
      [110.3242, -7.7389],
      [110.3244, -7.7385],
      [110.3247, -7.738],
      [110.3246, -7.7378],
      [110.3245, -7.7377],
      [110.3242, -7.7368],
      [110.3246, -7.7364],
      [110.3249, -7.7358],
      [110.3253, -7.7358],
      [110.3255, -7.7358],
      [110.3263, -7.735],
      [110.3268, -7.7351],
      [110.3272, -7.735],
      [110.3273, -7.7347],
      [110.3274, -7.7344],
      [110.3276, -7.7337],
      [110.3279, -7.7337],
      [110.3284, -7.7337],
      [110.3285, -7.7334],
      [110.3293, -7.7321],
      [110.3292, -7.7318],
      [110.3294, -7.7315],
      [110.3297, -7.7306],
      [110.3308, -7.7312],
      [110.3322, -7.7317],
      [110.3338, -7.7321],
      [110.3352, -7.7327],
      [110.3358, -7.7326],
      [110.3359, -7.7323],
      [110.3369, -7.7292],
      [110.3376, -7.7292],
      [110.3383, -7.7265],
      [110.3383, -7.7259],
      [110.3384, -7.7252],
      [110.3383, -7.7247],
      [110.3385, -7.7241],
      [110.3404, -7.7245],
      [110.3416, -7.7242],
      [110.3426, -7.7245],
      [110.3423, -7.7235],
      [110.3425, -7.723],
      [110.3419, -7.7227],
      [110.3424, -7.7216],
      [110.3419, -7.7212],
      [110.3416, -7.7217],
      [110.3407, -7.7218],
      [110.3397, -7.7214],
      [110.3401, -7.7205],
      [110.3395, -7.7201],
      [110.3396, -7.7197],
      [110.3399, -7.7186],
      [110.3411, -7.7175],
      [110.3416, -7.7173],
      [110.3425, -7.717],
      [110.343, -7.7166],
      [110.3431, -7.7161],
      [110.3421, -7.716],
      [110.3423, -7.7154],
      [110.3421, -7.7149],
      [110.342, -7.7147],
      [110.3419, -7.7146],
      [110.3416, -7.7143],
      [110.3412, -7.7137],
      [110.3407, -7.7133],
      [110.3402, -7.7132],
      [110.3399, -7.7126],
      [110.3398, -7.7119],
      [110.3397, -7.7117],
      [110.3399, -7.7111],
      [110.3401, -7.7107],
      [110.3402, -7.7106],
      [110.3403, -7.7104],
      [110.3403, -7.7097],
      [110.3404, -7.7092],
      [110.3403, -7.7087],
      [110.3398, -7.7077],
      [110.3397, -7.7074],
      [110.3394, -7.7066],
      [110.3391, -7.7062],
      [110.3383, -7.7066],
      [110.3375, -7.7066],
      [110.3372, -7.7067],
      [110.337, -7.7069],
      [110.3366, -7.7068],
      [110.3363, -7.7067],
      [110.3361, -7.707],
      [110.3359, -7.7075],
      [110.3358, -7.7081],
      [110.3353, -7.7078],
      [110.3346, -7.7073],
      [110.3343, -7.7076],
      [110.334, -7.7079],
      [110.334, -7.7087],
      [110.3337, -7.7096],
      [110.3336, -7.7101],
      [110.3333, -7.7106],
      [110.3327, -7.7105],
      [110.3322, -7.7104],
      [110.3319, -7.7102],
      [110.3316, -7.7099],
      [110.3319, -7.7094],
      [110.3322, -7.7087],
      [110.3329, -7.7073],
      [110.3331, -7.7069],
      [110.3333, -7.7062],
      [110.3335, -7.7053],
      [110.3337, -7.7046],
      [110.334, -7.704],
      [110.3341, -7.7031],
      [110.3344, -7.7025],
      [110.3345, -7.7021],
      [110.3347, -7.7012],
      [110.3337, -7.7009],
      [110.3327, -7.7007],
      [110.3317, -7.7004]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'DEPOK',
    village: 'CATURTUNGGAL',
    border: [
      [110.3855, -7.7515],
      [110.384, -7.7539],
      [110.3828, -7.7538],
      [110.3817, -7.7537],
      [110.3814, -7.7535],
      [110.382, -7.7516],
      [110.3794, -7.7512],
      [110.3783, -7.7519],
      [110.3781, -7.753],
      [110.3776, -7.755],
      [110.3773, -7.7564],
      [110.3774, -7.7568],
      [110.3817, -7.7585],
      [110.3812, -7.7599],
      [110.38, -7.7625],
      [110.3774, -7.7622],
      [110.3765, -7.7632],
      [110.3757, -7.7646],
      [110.3756, -7.7648],
      [110.3755, -7.7657],
      [110.3754, -7.7665],
      [110.3749, -7.7668],
      [110.3742, -7.7668],
      [110.3739, -7.7669],
      [110.3736, -7.7672],
      [110.3732, -7.7672],
      [110.3727, -7.7672],
      [110.3725, -7.767],
      [110.3721, -7.7665],
      [110.3717, -7.7663],
      [110.3714, -7.7665],
      [110.3712, -7.7669],
      [110.3712, -7.7673],
      [110.3711, -7.7678],
      [110.3708, -7.768],
      [110.3706, -7.7683],
      [110.3704, -7.7688],
      [110.3701, -7.7697],
      [110.3695, -7.7697],
      [110.3691, -7.77],
      [110.3691, -7.7702],
      [110.3691, -7.7705],
      [110.3691, -7.771],
      [110.3691, -7.7711],
      [110.3691, -7.7714],
      [110.3691, -7.7717],
      [110.3692, -7.7723],
      [110.3692, -7.7728],
      [110.3692, -7.7732],
      [110.3692, -7.7733],
      [110.3693, -7.7734],
      [110.3693, -7.7738],
      [110.3693, -7.7738],
      [110.3693, -7.7745],
      [110.3699, -7.7749],
      [110.37, -7.7751],
      [110.3702, -7.7752],
      [110.3709, -7.7752],
      [110.3714, -7.7753],
      [110.3719, -7.7754],
      [110.3726, -7.7757],
      [110.3728, -7.7757],
      [110.3728, -7.7756],
      [110.3733, -7.7758],
      [110.3741, -7.7761],
      [110.3751, -7.7763],
      [110.3756, -7.7765],
      [110.3769, -7.7765],
      [110.3779, -7.7764],
      [110.3786, -7.7765],
      [110.3789, -7.7767],
      [110.3794, -7.7773],
      [110.3799, -7.7782],
      [110.3803, -7.7785],
      [110.3806, -7.7786],
      [110.3808, -7.7786],
      [110.3809, -7.7786],
      [110.3839, -7.7789],
      [110.3856, -7.7792],
      [110.3875, -7.7795],
      [110.3884, -7.7799],
      [110.3888, -7.7801],
      [110.3894, -7.7809],
      [110.3902, -7.7822],
      [110.3913, -7.7841],
      [110.3921, -7.7855],
      [110.3924, -7.7858],
      [110.3934, -7.7862],
      [110.3944, -7.7867],
      [110.3948, -7.787],
      [110.3952, -7.7876],
      [110.3954, -7.7881],
      [110.3959, -7.7885],
      [110.396, -7.7884],
      [110.3971, -7.7884],
      [110.3972, -7.7884],
      [110.3972, -7.7881],
      [110.3971, -7.7878],
      [110.3972, -7.787],
      [110.3971, -7.7864],
      [110.3978, -7.7864],
      [110.3981, -7.7863],
      [110.3982, -7.786],
      [110.3984, -7.7858],
      [110.3986, -7.7855],
      [110.3988, -7.7855],
      [110.399, -7.7857],
      [110.3992, -7.786],
      [110.3991, -7.7864],
      [110.3988, -7.7869],
      [110.3987, -7.7871],
      [110.3985, -7.7873],
      [110.3985, -7.7876],
      [110.3984, -7.788],
      [110.3984, -7.7883],
      [110.399, -7.7882],
      [110.4002, -7.7882],
      [110.4017, -7.788],
      [110.4037, -7.7879],
      [110.4039, -7.7869],
      [110.4055, -7.7871],
      [110.4057, -7.787],
      [110.4063, -7.7855],
      [110.4067, -7.7856],
      [110.4067, -7.7859],
      [110.4069, -7.7859],
      [110.4066, -7.7866],
      [110.4064, -7.7871],
      [110.4066, -7.7876],
      [110.4068, -7.7876],
      [110.4084, -7.7874],
      [110.4084, -7.7868],
      [110.4086, -7.7864],
      [110.4092, -7.7863],
      [110.4094, -7.7874],
      [110.4102, -7.7874],
      [110.4105, -7.7873],
      [110.4119, -7.7872],
      [110.4137, -7.7871],
      [110.4162, -7.7869],
      [110.4164, -7.7868],
      [110.4162, -7.7841],
      [110.4162, -7.7832],
      [110.4181, -7.7832],
      [110.4191, -7.7832],
      [110.4193, -7.7828],
      [110.4193, -7.7825],
      [110.4191, -7.782],
      [110.4191, -7.7814],
      [110.4195, -7.7805],
      [110.4198, -7.7799],
      [110.4198, -7.7796],
      [110.4194, -7.7788],
      [110.4194, -7.7784],
      [110.4197, -7.7777],
      [110.4197, -7.7773],
      [110.4196, -7.7772],
      [110.419, -7.7765],
      [110.4189, -7.7762],
      [110.419, -7.7758],
      [110.4192, -7.7756],
      [110.4194, -7.7753],
      [110.4193, -7.775],
      [110.418, -7.7725],
      [110.4178, -7.7717],
      [110.4179, -7.7713],
      [110.4182, -7.7711],
      [110.4186, -7.7709],
      [110.4189, -7.7703],
      [110.4188, -7.7694],
      [110.4187, -7.7689],
      [110.418, -7.7686],
      [110.4175, -7.7686],
      [110.4172, -7.769],
      [110.4167, -7.769],
      [110.4164, -7.769],
      [110.4161, -7.7687],
      [110.4156, -7.7689],
      [110.4152, -7.7687],
      [110.4151, -7.7682],
      [110.4151, -7.7681],
      [110.4152, -7.7678],
      [110.4152, -7.7676],
      [110.4152, -7.7672],
      [110.4149, -7.7667],
      [110.4149, -7.7663],
      [110.4151, -7.7659],
      [110.4154, -7.7655],
      [110.4152, -7.7655],
      [110.4146, -7.7653],
      [110.414, -7.7653],
      [110.4141, -7.765],
      [110.4141, -7.7648],
      [110.414, -7.7645],
      [110.4141, -7.7641],
      [110.414, -7.7641],
      [110.4138, -7.7643],
      [110.4135, -7.7642],
      [110.4132, -7.7649],
      [110.4125, -7.7647],
      [110.4126, -7.7642],
      [110.4123, -7.7641],
      [110.4118, -7.764],
      [110.4116, -7.764],
      [110.4114, -7.7642],
      [110.4112, -7.7643],
      [110.411, -7.7643],
      [110.4106, -7.7664],
      [110.4088, -7.7661],
      [110.4087, -7.7664],
      [110.4087, -7.7667],
      [110.4084, -7.7669],
      [110.4079, -7.7669],
      [110.4069, -7.7668],
      [110.4058, -7.7665],
      [110.4054, -7.7695],
      [110.4053, -7.7707],
      [110.4052, -7.7711],
      [110.4051, -7.7723],
      [110.405, -7.7738],
      [110.4048, -7.7742],
      [110.4042, -7.774],
      [110.4037, -7.7747],
      [110.4012, -7.7743],
      [110.3999, -7.774],
      [110.3996, -7.7733],
      [110.3994, -7.7732],
      [110.3991, -7.7735],
      [110.399, -7.7739],
      [110.399, -7.7743],
      [110.3989, -7.7748],
      [110.3986, -7.775],
      [110.3987, -7.7758],
      [110.399, -7.7761],
      [110.399, -7.7762],
      [110.3981, -7.7762],
      [110.3977, -7.7758],
      [110.3974, -7.7754],
      [110.3969, -7.7752],
      [110.3964, -7.7742],
      [110.3956, -7.7742],
      [110.3952, -7.774],
      [110.3951, -7.7737],
      [110.3956, -7.7733],
      [110.3957, -7.773],
      [110.3955, -7.7728],
      [110.3946, -7.7724],
      [110.394, -7.7713],
      [110.3935, -7.7704],
      [110.3932, -7.7702],
      [110.3932, -7.7691],
      [110.3928, -7.7686],
      [110.3928, -7.7683],
      [110.3931, -7.7679],
      [110.3934, -7.7671],
      [110.3931, -7.7662],
      [110.3929, -7.7661],
      [110.3927, -7.7657],
      [110.3927, -7.7654],
      [110.3929, -7.7652],
      [110.3934, -7.7649],
      [110.3937, -7.7643],
      [110.394, -7.7637],
      [110.394, -7.7635],
      [110.3938, -7.7634],
      [110.3933, -7.7644],
      [110.3926, -7.7641],
      [110.3923, -7.765],
      [110.3904, -7.7642],
      [110.3891, -7.7637],
      [110.3895, -7.7626],
      [110.3896, -7.7622],
      [110.3878, -7.7616],
      [110.3884, -7.7597],
      [110.3886, -7.7594],
      [110.3889, -7.7591],
      [110.3892, -7.7591],
      [110.3896, -7.7579],
      [110.3885, -7.7577],
      [110.3883, -7.7572],
      [110.3885, -7.7568],
      [110.3886, -7.7564],
      [110.3882, -7.7558],
      [110.3874, -7.7555],
      [110.3871, -7.7529],
      [110.387, -7.7515],
      [110.3855, -7.7515]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'DEPOK',
    village: 'MAGUWOHARJO',
    border: [
      [110.447, -7.7463],
      [110.4468, -7.7464],
      [110.4466, -7.7467],
      [110.4462, -7.7472],
      [110.446, -7.7471],
      [110.445, -7.7467],
      [110.4449, -7.747],
      [110.4446, -7.747],
      [110.4438, -7.7468],
      [110.4426, -7.7467],
      [110.4414, -7.7465],
      [110.4407, -7.7463],
      [110.4399, -7.7464],
      [110.4396, -7.746],
      [110.4395, -7.7457],
      [110.4396, -7.7452],
      [110.4395, -7.7451],
      [110.439, -7.7449],
      [110.4383, -7.7448],
      [110.4382, -7.7453],
      [110.4379, -7.7458],
      [110.4371, -7.7454],
      [110.4363, -7.745],
      [110.4366, -7.7445],
      [110.4367, -7.7437],
      [110.4362, -7.7436],
      [110.4353, -7.7436],
      [110.4343, -7.7434],
      [110.4344, -7.744],
      [110.435, -7.7441],
      [110.4352, -7.7449],
      [110.4355, -7.7457],
      [110.4354, -7.7461],
      [110.4346, -7.746],
      [110.433, -7.7458],
      [110.4329, -7.7462],
      [110.4329, -7.7466],
      [110.433, -7.7472],
      [110.4331, -7.7478],
      [110.4331, -7.7483],
      [110.4332, -7.7487],
      [110.4336, -7.7489],
      [110.4337, -7.7491],
      [110.4336, -7.7494],
      [110.4335, -7.7498],
      [110.4336, -7.7504],
      [110.4335, -7.7513],
      [110.4334, -7.7513],
      [110.432, -7.7513],
      [110.4302, -7.7513],
      [110.4295, -7.7514],
      [110.4288, -7.7515],
      [110.4276, -7.7512],
      [110.4274, -7.7518],
      [110.4274, -7.7525],
      [110.4272, -7.7528],
      [110.4269, -7.7532],
      [110.4246, -7.753],
      [110.4236, -7.7529],
      [110.4236, -7.7525],
      [110.4227, -7.7523],
      [110.4225, -7.7527],
      [110.4216, -7.7525],
      [110.4195, -7.7522],
      [110.4194, -7.7508],
      [110.4189, -7.7508],
      [110.4165, -7.7509],
      [110.4168, -7.7513],
      [110.4167, -7.7516],
      [110.4167, -7.7519],
      [110.4162, -7.7523],
      [110.416, -7.753],
      [110.4158, -7.7532],
      [110.4154, -7.7541],
      [110.4153, -7.7547],
      [110.4155, -7.7555],
      [110.4153, -7.7563],
      [110.4148, -7.7565],
      [110.4147, -7.7566],
      [110.4151, -7.7575],
      [110.4154, -7.7578],
      [110.4155, -7.7586],
      [110.4158, -7.759],
      [110.4163, -7.76],
      [110.4165, -7.7604],
      [110.4164, -7.7612],
      [110.4165, -7.7619],
      [110.4169, -7.7621],
      [110.4165, -7.7635],
      [110.4158, -7.765],
      [110.4155, -7.7654],
      [110.4154, -7.7655],
      [110.4151, -7.7659],
      [110.4149, -7.7663],
      [110.4149, -7.7667],
      [110.4152, -7.7672],
      [110.4152, -7.7676],
      [110.4152, -7.7678],
      [110.4151, -7.7681],
      [110.4151, -7.7682],
      [110.4152, -7.7687],
      [110.4156, -7.7689],
      [110.4161, -7.7687],
      [110.4164, -7.769],
      [110.4167, -7.769],
      [110.4172, -7.769],
      [110.4175, -7.7686],
      [110.418, -7.7686],
      [110.4187, -7.7689],
      [110.4188, -7.7694],
      [110.4189, -7.7703],
      [110.4186, -7.7709],
      [110.4182, -7.7711],
      [110.4179, -7.7713],
      [110.4178, -7.7717],
      [110.418, -7.7725],
      [110.4193, -7.775],
      [110.4194, -7.7753],
      [110.4192, -7.7756],
      [110.419, -7.7758],
      [110.4189, -7.7762],
      [110.419, -7.7765],
      [110.4196, -7.7772],
      [110.4197, -7.7773],
      [110.4197, -7.7777],
      [110.4194, -7.7784],
      [110.4194, -7.7788],
      [110.4198, -7.7796],
      [110.4198, -7.7799],
      [110.4195, -7.7805],
      [110.4191, -7.7814],
      [110.4191, -7.782],
      [110.4193, -7.7825],
      [110.4193, -7.7828],
      [110.4191, -7.7832],
      [110.4181, -7.7832],
      [110.4162, -7.7832],
      [110.4162, -7.7841],
      [110.4164, -7.7868],
      [110.4183, -7.7867],
      [110.4197, -7.7866],
      [110.4208, -7.7864],
      [110.421, -7.787],
      [110.4212, -7.7876],
      [110.4212, -7.788],
      [110.421, -7.7884],
      [110.4209, -7.7886],
      [110.421, -7.7889],
      [110.4211, -7.7891],
      [110.4211, -7.7893],
      [110.4209, -7.7896],
      [110.4207, -7.7898],
      [110.4206, -7.79],
      [110.4208, -7.7903],
      [110.4211, -7.7904],
      [110.4214, -7.7905],
      [110.4214, -7.7907],
      [110.4212, -7.7908],
      [110.4208, -7.791],
      [110.4205, -7.7911],
      [110.4203, -7.7914],
      [110.4203, -7.7917],
      [110.4204, -7.792],
      [110.4208, -7.7924],
      [110.4209, -7.7926],
      [110.4208, -7.7929],
      [110.4208, -7.7932],
      [110.421, -7.7935],
      [110.4215, -7.7942],
      [110.4215, -7.7945],
      [110.4215, -7.7949],
      [110.4217, -7.7951],
      [110.422, -7.7953],
      [110.4223, -7.7956],
      [110.4226, -7.796],
      [110.4229, -7.7963],
      [110.4228, -7.7969],
      [110.4227, -7.7974],
      [110.4225, -7.7977],
      [110.4223, -7.7981],
      [110.4223, -7.7985],
      [110.4224, -7.7986],
      [110.4225, -7.799],
      [110.4227, -7.7996],
      [110.4228, -7.8002],
      [110.4226, -7.8011],
      [110.4225, -7.8022],
      [110.4223, -7.8031],
      [110.4224, -7.8033],
      [110.4228, -7.8037],
      [110.4234, -7.8042],
      [110.4239, -7.8044],
      [110.4248, -7.8045],
      [110.4254, -7.8048],
      [110.426, -7.8051],
      [110.4268, -7.8054],
      [110.4276, -7.8054],
      [110.4285, -7.8054],
      [110.4285, -7.805],
      [110.4285, -7.8049],
      [110.4285, -7.8044],
      [110.4287, -7.8038],
      [110.429, -7.8035],
      [110.4325, -7.8037],
      [110.4354, -7.8041],
      [110.4378, -7.8042],
      [110.4378, -7.804],
      [110.4379, -7.8037],
      [110.4377, -7.8033],
      [110.438, -7.8029],
      [110.4384, -7.8025],
      [110.4392, -7.8021],
      [110.4395, -7.8013],
      [110.4396, -7.8012],
      [110.4397, -7.8011],
      [110.4397, -7.8008],
      [110.4397, -7.7998],
      [110.4397, -7.7996],
      [110.4393, -7.7995],
      [110.4388, -7.7991],
      [110.4386, -7.7984],
      [110.4388, -7.7977],
      [110.439, -7.7972],
      [110.4389, -7.7965],
      [110.4393, -7.796],
      [110.4391, -7.7947],
      [110.4387, -7.7942],
      [110.4383, -7.7938],
      [110.4379, -7.793],
      [110.438, -7.7928],
      [110.4384, -7.7928],
      [110.4388, -7.7928],
      [110.4393, -7.7926],
      [110.4397, -7.7922],
      [110.4399, -7.792],
      [110.4398, -7.7916],
      [110.4399, -7.7913],
      [110.4404, -7.7909],
      [110.4407, -7.7905],
      [110.441, -7.7903],
      [110.441, -7.7901],
      [110.4402, -7.7893],
      [110.4401, -7.789],
      [110.4399, -7.7882],
      [110.4398, -7.788],
      [110.4398, -7.7878],
      [110.44, -7.7871],
      [110.4404, -7.7864],
      [110.4399, -7.7857],
      [110.4393, -7.7852],
      [110.4393, -7.7846],
      [110.4393, -7.7834],
      [110.4389, -7.7821],
      [110.4385, -7.7812],
      [110.4386, -7.7807],
      [110.4388, -7.7801],
      [110.4388, -7.7796],
      [110.4388, -7.7788],
      [110.4388, -7.7787],
      [110.4389, -7.7781],
      [110.4394, -7.7773],
      [110.4392, -7.7769],
      [110.4391, -7.7763],
      [110.4394, -7.7758],
      [110.44, -7.7756],
      [110.4402, -7.7756],
      [110.4403, -7.7749],
      [110.4403, -7.7743],
      [110.4402, -7.7739],
      [110.44, -7.7736],
      [110.44, -7.773],
      [110.4401, -7.7726],
      [110.4398, -7.772],
      [110.4401, -7.7715],
      [110.4402, -7.7713],
      [110.4401, -7.7705],
      [110.4401, -7.77],
      [110.4401, -7.7698],
      [110.4404, -7.7691],
      [110.4405, -7.7684],
      [110.4406, -7.7677],
      [110.4411, -7.767],
      [110.4424, -7.7675],
      [110.4437, -7.7677],
      [110.4441, -7.7665],
      [110.4442, -7.766],
      [110.4441, -7.7652],
      [110.4438, -7.7644],
      [110.4433, -7.764],
      [110.4431, -7.7634],
      [110.4433, -7.7626],
      [110.4435, -7.762],
      [110.4436, -7.7611],
      [110.4436, -7.7604],
      [110.4439, -7.7598],
      [110.4444, -7.7594],
      [110.4448, -7.7591],
      [110.4449, -7.759],
      [110.4452, -7.7585],
      [110.4457, -7.758],
      [110.4463, -7.7574],
      [110.4467, -7.7572],
      [110.4469, -7.7567],
      [110.447, -7.7563],
      [110.4472, -7.7554],
      [110.4472, -7.7544],
      [110.4473, -7.7533],
      [110.4474, -7.7532],
      [110.4473, -7.7527],
      [110.4473, -7.7526],
      [110.4474, -7.7524],
      [110.4475, -7.7518],
      [110.4478, -7.7514],
      [110.4483, -7.7511],
      [110.4483, -7.751],
      [110.4484, -7.7508],
      [110.4482, -7.7503],
      [110.448, -7.7499],
      [110.4475, -7.7495],
      [110.4474, -7.7492],
      [110.4476, -7.7487],
      [110.4477, -7.7481],
      [110.4476, -7.7474],
      [110.4474, -7.7468],
      [110.447, -7.7463]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'DEPOK',
    village: 'CONDONGCATUR',
    border: [
      [110.4012, -7.7324],
      [110.4006, -7.7323],
      [110.3999, -7.7324],
      [110.3992, -7.7328],
      [110.3989, -7.7336],
      [110.398, -7.7357],
      [110.3977, -7.736],
      [110.3971, -7.7368],
      [110.3968, -7.7376],
      [110.3967, -7.7382],
      [110.3969, -7.7391],
      [110.3971, -7.74],
      [110.3972, -7.7414],
      [110.3972, -7.7429],
      [110.3969, -7.7448],
      [110.397, -7.7458],
      [110.3971, -7.7475],
      [110.3972, -7.75],
      [110.396, -7.7498],
      [110.3958, -7.7507],
      [110.3954, -7.7508],
      [110.3954, -7.7512],
      [110.3957, -7.7512],
      [110.3956, -7.7518],
      [110.3948, -7.7517],
      [110.3947, -7.7523],
      [110.394, -7.7523],
      [110.3939, -7.7523],
      [110.3936, -7.7521],
      [110.3936, -7.7509],
      [110.3938, -7.7499],
      [110.394, -7.7496],
      [110.3942, -7.7494],
      [110.3944, -7.7493],
      [110.395, -7.7492],
      [110.395, -7.7486],
      [110.3947, -7.7472],
      [110.3946, -7.7463],
      [110.3947, -7.7456],
      [110.3945, -7.7452],
      [110.3942, -7.7451],
      [110.3939, -7.745],
      [110.3937, -7.7445],
      [110.3932, -7.7445],
      [110.3914, -7.744],
      [110.3901, -7.7439],
      [110.3901, -7.7437],
      [110.39, -7.7435],
      [110.3898, -7.7434],
      [110.3897, -7.7433],
      [110.3895, -7.7421],
      [110.3892, -7.7418],
      [110.3883, -7.7416],
      [110.3872, -7.7411],
      [110.3875, -7.7404],
      [110.3876, -7.7397],
      [110.3879, -7.7384],
      [110.3883, -7.7384],
      [110.3883, -7.7382],
      [110.3861, -7.7379],
      [110.3863, -7.7373],
      [110.3862, -7.7363],
      [110.3858, -7.7353],
      [110.3853, -7.7353],
      [110.3856, -7.7356],
      [110.3857, -7.7359],
      [110.3854, -7.7367],
      [110.3853, -7.7369],
      [110.3848, -7.7377],
      [110.3847, -7.7387],
      [110.3844, -7.7388],
      [110.3838, -7.7388],
      [110.3835, -7.7389],
      [110.3834, -7.7393],
      [110.3834, -7.7399],
      [110.3832, -7.7401],
      [110.3826, -7.74],
      [110.3822, -7.7401],
      [110.3819, -7.7408],
      [110.3817, -7.7413],
      [110.3817, -7.7415],
      [110.3815, -7.7421],
      [110.3814, -7.7422],
      [110.3808, -7.7438],
      [110.3823, -7.7444],
      [110.3834, -7.7447],
      [110.384, -7.7448],
      [110.3839, -7.7473],
      [110.3848, -7.7475],
      [110.3872, -7.7485],
      [110.3863, -7.75],
      [110.3855, -7.7515],
      [110.387, -7.7515],
      [110.3871, -7.7529],
      [110.3874, -7.7555],
      [110.3882, -7.7558],
      [110.3886, -7.7564],
      [110.3885, -7.7568],
      [110.3883, -7.7572],
      [110.3885, -7.7577],
      [110.3896, -7.7579],
      [110.3892, -7.7591],
      [110.3889, -7.7591],
      [110.3886, -7.7594],
      [110.3884, -7.7597],
      [110.3878, -7.7616],
      [110.3896, -7.7622],
      [110.3895, -7.7626],
      [110.3891, -7.7637],
      [110.3904, -7.7642],
      [110.3923, -7.765],
      [110.3926, -7.7641],
      [110.3933, -7.7644],
      [110.3938, -7.7634],
      [110.394, -7.7635],
      [110.394, -7.7637],
      [110.3937, -7.7643],
      [110.3934, -7.7649],
      [110.3929, -7.7652],
      [110.3927, -7.7654],
      [110.3927, -7.7657],
      [110.3929, -7.7661],
      [110.3931, -7.7662],
      [110.3934, -7.7671],
      [110.3931, -7.7679],
      [110.3928, -7.7683],
      [110.3928, -7.7686],
      [110.3932, -7.7691],
      [110.3932, -7.7702],
      [110.3935, -7.7704],
      [110.394, -7.7713],
      [110.3946, -7.7724],
      [110.3955, -7.7728],
      [110.3957, -7.773],
      [110.3956, -7.7733],
      [110.3951, -7.7737],
      [110.3952, -7.774],
      [110.3956, -7.7742],
      [110.3964, -7.7742],
      [110.3969, -7.7752],
      [110.3974, -7.7754],
      [110.3977, -7.7758],
      [110.3981, -7.7762],
      [110.399, -7.7762],
      [110.399, -7.7761],
      [110.3987, -7.7758],
      [110.3986, -7.775],
      [110.3989, -7.7748],
      [110.399, -7.7743],
      [110.399, -7.7739],
      [110.3991, -7.7735],
      [110.3994, -7.7732],
      [110.3996, -7.7733],
      [110.3999, -7.774],
      [110.4012, -7.7743],
      [110.4037, -7.7747],
      [110.4042, -7.774],
      [110.4048, -7.7742],
      [110.405, -7.7738],
      [110.4051, -7.7723],
      [110.4052, -7.7711],
      [110.4053, -7.7707],
      [110.4054, -7.7695],
      [110.4058, -7.7665],
      [110.4069, -7.7668],
      [110.4079, -7.7669],
      [110.4084, -7.7669],
      [110.4087, -7.7667],
      [110.4087, -7.7664],
      [110.4088, -7.7661],
      [110.4106, -7.7664],
      [110.411, -7.7643],
      [110.4112, -7.7643],
      [110.4114, -7.7642],
      [110.4116, -7.764],
      [110.4118, -7.764],
      [110.4123, -7.7641],
      [110.4126, -7.7642],
      [110.4125, -7.7647],
      [110.4132, -7.7649],
      [110.4135, -7.7642],
      [110.4138, -7.7643],
      [110.414, -7.7641],
      [110.4141, -7.7641],
      [110.414, -7.7645],
      [110.4141, -7.7648],
      [110.4141, -7.765],
      [110.414, -7.7653],
      [110.4146, -7.7653],
      [110.4152, -7.7655],
      [110.4154, -7.7655],
      [110.4155, -7.7654],
      [110.4158, -7.765],
      [110.4165, -7.7635],
      [110.4169, -7.7621],
      [110.4165, -7.7619],
      [110.4164, -7.7612],
      [110.4165, -7.7604],
      [110.4163, -7.76],
      [110.4158, -7.759],
      [110.4155, -7.7586],
      [110.4154, -7.7578],
      [110.4151, -7.7575],
      [110.4147, -7.7566],
      [110.4149, -7.7559],
      [110.4149, -7.7556],
      [110.4147, -7.7552],
      [110.4143, -7.7552],
      [110.4141, -7.7551],
      [110.414, -7.7556],
      [110.414, -7.756],
      [110.4137, -7.7564],
      [110.4134, -7.7563],
      [110.4133, -7.7559],
      [110.4131, -7.7552],
      [110.4128, -7.7546],
      [110.4127, -7.7541],
      [110.4133, -7.7539],
      [110.4141, -7.7537],
      [110.4139, -7.7533],
      [110.4135, -7.7531],
      [110.4129, -7.7528],
      [110.4123, -7.7524],
      [110.4121, -7.752],
      [110.4123, -7.7513],
      [110.4123, -7.7502],
      [110.4124, -7.75],
      [110.4123, -7.7496],
      [110.4123, -7.7495],
      [110.412, -7.7491],
      [110.4115, -7.749],
      [110.4109, -7.7489],
      [110.4099, -7.7482],
      [110.409, -7.7473],
      [110.4088, -7.7474],
      [110.4084, -7.7475],
      [110.408, -7.7478],
      [110.4078, -7.7482],
      [110.4079, -7.7489],
      [110.4076, -7.75],
      [110.4071, -7.7499],
      [110.4061, -7.7496],
      [110.4046, -7.7494],
      [110.4034, -7.7495],
      [110.4032, -7.7492],
      [110.4032, -7.7489],
      [110.403, -7.7485],
      [110.403, -7.7481],
      [110.4037, -7.7477],
      [110.4038, -7.7474],
      [110.4039, -7.7462],
      [110.404, -7.7459],
      [110.4047, -7.7458],
      [110.4057, -7.7453],
      [110.4063, -7.7452],
      [110.4064, -7.7449],
      [110.4064, -7.7443],
      [110.4062, -7.7429],
      [110.4063, -7.7424],
      [110.4063, -7.7421],
      [110.4065, -7.7415],
      [110.4068, -7.741],
      [110.4067, -7.7405],
      [110.4066, -7.7405],
      [110.4059, -7.7406],
      [110.4055, -7.7411],
      [110.4053, -7.7402],
      [110.4046, -7.7402],
      [110.4046, -7.7396],
      [110.4043, -7.7396],
      [110.4041, -7.74],
      [110.4038, -7.7412],
      [110.4033, -7.7417],
      [110.4024, -7.7424],
      [110.4024, -7.742],
      [110.4025, -7.7413],
      [110.4025, -7.7413],
      [110.4026, -7.7405],
      [110.4026, -7.7403],
      [110.4023, -7.7398],
      [110.4021, -7.7396],
      [110.4021, -7.7395],
      [110.4021, -7.7393],
      [110.4021, -7.7384],
      [110.402, -7.7383],
      [110.4017, -7.7372],
      [110.4017, -7.737],
      [110.4017, -7.7362],
      [110.4017, -7.7357],
      [110.4017, -7.735],
      [110.4014, -7.7343],
      [110.4014, -7.7342],
      [110.4013, -7.7336],
      [110.401, -7.733],
      [110.4011, -7.7326],
      [110.4012, -7.7324]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'BERBAH',
    village: 'SENDANGTIRTO',
    border: [
      [110.4378, -7.8042],
      [110.4354, -7.8041],
      [110.4325, -7.8037],
      [110.429, -7.8035],
      [110.4287, -7.8038],
      [110.4285, -7.8044],
      [110.4285, -7.8049],
      [110.4285, -7.805],
      [110.4285, -7.8054],
      [110.4276, -7.8054],
      [110.4268, -7.8054],
      [110.426, -7.8051],
      [110.4254, -7.8048],
      [110.4248, -7.8045],
      [110.4239, -7.8044],
      [110.4234, -7.8042],
      [110.4228, -7.8037],
      [110.4224, -7.8033],
      [110.4223, -7.8031],
      [110.4222, -7.8039],
      [110.4221, -7.8043],
      [110.422, -7.805],
      [110.4219, -7.8051],
      [110.4218, -7.8055],
      [110.4216, -7.8059],
      [110.4214, -7.8061],
      [110.4212, -7.8064],
      [110.421, -7.8068],
      [110.421, -7.8075],
      [110.4211, -7.808],
      [110.4212, -7.8086],
      [110.4212, -7.8091],
      [110.4211, -7.8096],
      [110.421, -7.81],
      [110.4211, -7.8107],
      [110.4214, -7.8112],
      [110.4217, -7.8118],
      [110.4218, -7.8119],
      [110.4219, -7.8127],
      [110.4219, -7.8131],
      [110.4216, -7.8135],
      [110.4214, -7.814],
      [110.4213, -7.8144],
      [110.4214, -7.815],
      [110.4216, -7.8157],
      [110.4214, -7.816],
      [110.421, -7.8163],
      [110.421, -7.8167],
      [110.4211, -7.817],
      [110.4216, -7.8172],
      [110.4225, -7.8175],
      [110.4229, -7.818],
      [110.423, -7.8184],
      [110.423, -7.8186],
      [110.4228, -7.8187],
      [110.4225, -7.8187],
      [110.4223, -7.8188],
      [110.4221, -7.8189],
      [110.4222, -7.8192],
      [110.4226, -7.8196],
      [110.4229, -7.8199],
      [110.4228, -7.8201],
      [110.4223, -7.8202],
      [110.422, -7.8205],
      [110.4215, -7.8207],
      [110.4214, -7.821],
      [110.4214, -7.8214],
      [110.4217, -7.8215],
      [110.4219, -7.8218],
      [110.4224, -7.8218],
      [110.4227, -7.8218],
      [110.4229, -7.8218],
      [110.4234, -7.822],
      [110.4243, -7.8225],
      [110.4244, -7.8225],
      [110.4248, -7.8225],
      [110.4252, -7.8225],
      [110.4257, -7.8226],
      [110.4262, -7.8226],
      [110.4264, -7.8221],
      [110.4265, -7.8223],
      [110.4263, -7.8226],
      [110.4262, -7.8229],
      [110.4268, -7.8231],
      [110.427, -7.8225],
      [110.4274, -7.8225],
      [110.4282, -7.8216],
      [110.4283, -7.8216],
      [110.4282, -7.824],
      [110.427, -7.8234],
      [110.4269, -7.8244],
      [110.4267, -7.8248],
      [110.4265, -7.8252],
      [110.4261, -7.8257],
      [110.4262, -7.8259],
      [110.4261, -7.8265],
      [110.4261, -7.827],
      [110.4264, -7.8275],
      [110.4274, -7.8282],
      [110.4274, -7.8287],
      [110.4276, -7.829],
      [110.4285, -7.8298],
      [110.4295, -7.8303],
      [110.4304, -7.8304],
      [110.4309, -7.8304],
      [110.4315, -7.8303],
      [110.4314, -7.8306],
      [110.4313, -7.8313],
      [110.4311, -7.8321],
      [110.4306, -7.832],
      [110.4299, -7.8319],
      [110.4299, -7.8339],
      [110.4299, -7.8358],
      [110.4299, -7.836],
      [110.4299, -7.8373],
      [110.4308, -7.8373],
      [110.4313, -7.8374],
      [110.4324, -7.8376],
      [110.4325, -7.837],
      [110.4322, -7.8369],
      [110.4324, -7.8365],
      [110.433, -7.8366],
      [110.4336, -7.8366],
      [110.4341, -7.8366],
      [110.4352, -7.8366],
      [110.4354, -7.8366],
      [110.4354, -7.8364],
      [110.4353, -7.8362],
      [110.4348, -7.836],
      [110.4341, -7.8355],
      [110.4341, -7.835],
      [110.4342, -7.8345],
      [110.4343, -7.8342],
      [110.4348, -7.8336],
      [110.4348, -7.8335],
      [110.4346, -7.8334],
      [110.4344, -7.8331],
      [110.4344, -7.833],
      [110.4347, -7.8327],
      [110.4352, -7.8326],
      [110.4358, -7.8324],
      [110.4362, -7.8321],
      [110.4364, -7.8317],
      [110.4364, -7.8313],
      [110.4363, -7.831],
      [110.4362, -7.8303],
      [110.4364, -7.8294],
      [110.4371, -7.8293],
      [110.4377, -7.8292],
      [110.4381, -7.8291],
      [110.4383, -7.8289],
      [110.4384, -7.8286],
      [110.4389, -7.8287],
      [110.4392, -7.8288],
      [110.4393, -7.828],
      [110.4396, -7.828],
      [110.4397, -7.8282],
      [110.4401, -7.8283],
      [110.4402, -7.8281],
      [110.4409, -7.8281],
      [110.441, -7.8275],
      [110.4406, -7.8275],
      [110.4407, -7.8271],
      [110.4397, -7.8268],
      [110.4396, -7.8258],
      [110.4396, -7.825],
      [110.4394, -7.8247],
      [110.4395, -7.8237],
      [110.4396, -7.8235],
      [110.4401, -7.8233],
      [110.4402, -7.8228],
      [110.4403, -7.8217],
      [110.4403, -7.8209],
      [110.4398, -7.82],
      [110.4395, -7.8191],
      [110.4394, -7.8188],
      [110.4391, -7.8185],
      [110.439, -7.818],
      [110.4393, -7.8167],
      [110.4395, -7.8163],
      [110.4397, -7.816],
      [110.4397, -7.8157],
      [110.44, -7.8152],
      [110.4401, -7.8144],
      [110.4402, -7.8133],
      [110.4401, -7.8131],
      [110.4395, -7.8128],
      [110.4391, -7.8124],
      [110.4386, -7.8118],
      [110.4385, -7.8113],
      [110.4385, -7.8111],
      [110.4392, -7.8108],
      [110.4394, -7.8106],
      [110.4393, -7.8101],
      [110.4395, -7.8088],
      [110.4396, -7.8083],
      [110.4397, -7.808],
      [110.4393, -7.8078],
      [110.4385, -7.8074],
      [110.4378, -7.8067],
      [110.4378, -7.8065],
      [110.4377, -7.8062],
      [110.4379, -7.8059],
      [110.4382, -7.8057],
      [110.4384, -7.8055],
      [110.4384, -7.8053],
      [110.4381, -7.8049],
      [110.4378, -7.8042]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'BERBAH',
    village: 'TEGALTIRTO',
    border: [
      [110.4599, -7.8128],
      [110.4598, -7.8128],
      [110.4578, -7.8128],
      [110.4578, -7.812],
      [110.4573, -7.8119],
      [110.4574, -7.8112],
      [110.4577, -7.8105],
      [110.4566, -7.8101],
      [110.4569, -7.8094],
      [110.4563, -7.8093],
      [110.4558, -7.8096],
      [110.4554, -7.8095],
      [110.4554, -7.8092],
      [110.455, -7.8091],
      [110.4548, -7.8096],
      [110.4536, -7.8092],
      [110.4538, -7.8088],
      [110.4532, -7.8083],
      [110.4522, -7.808],
      [110.4521, -7.8087],
      [110.4505, -7.8085],
      [110.4494, -7.8091],
      [110.4493, -7.8097],
      [110.4481, -7.8098],
      [110.4478, -7.8098],
      [110.4477, -7.8095],
      [110.4463, -7.8093],
      [110.4463, -7.8074],
      [110.4447, -7.8069],
      [110.4451, -7.805],
      [110.4456, -7.8032],
      [110.4456, -7.8029],
      [110.4452, -7.8024],
      [110.445, -7.8018],
      [110.445, -7.8011],
      [110.4452, -7.7988],
      [110.4446, -7.7987],
      [110.4447, -7.7991],
      [110.4446, -7.7996],
      [110.4441, -7.7998],
      [110.4436, -7.7998],
      [110.4433, -7.7998],
      [110.4431, -7.7991],
      [110.442, -7.7989],
      [110.4429, -7.7965],
      [110.4436, -7.7946],
      [110.4441, -7.7932],
      [110.4446, -7.7933],
      [110.4457, -7.7934],
      [110.4458, -7.7925],
      [110.4465, -7.791],
      [110.4469, -7.7901],
      [110.447, -7.7878],
      [110.4474, -7.7875],
      [110.4477, -7.787],
      [110.4481, -7.7857],
      [110.4476, -7.7855],
      [110.4481, -7.7847],
      [110.4483, -7.7844],
      [110.4484, -7.7844],
      [110.4484, -7.7837],
      [110.4483, -7.783],
      [110.447, -7.7836],
      [110.4454, -7.7836],
      [110.445, -7.7835],
      [110.4448, -7.7845],
      [110.442, -7.7848],
      [110.4418, -7.7854],
      [110.4415, -7.786],
      [110.4412, -7.7862],
      [110.4404, -7.7864],
      [110.44, -7.7871],
      [110.4398, -7.7878],
      [110.4398, -7.788],
      [110.4399, -7.7882],
      [110.4401, -7.789],
      [110.4402, -7.7893],
      [110.441, -7.7901],
      [110.441, -7.7903],
      [110.4407, -7.7905],
      [110.4404, -7.7909],
      [110.4399, -7.7913],
      [110.4398, -7.7916],
      [110.4399, -7.792],
      [110.4397, -7.7922],
      [110.4393, -7.7926],
      [110.4388, -7.7928],
      [110.4384, -7.7928],
      [110.438, -7.7928],
      [110.4379, -7.793],
      [110.4383, -7.7938],
      [110.4387, -7.7942],
      [110.4391, -7.7947],
      [110.4393, -7.796],
      [110.4389, -7.7965],
      [110.439, -7.7972],
      [110.4388, -7.7977],
      [110.4386, -7.7984],
      [110.4388, -7.7991],
      [110.4393, -7.7995],
      [110.4397, -7.7996],
      [110.4397, -7.7998],
      [110.4397, -7.8008],
      [110.4397, -7.8011],
      [110.4396, -7.8012],
      [110.4395, -7.8013],
      [110.4392, -7.8021],
      [110.4384, -7.8025],
      [110.438, -7.8029],
      [110.4377, -7.8033],
      [110.4379, -7.8037],
      [110.4378, -7.804],
      [110.4378, -7.8042],
      [110.4381, -7.8049],
      [110.4384, -7.8053],
      [110.4384, -7.8055],
      [110.4382, -7.8057],
      [110.4379, -7.8059],
      [110.4377, -7.8062],
      [110.4378, -7.8065],
      [110.4378, -7.8067],
      [110.4385, -7.8074],
      [110.4393, -7.8078],
      [110.4397, -7.808],
      [110.4396, -7.8083],
      [110.4395, -7.8088],
      [110.4393, -7.8101],
      [110.4394, -7.8106],
      [110.4392, -7.8108],
      [110.4385, -7.8111],
      [110.4385, -7.8113],
      [110.4386, -7.8118],
      [110.4391, -7.8124],
      [110.4395, -7.8128],
      [110.4401, -7.8131],
      [110.4402, -7.8133],
      [110.4401, -7.8144],
      [110.44, -7.8152],
      [110.4397, -7.8157],
      [110.4397, -7.816],
      [110.4395, -7.8163],
      [110.4393, -7.8167],
      [110.439, -7.818],
      [110.4391, -7.8185],
      [110.4394, -7.8188],
      [110.4395, -7.8191],
      [110.4398, -7.82],
      [110.4403, -7.8209],
      [110.4403, -7.8217],
      [110.4402, -7.8228],
      [110.4401, -7.8233],
      [110.4396, -7.8235],
      [110.4395, -7.8237],
      [110.4394, -7.8247],
      [110.4396, -7.825],
      [110.4396, -7.8258],
      [110.4397, -7.8268],
      [110.4407, -7.8271],
      [110.4406, -7.8275],
      [110.441, -7.8275],
      [110.4425, -7.8276],
      [110.4443, -7.8278],
      [110.4443, -7.8268],
      [110.4435, -7.8267],
      [110.4436, -7.826],
      [110.4439, -7.8239],
      [110.446, -7.824],
      [110.4478, -7.8241],
      [110.4501, -7.8243],
      [110.4504, -7.8244],
      [110.4511, -7.8245],
      [110.4525, -7.8247],
      [110.4526, -7.8247],
      [110.4527, -7.8233],
      [110.4528, -7.8224],
      [110.453, -7.8217],
      [110.4532, -7.8212],
      [110.4534, -7.8209],
      [110.4537, -7.8206],
      [110.4547, -7.821],
      [110.4561, -7.8214],
      [110.4566, -7.8211],
      [110.4572, -7.8207],
      [110.4576, -7.8206],
      [110.4579, -7.8205],
      [110.4588, -7.8202],
      [110.46, -7.8198],
      [110.4601, -7.8197],
      [110.4603, -7.8196],
      [110.4613, -7.8191],
      [110.462, -7.8185],
      [110.4622, -7.8183],
      [110.4621, -7.8178],
      [110.4619, -7.8171],
      [110.4622, -7.8159],
      [110.4624, -7.8156],
      [110.4625, -7.8151],
      [110.4622, -7.8145],
      [110.4619, -7.8142],
      [110.4612, -7.8141],
      [110.4607, -7.8138],
      [110.4603, -7.8134],
      [110.4599, -7.8128]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'BERBAH',
    village: 'KALITIRTO',
    border: [
      [110.4672, -7.786],
      [110.467, -7.786],
      [110.4661, -7.7857],
      [110.4655, -7.7855],
      [110.4649, -7.7853],
      [110.4645, -7.7849],
      [110.4642, -7.7845],
      [110.4642, -7.7839],
      [110.4623, -7.7832],
      [110.46, -7.7826],
      [110.4582, -7.782],
      [110.4586, -7.7807],
      [110.4595, -7.7784],
      [110.4584, -7.7774],
      [110.4582, -7.7775],
      [110.4571, -7.7781],
      [110.4553, -7.7792],
      [110.4543, -7.7796],
      [110.4524, -7.7807],
      [110.4504, -7.7818],
      [110.4488, -7.7827],
      [110.4483, -7.783],
      [110.4484, -7.7837],
      [110.4484, -7.7844],
      [110.4483, -7.7844],
      [110.4481, -7.7847],
      [110.4476, -7.7855],
      [110.4481, -7.7857],
      [110.4477, -7.787],
      [110.4474, -7.7875],
      [110.447, -7.7878],
      [110.4469, -7.7901],
      [110.4465, -7.791],
      [110.4458, -7.7925],
      [110.4457, -7.7934],
      [110.4446, -7.7933],
      [110.4441, -7.7932],
      [110.4436, -7.7946],
      [110.4429, -7.7965],
      [110.442, -7.7989],
      [110.4431, -7.7991],
      [110.4433, -7.7998],
      [110.4436, -7.7998],
      [110.4441, -7.7998],
      [110.4446, -7.7996],
      [110.4447, -7.7991],
      [110.4446, -7.7987],
      [110.4452, -7.7988],
      [110.445, -7.8011],
      [110.445, -7.8018],
      [110.4452, -7.8024],
      [110.4456, -7.8029],
      [110.4456, -7.8032],
      [110.4451, -7.805],
      [110.4447, -7.8069],
      [110.4463, -7.8074],
      [110.4463, -7.8093],
      [110.4477, -7.8095],
      [110.4478, -7.8098],
      [110.4481, -7.8098],
      [110.4493, -7.8097],
      [110.4494, -7.8091],
      [110.4505, -7.8085],
      [110.4521, -7.8087],
      [110.4522, -7.808],
      [110.4532, -7.8083],
      [110.4538, -7.8088],
      [110.4536, -7.8092],
      [110.4548, -7.8096],
      [110.455, -7.8091],
      [110.4554, -7.8092],
      [110.4554, -7.8095],
      [110.4558, -7.8096],
      [110.4563, -7.8093],
      [110.4569, -7.8094],
      [110.4566, -7.8101],
      [110.4577, -7.8105],
      [110.4574, -7.8112],
      [110.4573, -7.8119],
      [110.4578, -7.812],
      [110.4578, -7.8128],
      [110.4598, -7.8128],
      [110.4599, -7.8128],
      [110.4598, -7.8127],
      [110.4597, -7.8122],
      [110.46, -7.8111],
      [110.4599, -7.8099],
      [110.4595, -7.8092],
      [110.4594, -7.809],
      [110.4593, -7.8083],
      [110.4589, -7.8078],
      [110.4589, -7.8074],
      [110.4589, -7.8071],
      [110.459, -7.8056],
      [110.4591, -7.8045],
      [110.4594, -7.8039],
      [110.4603, -7.8029],
      [110.4611, -7.8022],
      [110.4615, -7.8016],
      [110.4614, -7.8008],
      [110.4611, -7.8],
      [110.4606, -7.7994],
      [110.4604, -7.799],
      [110.46, -7.798],
      [110.46, -7.7976],
      [110.4604, -7.7975],
      [110.4614, -7.798],
      [110.4619, -7.7983],
      [110.4638, -7.7989],
      [110.465, -7.7994],
      [110.4656, -7.7995],
      [110.4661, -7.7994],
      [110.4662, -7.7993],
      [110.4666, -7.799],
      [110.4671, -7.7984],
      [110.4675, -7.7974],
      [110.4676, -7.7962],
      [110.4675, -7.7958],
      [110.4673, -7.7953],
      [110.4673, -7.795],
      [110.4665, -7.7942],
      [110.4661, -7.7936],
      [110.4658, -7.7927],
      [110.466, -7.7912],
      [110.4661, -7.791],
      [110.4663, -7.7905],
      [110.4664, -7.7902],
      [110.4665, -7.7899],
      [110.4666, -7.7896],
      [110.4668, -7.7884],
      [110.4672, -7.7879],
      [110.468, -7.7878],
      [110.4685, -7.7875],
      [110.4687, -7.787],
      [110.4686, -7.7867],
      [110.4683, -7.7867],
      [110.4678, -7.7869],
      [110.4673, -7.7869],
      [110.4669, -7.7867],
      [110.4669, -7.7863],
      [110.4672, -7.786]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'BERBAH',
    village: 'JOGOTIRTO',
    border: [
      [110.4666, -7.799],
      [110.4662, -7.7993],
      [110.4661, -7.7994],
      [110.4656, -7.7995],
      [110.465, -7.7994],
      [110.4638, -7.7989],
      [110.4619, -7.7983],
      [110.4614, -7.798],
      [110.4604, -7.7975],
      [110.46, -7.7976],
      [110.46, -7.798],
      [110.4604, -7.799],
      [110.4606, -7.7994],
      [110.4611, -7.8],
      [110.4614, -7.8008],
      [110.4615, -7.8016],
      [110.4611, -7.8022],
      [110.4603, -7.8029],
      [110.4594, -7.8039],
      [110.4591, -7.8045],
      [110.459, -7.8056],
      [110.4589, -7.8071],
      [110.4589, -7.8074],
      [110.4589, -7.8078],
      [110.4593, -7.8083],
      [110.4594, -7.809],
      [110.4595, -7.8092],
      [110.4599, -7.8099],
      [110.46, -7.8111],
      [110.4597, -7.8122],
      [110.4598, -7.8127],
      [110.4599, -7.8128],
      [110.4603, -7.8134],
      [110.4607, -7.8138],
      [110.4612, -7.8141],
      [110.4619, -7.8142],
      [110.4622, -7.8145],
      [110.4625, -7.8151],
      [110.4624, -7.8156],
      [110.4622, -7.8159],
      [110.4619, -7.8171],
      [110.4621, -7.8178],
      [110.4622, -7.8183],
      [110.462, -7.8185],
      [110.4613, -7.8191],
      [110.4603, -7.8196],
      [110.4601, -7.8197],
      [110.46, -7.8198],
      [110.4602, -7.8202],
      [110.4607, -7.8214],
      [110.4612, -7.8227],
      [110.4615, -7.8228],
      [110.462, -7.8227],
      [110.462, -7.8225],
      [110.4619, -7.8218],
      [110.4619, -7.8215],
      [110.462, -7.8212],
      [110.4627, -7.8214],
      [110.4633, -7.8216],
      [110.464, -7.8219],
      [110.4646, -7.822],
      [110.4651, -7.8221],
      [110.4653, -7.8225],
      [110.4655, -7.8237],
      [110.4656, -7.8246],
      [110.4658, -7.8248],
      [110.4661, -7.8248],
      [110.4665, -7.8249],
      [110.4663, -7.826],
      [110.4661, -7.8274],
      [110.4661, -7.8277],
      [110.4669, -7.828],
      [110.4677, -7.8281],
      [110.4683, -7.8283],
      [110.4692, -7.8285],
      [110.4695, -7.8286],
      [110.4696, -7.8286],
      [110.4698, -7.8286],
      [110.4698, -7.8282],
      [110.4699, -7.8274],
      [110.4699, -7.8271],
      [110.4698, -7.8271],
      [110.4696, -7.8272],
      [110.4692, -7.8273],
      [110.4688, -7.8273],
      [110.4684, -7.8273],
      [110.4684, -7.8271],
      [110.4686, -7.827],
      [110.4689, -7.8269],
      [110.4694, -7.8264],
      [110.4698, -7.826],
      [110.47, -7.8259],
      [110.4702, -7.8258],
      [110.4709, -7.8253],
      [110.4709, -7.825],
      [110.4711, -7.8244],
      [110.4715, -7.8242],
      [110.4723, -7.8238],
      [110.4727, -7.8237],
      [110.4731, -7.8238],
      [110.4732, -7.8232],
      [110.474, -7.8234],
      [110.4749, -7.8234],
      [110.4759, -7.8238],
      [110.4756, -7.8251],
      [110.4769, -7.8254],
      [110.4771, -7.8241],
      [110.4775, -7.8242],
      [110.4794, -7.8249],
      [110.4798, -7.8235],
      [110.4804, -7.8215],
      [110.481, -7.819],
      [110.4814, -7.8175],
      [110.4827, -7.8179],
      [110.4847, -7.8183],
      [110.4854, -7.8185],
      [110.4856, -7.8183],
      [110.4871, -7.8185],
      [110.4873, -7.8162],
      [110.4875, -7.8145],
      [110.4876, -7.8125],
      [110.4871, -7.8124],
      [110.4863, -7.8127],
      [110.4858, -7.8131],
      [110.4857, -7.8133],
      [110.4856, -7.814],
      [110.4842, -7.8137],
      [110.4841, -7.8128],
      [110.4845, -7.8127],
      [110.4848, -7.8106],
      [110.4848, -7.8103],
      [110.4831, -7.8098],
      [110.482, -7.8095],
      [110.4811, -7.8094],
      [110.481, -7.8085],
      [110.4808, -7.8084],
      [110.4799, -7.8081],
      [110.4794, -7.8079],
      [110.4784, -7.8073],
      [110.4783, -7.8073],
      [110.4765, -7.8067],
      [110.4762, -7.8066],
      [110.4759, -7.8065],
      [110.4741, -7.8059],
      [110.4743, -7.8046],
      [110.4745, -7.8031],
      [110.4743, -7.8015],
      [110.473, -7.8012],
      [110.4727, -7.8028],
      [110.4697, -7.8018],
      [110.4688, -7.8015],
      [110.4684, -7.8013],
      [110.4675, -7.801],
      [110.467, -7.8009],
      [110.4668, -7.8001],
      [110.4669, -7.7998],
      [110.4666, -7.7992],
      [110.4666, -7.799]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PRAMBANAN',
    village: 'SUMBERHARJO',
    border: [
      [110.5212, -7.7972],
      [110.5211, -7.7967],
      [110.5209, -7.7957],
      [110.5206, -7.7958],
      [110.5203, -7.7958],
      [110.5202, -7.7955],
      [110.5202, -7.7953],
      [110.5197, -7.7946],
      [110.5191, -7.7946],
      [110.5189, -7.7944],
      [110.5184, -7.7946],
      [110.5175, -7.7942],
      [110.5167, -7.7938],
      [110.5164, -7.7938],
      [110.5156, -7.7942],
      [110.5151, -7.7945],
      [110.5147, -7.7949],
      [110.5145, -7.7953],
      [110.5143, -7.7958],
      [110.5139, -7.7961],
      [110.5133, -7.7962],
      [110.5129, -7.7965],
      [110.5127, -7.7971],
      [110.5124, -7.7974],
      [110.512, -7.7977],
      [110.5117, -7.7982],
      [110.5113, -7.7994],
      [110.5108, -7.7991],
      [110.51, -7.7988],
      [110.5096, -7.7986],
      [110.5092, -7.7988],
      [110.5089, -7.7989],
      [110.5087, -7.7988],
      [110.5083, -7.7985],
      [110.5075, -7.7985],
      [110.5068, -7.7984],
      [110.5066, -7.7983],
      [110.506, -7.7981],
      [110.5057, -7.7979],
      [110.5051, -7.798],
      [110.5047, -7.798],
      [110.5039, -7.7978],
      [110.5038, -7.7975],
      [110.5035, -7.7974],
      [110.5031, -7.7974],
      [110.5026, -7.7973],
      [110.5024, -7.7972],
      [110.5023, -7.7969],
      [110.5019, -7.7969],
      [110.5013, -7.7967],
      [110.501, -7.7966],
      [110.5002, -7.7968],
      [110.4997, -7.7965],
      [110.4995, -7.7966],
      [110.4991, -7.7965],
      [110.4989, -7.7966],
      [110.4987, -7.7967],
      [110.4986, -7.7967],
      [110.4982, -7.7967],
      [110.4982, -7.7969],
      [110.4978, -7.7971],
      [110.4974, -7.797],
      [110.4969, -7.7967],
      [110.4966, -7.7963],
      [110.4966, -7.7965],
      [110.4965, -7.7969],
      [110.4964, -7.7976],
      [110.4962, -7.798],
      [110.4959, -7.7982],
      [110.4955, -7.7984],
      [110.4952, -7.7987],
      [110.4953, -7.7993],
      [110.4954, -7.7997],
      [110.4953, -7.8001],
      [110.4955, -7.8004],
      [110.4957, -7.8006],
      [110.4961, -7.8007],
      [110.4953, -7.8013],
      [110.4945, -7.8017],
      [110.4935, -7.8017],
      [110.4906, -7.8014],
      [110.4898, -7.8013],
      [110.4877, -7.8012],
      [110.4872, -7.8012],
      [110.4867, -7.8011],
      [110.4854, -7.801],
      [110.4819, -7.8005],
      [110.4816, -7.8017],
      [110.4827, -7.8022],
      [110.4827, -7.8029],
      [110.4827, -7.805],
      [110.4822, -7.8052],
      [110.4821, -7.8052],
      [110.4817, -7.805],
      [110.4814, -7.805],
      [110.4814, -7.8055],
      [110.4811, -7.8055],
      [110.481, -7.8059],
      [110.4808, -7.806],
      [110.4803, -7.8063],
      [110.4798, -7.8063],
      [110.479, -7.8064],
      [110.4788, -7.8065],
      [110.4784, -7.8067],
      [110.4783, -7.807],
      [110.4783, -7.8073],
      [110.4784, -7.8073],
      [110.4794, -7.8079],
      [110.4799, -7.8081],
      [110.4808, -7.8084],
      [110.481, -7.8085],
      [110.4811, -7.8094],
      [110.482, -7.8095],
      [110.4831, -7.8098],
      [110.4848, -7.8103],
      [110.4848, -7.8106],
      [110.4845, -7.8127],
      [110.4841, -7.8128],
      [110.4842, -7.8137],
      [110.4856, -7.814],
      [110.4857, -7.8133],
      [110.4858, -7.8131],
      [110.4863, -7.8127],
      [110.4871, -7.8124],
      [110.4876, -7.8125],
      [110.4875, -7.8145],
      [110.4873, -7.8162],
      [110.4871, -7.8185],
      [110.4856, -7.8183],
      [110.4854, -7.8185],
      [110.4853, -7.819],
      [110.4851, -7.8195],
      [110.4846, -7.8205],
      [110.4843, -7.8211],
      [110.4845, -7.8213],
      [110.4851, -7.8214],
      [110.4854, -7.821],
      [110.4865, -7.8213],
      [110.487, -7.8213],
      [110.488, -7.8216],
      [110.4903, -7.8223],
      [110.4911, -7.8225],
      [110.4923, -7.8229],
      [110.4927, -7.8229],
      [110.4934, -7.8229],
      [110.4939, -7.8229],
      [110.4952, -7.8229],
      [110.4961, -7.8229],
      [110.4967, -7.8229],
      [110.4973, -7.8229],
      [110.4978, -7.8228],
      [110.4986, -7.8228],
      [110.4993, -7.8229],
      [110.4999, -7.8229],
      [110.5005, -7.8227],
      [110.5009, -7.8225],
      [110.5012, -7.8224],
      [110.5015, -7.8224],
      [110.5018, -7.8225],
      [110.5027, -7.8228],
      [110.5042, -7.8234],
      [110.5046, -7.8235],
      [110.5047, -7.8235],
      [110.5053, -7.8236],
      [110.5059, -7.8233],
      [110.5066, -7.8231],
      [110.5071, -7.8231],
      [110.5073, -7.8228],
      [110.5072, -7.8226],
      [110.5069, -7.8224],
      [110.5068, -7.8222],
      [110.5068, -7.8218],
      [110.5069, -7.8216],
      [110.5074, -7.8216],
      [110.5082, -7.8218],
      [110.5093, -7.8221],
      [110.5097, -7.8224],
      [110.5098, -7.8218],
      [110.5098, -7.8214],
      [110.5099, -7.8211],
      [110.51, -7.8203],
      [110.5101, -7.8198],
      [110.5101, -7.8189],
      [110.5102, -7.8181],
      [110.5103, -7.8178],
      [110.5103, -7.8177],
      [110.5101, -7.8171],
      [110.5091, -7.8164],
      [110.5081, -7.816],
      [110.5073, -7.8156],
      [110.5075, -7.8149],
      [110.5081, -7.8137],
      [110.5086, -7.8133],
      [110.5095, -7.8132],
      [110.5101, -7.8131],
      [110.5106, -7.8126],
      [110.5108, -7.8121],
      [110.5113, -7.8117],
      [110.5117, -7.8115],
      [110.5117, -7.8115],
      [110.5124, -7.8116],
      [110.5125, -7.8111],
      [110.5127, -7.8103],
      [110.5127, -7.8099],
      [110.5123, -7.8094],
      [110.5126, -7.8092],
      [110.5131, -7.8093],
      [110.5137, -7.8093],
      [110.5136, -7.8088],
      [110.5138, -7.8085],
      [110.5141, -7.808],
      [110.5142, -7.8076],
      [110.5142, -7.8072],
      [110.515, -7.8067],
      [110.5155, -7.8066],
      [110.5157, -7.8063],
      [110.5154, -7.8054],
      [110.5153, -7.8052],
      [110.5149, -7.8047],
      [110.5147, -7.8042],
      [110.5146, -7.8038],
      [110.5146, -7.8032],
      [110.5148, -7.8027],
      [110.5149, -7.8025],
      [110.5151, -7.8021],
      [110.5154, -7.8016],
      [110.5156, -7.801],
      [110.5161, -7.8009],
      [110.5165, -7.8005],
      [110.517, -7.8004],
      [110.5176, -7.8002],
      [110.5182, -7.7999],
      [110.5184, -7.7994],
      [110.5188, -7.799],
      [110.5194, -7.7985],
      [110.52, -7.798],
      [110.5207, -7.7974],
      [110.5212, -7.7972]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PRAMBANAN',
    village: 'WUKIRHARJO',
    border: [
      [110.5323, -7.8234],
      [110.5322, -7.8233],
      [110.5322, -7.8232],
      [110.5322, -7.823],
      [110.5321, -7.8228],
      [110.5324, -7.8228],
      [110.5325, -7.8226],
      [110.5327, -7.8223],
      [110.5325, -7.8218],
      [110.5325, -7.8213],
      [110.5327, -7.8211],
      [110.5328, -7.8207],
      [110.5328, -7.8204],
      [110.5329, -7.82],
      [110.5328, -7.8198],
      [110.5327, -7.8197],
      [110.5325, -7.8195],
      [110.5324, -7.8195],
      [110.5322, -7.8194],
      [110.5318, -7.8193],
      [110.5318, -7.8189],
      [110.5312, -7.8185],
      [110.5311, -7.8184],
      [110.531, -7.8183],
      [110.5304, -7.818],
      [110.53, -7.8177],
      [110.5299, -7.8173],
      [110.5291, -7.8162],
      [110.5287, -7.8155],
      [110.5284, -7.8134],
      [110.5281, -7.8126],
      [110.528, -7.8123],
      [110.528, -7.812],
      [110.5279, -7.8117],
      [110.5282, -7.8114],
      [110.5275, -7.8108],
      [110.5272, -7.8105],
      [110.5272, -7.81],
      [110.5273, -7.8092],
      [110.5272, -7.8088],
      [110.527, -7.8087],
      [110.5272, -7.8085],
      [110.5271, -7.8083],
      [110.5267, -7.8081],
      [110.5264, -7.808],
      [110.526, -7.8076],
      [110.5259, -7.8073],
      [110.5257, -7.8069],
      [110.5254, -7.8068],
      [110.5248, -7.8067],
      [110.5242, -7.8063],
      [110.524, -7.8061],
      [110.5238, -7.8055],
      [110.5237, -7.8049],
      [110.5234, -7.8046],
      [110.5231, -7.8044],
      [110.5231, -7.8038],
      [110.5232, -7.8028],
      [110.5231, -7.802],
      [110.5228, -7.801],
      [110.5223, -7.8003],
      [110.522, -7.7998],
      [110.522, -7.7989],
      [110.5218, -7.7983],
      [110.5216, -7.7976],
      [110.5214, -7.7971],
      [110.5212, -7.7972],
      [110.5207, -7.7974],
      [110.52, -7.798],
      [110.5194, -7.7985],
      [110.5188, -7.799],
      [110.5184, -7.7994],
      [110.5182, -7.7999],
      [110.5176, -7.8002],
      [110.517, -7.8004],
      [110.5165, -7.8005],
      [110.5161, -7.8009],
      [110.5156, -7.801],
      [110.5154, -7.8016],
      [110.5151, -7.8021],
      [110.5149, -7.8025],
      [110.5148, -7.8027],
      [110.5146, -7.8032],
      [110.5146, -7.8038],
      [110.5147, -7.8042],
      [110.5149, -7.8047],
      [110.5153, -7.8052],
      [110.5154, -7.8054],
      [110.5157, -7.8063],
      [110.5155, -7.8066],
      [110.515, -7.8067],
      [110.5142, -7.8072],
      [110.5142, -7.8076],
      [110.5141, -7.808],
      [110.5138, -7.8085],
      [110.5136, -7.8088],
      [110.5137, -7.8093],
      [110.5131, -7.8093],
      [110.5126, -7.8092],
      [110.5123, -7.8094],
      [110.5127, -7.8099],
      [110.5127, -7.8103],
      [110.5125, -7.8111],
      [110.5124, -7.8116],
      [110.5117, -7.8115],
      [110.5117, -7.8115],
      [110.5113, -7.8117],
      [110.5108, -7.8121],
      [110.5106, -7.8126],
      [110.5101, -7.8131],
      [110.5095, -7.8132],
      [110.5086, -7.8133],
      [110.5081, -7.8137],
      [110.5075, -7.8149],
      [110.5073, -7.8156],
      [110.5081, -7.816],
      [110.5091, -7.8164],
      [110.5101, -7.8171],
      [110.5103, -7.8177],
      [110.5103, -7.8178],
      [110.5102, -7.8181],
      [110.5101, -7.8189],
      [110.5101, -7.8198],
      [110.51, -7.8203],
      [110.5099, -7.8211],
      [110.5098, -7.8214],
      [110.5098, -7.8218],
      [110.5097, -7.8224],
      [110.5099, -7.8227],
      [110.5102, -7.8232],
      [110.5103, -7.8235],
      [110.5105, -7.8242],
      [110.5106, -7.8252],
      [110.5105, -7.8254],
      [110.5104, -7.8256],
      [110.5101, -7.8258],
      [110.51, -7.8262],
      [110.5101, -7.8265],
      [110.5105, -7.8265],
      [110.5107, -7.8263],
      [110.5109, -7.8264],
      [110.511, -7.8268],
      [110.511, -7.8272],
      [110.5109, -7.8275],
      [110.511, -7.8277],
      [110.5111, -7.8278],
      [110.5111, -7.8284],
      [110.5112, -7.8289],
      [110.5117, -7.8291],
      [110.5123, -7.8291],
      [110.5128, -7.829],
      [110.5133, -7.8287],
      [110.514, -7.8283],
      [110.5144, -7.8281],
      [110.5147, -7.8282],
      [110.5148, -7.8286],
      [110.5148, -7.8291],
      [110.515, -7.8294],
      [110.5149, -7.8299],
      [110.515, -7.8302],
      [110.5154, -7.8303],
      [110.516, -7.8304],
      [110.5166, -7.8303],
      [110.5174, -7.8304],
      [110.5177, -7.8305],
      [110.518, -7.8306],
      [110.5181, -7.8306],
      [110.5184, -7.8308],
      [110.5186, -7.8309],
      [110.519, -7.831],
      [110.5194, -7.8309],
      [110.5195, -7.8309],
      [110.5201, -7.8306],
      [110.5206, -7.8302],
      [110.5209, -7.8299],
      [110.5214, -7.8293],
      [110.5216, -7.8289],
      [110.5217, -7.8286],
      [110.5224, -7.8284],
      [110.5225, -7.8283],
      [110.5226, -7.8281],
      [110.5229, -7.828],
      [110.5233, -7.8282],
      [110.5236, -7.8282],
      [110.5237, -7.828],
      [110.5241, -7.8276],
      [110.5244, -7.8275],
      [110.5246, -7.8275],
      [110.5249, -7.8277],
      [110.5251, -7.8276],
      [110.5257, -7.8274],
      [110.526, -7.8274],
      [110.5263, -7.8276],
      [110.5266, -7.8278],
      [110.5268, -7.8278],
      [110.527, -7.8278],
      [110.5271, -7.8275],
      [110.5271, -7.8271],
      [110.5272, -7.8268],
      [110.5274, -7.8267],
      [110.5277, -7.8267],
      [110.5279, -7.8268],
      [110.528, -7.8271],
      [110.5283, -7.8271],
      [110.5284, -7.8269],
      [110.5283, -7.8266],
      [110.5282, -7.8263],
      [110.5282, -7.8262],
      [110.5283, -7.8261],
      [110.5285, -7.8258],
      [110.529, -7.8259],
      [110.5293, -7.8258],
      [110.5296, -7.8257],
      [110.53, -7.8254],
      [110.5301, -7.8255],
      [110.5302, -7.8256],
      [110.5303, -7.8256],
      [110.5305, -7.8257],
      [110.5307, -7.8258],
      [110.5309, -7.8258],
      [110.5311, -7.8258],
      [110.5311, -7.8256],
      [110.5309, -7.8254],
      [110.5307, -7.8252],
      [110.5306, -7.8251],
      [110.5302, -7.8247],
      [110.5297, -7.8243],
      [110.5297, -7.824],
      [110.5297, -7.8238],
      [110.5299, -7.8237],
      [110.5301, -7.8238],
      [110.5303, -7.8241],
      [110.5309, -7.8238],
      [110.5314, -7.8236],
      [110.532, -7.8234],
      [110.5323, -7.8234]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PRAMBANAN',
    village: 'GAYAMHARJO',
    border: [
      [110.5485, -7.7909],
      [110.5484, -7.7908],
      [110.5476, -7.7905],
      [110.547, -7.7903],
      [110.5466, -7.7909],
      [110.5464, -7.7911],
      [110.5463, -7.7913],
      [110.5463, -7.7914],
      [110.5462, -7.7916],
      [110.546, -7.792],
      [110.5458, -7.7922],
      [110.5457, -7.7923],
      [110.5455, -7.7926],
      [110.5448, -7.7928],
      [110.5442, -7.793],
      [110.5439, -7.7931],
      [110.5438, -7.7931],
      [110.5436, -7.7935],
      [110.5435, -7.7937],
      [110.5433, -7.7941],
      [110.543, -7.7943],
      [110.5424, -7.7944],
      [110.542, -7.7943],
      [110.5414, -7.7942],
      [110.5413, -7.7943],
      [110.5411, -7.7943],
      [110.5409, -7.7944],
      [110.5406, -7.7947],
      [110.5403, -7.7951],
      [110.5402, -7.7952],
      [110.5398, -7.7956],
      [110.5396, -7.796],
      [110.5395, -7.7961],
      [110.5395, -7.7962],
      [110.5395, -7.7965],
      [110.5395, -7.7966],
      [110.5397, -7.7968],
      [110.5402, -7.7969],
      [110.5405, -7.797],
      [110.5405, -7.7971],
      [110.5404, -7.7972],
      [110.5403, -7.7973],
      [110.5402, -7.7974],
      [110.5401, -7.7975],
      [110.54, -7.7976],
      [110.5399, -7.7978],
      [110.5398, -7.7979],
      [110.5394, -7.7977],
      [110.5389, -7.7976],
      [110.5387, -7.7976],
      [110.5386, -7.7976],
      [110.5383, -7.7976],
      [110.5377, -7.7977],
      [110.5372, -7.7978],
      [110.5367, -7.798],
      [110.5364, -7.7979],
      [110.5362, -7.7977],
      [110.5361, -7.7974],
      [110.5363, -7.797],
      [110.5366, -7.7966],
      [110.5366, -7.7961],
      [110.5365, -7.7959],
      [110.5366, -7.7958],
      [110.537, -7.7954],
      [110.5373, -7.795],
      [110.5374, -7.7947],
      [110.5371, -7.7943],
      [110.537, -7.7943],
      [110.5367, -7.7942],
      [110.5364, -7.7941],
      [110.5363, -7.794],
      [110.5359, -7.7941],
      [110.5357, -7.7941],
      [110.5353, -7.7943],
      [110.5352, -7.7944],
      [110.5351, -7.7947],
      [110.535, -7.7948],
      [110.5351, -7.7953],
      [110.5352, -7.7958],
      [110.5351, -7.7963],
      [110.5351, -7.7968],
      [110.5348, -7.7969],
      [110.5345, -7.797],
      [110.5343, -7.7971],
      [110.5339, -7.7971],
      [110.5334, -7.7971],
      [110.5327, -7.7972],
      [110.5323, -7.7977],
      [110.5321, -7.7979],
      [110.5317, -7.7982],
      [110.5314, -7.7982],
      [110.5309, -7.7981],
      [110.5307, -7.7979],
      [110.5307, -7.7973],
      [110.5308, -7.7969],
      [110.531, -7.7965],
      [110.5311, -7.7962],
      [110.531, -7.7959],
      [110.5306, -7.7956],
      [110.5302, -7.7954],
      [110.53, -7.7953],
      [110.5298, -7.795],
      [110.5299, -7.7948],
      [110.5301, -7.7945],
      [110.5302, -7.7944],
      [110.5304, -7.7942],
      [110.5304, -7.7939],
      [110.5304, -7.7937],
      [110.5303, -7.7936],
      [110.5303, -7.7933],
      [110.5302, -7.7932],
      [110.5302, -7.793],
      [110.5296, -7.7931],
      [110.5288, -7.7929],
      [110.528, -7.7929],
      [110.527, -7.7932],
      [110.5267, -7.7935],
      [110.5265, -7.7937],
      [110.5259, -7.7942],
      [110.5253, -7.7945],
      [110.525, -7.7949],
      [110.5246, -7.7952],
      [110.524, -7.7951],
      [110.5235, -7.7959],
      [110.5231, -7.7962],
      [110.5214, -7.7971],
      [110.5216, -7.7976],
      [110.5218, -7.7983],
      [110.522, -7.7989],
      [110.522, -7.7998],
      [110.5223, -7.8003],
      [110.5228, -7.801],
      [110.5231, -7.802],
      [110.5232, -7.8028],
      [110.5231, -7.8038],
      [110.5231, -7.8044],
      [110.5234, -7.8046],
      [110.5237, -7.8049],
      [110.5238, -7.8055],
      [110.524, -7.8061],
      [110.5242, -7.8063],
      [110.5248, -7.8067],
      [110.5254, -7.8068],
      [110.5257, -7.8069],
      [110.5259, -7.8073],
      [110.526, -7.8076],
      [110.5264, -7.808],
      [110.5267, -7.8081],
      [110.5271, -7.8083],
      [110.5272, -7.8085],
      [110.527, -7.8087],
      [110.5272, -7.8088],
      [110.5273, -7.8092],
      [110.5272, -7.81],
      [110.5272, -7.8105],
      [110.5275, -7.8108],
      [110.5282, -7.8114],
      [110.5279, -7.8117],
      [110.528, -7.812],
      [110.528, -7.8123],
      [110.5281, -7.8126],
      [110.5284, -7.8134],
      [110.5287, -7.8155],
      [110.5291, -7.8162],
      [110.5299, -7.8173],
      [110.53, -7.8177],
      [110.5304, -7.818],
      [110.531, -7.8183],
      [110.5311, -7.8184],
      [110.5312, -7.8185],
      [110.5318, -7.8189],
      [110.5318, -7.8193],
      [110.5322, -7.8194],
      [110.5324, -7.8195],
      [110.5325, -7.8195],
      [110.5327, -7.8197],
      [110.5328, -7.8198],
      [110.5329, -7.82],
      [110.5328, -7.8204],
      [110.5328, -7.8207],
      [110.5327, -7.8211],
      [110.5325, -7.8213],
      [110.5325, -7.8218],
      [110.5327, -7.8223],
      [110.5325, -7.8226],
      [110.5324, -7.8228],
      [110.5321, -7.8228],
      [110.5322, -7.823],
      [110.5322, -7.8232],
      [110.5322, -7.8233],
      [110.5323, -7.8234],
      [110.5324, -7.8235],
      [110.5323, -7.8239],
      [110.5323, -7.8242],
      [110.5323, -7.8245],
      [110.5324, -7.8246],
      [110.5325, -7.8247],
      [110.5327, -7.8245],
      [110.5328, -7.8244],
      [110.5329, -7.8243],
      [110.5329, -7.824],
      [110.5328, -7.8237],
      [110.5329, -7.8234],
      [110.533, -7.8232],
      [110.5332, -7.8231],
      [110.5337, -7.823],
      [110.534, -7.8229],
      [110.5341, -7.8228],
      [110.5343, -7.8228],
      [110.5346, -7.8226],
      [110.5347, -7.8225],
      [110.535, -7.8227],
      [110.5351, -7.8228],
      [110.5354, -7.8233],
      [110.5358, -7.8234],
      [110.536, -7.8234],
      [110.5367, -7.8234],
      [110.5371, -7.8235],
      [110.5373, -7.8237],
      [110.5374, -7.8238],
      [110.5377, -7.8241],
      [110.5381, -7.8238],
      [110.5385, -7.8237],
      [110.5385, -7.8235],
      [110.5386, -7.8235],
      [110.5389, -7.8238],
      [110.5394, -7.8238],
      [110.5397, -7.8239],
      [110.5399, -7.824],
      [110.5402, -7.8241],
      [110.5405, -7.8242],
      [110.5408, -7.8242],
      [110.5412, -7.8241],
      [110.5414, -7.8238],
      [110.5415, -7.8237],
      [110.5419, -7.8236],
      [110.5424, -7.8237],
      [110.5425, -7.8235],
      [110.5426, -7.8233],
      [110.5425, -7.8228],
      [110.5424, -7.8223],
      [110.5427, -7.8218],
      [110.5426, -7.8217],
      [110.5424, -7.8213],
      [110.5421, -7.8208],
      [110.5421, -7.8203],
      [110.542, -7.8198],
      [110.5421, -7.8194],
      [110.5424, -7.8189],
      [110.5429, -7.8181],
      [110.5435, -7.8177],
      [110.5442, -7.8174],
      [110.5448, -7.8168],
      [110.5452, -7.8163],
      [110.5455, -7.8158],
      [110.5455, -7.8147],
      [110.5453, -7.8143],
      [110.5451, -7.814],
      [110.5451, -7.8135],
      [110.5451, -7.8131],
      [110.5454, -7.8128],
      [110.5454, -7.8125],
      [110.5453, -7.8121],
      [110.5451, -7.8118],
      [110.5451, -7.8112],
      [110.5452, -7.811],
      [110.5453, -7.8107],
      [110.5455, -7.8104],
      [110.5458, -7.8101],
      [110.5463, -7.8099],
      [110.546, -7.8097],
      [110.5458, -7.8095],
      [110.5455, -7.8092],
      [110.5452, -7.8086],
      [110.545, -7.8082],
      [110.545, -7.808],
      [110.5448, -7.8078],
      [110.5445, -7.8075],
      [110.5445, -7.8071],
      [110.5447, -7.8069],
      [110.5447, -7.8065],
      [110.5449, -7.8061],
      [110.5447, -7.8058],
      [110.5445, -7.8055],
      [110.5447, -7.8053],
      [110.5449, -7.805],
      [110.5447, -7.8047],
      [110.5447, -7.8043],
      [110.5448, -7.8036],
      [110.5448, -7.8036],
      [110.5449, -7.8031],
      [110.5452, -7.8026],
      [110.5455, -7.8021],
      [110.5458, -7.8015],
      [110.5463, -7.8011],
      [110.5466, -7.8008],
      [110.547, -7.8006],
      [110.5474, -7.8006],
      [110.5478, -7.8006],
      [110.5479, -7.8003],
      [110.5478, -7.7999],
      [110.5478, -7.7995],
      [110.5479, -7.7991],
      [110.5481, -7.7989],
      [110.5483, -7.7988],
      [110.5486, -7.7987],
      [110.5489, -7.7986],
      [110.5489, -7.7983],
      [110.5486, -7.7974],
      [110.5484, -7.7965],
      [110.5484, -7.7959],
      [110.5484, -7.7949],
      [110.5485, -7.7944],
      [110.5486, -7.7938],
      [110.5488, -7.7933],
      [110.5489, -7.7929],
      [110.5492, -7.7924],
      [110.5494, -7.7922],
      [110.5496, -7.792],
      [110.5497, -7.7918],
      [110.5499, -7.7917],
      [110.5494, -7.7914],
      [110.5489, -7.7912],
      [110.5485, -7.7909]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PRAMBANAN',
    village: 'SAMBIREJO',
    border: [
      [110.5118, -7.7716],
      [110.5115, -7.7715],
      [110.5101, -7.7708],
      [110.51, -7.7707],
      [110.5094, -7.7702],
      [110.5088, -7.7698],
      [110.5087, -7.7698],
      [110.5085, -7.7696],
      [110.5083, -7.7695],
      [110.5081, -7.7694],
      [110.508, -7.7693],
      [110.5072, -7.769],
      [110.5066, -7.769],
      [110.5064, -7.7689],
      [110.5057, -7.7689],
      [110.5051, -7.7689],
      [110.5041, -7.7688],
      [110.504, -7.7688],
      [110.5034, -7.7687],
      [110.5029, -7.7687],
      [110.5022, -7.7686],
      [110.5017, -7.7686],
      [110.5015, -7.7686],
      [110.5009, -7.7686],
      [110.5008, -7.7686],
      [110.5007, -7.7686],
      [110.5002, -7.7686],
      [110.4999, -7.7686],
      [110.4993, -7.7685],
      [110.4985, -7.7684],
      [110.4982, -7.7683],
      [110.4981, -7.7683],
      [110.4963, -7.7676],
      [110.4957, -7.7677],
      [110.4951, -7.7678],
      [110.4945, -7.7679],
      [110.4939, -7.7679],
      [110.493, -7.7678],
      [110.4925, -7.7681],
      [110.4921, -7.7683],
      [110.4915, -7.7683],
      [110.4909, -7.7685],
      [110.4907, -7.7689],
      [110.4907, -7.7692],
      [110.4908, -7.7698],
      [110.4908, -7.7701],
      [110.4904, -7.7702],
      [110.4903, -7.7706],
      [110.4903, -7.771],
      [110.4905, -7.7711],
      [110.491, -7.7715],
      [110.4912, -7.7719],
      [110.4911, -7.7728],
      [110.4909, -7.7741],
      [110.4909, -7.7746],
      [110.4918, -7.7748],
      [110.4925, -7.7749],
      [110.4928, -7.7748],
      [110.4933, -7.7744],
      [110.4943, -7.7748],
      [110.4942, -7.7752],
      [110.4952, -7.7761],
      [110.4954, -7.7765],
      [110.4954, -7.7768],
      [110.4954, -7.7776],
      [110.4956, -7.778],
      [110.4958, -7.7782],
      [110.4955, -7.7784],
      [110.4955, -7.7789],
      [110.4957, -7.7791],
      [110.4953, -7.7791],
      [110.4948, -7.7791],
      [110.4937, -7.7793],
      [110.4924, -7.7794],
      [110.492, -7.7805],
      [110.4919, -7.7805],
      [110.4914, -7.7806],
      [110.491, -7.781],
      [110.4906, -7.7814],
      [110.4906, -7.782],
      [110.4909, -7.7823],
      [110.4914, -7.7826],
      [110.4911, -7.7829],
      [110.4905, -7.7829],
      [110.4902, -7.7834],
      [110.4902, -7.7838],
      [110.4903, -7.7841],
      [110.4905, -7.7843],
      [110.4909, -7.7842],
      [110.4911, -7.7841],
      [110.4912, -7.784],
      [110.4916, -7.784],
      [110.4923, -7.7843],
      [110.493, -7.7844],
      [110.493, -7.7852],
      [110.4935, -7.7857],
      [110.494, -7.786],
      [110.4942, -7.7865],
      [110.4945, -7.7869],
      [110.4948, -7.7873],
      [110.495, -7.7878],
      [110.4955, -7.7878],
      [110.4959, -7.7877],
      [110.4966, -7.7875],
      [110.497, -7.7874],
      [110.4971, -7.7877],
      [110.4971, -7.7879],
      [110.4968, -7.7884],
      [110.4965, -7.789],
      [110.4963, -7.7897],
      [110.4963, -7.7904],
      [110.4967, -7.79],
      [110.497, -7.7899],
      [110.4972, -7.7897],
      [110.4974, -7.7894],
      [110.4974, -7.7889],
      [110.498, -7.7883],
      [110.4985, -7.7882],
      [110.4989, -7.7886],
      [110.4993, -7.7896],
      [110.4996, -7.7904],
      [110.5004, -7.7905],
      [110.5011, -7.7902],
      [110.5028, -7.7902],
      [110.5032, -7.7901],
      [110.5039, -7.79],
      [110.5045, -7.7904],
      [110.5047, -7.7907],
      [110.5047, -7.7911],
      [110.505, -7.7915],
      [110.505, -7.7922],
      [110.5046, -7.7925],
      [110.5038, -7.793],
      [110.503, -7.7932],
      [110.5024, -7.7938],
      [110.5022, -7.7943],
      [110.5017, -7.795],
      [110.5014, -7.7953],
      [110.5009, -7.7956],
      [110.5008, -7.7959],
      [110.5007, -7.7964],
      [110.5004, -7.7965],
      [110.5, -7.7965],
      [110.4997, -7.7965],
      [110.5002, -7.7968],
      [110.501, -7.7966],
      [110.5013, -7.7967],
      [110.5019, -7.7969],
      [110.5023, -7.7969],
      [110.5024, -7.7972],
      [110.5026, -7.7973],
      [110.5031, -7.7974],
      [110.5035, -7.7974],
      [110.5038, -7.7975],
      [110.5039, -7.7978],
      [110.5047, -7.798],
      [110.5051, -7.798],
      [110.5057, -7.7979],
      [110.506, -7.7981],
      [110.5066, -7.7983],
      [110.5068, -7.7984],
      [110.5075, -7.7985],
      [110.5083, -7.7985],
      [110.5087, -7.7988],
      [110.5089, -7.7989],
      [110.5092, -7.7988],
      [110.5096, -7.7986],
      [110.51, -7.7988],
      [110.5108, -7.7991],
      [110.5113, -7.7994],
      [110.5117, -7.7982],
      [110.512, -7.7977],
      [110.5124, -7.7974],
      [110.5127, -7.7971],
      [110.5129, -7.7965],
      [110.5133, -7.7962],
      [110.5139, -7.7961],
      [110.5143, -7.7958],
      [110.5145, -7.7953],
      [110.5147, -7.7949],
      [110.5151, -7.7945],
      [110.5156, -7.7942],
      [110.5164, -7.7938],
      [110.5167, -7.7938],
      [110.5175, -7.7942],
      [110.5184, -7.7946],
      [110.5189, -7.7944],
      [110.5191, -7.7946],
      [110.5197, -7.7946],
      [110.5202, -7.7953],
      [110.5202, -7.7955],
      [110.5203, -7.7958],
      [110.5206, -7.7958],
      [110.5209, -7.7957],
      [110.5211, -7.7967],
      [110.5212, -7.7972],
      [110.5214, -7.7971],
      [110.5231, -7.7962],
      [110.5235, -7.7959],
      [110.524, -7.7951],
      [110.5246, -7.7952],
      [110.525, -7.7949],
      [110.5253, -7.7945],
      [110.5259, -7.7942],
      [110.5265, -7.7937],
      [110.5267, -7.7935],
      [110.527, -7.7932],
      [110.528, -7.7929],
      [110.5288, -7.7929],
      [110.5296, -7.7931],
      [110.5302, -7.793],
      [110.5301, -7.7928],
      [110.5301, -7.7927],
      [110.53, -7.7926],
      [110.53, -7.7924],
      [110.5299, -7.7921],
      [110.5298, -7.7915],
      [110.5296, -7.7912],
      [110.5294, -7.7908],
      [110.5293, -7.7905],
      [110.5291, -7.7904],
      [110.529, -7.7903],
      [110.5289, -7.7903],
      [110.5287, -7.7902],
      [110.5286, -7.7901],
      [110.528, -7.79],
      [110.5278, -7.7897],
      [110.5275, -7.7895],
      [110.5275, -7.7893],
      [110.5273, -7.789],
      [110.5271, -7.7889],
      [110.527, -7.7887],
      [110.5269, -7.7885],
      [110.5268, -7.7884],
      [110.5267, -7.7882],
      [110.5266, -7.7882],
      [110.5265, -7.7882],
      [110.5264, -7.7882],
      [110.5263, -7.7882],
      [110.5259, -7.7881],
      [110.5256, -7.7881],
      [110.5254, -7.7881],
      [110.5253, -7.7882],
      [110.5252, -7.788],
      [110.5251, -7.7878],
      [110.525, -7.7877],
      [110.525, -7.7876],
      [110.525, -7.7874],
      [110.525, -7.7873],
      [110.5249, -7.7872],
      [110.5249, -7.7871],
      [110.5248, -7.7869],
      [110.5246, -7.7868],
      [110.5245, -7.7867],
      [110.5243, -7.7866],
      [110.5241, -7.7866],
      [110.524, -7.7864],
      [110.5239, -7.7864],
      [110.5239, -7.7862],
      [110.5239, -7.7861],
      [110.5239, -7.7859],
      [110.5239, -7.7858],
      [110.524, -7.7854],
      [110.524, -7.7851],
      [110.5239, -7.7847],
      [110.5237, -7.7845],
      [110.5231, -7.7836],
      [110.5228, -7.7835],
      [110.5223, -7.7831],
      [110.5221, -7.783],
      [110.5217, -7.783],
      [110.5216, -7.7829],
      [110.5213, -7.7829],
      [110.5208, -7.783],
      [110.5206, -7.783],
      [110.5203, -7.783],
      [110.5195, -7.7829],
      [110.5189, -7.7828],
      [110.5178, -7.7824],
      [110.5172, -7.7821],
      [110.5163, -7.7814],
      [110.5158, -7.7812],
      [110.5156, -7.781],
      [110.5155, -7.781],
      [110.5148, -7.7807],
      [110.5136, -7.78],
      [110.5134, -7.7799],
      [110.513, -7.7795],
      [110.5124, -7.7791],
      [110.5118, -7.7782],
      [110.5117, -7.7781],
      [110.5116, -7.778],
      [110.5114, -7.7777],
      [110.5113, -7.7775],
      [110.5112, -7.7773],
      [110.511, -7.7769],
      [110.511, -7.7765],
      [110.5109, -7.7759],
      [110.5108, -7.7756],
      [110.5108, -7.7753],
      [110.5109, -7.7751],
      [110.5109, -7.7748],
      [110.5109, -7.7745],
      [110.511, -7.774],
      [110.5116, -7.7734],
      [110.5117, -7.7732],
      [110.5119, -7.7728],
      [110.512, -7.7726],
      [110.5121, -7.7724],
      [110.5121, -7.7723],
      [110.512, -7.7721],
      [110.5119, -7.7718],
      [110.5118, -7.7716]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PRAMBANAN',
    village: 'MADUREJO',
    border: [
      [110.4909, -7.7842],
      [110.491, -7.7847],
      [110.4911, -7.7849],
      [110.4914, -7.7849],
      [110.4916, -7.7852],
      [110.4913, -7.7856],
      [110.491, -7.786],
      [110.4909, -7.7864],
      [110.4909, -7.7867],
      [110.491, -7.7867],
      [110.4915, -7.7869],
      [110.4918, -7.787],
      [110.4917, -7.7873],
      [110.4911, -7.7877],
      [110.4908, -7.788],
      [110.4908, -7.7882],
      [110.491, -7.7885],
      [110.4914, -7.7887],
      [110.4911, -7.7894],
      [110.4905, -7.7893],
      [110.4906, -7.788],
      [110.4903, -7.7879],
      [110.4895, -7.7878],
      [110.4894, -7.7891],
      [110.4876, -7.7887],
      [110.4876, -7.7886],
      [110.4876, -7.7877],
      [110.4877, -7.7852],
      [110.487, -7.7852],
      [110.4872, -7.7845],
      [110.4884, -7.7847],
      [110.4893, -7.7847],
      [110.4892, -7.7837],
      [110.4874, -7.7834],
      [110.4849, -7.7814],
      [110.4832, -7.7814],
      [110.4824, -7.7814],
      [110.4825, -7.7833],
      [110.4821, -7.7832],
      [110.4811, -7.7831],
      [110.4803, -7.7831],
      [110.4784, -7.7832],
      [110.4773, -7.7818],
      [110.4763, -7.7802],
      [110.4757, -7.782],
      [110.4739, -7.7875],
      [110.4737, -7.7874],
      [110.4698, -7.7862],
      [110.4694, -7.7844],
      [110.4693, -7.7839],
      [110.4693, -7.7835],
      [110.4692, -7.7834],
      [110.4691, -7.7833],
      [110.4691, -7.7835],
      [110.4686, -7.7845],
      [110.468, -7.7854],
      [110.4674, -7.7857],
      [110.4672, -7.786],
      [110.4669, -7.7863],
      [110.4669, -7.7867],
      [110.4673, -7.7869],
      [110.4678, -7.7869],
      [110.4683, -7.7867],
      [110.4686, -7.7867],
      [110.4687, -7.787],
      [110.4685, -7.7875],
      [110.468, -7.7878],
      [110.4672, -7.7879],
      [110.4668, -7.7884],
      [110.4666, -7.7896],
      [110.4665, -7.7899],
      [110.4664, -7.7902],
      [110.4663, -7.7905],
      [110.4661, -7.791],
      [110.466, -7.7912],
      [110.4658, -7.7927],
      [110.4661, -7.7936],
      [110.4665, -7.7942],
      [110.4673, -7.795],
      [110.4673, -7.7953],
      [110.4675, -7.7958],
      [110.4676, -7.7962],
      [110.4675, -7.7974],
      [110.4671, -7.7984],
      [110.4666, -7.799],
      [110.4666, -7.7992],
      [110.4669, -7.7998],
      [110.4668, -7.8001],
      [110.467, -7.8009],
      [110.4675, -7.801],
      [110.4684, -7.8013],
      [110.4688, -7.8015],
      [110.4697, -7.8018],
      [110.4727, -7.8028],
      [110.473, -7.8012],
      [110.4743, -7.8015],
      [110.4745, -7.8031],
      [110.4743, -7.8046],
      [110.4741, -7.8059],
      [110.4759, -7.8065],
      [110.4762, -7.8066],
      [110.4765, -7.8067],
      [110.4783, -7.8073],
      [110.4783, -7.807],
      [110.4784, -7.8067],
      [110.4788, -7.8065],
      [110.479, -7.8064],
      [110.4798, -7.8063],
      [110.4803, -7.8063],
      [110.4808, -7.806],
      [110.481, -7.8059],
      [110.4811, -7.8055],
      [110.4814, -7.8055],
      [110.4814, -7.805],
      [110.4817, -7.805],
      [110.4821, -7.8052],
      [110.4822, -7.8052],
      [110.4827, -7.805],
      [110.4827, -7.8029],
      [110.4827, -7.8022],
      [110.4816, -7.8017],
      [110.4819, -7.8005],
      [110.4854, -7.801],
      [110.4867, -7.8011],
      [110.4872, -7.8012],
      [110.4877, -7.8012],
      [110.4898, -7.8013],
      [110.4906, -7.8014],
      [110.4935, -7.8017],
      [110.4945, -7.8017],
      [110.4953, -7.8013],
      [110.4961, -7.8007],
      [110.4957, -7.8006],
      [110.4955, -7.8004],
      [110.4953, -7.8001],
      [110.4954, -7.7997],
      [110.4953, -7.7993],
      [110.4952, -7.7987],
      [110.4955, -7.7984],
      [110.4959, -7.7982],
      [110.4962, -7.798],
      [110.4964, -7.7976],
      [110.4965, -7.7969],
      [110.4966, -7.7965],
      [110.4966, -7.7963],
      [110.4969, -7.7967],
      [110.4974, -7.797],
      [110.4978, -7.7971],
      [110.4982, -7.7969],
      [110.4982, -7.7967],
      [110.4986, -7.7967],
      [110.4987, -7.7967],
      [110.4989, -7.7966],
      [110.4991, -7.7965],
      [110.4995, -7.7966],
      [110.4997, -7.7965],
      [110.5, -7.7965],
      [110.5004, -7.7965],
      [110.5007, -7.7964],
      [110.5008, -7.7959],
      [110.5009, -7.7956],
      [110.5014, -7.7953],
      [110.5017, -7.795],
      [110.5022, -7.7943],
      [110.5024, -7.7938],
      [110.503, -7.7932],
      [110.5038, -7.793],
      [110.5046, -7.7925],
      [110.505, -7.7922],
      [110.505, -7.7915],
      [110.5047, -7.7911],
      [110.5047, -7.7907],
      [110.5045, -7.7904],
      [110.5039, -7.79],
      [110.5032, -7.7901],
      [110.5028, -7.7902],
      [110.5011, -7.7902],
      [110.5004, -7.7905],
      [110.4996, -7.7904],
      [110.4993, -7.7896],
      [110.4989, -7.7886],
      [110.4985, -7.7882],
      [110.498, -7.7883],
      [110.4974, -7.7889],
      [110.4974, -7.7894],
      [110.4972, -7.7897],
      [110.497, -7.7899],
      [110.4967, -7.79],
      [110.4963, -7.7904],
      [110.4963, -7.7897],
      [110.4965, -7.789],
      [110.4968, -7.7884],
      [110.4971, -7.7879],
      [110.4971, -7.7877],
      [110.497, -7.7874],
      [110.4966, -7.7875],
      [110.4959, -7.7877],
      [110.4955, -7.7878],
      [110.495, -7.7878],
      [110.4948, -7.7873],
      [110.4945, -7.7869],
      [110.4942, -7.7865],
      [110.494, -7.786],
      [110.4935, -7.7857],
      [110.493, -7.7852],
      [110.493, -7.7844],
      [110.4923, -7.7843],
      [110.4916, -7.784],
      [110.4912, -7.784],
      [110.4911, -7.7841],
      [110.4909, -7.7842]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PRAMBANAN',
    village: 'BOKOHARJO',
    border: [
      [110.4914, -7.7398],
      [110.4911, -7.7387],
      [110.491, -7.7388],
      [110.4908, -7.739],
      [110.4905, -7.7394],
      [110.4901, -7.7399],
      [110.4897, -7.7406],
      [110.4893, -7.7416],
      [110.4887, -7.7422],
      [110.4881, -7.7428],
      [110.4876, -7.7432],
      [110.4875, -7.7434],
      [110.4872, -7.7439],
      [110.4868, -7.7442],
      [110.4866, -7.745],
      [110.4867, -7.7454],
      [110.4868, -7.7456],
      [110.4871, -7.7462],
      [110.4873, -7.7468],
      [110.4879, -7.7475],
      [110.4886, -7.7484],
      [110.489, -7.749],
      [110.489, -7.7491],
      [110.4891, -7.7492],
      [110.4894, -7.7494],
      [110.4895, -7.7498],
      [110.4894, -7.7509],
      [110.4894, -7.7517],
      [110.4894, -7.7526],
      [110.4895, -7.7534],
      [110.4889, -7.7542],
      [110.4883, -7.755],
      [110.4878, -7.7558],
      [110.4876, -7.7562],
      [110.4875, -7.7565],
      [110.4869, -7.7572],
      [110.4864, -7.7582],
      [110.4859, -7.7591],
      [110.4854, -7.76],
      [110.4851, -7.7605],
      [110.4852, -7.7608],
      [110.4856, -7.7611],
      [110.4858, -7.7615],
      [110.486, -7.762],
      [110.4865, -7.7625],
      [110.487, -7.7633],
      [110.4871, -7.764],
      [110.4869, -7.7646],
      [110.4866, -7.765],
      [110.4863, -7.7655],
      [110.4859, -7.766],
      [110.4852, -7.7665],
      [110.4847, -7.7669],
      [110.4843, -7.7675],
      [110.4843, -7.7676],
      [110.4839, -7.768],
      [110.4835, -7.7682],
      [110.483, -7.7681],
      [110.4824, -7.7681],
      [110.4817, -7.7681],
      [110.4814, -7.7684],
      [110.4811, -7.7695],
      [110.4808, -7.7703],
      [110.4802, -7.7708],
      [110.4796, -7.771],
      [110.479, -7.7709],
      [110.4785, -7.7706],
      [110.4782, -7.7703],
      [110.4779, -7.77],
      [110.4774, -7.77],
      [110.4771, -7.7702],
      [110.4768, -7.7705],
      [110.4769, -7.7711],
      [110.477, -7.7715],
      [110.477, -7.7722],
      [110.477, -7.7729],
      [110.4771, -7.7735],
      [110.4772, -7.774],
      [110.4769, -7.7743],
      [110.4764, -7.7747],
      [110.4759, -7.7751],
      [110.4753, -7.7755],
      [110.475, -7.7759],
      [110.4744, -7.7763],
      [110.4737, -7.7764],
      [110.473, -7.7763],
      [110.4724, -7.7763],
      [110.472, -7.7766],
      [110.472, -7.7771],
      [110.472, -7.7775],
      [110.472, -7.7782],
      [110.4722, -7.7787],
      [110.4723, -7.779],
      [110.4723, -7.7791],
      [110.4724, -7.7793],
      [110.4722, -7.7795],
      [110.4716, -7.7796],
      [110.4709, -7.7795],
      [110.4702, -7.7795],
      [110.4695, -7.7795],
      [110.4693, -7.7797],
      [110.4692, -7.7799],
      [110.4693, -7.7803],
      [110.4696, -7.7804],
      [110.4698, -7.7808],
      [110.4699, -7.7808],
      [110.4698, -7.7814],
      [110.4693, -7.7827],
      [110.4691, -7.7833],
      [110.4692, -7.7834],
      [110.4693, -7.7835],
      [110.4693, -7.7839],
      [110.4694, -7.7844],
      [110.4698, -7.7862],
      [110.4737, -7.7874],
      [110.4739, -7.7875],
      [110.4757, -7.782],
      [110.4763, -7.7802],
      [110.4773, -7.7818],
      [110.4784, -7.7832],
      [110.4803, -7.7831],
      [110.4811, -7.7831],
      [110.4821, -7.7832],
      [110.4825, -7.7833],
      [110.4824, -7.7814],
      [110.4832, -7.7814],
      [110.4849, -7.7814],
      [110.4874, -7.7834],
      [110.4892, -7.7837],
      [110.4893, -7.7847],
      [110.4884, -7.7847],
      [110.4872, -7.7845],
      [110.487, -7.7852],
      [110.4877, -7.7852],
      [110.4876, -7.7877],
      [110.4876, -7.7886],
      [110.4876, -7.7887],
      [110.4894, -7.7891],
      [110.4895, -7.7878],
      [110.4903, -7.7879],
      [110.4906, -7.788],
      [110.4905, -7.7893],
      [110.4911, -7.7894],
      [110.4914, -7.7887],
      [110.491, -7.7885],
      [110.4908, -7.7882],
      [110.4908, -7.788],
      [110.4911, -7.7877],
      [110.4917, -7.7873],
      [110.4918, -7.787],
      [110.4915, -7.7869],
      [110.491, -7.7867],
      [110.4909, -7.7867],
      [110.4909, -7.7864],
      [110.491, -7.786],
      [110.4913, -7.7856],
      [110.4916, -7.7852],
      [110.4914, -7.7849],
      [110.4911, -7.7849],
      [110.491, -7.7847],
      [110.4909, -7.7842],
      [110.4905, -7.7843],
      [110.4903, -7.7841],
      [110.4902, -7.7838],
      [110.4902, -7.7834],
      [110.4905, -7.7829],
      [110.4911, -7.7829],
      [110.4914, -7.7826],
      [110.4909, -7.7823],
      [110.4906, -7.782],
      [110.4906, -7.7814],
      [110.491, -7.781],
      [110.4914, -7.7806],
      [110.4919, -7.7805],
      [110.492, -7.7805],
      [110.4924, -7.7794],
      [110.4937, -7.7793],
      [110.4948, -7.7791],
      [110.4953, -7.7791],
      [110.4957, -7.7791],
      [110.4955, -7.7789],
      [110.4955, -7.7784],
      [110.4958, -7.7782],
      [110.4956, -7.778],
      [110.4954, -7.7776],
      [110.4954, -7.7768],
      [110.4954, -7.7765],
      [110.4952, -7.7761],
      [110.4942, -7.7752],
      [110.4943, -7.7748],
      [110.4933, -7.7744],
      [110.4928, -7.7748],
      [110.4925, -7.7749],
      [110.4918, -7.7748],
      [110.4909, -7.7746],
      [110.4909, -7.7741],
      [110.4911, -7.7728],
      [110.4912, -7.7719],
      [110.491, -7.7715],
      [110.4905, -7.7711],
      [110.4903, -7.771],
      [110.4903, -7.7706],
      [110.4904, -7.7702],
      [110.4908, -7.7701],
      [110.4908, -7.7698],
      [110.4907, -7.7692],
      [110.4907, -7.7689],
      [110.4909, -7.7685],
      [110.4915, -7.7683],
      [110.4921, -7.7683],
      [110.4925, -7.7681],
      [110.493, -7.7678],
      [110.4939, -7.7679],
      [110.4945, -7.7679],
      [110.4951, -7.7678],
      [110.4957, -7.7677],
      [110.4948, -7.7675],
      [110.494, -7.7672],
      [110.4933, -7.7669],
      [110.493, -7.7668],
      [110.4919, -7.7669],
      [110.4918, -7.7667],
      [110.4918, -7.7665],
      [110.4917, -7.7662],
      [110.4917, -7.7661],
      [110.4918, -7.766],
      [110.4918, -7.7657],
      [110.4918, -7.7651],
      [110.4917, -7.7645],
      [110.4917, -7.7638],
      [110.4917, -7.7637],
      [110.4917, -7.7633],
      [110.4917, -7.7626],
      [110.4917, -7.7618],
      [110.4917, -7.7607],
      [110.4917, -7.7604],
      [110.4917, -7.7602],
      [110.4917, -7.7598],
      [110.4917, -7.7578],
      [110.4917, -7.7573],
      [110.4917, -7.7572],
      [110.4917, -7.7569],
      [110.4917, -7.7564],
      [110.4917, -7.7553],
      [110.4917, -7.7551],
      [110.4917, -7.755],
      [110.4917, -7.7549],
      [110.4917, -7.7538],
      [110.4917, -7.7531],
      [110.4924, -7.7531],
      [110.4925, -7.7509],
      [110.4917, -7.7509],
      [110.4917, -7.75],
      [110.4917, -7.7496],
      [110.4917, -7.749],
      [110.4919, -7.7476],
      [110.4919, -7.7459],
      [110.4919, -7.7444],
      [110.492, -7.743],
      [110.4919, -7.742],
      [110.4918, -7.7414],
      [110.4914, -7.7398],
      [110.4914, -7.7398]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'KALASAN',
    village: 'PURWOMARTANI',
    border: [
      [110.4625, -7.7389],
      [110.4623, -7.7387],
      [110.4614, -7.7383],
      [110.4613, -7.7366],
      [110.4612, -7.7364],
      [110.4603, -7.7361],
      [110.46, -7.736],
      [110.46, -7.735],
      [110.4592, -7.7343],
      [110.4589, -7.7333],
      [110.4581, -7.7329],
      [110.4575, -7.7323],
      [110.4573, -7.7318],
      [110.4573, -7.7311],
      [110.4574, -7.7306],
      [110.4572, -7.7306],
      [110.4567, -7.7305],
      [110.4554, -7.7301],
      [110.4544, -7.73],
      [110.4543, -7.7303],
      [110.454, -7.7308],
      [110.4538, -7.7309],
      [110.4537, -7.731],
      [110.4537, -7.7308],
      [110.4537, -7.7305],
      [110.453, -7.7302],
      [110.4523, -7.7302],
      [110.4525, -7.7316],
      [110.4529, -7.732],
      [110.4528, -7.7331],
      [110.4534, -7.7333],
      [110.4534, -7.7336],
      [110.4523, -7.7334],
      [110.4516, -7.7335],
      [110.4515, -7.7314],
      [110.4513, -7.7309],
      [110.451, -7.7308],
      [110.4503, -7.7308],
      [110.4493, -7.7306],
      [110.4485, -7.7304],
      [110.4484, -7.7305],
      [110.4483, -7.7306],
      [110.4483, -7.731],
      [110.4476, -7.7311],
      [110.4474, -7.7312],
      [110.4463, -7.7305],
      [110.4455, -7.7305],
      [110.4453, -7.7305],
      [110.4451, -7.7315],
      [110.4447, -7.7318],
      [110.4448, -7.7328],
      [110.4446, -7.7331],
      [110.4446, -7.7335],
      [110.4447, -7.7339],
      [110.4449, -7.734],
      [110.4449, -7.7342],
      [110.4447, -7.7344],
      [110.4444, -7.7344],
      [110.4443, -7.7346],
      [110.4444, -7.7349],
      [110.4445, -7.7351],
      [110.4446, -7.7355],
      [110.4448, -7.7356],
      [110.4448, -7.7363],
      [110.4449, -7.7369],
      [110.4447, -7.7374],
      [110.4449, -7.738],
      [110.4453, -7.7386],
      [110.4454, -7.7391],
      [110.4454, -7.7396],
      [110.4451, -7.74],
      [110.4447, -7.7406],
      [110.4447, -7.7411],
      [110.4449, -7.7415],
      [110.445, -7.742],
      [110.4453, -7.7425],
      [110.4457, -7.7429],
      [110.446, -7.7435],
      [110.4458, -7.744],
      [110.4455, -7.7441],
      [110.4454, -7.7445],
      [110.4455, -7.7448],
      [110.4459, -7.7453],
      [110.4459, -7.7453],
      [110.4464, -7.7456],
      [110.447, -7.7463],
      [110.4474, -7.7468],
      [110.4476, -7.7474],
      [110.4477, -7.7481],
      [110.4476, -7.7487],
      [110.4474, -7.7492],
      [110.4475, -7.7495],
      [110.448, -7.7499],
      [110.4482, -7.7503],
      [110.4484, -7.7508],
      [110.4483, -7.751],
      [110.4483, -7.7511],
      [110.4478, -7.7514],
      [110.4475, -7.7518],
      [110.4474, -7.7524],
      [110.4473, -7.7526],
      [110.4473, -7.7527],
      [110.4474, -7.7532],
      [110.4473, -7.7533],
      [110.4472, -7.7544],
      [110.4472, -7.7554],
      [110.447, -7.7563],
      [110.4469, -7.7567],
      [110.4467, -7.7572],
      [110.4463, -7.7574],
      [110.4457, -7.758],
      [110.4452, -7.7585],
      [110.4449, -7.759],
      [110.4448, -7.7591],
      [110.4444, -7.7594],
      [110.4439, -7.7598],
      [110.4436, -7.7604],
      [110.4436, -7.7611],
      [110.4435, -7.762],
      [110.4433, -7.7626],
      [110.4431, -7.7634],
      [110.4433, -7.764],
      [110.4438, -7.7644],
      [110.4441, -7.7652],
      [110.4442, -7.766],
      [110.4441, -7.7665],
      [110.4437, -7.7677],
      [110.4424, -7.7675],
      [110.4411, -7.767],
      [110.4406, -7.7677],
      [110.4405, -7.7684],
      [110.4404, -7.7691],
      [110.4401, -7.7698],
      [110.4401, -7.77],
      [110.4401, -7.7705],
      [110.4402, -7.7713],
      [110.4401, -7.7715],
      [110.4398, -7.772],
      [110.4401, -7.7726],
      [110.44, -7.773],
      [110.44, -7.7736],
      [110.4402, -7.7739],
      [110.4403, -7.7743],
      [110.4403, -7.7749],
      [110.4402, -7.7756],
      [110.44, -7.7756],
      [110.4394, -7.7758],
      [110.4391, -7.7763],
      [110.4392, -7.7769],
      [110.4394, -7.7773],
      [110.4389, -7.7781],
      [110.4388, -7.7787],
      [110.4388, -7.7788],
      [110.4388, -7.7796],
      [110.4388, -7.7801],
      [110.4386, -7.7807],
      [110.4385, -7.7812],
      [110.4389, -7.7821],
      [110.4393, -7.7834],
      [110.4393, -7.7846],
      [110.4393, -7.7852],
      [110.4399, -7.7857],
      [110.4404, -7.7864],
      [110.4412, -7.7862],
      [110.4415, -7.786],
      [110.4418, -7.7854],
      [110.442, -7.7848],
      [110.4448, -7.7845],
      [110.445, -7.7835],
      [110.4454, -7.7836],
      [110.447, -7.7836],
      [110.4483, -7.783],
      [110.4488, -7.7827],
      [110.4504, -7.7818],
      [110.4524, -7.7807],
      [110.4543, -7.7796],
      [110.4553, -7.7792],
      [110.4571, -7.7781],
      [110.4582, -7.7775],
      [110.4584, -7.7771],
      [110.4587, -7.7764],
      [110.459, -7.7761],
      [110.4592, -7.7757],
      [110.4591, -7.7751],
      [110.4591, -7.7747],
      [110.4594, -7.7741],
      [110.4596, -7.7734],
      [110.4597, -7.7733],
      [110.4599, -7.773],
      [110.4604, -7.7723],
      [110.4616, -7.7723],
      [110.4615, -7.7718],
      [110.4615, -7.7716],
      [110.4619, -7.7713],
      [110.4619, -7.7708],
      [110.4619, -7.7704],
      [110.4621, -7.77],
      [110.4622, -7.7691],
      [110.4625, -7.7681],
      [110.4624, -7.767],
      [110.4625, -7.7662],
      [110.4624, -7.7654],
      [110.4626, -7.7647],
      [110.4627, -7.7641],
      [110.4628, -7.7639],
      [110.4628, -7.7634],
      [110.4629, -7.7628],
      [110.4634, -7.7626],
      [110.4639, -7.7622],
      [110.4641, -7.7616],
      [110.4643, -7.7611],
      [110.4645, -7.7606],
      [110.4646, -7.7605],
      [110.4657, -7.7604],
      [110.4658, -7.7587],
      [110.4658, -7.7572],
      [110.4658, -7.7556],
      [110.4658, -7.7539],
      [110.4658, -7.7531],
      [110.4653, -7.7531],
      [110.4651, -7.7529],
      [110.4651, -7.7522],
      [110.4651, -7.7515],
      [110.4649, -7.7506],
      [110.4646, -7.75],
      [110.4642, -7.7495],
      [110.4642, -7.7491],
      [110.4643, -7.7487],
      [110.4643, -7.7482],
      [110.4643, -7.7477],
      [110.4639, -7.7472],
      [110.4634, -7.747],
      [110.4634, -7.7467],
      [110.4636, -7.7461],
      [110.4635, -7.7451],
      [110.4635, -7.7439],
      [110.4634, -7.7437],
      [110.4636, -7.7436],
      [110.4636, -7.7432],
      [110.4633, -7.7427],
      [110.4633, -7.7422],
      [110.4634, -7.7407],
      [110.4634, -7.7394],
      [110.4625, -7.7392],
      [110.4625, -7.7389]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'KALASAN',
    village: 'TIRTOMARTANI',
    border: [
      [110.4714, -7.7347],
      [110.4703, -7.7347],
      [110.4695, -7.7347],
      [110.469, -7.7346],
      [110.4688, -7.7344],
      [110.4685, -7.734],
      [110.4679, -7.7341],
      [110.4673, -7.7342],
      [110.4669, -7.7343],
      [110.4666, -7.7341],
      [110.4663, -7.734],
      [110.4661, -7.7336],
      [110.4655, -7.7328],
      [110.4654, -7.7328],
      [110.4644, -7.7333],
      [110.4629, -7.734],
      [110.4626, -7.7343],
      [110.4623, -7.7347],
      [110.4622, -7.7352],
      [110.4621, -7.7363],
      [110.4621, -7.737],
      [110.4623, -7.7372],
      [110.4626, -7.7375],
      [110.463, -7.7378],
      [110.4632, -7.7382],
      [110.4635, -7.7385],
      [110.464, -7.7389],
      [110.464, -7.7391],
      [110.4625, -7.7389],
      [110.4625, -7.7392],
      [110.4634, -7.7394],
      [110.4634, -7.7407],
      [110.4633, -7.7422],
      [110.4633, -7.7427],
      [110.4636, -7.7432],
      [110.4636, -7.7436],
      [110.4634, -7.7437],
      [110.4635, -7.7439],
      [110.4635, -7.7451],
      [110.4636, -7.7461],
      [110.4634, -7.7467],
      [110.4634, -7.747],
      [110.4639, -7.7472],
      [110.4643, -7.7477],
      [110.4643, -7.7482],
      [110.4643, -7.7487],
      [110.4642, -7.7491],
      [110.4642, -7.7495],
      [110.4646, -7.75],
      [110.4649, -7.7506],
      [110.4651, -7.7515],
      [110.4651, -7.7522],
      [110.4651, -7.7529],
      [110.4653, -7.7531],
      [110.4658, -7.7531],
      [110.4658, -7.7539],
      [110.4658, -7.7556],
      [110.4658, -7.7572],
      [110.4658, -7.7587],
      [110.4657, -7.7604],
      [110.4646, -7.7605],
      [110.4645, -7.7606],
      [110.4643, -7.7611],
      [110.4641, -7.7616],
      [110.4639, -7.7622],
      [110.4634, -7.7626],
      [110.4629, -7.7628],
      [110.4628, -7.7634],
      [110.4628, -7.7639],
      [110.4627, -7.7641],
      [110.4626, -7.7647],
      [110.4624, -7.7654],
      [110.4625, -7.7662],
      [110.4624, -7.767],
      [110.4625, -7.7681],
      [110.4622, -7.7691],
      [110.4621, -7.77],
      [110.4619, -7.7704],
      [110.4619, -7.7708],
      [110.4619, -7.7713],
      [110.4615, -7.7716],
      [110.4615, -7.7718],
      [110.4616, -7.7723],
      [110.4604, -7.7723],
      [110.4599, -7.773],
      [110.4597, -7.7733],
      [110.4596, -7.7734],
      [110.4594, -7.7741],
      [110.4591, -7.7747],
      [110.4591, -7.7751],
      [110.4592, -7.7757],
      [110.459, -7.7761],
      [110.4587, -7.7764],
      [110.4584, -7.7771],
      [110.4582, -7.7775],
      [110.4584, -7.7774],
      [110.4595, -7.7784],
      [110.4586, -7.7807],
      [110.4582, -7.782],
      [110.46, -7.7826],
      [110.4623, -7.7832],
      [110.4642, -7.7839],
      [110.4642, -7.7845],
      [110.4645, -7.7849],
      [110.4649, -7.7853],
      [110.4655, -7.7855],
      [110.4661, -7.7857],
      [110.467, -7.786],
      [110.4672, -7.786],
      [110.4674, -7.7857],
      [110.468, -7.7854],
      [110.4686, -7.7845],
      [110.4691, -7.7835],
      [110.4691, -7.7833],
      [110.4693, -7.7827],
      [110.4698, -7.7814],
      [110.4699, -7.7808],
      [110.4698, -7.7808],
      [110.4696, -7.7804],
      [110.4693, -7.7803],
      [110.4692, -7.7799],
      [110.4693, -7.7797],
      [110.4695, -7.7795],
      [110.4702, -7.7795],
      [110.4709, -7.7795],
      [110.4716, -7.7796],
      [110.4722, -7.7795],
      [110.4724, -7.7793],
      [110.4723, -7.7791],
      [110.4723, -7.779],
      [110.4722, -7.7787],
      [110.472, -7.7782],
      [110.472, -7.7775],
      [110.472, -7.7771],
      [110.472, -7.7766],
      [110.4724, -7.7763],
      [110.473, -7.7763],
      [110.4737, -7.7764],
      [110.4744, -7.7763],
      [110.475, -7.7759],
      [110.4753, -7.7755],
      [110.4759, -7.7751],
      [110.4764, -7.7747],
      [110.4769, -7.7743],
      [110.4772, -7.774],
      [110.4771, -7.7735],
      [110.477, -7.7729],
      [110.477, -7.7722],
      [110.477, -7.7715],
      [110.4769, -7.7711],
      [110.4768, -7.7705],
      [110.4771, -7.7702],
      [110.477, -7.7701],
      [110.4769, -7.77],
      [110.4755, -7.7696],
      [110.4754, -7.7696],
      [110.475, -7.7694],
      [110.4749, -7.7691],
      [110.475, -7.7686],
      [110.4752, -7.7684],
      [110.4752, -7.7681],
      [110.4751, -7.7677],
      [110.4753, -7.7674],
      [110.4752, -7.767],
      [110.4751, -7.7666],
      [110.4751, -7.7656],
      [110.4751, -7.7652],
      [110.4751, -7.7651],
      [110.4749, -7.7645],
      [110.4749, -7.7644],
      [110.475, -7.764],
      [110.4752, -7.7635],
      [110.4755, -7.7631],
      [110.4761, -7.7627],
      [110.4764, -7.7624],
      [110.4764, -7.7622],
      [110.4765, -7.762],
      [110.4763, -7.7619],
      [110.476, -7.7615],
      [110.4761, -7.7609],
      [110.4762, -7.7604],
      [110.4763, -7.76],
      [110.4765, -7.7596],
      [110.4768, -7.7595],
      [110.4769, -7.7591],
      [110.477, -7.7586],
      [110.4769, -7.758],
      [110.4769, -7.7575],
      [110.4767, -7.7569],
      [110.4769, -7.7566],
      [110.4775, -7.756],
      [110.4777, -7.7558],
      [110.4778, -7.7554],
      [110.478, -7.7552],
      [110.4782, -7.7551],
      [110.4782, -7.7548],
      [110.4779, -7.7546],
      [110.4779, -7.7542],
      [110.4777, -7.7534],
      [110.4776, -7.7525],
      [110.4775, -7.752],
      [110.477, -7.7514],
      [110.477, -7.7509],
      [110.4766, -7.7502],
      [110.4763, -7.7499],
      [110.4762, -7.7497],
      [110.4757, -7.7495],
      [110.4755, -7.7491],
      [110.4751, -7.7487],
      [110.4751, -7.7484],
      [110.4751, -7.7481],
      [110.4749, -7.7473],
      [110.4748, -7.7468],
      [110.4745, -7.7463],
      [110.4746, -7.7458],
      [110.4746, -7.7453],
      [110.4746, -7.7452],
      [110.4742, -7.7443],
      [110.474, -7.7436],
      [110.4739, -7.7428],
      [110.4735, -7.7417],
      [110.4731, -7.7409],
      [110.4731, -7.7406],
      [110.4732, -7.7403],
      [110.4728, -7.7399],
      [110.4729, -7.7394],
      [110.473, -7.7382],
      [110.4728, -7.7373],
      [110.4725, -7.7367],
      [110.4723, -7.7364],
      [110.472, -7.7361],
      [110.4717, -7.7359],
      [110.4715, -7.7357],
      [110.4716, -7.735],
      [110.4715, -7.7348],
      [110.4714, -7.7347]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'KALASAN',
    village: 'TAMANMARTANI',
    border: [
      [110.4842, -7.7078],
      [110.4841, -7.7078],
      [110.4838, -7.7079],
      [110.4833, -7.7077],
      [110.483, -7.7075],
      [110.4825, -7.7075],
      [110.4824, -7.7079],
      [110.4824, -7.7084],
      [110.4823, -7.7088],
      [110.4823, -7.7092],
      [110.4822, -7.7097],
      [110.4819, -7.7101],
      [110.4816, -7.7103],
      [110.4812, -7.7107],
      [110.4815, -7.7112],
      [110.4817, -7.7116],
      [110.4818, -7.7122],
      [110.4815, -7.7129],
      [110.4814, -7.7134],
      [110.4812, -7.7143],
      [110.481, -7.7148],
      [110.4811, -7.7154],
      [110.4812, -7.7156],
      [110.4815, -7.7154],
      [110.4819, -7.7155],
      [110.482, -7.7157],
      [110.4819, -7.7163],
      [110.482, -7.7169],
      [110.4822, -7.7177],
      [110.4822, -7.7183],
      [110.4821, -7.7184],
      [110.482, -7.7186],
      [110.4821, -7.719],
      [110.4822, -7.7196],
      [110.4823, -7.72],
      [110.482, -7.7204],
      [110.4817, -7.7208],
      [110.4817, -7.7211],
      [110.482, -7.7215],
      [110.4825, -7.722],
      [110.4828, -7.7223],
      [110.4827, -7.7228],
      [110.4827, -7.723],
      [110.483, -7.7236],
      [110.4834, -7.7244],
      [110.4834, -7.725],
      [110.4831, -7.7253],
      [110.4825, -7.7257],
      [110.4822, -7.7261],
      [110.4822, -7.7267],
      [110.4824, -7.727],
      [110.4825, -7.7275],
      [110.4829, -7.7278],
      [110.4832, -7.728],
      [110.4835, -7.7285],
      [110.4837, -7.7289],
      [110.4842, -7.7294],
      [110.4844, -7.7301],
      [110.4845, -7.7308],
      [110.484, -7.7313],
      [110.4837, -7.7317],
      [110.4836, -7.7326],
      [110.4835, -7.7334],
      [110.483, -7.7339],
      [110.4828, -7.7343],
      [110.4826, -7.7345],
      [110.4824, -7.7343],
      [110.4824, -7.7342],
      [110.4822, -7.7337],
      [110.4818, -7.7327],
      [110.4814, -7.7322],
      [110.481, -7.7313],
      [110.4809, -7.7313],
      [110.4804, -7.7314],
      [110.4796, -7.7317],
      [110.4787, -7.732],
      [110.4781, -7.7324],
      [110.478, -7.7328],
      [110.4773, -7.7344],
      [110.4772, -7.7345],
      [110.4768, -7.7346],
      [110.4758, -7.7346],
      [110.475, -7.7347],
      [110.4747, -7.7346],
      [110.4746, -7.7335],
      [110.4745, -7.7327],
      [110.4743, -7.7327],
      [110.474, -7.7327],
      [110.4727, -7.7327],
      [110.4714, -7.7327],
      [110.4704, -7.7328],
      [110.4703, -7.7328],
      [110.4706, -7.7335],
      [110.4708, -7.7338],
      [110.4714, -7.7347],
      [110.4715, -7.7348],
      [110.4716, -7.735],
      [110.4715, -7.7357],
      [110.4717, -7.7359],
      [110.472, -7.7361],
      [110.4723, -7.7364],
      [110.4725, -7.7367],
      [110.4728, -7.7373],
      [110.473, -7.7382],
      [110.4729, -7.7394],
      [110.4728, -7.7399],
      [110.4732, -7.7403],
      [110.4731, -7.7406],
      [110.4731, -7.7409],
      [110.4735, -7.7417],
      [110.4739, -7.7428],
      [110.474, -7.7436],
      [110.4742, -7.7443],
      [110.4746, -7.7452],
      [110.4746, -7.7453],
      [110.4746, -7.7458],
      [110.4745, -7.7463],
      [110.4748, -7.7468],
      [110.4749, -7.7473],
      [110.4751, -7.7481],
      [110.4751, -7.7484],
      [110.4751, -7.7487],
      [110.4755, -7.7491],
      [110.4757, -7.7495],
      [110.4762, -7.7497],
      [110.4763, -7.7499],
      [110.4766, -7.7502],
      [110.477, -7.7509],
      [110.477, -7.7514],
      [110.4775, -7.752],
      [110.4776, -7.7525],
      [110.4777, -7.7534],
      [110.4779, -7.7542],
      [110.4779, -7.7546],
      [110.4782, -7.7548],
      [110.4782, -7.7551],
      [110.478, -7.7552],
      [110.4778, -7.7554],
      [110.4777, -7.7558],
      [110.4775, -7.756],
      [110.4769, -7.7566],
      [110.4767, -7.7569],
      [110.4769, -7.7575],
      [110.4769, -7.758],
      [110.477, -7.7586],
      [110.4769, -7.7591],
      [110.4768, -7.7595],
      [110.4765, -7.7596],
      [110.4763, -7.76],
      [110.4762, -7.7604],
      [110.4761, -7.7609],
      [110.476, -7.7615],
      [110.4763, -7.7619],
      [110.4765, -7.762],
      [110.4764, -7.7622],
      [110.4764, -7.7624],
      [110.4761, -7.7627],
      [110.4755, -7.7631],
      [110.4752, -7.7635],
      [110.475, -7.764],
      [110.4749, -7.7644],
      [110.4749, -7.7645],
      [110.4751, -7.7651],
      [110.4751, -7.7652],
      [110.4751, -7.7656],
      [110.4751, -7.7666],
      [110.4752, -7.767],
      [110.4753, -7.7674],
      [110.4751, -7.7677],
      [110.4752, -7.7681],
      [110.4752, -7.7684],
      [110.475, -7.7686],
      [110.4749, -7.7691],
      [110.475, -7.7694],
      [110.4754, -7.7696],
      [110.4755, -7.7696],
      [110.4769, -7.77],
      [110.477, -7.7701],
      [110.4771, -7.7702],
      [110.4774, -7.77],
      [110.4779, -7.77],
      [110.4782, -7.7703],
      [110.4785, -7.7706],
      [110.479, -7.7709],
      [110.4796, -7.771],
      [110.4802, -7.7708],
      [110.4808, -7.7703],
      [110.4811, -7.7695],
      [110.4814, -7.7684],
      [110.4817, -7.7681],
      [110.4824, -7.7681],
      [110.483, -7.7681],
      [110.4835, -7.7682],
      [110.4839, -7.768],
      [110.4843, -7.7676],
      [110.4843, -7.7675],
      [110.4847, -7.7669],
      [110.4852, -7.7665],
      [110.4859, -7.766],
      [110.4863, -7.7655],
      [110.4866, -7.765],
      [110.4869, -7.7646],
      [110.4871, -7.764],
      [110.487, -7.7633],
      [110.4865, -7.7625],
      [110.486, -7.762],
      [110.4858, -7.7615],
      [110.4856, -7.7611],
      [110.4852, -7.7608],
      [110.4851, -7.7605],
      [110.4854, -7.76],
      [110.4859, -7.7591],
      [110.4864, -7.7582],
      [110.4869, -7.7572],
      [110.4875, -7.7565],
      [110.4876, -7.7562],
      [110.4878, -7.7558],
      [110.4883, -7.755],
      [110.4889, -7.7542],
      [110.4895, -7.7534],
      [110.4894, -7.7526],
      [110.4894, -7.7517],
      [110.4894, -7.7509],
      [110.4895, -7.7498],
      [110.4894, -7.7494],
      [110.4891, -7.7492],
      [110.489, -7.7491],
      [110.489, -7.749],
      [110.4886, -7.7484],
      [110.4879, -7.7475],
      [110.4873, -7.7468],
      [110.4871, -7.7462],
      [110.4868, -7.7456],
      [110.4867, -7.7454],
      [110.4866, -7.745],
      [110.4868, -7.7442],
      [110.4872, -7.7439],
      [110.4875, -7.7434],
      [110.4876, -7.7432],
      [110.4881, -7.7428],
      [110.4887, -7.7422],
      [110.4893, -7.7416],
      [110.4897, -7.7406],
      [110.4901, -7.7399],
      [110.4905, -7.7394],
      [110.4908, -7.739],
      [110.491, -7.7388],
      [110.4911, -7.7387],
      [110.4908, -7.7372],
      [110.4906, -7.7362],
      [110.4897, -7.7321],
      [110.4892, -7.7296],
      [110.4889, -7.7283],
      [110.4876, -7.723],
      [110.4875, -7.7226],
      [110.4871, -7.7207],
      [110.4871, -7.7205],
      [110.4863, -7.7172],
      [110.4862, -7.7163],
      [110.4857, -7.7142],
      [110.4855, -7.7136],
      [110.4854, -7.7131],
      [110.4854, -7.7129],
      [110.485, -7.711],
      [110.4846, -7.7094],
      [110.4842, -7.7078]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'KALASAN',
    village: 'SELOMARTANI',
    border: [
      [110.482, -7.7186],
      [110.4812, -7.7196],
      [110.4807, -7.7203],
      [110.4799, -7.7209],
      [110.4793, -7.7214],
      [110.4785, -7.7219],
      [110.4778, -7.7221],
      [110.4772, -7.7224],
      [110.4767, -7.7227],
      [110.4762, -7.7228],
      [110.4761, -7.7224],
      [110.4759, -7.7217],
      [110.476, -7.7207],
      [110.4755, -7.72],
      [110.475, -7.7197],
      [110.4745, -7.7194],
      [110.4743, -7.7192],
      [110.4737, -7.7185],
      [110.4735, -7.7174],
      [110.4729, -7.7157],
      [110.4721, -7.7164],
      [110.4717, -7.7167],
      [110.4714, -7.7165],
      [110.4714, -7.7159],
      [110.4713, -7.7152],
      [110.4711, -7.715],
      [110.4704, -7.7148],
      [110.4702, -7.7146],
      [110.4694, -7.7142],
      [110.4692, -7.7136],
      [110.4691, -7.7134],
      [110.4689, -7.713],
      [110.4685, -7.7123],
      [110.4685, -7.7118],
      [110.4682, -7.7114],
      [110.4679, -7.7111],
      [110.4676, -7.7105],
      [110.4674, -7.7103],
      [110.4674, -7.7098],
      [110.467, -7.7092],
      [110.466, -7.7096],
      [110.465, -7.7101],
      [110.4633, -7.7108],
      [110.4631, -7.7109],
      [110.461, -7.7118],
      [110.4615, -7.713],
      [110.4602, -7.7134],
      [110.4598, -7.7135],
      [110.4589, -7.7136],
      [110.4575, -7.7135],
      [110.4577, -7.7145],
      [110.4575, -7.7146],
      [110.4564, -7.7146],
      [110.4554, -7.7148],
      [110.4548, -7.7148],
      [110.4546, -7.7148],
      [110.4545, -7.7142],
      [110.4543, -7.7139],
      [110.454, -7.7134],
      [110.4536, -7.7138],
      [110.4535, -7.714],
      [110.4537, -7.7147],
      [110.4519, -7.715],
      [110.4519, -7.7155],
      [110.452, -7.7157],
      [110.452, -7.7158],
      [110.4518, -7.7168],
      [110.4505, -7.7171],
      [110.4492, -7.7176],
      [110.4482, -7.7177],
      [110.4473, -7.7174],
      [110.4468, -7.7169],
      [110.4462, -7.7169],
      [110.4449, -7.7172],
      [110.4438, -7.7175],
      [110.4426, -7.7176],
      [110.4423, -7.7177],
      [110.4419, -7.7181],
      [110.4417, -7.7186],
      [110.4414, -7.7189],
      [110.4409, -7.7189],
      [110.4406, -7.7191],
      [110.4405, -7.7192],
      [110.4404, -7.7193],
      [110.4403, -7.72],
      [110.4402, -7.7207],
      [110.4402, -7.721],
      [110.4403, -7.7214],
      [110.4404, -7.7218],
      [110.4405, -7.722],
      [110.4403, -7.7224],
      [110.4404, -7.7227],
      [110.4405, -7.723],
      [110.4406, -7.7233],
      [110.4407, -7.7234],
      [110.4407, -7.724],
      [110.4406, -7.7244],
      [110.4408, -7.7251],
      [110.441, -7.7256],
      [110.441, -7.7262],
      [110.4409, -7.7267],
      [110.4409, -7.727],
      [110.441, -7.7271],
      [110.4406, -7.7274],
      [110.4405, -7.7277],
      [110.4406, -7.7279],
      [110.4409, -7.7283],
      [110.4411, -7.7286],
      [110.4411, -7.7287],
      [110.4411, -7.7288],
      [110.441, -7.7292],
      [110.441, -7.7297],
      [110.4413, -7.73],
      [110.4416, -7.7303],
      [110.4414, -7.7308],
      [110.4413, -7.7313],
      [110.4413, -7.7317],
      [110.4414, -7.7318],
      [110.4416, -7.7319],
      [110.4421, -7.732],
      [110.4427, -7.732],
      [110.4431, -7.7319],
      [110.4434, -7.7321],
      [110.4435, -7.7325],
      [110.4434, -7.7329],
      [110.4432, -7.7334],
      [110.4433, -7.7336],
      [110.4435, -7.7342],
      [110.4437, -7.7347],
      [110.4442, -7.7352],
      [110.4444, -7.7354],
      [110.4446, -7.7355],
      [110.4445, -7.7351],
      [110.4444, -7.7349],
      [110.4443, -7.7346],
      [110.4444, -7.7344],
      [110.4447, -7.7344],
      [110.4449, -7.7342],
      [110.4449, -7.734],
      [110.4447, -7.7339],
      [110.4446, -7.7335],
      [110.4446, -7.7331],
      [110.4448, -7.7328],
      [110.4447, -7.7318],
      [110.4451, -7.7315],
      [110.4453, -7.7305],
      [110.4455, -7.7305],
      [110.4463, -7.7305],
      [110.4474, -7.7312],
      [110.4476, -7.7311],
      [110.4483, -7.731],
      [110.4483, -7.7306],
      [110.4484, -7.7305],
      [110.4485, -7.7304],
      [110.4493, -7.7306],
      [110.4503, -7.7308],
      [110.451, -7.7308],
      [110.4513, -7.7309],
      [110.4515, -7.7314],
      [110.4516, -7.7335],
      [110.4523, -7.7334],
      [110.4534, -7.7336],
      [110.4534, -7.7333],
      [110.4528, -7.7331],
      [110.4529, -7.732],
      [110.4525, -7.7316],
      [110.4523, -7.7302],
      [110.453, -7.7302],
      [110.4537, -7.7305],
      [110.4537, -7.7308],
      [110.4537, -7.731],
      [110.4538, -7.7309],
      [110.454, -7.7308],
      [110.4543, -7.7303],
      [110.4544, -7.73],
      [110.4554, -7.7301],
      [110.4567, -7.7305],
      [110.4572, -7.7306],
      [110.4574, -7.7306],
      [110.4573, -7.7311],
      [110.4573, -7.7318],
      [110.4575, -7.7323],
      [110.4581, -7.7329],
      [110.4589, -7.7333],
      [110.4592, -7.7343],
      [110.46, -7.735],
      [110.46, -7.736],
      [110.4603, -7.7361],
      [110.4612, -7.7364],
      [110.4613, -7.7366],
      [110.4614, -7.7383],
      [110.4623, -7.7387],
      [110.4625, -7.7389],
      [110.464, -7.7391],
      [110.464, -7.7389],
      [110.4635, -7.7385],
      [110.4632, -7.7382],
      [110.463, -7.7378],
      [110.4626, -7.7375],
      [110.4623, -7.7372],
      [110.4621, -7.737],
      [110.4621, -7.7363],
      [110.4622, -7.7352],
      [110.4623, -7.7347],
      [110.4626, -7.7343],
      [110.4629, -7.734],
      [110.4644, -7.7333],
      [110.4654, -7.7328],
      [110.4655, -7.7328],
      [110.4661, -7.7336],
      [110.4663, -7.734],
      [110.4666, -7.7341],
      [110.4669, -7.7343],
      [110.4673, -7.7342],
      [110.4679, -7.7341],
      [110.4685, -7.734],
      [110.4688, -7.7344],
      [110.469, -7.7346],
      [110.4695, -7.7347],
      [110.4703, -7.7347],
      [110.4714, -7.7347],
      [110.4708, -7.7338],
      [110.4706, -7.7335],
      [110.4703, -7.7328],
      [110.4704, -7.7328],
      [110.4714, -7.7327],
      [110.4727, -7.7327],
      [110.474, -7.7327],
      [110.4743, -7.7327],
      [110.4745, -7.7327],
      [110.4746, -7.7335],
      [110.4747, -7.7346],
      [110.475, -7.7347],
      [110.4758, -7.7346],
      [110.4768, -7.7346],
      [110.4772, -7.7345],
      [110.4773, -7.7344],
      [110.478, -7.7328],
      [110.4781, -7.7324],
      [110.4787, -7.732],
      [110.4796, -7.7317],
      [110.4804, -7.7314],
      [110.4809, -7.7313],
      [110.481, -7.7313],
      [110.4814, -7.7322],
      [110.4818, -7.7327],
      [110.4822, -7.7337],
      [110.4824, -7.7342],
      [110.4824, -7.7343],
      [110.4826, -7.7345],
      [110.4828, -7.7343],
      [110.483, -7.7339],
      [110.4835, -7.7334],
      [110.4836, -7.7326],
      [110.4837, -7.7317],
      [110.484, -7.7313],
      [110.4845, -7.7308],
      [110.4844, -7.7301],
      [110.4842, -7.7294],
      [110.4837, -7.7289],
      [110.4835, -7.7285],
      [110.4832, -7.728],
      [110.4829, -7.7278],
      [110.4825, -7.7275],
      [110.4824, -7.727],
      [110.4822, -7.7267],
      [110.4822, -7.7261],
      [110.4825, -7.7257],
      [110.4831, -7.7253],
      [110.4834, -7.725],
      [110.4834, -7.7244],
      [110.483, -7.7236],
      [110.4827, -7.723],
      [110.4827, -7.7228],
      [110.4828, -7.7223],
      [110.4825, -7.722],
      [110.482, -7.7215],
      [110.4817, -7.7211],
      [110.4817, -7.7208],
      [110.482, -7.7204],
      [110.4823, -7.72],
      [110.4822, -7.7196],
      [110.4821, -7.719],
      [110.482, -7.7186]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGEMPLAK',
    village: 'SINDUMARTANI',
    border: [
      [110.4775, -7.6777],
      [110.4774, -7.6777],
      [110.4767, -7.6779],
      [110.4762, -7.6769],
      [110.4761, -7.6761],
      [110.476, -7.6751],
      [110.4751, -7.6746],
      [110.4749, -7.6737],
      [110.4735, -7.6743],
      [110.4734, -7.6729],
      [110.4734, -7.672],
      [110.4716, -7.6725],
      [110.4719, -7.6738],
      [110.4709, -7.674],
      [110.4707, -7.6737],
      [110.4702, -7.6726],
      [110.4697, -7.6724],
      [110.4693, -7.6724],
      [110.4691, -7.6719],
      [110.4691, -7.6716],
      [110.469, -7.6712],
      [110.4689, -7.6708],
      [110.4686, -7.6708],
      [110.4681, -7.6707],
      [110.4678, -7.6706],
      [110.4675, -7.6704],
      [110.4666, -7.6706],
      [110.4666, -7.6713],
      [110.4666, -7.6718],
      [110.4669, -7.6724],
      [110.4671, -7.6727],
      [110.4671, -7.6729],
      [110.4674, -7.6734],
      [110.4678, -7.6743],
      [110.4683, -7.6754],
      [110.4684, -7.6756],
      [110.4686, -7.6762],
      [110.4687, -7.6763],
      [110.4687, -7.6765],
      [110.4683, -7.6775],
      [110.4678, -7.6787],
      [110.4674, -7.6793],
      [110.467, -7.6805],
      [110.4668, -7.6819],
      [110.4667, -7.6831],
      [110.4666, -7.6841],
      [110.4664, -7.6849],
      [110.4679, -7.685],
      [110.468, -7.6867],
      [110.4677, -7.6869],
      [110.4677, -7.6876],
      [110.4673, -7.6878],
      [110.4673, -7.6879],
      [110.4684, -7.688],
      [110.4684, -7.6883],
      [110.4686, -7.6884],
      [110.4689, -7.6881],
      [110.4692, -7.688],
      [110.4693, -7.688],
      [110.4692, -7.6892],
      [110.4704, -7.6891],
      [110.4703, -7.6904],
      [110.4704, -7.6915],
      [110.4713, -7.6929],
      [110.4716, -7.6939],
      [110.4718, -7.6949],
      [110.472, -7.6954],
      [110.4711, -7.6954],
      [110.4693, -7.6958],
      [110.4683, -7.696],
      [110.4671, -7.6959],
      [110.4665, -7.696],
      [110.4665, -7.696],
      [110.4666, -7.6965],
      [110.467, -7.6969],
      [110.4674, -7.6978],
      [110.4676, -7.6983],
      [110.4674, -7.6986],
      [110.4674, -7.6991],
      [110.4676, -7.6993],
      [110.4682, -7.6996],
      [110.4685, -7.6999],
      [110.4688, -7.7002],
      [110.4692, -7.7004],
      [110.4692, -7.701],
      [110.4692, -7.7015],
      [110.4693, -7.7022],
      [110.4695, -7.7031],
      [110.4698, -7.7037],
      [110.47, -7.7042],
      [110.4704, -7.7052],
      [110.4704, -7.7055],
      [110.4705, -7.7058],
      [110.471, -7.7063],
      [110.4711, -7.7065],
      [110.471, -7.7071],
      [110.4713, -7.7075],
      [110.4716, -7.7077],
      [110.4721, -7.7078],
      [110.4722, -7.7082],
      [110.4721, -7.7085],
      [110.4723, -7.7088],
      [110.4724, -7.7089],
      [110.473, -7.7092],
      [110.4736, -7.7093],
      [110.474, -7.71],
      [110.4748, -7.7109],
      [110.475, -7.7112],
      [110.4758, -7.7114],
      [110.4762, -7.7117],
      [110.4772, -7.7122],
      [110.4772, -7.7127],
      [110.4772, -7.7131],
      [110.4774, -7.7133],
      [110.4778, -7.7136],
      [110.4779, -7.7137],
      [110.4781, -7.7141],
      [110.4784, -7.7143],
      [110.4786, -7.7145],
      [110.4787, -7.7152],
      [110.479, -7.7157],
      [110.4793, -7.7163],
      [110.4795, -7.7164],
      [110.4797, -7.7166],
      [110.48, -7.717],
      [110.4801, -7.7172],
      [110.4805, -7.7173],
      [110.4809, -7.7175],
      [110.4812, -7.718],
      [110.4818, -7.7185],
      [110.482, -7.7186],
      [110.4821, -7.7184],
      [110.4822, -7.7183],
      [110.4822, -7.7177],
      [110.482, -7.7169],
      [110.4819, -7.7163],
      [110.482, -7.7157],
      [110.4819, -7.7155],
      [110.4815, -7.7154],
      [110.4812, -7.7156],
      [110.4811, -7.7154],
      [110.481, -7.7148],
      [110.4812, -7.7143],
      [110.4814, -7.7134],
      [110.4815, -7.7129],
      [110.4818, -7.7122],
      [110.4817, -7.7116],
      [110.4815, -7.7112],
      [110.4812, -7.7107],
      [110.4816, -7.7103],
      [110.4819, -7.7101],
      [110.4822, -7.7097],
      [110.4823, -7.7092],
      [110.4823, -7.7088],
      [110.4824, -7.7084],
      [110.4824, -7.7079],
      [110.4825, -7.7075],
      [110.483, -7.7075],
      [110.4833, -7.7077],
      [110.4838, -7.7079],
      [110.4841, -7.7078],
      [110.4842, -7.7078],
      [110.484, -7.7068],
      [110.4839, -7.7063],
      [110.4838, -7.7058],
      [110.4837, -7.7053],
      [110.4833, -7.7051],
      [110.4832, -7.7044],
      [110.4832, -7.7036],
      [110.4829, -7.702],
      [110.4827, -7.7012],
      [110.4827, -7.7011],
      [110.4821, -7.6979],
      [110.4817, -7.6963],
      [110.4812, -7.6941],
      [110.4808, -7.6924],
      [110.4801, -7.6892],
      [110.4796, -7.6873],
      [110.4796, -7.6872],
      [110.4791, -7.6851],
      [110.4784, -7.6819],
      [110.4781, -7.6804],
      [110.4779, -7.6795],
      [110.4777, -7.6785],
      [110.4775, -7.6777]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGEMPLAK',
    village: 'BIMOMARTANI',
    border: [
      [110.4686, -7.6884],
      [110.4687, -7.6885],
      [110.4687, -7.6892],
      [110.4691, -7.6894],
      [110.4689, -7.6903],
      [110.4676, -7.6903],
      [110.4674, -7.6907],
      [110.4668, -7.6906],
      [110.4667, -7.6903],
      [110.4666, -7.6903],
      [110.4664, -7.6906],
      [110.4662, -7.6906],
      [110.4661, -7.6905],
      [110.466, -7.6905],
      [110.4659, -7.6901],
      [110.4651, -7.69],
      [110.4643, -7.6898],
      [110.4639, -7.6895],
      [110.464, -7.6892],
      [110.4642, -7.6887],
      [110.4641, -7.6883],
      [110.4638, -7.688],
      [110.4633, -7.6878],
      [110.4629, -7.6875],
      [110.463, -7.6865],
      [110.463, -7.686],
      [110.4628, -7.6858],
      [110.4624, -7.6857],
      [110.4621, -7.6851],
      [110.462, -7.6845],
      [110.4621, -7.6844],
      [110.4623, -7.6837],
      [110.4629, -7.6823],
      [110.462, -7.6811],
      [110.4613, -7.6809],
      [110.4613, -7.6813],
      [110.4607, -7.6817],
      [110.4604, -7.6817],
      [110.4602, -7.6816],
      [110.46, -7.6812],
      [110.4598, -7.6808],
      [110.4597, -7.6801],
      [110.4595, -7.6798],
      [110.459, -7.6795],
      [110.4585, -7.6794],
      [110.458, -7.6795],
      [110.4579, -7.6795],
      [110.4573, -7.6799],
      [110.457, -7.68],
      [110.4565, -7.6802],
      [110.4559, -7.6801],
      [110.4554, -7.68],
      [110.4549, -7.6803],
      [110.4544, -7.6805],
      [110.4541, -7.6803],
      [110.4538, -7.6798],
      [110.4534, -7.6798],
      [110.4531, -7.6798],
      [110.4527, -7.6798],
      [110.4522, -7.6798],
      [110.4521, -7.6801],
      [110.4525, -7.6803],
      [110.4523, -7.6806],
      [110.4512, -7.6811],
      [110.4499, -7.6815],
      [110.4489, -7.6817],
      [110.4481, -7.682],
      [110.4481, -7.6824],
      [110.448, -7.6826],
      [110.448, -7.6829],
      [110.4484, -7.6833],
      [110.4484, -7.6837],
      [110.4483, -7.684],
      [110.4484, -7.6847],
      [110.4484, -7.685],
      [110.448, -7.6854],
      [110.448, -7.6864],
      [110.4482, -7.687],
      [110.4483, -7.6875],
      [110.4483, -7.6877],
      [110.4482, -7.6879],
      [110.4478, -7.6884],
      [110.4477, -7.6885],
      [110.4477, -7.6889],
      [110.4479, -7.6893],
      [110.4505, -7.6892],
      [110.4527, -7.6891],
      [110.4532, -7.6891],
      [110.4533, -7.6892],
      [110.4534, -7.6895],
      [110.4537, -7.6898],
      [110.454, -7.6898],
      [110.4542, -7.6899],
      [110.4544, -7.69],
      [110.4544, -7.6902],
      [110.4545, -7.6905],
      [110.4542, -7.6911],
      [110.4538, -7.6916],
      [110.4541, -7.692],
      [110.4543, -7.6925],
      [110.4544, -7.6933],
      [110.4545, -7.6936],
      [110.4547, -7.6939],
      [110.4549, -7.6943],
      [110.4549, -7.6946],
      [110.4545, -7.6951],
      [110.4543, -7.6953],
      [110.4543, -7.6958],
      [110.4543, -7.696],
      [110.4541, -7.6963],
      [110.4542, -7.6966],
      [110.4542, -7.6967],
      [110.4545, -7.6969],
      [110.4547, -7.6972],
      [110.4546, -7.6974],
      [110.4544, -7.698],
      [110.4546, -7.6981],
      [110.4548, -7.6983],
      [110.4549, -7.6984],
      [110.4553, -7.6997],
      [110.4556, -7.6996],
      [110.4558, -7.7003],
      [110.4567, -7.701],
      [110.4569, -7.7013],
      [110.4571, -7.7019],
      [110.4575, -7.7028],
      [110.4576, -7.7029],
      [110.4578, -7.703],
      [110.458, -7.7032],
      [110.4581, -7.7032],
      [110.4581, -7.7037],
      [110.4588, -7.7049],
      [110.4592, -7.7054],
      [110.4595, -7.7056],
      [110.4595, -7.7057],
      [110.4604, -7.7079],
      [110.461, -7.7094],
      [110.4611, -7.7095],
      [110.4613, -7.7097],
      [110.4607, -7.7098],
      [110.4603, -7.71],
      [110.4602, -7.7101],
      [110.4604, -7.7108],
      [110.4606, -7.7112],
      [110.461, -7.7118],
      [110.4631, -7.7109],
      [110.4633, -7.7108],
      [110.465, -7.7101],
      [110.466, -7.7096],
      [110.467, -7.7092],
      [110.4674, -7.7098],
      [110.4674, -7.7103],
      [110.4676, -7.7105],
      [110.4679, -7.7111],
      [110.4682, -7.7114],
      [110.4685, -7.7118],
      [110.4685, -7.7123],
      [110.4689, -7.713],
      [110.4691, -7.7134],
      [110.4692, -7.7136],
      [110.4694, -7.7142],
      [110.4702, -7.7146],
      [110.4704, -7.7148],
      [110.4711, -7.715],
      [110.4713, -7.7152],
      [110.4714, -7.7159],
      [110.4714, -7.7165],
      [110.4717, -7.7167],
      [110.4721, -7.7164],
      [110.4729, -7.7157],
      [110.4735, -7.7174],
      [110.4737, -7.7185],
      [110.4743, -7.7192],
      [110.4745, -7.7194],
      [110.475, -7.7197],
      [110.4755, -7.72],
      [110.476, -7.7207],
      [110.4759, -7.7217],
      [110.4761, -7.7224],
      [110.4762, -7.7228],
      [110.4767, -7.7227],
      [110.4772, -7.7224],
      [110.4778, -7.7221],
      [110.4785, -7.7219],
      [110.4793, -7.7214],
      [110.4799, -7.7209],
      [110.4807, -7.7203],
      [110.4812, -7.7196],
      [110.482, -7.7186],
      [110.4818, -7.7185],
      [110.4812, -7.718],
      [110.4809, -7.7175],
      [110.4805, -7.7173],
      [110.4801, -7.7172],
      [110.48, -7.717],
      [110.4797, -7.7166],
      [110.4795, -7.7164],
      [110.4793, -7.7163],
      [110.479, -7.7157],
      [110.4787, -7.7152],
      [110.4786, -7.7145],
      [110.4784, -7.7143],
      [110.4781, -7.7141],
      [110.4779, -7.7137],
      [110.4778, -7.7136],
      [110.4774, -7.7133],
      [110.4772, -7.7131],
      [110.4772, -7.7127],
      [110.4772, -7.7122],
      [110.4762, -7.7117],
      [110.4758, -7.7114],
      [110.475, -7.7112],
      [110.4748, -7.7109],
      [110.474, -7.71],
      [110.4736, -7.7093],
      [110.473, -7.7092],
      [110.4724, -7.7089],
      [110.4723, -7.7088],
      [110.4721, -7.7085],
      [110.4722, -7.7082],
      [110.4721, -7.7078],
      [110.4716, -7.7077],
      [110.4713, -7.7075],
      [110.471, -7.7071],
      [110.4711, -7.7065],
      [110.471, -7.7063],
      [110.4705, -7.7058],
      [110.4704, -7.7055],
      [110.4704, -7.7052],
      [110.47, -7.7042],
      [110.4698, -7.7037],
      [110.4695, -7.7031],
      [110.4693, -7.7022],
      [110.4692, -7.7015],
      [110.4692, -7.701],
      [110.4692, -7.7004],
      [110.4688, -7.7002],
      [110.4685, -7.6999],
      [110.4682, -7.6996],
      [110.4676, -7.6993],
      [110.4674, -7.6991],
      [110.4674, -7.6986],
      [110.4676, -7.6983],
      [110.4674, -7.6978],
      [110.467, -7.6969],
      [110.4666, -7.6965],
      [110.4665, -7.696],
      [110.4665, -7.696],
      [110.4671, -7.6959],
      [110.4683, -7.696],
      [110.4693, -7.6958],
      [110.4711, -7.6954],
      [110.472, -7.6954],
      [110.4718, -7.6949],
      [110.4716, -7.6939],
      [110.4713, -7.6929],
      [110.4704, -7.6915],
      [110.4703, -7.6904],
      [110.4704, -7.6891],
      [110.4692, -7.6892],
      [110.4693, -7.688],
      [110.4692, -7.688],
      [110.4689, -7.6881],
      [110.4686, -7.6884]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGEMPLAK',
    village: 'WIDODOMARTANI',
    border: [
      [110.4475, -7.6734],
      [110.4475, -7.673],
      [110.4473, -7.673],
      [110.4471, -7.6732],
      [110.4469, -7.6739],
      [110.4466, -7.6744],
      [110.4464, -7.6747],
      [110.4456, -7.675],
      [110.445, -7.675],
      [110.4447, -7.675],
      [110.444, -7.6749],
      [110.4431, -7.6748],
      [110.4422, -7.6748],
      [110.442, -7.6748],
      [110.4417, -7.6752],
      [110.4419, -7.6759],
      [110.4418, -7.6765],
      [110.442, -7.6772],
      [110.4419, -7.6777],
      [110.4419, -7.6782],
      [110.4422, -7.679],
      [110.4421, -7.679],
      [110.4417, -7.6789],
      [110.4406, -7.6789],
      [110.4399, -7.679],
      [110.439, -7.679],
      [110.4386, -7.679],
      [110.4387, -7.6792],
      [110.4391, -7.6796],
      [110.4393, -7.6798],
      [110.4396, -7.68],
      [110.4397, -7.6801],
      [110.4398, -7.6803],
      [110.4396, -7.681],
      [110.4397, -7.6815],
      [110.4395, -7.6819],
      [110.4394, -7.6824],
      [110.4394, -7.6827],
      [110.4394, -7.683],
      [110.4388, -7.6831],
      [110.4382, -7.6831],
      [110.4383, -7.684],
      [110.4384, -7.6842],
      [110.4385, -7.6843],
      [110.4386, -7.6846],
      [110.4385, -7.6851],
      [110.4385, -7.6857],
      [110.4386, -7.687],
      [110.4396, -7.6868],
      [110.4399, -7.6868],
      [110.4405, -7.6865],
      [110.4408, -7.6873],
      [110.4409, -7.6879],
      [110.441, -7.688],
      [110.4412, -7.688],
      [110.4414, -7.6881],
      [110.4422, -7.6897],
      [110.4425, -7.6905],
      [110.4426, -7.6908],
      [110.4426, -7.691],
      [110.4426, -7.6916],
      [110.4428, -7.6925],
      [110.443, -7.693],
      [110.4431, -7.6939],
      [110.4432, -7.6943],
      [110.4434, -7.6946],
      [110.4435, -7.6948],
      [110.4433, -7.6953],
      [110.4437, -7.696],
      [110.4439, -7.6963],
      [110.4443, -7.6966],
      [110.4444, -7.6974],
      [110.4443, -7.698],
      [110.4442, -7.6988],
      [110.4443, -7.6995],
      [110.4442, -7.7004],
      [110.4441, -7.7003],
      [110.4427, -7.7002],
      [110.4426, -7.7014],
      [110.4426, -7.702],
      [110.4427, -7.7025],
      [110.4429, -7.7039],
      [110.4428, -7.7043],
      [110.4428, -7.7047],
      [110.4431, -7.7051],
      [110.4432, -7.7055],
      [110.4429, -7.7059],
      [110.4425, -7.7064],
      [110.4424, -7.7068],
      [110.4426, -7.7075],
      [110.4427, -7.708],
      [110.4426, -7.7086],
      [110.4427, -7.709],
      [110.4431, -7.7094],
      [110.4432, -7.7098],
      [110.4429, -7.71],
      [110.4425, -7.7101],
      [110.4418, -7.7103],
      [110.4415, -7.7109],
      [110.4414, -7.7116],
      [110.4408, -7.712],
      [110.4408, -7.7127],
      [110.4406, -7.7132],
      [110.4407, -7.7136],
      [110.4408, -7.7139],
      [110.4407, -7.7147],
      [110.4406, -7.7153],
      [110.4402, -7.716],
      [110.44, -7.7164],
      [110.4399, -7.7169],
      [110.4403, -7.7176],
      [110.4405, -7.7182],
      [110.4405, -7.7186],
      [110.4405, -7.7192],
      [110.4406, -7.7191],
      [110.4409, -7.7189],
      [110.4414, -7.7189],
      [110.4417, -7.7186],
      [110.4419, -7.7181],
      [110.4423, -7.7177],
      [110.4426, -7.7176],
      [110.4438, -7.7175],
      [110.4449, -7.7172],
      [110.4462, -7.7169],
      [110.4468, -7.7169],
      [110.4473, -7.7174],
      [110.4482, -7.7177],
      [110.4492, -7.7176],
      [110.4505, -7.7171],
      [110.4518, -7.7168],
      [110.452, -7.7158],
      [110.452, -7.7157],
      [110.4519, -7.7155],
      [110.4519, -7.715],
      [110.4537, -7.7147],
      [110.4535, -7.714],
      [110.4536, -7.7138],
      [110.454, -7.7134],
      [110.4543, -7.7139],
      [110.4545, -7.7142],
      [110.4546, -7.7148],
      [110.4548, -7.7148],
      [110.4554, -7.7148],
      [110.4564, -7.7146],
      [110.4575, -7.7146],
      [110.4577, -7.7145],
      [110.4575, -7.7135],
      [110.4589, -7.7136],
      [110.4598, -7.7135],
      [110.4602, -7.7134],
      [110.4615, -7.713],
      [110.461, -7.7118],
      [110.4606, -7.7112],
      [110.4604, -7.7108],
      [110.4602, -7.7101],
      [110.4603, -7.71],
      [110.4607, -7.7098],
      [110.4613, -7.7097],
      [110.4611, -7.7095],
      [110.461, -7.7094],
      [110.4604, -7.7079],
      [110.4595, -7.7057],
      [110.4595, -7.7056],
      [110.4592, -7.7054],
      [110.4588, -7.7049],
      [110.4581, -7.7037],
      [110.4581, -7.7032],
      [110.458, -7.7032],
      [110.4578, -7.703],
      [110.4576, -7.7029],
      [110.4575, -7.7028],
      [110.4571, -7.7019],
      [110.4569, -7.7013],
      [110.4567, -7.701],
      [110.4558, -7.7003],
      [110.4556, -7.6996],
      [110.4553, -7.6997],
      [110.4549, -7.6984],
      [110.4548, -7.6983],
      [110.4546, -7.6981],
      [110.4544, -7.698],
      [110.4546, -7.6974],
      [110.4547, -7.6972],
      [110.4545, -7.6969],
      [110.4542, -7.6967],
      [110.4542, -7.6966],
      [110.4541, -7.6963],
      [110.4543, -7.696],
      [110.4543, -7.6958],
      [110.4543, -7.6953],
      [110.4545, -7.6951],
      [110.4549, -7.6946],
      [110.4549, -7.6943],
      [110.4547, -7.6939],
      [110.4545, -7.6936],
      [110.4544, -7.6933],
      [110.4543, -7.6925],
      [110.4541, -7.692],
      [110.4538, -7.6916],
      [110.4542, -7.6911],
      [110.4545, -7.6905],
      [110.4544, -7.6902],
      [110.4544, -7.69],
      [110.4542, -7.6899],
      [110.454, -7.6898],
      [110.4537, -7.6898],
      [110.4534, -7.6895],
      [110.4533, -7.6892],
      [110.4532, -7.6891],
      [110.4527, -7.6891],
      [110.4505, -7.6892],
      [110.4479, -7.6893],
      [110.4477, -7.6889],
      [110.4477, -7.6885],
      [110.4478, -7.6884],
      [110.4482, -7.6879],
      [110.4483, -7.6877],
      [110.4483, -7.6875],
      [110.4482, -7.687],
      [110.448, -7.6864],
      [110.448, -7.6854],
      [110.4484, -7.685],
      [110.4484, -7.6847],
      [110.4483, -7.684],
      [110.4484, -7.6837],
      [110.4484, -7.6833],
      [110.448, -7.6829],
      [110.448, -7.6826],
      [110.4481, -7.6824],
      [110.4481, -7.682],
      [110.4479, -7.682],
      [110.4478, -7.682],
      [110.4475, -7.6819],
      [110.4474, -7.6817],
      [110.4476, -7.6815],
      [110.4477, -7.6813],
      [110.448, -7.6809],
      [110.4481, -7.6802],
      [110.4484, -7.6795],
      [110.4484, -7.6794],
      [110.4484, -7.6792],
      [110.4484, -7.6789],
      [110.4488, -7.6788],
      [110.4488, -7.6787],
      [110.4487, -7.6786],
      [110.4486, -7.6783],
      [110.4483, -7.6781],
      [110.4482, -7.6778],
      [110.4483, -7.6777],
      [110.4486, -7.6775],
      [110.4486, -7.6772],
      [110.4484, -7.6772],
      [110.4481, -7.6772],
      [110.4475, -7.6754],
      [110.4473, -7.6751],
      [110.4473, -7.6748],
      [110.4473, -7.6747],
      [110.4473, -7.6745],
      [110.4475, -7.6737],
      [110.4475, -7.6734]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGEMPLAK',
    village: 'WEDOMARTANI',
    border: [
      [110.4408, -7.712],
      [110.4407, -7.7117],
      [110.4404, -7.7107],
      [110.4403, -7.71],
      [110.4399, -7.7093],
      [110.4398, -7.7089],
      [110.4397, -7.7086],
      [110.4392, -7.7082],
      [110.4393, -7.7074],
      [110.4387, -7.7075],
      [110.4384, -7.7075],
      [110.4374, -7.7076],
      [110.4365, -7.7075],
      [110.4355, -7.7078],
      [110.4345, -7.7078],
      [110.4343, -7.7079],
      [110.4342, -7.7082],
      [110.4342, -7.7085],
      [110.4344, -7.7088],
      [110.4346, -7.7093],
      [110.4347, -7.7099],
      [110.4347, -7.7105],
      [110.4345, -7.7117],
      [110.4344, -7.7133],
      [110.4344, -7.7134],
      [110.4342, -7.7137],
      [110.4333, -7.7145],
      [110.4324, -7.7147],
      [110.4307, -7.715],
      [110.4292, -7.7154],
      [110.4288, -7.7155],
      [110.4271, -7.7159],
      [110.4256, -7.7166],
      [110.4257, -7.7172],
      [110.4257, -7.7178],
      [110.4257, -7.718],
      [110.426, -7.7185],
      [110.4263, -7.7191],
      [110.4263, -7.7198],
      [110.4267, -7.7203],
      [110.4267, -7.7206],
      [110.4268, -7.7211],
      [110.4267, -7.7216],
      [110.4267, -7.722],
      [110.4263, -7.7227],
      [110.4254, -7.7229],
      [110.4245, -7.723],
      [110.4238, -7.7233],
      [110.4219, -7.7232],
      [110.4211, -7.723],
      [110.4194, -7.7223],
      [110.4182, -7.7219],
      [110.4177, -7.7219],
      [110.4164, -7.7216],
      [110.4159, -7.7214],
      [110.414, -7.7207],
      [110.4137, -7.7203],
      [110.4135, -7.7203],
      [110.4126, -7.7201],
      [110.4123, -7.7203],
      [110.4122, -7.7207],
      [110.4124, -7.7211],
      [110.4123, -7.7217],
      [110.4119, -7.7223],
      [110.4121, -7.7231],
      [110.4121, -7.7235],
      [110.412, -7.724],
      [110.4121, -7.724],
      [110.4127, -7.724],
      [110.4131, -7.7241],
      [110.4134, -7.7244],
      [110.4133, -7.7247],
      [110.4131, -7.7252],
      [110.4131, -7.7264],
      [110.413, -7.727],
      [110.4126, -7.7271],
      [110.4125, -7.7272],
      [110.4123, -7.7277],
      [110.4115, -7.7286],
      [110.4115, -7.7287],
      [110.411, -7.7296],
      [110.4105, -7.7304],
      [110.4106, -7.7308],
      [110.4106, -7.7312],
      [110.4105, -7.7316],
      [110.4101, -7.7318],
      [110.4097, -7.732],
      [110.4093, -7.7322],
      [110.4094, -7.7323],
      [110.41, -7.7326],
      [110.4101, -7.733],
      [110.4099, -7.7333],
      [110.4097, -7.7336],
      [110.4094, -7.7338],
      [110.4094, -7.7342],
      [110.4093, -7.7347],
      [110.4091, -7.735],
      [110.409, -7.7355],
      [110.4091, -7.736],
      [110.4094, -7.7366],
      [110.4096, -7.737],
      [110.4095, -7.7373],
      [110.4092, -7.7375],
      [110.409, -7.7375],
      [110.4088, -7.7377],
      [110.4087, -7.738],
      [110.4091, -7.7382],
      [110.4095, -7.7385],
      [110.4097, -7.7389],
      [110.4096, -7.7394],
      [110.4098, -7.7395],
      [110.4102, -7.7398],
      [110.4102, -7.7401],
      [110.4099, -7.7404],
      [110.4095, -7.7409],
      [110.4093, -7.7415],
      [110.4094, -7.7419],
      [110.4097, -7.7429],
      [110.4095, -7.7435],
      [110.4093, -7.7441],
      [110.4093, -7.7446],
      [110.4094, -7.7452],
      [110.409, -7.7455],
      [110.4087, -7.7458],
      [110.4087, -7.7468],
      [110.4088, -7.7474],
      [110.409, -7.7473],
      [110.4099, -7.7482],
      [110.4109, -7.7489],
      [110.4115, -7.749],
      [110.412, -7.7491],
      [110.4123, -7.7495],
      [110.4123, -7.7496],
      [110.4124, -7.75],
      [110.4123, -7.7502],
      [110.4123, -7.7513],
      [110.4121, -7.752],
      [110.4123, -7.7524],
      [110.4129, -7.7528],
      [110.4135, -7.7531],
      [110.4139, -7.7533],
      [110.4141, -7.7537],
      [110.4133, -7.7539],
      [110.4127, -7.7541],
      [110.4128, -7.7546],
      [110.4131, -7.7552],
      [110.4133, -7.7559],
      [110.4134, -7.7563],
      [110.4137, -7.7564],
      [110.414, -7.756],
      [110.414, -7.7556],
      [110.4141, -7.7551],
      [110.4143, -7.7552],
      [110.4147, -7.7552],
      [110.4149, -7.7556],
      [110.4149, -7.7559],
      [110.4147, -7.7566],
      [110.4148, -7.7565],
      [110.4153, -7.7563],
      [110.4155, -7.7555],
      [110.4153, -7.7547],
      [110.4154, -7.7541],
      [110.4158, -7.7532],
      [110.416, -7.753],
      [110.4162, -7.7523],
      [110.4167, -7.7519],
      [110.4167, -7.7516],
      [110.4168, -7.7513],
      [110.4165, -7.7509],
      [110.4189, -7.7508],
      [110.4194, -7.7508],
      [110.4195, -7.7522],
      [110.4216, -7.7525],
      [110.4225, -7.7527],
      [110.4227, -7.7523],
      [110.4236, -7.7525],
      [110.4236, -7.7529],
      [110.4246, -7.753],
      [110.4269, -7.7532],
      [110.4272, -7.7528],
      [110.4274, -7.7525],
      [110.4274, -7.7518],
      [110.4276, -7.7512],
      [110.4288, -7.7515],
      [110.4295, -7.7514],
      [110.4302, -7.7513],
      [110.432, -7.7513],
      [110.4334, -7.7513],
      [110.4335, -7.7513],
      [110.4336, -7.7504],
      [110.4335, -7.7498],
      [110.4336, -7.7494],
      [110.4337, -7.7491],
      [110.4336, -7.7489],
      [110.4332, -7.7487],
      [110.4331, -7.7483],
      [110.4331, -7.7478],
      [110.433, -7.7472],
      [110.4329, -7.7466],
      [110.4329, -7.7462],
      [110.433, -7.7458],
      [110.4346, -7.746],
      [110.4354, -7.7461],
      [110.4355, -7.7457],
      [110.4352, -7.7449],
      [110.435, -7.7441],
      [110.4344, -7.744],
      [110.4343, -7.7434],
      [110.4353, -7.7436],
      [110.4362, -7.7436],
      [110.4367, -7.7437],
      [110.4366, -7.7445],
      [110.4363, -7.745],
      [110.4371, -7.7454],
      [110.4379, -7.7458],
      [110.4382, -7.7453],
      [110.4383, -7.7448],
      [110.439, -7.7449],
      [110.4395, -7.7451],
      [110.4396, -7.7452],
      [110.4395, -7.7457],
      [110.4396, -7.746],
      [110.4399, -7.7464],
      [110.4407, -7.7463],
      [110.4414, -7.7465],
      [110.4426, -7.7467],
      [110.4438, -7.7468],
      [110.4446, -7.747],
      [110.4449, -7.747],
      [110.445, -7.7467],
      [110.446, -7.7471],
      [110.4462, -7.7472],
      [110.4466, -7.7467],
      [110.4468, -7.7464],
      [110.447, -7.7463],
      [110.4464, -7.7456],
      [110.4459, -7.7453],
      [110.4459, -7.7453],
      [110.4455, -7.7448],
      [110.4454, -7.7445],
      [110.4455, -7.7441],
      [110.4458, -7.744],
      [110.446, -7.7435],
      [110.4457, -7.7429],
      [110.4453, -7.7425],
      [110.445, -7.742],
      [110.4449, -7.7415],
      [110.4447, -7.7411],
      [110.4447, -7.7406],
      [110.4451, -7.74],
      [110.4454, -7.7396],
      [110.4454, -7.7391],
      [110.4453, -7.7386],
      [110.4449, -7.738],
      [110.4447, -7.7374],
      [110.4449, -7.7369],
      [110.4448, -7.7363],
      [110.4448, -7.7356],
      [110.4446, -7.7355],
      [110.4444, -7.7354],
      [110.4442, -7.7352],
      [110.4437, -7.7347],
      [110.4435, -7.7342],
      [110.4433, -7.7336],
      [110.4432, -7.7334],
      [110.4434, -7.7329],
      [110.4435, -7.7325],
      [110.4434, -7.7321],
      [110.4431, -7.7319],
      [110.4427, -7.732],
      [110.4421, -7.732],
      [110.4416, -7.7319],
      [110.4414, -7.7318],
      [110.4413, -7.7317],
      [110.4413, -7.7313],
      [110.4414, -7.7308],
      [110.4416, -7.7303],
      [110.4413, -7.73],
      [110.441, -7.7297],
      [110.441, -7.7292],
      [110.4411, -7.7288],
      [110.4411, -7.7287],
      [110.4411, -7.7286],
      [110.4409, -7.7283],
      [110.4406, -7.7279],
      [110.4405, -7.7277],
      [110.4406, -7.7274],
      [110.441, -7.7271],
      [110.4409, -7.727],
      [110.4409, -7.7267],
      [110.441, -7.7262],
      [110.441, -7.7256],
      [110.4408, -7.7251],
      [110.4406, -7.7244],
      [110.4407, -7.724],
      [110.4407, -7.7234],
      [110.4406, -7.7233],
      [110.4405, -7.723],
      [110.4404, -7.7227],
      [110.4403, -7.7224],
      [110.4405, -7.722],
      [110.4404, -7.7218],
      [110.4403, -7.7214],
      [110.4402, -7.721],
      [110.4402, -7.7207],
      [110.4403, -7.72],
      [110.4404, -7.7193],
      [110.4405, -7.7192],
      [110.4405, -7.7186],
      [110.4405, -7.7182],
      [110.4403, -7.7176],
      [110.4399, -7.7169],
      [110.44, -7.7164],
      [110.4402, -7.716],
      [110.4406, -7.7153],
      [110.4407, -7.7147],
      [110.4408, -7.7139],
      [110.4407, -7.7136],
      [110.4406, -7.7132],
      [110.4408, -7.7127],
      [110.4408, -7.712]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGEMPLAK',
    village: 'UMBULMARTANI',
    border: [
      [110.4305, -7.668],
      [110.4299, -7.668],
      [110.4284, -7.668],
      [110.4282, -7.6689],
      [110.428, -7.6698],
      [110.4279, -7.6707],
      [110.4276, -7.6719],
      [110.4274, -7.6729],
      [110.4271, -7.6733],
      [110.4268, -7.6733],
      [110.4263, -7.6726],
      [110.4258, -7.6719],
      [110.4252, -7.6718],
      [110.4249, -7.6718],
      [110.4237, -7.6715],
      [110.4228, -7.671],
      [110.4222, -7.6709],
      [110.422, -7.6715],
      [110.4217, -7.6721],
      [110.4215, -7.6721],
      [110.4213, -7.6719],
      [110.4208, -7.6714],
      [110.4204, -7.6708],
      [110.419, -7.6708],
      [110.4188, -7.6708],
      [110.4175, -7.6708],
      [110.4174, -7.6702],
      [110.4176, -7.669],
      [110.4175, -7.6686],
      [110.4167, -7.6684],
      [110.4167, -7.6688],
      [110.4169, -7.6693],
      [110.4169, -7.6699],
      [110.4171, -7.6704],
      [110.417, -7.6708],
      [110.4168, -7.6718],
      [110.4167, -7.6725],
      [110.4166, -7.6734],
      [110.4162, -7.674],
      [110.4159, -7.675],
      [110.4156, -7.6755],
      [110.4153, -7.6759],
      [110.4151, -7.6762],
      [110.4152, -7.6765],
      [110.4152, -7.6767],
      [110.4152, -7.6776],
      [110.4151, -7.6781],
      [110.415, -7.6783],
      [110.4147, -7.6788],
      [110.4143, -7.6791],
      [110.4134, -7.6794],
      [110.4125, -7.6798],
      [110.4121, -7.6805],
      [110.4117, -7.6812],
      [110.4114, -7.6817],
      [110.4107, -7.6822],
      [110.4105, -7.6826],
      [110.4105, -7.6828],
      [110.4106, -7.6832],
      [110.4105, -7.6833],
      [110.4102, -7.6835],
      [110.4101, -7.6838],
      [110.41, -7.684],
      [110.4099, -7.6846],
      [110.4097, -7.685],
      [110.4095, -7.6851],
      [110.4089, -7.6852],
      [110.4088, -7.6855],
      [110.4085, -7.6862],
      [110.4084, -7.6867],
      [110.4084, -7.687],
      [110.4085, -7.6869],
      [110.4098, -7.687],
      [110.4119, -7.6875],
      [110.4123, -7.688],
      [110.4126, -7.6881],
      [110.4131, -7.6883],
      [110.4133, -7.6884],
      [110.4137, -7.6886],
      [110.4137, -7.6893],
      [110.4138, -7.6902],
      [110.4145, -7.6904],
      [110.4147, -7.6909],
      [110.4147, -7.6914],
      [110.4146, -7.6924],
      [110.4147, -7.6924],
      [110.4153, -7.6926],
      [110.4163, -7.6927],
      [110.4167, -7.6924],
      [110.4173, -7.6922],
      [110.4175, -7.6923],
      [110.4179, -7.6924],
      [110.4186, -7.6922],
      [110.419, -7.6919],
      [110.4193, -7.6919],
      [110.4199, -7.692],
      [110.4201, -7.692],
      [110.4211, -7.692],
      [110.4213, -7.6928],
      [110.4219, -7.6929],
      [110.422, -7.6925],
      [110.4222, -7.6922],
      [110.4224, -7.6917],
      [110.4228, -7.691],
      [110.4229, -7.6904],
      [110.4229, -7.6902],
      [110.4225, -7.6895],
      [110.4226, -7.6892],
      [110.4233, -7.688],
      [110.4238, -7.6866],
      [110.4239, -7.6862],
      [110.4242, -7.686],
      [110.4242, -7.6857],
      [110.4242, -7.6852],
      [110.4241, -7.6849],
      [110.4242, -7.6848],
      [110.4245, -7.6845],
      [110.4251, -7.6846],
      [110.4263, -7.6852],
      [110.4273, -7.6857],
      [110.4274, -7.6858],
      [110.4274, -7.6867],
      [110.4271, -7.6876],
      [110.4269, -7.6882],
      [110.4272, -7.6884],
      [110.4279, -7.6883],
      [110.429, -7.6884],
      [110.4297, -7.6886],
      [110.4303, -7.6887],
      [110.4303, -7.6879],
      [110.4306, -7.6874],
      [110.4306, -7.6866],
      [110.4312, -7.6864],
      [110.4339, -7.6866],
      [110.4347, -7.6872],
      [110.4348, -7.6872],
      [110.4351, -7.6876],
      [110.4356, -7.688],
      [110.4361, -7.6884],
      [110.4361, -7.6891],
      [110.436, -7.69],
      [110.4361, -7.6907],
      [110.4361, -7.6914],
      [110.4366, -7.692],
      [110.4369, -7.6924],
      [110.4371, -7.6929],
      [110.4371, -7.6935],
      [110.437, -7.6941],
      [110.4372, -7.6945],
      [110.4372, -7.6953],
      [110.4373, -7.6957],
      [110.4374, -7.6958],
      [110.4374, -7.6959],
      [110.4378, -7.6968],
      [110.4377, -7.6973],
      [110.4376, -7.6978],
      [110.4371, -7.6981],
      [110.4371, -7.6991],
      [110.4373, -7.7003],
      [110.4376, -7.7019],
      [110.4379, -7.7022],
      [110.438, -7.7024],
      [110.4382, -7.7026],
      [110.4385, -7.7029],
      [110.4386, -7.7031],
      [110.4388, -7.7035],
      [110.4419, -7.7027],
      [110.4427, -7.7025],
      [110.4426, -7.702],
      [110.4426, -7.7014],
      [110.4427, -7.7002],
      [110.4441, -7.7003],
      [110.4442, -7.7004],
      [110.4443, -7.6995],
      [110.4442, -7.6988],
      [110.4443, -7.698],
      [110.4444, -7.6974],
      [110.4443, -7.6966],
      [110.4439, -7.6963],
      [110.4437, -7.696],
      [110.4433, -7.6953],
      [110.4435, -7.6948],
      [110.4434, -7.6946],
      [110.4432, -7.6943],
      [110.4431, -7.6939],
      [110.443, -7.693],
      [110.4428, -7.6925],
      [110.4426, -7.6916],
      [110.4426, -7.691],
      [110.4426, -7.6908],
      [110.4425, -7.6905],
      [110.4422, -7.6897],
      [110.4414, -7.6881],
      [110.4412, -7.688],
      [110.441, -7.688],
      [110.4409, -7.6879],
      [110.4408, -7.6873],
      [110.4405, -7.6865],
      [110.4399, -7.6868],
      [110.4396, -7.6868],
      [110.4386, -7.687],
      [110.4385, -7.6857],
      [110.4385, -7.6851],
      [110.4386, -7.6846],
      [110.4385, -7.6843],
      [110.4384, -7.6842],
      [110.4383, -7.684],
      [110.4382, -7.6831],
      [110.4388, -7.6831],
      [110.4394, -7.683],
      [110.4394, -7.6827],
      [110.4394, -7.6824],
      [110.4395, -7.6819],
      [110.4397, -7.6815],
      [110.4396, -7.681],
      [110.4398, -7.6803],
      [110.4397, -7.6801],
      [110.4396, -7.68],
      [110.4393, -7.6798],
      [110.4391, -7.6796],
      [110.4387, -7.6792],
      [110.4386, -7.679],
      [110.439, -7.679],
      [110.4399, -7.679],
      [110.4406, -7.6789],
      [110.4417, -7.6789],
      [110.4421, -7.679],
      [110.4422, -7.679],
      [110.4419, -7.6782],
      [110.4419, -7.6777],
      [110.442, -7.6772],
      [110.4418, -7.6765],
      [110.4419, -7.6759],
      [110.4417, -7.6752],
      [110.442, -7.6748],
      [110.4418, -7.6748],
      [110.4417, -7.6748],
      [110.4405, -7.6747],
      [110.4394, -7.6748],
      [110.4387, -7.6748],
      [110.4377, -7.6748],
      [110.436, -7.6748],
      [110.4357, -7.6748],
      [110.4355, -7.6741],
      [110.4352, -7.6735],
      [110.4348, -7.6735],
      [110.4339, -7.6738],
      [110.4332, -7.674],
      [110.4324, -7.6737],
      [110.4316, -7.6736],
      [110.4315, -7.6736],
      [110.4311, -7.6731],
      [110.4307, -7.673],
      [110.4305, -7.6726],
      [110.4305, -7.6722],
      [110.4305, -7.6717],
      [110.4303, -7.6711],
      [110.4304, -7.6705],
      [110.4305, -7.6697],
      [110.4305, -7.6692],
      [110.4304, -7.669],
      [110.4307, -7.6685],
      [110.4305, -7.668]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGAGLIK',
    village: 'SARIHARJO',
    border: [
      [110.3893, -7.714],
      [110.3894, -7.7134],
      [110.3897, -7.7126],
      [110.39, -7.7119],
      [110.3904, -7.7105],
      [110.3907, -7.7097],
      [110.3908, -7.7084],
      [110.3908, -7.7083],
      [110.3908, -7.7076],
      [110.3908, -7.7068],
      [110.3909, -7.7056],
      [110.3909, -7.7053],
      [110.3908, -7.7046],
      [110.3904, -7.7038],
      [110.3902, -7.7027],
      [110.39, -7.7025],
      [110.39, -7.7027],
      [110.39, -7.7029],
      [110.3898, -7.7033],
      [110.3895, -7.7037],
      [110.3894, -7.7041],
      [110.3892, -7.7045],
      [110.3891, -7.7047],
      [110.3889, -7.7048],
      [110.3885, -7.7051],
      [110.3879, -7.7051],
      [110.3873, -7.7051],
      [110.3865, -7.7049],
      [110.3863, -7.7059],
      [110.3861, -7.7069],
      [110.3855, -7.7076],
      [110.3844, -7.7084],
      [110.3837, -7.709],
      [110.3833, -7.7097],
      [110.3828, -7.7109],
      [110.3825, -7.7119],
      [110.382, -7.7125],
      [110.3814, -7.7133],
      [110.3812, -7.7132],
      [110.3805, -7.7129],
      [110.3791, -7.7124],
      [110.3785, -7.7122],
      [110.378, -7.7119],
      [110.3769, -7.7111],
      [110.3765, -7.7111],
      [110.3763, -7.7119],
      [110.3762, -7.7123],
      [110.3761, -7.7125],
      [110.3761, -7.7129],
      [110.3762, -7.7134],
      [110.3762, -7.7135],
      [110.3764, -7.7137],
      [110.3777, -7.7148],
      [110.3782, -7.7152],
      [110.3783, -7.7152],
      [110.3783, -7.7158],
      [110.378, -7.716],
      [110.3777, -7.7162],
      [110.3776, -7.7166],
      [110.3776, -7.7168],
      [110.3773, -7.7171],
      [110.377, -7.7172],
      [110.3769, -7.7175],
      [110.3768, -7.7179],
      [110.3766, -7.7181],
      [110.3766, -7.7189],
      [110.3764, -7.7192],
      [110.3761, -7.7197],
      [110.376, -7.72],
      [110.3758, -7.7202],
      [110.3754, -7.7202],
      [110.375, -7.7204],
      [110.3745, -7.7207],
      [110.3741, -7.7211],
      [110.3741, -7.7212],
      [110.3741, -7.7215],
      [110.3741, -7.7216],
      [110.3739, -7.7218],
      [110.3737, -7.722],
      [110.3735, -7.7223],
      [110.3734, -7.7227],
      [110.3731, -7.7232],
      [110.373, -7.7236],
      [110.3727, -7.7242],
      [110.3725, -7.7247],
      [110.3721, -7.7254],
      [110.3716, -7.726],
      [110.3714, -7.7265],
      [110.3716, -7.7268],
      [110.3716, -7.7271],
      [110.3715, -7.7276],
      [110.3713, -7.7281],
      [110.3714, -7.7284],
      [110.372, -7.7283],
      [110.3722, -7.728],
      [110.3729, -7.7277],
      [110.3744, -7.7277],
      [110.375, -7.7278],
      [110.3754, -7.7281],
      [110.3754, -7.7285],
      [110.3751, -7.7291],
      [110.3755, -7.7294],
      [110.3758, -7.7296],
      [110.3759, -7.7308],
      [110.3759, -7.7311],
      [110.3758, -7.7318],
      [110.3756, -7.7325],
      [110.3754, -7.7336],
      [110.3755, -7.7342],
      [110.3753, -7.7348],
      [110.3748, -7.735],
      [110.3742, -7.7355],
      [110.3736, -7.7358],
      [110.3734, -7.7362],
      [110.3731, -7.7364],
      [110.3727, -7.7365],
      [110.3724, -7.7365],
      [110.3719, -7.7365],
      [110.3714, -7.7366],
      [110.3711, -7.737],
      [110.3706, -7.7371],
      [110.3704, -7.7375],
      [110.37, -7.7377],
      [110.3695, -7.7381],
      [110.3691, -7.7383],
      [110.3687, -7.7383],
      [110.3681, -7.7384],
      [110.3678, -7.7386],
      [110.3674, -7.739],
      [110.3671, -7.7392],
      [110.3669, -7.7394],
      [110.367, -7.7397],
      [110.3672, -7.7398],
      [110.3676, -7.74],
      [110.3679, -7.7405],
      [110.3677, -7.741],
      [110.3675, -7.7415],
      [110.3671, -7.742],
      [110.3666, -7.7423],
      [110.3662, -7.7427],
      [110.3662, -7.7433],
      [110.3672, -7.7431],
      [110.3681, -7.743],
      [110.3696, -7.7429],
      [110.3698, -7.7433],
      [110.3698, -7.744],
      [110.3698, -7.745],
      [110.3695, -7.7453],
      [110.3692, -7.7456],
      [110.369, -7.7462],
      [110.3689, -7.7466],
      [110.3687, -7.7472],
      [110.3685, -7.7475],
      [110.3682, -7.7478],
      [110.3686, -7.7481],
      [110.3691, -7.7483],
      [110.3696, -7.7483],
      [110.3711, -7.7489],
      [110.3718, -7.7492],
      [110.3714, -7.7498],
      [110.3712, -7.7501],
      [110.3709, -7.7502],
      [110.3707, -7.7504],
      [110.3705, -7.7505],
      [110.3703, -7.7508],
      [110.37, -7.7512],
      [110.3698, -7.7517],
      [110.3696, -7.7522],
      [110.369, -7.752],
      [110.3688, -7.752],
      [110.3686, -7.7526],
      [110.3679, -7.7524],
      [110.3677, -7.7536],
      [110.3681, -7.7539],
      [110.3681, -7.7543],
      [110.3687, -7.7544],
      [110.3699, -7.7543],
      [110.37, -7.7539],
      [110.3714, -7.7543],
      [110.3725, -7.7546],
      [110.3727, -7.754],
      [110.3734, -7.7541],
      [110.3741, -7.7542],
      [110.3741, -7.754],
      [110.3742, -7.7535],
      [110.3746, -7.7529],
      [110.3748, -7.7523],
      [110.3752, -7.7517],
      [110.3755, -7.7511],
      [110.376, -7.7507],
      [110.3765, -7.7499],
      [110.3767, -7.7496],
      [110.3771, -7.7494],
      [110.3777, -7.7492],
      [110.3784, -7.7491],
      [110.3786, -7.7487],
      [110.3794, -7.7463],
      [110.3801, -7.7445],
      [110.3808, -7.7438],
      [110.3814, -7.7422],
      [110.3815, -7.7421],
      [110.3817, -7.7415],
      [110.3817, -7.7413],
      [110.3819, -7.7408],
      [110.3822, -7.7401],
      [110.3826, -7.74],
      [110.3832, -7.7401],
      [110.3834, -7.7399],
      [110.3834, -7.7393],
      [110.3835, -7.7389],
      [110.3838, -7.7388],
      [110.3844, -7.7388],
      [110.3847, -7.7387],
      [110.3848, -7.7377],
      [110.3853, -7.7369],
      [110.3854, -7.7367],
      [110.3857, -7.7359],
      [110.3856, -7.7356],
      [110.3853, -7.7353],
      [110.3858, -7.7353],
      [110.3862, -7.7351],
      [110.3862, -7.7349],
      [110.3862, -7.7345],
      [110.3862, -7.7338],
      [110.3864, -7.7336],
      [110.3868, -7.7331],
      [110.3873, -7.7323],
      [110.3877, -7.7314],
      [110.3877, -7.7312],
      [110.3876, -7.7309],
      [110.3876, -7.7307],
      [110.3877, -7.7302],
      [110.3876, -7.7302],
      [110.3866, -7.7296],
      [110.3852, -7.729],
      [110.3834, -7.7285],
      [110.3828, -7.7283],
      [110.3829, -7.7282],
      [110.3828, -7.7274],
      [110.3821, -7.7271],
      [110.382, -7.7269],
      [110.3821, -7.7264],
      [110.3824, -7.7257],
      [110.3827, -7.7254],
      [110.3827, -7.725],
      [110.383, -7.724],
      [110.3831, -7.7235],
      [110.3835, -7.7232],
      [110.3837, -7.7225],
      [110.3844, -7.7218],
      [110.3845, -7.7215],
      [110.3846, -7.7208],
      [110.3847, -7.7205],
      [110.3857, -7.72],
      [110.3862, -7.7194],
      [110.3871, -7.7186],
      [110.3873, -7.7182],
      [110.3874, -7.718],
      [110.3877, -7.7179],
      [110.3881, -7.7171],
      [110.3881, -7.7169],
      [110.3882, -7.7164],
      [110.3885, -7.7156],
      [110.3888, -7.7154],
      [110.3893, -7.714]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGAGLIK',
    village: 'MINOMARTANI',
    border: [
      [110.4115, -7.7286],
      [110.4114, -7.7286],
      [110.4112, -7.7286],
      [110.4109, -7.7282],
      [110.4106, -7.7281],
      [110.41, -7.7279],
      [110.4097, -7.7275],
      [110.4091, -7.7273],
      [110.4084, -7.7274],
      [110.408, -7.7278],
      [110.4076, -7.7283],
      [110.4074, -7.7288],
      [110.4073, -7.7293],
      [110.4074, -7.7299],
      [110.4073, -7.7299],
      [110.4067, -7.7302],
      [110.4039, -7.7293],
      [110.4025, -7.7291],
      [110.4024, -7.7292],
      [110.4017, -7.7308],
      [110.4012, -7.7319],
      [110.4012, -7.7324],
      [110.4011, -7.7326],
      [110.401, -7.733],
      [110.4013, -7.7336],
      [110.4014, -7.7342],
      [110.4014, -7.7343],
      [110.4017, -7.735],
      [110.4017, -7.7357],
      [110.4017, -7.7362],
      [110.4017, -7.737],
      [110.4017, -7.7372],
      [110.402, -7.7383],
      [110.4021, -7.7384],
      [110.4021, -7.7393],
      [110.4021, -7.7395],
      [110.4021, -7.7396],
      [110.4023, -7.7398],
      [110.4026, -7.7403],
      [110.4026, -7.7405],
      [110.4025, -7.7413],
      [110.4025, -7.7413],
      [110.4024, -7.742],
      [110.4024, -7.7424],
      [110.4033, -7.7417],
      [110.4038, -7.7412],
      [110.4041, -7.74],
      [110.4043, -7.7396],
      [110.4046, -7.7396],
      [110.4046, -7.7402],
      [110.4053, -7.7402],
      [110.4055, -7.7411],
      [110.4059, -7.7406],
      [110.4066, -7.7405],
      [110.4067, -7.7405],
      [110.4068, -7.741],
      [110.4065, -7.7415],
      [110.4063, -7.7421],
      [110.4063, -7.7424],
      [110.4062, -7.7429],
      [110.4064, -7.7443],
      [110.4064, -7.7449],
      [110.4063, -7.7452],
      [110.4057, -7.7453],
      [110.4047, -7.7458],
      [110.404, -7.7459],
      [110.4039, -7.7462],
      [110.4038, -7.7474],
      [110.4037, -7.7477],
      [110.403, -7.7481],
      [110.403, -7.7485],
      [110.4032, -7.7489],
      [110.4032, -7.7492],
      [110.4034, -7.7495],
      [110.4046, -7.7494],
      [110.4061, -7.7496],
      [110.4071, -7.7499],
      [110.4076, -7.75],
      [110.4079, -7.7489],
      [110.4078, -7.7482],
      [110.408, -7.7478],
      [110.4084, -7.7475],
      [110.4088, -7.7474],
      [110.4087, -7.7468],
      [110.4087, -7.7458],
      [110.409, -7.7455],
      [110.4094, -7.7452],
      [110.4093, -7.7446],
      [110.4093, -7.7441],
      [110.4095, -7.7435],
      [110.4097, -7.7429],
      [110.4094, -7.7419],
      [110.4093, -7.7415],
      [110.4095, -7.7409],
      [110.4099, -7.7404],
      [110.4102, -7.7401],
      [110.4102, -7.7398],
      [110.4098, -7.7395],
      [110.4096, -7.7394],
      [110.4097, -7.7389],
      [110.4095, -7.7385],
      [110.4091, -7.7382],
      [110.4087, -7.738],
      [110.4088, -7.7377],
      [110.409, -7.7375],
      [110.4092, -7.7375],
      [110.4095, -7.7373],
      [110.4096, -7.737],
      [110.4094, -7.7366],
      [110.4091, -7.736],
      [110.409, -7.7355],
      [110.4091, -7.735],
      [110.4093, -7.7347],
      [110.4094, -7.7342],
      [110.4094, -7.7338],
      [110.4097, -7.7336],
      [110.4099, -7.7333],
      [110.4101, -7.733],
      [110.41, -7.7326],
      [110.4094, -7.7323],
      [110.4093, -7.7322],
      [110.4097, -7.732],
      [110.4101, -7.7318],
      [110.4105, -7.7316],
      [110.4106, -7.7312],
      [110.4106, -7.7308],
      [110.4105, -7.7304],
      [110.411, -7.7296],
      [110.4115, -7.7287],
      [110.4115, -7.7286]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGAGLIK',
    village: 'SINDUHARJO',
    border: [
      [110.4137, -7.7106],
      [110.4132, -7.711],
      [110.4131, -7.7111],
      [110.4125, -7.7115],
      [110.4119, -7.7115],
      [110.4116, -7.7113],
      [110.4112, -7.7111],
      [110.4109, -7.7111],
      [110.4105, -7.7112],
      [110.41, -7.7113],
      [110.4093, -7.7117],
      [110.4085, -7.7122],
      [110.4082, -7.7123],
      [110.4073, -7.7119],
      [110.4069, -7.7113],
      [110.4065, -7.7107],
      [110.4059, -7.7101],
      [110.4051, -7.7098],
      [110.4049, -7.7098],
      [110.405, -7.71],
      [110.4051, -7.7102],
      [110.4052, -7.7103],
      [110.4052, -7.7104],
      [110.4049, -7.7106],
      [110.4049, -7.711],
      [110.4046, -7.7113],
      [110.4043, -7.7117],
      [110.4042, -7.7118],
      [110.4039, -7.7121],
      [110.4035, -7.7121],
      [110.4033, -7.7127],
      [110.4034, -7.7136],
      [110.4036, -7.7144],
      [110.404, -7.7148],
      [110.4041, -7.7159],
      [110.4045, -7.718],
      [110.4045, -7.7183],
      [110.4039, -7.7184],
      [110.4037, -7.7184],
      [110.4036, -7.7188],
      [110.4033, -7.719],
      [110.4031, -7.7191],
      [110.4032, -7.7199],
      [110.4034, -7.7202],
      [110.4037, -7.7203],
      [110.4036, -7.7208],
      [110.4033, -7.7214],
      [110.4033, -7.7221],
      [110.4029, -7.7224],
      [110.4032, -7.7231],
      [110.4031, -7.7233],
      [110.4029, -7.7235],
      [110.4027, -7.7243],
      [110.4025, -7.7245],
      [110.4027, -7.725],
      [110.4028, -7.7253],
      [110.4027, -7.7256],
      [110.4025, -7.726],
      [110.4022, -7.7259],
      [110.402, -7.7261],
      [110.4021, -7.7265],
      [110.4018, -7.7267],
      [110.4019, -7.7271],
      [110.4015, -7.7271],
      [110.4011, -7.7273],
      [110.4008, -7.7277],
      [110.4006, -7.7283],
      [110.4001, -7.7279],
      [110.3994, -7.7275],
      [110.3985, -7.7273],
      [110.3984, -7.7279],
      [110.3987, -7.7281],
      [110.3993, -7.7284],
      [110.3985, -7.7295],
      [110.3981, -7.7305],
      [110.3978, -7.7313],
      [110.3975, -7.7315],
      [110.3973, -7.7312],
      [110.3973, -7.7306],
      [110.3971, -7.73],
      [110.3968, -7.7295],
      [110.3965, -7.7294],
      [110.3955, -7.7288],
      [110.395, -7.7285],
      [110.3942, -7.7282],
      [110.3931, -7.7277],
      [110.3925, -7.7275],
      [110.3921, -7.7271],
      [110.3916, -7.7264],
      [110.392, -7.726],
      [110.392, -7.7254],
      [110.3923, -7.7251],
      [110.3923, -7.7245],
      [110.3922, -7.7244],
      [110.3917, -7.7241],
      [110.3912, -7.7239],
      [110.3905, -7.7232],
      [110.39, -7.7226],
      [110.3894, -7.7225],
      [110.389, -7.7219],
      [110.3885, -7.721],
      [110.3892, -7.7194],
      [110.3894, -7.7186],
      [110.3896, -7.7174],
      [110.3898, -7.7169],
      [110.3895, -7.7156],
      [110.3895, -7.7154],
      [110.3895, -7.7148],
      [110.3894, -7.7139],
      [110.3893, -7.714],
      [110.3888, -7.7154],
      [110.3885, -7.7156],
      [110.3882, -7.7164],
      [110.3881, -7.7169],
      [110.3881, -7.7171],
      [110.3877, -7.7179],
      [110.3874, -7.718],
      [110.3873, -7.7182],
      [110.3871, -7.7186],
      [110.3862, -7.7194],
      [110.3857, -7.72],
      [110.3847, -7.7205],
      [110.3846, -7.7208],
      [110.3845, -7.7215],
      [110.3844, -7.7218],
      [110.3837, -7.7225],
      [110.3835, -7.7232],
      [110.3831, -7.7235],
      [110.383, -7.724],
      [110.3827, -7.725],
      [110.3827, -7.7254],
      [110.3824, -7.7257],
      [110.3821, -7.7264],
      [110.382, -7.7269],
      [110.3821, -7.7271],
      [110.3828, -7.7274],
      [110.3829, -7.7282],
      [110.3828, -7.7283],
      [110.3834, -7.7285],
      [110.3852, -7.729],
      [110.3866, -7.7296],
      [110.3876, -7.7302],
      [110.3877, -7.7302],
      [110.3876, -7.7307],
      [110.3876, -7.7309],
      [110.3877, -7.7312],
      [110.3877, -7.7314],
      [110.3873, -7.7323],
      [110.3868, -7.7331],
      [110.3864, -7.7336],
      [110.3862, -7.7338],
      [110.3862, -7.7345],
      [110.3862, -7.7349],
      [110.3862, -7.7351],
      [110.3858, -7.7353],
      [110.3862, -7.7363],
      [110.3863, -7.7373],
      [110.3861, -7.7379],
      [110.3883, -7.7382],
      [110.3883, -7.7384],
      [110.3879, -7.7384],
      [110.3876, -7.7397],
      [110.3875, -7.7404],
      [110.3872, -7.7411],
      [110.3883, -7.7416],
      [110.3892, -7.7418],
      [110.3895, -7.7421],
      [110.3897, -7.7433],
      [110.3898, -7.7434],
      [110.39, -7.7435],
      [110.3901, -7.7437],
      [110.3901, -7.7439],
      [110.3914, -7.744],
      [110.3932, -7.7445],
      [110.3937, -7.7445],
      [110.3939, -7.745],
      [110.3942, -7.7451],
      [110.3945, -7.7452],
      [110.3947, -7.7456],
      [110.3946, -7.7463],
      [110.3947, -7.7472],
      [110.395, -7.7486],
      [110.395, -7.7492],
      [110.3944, -7.7493],
      [110.3942, -7.7494],
      [110.394, -7.7496],
      [110.3938, -7.7499],
      [110.3936, -7.7509],
      [110.3936, -7.7521],
      [110.3939, -7.7523],
      [110.394, -7.7523],
      [110.3947, -7.7523],
      [110.3948, -7.7517],
      [110.3956, -7.7518],
      [110.3957, -7.7512],
      [110.3954, -7.7512],
      [110.3954, -7.7508],
      [110.3958, -7.7507],
      [110.396, -7.7498],
      [110.3972, -7.75],
      [110.3971, -7.7475],
      [110.397, -7.7458],
      [110.3969, -7.7448],
      [110.3972, -7.7429],
      [110.3972, -7.7414],
      [110.3971, -7.74],
      [110.3969, -7.7391],
      [110.3967, -7.7382],
      [110.3968, -7.7376],
      [110.3971, -7.7368],
      [110.3977, -7.736],
      [110.398, -7.7357],
      [110.3989, -7.7336],
      [110.3992, -7.7328],
      [110.3999, -7.7324],
      [110.4006, -7.7323],
      [110.4012, -7.7324],
      [110.4012, -7.7319],
      [110.4017, -7.7308],
      [110.4024, -7.7292],
      [110.4025, -7.7291],
      [110.4039, -7.7293],
      [110.4067, -7.7302],
      [110.4073, -7.7299],
      [110.4074, -7.7299],
      [110.4073, -7.7293],
      [110.4074, -7.7288],
      [110.4076, -7.7283],
      [110.408, -7.7278],
      [110.4084, -7.7274],
      [110.4091, -7.7273],
      [110.4097, -7.7275],
      [110.41, -7.7279],
      [110.4106, -7.7281],
      [110.4109, -7.7282],
      [110.4112, -7.7286],
      [110.4114, -7.7286],
      [110.4115, -7.7286],
      [110.4123, -7.7277],
      [110.4125, -7.7272],
      [110.4126, -7.7271],
      [110.413, -7.727],
      [110.4131, -7.7264],
      [110.4131, -7.7252],
      [110.4133, -7.7247],
      [110.4134, -7.7244],
      [110.4131, -7.7241],
      [110.4127, -7.724],
      [110.4121, -7.724],
      [110.412, -7.724],
      [110.4121, -7.7235],
      [110.4121, -7.7231],
      [110.4119, -7.7223],
      [110.4123, -7.7217],
      [110.4124, -7.7211],
      [110.4122, -7.7207],
      [110.4123, -7.7203],
      [110.4126, -7.7201],
      [110.4135, -7.7203],
      [110.4137, -7.7203],
      [110.414, -7.7207],
      [110.4159, -7.7214],
      [110.4164, -7.7216],
      [110.4177, -7.7219],
      [110.4182, -7.7219],
      [110.4194, -7.7223],
      [110.4197, -7.7192],
      [110.4198, -7.7173],
      [110.4198, -7.7158],
      [110.4182, -7.7162],
      [110.4178, -7.7159],
      [110.4173, -7.7156],
      [110.4164, -7.7154],
      [110.4159, -7.7152],
      [110.4164, -7.7144],
      [110.4167, -7.7139],
      [110.4166, -7.7126],
      [110.4163, -7.7115],
      [110.4162, -7.7114],
      [110.4159, -7.7112],
      [110.4152, -7.7109],
      [110.415, -7.7108],
      [110.4143, -7.7108],
      [110.4139, -7.7106],
      [110.4137, -7.7106]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGAGLIK',
    village: 'SUKOHARJO',
    border: [
      [110.4427, -7.7025],
      [110.4419, -7.7027],
      [110.4388, -7.7035],
      [110.4386, -7.7031],
      [110.4385, -7.7029],
      [110.4382, -7.7026],
      [110.438, -7.7024],
      [110.4379, -7.7022],
      [110.4376, -7.7019],
      [110.4373, -7.7003],
      [110.4371, -7.6991],
      [110.4371, -7.6981],
      [110.4376, -7.6978],
      [110.4377, -7.6973],
      [110.4378, -7.6968],
      [110.4374, -7.6959],
      [110.4374, -7.6958],
      [110.4373, -7.6957],
      [110.4372, -7.6953],
      [110.4372, -7.6945],
      [110.437, -7.6941],
      [110.4371, -7.6935],
      [110.4371, -7.6929],
      [110.4369, -7.6924],
      [110.4366, -7.692],
      [110.4361, -7.6914],
      [110.4361, -7.6907],
      [110.436, -7.69],
      [110.4361, -7.6891],
      [110.4361, -7.6884],
      [110.4356, -7.688],
      [110.4351, -7.6876],
      [110.4348, -7.6872],
      [110.4347, -7.6872],
      [110.4339, -7.6866],
      [110.4312, -7.6864],
      [110.4306, -7.6866],
      [110.4306, -7.6874],
      [110.4303, -7.6879],
      [110.4303, -7.6887],
      [110.4297, -7.6886],
      [110.429, -7.6884],
      [110.4279, -7.6883],
      [110.4272, -7.6884],
      [110.4269, -7.6882],
      [110.4271, -7.6876],
      [110.4274, -7.6867],
      [110.4274, -7.6858],
      [110.4273, -7.6857],
      [110.4263, -7.6852],
      [110.4251, -7.6846],
      [110.4245, -7.6845],
      [110.4242, -7.6848],
      [110.4241, -7.6849],
      [110.4242, -7.6852],
      [110.4242, -7.6857],
      [110.4242, -7.686],
      [110.4239, -7.6862],
      [110.4238, -7.6866],
      [110.4233, -7.688],
      [110.4226, -7.6892],
      [110.4225, -7.6895],
      [110.4229, -7.6902],
      [110.4229, -7.6904],
      [110.4228, -7.691],
      [110.4224, -7.6917],
      [110.4222, -7.6922],
      [110.422, -7.6925],
      [110.4219, -7.6929],
      [110.4213, -7.6928],
      [110.4211, -7.692],
      [110.4201, -7.692],
      [110.4199, -7.692],
      [110.4193, -7.6919],
      [110.419, -7.6919],
      [110.4186, -7.6922],
      [110.4179, -7.6924],
      [110.4175, -7.6923],
      [110.4173, -7.6922],
      [110.4167, -7.6924],
      [110.4163, -7.6927],
      [110.4153, -7.6926],
      [110.4147, -7.6924],
      [110.4142, -7.693],
      [110.4139, -7.6934],
      [110.4139, -7.694],
      [110.4137, -7.6947],
      [110.4139, -7.695],
      [110.4141, -7.6953],
      [110.4146, -7.6957],
      [110.4163, -7.6961],
      [110.4165, -7.6965],
      [110.4166, -7.6973],
      [110.4168, -7.6974],
      [110.417, -7.6974],
      [110.4168, -7.6977],
      [110.4167, -7.6979],
      [110.4164, -7.6984],
      [110.4174, -7.6988],
      [110.4183, -7.6989],
      [110.4179, -7.7003],
      [110.4175, -7.7012],
      [110.4163, -7.7009],
      [110.4163, -7.701],
      [110.4162, -7.7019],
      [110.416, -7.7029],
      [110.4157, -7.7039],
      [110.4157, -7.7047],
      [110.4155, -7.7052],
      [110.4152, -7.7053],
      [110.4149, -7.7054],
      [110.4147, -7.7057],
      [110.4146, -7.7058],
      [110.4144, -7.7059],
      [110.4143, -7.7066],
      [110.4141, -7.7073],
      [110.4143, -7.7079],
      [110.4142, -7.7086],
      [110.4141, -7.7095],
      [110.414, -7.7099],
      [110.4137, -7.7102],
      [110.4137, -7.7106],
      [110.4139, -7.7106],
      [110.4143, -7.7108],
      [110.415, -7.7108],
      [110.4152, -7.7109],
      [110.4159, -7.7112],
      [110.4162, -7.7114],
      [110.4163, -7.7115],
      [110.4166, -7.7126],
      [110.4167, -7.7139],
      [110.4164, -7.7144],
      [110.4159, -7.7152],
      [110.4164, -7.7154],
      [110.4173, -7.7156],
      [110.4178, -7.7159],
      [110.4182, -7.7162],
      [110.4198, -7.7158],
      [110.4198, -7.7173],
      [110.4197, -7.7192],
      [110.4194, -7.7223],
      [110.4211, -7.723],
      [110.4219, -7.7232],
      [110.4238, -7.7233],
      [110.4245, -7.723],
      [110.4254, -7.7229],
      [110.4263, -7.7227],
      [110.4267, -7.722],
      [110.4267, -7.7216],
      [110.4268, -7.7211],
      [110.4267, -7.7206],
      [110.4267, -7.7203],
      [110.4263, -7.7198],
      [110.4263, -7.7191],
      [110.426, -7.7185],
      [110.4257, -7.718],
      [110.4257, -7.7178],
      [110.4257, -7.7172],
      [110.4256, -7.7166],
      [110.4271, -7.7159],
      [110.4288, -7.7155],
      [110.4292, -7.7154],
      [110.4307, -7.715],
      [110.4324, -7.7147],
      [110.4333, -7.7145],
      [110.4342, -7.7137],
      [110.4344, -7.7134],
      [110.4344, -7.7133],
      [110.4345, -7.7117],
      [110.4347, -7.7105],
      [110.4347, -7.7099],
      [110.4346, -7.7093],
      [110.4344, -7.7088],
      [110.4342, -7.7085],
      [110.4342, -7.7082],
      [110.4343, -7.7079],
      [110.4345, -7.7078],
      [110.4355, -7.7078],
      [110.4365, -7.7075],
      [110.4374, -7.7076],
      [110.4384, -7.7075],
      [110.4387, -7.7075],
      [110.4393, -7.7074],
      [110.4392, -7.7082],
      [110.4397, -7.7086],
      [110.4398, -7.7089],
      [110.4399, -7.7093],
      [110.4403, -7.71],
      [110.4404, -7.7107],
      [110.4407, -7.7117],
      [110.4408, -7.712],
      [110.4414, -7.7116],
      [110.4415, -7.7109],
      [110.4418, -7.7103],
      [110.4425, -7.7101],
      [110.4429, -7.71],
      [110.4432, -7.7098],
      [110.4431, -7.7094],
      [110.4427, -7.709],
      [110.4426, -7.7086],
      [110.4427, -7.708],
      [110.4426, -7.7075],
      [110.4424, -7.7068],
      [110.4425, -7.7064],
      [110.4429, -7.7059],
      [110.4432, -7.7055],
      [110.4431, -7.7051],
      [110.4428, -7.7047],
      [110.4428, -7.7043],
      [110.4429, -7.7039],
      [110.4427, -7.7025]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGAGLIK',
    village: 'SARDONOHARJO',
    border: [
      [110.404, -7.6872],
      [110.4025, -7.6863],
      [110.4017, -7.6862],
      [110.4017, -7.6872],
      [110.4013, -7.6872],
      [110.401, -7.6872],
      [110.3995, -7.6871],
      [110.3989, -7.687],
      [110.3981, -7.6869],
      [110.3977, -7.6869],
      [110.3972, -7.6868],
      [110.397, -7.6868],
      [110.3956, -7.6873],
      [110.3955, -7.6873],
      [110.3953, -7.6867],
      [110.3951, -7.6863],
      [110.3951, -7.6857],
      [110.395, -7.6849],
      [110.3953, -7.6843],
      [110.3939, -7.6836],
      [110.394, -7.6849],
      [110.3939, -7.6857],
      [110.3939, -7.6859],
      [110.3934, -7.6864],
      [110.3932, -7.6868],
      [110.3931, -7.6872],
      [110.3931, -7.6879],
      [110.393, -7.6883],
      [110.3927, -7.6891],
      [110.3923, -7.6897],
      [110.3923, -7.6898],
      [110.392, -7.6902],
      [110.3917, -7.6906],
      [110.3918, -7.6915],
      [110.3917, -7.6922],
      [110.3917, -7.6929],
      [110.3913, -7.6934],
      [110.3911, -7.6936],
      [110.3908, -7.695],
      [110.3906, -7.6952],
      [110.3902, -7.6955],
      [110.3901, -7.6958],
      [110.3903, -7.6962],
      [110.3901, -7.6965],
      [110.3897, -7.6969],
      [110.3896, -7.6973],
      [110.3894, -7.6977],
      [110.3894, -7.698],
      [110.3892, -7.6983],
      [110.3892, -7.6994],
      [110.3893, -7.6997],
      [110.3893, -7.7005],
      [110.3897, -7.7012],
      [110.3898, -7.7014],
      [110.3897, -7.7016],
      [110.3896, -7.7018],
      [110.3898, -7.7023],
      [110.39, -7.7025],
      [110.3902, -7.7027],
      [110.3904, -7.7038],
      [110.3908, -7.7046],
      [110.3909, -7.7053],
      [110.3909, -7.7056],
      [110.3908, -7.7068],
      [110.3908, -7.7076],
      [110.3908, -7.7083],
      [110.3908, -7.7084],
      [110.3907, -7.7097],
      [110.3904, -7.7105],
      [110.39, -7.7119],
      [110.3897, -7.7126],
      [110.3894, -7.7134],
      [110.3893, -7.714],
      [110.3894, -7.7139],
      [110.3895, -7.7148],
      [110.3895, -7.7154],
      [110.3895, -7.7156],
      [110.3898, -7.7169],
      [110.3896, -7.7174],
      [110.3894, -7.7186],
      [110.3892, -7.7194],
      [110.3885, -7.721],
      [110.389, -7.7219],
      [110.3894, -7.7225],
      [110.39, -7.7226],
      [110.3905, -7.7232],
      [110.3912, -7.7239],
      [110.3917, -7.7241],
      [110.3922, -7.7244],
      [110.3923, -7.7245],
      [110.3923, -7.7251],
      [110.392, -7.7254],
      [110.392, -7.726],
      [110.3916, -7.7264],
      [110.3921, -7.7271],
      [110.3925, -7.7275],
      [110.3931, -7.7277],
      [110.3942, -7.7282],
      [110.395, -7.7285],
      [110.3955, -7.7288],
      [110.3965, -7.7294],
      [110.3968, -7.7295],
      [110.3971, -7.73],
      [110.3973, -7.7306],
      [110.3973, -7.7312],
      [110.3975, -7.7315],
      [110.3978, -7.7313],
      [110.3981, -7.7305],
      [110.3985, -7.7295],
      [110.3993, -7.7284],
      [110.3987, -7.7281],
      [110.3984, -7.7279],
      [110.3985, -7.7273],
      [110.3994, -7.7275],
      [110.4001, -7.7279],
      [110.4006, -7.7283],
      [110.4008, -7.7277],
      [110.4011, -7.7273],
      [110.4015, -7.7271],
      [110.4019, -7.7271],
      [110.4018, -7.7267],
      [110.4021, -7.7265],
      [110.402, -7.7261],
      [110.4022, -7.7259],
      [110.4025, -7.726],
      [110.4027, -7.7256],
      [110.4028, -7.7253],
      [110.4027, -7.725],
      [110.4025, -7.7245],
      [110.4027, -7.7243],
      [110.4029, -7.7235],
      [110.4031, -7.7233],
      [110.4032, -7.7231],
      [110.4029, -7.7224],
      [110.4033, -7.7221],
      [110.4033, -7.7214],
      [110.4036, -7.7208],
      [110.4037, -7.7203],
      [110.4034, -7.7202],
      [110.4032, -7.7199],
      [110.4031, -7.7191],
      [110.4033, -7.719],
      [110.4036, -7.7188],
      [110.4037, -7.7184],
      [110.4039, -7.7184],
      [110.4045, -7.7183],
      [110.4045, -7.718],
      [110.4041, -7.7159],
      [110.404, -7.7148],
      [110.4036, -7.7144],
      [110.4034, -7.7136],
      [110.4033, -7.7127],
      [110.4035, -7.7121],
      [110.4039, -7.7121],
      [110.4042, -7.7118],
      [110.4043, -7.7117],
      [110.4046, -7.7113],
      [110.4049, -7.711],
      [110.4049, -7.7106],
      [110.4052, -7.7104],
      [110.4052, -7.7103],
      [110.4051, -7.7102],
      [110.405, -7.71],
      [110.4049, -7.7098],
      [110.4051, -7.7098],
      [110.4059, -7.7101],
      [110.4065, -7.7107],
      [110.4069, -7.7113],
      [110.4073, -7.7119],
      [110.4082, -7.7123],
      [110.4085, -7.7122],
      [110.4093, -7.7117],
      [110.41, -7.7113],
      [110.4105, -7.7112],
      [110.4109, -7.7111],
      [110.4112, -7.7111],
      [110.4116, -7.7113],
      [110.4119, -7.7115],
      [110.4125, -7.7115],
      [110.4131, -7.7111],
      [110.4132, -7.711],
      [110.4137, -7.7106],
      [110.4137, -7.7102],
      [110.414, -7.7099],
      [110.4141, -7.7095],
      [110.4142, -7.7086],
      [110.4143, -7.7079],
      [110.4141, -7.7073],
      [110.4143, -7.7066],
      [110.4144, -7.7059],
      [110.4146, -7.7058],
      [110.4147, -7.7057],
      [110.4149, -7.7054],
      [110.4152, -7.7053],
      [110.4155, -7.7052],
      [110.4157, -7.7047],
      [110.4157, -7.7039],
      [110.416, -7.7029],
      [110.4162, -7.7019],
      [110.4163, -7.701],
      [110.4163, -7.7009],
      [110.4175, -7.7012],
      [110.4179, -7.7003],
      [110.4183, -7.6989],
      [110.4174, -7.6988],
      [110.4164, -7.6984],
      [110.4167, -7.6979],
      [110.4168, -7.6977],
      [110.417, -7.6974],
      [110.4168, -7.6974],
      [110.4166, -7.6973],
      [110.4165, -7.6965],
      [110.4163, -7.6961],
      [110.4146, -7.6957],
      [110.4141, -7.6953],
      [110.4139, -7.695],
      [110.4137, -7.6947],
      [110.4139, -7.694],
      [110.4139, -7.6934],
      [110.4142, -7.693],
      [110.4147, -7.6924],
      [110.4146, -7.6924],
      [110.4147, -7.6914],
      [110.4147, -7.6909],
      [110.4145, -7.6904],
      [110.4138, -7.6902],
      [110.4137, -7.6893],
      [110.4137, -7.6886],
      [110.4133, -7.6884],
      [110.4131, -7.6883],
      [110.4126, -7.6881],
      [110.4123, -7.688],
      [110.4119, -7.6875],
      [110.4098, -7.687],
      [110.4085, -7.6869],
      [110.4084, -7.687],
      [110.4084, -7.6872],
      [110.408, -7.6874],
      [110.4076, -7.6875],
      [110.4073, -7.6877],
      [110.4073, -7.688],
      [110.4074, -7.6883],
      [110.4071, -7.6886],
      [110.4066, -7.6888],
      [110.4067, -7.6892],
      [110.4066, -7.6897],
      [110.4058, -7.6902],
      [110.4054, -7.6905],
      [110.4054, -7.691],
      [110.4052, -7.6912],
      [110.4051, -7.6918],
      [110.4046, -7.692],
      [110.4046, -7.6925],
      [110.4044, -7.6932],
      [110.4043, -7.6933],
      [110.4043, -7.6945],
      [110.404, -7.6954],
      [110.4037, -7.6961],
      [110.4038, -7.6965],
      [110.4038, -7.6969],
      [110.4036, -7.6971],
      [110.4033, -7.6971],
      [110.4033, -7.6974],
      [110.4034, -7.6978],
      [110.4031, -7.6981],
      [110.4027, -7.6984],
      [110.4027, -7.6989],
      [110.4022, -7.6992],
      [110.4019, -7.6995],
      [110.4017, -7.6998],
      [110.4013, -7.7],
      [110.4012, -7.7005],
      [110.4012, -7.7008],
      [110.4009, -7.7012],
      [110.4007, -7.7014],
      [110.4005, -7.7016],
      [110.4002, -7.7022],
      [110.4, -7.7023],
      [110.3997, -7.7026],
      [110.3986, -7.7018],
      [110.3985, -7.7014],
      [110.3983, -7.7011],
      [110.398, -7.7009],
      [110.3976, -7.7008],
      [110.3972, -7.7003],
      [110.3971, -7.7002],
      [110.3974, -7.6997],
      [110.3978, -7.6992],
      [110.3985, -7.6988],
      [110.3993, -7.6982],
      [110.4, -7.6976],
      [110.4003, -7.6967],
      [110.4004, -7.6956],
      [110.4004, -7.6954],
      [110.4004, -7.6953],
      [110.4004, -7.695],
      [110.4005, -7.6946],
      [110.4009, -7.6939],
      [110.401, -7.6939],
      [110.4012, -7.6939],
      [110.4012, -7.6936],
      [110.4013, -7.6932],
      [110.4016, -7.6924],
      [110.4016, -7.6919],
      [110.4017, -7.6916],
      [110.4018, -7.6914],
      [110.4018, -7.6912],
      [110.4026, -7.691],
      [110.4027, -7.6909],
      [110.403, -7.6907],
      [110.4033, -7.6896],
      [110.4034, -7.6892],
      [110.4042, -7.6885],
      [110.4046, -7.6884],
      [110.4042, -7.6876],
      [110.404, -7.6872]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'NGAGLIK',
    village: 'DONOHARJO',
    border: [
      [110.3944, -7.6752],
      [110.3932, -7.6749],
      [110.3927, -7.6747],
      [110.3918, -7.6745],
      [110.391, -7.6749],
      [110.3891, -7.6758],
      [110.3885, -7.676],
      [110.3879, -7.6759],
      [110.3863, -7.6755],
      [110.3845, -7.6751],
      [110.384, -7.675],
      [110.3833, -7.6734],
      [110.3826, -7.673],
      [110.3823, -7.6728],
      [110.3821, -7.6727],
      [110.3816, -7.6728],
      [110.3813, -7.6732],
      [110.3808, -7.6736],
      [110.3788, -7.6728],
      [110.3784, -7.6727],
      [110.3773, -7.6723],
      [110.377, -7.6724],
      [110.377, -7.6727],
      [110.3771, -7.6729],
      [110.3771, -7.673],
      [110.3771, -7.6735],
      [110.3773, -7.6739],
      [110.3774, -7.6744],
      [110.3774, -7.6745],
      [110.3774, -7.6752],
      [110.3773, -7.6759],
      [110.3771, -7.6765],
      [110.3766, -7.6773],
      [110.3765, -7.678],
      [110.3761, -7.6785],
      [110.3761, -7.6788],
      [110.3762, -7.6796],
      [110.3762, -7.6803],
      [110.3755, -7.6809],
      [110.3753, -7.6818],
      [110.3751, -7.6828],
      [110.3744, -7.6833],
      [110.3743, -7.6837],
      [110.374, -7.6839],
      [110.3743, -7.6841],
      [110.3757, -7.6846],
      [110.3758, -7.6846],
      [110.3785, -7.6855],
      [110.379, -7.6863],
      [110.3795, -7.6869],
      [110.3797, -7.687],
      [110.3799, -7.6871],
      [110.3807, -7.6873],
      [110.3816, -7.6877],
      [110.3819, -7.6878],
      [110.382, -7.6878],
      [110.3818, -7.6882],
      [110.3815, -7.6886],
      [110.381, -7.6892],
      [110.3808, -7.6898],
      [110.3806, -7.6903],
      [110.38, -7.691],
      [110.38, -7.6912],
      [110.3801, -7.6923],
      [110.3802, -7.6927],
      [110.3807, -7.6925],
      [110.3812, -7.6928],
      [110.3814, -7.6929],
      [110.3812, -7.6934],
      [110.3815, -7.6935],
      [110.3822, -7.6937],
      [110.3824, -7.6938],
      [110.3822, -7.6943],
      [110.3816, -7.6954],
      [110.3811, -7.6963],
      [110.3805, -7.6978],
      [110.3797, -7.6984],
      [110.3799, -7.6986],
      [110.3801, -7.699],
      [110.3802, -7.6995],
      [110.3803, -7.7002],
      [110.3802, -7.7003],
      [110.3799, -7.7005],
      [110.3795, -7.7006],
      [110.3791, -7.7004],
      [110.3786, -7.7006],
      [110.378, -7.7007],
      [110.3762, -7.701],
      [110.3755, -7.703],
      [110.375, -7.7041],
      [110.3746, -7.7045],
      [110.3744, -7.7053],
      [110.3751, -7.7057],
      [110.3767, -7.7065],
      [110.3764, -7.7068],
      [110.3764, -7.7076],
      [110.3762, -7.7077],
      [110.3763, -7.7086],
      [110.3754, -7.7092],
      [110.3747, -7.7093],
      [110.3742, -7.7097],
      [110.3737, -7.7105],
      [110.3729, -7.7112],
      [110.3726, -7.7113],
      [110.3724, -7.7115],
      [110.3729, -7.7117],
      [110.3735, -7.7119],
      [110.3741, -7.7116],
      [110.3745, -7.7114],
      [110.3746, -7.7114],
      [110.3745, -7.7118],
      [110.374, -7.7126],
      [110.3736, -7.7133],
      [110.3732, -7.7136],
      [110.3729, -7.714],
      [110.3723, -7.715],
      [110.3717, -7.7159],
      [110.3719, -7.7165],
      [110.3715, -7.7169],
      [110.3715, -7.7178],
      [110.3712, -7.7186],
      [110.3709, -7.719],
      [110.3708, -7.7191],
      [110.3701, -7.7195],
      [110.3699, -7.7199],
      [110.3699, -7.7204],
      [110.3697, -7.7205],
      [110.3692, -7.7205],
      [110.3686, -7.7203],
      [110.3683, -7.7202],
      [110.3683, -7.7206],
      [110.3686, -7.7208],
      [110.3691, -7.721],
      [110.3699, -7.7212],
      [110.3703, -7.7215],
      [110.3709, -7.722],
      [110.3711, -7.7221],
      [110.3715, -7.7218],
      [110.3717, -7.7216],
      [110.3725, -7.7215],
      [110.3729, -7.7217],
      [110.3733, -7.7219],
      [110.3735, -7.7223],
      [110.3737, -7.722],
      [110.3739, -7.7218],
      [110.3741, -7.7216],
      [110.3741, -7.7215],
      [110.3741, -7.7212],
      [110.3741, -7.7211],
      [110.3745, -7.7207],
      [110.375, -7.7204],
      [110.3754, -7.7202],
      [110.3758, -7.7202],
      [110.376, -7.72],
      [110.3761, -7.7197],
      [110.3764, -7.7192],
      [110.3766, -7.7189],
      [110.3766, -7.7181],
      [110.3768, -7.7179],
      [110.3769, -7.7175],
      [110.377, -7.7172],
      [110.3773, -7.7171],
      [110.3776, -7.7168],
      [110.3776, -7.7166],
      [110.3777, -7.7162],
      [110.378, -7.716],
      [110.3783, -7.7158],
      [110.3783, -7.7152],
      [110.3782, -7.7152],
      [110.3777, -7.7148],
      [110.3764, -7.7137],
      [110.3762, -7.7135],
      [110.3762, -7.7134],
      [110.3761, -7.7129],
      [110.3761, -7.7125],
      [110.3762, -7.7123],
      [110.3763, -7.7119],
      [110.3765, -7.7111],
      [110.3769, -7.7111],
      [110.378, -7.7119],
      [110.3785, -7.7122],
      [110.3791, -7.7124],
      [110.3805, -7.7129],
      [110.3812, -7.7132],
      [110.3814, -7.7133],
      [110.382, -7.7125],
      [110.3825, -7.7119],
      [110.3828, -7.7109],
      [110.3833, -7.7097],
      [110.3837, -7.709],
      [110.3844, -7.7084],
      [110.3855, -7.7076],
      [110.3861, -7.7069],
      [110.3863, -7.7059],
      [110.3865, -7.7049],
      [110.3873, -7.7051],
      [110.3879, -7.7051],
      [110.3885, -7.7051],
      [110.3889, -7.7048],
      [110.3891, -7.7047],
      [110.3892, -7.7045],
      [110.3894, -7.7041],
      [110.3895, -7.7037],
      [110.3898, -7.7033],
      [110.39, -7.7029],
      [110.39, -7.7027],
      [110.39, -7.7025],
      [110.3898, -7.7023],
      [110.3896, -7.7018],
      [110.3897, -7.7016],
      [110.3898, -7.7014],
      [110.3897, -7.7012],
      [110.3893, -7.7005],
      [110.3893, -7.6997],
      [110.3892, -7.6994],
      [110.3892, -7.6983],
      [110.3894, -7.698],
      [110.3894, -7.6977],
      [110.3896, -7.6973],
      [110.3897, -7.6969],
      [110.3901, -7.6965],
      [110.3903, -7.6962],
      [110.3901, -7.6958],
      [110.3902, -7.6955],
      [110.3906, -7.6952],
      [110.3908, -7.695],
      [110.3911, -7.6936],
      [110.3913, -7.6934],
      [110.3917, -7.6929],
      [110.3917, -7.6922],
      [110.3918, -7.6915],
      [110.3917, -7.6906],
      [110.392, -7.6902],
      [110.3923, -7.6898],
      [110.3923, -7.6897],
      [110.3927, -7.6891],
      [110.393, -7.6883],
      [110.3931, -7.6879],
      [110.3931, -7.6872],
      [110.3932, -7.6868],
      [110.3934, -7.6864],
      [110.3939, -7.6859],
      [110.3939, -7.6857],
      [110.394, -7.6849],
      [110.3939, -7.6836],
      [110.3937, -7.6835],
      [110.3936, -7.6835],
      [110.3936, -7.6831],
      [110.3937, -7.6827],
      [110.3939, -7.6824],
      [110.3939, -7.682],
      [110.3939, -7.6818],
      [110.3939, -7.6816],
      [110.3937, -7.6801],
      [110.3935, -7.6794],
      [110.3937, -7.6791],
      [110.3938, -7.6788],
      [110.3941, -7.6783],
      [110.3942, -7.6782],
      [110.3943, -7.6772],
      [110.3943, -7.6765],
      [110.3942, -7.6762],
      [110.3942, -7.6757],
      [110.3944, -7.6752]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SLEMAN',
    village: 'CATURHARJO',
    border: [
      [110.3398, -7.674],
      [110.3398, -7.6741],
      [110.3397, -7.6741],
      [110.3389, -7.674],
      [110.3382, -7.6738],
      [110.3376, -7.6733],
      [110.337, -7.6731],
      [110.3357, -7.6726],
      [110.3353, -7.6725],
      [110.3348, -7.6715],
      [110.3344, -7.6719],
      [110.3346, -7.6726],
      [110.3339, -7.6729],
      [110.3338, -7.6733],
      [110.3337, -7.6736],
      [110.3336, -7.6737],
      [110.3331, -7.6741],
      [110.3326, -7.6746],
      [110.3322, -7.6751],
      [110.3319, -7.6755],
      [110.3318, -7.6758],
      [110.3315, -7.6761],
      [110.3312, -7.6764],
      [110.3306, -7.6774],
      [110.3301, -7.6777],
      [110.33, -7.6781],
      [110.33, -7.6785],
      [110.3302, -7.6791],
      [110.3301, -7.6793],
      [110.3299, -7.6796],
      [110.3296, -7.6798],
      [110.3294, -7.6799],
      [110.3292, -7.6804],
      [110.3289, -7.6806],
      [110.3285, -7.6807],
      [110.3283, -7.6807],
      [110.3279, -7.6807],
      [110.3262, -7.6802],
      [110.3261, -7.6803],
      [110.3256, -7.6807],
      [110.3252, -7.6811],
      [110.325, -7.681],
      [110.3225, -7.6799],
      [110.3225, -7.6798],
      [110.3227, -7.6796],
      [110.323, -7.6795],
      [110.3231, -7.6793],
      [110.3231, -7.6792],
      [110.322, -7.6787],
      [110.3202, -7.6779],
      [110.3198, -7.6782],
      [110.3182, -7.6797],
      [110.3171, -7.6791],
      [110.3171, -7.6793],
      [110.3166, -7.6792],
      [110.3164, -7.6791],
      [110.3163, -7.6794],
      [110.3159, -7.6802],
      [110.3154, -7.6807],
      [110.3151, -7.6813],
      [110.3148, -7.6819],
      [110.3147, -7.6824],
      [110.3139, -7.6828],
      [110.3138, -7.6835],
      [110.3136, -7.6837],
      [110.3133, -7.684],
      [110.3131, -7.6842],
      [110.3128, -7.6846],
      [110.3127, -7.6849],
      [110.3126, -7.6851],
      [110.3125, -7.6859],
      [110.3124, -7.6861],
      [110.3121, -7.6863],
      [110.3119, -7.6865],
      [110.3117, -7.6868],
      [110.3114, -7.6868],
      [110.3113, -7.6873],
      [110.3112, -7.6881],
      [110.311, -7.6883],
      [110.3105, -7.6888],
      [110.3105, -7.6893],
      [110.3102, -7.6899],
      [110.3099, -7.6902],
      [110.3095, -7.6902],
      [110.3089, -7.6908],
      [110.3088, -7.6913],
      [110.3083, -7.6924],
      [110.3081, -7.6928],
      [110.3078, -7.6932],
      [110.3074, -7.6933],
      [110.3076, -7.6935],
      [110.3077, -7.6935],
      [110.3078, -7.6936],
      [110.3078, -7.6939],
      [110.3074, -7.6941],
      [110.307, -7.6944],
      [110.3067, -7.6947],
      [110.3075, -7.6952],
      [110.3078, -7.6955],
      [110.3089, -7.698],
      [110.3093, -7.6984],
      [110.3097, -7.6987],
      [110.3107, -7.6989],
      [110.3116, -7.6988],
      [110.3123, -7.6986],
      [110.3127, -7.6982],
      [110.3131, -7.6974],
      [110.3134, -7.6969],
      [110.3139, -7.6966],
      [110.3144, -7.6964],
      [110.3151, -7.697],
      [110.3157, -7.697],
      [110.3161, -7.6971],
      [110.3163, -7.6971],
      [110.3173, -7.6977],
      [110.3182, -7.6979],
      [110.319, -7.6981],
      [110.3198, -7.6983],
      [110.3202, -7.6983],
      [110.3209, -7.6982],
      [110.3212, -7.6981],
      [110.3215, -7.698],
      [110.3229, -7.6988],
      [110.3247, -7.6998],
      [110.3254, -7.6998],
      [110.326, -7.6997],
      [110.3264, -7.6998],
      [110.3265, -7.7],
      [110.3265, -7.7005],
      [110.3259, -7.7006],
      [110.3254, -7.7013],
      [110.3252, -7.7017],
      [110.3258, -7.702],
      [110.3259, -7.7024],
      [110.3266, -7.7029],
      [110.3265, -7.7032],
      [110.3264, -7.7034],
      [110.3266, -7.7036],
      [110.3276, -7.7042],
      [110.3281, -7.7045],
      [110.3291, -7.705],
      [110.3296, -7.7049],
      [110.3296, -7.7045],
      [110.3294, -7.7036],
      [110.3294, -7.7032],
      [110.3298, -7.7027],
      [110.3302, -7.7022],
      [110.3305, -7.7017],
      [110.3305, -7.7013],
      [110.3307, -7.7011],
      [110.3312, -7.7008],
      [110.3315, -7.7004],
      [110.3317, -7.7004],
      [110.3318, -7.7003],
      [110.3327, -7.7002],
      [110.333, -7.6999],
      [110.3339, -7.7],
      [110.3341, -7.6999],
      [110.3348, -7.6987],
      [110.3351, -7.6979],
      [110.3359, -7.6962],
      [110.3359, -7.696],
      [110.3356, -7.6955],
      [110.3356, -7.6951],
      [110.3358, -7.6949],
      [110.3363, -7.6946],
      [110.3366, -7.6944],
      [110.3367, -7.6938],
      [110.3368, -7.6933],
      [110.3371, -7.6927],
      [110.3372, -7.6923],
      [110.3374, -7.6919],
      [110.3374, -7.6916],
      [110.3379, -7.691],
      [110.3382, -7.6904],
      [110.3385, -7.6899],
      [110.3388, -7.6895],
      [110.3397, -7.6891],
      [110.34, -7.6888],
      [110.3402, -7.6884],
      [110.3406, -7.6881],
      [110.3412, -7.6881],
      [110.3418, -7.6878],
      [110.3419, -7.6873],
      [110.3413, -7.6859],
      [110.3409, -7.6851],
      [110.3408, -7.685],
      [110.3396, -7.6826],
      [110.3388, -7.6809],
      [110.3388, -7.6801],
      [110.3388, -7.6797],
      [110.3387, -7.6785],
      [110.3397, -7.6779],
      [110.3401, -7.6762],
      [110.3407, -7.6762],
      [110.3408, -7.6757],
      [110.3412, -7.6745],
      [110.3405, -7.6743],
      [110.3398, -7.674]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SLEMAN',
    village: 'TRIHARJO',
    border: [
      [110.3526, -7.6967],
      [110.3527, -7.696],
      [110.3525, -7.6951],
      [110.3525, -7.6942],
      [110.3524, -7.6934],
      [110.3526, -7.6924],
      [110.3527, -7.691],
      [110.3528, -7.6905],
      [110.3528, -7.6902],
      [110.3526, -7.6896],
      [110.3529, -7.6887],
      [110.3532, -7.6881],
      [110.353, -7.6872],
      [110.353, -7.6861],
      [110.3531, -7.6856],
      [110.3537, -7.6845],
      [110.354, -7.6838],
      [110.3541, -7.6835],
      [110.3544, -7.6818],
      [110.3545, -7.6805],
      [110.3547, -7.6793],
      [110.3547, -7.679],
      [110.352, -7.678],
      [110.3515, -7.6778],
      [110.3513, -7.6772],
      [110.3491, -7.6763],
      [110.3487, -7.6762],
      [110.3454, -7.6749],
      [110.3448, -7.6748],
      [110.3442, -7.6743],
      [110.3437, -7.6739],
      [110.3434, -7.6736],
      [110.3431, -7.6733],
      [110.341, -7.6726],
      [110.3405, -7.6742],
      [110.3405, -7.6743],
      [110.3412, -7.6745],
      [110.3408, -7.6757],
      [110.3407, -7.6762],
      [110.3401, -7.6762],
      [110.3397, -7.6779],
      [110.3387, -7.6785],
      [110.3388, -7.6797],
      [110.3388, -7.6801],
      [110.3388, -7.6809],
      [110.3396, -7.6826],
      [110.3408, -7.685],
      [110.3409, -7.6851],
      [110.3413, -7.6859],
      [110.3419, -7.6873],
      [110.3418, -7.6878],
      [110.3412, -7.6881],
      [110.3406, -7.6881],
      [110.3402, -7.6884],
      [110.34, -7.6888],
      [110.3397, -7.6891],
      [110.3388, -7.6895],
      [110.3385, -7.6899],
      [110.3382, -7.6904],
      [110.3379, -7.691],
      [110.3374, -7.6916],
      [110.3374, -7.6919],
      [110.3372, -7.6923],
      [110.3371, -7.6927],
      [110.3368, -7.6933],
      [110.3367, -7.6938],
      [110.3366, -7.6944],
      [110.3363, -7.6946],
      [110.3358, -7.6949],
      [110.3356, -7.6951],
      [110.3356, -7.6955],
      [110.3359, -7.696],
      [110.3359, -7.6962],
      [110.3351, -7.6979],
      [110.3348, -7.6987],
      [110.3341, -7.6999],
      [110.3339, -7.7],
      [110.333, -7.6999],
      [110.3327, -7.7002],
      [110.3318, -7.7003],
      [110.3317, -7.7004],
      [110.3327, -7.7007],
      [110.3337, -7.7009],
      [110.3347, -7.7012],
      [110.3345, -7.7021],
      [110.3344, -7.7025],
      [110.3341, -7.7031],
      [110.334, -7.704],
      [110.3337, -7.7046],
      [110.3335, -7.7053],
      [110.3333, -7.7062],
      [110.3331, -7.7069],
      [110.3329, -7.7073],
      [110.3322, -7.7087],
      [110.3319, -7.7094],
      [110.3316, -7.7099],
      [110.3319, -7.7102],
      [110.3322, -7.7104],
      [110.3327, -7.7105],
      [110.3333, -7.7106],
      [110.3336, -7.7101],
      [110.3337, -7.7096],
      [110.334, -7.7087],
      [110.334, -7.7079],
      [110.3343, -7.7076],
      [110.3346, -7.7073],
      [110.3353, -7.7078],
      [110.3358, -7.7081],
      [110.3359, -7.7075],
      [110.3361, -7.707],
      [110.3363, -7.7067],
      [110.3366, -7.7068],
      [110.337, -7.7069],
      [110.3372, -7.7067],
      [110.3375, -7.7066],
      [110.3383, -7.7066],
      [110.3391, -7.7062],
      [110.3394, -7.7066],
      [110.3397, -7.7074],
      [110.3398, -7.7077],
      [110.3403, -7.7087],
      [110.3405, -7.7083],
      [110.3406, -7.7078],
      [110.3407, -7.7073],
      [110.3413, -7.7071],
      [110.3423, -7.7069],
      [110.3433, -7.7068],
      [110.344, -7.707],
      [110.345, -7.7072],
      [110.3457, -7.7072],
      [110.3471, -7.7073],
      [110.3477, -7.7069],
      [110.3482, -7.704],
      [110.3486, -7.7022],
      [110.3486, -7.7009],
      [110.3481, -7.6995],
      [110.3481, -7.699],
      [110.3496, -7.6979],
      [110.3516, -7.6984],
      [110.3519, -7.6983],
      [110.3525, -7.6975],
      [110.3526, -7.6967]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SLEMAN',
    village: 'TRIDADI',
    border: [
      [110.358, -7.7006],
      [110.3575, -7.7005],
      [110.3571, -7.7003],
      [110.3569, -7.7],
      [110.3572, -7.6991],
      [110.3576, -7.6984],
      [110.358, -7.6981],
      [110.3578, -7.6977],
      [110.3578, -7.6971],
      [110.3581, -7.6962],
      [110.3581, -7.6957],
      [110.3577, -7.6957],
      [110.3574, -7.6959],
      [110.3572, -7.6965],
      [110.3569, -7.6976],
      [110.3567, -7.6982],
      [110.356, -7.6984],
      [110.3549, -7.6987],
      [110.3546, -7.6987],
      [110.3545, -7.6989],
      [110.3542, -7.699],
      [110.3541, -7.699],
      [110.3537, -7.6985],
      [110.3536, -7.6977],
      [110.3537, -7.6971],
      [110.3537, -7.6967],
      [110.3537, -7.6965],
      [110.3534, -7.6961],
      [110.3529, -7.6963],
      [110.3526, -7.6967],
      [110.3525, -7.6975],
      [110.3519, -7.6983],
      [110.3516, -7.6984],
      [110.3496, -7.6979],
      [110.3481, -7.699],
      [110.3481, -7.6995],
      [110.3486, -7.7009],
      [110.3486, -7.7022],
      [110.3482, -7.704],
      [110.3477, -7.7069],
      [110.3471, -7.7073],
      [110.3457, -7.7072],
      [110.345, -7.7072],
      [110.344, -7.707],
      [110.3433, -7.7068],
      [110.3423, -7.7069],
      [110.3413, -7.7071],
      [110.3407, -7.7073],
      [110.3406, -7.7078],
      [110.3405, -7.7083],
      [110.3403, -7.7087],
      [110.3404, -7.7092],
      [110.3403, -7.7097],
      [110.3403, -7.7104],
      [110.3402, -7.7106],
      [110.3401, -7.7107],
      [110.3399, -7.7111],
      [110.3397, -7.7117],
      [110.3398, -7.7119],
      [110.3399, -7.7126],
      [110.3402, -7.7132],
      [110.3407, -7.7133],
      [110.3412, -7.7137],
      [110.3416, -7.7143],
      [110.3419, -7.7146],
      [110.342, -7.7147],
      [110.3421, -7.7147],
      [110.3424, -7.7145],
      [110.3431, -7.7138],
      [110.3436, -7.7133],
      [110.3439, -7.7133],
      [110.3441, -7.7138],
      [110.3443, -7.7138],
      [110.3448, -7.7139],
      [110.3449, -7.7143],
      [110.345, -7.7147],
      [110.345, -7.715],
      [110.3454, -7.7154],
      [110.3451, -7.7159],
      [110.3448, -7.7174],
      [110.3447, -7.7183],
      [110.3444, -7.7198],
      [110.3446, -7.7201],
      [110.3449, -7.7205],
      [110.3449, -7.7207],
      [110.3446, -7.721],
      [110.3447, -7.7217],
      [110.3447, -7.7223],
      [110.3449, -7.7224],
      [110.3456, -7.7226],
      [110.3462, -7.7228],
      [110.3461, -7.7223],
      [110.3463, -7.722],
      [110.3465, -7.7214],
      [110.3469, -7.721],
      [110.3473, -7.7207],
      [110.3478, -7.7203],
      [110.3483, -7.7204],
      [110.3488, -7.7201],
      [110.3491, -7.7203],
      [110.3494, -7.7207],
      [110.3495, -7.7209],
      [110.3499, -7.7212],
      [110.3501, -7.7213],
      [110.3502, -7.7214],
      [110.3507, -7.7215],
      [110.351, -7.7214],
      [110.3519, -7.7214],
      [110.3536, -7.7213],
      [110.3553, -7.7215],
      [110.3556, -7.7216],
      [110.3556, -7.7219],
      [110.3556, -7.7221],
      [110.3555, -7.7227],
      [110.3553, -7.7232],
      [110.3549, -7.7236],
      [110.3544, -7.7243],
      [110.3538, -7.725],
      [110.3535, -7.7254],
      [110.3532, -7.726],
      [110.3534, -7.7265],
      [110.3536, -7.7267],
      [110.354, -7.7269],
      [110.3543, -7.7272],
      [110.354, -7.7279],
      [110.3549, -7.7283],
      [110.3555, -7.7286],
      [110.356, -7.7273],
      [110.3571, -7.7252],
      [110.3576, -7.7242],
      [110.3579, -7.7236],
      [110.358, -7.7231],
      [110.3581, -7.7226],
      [110.3583, -7.7221],
      [110.3589, -7.7222],
      [110.3595, -7.7224],
      [110.3603, -7.7229],
      [110.3607, -7.7232],
      [110.3613, -7.7231],
      [110.3619, -7.7235],
      [110.3622, -7.7241],
      [110.3629, -7.7241],
      [110.3632, -7.7242],
      [110.3637, -7.7247],
      [110.364, -7.7247],
      [110.3642, -7.7245],
      [110.3644, -7.7242],
      [110.3649, -7.7236],
      [110.365, -7.7229],
      [110.3651, -7.7223],
      [110.3651, -7.7216],
      [110.3653, -7.7211],
      [110.3655, -7.7211],
      [110.3659, -7.721],
      [110.3662, -7.7208],
      [110.3663, -7.7207],
      [110.3663, -7.7201],
      [110.3664, -7.7197],
      [110.3667, -7.7192],
      [110.3667, -7.7186],
      [110.3668, -7.7182],
      [110.3669, -7.718],
      [110.3664, -7.7177],
      [110.3663, -7.7177],
      [110.366, -7.7177],
      [110.365, -7.7178],
      [110.3645, -7.7184],
      [110.3642, -7.7186],
      [110.364, -7.7188],
      [110.3636, -7.7189],
      [110.3631, -7.719],
      [110.3626, -7.719],
      [110.3621, -7.7185],
      [110.3624, -7.7182],
      [110.3624, -7.7178],
      [110.362, -7.7174],
      [110.3619, -7.7169],
      [110.3621, -7.7163],
      [110.3625, -7.7157],
      [110.3627, -7.7154],
      [110.3626, -7.7149],
      [110.3625, -7.7144],
      [110.3623, -7.7138],
      [110.3623, -7.7136],
      [110.3625, -7.7136],
      [110.3628, -7.7136],
      [110.363, -7.7136],
      [110.3632, -7.7135],
      [110.3634, -7.713],
      [110.3632, -7.7126],
      [110.363, -7.7123],
      [110.363, -7.7117],
      [110.3628, -7.7115],
      [110.3626, -7.7111],
      [110.3626, -7.7109],
      [110.3628, -7.7107],
      [110.3628, -7.7105],
      [110.3628, -7.7102],
      [110.3631, -7.71],
      [110.3633, -7.7099],
      [110.3634, -7.7097],
      [110.3633, -7.7094],
      [110.3632, -7.7092],
      [110.363, -7.7091],
      [110.3627, -7.7091],
      [110.3619, -7.7094],
      [110.3609, -7.71],
      [110.3597, -7.7109],
      [110.3591, -7.7113],
      [110.3591, -7.7117],
      [110.3591, -7.712],
      [110.3589, -7.7122],
      [110.3586, -7.7123],
      [110.3582, -7.7121],
      [110.3564, -7.7102],
      [110.3563, -7.7096],
      [110.3562, -7.7091],
      [110.3563, -7.7088],
      [110.3566, -7.7085],
      [110.3572, -7.7082],
      [110.3579, -7.7083],
      [110.3582, -7.708],
      [110.3586, -7.7074],
      [110.3586, -7.7069],
      [110.3585, -7.7064],
      [110.3584, -7.706],
      [110.3585, -7.7056],
      [110.3589, -7.7052],
      [110.3594, -7.7051],
      [110.3597, -7.7048],
      [110.3595, -7.7041],
      [110.3595, -7.7038],
      [110.3598, -7.7036],
      [110.3599, -7.7033],
      [110.36, -7.7029],
      [110.36, -7.7023],
      [110.3596, -7.7022],
      [110.3586, -7.7019],
      [110.3583, -7.7017],
      [110.358, -7.7015],
      [110.358, -7.701],
      [110.358, -7.7006]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SLEMAN',
    village: 'PANDOWOHARJO',
    border: [
      [110.3744, -7.6833],
      [110.3743, -7.6832],
      [110.3741, -7.6832],
      [110.3737, -7.6829],
      [110.3735, -7.6826],
      [110.3738, -7.6823],
      [110.3738, -7.682],
      [110.3735, -7.6816],
      [110.3731, -7.6815],
      [110.3727, -7.6815],
      [110.372, -7.6813],
      [110.3714, -7.6813],
      [110.3709, -7.6812],
      [110.3706, -7.6809],
      [110.3707, -7.6806],
      [110.3708, -7.6801],
      [110.371, -7.6796],
      [110.3711, -7.6786],
      [110.3711, -7.6778],
      [110.371, -7.6775],
      [110.3708, -7.6772],
      [110.3697, -7.6765],
      [110.3693, -7.6764],
      [110.3684, -7.6762],
      [110.3683, -7.6761],
      [110.368, -7.6762],
      [110.3679, -7.6762],
      [110.3676, -7.6763],
      [110.3669, -7.6767],
      [110.3666, -7.6772],
      [110.3665, -7.678],
      [110.366, -7.6784],
      [110.3655, -7.6787],
      [110.3651, -7.6789],
      [110.3648, -7.6793],
      [110.3648, -7.6799],
      [110.365, -7.6803],
      [110.365, -7.6807],
      [110.3646, -7.6811],
      [110.3643, -7.6814],
      [110.3644, -7.6822],
      [110.364, -7.6828],
      [110.3639, -7.6835],
      [110.3637, -7.6843],
      [110.3636, -7.6844],
      [110.3633, -7.6843],
      [110.3631, -7.6843],
      [110.3628, -7.685],
      [110.3626, -7.6859],
      [110.3626, -7.6871],
      [110.3627, -7.6875],
      [110.3631, -7.6879],
      [110.3633, -7.6885],
      [110.363, -7.6892],
      [110.363, -7.6905],
      [110.3627, -7.691],
      [110.3626, -7.6916],
      [110.3624, -7.6921],
      [110.3619, -7.6933],
      [110.3621, -7.6938],
      [110.3623, -7.6948],
      [110.3621, -7.6954],
      [110.3617, -7.6956],
      [110.3612, -7.697],
      [110.361, -7.6976],
      [110.3605, -7.698],
      [110.3601, -7.6983],
      [110.3597, -7.6984],
      [110.3593, -7.6989],
      [110.3594, -7.6994],
      [110.3593, -7.7],
      [110.3589, -7.7003],
      [110.3585, -7.7005],
      [110.358, -7.7006],
      [110.358, -7.701],
      [110.358, -7.7015],
      [110.3583, -7.7017],
      [110.3586, -7.7019],
      [110.3596, -7.7022],
      [110.36, -7.7023],
      [110.36, -7.7029],
      [110.3599, -7.7033],
      [110.3598, -7.7036],
      [110.3595, -7.7038],
      [110.3595, -7.7041],
      [110.3597, -7.7048],
      [110.3594, -7.7051],
      [110.3589, -7.7052],
      [110.3585, -7.7056],
      [110.3584, -7.706],
      [110.3585, -7.7064],
      [110.3586, -7.7069],
      [110.3586, -7.7074],
      [110.3582, -7.708],
      [110.3579, -7.7083],
      [110.3572, -7.7082],
      [110.3566, -7.7085],
      [110.3563, -7.7088],
      [110.3562, -7.7091],
      [110.3563, -7.7096],
      [110.3564, -7.7102],
      [110.3582, -7.7121],
      [110.3586, -7.7123],
      [110.3589, -7.7122],
      [110.3591, -7.712],
      [110.3591, -7.7117],
      [110.3591, -7.7113],
      [110.3597, -7.7109],
      [110.3609, -7.71],
      [110.3619, -7.7094],
      [110.3627, -7.7091],
      [110.363, -7.7091],
      [110.3632, -7.7092],
      [110.3633, -7.7094],
      [110.3634, -7.7097],
      [110.3633, -7.7099],
      [110.3631, -7.71],
      [110.3628, -7.7102],
      [110.3628, -7.7105],
      [110.3628, -7.7107],
      [110.3626, -7.7109],
      [110.3626, -7.7111],
      [110.3628, -7.7115],
      [110.363, -7.7117],
      [110.363, -7.7123],
      [110.3632, -7.7126],
      [110.3634, -7.713],
      [110.3632, -7.7135],
      [110.363, -7.7136],
      [110.3628, -7.7136],
      [110.3625, -7.7136],
      [110.3623, -7.7136],
      [110.3623, -7.7138],
      [110.3625, -7.7144],
      [110.3626, -7.7149],
      [110.3627, -7.7154],
      [110.3625, -7.7157],
      [110.3621, -7.7163],
      [110.3619, -7.7169],
      [110.362, -7.7174],
      [110.3624, -7.7178],
      [110.3624, -7.7182],
      [110.3621, -7.7185],
      [110.3626, -7.719],
      [110.3631, -7.719],
      [110.3636, -7.7189],
      [110.364, -7.7188],
      [110.3642, -7.7186],
      [110.3645, -7.7184],
      [110.365, -7.7178],
      [110.366, -7.7177],
      [110.3663, -7.7177],
      [110.3664, -7.7177],
      [110.3669, -7.718],
      [110.3668, -7.7182],
      [110.3667, -7.7186],
      [110.3667, -7.7192],
      [110.3671, -7.7192],
      [110.3679, -7.719],
      [110.3679, -7.7197],
      [110.3681, -7.7201],
      [110.3683, -7.7202],
      [110.3686, -7.7203],
      [110.3692, -7.7205],
      [110.3697, -7.7205],
      [110.3699, -7.7204],
      [110.3699, -7.7199],
      [110.3701, -7.7195],
      [110.3708, -7.7191],
      [110.3709, -7.719],
      [110.3712, -7.7186],
      [110.3715, -7.7178],
      [110.3715, -7.7169],
      [110.3719, -7.7165],
      [110.3717, -7.7159],
      [110.3723, -7.715],
      [110.3729, -7.714],
      [110.3732, -7.7136],
      [110.3736, -7.7133],
      [110.374, -7.7126],
      [110.3745, -7.7118],
      [110.3746, -7.7114],
      [110.3745, -7.7114],
      [110.3741, -7.7116],
      [110.3735, -7.7119],
      [110.3729, -7.7117],
      [110.3724, -7.7115],
      [110.3726, -7.7113],
      [110.3729, -7.7112],
      [110.3737, -7.7105],
      [110.3742, -7.7097],
      [110.3747, -7.7093],
      [110.3754, -7.7092],
      [110.3763, -7.7086],
      [110.3762, -7.7077],
      [110.3764, -7.7076],
      [110.3764, -7.7068],
      [110.3767, -7.7065],
      [110.3751, -7.7057],
      [110.3744, -7.7053],
      [110.3746, -7.7045],
      [110.375, -7.7041],
      [110.3755, -7.703],
      [110.3762, -7.701],
      [110.378, -7.7007],
      [110.3786, -7.7006],
      [110.3791, -7.7004],
      [110.3795, -7.7006],
      [110.3799, -7.7005],
      [110.3802, -7.7003],
      [110.3803, -7.7002],
      [110.3802, -7.6995],
      [110.3801, -7.699],
      [110.3799, -7.6986],
      [110.3797, -7.6984],
      [110.3805, -7.6978],
      [110.3811, -7.6963],
      [110.3816, -7.6954],
      [110.3822, -7.6943],
      [110.3824, -7.6938],
      [110.3822, -7.6937],
      [110.3815, -7.6935],
      [110.3812, -7.6934],
      [110.3814, -7.6929],
      [110.3812, -7.6928],
      [110.3807, -7.6925],
      [110.3802, -7.6927],
      [110.3801, -7.6923],
      [110.38, -7.6912],
      [110.38, -7.691],
      [110.3806, -7.6903],
      [110.3808, -7.6898],
      [110.381, -7.6892],
      [110.3815, -7.6886],
      [110.3818, -7.6882],
      [110.382, -7.6878],
      [110.3819, -7.6878],
      [110.3816, -7.6877],
      [110.3807, -7.6873],
      [110.3799, -7.6871],
      [110.3797, -7.687],
      [110.3795, -7.6869],
      [110.379, -7.6863],
      [110.3785, -7.6855],
      [110.3758, -7.6846],
      [110.3757, -7.6846],
      [110.3743, -7.6841],
      [110.374, -7.6839],
      [110.3743, -7.6837],
      [110.3744, -7.6833]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'SLEMAN',
    village: 'TRIMULYO',
    border: [
      [110.3579, -7.6625],
      [110.3572, -7.6622],
      [110.3562, -7.662],
      [110.3553, -7.6628],
      [110.3552, -7.6631],
      [110.3551, -7.6632],
      [110.355, -7.6638],
      [110.3548, -7.6641],
      [110.3547, -7.6645],
      [110.3546, -7.665],
      [110.3546, -7.6656],
      [110.3546, -7.666],
      [110.3545, -7.6667],
      [110.3541, -7.6678],
      [110.3541, -7.6682],
      [110.3543, -7.6686],
      [110.3538, -7.6691],
      [110.3535, -7.6691],
      [110.3496, -7.6676],
      [110.3479, -7.667],
      [110.3482, -7.6668],
      [110.3484, -7.6666],
      [110.3485, -7.6666],
      [110.3487, -7.6665],
      [110.3488, -7.6662],
      [110.3479, -7.6658],
      [110.3467, -7.6653],
      [110.3437, -7.6641],
      [110.3438, -7.6643],
      [110.3438, -7.6646],
      [110.3436, -7.665],
      [110.3435, -7.6654],
      [110.3433, -7.666],
      [110.343, -7.6662],
      [110.3426, -7.6664],
      [110.3424, -7.6669],
      [110.3422, -7.6671],
      [110.3419, -7.6673],
      [110.3418, -7.6676],
      [110.3416, -7.6682],
      [110.3413, -7.669],
      [110.3413, -7.6698],
      [110.3412, -7.6706],
      [110.3405, -7.6716],
      [110.3401, -7.6727],
      [110.3398, -7.6737],
      [110.3398, -7.674],
      [110.3405, -7.6743],
      [110.3405, -7.6742],
      [110.341, -7.6726],
      [110.3431, -7.6733],
      [110.3434, -7.6736],
      [110.3437, -7.6739],
      [110.3442, -7.6743],
      [110.3448, -7.6748],
      [110.3454, -7.6749],
      [110.3487, -7.6762],
      [110.3491, -7.6763],
      [110.3513, -7.6772],
      [110.3515, -7.6778],
      [110.352, -7.678],
      [110.3547, -7.679],
      [110.3547, -7.6793],
      [110.3545, -7.6805],
      [110.3544, -7.6818],
      [110.3541, -7.6835],
      [110.354, -7.6838],
      [110.3537, -7.6845],
      [110.3531, -7.6856],
      [110.353, -7.6861],
      [110.353, -7.6872],
      [110.3532, -7.6881],
      [110.3529, -7.6887],
      [110.3526, -7.6896],
      [110.3528, -7.6902],
      [110.3528, -7.6905],
      [110.3527, -7.691],
      [110.3526, -7.6924],
      [110.3524, -7.6934],
      [110.3525, -7.6942],
      [110.3525, -7.6951],
      [110.3527, -7.696],
      [110.3526, -7.6967],
      [110.3529, -7.6963],
      [110.3534, -7.6961],
      [110.3537, -7.6965],
      [110.3537, -7.6967],
      [110.3537, -7.6971],
      [110.3536, -7.6977],
      [110.3537, -7.6985],
      [110.3541, -7.699],
      [110.3542, -7.699],
      [110.3545, -7.6989],
      [110.3546, -7.6987],
      [110.3549, -7.6987],
      [110.356, -7.6984],
      [110.3567, -7.6982],
      [110.3569, -7.6976],
      [110.3572, -7.6965],
      [110.3574, -7.6959],
      [110.3577, -7.6957],
      [110.3581, -7.6957],
      [110.3581, -7.6962],
      [110.3578, -7.6971],
      [110.3578, -7.6977],
      [110.358, -7.6981],
      [110.3576, -7.6984],
      [110.3572, -7.6991],
      [110.3569, -7.7],
      [110.3571, -7.7003],
      [110.3575, -7.7005],
      [110.358, -7.7006],
      [110.3585, -7.7005],
      [110.3589, -7.7003],
      [110.3593, -7.7],
      [110.3594, -7.6994],
      [110.3593, -7.6989],
      [110.3597, -7.6984],
      [110.3601, -7.6983],
      [110.3605, -7.698],
      [110.361, -7.6976],
      [110.3612, -7.697],
      [110.3617, -7.6956],
      [110.3621, -7.6954],
      [110.3623, -7.6948],
      [110.3621, -7.6938],
      [110.3619, -7.6933],
      [110.3624, -7.6921],
      [110.3626, -7.6916],
      [110.3627, -7.691],
      [110.363, -7.6905],
      [110.363, -7.6892],
      [110.3633, -7.6885],
      [110.3631, -7.6879],
      [110.3627, -7.6875],
      [110.3626, -7.6871],
      [110.3626, -7.6859],
      [110.3628, -7.685],
      [110.3631, -7.6843],
      [110.3633, -7.6843],
      [110.3636, -7.6844],
      [110.3637, -7.6843],
      [110.3639, -7.6835],
      [110.364, -7.6828],
      [110.3644, -7.6822],
      [110.3643, -7.6814],
      [110.3646, -7.6811],
      [110.365, -7.6807],
      [110.365, -7.6803],
      [110.3648, -7.6799],
      [110.3648, -7.6793],
      [110.3651, -7.6789],
      [110.3655, -7.6787],
      [110.366, -7.6784],
      [110.3665, -7.678],
      [110.3666, -7.6772],
      [110.3669, -7.6767],
      [110.3676, -7.6763],
      [110.3679, -7.6762],
      [110.368, -7.6762],
      [110.3683, -7.6761],
      [110.3681, -7.676],
      [110.3685, -7.6752],
      [110.3689, -7.6749],
      [110.3691, -7.675],
      [110.3692, -7.675],
      [110.3695, -7.6749],
      [110.3698, -7.6746],
      [110.3701, -7.6742],
      [110.3701, -7.6741],
      [110.3701, -7.6736],
      [110.3697, -7.6733],
      [110.3696, -7.6725],
      [110.3698, -7.6723],
      [110.3703, -7.6723],
      [110.3707, -7.6723],
      [110.3714, -7.6724],
      [110.3718, -7.6722],
      [110.3718, -7.6718],
      [110.3715, -7.6714],
      [110.3714, -7.671],
      [110.3716, -7.6704],
      [110.3716, -7.6701],
      [110.3717, -7.6698],
      [110.3715, -7.6692],
      [110.3714, -7.6692],
      [110.3711, -7.6692],
      [110.371, -7.6692],
      [110.3707, -7.6696],
      [110.3705, -7.6699],
      [110.37, -7.6698],
      [110.3699, -7.6698],
      [110.3694, -7.6696],
      [110.3689, -7.6696],
      [110.3687, -7.6698],
      [110.3685, -7.6702],
      [110.3679, -7.671],
      [110.3677, -7.671],
      [110.3671, -7.671],
      [110.3666, -7.6711],
      [110.3662, -7.6709],
      [110.366, -7.6708],
      [110.3649, -7.6704],
      [110.3646, -7.67],
      [110.3646, -7.6696],
      [110.3648, -7.669],
      [110.365, -7.6686],
      [110.3652, -7.6682],
      [110.3652, -7.6678],
      [110.365, -7.6673],
      [110.3648, -7.667],
      [110.3642, -7.6671],
      [110.3638, -7.6673],
      [110.3634, -7.6673],
      [110.3631, -7.6669],
      [110.3626, -7.6666],
      [110.3623, -7.6667],
      [110.3616, -7.6672],
      [110.3611, -7.6675],
      [110.361, -7.6679],
      [110.3605, -7.6688],
      [110.3604, -7.6689],
      [110.3593, -7.6685],
      [110.3584, -7.6679],
      [110.3579, -7.6676],
      [110.3572, -7.6675],
      [110.357, -7.6677],
      [110.3564, -7.6687],
      [110.3562, -7.6686],
      [110.356, -7.6681],
      [110.3557, -7.6675],
      [110.3558, -7.6669],
      [110.356, -7.6662],
      [110.3565, -7.6664],
      [110.3567, -7.6663],
      [110.3568, -7.666],
      [110.3568, -7.6652],
      [110.3568, -7.6648],
      [110.357, -7.6645],
      [110.3573, -7.6645],
      [110.3575, -7.6645],
      [110.3577, -7.6637],
      [110.3577, -7.6632],
      [110.3579, -7.6625]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'BANYUREJO',
    border: [
      [110.2909, -7.6877],
      [110.2903, -7.6876],
      [110.2901, -7.6872],
      [110.2901, -7.6868],
      [110.2899, -7.6863],
      [110.2895, -7.6859],
      [110.2894, -7.6861],
      [110.2893, -7.6863],
      [110.2886, -7.6867],
      [110.288, -7.6871],
      [110.2877, -7.6873],
      [110.2862, -7.6867],
      [110.2859, -7.687],
      [110.2859, -7.6875],
      [110.2854, -7.6878],
      [110.2851, -7.6883],
      [110.2849, -7.6882],
      [110.2848, -7.6881],
      [110.2841, -7.6878],
      [110.2838, -7.6881],
      [110.2837, -7.6883],
      [110.2836, -7.6886],
      [110.2834, -7.689],
      [110.283, -7.6893],
      [110.2826, -7.6893],
      [110.2823, -7.6895],
      [110.2822, -7.69],
      [110.2821, -7.6902],
      [110.282, -7.6907],
      [110.2816, -7.6911],
      [110.2811, -7.6913],
      [110.2809, -7.6914],
      [110.2807, -7.6918],
      [110.2804, -7.6924],
      [110.2801, -7.6928],
      [110.2797, -7.6935],
      [110.2792, -7.6946],
      [110.2785, -7.6953],
      [110.2782, -7.6956],
      [110.2782, -7.6957],
      [110.2779, -7.696],
      [110.2777, -7.6964],
      [110.2776, -7.6968],
      [110.2774, -7.6975],
      [110.2773, -7.6978],
      [110.2772, -7.698],
      [110.2768, -7.6982],
      [110.2764, -7.6985],
      [110.2763, -7.6988],
      [110.2763, -7.6991],
      [110.2758, -7.6994],
      [110.2755, -7.6996],
      [110.2749, -7.7003],
      [110.2747, -7.7006],
      [110.2744, -7.7008],
      [110.2739, -7.7012],
      [110.2736, -7.7016],
      [110.2738, -7.7023],
      [110.2738, -7.7029],
      [110.274, -7.7039],
      [110.2741, -7.7043],
      [110.2741, -7.7046],
      [110.274, -7.7047],
      [110.2738, -7.7048],
      [110.2736, -7.7047],
      [110.2734, -7.7046],
      [110.2731, -7.7045],
      [110.2729, -7.7046],
      [110.2728, -7.7047],
      [110.2728, -7.7049],
      [110.2727, -7.705],
      [110.2727, -7.7052],
      [110.2728, -7.7058],
      [110.2728, -7.7059],
      [110.2729, -7.7064],
      [110.2727, -7.7068],
      [110.2723, -7.707],
      [110.2719, -7.7069],
      [110.2712, -7.7067],
      [110.2708, -7.7066],
      [110.2705, -7.7063],
      [110.2704, -7.7061],
      [110.2703, -7.7058],
      [110.2703, -7.7057],
      [110.27, -7.706],
      [110.2695, -7.7064],
      [110.2691, -7.7068],
      [110.2688, -7.7071],
      [110.268, -7.7073],
      [110.2678, -7.7073],
      [110.2679, -7.7076],
      [110.2681, -7.7078],
      [110.2683, -7.7081],
      [110.2688, -7.7085],
      [110.269, -7.7086],
      [110.2695, -7.709],
      [110.2701, -7.7095],
      [110.2706, -7.7096],
      [110.2711, -7.7093],
      [110.2719, -7.7089],
      [110.2729, -7.709],
      [110.2734, -7.7094],
      [110.2738, -7.7094],
      [110.274, -7.7094],
      [110.2744, -7.7092],
      [110.2745, -7.7092],
      [110.2748, -7.7091],
      [110.2756, -7.7096],
      [110.2761, -7.7097],
      [110.2765, -7.7097],
      [110.2767, -7.7097],
      [110.2774, -7.7098],
      [110.2776, -7.7096],
      [110.278, -7.7092],
      [110.2783, -7.7091],
      [110.2786, -7.7091],
      [110.2789, -7.7094],
      [110.2791, -7.7097],
      [110.2794, -7.7099],
      [110.2803, -7.7102],
      [110.2807, -7.7102],
      [110.2811, -7.7101],
      [110.2816, -7.7101],
      [110.2817, -7.7101],
      [110.2822, -7.7102],
      [110.2827, -7.7102],
      [110.2834, -7.7097],
      [110.2838, -7.7095],
      [110.2841, -7.7089],
      [110.2851, -7.7086],
      [110.2862, -7.7085],
      [110.2869, -7.7083],
      [110.2874, -7.7079],
      [110.2878, -7.7077],
      [110.2884, -7.7078],
      [110.2891, -7.7082],
      [110.2895, -7.708],
      [110.2901, -7.7083],
      [110.2905, -7.7083],
      [110.2911, -7.7075],
      [110.291, -7.7073],
      [110.2909, -7.7071],
      [110.2912, -7.7063],
      [110.2917, -7.706],
      [110.292, -7.7057],
      [110.292, -7.7048],
      [110.2923, -7.7041],
      [110.2923, -7.7037],
      [110.2921, -7.7034],
      [110.2921, -7.7028],
      [110.2926, -7.7024],
      [110.2949, -7.7034],
      [110.296, -7.7037],
      [110.2968, -7.703],
      [110.2984, -7.7016],
      [110.2966, -7.6998],
      [110.2969, -7.6994],
      [110.2972, -7.6993],
      [110.2978, -7.6987],
      [110.2983, -7.6989],
      [110.2987, -7.6987],
      [110.2999, -7.6986],
      [110.3008, -7.6975],
      [110.3013, -7.6968],
      [110.3017, -7.6967],
      [110.3023, -7.6959],
      [110.3029, -7.6953],
      [110.3025, -7.6947],
      [110.3022, -7.6944],
      [110.302, -7.6944],
      [110.3018, -7.6948],
      [110.3015, -7.6951],
      [110.3011, -7.6951],
      [110.3009, -7.6955],
      [110.3005, -7.6956],
      [110.3002, -7.6957],
      [110.2998, -7.6957],
      [110.2991, -7.6962],
      [110.2987, -7.6961],
      [110.2987, -7.6959],
      [110.2991, -7.6959],
      [110.2994, -7.6956],
      [110.2996, -7.6951],
      [110.2998, -7.6947],
      [110.2999, -7.6946],
      [110.3, -7.6945],
      [110.3001, -7.694],
      [110.2996, -7.6935],
      [110.2992, -7.6933],
      [110.299, -7.6934],
      [110.2987, -7.6937],
      [110.2984, -7.6939],
      [110.2982, -7.6941],
      [110.2978, -7.6946],
      [110.2976, -7.6947],
      [110.2976, -7.6949],
      [110.2978, -7.6952],
      [110.298, -7.6956],
      [110.2981, -7.6958],
      [110.2976, -7.6956],
      [110.2971, -7.6951],
      [110.2969, -7.6948],
      [110.2986, -7.6933],
      [110.2987, -7.693],
      [110.2984, -7.6925],
      [110.298, -7.6921],
      [110.2976, -7.692],
      [110.2975, -7.6919],
      [110.2968, -7.6918],
      [110.2965, -7.692],
      [110.2964, -7.6925],
      [110.2963, -7.6926],
      [110.296, -7.6927],
      [110.2957, -7.6931],
      [110.2957, -7.6939],
      [110.2957, -7.6942],
      [110.2953, -7.6942],
      [110.2953, -7.6939],
      [110.2942, -7.6936],
      [110.2934, -7.6933],
      [110.2929, -7.6939],
      [110.2927, -7.6939],
      [110.2913, -7.6928],
      [110.2919, -7.692],
      [110.2909, -7.6912],
      [110.2906, -7.6911],
      [110.2904, -7.691],
      [110.29, -7.691],
      [110.2897, -7.6914],
      [110.2896, -7.6918],
      [110.2896, -7.6922],
      [110.2896, -7.6924],
      [110.2892, -7.6922],
      [110.2889, -7.6921],
      [110.2889, -7.692],
      [110.289, -7.6917],
      [110.2892, -7.6914],
      [110.2895, -7.6908],
      [110.2898, -7.6904],
      [110.2896, -7.6902],
      [110.2892, -7.6899],
      [110.2891, -7.6898],
      [110.2893, -7.6893],
      [110.2897, -7.6889],
      [110.2901, -7.6887],
      [110.2902, -7.6882],
      [110.2906, -7.6881],
      [110.2908, -7.6879],
      [110.2909, -7.6877]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'TAMBAKREJO',
    border: [
      [110.3164, -7.6791],
      [110.3163, -7.679],
      [110.3152, -7.6783],
      [110.3147, -7.679],
      [110.3134, -7.678],
      [110.3129, -7.6785],
      [110.3114, -7.6774],
      [110.3114, -7.6773],
      [110.3131, -7.6754],
      [110.3142, -7.6741],
      [110.3133, -7.6736],
      [110.3129, -7.6737],
      [110.3127, -7.6741],
      [110.3124, -7.6748],
      [110.3117, -7.6757],
      [110.3115, -7.676],
      [110.3112, -7.6763],
      [110.3109, -7.6768],
      [110.3108, -7.6775],
      [110.3105, -7.6779],
      [110.3104, -7.6779],
      [110.31, -7.6782],
      [110.3095, -7.6786],
      [110.309, -7.6789],
      [110.3087, -7.6789],
      [110.3084, -7.6789],
      [110.3083, -7.6787],
      [110.3083, -7.6779],
      [110.3082, -7.6778],
      [110.3079, -7.6779],
      [110.3075, -7.6782],
      [110.307, -7.6787],
      [110.3067, -7.6787],
      [110.3063, -7.6784],
      [110.3055, -7.6792],
      [110.3049, -7.6798],
      [110.3042, -7.6805],
      [110.3039, -7.6806],
      [110.3037, -7.6811],
      [110.3037, -7.6812],
      [110.3035, -7.6814],
      [110.303, -7.6815],
      [110.3024, -7.6816],
      [110.302, -7.6822],
      [110.3012, -7.6827],
      [110.3008, -7.6827],
      [110.2999, -7.683],
      [110.2998, -7.6831],
      [110.2997, -7.6832],
      [110.2993, -7.6835],
      [110.2984, -7.6838],
      [110.298, -7.6838],
      [110.2977, -7.6835],
      [110.2976, -7.6826],
      [110.2972, -7.6823],
      [110.2959, -7.682],
      [110.2958, -7.6818],
      [110.2955, -7.6814],
      [110.2954, -7.6814],
      [110.2952, -7.6814],
      [110.295, -7.6816],
      [110.2948, -7.6818],
      [110.2949, -7.682],
      [110.2949, -7.6823],
      [110.2949, -7.6829],
      [110.2944, -7.6835],
      [110.294, -7.6841],
      [110.2934, -7.6845],
      [110.2935, -7.6848],
      [110.294, -7.685],
      [110.2942, -7.6852],
      [110.2942, -7.6852],
      [110.2941, -7.6854],
      [110.2936, -7.6855],
      [110.2934, -7.6858],
      [110.2933, -7.6861],
      [110.2929, -7.6861],
      [110.2928, -7.6862],
      [110.2923, -7.6861],
      [110.292, -7.687],
      [110.2919, -7.6871],
      [110.2913, -7.6872],
      [110.2912, -7.6874],
      [110.2909, -7.6877],
      [110.2908, -7.6879],
      [110.2906, -7.6881],
      [110.2902, -7.6882],
      [110.2901, -7.6887],
      [110.2897, -7.6889],
      [110.2893, -7.6893],
      [110.2891, -7.6898],
      [110.2892, -7.6899],
      [110.2896, -7.6902],
      [110.2898, -7.6904],
      [110.2895, -7.6908],
      [110.2892, -7.6914],
      [110.289, -7.6917],
      [110.2889, -7.692],
      [110.2889, -7.6921],
      [110.2892, -7.6922],
      [110.2896, -7.6924],
      [110.2896, -7.6922],
      [110.2896, -7.6918],
      [110.2897, -7.6914],
      [110.29, -7.691],
      [110.2904, -7.691],
      [110.2906, -7.6911],
      [110.2909, -7.6912],
      [110.2919, -7.692],
      [110.2913, -7.6928],
      [110.2927, -7.6939],
      [110.2929, -7.6939],
      [110.2934, -7.6933],
      [110.2942, -7.6936],
      [110.2953, -7.6939],
      [110.2953, -7.6942],
      [110.2957, -7.6942],
      [110.2957, -7.6939],
      [110.2957, -7.6931],
      [110.296, -7.6927],
      [110.2963, -7.6926],
      [110.2964, -7.6925],
      [110.2965, -7.692],
      [110.2968, -7.6918],
      [110.2975, -7.6919],
      [110.2976, -7.692],
      [110.298, -7.6921],
      [110.2984, -7.6925],
      [110.2987, -7.693],
      [110.2986, -7.6933],
      [110.2969, -7.6948],
      [110.2971, -7.6951],
      [110.2976, -7.6956],
      [110.2981, -7.6958],
      [110.298, -7.6956],
      [110.2978, -7.6952],
      [110.2976, -7.6949],
      [110.2976, -7.6947],
      [110.2978, -7.6946],
      [110.2982, -7.6941],
      [110.2984, -7.6939],
      [110.2987, -7.6937],
      [110.299, -7.6934],
      [110.2992, -7.6933],
      [110.2996, -7.6935],
      [110.3001, -7.694],
      [110.3, -7.6945],
      [110.2999, -7.6946],
      [110.2998, -7.6947],
      [110.2996, -7.6951],
      [110.2994, -7.6956],
      [110.2991, -7.6959],
      [110.2987, -7.6959],
      [110.2987, -7.6961],
      [110.2991, -7.6962],
      [110.2998, -7.6957],
      [110.3002, -7.6957],
      [110.3005, -7.6956],
      [110.3009, -7.6955],
      [110.3011, -7.6951],
      [110.3015, -7.6951],
      [110.3018, -7.6948],
      [110.302, -7.6944],
      [110.3022, -7.6944],
      [110.3025, -7.6947],
      [110.3029, -7.6953],
      [110.3035, -7.6953],
      [110.3038, -7.6945],
      [110.3042, -7.6949],
      [110.3051, -7.6963],
      [110.3053, -7.6965],
      [110.3056, -7.6965],
      [110.3059, -7.6962],
      [110.3062, -7.6955],
      [110.3065, -7.695],
      [110.3067, -7.6947],
      [110.307, -7.6944],
      [110.3074, -7.6941],
      [110.3078, -7.6939],
      [110.3078, -7.6936],
      [110.3077, -7.6935],
      [110.3076, -7.6935],
      [110.3074, -7.6933],
      [110.3078, -7.6932],
      [110.3081, -7.6928],
      [110.3083, -7.6924],
      [110.3088, -7.6913],
      [110.3089, -7.6908],
      [110.3095, -7.6902],
      [110.3099, -7.6902],
      [110.3102, -7.6899],
      [110.3105, -7.6893],
      [110.3105, -7.6888],
      [110.311, -7.6883],
      [110.3112, -7.6881],
      [110.3113, -7.6873],
      [110.3114, -7.6868],
      [110.3117, -7.6868],
      [110.3119, -7.6865],
      [110.3121, -7.6863],
      [110.3124, -7.6861],
      [110.3125, -7.6859],
      [110.3126, -7.6851],
      [110.3127, -7.6849],
      [110.3128, -7.6846],
      [110.3131, -7.6842],
      [110.3133, -7.684],
      [110.3136, -7.6837],
      [110.3138, -7.6835],
      [110.3139, -7.6828],
      [110.3147, -7.6824],
      [110.3148, -7.6819],
      [110.3151, -7.6813],
      [110.3154, -7.6807],
      [110.3159, -7.6802],
      [110.3163, -7.6794],
      [110.3164, -7.6791]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'SUMBERREJO',
    border: [
      [110.3066, -7.6756],
      [110.3065, -7.6755],
      [110.3061, -7.6741],
      [110.3059, -7.6738],
      [110.3046, -7.6752],
      [110.3036, -7.674],
      [110.3044, -7.673],
      [110.3047, -7.6731],
      [110.3064, -7.6714],
      [110.3063, -7.6713],
      [110.3049, -7.6698],
      [110.3046, -7.6701],
      [110.3044, -7.6701],
      [110.3042, -7.6699],
      [110.3039, -7.6698],
      [110.3036, -7.6701],
      [110.3035, -7.6705],
      [110.3034, -7.6706],
      [110.3033, -7.6706],
      [110.3032, -7.6706],
      [110.3028, -7.6706],
      [110.3023, -7.6701],
      [110.3016, -7.6698],
      [110.3011, -7.6699],
      [110.3006, -7.6699],
      [110.3003, -7.6696],
      [110.2992, -7.6687],
      [110.2987, -7.6694],
      [110.2975, -7.6688],
      [110.2979, -7.6685],
      [110.2981, -7.6681],
      [110.2983, -7.6675],
      [110.2985, -7.6674],
      [110.2989, -7.6674],
      [110.299, -7.6671],
      [110.299, -7.6669],
      [110.2983, -7.6666],
      [110.298, -7.6669],
      [110.2976, -7.667],
      [110.2973, -7.6667],
      [110.2967, -7.6669],
      [110.2965, -7.667],
      [110.2962, -7.6671],
      [110.2956, -7.6677],
      [110.2955, -7.668],
      [110.2953, -7.6683],
      [110.2951, -7.6687],
      [110.2949, -7.6691],
      [110.2948, -7.6692],
      [110.2947, -7.6692],
      [110.2946, -7.6693],
      [110.2945, -7.6696],
      [110.2944, -7.6697],
      [110.2941, -7.6701],
      [110.2941, -7.6702],
      [110.2939, -7.6704],
      [110.2937, -7.6705],
      [110.2936, -7.6704],
      [110.2934, -7.6703],
      [110.2933, -7.6703],
      [110.2932, -7.6705],
      [110.2929, -7.6714],
      [110.2928, -7.6715],
      [110.2926, -7.6717],
      [110.292, -7.6722],
      [110.2919, -7.6722],
      [110.2916, -7.6722],
      [110.2915, -7.6722],
      [110.2914, -7.6723],
      [110.2913, -7.6724],
      [110.2913, -7.6725],
      [110.2913, -7.6726],
      [110.2912, -7.6729],
      [110.2912, -7.673],
      [110.2912, -7.6731],
      [110.2909, -7.6733],
      [110.2909, -7.6735],
      [110.2909, -7.6736],
      [110.2903, -7.6744],
      [110.2903, -7.6745],
      [110.29, -7.6749],
      [110.2898, -7.6755],
      [110.2897, -7.6761],
      [110.2894, -7.6768],
      [110.2893, -7.6771],
      [110.2892, -7.6774],
      [110.2888, -7.6777],
      [110.2886, -7.6782],
      [110.2885, -7.6784],
      [110.2885, -7.6787],
      [110.2883, -7.6789],
      [110.288, -7.6793],
      [110.2878, -7.6795],
      [110.2875, -7.6797],
      [110.2873, -7.6798],
      [110.287, -7.68],
      [110.2867, -7.6804],
      [110.2866, -7.681],
      [110.2866, -7.6817],
      [110.2865, -7.6821],
      [110.2864, -7.6824],
      [110.2864, -7.6829],
      [110.2861, -7.6831],
      [110.286, -7.6832],
      [110.2858, -7.6836],
      [110.2858, -7.6839],
      [110.2858, -7.6842],
      [110.2857, -7.6845],
      [110.2856, -7.6847],
      [110.2856, -7.685],
      [110.2854, -7.6856],
      [110.2852, -7.6862],
      [110.2847, -7.6873],
      [110.2845, -7.6875],
      [110.2841, -7.6878],
      [110.2848, -7.6881],
      [110.2849, -7.6882],
      [110.2851, -7.6883],
      [110.2854, -7.6878],
      [110.2859, -7.6875],
      [110.2859, -7.687],
      [110.2862, -7.6867],
      [110.2877, -7.6873],
      [110.288, -7.6871],
      [110.2886, -7.6867],
      [110.2893, -7.6863],
      [110.2894, -7.6861],
      [110.2895, -7.6859],
      [110.2899, -7.6863],
      [110.2901, -7.6868],
      [110.2901, -7.6872],
      [110.2903, -7.6876],
      [110.2909, -7.6877],
      [110.2912, -7.6874],
      [110.2913, -7.6872],
      [110.2919, -7.6871],
      [110.292, -7.687],
      [110.2923, -7.6861],
      [110.2928, -7.6862],
      [110.2929, -7.6861],
      [110.2933, -7.6861],
      [110.2934, -7.6858],
      [110.2936, -7.6855],
      [110.2941, -7.6854],
      [110.2942, -7.6852],
      [110.2942, -7.6852],
      [110.294, -7.685],
      [110.2935, -7.6848],
      [110.2934, -7.6845],
      [110.294, -7.6841],
      [110.2944, -7.6835],
      [110.2949, -7.6829],
      [110.2949, -7.6823],
      [110.2949, -7.682],
      [110.2948, -7.6818],
      [110.295, -7.6816],
      [110.2952, -7.6814],
      [110.2954, -7.6814],
      [110.2955, -7.6814],
      [110.2958, -7.6818],
      [110.2959, -7.682],
      [110.2972, -7.6823],
      [110.2976, -7.6826],
      [110.2977, -7.6835],
      [110.298, -7.6838],
      [110.2984, -7.6838],
      [110.2993, -7.6835],
      [110.2997, -7.6832],
      [110.2998, -7.6831],
      [110.2999, -7.683],
      [110.3008, -7.6827],
      [110.3012, -7.6827],
      [110.302, -7.6822],
      [110.3024, -7.6816],
      [110.303, -7.6815],
      [110.3035, -7.6814],
      [110.3037, -7.6812],
      [110.3037, -7.6811],
      [110.3039, -7.6806],
      [110.3042, -7.6805],
      [110.3049, -7.6798],
      [110.3055, -7.6792],
      [110.3063, -7.6784],
      [110.3061, -7.6781],
      [110.3058, -7.6778],
      [110.3057, -7.6775],
      [110.3057, -7.6771],
      [110.3059, -7.6767],
      [110.3063, -7.676],
      [110.3066, -7.6756]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'PONDOKREJO',
    border: [
      [110.3142, -7.6579],
      [110.3141, -7.6578],
      [110.3135, -7.6572],
      [110.313, -7.6567],
      [110.3135, -7.6565],
      [110.3141, -7.6557],
      [110.3144, -7.6552],
      [110.3146, -7.6548],
      [110.3145, -7.6542],
      [110.3141, -7.6538],
      [110.3137, -7.6537],
      [110.3125, -7.6535],
      [110.312, -7.6534],
      [110.3099, -7.6525],
      [110.3097, -7.6527],
      [110.3091, -7.6532],
      [110.3085, -7.6533],
      [110.3082, -7.6534],
      [110.308, -7.6535],
      [110.3078, -7.6536],
      [110.3074, -7.6538],
      [110.3072, -7.6542],
      [110.3069, -7.6541],
      [110.3066, -7.654],
      [110.3062, -7.6542],
      [110.3058, -7.6546],
      [110.3049, -7.6548],
      [110.3043, -7.655],
      [110.3039, -7.6555],
      [110.3035, -7.6559],
      [110.303, -7.6562],
      [110.3025, -7.6575],
      [110.3022, -7.6591],
      [110.3021, -7.6594],
      [110.3019, -7.6599],
      [110.3017, -7.6601],
      [110.3012, -7.6607],
      [110.3004, -7.6622],
      [110.3005, -7.6626],
      [110.3005, -7.6627],
      [110.3004, -7.6631],
      [110.3, -7.6638],
      [110.2999, -7.6641],
      [110.2999, -7.6644],
      [110.2998, -7.6648],
      [110.2996, -7.665],
      [110.2994, -7.665],
      [110.2991, -7.665],
      [110.2985, -7.6652],
      [110.2982, -7.6658],
      [110.2979, -7.6661],
      [110.2974, -7.6667],
      [110.2973, -7.6667],
      [110.2976, -7.667],
      [110.298, -7.6669],
      [110.2983, -7.6666],
      [110.299, -7.6669],
      [110.299, -7.6671],
      [110.2989, -7.6674],
      [110.2985, -7.6674],
      [110.2983, -7.6675],
      [110.2981, -7.6681],
      [110.2979, -7.6685],
      [110.2975, -7.6688],
      [110.2987, -7.6694],
      [110.2992, -7.6687],
      [110.3003, -7.6696],
      [110.3006, -7.6699],
      [110.3011, -7.6699],
      [110.3016, -7.6698],
      [110.3023, -7.6701],
      [110.3028, -7.6706],
      [110.3032, -7.6706],
      [110.3033, -7.6706],
      [110.3034, -7.6706],
      [110.3035, -7.6705],
      [110.3036, -7.6701],
      [110.3039, -7.6698],
      [110.3042, -7.6699],
      [110.3044, -7.6701],
      [110.3046, -7.6701],
      [110.3049, -7.6698],
      [110.3063, -7.6713],
      [110.3064, -7.6714],
      [110.3047, -7.6731],
      [110.3044, -7.673],
      [110.3036, -7.674],
      [110.3046, -7.6752],
      [110.3059, -7.6738],
      [110.3061, -7.6741],
      [110.3065, -7.6755],
      [110.3066, -7.6756],
      [110.3067, -7.6754],
      [110.3068, -7.6749],
      [110.3069, -7.6748],
      [110.3069, -7.6743],
      [110.307, -7.6741],
      [110.3071, -7.6738],
      [110.3072, -7.6734],
      [110.308, -7.6738],
      [110.3082, -7.6737],
      [110.3082, -7.6734],
      [110.3092, -7.6724],
      [110.3103, -7.6736],
      [110.3108, -7.673],
      [110.3111, -7.6726],
      [110.3116, -7.6722],
      [110.3119, -7.6722],
      [110.3125, -7.6723],
      [110.3129, -7.671],
      [110.3131, -7.6708],
      [110.3143, -7.671],
      [110.3149, -7.6712],
      [110.3154, -7.6714],
      [110.3158, -7.6711],
      [110.3161, -7.6704],
      [110.3162, -7.6698],
      [110.3164, -7.6691],
      [110.3164, -7.6689],
      [110.3165, -7.6688],
      [110.3171, -7.6683],
      [110.3176, -7.6674],
      [110.3171, -7.6666],
      [110.3175, -7.6661],
      [110.3163, -7.6651],
      [110.3164, -7.6649],
      [110.3167, -7.6646],
      [110.3169, -7.6644],
      [110.3172, -7.6641],
      [110.3172, -7.664],
      [110.317, -7.6638],
      [110.3166, -7.6635],
      [110.316, -7.6633],
      [110.3155, -7.6641],
      [110.315, -7.6645],
      [110.3145, -7.6644],
      [110.3142, -7.6643],
      [110.3137, -7.6642],
      [110.3132, -7.664],
      [110.313, -7.6639],
      [110.3128, -7.6636],
      [110.3128, -7.6635],
      [110.313, -7.6632],
      [110.3135, -7.6624],
      [110.314, -7.6617],
      [110.3134, -7.6611],
      [110.3139, -7.6605],
      [110.3144, -7.6599],
      [110.3139, -7.6591],
      [110.3142, -7.6589],
      [110.3143, -7.6585],
      [110.314, -7.6582],
      [110.3142, -7.6579]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'MOROREJO',
    border: [
      [110.3194, -7.6598],
      [110.3189, -7.6604],
      [110.3184, -7.6608],
      [110.3183, -7.6607],
      [110.317, -7.6592],
      [110.3165, -7.6591],
      [110.3161, -7.659],
      [110.3156, -7.6584],
      [110.3154, -7.6585],
      [110.3154, -7.6589],
      [110.3152, -7.659],
      [110.3142, -7.6579],
      [110.314, -7.6582],
      [110.3143, -7.6585],
      [110.3142, -7.6589],
      [110.3139, -7.6591],
      [110.3144, -7.6599],
      [110.3139, -7.6605],
      [110.3134, -7.6611],
      [110.314, -7.6617],
      [110.3135, -7.6624],
      [110.313, -7.6632],
      [110.3128, -7.6635],
      [110.3128, -7.6636],
      [110.313, -7.6639],
      [110.3132, -7.664],
      [110.3137, -7.6642],
      [110.3142, -7.6643],
      [110.3145, -7.6644],
      [110.315, -7.6645],
      [110.3155, -7.6641],
      [110.316, -7.6633],
      [110.3166, -7.6635],
      [110.317, -7.6638],
      [110.3172, -7.664],
      [110.3172, -7.6641],
      [110.3169, -7.6644],
      [110.3167, -7.6646],
      [110.3164, -7.6649],
      [110.3163, -7.6651],
      [110.3175, -7.6661],
      [110.3171, -7.6666],
      [110.3176, -7.6674],
      [110.3171, -7.6683],
      [110.3165, -7.6688],
      [110.3164, -7.6689],
      [110.3164, -7.6691],
      [110.3162, -7.6698],
      [110.3161, -7.6704],
      [110.3158, -7.6711],
      [110.3154, -7.6714],
      [110.3149, -7.6712],
      [110.3143, -7.671],
      [110.3131, -7.6708],
      [110.3129, -7.671],
      [110.3125, -7.6723],
      [110.3119, -7.6722],
      [110.3116, -7.6722],
      [110.3111, -7.6726],
      [110.3108, -7.673],
      [110.3103, -7.6736],
      [110.3092, -7.6724],
      [110.3082, -7.6734],
      [110.3082, -7.6737],
      [110.308, -7.6738],
      [110.3072, -7.6734],
      [110.3071, -7.6738],
      [110.307, -7.6741],
      [110.3069, -7.6743],
      [110.3069, -7.6748],
      [110.3068, -7.6749],
      [110.3067, -7.6754],
      [110.3066, -7.6756],
      [110.3063, -7.676],
      [110.3059, -7.6767],
      [110.3057, -7.6771],
      [110.3057, -7.6775],
      [110.3058, -7.6778],
      [110.3061, -7.6781],
      [110.3063, -7.6784],
      [110.3067, -7.6787],
      [110.307, -7.6787],
      [110.3075, -7.6782],
      [110.3079, -7.6779],
      [110.3082, -7.6778],
      [110.3083, -7.6779],
      [110.3083, -7.6787],
      [110.3084, -7.6789],
      [110.3087, -7.6789],
      [110.309, -7.6789],
      [110.3095, -7.6786],
      [110.31, -7.6782],
      [110.3104, -7.6779],
      [110.3105, -7.6779],
      [110.3108, -7.6775],
      [110.3109, -7.6768],
      [110.3112, -7.6763],
      [110.3115, -7.676],
      [110.3117, -7.6757],
      [110.3124, -7.6748],
      [110.3127, -7.6741],
      [110.3129, -7.6737],
      [110.3133, -7.6736],
      [110.3142, -7.6741],
      [110.3131, -7.6754],
      [110.3114, -7.6773],
      [110.3114, -7.6774],
      [110.3129, -7.6785],
      [110.3134, -7.678],
      [110.3147, -7.679],
      [110.3152, -7.6783],
      [110.3163, -7.679],
      [110.3164, -7.6791],
      [110.3166, -7.6792],
      [110.3171, -7.6793],
      [110.3171, -7.6791],
      [110.3182, -7.6797],
      [110.3198, -7.6782],
      [110.3202, -7.6779],
      [110.322, -7.6787],
      [110.3231, -7.6792],
      [110.3231, -7.6793],
      [110.323, -7.6795],
      [110.3227, -7.6796],
      [110.3225, -7.6798],
      [110.3225, -7.6799],
      [110.325, -7.681],
      [110.3252, -7.6811],
      [110.3256, -7.6807],
      [110.3261, -7.6803],
      [110.3262, -7.6802],
      [110.3279, -7.6807],
      [110.3283, -7.6807],
      [110.3285, -7.6807],
      [110.3289, -7.6806],
      [110.3292, -7.6804],
      [110.3294, -7.6799],
      [110.3296, -7.6798],
      [110.3299, -7.6796],
      [110.3301, -7.6793],
      [110.3302, -7.6791],
      [110.33, -7.6785],
      [110.33, -7.6781],
      [110.3301, -7.6777],
      [110.3306, -7.6774],
      [110.3312, -7.6764],
      [110.3315, -7.6761],
      [110.3318, -7.6758],
      [110.3319, -7.6755],
      [110.3322, -7.6751],
      [110.3326, -7.6746],
      [110.3331, -7.6741],
      [110.3336, -7.6737],
      [110.3337, -7.6736],
      [110.3327, -7.673],
      [110.3318, -7.6725],
      [110.3312, -7.6726],
      [110.3306, -7.6731],
      [110.3303, -7.6737],
      [110.33, -7.6743],
      [110.3295, -7.6743],
      [110.3285, -7.6737],
      [110.328, -7.6731],
      [110.3279, -7.6727],
      [110.3279, -7.6723],
      [110.3277, -7.6721],
      [110.3275, -7.6722],
      [110.3273, -7.6725],
      [110.327, -7.6723],
      [110.3265, -7.6715],
      [110.3262, -7.6701],
      [110.3248, -7.6697],
      [110.3246, -7.6689],
      [110.3245, -7.6682],
      [110.3246, -7.6677],
      [110.3245, -7.6676],
      [110.3242, -7.6671],
      [110.3227, -7.6654],
      [110.3218, -7.6644],
      [110.3217, -7.6642],
      [110.3218, -7.664],
      [110.3234, -7.6628],
      [110.3233, -7.6626],
      [110.3232, -7.6621],
      [110.3219, -7.6611],
      [110.3216, -7.6609],
      [110.3211, -7.6613],
      [110.3208, -7.6612],
      [110.3205, -7.6609],
      [110.3199, -7.6603],
      [110.3194, -7.6598]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'MARGOREJO',
    border: [
      [110.3454, -7.6536],
      [110.345, -7.6535],
      [110.3447, -7.6534],
      [110.3445, -7.6534],
      [110.3436, -7.6552],
      [110.3429, -7.6548],
      [110.3429, -7.6546],
      [110.3434, -7.6541],
      [110.3432, -7.6539],
      [110.3423, -7.6535],
      [110.3413, -7.6531],
      [110.3418, -7.6518],
      [110.3418, -7.6514],
      [110.3416, -7.6507],
      [110.3415, -7.6501],
      [110.3417, -7.6496],
      [110.3422, -7.6492],
      [110.3428, -7.6488],
      [110.3433, -7.6483],
      [110.3435, -7.6479],
      [110.3436, -7.6473],
      [110.3439, -7.6469],
      [110.3437, -7.6468],
      [110.343, -7.6461],
      [110.3414, -7.6452],
      [110.3408, -7.6448],
      [110.3404, -7.645],
      [110.3402, -7.6453],
      [110.3398, -7.6453],
      [110.3395, -7.6454],
      [110.3392, -7.6456],
      [110.3388, -7.6455],
      [110.3385, -7.6458],
      [110.3378, -7.6458],
      [110.3371, -7.6454],
      [110.3367, -7.6457],
      [110.3359, -7.6466],
      [110.3344, -7.648],
      [110.3341, -7.6484],
      [110.3341, -7.6487],
      [110.3342, -7.6492],
      [110.3339, -7.6494],
      [110.3337, -7.6496],
      [110.3342, -7.6499],
      [110.3339, -7.6502],
      [110.3337, -7.6507],
      [110.3339, -7.6509],
      [110.3344, -7.6515],
      [110.3353, -7.652],
      [110.3358, -7.6522],
      [110.3357, -7.6525],
      [110.3352, -7.6533],
      [110.3343, -7.6547],
      [110.3335, -7.6562],
      [110.3331, -7.6565],
      [110.3328, -7.6567],
      [110.3321, -7.6562],
      [110.332, -7.6561],
      [110.3321, -7.6555],
      [110.3318, -7.6556],
      [110.3304, -7.6568],
      [110.3302, -7.6568],
      [110.3295, -7.6553],
      [110.3288, -7.6558],
      [110.328, -7.6541],
      [110.3277, -7.6536],
      [110.3273, -7.6537],
      [110.3267, -7.6541],
      [110.3265, -7.6544],
      [110.326, -7.6547],
      [110.3255, -7.6552],
      [110.3255, -7.655],
      [110.3254, -7.6549],
      [110.3253, -7.6545],
      [110.3254, -7.6537],
      [110.3251, -7.6517],
      [110.3246, -7.6518],
      [110.3242, -7.6523],
      [110.3231, -7.6534],
      [110.321, -7.6557],
      [110.3222, -7.6568],
      [110.322, -7.657],
      [110.3215, -7.6576],
      [110.3194, -7.6598],
      [110.3199, -7.6603],
      [110.3205, -7.6609],
      [110.3208, -7.6612],
      [110.3211, -7.6613],
      [110.3216, -7.6609],
      [110.3219, -7.6611],
      [110.3232, -7.6621],
      [110.3233, -7.6626],
      [110.3234, -7.6628],
      [110.3218, -7.664],
      [110.3217, -7.6642],
      [110.3218, -7.6644],
      [110.3227, -7.6654],
      [110.3242, -7.6671],
      [110.3245, -7.6676],
      [110.3246, -7.6677],
      [110.3245, -7.6682],
      [110.3246, -7.6689],
      [110.3248, -7.6697],
      [110.3262, -7.6701],
      [110.3265, -7.6715],
      [110.327, -7.6723],
      [110.3273, -7.6725],
      [110.3275, -7.6722],
      [110.3277, -7.6721],
      [110.3279, -7.6723],
      [110.3279, -7.6727],
      [110.328, -7.6731],
      [110.3285, -7.6737],
      [110.3295, -7.6743],
      [110.33, -7.6743],
      [110.3303, -7.6737],
      [110.3306, -7.6731],
      [110.3312, -7.6726],
      [110.3318, -7.6725],
      [110.3327, -7.673],
      [110.3337, -7.6736],
      [110.3338, -7.6733],
      [110.3339, -7.6729],
      [110.3346, -7.6726],
      [110.3344, -7.6719],
      [110.3348, -7.6715],
      [110.3353, -7.6725],
      [110.3357, -7.6726],
      [110.337, -7.6731],
      [110.3376, -7.6733],
      [110.3382, -7.6738],
      [110.3389, -7.674],
      [110.3397, -7.6741],
      [110.3398, -7.6741],
      [110.3398, -7.674],
      [110.3398, -7.6737],
      [110.3401, -7.6727],
      [110.3405, -7.6716],
      [110.3412, -7.6706],
      [110.3413, -7.6698],
      [110.3413, -7.669],
      [110.3416, -7.6682],
      [110.3418, -7.6676],
      [110.3419, -7.6673],
      [110.3422, -7.6671],
      [110.3424, -7.6669],
      [110.3426, -7.6664],
      [110.343, -7.6662],
      [110.3433, -7.666],
      [110.3435, -7.6654],
      [110.3436, -7.665],
      [110.3438, -7.6646],
      [110.3438, -7.6643],
      [110.3437, -7.6641],
      [110.3437, -7.6629],
      [110.3438, -7.6625],
      [110.3442, -7.6614],
      [110.3443, -7.6605],
      [110.3441, -7.6599],
      [110.3442, -7.6592],
      [110.3448, -7.6585],
      [110.3451, -7.6578],
      [110.3447, -7.6574],
      [110.3446, -7.657],
      [110.344, -7.6568],
      [110.3441, -7.6565],
      [110.3442, -7.6561],
      [110.3446, -7.6556],
      [110.3449, -7.6551],
      [110.345, -7.6545],
      [110.3453, -7.654],
      [110.3454, -7.6536]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'LUMBUNGREJO',
    border: [
      [110.3341, -7.6484],
      [110.3336, -7.6481],
      [110.333, -7.6479],
      [110.3327, -7.6478],
      [110.3321, -7.6471],
      [110.3318, -7.6473],
      [110.3318, -7.6468],
      [110.3324, -7.6461],
      [110.3319, -7.6457],
      [110.332, -7.6451],
      [110.3322, -7.6448],
      [110.3326, -7.6447],
      [110.3329, -7.6447],
      [110.3334, -7.6449],
      [110.3335, -7.645],
      [110.3342, -7.6445],
      [110.3345, -7.6442],
      [110.336, -7.6427],
      [110.3353, -7.6418],
      [110.3356, -7.6412],
      [110.3358, -7.6408],
      [110.3357, -7.6405],
      [110.3354, -7.6401],
      [110.3354, -7.6399],
      [110.3361, -7.6393],
      [110.3358, -7.6388],
      [110.3355, -7.6385],
      [110.3353, -7.6383],
      [110.3354, -7.6379],
      [110.337, -7.6377],
      [110.3378, -7.6374],
      [110.3382, -7.6374],
      [110.3382, -7.6372],
      [110.3368, -7.6363],
      [110.338, -7.6351],
      [110.3383, -7.6347],
      [110.3385, -7.6343],
      [110.3388, -7.6344],
      [110.339, -7.6342],
      [110.3385, -7.6333],
      [110.3383, -7.6334],
      [110.3376, -7.6341],
      [110.3363, -7.6347],
      [110.3356, -7.6346],
      [110.3353, -7.6346],
      [110.3348, -7.6341],
      [110.3346, -7.6343],
      [110.3345, -7.6345],
      [110.3344, -7.6349],
      [110.3341, -7.6356],
      [110.3336, -7.6362],
      [110.3333, -7.6367],
      [110.3329, -7.637],
      [110.3328, -7.6374],
      [110.3325, -7.6376],
      [110.3319, -7.6377],
      [110.3316, -7.6379],
      [110.3312, -7.6381],
      [110.331, -7.6384],
      [110.3309, -7.6385],
      [110.3308, -7.6386],
      [110.3306, -7.6388],
      [110.3304, -7.6389],
      [110.3303, -7.6389],
      [110.3298, -7.639],
      [110.3293, -7.6393],
      [110.3287, -7.6396],
      [110.3279, -7.64],
      [110.3275, -7.6404],
      [110.3275, -7.6405],
      [110.3274, -7.6406],
      [110.3274, -7.6409],
      [110.3273, -7.6412],
      [110.3271, -7.6415],
      [110.3269, -7.6415],
      [110.3268, -7.6415],
      [110.3264, -7.6415],
      [110.3261, -7.6417],
      [110.3259, -7.6417],
      [110.3257, -7.6417],
      [110.3254, -7.6418],
      [110.325, -7.642],
      [110.3246, -7.6424],
      [110.3244, -7.6426],
      [110.3242, -7.643],
      [110.3242, -7.6432],
      [110.3241, -7.6436],
      [110.3239, -7.644],
      [110.3238, -7.6442],
      [110.3237, -7.6444],
      [110.3236, -7.6445],
      [110.3234, -7.6446],
      [110.3232, -7.6447],
      [110.323, -7.6447],
      [110.3229, -7.6448],
      [110.3227, -7.6451],
      [110.3227, -7.6452],
      [110.3224, -7.6453],
      [110.3222, -7.6454],
      [110.3221, -7.6455],
      [110.3217, -7.6457],
      [110.3214, -7.6458],
      [110.3211, -7.6458],
      [110.3209, -7.6458],
      [110.3206, -7.6458],
      [110.3205, -7.6459],
      [110.3202, -7.6461],
      [110.3201, -7.6462],
      [110.32, -7.6463],
      [110.3199, -7.6464],
      [110.3199, -7.6465],
      [110.3198, -7.6466],
      [110.3197, -7.6467],
      [110.3193, -7.647],
      [110.3187, -7.6472],
      [110.3179, -7.6474],
      [110.3174, -7.6478],
      [110.3171, -7.6479],
      [110.3164, -7.6481],
      [110.3163, -7.6483],
      [110.316, -7.6486],
      [110.3154, -7.6495],
      [110.3148, -7.65],
      [110.3146, -7.6502],
      [110.3143, -7.6506],
      [110.3143, -7.6508],
      [110.3135, -7.651],
      [110.3128, -7.6512],
      [110.3126, -7.6513],
      [110.3122, -7.6513],
      [110.3118, -7.6513],
      [110.3114, -7.6513],
      [110.3109, -7.6516],
      [110.3106, -7.6518],
      [110.3102, -7.6522],
      [110.3099, -7.6525],
      [110.312, -7.6534],
      [110.3125, -7.6535],
      [110.3137, -7.6537],
      [110.3141, -7.6538],
      [110.3145, -7.6542],
      [110.3146, -7.6548],
      [110.3144, -7.6552],
      [110.3141, -7.6557],
      [110.3135, -7.6565],
      [110.313, -7.6567],
      [110.3135, -7.6572],
      [110.3141, -7.6578],
      [110.3142, -7.6579],
      [110.3152, -7.659],
      [110.3154, -7.6589],
      [110.3154, -7.6585],
      [110.3156, -7.6584],
      [110.3161, -7.659],
      [110.3165, -7.6591],
      [110.317, -7.6592],
      [110.3183, -7.6607],
      [110.3184, -7.6608],
      [110.3189, -7.6604],
      [110.3194, -7.6598],
      [110.3215, -7.6576],
      [110.322, -7.657],
      [110.3222, -7.6568],
      [110.321, -7.6557],
      [110.3231, -7.6534],
      [110.3242, -7.6523],
      [110.3246, -7.6518],
      [110.3251, -7.6517],
      [110.3254, -7.6537],
      [110.3253, -7.6545],
      [110.3254, -7.6549],
      [110.3255, -7.655],
      [110.3255, -7.6552],
      [110.326, -7.6547],
      [110.3265, -7.6544],
      [110.3267, -7.6541],
      [110.3273, -7.6537],
      [110.3277, -7.6536],
      [110.328, -7.6541],
      [110.3288, -7.6558],
      [110.3295, -7.6553],
      [110.3302, -7.6568],
      [110.3304, -7.6568],
      [110.3318, -7.6556],
      [110.3321, -7.6555],
      [110.332, -7.6561],
      [110.3321, -7.6562],
      [110.3328, -7.6567],
      [110.3331, -7.6565],
      [110.3335, -7.6562],
      [110.3343, -7.6547],
      [110.3352, -7.6533],
      [110.3357, -7.6525],
      [110.3358, -7.6522],
      [110.3353, -7.652],
      [110.3344, -7.6515],
      [110.3339, -7.6509],
      [110.3337, -7.6507],
      [110.3339, -7.6502],
      [110.3342, -7.6499],
      [110.3337, -7.6496],
      [110.3339, -7.6494],
      [110.3342, -7.6492],
      [110.3341, -7.6487],
      [110.3341, -7.6484]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TEMPEL',
    village: 'MERDIKOREJO',
    border: [
      [110.3659, -7.6198],
      [110.3656, -7.619],
      [110.365, -7.6182],
      [110.3644, -7.6175],
      [110.3637, -7.6173],
      [110.3628, -7.6171],
      [110.3627, -7.6171],
      [110.3623, -7.617],
      [110.362, -7.6169],
      [110.3618, -7.617],
      [110.3611, -7.617],
      [110.3609, -7.6172],
      [110.3607, -7.6174],
      [110.3603, -7.6177],
      [110.3596, -7.6181],
      [110.3591, -7.6186],
      [110.3585, -7.6194],
      [110.3583, -7.6195],
      [110.3577, -7.6199],
      [110.3573, -7.6203],
      [110.357, -7.6205],
      [110.3564, -7.6207],
      [110.3559, -7.6211],
      [110.3557, -7.6217],
      [110.3554, -7.6223],
      [110.3551, -7.6225],
      [110.3549, -7.6225],
      [110.3539, -7.6226],
      [110.3535, -7.6226],
      [110.3524, -7.6225],
      [110.3511, -7.623],
      [110.3493, -7.6239],
      [110.3484, -7.6245],
      [110.3481, -7.6246],
      [110.347, -7.6251],
      [110.3453, -7.6253],
      [110.3434, -7.6263],
      [110.3428, -7.6276],
      [110.3426, -7.6279],
      [110.342, -7.628],
      [110.3415, -7.6285],
      [110.3409, -7.629],
      [110.3406, -7.6291],
      [110.34, -7.6293],
      [110.3398, -7.6295],
      [110.3396, -7.6302],
      [110.339, -7.6305],
      [110.3382, -7.6306],
      [110.338, -7.6307],
      [110.3378, -7.6313],
      [110.3372, -7.6318],
      [110.3369, -7.6321],
      [110.3367, -7.6324],
      [110.3365, -7.6329],
      [110.3362, -7.633],
      [110.3359, -7.6331],
      [110.3356, -7.6335],
      [110.3352, -7.6336],
      [110.3348, -7.634],
      [110.3348, -7.6341],
      [110.3353, -7.6346],
      [110.3356, -7.6346],
      [110.3363, -7.6347],
      [110.3376, -7.6341],
      [110.3383, -7.6334],
      [110.3385, -7.6333],
      [110.339, -7.6342],
      [110.3388, -7.6344],
      [110.3385, -7.6343],
      [110.3383, -7.6347],
      [110.338, -7.6351],
      [110.3368, -7.6363],
      [110.3382, -7.6372],
      [110.3382, -7.6374],
      [110.3378, -7.6374],
      [110.337, -7.6377],
      [110.3354, -7.6379],
      [110.3353, -7.6383],
      [110.3355, -7.6385],
      [110.3358, -7.6388],
      [110.3361, -7.6393],
      [110.3354, -7.6399],
      [110.3354, -7.6401],
      [110.3357, -7.6405],
      [110.3358, -7.6408],
      [110.3356, -7.6412],
      [110.3353, -7.6418],
      [110.336, -7.6427],
      [110.3345, -7.6442],
      [110.3342, -7.6445],
      [110.3335, -7.645],
      [110.3334, -7.6449],
      [110.3329, -7.6447],
      [110.3326, -7.6447],
      [110.3322, -7.6448],
      [110.332, -7.6451],
      [110.3319, -7.6457],
      [110.3324, -7.6461],
      [110.3318, -7.6468],
      [110.3318, -7.6473],
      [110.3321, -7.6471],
      [110.3327, -7.6478],
      [110.333, -7.6479],
      [110.3336, -7.6481],
      [110.3341, -7.6484],
      [110.3344, -7.648],
      [110.3359, -7.6466],
      [110.3367, -7.6457],
      [110.3371, -7.6454],
      [110.3378, -7.6458],
      [110.3385, -7.6458],
      [110.3388, -7.6455],
      [110.3392, -7.6456],
      [110.3395, -7.6454],
      [110.3398, -7.6453],
      [110.3402, -7.6453],
      [110.3404, -7.645],
      [110.3408, -7.6448],
      [110.3414, -7.6452],
      [110.343, -7.6461],
      [110.3437, -7.6468],
      [110.3439, -7.6469],
      [110.3436, -7.6473],
      [110.3435, -7.6479],
      [110.3433, -7.6483],
      [110.3428, -7.6488],
      [110.3422, -7.6492],
      [110.3417, -7.6496],
      [110.3415, -7.6501],
      [110.3416, -7.6507],
      [110.3418, -7.6514],
      [110.3418, -7.6518],
      [110.3413, -7.6531],
      [110.3423, -7.6535],
      [110.3432, -7.6539],
      [110.3434, -7.6541],
      [110.3429, -7.6546],
      [110.3429, -7.6548],
      [110.3436, -7.6552],
      [110.3445, -7.6534],
      [110.3447, -7.6534],
      [110.345, -7.6535],
      [110.3454, -7.6536],
      [110.3455, -7.6535],
      [110.3461, -7.6523],
      [110.3467, -7.6514],
      [110.3474, -7.6512],
      [110.3479, -7.651],
      [110.3483, -7.6507],
      [110.3483, -7.6503],
      [110.3483, -7.6496],
      [110.3483, -7.6491],
      [110.3487, -7.649],
      [110.349, -7.6488],
      [110.3493, -7.6485],
      [110.3495, -7.6483],
      [110.35, -7.6481],
      [110.3504, -7.6477],
      [110.3508, -7.6474],
      [110.3512, -7.6469],
      [110.3513, -7.6467],
      [110.3516, -7.646],
      [110.3518, -7.6454],
      [110.352, -7.6448],
      [110.352, -7.644],
      [110.3519, -7.6434],
      [110.3519, -7.6427],
      [110.3517, -7.6421],
      [110.3518, -7.6415],
      [110.352, -7.6408],
      [110.3521, -7.6403],
      [110.3521, -7.6398],
      [110.3525, -7.6393],
      [110.3527, -7.6389],
      [110.3529, -7.6385],
      [110.353, -7.638],
      [110.3528, -7.6375],
      [110.3527, -7.6373],
      [110.3523, -7.6366],
      [110.3523, -7.6363],
      [110.3526, -7.6358],
      [110.3531, -7.6354],
      [110.3534, -7.6353],
      [110.3536, -7.635],
      [110.3541, -7.6346],
      [110.3546, -7.6344],
      [110.355, -7.6343],
      [110.3554, -7.6336],
      [110.3558, -7.6331],
      [110.3563, -7.6327],
      [110.3564, -7.6322],
      [110.3565, -7.6313],
      [110.3563, -7.6306],
      [110.3563, -7.6303],
      [110.3565, -7.6296],
      [110.3568, -7.6288],
      [110.3571, -7.6284],
      [110.3575, -7.6282],
      [110.3576, -7.6278],
      [110.3585, -7.6274],
      [110.3589, -7.627],
      [110.3596, -7.6266],
      [110.3597, -7.6259],
      [110.36, -7.6254],
      [110.3604, -7.625],
      [110.3607, -7.6246],
      [110.361, -7.6245],
      [110.3611, -7.6245],
      [110.3613, -7.6245],
      [110.3616, -7.6244],
      [110.3618, -7.6241],
      [110.3618, -7.6238],
      [110.3619, -7.6236],
      [110.3621, -7.6233],
      [110.3626, -7.6232],
      [110.3628, -7.6228],
      [110.3629, -7.6227],
      [110.3631, -7.6227],
      [110.3634, -7.6223],
      [110.3635, -7.6222],
      [110.3636, -7.6221],
      [110.3638, -7.6221],
      [110.364, -7.622],
      [110.3642, -7.6218],
      [110.3644, -7.6216],
      [110.365, -7.621],
      [110.3654, -7.6205],
      [110.3656, -7.6201],
      [110.3659, -7.6198]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TURI',
    village: 'BANGUNKERTO',
    border: [
      [110.365, -7.6498],
      [110.3649, -7.6495],
      [110.3651, -7.6486],
      [110.3652, -7.6475],
      [110.3655, -7.6471],
      [110.3659, -7.6466],
      [110.3659, -7.6459],
      [110.366, -7.6457],
      [110.3665, -7.6454],
      [110.3665, -7.645],
      [110.3665, -7.6446],
      [110.3663, -7.6443],
      [110.3658, -7.6441],
      [110.3653, -7.6441],
      [110.3651, -7.6445],
      [110.3649, -7.6447],
      [110.3646, -7.6446],
      [110.3642, -7.6445],
      [110.3638, -7.6444],
      [110.3636, -7.6447],
      [110.3631, -7.6451],
      [110.3628, -7.6452],
      [110.3626, -7.6451],
      [110.3626, -7.6446],
      [110.3629, -7.6435],
      [110.3633, -7.6424],
      [110.3636, -7.6407],
      [110.3638, -7.6397],
      [110.3643, -7.639],
      [110.3644, -7.6382],
      [110.3646, -7.6378],
      [110.3647, -7.6374],
      [110.3646, -7.6371],
      [110.3644, -7.6366],
      [110.3646, -7.6361],
      [110.365, -7.6359],
      [110.3651, -7.6354],
      [110.3651, -7.6348],
      [110.3656, -7.634],
      [110.3659, -7.6335],
      [110.3658, -7.6324],
      [110.3656, -7.6317],
      [110.3658, -7.6312],
      [110.3658, -7.6306],
      [110.366, -7.6304],
      [110.3661, -7.6299],
      [110.3662, -7.6292],
      [110.3666, -7.6287],
      [110.3672, -7.6283],
      [110.3679, -7.628],
      [110.3681, -7.6274],
      [110.3684, -7.6273],
      [110.3686, -7.6269],
      [110.369, -7.6267],
      [110.3693, -7.6263],
      [110.3697, -7.626],
      [110.3701, -7.6257],
      [110.3703, -7.6252],
      [110.3704, -7.625],
      [110.3707, -7.6248],
      [110.3708, -7.6243],
      [110.371, -7.6242],
      [110.372, -7.6242],
      [110.3722, -7.6241],
      [110.3722, -7.6235],
      [110.3722, -7.6227],
      [110.3724, -7.6224],
      [110.3725, -7.622],
      [110.3726, -7.6217],
      [110.373, -7.6216],
      [110.3722, -7.6211],
      [110.3712, -7.6202],
      [110.3704, -7.6195],
      [110.37, -7.6192],
      [110.3697, -7.6191],
      [110.3688, -7.6191],
      [110.3683, -7.619],
      [110.3677, -7.6189],
      [110.3674, -7.6189],
      [110.3664, -7.6195],
      [110.3659, -7.6198],
      [110.3656, -7.6201],
      [110.3654, -7.6205],
      [110.365, -7.621],
      [110.3644, -7.6216],
      [110.3642, -7.6218],
      [110.364, -7.622],
      [110.3638, -7.6221],
      [110.3636, -7.6221],
      [110.3635, -7.6222],
      [110.3634, -7.6223],
      [110.3631, -7.6227],
      [110.3629, -7.6227],
      [110.3628, -7.6228],
      [110.3626, -7.6232],
      [110.3621, -7.6233],
      [110.3619, -7.6236],
      [110.3618, -7.6238],
      [110.3618, -7.6241],
      [110.3616, -7.6244],
      [110.3613, -7.6245],
      [110.3611, -7.6245],
      [110.361, -7.6245],
      [110.3607, -7.6246],
      [110.3604, -7.625],
      [110.36, -7.6254],
      [110.3597, -7.6259],
      [110.3596, -7.6266],
      [110.3589, -7.627],
      [110.3585, -7.6274],
      [110.3576, -7.6278],
      [110.3575, -7.6282],
      [110.3571, -7.6284],
      [110.3568, -7.6288],
      [110.3565, -7.6296],
      [110.3563, -7.6303],
      [110.3563, -7.6306],
      [110.3565, -7.6313],
      [110.3564, -7.6322],
      [110.3563, -7.6327],
      [110.3558, -7.6331],
      [110.3554, -7.6336],
      [110.355, -7.6343],
      [110.3546, -7.6344],
      [110.3541, -7.6346],
      [110.3536, -7.635],
      [110.3534, -7.6353],
      [110.3531, -7.6354],
      [110.3526, -7.6358],
      [110.3523, -7.6363],
      [110.3523, -7.6366],
      [110.3527, -7.6373],
      [110.3528, -7.6375],
      [110.353, -7.638],
      [110.3529, -7.6385],
      [110.3527, -7.6389],
      [110.3525, -7.6393],
      [110.3521, -7.6398],
      [110.3521, -7.6403],
      [110.352, -7.6408],
      [110.3518, -7.6415],
      [110.3517, -7.6421],
      [110.3519, -7.6427],
      [110.3519, -7.6434],
      [110.352, -7.644],
      [110.352, -7.6448],
      [110.3518, -7.6454],
      [110.3516, -7.646],
      [110.3513, -7.6467],
      [110.3512, -7.6469],
      [110.3508, -7.6474],
      [110.3504, -7.6477],
      [110.35, -7.6481],
      [110.3495, -7.6483],
      [110.3493, -7.6485],
      [110.349, -7.6488],
      [110.3487, -7.649],
      [110.3483, -7.6491],
      [110.3483, -7.6496],
      [110.3483, -7.6503],
      [110.3483, -7.6507],
      [110.3479, -7.651],
      [110.3474, -7.6512],
      [110.3467, -7.6514],
      [110.3461, -7.6523],
      [110.3455, -7.6535],
      [110.3455, -7.6536],
      [110.3453, -7.654],
      [110.345, -7.6545],
      [110.3449, -7.6551],
      [110.3446, -7.6556],
      [110.3442, -7.6561],
      [110.3441, -7.6565],
      [110.344, -7.6568],
      [110.3446, -7.657],
      [110.3447, -7.6574],
      [110.3451, -7.6578],
      [110.3448, -7.6585],
      [110.3442, -7.6592],
      [110.3441, -7.6599],
      [110.3443, -7.6605],
      [110.3442, -7.6614],
      [110.3438, -7.6625],
      [110.3437, -7.6629],
      [110.3437, -7.6641],
      [110.3467, -7.6653],
      [110.3479, -7.6658],
      [110.3488, -7.6662],
      [110.3487, -7.6665],
      [110.3485, -7.6666],
      [110.3484, -7.6666],
      [110.3482, -7.6668],
      [110.3479, -7.667],
      [110.3496, -7.6676],
      [110.3535, -7.6691],
      [110.3538, -7.6691],
      [110.3543, -7.6686],
      [110.3541, -7.6682],
      [110.3541, -7.6678],
      [110.3545, -7.6667],
      [110.3546, -7.666],
      [110.3546, -7.6656],
      [110.3546, -7.665],
      [110.3547, -7.6645],
      [110.3548, -7.6641],
      [110.355, -7.6638],
      [110.3551, -7.6632],
      [110.3552, -7.6631],
      [110.3553, -7.6628],
      [110.3562, -7.662],
      [110.3572, -7.6622],
      [110.3579, -7.6625],
      [110.3582, -7.662],
      [110.3584, -7.6613],
      [110.3584, -7.6601],
      [110.3585, -7.6593],
      [110.3587, -7.6586],
      [110.3589, -7.6576],
      [110.3592, -7.6567],
      [110.3591, -7.6562],
      [110.359, -7.6558],
      [110.3595, -7.6553],
      [110.3596, -7.655],
      [110.3597, -7.6545],
      [110.3599, -7.6544],
      [110.3613, -7.6548],
      [110.3622, -7.6549],
      [110.3626, -7.6548],
      [110.3627, -7.6547],
      [110.3629, -7.6546],
      [110.3632, -7.6536],
      [110.3639, -7.6521],
      [110.3644, -7.6509],
      [110.3648, -7.6502],
      [110.365, -7.6498]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TURI',
    village: 'DONOKERTO',
    border: [
      [110.3799, -7.6459],
      [110.3796, -7.6457],
      [110.3783, -7.6451],
      [110.3769, -7.6475],
      [110.3756, -7.65],
      [110.3751, -7.6508],
      [110.3749, -7.6509],
      [110.3747, -7.6511],
      [110.3741, -7.6514],
      [110.3737, -7.6514],
      [110.3735, -7.6517],
      [110.3729, -7.6521],
      [110.3724, -7.6525],
      [110.3718, -7.6531],
      [110.3717, -7.6533],
      [110.3714, -7.6534],
      [110.3713, -7.6537],
      [110.3712, -7.654],
      [110.3708, -7.6543],
      [110.3705, -7.6544],
      [110.3704, -7.6545],
      [110.3701, -7.6547],
      [110.3694, -7.6547],
      [110.3686, -7.6543],
      [110.3683, -7.6541],
      [110.3683, -7.6536],
      [110.3681, -7.6536],
      [110.3679, -7.6536],
      [110.3676, -7.6539],
      [110.3676, -7.654],
      [110.3673, -7.6539],
      [110.3672, -7.6537],
      [110.3673, -7.6532],
      [110.3675, -7.6525],
      [110.368, -7.6521],
      [110.3686, -7.652],
      [110.3697, -7.6518],
      [110.3694, -7.6514],
      [110.369, -7.6509],
      [110.3687, -7.6505],
      [110.3695, -7.6486],
      [110.3699, -7.6477],
      [110.3698, -7.6472],
      [110.3701, -7.6469],
      [110.3714, -7.6437],
      [110.3717, -7.6428],
      [110.3714, -7.6416],
      [110.3711, -7.6411],
      [110.3707, -7.6407],
      [110.3705, -7.6407],
      [110.3702, -7.6415],
      [110.3701, -7.6419],
      [110.3702, -7.6421],
      [110.369, -7.6446],
      [110.3686, -7.6454],
      [110.3685, -7.6459],
      [110.3676, -7.6477],
      [110.3667, -7.649],
      [110.3661, -7.6496],
      [110.3653, -7.6499],
      [110.365, -7.6498],
      [110.3648, -7.6502],
      [110.3644, -7.6509],
      [110.3639, -7.6521],
      [110.3632, -7.6536],
      [110.3629, -7.6546],
      [110.3627, -7.6547],
      [110.3626, -7.6548],
      [110.3622, -7.6549],
      [110.3613, -7.6548],
      [110.3599, -7.6544],
      [110.3597, -7.6545],
      [110.3596, -7.655],
      [110.3595, -7.6553],
      [110.359, -7.6558],
      [110.3591, -7.6562],
      [110.3592, -7.6567],
      [110.3589, -7.6576],
      [110.3587, -7.6586],
      [110.3585, -7.6593],
      [110.3584, -7.6601],
      [110.3584, -7.6613],
      [110.3582, -7.662],
      [110.3579, -7.6625],
      [110.3577, -7.6632],
      [110.3577, -7.6637],
      [110.3575, -7.6645],
      [110.3573, -7.6645],
      [110.357, -7.6645],
      [110.3568, -7.6648],
      [110.3568, -7.6652],
      [110.3568, -7.666],
      [110.3567, -7.6663],
      [110.3565, -7.6664],
      [110.356, -7.6662],
      [110.3558, -7.6669],
      [110.3557, -7.6675],
      [110.356, -7.6681],
      [110.3562, -7.6686],
      [110.3564, -7.6687],
      [110.357, -7.6677],
      [110.3572, -7.6675],
      [110.3579, -7.6676],
      [110.3584, -7.6679],
      [110.3593, -7.6685],
      [110.3604, -7.6689],
      [110.3605, -7.6688],
      [110.361, -7.6679],
      [110.3611, -7.6675],
      [110.3616, -7.6672],
      [110.3623, -7.6667],
      [110.3626, -7.6666],
      [110.3631, -7.6669],
      [110.3634, -7.6673],
      [110.3638, -7.6673],
      [110.3642, -7.6671],
      [110.3648, -7.667],
      [110.365, -7.6673],
      [110.3652, -7.6678],
      [110.3652, -7.6682],
      [110.365, -7.6686],
      [110.3648, -7.669],
      [110.3646, -7.6696],
      [110.3646, -7.67],
      [110.3649, -7.6704],
      [110.366, -7.6708],
      [110.3662, -7.6709],
      [110.3666, -7.6711],
      [110.3671, -7.671],
      [110.3677, -7.671],
      [110.3679, -7.671],
      [110.3685, -7.6702],
      [110.3687, -7.6698],
      [110.3689, -7.6696],
      [110.3694, -7.6696],
      [110.3699, -7.6698],
      [110.37, -7.6698],
      [110.3705, -7.6699],
      [110.3707, -7.6696],
      [110.371, -7.6692],
      [110.3711, -7.6692],
      [110.3714, -7.6692],
      [110.3715, -7.6692],
      [110.3717, -7.6698],
      [110.3716, -7.6701],
      [110.3716, -7.6704],
      [110.3714, -7.671],
      [110.3715, -7.6714],
      [110.3718, -7.6718],
      [110.3718, -7.6722],
      [110.3714, -7.6724],
      [110.3707, -7.6723],
      [110.3703, -7.6723],
      [110.3698, -7.6723],
      [110.3696, -7.6725],
      [110.3697, -7.6733],
      [110.3701, -7.6736],
      [110.3701, -7.6741],
      [110.3701, -7.6742],
      [110.3698, -7.6746],
      [110.3695, -7.6749],
      [110.3692, -7.675],
      [110.3691, -7.675],
      [110.3689, -7.6749],
      [110.3685, -7.6752],
      [110.3681, -7.676],
      [110.3683, -7.6761],
      [110.3684, -7.6762],
      [110.3693, -7.6764],
      [110.3697, -7.6765],
      [110.3708, -7.6772],
      [110.371, -7.6775],
      [110.3711, -7.6778],
      [110.3711, -7.6786],
      [110.371, -7.6796],
      [110.3708, -7.6801],
      [110.3707, -7.6806],
      [110.3706, -7.6809],
      [110.3709, -7.6812],
      [110.3714, -7.6813],
      [110.372, -7.6813],
      [110.3727, -7.6815],
      [110.3731, -7.6815],
      [110.3735, -7.6816],
      [110.3738, -7.682],
      [110.3738, -7.6823],
      [110.3735, -7.6826],
      [110.3737, -7.6829],
      [110.3741, -7.6832],
      [110.3743, -7.6832],
      [110.3744, -7.6833],
      [110.3751, -7.6828],
      [110.3753, -7.6818],
      [110.3755, -7.6809],
      [110.3762, -7.6803],
      [110.3762, -7.6796],
      [110.3761, -7.6788],
      [110.3761, -7.6785],
      [110.3765, -7.678],
      [110.3766, -7.6773],
      [110.3771, -7.6765],
      [110.3773, -7.6759],
      [110.3774, -7.6752],
      [110.3774, -7.6745],
      [110.3774, -7.6744],
      [110.3773, -7.6739],
      [110.3771, -7.6735],
      [110.3771, -7.673],
      [110.3771, -7.6729],
      [110.377, -7.6727],
      [110.377, -7.6724],
      [110.3773, -7.6723],
      [110.3773, -7.6719],
      [110.3775, -7.6703],
      [110.3782, -7.6681],
      [110.3783, -7.6673],
      [110.3785, -7.6664],
      [110.3787, -7.6661],
      [110.379, -7.6658],
      [110.3791, -7.6655],
      [110.3792, -7.6654],
      [110.3796, -7.6653],
      [110.38, -7.6652],
      [110.3801, -7.6648],
      [110.3804, -7.6648],
      [110.3808, -7.6646],
      [110.381, -7.6642],
      [110.3813, -7.6636],
      [110.3816, -7.6629],
      [110.3819, -7.6622],
      [110.3822, -7.6619],
      [110.3825, -7.6616],
      [110.3825, -7.6612],
      [110.3826, -7.6606],
      [110.383, -7.6603],
      [110.3834, -7.6602],
      [110.3832, -7.6599],
      [110.383, -7.6597],
      [110.3833, -7.6593],
      [110.3836, -7.659],
      [110.3841, -7.6587],
      [110.3844, -7.6583],
      [110.3848, -7.6579],
      [110.3849, -7.6577],
      [110.3854, -7.6571],
      [110.3859, -7.6567],
      [110.3862, -7.6561],
      [110.3867, -7.6556],
      [110.387, -7.6547],
      [110.3873, -7.654],
      [110.3878, -7.6533],
      [110.3885, -7.653],
      [110.3892, -7.6522],
      [110.39, -7.6515],
      [110.3909, -7.6506],
      [110.3908, -7.6496],
      [110.391, -7.6489],
      [110.3914, -7.6485],
      [110.3913, -7.6479],
      [110.3913, -7.6478],
      [110.391, -7.6477],
      [110.3909, -7.6477],
      [110.3904, -7.6475],
      [110.39, -7.6472],
      [110.3898, -7.6467],
      [110.3895, -7.6461],
      [110.3898, -7.6452],
      [110.3905, -7.6438],
      [110.3908, -7.6432],
      [110.391, -7.6428],
      [110.3907, -7.6426],
      [110.3904, -7.6426],
      [110.3899, -7.6428],
      [110.3895, -7.643],
      [110.3891, -7.6433],
      [110.3887, -7.6436],
      [110.3886, -7.644],
      [110.3884, -7.6443],
      [110.388, -7.6445],
      [110.3873, -7.6443],
      [110.387, -7.6443],
      [110.3868, -7.6445],
      [110.3867, -7.6449],
      [110.3862, -7.6452],
      [110.3855, -7.646],
      [110.3854, -7.6463],
      [110.3848, -7.6465],
      [110.3842, -7.6469],
      [110.3838, -7.6474],
      [110.3835, -7.6477],
      [110.383, -7.6487],
      [110.3825, -7.6492],
      [110.3822, -7.6493],
      [110.382, -7.6498],
      [110.3816, -7.65],
      [110.3813, -7.6503],
      [110.3811, -7.6507],
      [110.3807, -7.651],
      [110.3803, -7.6512],
      [110.3804, -7.6516],
      [110.3804, -7.6521],
      [110.3803, -7.6528],
      [110.38, -7.6535],
      [110.3794, -7.6536],
      [110.379, -7.6534],
      [110.379, -7.6532],
      [110.3792, -7.6528],
      [110.3793, -7.6525],
      [110.3793, -7.6522],
      [110.379, -7.6514],
      [110.3791, -7.6513],
      [110.3795, -7.6511],
      [110.3795, -7.6509],
      [110.3799, -7.6504],
      [110.38, -7.6501],
      [110.3801, -7.6498],
      [110.3801, -7.6493],
      [110.3802, -7.649],
      [110.3802, -7.6486],
      [110.3801, -7.6481],
      [110.3801, -7.6477],
      [110.3801, -7.6475],
      [110.3804, -7.647],
      [110.3806, -7.6467],
      [110.3802, -7.6465],
      [110.38, -7.6462],
      [110.38, -7.646],
      [110.3799, -7.6459]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TURI',
    village: 'GIRIKERTO',
    border: [
      [110.4159, -7.5749],
      [110.4156, -7.5737],
      [110.4153, -7.5722],
      [110.4153, -7.5713],
      [110.4152, -7.571],
      [110.4152, -7.5701],
      [110.4145, -7.5687],
      [110.4141, -7.5681],
      [110.4136, -7.5671],
      [110.4137, -7.5664],
      [110.4135, -7.5656],
      [110.4134, -7.5654],
      [110.412, -7.5672],
      [110.4119, -7.5674],
      [110.4104, -7.5693],
      [110.4101, -7.5697],
      [110.4096, -7.5703],
      [110.4094, -7.5707],
      [110.4092, -7.5709],
      [110.4086, -7.572],
      [110.4083, -7.5724],
      [110.4079, -7.5729],
      [110.4072, -7.5738],
      [110.407, -7.574],
      [110.406, -7.5753],
      [110.4063, -7.5755],
      [110.4073, -7.5753],
      [110.4073, -7.5753],
      [110.4085, -7.5751],
      [110.4091, -7.575],
      [110.4099, -7.5751],
      [110.4102, -7.5756],
      [110.4103, -7.5762],
      [110.4103, -7.5766],
      [110.4097, -7.5786],
      [110.4089, -7.5806],
      [110.4085, -7.5821],
      [110.4082, -7.5833],
      [110.4077, -7.585],
      [110.4072, -7.5871],
      [110.4067, -7.5895],
      [110.4065, -7.5902],
      [110.4061, -7.5907],
      [110.4051, -7.5918],
      [110.4034, -7.5932],
      [110.4012, -7.5948],
      [110.3992, -7.597],
      [110.3988, -7.5975],
      [110.3987, -7.598],
      [110.3985, -7.5983],
      [110.398, -7.5985],
      [110.3978, -7.5985],
      [110.3976, -7.5985],
      [110.3974, -7.5988],
      [110.3971, -7.599],
      [110.3967, -7.5992],
      [110.3965, -7.5995],
      [110.3965, -7.5999],
      [110.396, -7.6001],
      [110.3957, -7.6005],
      [110.3955, -7.6005],
      [110.3953, -7.6007],
      [110.3952, -7.6011],
      [110.3951, -7.6016],
      [110.395, -7.6018],
      [110.3947, -7.6017],
      [110.3944, -7.6022],
      [110.3939, -7.6024],
      [110.3932, -7.6032],
      [110.3929, -7.6042],
      [110.3929, -7.6046],
      [110.393, -7.6049],
      [110.3928, -7.6053],
      [110.3928, -7.6056],
      [110.3927, -7.6058],
      [110.3928, -7.606],
      [110.3927, -7.6067],
      [110.3929, -7.6074],
      [110.3929, -7.6078],
      [110.3926, -7.6082],
      [110.3926, -7.6089],
      [110.3925, -7.6093],
      [110.3924, -7.6097],
      [110.3917, -7.6104],
      [110.391, -7.6109],
      [110.3906, -7.6113],
      [110.3905, -7.6115],
      [110.3908, -7.6121],
      [110.3909, -7.6123],
      [110.3908, -7.6125],
      [110.3908, -7.6126],
      [110.3902, -7.6135],
      [110.39, -7.6137],
      [110.3899, -7.6141],
      [110.3897, -7.6144],
      [110.3897, -7.615],
      [110.3902, -7.6152],
      [110.3905, -7.6163],
      [110.3905, -7.6171],
      [110.3904, -7.6173],
      [110.3897, -7.6174],
      [110.3895, -7.6179],
      [110.3898, -7.6183],
      [110.3897, -7.619],
      [110.3895, -7.6197],
      [110.3893, -7.6202],
      [110.3884, -7.6207],
      [110.3878, -7.6215],
      [110.3876, -7.6224],
      [110.3869, -7.6234],
      [110.3869, -7.6236],
      [110.3867, -7.624],
      [110.3867, -7.6245],
      [110.3868, -7.6252],
      [110.3876, -7.6256],
      [110.3877, -7.6257],
      [110.3878, -7.6259],
      [110.3879, -7.6261],
      [110.3875, -7.6266],
      [110.3868, -7.6274],
      [110.3865, -7.6282],
      [110.3861, -7.6284],
      [110.386, -7.6285],
      [110.386, -7.6295],
      [110.3859, -7.63],
      [110.3859, -7.6304],
      [110.3858, -7.631],
      [110.3858, -7.6315],
      [110.3859, -7.632],
      [110.3858, -7.6333],
      [110.3855, -7.6336],
      [110.3855, -7.6341],
      [110.3852, -7.6346],
      [110.385, -7.6352],
      [110.3849, -7.6359],
      [110.3846, -7.6364],
      [110.3843, -7.6371],
      [110.3841, -7.6376],
      [110.3841, -7.638],
      [110.384, -7.6384],
      [110.3837, -7.6389],
      [110.3838, -7.6392],
      [110.3838, -7.6394],
      [110.3837, -7.6397],
      [110.3833, -7.6404],
      [110.3819, -7.6417],
      [110.3813, -7.6423],
      [110.3813, -7.6428],
      [110.3812, -7.643],
      [110.3809, -7.6434],
      [110.3809, -7.6439],
      [110.3809, -7.6442],
      [110.3807, -7.6452],
      [110.3802, -7.646],
      [110.38, -7.6459],
      [110.38, -7.646],
      [110.38, -7.6462],
      [110.3802, -7.6465],
      [110.3806, -7.6467],
      [110.3804, -7.647],
      [110.3801, -7.6475],
      [110.3801, -7.6477],
      [110.3801, -7.6481],
      [110.3802, -7.6486],
      [110.3802, -7.649],
      [110.3801, -7.6493],
      [110.3801, -7.6498],
      [110.38, -7.6501],
      [110.3799, -7.6504],
      [110.3795, -7.6509],
      [110.3795, -7.6511],
      [110.3791, -7.6513],
      [110.379, -7.6514],
      [110.3793, -7.6522],
      [110.3793, -7.6525],
      [110.3792, -7.6528],
      [110.379, -7.6532],
      [110.379, -7.6534],
      [110.3794, -7.6536],
      [110.38, -7.6535],
      [110.3803, -7.6528],
      [110.3804, -7.6521],
      [110.3804, -7.6516],
      [110.3803, -7.6512],
      [110.3807, -7.651],
      [110.3811, -7.6507],
      [110.3813, -7.6503],
      [110.3816, -7.65],
      [110.382, -7.6498],
      [110.3822, -7.6493],
      [110.3825, -7.6492],
      [110.383, -7.6487],
      [110.3835, -7.6477],
      [110.3838, -7.6474],
      [110.3842, -7.6469],
      [110.3848, -7.6465],
      [110.3854, -7.6463],
      [110.3855, -7.646],
      [110.3862, -7.6452],
      [110.3867, -7.6449],
      [110.3868, -7.6445],
      [110.387, -7.6443],
      [110.3873, -7.6443],
      [110.388, -7.6445],
      [110.3884, -7.6443],
      [110.3886, -7.644],
      [110.3887, -7.6436],
      [110.3891, -7.6433],
      [110.3895, -7.643],
      [110.3899, -7.6428],
      [110.3904, -7.6426],
      [110.3907, -7.6426],
      [110.391, -7.6428],
      [110.3908, -7.6432],
      [110.3905, -7.6438],
      [110.3898, -7.6452],
      [110.3895, -7.6461],
      [110.3898, -7.6467],
      [110.39, -7.6472],
      [110.3904, -7.6475],
      [110.3909, -7.6477],
      [110.391, -7.6477],
      [110.3913, -7.6478],
      [110.3912, -7.6473],
      [110.3915, -7.6466],
      [110.3918, -7.6458],
      [110.3921, -7.6451],
      [110.3923, -7.6446],
      [110.3924, -7.6441],
      [110.3925, -7.6432],
      [110.3927, -7.6428],
      [110.3932, -7.6425],
      [110.3936, -7.6422],
      [110.394, -7.6422],
      [110.3943, -7.6418],
      [110.395, -7.6414],
      [110.3954, -7.6409],
      [110.3961, -7.64],
      [110.3965, -7.6392],
      [110.397, -7.6382],
      [110.3973, -7.638],
      [110.3973, -7.6369],
      [110.3977, -7.6366],
      [110.3982, -7.6364],
      [110.3983, -7.6363],
      [110.3983, -7.636],
      [110.3983, -7.6358],
      [110.398, -7.6354],
      [110.3978, -7.6349],
      [110.3981, -7.6342],
      [110.3989, -7.632],
      [110.3997, -7.6299],
      [110.3998, -7.6294],
      [110.4002, -7.6289],
      [110.401, -7.6276],
      [110.4018, -7.6273],
      [110.4024, -7.6263],
      [110.4031, -7.6251],
      [110.4033, -7.6245],
      [110.4036, -7.6241],
      [110.4038, -7.6232],
      [110.4038, -7.623],
      [110.4038, -7.6228],
      [110.4037, -7.6225],
      [110.4038, -7.6221],
      [110.4042, -7.6216],
      [110.4045, -7.6212],
      [110.4048, -7.6203],
      [110.4044, -7.6199],
      [110.4031, -7.6194],
      [110.4033, -7.6186],
      [110.4035, -7.618],
      [110.4039, -7.6175],
      [110.4043, -7.6168],
      [110.4047, -7.6164],
      [110.4049, -7.6159],
      [110.4051, -7.6157],
      [110.4055, -7.6155],
      [110.4056, -7.6147],
      [110.4059, -7.614],
      [110.4061, -7.6136],
      [110.4064, -7.6132],
      [110.4065, -7.6126],
      [110.4066, -7.6122],
      [110.4068, -7.612],
      [110.407, -7.6113],
      [110.4073, -7.611],
      [110.4076, -7.6108],
      [110.4076, -7.6104],
      [110.4079, -7.6102],
      [110.4081, -7.6097],
      [110.4082, -7.6094],
      [110.4081, -7.6088],
      [110.4081, -7.6084],
      [110.4087, -7.6078],
      [110.4086, -7.6076],
      [110.4087, -7.6075],
      [110.4091, -7.6071],
      [110.4091, -7.6068],
      [110.4091, -7.6067],
      [110.4091, -7.6066],
      [110.4091, -7.6059],
      [110.4093, -7.6055],
      [110.4092, -7.6052],
      [110.4092, -7.6044],
      [110.4094, -7.6042],
      [110.4094, -7.6036],
      [110.4094, -7.6033],
      [110.4095, -7.6027],
      [110.4096, -7.6019],
      [110.4092, -7.6013],
      [110.409, -7.6006],
      [110.409, -7.5994],
      [110.4091, -7.599],
      [110.4094, -7.5987],
      [110.4094, -7.5981],
      [110.4097, -7.5979],
      [110.4102, -7.5976],
      [110.4102, -7.5973],
      [110.4101, -7.5969],
      [110.4106, -7.5966],
      [110.4108, -7.5966],
      [110.4109, -7.596],
      [110.4111, -7.5954],
      [110.4114, -7.5952],
      [110.4119, -7.5953],
      [110.4122, -7.5952],
      [110.4125, -7.5938],
      [110.413, -7.5919],
      [110.4131, -7.591],
      [110.4131, -7.5903],
      [110.4133, -7.5897],
      [110.4132, -7.5887],
      [110.4133, -7.5881],
      [110.4132, -7.5875],
      [110.4133, -7.5869],
      [110.4138, -7.5861],
      [110.4143, -7.5854],
      [110.4146, -7.585],
      [110.4152, -7.5851],
      [110.4158, -7.5841],
      [110.4163, -7.5828],
      [110.4163, -7.582],
      [110.4164, -7.5817],
      [110.4161, -7.5812],
      [110.416, -7.5811],
      [110.4161, -7.5803],
      [110.4162, -7.5794],
      [110.4162, -7.5789],
      [110.4162, -7.578],
      [110.416, -7.5766],
      [110.4159, -7.5761],
      [110.4159, -7.5749]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'TURI',
    village: 'WONOKERTO',
    border: [
      [110.38, -7.6459],
      [110.3802, -7.646],
      [110.3807, -7.6452],
      [110.3809, -7.6442],
      [110.3809, -7.6439],
      [110.3809, -7.6434],
      [110.3812, -7.643],
      [110.3813, -7.6428],
      [110.3813, -7.6423],
      [110.3819, -7.6417],
      [110.3833, -7.6404],
      [110.3837, -7.6397],
      [110.3838, -7.6394],
      [110.3838, -7.6392],
      [110.3837, -7.6389],
      [110.384, -7.6384],
      [110.3841, -7.638],
      [110.3841, -7.6376],
      [110.3843, -7.6371],
      [110.3846, -7.6364],
      [110.3849, -7.6359],
      [110.385, -7.6352],
      [110.3852, -7.6346],
      [110.3855, -7.6341],
      [110.3855, -7.6336],
      [110.3858, -7.6333],
      [110.3859, -7.632],
      [110.3858, -7.6315],
      [110.3858, -7.631],
      [110.3859, -7.6304],
      [110.3859, -7.63],
      [110.386, -7.6295],
      [110.386, -7.6285],
      [110.3861, -7.6284],
      [110.3865, -7.6282],
      [110.3868, -7.6274],
      [110.3875, -7.6266],
      [110.3879, -7.6261],
      [110.3878, -7.6259],
      [110.3877, -7.6257],
      [110.3876, -7.6256],
      [110.3868, -7.6252],
      [110.3867, -7.6245],
      [110.3867, -7.624],
      [110.3869, -7.6236],
      [110.3869, -7.6234],
      [110.3876, -7.6224],
      [110.3878, -7.6215],
      [110.3884, -7.6207],
      [110.3893, -7.6202],
      [110.3895, -7.6197],
      [110.3897, -7.619],
      [110.3898, -7.6183],
      [110.3895, -7.6179],
      [110.3897, -7.6174],
      [110.3904, -7.6173],
      [110.3905, -7.6171],
      [110.3905, -7.6163],
      [110.3902, -7.6152],
      [110.3897, -7.615],
      [110.3897, -7.6144],
      [110.3899, -7.6141],
      [110.39, -7.6137],
      [110.3902, -7.6135],
      [110.3908, -7.6126],
      [110.3908, -7.6125],
      [110.3909, -7.6123],
      [110.3908, -7.6121],
      [110.3905, -7.6115],
      [110.3906, -7.6113],
      [110.391, -7.6109],
      [110.3917, -7.6104],
      [110.3924, -7.6097],
      [110.3925, -7.6093],
      [110.3926, -7.6089],
      [110.3926, -7.6082],
      [110.3929, -7.6078],
      [110.3929, -7.6074],
      [110.3927, -7.6067],
      [110.3928, -7.606],
      [110.3927, -7.6058],
      [110.3928, -7.6056],
      [110.3928, -7.6053],
      [110.393, -7.6049],
      [110.3929, -7.6046],
      [110.3929, -7.6042],
      [110.3932, -7.6032],
      [110.3939, -7.6024],
      [110.3944, -7.6022],
      [110.3947, -7.6017],
      [110.395, -7.6018],
      [110.3951, -7.6016],
      [110.3952, -7.6011],
      [110.3953, -7.6007],
      [110.3955, -7.6005],
      [110.3957, -7.6005],
      [110.396, -7.6001],
      [110.3965, -7.5999],
      [110.3965, -7.5995],
      [110.3967, -7.5992],
      [110.3971, -7.599],
      [110.3974, -7.5988],
      [110.3976, -7.5985],
      [110.3978, -7.5985],
      [110.398, -7.5985],
      [110.3985, -7.5983],
      [110.3987, -7.598],
      [110.3988, -7.5975],
      [110.3992, -7.597],
      [110.4012, -7.5948],
      [110.4034, -7.5932],
      [110.4051, -7.5918],
      [110.4061, -7.5907],
      [110.4065, -7.5902],
      [110.4067, -7.5895],
      [110.4072, -7.5871],
      [110.4077, -7.585],
      [110.4082, -7.5833],
      [110.4085, -7.5821],
      [110.4089, -7.5806],
      [110.4097, -7.5786],
      [110.4103, -7.5766],
      [110.4103, -7.5762],
      [110.4102, -7.5756],
      [110.4099, -7.5751],
      [110.4091, -7.575],
      [110.4085, -7.5751],
      [110.4073, -7.5753],
      [110.4073, -7.5753],
      [110.4063, -7.5755],
      [110.406, -7.5753],
      [110.4058, -7.5755],
      [110.4048, -7.576],
      [110.4042, -7.5764],
      [110.4041, -7.5766],
      [110.4041, -7.5772],
      [110.4042, -7.5775],
      [110.4042, -7.5778],
      [110.4039, -7.5779],
      [110.4037, -7.578],
      [110.4035, -7.5781],
      [110.4031, -7.5782],
      [110.403, -7.5784],
      [110.4028, -7.5789],
      [110.4026, -7.579],
      [110.4021, -7.579],
      [110.4019, -7.5792],
      [110.4016, -7.5797],
      [110.4016, -7.5799],
      [110.4016, -7.5803],
      [110.4015, -7.5807],
      [110.4016, -7.581],
      [110.4017, -7.5812],
      [110.4018, -7.5814],
      [110.4014, -7.5818],
      [110.401, -7.5821],
      [110.4011, -7.5824],
      [110.401, -7.5827],
      [110.4007, -7.5829],
      [110.4006, -7.5832],
      [110.4005, -7.5836],
      [110.4005, -7.5839],
      [110.4005, -7.5842],
      [110.4003, -7.5846],
      [110.4001, -7.585],
      [110.3993, -7.5849],
      [110.3992, -7.585],
      [110.3989, -7.5851],
      [110.3988, -7.5856],
      [110.3988, -7.5861],
      [110.3987, -7.5863],
      [110.3986, -7.5865],
      [110.3982, -7.5867],
      [110.3981, -7.5871],
      [110.3976, -7.5875],
      [110.3972, -7.5881],
      [110.3969, -7.5883],
      [110.3968, -7.5886],
      [110.3965, -7.5892],
      [110.3964, -7.5893],
      [110.3958, -7.5893],
      [110.3956, -7.5894],
      [110.3955, -7.5895],
      [110.3953, -7.5898],
      [110.3949, -7.5899],
      [110.3947, -7.5899],
      [110.3945, -7.5902],
      [110.3941, -7.5904],
      [110.394, -7.5906],
      [110.3937, -7.5909],
      [110.3934, -7.5912],
      [110.3931, -7.5915],
      [110.3928, -7.5916],
      [110.3926, -7.5917],
      [110.3922, -7.5917],
      [110.3913, -7.5923],
      [110.391, -7.5925],
      [110.3904, -7.5924],
      [110.3901, -7.5925],
      [110.3901, -7.5926],
      [110.3901, -7.593],
      [110.39, -7.5933],
      [110.39, -7.5936],
      [110.3903, -7.5939],
      [110.3906, -7.5939],
      [110.3908, -7.594],
      [110.3909, -7.5942],
      [110.391, -7.5945],
      [110.3911, -7.5948],
      [110.391, -7.5951],
      [110.3908, -7.5954],
      [110.3907, -7.5955],
      [110.3905, -7.5955],
      [110.3903, -7.5957],
      [110.3902, -7.5959],
      [110.3896, -7.5962],
      [110.3888, -7.5969],
      [110.3884, -7.5973],
      [110.3883, -7.5976],
      [110.3878, -7.5978],
      [110.3876, -7.5979],
      [110.3873, -7.598],
      [110.3871, -7.5981],
      [110.3869, -7.5982],
      [110.3866, -7.5983],
      [110.386, -7.5983],
      [110.3859, -7.5982],
      [110.3856, -7.5982],
      [110.3856, -7.5983],
      [110.3856, -7.5984],
      [110.3853, -7.5988],
      [110.3851, -7.5991],
      [110.3849, -7.5994],
      [110.3847, -7.5996],
      [110.3845, -7.5999],
      [110.3843, -7.6001],
      [110.3842, -7.6001],
      [110.3839, -7.6001],
      [110.3838, -7.6001],
      [110.3833, -7.6001],
      [110.383, -7.6001],
      [110.3828, -7.6003],
      [110.3826, -7.6005],
      [110.3821, -7.6005],
      [110.3814, -7.6005],
      [110.3811, -7.6006],
      [110.381, -7.6009],
      [110.381, -7.6012],
      [110.3809, -7.6014],
      [110.3807, -7.6014],
      [110.3802, -7.6015],
      [110.3799, -7.6014],
      [110.3796, -7.6013],
      [110.3795, -7.6016],
      [110.3792, -7.6018],
      [110.379, -7.602],
      [110.3788, -7.6023],
      [110.3786, -7.6028],
      [110.3786, -7.6033],
      [110.3784, -7.6034],
      [110.3782, -7.6035],
      [110.3781, -7.6036],
      [110.3774, -7.6038],
      [110.3773, -7.604],
      [110.3771, -7.6043],
      [110.3771, -7.605],
      [110.377, -7.6054],
      [110.3766, -7.6059],
      [110.3763, -7.6065],
      [110.3763, -7.6066],
      [110.3758, -7.6069],
      [110.3754, -7.6072],
      [110.3752, -7.6073],
      [110.3748, -7.6074],
      [110.3747, -7.6075],
      [110.3745, -7.6076],
      [110.3742, -7.6079],
      [110.3741, -7.608],
      [110.3733, -7.6084],
      [110.3731, -7.6085],
      [110.3728, -7.6087],
      [110.3723, -7.6089],
      [110.3719, -7.6093],
      [110.3714, -7.6094],
      [110.371, -7.6096],
      [110.3707, -7.6097],
      [110.3704, -7.6099],
      [110.3701, -7.6103],
      [110.3697, -7.6107],
      [110.3687, -7.6112],
      [110.3682, -7.6115],
      [110.3676, -7.6118],
      [110.3674, -7.6119],
      [110.3672, -7.6121],
      [110.3671, -7.6122],
      [110.3669, -7.6123],
      [110.3662, -7.6127],
      [110.3656, -7.6134],
      [110.3652, -7.614],
      [110.3649, -7.6146],
      [110.3646, -7.6152],
      [110.3639, -7.6158],
      [110.3633, -7.6163],
      [110.3631, -7.6165],
      [110.3625, -7.6168],
      [110.362, -7.6169],
      [110.3623, -7.617],
      [110.3627, -7.6171],
      [110.3628, -7.6171],
      [110.3637, -7.6173],
      [110.3644, -7.6175],
      [110.365, -7.6182],
      [110.3656, -7.619],
      [110.3659, -7.6198],
      [110.3664, -7.6195],
      [110.3674, -7.6189],
      [110.3677, -7.6189],
      [110.3683, -7.619],
      [110.3688, -7.6191],
      [110.3697, -7.6191],
      [110.37, -7.6192],
      [110.3704, -7.6195],
      [110.3712, -7.6202],
      [110.3722, -7.6211],
      [110.373, -7.6216],
      [110.3726, -7.6217],
      [110.3725, -7.622],
      [110.3724, -7.6224],
      [110.3722, -7.6227],
      [110.3722, -7.6235],
      [110.3722, -7.6241],
      [110.372, -7.6242],
      [110.371, -7.6242],
      [110.3708, -7.6243],
      [110.3707, -7.6248],
      [110.3704, -7.625],
      [110.3703, -7.6252],
      [110.3701, -7.6257],
      [110.3697, -7.626],
      [110.3693, -7.6263],
      [110.369, -7.6267],
      [110.3686, -7.6269],
      [110.3684, -7.6273],
      [110.3681, -7.6274],
      [110.3679, -7.628],
      [110.3672, -7.6283],
      [110.3666, -7.6287],
      [110.3662, -7.6292],
      [110.3661, -7.6299],
      [110.366, -7.6304],
      [110.3658, -7.6306],
      [110.3658, -7.6312],
      [110.3656, -7.6317],
      [110.3658, -7.6324],
      [110.3659, -7.6335],
      [110.3656, -7.634],
      [110.3651, -7.6348],
      [110.3651, -7.6354],
      [110.365, -7.6359],
      [110.3646, -7.6361],
      [110.3644, -7.6366],
      [110.3646, -7.6371],
      [110.3647, -7.6374],
      [110.3646, -7.6378],
      [110.3644, -7.6382],
      [110.3643, -7.639],
      [110.3638, -7.6397],
      [110.3636, -7.6407],
      [110.3633, -7.6424],
      [110.3629, -7.6435],
      [110.3626, -7.6446],
      [110.3626, -7.6451],
      [110.3628, -7.6452],
      [110.3631, -7.6451],
      [110.3636, -7.6447],
      [110.3638, -7.6444],
      [110.3642, -7.6445],
      [110.3646, -7.6446],
      [110.3649, -7.6447],
      [110.3651, -7.6445],
      [110.3653, -7.6441],
      [110.3658, -7.6441],
      [110.3663, -7.6443],
      [110.3665, -7.6446],
      [110.3665, -7.645],
      [110.3665, -7.6454],
      [110.366, -7.6457],
      [110.3659, -7.6459],
      [110.3659, -7.6466],
      [110.3655, -7.6471],
      [110.3652, -7.6475],
      [110.3651, -7.6486],
      [110.3649, -7.6495],
      [110.365, -7.6498],
      [110.3653, -7.6499],
      [110.3661, -7.6496],
      [110.3667, -7.649],
      [110.3676, -7.6477],
      [110.3685, -7.6459],
      [110.3686, -7.6454],
      [110.369, -7.6446],
      [110.3702, -7.6421],
      [110.3701, -7.6419],
      [110.3702, -7.6415],
      [110.3705, -7.6407],
      [110.3707, -7.6407],
      [110.3711, -7.6411],
      [110.3714, -7.6416],
      [110.3717, -7.6428],
      [110.3714, -7.6437],
      [110.3701, -7.6469],
      [110.3698, -7.6472],
      [110.3699, -7.6477],
      [110.3695, -7.6486],
      [110.3687, -7.6505],
      [110.369, -7.6509],
      [110.3694, -7.6514],
      [110.3697, -7.6518],
      [110.3686, -7.652],
      [110.368, -7.6521],
      [110.3675, -7.6525],
      [110.3673, -7.6532],
      [110.3672, -7.6537],
      [110.3673, -7.6539],
      [110.3676, -7.654],
      [110.3676, -7.6539],
      [110.3679, -7.6536],
      [110.3681, -7.6536],
      [110.3683, -7.6536],
      [110.3683, -7.6541],
      [110.3686, -7.6543],
      [110.3694, -7.6547],
      [110.3701, -7.6547],
      [110.3704, -7.6545],
      [110.3705, -7.6544],
      [110.3708, -7.6543],
      [110.3712, -7.654],
      [110.3713, -7.6537],
      [110.3714, -7.6534],
      [110.3717, -7.6533],
      [110.3718, -7.6531],
      [110.3724, -7.6525],
      [110.3729, -7.6521],
      [110.3735, -7.6517],
      [110.3737, -7.6514],
      [110.3741, -7.6514],
      [110.3747, -7.6511],
      [110.3749, -7.6509],
      [110.3751, -7.6508],
      [110.3756, -7.65],
      [110.3769, -7.6475],
      [110.3783, -7.6451],
      [110.3796, -7.6457],
      [110.3799, -7.6459],
      [110.38, -7.6459]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PAKEM',
    village: 'PURWOBINANGUN',
    border: [
      [110.4088, -7.6274],
      [110.4091, -7.6272],
      [110.4093, -7.6268],
      [110.41, -7.6266],
      [110.4103, -7.6266],
      [110.4106, -7.6265],
      [110.411, -7.6264],
      [110.4115, -7.6264],
      [110.4115, -7.6259],
      [110.4117, -7.6253],
      [110.412, -7.625],
      [110.4121, -7.6248],
      [110.4127, -7.6247],
      [110.4132, -7.6246],
      [110.4134, -7.6245],
      [110.4137, -7.6241],
      [110.4141, -7.6239],
      [110.4141, -7.6235],
      [110.4143, -7.623],
      [110.4145, -7.6223],
      [110.4149, -7.6214],
      [110.4148, -7.6203],
      [110.4148, -7.62],
      [110.4148, -7.6197],
      [110.4146, -7.6193],
      [110.4149, -7.619],
      [110.4152, -7.6188],
      [110.4154, -7.6184],
      [110.4157, -7.6182],
      [110.4159, -7.6179],
      [110.4161, -7.6167],
      [110.4155, -7.6162],
      [110.4154, -7.6158],
      [110.4155, -7.6153],
      [110.4158, -7.615],
      [110.416, -7.6145],
      [110.416, -7.6142],
      [110.4158, -7.6135],
      [110.416, -7.6128],
      [110.4162, -7.6124],
      [110.4164, -7.6122],
      [110.4165, -7.6121],
      [110.4165, -7.6116],
      [110.417, -7.6108],
      [110.4171, -7.6103],
      [110.4171, -7.6096],
      [110.4176, -7.6093],
      [110.4176, -7.6075],
      [110.4178, -7.607],
      [110.4177, -7.6068],
      [110.4177, -7.6063],
      [110.4178, -7.6061],
      [110.4179, -7.6056],
      [110.418, -7.6052],
      [110.4182, -7.6045],
      [110.418, -7.6041],
      [110.4179, -7.6038],
      [110.418, -7.6035],
      [110.4184, -7.6032],
      [110.4184, -7.6027],
      [110.4184, -7.602],
      [110.4185, -7.6015],
      [110.4188, -7.601],
      [110.4192, -7.6004],
      [110.4191, -7.6],
      [110.4192, -7.5994],
      [110.4193, -7.5989],
      [110.4195, -7.5988],
      [110.4201, -7.5986],
      [110.4202, -7.5982],
      [110.4205, -7.5982],
      [110.4206, -7.5978],
      [110.4206, -7.5975],
      [110.4208, -7.5974],
      [110.4209, -7.5971],
      [110.4209, -7.5967],
      [110.421, -7.5965],
      [110.4212, -7.5965],
      [110.4217, -7.5968],
      [110.422, -7.5968],
      [110.4222, -7.5967],
      [110.4226, -7.5963],
      [110.4229, -7.596],
      [110.4232, -7.5956],
      [110.4231, -7.5954],
      [110.423, -7.5953],
      [110.4231, -7.5946],
      [110.4233, -7.5937],
      [110.4236, -7.5932],
      [110.4239, -7.5923],
      [110.4243, -7.5909],
      [110.4246, -7.5907],
      [110.4251, -7.5904],
      [110.4252, -7.5901],
      [110.4256, -7.5894],
      [110.4257, -7.5885],
      [110.4258, -7.5881],
      [110.426, -7.5878],
      [110.4263, -7.5876],
      [110.4265, -7.587],
      [110.4264, -7.5868],
      [110.4264, -7.5866],
      [110.4264, -7.5865],
      [110.4265, -7.5862],
      [110.4268, -7.5852],
      [110.4269, -7.584],
      [110.4266, -7.5825],
      [110.4264, -7.5821],
      [110.4258, -7.5809],
      [110.4258, -7.5808],
      [110.4259, -7.5803],
      [110.4259, -7.58],
      [110.4259, -7.5798],
      [110.4259, -7.5796],
      [110.4259, -7.5783],
      [110.4257, -7.577],
      [110.4257, -7.5761],
      [110.4254, -7.5757],
      [110.4245, -7.5752],
      [110.4226, -7.5748],
      [110.4208, -7.5747],
      [110.4189, -7.5747],
      [110.4159, -7.5749],
      [110.4159, -7.5761],
      [110.416, -7.5766],
      [110.4162, -7.578],
      [110.4162, -7.5789],
      [110.4162, -7.5794],
      [110.4161, -7.5803],
      [110.416, -7.5811],
      [110.4161, -7.5812],
      [110.4164, -7.5817],
      [110.4163, -7.582],
      [110.4163, -7.5828],
      [110.4158, -7.5841],
      [110.4152, -7.5851],
      [110.4146, -7.585],
      [110.4143, -7.5854],
      [110.4138, -7.5861],
      [110.4133, -7.5869],
      [110.4132, -7.5875],
      [110.4133, -7.5881],
      [110.4132, -7.5887],
      [110.4133, -7.5897],
      [110.4131, -7.5903],
      [110.4131, -7.591],
      [110.413, -7.5919],
      [110.4125, -7.5938],
      [110.4122, -7.5952],
      [110.4119, -7.5953],
      [110.4114, -7.5952],
      [110.4111, -7.5954],
      [110.4109, -7.596],
      [110.4108, -7.5966],
      [110.4106, -7.5966],
      [110.4101, -7.5969],
      [110.4102, -7.5973],
      [110.4102, -7.5976],
      [110.4097, -7.5979],
      [110.4094, -7.5981],
      [110.4094, -7.5987],
      [110.4091, -7.599],
      [110.409, -7.5994],
      [110.409, -7.6006],
      [110.4092, -7.6013],
      [110.4096, -7.6019],
      [110.4095, -7.6027],
      [110.4094, -7.6033],
      [110.4094, -7.6036],
      [110.4094, -7.6042],
      [110.4092, -7.6044],
      [110.4092, -7.6052],
      [110.4093, -7.6055],
      [110.4091, -7.6059],
      [110.4091, -7.6066],
      [110.4091, -7.6067],
      [110.4091, -7.6068],
      [110.4091, -7.6071],
      [110.4087, -7.6075],
      [110.4086, -7.6076],
      [110.4087, -7.6078],
      [110.4081, -7.6084],
      [110.4081, -7.6088],
      [110.4082, -7.6094],
      [110.4081, -7.6097],
      [110.4079, -7.6102],
      [110.4076, -7.6104],
      [110.4076, -7.6108],
      [110.4073, -7.611],
      [110.407, -7.6113],
      [110.4068, -7.612],
      [110.4066, -7.6122],
      [110.4065, -7.6126],
      [110.4064, -7.6132],
      [110.4061, -7.6136],
      [110.4059, -7.614],
      [110.4056, -7.6147],
      [110.4055, -7.6155],
      [110.4051, -7.6157],
      [110.4049, -7.6159],
      [110.4047, -7.6164],
      [110.4043, -7.6168],
      [110.4039, -7.6175],
      [110.4035, -7.618],
      [110.4033, -7.6186],
      [110.4031, -7.6194],
      [110.4044, -7.6199],
      [110.4048, -7.6203],
      [110.4045, -7.6212],
      [110.4042, -7.6216],
      [110.4038, -7.6221],
      [110.4037, -7.6225],
      [110.4038, -7.6228],
      [110.4038, -7.623],
      [110.4038, -7.6232],
      [110.4036, -7.6241],
      [110.4033, -7.6245],
      [110.4031, -7.6251],
      [110.4024, -7.6263],
      [110.4018, -7.6273],
      [110.401, -7.6276],
      [110.4002, -7.6289],
      [110.3998, -7.6294],
      [110.3997, -7.6299],
      [110.3989, -7.632],
      [110.3981, -7.6342],
      [110.3978, -7.6349],
      [110.398, -7.6354],
      [110.3983, -7.6358],
      [110.3983, -7.636],
      [110.3983, -7.6363],
      [110.3982, -7.6364],
      [110.3977, -7.6366],
      [110.3973, -7.6369],
      [110.3973, -7.638],
      [110.397, -7.6382],
      [110.3965, -7.6392],
      [110.3961, -7.64],
      [110.3954, -7.6409],
      [110.395, -7.6414],
      [110.3943, -7.6418],
      [110.394, -7.6422],
      [110.3936, -7.6422],
      [110.3932, -7.6425],
      [110.3927, -7.6428],
      [110.3925, -7.6432],
      [110.3924, -7.6441],
      [110.3923, -7.6446],
      [110.3921, -7.6451],
      [110.3918, -7.6458],
      [110.3915, -7.6466],
      [110.3912, -7.6473],
      [110.3913, -7.6478],
      [110.3913, -7.6479],
      [110.3914, -7.6485],
      [110.391, -7.6489],
      [110.3908, -7.6496],
      [110.3909, -7.6506],
      [110.39, -7.6515],
      [110.3892, -7.6522],
      [110.3885, -7.653],
      [110.3878, -7.6533],
      [110.3873, -7.654],
      [110.387, -7.6547],
      [110.3867, -7.6556],
      [110.3862, -7.6561],
      [110.3859, -7.6567],
      [110.3854, -7.6571],
      [110.3849, -7.6577],
      [110.3848, -7.6579],
      [110.3844, -7.6583],
      [110.3841, -7.6587],
      [110.3836, -7.659],
      [110.3833, -7.6593],
      [110.383, -7.6597],
      [110.3832, -7.6599],
      [110.3834, -7.6602],
      [110.383, -7.6603],
      [110.3826, -7.6606],
      [110.3825, -7.6612],
      [110.3825, -7.6616],
      [110.3822, -7.6619],
      [110.3819, -7.6622],
      [110.3816, -7.6629],
      [110.3813, -7.6636],
      [110.381, -7.6642],
      [110.3808, -7.6646],
      [110.3804, -7.6648],
      [110.3801, -7.6648],
      [110.38, -7.6652],
      [110.3796, -7.6653],
      [110.3792, -7.6654],
      [110.3791, -7.6655],
      [110.379, -7.6658],
      [110.3787, -7.6661],
      [110.3785, -7.6664],
      [110.3783, -7.6673],
      [110.3782, -7.6681],
      [110.3775, -7.6703],
      [110.3773, -7.6719],
      [110.3773, -7.6723],
      [110.3784, -7.6727],
      [110.3788, -7.6728],
      [110.3808, -7.6736],
      [110.3813, -7.6732],
      [110.3816, -7.6728],
      [110.3821, -7.6727],
      [110.3823, -7.6728],
      [110.3826, -7.673],
      [110.3833, -7.6734],
      [110.384, -7.675],
      [110.3845, -7.6751],
      [110.3863, -7.6755],
      [110.3879, -7.6759],
      [110.3885, -7.676],
      [110.3891, -7.6758],
      [110.391, -7.6749],
      [110.3918, -7.6745],
      [110.3927, -7.6747],
      [110.3932, -7.6749],
      [110.3944, -7.6752],
      [110.3947, -7.674],
      [110.3951, -7.6728],
      [110.3951, -7.6721],
      [110.3952, -7.6716],
      [110.3955, -7.6704],
      [110.3953, -7.6696],
      [110.3956, -7.6685],
      [110.3955, -7.6674],
      [110.3955, -7.6663],
      [110.3957, -7.6656],
      [110.396, -7.6651],
      [110.396, -7.6647],
      [110.3958, -7.6638],
      [110.3961, -7.6634],
      [110.3961, -7.6627],
      [110.3962, -7.6622],
      [110.396, -7.6618],
      [110.3962, -7.6615],
      [110.3963, -7.6612],
      [110.3962, -7.6609],
      [110.3963, -7.6606],
      [110.3964, -7.6599],
      [110.3964, -7.6596],
      [110.3964, -7.6589],
      [110.3967, -7.6588],
      [110.3969, -7.6583],
      [110.3972, -7.6579],
      [110.3972, -7.6572],
      [110.3974, -7.6567],
      [110.3977, -7.6565],
      [110.3978, -7.6562],
      [110.3978, -7.6557],
      [110.398, -7.6552],
      [110.3982, -7.6549],
      [110.3985, -7.6548],
      [110.3988, -7.6545],
      [110.3989, -7.654],
      [110.3991, -7.6538],
      [110.3992, -7.6536],
      [110.3991, -7.6535],
      [110.399, -7.6532],
      [110.3992, -7.6522],
      [110.3992, -7.6517],
      [110.3991, -7.6515],
      [110.3992, -7.6509],
      [110.3991, -7.6506],
      [110.399, -7.6503],
      [110.3993, -7.6498],
      [110.3995, -7.6495],
      [110.3995, -7.6492],
      [110.3992, -7.6488],
      [110.3993, -7.648],
      [110.3991, -7.6472],
      [110.3991, -7.6471],
      [110.3995, -7.6467],
      [110.3995, -7.6463],
      [110.4, -7.6461],
      [110.4, -7.6457],
      [110.3998, -7.645],
      [110.3999, -7.6444],
      [110.4001, -7.6439],
      [110.4006, -7.6438],
      [110.401, -7.6433],
      [110.4013, -7.6428],
      [110.4013, -7.6424],
      [110.4015, -7.642],
      [110.4017, -7.6416],
      [110.4016, -7.6412],
      [110.4014, -7.6409],
      [110.4014, -7.6406],
      [110.4016, -7.6401],
      [110.4015, -7.6399],
      [110.402, -7.6391],
      [110.4026, -7.6389],
      [110.4031, -7.6389],
      [110.4032, -7.6389],
      [110.4036, -7.6386],
      [110.4044, -7.6382],
      [110.4045, -7.6377],
      [110.4045, -7.6371],
      [110.4046, -7.6364],
      [110.4047, -7.6361],
      [110.4049, -7.6352],
      [110.4052, -7.6345],
      [110.4055, -7.6341],
      [110.4056, -7.6333],
      [110.406, -7.6328],
      [110.4062, -7.6323],
      [110.4066, -7.6317],
      [110.4066, -7.6315],
      [110.4066, -7.6313],
      [110.4066, -7.6309],
      [110.4067, -7.6305],
      [110.4068, -7.6298],
      [110.407, -7.6295],
      [110.407, -7.6293],
      [110.4071, -7.6289],
      [110.4075, -7.6283],
      [110.408, -7.628],
      [110.4086, -7.6275],
      [110.4088, -7.6274]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PAKEM',
    village: 'CANDIBINANGUN',
    border: [
      [110.4105, -7.6432],
      [110.4105, -7.6431],
      [110.4103, -7.643],
      [110.4102, -7.6428],
      [110.4099, -7.6423],
      [110.41, -7.6419],
      [110.4101, -7.6416],
      [110.4108, -7.6414],
      [110.4111, -7.6413],
      [110.4118, -7.641],
      [110.4126, -7.6408],
      [110.4126, -7.6402],
      [110.4131, -7.6398],
      [110.4129, -7.6398],
      [110.4127, -7.6396],
      [110.4127, -7.6387],
      [110.4126, -7.6379],
      [110.4128, -7.637],
      [110.413, -7.6362],
      [110.4132, -7.6359],
      [110.4135, -7.6356],
      [110.4136, -7.6352],
      [110.4137, -7.6349],
      [110.4138, -7.6348],
      [110.4136, -7.6343],
      [110.4136, -7.6336],
      [110.4135, -7.6334],
      [110.4134, -7.6332],
      [110.4133, -7.6327],
      [110.4133, -7.6322],
      [110.4134, -7.6318],
      [110.4134, -7.6314],
      [110.4136, -7.631],
      [110.4139, -7.6303],
      [110.4142, -7.6298],
      [110.4144, -7.6293],
      [110.4143, -7.6292],
      [110.414, -7.6288],
      [110.4139, -7.6288],
      [110.4134, -7.6285],
      [110.4131, -7.6284],
      [110.4129, -7.6284],
      [110.4121, -7.628],
      [110.4114, -7.6279],
      [110.4111, -7.6279],
      [110.4107, -7.6281],
      [110.4104, -7.6281],
      [110.41, -7.6279],
      [110.4096, -7.6277],
      [110.4094, -7.6276],
      [110.409, -7.6275],
      [110.4088, -7.6274],
      [110.4086, -7.6275],
      [110.408, -7.628],
      [110.4075, -7.6283],
      [110.4071, -7.6289],
      [110.407, -7.6293],
      [110.407, -7.6295],
      [110.4068, -7.6298],
      [110.4067, -7.6305],
      [110.4066, -7.6309],
      [110.4066, -7.6313],
      [110.4066, -7.6315],
      [110.4066, -7.6317],
      [110.4062, -7.6323],
      [110.406, -7.6328],
      [110.4056, -7.6333],
      [110.4055, -7.6341],
      [110.4052, -7.6345],
      [110.4049, -7.6352],
      [110.4047, -7.6361],
      [110.4046, -7.6364],
      [110.4045, -7.6371],
      [110.4045, -7.6377],
      [110.4044, -7.6382],
      [110.4036, -7.6386],
      [110.4032, -7.6389],
      [110.4031, -7.6389],
      [110.4026, -7.6389],
      [110.402, -7.6391],
      [110.4015, -7.6399],
      [110.4016, -7.6401],
      [110.4014, -7.6406],
      [110.4014, -7.6409],
      [110.4016, -7.6412],
      [110.4017, -7.6416],
      [110.4015, -7.642],
      [110.4013, -7.6424],
      [110.4013, -7.6428],
      [110.401, -7.6433],
      [110.4006, -7.6438],
      [110.4001, -7.6439],
      [110.3999, -7.6444],
      [110.3998, -7.645],
      [110.4, -7.6457],
      [110.4, -7.6461],
      [110.3995, -7.6463],
      [110.3995, -7.6467],
      [110.3991, -7.6471],
      [110.3991, -7.6472],
      [110.3993, -7.648],
      [110.3992, -7.6488],
      [110.3995, -7.6492],
      [110.3995, -7.6495],
      [110.3993, -7.6498],
      [110.399, -7.6503],
      [110.3991, -7.6506],
      [110.3992, -7.6509],
      [110.3991, -7.6515],
      [110.3992, -7.6517],
      [110.3992, -7.6522],
      [110.399, -7.6532],
      [110.3991, -7.6535],
      [110.3992, -7.6536],
      [110.3991, -7.6538],
      [110.3989, -7.654],
      [110.3988, -7.6545],
      [110.3985, -7.6548],
      [110.3982, -7.6549],
      [110.398, -7.6552],
      [110.3978, -7.6557],
      [110.3978, -7.6562],
      [110.3977, -7.6565],
      [110.3974, -7.6567],
      [110.3972, -7.6572],
      [110.3972, -7.6579],
      [110.3969, -7.6583],
      [110.3967, -7.6588],
      [110.3964, -7.6589],
      [110.3964, -7.6596],
      [110.3964, -7.6599],
      [110.3963, -7.6606],
      [110.3962, -7.6609],
      [110.3963, -7.6612],
      [110.3962, -7.6615],
      [110.396, -7.6618],
      [110.3962, -7.6622],
      [110.3961, -7.6627],
      [110.3961, -7.6634],
      [110.3958, -7.6638],
      [110.396, -7.6647],
      [110.396, -7.6651],
      [110.3957, -7.6656],
      [110.3955, -7.6663],
      [110.3955, -7.6674],
      [110.3956, -7.6685],
      [110.3953, -7.6696],
      [110.3955, -7.6704],
      [110.3952, -7.6716],
      [110.3951, -7.6721],
      [110.3951, -7.6728],
      [110.3947, -7.674],
      [110.3944, -7.6752],
      [110.3942, -7.6757],
      [110.3942, -7.6762],
      [110.3943, -7.6765],
      [110.3943, -7.6772],
      [110.3942, -7.6782],
      [110.3941, -7.6783],
      [110.3938, -7.6788],
      [110.3937, -7.6791],
      [110.3935, -7.6794],
      [110.3937, -7.6801],
      [110.3939, -7.6816],
      [110.3939, -7.6818],
      [110.3939, -7.682],
      [110.3939, -7.6824],
      [110.3937, -7.6827],
      [110.3936, -7.6831],
      [110.3936, -7.6835],
      [110.3937, -7.6835],
      [110.3939, -7.6836],
      [110.3953, -7.6843],
      [110.395, -7.6849],
      [110.3951, -7.6857],
      [110.3951, -7.6863],
      [110.3953, -7.6867],
      [110.3955, -7.6873],
      [110.3956, -7.6873],
      [110.397, -7.6868],
      [110.3972, -7.6868],
      [110.3977, -7.6869],
      [110.3981, -7.6869],
      [110.3989, -7.687],
      [110.3995, -7.6871],
      [110.401, -7.6872],
      [110.4013, -7.6872],
      [110.4017, -7.6872],
      [110.4017, -7.6862],
      [110.4025, -7.6863],
      [110.404, -7.6872],
      [110.4042, -7.6871],
      [110.4043, -7.6871],
      [110.4043, -7.6865],
      [110.4041, -7.6859],
      [110.4042, -7.6856],
      [110.4044, -7.6853],
      [110.4042, -7.6845],
      [110.4041, -7.6841],
      [110.4042, -7.6836],
      [110.4042, -7.6835],
      [110.4041, -7.6833],
      [110.404, -7.6827],
      [110.404, -7.6823],
      [110.4041, -7.6816],
      [110.404, -7.681],
      [110.4038, -7.6804],
      [110.4037, -7.6797],
      [110.4039, -7.6791],
      [110.4037, -7.6786],
      [110.4038, -7.6783],
      [110.404, -7.6775],
      [110.4045, -7.6769],
      [110.4044, -7.6761],
      [110.4045, -7.6753],
      [110.4046, -7.6748],
      [110.4043, -7.674],
      [110.4035, -7.6726],
      [110.4033, -7.6721],
      [110.4035, -7.6716],
      [110.4034, -7.6711],
      [110.4037, -7.6699],
      [110.4039, -7.6689],
      [110.4038, -7.6685],
      [110.4038, -7.6684],
      [110.4038, -7.668],
      [110.4042, -7.6675],
      [110.4046, -7.667],
      [110.4045, -7.6664],
      [110.4045, -7.6658],
      [110.4048, -7.6655],
      [110.4051, -7.6649],
      [110.4052, -7.6642],
      [110.4056, -7.6635],
      [110.4059, -7.663],
      [110.4062, -7.6622],
      [110.4062, -7.662],
      [110.4062, -7.6617],
      [110.4062, -7.6614],
      [110.4062, -7.6612],
      [110.4061, -7.6609],
      [110.4056, -7.6604],
      [110.4056, -7.6601],
      [110.4057, -7.6597],
      [110.4059, -7.6594],
      [110.4059, -7.6588],
      [110.406, -7.6581],
      [110.4061, -7.6576],
      [110.4066, -7.657],
      [110.407, -7.6564],
      [110.4076, -7.6559],
      [110.4078, -7.6552],
      [110.4082, -7.6546],
      [110.4084, -7.6535],
      [110.4084, -7.6531],
      [110.4089, -7.6521],
      [110.4093, -7.6521],
      [110.4102, -7.6511],
      [110.4103, -7.6499],
      [110.4102, -7.6498],
      [110.4101, -7.6497],
      [110.41, -7.6496],
      [110.4097, -7.6494],
      [110.4096, -7.6493],
      [110.409, -7.6483],
      [110.4091, -7.6479],
      [110.4091, -7.6476],
      [110.4096, -7.6468],
      [110.41, -7.6462],
      [110.4103, -7.6456],
      [110.4105, -7.6451],
      [110.4106, -7.6447],
      [110.4104, -7.644],
      [110.4105, -7.6432]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PAKEM',
    village: 'HARJOBINANGUN',
    border: [
      [110.4176, -7.6497],
      [110.4176, -7.6495],
      [110.4175, -7.6491],
      [110.4178, -7.649],
      [110.4183, -7.649],
      [110.4184, -7.6485],
      [110.4183, -7.6482],
      [110.4182, -7.6478],
      [110.4187, -7.6479],
      [110.419, -7.6478],
      [110.4192, -7.6473],
      [110.4194, -7.6464],
      [110.4194, -7.6456],
      [110.4193, -7.6456],
      [110.4189, -7.6455],
      [110.4176, -7.6455],
      [110.4174, -7.6454],
      [110.4174, -7.6448],
      [110.417, -7.6447],
      [110.4148, -7.6442],
      [110.4142, -7.6441],
      [110.4127, -7.6437],
      [110.411, -7.6432],
      [110.4105, -7.6432],
      [110.4104, -7.644],
      [110.4106, -7.6447],
      [110.4105, -7.6451],
      [110.4103, -7.6456],
      [110.41, -7.6462],
      [110.4096, -7.6468],
      [110.4091, -7.6476],
      [110.4091, -7.6479],
      [110.409, -7.6483],
      [110.4096, -7.6493],
      [110.4097, -7.6494],
      [110.41, -7.6496],
      [110.4101, -7.6497],
      [110.4102, -7.6498],
      [110.4103, -7.6499],
      [110.4102, -7.6511],
      [110.4093, -7.6521],
      [110.4089, -7.6521],
      [110.4084, -7.6531],
      [110.4084, -7.6535],
      [110.4082, -7.6546],
      [110.4078, -7.6552],
      [110.4076, -7.6559],
      [110.407, -7.6564],
      [110.4066, -7.657],
      [110.4061, -7.6576],
      [110.406, -7.6581],
      [110.4059, -7.6588],
      [110.4059, -7.6594],
      [110.4057, -7.6597],
      [110.4056, -7.6601],
      [110.4056, -7.6604],
      [110.4061, -7.6609],
      [110.4062, -7.6612],
      [110.4062, -7.6614],
      [110.4062, -7.6617],
      [110.4062, -7.662],
      [110.4062, -7.6622],
      [110.4059, -7.663],
      [110.4056, -7.6635],
      [110.4052, -7.6642],
      [110.4051, -7.6649],
      [110.4048, -7.6655],
      [110.4045, -7.6658],
      [110.4045, -7.6664],
      [110.4046, -7.667],
      [110.4042, -7.6675],
      [110.4038, -7.668],
      [110.4038, -7.6684],
      [110.4038, -7.6685],
      [110.4039, -7.6689],
      [110.4037, -7.6699],
      [110.4034, -7.6711],
      [110.4035, -7.6716],
      [110.4033, -7.6721],
      [110.4035, -7.6726],
      [110.4043, -7.674],
      [110.4046, -7.6748],
      [110.4045, -7.6753],
      [110.4044, -7.6761],
      [110.4045, -7.6769],
      [110.404, -7.6775],
      [110.4038, -7.6783],
      [110.4037, -7.6786],
      [110.4039, -7.6791],
      [110.4037, -7.6797],
      [110.4038, -7.6804],
      [110.404, -7.681],
      [110.4041, -7.6816],
      [110.404, -7.6823],
      [110.404, -7.6827],
      [110.4041, -7.6833],
      [110.4042, -7.6835],
      [110.4042, -7.6836],
      [110.4041, -7.6841],
      [110.4042, -7.6845],
      [110.4044, -7.6853],
      [110.4042, -7.6856],
      [110.4041, -7.6859],
      [110.4043, -7.6865],
      [110.4043, -7.6871],
      [110.4042, -7.6871],
      [110.404, -7.6872],
      [110.4042, -7.6876],
      [110.4046, -7.6884],
      [110.4042, -7.6885],
      [110.4034, -7.6892],
      [110.4033, -7.6896],
      [110.403, -7.6907],
      [110.4027, -7.6909],
      [110.4026, -7.691],
      [110.4018, -7.6912],
      [110.4018, -7.6914],
      [110.4017, -7.6916],
      [110.4016, -7.6919],
      [110.4016, -7.6924],
      [110.4013, -7.6932],
      [110.4012, -7.6936],
      [110.4012, -7.6939],
      [110.401, -7.6939],
      [110.4009, -7.6939],
      [110.4005, -7.6946],
      [110.4004, -7.695],
      [110.4004, -7.6953],
      [110.4004, -7.6954],
      [110.4004, -7.6956],
      [110.4003, -7.6967],
      [110.4, -7.6976],
      [110.3993, -7.6982],
      [110.3985, -7.6988],
      [110.3978, -7.6992],
      [110.3974, -7.6997],
      [110.3971, -7.7002],
      [110.3972, -7.7003],
      [110.3976, -7.7008],
      [110.398, -7.7009],
      [110.3983, -7.7011],
      [110.3985, -7.7014],
      [110.3986, -7.7018],
      [110.3997, -7.7026],
      [110.4, -7.7023],
      [110.4002, -7.7022],
      [110.4005, -7.7016],
      [110.4007, -7.7014],
      [110.4009, -7.7012],
      [110.4012, -7.7008],
      [110.4012, -7.7005],
      [110.4013, -7.7],
      [110.4017, -7.6998],
      [110.4019, -7.6995],
      [110.4022, -7.6992],
      [110.4027, -7.6989],
      [110.4027, -7.6984],
      [110.4031, -7.6981],
      [110.4034, -7.6978],
      [110.4033, -7.6974],
      [110.4033, -7.6971],
      [110.4036, -7.6971],
      [110.4038, -7.6969],
      [110.4038, -7.6965],
      [110.4037, -7.6961],
      [110.404, -7.6954],
      [110.4043, -7.6945],
      [110.4043, -7.6933],
      [110.4044, -7.6932],
      [110.4046, -7.6925],
      [110.4046, -7.692],
      [110.4051, -7.6918],
      [110.4052, -7.6912],
      [110.4054, -7.691],
      [110.4054, -7.6905],
      [110.4058, -7.6902],
      [110.4066, -7.6897],
      [110.4067, -7.6892],
      [110.4066, -7.6888],
      [110.4071, -7.6886],
      [110.4074, -7.6883],
      [110.4073, -7.688],
      [110.4073, -7.6877],
      [110.4076, -7.6875],
      [110.408, -7.6874],
      [110.4084, -7.6872],
      [110.4084, -7.687],
      [110.4084, -7.6867],
      [110.4085, -7.6862],
      [110.4088, -7.6855],
      [110.4089, -7.6852],
      [110.4095, -7.6851],
      [110.4097, -7.685],
      [110.4099, -7.6846],
      [110.41, -7.684],
      [110.4101, -7.6838],
      [110.4102, -7.6835],
      [110.4105, -7.6833],
      [110.4106, -7.6832],
      [110.4105, -7.6828],
      [110.4105, -7.6826],
      [110.4107, -7.6822],
      [110.4114, -7.6817],
      [110.4117, -7.6812],
      [110.4121, -7.6805],
      [110.4125, -7.6798],
      [110.4134, -7.6794],
      [110.4143, -7.6791],
      [110.4147, -7.6788],
      [110.415, -7.6783],
      [110.4151, -7.6781],
      [110.4152, -7.6776],
      [110.4152, -7.6767],
      [110.4152, -7.6765],
      [110.4151, -7.6762],
      [110.4153, -7.6759],
      [110.4156, -7.6755],
      [110.4159, -7.675],
      [110.4162, -7.674],
      [110.4166, -7.6734],
      [110.4167, -7.6725],
      [110.4168, -7.6718],
      [110.417, -7.6708],
      [110.4171, -7.6704],
      [110.4169, -7.6699],
      [110.4169, -7.6693],
      [110.4167, -7.6688],
      [110.4167, -7.6684],
      [110.4168, -7.6681],
      [110.417, -7.6678],
      [110.4174, -7.6674],
      [110.4174, -7.666],
      [110.4174, -7.665],
      [110.416, -7.6645],
      [110.4157, -7.6643],
      [110.4149, -7.664],
      [110.4138, -7.6633],
      [110.4138, -7.6629],
      [110.4142, -7.6626],
      [110.4142, -7.6624],
      [110.4142, -7.6621],
      [110.4145, -7.6617],
      [110.4145, -7.6609],
      [110.4145, -7.6603],
      [110.4146, -7.6597],
      [110.4149, -7.6595],
      [110.4152, -7.6593],
      [110.4153, -7.6589],
      [110.4152, -7.658],
      [110.4155, -7.6579],
      [110.4157, -7.6576],
      [110.4154, -7.6572],
      [110.4155, -7.6566],
      [110.4159, -7.6554],
      [110.4159, -7.6548],
      [110.4157, -7.6545],
      [110.4154, -7.6541],
      [110.4154, -7.6539],
      [110.4157, -7.6534],
      [110.4161, -7.6524],
      [110.4167, -7.6513],
      [110.4167, -7.651],
      [110.4166, -7.6507],
      [110.4168, -7.6506],
      [110.4167, -7.6503],
      [110.4167, -7.65],
      [110.4172, -7.6498],
      [110.4176, -7.6497]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PAKEM',
    village: 'PAKEMBINANGUN',
    border: [
      [110.4285, -7.644],
      [110.4276, -7.644],
      [110.4274, -7.6439],
      [110.4249, -7.6438],
      [110.4235, -7.6433],
      [110.4221, -7.6427],
      [110.4218, -7.6433],
      [110.4215, -7.6438],
      [110.4213, -7.6448],
      [110.4215, -7.6448],
      [110.4224, -7.645],
      [110.4232, -7.6451],
      [110.4232, -7.6455],
      [110.4231, -7.646],
      [110.4227, -7.6472],
      [110.4222, -7.6483],
      [110.4218, -7.6488],
      [110.4217, -7.6491],
      [110.4214, -7.6494],
      [110.4208, -7.6496],
      [110.4205, -7.6497],
      [110.4202, -7.6499],
      [110.4201, -7.6499],
      [110.4198, -7.6499],
      [110.4188, -7.6498],
      [110.4179, -7.6498],
      [110.4176, -7.6497],
      [110.4172, -7.6498],
      [110.4167, -7.65],
      [110.4167, -7.6503],
      [110.4168, -7.6506],
      [110.4166, -7.6507],
      [110.4167, -7.651],
      [110.4167, -7.6513],
      [110.4161, -7.6524],
      [110.4157, -7.6534],
      [110.4154, -7.6539],
      [110.4154, -7.6541],
      [110.4157, -7.6545],
      [110.4159, -7.6548],
      [110.4159, -7.6554],
      [110.4155, -7.6566],
      [110.4154, -7.6572],
      [110.4157, -7.6576],
      [110.4155, -7.6579],
      [110.4152, -7.658],
      [110.4153, -7.6589],
      [110.4152, -7.6593],
      [110.4149, -7.6595],
      [110.4146, -7.6597],
      [110.4145, -7.6603],
      [110.4145, -7.6609],
      [110.4145, -7.6617],
      [110.4142, -7.6621],
      [110.4142, -7.6624],
      [110.4142, -7.6626],
      [110.4138, -7.6629],
      [110.4138, -7.6633],
      [110.4149, -7.664],
      [110.4157, -7.6643],
      [110.416, -7.6645],
      [110.4174, -7.665],
      [110.4174, -7.666],
      [110.4174, -7.6674],
      [110.417, -7.6678],
      [110.4168, -7.6681],
      [110.4167, -7.6684],
      [110.4175, -7.6686],
      [110.4176, -7.669],
      [110.4174, -7.6702],
      [110.4175, -7.6708],
      [110.4188, -7.6708],
      [110.419, -7.6708],
      [110.4204, -7.6708],
      [110.4208, -7.6714],
      [110.4213, -7.6719],
      [110.4215, -7.6721],
      [110.4217, -7.6721],
      [110.422, -7.6715],
      [110.4222, -7.6709],
      [110.4228, -7.671],
      [110.4237, -7.6715],
      [110.4249, -7.6718],
      [110.4252, -7.6718],
      [110.4258, -7.6719],
      [110.4263, -7.6726],
      [110.4268, -7.6733],
      [110.4271, -7.6733],
      [110.4274, -7.6729],
      [110.4276, -7.6719],
      [110.4279, -7.6707],
      [110.428, -7.6698],
      [110.4282, -7.6689],
      [110.4284, -7.668],
      [110.4299, -7.668],
      [110.4305, -7.668],
      [110.4307, -7.6674],
      [110.4308, -7.6667],
      [110.4305, -7.6663],
      [110.4305, -7.6656],
      [110.4308, -7.6652],
      [110.4313, -7.6649],
      [110.432, -7.6645],
      [110.4321, -7.6644],
      [110.4322, -7.664],
      [110.4321, -7.6634],
      [110.4319, -7.6627],
      [110.4316, -7.6624],
      [110.4311, -7.6621],
      [110.4307, -7.6618],
      [110.4303, -7.6612],
      [110.4301, -7.6608],
      [110.4297, -7.6597],
      [110.4297, -7.6592],
      [110.4298, -7.6585],
      [110.4299, -7.6582],
      [110.4296, -7.658],
      [110.4296, -7.6577],
      [110.4297, -7.6571],
      [110.4302, -7.6564],
      [110.4305, -7.6557],
      [110.4305, -7.6546],
      [110.4307, -7.6537],
      [110.431, -7.6527],
      [110.4309, -7.6524],
      [110.4307, -7.6517],
      [110.4306, -7.6516],
      [110.4303, -7.6514],
      [110.4299, -7.6505],
      [110.4299, -7.6504],
      [110.4296, -7.6497],
      [110.4295, -7.6495],
      [110.4294, -7.6493],
      [110.4293, -7.6492],
      [110.4287, -7.6487],
      [110.4283, -7.6475],
      [110.4281, -7.6469],
      [110.428, -7.6463],
      [110.4281, -7.6456],
      [110.428, -7.6453],
      [110.428, -7.6449],
      [110.4283, -7.6444],
      [110.4285, -7.644]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'PAKEM',
    village: 'HARGOBINANGUN',
    border: [
      [110.4585, -7.5675],
      [110.4582, -7.5671],
      [110.458, -7.5669],
      [110.4578, -7.5666],
      [110.4574, -7.5659],
      [110.4572, -7.5655],
      [110.4569, -7.5645],
      [110.4569, -7.5643],
      [110.4565, -7.5632],
      [110.4563, -7.5622],
      [110.4561, -7.5613],
      [110.4561, -7.561],
      [110.456, -7.5604],
      [110.4561, -7.5598],
      [110.4561, -7.559],
      [110.4562, -7.5583],
      [110.4562, -7.5582],
      [110.4561, -7.5575],
      [110.4561, -7.5573],
      [110.4561, -7.557],
      [110.456, -7.5564],
      [110.4559, -7.556],
      [110.4558, -7.5558],
      [110.4555, -7.5549],
      [110.4554, -7.5545],
      [110.455, -7.5538],
      [110.4549, -7.5537],
      [110.4548, -7.5534],
      [110.4547, -7.5532],
      [110.4545, -7.5531],
      [110.4542, -7.5527],
      [110.4541, -7.5525],
      [110.4528, -7.5513],
      [110.4519, -7.5506],
      [110.4516, -7.5503],
      [110.4514, -7.5502],
      [110.4504, -7.5495],
      [110.4504, -7.5494],
      [110.4493, -7.5484],
      [110.4487, -7.5478],
      [110.4484, -7.5474],
      [110.448, -7.5462],
      [110.4468, -7.5434],
      [110.4461, -7.542],
      [110.4459, -7.5422],
      [110.4453, -7.5432],
      [110.4452, -7.5434],
      [110.4444, -7.544],
      [110.444, -7.5444],
      [110.4407, -7.5464],
      [110.4381, -7.5477],
      [110.4318, -7.5503],
      [110.4317, -7.5503],
      [110.4315, -7.5505],
      [110.4314, -7.5506],
      [110.4312, -7.5507],
      [110.431, -7.5508],
      [110.4308, -7.5509],
      [110.4307, -7.5509],
      [110.4305, -7.5511],
      [110.4304, -7.5512],
      [110.4303, -7.5513],
      [110.4301, -7.5514],
      [110.43, -7.5515],
      [110.4299, -7.5516],
      [110.4297, -7.5517],
      [110.4296, -7.5517],
      [110.4294, -7.5518],
      [110.4293, -7.5518],
      [110.4292, -7.5519],
      [110.429, -7.552],
      [110.4288, -7.5521],
      [110.4287, -7.5522],
      [110.4286, -7.5523],
      [110.4284, -7.5525],
      [110.4281, -7.5527],
      [110.428, -7.5528],
      [110.4279, -7.553],
      [110.4278, -7.5531],
      [110.4277, -7.5533],
      [110.4262, -7.5541],
      [110.4256, -7.5546],
      [110.4239, -7.5557],
      [110.4223, -7.5571],
      [110.4219, -7.5573],
      [110.421, -7.558],
      [110.4197, -7.5591],
      [110.4192, -7.5595],
      [110.4177, -7.5608],
      [110.4165, -7.562],
      [110.4153, -7.563],
      [110.4149, -7.5635],
      [110.4144, -7.564],
      [110.4141, -7.5645],
      [110.4138, -7.5649],
      [110.4137, -7.565],
      [110.4136, -7.5652],
      [110.4135, -7.5653],
      [110.4134, -7.5654],
      [110.4135, -7.5656],
      [110.4137, -7.5664],
      [110.4136, -7.5671],
      [110.4141, -7.5681],
      [110.4145, -7.5687],
      [110.4152, -7.5701],
      [110.4152, -7.571],
      [110.4153, -7.5713],
      [110.4153, -7.5722],
      [110.4156, -7.5737],
      [110.4159, -7.5749],
      [110.4189, -7.5747],
      [110.4208, -7.5747],
      [110.4226, -7.5748],
      [110.4245, -7.5752],
      [110.4254, -7.5757],
      [110.4257, -7.5761],
      [110.4257, -7.577],
      [110.4259, -7.5783],
      [110.4259, -7.5796],
      [110.4259, -7.5798],
      [110.4259, -7.58],
      [110.4259, -7.5803],
      [110.4258, -7.5808],
      [110.4258, -7.5809],
      [110.4264, -7.5821],
      [110.4266, -7.5825],
      [110.4269, -7.584],
      [110.4268, -7.5852],
      [110.4265, -7.5862],
      [110.4264, -7.5865],
      [110.4264, -7.5866],
      [110.4264, -7.5868],
      [110.4265, -7.587],
      [110.4263, -7.5876],
      [110.426, -7.5878],
      [110.4258, -7.5881],
      [110.4257, -7.5885],
      [110.4256, -7.5894],
      [110.4252, -7.5901],
      [110.4251, -7.5904],
      [110.4246, -7.5907],
      [110.4243, -7.5909],
      [110.4239, -7.5923],
      [110.4236, -7.5932],
      [110.4233, -7.5937],
      [110.4231, -7.5946],
      [110.423, -7.5953],
      [110.4231, -7.5954],
      [110.4232, -7.5956],
      [110.4229, -7.596],
      [110.4226, -7.5963],
      [110.4222, -7.5967],
      [110.422, -7.5968],
      [110.4217, -7.5968],
      [110.4212, -7.5965],
      [110.421, -7.5965],
      [110.4209, -7.5967],
      [110.4209, -7.5971],
      [110.4208, -7.5974],
      [110.4206, -7.5975],
      [110.4206, -7.5978],
      [110.4205, -7.5982],
      [110.4202, -7.5982],
      [110.4201, -7.5986],
      [110.4195, -7.5988],
      [110.4193, -7.5989],
      [110.4192, -7.5994],
      [110.4191, -7.6],
      [110.4192, -7.6004],
      [110.4188, -7.601],
      [110.4185, -7.6015],
      [110.4184, -7.602],
      [110.4184, -7.6027],
      [110.4184, -7.6032],
      [110.418, -7.6035],
      [110.4179, -7.6038],
      [110.418, -7.6041],
      [110.4182, -7.6045],
      [110.418, -7.6052],
      [110.4179, -7.6056],
      [110.4178, -7.6061],
      [110.4177, -7.6063],
      [110.4177, -7.6068],
      [110.4178, -7.607],
      [110.4176, -7.6075],
      [110.4176, -7.6093],
      [110.4171, -7.6096],
      [110.4171, -7.6103],
      [110.417, -7.6108],
      [110.4165, -7.6116],
      [110.4165, -7.6121],
      [110.4164, -7.6122],
      [110.4162, -7.6124],
      [110.416, -7.6128],
      [110.4158, -7.6135],
      [110.416, -7.6142],
      [110.416, -7.6145],
      [110.4158, -7.615],
      [110.4155, -7.6153],
      [110.4154, -7.6158],
      [110.4155, -7.6162],
      [110.4161, -7.6167],
      [110.4159, -7.6179],
      [110.4157, -7.6182],
      [110.4154, -7.6184],
      [110.4152, -7.6188],
      [110.4149, -7.619],
      [110.4146, -7.6193],
      [110.4148, -7.6197],
      [110.4148, -7.62],
      [110.4148, -7.6203],
      [110.4149, -7.6214],
      [110.4145, -7.6223],
      [110.4143, -7.623],
      [110.4141, -7.6235],
      [110.4141, -7.6239],
      [110.4137, -7.6241],
      [110.4134, -7.6245],
      [110.4132, -7.6246],
      [110.4127, -7.6247],
      [110.4121, -7.6248],
      [110.412, -7.625],
      [110.4117, -7.6253],
      [110.4115, -7.6259],
      [110.4115, -7.6264],
      [110.411, -7.6264],
      [110.4106, -7.6265],
      [110.4103, -7.6266],
      [110.41, -7.6266],
      [110.4093, -7.6268],
      [110.4091, -7.6272],
      [110.4088, -7.6274],
      [110.409, -7.6275],
      [110.4094, -7.6276],
      [110.4096, -7.6277],
      [110.41, -7.6279],
      [110.4104, -7.6281],
      [110.4107, -7.6281],
      [110.4111, -7.6279],
      [110.4114, -7.6279],
      [110.4121, -7.628],
      [110.4129, -7.6284],
      [110.4131, -7.6284],
      [110.4134, -7.6285],
      [110.4139, -7.6288],
      [110.414, -7.6288],
      [110.4143, -7.6292],
      [110.4144, -7.6293],
      [110.4142, -7.6298],
      [110.4139, -7.6303],
      [110.4136, -7.631],
      [110.4134, -7.6314],
      [110.4134, -7.6318],
      [110.4133, -7.6322],
      [110.4133, -7.6327],
      [110.4134, -7.6332],
      [110.4135, -7.6334],
      [110.4136, -7.6336],
      [110.4136, -7.6343],
      [110.4138, -7.6348],
      [110.4137, -7.6349],
      [110.4136, -7.6352],
      [110.4135, -7.6356],
      [110.4132, -7.6359],
      [110.413, -7.6362],
      [110.4128, -7.637],
      [110.4126, -7.6379],
      [110.4127, -7.6387],
      [110.4127, -7.6396],
      [110.4129, -7.6398],
      [110.4131, -7.6398],
      [110.4126, -7.6402],
      [110.4126, -7.6408],
      [110.4118, -7.641],
      [110.4111, -7.6413],
      [110.4108, -7.6414],
      [110.4101, -7.6416],
      [110.41, -7.6419],
      [110.4099, -7.6423],
      [110.4102, -7.6428],
      [110.4103, -7.643],
      [110.4105, -7.6431],
      [110.4105, -7.6432],
      [110.411, -7.6432],
      [110.4127, -7.6437],
      [110.4142, -7.6441],
      [110.4148, -7.6442],
      [110.417, -7.6447],
      [110.4174, -7.6448],
      [110.4174, -7.6454],
      [110.4176, -7.6455],
      [110.4189, -7.6455],
      [110.4193, -7.6456],
      [110.4194, -7.6456],
      [110.4194, -7.6464],
      [110.4192, -7.6473],
      [110.419, -7.6478],
      [110.4187, -7.6479],
      [110.4182, -7.6478],
      [110.4183, -7.6482],
      [110.4184, -7.6485],
      [110.4183, -7.649],
      [110.4178, -7.649],
      [110.4175, -7.6491],
      [110.4176, -7.6495],
      [110.4176, -7.6497],
      [110.4179, -7.6498],
      [110.4188, -7.6498],
      [110.4198, -7.6499],
      [110.4201, -7.6499],
      [110.4202, -7.6499],
      [110.4205, -7.6497],
      [110.4208, -7.6496],
      [110.4214, -7.6494],
      [110.4217, -7.6491],
      [110.4218, -7.6488],
      [110.4222, -7.6483],
      [110.4227, -7.6472],
      [110.4231, -7.646],
      [110.4232, -7.6455],
      [110.4232, -7.6451],
      [110.4224, -7.645],
      [110.4215, -7.6448],
      [110.4213, -7.6448],
      [110.4215, -7.6438],
      [110.4218, -7.6433],
      [110.4221, -7.6427],
      [110.4235, -7.6433],
      [110.4249, -7.6438],
      [110.4274, -7.6439],
      [110.4276, -7.644],
      [110.4285, -7.644],
      [110.4284, -7.6436],
      [110.4283, -7.6431],
      [110.4283, -7.6425],
      [110.4284, -7.6418],
      [110.4285, -7.6415],
      [110.4285, -7.6413],
      [110.4285, -7.6409],
      [110.4284, -7.6401],
      [110.4284, -7.6397],
      [110.428, -7.6395],
      [110.4276, -7.6394],
      [110.4272, -7.6392],
      [110.4271, -7.6388],
      [110.4269, -7.6384],
      [110.4266, -7.6381],
      [110.4265, -7.6375],
      [110.4264, -7.6371],
      [110.4264, -7.6366],
      [110.4265, -7.6363],
      [110.4266, -7.6362],
      [110.4269, -7.636],
      [110.4273, -7.6356],
      [110.4276, -7.6351],
      [110.4275, -7.6347],
      [110.4274, -7.6343],
      [110.4276, -7.6338],
      [110.4276, -7.6336],
      [110.4274, -7.6331],
      [110.4276, -7.6327],
      [110.4275, -7.6323],
      [110.4273, -7.632],
      [110.4274, -7.6316],
      [110.4272, -7.6312],
      [110.4271, -7.6309],
      [110.4269, -7.6305],
      [110.427, -7.63],
      [110.427, -7.6296],
      [110.4271, -7.6294],
      [110.4275, -7.629],
      [110.4278, -7.6283],
      [110.4276, -7.6278],
      [110.4273, -7.6274],
      [110.4273, -7.6273],
      [110.4271, -7.6269],
      [110.4273, -7.6266],
      [110.4273, -7.6263],
      [110.4268, -7.6259],
      [110.4268, -7.6252],
      [110.427, -7.6249],
      [110.427, -7.6244],
      [110.427, -7.624],
      [110.4271, -7.6237],
      [110.4272, -7.6234],
      [110.4273, -7.6233],
      [110.4274, -7.6231],
      [110.4275, -7.6229],
      [110.4275, -7.6225],
      [110.4274, -7.6222],
      [110.4275, -7.622],
      [110.4276, -7.6216],
      [110.4276, -7.6212],
      [110.4276, -7.6209],
      [110.4277, -7.6206],
      [110.4278, -7.6203],
      [110.4278, -7.6198],
      [110.428, -7.6195],
      [110.4281, -7.6192],
      [110.4282, -7.6188],
      [110.4284, -7.6185],
      [110.4283, -7.6181],
      [110.4285, -7.6177],
      [110.4286, -7.6173],
      [110.4289, -7.617],
      [110.4293, -7.6167],
      [110.4298, -7.6162],
      [110.4302, -7.6155],
      [110.43, -7.6152],
      [110.43, -7.615],
      [110.4298, -7.6147],
      [110.4295, -7.6145],
      [110.4293, -7.6143],
      [110.4292, -7.6139],
      [110.429, -7.6136],
      [110.429, -7.6131],
      [110.4289, -7.6128],
      [110.4291, -7.6124],
      [110.4292, -7.612],
      [110.4295, -7.6114],
      [110.4297, -7.6108],
      [110.4298, -7.6101],
      [110.43, -7.6098],
      [110.43, -7.6094],
      [110.43, -7.6091],
      [110.43, -7.609],
      [110.43, -7.6086],
      [110.4301, -7.6081],
      [110.4302, -7.6079],
      [110.4304, -7.6073],
      [110.4305, -7.6066],
      [110.4311, -7.6065],
      [110.4312, -7.6063],
      [110.4312, -7.6059],
      [110.4317, -7.6057],
      [110.4322, -7.6056],
      [110.4324, -7.6054],
      [110.4326, -7.6051],
      [110.4328, -7.6051],
      [110.4329, -7.605],
      [110.4332, -7.6052],
      [110.4334, -7.6048],
      [110.4337, -7.6045],
      [110.434, -7.6042],
      [110.4342, -7.6037],
      [110.4344, -7.6033],
      [110.4348, -7.6029],
      [110.4351, -7.6028],
      [110.4353, -7.6027],
      [110.4355, -7.6023],
      [110.4353, -7.6019],
      [110.4354, -7.6015],
      [110.4357, -7.601],
      [110.436, -7.6007],
      [110.4363, -7.6003],
      [110.4366, -7.5998],
      [110.4369, -7.5993],
      [110.4372, -7.5991],
      [110.4373, -7.5989],
      [110.4375, -7.5986],
      [110.4378, -7.5987],
      [110.438, -7.5986],
      [110.4379, -7.5981],
      [110.4381, -7.5971],
      [110.4381, -7.5961],
      [110.4383, -7.5956],
      [110.4385, -7.5954],
      [110.4386, -7.5952],
      [110.4391, -7.5952],
      [110.4394, -7.5947],
      [110.4395, -7.5943],
      [110.4395, -7.5936],
      [110.4396, -7.5934],
      [110.4396, -7.5931],
      [110.44, -7.5928],
      [110.4402, -7.5923],
      [110.4405, -7.592],
      [110.4411, -7.5916],
      [110.4411, -7.5913],
      [110.4412, -7.5909],
      [110.4415, -7.5907],
      [110.4415, -7.5903],
      [110.4412, -7.59],
      [110.4409, -7.5896],
      [110.4409, -7.5888],
      [110.4408, -7.5884],
      [110.4405, -7.588],
      [110.4398, -7.588],
      [110.4394, -7.5876],
      [110.4394, -7.5872],
      [110.4395, -7.5867],
      [110.4401, -7.5866],
      [110.4402, -7.5865],
      [110.4406, -7.5864],
      [110.4406, -7.586],
      [110.4404, -7.5856],
      [110.4404, -7.5853],
      [110.4407, -7.5849],
      [110.4407, -7.5846],
      [110.4407, -7.5843],
      [110.4409, -7.5841],
      [110.4412, -7.5837],
      [110.4414, -7.5834],
      [110.4416, -7.583],
      [110.4416, -7.5828],
      [110.4416, -7.5824],
      [110.4418, -7.5819],
      [110.4418, -7.5816],
      [110.4417, -7.5812],
      [110.4419, -7.5809],
      [110.4421, -7.5807],
      [110.4424, -7.58],
      [110.4424, -7.579],
      [110.4419, -7.5782],
      [110.4416, -7.5776],
      [110.4417, -7.5767],
      [110.4422, -7.5756],
      [110.443, -7.5735],
      [110.4435, -7.5726],
      [110.4442, -7.5719],
      [110.445, -7.5714],
      [110.4453, -7.5712],
      [110.4455, -7.5712],
      [110.4473, -7.5705],
      [110.4475, -7.5705],
      [110.4489, -7.5704],
      [110.4494, -7.5705],
      [110.4495, -7.5705],
      [110.451, -7.5706],
      [110.4513, -7.5707],
      [110.4524, -7.5707],
      [110.4529, -7.5708],
      [110.4537, -7.5707],
      [110.4541, -7.5706],
      [110.4548, -7.5705],
      [110.455, -7.5705],
      [110.4567, -7.5696],
      [110.4578, -7.5687],
      [110.4583, -7.5676],
      [110.4585, -7.5675]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'CANGKRINGAN',
    village: 'ARGOMULYO',
    border: [
      [110.4636, -7.6506],
      [110.4635, -7.6506],
      [110.4625, -7.6505],
      [110.4624, -7.6514],
      [110.4623, -7.6537],
      [110.4622, -7.6546],
      [110.4623, -7.6548],
      [110.4624, -7.655],
      [110.4623, -7.6553],
      [110.4617, -7.6553],
      [110.4616, -7.6551],
      [110.4614, -7.6552],
      [110.4612, -7.6557],
      [110.4613, -7.6559],
      [110.4613, -7.6565],
      [110.4611, -7.6565],
      [110.4598, -7.6565],
      [110.4586, -7.6565],
      [110.4585, -7.6566],
      [110.4582, -7.6568],
      [110.4564, -7.657],
      [110.455, -7.657],
      [110.455, -7.6576],
      [110.4547, -7.6578],
      [110.4545, -7.6581],
      [110.4546, -7.6587],
      [110.4548, -7.6589],
      [110.455, -7.6591],
      [110.4553, -7.6594],
      [110.4555, -7.6595],
      [110.4553, -7.6598],
      [110.455, -7.6605],
      [110.4545, -7.6608],
      [110.4542, -7.661],
      [110.4535, -7.6615],
      [110.4531, -7.6617],
      [110.4524, -7.6622],
      [110.4517, -7.6626],
      [110.4505, -7.6623],
      [110.4503, -7.6623],
      [110.4499, -7.6624],
      [110.4496, -7.6626],
      [110.4491, -7.6631],
      [110.4489, -7.6635],
      [110.4486, -7.6642],
      [110.4483, -7.6645],
      [110.4482, -7.665],
      [110.4479, -7.6652],
      [110.4481, -7.6656],
      [110.4481, -7.6657],
      [110.4482, -7.6659],
      [110.448, -7.6662],
      [110.4479, -7.6667],
      [110.4478, -7.6677],
      [110.4479, -7.668],
      [110.4481, -7.6688],
      [110.4482, -7.6693],
      [110.4482, -7.6695],
      [110.4483, -7.6696],
      [110.4485, -7.6698],
      [110.4485, -7.6701],
      [110.4482, -7.6705],
      [110.4484, -7.6707],
      [110.4485, -7.6712],
      [110.4486, -7.6715],
      [110.4483, -7.6718],
      [110.448, -7.672],
      [110.4481, -7.6726],
      [110.4479, -7.6729],
      [110.4475, -7.6733],
      [110.4475, -7.6734],
      [110.4475, -7.6737],
      [110.4473, -7.6745],
      [110.4473, -7.6747],
      [110.4473, -7.6748],
      [110.4473, -7.6751],
      [110.4475, -7.6754],
      [110.4481, -7.6772],
      [110.4484, -7.6772],
      [110.4486, -7.6772],
      [110.4486, -7.6775],
      [110.4483, -7.6777],
      [110.4482, -7.6778],
      [110.4483, -7.6781],
      [110.4486, -7.6783],
      [110.4487, -7.6786],
      [110.4488, -7.6787],
      [110.4488, -7.6788],
      [110.4484, -7.6789],
      [110.4484, -7.6792],
      [110.4484, -7.6794],
      [110.4484, -7.6795],
      [110.4481, -7.6802],
      [110.448, -7.6809],
      [110.4477, -7.6813],
      [110.4476, -7.6815],
      [110.4474, -7.6817],
      [110.4475, -7.6819],
      [110.4478, -7.682],
      [110.4479, -7.682],
      [110.4481, -7.682],
      [110.4489, -7.6817],
      [110.4499, -7.6815],
      [110.4512, -7.6811],
      [110.4523, -7.6806],
      [110.4525, -7.6803],
      [110.4521, -7.6801],
      [110.4522, -7.6798],
      [110.4527, -7.6798],
      [110.4531, -7.6798],
      [110.4534, -7.6798],
      [110.4538, -7.6798],
      [110.4541, -7.6803],
      [110.4544, -7.6805],
      [110.4549, -7.6803],
      [110.4554, -7.68],
      [110.4559, -7.6801],
      [110.4565, -7.6802],
      [110.457, -7.68],
      [110.4573, -7.6799],
      [110.4579, -7.6795],
      [110.458, -7.6795],
      [110.4585, -7.6794],
      [110.459, -7.6795],
      [110.4595, -7.6798],
      [110.4597, -7.6801],
      [110.4598, -7.6808],
      [110.46, -7.6812],
      [110.4602, -7.6816],
      [110.4604, -7.6817],
      [110.4607, -7.6817],
      [110.4613, -7.6813],
      [110.4613, -7.6809],
      [110.462, -7.6811],
      [110.4629, -7.6823],
      [110.4623, -7.6837],
      [110.4621, -7.6844],
      [110.462, -7.6845],
      [110.4621, -7.6851],
      [110.4624, -7.6857],
      [110.4628, -7.6858],
      [110.463, -7.686],
      [110.463, -7.6865],
      [110.4629, -7.6875],
      [110.4633, -7.6878],
      [110.4638, -7.688],
      [110.4641, -7.6883],
      [110.4642, -7.6887],
      [110.464, -7.6892],
      [110.4639, -7.6895],
      [110.4643, -7.6898],
      [110.4651, -7.69],
      [110.4659, -7.6901],
      [110.466, -7.6905],
      [110.4661, -7.6905],
      [110.4662, -7.6906],
      [110.4664, -7.6906],
      [110.4666, -7.6903],
      [110.4667, -7.6903],
      [110.4668, -7.6906],
      [110.4674, -7.6907],
      [110.4676, -7.6903],
      [110.4689, -7.6903],
      [110.4691, -7.6894],
      [110.4687, -7.6892],
      [110.4687, -7.6885],
      [110.4686, -7.6884],
      [110.4684, -7.6883],
      [110.4684, -7.688],
      [110.4673, -7.6879],
      [110.4673, -7.6878],
      [110.4677, -7.6876],
      [110.4677, -7.6869],
      [110.468, -7.6867],
      [110.4679, -7.685],
      [110.4664, -7.6849],
      [110.4666, -7.6841],
      [110.4667, -7.6831],
      [110.4668, -7.6819],
      [110.467, -7.6805],
      [110.4674, -7.6793],
      [110.4678, -7.6787],
      [110.4683, -7.6775],
      [110.4687, -7.6765],
      [110.4687, -7.6763],
      [110.4686, -7.6762],
      [110.4684, -7.6756],
      [110.4683, -7.6754],
      [110.4678, -7.6743],
      [110.4674, -7.6734],
      [110.4671, -7.6729],
      [110.4671, -7.6727],
      [110.4669, -7.6724],
      [110.4666, -7.6718],
      [110.4666, -7.6713],
      [110.4666, -7.6706],
      [110.4675, -7.6704],
      [110.4678, -7.6706],
      [110.4681, -7.6707],
      [110.4686, -7.6708],
      [110.4689, -7.6708],
      [110.469, -7.6712],
      [110.4691, -7.6716],
      [110.4691, -7.6719],
      [110.4693, -7.6724],
      [110.4697, -7.6724],
      [110.4702, -7.6726],
      [110.4707, -7.6737],
      [110.4709, -7.674],
      [110.4719, -7.6738],
      [110.4716, -7.6725],
      [110.4734, -7.672],
      [110.4734, -7.6729],
      [110.4735, -7.6743],
      [110.4749, -7.6737],
      [110.4751, -7.6746],
      [110.476, -7.6751],
      [110.4761, -7.6761],
      [110.4762, -7.6769],
      [110.4767, -7.6779],
      [110.4774, -7.6777],
      [110.4775, -7.6777],
      [110.4774, -7.6771],
      [110.4772, -7.6762],
      [110.4772, -7.6759],
      [110.4771, -7.6756],
      [110.4768, -7.674],
      [110.4765, -7.6733],
      [110.4763, -7.6721],
      [110.4761, -7.6716],
      [110.476, -7.6714],
      [110.4758, -7.67],
      [110.4755, -7.6688],
      [110.4753, -7.6684],
      [110.4752, -7.6676],
      [110.4751, -7.6674],
      [110.475, -7.6668],
      [110.4748, -7.666],
      [110.4748, -7.6657],
      [110.4746, -7.6648],
      [110.4745, -7.6645],
      [110.4743, -7.6635],
      [110.4737, -7.6607],
      [110.4731, -7.6581],
      [110.4727, -7.656],
      [110.4724, -7.6554],
      [110.4724, -7.6553],
      [110.4724, -7.655],
      [110.4722, -7.6546],
      [110.4721, -7.654],
      [110.4721, -7.6537],
      [110.472, -7.6533],
      [110.4719, -7.6525],
      [110.4715, -7.6508],
      [110.4714, -7.6508],
      [110.4694, -7.6507],
      [110.4693, -7.6507],
      [110.4691, -7.6513],
      [110.4688, -7.6518],
      [110.4687, -7.652],
      [110.4685, -7.653],
      [110.4682, -7.6534],
      [110.4661, -7.6534],
      [110.4654, -7.6534],
      [110.4655, -7.6547],
      [110.465, -7.6547],
      [110.4643, -7.6547],
      [110.4637, -7.6547],
      [110.4638, -7.6536],
      [110.4638, -7.652],
      [110.4636, -7.6513],
      [110.4636, -7.6506]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'CANGKRINGAN',
    village: 'WUKIRSARI',
    border: [
      [110.4463, -7.6339],
      [110.4463, -7.6346],
      [110.4465, -7.6353],
      [110.4464, -7.636],
      [110.4465, -7.6363],
      [110.4461, -7.6369],
      [110.4462, -7.6372],
      [110.4463, -7.6374],
      [110.4464, -7.6375],
      [110.4465, -7.6377],
      [110.4466, -7.6382],
      [110.4465, -7.6389],
      [110.4462, -7.6393],
      [110.4461, -7.6397],
      [110.4462, -7.6398],
      [110.4463, -7.6399],
      [110.4464, -7.64],
      [110.4464, -7.6404],
      [110.4462, -7.6408],
      [110.4458, -7.641],
      [110.4456, -7.6415],
      [110.4454, -7.6415],
      [110.4439, -7.6415],
      [110.442, -7.6415],
      [110.4417, -7.6414],
      [110.4416, -7.6411],
      [110.4416, -7.6408],
      [110.4418, -7.6405],
      [110.4416, -7.6398],
      [110.4417, -7.639],
      [110.4418, -7.6385],
      [110.4419, -7.6381],
      [110.4419, -7.6375],
      [110.4419, -7.6369],
      [110.4419, -7.6362],
      [110.4419, -7.6358],
      [110.4417, -7.6356],
      [110.4414, -7.6352],
      [110.4412, -7.6348],
      [110.4416, -7.6343],
      [110.4417, -7.6339],
      [110.4415, -7.6334],
      [110.4417, -7.6328],
      [110.4418, -7.6323],
      [110.4416, -7.6319],
      [110.4415, -7.6313],
      [110.4412, -7.6307],
      [110.44, -7.6303],
      [110.4393, -7.63],
      [110.4394, -7.6296],
      [110.4393, -7.6292],
      [110.439, -7.6289],
      [110.4385, -7.6286],
      [110.4376, -7.6286],
      [110.4369, -7.6288],
      [110.4364, -7.6289],
      [110.4364, -7.6295],
      [110.4368, -7.63],
      [110.4369, -7.6302],
      [110.437, -7.6304],
      [110.4369, -7.6308],
      [110.4365, -7.6321],
      [110.4365, -7.6336],
      [110.4362, -7.6354],
      [110.436, -7.6367],
      [110.4334, -7.6369],
      [110.4308, -7.637],
      [110.4308, -7.6374],
      [110.4309, -7.6375],
      [110.4311, -7.6376],
      [110.4314, -7.6378],
      [110.4324, -7.6381],
      [110.4326, -7.6383],
      [110.4326, -7.6391],
      [110.4325, -7.6398],
      [110.4324, -7.6405],
      [110.4325, -7.6409],
      [110.4323, -7.6416],
      [110.4319, -7.6419],
      [110.4318, -7.6423],
      [110.4317, -7.6429],
      [110.4316, -7.644],
      [110.4314, -7.6442],
      [110.4314, -7.6456],
      [110.4307, -7.6465],
      [110.4304, -7.6473],
      [110.4304, -7.6481],
      [110.4309, -7.6483],
      [110.431, -7.6485],
      [110.4312, -7.6487],
      [110.4313, -7.6498],
      [110.4314, -7.6505],
      [110.4312, -7.651],
      [110.4313, -7.6514],
      [110.4312, -7.6527],
      [110.4311, -7.6527],
      [110.4307, -7.6537],
      [110.4305, -7.6546],
      [110.4305, -7.6557],
      [110.4302, -7.6564],
      [110.4297, -7.6571],
      [110.4296, -7.6577],
      [110.4296, -7.658],
      [110.4299, -7.6582],
      [110.4298, -7.6585],
      [110.4297, -7.6592],
      [110.4297, -7.6597],
      [110.4301, -7.6608],
      [110.4303, -7.6612],
      [110.4307, -7.6618],
      [110.4311, -7.6621],
      [110.4316, -7.6624],
      [110.4319, -7.6627],
      [110.4321, -7.6634],
      [110.4322, -7.664],
      [110.4321, -7.6644],
      [110.432, -7.6645],
      [110.4313, -7.6649],
      [110.4308, -7.6652],
      [110.4305, -7.6656],
      [110.4305, -7.6663],
      [110.4308, -7.6667],
      [110.4307, -7.6674],
      [110.4305, -7.668],
      [110.4307, -7.6685],
      [110.4304, -7.669],
      [110.4305, -7.6692],
      [110.4305, -7.6697],
      [110.4304, -7.6705],
      [110.4303, -7.6711],
      [110.4305, -7.6717],
      [110.4305, -7.6722],
      [110.4305, -7.6726],
      [110.4307, -7.673],
      [110.4311, -7.6731],
      [110.4315, -7.6736],
      [110.4316, -7.6736],
      [110.4324, -7.6737],
      [110.4332, -7.674],
      [110.4339, -7.6738],
      [110.4348, -7.6735],
      [110.4352, -7.6735],
      [110.4355, -7.6741],
      [110.4357, -7.6748],
      [110.436, -7.6748],
      [110.4377, -7.6748],
      [110.4387, -7.6748],
      [110.4394, -7.6748],
      [110.4405, -7.6747],
      [110.4417, -7.6748],
      [110.4418, -7.6748],
      [110.442, -7.6748],
      [110.4422, -7.6748],
      [110.4431, -7.6748],
      [110.444, -7.6749],
      [110.4447, -7.675],
      [110.445, -7.675],
      [110.4456, -7.675],
      [110.4464, -7.6747],
      [110.4466, -7.6744],
      [110.4469, -7.6739],
      [110.4471, -7.6732],
      [110.4473, -7.673],
      [110.4475, -7.673],
      [110.4475, -7.6734],
      [110.4475, -7.6733],
      [110.4479, -7.6729],
      [110.4481, -7.6726],
      [110.448, -7.672],
      [110.4483, -7.6718],
      [110.4486, -7.6715],
      [110.4485, -7.6712],
      [110.4484, -7.6707],
      [110.4482, -7.6705],
      [110.4485, -7.6701],
      [110.4485, -7.6698],
      [110.4483, -7.6696],
      [110.4482, -7.6695],
      [110.4482, -7.6693],
      [110.4481, -7.6688],
      [110.4479, -7.668],
      [110.4478, -7.6677],
      [110.4479, -7.6667],
      [110.448, -7.6662],
      [110.4482, -7.6659],
      [110.4481, -7.6657],
      [110.4481, -7.6656],
      [110.4479, -7.6652],
      [110.4482, -7.665],
      [110.4483, -7.6645],
      [110.4486, -7.6642],
      [110.4489, -7.6635],
      [110.4491, -7.6631],
      [110.4496, -7.6626],
      [110.4499, -7.6624],
      [110.4503, -7.6623],
      [110.4505, -7.6623],
      [110.4517, -7.6626],
      [110.4524, -7.6622],
      [110.4531, -7.6617],
      [110.4535, -7.6615],
      [110.4542, -7.661],
      [110.4545, -7.6608],
      [110.455, -7.6605],
      [110.4553, -7.6598],
      [110.4555, -7.6595],
      [110.4553, -7.6594],
      [110.455, -7.6591],
      [110.4548, -7.6589],
      [110.4546, -7.6587],
      [110.4545, -7.6581],
      [110.4547, -7.6578],
      [110.455, -7.6576],
      [110.455, -7.657],
      [110.4564, -7.657],
      [110.4582, -7.6568],
      [110.4585, -7.6566],
      [110.4586, -7.6565],
      [110.4598, -7.6565],
      [110.4611, -7.6565],
      [110.4613, -7.6565],
      [110.4613, -7.6559],
      [110.4612, -7.6557],
      [110.4614, -7.6552],
      [110.4616, -7.6551],
      [110.4617, -7.6553],
      [110.4623, -7.6553],
      [110.4624, -7.655],
      [110.4623, -7.6548],
      [110.4622, -7.6546],
      [110.4623, -7.6537],
      [110.4624, -7.6514],
      [110.4625, -7.6505],
      [110.4635, -7.6506],
      [110.4636, -7.6506],
      [110.4636, -7.6506],
      [110.4639, -7.6498],
      [110.4632, -7.6479],
      [110.4625, -7.6468],
      [110.4617, -7.6457],
      [110.4616, -7.6452],
      [110.4618, -7.6445],
      [110.462, -7.6437],
      [110.4619, -7.6432],
      [110.4615, -7.6423],
      [110.4612, -7.6414],
      [110.4613, -7.6402],
      [110.4614, -7.6394],
      [110.4613, -7.639],
      [110.461, -7.6387],
      [110.4606, -7.638],
      [110.4605, -7.6375],
      [110.4606, -7.6366],
      [110.4606, -7.636],
      [110.4606, -7.6355],
      [110.4606, -7.6352],
      [110.4602, -7.6344],
      [110.4599, -7.6335],
      [110.4598, -7.6334],
      [110.4594, -7.6333],
      [110.4583, -7.6332],
      [110.4575, -7.6334],
      [110.4561, -7.6334],
      [110.4557, -7.6334],
      [110.4539, -7.6335],
      [110.4534, -7.6335],
      [110.4519, -7.6335],
      [110.4503, -7.6333],
      [110.4496, -7.6334],
      [110.4495, -7.6334],
      [110.4484, -7.6332],
      [110.4475, -7.6335],
      [110.4463, -7.6339]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'CANGKRINGAN',
    village: 'GLAGAHARJO',
    border: [
      [110.467, -7.6131],
      [110.4668, -7.6128],
      [110.4668, -7.6127],
      [110.4667, -7.6125],
      [110.4665, -7.612],
      [110.4662, -7.6114],
      [110.4661, -7.611],
      [110.466, -7.6108],
      [110.4658, -7.6104],
      [110.4657, -7.6102],
      [110.4655, -7.6098],
      [110.4654, -7.6095],
      [110.4653, -7.6094],
      [110.4652, -7.6091],
      [110.4651, -7.6088],
      [110.465, -7.6087],
      [110.4649, -7.6084],
      [110.4648, -7.6082],
      [110.4647, -7.608],
      [110.4646, -7.6076],
      [110.4642, -7.6069],
      [110.4641, -7.6066],
      [110.4639, -7.6063],
      [110.4638, -7.6059],
      [110.4636, -7.6055],
      [110.4635, -7.6052],
      [110.4634, -7.605],
      [110.4633, -7.6049],
      [110.4633, -7.6047],
      [110.4632, -7.6042],
      [110.4631, -7.6037],
      [110.463, -7.6034],
      [110.463, -7.6033],
      [110.463, -7.6032],
      [110.463, -7.603],
      [110.4629, -7.6026],
      [110.4628, -7.6024],
      [110.4628, -7.6019],
      [110.4627, -7.6015],
      [110.4626, -7.6008],
      [110.4625, -7.6006],
      [110.4625, -7.6004],
      [110.4625, -7.6001],
      [110.4624, -7.5998],
      [110.4624, -7.5997],
      [110.4623, -7.5992],
      [110.4622, -7.5989],
      [110.4622, -7.5987],
      [110.4622, -7.5985],
      [110.4621, -7.5983],
      [110.4621, -7.5979],
      [110.462, -7.5977],
      [110.462, -7.5975],
      [110.4619, -7.5972],
      [110.4619, -7.597],
      [110.4618, -7.5967],
      [110.4618, -7.5965],
      [110.4617, -7.5961],
      [110.4617, -7.5957],
      [110.4616, -7.5952],
      [110.4615, -7.5947],
      [110.4615, -7.5946],
      [110.4614, -7.5943],
      [110.4614, -7.5942],
      [110.4614, -7.5939],
      [110.4613, -7.5937],
      [110.4612, -7.5936],
      [110.4612, -7.5935],
      [110.4612, -7.5934],
      [110.4612, -7.5932],
      [110.4612, -7.5929],
      [110.461, -7.5922],
      [110.461, -7.592],
      [110.4611, -7.5918],
      [110.4611, -7.5917],
      [110.461, -7.5916],
      [110.4609, -7.5914],
      [110.4608, -7.5909],
      [110.4608, -7.5907],
      [110.4607, -7.5904],
      [110.4607, -7.5902],
      [110.4606, -7.5901],
      [110.4606, -7.59],
      [110.4605, -7.5893],
      [110.4604, -7.5887],
      [110.4603, -7.5881],
      [110.4601, -7.5861],
      [110.46, -7.5856],
      [110.4599, -7.5845],
      [110.4598, -7.5834],
      [110.4597, -7.5826],
      [110.4597, -7.5824],
      [110.4595, -7.58],
      [110.4595, -7.5784],
      [110.4595, -7.5772],
      [110.4595, -7.5764],
      [110.4595, -7.5757],
      [110.4595, -7.5746],
      [110.4594, -7.5736],
      [110.4594, -7.573],
      [110.4594, -7.5722],
      [110.4593, -7.5715],
      [110.4592, -7.5703],
      [110.4592, -7.5699],
      [110.4591, -7.5693],
      [110.4588, -7.5686],
      [110.4586, -7.5679],
      [110.4585, -7.5675],
      [110.4583, -7.5676],
      [110.4578, -7.5687],
      [110.4567, -7.5696],
      [110.455, -7.5705],
      [110.4548, -7.5705],
      [110.4541, -7.5706],
      [110.4537, -7.5707],
      [110.4529, -7.5708],
      [110.4524, -7.5707],
      [110.4513, -7.5707],
      [110.451, -7.5706],
      [110.4495, -7.5705],
      [110.4494, -7.5705],
      [110.4496, -7.571],
      [110.4495, -7.5721],
      [110.4496, -7.5736],
      [110.4502, -7.5741],
      [110.4508, -7.5748],
      [110.4514, -7.5751],
      [110.4514, -7.5754],
      [110.4516, -7.576],
      [110.4514, -7.5764],
      [110.4514, -7.5772],
      [110.4516, -7.5778],
      [110.4518, -7.578],
      [110.4516, -7.5785],
      [110.4516, -7.5789],
      [110.452, -7.5793],
      [110.4521, -7.5799],
      [110.4519, -7.5802],
      [110.4518, -7.5806],
      [110.452, -7.5807],
      [110.4521, -7.5808],
      [110.452, -7.5814],
      [110.4523, -7.5816],
      [110.4523, -7.5819],
      [110.4524, -7.5821],
      [110.4526, -7.5825],
      [110.4527, -7.5831],
      [110.4522, -7.5833],
      [110.452, -7.5837],
      [110.4521, -7.5845],
      [110.4523, -7.5847],
      [110.4525, -7.5848],
      [110.4525, -7.5852],
      [110.4529, -7.5855],
      [110.4531, -7.5859],
      [110.4533, -7.5859],
      [110.4537, -7.5858],
      [110.4535, -7.5862],
      [110.4534, -7.5863],
      [110.4533, -7.5865],
      [110.4533, -7.5866],
      [110.4533, -7.5868],
      [110.4536, -7.5873],
      [110.4535, -7.5878],
      [110.4534, -7.5888],
      [110.4534, -7.5894],
      [110.4532, -7.5898],
      [110.4531, -7.5901],
      [110.4532, -7.5904],
      [110.453, -7.5907],
      [110.4529, -7.5911],
      [110.4528, -7.5915],
      [110.4531, -7.5916],
      [110.4532, -7.5918],
      [110.453, -7.592],
      [110.4528, -7.5923],
      [110.4527, -7.5925],
      [110.4528, -7.5932],
      [110.4527, -7.5937],
      [110.4525, -7.5941],
      [110.4527, -7.5946],
      [110.4532, -7.595],
      [110.4541, -7.5957],
      [110.4544, -7.5964],
      [110.4546, -7.5972],
      [110.4546, -7.598],
      [110.4542, -7.5988],
      [110.454, -7.5993],
      [110.454, -7.5996],
      [110.4542, -7.6001],
      [110.4546, -7.6001],
      [110.4551, -7.6002],
      [110.4554, -7.6002],
      [110.4556, -7.6004],
      [110.4557, -7.6006],
      [110.4557, -7.6013],
      [110.4558, -7.6016],
      [110.4565, -7.6027],
      [110.4567, -7.6033],
      [110.4567, -7.6038],
      [110.4562, -7.6044],
      [110.4559, -7.6046],
      [110.4557, -7.6052],
      [110.456, -7.606],
      [110.4563, -7.6066],
      [110.4567, -7.6073],
      [110.4569, -7.6078],
      [110.457, -7.608],
      [110.4576, -7.6085],
      [110.4576, -7.6088],
      [110.457, -7.6093],
      [110.4567, -7.6094],
      [110.4564, -7.6103],
      [110.4566, -7.6108],
      [110.4572, -7.6115],
      [110.4573, -7.6122],
      [110.4574, -7.6128],
      [110.4573, -7.6132],
      [110.4578, -7.6145],
      [110.458, -7.6151],
      [110.4583, -7.6156],
      [110.4584, -7.6158],
      [110.4591, -7.6164],
      [110.4592, -7.6165],
      [110.4596, -7.6167],
      [110.4597, -7.6172],
      [110.4597, -7.6174],
      [110.4596, -7.6177],
      [110.4596, -7.6184],
      [110.4598, -7.6195],
      [110.4596, -7.6197],
      [110.4597, -7.6207],
      [110.4599, -7.6218],
      [110.46, -7.6221],
      [110.46, -7.6223],
      [110.4604, -7.6227],
      [110.4605, -7.6229],
      [110.4606, -7.623],
      [110.4606, -7.6232],
      [110.4605, -7.6235],
      [110.4604, -7.6236],
      [110.4602, -7.6238],
      [110.4601, -7.6242],
      [110.4603, -7.625],
      [110.4604, -7.6253],
      [110.4605, -7.6254],
      [110.4612, -7.6257],
      [110.4619, -7.6259],
      [110.4622, -7.6261],
      [110.4625, -7.6269],
      [110.4625, -7.6271],
      [110.4627, -7.6274],
      [110.4628, -7.6275],
      [110.4631, -7.6286],
      [110.4633, -7.6291],
      [110.4635, -7.6296],
      [110.4635, -7.6304],
      [110.4633, -7.6308],
      [110.4633, -7.632],
      [110.4625, -7.632],
      [110.4605, -7.6321],
      [110.4603, -7.6321],
      [110.4604, -7.6324],
      [110.4602, -7.6328],
      [110.46, -7.6332],
      [110.4599, -7.6335],
      [110.4602, -7.6344],
      [110.4606, -7.6352],
      [110.4606, -7.6355],
      [110.4606, -7.636],
      [110.4606, -7.6366],
      [110.4605, -7.6375],
      [110.4606, -7.638],
      [110.461, -7.6387],
      [110.4613, -7.639],
      [110.4614, -7.6394],
      [110.4613, -7.6402],
      [110.4612, -7.6414],
      [110.4615, -7.6423],
      [110.4619, -7.6432],
      [110.462, -7.6437],
      [110.4618, -7.6445],
      [110.4616, -7.6452],
      [110.4617, -7.6457],
      [110.4625, -7.6468],
      [110.4632, -7.6479],
      [110.4639, -7.6498],
      [110.4636, -7.6506],
      [110.4636, -7.6506],
      [110.4636, -7.6513],
      [110.4638, -7.652],
      [110.4638, -7.6536],
      [110.4637, -7.6547],
      [110.4643, -7.6547],
      [110.465, -7.6547],
      [110.4655, -7.6547],
      [110.4654, -7.6534],
      [110.4661, -7.6534],
      [110.4682, -7.6534],
      [110.4685, -7.653],
      [110.4687, -7.652],
      [110.4688, -7.6518],
      [110.4691, -7.6513],
      [110.4693, -7.6507],
      [110.4694, -7.6507],
      [110.4714, -7.6508],
      [110.4715, -7.6508],
      [110.4713, -7.6497],
      [110.4711, -7.6489],
      [110.4709, -7.6484],
      [110.4709, -7.6483],
      [110.4707, -7.6479],
      [110.4707, -7.6474],
      [110.4706, -7.6468],
      [110.4702, -7.6452],
      [110.4701, -7.644],
      [110.4697, -7.6428],
      [110.4695, -7.6419],
      [110.4695, -7.6418],
      [110.4693, -7.6408],
      [110.4691, -7.6396],
      [110.469, -7.6393],
      [110.4688, -7.6384],
      [110.4686, -7.6374],
      [110.4685, -7.6372],
      [110.4685, -7.6369],
      [110.4685, -7.6346],
      [110.4685, -7.6333],
      [110.4686, -7.6318],
      [110.4685, -7.6298],
      [110.4685, -7.6284],
      [110.4684, -7.628],
      [110.4685, -7.6276],
      [110.4685, -7.6272],
      [110.4685, -7.6268],
      [110.4685, -7.6238],
      [110.4685, -7.6231],
      [110.4685, -7.6223],
      [110.4685, -7.6218],
      [110.4685, -7.6216],
      [110.4685, -7.6215],
      [110.4686, -7.621],
      [110.4686, -7.6209],
      [110.4686, -7.6202],
      [110.4686, -7.62],
      [110.4686, -7.6197],
      [110.4686, -7.6192],
      [110.4685, -7.619],
      [110.4685, -7.6189],
      [110.4685, -7.6187],
      [110.4685, -7.6185],
      [110.4685, -7.6178],
      [110.4685, -7.6177],
      [110.4685, -7.6176],
      [110.4685, -7.6175],
      [110.4684, -7.6174],
      [110.4683, -7.6169],
      [110.4683, -7.6167],
      [110.4682, -7.6166],
      [110.4681, -7.6161],
      [110.4679, -7.6155],
      [110.4678, -7.6153],
      [110.4677, -7.6151],
      [110.4677, -7.615],
      [110.4676, -7.6146],
      [110.4675, -7.6145],
      [110.4675, -7.6144],
      [110.4674, -7.6142],
      [110.4674, -7.6141],
      [110.4671, -7.6133],
      [110.467, -7.6131]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'CANGKRINGAN',
    village: 'KEPUHARJO',
    border: [
      [110.4494, -7.5705],
      [110.4489, -7.5704],
      [110.4475, -7.5705],
      [110.4473, -7.5705],
      [110.4455, -7.5712],
      [110.4453, -7.5712],
      [110.4457, -7.5729],
      [110.4465, -7.5758],
      [110.4464, -7.5767],
      [110.4466, -7.5778],
      [110.4467, -7.5783],
      [110.4468, -7.5791],
      [110.4467, -7.5802],
      [110.4467, -7.5819],
      [110.4466, -7.583],
      [110.4465, -7.5837],
      [110.4462, -7.5851],
      [110.4456, -7.5873],
      [110.4447, -7.5889],
      [110.4443, -7.5904],
      [110.4441, -7.5912],
      [110.4441, -7.5924],
      [110.4441, -7.5926],
      [110.4441, -7.5929],
      [110.4449, -7.5936],
      [110.445, -7.5941],
      [110.4454, -7.5945],
      [110.4457, -7.5949],
      [110.4461, -7.5956],
      [110.4462, -7.5959],
      [110.4463, -7.5967],
      [110.4464, -7.597],
      [110.446, -7.5976],
      [110.446, -7.5981],
      [110.4458, -7.5988],
      [110.4459, -7.5993],
      [110.446, -7.5994],
      [110.4456, -7.5998],
      [110.4457, -7.6001],
      [110.4458, -7.6006],
      [110.446, -7.6009],
      [110.446, -7.6014],
      [110.4461, -7.6019],
      [110.4461, -7.6023],
      [110.4465, -7.6027],
      [110.4465, -7.6035],
      [110.4466, -7.6045],
      [110.4468, -7.6053],
      [110.4472, -7.6058],
      [110.4477, -7.6059],
      [110.4477, -7.6061],
      [110.4477, -7.6063],
      [110.4478, -7.6066],
      [110.4477, -7.607],
      [110.4476, -7.6071],
      [110.4475, -7.6072],
      [110.4472, -7.6075],
      [110.4468, -7.608],
      [110.4465, -7.6085],
      [110.4465, -7.6097],
      [110.4463, -7.6106],
      [110.4464, -7.6111],
      [110.4459, -7.6126],
      [110.4456, -7.6134],
      [110.4455, -7.6141],
      [110.4451, -7.6145],
      [110.445, -7.6148],
      [110.4446, -7.6152],
      [110.4444, -7.6157],
      [110.4441, -7.6165],
      [110.4442, -7.6171],
      [110.4439, -7.6176],
      [110.4436, -7.6179],
      [110.4436, -7.6185],
      [110.4437, -7.6188],
      [110.4436, -7.6193],
      [110.4439, -7.6197],
      [110.4442, -7.6198],
      [110.4442, -7.6203],
      [110.4442, -7.621],
      [110.444, -7.6216],
      [110.4442, -7.6222],
      [110.4441, -7.6228],
      [110.4443, -7.6236],
      [110.4445, -7.6242],
      [110.4446, -7.6247],
      [110.4449, -7.6251],
      [110.4453, -7.6253],
      [110.4455, -7.6263],
      [110.4458, -7.6267],
      [110.446, -7.6273],
      [110.446, -7.6276],
      [110.4457, -7.6281],
      [110.4456, -7.6286],
      [110.4455, -7.6293],
      [110.4456, -7.6299],
      [110.4458, -7.6304],
      [110.4455, -7.6307],
      [110.4455, -7.6309],
      [110.4456, -7.6311],
      [110.4458, -7.6313],
      [110.4459, -7.6323],
      [110.4462, -7.6331],
      [110.4462, -7.6334],
      [110.4463, -7.6339],
      [110.4475, -7.6335],
      [110.4484, -7.6332],
      [110.4495, -7.6334],
      [110.4496, -7.6334],
      [110.4503, -7.6333],
      [110.4519, -7.6335],
      [110.4534, -7.6335],
      [110.4539, -7.6335],
      [110.4557, -7.6334],
      [110.4561, -7.6334],
      [110.4575, -7.6334],
      [110.4583, -7.6332],
      [110.4594, -7.6333],
      [110.4598, -7.6334],
      [110.4599, -7.6335],
      [110.46, -7.6332],
      [110.4602, -7.6328],
      [110.4604, -7.6324],
      [110.4603, -7.6321],
      [110.4605, -7.6321],
      [110.4625, -7.632],
      [110.4633, -7.632],
      [110.4633, -7.6308],
      [110.4635, -7.6304],
      [110.4635, -7.6296],
      [110.4633, -7.6291],
      [110.4631, -7.6286],
      [110.4628, -7.6275],
      [110.4627, -7.6274],
      [110.4625, -7.6271],
      [110.4625, -7.6269],
      [110.4622, -7.6261],
      [110.4619, -7.6259],
      [110.4612, -7.6257],
      [110.4605, -7.6254],
      [110.4604, -7.6253],
      [110.4603, -7.625],
      [110.4601, -7.6242],
      [110.4602, -7.6238],
      [110.4604, -7.6236],
      [110.4605, -7.6235],
      [110.4606, -7.6232],
      [110.4606, -7.623],
      [110.4605, -7.6229],
      [110.4604, -7.6227],
      [110.46, -7.6223],
      [110.46, -7.6221],
      [110.4599, -7.6218],
      [110.4597, -7.6207],
      [110.4596, -7.6197],
      [110.4598, -7.6195],
      [110.4596, -7.6184],
      [110.4596, -7.6177],
      [110.4597, -7.6174],
      [110.4597, -7.6172],
      [110.4596, -7.6167],
      [110.4592, -7.6165],
      [110.4591, -7.6164],
      [110.4584, -7.6158],
      [110.4583, -7.6156],
      [110.458, -7.6151],
      [110.4578, -7.6145],
      [110.4573, -7.6132],
      [110.4574, -7.6128],
      [110.4573, -7.6122],
      [110.4572, -7.6115],
      [110.4566, -7.6108],
      [110.4564, -7.6103],
      [110.4567, -7.6094],
      [110.457, -7.6093],
      [110.4576, -7.6088],
      [110.4576, -7.6085],
      [110.457, -7.608],
      [110.4569, -7.6078],
      [110.4567, -7.6073],
      [110.4563, -7.6066],
      [110.456, -7.606],
      [110.4557, -7.6052],
      [110.4559, -7.6046],
      [110.4562, -7.6044],
      [110.4567, -7.6038],
      [110.4567, -7.6033],
      [110.4565, -7.6027],
      [110.4558, -7.6016],
      [110.4557, -7.6013],
      [110.4557, -7.6006],
      [110.4556, -7.6004],
      [110.4554, -7.6002],
      [110.4551, -7.6002],
      [110.4546, -7.6001],
      [110.4542, -7.6001],
      [110.454, -7.5996],
      [110.454, -7.5993],
      [110.4542, -7.5988],
      [110.4546, -7.598],
      [110.4546, -7.5972],
      [110.4544, -7.5964],
      [110.4541, -7.5957],
      [110.4532, -7.595],
      [110.4527, -7.5946],
      [110.4525, -7.5941],
      [110.4527, -7.5937],
      [110.4528, -7.5932],
      [110.4527, -7.5925],
      [110.4528, -7.5923],
      [110.453, -7.592],
      [110.4532, -7.5918],
      [110.4531, -7.5916],
      [110.4528, -7.5915],
      [110.4529, -7.5911],
      [110.453, -7.5907],
      [110.4532, -7.5904],
      [110.4531, -7.5901],
      [110.4532, -7.5898],
      [110.4534, -7.5894],
      [110.4534, -7.5888],
      [110.4535, -7.5878],
      [110.4536, -7.5873],
      [110.4533, -7.5868],
      [110.4533, -7.5866],
      [110.4533, -7.5865],
      [110.4534, -7.5863],
      [110.4535, -7.5862],
      [110.4537, -7.5858],
      [110.4533, -7.5859],
      [110.4531, -7.5859],
      [110.4529, -7.5855],
      [110.4525, -7.5852],
      [110.4525, -7.5848],
      [110.4523, -7.5847],
      [110.4521, -7.5845],
      [110.452, -7.5837],
      [110.4522, -7.5833],
      [110.4527, -7.5831],
      [110.4526, -7.5825],
      [110.4524, -7.5821],
      [110.4523, -7.5819],
      [110.4523, -7.5816],
      [110.452, -7.5814],
      [110.4521, -7.5808],
      [110.452, -7.5807],
      [110.4518, -7.5806],
      [110.4519, -7.5802],
      [110.4521, -7.5799],
      [110.452, -7.5793],
      [110.4516, -7.5789],
      [110.4516, -7.5785],
      [110.4518, -7.578],
      [110.4516, -7.5778],
      [110.4514, -7.5772],
      [110.4514, -7.5764],
      [110.4516, -7.576],
      [110.4514, -7.5754],
      [110.4514, -7.5751],
      [110.4508, -7.5748],
      [110.4502, -7.5741],
      [110.4496, -7.5736],
      [110.4495, -7.5721],
      [110.4496, -7.571],
      [110.4494, -7.5705]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'SLEMAN',
    sub_district: 'CANGKRINGAN',
    village: 'UMBULHARJO',
    border: [
      [110.4463, -7.6339],
      [110.4462, -7.6334],
      [110.4462, -7.6331],
      [110.4459, -7.6323],
      [110.4458, -7.6313],
      [110.4456, -7.6311],
      [110.4455, -7.6309],
      [110.4455, -7.6307],
      [110.4458, -7.6304],
      [110.4456, -7.6299],
      [110.4455, -7.6293],
      [110.4456, -7.6286],
      [110.4457, -7.6281],
      [110.446, -7.6276],
      [110.446, -7.6273],
      [110.4458, -7.6267],
      [110.4455, -7.6263],
      [110.4453, -7.6253],
      [110.4449, -7.6251],
      [110.4446, -7.6247],
      [110.4445, -7.6242],
      [110.4443, -7.6236],
      [110.4441, -7.6228],
      [110.4442, -7.6222],
      [110.444, -7.6216],
      [110.4442, -7.621],
      [110.4442, -7.6203],
      [110.4442, -7.6198],
      [110.4439, -7.6197],
      [110.4436, -7.6193],
      [110.4437, -7.6188],
      [110.4436, -7.6185],
      [110.4436, -7.6179],
      [110.4439, -7.6176],
      [110.4442, -7.6171],
      [110.4441, -7.6165],
      [110.4444, -7.6157],
      [110.4446, -7.6152],
      [110.445, -7.6148],
      [110.4451, -7.6145],
      [110.4455, -7.6141],
      [110.4456, -7.6134],
      [110.4459, -7.6126],
      [110.4464, -7.6111],
      [110.4463, -7.6106],
      [110.4465, -7.6097],
      [110.4465, -7.6085],
      [110.4468, -7.608],
      [110.4472, -7.6075],
      [110.4475, -7.6072],
      [110.4476, -7.6071],
      [110.4477, -7.607],
      [110.4478, -7.6066],
      [110.4477, -7.6063],
      [110.4477, -7.6061],
      [110.4477, -7.6059],
      [110.4472, -7.6058],
      [110.4468, -7.6053],
      [110.4466, -7.6045],
      [110.4465, -7.6035],
      [110.4465, -7.6027],
      [110.4461, -7.6023],
      [110.4461, -7.6019],
      [110.446, -7.6014],
      [110.446, -7.6009],
      [110.4458, -7.6006],
      [110.4457, -7.6001],
      [110.4456, -7.5998],
      [110.446, -7.5994],
      [110.4459, -7.5993],
      [110.4458, -7.5988],
      [110.446, -7.5981],
      [110.446, -7.5976],
      [110.4464, -7.597],
      [110.4463, -7.5967],
      [110.4462, -7.5959],
      [110.4461, -7.5956],
      [110.4457, -7.5949],
      [110.4454, -7.5945],
      [110.445, -7.5941],
      [110.4449, -7.5936],
      [110.4441, -7.5929],
      [110.4441, -7.5926],
      [110.4441, -7.5924],
      [110.4441, -7.5912],
      [110.4443, -7.5904],
      [110.4447, -7.5889],
      [110.4456, -7.5873],
      [110.4462, -7.5851],
      [110.4465, -7.5837],
      [110.4466, -7.583],
      [110.4467, -7.5819],
      [110.4467, -7.5802],
      [110.4468, -7.5791],
      [110.4467, -7.5783],
      [110.4466, -7.5778],
      [110.4464, -7.5767],
      [110.4465, -7.5758],
      [110.4457, -7.5729],
      [110.4453, -7.5712],
      [110.445, -7.5714],
      [110.4442, -7.5719],
      [110.4435, -7.5726],
      [110.443, -7.5735],
      [110.4422, -7.5756],
      [110.4417, -7.5767],
      [110.4416, -7.5776],
      [110.4419, -7.5782],
      [110.4424, -7.579],
      [110.4424, -7.58],
      [110.4421, -7.5807],
      [110.4419, -7.5809],
      [110.4417, -7.5812],
      [110.4418, -7.5816],
      [110.4418, -7.5819],
      [110.4416, -7.5824],
      [110.4416, -7.5828],
      [110.4416, -7.583],
      [110.4414, -7.5834],
      [110.4412, -7.5837],
      [110.4409, -7.5841],
      [110.4407, -7.5843],
      [110.4407, -7.5846],
      [110.4407, -7.5849],
      [110.4404, -7.5853],
      [110.4404, -7.5856],
      [110.4406, -7.586],
      [110.4406, -7.5864],
      [110.4402, -7.5865],
      [110.4401, -7.5866],
      [110.4395, -7.5867],
      [110.4394, -7.5872],
      [110.4394, -7.5876],
      [110.4398, -7.588],
      [110.4405, -7.588],
      [110.4408, -7.5884],
      [110.4409, -7.5888],
      [110.4409, -7.5896],
      [110.4412, -7.59],
      [110.4415, -7.5903],
      [110.4415, -7.5907],
      [110.4412, -7.5909],
      [110.4411, -7.5913],
      [110.4411, -7.5916],
      [110.4405, -7.592],
      [110.4402, -7.5923],
      [110.44, -7.5928],
      [110.4396, -7.5931],
      [110.4396, -7.5934],
      [110.4395, -7.5936],
      [110.4395, -7.5943],
      [110.4394, -7.5947],
      [110.4391, -7.5952],
      [110.4386, -7.5952],
      [110.4385, -7.5954],
      [110.4383, -7.5956],
      [110.4381, -7.5961],
      [110.4381, -7.5971],
      [110.4379, -7.5981],
      [110.438, -7.5986],
      [110.4378, -7.5987],
      [110.4375, -7.5986],
      [110.4373, -7.5989],
      [110.4372, -7.5991],
      [110.4369, -7.5993],
      [110.4366, -7.5998],
      [110.4363, -7.6003],
      [110.436, -7.6007],
      [110.4357, -7.601],
      [110.4354, -7.6015],
      [110.4353, -7.6019],
      [110.4355, -7.6023],
      [110.4353, -7.6027],
      [110.4351, -7.6028],
      [110.4348, -7.6029],
      [110.4344, -7.6033],
      [110.4342, -7.6037],
      [110.434, -7.6042],
      [110.4337, -7.6045],
      [110.4334, -7.6048],
      [110.4332, -7.6052],
      [110.4329, -7.605],
      [110.4328, -7.6051],
      [110.4326, -7.6051],
      [110.4324, -7.6054],
      [110.4322, -7.6056],
      [110.4317, -7.6057],
      [110.4312, -7.6059],
      [110.4312, -7.6063],
      [110.4311, -7.6065],
      [110.4305, -7.6066],
      [110.4304, -7.6073],
      [110.4302, -7.6079],
      [110.4301, -7.6081],
      [110.43, -7.6086],
      [110.43, -7.609],
      [110.43, -7.6091],
      [110.43, -7.6094],
      [110.43, -7.6098],
      [110.4298, -7.6101],
      [110.4297, -7.6108],
      [110.4295, -7.6114],
      [110.4292, -7.612],
      [110.4291, -7.6124],
      [110.4289, -7.6128],
      [110.429, -7.6131],
      [110.429, -7.6136],
      [110.4292, -7.6139],
      [110.4293, -7.6143],
      [110.4295, -7.6145],
      [110.4298, -7.6147],
      [110.43, -7.615],
      [110.43, -7.6152],
      [110.4302, -7.6155],
      [110.4298, -7.6162],
      [110.4293, -7.6167],
      [110.4289, -7.617],
      [110.4286, -7.6173],
      [110.4285, -7.6177],
      [110.4283, -7.6181],
      [110.4284, -7.6185],
      [110.4282, -7.6188],
      [110.4281, -7.6192],
      [110.428, -7.6195],
      [110.4278, -7.6198],
      [110.4278, -7.6203],
      [110.4277, -7.6206],
      [110.4276, -7.6209],
      [110.4276, -7.6212],
      [110.4276, -7.6216],
      [110.4275, -7.622],
      [110.4274, -7.6222],
      [110.4275, -7.6225],
      [110.4275, -7.6229],
      [110.4274, -7.6231],
      [110.4273, -7.6233],
      [110.4272, -7.6234],
      [110.4271, -7.6237],
      [110.427, -7.624],
      [110.427, -7.6244],
      [110.427, -7.6249],
      [110.4268, -7.6252],
      [110.4268, -7.6259],
      [110.4273, -7.6263],
      [110.4273, -7.6266],
      [110.4271, -7.6269],
      [110.4273, -7.6273],
      [110.4273, -7.6274],
      [110.4276, -7.6278],
      [110.4278, -7.6283],
      [110.4275, -7.629],
      [110.4271, -7.6294],
      [110.427, -7.6296],
      [110.427, -7.63],
      [110.4269, -7.6305],
      [110.4271, -7.6309],
      [110.4272, -7.6312],
      [110.4274, -7.6316],
      [110.4273, -7.632],
      [110.4275, -7.6323],
      [110.4276, -7.6327],
      [110.4274, -7.6331],
      [110.4276, -7.6336],
      [110.4276, -7.6338],
      [110.4274, -7.6343],
      [110.4275, -7.6347],
      [110.4276, -7.6351],
      [110.4273, -7.6356],
      [110.4269, -7.636],
      [110.4266, -7.6362],
      [110.4265, -7.6363],
      [110.4264, -7.6366],
      [110.4264, -7.6371],
      [110.4265, -7.6375],
      [110.4266, -7.6381],
      [110.4269, -7.6384],
      [110.4271, -7.6388],
      [110.4272, -7.6392],
      [110.4276, -7.6394],
      [110.428, -7.6395],
      [110.4284, -7.6397],
      [110.4284, -7.6401],
      [110.4285, -7.6409],
      [110.4285, -7.6413],
      [110.4285, -7.6415],
      [110.4284, -7.6418],
      [110.4283, -7.6425],
      [110.4283, -7.6431],
      [110.4284, -7.6436],
      [110.4285, -7.644],
      [110.4283, -7.6444],
      [110.428, -7.6449],
      [110.428, -7.6453],
      [110.4281, -7.6456],
      [110.428, -7.6463],
      [110.4281, -7.6469],
      [110.4283, -7.6475],
      [110.4287, -7.6487],
      [110.4293, -7.6492],
      [110.4294, -7.6493],
      [110.4295, -7.6495],
      [110.4296, -7.6497],
      [110.4299, -7.6504],
      [110.4299, -7.6505],
      [110.4303, -7.6514],
      [110.4306, -7.6516],
      [110.4307, -7.6517],
      [110.4309, -7.6524],
      [110.4311, -7.6527],
      [110.4312, -7.6527],
      [110.4313, -7.6514],
      [110.4312, -7.651],
      [110.4314, -7.6505],
      [110.4313, -7.6498],
      [110.4312, -7.6487],
      [110.431, -7.6485],
      [110.4309, -7.6483],
      [110.4304, -7.6481],
      [110.4304, -7.6473],
      [110.4307, -7.6465],
      [110.4314, -7.6456],
      [110.4314, -7.6442],
      [110.4316, -7.644],
      [110.4317, -7.6429],
      [110.4318, -7.6423],
      [110.4319, -7.6419],
      [110.4323, -7.6416],
      [110.4325, -7.6409],
      [110.4324, -7.6405],
      [110.4325, -7.6398],
      [110.4326, -7.6391],
      [110.4326, -7.6383],
      [110.4324, -7.6381],
      [110.4314, -7.6378],
      [110.4311, -7.6376],
      [110.4309, -7.6375],
      [110.4308, -7.6374],
      [110.4308, -7.637],
      [110.4334, -7.6369],
      [110.436, -7.6367],
      [110.4362, -7.6354],
      [110.4365, -7.6336],
      [110.4365, -7.6321],
      [110.4369, -7.6308],
      [110.437, -7.6304],
      [110.4369, -7.6302],
      [110.4368, -7.63],
      [110.4364, -7.6295],
      [110.4364, -7.6289],
      [110.4369, -7.6288],
      [110.4376, -7.6286],
      [110.4385, -7.6286],
      [110.439, -7.6289],
      [110.4393, -7.6292],
      [110.4394, -7.6296],
      [110.4393, -7.63],
      [110.44, -7.6303],
      [110.4412, -7.6307],
      [110.4415, -7.6313],
      [110.4416, -7.6319],
      [110.4418, -7.6323],
      [110.4417, -7.6328],
      [110.4415, -7.6334],
      [110.4417, -7.6339],
      [110.4416, -7.6343],
      [110.4412, -7.6348],
      [110.4414, -7.6352],
      [110.4417, -7.6356],
      [110.4419, -7.6358],
      [110.4419, -7.6362],
      [110.4419, -7.6369],
      [110.4419, -7.6375],
      [110.4419, -7.6381],
      [110.4418, -7.6385],
      [110.4417, -7.639],
      [110.4416, -7.6398],
      [110.4418, -7.6405],
      [110.4416, -7.6408],
      [110.4416, -7.6411],
      [110.4417, -7.6414],
      [110.442, -7.6415],
      [110.4439, -7.6415],
      [110.4454, -7.6415],
      [110.4456, -7.6415],
      [110.4458, -7.641],
      [110.4462, -7.6408],
      [110.4464, -7.6404],
      [110.4464, -7.64],
      [110.4463, -7.6399],
      [110.4462, -7.6398],
      [110.4461, -7.6397],
      [110.4462, -7.6393],
      [110.4465, -7.6389],
      [110.4466, -7.6382],
      [110.4465, -7.6377],
      [110.4464, -7.6375],
      [110.4463, -7.6374],
      [110.4462, -7.6372],
      [110.4461, -7.6369],
      [110.4465, -7.6363],
      [110.4464, -7.636],
      [110.4465, -7.6353],
      [110.4463, -7.6346],
      [110.4463, -7.6339]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'TEGALREJO',
    village: 'KRICAK',
    border: [
      [110.3613, -7.7686],
      [110.3606, -7.7686],
      [110.3607, -7.7692],
      [110.3597, -7.7692],
      [110.359, -7.769],
      [110.3572, -7.7687],
      [110.3564, -7.7684],
      [110.356, -7.7682],
      [110.3557, -7.7688],
      [110.3545, -7.7679],
      [110.3537, -7.7673],
      [110.3525, -7.7666],
      [110.3522, -7.7665],
      [110.3522, -7.7666],
      [110.3522, -7.7667],
      [110.3526, -7.7671],
      [110.3528, -7.7673],
      [110.3528, -7.7677],
      [110.3526, -7.7681],
      [110.3526, -7.7684],
      [110.3526, -7.7686],
      [110.3531, -7.7704],
      [110.3533, -7.7709],
      [110.3534, -7.7713],
      [110.3535, -7.7718],
      [110.3533, -7.7722],
      [110.3528, -7.7723],
      [110.3525, -7.7722],
      [110.3524, -7.7725],
      [110.3528, -7.7734],
      [110.3529, -7.7736],
      [110.3531, -7.7738],
      [110.3534, -7.7741],
      [110.3536, -7.7741],
      [110.3538, -7.7741],
      [110.3541, -7.7735],
      [110.3545, -7.7734],
      [110.3548, -7.7738],
      [110.3548, -7.7744],
      [110.3548, -7.7745],
      [110.3546, -7.7751],
      [110.3542, -7.7757],
      [110.3539, -7.7761],
      [110.3539, -7.7766],
      [110.3541, -7.777],
      [110.3545, -7.7773],
      [110.3548, -7.7772],
      [110.3556, -7.7772],
      [110.3556, -7.7772],
      [110.3558, -7.7771],
      [110.3559, -7.7769],
      [110.3559, -7.7767],
      [110.3559, -7.7764],
      [110.356, -7.7762],
      [110.356, -7.7758],
      [110.3562, -7.7756],
      [110.3567, -7.7757],
      [110.3572, -7.776],
      [110.3576, -7.7766],
      [110.3575, -7.7769],
      [110.3574, -7.7773],
      [110.3575, -7.7782],
      [110.3575, -7.7785],
      [110.358, -7.7789],
      [110.3581, -7.7789],
      [110.3582, -7.7791],
      [110.3585, -7.7795],
      [110.3591, -7.7802],
      [110.3592, -7.7803],
      [110.3595, -7.78],
      [110.3603, -7.7802],
      [110.3608, -7.7803],
      [110.3608, -7.7798],
      [110.3609, -7.7774],
      [110.3609, -7.7765],
      [110.361, -7.7739],
      [110.3613, -7.769],
      [110.3613, -7.7686]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'TEGALREJO',
    village: 'KARANGWARU',
    border: [
      [110.3691, -7.77],
      [110.369, -7.7697],
      [110.3689, -7.7695],
      [110.3681, -7.7693],
      [110.3679, -7.7701],
      [110.3677, -7.7714],
      [110.3676, -7.7717],
      [110.3669, -7.7717],
      [110.3666, -7.7716],
      [110.3666, -7.7713],
      [110.3665, -7.7711],
      [110.3666, -7.7706],
      [110.3666, -7.7703],
      [110.366, -7.7701],
      [110.366, -7.7705],
      [110.3657, -7.7706],
      [110.3653, -7.7706],
      [110.3651, -7.7705],
      [110.365, -7.7704],
      [110.3649, -7.7703],
      [110.3648, -7.7702],
      [110.3647, -7.7701],
      [110.3648, -7.7698],
      [110.3653, -7.7695],
      [110.3656, -7.7693],
      [110.3656, -7.769],
      [110.3655, -7.7688],
      [110.3652, -7.7685],
      [110.3645, -7.7685],
      [110.3639, -7.7685],
      [110.3637, -7.7684],
      [110.3634, -7.7687],
      [110.3631, -7.7688],
      [110.3628, -7.7688],
      [110.3626, -7.7685],
      [110.3618, -7.7686],
      [110.3613, -7.7686],
      [110.3613, -7.769],
      [110.361, -7.7739],
      [110.3609, -7.7765],
      [110.3609, -7.7774],
      [110.3608, -7.7798],
      [110.3608, -7.7803],
      [110.3619, -7.7803],
      [110.3629, -7.7805],
      [110.3634, -7.7805],
      [110.3636, -7.78],
      [110.3642, -7.7803],
      [110.365, -7.7804],
      [110.3651, -7.779],
      [110.3652, -7.7776],
      [110.3652, -7.7775],
      [110.3652, -7.777],
      [110.3652, -7.7762],
      [110.3654, -7.7757],
      [110.3657, -7.7757],
      [110.3657, -7.7756],
      [110.3664, -7.7755],
      [110.3665, -7.775],
      [110.3665, -7.7744],
      [110.3671, -7.7746],
      [110.3672, -7.7745],
      [110.3672, -7.7741],
      [110.3674, -7.7737],
      [110.3676, -7.7733],
      [110.368, -7.7734],
      [110.3688, -7.7736],
      [110.3693, -7.7738],
      [110.3693, -7.7734],
      [110.3692, -7.7733],
      [110.3692, -7.7732],
      [110.3692, -7.7728],
      [110.3692, -7.7723],
      [110.3691, -7.7717],
      [110.3691, -7.7714],
      [110.3691, -7.7711],
      [110.3691, -7.771],
      [110.3691, -7.7705],
      [110.3691, -7.7702],
      [110.3691, -7.77]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'TEGALREJO',
    village: 'TEGALREJO',
    border: [
      [110.3572, -7.7826],
      [110.3571, -7.7826],
      [110.356, -7.7823],
      [110.3544, -7.782],
      [110.353, -7.7817],
      [110.3514, -7.7814],
      [110.3508, -7.7813],
      [110.3507, -7.7817],
      [110.3504, -7.7844],
      [110.3501, -7.787],
      [110.3499, -7.7884],
      [110.3497, -7.7896],
      [110.3495, -7.7898],
      [110.3493, -7.7902],
      [110.3492, -7.7908],
      [110.3489, -7.7919],
      [110.3488, -7.7924],
      [110.3488, -7.793],
      [110.3487, -7.7932],
      [110.349, -7.7933],
      [110.3495, -7.7934],
      [110.35, -7.7934],
      [110.35, -7.7932],
      [110.3501, -7.7929],
      [110.3502, -7.7928],
      [110.3504, -7.7928],
      [110.3513, -7.7929],
      [110.3535, -7.7931],
      [110.3534, -7.794],
      [110.3538, -7.7941],
      [110.3554, -7.7942],
      [110.3555, -7.7942],
      [110.3556, -7.794],
      [110.3554, -7.7938],
      [110.3556, -7.7936],
      [110.3558, -7.7933],
      [110.3558, -7.793],
      [110.3558, -7.7927],
      [110.3556, -7.7923],
      [110.3556, -7.792],
      [110.3558, -7.7917],
      [110.3562, -7.7914],
      [110.3568, -7.7911],
      [110.3571, -7.7905],
      [110.3571, -7.7902],
      [110.357, -7.7898],
      [110.3567, -7.7897],
      [110.3559, -7.7895],
      [110.3553, -7.7894],
      [110.3552, -7.7894],
      [110.3552, -7.7892],
      [110.3554, -7.7887],
      [110.3555, -7.7883],
      [110.3554, -7.7879],
      [110.3553, -7.7875],
      [110.3554, -7.787],
      [110.3554, -7.7867],
      [110.3552, -7.7863],
      [110.3552, -7.7858],
      [110.3553, -7.7854],
      [110.356, -7.7846],
      [110.3565, -7.784],
      [110.3569, -7.7836],
      [110.3571, -7.7832],
      [110.3572, -7.7826]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'TEGALREJO',
    village: 'BENER',
    border: [
      [110.3525, -7.7722],
      [110.3521, -7.7719],
      [110.3519, -7.7716],
      [110.3519, -7.7711],
      [110.3518, -7.7709],
      [110.3517, -7.7704],
      [110.3514, -7.7697],
      [110.3511, -7.7697],
      [110.3511, -7.7703],
      [110.3511, -7.7707],
      [110.3512, -7.7712],
      [110.3513, -7.7718],
      [110.3513, -7.7722],
      [110.3512, -7.7726],
      [110.3511, -7.7731],
      [110.3511, -7.7732],
      [110.3511, -7.7735],
      [110.3511, -7.7743],
      [110.351, -7.775],
      [110.3508, -7.7755],
      [110.3509, -7.7763],
      [110.3509, -7.7766],
      [110.3511, -7.7793],
      [110.3511, -7.7803],
      [110.3509, -7.7808],
      [110.3508, -7.7811],
      [110.3508, -7.7813],
      [110.3514, -7.7814],
      [110.353, -7.7817],
      [110.3544, -7.782],
      [110.356, -7.7823],
      [110.3571, -7.7826],
      [110.3572, -7.7826],
      [110.3573, -7.7821],
      [110.3576, -7.7818],
      [110.3577, -7.7816],
      [110.3578, -7.7814],
      [110.358, -7.7813],
      [110.3582, -7.781],
      [110.3583, -7.7808],
      [110.3588, -7.7809],
      [110.3591, -7.7806],
      [110.3592, -7.7803],
      [110.3591, -7.7802],
      [110.3585, -7.7795],
      [110.3582, -7.7791],
      [110.3581, -7.7789],
      [110.358, -7.7789],
      [110.3575, -7.7785],
      [110.3575, -7.7782],
      [110.3574, -7.7773],
      [110.3575, -7.7769],
      [110.3576, -7.7766],
      [110.3572, -7.776],
      [110.3567, -7.7757],
      [110.3562, -7.7756],
      [110.356, -7.7758],
      [110.356, -7.7762],
      [110.3559, -7.7764],
      [110.3559, -7.7767],
      [110.3559, -7.7769],
      [110.3558, -7.7771],
      [110.3556, -7.7772],
      [110.3556, -7.7772],
      [110.3548, -7.7772],
      [110.3545, -7.7773],
      [110.3541, -7.777],
      [110.3539, -7.7766],
      [110.3539, -7.7761],
      [110.3542, -7.7757],
      [110.3546, -7.7751],
      [110.3548, -7.7745],
      [110.3548, -7.7744],
      [110.3548, -7.7738],
      [110.3545, -7.7734],
      [110.3541, -7.7735],
      [110.3538, -7.7741],
      [110.3536, -7.7741],
      [110.3534, -7.7741],
      [110.3531, -7.7738],
      [110.3529, -7.7736],
      [110.3528, -7.7734],
      [110.3524, -7.7725],
      [110.3525, -7.7722]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'JETIS',
    village: 'BUMIJO',
    border: [
      [110.3608, -7.7803],
      [110.3603, -7.7802],
      [110.3595, -7.78],
      [110.3592, -7.7803],
      [110.3591, -7.7806],
      [110.3588, -7.7809],
      [110.3583, -7.7808],
      [110.3582, -7.781],
      [110.358, -7.7813],
      [110.3578, -7.7814],
      [110.3577, -7.7816],
      [110.3576, -7.7818],
      [110.3573, -7.7821],
      [110.3572, -7.7826],
      [110.3571, -7.7832],
      [110.3569, -7.7836],
      [110.3565, -7.784],
      [110.356, -7.7846],
      [110.3553, -7.7854],
      [110.3552, -7.7858],
      [110.3552, -7.7863],
      [110.3554, -7.7867],
      [110.3554, -7.787],
      [110.3553, -7.7875],
      [110.3554, -7.7879],
      [110.3555, -7.7883],
      [110.3554, -7.7887],
      [110.3574, -7.7888],
      [110.3594, -7.789],
      [110.3597, -7.789],
      [110.3608, -7.7892],
      [110.3618, -7.7892],
      [110.3626, -7.7885],
      [110.3626, -7.7879],
      [110.3628, -7.7873],
      [110.3627, -7.7873],
      [110.3628, -7.7864],
      [110.3631, -7.7851],
      [110.3634, -7.7829],
      [110.363, -7.7828],
      [110.3611, -7.7827],
      [110.3608, -7.7824],
      [110.3609, -7.782],
      [110.3607, -7.7815],
      [110.3608, -7.7803]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'JETIS',
    village: 'COKRODININGRATAN',
    border: [
      [110.3693, -7.7738],
      [110.3688, -7.7736],
      [110.368, -7.7734],
      [110.3676, -7.7733],
      [110.3674, -7.7737],
      [110.3672, -7.7741],
      [110.3672, -7.7745],
      [110.3671, -7.7746],
      [110.3665, -7.7744],
      [110.3665, -7.775],
      [110.3664, -7.7755],
      [110.3657, -7.7756],
      [110.3657, -7.7757],
      [110.3654, -7.7757],
      [110.3652, -7.7762],
      [110.3652, -7.777],
      [110.3652, -7.7775],
      [110.3652, -7.7776],
      [110.3651, -7.779],
      [110.365, -7.7804],
      [110.3642, -7.7803],
      [110.3636, -7.78],
      [110.3634, -7.7805],
      [110.3629, -7.7805],
      [110.3619, -7.7803],
      [110.3608, -7.7803],
      [110.3607, -7.7815],
      [110.3609, -7.782],
      [110.3609, -7.7821],
      [110.3634, -7.7822],
      [110.367, -7.7822],
      [110.3669, -7.7831],
      [110.3698, -7.7831],
      [110.3709, -7.7831],
      [110.3711, -7.7831],
      [110.3711, -7.7827],
      [110.3714, -7.7823],
      [110.3716, -7.7818],
      [110.3716, -7.7814],
      [110.3712, -7.7811],
      [110.3705, -7.7809],
      [110.3702, -7.7807],
      [110.3702, -7.7802],
      [110.3704, -7.7798],
      [110.3704, -7.7795],
      [110.3704, -7.7791],
      [110.3705, -7.7787],
      [110.3709, -7.7781],
      [110.3713, -7.7778],
      [110.3714, -7.7773],
      [110.3711, -7.7767],
      [110.3707, -7.776],
      [110.3701, -7.7758],
      [110.3702, -7.7752],
      [110.37, -7.7751],
      [110.3699, -7.7749],
      [110.3693, -7.7745],
      [110.3693, -7.7738],
      [110.3693, -7.7738]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'JETIS',
    village: 'GOWONGAN',
    border: [
      [110.3711, -7.7831],
      [110.3709, -7.7831],
      [110.3698, -7.7831],
      [110.3669, -7.7831],
      [110.367, -7.7822],
      [110.3634, -7.7822],
      [110.3609, -7.7821],
      [110.3609, -7.782],
      [110.3608, -7.7824],
      [110.3611, -7.7827],
      [110.363, -7.7828],
      [110.3634, -7.7829],
      [110.3631, -7.7851],
      [110.3628, -7.7864],
      [110.3627, -7.7873],
      [110.3628, -7.7873],
      [110.3655, -7.7878],
      [110.3657, -7.7877],
      [110.3663, -7.7878],
      [110.3663, -7.7883],
      [110.3662, -7.7895],
      [110.3669, -7.7896],
      [110.3676, -7.7897],
      [110.3686, -7.7898],
      [110.3689, -7.7898],
      [110.369, -7.7895],
      [110.3687, -7.789],
      [110.3686, -7.7884],
      [110.3683, -7.7875],
      [110.3682, -7.787],
      [110.3684, -7.7866],
      [110.3688, -7.7862],
      [110.3689, -7.7857],
      [110.3696, -7.7853],
      [110.3703, -7.7849],
      [110.3708, -7.7844],
      [110.3711, -7.784],
      [110.3711, -7.7834],
      [110.3711, -7.7831],
      [110.3711, -7.7831]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GONDOKUSUMAN',
    village: 'DEMANGAN',
    border: [
      [110.3959, -7.7885],
      [110.3954, -7.7881],
      [110.3952, -7.7876],
      [110.3948, -7.787],
      [110.3944, -7.7867],
      [110.3934, -7.7862],
      [110.3924, -7.7858],
      [110.3921, -7.7855],
      [110.3913, -7.7841],
      [110.3902, -7.7822],
      [110.3894, -7.7809],
      [110.3888, -7.7801],
      [110.3884, -7.7799],
      [110.3883, -7.7805],
      [110.388, -7.782],
      [110.3879, -7.783],
      [110.3881, -7.7832],
      [110.3883, -7.7843],
      [110.3882, -7.785],
      [110.3881, -7.7853],
      [110.3875, -7.786],
      [110.387, -7.7862],
      [110.3861, -7.7863],
      [110.3836, -7.7863],
      [110.3823, -7.7863],
      [110.382, -7.7872],
      [110.3815, -7.7874],
      [110.3814, -7.7876],
      [110.3806, -7.7878],
      [110.3809, -7.7886],
      [110.3809, -7.789],
      [110.3809, -7.7897],
      [110.3827, -7.7895],
      [110.3851, -7.7894],
      [110.387, -7.7893],
      [110.3895, -7.789],
      [110.3912, -7.7889],
      [110.3931, -7.7887],
      [110.3949, -7.7886],
      [110.3959, -7.7885]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GONDOKUSUMAN',
    village: 'KOTABARU',
    border: [
      [110.3791, -7.7833],
      [110.3741, -7.7832],
      [110.3724, -7.7832],
      [110.3711, -7.7831],
      [110.3711, -7.7834],
      [110.3711, -7.784],
      [110.3708, -7.7844],
      [110.3703, -7.7849],
      [110.3696, -7.7853],
      [110.3689, -7.7857],
      [110.3688, -7.7862],
      [110.3684, -7.7866],
      [110.3682, -7.787],
      [110.3683, -7.7875],
      [110.3686, -7.7884],
      [110.3687, -7.789],
      [110.369, -7.7895],
      [110.3689, -7.7898],
      [110.37, -7.7901],
      [110.3716, -7.7902],
      [110.3729, -7.7903],
      [110.3729, -7.7903],
      [110.3732, -7.7901],
      [110.3738, -7.7897],
      [110.374, -7.7891],
      [110.375, -7.789],
      [110.377, -7.7887],
      [110.3783, -7.7883],
      [110.3786, -7.7864],
      [110.3791, -7.7833]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GONDOKUSUMAN',
    village: 'KLITREN',
    border: [
      [110.3884, -7.7799],
      [110.3875, -7.7795],
      [110.3856, -7.7792],
      [110.3839, -7.7789],
      [110.3809, -7.7786],
      [110.3808, -7.7786],
      [110.3806, -7.7786],
      [110.3807, -7.7795],
      [110.3809, -7.7808],
      [110.3806, -7.7815],
      [110.3809, -7.7822],
      [110.3811, -7.783],
      [110.3791, -7.7829],
      [110.3791, -7.7833],
      [110.3786, -7.7864],
      [110.3783, -7.7883],
      [110.3781, -7.79],
      [110.3785, -7.7899],
      [110.3805, -7.7898],
      [110.3807, -7.7897],
      [110.3809, -7.7897],
      [110.3809, -7.789],
      [110.3809, -7.7886],
      [110.3806, -7.7878],
      [110.3814, -7.7876],
      [110.3815, -7.7874],
      [110.382, -7.7872],
      [110.3823, -7.7863],
      [110.3836, -7.7863],
      [110.3861, -7.7863],
      [110.387, -7.7862],
      [110.3875, -7.786],
      [110.3881, -7.7853],
      [110.3882, -7.785],
      [110.3883, -7.7843],
      [110.3881, -7.7832],
      [110.3879, -7.783],
      [110.388, -7.782],
      [110.3883, -7.7805],
      [110.3884, -7.7799]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GONDOKUSUMAN',
    village: 'BACIRO',
    border: [
      [110.3877, -7.7945],
      [110.3882, -7.7933],
      [110.3907, -7.794],
      [110.3912, -7.7939],
      [110.3926, -7.7929],
      [110.3933, -7.7926],
      [110.3944, -7.792],
      [110.3949, -7.7917],
      [110.3949, -7.791],
      [110.3951, -7.7903],
      [110.3953, -7.7899],
      [110.3955, -7.7896],
      [110.3956, -7.7892],
      [110.3958, -7.789],
      [110.3961, -7.7888],
      [110.396, -7.7884],
      [110.3959, -7.7885],
      [110.3949, -7.7886],
      [110.3931, -7.7887],
      [110.3912, -7.7889],
      [110.3895, -7.789],
      [110.387, -7.7893],
      [110.3851, -7.7894],
      [110.3827, -7.7895],
      [110.3809, -7.7897],
      [110.3807, -7.7897],
      [110.3805, -7.7898],
      [110.3785, -7.7899],
      [110.3781, -7.79],
      [110.3778, -7.7908],
      [110.3777, -7.7924],
      [110.3778, -7.7939],
      [110.3777, -7.7953],
      [110.3775, -7.7973],
      [110.3775, -7.7975],
      [110.3779, -7.7981],
      [110.379, -7.7982],
      [110.38, -7.7983],
      [110.3804, -7.7984],
      [110.3805, -7.7984],
      [110.3805, -7.7982],
      [110.3807, -7.7975],
      [110.3806, -7.7967],
      [110.3805, -7.7962],
      [110.3807, -7.7956],
      [110.3807, -7.795],
      [110.3804, -7.7945],
      [110.3803, -7.7941],
      [110.3824, -7.7945],
      [110.3825, -7.794],
      [110.3826, -7.7936],
      [110.3833, -7.7936],
      [110.3838, -7.7938],
      [110.3843, -7.7939],
      [110.385, -7.7941],
      [110.3861, -7.7943],
      [110.387, -7.7944],
      [110.3877, -7.7945]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GONDOKUSUMAN',
    village: 'TERBAN',
    border: [
      [110.3806, -7.7786],
      [110.3803, -7.7785],
      [110.3799, -7.7782],
      [110.3794, -7.7773],
      [110.3789, -7.7767],
      [110.3786, -7.7765],
      [110.3779, -7.7764],
      [110.3769, -7.7765],
      [110.3756, -7.7765],
      [110.3751, -7.7763],
      [110.3741, -7.7761],
      [110.3733, -7.7758],
      [110.3728, -7.7756],
      [110.3728, -7.7757],
      [110.3726, -7.7757],
      [110.3719, -7.7754],
      [110.3714, -7.7753],
      [110.3709, -7.7752],
      [110.3702, -7.7752],
      [110.3701, -7.7758],
      [110.3707, -7.776],
      [110.3711, -7.7767],
      [110.3714, -7.7773],
      [110.3713, -7.7778],
      [110.3709, -7.7781],
      [110.3705, -7.7787],
      [110.3704, -7.7791],
      [110.3704, -7.7795],
      [110.3704, -7.7798],
      [110.3702, -7.7802],
      [110.3702, -7.7807],
      [110.3705, -7.7809],
      [110.3712, -7.7811],
      [110.3716, -7.7814],
      [110.3716, -7.7818],
      [110.3714, -7.7823],
      [110.3711, -7.7827],
      [110.3711, -7.7831],
      [110.3711, -7.7831],
      [110.3724, -7.7832],
      [110.3741, -7.7832],
      [110.3791, -7.7833],
      [110.3791, -7.7829],
      [110.3811, -7.783],
      [110.3809, -7.7822],
      [110.3806, -7.7815],
      [110.3809, -7.7808],
      [110.3807, -7.7795],
      [110.3806, -7.7786]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'DANUREJAN',
    village: 'SURYATMAJAN',
    border: [
      [110.3689, -7.7898],
      [110.3686, -7.7898],
      [110.3676, -7.7897],
      [110.3669, -7.7896],
      [110.3662, -7.7895],
      [110.3661, -7.79],
      [110.3658, -7.7923],
      [110.3655, -7.7943],
      [110.3652, -7.7965],
      [110.3654, -7.7965],
      [110.3657, -7.7965],
      [110.3672, -7.7966],
      [110.3684, -7.7967],
      [110.3692, -7.7968],
      [110.37, -7.7968],
      [110.3702, -7.7962],
      [110.3702, -7.7958],
      [110.3703, -7.7953],
      [110.3702, -7.7948],
      [110.3696, -7.7937],
      [110.3691, -7.7933],
      [110.3687, -7.7924],
      [110.3685, -7.7918],
      [110.3687, -7.7909],
      [110.3689, -7.7898]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'DANUREJAN',
    village: 'TEGALPANGGUNG',
    border: [
      [110.3729, -7.7903],
      [110.3716, -7.7902],
      [110.37, -7.7901],
      [110.3689, -7.7898],
      [110.3687, -7.7909],
      [110.3685, -7.7918],
      [110.3687, -7.7924],
      [110.3691, -7.7933],
      [110.3696, -7.7937],
      [110.3702, -7.7948],
      [110.3703, -7.7953],
      [110.3702, -7.7958],
      [110.3702, -7.7962],
      [110.37, -7.7968],
      [110.3712, -7.7968],
      [110.3719, -7.7969],
      [110.3726, -7.797],
      [110.3724, -7.7963],
      [110.3724, -7.7957],
      [110.3729, -7.7936],
      [110.3737, -7.7912],
      [110.3739, -7.7906],
      [110.3729, -7.7906],
      [110.3729, -7.7903]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'DANUREJAN',
    village: 'BAUSASRAN',
    border: [
      [110.3781, -7.79],
      [110.3783, -7.7883],
      [110.377, -7.7887],
      [110.375, -7.789],
      [110.374, -7.7891],
      [110.3738, -7.7897],
      [110.3732, -7.7901],
      [110.3729, -7.7903],
      [110.3729, -7.7906],
      [110.3739, -7.7906],
      [110.3737, -7.7912],
      [110.3729, -7.7936],
      [110.3724, -7.7957],
      [110.3724, -7.7963],
      [110.3726, -7.797],
      [110.3733, -7.797],
      [110.3769, -7.7973],
      [110.3775, -7.7973],
      [110.3777, -7.7953],
      [110.3778, -7.7939],
      [110.3777, -7.7924],
      [110.3778, -7.7908],
      [110.3781, -7.79]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GEDONGTENGEN',
    village: 'SOSROMENDURAN',
    border: [
      [110.3662, -7.7895],
      [110.3663, -7.7883],
      [110.3663, -7.7878],
      [110.3657, -7.7877],
      [110.3655, -7.7878],
      [110.3628, -7.7873],
      [110.3626, -7.7879],
      [110.3626, -7.7885],
      [110.3618, -7.7892],
      [110.3608, -7.7892],
      [110.3597, -7.789],
      [110.3594, -7.789],
      [110.3594, -7.7895],
      [110.3616, -7.7897],
      [110.3622, -7.7899],
      [110.3619, -7.7911],
      [110.3613, -7.7936],
      [110.3613, -7.7955],
      [110.3619, -7.796],
      [110.362, -7.7963],
      [110.3623, -7.7962],
      [110.3635, -7.7963],
      [110.3646, -7.7964],
      [110.3652, -7.7965],
      [110.3655, -7.7943],
      [110.3658, -7.7923],
      [110.3661, -7.79],
      [110.3662, -7.7895]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GEDONGTENGEN',
    village: 'PRINGGOKUSUMAN',
    border: [
      [110.3594, -7.789],
      [110.3574, -7.7888],
      [110.3554, -7.7887],
      [110.3552, -7.7892],
      [110.3552, -7.7894],
      [110.3553, -7.7894],
      [110.3559, -7.7895],
      [110.3567, -7.7897],
      [110.357, -7.7898],
      [110.3571, -7.7902],
      [110.3571, -7.7905],
      [110.3568, -7.7911],
      [110.3562, -7.7914],
      [110.3558, -7.7917],
      [110.3556, -7.792],
      [110.3556, -7.7923],
      [110.3558, -7.7927],
      [110.3558, -7.793],
      [110.3558, -7.7933],
      [110.3556, -7.7936],
      [110.3554, -7.7938],
      [110.3556, -7.794],
      [110.3555, -7.7942],
      [110.3555, -7.7945],
      [110.3553, -7.7949],
      [110.3555, -7.7952],
      [110.3555, -7.7956],
      [110.3555, -7.7958],
      [110.3562, -7.7957],
      [110.3568, -7.7957],
      [110.3579, -7.7957],
      [110.3588, -7.7958],
      [110.3599, -7.7959],
      [110.3614, -7.7963],
      [110.362, -7.7963],
      [110.3619, -7.796],
      [110.3613, -7.7955],
      [110.3613, -7.7936],
      [110.3619, -7.7911],
      [110.3622, -7.7899],
      [110.3616, -7.7897],
      [110.3594, -7.7895],
      [110.3594, -7.789]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'NGAMPILAN',
    village: 'NGAMPILAN',
    border: [
      [110.362, -7.7963],
      [110.3614, -7.7963],
      [110.3599, -7.7959],
      [110.3588, -7.7958],
      [110.3579, -7.7957],
      [110.3568, -7.7957],
      [110.3562, -7.7957],
      [110.3555, -7.7958],
      [110.3553, -7.7962],
      [110.3552, -7.7972],
      [110.3552, -7.7979],
      [110.355, -7.7981],
      [110.3551, -7.7983],
      [110.3553, -7.7985],
      [110.3556, -7.7991],
      [110.3555, -7.7997],
      [110.3552, -7.8005],
      [110.355, -7.801],
      [110.3553, -7.801],
      [110.3574, -7.801],
      [110.3596, -7.8011],
      [110.361, -7.8012],
      [110.3618, -7.8011],
      [110.362, -7.801],
      [110.362, -7.7996],
      [110.3621, -7.7981],
      [110.3621, -7.7976],
      [110.3621, -7.797],
      [110.362, -7.7963]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'NGAMPILAN',
    village: 'NOTOPRAJAN',
    border: [
      [110.361, -7.8012],
      [110.3596, -7.8011],
      [110.3574, -7.801],
      [110.3553, -7.801],
      [110.355, -7.801],
      [110.3548, -7.8013],
      [110.3543, -7.8024],
      [110.3546, -7.8036],
      [110.3547, -7.8042],
      [110.3544, -7.8045],
      [110.354, -7.8047],
      [110.3539, -7.8049],
      [110.354, -7.8051],
      [110.3541, -7.8053],
      [110.3544, -7.8055],
      [110.3549, -7.8055],
      [110.3551, -7.8056],
      [110.3553, -7.8058],
      [110.3554, -7.8061],
      [110.3552, -7.8064],
      [110.3549, -7.8064],
      [110.3544, -7.8064],
      [110.3543, -7.8066],
      [110.354, -7.8072],
      [110.3538, -7.8082],
      [110.3558, -7.8086],
      [110.3559, -7.8082],
      [110.3559, -7.8071],
      [110.356, -7.806],
      [110.356, -7.8049],
      [110.356, -7.8044],
      [110.3562, -7.8041],
      [110.3565, -7.8041],
      [110.3585, -7.8043],
      [110.3605, -7.8044],
      [110.3606, -7.8035],
      [110.3609, -7.802],
      [110.361, -7.8012]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'WIROBRAJAN',
    village: 'PAKUNCEN',
    border: [
      [110.3555, -7.7942],
      [110.3554, -7.7942],
      [110.3538, -7.7941],
      [110.3534, -7.794],
      [110.3535, -7.7931],
      [110.3513, -7.7929],
      [110.3504, -7.7928],
      [110.3502, -7.7928],
      [110.3501, -7.7929],
      [110.35, -7.7932],
      [110.35, -7.7934],
      [110.3495, -7.7934],
      [110.349, -7.7933],
      [110.3487, -7.7932],
      [110.3487, -7.7934],
      [110.3483, -7.7933],
      [110.348, -7.7933],
      [110.3475, -7.7933],
      [110.3474, -7.7935],
      [110.3473, -7.7938],
      [110.3474, -7.7942],
      [110.3479, -7.7941],
      [110.348, -7.7945],
      [110.3495, -7.7942],
      [110.3495, -7.795],
      [110.3489, -7.795],
      [110.3475, -7.7952],
      [110.3476, -7.7957],
      [110.3476, -7.796],
      [110.3474, -7.7961],
      [110.3473, -7.7964],
      [110.3471, -7.7967],
      [110.347, -7.7972],
      [110.3468, -7.7977],
      [110.3467, -7.7979],
      [110.347, -7.798],
      [110.3469, -7.7987],
      [110.3473, -7.799],
      [110.347, -7.7999],
      [110.3468, -7.8008],
      [110.3467, -7.8011],
      [110.3493, -7.8012],
      [110.353, -7.8013],
      [110.3546, -7.8013],
      [110.3548, -7.8013],
      [110.355, -7.801],
      [110.3552, -7.8005],
      [110.3555, -7.7997],
      [110.3556, -7.7991],
      [110.3553, -7.7985],
      [110.3551, -7.7983],
      [110.355, -7.7981],
      [110.3552, -7.7979],
      [110.3552, -7.7972],
      [110.3553, -7.7962],
      [110.3555, -7.7958],
      [110.3555, -7.7956],
      [110.3555, -7.7952],
      [110.3553, -7.7949],
      [110.3555, -7.7945],
      [110.3555, -7.7942]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'WIROBRAJAN',
    village: 'WIROBRAJAN',
    border: [
      [110.3548, -7.8013],
      [110.3546, -7.8013],
      [110.353, -7.8013],
      [110.3493, -7.8012],
      [110.3467, -7.8011],
      [110.3467, -7.8019],
      [110.3468, -7.802],
      [110.3468, -7.8022],
      [110.3468, -7.8024],
      [110.3467, -7.8025],
      [110.3465, -7.8027],
      [110.3463, -7.8031],
      [110.3463, -7.8035],
      [110.346, -7.8042],
      [110.3457, -7.8049],
      [110.3453, -7.8059],
      [110.3451, -7.8065],
      [110.3446, -7.8068],
      [110.3445, -7.8069],
      [110.3443, -7.807],
      [110.3451, -7.8072],
      [110.3492, -7.8077],
      [110.3522, -7.8081],
      [110.3533, -7.8083],
      [110.3536, -7.8085],
      [110.3538, -7.8086],
      [110.3538, -7.8082],
      [110.354, -7.8072],
      [110.3543, -7.8066],
      [110.3544, -7.8064],
      [110.3549, -7.8064],
      [110.3552, -7.8064],
      [110.3554, -7.8061],
      [110.3553, -7.8058],
      [110.3551, -7.8056],
      [110.3549, -7.8055],
      [110.3544, -7.8055],
      [110.3541, -7.8053],
      [110.354, -7.8051],
      [110.3539, -7.8049],
      [110.354, -7.8047],
      [110.3544, -7.8045],
      [110.3547, -7.8042],
      [110.3546, -7.8036],
      [110.3543, -7.8024],
      [110.3548, -7.8013]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'WIROBRAJAN',
    village: 'PATANGPULUHAN',
    border: [
      [110.3538, -7.8086],
      [110.3536, -7.8085],
      [110.3533, -7.8083],
      [110.3522, -7.8081],
      [110.3492, -7.8077],
      [110.3451, -7.8072],
      [110.3443, -7.807],
      [110.3443, -7.8073],
      [110.3443, -7.8075],
      [110.3446, -7.8076],
      [110.3448, -7.8078],
      [110.345, -7.808],
      [110.3451, -7.8082],
      [110.3452, -7.8085],
      [110.3452, -7.8089],
      [110.3452, -7.8093],
      [110.3452, -7.8096],
      [110.3455, -7.8099],
      [110.3456, -7.81],
      [110.3457, -7.8102],
      [110.3458, -7.8106],
      [110.3456, -7.811],
      [110.3456, -7.8113],
      [110.3457, -7.8114],
      [110.3503, -7.8121],
      [110.3517, -7.8123],
      [110.3522, -7.8124],
      [110.3522, -7.8116],
      [110.352, -7.8105],
      [110.3521, -7.8101],
      [110.3528, -7.8096],
      [110.3538, -7.8086]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'MANTRIJERON',
    village: 'GEDONGKIWO',
    border: [
      [110.3558, -7.8086],
      [110.3538, -7.8082],
      [110.3538, -7.8086],
      [110.3528, -7.8096],
      [110.3521, -7.8101],
      [110.352, -7.8105],
      [110.3522, -7.8116],
      [110.3522, -7.8124],
      [110.352, -7.813],
      [110.3516, -7.8135],
      [110.3514, -7.8139],
      [110.3512, -7.8143],
      [110.3511, -7.8145],
      [110.3512, -7.8146],
      [110.3514, -7.8148],
      [110.3518, -7.8148],
      [110.3521, -7.8148],
      [110.3522, -7.8152],
      [110.3523, -7.8158],
      [110.3525, -7.8159],
      [110.3526, -7.8163],
      [110.3524, -7.8166],
      [110.3522, -7.8169],
      [110.3521, -7.8171],
      [110.3518, -7.8172],
      [110.3515, -7.8173],
      [110.3514, -7.8174],
      [110.3513, -7.8175],
      [110.3512, -7.8178],
      [110.3511, -7.8183],
      [110.3511, -7.8189],
      [110.351, -7.8198],
      [110.3509, -7.8208],
      [110.3508, -7.8214],
      [110.351, -7.8218],
      [110.351, -7.822],
      [110.3513, -7.8222],
      [110.3515, -7.8224],
      [110.3517, -7.8225],
      [110.3522, -7.823],
      [110.3524, -7.8234],
      [110.3526, -7.8236],
      [110.3525, -7.8238],
      [110.3523, -7.8239],
      [110.352, -7.8239],
      [110.3518, -7.824],
      [110.3516, -7.8243],
      [110.3515, -7.8248],
      [110.3514, -7.8252],
      [110.3516, -7.8256],
      [110.3519, -7.8256],
      [110.3522, -7.8256],
      [110.3532, -7.8258],
      [110.3546, -7.826],
      [110.3556, -7.826],
      [110.3557, -7.8253],
      [110.3555, -7.8249],
      [110.3557, -7.8246],
      [110.3557, -7.8235],
      [110.356, -7.8222],
      [110.3563, -7.8212],
      [110.3563, -7.82],
      [110.3564, -7.8195],
      [110.3567, -7.8189],
      [110.3569, -7.8175],
      [110.3561, -7.8175],
      [110.3561, -7.8152],
      [110.3561, -7.813],
      [110.356, -7.8129],
      [110.3561, -7.8114],
      [110.3562, -7.8087],
      [110.3558, -7.8086]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'MANTRIJERON',
    village: 'SURYODININGRATAN',
    border: [
      [110.3629, -7.814],
      [110.3611, -7.8137],
      [110.3578, -7.8133],
      [110.3574, -7.8132],
      [110.3561, -7.813],
      [110.3561, -7.8152],
      [110.3561, -7.8175],
      [110.3569, -7.8175],
      [110.3567, -7.8189],
      [110.3564, -7.8195],
      [110.3563, -7.82],
      [110.3563, -7.8212],
      [110.356, -7.8222],
      [110.3557, -7.8235],
      [110.3557, -7.8246],
      [110.3555, -7.8249],
      [110.3557, -7.8253],
      [110.3556, -7.826],
      [110.3561, -7.826],
      [110.3572, -7.8261],
      [110.3585, -7.8262],
      [110.3594, -7.8261],
      [110.3603, -7.8261],
      [110.3609, -7.826],
      [110.3613, -7.8237],
      [110.3618, -7.8209],
      [110.3622, -7.8183],
      [110.3629, -7.814]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'MANTRIJERON',
    village: 'MANTRIJERON',
    border: [
      [110.3687, -7.8147],
      [110.3664, -7.8143],
      [110.3643, -7.8141],
      [110.3633, -7.8141],
      [110.3629, -7.814],
      [110.3622, -7.8183],
      [110.3618, -7.8209],
      [110.3613, -7.8237],
      [110.3609, -7.826],
      [110.3609, -7.8263],
      [110.3615, -7.8264],
      [110.362, -7.8264],
      [110.3638, -7.8265],
      [110.3654, -7.8266],
      [110.3656, -7.8266],
      [110.3664, -7.8266],
      [110.3661, -7.8282],
      [110.3674, -7.8284],
      [110.3674, -7.8273],
      [110.3678, -7.8273],
      [110.3677, -7.827],
      [110.3678, -7.8256],
      [110.3679, -7.8234],
      [110.3681, -7.8206],
      [110.3682, -7.8191],
      [110.3684, -7.8169],
      [110.3685, -7.8153],
      [110.3687, -7.8148],
      [110.3687, -7.8147]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'KRATON',
    village: 'PATEHAN',
    border: [
      [110.3631, -7.8088],
      [110.3614, -7.8087],
      [110.3609, -7.8083],
      [110.3601, -7.8081],
      [110.3588, -7.8079],
      [110.3587, -7.8085],
      [110.358, -7.8088],
      [110.3577, -7.8105],
      [110.3577, -7.8116],
      [110.3561, -7.8114],
      [110.356, -7.8129],
      [110.3561, -7.813],
      [110.3574, -7.8132],
      [110.3578, -7.8133],
      [110.3611, -7.8137],
      [110.3629, -7.814],
      [110.3631, -7.8125],
      [110.3637, -7.8126],
      [110.364, -7.8115],
      [110.3636, -7.8113],
      [110.3639, -7.8091],
      [110.3635, -7.8088],
      [110.3631, -7.8088]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'KRATON',
    village: 'PANEMBAHAN',
    border: [
      [110.3647, -7.8028],
      [110.3645, -7.8028],
      [110.3642, -7.8051],
      [110.3643, -7.8055],
      [110.3641, -7.8066],
      [110.3638, -7.8066],
      [110.3636, -7.8069],
      [110.3629, -7.8067],
      [110.3628, -7.8071],
      [110.3625, -7.808],
      [110.3631, -7.8081],
      [110.3631, -7.8084],
      [110.3631, -7.8088],
      [110.3635, -7.8088],
      [110.3639, -7.8091],
      [110.3636, -7.8113],
      [110.364, -7.8115],
      [110.3637, -7.8126],
      [110.3631, -7.8125],
      [110.3629, -7.814],
      [110.3633, -7.8141],
      [110.3643, -7.8141],
      [110.3664, -7.8143],
      [110.3687, -7.8147],
      [110.3688, -7.8145],
      [110.3689, -7.8138],
      [110.369, -7.8122],
      [110.369, -7.8106],
      [110.3692, -7.8089],
      [110.3693, -7.808],
      [110.3693, -7.8079],
      [110.3694, -7.806],
      [110.3695, -7.8045],
      [110.3695, -7.8041],
      [110.3693, -7.8035],
      [110.3687, -7.8035],
      [110.368, -7.8035],
      [110.3671, -7.8033],
      [110.3662, -7.8033],
      [110.3659, -7.8032],
      [110.3657, -7.803],
      [110.365, -7.8029],
      [110.3647, -7.8028]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'KRATON',
    village: 'KADIPATEN',
    border: [
      [110.3605, -7.8044],
      [110.3585, -7.8043],
      [110.3565, -7.8041],
      [110.3562, -7.8041],
      [110.356, -7.8044],
      [110.356, -7.8049],
      [110.356, -7.806],
      [110.3559, -7.8071],
      [110.3559, -7.8082],
      [110.3558, -7.8086],
      [110.3562, -7.8087],
      [110.3561, -7.8114],
      [110.3577, -7.8116],
      [110.3577, -7.8105],
      [110.358, -7.8088],
      [110.3587, -7.8085],
      [110.3588, -7.8079],
      [110.3601, -7.8081],
      [110.3609, -7.8083],
      [110.3614, -7.8087],
      [110.3631, -7.8088],
      [110.3631, -7.8084],
      [110.3631, -7.8081],
      [110.3625, -7.808],
      [110.3628, -7.8071],
      [110.3629, -7.8067],
      [110.3636, -7.8069],
      [110.3638, -7.8066],
      [110.3641, -7.8066],
      [110.3643, -7.8055],
      [110.3642, -7.8051],
      [110.3633, -7.8051],
      [110.3633, -7.8047],
      [110.3612, -7.8045],
      [110.3605, -7.8044]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GONDOMANAN',
    village: 'NGUPASAN',
    border: [
      [110.3713, -7.8016],
      [110.3711, -7.8016],
      [110.3691, -7.8016],
      [110.3677, -7.8016],
      [110.3659, -7.8015],
      [110.3648, -7.8015],
      [110.3647, -7.8024],
      [110.3647, -7.8028],
      [110.365, -7.8029],
      [110.3657, -7.803],
      [110.3659, -7.8032],
      [110.3662, -7.8033],
      [110.3671, -7.8033],
      [110.368, -7.8035],
      [110.3687, -7.8035],
      [110.3693, -7.8035],
      [110.3695, -7.8041],
      [110.3695, -7.8045],
      [110.3694, -7.806],
      [110.3693, -7.8079],
      [110.3693, -7.808],
      [110.3696, -7.808],
      [110.3713, -7.8079],
      [110.3721, -7.8078],
      [110.3731, -7.8077],
      [110.3737, -7.8078],
      [110.3738, -7.8063],
      [110.3738, -7.8061],
      [110.374, -7.806],
      [110.3742, -7.806],
      [110.3742, -7.8054],
      [110.3741, -7.805],
      [110.3734, -7.8048],
      [110.3726, -7.8046],
      [110.3724, -7.8045],
      [110.3719, -7.804],
      [110.3716, -7.8036],
      [110.3715, -7.803],
      [110.3713, -7.8016]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'GONDOMANAN',
    village: 'PRAWIRODIRJAN',
    border: [
      [110.3652, -7.7965],
      [110.3646, -7.7964],
      [110.3635, -7.7963],
      [110.3623, -7.7962],
      [110.362, -7.7963],
      [110.3621, -7.797],
      [110.3621, -7.7976],
      [110.3621, -7.7981],
      [110.362, -7.7996],
      [110.362, -7.801],
      [110.3618, -7.8011],
      [110.361, -7.8012],
      [110.3609, -7.802],
      [110.3606, -7.8035],
      [110.3605, -7.8044],
      [110.3612, -7.8045],
      [110.3633, -7.8047],
      [110.3633, -7.8051],
      [110.3642, -7.8051],
      [110.3645, -7.8028],
      [110.3647, -7.8028],
      [110.3647, -7.8024],
      [110.3648, -7.8015],
      [110.3659, -7.8015],
      [110.3677, -7.8016],
      [110.3691, -7.8016],
      [110.3711, -7.8016],
      [110.3713, -7.8016],
      [110.3712, -7.8014],
      [110.3711, -7.8011],
      [110.3709, -7.8006],
      [110.3709, -7.8002],
      [110.3708, -7.7996],
      [110.3703, -7.7989],
      [110.3703, -7.7988],
      [110.3697, -7.7979],
      [110.3697, -7.7974],
      [110.3698, -7.7972],
      [110.37, -7.7968],
      [110.3692, -7.7968],
      [110.3684, -7.7967],
      [110.3672, -7.7966],
      [110.3657, -7.7965],
      [110.3654, -7.7965],
      [110.3652, -7.7965]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'PAKUALAMAN',
    village: 'PURWOKINANTI',
    border: [
      [110.3726, -7.797],
      [110.3719, -7.7969],
      [110.3712, -7.7968],
      [110.37, -7.7968],
      [110.3698, -7.7972],
      [110.3697, -7.7974],
      [110.3697, -7.7979],
      [110.3703, -7.7988],
      [110.3703, -7.7989],
      [110.3708, -7.7996],
      [110.3709, -7.8002],
      [110.3709, -7.8006],
      [110.3711, -7.8011],
      [110.3712, -7.8014],
      [110.3718, -7.8015],
      [110.3731, -7.8014],
      [110.3731, -7.8009],
      [110.375, -7.8008],
      [110.3756, -7.8009],
      [110.3757, -7.8011],
      [110.3757, -7.8018],
      [110.3769, -7.8018],
      [110.3769, -7.7991],
      [110.3769, -7.7976],
      [110.3769, -7.7973],
      [110.3733, -7.797],
      [110.3726, -7.797]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'PAKUALAMAN',
    village: 'GUNUNGKETUR',
    border: [
      [110.3805, -7.7984],
      [110.3804, -7.7984],
      [110.38, -7.7983],
      [110.379, -7.7982],
      [110.3779, -7.7981],
      [110.3775, -7.7975],
      [110.3775, -7.7973],
      [110.3769, -7.7973],
      [110.3769, -7.7976],
      [110.3769, -7.7991],
      [110.3769, -7.8018],
      [110.3757, -7.8018],
      [110.3757, -7.8011],
      [110.3756, -7.8009],
      [110.375, -7.8008],
      [110.3731, -7.8009],
      [110.3731, -7.8014],
      [110.3736, -7.8015],
      [110.3743, -7.8015],
      [110.3751, -7.8015],
      [110.3751, -7.803],
      [110.3752, -7.8045],
      [110.3781, -7.8047],
      [110.3787, -7.8026],
      [110.3788, -7.8019],
      [110.3797, -7.8019],
      [110.3805, -7.8019],
      [110.3807, -7.8019],
      [110.3805, -7.801],
      [110.3806, -7.7989],
      [110.3805, -7.7984]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'MERGANGSAN',
    village: 'KEPARAKAN',
    border: [
      [110.3737, -7.8078],
      [110.3731, -7.8077],
      [110.3721, -7.8078],
      [110.3713, -7.8079],
      [110.3696, -7.808],
      [110.3693, -7.808],
      [110.3692, -7.8089],
      [110.369, -7.8106],
      [110.369, -7.8122],
      [110.3689, -7.8138],
      [110.3688, -7.8145],
      [110.3687, -7.8147],
      [110.3687, -7.8148],
      [110.3696, -7.8149],
      [110.3713, -7.8152],
      [110.3723, -7.8154],
      [110.3737, -7.8156],
      [110.374, -7.8157],
      [110.374, -7.815],
      [110.3736, -7.8145],
      [110.3737, -7.8116],
      [110.3738, -7.8111],
      [110.3735, -7.8099],
      [110.3733, -7.809],
      [110.3737, -7.8085],
      [110.3737, -7.8078]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'MERGANGSAN',
    village: 'WIROGUNAN',
    border: [
      [110.3807, -7.8019],
      [110.3805, -7.8019],
      [110.3797, -7.8019],
      [110.3788, -7.8019],
      [110.3787, -7.8026],
      [110.3781, -7.8047],
      [110.3752, -7.8045],
      [110.3751, -7.803],
      [110.3751, -7.8015],
      [110.3743, -7.8015],
      [110.3736, -7.8015],
      [110.3731, -7.8014],
      [110.3718, -7.8015],
      [110.3712, -7.8014],
      [110.3713, -7.8016],
      [110.3715, -7.803],
      [110.3716, -7.8036],
      [110.3719, -7.804],
      [110.3724, -7.8045],
      [110.3726, -7.8046],
      [110.3734, -7.8048],
      [110.3741, -7.805],
      [110.3742, -7.8054],
      [110.3742, -7.806],
      [110.374, -7.806],
      [110.3738, -7.8061],
      [110.3738, -7.8063],
      [110.3737, -7.8078],
      [110.3737, -7.8085],
      [110.3733, -7.809],
      [110.3735, -7.8099],
      [110.3738, -7.8111],
      [110.3737, -7.8116],
      [110.3736, -7.8145],
      [110.374, -7.815],
      [110.374, -7.8157],
      [110.3746, -7.8157],
      [110.3754, -7.8158],
      [110.3763, -7.8159],
      [110.3764, -7.8151],
      [110.3766, -7.8138],
      [110.377, -7.8138],
      [110.3775, -7.8141],
      [110.3783, -7.8143],
      [110.3794, -7.8145],
      [110.3808, -7.815],
      [110.3813, -7.8152],
      [110.3816, -7.8152],
      [110.3816, -7.8133],
      [110.3816, -7.8123],
      [110.3807, -7.8123],
      [110.3807, -7.8114],
      [110.3806, -7.8111],
      [110.3801, -7.8103],
      [110.3798, -7.81],
      [110.379, -7.8098],
      [110.3791, -7.8087],
      [110.3791, -7.8085],
      [110.3791, -7.8078],
      [110.3794, -7.8067],
      [110.3797, -7.8061],
      [110.3801, -7.8057],
      [110.3806, -7.8054],
      [110.381, -7.8053],
      [110.3813, -7.8051],
      [110.3814, -7.8051],
      [110.381, -7.8034],
      [110.3807, -7.8022],
      [110.3807, -7.8019]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'MERGANGSAN',
    village: 'BRONTOKUSUMAN',
    border: [
      [110.374, -7.8157],
      [110.3737, -7.8156],
      [110.3723, -7.8154],
      [110.3713, -7.8152],
      [110.3696, -7.8149],
      [110.3687, -7.8148],
      [110.3685, -7.8153],
      [110.3684, -7.8169],
      [110.3682, -7.8191],
      [110.3681, -7.8206],
      [110.3679, -7.8234],
      [110.3678, -7.8256],
      [110.3677, -7.827],
      [110.3678, -7.8273],
      [110.368, -7.8271],
      [110.3682, -7.8271],
      [110.3686, -7.8271],
      [110.3686, -7.8265],
      [110.3689, -7.8256],
      [110.3693, -7.825],
      [110.3696, -7.8255],
      [110.37, -7.8256],
      [110.3709, -7.826],
      [110.3718, -7.8267],
      [110.3718, -7.8279],
      [110.372, -7.828],
      [110.3727, -7.8281],
      [110.3727, -7.8268],
      [110.3732, -7.8264],
      [110.3736, -7.8261],
      [110.3736, -7.8259],
      [110.3736, -7.8256],
      [110.3735, -7.825],
      [110.3737, -7.8247],
      [110.3739, -7.8244],
      [110.3744, -7.8245],
      [110.375, -7.8246],
      [110.3751, -7.8243],
      [110.3752, -7.824],
      [110.3755, -7.8232],
      [110.376, -7.8222],
      [110.3761, -7.8219],
      [110.3762, -7.8214],
      [110.3763, -7.8203],
      [110.3762, -7.8195],
      [110.376, -7.8186],
      [110.376, -7.8181],
      [110.3762, -7.8167],
      [110.3763, -7.8159],
      [110.3754, -7.8158],
      [110.3746, -7.8157],
      [110.374, -7.8157]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'UMBULHARJO',
    village: 'SEMAKI',
    border: [
      [110.3877, -7.7945],
      [110.387, -7.7944],
      [110.3861, -7.7943],
      [110.385, -7.7941],
      [110.3843, -7.7939],
      [110.3838, -7.7938],
      [110.3833, -7.7936],
      [110.3826, -7.7936],
      [110.3825, -7.794],
      [110.3824, -7.7945],
      [110.3803, -7.7941],
      [110.3804, -7.7945],
      [110.3807, -7.795],
      [110.3807, -7.7956],
      [110.3805, -7.7962],
      [110.3806, -7.7967],
      [110.3807, -7.7975],
      [110.3805, -7.7982],
      [110.3805, -7.7984],
      [110.3806, -7.7989],
      [110.3805, -7.801],
      [110.3807, -7.8019],
      [110.3822, -7.802],
      [110.3846, -7.802],
      [110.3866, -7.802],
      [110.3869, -7.8011],
      [110.3874, -7.7992],
      [110.3876, -7.7984],
      [110.3868, -7.7982],
      [110.3872, -7.7963],
      [110.3875, -7.7949],
      [110.3877, -7.7945]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'UMBULHARJO',
    village: 'MUJA MUJU',
    border: [
      [110.3979, -7.8021],
      [110.3978, -7.802],
      [110.3981, -7.8019],
      [110.3985, -7.8017],
      [110.3986, -7.8013],
      [110.3986, -7.8008],
      [110.3987, -7.8002],
      [110.3985, -7.7996],
      [110.3983, -7.7995],
      [110.398, -7.7989],
      [110.3977, -7.7982],
      [110.3972, -7.7975],
      [110.3966, -7.7961],
      [110.3964, -7.7952],
      [110.3962, -7.7947],
      [110.396, -7.7944],
      [110.396, -7.794],
      [110.396, -7.7936],
      [110.396, -7.7932],
      [110.3959, -7.7928],
      [110.396, -7.7925],
      [110.3961, -7.7923],
      [110.3962, -7.792],
      [110.3964, -7.7917],
      [110.3963, -7.7915],
      [110.3961, -7.7913],
      [110.3961, -7.7911],
      [110.3965, -7.7909],
      [110.3969, -7.7907],
      [110.3973, -7.7904],
      [110.3975, -7.7899],
      [110.3974, -7.7893],
      [110.3972, -7.7884],
      [110.3971, -7.7884],
      [110.396, -7.7884],
      [110.3961, -7.7888],
      [110.3958, -7.789],
      [110.3956, -7.7892],
      [110.3955, -7.7896],
      [110.3953, -7.7899],
      [110.3951, -7.7903],
      [110.3949, -7.791],
      [110.3949, -7.7917],
      [110.3944, -7.792],
      [110.3933, -7.7926],
      [110.3926, -7.7929],
      [110.3912, -7.7939],
      [110.3907, -7.794],
      [110.3882, -7.7933],
      [110.3877, -7.7945],
      [110.3875, -7.7949],
      [110.3872, -7.7963],
      [110.3868, -7.7982],
      [110.3876, -7.7984],
      [110.3874, -7.7992],
      [110.3869, -7.8011],
      [110.3866, -7.802],
      [110.3871, -7.802],
      [110.3871, -7.8019],
      [110.389, -7.802],
      [110.3908, -7.802],
      [110.3907, -7.803],
      [110.3905, -7.8053],
      [110.3925, -7.8072],
      [110.393, -7.8076],
      [110.3934, -7.8078],
      [110.3943, -7.8086],
      [110.3949, -7.809],
      [110.3952, -7.8087],
      [110.3957, -7.8083],
      [110.3962, -7.808],
      [110.3964, -7.8075],
      [110.3967, -7.8068],
      [110.3968, -7.8062],
      [110.3968, -7.8052],
      [110.3968, -7.8048],
      [110.3969, -7.8041],
      [110.3972, -7.8035],
      [110.3974, -7.803],
      [110.3974, -7.8024],
      [110.3974, -7.8021],
      [110.3976, -7.8021],
      [110.3979, -7.8021]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'UMBULHARJO',
    village: 'TAHUNAN',
    border: [
      [110.3866, -7.802],
      [110.3846, -7.802],
      [110.3822, -7.802],
      [110.3807, -7.8019],
      [110.3807, -7.8022],
      [110.381, -7.8034],
      [110.3814, -7.8051],
      [110.3813, -7.8051],
      [110.381, -7.8053],
      [110.3806, -7.8054],
      [110.3801, -7.8057],
      [110.3797, -7.8061],
      [110.3794, -7.8067],
      [110.3791, -7.8078],
      [110.3791, -7.8085],
      [110.3791, -7.8087],
      [110.379, -7.8098],
      [110.3798, -7.81],
      [110.3801, -7.8103],
      [110.3806, -7.8111],
      [110.3807, -7.8114],
      [110.3809, -7.8113],
      [110.381, -7.8116],
      [110.3818, -7.8115],
      [110.3818, -7.8109],
      [110.3826, -7.8108],
      [110.385, -7.8114],
      [110.3859, -7.8115],
      [110.3861, -7.811],
      [110.3865, -7.8098],
      [110.3866, -7.8089],
      [110.3866, -7.8085],
      [110.3867, -7.8079],
      [110.387, -7.8062],
      [110.3867, -7.8054],
      [110.3868, -7.8038],
      [110.387, -7.8021],
      [110.3871, -7.802],
      [110.3866, -7.802]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'UMBULHARJO',
    village: 'WARUNGBOTO',
    border: [
      [110.3949, -7.809],
      [110.3943, -7.8086],
      [110.3934, -7.8078],
      [110.393, -7.8076],
      [110.3925, -7.8072],
      [110.3905, -7.8053],
      [110.3907, -7.803],
      [110.3908, -7.802],
      [110.389, -7.802],
      [110.3871, -7.8019],
      [110.3871, -7.802],
      [110.387, -7.8021],
      [110.3868, -7.8038],
      [110.3867, -7.8054],
      [110.387, -7.8062],
      [110.3867, -7.8079],
      [110.3866, -7.8085],
      [110.3866, -7.8089],
      [110.3865, -7.8098],
      [110.3861, -7.811],
      [110.3859, -7.8115],
      [110.3874, -7.8117],
      [110.3902, -7.8123],
      [110.392, -7.8128],
      [110.3915, -7.8146],
      [110.3933, -7.814],
      [110.3934, -7.8135],
      [110.3936, -7.8128],
      [110.3939, -7.8122],
      [110.3943, -7.8119],
      [110.3943, -7.811],
      [110.3943, -7.8103],
      [110.3943, -7.8097],
      [110.3947, -7.8091],
      [110.3949, -7.809]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'UMBULHARJO',
    village: 'PANDEYAN',
    border: [
      [110.3859, -7.8115],
      [110.385, -7.8114],
      [110.3826, -7.8108],
      [110.3818, -7.8109],
      [110.3818, -7.8115],
      [110.381, -7.8116],
      [110.3809, -7.8113],
      [110.3807, -7.8114],
      [110.3807, -7.8123],
      [110.3816, -7.8123],
      [110.3816, -7.8133],
      [110.3816, -7.8152],
      [110.3813, -7.8152],
      [110.3813, -7.8166],
      [110.383, -7.8168],
      [110.383, -7.8172],
      [110.3826, -7.8191],
      [110.3824, -7.8201],
      [110.3832, -7.8203],
      [110.3832, -7.8204],
      [110.3836, -7.8206],
      [110.3842, -7.8206],
      [110.385, -7.8205],
      [110.3848, -7.8208],
      [110.3846, -7.8208],
      [110.3843, -7.8222],
      [110.3854, -7.8221],
      [110.3859, -7.822],
      [110.3861, -7.8219],
      [110.3862, -7.8217],
      [110.3868, -7.8218],
      [110.3884, -7.8218],
      [110.3893, -7.8218],
      [110.3895, -7.8218],
      [110.3903, -7.8224],
      [110.3903, -7.8223],
      [110.3929, -7.8234],
      [110.3923, -7.8252],
      [110.3934, -7.8258],
      [110.3935, -7.8247],
      [110.3938, -7.8239],
      [110.3943, -7.8232],
      [110.3943, -7.8218],
      [110.3943, -7.8212],
      [110.3938, -7.8206],
      [110.3936, -7.8202],
      [110.3936, -7.8199],
      [110.394, -7.8197],
      [110.3944, -7.8194],
      [110.3944, -7.8193],
      [110.3945, -7.8189],
      [110.3943, -7.8185],
      [110.3938, -7.8181],
      [110.3938, -7.8173],
      [110.3939, -7.8168],
      [110.3932, -7.8166],
      [110.3924, -7.8164],
      [110.3919, -7.8159],
      [110.3918, -7.8152],
      [110.3924, -7.8151],
      [110.393, -7.815],
      [110.3931, -7.8147],
      [110.3933, -7.814],
      [110.3915, -7.8146],
      [110.392, -7.8128],
      [110.3902, -7.8123],
      [110.3874, -7.8117],
      [110.3859, -7.8115]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'UMBULHARJO',
    village: 'SOROSUTAN',
    border: [
      [110.3813, -7.8152],
      [110.3808, -7.815],
      [110.3794, -7.8145],
      [110.3783, -7.8143],
      [110.3775, -7.8141],
      [110.377, -7.8138],
      [110.3766, -7.8138],
      [110.3764, -7.8151],
      [110.3763, -7.8159],
      [110.3762, -7.8167],
      [110.376, -7.8181],
      [110.376, -7.8186],
      [110.3762, -7.8195],
      [110.3763, -7.8203],
      [110.3762, -7.8214],
      [110.3761, -7.8219],
      [110.376, -7.8222],
      [110.3755, -7.8232],
      [110.3752, -7.824],
      [110.3751, -7.8243],
      [110.375, -7.8246],
      [110.375, -7.8252],
      [110.3749, -7.8256],
      [110.375, -7.826],
      [110.3752, -7.8263],
      [110.3755, -7.8266],
      [110.3757, -7.8269],
      [110.3757, -7.8273],
      [110.3757, -7.8277],
      [110.3757, -7.8281],
      [110.3756, -7.8284],
      [110.3757, -7.8288],
      [110.3757, -7.829],
      [110.3756, -7.8294],
      [110.3753, -7.8298],
      [110.3753, -7.8301],
      [110.3752, -7.8306],
      [110.3751, -7.8311],
      [110.3753, -7.831],
      [110.3754, -7.831],
      [110.3756, -7.8309],
      [110.3757, -7.8304],
      [110.3757, -7.83],
      [110.3758, -7.8296],
      [110.3761, -7.8292],
      [110.3762, -7.8296],
      [110.3763, -7.8299],
      [110.3764, -7.8304],
      [110.3764, -7.8306],
      [110.3766, -7.831],
      [110.3768, -7.831],
      [110.3777, -7.8309],
      [110.379, -7.8305],
      [110.3791, -7.8302],
      [110.3792, -7.83],
      [110.3796, -7.8298],
      [110.3802, -7.8298],
      [110.3799, -7.8286],
      [110.3809, -7.8287],
      [110.3811, -7.829],
      [110.3813, -7.8291],
      [110.3821, -7.8291],
      [110.3831, -7.8291],
      [110.3845, -7.8291],
      [110.3844, -7.8296],
      [110.3844, -7.83],
      [110.3848, -7.8301],
      [110.386, -7.8302],
      [110.3864, -7.8303],
      [110.3862, -7.8297],
      [110.3861, -7.8293],
      [110.3866, -7.829],
      [110.387, -7.8291],
      [110.387, -7.8282],
      [110.3864, -7.828],
      [110.3863, -7.8268],
      [110.3863, -7.8261],
      [110.3856, -7.8256],
      [110.3856, -7.8251],
      [110.3858, -7.8244],
      [110.3859, -7.8236],
      [110.386, -7.8226],
      [110.3859, -7.822],
      [110.3854, -7.8221],
      [110.3843, -7.8222],
      [110.3846, -7.8208],
      [110.3848, -7.8208],
      [110.385, -7.8205],
      [110.3842, -7.8206],
      [110.3836, -7.8206],
      [110.3832, -7.8204],
      [110.3832, -7.8203],
      [110.3824, -7.8201],
      [110.3826, -7.8191],
      [110.383, -7.8172],
      [110.383, -7.8168],
      [110.3813, -7.8166],
      [110.3813, -7.8152]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'UMBULHARJO',
    village: 'GIWANGAN',
    border: [
      [110.3903, -7.8224],
      [110.3895, -7.8218],
      [110.3893, -7.8218],
      [110.3884, -7.8218],
      [110.3868, -7.8218],
      [110.3862, -7.8217],
      [110.3861, -7.8219],
      [110.3859, -7.822],
      [110.386, -7.8226],
      [110.3859, -7.8236],
      [110.3858, -7.8244],
      [110.3856, -7.8251],
      [110.3856, -7.8256],
      [110.3863, -7.8261],
      [110.3863, -7.8268],
      [110.3864, -7.828],
      [110.387, -7.8282],
      [110.387, -7.8291],
      [110.3866, -7.829],
      [110.3861, -7.8293],
      [110.3862, -7.8297],
      [110.3864, -7.8303],
      [110.386, -7.8302],
      [110.3848, -7.8301],
      [110.3844, -7.83],
      [110.3845, -7.8306],
      [110.3846, -7.8314],
      [110.3853, -7.8314],
      [110.3857, -7.8315],
      [110.3861, -7.8322],
      [110.3862, -7.8326],
      [110.3861, -7.8331],
      [110.3861, -7.8337],
      [110.3861, -7.8341],
      [110.3863, -7.8341],
      [110.3876, -7.8341],
      [110.3878, -7.8341],
      [110.3878, -7.8347],
      [110.3877, -7.8354],
      [110.3877, -7.8358],
      [110.3875, -7.836],
      [110.3873, -7.8362],
      [110.387, -7.8362],
      [110.3869, -7.8365],
      [110.3871, -7.8369],
      [110.3876, -7.8366],
      [110.3879, -7.8367],
      [110.3881, -7.8368],
      [110.3882, -7.8373],
      [110.3882, -7.8376],
      [110.3882, -7.8381],
      [110.388, -7.8393],
      [110.3881, -7.8401],
      [110.3888, -7.8401],
      [110.3895, -7.8402],
      [110.3899, -7.8402],
      [110.39, -7.8397],
      [110.3903, -7.8379],
      [110.3906, -7.8369],
      [110.3912, -7.8368],
      [110.3921, -7.8369],
      [110.3931, -7.837],
      [110.3939, -7.8371],
      [110.3943, -7.8371],
      [110.395, -7.8371],
      [110.3951, -7.837],
      [110.3952, -7.8369],
      [110.3953, -7.8367],
      [110.3951, -7.8361],
      [110.3951, -7.8352],
      [110.3951, -7.8343],
      [110.3951, -7.8337],
      [110.3955, -7.8336],
      [110.396, -7.8333],
      [110.396, -7.8328],
      [110.3958, -7.8325],
      [110.3953, -7.832],
      [110.3947, -7.8314],
      [110.3942, -7.8308],
      [110.3937, -7.83],
      [110.3934, -7.8296],
      [110.3933, -7.8293],
      [110.3934, -7.8291],
      [110.3937, -7.8288],
      [110.3938, -7.8285],
      [110.3939, -7.8281],
      [110.3939, -7.8277],
      [110.3937, -7.8271],
      [110.3936, -7.8271],
      [110.3926, -7.8266],
      [110.3917, -7.8261],
      [110.3901, -7.8251],
      [110.3902, -7.824],
      [110.3902, -7.8232],
      [110.3903, -7.8224]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'KOTAGEDE',
    village: 'REJOWINANGUN',
    border: [
      [110.4023, -7.8067],
      [110.4023, -7.8067],
      [110.4024, -7.8043],
      [110.4024, -7.8022],
      [110.4014, -7.8022],
      [110.4004, -7.8022],
      [110.3997, -7.8022],
      [110.3992, -7.8022],
      [110.3985, -7.8022],
      [110.3979, -7.8021],
      [110.3976, -7.8021],
      [110.3974, -7.8021],
      [110.3974, -7.8024],
      [110.3974, -7.803],
      [110.3972, -7.8035],
      [110.3969, -7.8041],
      [110.3968, -7.8048],
      [110.3968, -7.8052],
      [110.3968, -7.8062],
      [110.3967, -7.8068],
      [110.3964, -7.8075],
      [110.3962, -7.808],
      [110.3957, -7.8083],
      [110.3952, -7.8087],
      [110.3949, -7.809],
      [110.3947, -7.8091],
      [110.3943, -7.8097],
      [110.3943, -7.8103],
      [110.3943, -7.811],
      [110.3943, -7.8119],
      [110.3939, -7.8122],
      [110.3936, -7.8128],
      [110.3934, -7.8135],
      [110.3933, -7.814],
      [110.3934, -7.814],
      [110.3937, -7.8138],
      [110.3943, -7.8137],
      [110.3943, -7.8146],
      [110.3953, -7.8148],
      [110.3961, -7.8149],
      [110.397, -7.8149],
      [110.3969, -7.8159],
      [110.397, -7.816],
      [110.398, -7.8163],
      [110.3977, -7.8182],
      [110.3974, -7.8198],
      [110.4003, -7.8205],
      [110.4009, -7.8207],
      [110.4011, -7.8205],
      [110.4015, -7.8192],
      [110.4019, -7.8181],
      [110.4021, -7.8173],
      [110.4021, -7.816],
      [110.4023, -7.8105],
      [110.4023, -7.8067]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'KOTAGEDE',
    village: 'PRENGGAN',
    border: [
      [110.4009, -7.8207],
      [110.4003, -7.8205],
      [110.3974, -7.8198],
      [110.3977, -7.8182],
      [110.398, -7.8163],
      [110.397, -7.816],
      [110.3969, -7.8159],
      [110.397, -7.8149],
      [110.3961, -7.8149],
      [110.3953, -7.8148],
      [110.3943, -7.8146],
      [110.3943, -7.8137],
      [110.3937, -7.8138],
      [110.3934, -7.814],
      [110.3933, -7.814],
      [110.3931, -7.8147],
      [110.393, -7.815],
      [110.3924, -7.8151],
      [110.3918, -7.8152],
      [110.3919, -7.8159],
      [110.3924, -7.8164],
      [110.3932, -7.8166],
      [110.3939, -7.8168],
      [110.3938, -7.8173],
      [110.3938, -7.8181],
      [110.3943, -7.8185],
      [110.3945, -7.8189],
      [110.3944, -7.8193],
      [110.3944, -7.8194],
      [110.394, -7.8197],
      [110.3936, -7.8199],
      [110.3936, -7.8202],
      [110.3938, -7.8206],
      [110.3943, -7.8212],
      [110.3943, -7.8218],
      [110.3943, -7.8232],
      [110.3938, -7.8239],
      [110.3935, -7.8247],
      [110.3934, -7.8258],
      [110.3923, -7.8252],
      [110.3929, -7.8234],
      [110.3903, -7.8223],
      [110.3903, -7.8224],
      [110.3902, -7.8232],
      [110.3902, -7.824],
      [110.3901, -7.8251],
      [110.3917, -7.8261],
      [110.3926, -7.8266],
      [110.3936, -7.8271],
      [110.3937, -7.8271],
      [110.3947, -7.8274],
      [110.3953, -7.8274],
      [110.3966, -7.8275],
      [110.398, -7.8276],
      [110.3986, -7.8277],
      [110.3993, -7.8278],
      [110.3996, -7.8278],
      [110.4, -7.8255],
      [110.4004, -7.8233],
      [110.4007, -7.8219],
      [110.4009, -7.8207]
    ]
  },
  {
    province: 'DAERAH ISTIMEWA YOGYAKARTA',
    district: 'KOTA YOGYAKARTA',
    sub_district: 'KOTAGEDE',
    village: 'PURBAYAN',
    border: [
      [110.4067, -7.8315],
      [110.4066, -7.8313],
      [110.4065, -7.8308],
      [110.4063, -7.8307],
      [110.4057, -7.8305],
      [110.4051, -7.8304],
      [110.405, -7.8296],
      [110.405, -7.8279],
      [110.405, -7.8257],
      [110.405, -7.8255],
      [110.4047, -7.8255],
      [110.4042, -7.8254],
      [110.4043, -7.825],
      [110.4048, -7.825],
      [110.4048, -7.8244],
      [110.4046, -7.8241],
      [110.4047, -7.8238],
      [110.4048, -7.8229],
      [110.4048, -7.8225],
      [110.404, -7.8225],
      [110.404, -7.8216],
      [110.4039, -7.821],
      [110.4038, -7.821],
      [110.4033, -7.8209],
      [110.4024, -7.8207],
      [110.4011, -7.8205],
      [110.4009, -7.8207],
      [110.4007, -7.8219],
      [110.4004, -7.8233],
      [110.4, -7.8255],
      [110.3996, -7.8278],
      [110.3993, -7.8278],
      [110.3994, -7.8283],
      [110.3994, -7.8287],
      [110.3993, -7.8289],
      [110.3991, -7.8293],
      [110.3989, -7.8297],
      [110.3989, -7.8302],
      [110.3989, -7.8311],
      [110.3989, -7.8317],
      [110.3991, -7.8319],
      [110.399, -7.8322],
      [110.3989, -7.8334],
      [110.3991, -7.8335],
      [110.3998, -7.8335],
      [110.4002, -7.8333],
      [110.4004, -7.8327],
      [110.4006, -7.832],
      [110.4008, -7.832],
      [110.4007, -7.8324],
      [110.4007, -7.8327],
      [110.4009, -7.8331],
      [110.4011, -7.8335],
      [110.4018, -7.8334],
      [110.402, -7.8339],
      [110.4022, -7.834],
      [110.403, -7.834],
      [110.4039, -7.834],
      [110.4049, -7.8341],
      [110.4058, -7.8343],
      [110.4065, -7.8345],
      [110.4067, -7.8334],
      [110.4067, -7.8333],
      [110.4068, -7.833],
      [110.4066, -7.8326],
      [110.4066, -7.832],
      [110.4067, -7.8316],
      [110.4067, -7.8315]
    ]
  }
];

export default data;
