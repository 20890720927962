import {Box, Card, Container, Grid, Typography} from "@mui/material";
import Page from '../../components/Page';
import Spm from '../Spm/Index';
import Tb from '../Tb/Index';
import Ht from '../Hypertension/Index';

const Index = () => {

    return (
        <Page title="Dashboard Dinkes Sleman">
            <div style={{position: 'absolute', left: 0, top: 0, right: 0}}>
                <div style={{height: '100vh', marginBottom: 24}}>
                    <div style={{position: 'absolute', left: 0, top: 0, right: 0, height: '100vh'}}>
                        <img src={'/static/candi.jpg'} alt={''} style={{width: '100vw', height: '100vh', objectFit: 'cover', position: 'absolute'}}/>
                        <div style={{backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(0,30,0,0.6))', width: '100vw', height: '100vh', position: 'absolute'}}/>
                        <div style={{backgroundColor: 'rgba(0,0,0,0.4)', width: '100vw', height: '100vh', position: 'absolute'}}/>
                    </div>
                    <Container maxWidth="xl">
                        <Layanan/>
                    </Container>
                </div>
                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', left: 0, right: 0, height: '100%'}}>
                        <img src={'/static/hexagon.jpg'} alt={''} style={{width: '100vw', height: '100%', objectFit: 'cover', position: 'absolute', opacity: .5}}/>
                        <div style={{backgroundColor: 'rgba(0,0,0,0.05)', width: '100vw', height: '100%', position: 'absolute'}}/>
                    </div>
                    <Container maxWidth="xl" style={{paddingTop: 50, paddingBottom: 20}}>
                        <Spm/>
                    </Container>
                </div>
                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', left: 0, right: 0, height: '100%'}}>
                        <img src={'/static/tb.jpg'} alt={''} style={{width: '100vw', height: '100%', objectFit: 'cover', position: 'absolute', opacity: .6}}/>
                        <div style={{backgroundColor: 'rgba(0,40,0,0.15)', width: '100vw', height: '100%', position: 'absolute'}}/>
                    </div>
                    <Container maxWidth="xl" style={{paddingTop: 50, paddingBottom: 50}}>
                        <Tb/>
                    </Container>
                </div>
                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', left: 0, right: 0, height: '100%'}}>
                        <img src={'/static/hexagon.jpg'} alt={''} style={{width: '100vw', height: '100%', objectFit: 'cover', position: 'absolute', opacity: .4}}/>
                        <div style={{backgroundColor: 'rgba(0,0,0,0.05)', width: '100vw', height: '100%', position: 'absolute'}}/>
                    </div>
                    <Container maxWidth="xl" style={{paddingTop: 50, paddingBottom: 20}}>
                        <Ht/>
                    </Container>
                </div>
            </div>
        </Page>
    )
}

const Layanan = () => {

    const Menu = ({title, image, link}) => (
        <Box style={{width: 150, padding: 8}}>
            <a href={link} target={'_blank'} rel="noreferrer" style={{textDecoration: 'none'}}>
                <Card
                    sx={{
                        background: '#00000000', boxShadow: 'none',
                    }}
                >
                    <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div className={'layanan'} style={{
                            marginTop: 14,
                            marginBottom: 4,
                            padding: 20,
                            height: 130,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'white',
                            overflow: 'hidden',
                            borderRadius: 100,
                            border: '5px solid green',
                        }}>
                            <img src={image} alt={''} />
                        </div>
                        <div style={{height: 40, marginTop: 16, display: 'flex', alignItems: 'center'}}>
                            <h4 style={{color: 'white', marginBottom: 16, textAlign: 'center'}}>{title}</h4>
                        </div>
                    </Box>
                </Card>
            </a>
        </Box>
    )

    return (
        <div style={{paddingLeft: 24, paddingRight: 24}}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{minHeight: '50vh'}}>
                    <div style={{height: 100}}></div>
                    <Box style={{margin: 24, display: 'flex', justifyContent: 'center'}}>
                        <Card style={{background: '#00000000', boxShadow: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{height: 40}}/>
                            <img src={'/static/logo-sleman.png'} alt={''} width={150}/>
                            <div style={{height: 10}}/>
                            <h2 style={{color: 'white', textAlign: 'center'}}>
                                Dinas Kesehatan Kabupaten Sleman
                            </h2>
                        </Card>
                    </Box>
                    <Box style={{margin: 24, display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Menu
                            title={'SID'}
                            image={'/static/sid.jpeg'}
                            link={'https://smartdinkes.slemankab.go.id/smarthealth/'}
                        />
                        <Menu
                            title={'SIP'}
                            image={'/static/sip.jpeg'}
                            link={'https://smartdinkes.slemankab.go.id/smarthealth/'}
                        />
                        <Menu
                            title={'Keamanan Pangan'}
                            image={'/static/pkp.jpeg'}
                            link={'https://smartdinkes.slemankab.go.id/v2/service/pirt/daftar-penyuluhan'}
                        />
                        <Menu
                            title={'Website Sleman'}
                            image={'/static/logo-sleman.png'}
                            link={'https://dinkes.slemankab.go.id/'}
                        />
                        <Menu
                            title={'Matahati'}
                            image={'/static/matahati.png'}
                            link={'https://smartdinkes.slemankab.go.id/pelayanan-matahati/'}
                        />
                    </Box>
                    <div style={{height: 100}}></div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Index