import { filter, result } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
//

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.namars.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TableData({ datas, kategori }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('no');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = datas.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datas.length) : 0;

  const filteredUsers = applySortFilter(datas, getComparator(order, orderBy), filterName);
  // const filteredUsers = [];
  const isUserNotFound = filteredUsers.length === 0;
  let TABLE_HEAD = [
    { id: 'no', label: 'no', alignRight: false },
    { id: 'name', label: 'desa / dusun', alignRight: false },
    { id: 'screening', label: 'Screening', alignRight: false },
    { id: 'terduga', label: 'Terduga', alignRight: false },
    { id: 'positif', label: 'Positif', alignRight: false }
  ];

  if (kategori.length > 0) {
    if (kategori.includes('Positif')) {
      if (kategori.includes('Terduga')) {
        TABLE_HEAD = [
          { id: 'no', label: 'no', alignRight: false },
          { id: 'name', label: 'desa / dusun', alignRight: false },
          { id: 'terduga', label: 'Terduga', alignRight: false },
          { id: 'positif', label: 'Positif', alignRight: false }
        ];
      } else {
        TABLE_HEAD = [
          { id: 'no', label: 'no', alignRight: false },
          { id: 'name', label: 'desa / dusun', alignRight: false },
          { id: 'positif', label: 'Positif', alignRight: false }
        ];
      }
    } else {
      TABLE_HEAD = [
        { id: 'no', label: 'no', alignRight: false },
        { id: 'name', label: 'desa / dusun', alignRight: false },
        { id: 'terduga', label: 'Terduga', alignRight: false }
      ];
    }
  }

  const generateCell = (row, isItemSelected) => {
    const { no, name, screening, positif, terduga } = row;
    const generate = [];
    if (kategori.length > 0) {
      if (kategori.includes('Positif')) {
        if (kategori.includes('Terduga')) {
          generate.push(
            <TableRow
              hover
              key={no}
              tabIndex={-1}
              role="checkbox"
              selected={isItemSelected}
              aria-checked={isItemSelected}
            >
              <TableCell align="left">{no}</TableCell>
              <TableCell align="left">{name}</TableCell>
              <TableCell align="left">{terduga}</TableCell>
              <TableCell align="left">{positif}</TableCell>
            </TableRow>
          );
        } else {
          generate.push(
            <TableRow
              hover
              key={no}
              tabIndex={-1}
              role="checkbox"
              selected={isItemSelected}
              aria-checked={isItemSelected}
            >
              <TableCell align="left">{no}</TableCell>
              <TableCell align="left">{name}</TableCell>
              <TableCell align="left">{positif}</TableCell>
            </TableRow>
          );
        }
      } else {
        generate.push(
          <TableRow
            hover
            key={no}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            <TableCell align="left">{no}</TableCell>
            <TableCell align="left">{name}</TableCell>
            <TableCell align="left">{terduga}</TableCell>
          </TableRow>
        );
      }
    } else {
      generate.push(
        <TableRow
          hover
          key={no}
          tabIndex={-1}
          role="checkbox"
          selected={isItemSelected}
          aria-checked={isItemSelected}
        >
          <TableCell align="left">{no}</TableCell>
          <TableCell align="left">{name}</TableCell>
          <TableCell align="left">{screening}</TableCell>
          <TableCell align="left">{terduga}</TableCell>
          <TableCell align="left">{positif}</TableCell>
        </TableRow>
      );
    }
    return generate;
  };
  return (
    // <Container maxWidth="xl">
      <Card
        sx={{
          boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
          '&:hover': {
            boxShadow: '-1px 10px 29px 0px rgba(0,0,0,0.8)'
          }
        }}
      >
        {/* <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        /> */}

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={0}
                numSelected={0}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { no, name, screening, positif, terduga } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return generateCell(row, isItemSelected);
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={11} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    // </Container>
  );
}
