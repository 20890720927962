import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import iconClose from '@iconify/icons-eva/close-square-outline';

// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  Grid,
  Modal,
  Typography
} from '@mui/material';
// components
import NavSection from '../../components/NavTopSection';
import sidebarConfig from './SidebarConfig';
import { MHidden } from '../../components/@material-extend';
//
// import Searchbar from './Searchbar';
// import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';
import Logo from '../../components/Logo';
import store from '../../store';

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.25),
  [theme.breakpoints.up('lg')]: {
    width: `100%`
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 0)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

// function Modalx({ open, handleClose, name, sub, data }) {
//   const [province, setprovince] = React.useState(JSON.stringify(data.filter));
//   const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4
//   };
//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//     >
//       <Box sx={style}>
//         <Grid container direction="row">
//           <Typography sx={{ flexGrow: 0.8 }} id="modal-modal-title" variant="h6" component="h2">
//             PILIH AREA YANG DIINGINKAN
//           </Typography>
//           <IconButton
//             color="error"
//             sx={{ mt: -2 }}
//             aria-label="upload picture"
//             component="span"
//             onClick={handleClose}
//           >
//             <Icon icon={iconClose} />
//           </IconButton>
//         </Grid>
//         {name === 'NASIONAL' ? (
//           <FormControl size="small" sx={{ minWidth: '100%', marginTop: 3 }}>
//             <InputLabel id="demo-simple-select-autowidth-label">Pilih Kategori</InputLabel>
//             <Select
//               labelId="demo-simple-select-autowidth-label"
//               id="demo-simple-select-autowidth"
//               value={sub}
//               // onChange={(e) => setSub(e.target.value)}
//               minwidth="50%"
//               label="Jenis Layanan"
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               <MenuItem value="PROVINSI">Per Provinsi</MenuItem>
//               <MenuItem value="RS">Per RS</MenuItem>
//             </Select>
//           </FormControl>
//         ) : (
//           ''
//         )}
//         {name === 'PROVINSI' ? (
//           <FormControl size="small" sx={{ minWidth: '100%', marginTop: 3 }}>
//             <InputLabel id="demo-simple-select-autowidth-label">Pilih Provinsi</InputLabel>
//             <Select
//               labelId="demo-simple-select-autowidth-label"
//               id="demo-simple-select-autowidth"
//               value={province}
//               name="provinsi"
//               onChange={(e) => {
//                 store.dispatch({ type: 'PROVINSI', state: JSON.parse(e.target.value) });
//                 setprovince(e.target.value);
//               }}
//               minwidth="50%"
//               label="prov"
//             >
//               <MenuItem value="">
//                 <em>None</em>
//               </MenuItem>
//               {data.refProvince.map((d, k) => (
//                 <MenuItem
//                   value={`{"value": ${d.code}, "label": "${d.name}"}`}
//                   name={d.name}
//                   key={k}
//                 >
//                   {d.name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         ) : (
//           ''
//         )}
//         <Stack direction="row" sx={{ marginTop: 3 }}>
//           <Button
//             aria-controls="demo-positioned-menu"
//             aria-haspopup="true"
//             // aria-expanded={open ? 'true' : undefined}
//             onClick={handleClose}
//             variant="outlined"
//             color="secondary"
//             sx={{
//               mr: 4
//             }}
//           >
//             Simpan
//           </Button>
//         </Stack>
//       </Box>
//     </Modal>
//   );
// }

export default function DashboardNavbar({ onOpenSidebar }) {
  const data = store.getState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [name, setName] = React.useState(data.case);
  const [sub, setSub] = React.useState(data?.filter);
  const [modal, setModal] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // console.log(data);
  return (
    <RootStyle>
      <ToolbarStyle>
        <Box sx={{ px: 2.5, py: 0 }}>
          <Box to="/" sx={{ display: 'inline-flex' }}>
            <Logo />
          </Box>
        </Box>
        {/* <Searchbar /> */}
        <MHidden width="lgDown">
          <Button
            id="demo-positioned-button"
            aria-controls="demo-positioned-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            // onClick={handleClick}
            variant="outlined"
            color="secondary"
            size="small"
          >
            {/* {data.case} | {data?.filter?.label} */}
            Kab Sleman
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                store.dispatch({ type: 'NASIONAL', state: { label: 'PER RS', value: '' } });
                // setModal(true);
              }}
            >
              NASIONAL
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                store.dispatch({ type: 'PROVINSI', state: '' });
                setModal(true);
              }}
            >
              PROVINSI
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                store.dispatch({ type: 'KABUPATEN', state: '' });
                setModal(true);
              }}
            >
              KABUPATEN
            </MenuItem>
          </Menu>
          {/* <FormControl size="small" sx={{ minWidth: '10%', ml: 1 }}>
            <InputLabel id="demo-simple-select-autowidth-label">Jenis Layanan</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={10}
              // onChange={handleChange}
              minwidth="50%"
              label="Jenis Layanan"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Jantung</MenuItem>
              <MenuItem value={21}>Anastesi</MenuItem>
              <MenuItem value={22}>Jantung</MenuItem>
            </Select>
          </FormControl> */}
          <Box sx={{ flexGrow: 1 }} />
          <NavSection navConfig={sidebarConfig} />
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            {/* <LanguagePopover />
            <NotificationsPopover />
            <AccountPopover /> */}
          </Stack>
        </MHidden>
        <MHidden width="lgUp">
          <Grid container spacing={2}>
            <Grid item>
              <Button
                id="demo-positioned-button"
                aria-controls="demo-positioned-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // onClick={handleClick}
                variant="outlined"
                color="secondary"
                sx={{
                  mr: 4
                }}
              >
                {data.case} | {data?.filter?.label}
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    store.dispatch({ type: 'NASIONAL', state: { label: 'PER RS', value: '' } });
                  }}
                >
                  NASIONAL
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    // setName('PROVINSI');
                    setModal(true);
                  }}
                >
                  PROVINSI
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    // setName('KABUPATEN');
                    setModal(true);
                  }}
                >
                  KABUPATEN
                </MenuItem>
              </Menu>
            </Grid>
            {/* <Grid item>
              <FormControl size="small" sx={{ minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-autowidth-label">Jenis Layanan</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={10}
                  // onChange={handleChange}
                  minwidth="10%"
                  label="Jenis Layanan"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Jantung</MenuItem>
                  <MenuItem value={21}>Anastesi</MenuItem>
                  <MenuItem value={22}>Jantung</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
      </ToolbarStyle>
      {/* <Modalx
        open={modal}
        handleClose={() => setModal(false)}
        name={data.case}
        sub={sub}
        setSub={setSub}
        data={data}
      /> */}
    </RootStyle>
  );
}
