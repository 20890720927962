import { useState, useEffect } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Config from "../../Config";
import {downloadSpmExcel} from "./SpmExport";

const showLoading = () => {}
const toast = (obj) => {}

const SpmChart = ({tahun, reload, forceReload}) => {

    const listJenis = [1,2,3,4,5,6,7,8,9,10,11,12]
    const defaultTotal = {
        tw: {total: 0, tw1: 0, tw2: 0, tw3: 0, tw4: 0},
        layanan: {total: 0, tw1: 0, tw2: 0, tw3: 0, tw4: 0},
        mutu: {total: 0, tw1: 0, tw2: 0, tw3: 0, tw4: 0},
    }

    const [listSpm, setListSpm] = useState([])
    const [total, setTotal] = useState(defaultTotal)

    useEffect(() => {
        if (reload) {
            getListSpm(reload, 1000)
        }
    }, [reload, forceReload])

    useEffect(() => {
        showLoading()
        setListSpm([])
        setTotal(defaultTotal)
        listJenis.forEach((v, k) => getListSpm(v, k === listJenis.length - 1))
    }, [tahun])

    useEffect(() => {
        if (listSpm.length) {
            let t = defaultTotal
            listSpm.forEach(v => {
                t.tw.total += +v.data.total > 100 ? 100 : +v.data.total
                t.tw.tw1 += +v.data.tw1
                t.tw.tw2 += +v.data.tw2
                t.tw.tw3 += +v.data.tw3
                t.tw.tw4 += +v.data.tw4

                t.layanan.total += +v.data.total_layanan > 100 ? 100 : +v.data.total_layanan
                t.layanan.tw1 += +v.data.tw1_layanan
                t.layanan.tw2 += +v.data.tw2_layanan
                t.layanan.tw3 += +v.data.tw3_layanan
                t.layanan.tw4 += +v.data.tw4_layanan

                t.mutu.total += +v.data.total_mutu > 100 ? 100 : +v.data.total_mutu
                t.mutu.tw1 += +v.data.tw1_mutu
                t.mutu.tw2 += +v.data.tw2_mutu
                t.mutu.tw3 += +v.data.tw3_mutu
                t.mutu.tw4 += +v.data.tw4_mutu
            })

            t.tw.total /= listSpm.length
            t.tw.tw1 /= listSpm.length
            t.tw.tw2 /= listSpm.length
            t.tw.tw3 /= listSpm.length
            t.tw.tw4 /= listSpm.length

            t.layanan.total /= listSpm.length
            t.layanan.tw1 /= listSpm.length
            t.layanan.tw2 /= listSpm.length
            t.layanan.tw3 /= listSpm.length
            t.layanan.tw4 /= listSpm.length

            t.mutu.total /= listSpm.length
            t.mutu.tw1 /= listSpm.length
            t.mutu.tw2 /= listSpm.length
            t.mutu.tw3 /= listSpm.length
            t.mutu.tw4 /= listSpm.length

            setTotal(t)
        }
    }, [JSON.stringify(listSpm)])

    const getListSpm = async (jenisId, isLast) => {
        const r = await Config.get(`/dashboard/get_active_trx_spm/${jenisId}/${tahun}`)
        if (r?.code === 200) {
            if (!r?.is_published) {
                return
            }

            if (isLast === 1000) {
                let oldList = []
                listSpm.forEach(v => {
                    if (v.jenis_id === jenisId)
                        oldList.push({
                            jenis_id: jenisId,
                            data: r.data,
                            is_published: r.is_published,
                            last_published_at: r.last_published_at
                        })
                    else
                        oldList.push(v)
                })
                setListSpm(oldList)
            }
            else {
                setListSpm(prevListSpm => [...prevListSpm, {
                    jenis_id: jenisId,
                    data: r.data,
                    is_published: r.is_published,
                    last_published_at: r.last_published_at
                }])
            }
        }

        if (isLast) {
            toast({ status: "success", text: "berhasil mendapatkan data" })
        }
    }

    const getRefSpmQuestion = async (jenisId) => {
        // showLoading('Menyiapkan data')
        // let dat = await Config.get(`/dashboard/get_spm_question/${jenisId}/${tahun}/${tw ?? -1}`)
        // if (dat?.code === 200) {
        //     dat.data.push({
        //         id: -1,
        //         ref_jenis_spm_id: dat.data.length ? dat.data[0].ref_jenis_spm_id : 0,
        //         question: 'Jumlah',
        //         trx_answer: {
        //             dibutuhkan: dat.data.reduce((a, o) => a + (o.trx_answer?.dibutuhkan ?? 0), 0),
        //             tersedia: dat.data.reduce((a, o) => a + (o.trx_answer?.tersedia ?? 0), 0),
        //             belum_tersedia: dat.data.reduce((a, o) => a + (o.trx_answer?.belum_tersedia ?? 0), 0),
        //             persentase: dat.data.reduce((a, o) => a + +(o.trx_answer?.persentase ?? '0'), 0)
        //         }
        //     })
        // }
        // toast({ status: "success", text: "Selesai menyiapkan data" })
        // return dat.data
    }

    const download = async (jenis_id, title) => {
        const questions = await getRefSpmQuestion(jenis_id)
        downloadSpmExcel(questions, title)
    }

    const getLastPublished = (id) => listSpm.find(v => v.jenis_id === id)?.last_published_at

    const getData = (id) => listSpm.find(v => v.jenis_id === id)?.data

    const getTotal = (id) => listSpm.find(v => v.jenis_id === id)?.data?.total

    return (
        <>
            <div style={{display: 'flex', alignItems: 'stretch'}}>
                <div className={'text-center'} style={{flex: 1, paddingRight: 26}}>
                    <h1 style={{textTransform: 'capitalize', textAlign: 'center'}}>Indeks Capaian SPM<br/>{tahun}</h1>
                </div>
                <div style={{flex: 3, margin: '0 26px'}} className={'text-center'}>
                    <h2 style={{textAlign: 'center'}}>Capaian SPM<br/>{tahun}</h2>
                </div>
            </div>
            <div style={{display: 'flex', alignItems: 'stretch'}}>
                <div className={'text-center'} style={{flex: 1, borderRight: '1px solid #EEEEEE', paddingRight: 26}}>
                    <div style={{height: 20}}/>
                    <PieProgress value={total.tw.total?.toFixed(2) ?? 0} strokeWidth={10}/>
                    <div style={{height: 20}}/>
                    <BarChart
                        data={[+(total.tw.tw1).toFixed(2), +(total.tw.tw2).toFixed(2), +(total.tw.tw3).toFixed(2), +(total.tw.tw4).toFixed(2)]}
                        layanan={[+(total.layanan.tw1).toFixed(2), +(total.layanan.tw2).toFixed(2), +(total.layanan.tw3).toFixed(2), +(total.layanan.tw4).toFixed(2)]}
                        mutu={[+(total.mutu.tw1).toFixed(2), +(total.mutu.tw2).toFixed(2), +(total.mutu.tw3).toFixed(2), +(total.mutu.tw4).toFixed(2)]}
                        height={'70%'}
                        pointWidth={30}
                        borderRadius={8}
                        tickAmount={4}
                    />
                </div>
                <div style={{flex: 1, margin: '0 26px'}}>
                    <CapaianSpmItem title={'Ibu Hamil'} data={getData(1)} percentage={getTotal(1)} download={() => download(1, 'Ibu Hamil')} publishedat={getLastPublished(1)}/>
                    <CapaianSpmItem title={'Ibu Bersalin'} data={getData(2)} percentage={getTotal(2)} download={() => download(2, 'Ibu Bersalin')} publishedat={getLastPublished(2)}/>
                    <CapaianSpmItem title={'Bayi Baru Lahir'} data={getData(3)} percentage={getTotal(3)} download={() => download(3, 'Bayi Baru Lahir')} publishedat={getLastPublished(3)}/>
                    <CapaianSpmItem title={'Balita'} data={getData(4)} percentage={getTotal(4)} download={() => download(4, 'Balita')} publishedat={getLastPublished(4)}/>
                </div>
                <div style={{flex: 1, margin: '0 26px'}}>
                    <CapaianSpmItem title={'Usia Pendidikan Dasar'} data={getData(5)} percentage={getTotal(5)} download={() => download(5, 'Usia Pendidikan Dasar')} publishedat={getLastPublished(5)}/>
                    <CapaianSpmItem title={'Usia Produktif'} data={getData(6)} percentage={getTotal(6)} download={() => download(6, 'Usia Produktif')} publishedat={getLastPublished(6)}/>
                    <CapaianSpmItem title={'Usia Lanjut'} data={getData(7)} percentage={getTotal(7)} download={() => download(7, 'Usia Lanjut')} publishedat={getLastPublished(7)}/>
                    <CapaianSpmItem title={'Penderita Hipertensi'} data={getData(8)} percentage={getTotal(8)} download={() => download(8, 'Penderita Hipertensi')} publishedat={getLastPublished(8)}/>
                </div>
                <div style={{flex: 1, marginLeft: '26px'}}>
                    <CapaianSpmItem title={'Penderita DM'} data={getData(9)} percentage={getTotal(9)} download={() => download(9, 'Penderita DM')} publishedat={getLastPublished(9)}/>
                    <CapaianSpmItem title={'ODGJ Berat'} data={getData(10)} percentage={getTotal(10)} download={() => download(10, 'ODGJ Berat')} publishedat={getLastPublished(10)}/>
                    <CapaianSpmItem title={'Terduga TB'} data={getData(11)} percentage={getTotal(11)} download={() => download(11, 'Terduga TB')} publishedat={getLastPublished(11)}/>
                    <CapaianSpmItem title={'Beresiko HIV'} data={getData(12)} percentage={getTotal(12)} download={() => download(12, 'Beresiko HIV')} publishedat={getLastPublished(12)}/>
                </div>
            </div>
        </>
    )
}

const CapaianSpmItem = ({title, data, percentage, download, publishedat}) => {

    const getChartData = () => !data ? [0,0,0,0] : Array.isArray(data) ? data : [+(+data.tw1).toFixed(2), +(+data.tw2).toFixed(2), +(+data.tw3).toFixed(2), +(+data.tw4).toFixed(2)]
    const getLayanan = () => !data ? [0,0,0,0] : Array.isArray(data) ? data : [+(+data.tw1_layanan).toFixed(2), +(+data.tw2_layanan).toFixed(2), +(+data.tw3_layanan).toFixed(2), +(+data.tw4_layanan).toFixed(2)]
    const getMutu = () => !data ? [0,0,0,0] : Array.isArray(data) ? data : [+(+data.tw1_mutu).toFixed(2), +(+data.tw2_mutu).toFixed(2), +(+data.tw3_mutu).toFixed(2), +(+data.tw4_mutu).toFixed(2)]

    function toIndoDate() {
        if (!publishedat) {
            return ''
        }

        const t = publishedat.split(/[- :]/);
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return `${day}/${month}/${year} ${t[3]}:${t[4]}`;
    }

    return (
        <div className={'text-center mt-4'} style={{marginTop: 16}}>
            <div style={{borderRadius: 8, background: '#DDDDDD', padding: '3px 0', marginBottom: 8}}>
                <h5 style={{textTransform: 'capitalize', margin: 0, textAlign: 'center'}}>{title}</h5>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{flex: 1}}>
                    <PieProgress value={!percentage ? 0 : +percentage > 100 ? 100 : +percentage}/>
                </div>
                <div style={{flex: 3}}>
                    <BarChart data={getChartData()} layanan={getLayanan()} mutu={getMutu()} download={download}/>
                </div>
            </div>
            {publishedat && <div style={{textAlign: 'right'}}>
                <small><i>Last update: {toIndoDate()}</i></small>
            </div>}
            {!publishedat && <div style={{textAlign: 'right'}}>
                <small><i style={{color: 'white'}}>-</i></small>
            </div>}
        </div>
    )
}

const PieProgress = ({value, strokeWidth}) => {
    return (
        <CircularProgressbar
            value={+value > 100 ? 100 : +((+value).toFixed(2))}
            text={`${+value > 100 ? 100 : +((+value).toFixed(2))}%`}
            styles={{
                path: {
                    stroke: '#ff910e'
                },
                trail: {
                    stroke: '#EEEEEE'
                },
                text: {
                    fill: '#ff910e',
                    fontWeight: 'bold',
                    fontsize: '9px'
                }
            }}
            strokeWidth={strokeWidth ?? 12}
        />
    )
}

const BarChart = ({data, height, pointWidth, borderRadius, tickAmount, layanan, mutu, download}) => {
    const options = {
        exporting: {
            menuItemDefinitions: {
                label: {
                    onclick: function () {
                        if (download) {
                            download()
                        }
                    },
                    text: 'Download Excel'
                }
            },
            buttons: {
                contextButton: {
                    menuItems: ['label', "viewFullscreen", "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                }
            }
        },
        title: {
            text: null
        },
        xAxis: {
            categories: ['TW I', 'TW II', 'TW III', 'TW IV'],
        },
        yAxis: {
            title: false,
            tickAmount: tickAmount ?? 3
        },
        legend: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                return `
                    <b>${this.x}: ${this.y}%</b><br><br>
                    Layanan: ${layanan[this.point.x]}%<br>
                    Mutu: ${mutu[this.point.x]}%
                `
            }
        },
        plotOptions: {
            column: {
                pointWidth: pointWidth ?? 16
            },
            series: {
                borderRadius: borderRadius ?? 5
            }
        },
        chart: {
            height: height ?? '50%',
        },
        series: [{
            type: 'column',
            styledMode: true,
            data: data
        }]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export const bulans = [
    {id: 1, name: 'Januari'},
    {id: 2, name: 'Februari'},
    {id: 3, name: 'Maret'},
    {id: 4, name: 'April'},
    {id: 5, name: 'Mei'},
    {id: 6, name: 'Juni'},
    {id: 7, name: 'Juli'},
    {id: 8, name: 'Agustus'},
    {id: 9, name: 'September'},
    {id: 10, name: 'Oktober'},
    {id: 11, name: 'November'},
    {id: 12, name: 'Desember'},
]

export default SpmChart
