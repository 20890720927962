import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
// import peopleFill from '@iconify/icons-eva/people-fill';
// import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'Home',
  //   path: '/dashboard/home',
  //   icon: getIcon(pieChart2Fill)
  // },
  {
    title: 'Portal',
    path: '/portal',
    icon: getIcon(fileTextFill)
  },
  // {
  //   title: 'Capaian SPM',
  //   path: '/dashboard/spm',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'Persebaran TB',
  //   path: '/dashboard/tb',
  //   icon: getIcon(pieChart2Fill)
  // },
  // {
  //   title: 'Hipertensi',
  //   path: '/dashboard/hypertension',
  //   icon: getIcon(alertTriangleFill)
  // }
  // {
  //   title: 'ASPAK',
  //   path: '/dask/aspak',
  //   icon: getIcon(shoppingBagFill)
  // },
  // {
  //   title: 'SDM',
  //   path: '/dask/sdm',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: 'Utilisasi',
  //   path: '/dask/utilisasi',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'Perujukan',
  //   path: '/dask/perujukan',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Analisis',
  //   path: '/dask/analisis',
  //   icon: getIcon(personAddFill)
  // }
];

export default sidebarConfig;
