import { useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import store from './store';
import Config from './Config';
// ----------------------------------------------------------------------

export default function App() {
  store.dispatch({ type: 'KABUPATEN', state: 'Sleman' });
  const data = store.getState();
  useEffect(() => {
    getProfince();
    if (data.refProvince.length > 0) {
      console.log(data);
    }
  }, [data.refProvince.length]);

  useEffect(() => {
    console.log(store.getState());
  }, [data]);

  const getProfince = async () => {
    const res = await Config.post('/area/filter', { category_id: 1 });
    if (res?.status === 200) {
      store.dispatch({ type: 'refProvince', state: res.data });
    }
  };

  // const getKabupaten = async () => {
  //   const res = await Config.post('/area/filter', { category_id: 2 });
  //   if (res.status === 200) {
  //     store.dispatch({ type: 'refKabupaten', state: res.data });
  //   }
  // };

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}
