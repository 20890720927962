// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  CardHeader,
  OutlinedInput,
  Chip,
  TextField,
  Checkbox,
  Button,
  Skeleton
} from '@mui/material';
import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// components
import store from '../../store';
import Page from '../../components/Page';
import Config from '../../Config';
import MapTb from './MapTb';
import TableData from './Table';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

// ----------------------------------------------------------------------
export default function Index() {
  const [data, setdata] = useState([]);
  const [tahun, settahun] = useState('2023');

  const [casePersebaran, setcase] = useState('desa');

  const [desa, setDesa] = useState([]);
  const [refDesa, setRefDesa] = useState([]);

  const [refKecamatan, setRefKecamatan] = useState([]);
  const [kecamatan, setKecamatan] = useState([]);

  const [dusun, setDusun] = useState([]);
  const [result, setResult] = useState([]);

  const [loadMap, setLoadmap] = useState(false);
  const [loading, setloading] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    getDistrict();
  }, [refKecamatan.length]);

  const onChangeKecamatan = (value) => {
    setKecamatan(value);
  };

  const listResult = ['Terduga', 'Positif'];
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setResult(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  useEffect(() => {
    console.log(result);
  }, [result]);

  useEffect(() => {
    kecamatan.forEach((element) => {
      setRefDesa(element.villages);
    });
  }, [kecamatan.length]);

  const getDistrict = async () => {
    const res = await Config.get('/demograph/cities/find/3404');
    if (res?.code === 200) {
      setRefKecamatan(res.data.districts);
    }
  };

  const getDusun = async () => {
    const param = {
      villages: desa.map((a) => a.id)
    };
    const res = await Config.post('/demograph/dusun/find', param);
    if (res?.code === 200) {
      setDusun(res.data);
    }
  };

  useEffect(() => {
    if (casePersebaran === 'dusun') {
      getDusun();
    } else {
      setDusun([]);
    }
  }, [casePersebaran]);
  const getData = async () => {
    setloading(true);
    const param = {
      case: casePersebaran,
      dusun: dusun.map((a) => a.id),
      desa: desa.map((a) => a.id),
      result
    };
    const res = await Config.post('/dashboard/tb/count', param);
    if (res?.code === 200) {
      setLoadmap(true);
      setdata(res.data);
    } else {
      setdata([]);
    }
    setLoadmap(false);
    setloading(false);
  };

  const json2array = (json) => {
    const result = [];
    const keys = Object.keys(json);
    let no = 1;
    keys.forEach((key) => {
      const positif = json[key]?.Positif ? json[key].Positif.count : 0;
      const terduga = json[key]?.Terduga ? json[key].Terduga.count : 0;
      let takTerduga = 0;
      if (json[key]) {
        takTerduga = json[key]['Tak Terduga'] ? json[key]['Tak Terduga'].count : 0;
      } else {
        takTerduga = 0;
      }
      const screening = positif + terduga + takTerduga;
      result.push({ no, name: json[key].name ?? key, screening, positif, terduga });
      no += 1;
    });
    return result;
  };
  return (
    <Page title="Dashboard Dinkes Sleman">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            <Card
              sx={{
                mb: 4,
                boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
                '&:hover': {
                  boxShadow: '-1px 2px 15px 0px rgba(0,0,0,0.2)'
                }
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography variant="h8">Pilih Filter Pemetaan</Typography>
              </Box>
              <Box sx={{ p: 2 }}>
                <FormControl sx={{ m: 1, minWidth: '100%' }}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={refKecamatan}
                    disableCloseOnSelect
                    value={kecamatan}
                    onChange={(event, value) => onChangeKecamatan(value)}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pilih Kecamatan"
                        placeholder="Pilih Kecamatan"
                      />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: '100%' }}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={refDesa}
                    value={desa}
                    onChange={(event, value) => setDesa(value)}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    // style={{ width: 500 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Pilih Desa" placeholder="Pilih Desa" />
                    )}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: '100%' }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Kategori Persebaran
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={casePersebaran}
                    onChange={(e) => setcase(e.target.value)}
                    minwidth="100%"
                    label="Jenis Persebaran"
                  >
                    <MenuItem value="desa">Desa</MenuItem>
                    <MenuItem value="dusun">Dusun</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  <InputLabel id="demo-multiple-chip-label">kategori</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={result}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {listResult.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button variant="outlined" disabled={loading} onClick={() => getData()}>
                  Cari
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <Card
              sx={{
                mb: 4,
                p: 2,
                boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
                '&:hover': {
                  boxShadow: '-1px 2px 15px 0px rgba(0,0,0,0.2)'
                }
              }}
            >
              <CardHeader title="Peta Persebaran Kasus Tb" />
              <Box sx={{ p: 2 }}>
                {loading ? (
                  <Box width="100%">
                    <Skeleton variant="rectangular" width="100%" height={400} />
                  </Box>
                ) : (
                  <MapTb
                    data={data}
                    tahun={tahun}
                    load={loadMap}
                    cat={casePersebaran}
                    kategori={result}
                  />
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
        <TableData datas={json2array(data)} kategori={result} />
      </Container>
    </Page>
  );
}
