const Reducer = (state = { case: '', filter: '', refProvince: [], refKabupaten: [] }, action) => {
  switch (action.type) {
    case 'NASIONAL':
      state = {
        ...state,
        case: action.type,
        filter: action.state
      };
      break;
    case 'PROVINSI':
      state = {
        ...state,
        case: action.type,
        filter: action.state
      };
      break;
    case 'KABUPATEN':
      state = {
        ...state,
        case: action.type,
        filter: action.state
      };
      break;
    case 'refProvince':
      state = {
        ...state,
        refProvince: action.state
      };
      break;
    default:
      state = {
        ...state,
        case: '',
        filter: ''
      };
  }
  return state;
};

export default Reducer;
