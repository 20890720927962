import * as XLSX from "xlsx-js-style";
import * as FileSaver from 'file-saver';

export const downloadSpmExcel = (list, title, option) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const Heading = [
            ['No', 'Indikator Kinerja / Jenis Layanan SPM', 'Indikator Pencapaian / Output', '', '', 'Total Pencapaian']
        ];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(csvData, { origin: 'A2', skipHeader: true });
        XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
        XLSX.utils.book_append_sheet(wb, ws, 'Records');

        applyCellStyle(ws, 'A1:F1', { font: { bold: true } })
        applyCellStyle(ws, 'A3:F6', { font: { bold: true } })
        applyCellStyle(ws, 'B7', { font: { bold: true } })
        applyCellStyle(ws, 'F7', { font: { bold: true } })
        applyCellStyle(ws, 'A8:F10', { font: { bold: true } })
        applyCellStyle(ws, `F11:F${10 + list.length}`, { font: { bold: true } })
        applyCellStyle(ws, `B${10 + list.length}:E${10 + list.length}`, { font: { bold: true } })

        applyCellStyle(ws, 'A3:F3', { fill: { fgColor: { rgb: "33ccff" } }, font: { bold: true, sz: 15 } })
        applyCellStyle(ws, 'A4:F4', { fill: { fgColor: { rgb: "99ff33" } } })
        applyCellStyle(ws, 'F5:F6', { fill: { fgColor: { rgb: "ffff00" } } })
        applyCellStyle(ws, 'F8', { fill: { fgColor: { rgb: "ffff00" } } })
        applyCellStyle(ws, `B11:B${10 + list.length}`, { alignment: { wrapText: true } })

        applyCellBorder(ws, 'A1:F3')
        applyCellBorder(ws, 'B4:F4')
        applyCellBorder(ws, 'B5:B6')
        applyCellBorder(ws, 'C5:C6', ['right'])
        applyCellBorder(ws, 'D5:D6', ['right'])
        applyCellBorder(ws, 'F5:F6')
        applyCellBorder(ws, 'B7:F7')
        applyCellBorder(ws, 'B8:F8')
        applyCellBorder(ws, 'B9:B10')
        applyCellBorder(ws, 'C9:C10', ['right'])
        applyCellBorder(ws, 'D9:D10', ['right'])
        applyCellBorder(ws, 'F9:F10')
        applyCellBorder(ws, `B11:F${10 + list.length}`)
        applyCellBorder(ws, `A${10 + list.length}`, ['bottom'])

        applyCellAlignment(ws, "A1:F2", 'center', 'center')
        applyCellAlignment(ws, "B3", 'center', 'center')
        applyCellAlignment(ws, "F3:F10", 'center', 'right')
        applyCellAlignment(ws, "A4", 'center', 'center')
        applyCellAlignment(ws, "B5:E6", 'center', 'center')
        applyCellAlignment(ws, "B9", 'center', 'left')
        applyCellAlignment(ws, "C9:E10", 'center', 'center')
        applyCellAlignment(ws, `C11:F${10 + list.length}`, 'center', 'right')

        ws["!merges"] = [
            merge('C1:E1'),
            merge('C2:E2'),
            merge('C3:E3'),
            merge('C4:E4'),
            merge('A5:A6'),
            merge('B5:B6'),
            merge('F5:F6'),
            merge('B8:E8'),
            merge('A9:A10'),
            merge('B9:B10'),
            merge('F9:F10'),
        ];
        ws['!cols'] = [
            { width: 5 },
            { width: 50 },
            { width: 21 },
            { width: 21 },
            { width: 21 },
            { width: 10 },
        ]
        ws['!rows'] = [
            {'hpt' : 28},
            {'hpt' : 16},
            {'hpt' : 20},
            {'hpt' : 16},
            {'hpt' : 16},
        ]
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    if (option) {

    }

    exportToCSV(buildSpmData(list, title), `SPM-${title.replaceAll(' ', '-')}`)
}

const buildSpmData = (data, title) => {

    function number(x) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    }

    const dataToRow = () => data.map((v, k) => ({
        a: '',
        b: k + 1 === data.length ? 'JUMLAH' : `${k + 1}. ${v.question}`,
        c: v.trx_answer ? number(v.trx_answer.dibutuhkan) : '',
        d: v.trx_answer ? number(v.trx_answer.tersedia) : '',
        e: v.trx_answer ? number(v.trx_answer.belum_tersedia) : '',
        f: v.trx_answer ? `${v.trx_answer.persentase}%` : '',
    }))

    const getPersentaseMutu = () => (data.reduce((a, b) => a + (b.trx_answer ? +b.trx_answer.persentase : 0), 0) / data.length).toFixed(2)

    return [
        {a:'1',b:'2',c:'3',d:'3',e:'3',f:'4',},
        {a:'',b:'KATEGORI INDEKS PENCAPAIAN SPM',c:'',d:'',e:'',f:'10%',},
        {a:'1.',b:`Pelayanan Kesehatan ${title}`,c:'',d:'',e:'',f:'10%',},
        {a:'',b:'PERSENTASE PENCAPAIAN PENERIMA LAYANAN DASAR (80%)',c:'Jumlah Total',d:'Jumlah Total',e:'Yang Belum',f:'10%',},
        {a:'',b:'',c:'Yang Harus Dilayani',d:'Yang Terlayani',e:'Terlayani',f:'10%',},
        {a:'',b:'A. JUMLAH YANG HARUS DILAYANI :',c:'13.637',d:'8.794',e:'4.843',f:'64,49%',},
        {a:'',b:'PERSENTASE PENCAPAIAN MUTU MINIMAL LAYANAN DASAR (20%)',c:'',d:'',e:'',f:'18,99%',},
        {a:'',b:'B. Jumlah Mutu Barang / Jasa / SDM',c:'Jumlah Mutu Barang /',d:'Jumlah Mutu Barang /',e:'Jumlah Mutu Barang /',f:getPersentaseMutu() + '%',},
        {a:'',b:'',c:'Jasa Yang Dibutuhkan',d:'Jasa Yang Tersedia',e:'Jasa Yang Belum Tersedia',f:'',},
        ...dataToRow()
    ]
}

export function applyCellAlignment(ws, cell, verAlignment, horAlignment) {
    const style = {
        alignment: {
            vertical: verAlignment,
            horizontal: horAlignment,
            wrapText: true
        }
    }

    applyCellStyle(ws, cell, style)
}

export function applyCellBorder(ws, cell, position = 'all') {
    let a = {}
    const pos = position === 'all' ? ['top', 'left', 'right', 'bottom'] : position
    pos.forEach(v => {
        a[v] = { style: 'thin', color: { auto: 1 } }
    })

    applyCellStyle(ws, cell, {
        border: a
    })
}

export function applyCellStyle(ws, cell, style) {
    if (cell.includes(':')) {
        const ar = cell.split(':')
        const start = ar[0]
        const end = ar[1]

        const startCol = start[0]
        const startRow = +start.substr(1)

        const endCol = end[0]
        const endRow = +end.substr(1)

        for (let a = startCol.charCodeAt(0); a <= endCol.charCodeAt(0); a++) {
            for (let b = startRow; b <= endRow; b++) {
                const cellIndex = String.fromCharCode(a) + '' + b

                if (ws[cellIndex]) {
                    if (ws[cellIndex].s) {
                        ws[cellIndex].s = { ...ws[cellIndex].s, ...style }
                    }
                    else {
                        ws[cellIndex].s = style
                    }
                }
            }
        }
    }
    else {
        if (ws[cell].s)
            ws[cell].s = { ...ws[cell].s, ...style }
        else
            ws[cell].s = style
    }
}

const merge = (cells) => {
    const a = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('')
    const split = cells.split(':')
    const c1 = split[0]
    const c2 = split[1]
    return { s: { r: +c1.replace(/\D/g,'') - 1, c: a.findIndex(v => v === c1[0]) }, e: { r: +c2.replace(/\D/g,'') - 1, c: a.findIndex(v => v === c2[0]) } }
}