import React, { useState, useEffect } from 'react';
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Popup,
  GeoJSON,
  Polygon,
  Tooltip
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import Config from '../../Config';
import villageBorder from './villageBorder';

const MapTb = ({ data, tahun, load, cat, kategori }) => {
  const [center, setCenter] = useState([-7.691711, 110.390204]);
  const [map, setmap] = useState(null);
  const [village, setvillage] = useState([]);
  const [poly, setpoly] = useState([]);
  const [dusun, setdusun] = useState([]);
  const [coba, setcoba] = useState([]);

  function onEachFeature(feature, layer) {
    // does this feature have a property named popupContent?
    if (feature.properties && feature.properties.popupContent) {
      layer.bindPopup(feature.properties.popupContent);
    }
  }

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      // generatePolyline();
    }
  }, [village, tahun]);

  const arrayMove = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k > 1) {
        k -= 1;
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
  };

  const generatePolyline = () => {
    const pol = [];
    if (Object.keys(data).length > 0) {
      village.map((a, k) => {
        const x = data.find(
          (e) =>
            e.display.toLowerCase().split(' ').join('') ===
            a.village.toLowerCase().split(' ').join('')
        );
        if (x) {
          pol.push(
            <Polygon
              key={k + 1}
              pathOptions={{
                color: 'black',
                stroke: true,
                weight: 0.3,
                fillOpacity: 0
              }}
              positions={a.border}
            >
              <Tooltip sticky>
                <div>
                  <div>{a.village}</div>
                  <div>Screening : {x ? x.screening : 0}</div>
                  <div>Terduga : {x ? x.terduga : 0}</div>
                  <div>Kasus : {x ? x.kasus : 0}</div>
                </div>
              </Tooltip>
            </Polygon>
          );
        }
        return pol;
      });
    }
    setpoly(pol);
  };

  useEffect(() => {
    if (map) {
      map.target._onResize();
      if (cat === 'dusun') {
        generateByDusun();
      } else {
        generateByDesa();
      }
    }
  }, [map]);

  useEffect(() => {
    if (load && map) {
      map.target._onResize();
      if (cat === 'dusun') {
        generateByDusun();
      } else {
        generateByDesa();
      }
    }
  }, [load, Object.keys(data).length]);
  // const clearMap = () => {
  //   for (i in map._layers) {
  //     if (map._layers[i].options.format == undefined) {
  //       try {
  //         map.removeLayer(map._layers[i]);
  //       } catch (e) {
  //         console.log('problem with ' + e + map._layers[i]);
  //       }
  //     }
  //   }
  // };
  const generateByDusun = () => {
    if (Object.keys(data).length > 0) {
      Object.keys(data).map((key) => {
        const newLatLong = [];
        const element = data[key];
        if (element.boundaries) {
          const boundaries = JSON.parse(element.boundaries);
          for (let j = 0; j < boundaries[0].length; j += 1) {
            const el = boundaries[0][j];
            newLatLong.push(arrayMove(el, 0, 1));
          }
        }
        const Positif = data[key]?.Positif ? data[key].Positif.count : 0;
        const Terduga = data[key]?.Terduga ? data[key].Terduga.count : 0;
        let TakTerduga = 0;
        if (data[key]) {
          TakTerduga = data[key]['Tak Terduga'] ? data[key]['Tak Terduga'].count : 0;
        } else {
          TakTerduga = 0;
        }
        L.polygon(newLatLong, {
          color: '#05b7f7',
          stroke: true,
          weight: 2,
          fillOpacity: 0.2
        })
          // .bindTooltip(
          //   `<div>
          //     <div> <h4>${data[key].name} </h4></div>
          //   <div><h4>Positif : ${data[key]?.Positif ? data[key].Positif.count : 0} </h4></div>
          //   <div>Terduga: ${data[key]?.Terduga ? data[key].Terduga.count : 0}</div>
          //   <div>Screening: ${Terduga + TakTerduga + Positif}</div>
          //  </div>`
          // )
          .bindTooltip(
            `<div> 
          <div> <h4>${data[key].name}  </h4></div>
        
        ${
          kategori.includes('Positif') && kategori.includes('Terduga')
            ? `<div><h4>Positif : ${
                data[key]?.Positif ? data[key].Positif.count : 0
              } </h4></div><div>Terduga: ${data[key]?.Terduga ? data[key].Terduga.count : 0}</div>`
            : ``
        }
        ${
          kategori.includes('Positif') && kategori.length === 1
            ? `<div><h4>Positif : ${data[key]?.Positif ? data[key].Positif.count : 0} </h4></div>`
            : ``
        }
        ${
          kategori.includes('Terduga') && kategori.length === 1
            ? `<div>Terduga: ${data[key]?.Terduga ? data[key].Terduga.count : 0}</div>`
            : ``
        }
        ${
          kategori.length === 0
            ? `<div><h4>Positif : ${data[key]?.Positif ? data[key].Positif.count : 0} </h4></div>
               <div>Terduga: ${data[key]?.Terduga ? data[key].Terduga.count : 0}</div>
               <div>Screening: ${Terduga + TakTerduga + Positif}</div>`
            : ``
        }
       </div>`
          )
          .addTo(map.target);
        return data;
      });
    }
  };

  const generateByDesa = () => {
    const dat = villageBorder.filter((e) => e.district === 'SLEMAN');
    if (Object.keys(data).length > 0) {
      const result = Object.keys(data);
      const newData = [];
      result.forEach((element) => {
        if (dat.find((e) => e.village === element.replace(' ', '')) !== undefined) {
          newData.push(dat.find((e) => e.village === element.replace(' ', '')));
        }
      });
      if (newData.length > 0) {
        for (let i = 0; i < newData.length; i += 1) {
          const newLatLong = [];
          const element = newData[i];
          for (let j = 0; j < element.border.length; j += 1) {
            const el = element.border[j];
            newLatLong.push(arrayMove(el, 0, 1));
          }

          const hasil = result.find((a) => a.replace(' ', '') === element.village);
          const Positif = data[hasil]?.Positif ? data[hasil].Positif.count : 0;
          const Terduga = data[hasil]?.Terduga ? data[hasil].Terduga.count : 0;
          let TakTerduga = 0;
          if (data[hasil]) {
            TakTerduga = data[hasil]['Tak Terduga'] ? data[hasil]['Tak Terduga'].count : 0;
          } else {
            TakTerduga = 0;
          }
          L.polygon(newLatLong, {
            color: '#05b7f7',
            stroke: true,
            weight: 0.3,
            fillOpacity: 0.2
          })
            //   .bindTooltip(
            //     `<div>
            //     <div> <h4>${hasil} </h4></div>
            //   <div><h4>Positif : ${data[hasil]?.Positif ? data[hasil].Positif.count : 0} </h4></div>
            //   <div>Terduga: ${data[hasil]?.Terduga ? data[hasil].Terduga.count : 0}</div>
            //   <div>Screening: ${Terduga + TakTerduga + Positif}</div>
            //  </div>`
            //   )
            .bindTooltip(
              `<div> 
            <div> <h4>${hasil} </h4></div>
          
          ${
            kategori.includes('Positif') && kategori.includes('Terduga')
              ? `<div><h4>Positif : ${
                  data[hasil]?.Positif ? data[hasil].Positif.count : 0
                } </h4></div><div>Terduga: ${
                  data[hasil]?.Terduga ? data[hasil].Terduga.count : 0
                }</div>`
              : ``
          }
          ${
            kategori.includes('Positif') && kategori.length === 1
              ? `<div><h4>Positif : ${
                  data[hasil]?.Positif ? data[hasil].Positif.count : 0
                } </h4></div>`
              : ``
          }
          ${
            kategori.includes('Terduga') && kategori.length === 1
              ? `<div>Terduga: ${data[hasil]?.Terduga ? data[hasil].Terduga.count : 0}</div>`
              : ``
          }
          ${
            kategori.length === 0
              ? `<div><h4>Positif : ${
                  data[hasil]?.Positif ? data[hasil].Positif.count : 0
                } </h4></div>
                 <div>Terduga: ${data[hasil]?.Terduga ? data[hasil].Terduga.count : 0}</div>
                 <div>Screening: ${Terduga + TakTerduga + Positif}</div>`
              : ``
          }
         </div>`
            )
            .addTo(map.target);
          newData[i].border = newLatLong;
        }
      }
      setvillage(newData);
    }
  };
  return (
    <MapContainer
      id="kunjungan"
      center={center}
      style={{ width: '100%', height: '58vh' }}
      zoom={11}
      whenReady={setmap}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
};

export default MapTb;
