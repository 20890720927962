import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
// import Ds from './pages/DashboardApp';
// import Usr from './pages/User';
import Tb from './pages/Tb/Index';
import Spm from './pages/Spm/Index';
import Hypertension from './pages/Hypertension/Index';
import Portal from './pages/Portal/Index';
// import Sarana from './pages/Aspak/Index';
// import Sdm from './pages/Sdm/Index';
// import Utilisasi from './pages/Utilisasi/Index';
// import Perujukan from './pages/Perujukan/Index';
// import Analisis from './pages/Analisis/Index';

import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/portal" replace /> },
        // { path: 'home', element: <Home /> },
        { path: 'portal', element: <Portal /> },
        // { path: 'tb', element: <Tb /> },
        // { path: 'spm', element: <Spm /> },
        // { path: 'hypertension', element: <Hypertension /> }
        // { path: 'aspak', element: <Sarana /> },
        // { path: 'sdm', element: <Sdm /> },
        // { path: 'utilisasi', element: <Utilisasi /> },
        // { path: 'perujukan', element: <Perujukan /> },
        // { path: 'Analisis', element: <Analisis /> },
        // { path: 'ex', element: <Ds /> },
        // { path: 'usr', element: <Usr /> }
      ]
    },
    { path: '/', element: <Navigate to="/dashboard" replace /> },

    {
      path: '/404',
      element: <NotFound />,
      children: [{ element: <Navigate to="/404" replace /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
