import axios from 'axios';

class API {
  service = axios.create({
    // baseURL: 'https://smartdinkes.slemankab.go.id/be-db-migrate',
    baseURL: 'https://sikda.simkes.id',
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json'
    }
  });

  async get(url) {
    try {
      const res = await this.service.get(url, {
        headers: this.header
      });
      return res.data;
    } catch (error) {
      return error.response;
    }
  }

  async post(url, data) {
    try {
      const res = await this.service.post(url, data, {
        headers: this.header
      });
      return res.data;
    } catch (error) {
      return error.response;
    }
  }
}

export default new API();
