import {Box, Card, Container, Grid, Typography} from "@mui/material";
import Page from '../../components/Page';

const Index = () => {
    return (
        <Page title="Dashboard Dinkes Sleman">
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Card
                            sx={{
                                mb: 4,
                                mt: 4,
                                boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.4)',
                                '&:hover': {
                                    boxShadow: '-1px 2px 15px 0px rgba(0,0,0,0.2)'
                                }
                            }}
                        >
                            <Box>
                                <iframe
                                    src={'https://smartdinkes.slemankab.go.id/backend-v4/hipertensi/dashboard'}
                                    title={'ht'}
                                    style={{width: '100%', height: 800}}
                                />
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default Index